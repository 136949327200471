/**
 * Helper functions for dealing with the "home node" of the document. The
 * home node is the entry point for the project when exporting or using the
 * shared preview.
 *
 * The home node is stored on the RootNode of the canvas tree but this module
 * acknowledges that this value may become out of sync with the underlying
 * tree. The exported functions will always try and return a valid value in
 * situations where the document is out of sync.
 *
 * The resetHomeNode() function is then used to attempt to repair the document
 * and put it back into a consistent state.
 */
import { assert } from "@framerjs/shared"
import { CanvasTree, CanvasNode, isDeviceNode, isShapeContainerNode } from ".."
import { isCodeComponentNode } from "../nodes/CodeComponentNode"
import { isTextNode } from "../nodes/TextNode"
import type { MaybeNodeID } from "../nodes/NodeID"
import { PageNode, isPageNode } from "../nodes/PageNode"

function getUnsafeHomeNodeId(page: PageNode): MaybeNodeID {
    return page.homeNodeId
}

/**
 * Returns the CanvasNode referenced by PageNode.homeNodeId. This will not
 * check the validity of the homeNodeId.
 */
function getUnsafeHomeNode(tree: CanvasTree, page: PageNode): CanvasNode | null {
    const homeNodeId = getUnsafeHomeNodeId(page)
    return homeNodeId ? tree.getNode(homeNodeId) : null
}

/**
 * Returns true if the node provided is the home node for the given page. It
 * should be noted that this function behaves consistently with getHomeNode()
 * and will check the validity of the PageNode.homeNodeId in determining
 * the result.
 */
export function isHomeNode(tree: CanvasTree, page: PageNode, node: CanvasNode): boolean {
    const homeNode = getHomeNode(tree, page)
    return !!homeNode && homeNode.id === node.id
}

export function canBeHomeNode(tree: CanvasTree, page: PageNode, node: CanvasNode): boolean {
    return tree.isAncestorOfNode(node, page.id) && tree.isGroundNode(node) && !isTextNode(node)
}

export function hasValidHomeNode(tree: CanvasTree, page: PageNode): boolean {
    const homeNodeId = getUnsafeHomeNodeId(page)

    if (!homeNodeId) {
        return false
    }

    const homeNode = tree.getNode(homeNodeId)

    return !!homeNode && canBeHomeNode(tree, page, homeNode)
}

export function setHomeNode(tree: CanvasTree, page: PageNode, node: CanvasNode | null): void {
    const currentHomeNodeId = getUnsafeHomeNodeId(page)

    if (node && !canBeHomeNode(tree, page, node)) {
        return
    }

    if ((node && node.id === currentHomeNodeId) || node === currentHomeNodeId) {
        return
    }

    tree.updateNode(page, {
        homeNodeId: node ? node.id : null,
    })
}

export function getFallbackHomeNode(tree: CanvasTree, page: PageNode): CanvasNode | null {
    const homeNode = getUnsafeHomeNode(tree, page)

    if (homeNode && tree.isAncestorOfNode(homeNode, page.id)) {
        return tree.getGroundNodeFor(homeNode)
    }

    const groundNodes = page.getGroundNodes()
    if (groundNodes.length > 0) {
        // Set the first viable node in the Layers panel as the fallback home node
        return groundNodes.reverse().find(node => canBeFallbackHomeNode(tree, page, node)) ?? null
    }

    return null
}

// Ignore Graphics & Code Components when picking a fallback. These
// can still be set manually if needed.
function canBeFallbackHomeNode(tree: CanvasTree, page: PageNode, node: CanvasNode): boolean {
    if (!canBeHomeNode(tree, page, node)) return false
    if (isShapeContainerNode(node)) return false
    if (isCodeComponentNode(node) && !isDeviceNode(node)) return false
    return true
}

/**
 * Returns a CanvasNode to be used as the home node for the project. This
 * will be the node referenced by the firstPage PageNode.homeNodeId if valid otherwise
 * it will fallback to the next appropriate node.
 */
export function getProjectHomeNode(tree: CanvasTree) {
    const page = tree.root.children.find(isPageNode)
    assert(page, "Tree must include at least one page")
    return getHomeNode(tree, page)
}

/**
 * Returns a CanvasNode to be used as the home node for a page. This
 * will be the node referenced by the PageNode.homeNodeId if valid otherwise
 * it will fallback to the next appropriate node.
 */
export function getHomeNode(tree: CanvasTree, page: PageNode): CanvasNode | null {
    const node = getUnsafeHomeNode(tree, page)
    return node && canBeHomeNode(tree, page, node) ? node : getFallbackHomeNode(tree, page)
}

export function resetHomeNode(tree: CanvasTree, page: PageNode): void {
    const fallbackNode = getFallbackHomeNode(tree, page)
    setHomeNode(tree, page, fallbackNode)
}
