import type { CanvasNode } from "../../nodes/CanvasNode"
import type { UnknownProps } from "../utils/collectVariantDefinitionForNode"
import { StackProperties } from "framer"
import { isFrameNode } from "../../nodes/FrameNode"

export function collectStackLayout(node: CanvasNode, props: UnknownProps) {
    if (!isFrameNode(node) || !node.stackEnabled) return

    const stackProps: Partial<StackProperties> = {
        direction: node.stackDirection,
        distribution: node.stackDistribution,
        alignment: node.stackAlignment,
        gap: node.stackGap,
    }

    Object.assign(props, stackProps, { __fromCanvasComponent: true })
}

export const reverseStackLayoutPropMap = {
    stackDirection: "direction",
    stackDistribution: "distribution",
    stackAlignment: "alignment",
    stackGap: "gap",
}
