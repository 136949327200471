import type { CanvasNode } from "document/models/CanvasTree/nodes/CanvasNode"

// WARNING: this function will return `null` if the node isn't in a tree,
// instead of throwing an error, so make sure this is really what you want
// before you use it. Always check if you can use `tree.getParent()`, instead.
export function unsafeGetParent(node: CanvasNode) {
    if (node.cache.latest && node.parentid) {
        const parent = node.tree().get(node.parentid)
        return parent
    }
    return null
}
