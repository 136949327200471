// IMPORTANT!
// Everything in this file is also used in FramerBuildService.
// Please make sure its externalDocuments.ts file matches this one.

import { assert } from "@framerjs/shared"
import { createAssetReference } from "@framerjs/assets"
import type { DesignJSON, TokenMap } from "../../host/componentLoader/types"

export interface ReplicaNodeOverrides {
    [key: string]: unknown
    _deleted?: string[]
}

export interface ReplicaOverrides {
    [key: string]: ReplicaNodeOverrides
}

export interface ReplicaInfo {
    master: string
    overrides: ReplicaOverrides
    inheritsFrom?: string
}

interface Node {
    id: string
    codeComponentIdentifier?: string
    fillImage?: string
    replicaInfo?: ReplicaInfo
}

/** Collects design components in the specified package's document.json. */
export function collectDesignComponents(node: any, packageIdentifier: string, components: any[]) {
    if (!node.id || node.replicaInfo) return

    const children = node.children || []
    for (const child of children) {
        collectDesignComponents(child, packageIdentifier, components)
    }

    if (!node.isMaster || node.isVariant || (node.isExternalMaster && node.isExternalMaster !== packageIdentifier)) {
        return
    }

    const component = updateDesignComponentJSON(node, `${packageIdentifier}-${node.id}-`, packageIdentifier, null)
    component.id = `${packageIdentifier}-${node.id}`
    component.isMaster = true
    component.isExternalMaster = packageIdentifier
    component.top = 0
    component.left = 0
    component.bottom = null
    component.right = null
    components.push(component)
}

/** Collects tokens in the specified package's document.json. */
export function collectTokens(documentJSON: DesignJSON): TokenMap {
    // We now perform the token collection in the build process.
    if ("tokens" in documentJSON) {
        return documentJSON.tokens
    }
    // If we get here, we're dealing with legacy vendors.js.

    if (!documentJSON.root || !documentJSON.root.tokens) return {}

    const tokens: TokenMap = {}
    const index = documentJSON.root.tokensIndex || Object.keys(documentJSON.root.tokens)
    for (const key of index) {
        tokens[key] = documentJSON.root.tokens[key]
    }
    return tokens
}

function ensureAbsoluteOverrides(overrides: ReplicaOverrides, packageIdentifier: string): ReplicaOverrides {
    const result: ReplicaOverrides = {}
    for (const [key, entry] of Object.entries(overrides)) {
        result[`${packageIdentifier}-${key}`] = entry
    }
    return result
}

function ensureAbsoluteReferences(node: Node, packageIdentifier: string) {
    const { codeComponentIdentifier, fillImage, replicaInfo } = node
    // TODO: Better way to decide if reference is local?
    if (codeComponentIdentifier && codeComponentIdentifier.startsWith(".")) {
        node.codeComponentIdentifier = `${packageIdentifier}/${codeComponentIdentifier}`
    }
    if (fillImage) {
        node.fillImage = createAssetReference(`design/images/${fillImage}`, packageIdentifier)
    }

    // Make sure that a reference to another design component in
    // the same package is also updated to include a prefix.
    // TODO: Better way to decide if reference is local?
    if (replicaInfo && !replicaInfo.master.includes("-")) {
        node.replicaInfo = {
            overrides: ensureAbsoluteOverrides(replicaInfo.overrides, packageIdentifier),
            master: `${packageIdentifier}-${replicaInfo.master}`,
        }
    }
}

function updateDesignComponentJSON(
    node: any,
    idPrefix: string,
    packageIdentifier: string,
    parentid: string | null
): any {
    assert(!node.id.startsWith(idPrefix))

    const id = idPrefix + node.id

    let children: any = undefined
    if (node.children && Array.isArray(node.children)) {
        children = node.children.map((child: any) => updateDesignComponentJSON(child, idPrefix, packageIdentifier, id))
    }

    const clone = { ...node, id, parentid, children, isMaster: false }
    ensureAbsoluteReferences(clone, packageIdentifier)

    return clone
}
