import type { DrawableNode, CanvasNode } from "document/models/CanvasTree"

export interface WithLock {
    locked: boolean
}

export const lockDefaults: WithLock = {
    locked: false,
}

const key: keyof WithLock = "locked"

export function withLock(node: CanvasNode): node is DrawableNode & WithLock {
    return key in node
}
