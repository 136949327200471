import { CanvasNode } from "document/models/CanvasTree/nodes/CanvasNode"
import { withRadius } from "document/models/CanvasTree/traits/Radius"
import { withRadiusPerCorner } from "document/models/CanvasTree/traits/RadiusPerCorner"

export function borderRadiusForNode(node: CanvasNode) {
    if (withRadiusPerCorner(node) && node.radiusPerCorner) {
        return {
            topLeft: node.radiusTopLeft,
            topRight: node.radiusTopRight,
            bottomRight: node.radiusBottomRight,
            bottomLeft: node.radiusBottomLeft,
        }
    } else if (withRadius(node)) {
        const resolvedRadius = node.resolveValue("radius") || 0
        return {
            topLeft: resolvedRadius,
            topRight: resolvedRadius,
            bottomRight: resolvedRadius,
            bottomLeft: resolvedRadius,
        }
    }
}
