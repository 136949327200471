import * as React from "react"
import { cx } from "linaria"
import type { OverrideType, HTMLDivAttributes } from "./types"
import { IconDropdownButtonChevron } from "../icons"
import * as styles from "./DropdownButton.styles"

export type DropdownButtonProps = OverrideType<
    HTMLDivAttributes,
    {
        title?: string
    }
>

/** Currently the Dropdown Button is not responsible for displaying the dropdown. */
export const DropdownButton = React.memo(
    React.forwardRef(function DropdownButton(
        { className, title, children, ...rest }: DropdownButtonProps,
        ref: React.Ref<HTMLDivElement>
    ) {
        return (
            <div className={cx(styles.dropDown, className)} {...rest} ref={ref}>
                {title || children}
                <div className={styles.spacer} />
                <IconDropdownButtonChevron />
            </div>
        )
    })
)
