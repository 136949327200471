import { V64, V65 } from "../types"
import { transform } from "../utils/transform"
import { _, exactCheck } from "../utils/exactCheck"

export function migration_64_65(documentJSON: V64.Tree): V65.Tree {
    const migratedRoot = transform(documentJSON.root, node => {
        if (node.__class === "RootNode") {
            return exactCheck({ disableBackdropFilters: false, ...node }, _ as V65.RootNode)
        }
        return exactCheck(node, _ as V65.TreeNode)
    })
    return { version: 65, root: migratedRoot }
}
