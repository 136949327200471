import { assert } from "@framerjs/shared"
import { randomId } from "./random"
import { ModuleSpecifier } from "./types"

export type RedirectMap = Record<ModuleSpecifier, ModuleSpecifier>

const SW_SCOPE = "/"
const SW_PATH = SW_SCOPE + "sandboxServiceWorker.js"

export async function setModuleRedirects(clientId: string, redirectMap: RedirectMap) {
    const messageId = randomId()

    const activeServiceWorker = await navigator.serviceWorker.getRegistrations().then(regs => {
        // There can theoretically be multiple registrations for different scopes
        // we are interested in the one handling "/", this is our modules SW.
        const registration = regs.find(
            reg => new URL(reg.scope).pathname === SW_SCOPE && reg.active?.scriptURL?.endsWith(SW_PATH)
        )
        assert(registration, "Cannot find active sandboxServiceWorker")
        return getServiceWorkerInstance(registration)
    })

    const responsePromise = new Promise<void>((resolve, reject) => {
        navigator.serviceWorker.addEventListener("message", onMessage)

        // We should receive the "pong" message from SW almost instantly,
        // if we haven't within 1s, throw a timeout error.
        const timerId = window.setTimeout(() => {
            navigator.serviceWorker.removeEventListener("message", onMessage)
            reject(new Error("setModuleRedirects failed due to a timeout."))
        }, 1000)

        function onMessage(event: MessageEvent) {
            const { data } = event
            if (data.sender !== "modules-server" || data.type !== "return" || data.messageId !== messageId) return

            navigator.serviceWorker.removeEventListener("message", onMessage)
            window.clearTimeout(timerId)
            resolve()
        }
    })

    activeServiceWorker.postMessage({
        method: "setModuleRedirects",
        clientId,
        redirectMap,
        messageId,
    })

    return responsePromise
}

async function getServiceWorkerInstance(registration: ServiceWorkerRegistration): Promise<ServiceWorker> {
    if (registration.active) return registration.active

    const swNullable: ServiceWorker | null = registration.installing ?? registration.waiting
    assert(swNullable, "Cannot obtain the ServiceWorker instance.")

    // Making TS happy, otherwise TS invalidates the refinement later inside the event listener.
    const sw: ServiceWorker = swNullable

    // Wait until SW is activated or throw a timeout error
    return await new Promise<ServiceWorker>((resolve, reject) => {
        sw.addEventListener("statechange", handleStateChange)

        const timerId = window.setTimeout(() => {
            sw.removeEventListener("statechange", handleStateChange)
            reject(new Error("Activation of the ServiceWorker failed due to a timeout."))
        }, 10000)

        function handleStateChange() {
            if (sw.state !== "activated") return

            window.clearTimeout(timerId)
            sw.removeEventListener("statechange", handleStateChange)
            resolve(sw)
        }
    })
}
