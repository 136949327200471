import { css } from "linaria"
import { colors, dimensions, cursors, fonts } from "../tokens"

export const stackContainer = css`
    position: relative;
    width: 100%;
`

export const navigationContent = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.25s transform, 0.25s opacity;
`

export const navigationContentCurrent = css`
    position: relative;
`

export const transparent = css`
    opacity: 0;
`

export const offsetToLeft = css`
    transform: translate(-30%);
`

export const offsetToRight = css`
    transform: translate(100%);
`

export const navigationContentEnterAnimation = css`
    animation: enter 0.2s;
    @keyframes enter {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: none;
        }
    }
`

export const navigationBar = css`
    position: relative;
    display: flex;
    width: 100%;
    height: ${dimensions.floatingWindowToolbarHeight}px;
    align-items: center;
    justify-content: space-between;
    overflow-x: hidden;

    ::after {
        position: absolute;
        right: ${dimensions.panelPadding}px;
        bottom: 0;
        left: ${dimensions.panelPadding}px;
        height: 1px;
        background: ${colors.panelDivider};
        content: "";
        opacity: 0;
        transition: opacity 0.2s;
    }
`

export const navigationBarDivider = css`
    ::after {
        opacity: 1;
    }
`

export const title = css`
    position: absolute;
    right: 40px;
    left: 40px;
    color: ${colors.panelTitle};
    font-weight: ${fonts.weight.heading};
    pointer-events: none;
    text-align: center;
    transition: 0.25s transform, 0.125s opacity;
`

export const firstTitle = css`
    left: ${dimensions.panelPadding}px;
    text-align: left;
`

export const titleEnterAnimation = css`
    animation: enter 0.2s;
    @keyframes enter {
        0% {
            opacity: 0;
            transform: translateX(100%);
        }
        100% {
            opacity: 1;
            transform: none;
        }
    }
`

export const navigationBarAction = css`
    width: 40px;
    height: 100%;
    color: ${colors.floatingWindowToolbarIcon};

    & > div {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: ${cursors.button};
        opacity: 1;
        transition: opacity 0.2s;
    }
`

export const navigationBarActionHidden = css`
    & > div {
        opacity: 0;
        pointer-events: none;
    }
`
