import assert from "assert"
import { componentLoader } from "@framerjs/framer-runtime"
import { isTree, migrateDocument, getFallbackPageId } from "@framerjs/document-migrations"
import { CanvasTree } from "document/models/CanvasTree/CanvasTree"
import type { CanvasNode, NodeID } from "document/models/CanvasTree"
import { isScopeNode } from "document/models/CanvasTree"
import { CanvasTreeVersion } from "document/models/CanvasTree/CanvasTreeVersion"
import CodeComponentNode, { isCodeComponentNode } from "document/models/CanvasTree/nodes/CodeComponentNode"
import {
    isMaster,
    WithTemplate,
    IsMaster,
    withTemplate,
    isReplica,
    IsReplica,
    isReplicaChild,
    isHiddenMaster,
} from "document/models/CanvasTree/traits/Template"
import { randomID, MaybeNodeID, NullID } from "document/models/CanvasTree/nodes/NodeID"
import { TemplateHelper } from "document/models/CanvasTree/nodes/TemplateHelper"
import { ControlType } from "framer"
import { checkTreeForCycles, DetectedCycles } from "./traits/Children"
import { canvasNodeFromValue } from "./nodes/canvasNodeFromValue"
import type { RootNode } from "./nodes/RootNode"
import { isPageNode, PageNode } from "./nodes/PageNode"
import { isNumber } from "utils/typeChecks"
import { prefixControlProps } from "./traits/utils/codeComponentProps"
import type { ArrayValue } from "./traits/CodeComponent"

type SimpleTree = Map<NodeID, CanvasNode>

export class VersionTooLowError extends Error {
    constructor(actual: number, expected: number) {
        super(`Document version is too low. Expected ${expected}, got ${actual}.`)
    }
}

export class VersionTooHighError extends Error {
    constructor(actual: number, expected: number) {
        super(`Document version is too high. Expected ${expected}, got ${actual}.`)
    }
}

/**
 * Load a document from json resulting in a {@link CanvasTree}, will repair errors and collect them in the #errors array.
 *
 * @param json - object representing a vekter document
 * @param errors - by reference array that will be filled with repaired errors
 * @returns a CanvasTree
 * @throws on errors that cannot be repaired
 */
export function safeLoadDocument(json: unknown, errors: string[]): CanvasTree {
    if (!isTree(json)) throw Error("Invalid document.")

    if (!isNumber(json.version)) {
        throw Error("Unable to read document.version")
    }
    if (!json.root) {
        throw Error("Unable to read document.root")
    }
    if (json.version < CanvasTree.minimumLegacySerializationVersion) {
        throw new VersionTooLowError(json.version, CanvasTree.minimumLegacySerializationVersion)
    }
    if (json.version > CanvasTreeVersion) {
        throw new VersionTooHighError(json.version, CanvasTreeVersion)
    }

    // This throws if the document version is higher than the latest known version.
    const documentJSON = migrateDocument(json)

    const root = canvasNodeFromValue(documentJSON.root, errors) as RootNode | null
    if (!root) {
        throw Error("Unable to create load document")
    }

    repairCanvasPages(root, errors)
    const tree: SimpleTree = new Map<NodeID, CanvasNode>()
    buildSimpleTree(tree, errors, root, NullID)
    repairTree(tree, root, errors)
    let canvasTree = CanvasTree.createByAdoptingRoot(root)
    // we explicitly verify the tree here, because production builds don't verify automatically
    canvasTree.verify()
    canvasTree = TemplateHelper.treeDidLoad(canvasTree, errors).didNonLinearMove(componentLoader)

    // after all replica's have been created, check again for cycles via replica's
    const cycles: DetectedCycles = []
    if (checkTreeForCycles(canvasTree, cycles)) {
        cycles.forEach(cycle => {
            errors.push(`${cycle.id}: code component links itself via ${cycle.stack}`)
            repairCycleInNode(canvasTree, cycle.id, cycle.stack)
        })
        canvasTree = canvasTree.commit()
    }
    return canvasTree
}

function repairCanvasPages(root: RootNode, errors: string[] = []) {
    const children = root.children
    let firstPage = children.find(isPageNode)

    if (firstPage === undefined) {
        errors.push(`${root.id}: Root does not contain a page`)

        firstPage = new PageNode({
            id: getFallbackPageId(root),
        })

        children.push(firstPage)
    }

    for (let index = 0; index < children.length; index++) {
        const node = children[index]

        if (isScopeNode(node)) continue
        if (isHiddenMaster(node)) continue

        errors.push(`${node.id}: Ground node is not on a page`)

        // We need to decrease the index after splicing
        children.splice(index--, 1)
        firstPage.children.push(node)
        node.parentid = firstPage.id
    }
}

function repairCycleInNode(tree: CanvasTree, errorid: NodeID, stack: NodeID[]): void {
    const node = tree.get(stack[stack.length - 1])
    if (!node) return
    if (!isCodeComponentNode(node)) return
    const controlProps = { ...node.getControlProps() }
    for (const key in controlProps) {
        const prop = controlProps[key]
        if (!prop) continue
        const { value } = prop
        if (!Array.isArray(value)) continue
        const newValue = (value as ArrayValue[])
            .map(entry => {
                if (entry.type !== ControlType.ComponentInstance) return entry
                if (entry.value === errorid) return null
                return entry
            })
            .filter(e => !!e)
        controlProps[key] = { type: prop.type, value: newValue }
    }

    const nodeUpdate = prefixControlProps(controlProps)
    node.set(nodeUpdate)
}

// fill the hashmap of the simple tree, at the same time, track and repair duplicate id's
function buildSimpleTree(tree: SimpleTree, errors: string[], node: CanvasNode, parentid: MaybeNodeID) {
    node.parentid = parentid
    while (tree.has(node.id)) {
        errors.push(`${node.id}: duplicate id in document`)
        ;(node as any).id = randomID()
    }
    tree.set(node.id, node)

    const children = node.children
    if (!children) return

    for (let i = 0, il = children.length; i < il; i++) {
        buildSimpleTree(tree, errors, children[i], node.id)
    }
}

function repairTree(tree: SimpleTree, root: CanvasNode, errors: string[]) {
    // code component can not have children that ultimately routes back to itself
    // masters cannot have have their own replica as child
    //
    // To do this deterministically, we walk the tree, and per node, walk any side-links.
    // If side-links ultimately circle back, we break the circle at that last step.

    root.walk(node => {
        assert(node.isMutable)
        if (isCodeComponentNode(node)) {
            repairComponentLinks(tree, node.id, new Set([node.id]), node, errors)
        }
        if (isMaster(node)) {
            repairMasterLinks(tree, node, node, errors)
        }
        if (isReplica(node)) {
            repairReplica(tree, node, errors)
        }
        if (isReplicaChild(node)) {
            repairReplicaChild(node, errors)
        }
    })
}

function repairComponentLinks(
    tree: SimpleTree,
    start: NodeID,
    seen: Set<NodeID>,
    at: CodeComponentNode,
    errors: string[]
) {
    const controlProps = at.getControlProps()
    const controlPropKeys = Object.keys(controlProps)

    for (const key of controlPropKeys) {
        const array = controlProps[key]?.value
        if (!Array.isArray(array)) continue

        const errorIndexes: number[] = []

        for (let i = 0, il = array.length; i < il; i++) {
            const arrayValue = array[i]

            if (arrayValue.type !== ControlType.ComponentInstance) continue

            const slotId = arrayValue.value

            if (seen.has(slotId)) {
                errors.push(`${start}: code component links itself via ${at.id}`)
                errorIndexes.push(i)
                continue
            }

            const link = tree.get(slotId)
            if (!link) {
                errors.push(`${at.id}: code component has bad link at ${slotId}`)
                errorIndexes.push(i)
                continue
            }

            link.walk(node => {
                if (seen.has(node.id)) {
                    errors.push(`${start}: code component links itself via ${at.id} via ${slotId}`)
                    errorIndexes.push(i)
                    return
                }
                if (isCodeComponentNode(node)) {
                    repairComponentLinks(tree, start, new Set([...seen, node.id]), node, errors)
                }
            })
        }

        // remove error indexes from array
        while (errorIndexes.length > 0) {
            array.splice(errorIndexes.pop()!, 1)
        }
    }
}

function detachReplica(node: CanvasNode) {
    node.originalid = null
    ;(node as CanvasNode & WithTemplate).replicaInfo = null
}

function repairMasterLinks(
    tree: SimpleTree,
    start: CanvasNode & IsMaster,
    at: CanvasNode & IsMaster,
    errors: string[]
) {
    at.walk(node => {
        if (node === at) return
        if (!withTemplate(node)) return

        if (isReplica(node)) {
            const master = repairReplica(tree, node, errors)
            if (!master) return

            if (start === master) {
                errors.push(`${start.id}: design component links itself via ${at.id}`)
                detachReplica(node)
                return null
            }

            repairMasterLinks(tree, start, master, errors)
        }
    })
}

function repairReplica(
    tree: SimpleTree,
    node: CanvasNode & IsReplica,
    errors: string[]
): (CanvasNode & IsMaster) | null {
    const masterid = node.replicaInfo.master
    const master = tree.get(masterid)
    if (!master) {
        errors.push(`${node.id}: design component has bad link: ${masterid}`)
        detachReplica(node)
        return null
    }
    if (!isMaster(master)) {
        errors.push(`${node.id}: design component doesn't link to a master: ${masterid}`)
        detachReplica(node)
        return null
    }
    if (node.originalid !== masterid) {
        errors.push(`${node.id}: design component master isn't originalid`)
        node.originalid = masterid
    }
    return master
}

function repairReplicaChild(node: CanvasNode, errors: string[]) {
    if (node.originalid) {
        node.originalid = null
        errors.push(`${node.id}: removing original id from orphan replica child`)
    }
}
