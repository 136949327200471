import { V59, V60 } from "../types"
import { transform } from "../utils/transform"
import { _, exactCheck } from "../utils/exactCheck"
import { withoutProps } from "../utils/withoutProps"

export function migration_59_60(documentJSON: V59.Tree): V60.Tree {
    const migratedRoot = transform(documentJSON.root, node => {
        if (node.__class !== "CodeComponentNode") return exactCheck(node, _ as V60.TreeNode)

        const migratedCodeComponentNode = withoutProps(
            {
                ...node,
                originalid: null,
            },
            "replicaInfo"
        )

        return exactCheck(migratedCodeComponentNode, _ as V60.CodeComponentNode)
    })
    return { version: 60, root: migratedRoot }
}
