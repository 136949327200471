import type { Mutable } from "../utils/Mutable"
import type { ModuleEvent } from "@framerjs/socket"
import { useMemo } from "react"
import { ModulesAPI, ModulesState, ServiceEventEmitter } from "@framerjs/framer-services"
import { produceWithPatches } from "@framerjs/vekter-web-merge/src/lib/immer"

type ReadOnlyModulesStateService = ModulesState.Interface & {
    handleEvents(events: ModuleEvent[]): void
}

/**
 * Creates a simpler version of the ModulesState service that does not perform any write operations
 * or compilation. It can only update its internal state by fetching new data from the server.
 *
 * If no endpoint is provided, the service just doesn't return any data.
 */
export function createReadOnlyModulesStateService(endpointURL?: string): ReadOnlyModulesStateService {
    const emitter = new ServiceEventEmitter<ModulesState.ModulesUpdateEvent>()
    if (!endpointURL) {
        return {
            getModules: () => Promise.resolve({ modules: {}, revision: 1, initialized: true }),
            handleEvents: _ => {},
            moduleUpdatesStream: () => emitter.newStream(),
        }
    }

    let currentModules: Record<string, ModulesState.CompiledModule> = {}
    let currentRevision = 0
    const fetchLatestModules = async () => {
        const fetchRevision = currentRevision
        const nextModulesRaw = await getCompiledModules(endpointURL)
        if (fetchRevision !== currentRevision) {
            // Something else got to update first, ignore this response.
            return [currentModules, currentRevision] as const
        }
        const [nextModules, patches] = produceWithPatches(currentModules, draft => {
            // Remove modules no longer in the new response.
            for (const key of Object.keys(currentModules)) {
                if (!nextModulesRaw[key]) continue
                delete draft[key]
            }
            // Add/update modules that are not the same as before.
            for (const [key, nextModule] of Object.entries(nextModulesRaw)) {
                // Don't generate patches for unchanged modules.
                if (currentModules[key]?.moduleURL === nextModule.moduleURL) return
                draft[key] = nextModule
            }
        })
        if (patches.length === 0) {
            // Nothing changed.
            return [currentModules, currentRevision] as const
        }
        currentModules = nextModules
        currentRevision++
        // Don't emit the patch for the initial change since we expect the requester to fetch it.
        // TODO: We should use services synchronization abilities to not have to do this.
        if (currentRevision > 1) {
            emitter.emit({
                patches: (patches as unknown) as ModulesState.Patch[],
                revision: currentRevision,
                dependentModules: [],
                initialized: true,
            })
        }
        return [currentModules, currentRevision] as const
    }

    return {
        getModules: async () => {
            const [modules, revision] = await fetchLatestModules()
            return { modules, revision, initialized: true }
        },
        handleEvents(events) {
            if (events.length === 0) return
            // Just refetch the whole thing on every event (inefficient, but works for now).
            void fetchLatestModules()
        },
        moduleUpdatesStream: () => emitter.newStream(),
    }
}

export function useReadOnlyModulesStateService(endpointURL?: string): ReadOnlyModulesStateService {
    return useMemo(() => {
        return createReadOnlyModulesStateService(endpointURL)
    }, [endpointURL])
}

async function getCompiledModules(endpointURL: string) {
    const response = await fetch(endpointURL, { credentials: "include" })
    const list: ModulesAPI.ListResponse = await response.json()
    // Construct a ModulesState-compatible map of modules and return it.
    const compiledModules: Record<string, Mutable<ModulesState.CompiledModule>> = {}
    for (const { localId, type, name, baseURL, files } of list.data) {
        compiledModules[`${type}/${name}`] = {
            kind: "unanalyzed",
            localId,
            name,
            type,
            moduleURL: baseURL + files.module,
        }
    }
    return compiledModules
}
