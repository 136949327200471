import type { Rect, Size, LayerProps } from "framer"
import type { CanvasNode } from "document/models/CanvasTree"

export interface WithDOMLayout {
    /**
     * Determines whether this node is using a DOMRect, and caches the result.
     * If a cached value is available already, it will be returned. If you need
     * to check whether a node should be using a DOMRect, it's recommended that
     * you use this method over its non-cached alternative `usesDOMRect`.
     */
    usesDOMRectCached(): boolean

    /**
     * Determines whether this node is using a DOMRect. Since the result of
     * this method isn't cached, it should be used sparingly. When in doubt, use
     * the cached `usesDOMRectCached` method.
     */
    usesDOMRect(): boolean

    /**
     * Resets the cached values for `usesDOMRect` and `domRect`.
     */
    invalidateDOMRect(): void

    /**
     * Force-sets `needsMeasure` for this node, and any containing Stack
     * parents. Used whenever a DOMLayout-enabled node will re-render outside of
     * the regular tree-update -> render cycle, e.g. when a component re-renders
     * due to a font loading, or when placeholder indices update.
     */
    forceNeedsMeasure(): void

    /** Returns the latest DOMRect from the layout cache if one is available */
    getDOMRect(): Rect | null

    /**
     * Overrides CanvasNode's `rect` to return the DOMRect for this node if one
     * is available AND if it should be used for this node. If none of those
     * conditions are true, it will hand off the rect call to its superclass.
     *
     * Note that due to the idiosyncrasies of the browser's paint behavior, DOM
     * rects will never be pixelAligned, and the `pixelAlign` argument will be ignored.
     */
    rect: (parentSize: Size | null, pixelAlign: boolean) => Rect

    /**
     * Adds DOM layout-specific props like _needsMeasure to the result of
     * `getProps`, so they can be used during rendering.
     */
    getProps(_cachePath?: string[]): Partial<LayerProps>
}

const key: keyof WithDOMLayout = "usesDOMRect"

export function withDOMLayout(node: CanvasNode): node is CanvasNode & WithDOMLayout {
    return key in node
}
