import type { CanvasNode } from "../../nodes/CanvasNode"
import type { MotionStyle } from "framer-motion"
import type { CollectTraitOptions } from "."
import { withRadius, radiusDefaults, withRelativeRadius } from "document/models/CanvasTree/traits/Radius"
import {
    withRadiusPerCorner,
    radiusPerCornerDefaults,
    radiusPerCornerKeys,
} from "document/models/CanvasTree/traits/RadiusPerCorner"
import { isUndefined } from "utils/typeChecks"
import { isVariableReference } from "../VariableReference"
import { withShape } from "framer"

/**
 * In a single loop, check if every radius is the default,
 * and that at least one is defined.
 */
function hasRadiusPerCorner(node: CanvasNode): { hasAnyCornerRadius: boolean; hasDefaultCornerRadius: boolean } {
    let hasAnyCornerRadius = false
    let hasDefaultCornerRadius = true

    for (const cornerRadius of radiusPerCornerKeys) {
        if (isUndefined(node[cornerRadius])) {
            hasDefaultCornerRadius = false
            continue
        }

        hasAnyCornerRadius = true

        if (node[cornerRadius] !== radiusPerCornerDefaults[cornerRadius]) {
            hasDefaultCornerRadius = false
        }
    }

    return { hasAnyCornerRadius, hasDefaultCornerRadius }
}

export function collectRadius(
    node: CanvasNode,
    style: MotionStyle,
    { ignoreDefaults = true, withInlineVariables = false }: CollectTraitOptions = {}
): void {
    if (withShape(node)) return

    if (withRadius(node) && isVariableReference(node.radius)) {
        const radiusValue = node.resolveValue("radius", withInlineVariables)
        style.borderTopLeftRadius = style.borderTopRightRadius = style.borderBottomRightRadius = style.borderBottomLeftRadius = radiusValue
        return
    }

    if (withRelativeRadius(node) && node.radiusIsRelative && !isUndefined(node.radius)) {
        if (ignoreDefaults && node.radius === radiusDefaults.radius) return

        style.borderTopLeftRadius = style.borderTopRightRadius = style.borderBottomRightRadius = style.borderBottomLeftRadius = `${node.radius}%`
    } else if (withRadiusPerCorner(node) && node.radiusPerCorner) {
        const { hasAnyCornerRadius, hasDefaultCornerRadius } = hasRadiusPerCorner(node)

        if (!hasAnyCornerRadius || (ignoreDefaults && hasDefaultCornerRadius)) return

        style.borderTopLeftRadius = node.radiusTopLeft
        style.borderTopRightRadius = node.radiusTopRight
        style.borderBottomRightRadius = node.radiusBottomRight
        style.borderBottomLeftRadius = node.radiusBottomLeft
    } else if (withRadius(node) && !isUndefined(node.radius) && !isVariableReference(node.radius)) {
        if (ignoreDefaults && node.radius === radiusDefaults.radius) return
        style.borderTopLeftRadius = style.borderTopRightRadius = style.borderBottomRightRadius = style.borderBottomLeftRadius =
            node.radius
    }
}
