export const defaultFeatures = {
    canCreateCustomCollections: "upsell",
    isAutoRenew: "on",
    canInviteEditors: "on",
    canUsePrivateLink: "upsell",
    canPublishTeamPackages: "upsell",
    canUseFramerX: "upsell",
    canRestrictEmailDomain: "upsell",
    canChangeAutoJoinTeam: "on",
    canUseCustomFonts: "upsell",
    canChangeDefaultProjectAccess: "upsell",
    canChangeDefaultPrototypeAccess: "upsell",
    canRestrictPublicPackages: "upsell",
    canRestrictPrivatePackages: "upsell",
    canChangeProjectAccess: "off",
    canChangePrototypeAccess: "off",
    canInviteAnyDomain: "off",
    canInviteAnyDomainToPrototype: "off",
    canUseTeamPackages: "off",
    canChangeAutoJoinDefaultRole: "on",
}

type Features = typeof defaultFeatures
export type FeatureName = keyof Features
