import * as React from "react"
import { cx } from "linaria"
import { isFiniteNumber } from "./utils/isFiniteNumber"
import { InputWrapper } from "./InputWrapper"
import { NumberInput, NumberInputProps } from "./NumberInput"
import { NumberTicker } from "./NumberTicker"
import * as styles from "./NumberInputWithTicker.styles"
import { frescoSettingsContext } from "./FrescoSettings"

export type NumberInputWithTickerProps = NumberInputProps & {
    label?: string
    labelShort?: string
    wrapperStyle?: React.CSSProperties
}

export const NumberInputWithTicker = React.memo(
    React.forwardRef(function NumberInputWithTicker(
        props: NumberInputWithTickerProps,
        forwardedRef: React.Ref<HTMLInputElement>
    ) {
        const {
            value,
            defaultValue,
            onChange,
            enabled,
            min,
            max,
            label,
            labelShort,
            wrapperStyle,
            className,
            step,
            ...rest
        } = props

        const tickerChangeHandler = React.useCallback(
            (newValue: number) => {
                onChange(Number(newValue.toFixed(4)), undefined, () => {})
            },
            [onChange]
        )

        const [displayValue, setDisplayValue] = React.useState(value)
        const [isDragging, setDragging] = React.useState(false)
        const [isMouseInside, setMouseInside] = React.useState(false)

        React.useEffect(() => {
            setDisplayValue(value)
        }, [value])

        const frescoSettings = React.useContext(frescoSettingsContext)

        const dragStartHandler = React.useCallback(() => {
            setDragging(true)
            if (frescoSettings.beginUndoGroup) frescoSettings.beginUndoGroup()
        }, [frescoSettings])

        const dragEndHandler = React.useCallback(() => {
            setDragging(false)
            if (frescoSettings.endUndoGroup) frescoSettings.endUndoGroup()
        }, [frescoSettings])

        const displayValueChangeHandler = React.useCallback(
            (newDisplayValue: string) => {
                setDisplayValue(newDisplayValue)
            },
            [setDisplayValue]
        )

        const mouseEnterHandler = React.useCallback(() => setMouseInside(true), [])
        const mouseLeaveHandler = React.useCallback(() => setMouseInside(false), [])

        const isTickerVisible = isMouseInside || isDragging
        const labelValue = getLabelValue(displayValue, label, labelShort)

        return (
            <InputWrapper onMouseEnter={mouseEnterHandler} onMouseLeave={mouseLeaveHandler} style={wrapperStyle}>
                <NumberInput
                    ref={forwardedRef}
                    className={cx(styles.input, className)}
                    value={value}
                    onChange={onChange}
                    onDisplayValueChange={displayValueChangeHandler}
                    enabled={enabled}
                    min={min}
                    max={max}
                    step={step}
                    {...rest}
                />
                <NumberTicker
                    value={tickerValue(value, defaultValue)}
                    onChange={tickerChangeHandler}
                    enabled={enabled}
                    visible={isTickerVisible}
                    min={min}
                    max={max}
                    step={step}
                    onDragStart={dragStartHandler}
                    onDragEnd={dragEndHandler}
                    className={styles.numberTicker}
                />
                {labelValue && !isTickerVisible && (
                    <div className={cx(styles.inputLabel, enabled === false && styles.inputLabelDisabled)}>
                        {labelValue}
                    </div>
                )}
            </InputWrapper>
        )
    })
)

function tickerValue(value: number | string | null | undefined, defaultValue: number | undefined): number {
    if (isFiniteNumber(value)) return value
    if (typeof value === "string") {
        const parsedValue = parseFloat(value)
        if (isFiniteNumber(parsedValue)) return parsedValue
    }
    return defaultValue || 0
}

const labelThreshold = 48

function getLabelValue(
    value: string | number | null | undefined,
    label: string | undefined,
    shortLabel: string | undefined
): string | undefined {
    const valueLength = displayLength(value) * 8
    const labelLength = displayLength(label) * 5
    const shortLabelLength = displayLength(shortLabel) * 6

    if (valueLength + labelLength < labelThreshold && label) return label
    if (valueLength + shortLabelLength < labelThreshold && shortLabel) return shortLabel
    return undefined
}

function displayLength(value: number | string | null | undefined): number {
    if (isFiniteNumber(value)) return `${value}`.split(".").join("").length // Character count minus dots
    if (typeof value === "string") return value.split(".").join("").length
    return 0
}
