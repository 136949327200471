/**
 * TypeScript only channel that only forwards messages locally to listeners to the same channel object.
 * It is of not much use than to relpace a current Service implementation with a local JS object
 */

import { ServiceChannel } from "../ServiceChannel"
import { ServiceDebugging } from "../ServiceDebugging"

export class LocalChannel implements ServiceChannel {
    #log?: ServiceDebugging.Logger
    private get log() {
        return (this.#log = this.#log ?? ServiceDebugging.log.extend("LocalChannel"))
    }

    postMessage(message: ServiceChannel.Message): void {
        this.log.trace("↗︎", message)
        this.listeners.forEach(listener => listener(message))
    }
    addMessageListener(callback: (message: ServiceChannel.Message) => void): void {
        this.listeners.add(callback)
    }
    removeMessageListener(callback: (message: ServiceChannel.Message) => void): void {
        this.listeners.delete(callback)
    }
    private readonly listeners: Set<(message: ServiceChannel.Message) => void> = new Set()
}

export const localChannel = new LocalChannel()
