import { List } from "immutable"
import type { SandboxComponentLoaderInterface } from "@framerjs/framer-runtime/sandbox"
import { componentLoader, ComponentLoaderInterface, PackageIdentifier } from "@framerjs/framer-runtime"
import {
    TokenNode,
    TokenId,
    tokenToCSSCustomProperty,
    isTokenCSSVariable,
    stripMetadataFromCSSVariable,
} from "../nodes/TokenNode"
import type { CanvasNode } from "../nodes/CanvasNode"
import { canvasNodeFromValue } from "../nodes/canvasNodeFromValue"
import { withTokenVariables } from "../traits/TokenVariables"
import { isCodeComponentNode } from "../nodes/CodeComponentNode"
import type { CanvasTree, AnyNodeUpdate } from ".."
import { findValueForTokenCSSVariable } from "./findValueForTokenCSSVariable"
import { isObject } from "utils/typeChecks"
import { isCodeComponentProp } from "../traits/utils/codeComponentProps"
import type { ControlProp } from "../traits/CodeComponent"

/**
 * Finds the TokenNode in the CanvasTree and returns it or null if not found.
 */
export function findTokenNode(
    tokenId: TokenId,
    tree: CanvasTree,
    loader: ComponentLoaderInterface | SandboxComponentLoaderInterface = componentLoader
): TokenNode | null {
    const token = tree.root.getToken(tokenId)
    if (token) {
        return token
    }

    const packageIds: PackageIdentifier[] = loader.packageIdentifiers()

    for (const packageId of packageIds) {
        const tokens = loader.tokensForPackage(packageId)
        if (!tokens) continue

        const tokenJSON = tokens[tokenId]
        const errors: string[] = []
        const node = canvasNodeFromValue(tokenJSON, errors) as TokenNode | null

        if (errors.length) {
            throw new Error(`Unable to parse TokenNode: \n${errors.join("\n")}`)
        }
        if (node) return node
    }

    return null
}

/**
 * Returns an object map of CSS custom properties to value for all tokens both local and imported.
 * Useful for generating a stylesheet or passing into a style prop on a React component.
 */
export function getTokenCSSProperties(
    tree: CanvasTree,
    loader: ComponentLoaderInterface | SandboxComponentLoaderInterface = componentLoader
): { [prop: string]: string } {
    const result: { [id: string]: string } = {}

    // Collect local tokens.
    tree.root.getAllTokens().forEach(t => {
        if (t.value) result[tokenToCSSCustomProperty(t)] = t.value
    })

    // Collect dependant tokens.
    const packageIds: PackageIdentifier[] = loader.packageIdentifiers()
    for (const packageId of packageIds) {
        const tokens = loader.tokensForPackage(packageId)
        if (!tokens) continue
        for (const tokenJSON of Object.values(tokens)) {
            result[tokenToCSSCustomProperty(tokenJSON)] = tokenJSON.value
        }
    }

    return result
}

/** Returns a map of TokenId to TokenNode, optionally filtering by TokenNode subclass. */
export function getTokenMap<T extends TokenNode>(
    tree: CanvasTree,
    filterBy?: { new (...args: unknown[]): T },
    loader: ComponentLoaderInterface | SandboxComponentLoaderInterface = componentLoader
): { [id: string]: T } {
    const result: { [id: string]: T } = {}
    const packages = getTokenByPackageIdMap(tree, filterBy, loader)
    for (const key in packages) {
        Object.assign(result, packages[key])
    }
    return result
}

/**
 * Returns a nested map of TokenNodes indexed by TokenId. Then grouped by
 * PackageId. Can optionally be filtered by TokenNode type.
 */
export function getTokenByPackageIdMap<T extends TokenNode>(
    tree: CanvasTree,
    filterBy?: { new (...args: unknown[]): T },
    loader: ComponentLoaderInterface | SandboxComponentLoaderInterface = componentLoader
): { [pkgId: string]: { [tokenId: string]: T } } {
    const result: { [pkgId: string]: { [tokenId: string]: T } } = {}
    const filterByType = filterBy ? (t: T) => t instanceof filterBy : () => true

    // Collect local tokens.
    tree.root
        .getAllTokens()
        .filter(filterByType)
        .forEach((t: T) => {
            if (!result[componentLoader.localPackageIdentifier()]) {
                result[componentLoader.localPackageIdentifier()] = {}
            }
            result[componentLoader.localPackageIdentifier()][t.id] = t
        })

    // Collect dependant tokens.
    const packageIds: PackageIdentifier[] = loader.packageIdentifiers()
    for (const packageId of packageIds) {
        const tokens = loader.tokensForPackage(packageId)
        if (!tokens) continue

        const tokenNodes = Object.values(tokens).map(t => canvasNodeFromValue(t)) as (T | null)[]
        for (const token of tokenNodes) {
            if (!token) continue
            if (!result[packageId]) {
                result[packageId] = {}
            }
            result[packageId][token.id] = token
        }
    }

    return result
}

export function removeTokenFromNode(node: CanvasNode, token: TokenNode): AnyNodeUpdate | undefined {
    return removeTokensFromNode(node, [token])
}

/**
 * Creates a clone of the node provided and walks the children replacing any
 * variables within the nodes with values. Then returns the cloned node.
 */
export function removeAllTokensFromNodeTree<T extends CanvasNode>(node: T, tree: CanvasTree): T {
    const clone = node.cloneWithIds()

    // Replace all token references with the value
    const tokens = Object.values(getTokenMap(tree))
    clone.walk(n => Object.assign(n, removeTokensFromNode(n, tokens)))

    return clone
}

/**
 * Returns an object of updates that can be performed on the node provided
 * to restore any properties using the tokens provided to their original
 * value. Will return `undefined` if no changes were made.
 */
export function removeTokensFromNode(node: CanvasNode, tokens: TokenNode[]): AnyNodeUpdate | undefined {
    let updated: AnyNodeUpdate | undefined
    const map: { [tokenId: string]: string } = {}
    tokens.forEach(t => (map[t.id] = t.value))

    Object.entries(node).forEach(([key, value]) => {
        if (key === "children") return
        if (key === "id") return
        if (key === "cache") return

        let result: typeof value | undefined

        // Treat lists as an Array and convert back later. It's easier to read.
        let wasList = false
        if (List.isList(value)) {
            value = Array.from(value)
            wasList = true
        }

        if (Array.isArray(value)) {
            const mapped: unknown[] = []
            let didReplaceToken = false
            value.forEach((val, idx) => {
                const replaced = replacePropertyToken(String(idx), val, map)
                if (replaced) didReplaceToken = true
                mapped.push(replaced ? replaced : val)
            })
            if (didReplaceToken) result = mapped
        } else {
            result = replacePropertyToken(key, value, map)
        }

        if (typeof result !== "undefined") {
            if (!updated) updated = {}
            if (wasList) result = List(result)
            updated[key] = result
        }
    })

    return updated
}

function replacePropertyToken(key: string, value: unknown, map: { [tokenId: string]: string }): unknown | undefined {
    if (isCodeComponentProp(key)) {
        return removeTokenFromCodeComponentProp(value, map)
    }

    if (withTokenVariables(value)) {
        const newValue = value.removeTokenVariables(map)
        if (newValue) {
            return newValue
        }
    }

    const replacement = findValueForTokenCSSVariable(value, map)
    if (replacement) return replacement

    // If we have a token variable, but couldn't find replacement (e.g. the color package no longer exist in the project),
    // at least strip away the metadata
    if (isTokenCSSVariable(value)) {
        return stripMetadataFromCSSVariable(value)
    }
}

function removeTokenFromCodeComponentProp(prop: unknown, map: { [tokenId: string]: string }): unknown | undefined {
    if (!prop || typeof prop !== "object" || prop === null || prop["value"] === undefined) return undefined
    const { value } = prop as ControlProp
    const replacement = removeTokenFromValue(value, map)
    return replacement === undefined ? undefined : { ...prop, value: replacement }
}

function removeTokenFromValue(value: unknown, map: { [tokenId: string]: string }): unknown | undefined {
    if (Array.isArray(value)) {
        let hasChanged = false
        const clonedArray = value.map(entry => {
            const replacement = removeTokenFromValue(entry, map)
            if (replacement) {
                hasChanged = true
                return replacement
            } else {
                return entry
            }
        })

        return hasChanged ? clonedArray : undefined
    }

    if (typeof value === "object" && value !== null) {
        const clonedObject = {}
        let hasChanged = false
        for (const key in value) {
            const replacement = removeTokenFromValue(value[key], map)
            if (replacement) {
                hasChanged = true
                clonedObject[key] = replacement
            } else {
                clonedObject[key] = value[key]
            }
        }
        return hasChanged ? clonedObject : undefined
    }

    if (isTokenCSSVariable(value)) {
        return findValueForTokenCSSVariable(value, map)
    }

    return undefined
}

export function nodeContainsToken(node: CanvasNode): boolean {
    if (isCodeComponentNode(node) && objectContainsToken(node.getControlProps())) {
        return true
    }

    return Object.keys(node).some(key => {
        if (key === "children") return false
        const value = node[key]
        if (isList(value)) {
            const listContainsToken = value.some(
                item => isTokenCSSVariable(value) || (withTokenVariables(item) && item.tokenVariables().length > 0)
            )
            if (listContainsToken) return true
        }
        if (withTokenVariables(value) && value.tokenVariables().length > 0) {
            return true
        }
        return isTokenCSSVariable(value)
    })
}

function objectContainsToken(object: unknown): boolean {
    if (!isObject(object)) return false
    const keys = Object.keys(object)
    for (const key of keys) {
        const value = object[key]
        if (isTokenCSSVariable(value)) return true
        if (objectContainsToken(value)) return true
    }
    return false
}

function isList(value: any): value is List<any> {
    return List.isList(value)
}
