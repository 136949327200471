import { assert } from "@framerjs/shared"
import {
    CanvasTree,
    CanvasNode,
    MaybeNodeID,
    isFrameNode,
    isDrawableNode,
    NodeID,
    isScopeNode,
    ScopeNode,
} from "./CanvasTree"
import { Rect } from "framer"
import { isHiddenMaster, isMaster } from "./CanvasTree/traits/Template"
import Matrix from "./Matrix"
import { withShape } from "./CanvasTree/traits/Shape"
import { withStroke } from "./CanvasTree/traits/Stroke"
import { childrenRectsById } from "./CanvasTree/traits/utils/getChildrenRects"
import type { BoundingBox } from "./CanvasTree/nodes/CanvasNodeCache"
import type { VariableValueMap } from "./CanvasTree/traits/Variables"
import { withVariables } from "./CanvasTree/traits/Variables"
import { withDOMLayout } from "./CanvasTree/traits/DOMLayout"
import type { LayoutCache } from "document/LayoutCache"

export function updateTreeCacheForVekter(tree: CanvasTree, layoutCache: LayoutCache): CanvasTree {
    const root = tree.root
    const cache = root.cache
    cache.visible = true
    cache.resetBoundingBox()
    const children = root.children
    for (let i = 0, il = children.length; i < il; i++) {
        const child = children[i]

        if (isScopeNode(child)) {
            updateScopeNodeCache(tree, child, layoutCache)
        } else {
            updateVekterNodeCache(tree, child as any, true, null, new DOMMatrix(), false, null, undefined, layoutCache)
        }

        cache.extendBoundingBox(child.cache)
    }
    return tree
}

function updateScopeNodeCache(tree: CanvasTree, node: ScopeNode, layoutCache: LayoutCache) {
    const cache = node.cache
    cache.visible = true
    cache.resetBoundingBox()
    const children = node.children
    for (let i = 0, il = children.length; i < il; i++) {
        const child = children[i]
        const variableValueMap = withVariables(node) ? node.getVariableValueMap() : undefined
        updateVekterNodeCache(
            tree,
            child as any,
            true,
            null,
            new DOMMatrix(),
            false,
            null,
            variableValueMap,
            layoutCache
        )
        cache.extendBoundingBox(child.cache)
    }
}

function updateVekterNodeCache(
    tree: CanvasTree,
    node: CanvasNode,
    parentVisible: boolean,
    parentRect: Rect | null,
    parentMatrix: DOMMatrixReadOnly,
    parentUsesDOMRect: boolean,
    masterAncestorId: MaybeNodeID,
    variableValueMap: VariableValueMap | undefined,
    layoutCache: LayoutCache,
    childRects?: { [key: string]: Rect }
): void {
    const cache = node.cache
    const equalVariableValueMap = cache.isEqualVariableValueMap(variableValueMap)
    // The variable value map needs to be set first because variables are already resolved below
    cache.setVariableValueMap(variableValueMap)

    cache.masterAncestorId = masterAncestorId
    const visible = parentVisible && node.resolveValue("visible") !== false && !isHiddenMaster(node)
    const parentMatrixSame = Matrix.equals(cache.parentMatrix, parentMatrix)
    const stackEnabledSame = !isFrameNode(node) || cache.stackEnabled === node.stackEnabled
    const propertiesSame =
        stackEnabledSame &&
        cache.visible === visible &&
        cache.lastUpdate === node.update &&
        Rect.equals(cache.parentRect, parentRect) &&
        !childRects &&
        equalVariableValueMap
    const same = propertiesSame && parentMatrixSame

    if (same) return

    if (process.env.NODE_ENV !== "production" && window["perf"]) window["perf"].cacheUpdated()

    if (!propertiesSame) {
        if (process.env.NODE_ENV !== "production" && window["perf"]) window["perf"].cacheReset()
        cache.reset()
        cache.masterAncestorId = masterAncestorId
        cache.visible = visible
        cache.lastUpdate = node.update
        cache.parentDirected = childRects !== undefined
        cache.parentRect = parentRect
        cache.stackEnabled = isFrameNode(node) && node.stackEnabled
    } else {
        cache.canvasRect = null
    }

    const domRect = layoutCache.getRect(node.id)
    if (domRect) {
        cache.domRect = { ...domRect }
    }

    const parentDirectedRect = childRects && childRects[node.id]
    cache.parentDirectedRect = parentDirectedRect ? parentDirectedRect : null

    // Nodes contained in DOMRect-using nodes should not use pixelAligned rects
    // when constructing their matrix. This is because rect()'s pixel-aligning
    // works on the top/left coordinate, and the actual HTML element might be
    // positioned using a top/right absolute position, depending on pinning, so
    // its resolved top/left coordinate might be on a subpixel. When this
    // happens, pixel-aligning will lead to outlines falling out of sync.
    // For an example, see: https://github.com/framer/company/issues/20267
    const usesDOMRect = withDOMLayout(node) && node.usesDOMRectCached()
    const pixelAlign = !(usesDOMRect || parentUsesDOMRect)
    const frame = parentDirectedRect || node.rect(parentRect, pixelAlign)

    const transformMatrix = node.transformMatrix(parentRect, frame)
    let matrix: DOMMatrix

    try {
        matrix = parentMatrix.multiply(transformMatrix)
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Trap for #20713", node.getProps())
        // eslint-disable-next-line no-console
        console.error("parentRect", parentRect)
        // eslint-disable-next-line no-console
        console.error("frame", frame)
        throw error
    }

    cache.matrix = matrix
    cache.parentMatrix = parentMatrix

    let rect = tree.getCanvasRectCached(node)
    if (withShape(node) && withStroke(node) && node.strokeEnabled && !!node.strokeWidth) {
        rect = Rect.inflate(rect, node.strokeWidth / 2)
    }
    cache.setBoundingBox(rect)

    const children = node.children
    if (children) {
        const childRectsById = childrenRectsById(node, layoutCache, false)

        let masterId = masterAncestorId
        if (!masterId && isMaster(node)) masterId = node.id

        for (let i = 0, il = children.length; i < il; i++) {
            const child = children[i]
            if (!stackEnabledSame) {
                child.cache.parentRect = null
            }
            updateVekterNodeCache(
                tree,
                child,
                visible,
                frame,
                matrix,
                usesDOMRect,
                masterId,
                variableValueMap,
                layoutCache,
                childRectsById
            )
            cache.extendBoundingBox(child.cache)
        }
    }
}

/**
 * This is the same as the updateTreeCacheForVekter function, except it only
 * changes layout-related cached properties, and doesn't reset the cache.
 */
export function updateTreeCacheWithLatestDOMLayout(
    tree: CanvasTree,
    layoutCache: LayoutCache,
    changedNodeIds: Set<NodeID>,
    /**
     * When true, the cache will be updated with props that are used by the
     * editor and its tools, but are not required for render: matrix,
     * parentMatrix, bounding box, canvasRect.
     */
    updateCachePropsForEditing: boolean
) {
    const affectedNodeIds = buildAffectedNodesAndAncestorsSet(tree, changedNodeIds)

    const root = tree.root
    const cache = root.cache
    cache.visible = true
    cache.resetBoundingBox()
    const children = root.children
    for (let i = 0, il = children.length; i < il; i++) {
        const child = children[i]

        if (isScopeNode(child)) {
            updateCanvasScopeCacheWithLatestDOMLayout(
                tree,
                child,
                layoutCache,
                affectedNodeIds,
                updateCachePropsForEditing
            )
        } else {
            updateNodeCacheWithLatestDOMLayout(
                tree,
                child as any,
                null,
                new DOMMatrix(),
                false,
                layoutCache,
                affectedNodeIds,
                updateCachePropsForEditing
            )
        }
        cache.extendBoundingBox(child.cache)
    }
    return affectedNodeIds
}

/**
 * Expands a set of node IDs to include all nodes on the path to those nodes, as
 * well as all nodes that are descendants of the affected nodes. Useful when we
 * need to update the tree cache with new layout measurements, which should also
 * update parentRects, matrices and other related values for all descendants.
 *
 * @param tree the tree
 * @param affectedNodeIds a set IDs of the affected nodes
 */
export function buildAffectedNodesAndAncestorsSet(tree: CanvasTree, affectedNodeIds: Set<NodeID>) {
    const nodes = new Set<NodeID>()
    const nodesWithExhaustedDescendants = new Set<NodeID>()

    function addDescendants(node: CanvasNode) {
        // we don't need to go down branches we've seen before
        if (nodesWithExhaustedDescendants.has(node.id)) return
        nodes.add(node.id)
        nodesWithExhaustedDescendants.add(node.id)

        if (!node.children) return
        node.children.forEach(addDescendants)
    }

    function addAncestors(node: CanvasNode) {
        let id: string | null | undefined = node.id

        while (id && !nodes.has(id)) {
            nodes.add(id)
            const current: CanvasNode | null = tree.getNode(id)
            id = current?.parentid
        }
    }

    for (const id of affectedNodeIds) {
        const affectedNode = tree.getNode(id)

        if (affectedNode) {
            addAncestors(affectedNode)

            // if the layout of this node changes, all of its children will also need to have
            // their matrices recalculated since they depend on the parent matrix
            if (affectedNode.children) {
                affectedNode.children.forEach(addDescendants)
            }
        }
    }

    return nodes
}

function updateCanvasScopeCacheWithLatestDOMLayout(
    tree: CanvasTree,
    node: ScopeNode,
    layoutCache: LayoutCache,
    affectedNodeIds: Set<NodeID>,
    updateCachePropsForEditing: boolean
) {
    const cache = node.cache
    cache.visible = true
    cache.resetBoundingBox()
    const children = node.children
    for (let i = 0, il = children.length; i < il; i++) {
        const child = children[i]
        updateNodeCacheWithLatestDOMLayout(
            tree,
            child,
            null,
            updateCachePropsForEditing ? new DOMMatrix() : null,
            false,
            layoutCache,
            affectedNodeIds,
            updateCachePropsForEditing
        )
        cache.extendBoundingBox(child.cache)
    }
}

function updateNodeCacheWithLatestDOMLayout(
    tree: CanvasTree,
    node: CanvasNode,
    parentRect: Rect | null,
    parentMatrix: DOMMatrixReadOnly | null,
    parentUsesDOMRect: boolean,
    layoutCache: LayoutCache,
    affectedNodeIds: Set<NodeID>,
    /**
     * When true, the cache will be updated with props that are used by the
     * editor and its tools, but are not required for render: matrix,
     * parentMatrix, bounding box, canvasRect.
     */
    updateCachePropsForEditing: boolean,
    childRects?: { [key: string]: Rect }
): void {
    if (!affectedNodeIds.has(node.id)) {
        return
    }

    const cache = node.cache

    const domRect = layoutCache.getRect(node.id)
    if (domRect) {
        cache.domRect = { ...domRect }
    }

    cache.parentRect = parentRect
    // Sandbox-side uses just the size from parentRect, while editor side uses
    // the whole rect
    cache.parentSize = parentRect
    cache.parentDirected = childRects !== undefined
    const parentDirectedRect = childRects && childRects[node.id]
    cache.parentDirectedRect = parentDirectedRect ? parentDirectedRect : null

    // cache.visible is already set by the pre-render cache update
    if (!cache.visible) return

    // See comment about pixelAlign in updateVekterNodeCache() above
    const usesDOMRect = withDOMLayout(node) && node.usesDOMRectCached()
    const pixelAlign = !(usesDOMRect || parentUsesDOMRect)
    const frame = parentDirectedRect || node.rect(parentRect, pixelAlign)

    if (updateCachePropsForEditing) {
        assert(parentMatrix, "A valid parentMatrix must be passed in when updating cache props for editing")

        cache.canvasRect = null
        const matrix = parentMatrix.multiply(node.transformMatrix(parentRect, frame))
        cache.matrix = matrix
        cache.parentMatrix = parentMatrix

        // The next line also sets the cache.canvasRect that's null-ed out above
        let rect = tree.getCanvasRectCached(node)
        if (withShape(node) && withStroke(node) && node.strokeEnabled && !!node.strokeWidth) {
            rect = Rect.inflate(rect, node.strokeWidth / 2)
        }
        cache.setBoundingBox(rect)
    }

    const children = node.children
    if (!children) return

    const childRectsById = childrenRectsById(node, layoutCache, false)

    for (let i = 0, il = children.length; i < il; i++) {
        const child = children[i]
        updateNodeCacheWithLatestDOMLayout(
            tree,
            child,
            frame,
            updateCachePropsForEditing ? cache.matrix : null,
            usesDOMRect,
            layoutCache,
            affectedNodeIds,
            updateCachePropsForEditing,
            childRectsById
        )
        if (updateCachePropsForEditing) cache.extendBoundingBox(child.cache)
    }
}

function intersects(a: BoundingBox, b: BoundingBox): boolean {
    return b.minX <= a.maxX && b.minY <= a.maxY && b.maxX >= a.minX && b.maxY >= a.minY
}

/** Collect all the nodes whose bounding box intersects with the given box.
 * Notice a node's bounding box fits the node and all its children. */
export function collectNodesWithIntersectingBoundingBox(node: CanvasNode, box: BoundingBox, results: CanvasNode[]) {
    const cache = node.cache
    if (!cache.visible) return
    if (!intersects(cache, box)) return

    node = cache.future ? cache.future : node

    if (isDrawableNode(node)) {
        results.push(node)
    }

    const children = node.children
    if (!children) return
    for (let i = 0, il = children.length; i < il; i++) {
        collectNodesWithIntersectingBoundingBox(children[i], box, results)
    }
}
