import * as React from "react"

interface FileDropOptions {
    onDragEnter?: (event: DragEvent) => void
    onDragLeave?: (event: DragEvent) => void
    onDrop: (event: DragEvent) => void
}

type HTMLDivSelectedAttributes = Pick<React.HTMLAttributes<HTMLDivElement>, "style" | "className">
type HTMLDivRef = React.Ref<HTMLDivElement | null>

interface Props extends FileDropOptions, HTMLDivSelectedAttributes {
    children?: React.ReactNode
}

export const FileDropZone = React.forwardRef<HTMLDivElement, Props>(
    ({ children, onDragEnter, onDragLeave, onDrop, ...htmlAttributes }: Props, forwardedRef: HTMLDivRef | null) => {
        const defaultRef = React.useRef<HTMLDivElement>(null)
        const ref = (forwardedRef || defaultRef) as React.RefObject<HTMLDivElement>

        useFileDrop({ onDragEnter, onDragLeave, onDrop }, ref)
        return (
            <div ref={ref} {...htmlAttributes}>
                {children}
            </div>
        )
    }
)

function useFileDrop(events: FileDropOptions, ref: React.RefObject<HTMLDivElement>) {
    const eventsRef = React.useRef(events)
    eventsRef.current = events

    // We need to count the times drag enter and drag leave are called because
    // the same event is also fired for all descendants that are hovered.
    const dragOverCounter = React.useRef(0)
    React.useEffect(() => {
        const element = ref.current
        if (!element) return

        function onDragOver(event: DragEvent) {
            if (!event.dataTransfer || !containsNativeFiles(event)) return
            event.preventDefault()
            event.stopPropagation()
            event.dataTransfer.dropEffect = "copy"
        }

        function onDragEnter(event: DragEvent) {
            if (!event.dataTransfer || !containsNativeFiles(event)) return
            dragOverCounter.current += 1
            event.preventDefault()
            event.stopPropagation()
            event.dataTransfer.dropEffect = "copy"
            if (eventsRef.current.onDragEnter) {
                eventsRef.current.onDragEnter(event)
            }
        }

        function onDragLeave(event: DragEvent) {
            dragOverCounter.current = Math.max(0, dragOverCounter.current - 1)
            if (dragOverCounter.current !== 0) return
            if (eventsRef.current.onDragLeave) {
                eventsRef.current.onDragLeave(event)
            }
        }

        function onDrop(event: DragEvent) {
            if (!event.dataTransfer || !containsNativeFiles(event)) return
            event.preventDefault()
            event.stopPropagation()
            if (eventsRef.current.onDragLeave) eventsRef.current.onDragLeave(event)
            eventsRef.current.onDrop(event)
        }

        element.addEventListener("dragover", onDragOver)
        element.addEventListener("dragenter", onDragEnter)
        element.addEventListener("dragleave", onDragLeave)
        element.addEventListener("drop", onDrop)

        return () => {
            element.removeEventListener("dragover", onDragOver)
            element.removeEventListener("dragenter", onDragEnter)
            element.removeEventListener("dragleave", onDragLeave)
            element.removeEventListener("drop", onDrop)
        }
    }, [ref])
}

const nativeFileType = "Files"

function containsNativeFiles(event: DragEvent) {
    return !!event.dataTransfer && event.dataTransfer.types.includes(nativeFileType)
}
