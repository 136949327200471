import { webAssetResolver, desktopAssetResolver, AssetResolver, AssetMap } from "@framerjs/assets"
import { getLogger, getServiceMap } from "@framerjs/shared"

const log = getLogger("initializeAssetResolver")

let _assetResolver: AssetResolver | null | undefined = undefined

// FIXME: This is used by unsafeUpdateDesktopAssetResolver, so if/when we get
// rid of that, we should get rid of this too.
export function unsafeSetAssetResolver(assetResolver: AssetResolver | null) {
    _assetResolver = assetResolver
}

export function initializeAssetResolver(
    assetMap: AssetMap | undefined,
    imageBaseURL: string | undefined,
    type: "web" | "studio"
): AssetResolver | null {
    if (_assetResolver) {
        throw new Error("initializeAssetResolver() should only be called once")
    }
    log.debug("initializeAssetResolver", { type, imageBaseURL, assetMap })
    if (type === "web" && assetMap) {
        _assetResolver = webAssetResolver(getServiceMap().userContent, assetMap)
    } else if (type === "studio" && imageBaseURL !== undefined) {
        _assetResolver = desktopAssetResolver(imageBaseURL)
    } else {
        _assetResolver = null
        log.warn(
            "Unable to initialize assetResolver of type ",
            type,
            " with assetMap ",
            assetMap,
            "imageBaseURL",
            imageBaseURL
        )
    }
    return _assetResolver
}

export function getAssetResolver(): AssetResolver | null {
    if (_assetResolver === undefined) {
        throw new Error("getAssetResolver() should not be called before initializeAssetResolver()")
    }
    return _assetResolver
}
