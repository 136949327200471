import { css } from "linaria"
import { dimensions } from "../tokens"

export const bar = css`
    --bar-item-spacing: 10px;
    position: relative;
    display: flex;
    height: ${dimensions.webBarHeight}px;
    box-sizing: border-box;
    flex-shrink: 0;
    align-items: center;
    background-color: var(--background);
    font-size: 14px;
    user-select: none;

    & > .center {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        pointer-events: none;

        & > * {
            pointer-events: all;
        }
    }

    & > :not(.center):not(.spanner) + :not(.center):not(.spanner) {
        margin-left: var(--bar-item-spacing);
    }

    & > .spanner {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;
    }
`
