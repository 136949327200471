import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springBouncy } from "./utils"
import { colors } from "../tokens"

const transition = { delay: 0.3, ...springBouncy }

export const AnimationComments = React.memo(function AnimationComments() {
    const bubble1 = useAnimation()
    const bubble2 = useAnimation()

    async function animation() {
        await Promise.all([
            bubble1.start({
                scale: 0.5,
                opacity: 0.5,
                transition: transition,
            }),
            bubble2.start({
                scale: 0.9,
                opacity: 1,
                transition: transition,
            }),
        ])
        return await Promise.all([
            bubble1.start({
                scale: 0.9,
                opacity: 1,
                transition: transition,
            }),
            bubble2.start({
                scale: 0.5,
                opacity: 0.5,
                transition: transition,
            }),
        ])
    }

    useLoop(animation)

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                position: "relative",
                width: 50,
                height: 50,
                borderRadius: 8,
                paddingBottom: 3,
                background: colors.animationBackgroundLight,
            }}
        >
            <motion.div
                animate={bubble1}
                style={{
                    left: 10,
                    position: "absolute",
                    transformOrigin: "bottom left",
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                    <path
                        d="M 12 0 C 18.627 0 24 5.373 24 12 C 24 18.627 18.627 24 12 24 L 2.571 24 C 1.151 24 0 22.849 0 21.429 L 0 12 C 0 5.373 5.373 0 12 0 Z"
                        fill={colors.animationForground}
                    ></path>
                </svg>
            </motion.div>
            <motion.div
                initial={{ scale: 0.6, opacity: 0.5 }}
                animate={bubble2}
                style={{
                    left: 15,
                    position: "absolute",
                    transformOrigin: "bottom right",
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                    <path
                        d="M 0 12 C 0 5.373 5.373 0 12 0 C 18.627 0 24 5.373 24 12 L 24 21.429 C 24 22.849 22.849 24 21.429 24 L 12 24 C 5.373 24 0 18.627 0 12 Z"
                        fill={colors.animationForground}
                    ></path>
                </svg>
            </motion.div>
        </div>
    )
})
