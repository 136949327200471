import { basicChildrenDefaults } from "document/models/CanvasTree/traits/Children"
import { nameDefaults } from "../traits/Name"
import { guidesDefaults } from "../traits/Guides"
import { memoRecord } from "./memoRecord"

export const getScopeNodeRecord = memoRecord(() => {
    return {
        ...nameDefaults,
        ...basicChildrenDefaults,
        ...guidesDefaults,
    }
})
