import type { CanvasNode } from "document/models/CanvasTree"
import type { StrokeAlignment, LineJoin, LineCap } from "framer"

export interface StrokeWidth {
    strokeWidth: number | undefined
}

export interface StrokeDash {
    strokeDashArray: string | undefined
    strokeDashOffset: number | undefined
}

export interface WithStroke extends StrokeWidth, StrokeDash {
    strokeEnabled: boolean | undefined
    strokeAlignment: StrokeAlignment | undefined
    strokeColor: string | undefined
    lineJoin: LineJoin | undefined
    lineCap: LineCap | undefined
    strokeMiterLimit: number | undefined
}

export const strokeDefaults: WithStroke = {
    strokeEnabled: true,
    strokeAlignment: "center",
    strokeWidth: 1,
    strokeColor: "#AAA",
    lineJoin: "miter",
    lineCap: "butt",
    strokeMiterLimit: 4,
    strokeDashArray: "0",
    strokeDashOffset: 0,
}

export const shapeStrokeDefaults: WithStroke = {
    strokeEnabled: undefined,
    strokeAlignment: undefined,
    strokeWidth: undefined,
    strokeColor: undefined,
    lineJoin: undefined,
    lineCap: undefined,
    strokeMiterLimit: undefined,
    strokeDashArray: undefined,
    strokeDashOffset: undefined,
}

const key: keyof WithStroke = "strokeEnabled"

export const withStroke = (node: CanvasNode): node is CanvasNode & WithStroke => {
    return key in node
}
