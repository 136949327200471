import type { CanvasNode } from "document/models/CanvasTree"

export interface WithSize {
    width: number
    height: number
}

export const sizeDefaults: WithSize = {
    width: 100,
    height: 100,
}

export const pathSizeDefaults: WithSize = {
    width: 1,
    height: 1,
}

const key: keyof WithSize = "width"

export function withSize(node: CanvasNode): node is CanvasNode & WithSize {
    return key in node
}
