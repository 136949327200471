import * as React from "react"
import "../components/Theme.styles"
import type { OverrideType } from "./types"

export interface FrescoSettingsContext {
    smallNudgeIncrement: number
    largeNudgeIncrement: number
    beginUndoGroup?: () => void
    endUndoGroup?: () => void
    mouseTrackerWillStart?: () => void
    mouseTrackerDidEnd?: () => void
}

export const frescoSettingsContext = React.createContext<FrescoSettingsContext>({
    smallNudgeIncrement: 1,
    largeNudgeIncrement: 10,
})

export type FrescoSettingsProps = OverrideType<
    FrescoSettingsContext,
    {
        children?: React.ReactNode
        smallNudgeIncrement?: number
        largeNudgeIncrement?: number
    }
>

export const FrescoSettings = React.memo(function FrescoSettings({
    children,
    smallNudgeIncrement = 1,
    largeNudgeIncrement = 10,
    beginUndoGroup,
    endUndoGroup,
    mouseTrackerWillStart,
    mouseTrackerDidEnd,
}: FrescoSettingsProps) {
    const [contextState, setContextState] = React.useState<FrescoSettingsContext>({
        smallNudgeIncrement,
        largeNudgeIncrement,
        beginUndoGroup,
        endUndoGroup,
        mouseTrackerWillStart,
        mouseTrackerDidEnd,
    })

    React.useEffect(() => {
        setContextState({
            smallNudgeIncrement,
            largeNudgeIncrement,
            beginUndoGroup,
            endUndoGroup,
            mouseTrackerWillStart,
            mouseTrackerDidEnd,
        })
    }, [
        beginUndoGroup,
        endUndoGroup,
        mouseTrackerWillStart,
        mouseTrackerDidEnd,
        smallNudgeIncrement,
        largeNudgeIncrement,
    ])

    return <frescoSettingsContext.Provider value={contextState}>{children}</frescoSettingsContext.Provider>
})
