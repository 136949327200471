import type { List } from "immutable"
import type { DeprecatedVisualProperties, Rect, Size } from "framer"
import {
    ConstraintValues,
    LayerProps,
    ImageFit,
    ConstraintMask,
    DimensionType,
    WithFractionOfFreeSpace,
    ConstraintProperties,
    FrameProps,
    NewConstraintProperties,
} from "framer"
import { CanvasNode } from "./CanvasNode"
import type { CanvasTree, DrawableNode, VectorNode, NodeID, NodeUpdate } from ".."
import type { WithRect } from "../traits/Frame"
import { getShapeContainerRecord } from "../records/ShapeContainerRecord"
import { updateConstrainedFrame, updateConstrainedSize } from "document/models/ConstraintSolver"
import type { WithName } from "document/models/CanvasTree/traits/Name"
import type { WithChildren } from "document/models/CanvasTree/traits/Children"
import type { WithVisibility } from "document/models/CanvasTree/traits/Visibility"
import type { WithLock } from "document/models/CanvasTree/traits/Lock"
import type { WithExport, WithExportIncludesBackground } from "document/models/CanvasTree/traits/Export"
import { setDefaults } from "./MutableNode"
import type { ExportOptions } from "./ExportOptions"
import type { WithFill, WithOptionalFill, FillType } from "document/models/CanvasTree/traits/Fill"
import type { RadialGradient, LinearGradient } from "document/models/Gradient"
import { collectBackgroundPropsForNode } from "document/models/CanvasTree/traits/utils/collectBackgroundForNode"
import { collectNameForNode } from "document/models/CanvasTree/traits/utils/collectNameForNode"
import type { WithAutoSize } from "document/models/CanvasTree/traits/AutoSize"
import type { WithOpacity } from "document/models/CanvasTree/traits/Opacity"
import type { WithPins } from "document/models/CanvasTree/traits/Pins"
import type { WithRadius } from "document/models/CanvasTree/traits/Radius"
import type { WithRadiusPerCorner } from "document/models/CanvasTree/traits/RadiusPerCorner"
import type { WithSizeFunctions } from "document/models/CanvasTree/traits/sizeFunctions"
import type { WithSizeToFit } from "document/models/CanvasTree/traits/SizeToFit"
import type { WithSize } from "document/models/CanvasTree/traits/Size"
import { validBoundingBoxUpdatesForFrameNode } from "document/models/CanvasTree/utils/shapes"
import { sizeToFitContent } from "document/models/CanvasTree/utils/sizeToFitContent"
import { borderPropsForNode } from "../traits/utils/borderForNode"
import type { WithPreviewSettings } from "../traits/PreviewSettings"
import type { PreviewSettings } from "preview-next/PreviewSettings"
import { newConstraintProperties } from "../traits/mixins/withPinsSizeRatioConstraints"
import { EventActions, WithFrameEvents, frameEventKeys } from "../traits/FrameEvents"
import type { WithEventActions, EventActionInfoMap } from "../traits/EventActions"
import { withClassDiscriminator } from "utils/withClassDiscriminator"
import { isNumber } from "utils/typeChecks"
import { collectStyle } from "../traits/collectStyles"
import type { VariableReference } from "../traits/VariableReference"
import { isVariableReference } from "../traits/VariableReference"
import { TagProps } from "variants/types"
import { Attribute } from "../utils/buildJSX"
import { attributesFromProps } from "variants/utils/attributesFromProps"
import { createNodeEventHandler } from "variants/utils/createEventHandler"
import { withDOMLayoutTraits } from "document/models/CanvasTree/traits/mixins/withDOMLayoutTraits"

export function isShapeContainerNode(node: CanvasNode): node is DrawableNode & ShapeContainerNode {
    return node instanceof ShapeContainerNode
}

// eslint-disable-next-line import/no-default-export
export default class ShapeContainerNode
    extends withClassDiscriminator("ShapeContainerNode", withDOMLayoutTraits(CanvasNode))
    implements
        WithPins,
        WithSize,
        WithSizeFunctions,
        WithRect,
        WithName,
        WithPreviewSettings,
        WithVisibility,
        WithLock,
        WithChildren,
        WithFill,
        WithOptionalFill,
        WithOpacity,
        WithAutoSize,
        WithSizeToFit,
        WithRadius,
        WithRadiusPerCorner,
        WithFrameEvents,
        WithEventActions,
        WithExportIncludesBackground,
        WithExport {
    children: CanvasNode[]
    name: string | null
    visible: boolean | VariableReference
    locked: boolean
    previewSettings: PreviewSettings | null

    // WithPins
    constraintsLocked: boolean
    left: number | null
    right: number | null
    top: number | null
    bottom: number | null
    centerAnchorX: number
    centerAnchorY: number
    widthType: DimensionType
    heightType: DimensionType

    // WithOpacity
    opacity: number | VariableReference

    // WithSize
    width: number
    height: number

    onTap: EventActions
    onTapStart: EventActions
    onMouseDown: EventActions
    onClick: EventActions
    onMouseUp: EventActions
    onMouseEnter: EventActions
    onMouseLeave: EventActions
    onWheel: EventActions
    onPan: EventActions
    onPanStart: EventActions
    onPanEnd: EventActions

    autoSize: boolean

    sizeToFit: boolean

    // relative radius not supported because it needs to be possible to render in SVG
    radiusPerCorner: boolean
    radius: number | VariableReference
    radiusTopLeft: number
    radiusTopRight: number
    radiusBottomLeft: number
    radiusBottomRight: number

    fillEnabled: boolean
    fillType: FillType
    fillColor: string | VariableReference
    fillLinearGradient: LinearGradient | undefined
    fillRadialGradient: RadialGradient | undefined
    fillImage: string | VariableReference | null
    fillImageOriginalName: string | null
    fillImageResize: ImageFit
    fillImagePixelWidth: number | null
    fillImagePixelHeight: number | null

    exportOptions: List<ExportOptions>
    exportIncludesBackground: boolean

    constructor(properties?: Partial<ShapeContainerNode>) {
        super()
        setDefaults<ShapeContainerNode>(this, getShapeContainerRecord(), properties)
    }

    updateForRect(frame: Rect, parentSize: Size | null, constraintsLocked: boolean): any {
        return updateConstrainedFrame(
            frame,
            parentSize,
            this.constraints(),
            this.constraintsLocked || constraintsLocked,
            // pixelAlign
            !this.usesDOMRectCached()
        )
    }

    updateForSize(size: Partial<Size>, parentSize: Size | null): Partial<ConstraintValues> {
        return updateConstrainedSize(size, parentSize, this.constraintValues())
    }

    getProps(): Partial<FrameProps> & LayerProps {
        const style: React.CSSProperties = {}

        const props: Partial<FrameProps> & LayerProps = {
            ...super.getProps(),
            ...this.newConstraintProperties(),
            _border: borderPropsForNode(this),
            visible: this.resolveValue("visible"),
            overflow: "hidden",
            style,
        }

        if (this.__unsafeIsGroundNode()) {
            props.left = 0
            props.top = 0
        }

        const visualProperties: DeprecatedVisualProperties = {}
        collectBackgroundPropsForNode(this, visualProperties)
        collectStyle(this, style)
        props.background = visualProperties.background
        collectNameForNode(this, props)
        return props
    }

    getAttributes(defaultProps: Partial<TagProps>): Attribute[] {
        const props: TagProps = {
            layoutId: this.id,
            style: {},
            ...defaultProps,
        }

        collectStyle(this, props.style, { withInlineVariables: true }, true)
        collectBackgroundPropsForNode(this, props as any, true, { withInlineVariables: true })

        const actions = this.getActions()
        const attributes = attributesFromProps(props)
        // Add an attribute with each event handler for this node to the node's
        // attributes. This relies on useVariantState being in scope in the
        // generated component.
        for (const key in actions) {
            const handler = createNodeEventHandler(actions[key])
            if (!handler) continue

            attributes.push({
                type: "variable",
                name: key,
                value: handler,
            })
        }

        return attributes
    }

    newConstraintProperties(): NewConstraintProperties {
        return newConstraintProperties(this)
    }

    constraintProperties(): ConstraintProperties {
        if (this.cache.constraintProps) return this.cache.constraintProps
        const constraints = this.constraints()
        return (this.cache.constraintProps = {
            left: constraints.left ? this.left : null,
            right: constraints.right ? this.right : null,
            top: constraints.top ? this.top : null,
            bottom: constraints.bottom ? this.bottom : null,
            width: this.width,
            height: this.height,
            aspectRatio: constraints.aspectRatio,
            centerX: `${this.centerAnchorX * 100}%`,
            centerY: `${this.centerAnchorY * 100}%`,
            parentSize: null,
        })
    }

    constraints(): ConstraintMask {
        if (this.cache.parentDirected) {
            return ConstraintMask.quickfix({
                left: false,
                right: false,
                top: false,
                bottom: false,
                widthType: this.widthType,
                heightType: this.heightType,
                aspectRatio: null,
                fixedSize: true,
            })
        }

        return ConstraintMask.quickfix({
            left: isNumber(this.left),
            right: isNumber(this.right),
            top: isNumber(this.top),
            bottom: isNumber(this.bottom),
            widthType: this.widthType,
            heightType: this.heightType,
            aspectRatio: null,
            fixedSize: true,
        })
    }

    constraintValues(): ConstraintValues {
        const constraints = this.constraints()
        return {
            left: constraints.left ? this.left : null,
            right: constraints.right ? this.right : null,
            top: constraints.top ? this.top : null,
            bottom: constraints.bottom ? this.bottom : null,
            widthType: this.widthType,
            heightType: this.heightType,
            width: this.width,
            height: this.height,
            aspectRatio: null,
            centerAnchorX: this.centerAnchorX,
            centerAnchorY: this.centerAnchorY,
        }
    }

    minSize(parentSize: Size | null): Size {
        return ConstraintValues.toMinSize(this.constraintValues(), parentSize)
    }

    size(parentSize: Size | null, freeSpace: WithFractionOfFreeSpace | null, allowCache = true): Size {
        const { parentDirectedRect } = this.cache
        if (parentDirectedRect && allowCache) {
            return { width: parentDirectedRect.width, height: parentDirectedRect.height }
        }
        return ConstraintValues.toSize(this.constraintValues(), parentSize, null, freeSpace)
    }

    rect(parentSize: Size | null, pixelAlign = true): Rect {
        if (this.usesDOMRectCached()) {
            const domRect = this.getDOMRect()
            if (domRect) return domRect
        }
        if (this.cache.parentDirectedRect) {
            return { ...this.cache.parentDirectedRect }
        }
        return ConstraintValues.toRect(this.constraintValues(), parentSize, null, pixelAlign)
    }

    matrix(parentSize?: Size | null, frame?: Rect): DOMMatrix {
        if (!frame && parentSize !== undefined) {
            frame = this.rect(parentSize)
        }
        if (!frame) {
            throw Error("Could not determine frame to build matrix from")
        }
        return new DOMMatrix().translateSelf(frame.x, frame.y)
    }

    transformMatrix(parentSize: Size | null, frame?: Rect): DOMMatrix {
        if (!frame) {
            frame = this.rect(parentSize)
        }
        if (!frame) {
            throw Error("Could not determine frame to build matrix from")
        }
        return new DOMMatrix()
            .translateSelf(0.5 * frame.width, 0.5 * frame.height)
            .multiplySelf(this.matrix(parentSize, frame))
            .translateSelf(-0.5 * frame.width, -0.5 * frame.height)
    }

    isRotated() {
        return false
    }

    preFreeze(tree: CanvasTree) {
        // If this is not the tree in the editor, like preview, or while
        // loading, etc., we trust the sizes and positioning of the nodes.
        if (!tree.inEditor) return

        // We can't trust the DOM rect during preFreeze, because we might be in
        // a situation where this node has updated size and position props, but
        // hasn't rendered, yet, so we don't know the actual rendered position
        // and size upfront. Invalidating the DOM rect means this node will fall
        // back on regular rect() calculation based on the current/cached parent
        // size.
        this.invalidateDOMRect()

        // But if in the editor, we have to correct the bounding box with every
        // edit. And correct path positions as top-left coordinates might
        // change.
        this.children.forEach(child => shapePreFreeze(tree, child as VectorNode))
        if (this.sizeToFit) {
            sizeToFitContent(tree, [this])
        }
    }

    getActions(): EventActionInfoMap {
        const actions: EventActionInfoMap = {}

        for (const actionKey of frameEventKeys) {
            const value = this[actionKey] as EventActions
            if (!value || value.length === 0) continue
            actions[actionKey] = value.map(eventAction => ({
                identifier: eventAction.actionIdentifier,
                info: { ...eventAction.controls },
            }))
        }

        return actions
    }

    hasActions(): boolean {
        return frameEventKeys.some(actionKey => {
            const value = this[actionKey]
            if (value && value.length > 0) return true
        })
    }

    supportsVariables() {
        return false
    }

    resolveValue<K extends keyof this>(key: K): Exclude<this[K], VariableReference> | undefined {
        const value = this[key]
        if (isVariableReference(value)) {
            return undefined
        } else {
            return value as Exclude<this[K], VariableReference>
        }
    }
}

function updateHasActualChanges(node: CanvasNode, update: NodeUpdate): boolean {
    for (const [key, value] of Object.entries(update)) {
        if (node[key] !== value) return true
    }
    return false
}

function shapePreFreeze(tree: CanvasTree, node: VectorNode) {
    const boundingBoxUpdates = validBoundingBoxUpdatesForFrameNode(tree, node)
    const updateIds = Object.keys(boundingBoxUpdates) as NodeID[]
    for (let i = 0, il = updateIds.length; i < il; i++) {
        const id = updateIds[i]
        const update = boundingBoxUpdates[id]

        // Often these updates don't actually contain new values, no need to
        // then update the node.
        if (!updateHasActualChanges(tree.get(id)!, update)) continue

        tree.update(id, update)
    }
}
