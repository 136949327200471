// New z-indexes can be added here in their respective order
// The first z-index starts from 1 and auto-increments

export enum ZIndex {
    header = 1,
    floatingWindow,
    fullsizePreview,
    projectBar,
    modal,
    popover,
    tooltip,
    toaster,
    dropdown,
}
