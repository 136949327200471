import { RadialGradient as RadialGradientInterface, RadialGradientBase } from "framer"
import { RadialGradientRecord } from "document/models/CanvasTree/records/RadialGradientRecord"
import type { WithTokenVariables } from "document/models/CanvasTree/traits/TokenVariables"
import { isTokenCSSVariable } from "document/models/CanvasTree/nodes/TokenNode"
import { findValueForTokenCSSVariable } from "document/models/CanvasTree/utils/findValueForTokenCSSVariable"
import { GradientColorStop, GradientColorStops, equalColorStops } from "./GradientColorStop"
import { withClassDiscriminator } from "utils/withClassDiscriminator"

export function isRadialGradient(node: any): node is RadialGradient {
    return node instanceof RadialGradient
}

export class RadialGradient extends withClassDiscriminator("RadialGradient", RadialGradientRecord)
    implements RadialGradientBase, WithTokenVariables<RadialGradient> {
    alpha: number
    widthFactor: number
    heightFactor: number
    centerAnchorX: number
    centerAnchorY: number
    stops: GradientColorStops
    private __css: string
    private __js: any

    toJS() {
        let __js = this.__js
        if (__js) {
            return __js
        }
        __js = super.toJS()
        __js["__class"] = this.__class
        // Alpha isn't compatible with variables
        if (this.stops.some((stop: GradientColorStop) => isTokenCSSVariable(stop.value))) {
            __js["alpha"] = 1
        }
        return (this.__js = __js)
    }

    toJSON() {
        return this.toJS()
    }

    toCSS() {
        const __css = this.__css
        if (__css) {
            return __css
        }
        return (this.__css = RadialGradientInterface.toCSS(this.toJS()))
    }

    equals(other: any): boolean {
        return (
            this.widthFactor === other.widthFactor &&
            this.heightFactor === other.heightFactor &&
            this.centerAnchorX === other.centerAnchorX &&
            this.centerAnchorY === other.centerAnchorY &&
            this.equalColors(other)
        )
    }

    equalColors(other: any): boolean {
        return this.alpha === other.alpha && equalColorStops(this.stops, other.stops)
    }

    // WithTokenVariables

    tokenVariables(): string[] {
        const result: string[] = []
        this.stops.forEach((stop: GradientColorStop) => {
            if (isTokenCSSVariable(stop.value)) {
                result.push(stop.value)
            }
        })
        return result
    }

    removeTokenVariables(variables: { [tokenId: string]: string }): RadialGradient | undefined {
        let changed = false

        const stops = this.stops.map((stop: GradientColorStop) => {
            const replacement = findValueForTokenCSSVariable(stop.value, variables)
            if (replacement) {
                changed = true
                return new GradientColorStop(stop.merge({ value: replacement }))
            } else {
                return stop
            }
        })

        if (changed) {
            return new RadialGradient({ ...this.toJS(), stops })
        }
    }
}
