/**
 * This is legacy functionality imported from Framer Motion 1.x
 * It's been superseded by Magic Motion but it's been recreated here to provide support
 * for positionTransition and layoutTransition in Framer Library to avoid a major version bump.
 * Can be considered deprecated for `framer@2.0.0`
 */

import * as React from "react"
import { animateVisualElement, MotionFeature, FeatureProps, Transition, TargetAndTransition } from "framer-motion"
import { CustomMotionProps, ResolveLayoutTransition } from "./types"

interface Layout {
    top: number
    left: number
    right: number
    bottom: number
    width: number
    height: number
}

interface XDelta {
    x: number
    originX: number
    width: number
}

interface YDelta {
    y: number
    originY: number
    height: number
}

// We measure the positional delta as x/y as we're actually going to figure out
// and track the motion of the component's visual center.
interface LayoutDelta extends XDelta, YDelta {}

// We use both offset and bounding box measurements, and they need to be handled slightly differently
interface LayoutType {
    measure: (element: HTMLElement) => Layout
    getLayout: (info: VisualInfo) => Layout
}

interface VisualInfo {
    offset: Layout
    boundingBox: Layout
}

const defaultLayoutTransition = {
    duration: 0.8,
    ease: [0.45, 0.05, 0.19, 1.0],
}

const defaultPositionTransition = {
    type: "spring",
    stiffness: 500,
    damping: 25,
    restDelta: 0.5,
    restSpeed: 10,
}

function getDefaultLayoutTransition(isPositionOnly: boolean) {
    return isPositionOnly ? defaultPositionTransition : defaultLayoutTransition
}

function isResolver(transition: CustomMotionProps["layoutTransition"]): transition is ResolveLayoutTransition {
    return typeof transition === "function"
}

interface XLabels {
    id: "x"
    size: "width"
    min: "left"
    max: "right"
    origin: "originX"
}

interface YLabels {
    id: "y"
    size: "height"
    min: "top"
    max: "bottom"
    origin: "originY"
}

const axisLabels: { x: XLabels; y: YLabels } = {
    x: {
        id: "x",
        size: "width",
        min: "left",
        max: "right",
        origin: "originX",
    },
    y: {
        id: "y",
        size: "height",
        min: "top",
        max: "bottom",
        origin: "originY",
    },
}

function centerOf(min: number, max: number) {
    return (min + max) / 2
}

function calcAxisDelta(prev: Layout, next: Layout, names: XLabels): XDelta
function calcAxisDelta(prev: Layout, next: Layout, names: YLabels): YDelta
function calcAxisDelta(prev: Layout, next: Layout, names: XLabels | YLabels): XDelta | YDelta {
    const sizeDelta = prev[names.size] - next[names.size]
    let origin = 0.5

    // If the element has changed size we want to check whether either side is in
    // the same position before/after the layout transition. If so, we can anchor
    // the element to that position and only animate its size.
    if (sizeDelta) {
        if (prev[names.min] === next[names.min]) {
            origin = 0
        } else if (prev[names.max] === next[names.max]) {
            origin = 1
        }
    }

    const delta = {
        [names.size]: sizeDelta,
        [names.origin]: origin,
        [names.id]:
            // Only measure a position delta if we haven't anchored to one side
            origin === 0.5
                ? centerOf(prev[names.min], prev[names.max]) - centerOf(next[names.min], next[names.max])
                : 0,
    }

    return delta as any
}

function calcDelta(prev: Layout, next: Layout): LayoutDelta {
    const delta = {
        ...calcAxisDelta(prev, next, axisLabels.x),
        ...calcAxisDelta(prev, next, axisLabels.y),
    }

    return delta as LayoutDelta
}

const offset: LayoutType = {
    getLayout: ({ offset }) => offset,
    measure: element => {
        const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = element

        return {
            left: offsetLeft,
            top: offsetTop,
            right: offsetLeft + offsetWidth,
            bottom: offsetTop + offsetHeight,
            width: offsetWidth,
            height: offsetHeight,
        }
    },
}
const boundingBox: LayoutType = {
    getLayout: ({ boundingBox }) => boundingBox,
    measure: element => {
        const { left, top, width, height, right, bottom } = element.getBoundingClientRect()
        return { left, top, width, height, right, bottom }
    },
}

function readPositionStyle(element: HTMLElement) {
    return window.getComputedStyle(element).position
}

function getLayoutType(prev: string | null, next: string | null, isPositionOnly: boolean): LayoutType {
    return isPositionOnly && prev === next ? offset : boundingBox
}

function isSizeKey(key: string) {
    return key === "width" || key === "height"
}

interface LayoutProps {
    positionTransition?: boolean
    layoutTransition?: boolean
}

function getTransition({ layoutTransition, positionTransition }: FeatureProps & LayoutProps) {
    return layoutTransition || positionTransition
}

export class LayoutAnimation extends React.Component<FeatureProps & LayoutProps> {
    // Measure the current state of the DOM before it's updated, and schedule checks to see
    // if it's changed as a result of a React render.
    getSnapshotBeforeUpdate() {
        const { visualElement, positionTransition } = this.props
        const element = visualElement.getInstance()

        if (!isHTMLElement(element)) return

        const layoutTransition = getTransition(this.props) as boolean
        const isPositionOnly = !!positionTransition

        const positionStyle = readPositionStyle(element)
        const prev: VisualInfo = {
            offset: offset.measure(element),
            boundingBox: boundingBox.measure(element),
        }

        let transform: string | null
        let next: VisualInfo
        let compare: LayoutType

        // We split the unsetting, read and reapplication of the `transform` style prop into
        // different steps via useSyncEffect. Multiple components might all be doing the same
        // thing and by splitting these jobs and flushing them in batches we prevent layout thrashing.
        layoutSync.prepare(() => {
            // Unset the transform of all layoutTransition components so we can accurately measure
            // the target bounding box
            transform = element.style.transform
            element.style.transform = ""
        })

        layoutSync.read(() => {
            // Read the target VisualInfo of all layoutTransition components
            next = {
                offset: offset.measure(element),
                boundingBox: boundingBox.measure(element),
            }

            const nextPosition = readPositionStyle(element)
            compare = getLayoutType(positionStyle, nextPosition, isPositionOnly)
        })

        layoutSync.render(() => {
            // Reverse the layout delta of all newly laid-out layoutTransition components into their
            // prev visual state and then animate them into their new one using transforms.
            const prevLayout = compare.getLayout(prev)
            const nextLayout = compare.getLayout(next)
            const delta = calcDelta(prevLayout, nextLayout)
            const hasAnyChanged = delta.x || delta.y || delta.width || delta.height

            if (!hasAnyChanged) {
                // If layout hasn't changed, reapply the transform and get out of here.
                transform && (element.style.transform = transform)
                return
            }

            const latest = visualElement.getLatestValues()
            latest.originX = delta.originX
            latest.originY = delta.originY

            const target: TargetAndTransition = {}
            const transition: Transition = {}

            const transitionDefinition = isResolver(layoutTransition) ? layoutTransition({ delta }) : layoutTransition

            function makeTransition(
                layoutKey: keyof Layout,
                transformKey: string,
                targetValue: number,
                visualOrigin: number
            ) {
                // If this dimension hasn't changed, early return
                const deltaKey = isSizeKey(layoutKey) ? layoutKey : transformKey
                if (!delta[deltaKey]) return

                const baseTransition =
                    typeof transitionDefinition === "boolean"
                        ? { ...getDefaultLayoutTransition(isPositionOnly) }
                        : transitionDefinition

                const value = visualElement.getValue(transformKey, targetValue)
                const velocity = value.getVelocity()

                transition[transformKey] = baseTransition[transformKey]
                    ? { ...baseTransition[transformKey] }
                    : { ...baseTransition }

                if (transition[transformKey].velocity === undefined) {
                    transition[transformKey].velocity = velocity || 0
                }

                // The target value of all transforms is the default value of that prop (ie x = 0, scaleX = 1)
                // This is because we're inverting the layout change with `transform` and then animating to `transform: none`
                target[transformKey] = targetValue

                const offsetToApply = !isSizeKey(layoutKey) && compare === offset ? value.get() : 0

                value.set(visualOrigin + offsetToApply)
            }

            makeTransition("left", "x", 0, delta.x)
            makeTransition("top", "y", 0, delta.y)

            if (!isPositionOnly) {
                makeTransition("width", "scaleX", 1, prev.boundingBox.width / next.boundingBox.width)
                makeTransition("height", "scaleY", 1, prev.boundingBox.height / next.boundingBox.height)
            }

            target.transition = transition

            // Only start the transition if `transitionDefinition` isn't `false`. Otherwise we want
            // to leave the values in their newly-inverted state and let the user cope with the rest.
            transitionDefinition && animateVisualElement(visualElement, target)
        })

        return null
    }

    componentDidUpdate() {
        layoutSync.flush()
    }

    render() {
        return null
    }
}

export const layoutTransition: MotionFeature = {
    key: "layout",
    shouldRender: ({ positionTransition, layoutTransition }: any) => {
        return typeof window !== "undefined" && !!(positionTransition || layoutTransition)
    },
    getComponent: () => LayoutAnimation,
}

function isHTMLElement(element?: Element | HTMLElement | null): element is HTMLElement {
    return element instanceof HTMLElement
}

type Callback = () => void

enum StepName {
    Prepare = "prepare",
    Read = "read",
    Render = "render",
}

interface CallbackLists {
    prepare: Callback[]
    read: Callback[]
    render: Callback[]
}

const stepOrder: StepName[] = [StepName.Prepare, StepName.Read, StepName.Render]

const jobs: CallbackLists = stepOrder.reduce((acc, key) => {
    acc[key] = []
    return acc
}, {}) as CallbackLists

let jobsNeedProcessing = false

function flushCallbackList(list: Callback[]) {
    const numJobs = list.length

    for (let i = 0; i < numJobs; i++) {
        list[i]()
    }

    list.length = 0
}

function flushAllJobs() {
    if (!jobsNeedProcessing) return

    flushCallbackList(jobs.prepare)
    flushCallbackList(jobs.read)
    flushCallbackList(jobs.render)
    jobsNeedProcessing = false
}

// Note: The approach of schedulng jobs during the render step is incompatible with concurrent mode
// where multiple renders might happen without a DOM update. This would result in unneccessary batched
// jobs. But this was already a problem with our previous approach to positionTransition.
// Hopefully the React team offer a getSnapshotBeforeUpdate-esque hook and we can move to that.
const createUseSyncEffect = (stepName: StepName) => (callback?: Callback) => {
    if (!callback) return

    jobsNeedProcessing = true
    jobs[stepName].push(callback)
}

export const layoutSync = {
    [StepName.Prepare]: createUseSyncEffect(StepName.Prepare),
    [StepName.Read]: createUseSyncEffect(StepName.Read),
    [StepName.Render]: createUseSyncEffect(StepName.Render),
    flush: flushAllJobs,
}
