import { IconClose, IconNavigationStackBack } from "../icons"
import { FontSize, fonts } from "../tokens/fonts"
import { Tabbable } from "./Tabbable"
import { cx } from "linaria"
import * as React from "react"
import * as styles from "./NavigationStack.styles"

export type NavigationStackItemId = string

export interface NavigationStackItem<T extends object = {}> {
    id: NavigationStackItemId
    componentProps: T
    component: React.ComponentType<T>
    title: string
    centerTitle?: boolean
    displayDivider?: boolean
    triggerRef?: React.RefObject<any> // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const NavigationStackItemContext = React.createContext<NavigationStackItemId | null>(null)

export interface NavigationStackProps {
    stack: NavigationStackItem[]
    currentIndex: number
    onClose?(): void
    onBack?(): void
    /** Prevents animating stack items when the component first mounst */
    disableEnterAnimation?: boolean
    renderNavigationBarWrapper?(title: React.ReactNode): React.ReactNode
    titleSize?: FontSize
    disableNavigationBar?: boolean
}

const defaultNavigationBarWrapper = (bar: React.ReactNode) => bar

export const NavigationStack = ({
    stack,
    currentIndex,
    onBack,
    onClose,
    disableEnterAnimation = false,
    renderNavigationBarWrapper = defaultNavigationBarWrapper,
    titleSize = fonts.size.base,
    disableNavigationBar = false,
}: NavigationStackProps) => {
    const onlyOneElement = stack.length === 1
    const currentItem = stack[currentIndex]
    const canGoBack = currentIndex > 0
    const disableDivider = currentItem.displayDivider !== true
    const focusInside = React.useCallback(
        (target: unknown) => {
            if (!(target instanceof HTMLElement)) return
            if (target.contains(document.activeElement)) return
            const transitionIndexData = target.getAttribute("data-transition-index")
            const transitionIndex = Number(transitionIndexData)
            if (transitionIndex !== currentIndex) return

            const nextItem = stack[transitionIndex + 1]
            const triggerElement = nextItem?.triggerRef?.current
            if (isFocusable(triggerElement) && target.contains(triggerElement)) {
                triggerElement.focus()
            } else {
                const focusableElement = target.querySelector("input:enabled, select:enabled")
                if (focusableElement instanceof HTMLElement) {
                    focusableElement.focus()
                }
            }
        },
        [stack, currentIndex]
    )

    const onTransitionOrAnimationEnd = React.useCallback(
        (event: React.TransitionEvent<HTMLDivElement> | React.AnimationEvent<HTMLDivElement>) => {
            focusInside(event.target)
        },
        [focusInside]
    )

    return (
        <>
            {!disableNavigationBar &&
                renderNavigationBarWrapper(
                    <NavigationBar
                        disableDivider={disableDivider}
                        onBack={canGoBack ? onBack : undefined}
                        onClose={onClose}
                    >
                        <NavigationBarTitles
                            stack={stack}
                            currentIndex={currentIndex}
                            disableEnterAnimation={disableEnterAnimation}
                            size={titleSize}
                        />
                    </NavigationBar>
                )}
            <div className={styles.stackContainer}>
                {stack.map((stackItem, stackItemIndex) => {
                    const isCurrent = stackItemIndex === currentIndex
                    return (
                        <NavigationStackItemContext.Provider key={stackItem.id} value={stackItem.id}>
                            <Tabbable enabled={isCurrent}>
                                <NavigationContentContainer
                                    ref={onlyOneElement ? focusInside : undefined}
                                    index={stackItemIndex}
                                    currentIndex={currentIndex}
                                    disableEnterAnimation={disableEnterAnimation}
                                    onTransitionEnd={isCurrent ? onTransitionOrAnimationEnd : undefined}
                                    onAnimationEnd={isCurrent ? onTransitionOrAnimationEnd : undefined}
                                >
                                    <stackItem.component {...stackItem.componentProps} />
                                </NavigationContentContainer>
                            </Tabbable>
                        </NavigationStackItemContext.Provider>
                    )
                })}
            </div>
        </>
    )
}

interface NavigationContentContainerProps {
    index: number
    currentIndex: number
    disableEnterAnimation: boolean
    children: React.ReactNode
    onTransitionEnd: ((event: React.TransitionEvent<HTMLDivElement>) => void) | undefined
    onAnimationEnd: ((event: React.AnimationEvent<HTMLDivElement>) => void) | undefined
}

const NavigationContentContainer = React.forwardRef<HTMLDivElement, NavigationContentContainerProps>(
    function NavigationContentContainer(
        { index, currentIndex, disableEnterAnimation, children, onTransitionEnd, onAnimationEnd },
        forwardedRef
    ) {
        return (
            <div
                ref={forwardedRef}
                data-transition-index={index}
                onTransitionEnd={onTransitionEnd}
                onAnimationEnd={onAnimationEnd}
                className={cx(
                    styles.navigationContent,
                    index === currentIndex && styles.navigationContentCurrent,
                    index < currentIndex && styles.offsetToLeft,
                    index < currentIndex && styles.transparent,
                    index > currentIndex && styles.offsetToRight,
                    !disableEnterAnimation && index !== 0 && styles.navigationContentEnterAnimation
                )}
            >
                {children}
            </div>
        )
    }
)

interface NavigationBarProps {
    disableDivider?: boolean
    children?: React.ReactNode
    onBack?: () => void
    onClose?: () => void
}

function NavigationBar({ disableDivider, onBack, onClose, children }: NavigationBarProps) {
    return (
        <div className={cx(styles.navigationBar, !disableDivider && styles.navigationBarDivider)}>
            <div className={cx(styles.navigationBarAction, !onBack && styles.navigationBarActionHidden)}>
                <div onClick={onBack}>
                    <IconNavigationStackBack />
                </div>
            </div>
            {children}
            <div className={cx(styles.navigationBarAction, !onClose && styles.navigationBarActionHidden)}>
                <div onClick={onClose}>
                    <IconClose />
                </div>
            </div>
        </div>
    )
}

interface NavigationBarTitlesProps {
    currentIndex: number
    stack: NavigationStackItem[]
    disableEnterAnimation: boolean
    size: FontSize
}

function NavigationBarTitles({ currentIndex, stack, disableEnterAnimation, size }: NavigationBarTitlesProps) {
    const style: React.CSSProperties = {
        fontSize: `${size}px`,
    }
    return (
        <>
            {stack.map((stackItem, index) => {
                return (
                    <div
                        key={stackItem.id}
                        className={cx(
                            styles.title,
                            !stackItem.centerTitle && index === 0 && styles.firstTitle,
                            !disableEnterAnimation && index !== 0 && styles.titleEnterAnimation,
                            index !== currentIndex && styles.transparent,
                            index < currentIndex && styles.offsetToLeft,
                            index > currentIndex && styles.offsetToRight
                        )}
                        style={style}
                    >
                        {stackItem.title}
                    </div>
                )
            })}
        </>
    )
}

function isFocusable(element: unknown): element is HTMLElement {
    if (element instanceof HTMLButtonElement) return !element.disabled && element.tabIndex >= 0
    if (element instanceof HTMLSelectElement) return !element.disabled && element.tabIndex >= 0
    if (element instanceof HTMLInputElement) return !element.disabled && element.tabIndex >= 0
    if (element instanceof HTMLDivElement) return element.tabIndex >= 0
    return false
}
