import { createCSSVariables, TokenCategory } from "../utils/variables"
import { shadowsLight } from "./shadowsLight"
import { shadowsDark } from "./shadowsDark"
export type { ShadowTokenMap } from "./shadowTypes"

const scope = "shadow"

export const shadows = createCSSVariables(shadowsLight, scope)

export const shadowTokensLight: TokenCategory = { tokens: shadowsLight, scope }
export const shadowTokensDark: TokenCategory = { tokens: shadowsDark, scope }
