import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springAnimation3, wait, animationDelay } from "./utils"
import { colors } from "../tokens"

export const AnimationGraphic = React.memo(function AnimationGraphic() {
    const lineControl = useAnimation()
    const starControl = useAnimation()
    const circleControl = useAnimation()
    const pentagonControl = useAnimation()

    async function animation() {
        await wait(animationDelay)

        await Promise.all([
            lineControl.start({
                scale: 1,
                opacity: 1,
                transition: { type: false },
            }),
            circleControl.start({
                scale: 0,
                opacity: 0,
                transition: { type: false },
            }),
            pentagonControl.start({
                scale: 0,
                opacity: 0,
                transition: { type: false },
            }),
            starControl.start({
                scale: 0,
                opacity: 0,
                transition: { type: false },
            }),
        ])
        await Promise.all([
            lineControl.start({
                scale: 0,
                opacity: 0,
                transition: springAnimation3,
            }),
            circleControl.start({
                scale: 1,
                opacity: 1,
                transition: springAnimation3,
            }),
        ])
        await Promise.all([
            circleControl.start({
                scale: 0,
                opacity: 0,
                transition: springAnimation3,
            }),
            pentagonControl.start({
                scale: 1,
                opacity: 1,
                transition: springAnimation3,
            }),
        ])
        await Promise.all([
            pentagonControl.start({
                scale: 0,
                opacity: 0,
                transition: springAnimation3,
            }),
            starControl.start({
                scale: 1,
                opacity: 1,
                transition: springAnimation3,
            }),
        ])
        return await Promise.all([
            starControl.start({
                scale: 0,
                opacity: 0,
                transition: springAnimation3,
            }),
            lineControl.start({
                scale: 1,
                opacity: 1,
                transition: springAnimation3,
            }),
        ])
    }

    useLoop(animation)

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 2,
                width: 40,
                height: 40,
                borderRadius: 8,
                background: colors.animationForgroundLight,
            }}
        >
            <motion.div animate={lineControl} style={{ position: "absolute" }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24"
                    height="24"
                >
                    <path d="M9 15l6-6" fill="transparent" strokeWidth="2" stroke={colors.animationForground} />
                    <g transform="translate(3 3)">
                        <defs>
                            <path d="M3 12a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" id="animation-graphic-a" />
                            <clipPath id="animation-graphic-b">
                                <use xlinkHref="#animation-graphic-a" />
                            </clipPath>
                        </defs>
                        <use
                            xlinkHref="#animation-graphic-a"
                            fill={colors.animationForground}
                            clipPath="url(#animation-graphic-b)"
                            strokeWidth="2"
                            stroke={colors.animationForground}
                        />
                    </g>
                    <g transform="translate(3 3)">
                        <defs>
                            <path d="M15 0a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" id="animation-graphic-c" />
                            <clipPath id="animation-graphic-d">
                                <use xlinkHref="#animation-graphic-c" />
                            </clipPath>
                        </defs>
                        <use
                            xlinkHref="#animation-graphic-c"
                            fill={colors.animationForground}
                            clipPath="url(#animation-graphic-d)"
                            strokeWidth="2"
                            stroke={colors.animationForground}
                        />
                    </g>
                </svg>
            </motion.div>
            <motion.div animate={circleControl} style={{ position: "absolute", opacity: 0 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                    <path d="M12 3a9 9 0 1 1 0 18 9 9 0 0 1 0-18z" fill={colors.animationForground} />
                </svg>
            </motion.div>
            <motion.div animate={pentagonControl} style={{ position: "absolute", opacity: 0 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                    <path
                        d="M10.8 1.9a2 2 0 0 1 2.4 0L21.8 8c.7.5 1 1.5.8 2.3l-3.4 10.1a2 2 0 0 1-1.9 1.4H6.7a2 2 0 0 1-2-1.4L1.5 10.4A2 2 0 0 1 2.2 8z"
                        fill={colors.animationForground}
                    />
                </svg>
            </motion.div>
            <motion.div animate={starControl} style={{ position: "absolute", opacity: 0 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                    <path
                        d="M11 1.5a1 1 0 0 1 2 0l2.6 5.2c.1.3.4.6.8.6l5.8.9a1 1 0 0 1 .6 1.8l-4.2 4.1a1 1 0 0 0-.3 1l1 5.8a1 1 0 0 1-1.6 1.1l-5.2-2.7a1 1 0 0 0-1 0L6.3 22a1 1 0 0 1-1.6-1.1l1-5.8c0-.4 0-.7-.3-1l-4.2-4a1 1 0 0 1 .6-2l5.8-.8c.4 0 .7-.3.8-.6z"
                        fill={colors.animationForground}
                    />
                </svg>
            </motion.div>
        </div>
    )
})
