import { css } from "linaria"
import { colors, fonts } from "../tokens"

// Inherits and overrides global button styles in web.css.
export const projectBarButton = css`
    display: flex;
    min-width: 30px;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    padding: 0 14px;
    border-radius: 15px;
    font-size: 13px;
    font-weight: ${fonts.weight.heading};
    transition: border-color ease 150ms, background-color ease 150ms;

    svg {
        transition: fill ease 150ms, stroke ease 150ms, transform ease 100ms;
    }
`

export const round = css`
    padding: 0;
`

// We apply the default background color explicitly to not override the primary styles in Button
export const defaultStyles = css`
    background-color: ${colors.toolbarButtonBackground};
    color: ${colors.toolbarButtonText};

    :focus:not(:disabled),
    :hover:not(:disabled),
    :active:focus:not(:disabled),
    :active:hover:not(:disabled) {
        background-color: ${colors.buttonBackgroundHover};
    }
`

export const warning = css`
    background-color: ${colors.toolbarButtonBackgroundWarning};
    color: ${colors.toolbarButtonTextWarning};

    :focus:not(:disabled),
    :hover:not(:disabled),
    :active:focus:not(:disabled),
    :active:hover:not(:disabled) {
        background-color: ${colors.toolbarButtonBackgroundWarningActive};
    }
`

export const error = css`
    background-color: ${colors.toolbarButtonBackgroundError};
    color: ${colors.toolbarButtonTextError};

    :focus:not(:disabled),
    :hover:not(:disabled),
    :active:focus:not(:disabled),
    :active:hover:not(:disabled) {
        background-color: ${colors.toolbarButtonBackgroundErrorActive};
    }
`

export const active = css`
    background-color: ${colors.toolbarButtonBackgroundActive};
    color: ${colors.toolbarButtonTextActive};

    :focus:not(:disabled),
    :hover:not(:disabled),
    :active:hover:not(:disabled),
    :active:focus:not(:disabled) {
        background-color: ${colors.toolbarButtonBackgroundActive};
        color: ${colors.toolbarButtonTextActive};
    }
`
