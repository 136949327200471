import Paper from "paper"
import { getLogger } from "@framerjs/shared"
import type { PathOperator } from "utils/pathOperator"
import { convertWithPathsToPaperPathItem } from "utils/paperjs/vekterToPaperjs"
import { PathBooleanType } from "document/models/CanvasTree/traits/PathBoolean"
import { convertPaperItemToNodes, convertPaperPathItemToWithPaths } from "utils/paperjs/paperjsToVekter"
import { CanvasTree, VectorNode, ShapeContainerNode, DrawableNode } from "document/models/CanvasTree"
import { scaleMutableNodes } from "document/models/CanvasTree/utils/scaleMutableNodes"
import setupPaperWithoutView from "utils/paperjs/utils/setupPaperWithoutView"
import type { WithColorable } from "document/models/CanvasTree/traits/Colorable"
import type { Size, WithPaths } from "framer"
import { withIntrinsicSize } from "document/models/CanvasTree/traits/IntrinsicSize"
import type { WithFill } from "document/models/CanvasTree/traits/Fill"
import { LinearGradient } from "document/models/Gradient"
import { isString } from "utils/typeChecks"

/**
 * This is an implementation of the PathOperator interface, that uses
 * the Paperjs library, as-is.
 */

setupPaperWithoutView()

// https://www.w3.org/TR/SVG11/struct.html#InterfaceSVGDocument
type SVGDocument = Document &
    DocumentEvent & {
        title: string
        referrer: string
        domain: string
        URL: string
        rootElement: SVGSVGElement
    }

export class PaperjsPathOperator implements PathOperator {
    booleanJoin(paths: WithPaths[], type: PathBooleanType): WithPaths {
        if (type === PathBooleanType.Join) {
            const res: WithPaths = []
            return res.concat(...paths)
        }

        const paperPaths = paths.map(path => {
            return convertWithPathsToPaperPathItem(path, true)
        })

        // When debugging use this code to get SVG snippets for the generated
        // paths:
        /*
        paperPaths.forEach(paperPath => {
            paperPath.fillColor = new Color("#bada55")
            console.log(paperPath.exportSVG({ asString: true }))
        })
        */

        let joinedShape: Paper.PathItem | undefined = paperPaths.shift()

        paperPaths.forEach(next => {
            if (joinedShape && next) {
                switch (type) {
                    case PathBooleanType.Unite:
                        joinedShape = joinedShape.unite(next)
                        break
                    case PathBooleanType.Exclude:
                        joinedShape = joinedShape.exclude(next)
                        break
                    case PathBooleanType.Intersect:
                        joinedShape = joinedShape.intersect(next)
                        break
                    case PathBooleanType.Subtract:
                        joinedShape = joinedShape.subtract(next)
                        break
                }
            }
        })

        if (joinedShape) {
            // When debugging use this code to get the resulting joined shape
            // as an SVG:
            /*
            try {
                console.log(joinedShape.exportSVG({ asString: true }))
            } catch (error) {
                console.log("failed to extract svg")
            }
            */

            const withShape = convertPaperPathItemToWithPaths({ item: joinedShape })
            return withShape
        }

        return []
    }

    containsPoint(node: VectorNode, point: { x: number; y: number }): boolean {
        let paperPath: Paper.PathItem
        const cache = node.cache
        if (cache.paperPath) {
            paperPath = cache.paperPath
        } else {
            paperPath = convertWithPathsToPaperPathItem(node.calculatedPaths())
            cache.paperPath = paperPath
        }
        return paperPath.contains(new Paper.Point(point))
    }

    importSvg(svg: string, size?: Size, node?: WithFill & WithColorable): ShapeContainerNode | null {
        const log = getLogger("app:importSvg")

        let svgStringOrElement: string | SVGElement = svg
        let attributeOverrides: Partial<WithFill> | undefined

        if (node && node.colorable) {
            const domParser = new DOMParser()
            const svgDocument = domParser.parseFromString(svg, "image/svg+xml") as SVGDocument
            const svgElement = svgDocument.rootElement
            if (!svgElement) {
                throw new Error(`Unsupported SVG: ${svg}}`)
            }
            const presetFill = svgElement.getAttribute("fill")
            const shouldFill = !(presetFill && presetFill.toLowerCase() === "none")

            if (shouldFill) {
                if (node.fillType === "linear-gradient" && node.fillLinearGradient instanceof LinearGradient) {
                    attributeOverrides = {
                        fillType: "linear-gradient",
                        fillLinearGradient: node.fillLinearGradient,
                    }
                } else if (node.fillType === "image" && node.fillImage) {
                    attributeOverrides = {
                        fillType: "image",
                        fillImage: node.fillImage,
                        fillImageResize: node.fillImageResize,
                        fillImagePixelWidth: node.fillImagePixelWidth,
                        fillImagePixelHeight: node.fillImagePixelHeight,
                    }
                } else if (node.fillType === "color" && isString(node.fillColor)) {
                    const fillColorForStyle = new Paper.Color(node.fillColor)
                    const fillColorCSS = fillColorForStyle.toCSS(false)
                    if (shouldFill) {
                        svgElement.setAttribute("style", `fill: ${fillColorCSS}; color: ${fillColorCSS}`)
                    }

                    const presetStroke = svgElement.getAttribute("stroke")
                    if (presetStroke && presetStroke.toLowerCase() === "currentcolor") {
                        svgElement.setAttribute("stroke", fillColorCSS)
                    }
                }

                svgStringOrElement = svgElement
            }
        }

        let anErrorOccuredWhileImporting = false
        const paperItem = Paper.project.importSVG(svgStringOrElement, {
            expandShapes: true,
            insert: false,
            size,
            onError: (error: any) => {
                log.reportError(error)
                anErrorOccuredWhileImporting = true

                // For reasons unknown, PaperJS decides to leave SVGs lingering
                // on the canvas when an error occurs. Clean this up:
                const body = document && document.getElementsByTagName("body")
                if (body && body[0]) {
                    let lastChild = body[0].lastElementChild
                    while (lastChild && lastChild.tagName === "svg") {
                        lastChild.remove()
                        lastChild = body[0].lastElementChild
                    }
                }
            },
        })

        if (anErrorOccuredWhileImporting) {
            return null
        }

        let nodes: DrawableNode[]
        try {
            nodes = convertPaperItemToNodes({ item: paperItem, fillOverrides: attributeOverrides })
        } catch (error) {
            log.reportError(error)
            return null
        }

        const root = nodes[0]
        if (!root) {
            return null
        }

        if (size) {
            const originalSize = { width: root.width, height: root.height }

            // Hack because the root size info from Paper might have discarded some info
            const originalNode = node as any
            if (originalNode && withIntrinsicSize(originalNode)) {
                originalSize.height = originalNode.intrinsicHeight || originalSize.height
                originalSize.width = originalNode.intrinsicWidth || originalSize.width
            }

            const scaleX = size.width / originalSize.width
            const scaleY = size.height / originalSize.height
            if (scaleX !== 1 || scaleY !== 1) {
                scaleMutableNodes(nodes.slice(1), scaleX, scaleY)
            }
        }

        // Pull it through a tree to make sure all internals are correct
        return CanvasTree.createEmpty().insertNodes(nodes).getNode(root.id) as ShapeContainerNode
    }
}
