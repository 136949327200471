import type { OverrideType, HTMLAnchorAttributes, HTMLDivAttributes } from "./types"

import * as React from "react"
import { cx } from "linaria"
import { dimensions } from "../tokens"
import { truncateWithEllipsis } from "./utils/truncateWithEllipsis.styles"
import * as styles from "./SegmentedControl.styles"

export type SegmentedControlItemProps<T extends string | number | boolean | undefined> = OverrideType<
    Omit<HTMLAnchorAttributes, "onClick">,
    {
        /** Distinguish items that share the same title. */
        identifier?: T
        /** The title of the item. A function can be passed that receives the identifier as an argument. */
        title: string | ((identifier: T | undefined) => string)
        /** Highlights the item. */
        selected: boolean
        /** The event handler receives the identifier as an argument. Allowing a single function to handle multiple items. */
        onSelect: (identifier: T | undefined) => void
        /** Makes the item non selectable. */
        enabled?: boolean
    }
>

// This function is only used for its type. React forwardRef and memo break the use of generics in Props
function SegmentedControlItemType<T extends string | number | boolean | undefined = undefined>(
    _props: SegmentedControlItemProps<T>
) {
    return <></>
}

export const SegmentedControlItem = React.memo(
    React.forwardRef(function SegmentedControlItem<T extends string | number | boolean | undefined>(
        props: SegmentedControlItemProps<T>,
        ref: React.Ref<HTMLAnchorElement>
    ) {
        const { identifier, title, selected, onSelect, enabled, className, children, ...rest } = props

        let tooltip: string | undefined = undefined
        let label: string | undefined = undefined
        const titleText = typeof title === "function" ? title(identifier) : title

        const childCount = React.Children.toArray(children).filter(child => child !== undefined && child !== null)
            .length
        const shouldRenderChildren = childCount > 0

        if (shouldRenderChildren) {
            tooltip = titleText
        } else {
            label = titleText
        }

        const onClickHandler = React.useCallback(() => onSelect(identifier), [onSelect, identifier])

        return (
            <a
                ref={ref}
                title={tooltip}
                className={cx(
                    styles.segment,
                    truncateWithEllipsis,
                    enabled === false && styles.segmentDisabled,
                    selected && styles.segmentSelected,
                    className
                )}
                {...rest}
                onClick={onClickHandler}
            >
                {shouldRenderChildren ? children : <div className={styles.titleWrapper}>{label}</div>}
            </a>
        )
    })
) as typeof SegmentedControlItemType

export type SegmentedControlProps = OverrideType<
    Omit<HTMLDivAttributes, "onClick">,
    {
        /** Allows disabling the control. */
        enabled?: boolean
        /** Increases the height. */
        large?: boolean
        /** Horizontal or vertical. Defaults to horizontal */
        direction?: "horizontal" | "vertical"
    }
>

/**
 * A segmented control is a linear set of two or more segments, each of which functions as a mutually exclusive button. Within the control, all segments are equal in width.
 *
 * ```jsx
 * import * as React from "react"
 *
 * const fruits = ["apple", "banana", "cherry"]
 *
 * export function MyComponent() {
 *   const [activeFruit, setActiveFruit] = useState("apple")
 *
 *   return (
 *      <SegmentedControl>
 *          {fruits.map(fruit => {
 *              return <SegmentedControlItem
 *                  key={fruit}
 *                  identifier={fruit}
 *                  title={fruit}
 *                  onSelect={setActiveFruit}
 *              >
 *          })}
 *      <SegmentedControl>)
 * }
 * ```
 */
export const SegmentedControl = React.memo(
    React.forwardRef<HTMLDivElement, SegmentedControlProps>(function SegmentedControl(props, forwardedRef) {
        const { enabled, children, large, direction = "horizontal", className, style, ...rest } = props

        const segmentsWithDividers: React.ReactNode[] = []

        const childCount = React.Children.count(children)
        const isVertical = direction === "vertical"

        let selectedItemIndex = -1

        React.Children.forEach(children, (child, idx) => {
            const isSelected = elementIsSelected(child)
            if (isSelected) {
                selectedItemIndex = idx
            }
            const element = React.Children.toArray(children)[idx + 1]
            const isNextItemSelected = !!children && elementIsSelected(element)
            const dividerSiblingSelected = isSelected || isNextItemSelected

            segmentsWithDividers.push(child)
            const isLastSegment = idx === childCount - 1
            if (isLastSegment) return
            if (isVertical) return
            const divider = (
                <div
                    key={idx + "divider"}
                    className={cx(styles.divider, dividerSiblingSelected && styles.hiddenDivider)}
                />
            )
            segmentsWithDividers.push(divider)
        })

        const itemHeight = large ? dimensions.inputHeightLarge : dimensions.inputHeight

        const selectedItemBackgroundSizeRatio = 1 / (childCount || 1)
        const selectedItemBackgroundSize = `${selectedItemBackgroundSizeRatio * 100}%`
        const selectedItemBackgroundPosition = `${selectedItemIndex * selectedItemBackgroundSizeRatio * 100}%`

        const lastSelectedItemIndex = React.useRef(selectedItemIndex)
        const selectedItemDidChange = selectedItemIndex !== lastSelectedItemIndex.current
        lastSelectedItemIndex.current = selectedItemIndex

        const userInteractedRef = React.useRef(false)
        const clickHandler = React.useCallback(() => {
            userInteractedRef.current = true
        }, [])
        const shouldAnimateSelectedItem = userInteractedRef.current && selectedItemDidChange
        userInteractedRef.current = false

        const selectedItemLengthKey = isVertical ? "height" : "width" // size along the segmented control direction
        const selectedItemPositionKey = isVertical ? "top" : "left"
        // For vertical segmented control we need to calculate the wrapper height
        const wrapperHeight = itemHeight * childCount + 1 * (childCount - 1) // 1px gap between items
        return (
            <div
                ref={forwardedRef}
                className={cx(
                    styles.segmentedControl,
                    enabled === false && styles.segmentedControlDisabled,
                    large && styles.segmentedControlLarge,
                    isVertical && styles.verticalSegmentedControl,
                    className
                )}
                style={{
                    ...style,
                    ...(isVertical ? { height: wrapperHeight } : { height: itemHeight }),
                }}
                {...rest}
                onClick={clickHandler}
            >
                <div className={cx(styles.segmentsWrapper, isVertical && styles.verticalSegmentsWrapper)}>
                    <div
                        className={cx(
                            styles.segmentedControlSegmentBackground,
                            shouldAnimateSelectedItem && styles.segmentedControlSegmentBackgroundAnimated
                        )}
                        style={{
                            display: selectedItemIndex !== -1 ? "" : "none",
                            [selectedItemLengthKey]: selectedItemBackgroundSize,
                            [selectedItemPositionKey]: selectedItemBackgroundPosition,
                        }}
                    />
                    {segmentsWithDividers}
                </div>
            </div>
        )
    })
)

function elementIsSelected(element: unknown) {
    if (!element || typeof element !== "object") return false
    if (!React.isValidElement(element)) return false
    const childProps = element.props as SegmentedControlItemProps<any>
    return childProps.enabled !== false && !!childProps.selected
}
