import { unhandledError } from "@framerjs/shared"

let windowOrGlobal: Window | NodeJS.Global | undefined

if (typeof global !== "undefined") {
    windowOrGlobal = global
}

if (typeof window !== "undefined") {
    windowOrGlobal = window
}

function queueMicrotask(task: Function) {
    Promise.resolve()
        .then(task as () => void)
        .catch(unhandledError)
}

if (windowOrGlobal && !windowOrGlobal["queueMicrotask"]) {
    windowOrGlobal["queueMicrotask"] = queueMicrotask
}
