import { css } from "linaria"
import { colors, dimensions, fonts } from "../tokens"

export const inputWrapper = css`
    position: relative;
    display: flex;
    width: auto;
    height: ${dimensions.inputHeight}px;
    flex-direction: row;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-color: ${colors.inputBorder};
    background: ${colors.inputBackground};
    border-radius: ${dimensions.inputRadius}px;
    color: ${colors.inputIcon};

    :focus-within {
        border-color: ${colors.inputBorderActive};
    }

    input {
        width: 1px;
        flex-grow: 1;
        border: none;
        background: none;

        :focus {
            border: none;
        }
    }
`

export const inputWrapperError = css`
    border-color: ${colors.inputBorderError};
`

export const inputWrapperLarge = css`
    height: ${dimensions.inputHeightLarge}px;

    input {
        height: calc(100% - 2px);
        font-weight: ${fonts.weight.baseActive};
    }
`
