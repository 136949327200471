import Paper from "paper"
import type { WithPaths, WithPath } from "framer"

/**
 * This file contains functions that convert Framer modelled paths into
 * Paperjs modelled equivalents.
 *
 * Note that the methods only concern themselves with the Framer side
 * segment info; for that's all we need at this point to have Paperjs
 * do Boolean calculations.
 */

export function convertWithPathsToPaperPathItem(withPaths: WithPaths, close?: boolean): Paper.PathItem {
    const paths = withPaths.map(path => {
        return convertWithPathToPaperPath(path, close)
    })

    if (paths.length === 1) {
        return paths[0]!
    } else {
        return new Paper.CompoundPath({
            insert: false,
            children: paths,
        })
    }
}

export function convertWithPathToPaperPath({ pathSegments, pathClosed }: WithPath, close?: boolean): Paper.Path {
    const path = new Paper.Path({
        insert: false,
        closed: pathClosed || close,
    })

    pathSegments.forEach(segment => {
        if (segment) {
            path.add(
                new Paper.Segment({
                    point: new Paper.Point(segment.x, segment.y),
                    handleIn: new Paper.Point(segment.handleInX, segment.handleInY),
                    handleOut: new Paper.Point(segment.handleOutX, segment.handleOutY),
                })
            )
        }
    })
    return path
}
