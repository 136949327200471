import type { AnyTreeNode } from "../types"
import type { Checked } from "./exactCheck"

const isInTest = process.env.NODE_ENV === "test"

export interface IdToNodeMap<N> {
    [id: string]: N
}

export function transform<NodeIn extends AnyTreeNode, NodeOut extends Checked<AnyTreeNode>>(
    nodeIn: NodeIn,
    visit: (node: NodeIn, idToNode: IdToNodeMap<NodeIn>) => NodeOut,
    idToNode?: IdToNodeMap<NodeIn>
): NodeOut {
    if (!idToNode) {
        idToNode = collectIdToNodeMap(nodeIn, {})
    }

    // Any mutation to the node should be done on a copy otherwise we may bump into race conditions,
    // we check this only in test environment to catch possible violations
    if (isInTest) {
        Object.freeze(nodeIn)
    }

    let transformedNode = visit(nodeIn, idToNode)

    if (!hasChildren(nodeIn)) return transformedNode
    // We can't assign children if the node points to nodeIn,
    // theoretically this should never happen as all mutation on the node should be done on a copy
    // @ts-ignore-next-line
    if (isInTest && transformedNode === nodeIn) {
        // @ts-ignore-next-line
        transformedNode = { ...transformedNode }
    }

    transformedNode.children = nodeIn.children
        .map((child: NodeIn) => {
            return transform(child, visit, idToNode)
        })
        .filter(child => !!child)

    return transformedNode
}

function hasChildren<T extends AnyTreeNode>(node: T): node is T & { children: T[] } {
    return Array.isArray(node.children) && node.children.length > 0
}

function collectIdToNodeMap<N extends AnyTreeNode>(nodeIn: N, idToNode: IdToNodeMap<N>): IdToNodeMap<N> {
    idToNode[nodeIn.id] = nodeIn
    if (hasChildren(nodeIn)) {
        for (const child of nodeIn.children) {
            collectIdToNodeMap(child, idToNode)
        }
    }
    return idToNode
}
