import * as React from "react"
import { useFocusTrap } from "./utils/useFocusTrap"

export interface FocusTrapProps extends React.ComponentPropsWithoutRef<"div"> {
    focusTrapActive: boolean
}

const FocusTrappedByChildContext = React.createContext<React.Dispatch<React.SetStateAction<boolean>> | null>(null)

/** Makes sure the focus stays within the element or a child focus trap. */
export const FocusTrap = React.forwardRef<HTMLDivElement, FocusTrapProps>(function FocusTrap(
    { focusTrapActive, ...rest },
    forwardedRef
) {
    const fallbackRef = React.useRef<HTMLDivElement>(null)
    const ref = forwardedRef ?? fallbackRef
    const [focusTrappedByChild, setFocusTrappedByChild] = React.useState(false)

    useFocusTrap({ ref, active: focusTrapActive && !focusTrappedByChild })

    // Let ancestor focus trap know when the focus is trapped in descendant
    const setContainsFocus = React.useContext(FocusTrappedByChildContext)
    const containsFocus = focusTrapActive || focusTrappedByChild

    React.useEffect(() => {
        if (!setContainsFocus || !containsFocus) return
        setContainsFocus(true)
        return () => setContainsFocus(false)
    }, [containsFocus, setContainsFocus])

    return (
        <FocusTrappedByChildContext.Provider value={setFocusTrappedByChild}>
            <div ref={ref} tabIndex={0} {...rest} />
        </FocusTrappedByChildContext.Provider>
    )
})
