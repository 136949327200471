import * as React from "react"

export function IconMinus() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
            <path
                d="M 0 4.75 C 0 4.336 0.336 4 0.75 4 L 8.75 4 C 9.164 4 9.5 4.336 9.5 4.75 L 9.5 4.75 C 9.5 5.164 9.164 5.5 8.75 5.5 L 0.75 5.5 C 0.336 5.5 0 5.164 0 4.75 Z"
                fill="currentColor"
            ></path>
        </svg>
    )
}
