import {
    ContentState,
    convertFromRaw,
    Editor,
    EditorProps,
    EditorState,
    RawDraftContentState,
    Modifier,
    SelectionState,
} from "draft-js"
import { safeWindow } from "../../utils/safeWindow"
import * as React from "react"
import { Animatable } from "../../animation/Animatable"
import { deviceFont } from "../../utils/environment"
import { fontStore } from "../fonts/fontStore"
import { draftStyleFunction } from "../text/draft"
import { collectTextShadowsForProps } from "../style/shadow"
import { FilterProperties } from "../traits/Filters"
import {
    calculateRect,
    NewConstraintProperties,
    ParentSize,
    ParentSizeState,
    useParentSize,
} from "../types/NewConstraints"
import { Rect } from "../types/Rect"
import { RenderTarget } from "../types/RenderEnvironment"
import { Shadow } from "../types/Shadow"
import { collectFiltersFromProps } from "../utils/filtersForNode"
import { injectComponentCSSRules } from "../utils/injectComponentCSSRules"
import { ComponentContainerContext } from "./ComponentContainerContext"
import { Layer, LayerProps } from "./Layer"
import { draftBlockRendererFunction } from "./TextBlock"
import { forceLayerBackingWithCSSProperties } from "../utils/setLayerBacked"
import { isFiniteNumber } from "../utils/isFiniteNumber"
import { useLayoutId } from "../utils/useLayoutId"
import { motion, Transition, Variants } from "framer-motion"
import { transformTemplate } from "../utils/transformTemplate"
import { useMeasureLayout } from "../utils/useMeasureLayout"
import { layoutHintDataPropsForCenter } from "../utils/layoutHintDataPropsForCenter"
import { isString } from "../../utils/utils"

/**
 * @internal
 */
export type TextAlignment = "left" | "right" | "center" | undefined

/**
 * @internal
 */
export type TextVerticalAlignment = "top" | "center" | "bottom"

/**
 * @internal
 */
export interface TextProps extends NewConstraintProperties, Partial<FilterProperties> {
    rotation: Animatable<number> | number
    visible: boolean
    name?: string
    /** @deprecated */
    contentState?: any /* ContentState, but api-extractor fails because of it: https://github.com/Microsoft/web-build-tools/issues/949 */
    alignment: TextAlignment
    verticalAlignment: TextVerticalAlignment
    autoSize: boolean
    opacity?: number
    shadows: Readonly<Shadow[]>
    style?: React.CSSProperties
    text?: string
    font?: string
    parentSize?: ParentSize
}

/**
 * @internal
 */
export interface TextProperties extends TextProps, LayerProps {
    rawHTML?: string
    isEditable?: boolean
    fonts?: string[]
    layoutId?: string | undefined
    className?: string
    /** @internal */
    withExternalLayout?: boolean
    /** @internal for testing */
    environment?(): RenderTarget
    /** @internal */
    innerRef?: React.RefObject<HTMLDivElement>
    transition?: Transition
    variants?: Variants
    /** @internal */
    __fromCanvasComponent?: boolean
}

// Before migrating to functional components we need to get parentSize data from context
/**
 * @internal
 */
export function Text(props: Partial<TextProperties>) {
    const parentSize = useParentSize()
    const layoutId = useLayoutId(props)
    const layoutRef = React.useRef<HTMLDivElement>(null)

    useMeasureLayout(props, layoutRef)

    return <TextComponent {...props} innerRef={layoutRef} layoutId={layoutId} parentSize={parentSize} />
}

class TextComponent extends Layer<TextProperties, {}> {
    static supportsConstraints = true
    static defaultTextProps: TextProps = {
        opacity: undefined,
        left: undefined,
        right: undefined,
        top: undefined,
        bottom: undefined,
        _constraints: {
            enabled: true,
            aspectRatio: null,
        },
        rotation: 0,
        visible: true,
        contentState: undefined,
        alignment: undefined,
        verticalAlignment: "top",
        autoSize: true,
        shadows: [],
        font: "16px " + deviceFont(),
    }

    static readonly defaultProps: TextProperties = {
        ...Layer.defaultProps,
        ...TextComponent.defaultTextProps,
        isEditable: false,
        environment: RenderTarget.current,
        withExternalLayout: false,
    }

    editorText: string | undefined
    private editorState: EditorState | null // XXX:DRAFT Remove

    get frame(): Rect | null {
        return calculateRect(this.props, this.props.parentSize || ParentSizeState.Unknown, false)
    }

    // XXX:DRAFT Remove
    private editorStateForContentState(contentState?: ContentState | RawDraftContentState) {
        if (contentState) {
            if (!(contentState instanceof ContentState)) {
                contentState = convertFromRaw(contentState)
            }
            return EditorState.createWithContent(contentState)
        } else {
            return EditorState.createEmpty()
        }
    }

    // XXX:DRAFT Remove
    UNSAFE_componentWillReceiveProps(nextProps: TextProperties) {
        if (nextProps.contentState !== this.props.contentState) {
            this.editorState = this.editorStateForContentState(nextProps.contentState)
        }
    }

    getOverrideText() {
        const { id, _forwardedOverrides } = this.props
        if (id && _forwardedOverrides) {
            const text = _forwardedOverrides[id]
            if (isString(text)) {
                return text
            }
        }
    }

    render() {
        // Refactor to use React.useContext()
        return <ComponentContainerContext.Consumer>{this.renderMain}</ComponentContainerContext.Consumer>
    }

    private collectLayout(style: React.CSSProperties) {
        if (this.props.withExternalLayout) return

        const frame = this.frame
        const { rotation, autoSize } = this.props
        const rotate = Animatable.getNumber(rotation)

        if (frame && RenderTarget.hasRestrictions()) {
            Object.assign(style, {
                transform: `translate(${frame.x}px, ${frame.y}px) rotate(${rotate.toFixed(4)}deg)`,
                // Using “auto” fixes wrapping problems where our size calculation does not work out well when zooming the
                // text (due to rendering differences).
                width: autoSize ? "auto" : `${frame.width}px`,
                minWidth: `${frame.width}px`,
                height: `${frame.height}px`,
            })
        } else {
            const { left, right, top, bottom, width: externalWidth, height: externalHeight } = this.props

            let width: number | string | undefined
            let height: number | string | undefined
            if (autoSize) {
                width = "auto"
                height = "auto"
            } else {
                if (!isFiniteNumber(left) || !isFiniteNumber(right)) {
                    width = externalWidth
                }
                if (!isFiniteNumber(top) || !isFiniteNumber(bottom)) {
                    height = externalHeight
                }
            }

            Object.assign(style, {
                left,
                right,
                top,
                bottom,
                width,
                height,
                rotate,
            })
        }
    }

    /** Used by the ComponentContainerContext */
    private renderMain = (isCodeComponentChild: boolean) => {
        if (process.env.NODE_ENV !== "production" && safeWindow["perf"]) safeWindow["perf"].nodeRender()

        const {
            font,
            fonts: fontSelectors,
            visible,
            alignment,
            autoSize,
            willChangeTransform,
            opacity,
            id,
            layoutId,
            className,
            transition,
            variants,
            name,
            innerRef,
            __fromCanvasComponent,
        } = this.props
        const frame = this.frame

        if (!visible) {
            return null
        }
        if (fontSelectors) {
            fontStore.loadWebFontsFromSelectors(fontSelectors)
        }

        injectComponentCSSRules()

        // We want to hide the Text component underneath the TextEditor when editing.
        const isHidden = this.props.isEditable && this.props.environment!() === RenderTarget.canvas

        const justifyContent = convertVerticalAlignment(this.props.verticalAlignment)

        // Add more styling and support vertical text alignment
        const style: React.CSSProperties = {
            wordWrap: "break-word",
            outline: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: justifyContent,
            opacity: isHidden ? 0 : opacity,
            flexShrink: 0,
        }

        const dataProps = {
            "data-framer-component-type": "Text",
            "data-framer-name": name,
        }

        if (autoSize) {
            dataProps["data-framer-component-text-autosized"] = "true"
        }

        this.collectLayout(style)

        collectFiltersFromProps(this.props, style)
        collectTextShadowsForProps(this.props, style)

        if (style.opacity === 1 || style.opacity === undefined) {
            // Wipe opacity setting if it's the default (1 or undefined)
            delete style.opacity
        }

        if (willChangeTransform) {
            // We're not using Layer.applyWillChange here, because adding willChange:transform causes clipping issues in export
            forceLayerBackingWithCSSProperties(style)
        }

        let rawHTML = this.props.rawHTML
        let contentState = this.props.contentState
        const text = this.getOverrideText() || this.props.text

        if (isString(text)) {
            if (rawHTML) {
                const isRenderedWithRawDraftRendering = rawHTML.indexOf("data-text=") === -1
                rawHTML = isRenderedWithRawDraftRendering
                    ? replaceDraftHTMLWithText(rawHTML, text)
                    : replaceHTMLWithText(rawHTML, text)
            } else if (contentState) {
                contentState = replaceContentStateWithText(contentState, text)
            } else {
                rawHTML = `<p style="font: ${font}">${text}</p>`
            }
        }

        if (this.props.style) {
            Object.assign(style, this.props.style)
        }

        const hasTransformTemplate = !frame || !RenderTarget.hasRestrictions() || __fromCanvasComponent
        if (hasTransformTemplate) {
            Object.assign(dataProps, layoutHintDataPropsForCenter(this.props.center))
        }

        if (rawHTML) {
            style.textAlign = alignment
            style.whiteSpace = "pre-wrap"
            style.wordWrap = "break-word"
            style.lineHeight = "1px"
            style.fontSize = "0px"

            return (
                <motion.div
                    layoutId={layoutId}
                    id={id}
                    {...dataProps}
                    style={style}
                    transformTemplate={hasTransformTemplate ? transformTemplate(this.props.center) : undefined}
                    dangerouslySetInnerHTML={{ __html: rawHTML! }}
                    data-center={this.props.center}
                    className={className}
                    transition={transition}
                    variants={variants}
                    ref={innerRef}
                />
            )
        }

        // XXX:DRAFT Remove rest of function

        if (!this.editorState || this.editorText !== text) {
            this.editorText = text
            this.editorState = this.editorStateForContentState(contentState)
        }

        return (
            <motion.div
                layoutId={layoutId}
                id={id}
                {...dataProps}
                transformTemplate={hasTransformTemplate ? transformTemplate(this.props.center) : undefined}
                data-center={this.props.center}
                style={style}
                className={className}
                transition={transition}
                variants={variants}
                ref={innerRef}
            >
                <Editor
                    editorState={this.editorState}
                    onChange={this.onChange}
                    readOnly
                    customStyleFn={draftStyleFunction}
                    blockRendererFn={this.blockRendererFn}
                    textAlignment={alignment}
                />
            </motion.div>
        )
    }

    // XXX:DRAFT Remove
    private blockRendererFn: EditorProps["blockRendererFn"] = block => {
        return draftBlockRendererFunction({ editable: false, alignment: this.props.alignment })(block)
    }

    // XXX:DRAFT Remove
    private onChange = (_: EditorState) => {
        // NOOP
    }
}

function replaceHTMLWithText(rawHTML: string, text: string): string {
    const orig = rawHTML.split('<span data-text="true">')
    return orig[0] + '<span data-text="true">' + text + "</span></span>"
}

// This regular expression will capture the first <span> with attributes that it finds, and
// keep capturing until the last </span> tag that it finds. If we assume that the first and
// last <span> elements are on the same level in the DOM tree, this will work fine.
const textContentRegex = /(<span [^>]+>).*<\/span>/s
function replaceDraftHTMLWithText(rawHTML: string, text: string): string {
    return rawHTML.replace(textContentRegex, (_, span) => span + text + "</span>")
}

// XXX:DRAFT Remove
function replaceContentStateWithText(contentState: ContentState | RawDraftContentState, text: string): ContentState {
    // The presentation tree render path passes down RawDraftContentState and
    // the canvas render path provides ContentState so we need to handle both.
    if (!(contentState instanceof ContentState)) {
        contentState = convertFromRaw(contentState)
    }

    const updatedContentState = ContentState.createFromText(text)
    const firstBlock = contentState.getFirstBlock()
    if (!firstBlock || firstBlock.getLength() === 0) {
        return updatedContentState
    }

    const initialStyles = firstBlock.getInlineStyleAt(0)
    const selectionState = new SelectionState({
        anchorKey: updatedContentState.getFirstBlock().getKey(),
        anchorOffset: 0,
        focusKey: updatedContentState.getLastBlock().getKey(),
        focusOffset: updatedContentState.getLastBlock().getLength(),
    })

    return initialStyles.reduce(
        (nextContentState: ContentState, inlineStyle: string) =>
            Modifier.applyInlineStyle(nextContentState, selectionState, inlineStyle),
        updatedContentState
    )
}

function convertVerticalAlignment(verticalAlignment: TextVerticalAlignment): "center" | "flex-start" | "flex-end" {
    switch (verticalAlignment) {
        case "top":
            return "flex-start"
        case "center":
            return "center"
        case "bottom":
            return "flex-end"
    }
}
