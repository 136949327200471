export type Range = [number, number]

export function isRangeOverlappingRange(a: Range, b: Range) {
    a = getSortedRange(a)
    b = getSortedRange(b)
    return Math.max(a[0], b[0]) < Math.min(a[1], b[1])
}

export function getRangeForCharacterIndex(index: number): Range {
    return [index, index + 1]
}

// NOTE: Only exported for testing
export function isIndexInRange(index: number, range: Range) {
    return index >= Math.min(...range) && index <= Math.max(...range)
}

function isIndexInRanges(index: number, ranges: Range[]) {
    for (const range of ranges) {
        if (isIndexInRange(index, range)) return true
    }
    return false
}

// NOTE: Only exported for testing
export function isRangeCovered(range: Range, ranges: Range[]) {
    if (!ranges.length) return false
    for (let index = range[0]; index <= range[1]; index++) {
        if (isIndexInRanges(index, ranges)) continue
        return false
    }
    return true
}

export function getSortedRange(range: Range): Range {
    return [Math.min(...range), Math.max(...range)]
}

export function isRangeCollapsed(range: Range) {
    return range[0] === range[1]
}
