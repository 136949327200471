import type { AnyNodeUpdate, CanvasNode, CanvasTree } from ".."
import type { Variable, VariableID } from "../traits/Variables"
import type { VariableReference } from "../traits/VariableReference"

import { ControlType } from "framer"
import { isVariableReference } from "../traits/VariableReference"
import { isTextNode } from ".."
import { withCodeComponentProps } from "../traits/CodeComponent"
import { prefixControlKey } from "../traits/utils/codeComponentProps"
import { isCodeComponentNode } from "../nodes/CodeComponentNode"
import { allVariableKeys } from "./allVariableKeys"
import { frameEventKeys, withFrameEvents } from "../traits/FrameEvents"
import { isTriggerEventAction } from "../actions/triggerEventActions"
import { isReplicaOrReplicaChild } from "../traits/Template"
import { TemplateHelper } from "../nodes/TemplateHelper"
import { isArray, isString } from "utils/typeChecks"
import { EventAction } from "document/models/EventAction"

/**
 * Get any Variable IDs referenced by a node. This requires checking any node
 * which supports actions to see if any action is a triggerEvent action.
 */
export function getUsedVariableReferenceIds(node: CanvasNode): VariableID[] {
    const result: VariableID[] = []

    if (!node.supportsVariables()) return result

    if (withCodeComponentProps(node)) {
        const controlProps = node.getControlProps()
        for (const propKey in controlProps) {
            const control = controlProps[propKey]
            const value = control?.value
            if (value && isVariableReference(value)) {
                result.push(value.id)
            } else if (control?.type === ControlType.EventHandler) {
                if (!isArray(value) || value.length === 0) continue

                for (const eventAction of value) {
                    if (isTriggerEventAction(eventAction) && eventAction.controls.id?.value) {
                        result.push(eventAction.controls.id.value)
                    }
                }
            }
        }
    }

    if (withFrameEvents(node)) {
        for (const actionKey of frameEventKeys) {
            const value = node[actionKey]
            if (!isArray(value) || value.length === 0) continue

            for (const eventAction of value) {
                if (isTriggerEventAction(eventAction) && eventAction.controls.id?.value) {
                    result.push(eventAction.controls.id.value)
                }
            }
        }
    }

    forEachVariable(node, (_, value) => result.push(value.id))

    return result
}

/**
 * Cleanup nodes that reference Variables that have been deleted by setting
 * VariableReferences back to values, or removing triggerEvent actions.
 */
export function cleanupVariableReferencesWithValues(
    tree: CanvasTree,
    node: CanvasNode,
    variableValueMap: Map<VariableID, Variable["initialValue"]>
) {
    if (!node.supportsVariables()) return

    const update: Partial<Record<keyof AnyNodeUpdate, unknown>> = {}

    if (isCodeComponentNode(node)) {
        let hasCodeComponentChanges = false

        // Iterate over each property of a code component node, performing a
        // callback if the value is a variable, or removing triggerEventActions.
        const controlProps = node.getControlProps()
        for (const propKey in controlProps) {
            const controlProp = controlProps[propKey]
            if (controlProp && isVariableReference(controlProp.value) && variableValueMap.has(controlProp.value.id)) {
                hasCodeComponentChanges = true
                update[prefixControlKey(propKey)] = {
                    ...controlProp,
                    value: variableValueMap.get(controlProp.value.id),
                }
            } else if (controlProp?.type === ControlType.EventHandler) {
                const value = controlProp.value
                if (!isArray(value) || value.length === 0) continue

                hasCodeComponentChanges = true

                const newActions = filterVariableTriggerEventActions(value, variableValueMap)
                if (newActions.length !== value.length) {
                    controlProp.value = newActions
                    updateOrResetAction(tree, node, newActions.length === 0, controlProp, prefixControlKey(propKey))
                }
            }
        }

        // Nodes are cloned with their cache, so since we cache code component
        // props aggressively, we need to reset the cache here to ensure the
        // cache is properly rebuilt with our changes when the node is inserted
        // into the tree.
        if (hasCodeComponentChanges) node.cache.clearCodeComponentProps()
    }

    if (withFrameEvents(node)) {
        for (const actionKey of frameEventKeys) {
            const value = node[actionKey]
            if (!isArray(value) || value.length === 0) continue

            const newActions = filterVariableTriggerEventActions(value, variableValueMap)
            if (newActions.length !== value.length) {
                updateOrResetAction(tree, node, newActions.length === 0, newActions, actionKey)
            }
        }
    }

    // Iterate over the subset of node properties of a non-component node that
    // could be a variable, performing a callback if the valuable actually is a
    // variable.
    forEachVariable(node, (key, variable) => {
        if (variableValueMap.has(variable.id)) {
            update[key] = variableValueMap.get(variable.id)
        }
    })

    // Styled text content variable is a separate property from the actual text content value
    if (isTextNode(node) && isString(update.textContent)) {
        update.styledText = node.styledText.withUpdatedText(update.textContent)
        update.textContent = undefined
    }

    if (Object.keys(update).length > 0) node.set(update)
}

function forEachVariable<T>(node: T, callback: (key: keyof T, value: VariableReference) => void): void {
    for (const key of allVariableKeys) {
        const value = node[key]

        // This cast should be safe since we check that the key exists in the node.
        if (key in node && isVariableReference(value)) callback(key as keyof T, value)
    }
}

function filterVariableTriggerEventActions(
    actions: unknown[],
    variables: Map<VariableID, Variable["initialValue"]>
): EventAction[] {
    return actions.filter((eventAction): eventAction is EventAction => {
        if (!isTriggerEventAction(eventAction)) return true
        const variableId = eventAction.controls.id?.value
        return !variableId || !variables.has(variableId)
    })
}

function updateOrResetAction(
    tree: CanvasTree,
    node: CanvasNode,
    unset: boolean,
    updatedActions: unknown,
    actionKey: string
): void {
    if (unset && isReplicaOrReplicaChild(node)) {
        const replica = TemplateHelper.getReplicaForTemplateNode(tree, node)
        if (replica) TemplateHelper.resetOverridesByProperty(tree, replica, node, [actionKey])
    } else if (unset) {
        node.set({ [actionKey]: undefined })
    } else {
        node.set({ [actionKey]: updatedActions })
    }
}
