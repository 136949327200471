import { useLayoutEffect, useContext, RefObject } from "react"

import { RenderTarget } from "../types/RenderEnvironment"
import { runtime } from "../../utils/runtimeInjection"
import { nodeIdFromString } from "./nodeIdFromString"
import { ComponentContainerContext } from "../presentation/ComponentContainerContext"

// Returns all children that need to be considered for layout measurements of
// this node. This is usually used to skip over invisible, or border elements
// since they don't contribute to the layout of the node. Elements returned from
// this function will also be used in calculating the content size for "Fit Content".
type GetChildrenFn = (element: Element) => Element[]

/**
 * Adds the element and its children to the layout measure queue
 *
 * @internal
 */
export function useMeasureLayout(
    props: { id?: string; visible?: boolean; _needsMeasure?: boolean },
    ref: RefObject<Element | null>,
    getChildren: GetChildrenFn = () => [],
    options: { skipHook?: boolean } = {}
) {
    const { id, visible, _needsMeasure } = props
    const { skipHook = false } = options
    const inCodeComponent = Boolean(useContext(ComponentContainerContext))
    const onCanvas = RenderTarget.current() === RenderTarget.canvas

    useLayoutEffect(() => {
        // must be on the canvas, not in a code component, and must not be
        // explicitly skipped through skipHook
        if (!onCanvas || inCodeComponent || skipHook) {
            return
        }

        // must have a valid ref, id, be visible and need measure
        if (!(ref.current && id && visible && _needsMeasure)) {
            return
        }

        runtime.queueMeasureRequest(nodeIdFromString(id), ref.current, getChildren(ref.current))
    })
}
