import type { CanvasNode } from "document/models/CanvasTree"

export interface WithCodeOverride {
    codeOverrideEnabled: boolean
    codeOverrideIdentifier: string | undefined
    codeOverrideFile: string | undefined
    codeOverrideName: string | undefined
}

export const codeOverrideDefaults: WithCodeOverride = {
    codeOverrideEnabled: false,
    codeOverrideIdentifier: undefined,
    codeOverrideFile: undefined,
    codeOverrideName: undefined,
}

const key: keyof WithCodeOverride = "codeOverrideIdentifier"

export function withCodeOverride(node: CanvasNode): node is CanvasNode & WithCodeOverride {
    return key in node
}
interface ReducedOverrides {
    codeOverrideEnabled?: boolean | null
    codeOverrideIdentifier?: string | null
    codeOverrideFiles: string[]
    codeOverrideName?: string | null
}

export function reduceOverride(node: CanvasNode, result: ReducedOverrides) {
    if (!withCodeOverride(node)) return

    if (node.codeOverrideFile && !result.codeOverrideFiles.includes(node.codeOverrideFile)) {
        result.codeOverrideFiles.push(node.codeOverrideFile)
    }

    if (result.codeOverrideEnabled === undefined) {
        result.codeOverrideEnabled = node.codeOverrideEnabled
        result.codeOverrideIdentifier = node.codeOverrideIdentifier
        result.codeOverrideName = node.codeOverrideName
        return
    }
    if (result.codeOverrideEnabled !== null && node.codeOverrideEnabled !== result.codeOverrideEnabled) {
        result.codeOverrideEnabled = null
    }
    if (result.codeOverrideIdentifier !== null && node.codeOverrideIdentifier !== result.codeOverrideIdentifier) {
        result.codeOverrideIdentifier = null
    }
    if (result.codeOverrideName !== null && node.codeOverrideName !== result.codeOverrideName) {
        result.codeOverrideName = null
    }
}
