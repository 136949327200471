import { List, Record } from "immutable"
import { ConvertColor } from "framer"
import { withClassDiscriminator } from "utils/withClassDiscriminator"

interface GradientColorStopInterface {
    value: string
    position: number
}

const gradientDefaults: GradientColorStopInterface = {
    value: "black",
    position: 0,
}

export const GradientColorStopRecord = Record(gradientDefaults)

export class GradientColorStop extends withClassDiscriminator("GradientColorStop", GradientColorStopRecord)
    implements GradientColorStopInterface {
    value: string
    position: number

    toJS() {
        const JS = super.toJS()
        JS["__class"] = this.__class
        return JS
    }

    toJSON() {
        return this.toJS()
    }
}

export type GradientColorStops = List<GradientColorStop>

export function isColorStops(value: any): value is GradientColorStops {
    return isList(value) && value.every(stop => stop instanceof GradientColorStop)
}

export function equalColorStops(a: GradientColorStops, b: GradientColorStops) {
    return (
        a.count() === b.count() &&
        a.every((stop: GradientColorStop, idx: number) => {
            const otherStop = b.get(idx)

            // Shared colors should only be equal if the value is equal as ConvertColor will return true
            if (stop.value.startsWith("var(") || otherStop.value.startsWith("var(")) {
                return stop.value === otherStop.value
            }

            const equalValues = stop.value === otherStop.value || ConvertColor.equals(stop.value, otherStop.value)
            return equalValues && stop.position === otherStop.position
        })
    )
}

function isList(value: any): value is List<any> {
    return List.isList(value)
}
