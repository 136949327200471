import { experiments } from "app/experiments"

// TODO: Remove entire file when experiment is over.

// This file will be loaded on both the editor and sandbox sides, but the actual
// experiment value only matters on the editor side. The editor will then
// explicitly pass the current value of the experiment to the sandbox when it initializes.
let _isDOMLayoutEnabled = experiments.isOn("domLayoutStack")

export function isDOMLayoutEnabled() {
    return _isDOMLayoutEnabled
}

export function setDOMLayoutEnabled(enabled: boolean) {
    _isDOMLayoutEnabled = enabled
}
