import { draftStyleDefinitions, TextDirection } from "framer"
import { ContentBlock, ContentState, DraftInlineStyle, EditorState, Modifier, SelectionState } from "draft-js"
import { OrderedSet, Map } from "immutable"

export const singleTextStyles = ["BOLD", "ITALIC"]
export const additiveTextStyles = [
    draftStyleDefinitions.textTransform.prefix,
    draftStyleDefinitions.textDecoration.prefix,
]

export const hasStylePrefix = (set: OrderedSet<string> | string[], prefix: string) =>
    set.some((s: string) => s.startsWith(prefix))

function getEditorText(editor: EditorState) {
    return editor.getCurrentContent().getPlainText()
}

export function getEditorWithUpdatedText(editorState: EditorState, text: string) {
    const content = editorState.getCurrentContent()
    const block = content.getFirstBlock()
    const blockLength = block.getLength()
    let style = block.getData().get("emptyStyle")
    if (!style && blockLength > 0) {
        style = block.getInlineStyleAt(blockLength - 1)
    }
    return EditorState.createWithContent(
        Modifier.replaceText(content, getEditorFullSelection(editorState), text, style)
    )
}

export function getPatchedEditor(from: EditorState, to: EditorState, reference: EditorState): EditorState {
    if (getEditorText(from) !== getEditorText(to) && getEditorText(from) === getEditorText(reference)) {
        return getEditorWithUpdatedText(reference, getEditorText(to))
    }

    const fromStyles: string[] = []
    if (from) {
        const content = from.getCurrentContent()
        const styles = content.getFirstBlock().getInlineStyleAt(0)
        styles.forEach(s => {
            if (s) fromStyles.push(s)
        })
    }

    const toStyles: string[] = []
    if (to) {
        const content = to.getCurrentContent()
        const styles = content.getFirstBlock().getInlineStyleAt(0)
        styles.forEach(s => {
            if (s) toStyles.push(s)
        })
    }

    const referenceStyles: string[] = []
    if (reference) {
        const content = reference.getCurrentContent()
        const styles = content.getFirstBlock().getInlineStyleAt(0)
        styles.forEach(s => {
            if (s) referenceStyles.push(s)
        })
    }

    toStyles.forEach(style => {
        if (style === "SELECTED") return
        if (fromStyles.indexOf(style) >= 0) return
        // changed
        let colon = style.indexOf(":")
        if (colon < 0) {
            colon = style.length
        }

        const key = style.slice(0, colon)
        const fromStyle = fromStyles.find(s => s.startsWith(key))
        const referenceStyle = referenceStyles.find(s => s.startsWith(key))
        if (fromStyle === referenceStyle) {
            const value = style.slice(colon)
            reference = getUpdatedEditorWithStyle(reference, referenceStyles, key, value, false)
        }
    })

    const singleStyles = singleTextStyles
    singleStyles.forEach(style => {
        if (fromStyles.indexOf(style) < 0) return
        if (referenceStyles.indexOf(style) < 0) return
        if (toStyles.indexOf(style) >= 0) return
        reference = getUpdatedEditorWithToggledStyle(reference, referenceStyles, style, false)
    })

    // Clear the styles removed from the primary component
    const additiveStyles = additiveTextStyles
    additiveStyles.forEach(style => {
        if (!hasStylePrefix(fromStyles, style)) return
        if (!hasStylePrefix(referenceStyles, style)) return
        if (hasStylePrefix(toStyles, style)) return
        reference = getUpdatedEditorWithStyle(reference, referenceStyles, style, undefined, false)
    })
    return reference
}

export function getEditorFullSelection(editorState: EditorState): SelectionState {
    const contentState = editorState.getCurrentContent()
    return new SelectionState({
        anchorKey: contentState.getFirstBlock().getKey(),
        anchorOffset: 0,
        focusKey: contentState.getLastBlock().getKey(),
        focusOffset: contentState.getLastBlock().getLength(),
    })
}

export function getUpdatedEditorWithStyle(
    editorState: EditorState,
    currentStyles: string[],
    prefix: string,
    style: string | undefined,
    currentSelection: boolean
): EditorState {
    const prefixedStyle = `${prefix}${style}`
    let contentState = editorState.getCurrentContent()
    const editorSelection = editorState.getSelection()
    const selection = currentSelection ? editorSelection : getEditorFullSelection(editorState)
    const blockKeys = getBlockKeysInSelection(contentState, selection)

    contentState = getBlockWithUpdatedEmptyStyle(contentState, blockKeys, prefix, style)

    let styleOverride: DraftInlineStyle | undefined
    if (selection.isCollapsed()) {
        styleOverride = OrderedSet(
            currentStyles.filter(currentStyle => {
                return currentStyle !== undefined && !currentStyle.startsWith(prefix)
            })
        )

        // clear the current style if the style is set to undefined
        styleOverride = style === undefined ? styleOverride.clear() : styleOverride.add(prefixedStyle)
    } else {
        currentStyles.forEach(currentStyle => {
            if (currentStyle && currentStyle.startsWith(prefix)) {
                contentState = Modifier.removeInlineStyle(contentState, selection, currentStyle)
            }
        })

        // If the value of the style is undefined, we clear the style by only removing the current style and not applying new style,
        // since keeping `[prefix]:undefined` won't be valuable.
        // This could happen e.g. when text transform is set to "none".
        if (style !== undefined) {
            contentState = Modifier.applyInlineStyle(contentState, selection, prefixedStyle)
        }
    }

    return getUpdatedEditorAfterStyling(editorState, contentState, editorSelection, styleOverride)
}

export function getUpdatedEditorWithToggledStyle(
    editorState: EditorState,
    styles: string[],
    style: string,
    currentSelection: boolean
): EditorState {
    const currentStyle = OrderedSet<string>(styles)
    let contentState = editorState.getCurrentContent()
    const editorSelection = editorState.getSelection()
    const selection = currentSelection ? editorSelection : getEditorFullSelection(editorState)

    const blockKeys = getBlockKeysInSelection(contentState, selection)
    if (currentStyle.includes(style)) {
        contentState = removeBlockEmptyStyle(contentState, blockKeys, style)
    } else {
        contentState = addBlockEmptyStyle(contentState, blockKeys, style)
    }

    let styleOverride: DraftInlineStyle | undefined
    if (selection.isCollapsed()) {
        if (currentStyle.includes(style)) {
            styleOverride = currentStyle.remove(style)
        } else {
            styleOverride = currentStyle.add(style)
        }
    } else {
        if (currentStyle.includes(style)) {
            contentState = Modifier.removeInlineStyle(contentState, selection, style)
        } else {
            contentState = Modifier.applyInlineStyle(contentState, selection, style)
        }
    }

    return getUpdatedEditorAfterStyling(editorState, contentState, editorSelection, styleOverride)
}

function getUpdatedEditorAfterStyling(
    editorState: EditorState,
    contentState: ContentState,
    selection: SelectionState,
    styleOverride?: DraftInlineStyle
): EditorState {
    let newEditorState = EditorState.push(editorState, contentState, "change-inline-style")
    newEditorState = EditorState.acceptSelection(newEditorState, selection)
    if (styleOverride !== undefined) {
        newEditorState = EditorState.setInlineStyleOverride(newEditorState, styleOverride)
    }
    return newEditorState
}

function getBlockKeysInSelection(contentState: ContentState, selection: SelectionState) {
    if (selection.isCollapsed()) {
        return selection.getAnchorOffset() === 0 ? [selection.getAnchorKey()] : []
    } else {
        const startKey = selection.getStartKey()
        const endKey = selection.getEndKey()
        const blockMap = contentState.getBlockMap()
        const keys = blockMap
            .keySeq()
            .skipUntil(key => key === startKey)
            .takeUntil(key => key === endKey)
            .concat(endKey)
        return (selection.getAnchorOffset() === 0 ? keys : keys.rest()).toArray()
    }
}

function getBlockWithUpdatedEmptyStyle(
    contentState: ContentState,
    blockKeys: string[],
    prefix: string,
    style?: string
) {
    // NOTE: We have to special cases these. When you have your caret in a
    // decorated text range and remove decoration, you expect the next inserted
    // character to _not_ have decoration.
    if (prefix === "TEXTTRANSFORM:" && !style) style = "none"
    if (prefix === "TEXTDECORATION:" && !style) style = "none"

    const prefixedStyle = `${prefix}${style}`

    blockKeys.forEach(blockKey => {
        const block = contentState.getBlockForKey(blockKey)
        getBlockEmptyStyle(block).forEach(emptyStyle => {
            if (emptyStyle && emptyStyle.startsWith(prefix)) {
                contentState = removeBlockEmptyStyle(contentState, [blockKey], emptyStyle)
            }
        })
    })
    // If style is undefined, just remove the emptyStyle.
    if (style !== undefined) {
        contentState = addBlockEmptyStyle(contentState, blockKeys, prefixedStyle)
    }
    return contentState
}

export function getBlockEmptyStyle(block: ContentBlock): DraftInlineStyle {
    return block.getData().get("emptyStyle") || OrderedSet()
}

export function setBlockEmptyStyle(block: ContentBlock, style: DraftInlineStyle): ContentBlock {
    return block.merge({ data: block.getData().merge({ emptyStyle: style }) }) as ContentBlock
}

export function addBlockEmptyStyle(contentState: ContentState, blockKeys: string[], style: string) {
    return addOrRemoveBlockEmptyStyle(contentState, blockKeys, style, true)
}

export function removeBlockEmptyStyle(contentState: ContentState, blockKeys: string[], style: string) {
    return addOrRemoveBlockEmptyStyle(contentState, blockKeys, style, false)
}

function addOrRemoveBlockEmptyStyle(
    contentState: ContentState,
    blockKeys: string[],
    style: string,
    addOrRemove: boolean
) {
    if (blockKeys.length === 0) {
        return contentState
    }

    const updatedBlocks = contentState
        .getBlockMap()
        .filter((block: ContentBlock) => blockKeys.indexOf(block.getKey()) !== -1)
        .map((block: ContentBlock) => {
            const currentStyle = getBlockEmptyStyle(block)
            const updatedStyle = addOrRemove ? currentStyle.add(style) : currentStyle.remove(style)
            return setBlockEmptyStyle(block, updatedStyle)
        })
    return contentState.merge({ blockMap: contentState.getBlockMap().merge(updatedBlocks) }) as ContentState
}

function getBlockTextDirection(block: ContentBlock): TextDirection {
    return block.getData().get("direction")
}

function setBlockTextDirection(block: ContentBlock, direction: TextDirection): ContentBlock {
    const data =
        direction === undefined ? block.getData().remove("direction") : block.getData().set("direction", direction)
    return block.merge({ data }) as ContentBlock
}

/**
 * Provides an opportunity to update the styles for the entire text or
 * just the current selection (via `onlySelection`) for the style prefix
 * provided.
 *
 * The callback should return either a new value or the original if it
 * should be unchanged. Returning `undefined` will remove the style
 * entirely.
 */
export function getEditorWithAdjustedStyle(
    editorState: EditorState,
    styles: string[],
    prefix: string,
    updateValue: (current?: string) => string | undefined,
    onlySelection: boolean = false
): EditorState {
    const currentSelection = editorState.getSelection()
    let newContentState = editorState.getCurrentContent()

    // Special case
    if (onlySelection && currentSelection.isCollapsed()) {
        const currentStyle = styles.find((style: string) => style.startsWith(prefix))
        let currentValue = undefined
        if (currentStyle !== undefined) {
            currentValue = currentStyle.slice(prefix.length)
        }
        const newValue = updateValue(currentValue)
        return getUpdatedEditorWithStyle(editorState, styles, prefix, newValue, true)
    }

    const content = editorState.getCurrentContent()
    let inSelection = false

    content.getBlocksAsArray().forEach(contentBlock => {
        // Filtering on selection (if needed)
        let minStartIndex = NaN
        let maxEndIndex = NaN
        if (onlySelection) {
            if (!inSelection) {
                if (contentBlock.getKey() === currentSelection.getStartKey()) {
                    inSelection = true
                    minStartIndex = currentSelection.getStartOffset()
                } else {
                    return
                }
            }
            if (inSelection) {
                // Not an “else”, can happen immediately
                if (contentBlock.getKey() === currentSelection.getEndKey()) {
                    maxEndIndex = currentSelection.getEndOffset()
                }
            }
        }

        let foundStyle: string | undefined
        contentBlock.findStyleRanges(
            characterMetaData => {
                // const styles = characterMetaData.getStyle()
                foundStyle = undefined
                characterMetaData.getStyle().forEach((style: string) => {
                    if (style.startsWith(prefix)) {
                        foundStyle = style
                        return false // Stops forEach iteration
                    }
                })
                return true
            },
            (startIndex: number, endIndex: number) => {
                if (!isNaN(minStartIndex)) {
                    if (minStartIndex > endIndex) {
                        return
                    } else {
                        startIndex = Math.max(minStartIndex, startIndex)
                    }
                }
                if (!isNaN(maxEndIndex)) {
                    if (startIndex > maxEndIndex) {
                        return
                    } else {
                        endIndex = Math.min(maxEndIndex, endIndex)
                    }
                }

                const selection = new SelectionState({
                    anchorKey: contentBlock.getKey(),
                    anchorOffset: startIndex,
                    focusKey: contentBlock.getKey(),
                    focusOffset: endIndex,
                })

                let currentValue: undefined | string

                if (foundStyle !== undefined) {
                    // Remove old style, if used
                    newContentState = Modifier.removeInlineStyle(newContentState, selection, foundStyle)
                    currentValue = foundStyle.slice(prefix.length)
                }

                const newValue = updateValue(currentValue)
                if (newValue !== undefined) {
                    // Add new style
                    newContentState = Modifier.applyInlineStyle(newContentState, selection, `${prefix}${newValue}`)
                }
            }
        )

        // Update the block empty style
        let firstFoundValue: string | undefined
        const newContentBlock = newContentState.getBlockForKey(contentBlock.getKey())
        newContentBlock.getInlineStyleAt(0).forEach((style: string) => {
            if (style.startsWith(prefix)) {
                firstFoundValue = style.slice(prefix.length)
                return false
            }
        })
        if (firstFoundValue !== undefined) {
            newContentState = getBlockWithUpdatedEmptyStyle(
                newContentState,
                [contentBlock.getKey()],
                prefix,
                firstFoundValue
            )
        }
        // this.stylesPrefixedWith(prefix, false)

        if (!isNaN(maxEndIndex)) {
            inSelection = false
        }
    })

    let newEditorState = EditorState.push(editorState, newContentState, "change-inline-style")
    newEditorState = EditorState.acceptSelection(newEditorState, currentSelection)
    return newEditorState
}

export function ensureBlockEmptyStyles(
    contentState: ContentState,
    emptyBlockStyle?: OrderedSet<string>,
    defaultStyleOverride?: OrderedSet<string>
): ContentState {
    let updatedBlocks = Map<string, ContentBlock>()
    let styleIfMissing = emptyBlockStyle || defaultStyleOverride || OrderedSet()

    contentState.getBlockMap().forEach((block: ContentBlock, key: string) => {
        const blockLength = block.getLength()
        if (block.getData().get("emptyStyle") === undefined) {
            const emptyStyle = blockLength === 0 ? styleIfMissing : block.getInlineStyleAt(0)
            updatedBlocks = updatedBlocks.set(key, setBlockEmptyStyle(block, emptyStyle))
        }
        if (blockLength > 0) {
            styleIfMissing = block.getInlineStyleAt(blockLength - 1)
        }
    })

    if (updatedBlocks.count() > 0) {
        return contentState.merge({ blockMap: contentState.getBlockMap().merge(updatedBlocks) }) as ContentState
    } else {
        return contentState
    }
}

// Matches BidiDirection type from https://github.com/facebook/fbjs/blob/master/packages/fbjs/src/unicode/UnicodeBidiDirection.js
type BidiDirection = "LTR" | "RTL" | "NEUTRAL"

function bidiDirectionToTextDirection(direction?: BidiDirection): TextDirection {
    if (direction === "LTR") return "ltr"
    if (direction === "RTL") return "rtl"
    return undefined
}

export function ensureBlockBidiData(
    contentState: ContentState,
    directionMap: Map<string, BidiDirection>
): ContentState {
    let updatedBlocks = Map<string, ContentBlock>()

    contentState.getBlockMap().forEach((block: ContentBlock, key: string) => {
        const direction = bidiDirectionToTextDirection(directionMap.get(key))
        if (getBlockTextDirection(block) !== direction) {
            updatedBlocks = updatedBlocks.set(key, setBlockTextDirection(block, direction))
        }
    })

    if (updatedBlocks.count() > 0) {
        return contentState.merge({ blockMap: contentState.getBlockMap().merge(updatedBlocks) }) as ContentState
    } else {
        return contentState
    }
}
