import { variableName } from "./variables"
import type { ColorTokenMap, ColorTokenKey } from "../colors/colorTypes"
import { colorsScope } from "../colors/colorsScope"
import type { Theme } from "../../components/types"
import { colorsLight } from "../colors/colorsLight"
import { colorsDark } from "../colors/colorsDark"

const getColorTokenMap = (theme: Theme): ColorTokenMap => (theme === "light" ? colorsLight : colorsDark)
const buildResultCSS = (result: Record<string, string>): string =>
    Object.entries(result)
        .map(([key, value]) => `${key}: ${value};`)
        .join("\n")

export type ThemeOverrideMode = "graphics" | "component" | "popover" | "dashboard" | "prototype-view"

/**
 * Updates tint colors with the given color tokens.
 * Each similar value in the color map will also be compared and replaced with the new specified override value.
 *
 * ```ts
 * themeTintOverride("dark", {
 *    tint: "graphicTint",
 *    tintDark: "graphicTint",
 *    tintDimmed: "graphicTintDimmed",
 *    layerItemIconDimmed: "layerItemIconGraphicDimmed",
 * })
 * ```
 */
export function themeTintOverride(theme: Theme, overrides: Partial<Record<ColorTokenKey, ColorTokenKey>>): string {
    const tokenMap = getColorTokenMap(theme)
    const replaceMap: Record<string, string> = {}
    const result: Record<string, string> = {}
    const keys = Object.keys(overrides) as ColorTokenKey[]

    keys.forEach(key => {
        const value = overrides[key]
        if (value) {
            replaceMap[tokenMap[key]] = tokenMap[value]
        }
    })

    const valuesToFind: string[] = Object.keys(replaceMap)

    Object.entries(tokenMap).forEach(([key, value]) => {
        if (valuesToFind.includes(value)) {
            result[variableName(key, colorsScope)] = replaceMap[value]
        }
    })

    return buildResultCSS(result)
}
