export enum BuiltInFramerComponentIdentifier {
    Scroll = "framer/Scroll",
    Page = "framer/Page",
    Stack = "framer/Stack",
    Device = "__builtin/Device",
}

// A "fake" package identifier for components built into the Framer app itself,
// as opposed to those built into the Framer library.
export const BuiltInPackageIdentifier = "__builtin"

export const BUILT_IN_COMPONENT_IDENTIFIERS: string[] = Object.values(BuiltInFramerComponentIdentifier)
