import type { VariableID } from "document/models/CanvasTree/traits/Variables"

/** INLINE VARIABLES */
const INLINE_VARIABLE_WRAPPER = "__VAR__"
const INLINE_VARIABLE_REGEX = /(__VAR__.+?__VAR__)+/
const GLOBAL_INLINE_VARIABLE_REGEX = new RegExp(INLINE_VARIABLE_REGEX.source, "g")
const INLINE_VARIABLES_REGEX = /("__VAR__.+?__VAR__")+/g

export function createInlineVariable(variableId: VariableID) {
    return `${INLINE_VARIABLE_WRAPPER}${variableId}${INLINE_VARIABLE_WRAPPER}`
}

export function isInlineVariable(value: string): boolean {
    return INLINE_VARIABLE_REGEX.test(value)
}

export function unwrapInlineVariable(inlineVariable: string) {
    return inlineVariable.slice(INLINE_VARIABLE_WRAPPER.length, -INLINE_VARIABLE_WRAPPER.length)
}

export function unwrapAndEscapeInlineVariables(template: string) {
    return (
        template
            // Replace values that are inline variables (variables inlined in string values are handled below)
            // e.g. "color": "__VAR__YU7DFcZKn__VAR__" -> "color": YU7DFcZKn
            .replace(INLINE_VARIABLES_REGEX, match =>
                match.slice(INLINE_VARIABLE_WRAPPER.length + 1, -INLINE_VARIABLE_WRAPPER.length - 1)
            )
            /**
             * @FIXME We should find a system to render `hue-rotate(${U7DFcZKn}deg)`,
             * rather than rendering "hue-rotate(" + YU7DFcZKn + "deg)", as it's
             * a bit more idiomatic.
             */
            // Replace inline variables in string values
            // e.g. "filter":"hue-rotate(__VAR__YU7DFcZKn__VAR__deg)"
            // -> "filter":"hue-rotate(" + YU7DFcZKn + "deg)"
            .replace(GLOBAL_INLINE_VARIABLE_REGEX, match => `" + ${unwrapInlineVariable(match)} + "`)
    )
}

/** INLINE ACTIONS */
const INLINE_ACTION_WRAPPER = "__ACTION__"
const INLINE_ACTION_REGEX = /(__ACTION__.+?__ACTION__)+/
const INLINE_ACTIONS_REGEX = /("__ACTION__.+?__ACTION__")+/g

export function isInlineHandler(value: string): boolean {
    return INLINE_ACTION_REGEX.test(value)
}

export function wrapInlineHandler(handler: string) {
    return `${INLINE_ACTION_WRAPPER}${handler}${INLINE_ACTION_WRAPPER}`
}

export function unwrapInlineHandler(inlineAction: string) {
    return inlineAction.slice(INLINE_ACTION_WRAPPER.length, -INLINE_ACTION_WRAPPER.length)
}

export function unwrapAndEscapeInlineHandlers(template: string) {
    return template.replace(INLINE_ACTIONS_REGEX, match =>
        match.slice(INLINE_ACTION_WRAPPER.length + 1, -INLINE_ACTION_WRAPPER.length - 1).replace(/\\/g, "")
    )
}

/** CONTROL TYPES */
const QUOTE_REGEX = /"+/g
const CONTROL_TYPE_REGEX = /("ControlType.+?")+/g

export function escapeControlTypes(object: object) {
    return JSON.stringify(object).replace(CONTROL_TYPE_REGEX, match => match.replace(QUOTE_REGEX, ""))
}
