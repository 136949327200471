import { css } from "linaria"

export const base = css`
    transition: transform 0.1s ease;
`

export const down = css`
    transform: rotate(180deg);
`

export const right = css`
    transform: rotate(-90deg);
`
