import { cx } from "linaria"
import * as React from "react"
import * as classes from "./Tooltip.styles"
import { dimensions } from "../tokens"
import { Text } from "./Text"
import { useState } from "react"
import type { CSSProperties } from "./types"

interface BaseProps {
    tint?: string
    className?: string
    isDisabled?: boolean
    /** Integer in ms to delay the tooltip from being displayed after onMouseEnter is triggered. */
    delay?: number
    children: React.ReactNode
}

interface CustomTooltip extends BaseProps {
    tooltip: React.ReactNode
    text?: never
}

interface TextTooltip extends BaseProps {
    tooltip?: never
    text: string
}

export function TooltipArrow({ className, style }: { className?: string; style?: CSSProperties }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={dimensions.tooltipArrowWidth}
            height={dimensions.tooltipArrowHeight}
            className={className}
            style={style}
        >
            <path
                d="M 12.833 1.333 C 13.451 0.627 14.549 0.627 15.167 1.333 L 18.012 4.585 C 19.911 6.755 22.654 8 25.538 8 L 28 8 L 0 8 L 2.462 8 C 5.346 8 8.089 6.755 9.988 4.585 Z"
                fill="currentColor"
            ></path>
        </svg>
    )
}

export type Props = TextTooltip | CustomTooltip

export const TooltipComponent = React.memo(function TooltipComponent({
    tint,
    tooltip,
    text,
    delay,
    className,
}: Omit<Props, "children">) {
    const style = {} as CSSProperties

    if (delay) {
        style["--delay"] = `${delay}ms`
    }

    if (tint) {
        style.background = tint
    }

    return (
        <div className={cx(classes.tooltip, className)} style={style}>
            <TooltipArrow className={classes.arrow} style={tint ? { color: tint } : {}} />
            {tooltip}
            <Text>{text}</Text>
        </div>
    )
})

export function Tooltip({ children, tooltip, text, isDisabled, tint, delay, className }: Props) {
    const [isHovered, setIsHovered] = useState(false)

    return (
        <div
            className={cx(classes.container, className)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => setIsHovered(false)}
        >
            {children}
            {!isDisabled && isHovered && <TooltipComponent tint={tint} delay={delay} tooltip={tooltip} text={text} />}
        </div>
    )
}
