import type { CanvasTree } from "document/models/CanvasTree"
import type { CanvasNode } from "../nodes/CanvasNode"
import type { IsMaster, IsReplica, WithInheritedOverrides } from "./Template"
import type { Transition } from "document/models/Transition"
import { isMaster, isReplica, isReplicaChild, withInheritedOverrides } from "./Template"
import { Dictionary } from "app/dictionary"

export interface WithVariant {
    isVariant?: boolean
    gesture?: GestureType
    // Only set on primary variant, can be overridden by other variants
    variantTransition?: Transition
}

export interface IsVariant {
    isVariant: true
}

export const variantDefaults = {
    isVariant: undefined,
    gesture: undefined,
}

const variantKey: keyof IsVariant = "isVariant"
export function withVariant(node: CanvasNode): node is CanvasNode & WithVariant {
    return variantKey in node
}

export type GestureType = "hover" | "pressed"
export interface WithGesture {
    gesture: GestureType
}

const gestureVariantKey: keyof WithGesture = "gesture"
export function withGesture(node: CanvasNode): node is CanvasNode & WithGesture {
    return gestureVariantKey in node
}

/**
 * Node is any variant node: Primary, Top Level, or Gesture.
 */
export function isVariant(node: CanvasNode): node is CanvasNode & IsVariant {
    return withVariant(node) && node.isVariant === true
}

export type IsPrimaryVariant = IsMaster & IsVariant
/**
 * Node is the primary variant node.
 * This node's tree is used for the generated canvas component's initial props, and tree.
 */
export function isPrimaryVariant(node: CanvasNode): node is CanvasNode & IsPrimaryVariant {
    return isVariant(node) && isMaster(node)
}

export type IsTopLevelVariant = Exclude<IsVariant, WithGesture>
/**
 * Node is a top level variant, in instances, this is selectable via the canvas component's variant property control.
 */
export function isTopLevelVariant(node: CanvasNode): node is CanvasNode & IsTopLevelVariant {
    if (!isVariant(node)) return false
    return !withGesture(node) || !node.gesture
}

export type IsGestureVariant = IsReplica & IsVariant & WithGesture & WithInheritedOverrides
/**
 * Node is a gesture variant, in instances, it is only visible when the relevant gesture is triggered.
 * This node's tree is ignored, but it's overrides are used to generate the framer-motion variants of the generated code.
 */
export function isGestureVariant(node: CanvasNode): node is CanvasNode & IsGestureVariant {
    return isReplicaVariant(node) && withInheritedOverrides(node) && withGesture(node) && !!node.gesture
}

export function isGestureOrGestureVariantChild(node: CanvasNode): boolean {
    if (isGestureVariant(node)) return true
    if (!isReplicaChild(node)) return false
    for (const ancestor of node.ancestors()) {
        if (isGestureVariant(ancestor)) return true
    }
    return false
}

export function titleForGestureType(gesture: GestureType) {
    return gesture === "hover" ? Dictionary.HOVER : Dictionary.PRESSED
}

/**
 * Node is a non-primary variant.
 */
export function isReplicaVariant(node: CanvasNode): node is CanvasNode & IsVariant & IsReplica {
    return isReplica(node) && isVariant(node)
}

/**
 * Node is a child of a non-primary variant.
 */
export function isReplicaVariantChild(tree: CanvasTree, node: CanvasNode): boolean {
    if (!isReplicaChild(node)) return false
    const groundNode = tree.getGroundNodeFor(node)
    return isReplicaVariant(groundNode)
}
