import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springAnimation, springAnimation3, wait, animationDelay } from "./utils"
import { colors } from "../tokens"

export const AnimationLink = React.memo(function AnimationLink() {
    const buttonControl = useAnimation()
    const pushControl = useAnimation()
    const modalControl = useAnimation()

    async function animation() {
        await wait(animationDelay)

        await Promise.all([
            buttonControl.start({
                x: 0,
                opacity: 1,
                transition: { type: false },
            }),
            pushControl.start({
                x: 30,
                opacity: 1,
                transition: { type: false },
            }),
            modalControl.start({
                scale: 0.5,
                opacity: 0,
                transition: { type: false },
            }),
        ])
        await Promise.all([
            buttonControl.start({
                x: -40,
                opacity: 0,
                transition: springAnimation,
            }),
            pushControl.start({
                x: 0,
                transition: springAnimation,
            }),
        ])
        await Promise.all([
            pushControl.start({
                opacity: 0,
                transition: { ease: "easeInOut" },
            }),
            modalControl.start({
                scale: 1,
                opacity: 1,
                transition: springAnimation3,
            }),
        ])
        await Promise.all([
            pushControl.start({
                opacity: 1,
                transition: { ease: "easeInOut", delay: 0.4 },
            }),
            modalControl.start({
                opacity: 0,
                transition: springAnimation,
            }),
        ])
        return await Promise.all([
            buttonControl.start({
                x: 0,
                opacity: 1,
                transition: springAnimation,
            }),
            pushControl.start({
                x: 40,
                transition: springAnimation,
            }),
        ])
    }

    useLoop(animation)

    return (
        <div
            style={{
                width: 30,
                height: 40,
                borderRadius: 8,
                background: colors.animationForgroundLight,
                overflow: "hidden",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                zIndex: 1,
            }}
        >
            <motion.div
                animate={modalControl}
                style={{
                    borderRadius: 4,
                    top: 9,
                    width: 22,
                    height: 22,
                    background: colors.animationForground,
                    position: "absolute",
                    opacity: 0,
                }}
            />
            <motion.div
                animate={buttonControl}
                style={{
                    borderRadius: 4,
                    margin: "28px 0 0 4px",
                    right: 4,
                    width: 22,
                    height: 8,
                    background: colors.animationForground,
                    position: "absolute",
                }}
            />
            <motion.div
                animate={pushControl}
                style={{
                    width: "120%",
                    height: "100%",
                    background: colors.animationForground,
                    opacity: 0,
                    position: "absolute",
                }}
            />
        </div>
    )
})
