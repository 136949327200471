import type {
    SandboxEntityMap,
    SandboxEntityIdsByPackageIdMap,
    SandboxEntityDefinition,
} from "../componentLoader/types"
import { useOpenURL } from "./useOpenURL"
import { useLog } from "./useLog"
import { useNavigate } from "./useNavigate"
import { getActionControls } from "./actionControls"
import { useSetVariant } from "./useSetVariant"
import { useTriggerEvent } from "./useTriggerEvent"
import type { Action } from "framer"

type DefaultActionKey =
    | "framer/useNavigate"
    | "framer/useOpenURL"
    | "framer/useLog"
    | "framer/useSetVariant"
    | "framer/useTriggerEvent"

const defaultActionKeys: DefaultActionKey[] = [
    "framer/useNavigate",
    "framer/useOpenURL",
    "framer/useLog",
    "framer/useSetVariant",
    "framer/useTriggerEvent",
]

function actionForKey(key: DefaultActionKey): Action<any> {
    switch (key) {
        case "framer/useNavigate":
            return useNavigate
        case "framer/useOpenURL":
            return useOpenURL
        case "framer/useLog":
            return useLog
        case "framer/useSetVariant":
            return useSetVariant
        case "framer/useTriggerEvent":
            return useTriggerEvent
    }
}

const defaultActions: SandboxEntityMap = {}

for (const defaultActionKey of defaultActionKeys) {
    const action = actionForKey(defaultActionKey)
    const actionControls = getActionControls(action)
    if (actionControls && actionControls.title && actionControls.controls) {
        const definition: SandboxEntityDefinition = {
            class: action,
            depth: 1,
            file: "",
            identifier: defaultActionKey,
            name: actionControls.title,
            packageIdentifier: "framer",
            properties: actionControls.controls,
            type: "action",
        }
        defaultActions[defaultActionKey] = definition
    }
}

export function addDefaultActions(entities: SandboxEntityMap, entityIdsByPackage: SandboxEntityIdsByPackageIdMap) {
    Object.assign(entities, defaultActions)

    const packageDefinitions = entityIdsByPackage["framer"] || []
    entityIdsByPackage["framer"] = packageDefinitions

    packageDefinitions.push(...Object.values(defaultActions).map(actionDefinition => actionDefinition.identifier))
}
