import { capitalizeFirstLetter } from "./capitalizeFirstLetter"

/** Chunks a string into meaningful groups of characters (words, acronyms or numbers) */
/** [A-Z]{2,}: Extracts uppercase groups with a minimum length of 2 */
/**            (Min. length of 2 to avoid conflicts with capitalized words) */
/** [A-Z][a-z]+: Extracts capitalized groups */
/** [a-z]+: Extracts lowercase groups */
/** [A-Z]: Extracts uppercase groups with a length of 1 */
/** \d+: Extracts number groups */
const groupsRegex = /[A-Z]{2,}|[A-Z][a-z]+|[a-z]+|[A-Z]|\d+/gu

export function titleCase(value: string): string {
    const groups = value.match(groupsRegex) || []

    return groups.map(group => capitalizeFirstLetter(group)).join(" ")
}
