export function memoRecord<T extends object>(createRecord: () => T) {
    let record: T | undefined

    return () => {
        if (record === undefined) {
            record = createRecord()
        }

        return record
    }
}
