import type { List } from "immutable"
import {
    Size,
    Rect,
    ControlDescription,
    BorderStyle,
    WithFractionOfFreeSpace,
    ComponentContainerProperties,
    DeprecatedVisualProperties,
    LayerProps,
    ImageFit,
    MotionStyle,
    RenderTarget,
} from "framer"
import { ConvertColor } from "framer"
import { DimensionType, ControlType, ConstraintValues } from "framer"
import { isFiniteNumber } from "@framerjs/framer-runtime"
import { CanvasNode } from "./CanvasNode"
import { withPinsSizeRatioConstraints } from "../traits/mixins/withPinsSizeRatioConstraints"
import type { WithRect } from "../traits/Frame"
import type { ExportOptions } from "./ExportOptions"
import type { WithName } from "document/models/CanvasTree/traits/Name"
import type { WithVisibility } from "document/models/CanvasTree/traits/Visibility"
import type { WithLock } from "document/models/CanvasTree/traits/Lock"
import type { WithRotation } from "document/models/CanvasTree/traits/Rotation"
import type { WithExport } from "document/models/CanvasTree/traits/Export"
import { getCodeComponentRecord } from "../records/CodeComponentRecord"
import { updateConstrainedFrame, updateConstrainedSize } from "document/models/ConstraintSolver"
import type {
    WithCodeComponent,
    SlotLink,
    ControlProp,
    WithCodeComponentProps,
    CodeComponentProps,
    ArrayValue,
    ReactComponentDefinitionProvider,
} from "../traits/CodeComponent"
import { FUSEDNUMBER_TOGGLE_KEY } from "../traits/CodeComponent"
import type { WithIntrinsicSize } from "document/models/CanvasTree/traits/IntrinsicSize"
import type { WithCodeOverride } from "../traits/CodeOverride"
import type { NodeID } from "./NodeID"
import type { WithBorder } from "document/models/CanvasTree/traits/Border"
import type { WithBorderPerSide } from "document/models/CanvasTree/traits/BorderPerSide"
import type { WithBoxShadow } from "document/models/CanvasTree/traits/BoxShadow"
import type { BoxShadow } from "document/models/Shadow"
import type { WithOptionalFill, WithFill, FillType } from "document/models/CanvasTree/traits/Fill"
import { borderPropsForNode } from "document/models/CanvasTree/traits/utils/borderForNode"
import type { WithClip } from "document/models/CanvasTree/traits/Clip"
import type { WithRelativeRadius } from "document/models/CanvasTree/traits/Radius"
import type { WithRadiusPerCorner } from "document/models/CanvasTree/traits/RadiusPerCorner"
import type { WithFilters } from "document/models/CanvasTree/traits/Filters"
import {
    HardCodedCodeIdentifier,
    ALL_IDENTIFIERS,
} from "document/models/CanvasTree/traits/utils/hardCodedCodeComponentIdentifiers"
import { collectBackgroundPropsForNode } from "../traits/utils/collectBackgroundForNode"
import { collectNameForNode } from "document/models/CanvasTree/traits/utils/collectNameForNode"
import type { LinearGradient, RadialGradient } from "document/models/Gradient"
import type { PreviewSettings } from "preview-next/PreviewSettings"
import type { WithPreviewSettings } from "../traits/PreviewSettings"
import type { EventActionInfoMap, WithEventActions } from "../traits/EventActions"
import { CodeComponentNodeCache } from "./CodeComponentNodeCache"
import { setDefaults } from "./MutableNode"
import { getAssetResolver } from "renderer/getAssetResolver"
import { withClassDiscriminator } from "utils/withClassDiscriminator"
import {
    prefixControlProps,
    isValidSlotControlProp,
    isCodeComponentProp,
    propWithoutControlPrefix,
    prefixControlKey,
} from "../traits/utils/codeComponentProps"
import { CONTROL_PREFIX } from "../traits/CodeComponent"
import { isValidFusedNumberValue } from "document/components/chrome/properties/codeComponentRows/utils/isValidPropertyValue"
import { collectOpacity, collectRotate, collectStyle } from "../traits/collectStyles"
import { isVariant } from "../traits/Variant"
import type { VariableReference } from "../traits/VariableReference"
import { isVariableReference } from "../traits/VariableReference"
import type { WithOpacity } from "../traits/Opacity"
import { Attribute } from "../utils/buildJSX"
import { TagProps } from "variants/types"
import { attributesFromProps } from "variants/utils/attributesFromProps"
import type { WithClearResolvedCache } from "../traits/ClearResolvedCache"
import { createInlineVariable } from "variants/utils/inlineValues"
import { isString, isObject, isUndefined } from "utils/typeChecks"
import { generatedComponentIdentifier } from "variants/GeneratedComponent"
import { isTokenCSSVariable } from "./TokenNode"
import { withDOMLayoutTraits } from "document/models/CanvasTree/traits/mixins/withDOMLayoutTraits"
import { localCanvasComponentId } from "./utils/canvasComponentInstanceHelpers"
import { VariableID } from "../traits/Variables"
import { getLogger } from "@framerjs/shared"
import { experiments } from "app/experiments"

const log = getLogger("CodeComponentNode")

const stylableCodeComponents = new Set<string>([
    HardCodedCodeIdentifier.stack,
    HardCodedCodeIdentifier.page,
    HardCodedCodeIdentifier.scroll,
])

export function isCodeComponentNode(node: CanvasNode): node is CodeComponentNode {
    return node instanceof CodeComponentNode
}

const noCustomCodeComponentProps: { [key: string]: unknown } = {}
Object.freeze(noCustomCodeComponentProps)

// eslint-disable-next-line import/no-default-export
export default class CodeComponentNode
    extends withClassDiscriminator("CodeComponentNode", withDOMLayoutTraits(withPinsSizeRatioConstraints(CanvasNode)))
    implements
        WithRect,
        WithName,
        WithPreviewSettings,
        WithVisibility,
        WithLock,
        WithIntrinsicSize,
        WithOpacity,
        WithRotation,
        WithExport,
        WithOptionalFill,
        WithFill,
        WithClip,
        WithBorder,
        WithBorderPerSide,
        WithRelativeRadius,
        WithRadiusPerCorner,
        WithBoxShadow,
        WithFilters,
        WithCodeComponent,
        WithCodeComponentProps,
        WithCodeOverride,
        WithEventActions,
        WithClearResolvedCache {
    cache: CodeComponentNodeCache

    name: string | null
    visible: boolean | VariableReference
    locked: boolean
    previewSettings: PreviewSettings | null

    rotation: number | VariableReference
    opacity: number | VariableReference
    clip: boolean

    intrinsicWidth: number | null
    intrinsicHeight: number | null

    exportOptions: List<ExportOptions>

    codeComponentIdentifier: string
    /** @deprecated This field is not used anywhere but removing it would require a document version bump */
    codeComponentPackageVersion: string | null = null

    codeOverrideEnabled: boolean
    codeOverrideIdentifier: string | undefined
    codeOverrideFile: string | undefined
    codeOverrideName: string | undefined

    fillEnabled: boolean
    fillType: FillType
    fillColor: string | VariableReference
    fillLinearGradient: LinearGradient | undefined
    fillRadialGradient: RadialGradient | undefined
    fillImage: string | VariableReference | null
    fillImageOriginalName: string | null
    fillImageResize: ImageFit
    fillImagePixelWidth: number | null
    fillImagePixelHeight: number | null

    blur: number | VariableReference | undefined
    backgroundBlur: number | VariableReference | undefined
    brightness: number | VariableReference | undefined
    contrast: number | VariableReference | undefined
    grayscale: number | VariableReference | undefined
    hueRotate: number | VariableReference | undefined
    invert: number | VariableReference | undefined
    saturate: number | VariableReference | undefined
    sepia: number | VariableReference | undefined

    radiusPerCorner: boolean
    radius: number | VariableReference
    radiusIsRelative: boolean
    radiusTopLeft: number
    radiusTopRight: number
    radiusBottomLeft: number
    radiusBottomRight: number

    borderEnabled: boolean | undefined
    borderColor: string | undefined
    borderWidth: number | undefined
    borderStyle: BorderStyle | undefined
    borderPerSide: boolean | undefined
    borderTop: number | undefined
    borderRight: number | undefined
    borderBottom: number | undefined
    borderLeft: number | undefined

    boxShadows: Readonly<BoxShadow[]> | undefined

    constructor(properties?: Partial<CodeComponentNode>, cache: CodeComponentNodeCache = new CodeComponentNodeCache()) {
        super(undefined, cache)
        // TODO: Pass this in via super()
        setDefaults<CodeComponentNode>(this, getCodeComponentRecord(), properties)
        delete this.children // See comment in `MutableNode.ts`
    }

    updateForRect(frame: Rect, parentSize: Size | null, constraintsLocked: boolean) {
        return updateConstrainedFrame(
            frame,
            parentSize,
            this.constraints(),
            this.constraintsLocked || constraintsLocked,
            // pixelAlign
            !this.usesDOMRectCached()
        )
    }

    updateForSize(size: Partial<Size>, parentSize: Size | null): Partial<ConstraintValues> {
        return updateConstrainedSize(size, parentSize, this.constraintValues())
    }

    getRawChildrenSlotValues(): SlotLink[] {
        const childrenSlot = this.getControlProp("children")
        if (!childrenSlot) return []
        if (!isValidSlotControlProp(childrenSlot)) return []
        const { type, value: children } = childrenSlot
        if (type === ControlType.ComponentInstance) return [children[0]] // Should return max 1

        return children
    }

    getComponentChildren(): CanvasNode[] {
        if (!this.cache.latest) return []

        const tree = this.tree()
        if (!tree) return []

        const scopeNode = tree.getScopeNodeFor(this)
        if (scopeNode === null) return []

        const children = this.getRawChildrenSlotValues()
        const result: CanvasNode[] = []
        for (let i = 0, il = children.length; i < il; i++) {
            const child = children[i]
            if (!child) continue
            if (typeof child.value === "string") {
                const node = tree.getNode(child.value)
                if (node === null) continue
                if (isVariant(node)) continue
                const parent = tree.get(node.parentid)
                if (parent === null) continue
                if (parent.id === scopeNode.id) result.push(node)
            }
        }

        return result
    }

    getComponentSlotChildren(
        componentDefinitionProvider: ReactComponentDefinitionProvider
    ): { [key: string]: CanvasNode[] } {
        const result: { [key: string]: CanvasNode[] } = {}
        const slotKeys = this.getSlotKeys(componentDefinitionProvider)

        for (const slotKey of slotKeys) {
            const slotControlProp = this.getControlProp(slotKey)
            if (!slotControlProp) continue
            const values = slotControlProp.value as ArrayValue[]
            result[slotKey] = this.getNodesForSlotValues(values)
        }

        return result
    }

    getControlProps(): CodeComponentProps {
        const cachedValue = this.cache.getControlProps(this.update)
        if (cachedValue) return cachedValue

        const controlProps: CodeComponentProps = {}
        for (const key in this) {
            if (isCodeComponentProp(key)) {
                controlProps[propWithoutControlPrefix(key)] = (this[key] as unknown) as ControlProp
            }
        }

        return this.cache.setControlProps(this.update, controlProps)
    }

    getControlProp(propKey: string): ControlProp | undefined {
        return this[`${CONTROL_PREFIX}${propKey}`]
    }

    resolveAssetValue(value: unknown): string | undefined | null {
        if (!isString(value) && !isUndefined(value)) return null

        let resolvedValue = value
        const assetResolver = getAssetResolver()
        if (assetResolver) {
            resolvedValue = assetResolver(value, {
                isFramerResourceURL: true,
                isExport: RenderTarget.current() === RenderTarget.export,
            })
        }

        return resolvedValue
    }

    // Process the value passed in if needed (depends on the control type), and collect it into the result.
    // controlProp is needed only for collecting the FusedNumber toggle value.
    collectCodeComponentPropValue(
        key: string,
        property: ControlDescription,
        result: { [key: string]: unknown },
        value: unknown,
        options: { controlProp?: ControlProp } = {}
    ) {
        switch (property.type) {
            case ControlType.File:
            case ControlType.Image: {
                if (this.getLocalCanvasComponentNodeId() === null) {
                    const resolvedValue = this.resolveAssetValue(value)
                    if (resolvedValue === null) break
                    result[key] = resolvedValue
                } else {
                    result[key] = value
                }
                break
            }
            case ControlType.FusedNumber: {
                // map fusedNumber control value to the value keys
                if (!isObject(value) || value === null) break
                if (!isValidFusedNumberValue(value)) break

                const { controlProp } = options
                result[key] = value.single
                result[property.toggleKey as string] =
                    controlProp && FUSEDNUMBER_TOGGLE_KEY in controlProp ? controlProp[FUSEDNUMBER_TOGGLE_KEY] : false
                property.valueKeys.forEach((fusedNumberKey, idx) => {
                    result[fusedNumberKey as string] = value.fused[idx]
                })
                break
            }
            case ControlType.Array: {
                if (!Array.isArray(value)) break
                const arrayType = property.control.type
                if (arrayType === ControlType.ComponentInstance) {
                    result[key] = this.getNodesForSlotValues(value).map(node => node.id)
                } else {
                    const list: unknown[] = []
                    result[key] = list
                    for (const arrayValue of value as ArrayValue[]) {
                        let resolvedValue = arrayValue.value
                        if (arrayType === ControlType.File || arrayType === ControlType.Image) {
                            const assetResolver = getAssetResolver()
                            if (assetResolver && typeof arrayValue.value === "string") {
                                resolvedValue = this.resolveAssetValue(arrayValue.value)
                            }
                        }
                        list.push(resolvedValue)
                    }
                    if (isFiniteNumber(property.maxCount)) {
                        result[key] = (result[key] as unknown[]).slice(0, property.maxCount)
                    }
                }
                break
            }
            case ControlType.ComponentInstance: {
                if (!Array.isArray(value)) break
                result[key] = this.getNodesForSlotValues(value).map(node => node.id)
                break
            }
            case ControlType.EventHandler:
                // Handled using getActions
                break
            case ControlType.Color: {
                /**
                 * @TODO Canvas Components can only animate between variants
                 * when colors are all in the same color space, so for those
                 * code components, color picker values need to be converted
                 * to RGB(A). Because we don't have access to the
                 * ModulesStore in a node, the only way to check if the node
                 * is a Canvas Component is to reverse engineer the
                 * identifer and see if that matches the identifier we would
                 * have generated for it. If it matches, we convert the
                 * value into RGB. We may want to consider doing this in
                 * some other way, such as limiting the color picker output
                 * to RGB.
                 */
                let colorValue = value
                // @FIXME: this line needs to be updated when we introduce remote modules
                const canvasComponentNodeId = this.getLocalCanvasComponentNodeId()
                if (
                    isString(value) &&
                    !!canvasComponentNodeId &&
                    generatedComponentIdentifier(canvasComponentNodeId) === this.codeComponentIdentifier &&
                    !isTokenCSSVariable(value)
                ) {
                    colorValue = ConvertColor.toRgbString(value)
                }
                result[key] = colorValue
                break
            }
            case ControlType.Object: {
                if (!isObject(value)) {
                    // Fallback so user code won't have to deal with an undefined object
                    result[key] = {}
                    break
                }

                const objectControlValues: { [key: string]: unknown } = {}

                Object.entries(property.controls).forEach(([objectKey, subControl]) => {
                    const subControlProp = value[objectKey] as ControlProp | undefined
                    if (subControlProp) {
                        this.collectCodeComponentPropValue(
                            objectKey,
                            subControl,
                            objectControlValues,
                            subControlProp.value
                        )
                    }
                })

                result[key] = objectControlValues

                break
            }
            default:
                result[key] = value
        }
    }

    getCustomCodeComponentProps(
        componentDefinitionProvider: ReactComponentDefinitionProvider
    ): { [key: string]: unknown } {
        const componentDefinition = componentDefinitionProvider.reactComponentForIdentifier(
            this.codeComponentIdentifier
        )
        if (!componentDefinition) return noCustomCodeComponentProps
        if (!componentDefinition.properties) return noCustomCodeComponentProps

        const controlProps = this.getControlProps()

        const cachedResult = this.cache.getCustomCodeComponentProps(controlProps, componentDefinition.properties)
        if (cachedResult) {
            return cachedResult
        }

        const customProps: { [key: string]: unknown } = {}

        for (const key in componentDefinition.properties) {
            // Children are handled using a seperate flow
            if (key === "children") continue

            const property = componentDefinition.properties[key] as ControlDescription
            const controlProp = controlProps[key]
            if (!controlProp) {
                customProps[key] = undefined
                continue
            }

            const { value } = controlProp
            if (isVariableReference(value)) {
                customProps[key] = value
            } else {
                this.collectCodeComponentPropValue(key, property, customProps, value, { controlProp })
            }
        }

        if (ALL_IDENTIFIERS.includes(this.codeComponentIdentifier)) {
            customProps.__fromCodeComponentNode = true
        }

        this.cache.updateCustomCodeComponentProps({
            controls: componentDefinition.properties,
            controlProps,
            result: customProps,
        })

        return customProps
    }

    getResolvedCustomCodeComponentProps(
        componentDefinitionProvider: ReactComponentDefinitionProvider,
        withInlineVariables: boolean = false
    ) {
        const customProps = this.getCustomCodeComponentProps(componentDefinitionProvider)

        const cachedResult = this.cache.getResolvedCustomCodeComponentProps(customProps, withInlineVariables)
        if (cachedResult) {
            return this.mapVariableProps(componentDefinitionProvider, cachedResult)
        }

        const componentDefinition = componentDefinitionProvider.reactComponentForIdentifier(
            this.codeComponentIdentifier
        )
        const result = { ...customProps }

        // Resolve all variable references
        for (const propKey in result) {
            const property = componentDefinition?.properties[propKey]
            const value = result[propKey]
            if (isVariableReference(value)) {
                if (withInlineVariables) {
                    result[propKey] = this.cache.hasVariable(value.id) ? createInlineVariable(value.id) : undefined
                } else {
                    const variableValue = this.cache.getVariableValue(value.id)
                    const controlProp = this.getControlProp(propKey)
                    if (property && controlProp) {
                        this.collectCodeComponentPropValue(propKey, property, result, variableValue)
                    } else {
                        result[propKey] = variableValue
                    }
                }
            }
        }

        this.cache.updateResolvedCustomCodeComponentProps(
            { customCodeComponentProps: customProps, result },
            withInlineVariables
        )

        return this.mapVariableProps(componentDefinitionProvider, result)
    }

    /**
     * If a CodeComponentNode is a Generated Component instance, its custom
     * properties are keyed by the VariableID used on the Variant Canvas -->
     * Record<VariableID, Control>. However, to render this instance we need to
     * render the component with the human-readable React properties that it
     * expects. Here, we use a mapping provided via an annotation if it is
     * provided to map the resolve code component props, to the actual React
     * prop names.
     */
    mapVariableProps<T extends {}>(componentLoader: ReactComponentDefinitionProvider, resolvedCustomProps: T): T {
        const mappedResolvedCustomProps = {} as T
        const propertyVariables = this.getHumanReadableReactPropMap(componentLoader)

        if (propertyVariables === null || Object.keys(propertyVariables).length === 0) {
            return resolvedCustomProps
        }

        for (const key in resolvedCustomProps) {
            const mappedKey = this.getHumanReadableKey(propertyVariables, key)
            mappedResolvedCustomProps[mappedKey] = resolvedCustomProps[key]
        }

        return mappedResolvedCustomProps
    }

    getHumanReadableKey(propertyVariables: Record<VariableID, string>, key: string) {
        return key in propertyVariables ? propertyVariables[key] : key
    }

    // TODO: cache code component props
    getCodeComponentProps(componentDefinitionProvider: ReactComponentDefinitionProvider) {
        const codeComponentProps = {
            ...this.getResolvedCustomCodeComponentProps(componentDefinitionProvider),
        }

        if (!experiments.isOn("deprecatedCodeComponentSizeWrapper")) {
            // Just taking this.width and this.height is not enough, because
            // they won't update directly when the parentSize updates.
            const rect = this.rect(this.cache.parentSize)

            if (rect) {
                codeComponentProps.width = rect.width
                codeComponentProps.height = rect.height
            }
        }

        if (this.isStylable()) {
            Object.assign(codeComponentProps, this.getStylingProps())
        }

        return codeComponentProps
    }

    getStylingProps() {
        const stylingProps: any = {}
        const componentStyle: React.CSSProperties = {}

        const collectStyleTarget: DeprecatedVisualProperties = {}
        collectBackgroundPropsForNode(this, collectStyleTarget, true)
        // We do not collect opacity and rotation because it is already applied
        // to the container (see CodeComponentNode.getProps())
        collectStyle(this, componentStyle, {}, false)
        stylingProps.background = collectStyleTarget.background
        stylingProps._border = borderPropsForNode(this)
        stylingProps.style = componentStyle
        return stylingProps
    }

    getNodesForSlotValues(values: ArrayValue[]) {
        const result: CanvasNode[] = []
        if (!Array.isArray(values)) return result

        const tree = this.tree()

        const scopeNode = tree.getScopeNodeFor(this)
        if (scopeNode === null) return result

        for (const arrayItem of values) {
            const id = arrayItem.value
            if (typeof id !== "string") continue
            const node = tree.getNode(id)
            if (node === null) continue
            if (isVariant(node)) continue
            const parent = tree.get(node.parentid)
            if (parent === null) continue
            if (parent.id === scopeNode.id) result.push(node)
        }

        return result
    }

    getProps(): Partial<ComponentContainerProperties> & LayerProps {
        const style: MotionStyle = {}
        collectOpacity(this, style)
        collectRotate(this, style)
        const props: Partial<ComponentContainerProperties> = {
            ...super.getProps(),
            ...this.newConstraintProperties(),
            componentIdentifier: this.codeComponentIdentifier,
            visible: this.resolveValue("visible"),
            style,
        }

        if (this.__unsafeIsGroundNode()) {
            props.left = 0
            props.top = 0
        }

        collectNameForNode(this, props)

        return props
    }

    getSlotKeys(componentDefinitionProvider: ReactComponentDefinitionProvider) {
        const slotKeys = this.slotDictionary(componentDefinitionProvider)
        return slotKeys ? [...slotKeys.single, ...slotKeys.multi] : []
    }

    slotTitleForKey(componentDefinitionProvider: ReactComponentDefinitionProvider, key: string): string {
        const componentDefinition = componentDefinitionProvider.reactComponentForIdentifier(
            this.codeComponentIdentifier
        )
        if (!componentDefinition) return key
        if (!componentDefinition.properties) return key
        const property = componentDefinition.properties[key]
        if (!property) return key
        return property.title || key
    }

    slotDictionary(
        componentDefinitionProvider: ReactComponentDefinitionProvider
    ): { single: string[]; multi: string[] } | null {
        const componentDefinition = componentDefinitionProvider.reactComponentForIdentifier(
            this.codeComponentIdentifier
        )
        if (!componentDefinition) return null
        const { properties } = componentDefinition
        if (!properties) return null

        const result: { single: string[]; multi: string[] } = { single: [], multi: [] }

        const propKeys = Object.keys(properties)

        for (let i = 0, il = propKeys.length; i < il; i++) {
            const key = propKeys[i]
            const property = properties[key]
            if (!property) continue
            switch (property.type) {
                case ControlType.ComponentInstance:
                    result.single.push(key)
                    break
                case ControlType.Array:
                    if (property.control.type === ControlType.ComponentInstance) {
                        result.multi.push(key)
                    }
                    break
            }
        }

        return result
    }

    // Only returns a slot key if there is a single one that has content
    singleSlotWithContent(componentDefinitionProvider: ReactComponentDefinitionProvider) {
        const slots = this.slots(componentDefinitionProvider)
        const slotKeys = Object.keys(slots)
        let slotWithContent: string | undefined
        for (const slotKey of slotKeys) {
            const values = this.slots[slotKey]
            if (values.length === 0) continue
            if (slotWithContent) {
                return null
            } else {
                slotWithContent = slotKey
            }
        }
        return slotWithContent || null
    }

    slots(componentDefinitionProvider: ReactComponentDefinitionProvider): { [key: string]: NodeID[] } {
        const result: { [key: string]: NodeID[] } = {}

        const allSlotKeys = this.getSlotKeys(componentDefinitionProvider)

        const tree = this.tree()

        const scopeNode = tree.getScopeNodeFor(this)
        if (scopeNode === null) return result

        for (const key of allSlotKeys) {
            const prop = this.getControlProp(key)
            if (!prop || !prop.type || ![ControlType.ComponentInstance, ControlType.Array].includes(prop.type)) {
                continue
            }
            const values = prop.value
            result[key] = []
            if (!values || !Array.isArray(values)) {
                continue
            }
            for (let j = 0, jl = values.length; j < jl; j++) {
                const arrayItem = values[j]
                if (!arrayItem) {
                    continue
                }

                const nodeId = arrayItem.value
                if (typeof nodeId !== "string") {
                    continue
                }

                const node = tree.get(nodeId)
                if (node === null) {
                    continue
                }

                const isAncestor = tree.isAncestorOfNode(node, scopeNode.id)
                if (isAncestor) result[key].push(nodeId)
            }
        }

        return result
    }

    allSlotContent(componentDefinitionProvider: ReactComponentDefinitionProvider): NodeID[] {
        const slots = this.slots(componentDefinitionProvider)
        const slotKeys = Object.keys(slots)
        const result: NodeID[] = []
        for (const slotKey of slotKeys) {
            const values = slots[slotKey]
            result.push(...values)
        }
        return result
    }

    removeNodeFromSlotContent(id: NodeID, componentDefinitionProvider: ReactComponentDefinitionProvider) {
        // return early if there is nothing to update for this code component
        const allSlotContent = this.allSlotContent(componentDefinitionProvider)
        if (!allSlotContent.includes(id)) return

        const codeComponentProps = { ...this.getControlProps() }
        const slots = this.slots(componentDefinitionProvider)
        let updated = false

        for (const slotKey in slots) {
            // eslint-disable-next-line no-prototype-builtins
            if (!slots.hasOwnProperty(slotKey) || !slots[slotKey].includes(id)) {
                continue
            }
            const slotProp = codeComponentProps[slotKey]
            if (!slotProp || !Array.isArray(slotProp.value)) {
                continue
            }
            codeComponentProps[slotKey] = {
                ...slotProp,
                value: slotProp.value.filter((item: ArrayValue) => item.value !== id),
            }
            updated = true
        }

        if (updated) {
            const tree = this.tree()
            if (tree && this.id) {
                const update = prefixControlProps(codeComponentProps)
                tree.update(this.id, update)
            }
        }
    }

    providesFreeSpace(): boolean {
        return false
        // return isStackComponent(this)
    }

    isStylable(): boolean {
        return stylableCodeComponents.has(this.codeComponentIdentifier)
    }

    framerComponent(): boolean {
        return ALL_IDENTIFIERS.includes(this.codeComponentIdentifier)
    }

    hasAutoSize(): boolean {
        return false
        // return isStackComponent(this)
    }

    autoSize(_minSize: Size) {
        // if (isStackComponent(this) && this.children) {
        //     const minChildrenSizes = this.children.map(child => toMinSize(child, minSize))
        //     const invisibleItems = invisibleChildIndexes(this)
        //     return autoSize(this, minChildrenSizes, invisibleItems)
        // }
        return null
    }

    minSize(parentSize: Size | null): Size {
        let autoSize: Size | null = null
        if ((this.widthType === DimensionType.Auto || this.heightType === DimensionType.Auto) && this.children) {
            const minSize = super.minSize(parentSize)
            autoSize = this.autoSize(minSize)
        }

        return ConstraintValues.toMinSize(this.constraintValues(), parentSize, autoSize)
    }

    size(parentSize: Size | null, freeSpace: WithFractionOfFreeSpace | null, allowCache = true): Size {
        const { parentDirectedRect } = this.cache
        if (parentDirectedRect && allowCache) {
            return { width: parentDirectedRect.width, height: parentDirectedRect.height }
        }

        let autoSize: Size | null = null
        if ((this.widthType === DimensionType.Auto || this.heightType === DimensionType.Auto) && this.children) {
            const minSize = super.minSize(parentSize)
            autoSize = this.autoSize(minSize)
        }

        return ConstraintValues.toSize(this.constraintValues(), parentSize, autoSize, freeSpace)
    }

    rect(parentSize: Size | null, pixelAlign = true): Rect {
        if (this.usesDOMRectCached()) {
            const domRect = this.getDOMRect()
            if (domRect) return domRect
        }

        if (this.cache.parentDirectedRect) {
            return { ...this.cache.parentDirectedRect }
        }

        let autoSize: Size | null = null
        if ((this.widthType === DimensionType.Auto || this.heightType === DimensionType.Auto) && this.children) {
            const minSize = this.minSize(parentSize)
            autoSize = this.autoSize(minSize)
        }

        return ConstraintValues.toRect(this.constraintValues(), parentSize, autoSize, pixelAlign)
    }

    getActions(componentDefinitionProvider: ReactComponentDefinitionProvider): EventActionInfoMap {
        const componentDefinition = componentDefinitionProvider.reactComponentForIdentifier(
            this.codeComponentIdentifier
        )
        if (!componentDefinition) return {}
        if (!componentDefinition.properties) return {}

        const actions: EventActionInfoMap = {}
        const keys = Object.keys(componentDefinition.properties)
        for (let i = 0, il = keys.length; i < il; i++) {
            const key = keys[i]

            const control = componentDefinition.properties[key]
            if (!control || control.type !== ControlType.EventHandler) continue

            const controlProp = this.getControlProp(key)
            if (!controlProp) continue
            const { value } = controlProp
            if (!Array.isArray(value) || value.length === 0) continue
            actions[key] = value.map(eventAction => ({
                identifier: eventAction.actionIdentifier,
                info: { ...eventAction.controls },
            }))
        }

        return this.mapVariableProps(componentDefinitionProvider, actions)
    }

    resetActions(componentDefinitionProvider: ReactComponentDefinitionProvider): void {
        /**
         * @TODO
         * No need for the component definition here since the control props
         * have a type.
         */
        const componentDefinition = componentDefinitionProvider.reactComponentForIdentifier(
            this.codeComponentIdentifier
        )
        if (!componentDefinition) return
        if (!componentDefinition.properties) return
        const updates = {}
        for (const key in componentDefinition.properties) {
            const control = componentDefinition.properties[key]
            if (!control || control.type !== ControlType.EventHandler) continue
            updates[prefixControlKey(key)] = undefined
        }
        this.set(prefixControlProps(updates))
    }

    getAttributes(defaultProps: Partial<TagProps>): Attribute[] {
        const props: TagProps = {
            layoutId: this.id,
            style: {},
            ...defaultProps,
        }

        collectRotate(this, props.style)
        collectOpacity(this, props.style)

        return attributesFromProps(props)
    }

    getHumanReadableReactPropMap(componentLoader: ReactComponentDefinitionProvider) {
        const definition = componentLoader.reactComponentForIdentifier(this.codeComponentIdentifier)
        if (!definition) return null

        const { annotations } = definition
        if (!annotations) return null

        return propertyVariablesFromAnnotations(annotations) ?? null
    }

    clearResolvedCache() {
        this.cache.clearResolvedCodeComponentProps()
    }

    getLocalCanvasComponentNodeId(): string | null {
        return localCanvasComponentId(this.codeComponentIdentifier)
    }
}

function propertyVariablesFromAnnotations(annotations: Record<string, string>): Record<VariableID, string> | undefined {
    const stringifiedVariables = annotations["framervariables"]
    if (!stringifiedVariables) return undefined

    try {
        return JSON.parse(stringifiedVariables)
    } catch (err) {
        log.reportError(err)
        return undefined
    }
}
