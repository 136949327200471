import { rgba } from "../../tokens/utils/rgba"
import type { ColorTokenMap } from "./colorTypes"
import { palette } from "./palette"

const tint = palette.blue60
const tintDark = palette.blue70
const tintDimmed = rgba(palette.blue60, 0.1)
const componentTint = palette.purple100
const componentTintDark = palette.purple140
const componentTintDimmed = rgba(palette.purple100, 0.1)
const componentTintLight = palette.purple50
const errorTint = palette.red100
const errorTintDimmed = rgba(palette.red100, 0.1)
const warningTint = palette.yellow110
const warningTintDimmed = rgba(palette.yellow110, 0.2)
const graphicTint = palette.purple60
const graphicTintDimmed = rgba(palette.purple60, 0.15)
const panelText = palette.dark50
const panelTextSelected = palette.white100
const panelBackground = palette.white100
const popoverBackground = palette.white100
const inputBackground = palette.light58
const inputBackgroundHover = palette.light60
const inputBackgroundActive = palette.light70
const inputBorder = "transparent"
const inputBorderActive = tint
const inputText = palette.dark80
const inputTextDisabled = palette.light90
const inputPlaceholder = palette.dark20
const buttonText = palette.dark80
const beta = palette.purple125

export const colorsLight: ColorTokenMap = {
    tint: tint,
    tintDark: tintDark,
    tintDimmed: tintDimmed,
    componentTint: componentTint,
    componentTintDark: componentTintDark,
    componentTintDimmed: componentTintDimmed,
    componentTintDimmedDrag: rgba(palette.purple100, 0.2),
    componentTintDimmedActive: rgba(palette.purple100, 0.3),
    componentTintLight: componentTintLight,
    errorTint: errorTint,
    errorTintDimmed: errorTintDimmed,
    warningTint: warningTint,
    warningTintDimmed: warningTintDimmed,
    graphicTint: graphicTint,
    graphicTintDimmed: graphicTintDimmed,
    beta: beta,

    // Panel
    panelTitle: inputText,
    panelText: panelText,
    panelTextSelected: panelTextSelected,
    panelBackground: panelBackground,
    panelDivider: palette.light60,
    panelSectionHeaderIcon: palette.dark70,
    panelChevron: palette.dark20,
    panelSubtitle: palette.dark50,

    // Dropdown Button
    dropdownText: palette.dark20,

    // Canvas
    canvasCreatePreviewBorder: palette.blue60,
    canvasSelectionRectBackground: rgba(palette.blue60, 0.1),
    canvasSelectionRectBorder: rgba(palette.blue60, 0.5),
    canvasTooltipText: palette.white100,
    canvasTooltipBackground: rgba(palette.dark100, 0.4),

    // Floating Window Toolbar
    floatingWindowToolbarIcon: palette.dark20,

    // Modal
    modalBackground: palette.white100,
    modalBackgroundTransparent: rgba(palette.white100, 0),
    modalSecondaryBackground: palette.light40,
    modalBackdrop: rgba(palette.black100, 0.4),
    modalTitle: palette.dark80,
    modalDescription: palette.dark50,
    modalCloseIcon: palette.dark20,

    // Variables Modal
    variablesModalEmptyStateIcon: palette.light100,

    // Tutorial Modals
    modalTutorialRowHover: rgba(palette.black100, 0.04),

    // Button
    buttonBackground: inputBackground,
    buttonBackgroundHover: inputBackgroundHover,
    buttonBackgroundActive: inputBackgroundActive,
    buttonText: buttonText,
    buttonBackgroundPrimary: tint,
    buttonBackgroundPrimaryHover: tintDark,
    buttonBackgroundPrimaryActive: palette.blue80,
    buttonTextPrimary: palette.white100,
    buttonBackgroundDestructive: palette.red100,
    buttonBackgroundDestructiveHover: palette.red114,
    buttonBackgroundDestructiveActive: palette.red120,
    buttonTextDestructive: palette.white100,

    // Row Overlay Button
    rowOverlayButtonBackground: palette.light60,
    rowOverlayButtonBackgroundHover: palette.light62,
    rowOverlayButtonBackgroundActive: palette.light70,

    // Tab Bar
    tabBarText: palette.dark30,

    // Electron
    electronTabBarBackground: palette.light58,
    electronTabBarBackgroundActive: palette.white100,
    electronTabBarBorder: palette.light62,
    electronTabBarText: palette.dark30,
    electronTabBarTextActive: palette.dark80,

    // Input
    inputBackground: inputBackground,
    inputBorder: inputBorder,
    inputBorderError: errorTint,
    inputBorderActive: inputBorderActive,
    inputText: inputText,
    inputTextError: errorTint,
    inputTextDisabled: inputTextDisabled,
    inputPlaceholder: inputPlaceholder,
    inputLabel: palette.dark20,
    inputLabelDisabled: palette.light70,
    inputIcon: palette.dark20,
    inputIconHover: palette.dark30,
    inputIconDisabled: inputTextDisabled,

    // Padlock
    iconPadlock: palette.light90,
    padLockConnector: palette.light70,

    // Popover
    popoverBackground: popoverBackground,
    popoverDivider: palette.light60,

    // Popup Button
    popupButtonBackground: inputBackground,
    popupButtonBorder: inputBorder,
    popupButtonText: buttonText,

    // Radio Button
    radioButtonBackground: palette.light70,
    radioButtonBackgroundHover: palette.light80,
    radioButtonLabel: buttonText,
    radioButtonLabelDisabled: palette.light80,

    // Segmented Control
    segmentedControlBackground: inputBackground,
    segmentedControlBorder: inputBorder,
    segmentedControlItemBackground: inputBackground,
    segmentedControlItemBackgroundActive: inputBackgroundHover,
    segmentedControlItemBackgroundSelected: palette.white100,
    segmentedControlItemText: palette.dark20,
    segmentedControlItemTextHover: palette.dark30,
    segmentedControlItemTextSelected: tint,
    segmentedControlItemTextSelectedHover: tintDark,
    segmentedControlDivider: palette.light62,

    // Slider
    sliderKnob: palette.white100,
    sliderTrack: palette.light70,
    sliderTrackActive: tint,
    sliderTrackDisabled: palette.light60,

    // Spinner
    spinnerBackground: palette.black100,

    // Swatch
    swatchBorder: rgba(palette.black100, 0.1),
    swatchBorderPlaceholder: rgba(palette.black100, 0.05),
    swatchBackgroundPlaceholderForFill: palette.white100,
    swatchBackgroundPlaceholderForBorder: palette.light80,
    swatchBackgroundPlaceholderForShadow: rgba(palette.light70, 0.5),

    // Checkbox
    checkboxBackground: palette.light70,
    checkboxBackgroundActive: palette.light80,
    checkboxBackgroundChecked: tint,
    checkboxBackgroundCheckedActive: tintDark,
    checkboxLabel: buttonText,
    checkboxLabelDisabled: palette.light80,

    // Checkbox "more info" text
    moreInfoText: palette.dark80,
    moreInfoDisclosureIcon: palette.light100,

    // Code Editor
    codeEditorBackground: palette.light52,

    // Toolbar
    toolbarIcon: palette.dark30,
    toolbarIconHover: palette.dark40,
    toolbarIconActive: tint,
    toolbarIconBadge: palette.orange100,
    toolbarTitleText: palette.dark30,
    toolbarInputText: palette.dark80,
    toolbarImportMenuIconBackground: palette.light55,

    // Animations
    animationForground: palette.white100,
    animationForgroundDimmed: palette.blue40,
    animationForgroundLight: palette.blue30,
    animationBackgroundLight: rgba(palette.white100, 0.2),
    animationNavigationTargetFrame: palette.white100,
    animationNavigationTargetFrameSelected: "#EAF4FF",
    animationTriggerFrame: palette.white100,
    animationTriggerCard: palette.light60,

    // TextArea
    textAreaScrollbar: palette.light80,

    // Toolbar Button
    toolbarButtonBackground: inputBackground,
    toolbarButtonBackgroundActive: palette.dark80,
    toolbarButtonBackgroundDisabled: palette.dark20,
    toolbarButtonBackgroundWarning: rgba(palette.yellow120, 0.2),
    toolbarButtonBackgroundWarningActive: rgba(palette.yellow120, 0.3),
    toolbarButtonBackgroundError: rgba(palette.red106, 0.1),
    toolbarButtonBackgroundErrorActive: rgba(palette.red106, 0.2),
    toolbarButtonText: palette.dark50,
    toolbarButtonTextWarning: palette.yellow120,
    toolbarButtonTextError: palette.red106,
    toolbarButtonTextActive: palette.white100,

    // Pages
    pageRowBackgroundActive: inputBackgroundHover,

    // Layers Panel
    layerItemTextDimmed: rgba(palette.dark50, 0.8),
    layerItemTextInactive: rgba(palette.black100, 0.5),
    layerItemTextInactiveSelected: rgba(palette.white100, 0.5),
    layerItemIcon: palette.dark20,
    layerItemIconDimmed: palette.purple30,
    layerItemIconComponentDimmed: palette.purple110,
    layerItemIconGraphicDimmed: palette.purple30,
    layerItemIconInactive: rgba(palette.light90, 0.5),
    layerItemIconInactiveSelected: rgba(palette.light90, 0.5),
    layerItemPlaceholderText: palette.dark20,
    layerItemComponentLabel: palette.light100,
    layerItemComponentLabelSelected: rgba(palette.white100, 0.5),

    // Code panel
    codePanelNoSearchResults: palette.dark20,

    // Canvas
    canvasBackground: palette.light60,
    canvasFrameTitle: palette.light90,
    canvasFrameOutline: palette.light80,
    canvasFramePreview: rgba(palette.black100, 0.03),
    canvasFramePreviewOutline: rgba(palette.black100, 0.1),
    canvasConnectionLine: "rgba(135, 135, 135, 0.5)",
    canvasArrowInactive: palette.light80,
    canvasSuggestionOutline: rgba(palette.black100, 0.05),
    canvasSuggestionBackground: rgba(palette.black100, 0.04),
    canvasSuggestionText: palette.dark20,

    // Rulers
    rulerBackground: "#E5E5E5",
    rulerBorderColor: palette.light70,
    rulerTickColor: palette.light80,
    rulerTextColor: palette.light100,
    rulerFadeOut: rgba("#E5E5E5", 0),
    guideColor: "#00E4F5",
    guideHighlightColor: tint,

    // Selection Box
    selectionBox: "rgba(125,125,125,.03)",
    selectionBoxOutline: "rgba(125,125,125,0.3)",
    selectionBoxBackground: "rgba(0, 153, 255, 0.2)",
    selectionBoxComponentBackground: "rgba(136, 85, 255,0.2)",

    // Snapping
    snapLine: "#FF5AAA",
    snapConstraint: "#FFD700",

    // Text editor
    textEditorOutline: palette.light80,

    // Crash
    error: palette.orange100,
    errorText: palette.white100,
    fatalErrorText: palette.black100,

    // Code export
    codeExportPanelFadeOut: rgba(panelBackground, 0),
    // The rgba coversion is a workaround as browsers make the text ::selection color lighter by default
    codeExportPanelSelection: "rgba(229, 229, 229, 0.99)",
    codeExportChipBackground: "rgba(255, 215, 6, 0.16)",

    // Font popover
    fontFamilyGoogleIcon: palette.light70,
    fontFamilyGoogleIconHover: palette.dark20,

    // Packages Panel
    packagesCompactCardTextDimmed: palette.light100,
    packagesPublishedPackageTitle: palette.dark80,
    packagesDescriptionBorder: "#E2E2E2",
    packagesInputPlaceholder: "#CCC",
    packagesInputPlaceholderFocused: "#DDD",
    packagesMarkdownCodeBackground: "#FFF",
    packagesMarkdownCodeBlockBackground: "#fdfdfd",
    packagesMarkdownInnerBorder: "#EEE",
    packagesMarkdownTableRow: "rgba(0, 0, 0, 0.025)",
    packagesShareIcon: "#999",
    packagesTextColor: "#111",
    packagesTextColorLight: "#777",
    packagesTextColorLightest: "#999",

    // Preview
    previewConsoleBackground: palette.light56,
    previewConsoleButtonText: palette.dark20,
    previewConsoleDivider: rgba(palette.light70, 0.5),
    previewConsoleCollapseIcon: palette.light90,
    previewConsoleBadge: palette.light92,
    previewSpinnerBackground: palette.black100,
    previewCloseIconBackground: "rgba(0, 0, 0, 0.05)",
    previewCloseIconBackgroundHover: "rgba(0, 0, 0, 0.08)",
    previewCloseIconColor: "rgba(0, 0, 0, 0.55)",
    previewLabelDisabled: palette.dark20,
    previewRemovePrototypeUserIcon: palette.dark20,
    previewEmptyDocumentText: palette.dark30,
    previewDeviceColorDotInnerBorder: rgba(palette.black100, 0.1),

    // Shared Preview
    sharedPreviewStatusHeading: palette.dark80,
    sharedPreviewStatusText: palette.dark20,
    sharedPreviewStatusIcon: palette.dark50,
    sharedPreviewBackground: palette.white100,
    sharedPreviewMobileQRBackground: palette.light58,
    sharedPreviewMobileQR: palette.black100,

    // Tooltip
    tooltipBackground: palette.dark20,

    // Badge
    badgeBackground: rgba(tint, 0.15),
    timeBadgeBackground: rgba(palette.white100, 0.2),
    betaBadgeBackground: rgba(beta, 0.15),
    betaBadgeLightBackground: palette.white100,

    // Avatars
    inactiveAvatarBackground: palette.dark30,

    // Feedback
    feedbackSidebarBackground: panelBackground,
    feedbackPrimaryText: palette.dark80,
    feedbackSecondaryText: palette.dark20,
    feedbackTertiaryText: palette.dark50,
    feedbackFormButtonBackground: inputBackground,
    feedbackFormButtonFill: palette.dark30,
    feedbackFormButtonSubmitFocus: palette.blue90,
    feedbackFormButtonFocus: palette.light60,
    feedbackFormBackground: panelBackground,
    feedbackFormPlaceholder: palette.light90,
    feedbackDivider: palette.light60,
    feedbackLabelBackground: palette.light55,
    feedbackHasRepliesBackground: rgba(palette.blue60, 0.1),
    feedbackDisclosureBackground: palette.light55,
    feedbackDisclosureFill: palette.dark20,

    // Empty State
    emptyStateIcon: palette.dark20,
    emptyStateCardBackground: palette.light55,
    emptyStateBodyText: palette.dark50,
    emptyStateInputShape: palette.light53,
    emptyStateTextShape: inputBackground,

    // Error Sttate
    errorStateIconColor: palette.dark20,
    errorStateCodeText: palette.dark50,
    errorStateCodeBackground: palette.light55,
    errorStateTitleText: palette.dark80,
    errorStateDescriptionText: palette.dark50,

    // Dashboard
    dashboardTitleDivision: palette.light60,
    dashboardItemIcon: palette.dark80,
    dashboardSearchPlaceholder: palette.dark20,
    dashboardMoreButtonIcon: palette.dark20,
    dashboardEmptyState: palette.light40,
    dashboardEmptyStateTitle: palette.dark50,
    dashboardEmptyStateSubtitle: palette.dark20,
    dashboardEmptyStateIcon: palette.light100,

    // Dashboard sidebar
    dashboardSidebarBackground: palette.light40,
    dashboardSidebarTitle: palette.black100,
    dashboardSidebarText: palette.dark50,
    dashboardSidebarTextDimmed: palette.light100,
    dashboardSidebarTextHover: palette.dark70,
    dashboardSidebarDivider: palette.light60,
    dashboardSidebarActions: palette.dark50,
    dashboardSidebarJoinTeamButton: palette.light60,
    dashboardSidebarJoinTeamButtonHover: palette.light62,
    dasboardSidebarUpgradeCardBackground: palette.light60,
    dasboardSidebarUpgradeCardText: palette.dark50,

    // Dashboard learn
    dashboardLearnTitle: palette.black100,
    dashboardLearnCard: palette.light40,
    dashboardLearnCardTitle: palette.white100,
    dashboardLearnCardBadge: palette.white100,
    dashboardLearnArticleTitle: palette.dark80,
    dashboardLearnArticleSubtitle: palette.dark50,

    // Dashboard new project modal
    dashboardNewProjectModalDivider: palette.light60,
    dashboardNewProjectModalTitle: palette.dark80,
    dashboardNewProjectCardModalBackground: palette.white100,
    dashboardNewProjectCardModalBackgroundBlank: palette.light53,
    dashboardNewProjectModalCardContent: palette.dark50,

    // Settings Modal
    settingsModalBackground: palette.white100,
    settingsModalInputDivision: palette.light70,
    settingsModalTeamMemberRole: palette.dark50,
    settingsModalTeamSearchIcon: palette.dark50,
    settingsModalTeamRegenerateLinkIcon: palette.dark50,
    settingsModalCloseIcon: palette.dark30,
    settingsModalText: palette.dark50,
    settingsModalTextDimmed: palette.dark30,
    settingsModalDivider: palette.light58,
    settingsModalUnknownClientIcon: palette.light55,
    settingsModalLabel: palette.dark80,
    settingsModalSidebarBackground: palette.light40,
    settingsModalNavigationLink: palette.dark50,
    settingsModalNavigationLinkHover: palette.dark50,
    settingsModalTitle: palette.black100,
    settingsModalPlanEmptyEditorsAvatar: palette.light58,
    settingsModalPlanEmptyEditorsAvatarText: palette.dark50,
    settingsModalPlanCardBackground: palette.light58,
    settingsModalFontDeleteIcon: palette.dark50,
    settingsModalTeamSearchFilterButtonActiveBackground: palette.dark80,
    settingsModalTeamSearchFilterButtonActiveText: palette.white100,
    settingsModalTeamSearchFilterTagBackground: palette.light55,
    settingsModalTeamSearchFilterTagText: palette.dark80,
    settingsModalInvoicesDate: palette.dark50,
    settingsModalInvoicesId: palette.dark80,
    settingsModalPlanMoreButton: palette.dark30,
    settingsModalTitleBorder: palette.light60,

    // Organization
    organizationTextDimmed: palette.dark50,
    organizationMemberRowHover: palette.light40,
    organizationMemberDetailBackButton: palette.dark80,
    organizationMemberDetailSubtitle: palette.dark80,
    organizationInviteRoleMenu: palette.dark80,
    organizationMemberName: palette.dark80,
    organizationNoteBackground: palette.light58,
    organizationDetailTitle: palette.dark80,

    // Custom notices
    noticeModalBackground: palette.white100,
    noticeModalTitle: palette.dark80,
    noticeModalDescription: palette.dark80,
    noticeModalCloseIcon: palette.dark20,

    // Confirmation modals
    confirmationModalTitle: palette.dark80,
    confirmationModalDescription: palette.dark50,

    // Insert Menu
    insertMenuPrimaryText: palette.dark80,
    insertMenuSecondaryText: palette.dark20,
    insertMenuFocusedText: palette.white100,
    insertMenuDeviceIconBackground: palette.dark20,
    insertMenuIconColor: palette.white100,
    insertMenuIconBackground: rgba(palette.white100, 0.3),
    insertMenuPreviewBackground: palette.light58,
    insertMenuNavText: palette.dark50,
    insertMenuNavTextHover: palette.dark80,
    insertMenuChevronColor: palette.light80,
    insertMenuUnfavorite: palette.light70,
    insertMenuFavorite: palette.yellow110,
    insertMenuShowMoreIcon: palette.light80,
    insertMenuGridItem: palette.light58,
    insertMenuPreviewIcons: palette.dark50,
    insertMenuScreenBackground: palette.white100,
    insertMenuScreenBackgroundSecondary: palette.light54,
    insertMenuUnsplash: palette.white100,
    insertMenuUnsplashBackground: palette.black100,

    // Insert Menu Interaction Previews
    insertMenuInteractionPreviewFrameFill: palette.white100,
    insertMenuInteractionPreviewFrameFillDark: palette.light70,
    insertMenuInteractionPreviewDashedLine: palette.light80,
    insertMenuInteractionPreviewTextLarge: palette.light60,
    insertMenuInteractionPreviewText: palette.light90,

    // Table
    dashboardTableHeaderText: palette.dark20,
    dashboardTableBodyText: palette.dark80,
    dashboardTableBodyTextLight: palette.dark30,
    dashboardTableRowHighlight: palette.light55,

    // Toasts
    toastBackground: palette.white100,
    toastBackgroundPrimary: tint,
    toastIcon: palette.dark30,
    toastText: palette.dark80,
    toastTextPrimary: palette.white100,

    // Shortcut Modal
    shortcutsModalListTitle: palette.dark20,
    shortcutsModalActionButton: palette.dark30,
    shortcutsModalDivider: palette.light60,
    shortcutsModalKeyBackground: palette.light58,

    // Loader
    loaderPrimary: palette.light58,
    loaderSecondary: palette.light56,

    // Upsells
    upsellCTAText: palette.white100,
    upsellCTAButtonBackground: palette.white100,
    upsellCTAButtonBackgroundHover: palette.light60,

    // Team creation
    teamCreationModalBackground: palette.white100,
    teamCreationCloseIcon: palette.dark30,
    teamCreationText: palette.dark80,
    teamCreationTextDimmed: palette.dark50,
    teamCreationNavigation: palette.light70,
    teamCreationNavigationActive: tint,
    teamCreationBenefitIcon: tint,
    teamCreationBenefitDivider: palette.light60,

    // Error Boundary
    errorBoundaryDescription: palette.dark20,

    // Highlights
    highlightsBorder: palette.blue60,
    highlightsBackground: rgba(palette.blue60, 0.2),

    // Status Bar
    statusBarCustomerBetaBackground: palette.purple60,
    statusBarCustomerBetaBorder: palette.purple60,
    statusBarCustomerBetaText: palette.white100,
    statusBarNonPayingCustomerBackground: tint,
    statusBarNonPayingCustomerBorder: tint,
    statusBarNonPayingCustomerText: palette.white100,
    statusBarDevelopmentBackground: "#ff8822",
    statusBarDevelopmentBorder: "#ff8822",
    statusBarDevelopmentText: palette.white100,
    statusBarEmployeeBetaBackground: palette.light52,
    statusBarEmployeeBetaBorder: palette.light60,
    statusBarEmployeeBetaText: palette.dark80,
    statusBarPreviewLinkBackground: palette.blue60,
    statusBarPreviewLinkBorder: palette.blue60,
    statusBarPreviewLinkText: palette.white100,
    statusBarStableBackground: palette.green100,
    statusBarStableBorder: palette.green100,
    statusBarStableText: palette.white100,

    // Beta Features
    betaFeatureButtonText: palette.white100,
    betaFeatureButtonHover: palette.purple126,
    betaFeatureIcon: palette.white100,

    // User Test popover
    userTestButtonBackground: inputBackground,
    userTestButtonBackgroundHover: inputBackgroundHover,
    userTestText: panelText,
}
