export enum FontFamily {
    monospace = `"Input Mono", Menlo, monospace`,
    sansSerif = `Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif, emoji, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    sansSerifWithoutEmoji = `Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif`,
}

export enum FontSize {
    label = 10,
    base = 12,
    heading = 13,
}

export enum FontWeight {
    base = 400,
    baseActive = 500,
    heading = 600,
    headingBold = 700,
    headingExtraBold = 800,
}

export const fonts = {
    family: {
        monospace: FontFamily.monospace,
        sansSerif: FontFamily.sansSerif,
        sansSerifWithoutEmoji: FontFamily.sansSerifWithoutEmoji,
    },
    size: {
        label: FontSize.label,
        base: FontSize.base,
        heading: FontSize.heading,
    },
    weight: {
        base: FontWeight.base,
        baseActive: FontWeight.baseActive,
        heading: FontWeight.heading,
        headingBold: FontWeight.headingBold,
        headingExtraBold: FontWeight.headingExtraBold,
    },
}
