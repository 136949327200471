import type { CanvasNode } from "document/models/CanvasTree"
import type { BoxShadow } from "document/models/Shadow"
import { stylableNode } from "./utils/stylableNode"

export function hasBoxShadow(node: CanvasNode): node is CanvasNode & WithBoxShadow {
    return withBoxShadow(node) && stylableNode(node)
}

export interface WithBoxShadow {
    boxShadows: Readonly<BoxShadow[]> | undefined
}

export const boxShadowsKey: keyof WithBoxShadow = "boxShadows"

export function withBoxShadow(target: any): target is WithBoxShadow {
    return boxShadowsKey in target
}
