export interface ServiceMap {
    api: string
    app: string
    canvas: string
    checkout: string
    events: string
    export: string
    login: string
    privateStore: string
    publicStore: string
    registry: string
    userContent: string
}

interface WindowWithServiceMap extends Window {
    pageProps?: {
        services?: ServiceMap
    }
}

let cachedServiceMap: ServiceMap | undefined

export function getServiceMap(): ServiceMap {
    if (cachedServiceMap) return cachedServiceMap
    cachedServiceMap = extractServiceMap()
    return cachedServiceMap
}

function extractServiceMap(): ServiceMap {
    const location = window.location

    // First check for the service map embedded on the page.
    // TODO: Embed it in a nicer way (a script tag that exposes getServiceMap()).
    let services = (window as WindowWithServiceMap)?.pageProps?.services
    if (services) {
        return services
    }
    // If the service map is not available, try accessing the top frame.
    let topOrigin: string | undefined
    try {
        const topWindow = window.top as WindowWithServiceMap
        // This fails if we are sandboxed from the top frame.
        topOrigin = topWindow.location.origin
        services = (window.top as WindowWithServiceMap)?.pageProps?.services
        if (services) {
            return services
        }
    } catch (e) {
        // Ignore
    }
    if (topOrigin && topOrigin !== location.origin) {
        throw Error(`Unexpectedly embedded by ${topOrigin} (expected ${location.origin})`)
    }
    // Don't trust any values if we get here on what seems to be a framer.com domain.
    if (location.origin.endsWith("framer.com") || location.origin.endsWith("framer.dev")) {
        throw Error("ServiceMap data was not provided in document")
    }
    // If we get here we're sandboxed from the top frame.
    // TODO: Provide this data via a trusted endpoint instead of query string.
    try {
        const servicesJSON =
            new URLSearchParams(location.search).get("services") ||
            new URLSearchParams(location.hash.substring(1)).get("services")
        if (servicesJSON) {
            services = JSON.parse(servicesJSON)
        }
    } catch (e) {
        // Ignore
    }
    if (services && typeof services === "object" && services.api) {
        return services
    }
    throw Error("ServiceMap requested but not available")
}
