import * as React from "react"
import { cx } from "linaria"
import { IconInputSearch } from "../icons/IconInputSearch"
import type { OverrideType, HTMLDivAttributes } from "./types"
import { InputWrapper } from "./InputWrapper"
import { TextInput, TextInputProps } from "./TextInput"
import * as styles from "./SearchBar.styles"

export type SearchBarProps = OverrideType<
    HTMLDivAttributes,
    {
        ref?: React.RefObject<HTMLInputElement>
        /** The value. */
        value: string
        /** Called when the value changes, the final flag can be used to know when the user committed by pressing enter or blurring. The reset function can be called to reset the internal value. */
        onChange: (value: string, final: boolean, reset: () => void) => void
        /** Allows disabling the control. */
        enabled?: boolean
        /** Increases the search bar height. */
        large?: boolean
        /** When enabled on mount, focuses the input. */
        autoFocus?: TextInputProps["autoFocus"]
    }
>

export const SearchBar = React.forwardRef<HTMLInputElement, SearchBarProps>(function SearchBar(
    props,
    forwardedRef: React.Ref<HTMLInputElement>
) {
    const { value, onChange, enabled, large, className, autoFocus, onKeyDown, ...rest } = props

    const fallbackRef = React.useRef<HTMLInputElement>(null)
    const ref = forwardedRef || fallbackRef

    const mousedownHandler = React.useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault()
            const inputElement = (ref as React.RefObject<HTMLInputElement>).current
            if (inputElement) inputElement.focus()
        },
        [ref]
    )

    return (
        <InputWrapper className={cx(styles.searchBarWrapper, className)} large={large} {...rest}>
            <TextInput
                ref={ref}
                placeholder="Search"
                constantChange
                value={value}
                onChange={onChange}
                enabled={enabled}
                className={styles.searchInput}
                autoFocus={autoFocus}
                onKeyDown={onKeyDown}
            />

            <IconInputSearch onMouseDown={mousedownHandler} />
        </InputWrapper>
    )
})
