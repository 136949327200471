import * as React from "react"
import { motion, useAnimation, useMotionValue, useDeprecatedInvertedScale as useInvertedScale } from "framer-motion"
import { useLoop, wait, animationDelay } from "./utils"
import { useInvertedBorderRadius } from "../lib/useInvertedRadius"
import { colors } from "../tokens"

const size = 40
const radius = 8

const curve = {
    default: {
        type: "spring",
        stiffness: 200,
        damping: 18,
        delay: 0,
        velocity: 0,
    },
    originX: { type: false },
    originY: { type: false },
}

export const AnimationFrame = React.memo(function AnimationFrame() {
    const parentControls = useAnimation()
    const itemControls = useAnimation()
    async function animation() {
        await wait(animationDelay)

        parentControls.set({
            opacity: 1,
            radius: radius,
            scaleX: 0,
            scaleY: 0,
            x: 0,
            y: 0,
        })
        itemControls.set({
            scaleX: 0,
            scaleY: 0,
            originY: 1.25,
            height: 0,
        })
        await parentControls.start({
            scaleX: 1,
            scaleY: 1,
            originX: 0,
            originY: 0,
            transition: curve,
        })
        await itemControls.start({
            scaleX: 1,
            scaleY: 1,
            height: 15,
            originX: 0,
            transition: curve,
        })
        itemControls
            .start({
                scaleX: 1.05,
                originX: 0.5,
                transition: curve,
            })
            .catch(_ => {})
        await parentControls.start({
            originX: 0.5,
            scaleX: 1.25,
            transition: curve,
        })
        itemControls
            .start({
                scaleX: 1.0,
                originX: 0.5,
                transition: curve,
            })
            .catch(_ => {})
        await parentControls.start({
            scaleX: 1,
            transition: curve,
        })
        await parentControls.start({
            originX: 0.5,
            originY: 0.5,
            scaleY: 1.25,
            transition: curve,
        })
        await parentControls.start({
            scaleY: 1,
            transition: curve,
        })
        await parentControls.start({ opacity: 0 })

        return await parentControls.start({ opacity: 0 })
    }

    useLoop(animation)

    const scaleX = useMotionValue(1)
    const scaleY = useMotionValue(1)
    const inverted = useInvertedScale({ scaleX, scaleY })
    const invertedRadius = useInvertedBorderRadius(scaleX, scaleY, radius)
    const invertedChildRadius = useInvertedBorderRadius(scaleX, scaleY, 4)

    return (
        <motion.div
            animate={parentControls}
            style={{
                x: 0,
                y: 0,
                width: size,
                height: size,
                borderRadius: radius,
                background: colors.animationForground,
                position: "relative",
                scaleX,
                scaleY,
                ...invertedRadius,
            }}
        >
            <motion.div
                animate={itemControls}
                style={{
                    borderRadius: 4,
                    x: 5,
                    top: size - 20,
                    position: "absolute",
                    width: size - 10,
                    height: 15,
                    backgroundColor: colors.animationForgroundLight,
                    scaleY: inverted.scaleY,
                    ...invertedChildRadius,
                }}
            />
        </motion.div>
    )
})
