import type { CanvasNode } from "document/models/CanvasTree"
import type { PreviewSettings } from "preview-next/PreviewSettings"

export interface WithPreviewSettings {
    previewSettings: PreviewSettings | null
}

export const previewSettingsDefaults: WithPreviewSettings = {
    previewSettings: null,
}

const key: keyof WithPreviewSettings = "previewSettings"

export function withPreviewSettings(node: CanvasNode): node is CanvasNode & WithPreviewSettings {
    return key in node
}
