import { css } from "linaria"
import { colors, dimensions, cursors } from "../tokens"
import { inputWrapper } from "./InputWrapper.styles"

const verticalOffset = 1

export const dropDown = css`
    display: flex;
    width: auto;
    height: ${dimensions.panelSectionHeaderHeight - verticalOffset}px;
    flex-direction: row;
    align-items: center;
    margin: ${verticalOffset}px 4px 0;
    color: ${colors.dropdownText};
    cursor: ${cursors.button};
    font-size: 10px;

    .${inputWrapper} & {
        height: 100%;
        margin: 0 4px;
    }
`

export const spacer = css`
    width: 5px;
`
