export function isBoolean(value: unknown): value is boolean {
    return value === true || value === false
}

export function isString(value: unknown): value is string {
    return typeof value === "string"
}

export function isNumber(value: unknown): value is number {
    return typeof value === "number" && Number.isFinite(value)
}

export function isObject(value: unknown): value is { [key: string]: unknown } {
    return typeof value === "object" && value !== null && !Array.isArray(value)
}

export function isArray(value: unknown): value is unknown[] {
    return Array.isArray(value)
}

export function isUndefined(value: unknown): value is undefined {
    return value === undefined
}

export function isFunction(value: unknown): value is (...parameters: unknown[]) => unknown {
    return typeof value === "function"
}
