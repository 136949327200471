import type { AssetMap } from "../AssetMap"
import type { AssetContext, AssetResolver } from "../types/AssetResolver"
import type { AssetSize } from "../types/Asset"
import { parseAssetReference } from "../assetReference"

const nodeModulesRegex = /^node_modules\/(@[^/]+\/[^/]+|[^/]+)\/(.*)/

function packageResourceURL(userContentOrigin: string, packageName: string, filePath: string): string | undefined {
    return `${userContentOrigin}/packages/${packageName}/latest/${filePath}`
}

function resolveFramerResourceURL(userContentOrigin: string, p: string): string {
    const path = p.replace(/%40/g, "@")
    // XXX Temporary hack to support package assets hack for Framer Web inside the isolated canvas
    const matches = nodeModulesRegex.exec(path)
    if (matches) {
        const packageName = matches[1]
        const filePath = matches[2]
        const resourceURL = packageResourceURL(userContentOrigin, packageName, filePath)
        if (resourceURL) {
            return resourceURL
        }
    }
    // Otherwise return the same path
    return path
}

export function webAssetResolver(userContentOrigin: string, assetMap: AssetMap) {
    const assetResolver: AssetResolver = (reference: string | undefined, context: AssetContext): string | undefined => {
        if (!reference) {
            return undefined
        }
        const details = parseAssetReference(reference)
        if (details) {
            const { identifier, packageIdentifier } = details
            if (packageIdentifier) {
                return packageResourceURL(userContentOrigin, packageIdentifier, identifier)
            } // else
            reference = identifier
        } else if (context.isFramerResourceURL) {
            return resolveFramerResourceURL(userContentOrigin, reference)
        }
        const prefferedSize: AssetSize | null = context.preferredSize ?? details?.preferredSize ?? null
        const result = assetMap.urlForKey(reference, prefferedSize, context?.size)
        return result
    }
    return assetResolver
}
