import type { EntityIdentifier } from "@framerjs/framer-runtime"
import type { ExportSpecifier } from "./compiler"
import { localModuleIdForStableName } from "./localModuleIdForStableName"

export function localModuleEntityIdentifierForStableName({
    type,
    name,
    exportSpecifier,
}: {
    type: string
    name: string
    exportSpecifier: ExportSpecifier
}): EntityIdentifier {
    return `local-module:${localModuleIdForStableName({ name, type })}:${exportSpecifier}`
}
