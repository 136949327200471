import type { CanvasNode, NodeID } from "document/models/CanvasTree"
import type { ControlType } from "framer"
import type { SandboxReactComponentDefinition } from "@framerjs/framer-runtime/sandbox"
import type { EntityIdentifier, ReactComponentDefinition } from "@framerjs/framer-runtime"
import type { Transition } from "document/models/Transition"
import { isObject } from "utils/typeChecks"

export interface WithCodeComponent {
    codeComponentIdentifier: string
}

export const codeComponentDefaults: WithCodeComponent = {
    codeComponentIdentifier: "",
}

const key: keyof WithCodeComponent = "codeComponentIdentifier"

export function withCodeComponent(node: unknown): node is CanvasNode & WithCodeComponent & WithCodeComponentProps {
    return typeof node === "object" && !!node && key in node
}

export function reduceCodeComponents(
    node: CanvasNode,
    result: {
        codeComponentIdentifiers: string[]
    }
) {
    if (!withCodeComponent(node)) return
    if (result.codeComponentIdentifiers.includes(node.codeComponentIdentifier)) return
    result.codeComponentIdentifiers.push(node.codeComponentIdentifier)
}

// Code component props

// When changing the prefix please also change the one in src/document-migrations/src/migrations/migration_73_74.ts
export const CONTROL_PREFIX = "$control__"

export type ControlProp =
    | BaseControlProp
    | FusedNumberControlProp
    | TransitionControlProp
    | ComponentInstanceControlProp
    | ArrayControlProp
    | ObjectControlProp

const typeKey: keyof ControlProp = "type"
const valueKey: keyof ControlProp = "value"

export function isControlProp(value: unknown): value is ControlProp {
    return isObject(value) && typeKey in value && valueKey in value
}

interface BaseControlProp {
    type: ControlType | null // could be null resulted from migration
    value?: unknown // optional because key won't exist if value is `undefined`
}

export const FUSEDNUMBER_SINGLE_KEY = "single"
export const FUSEDNUMBER_FUSED_KEY = "fused"
export const FUSEDNUMBER_TOGGLE_KEY = "isFused"
export interface FusedNumberControlProp extends BaseControlProp {
    type: ControlType.FusedNumber
    [FUSEDNUMBER_TOGGLE_KEY]: boolean
    value: {
        [FUSEDNUMBER_SINGLE_KEY]: number
        [FUSEDNUMBER_FUSED_KEY]: [number, number, number, number]
    }
}

export interface TransitionControlProp extends BaseControlProp {
    type: ControlType.Transition
    value: Transition
}

export interface ObjectControlProp extends BaseControlProp {
    type: ControlType.Object
    value: { [key: string]: BaseControlProp }
}

export type ArrayValue = Exclude<ControlProp, ArrayControlProp> & {
    id: string
}

export interface ArrayControlProp extends BaseControlProp {
    type: ControlType.Array
    value: ArrayValue[]
}

export type SlotLink = ArrayValue & {
    value: NodeID
    type: ControlType.ComponentInstance
}
export interface ComponentInstanceControlProp extends BaseControlProp {
    type: ControlType.ComponentInstance
    value: SlotLink[]
}

// @FIXME: the index signature can be improved when we have template literal support in ts.
export interface CodeComponentProps {
    [key: string]: ControlProp | undefined
}

export interface ReactComponentDefinitionProvider {
    reactComponentForIdentifier(
        identifier: EntityIdentifier
    ): ReactComponentDefinition | SandboxReactComponentDefinition | null
}

export interface WithCodeComponentProps {
    getControlProps(): CodeComponentProps
    getControlProp(key: string): ControlProp | undefined
    getCustomCodeComponentProps(
        componentDefinitionProvider: ReactComponentDefinitionProvider
    ): { [prop: string]: unknown }
    getCodeComponentProps(componentDefinitionProvider: ReactComponentDefinitionProvider): { [prop: string]: unknown }
}

const codeComponentPropsKey: keyof WithCodeComponentProps = "getControlProps"
export function withCodeComponentProps(node: unknown): node is CanvasNode & WithCodeComponent & WithCodeComponentProps {
    return typeof node === "object" && !!node && codeComponentPropsKey in node
}
