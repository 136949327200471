import * as React from "react"
import { cx } from "linaria"
import { truncateWithEllipsis } from "./utils/truncateWithEllipsis.styles"
import type { OverrideType } from "./types"
import * as styles from "./TextInput.styles"
import { useTextInput } from "./utils/useTextInput"
import type { TextInputSharedProps } from "./utils/textInputShared"
import { textInputSharedStyles } from "./utils/textInputShared.styles"
import { useTabIndex } from "./Tabbable"

export type TextInputProps = OverrideType<
    Omit<React.ComponentPropsWithoutRef<"input">, "disabled">,
    TextInputSharedProps & {
        /** Enable to trigger the onChange event for every value change. By default this only happens when pressing enter or blurring. */
        constantChange?: boolean
        /** Truncates overflowing text by showing ellipsis. Defaults to true. */
        truncate?: boolean
        /** Increases the input height. */
        large?: boolean
        /** Prevent arrow key keydown events from propagating. Defaults to true. */
        stopUpDownKeyPropagation?: boolean
    }
>

export const TextInput = React.memo(
    React.forwardRef<HTMLInputElement, TextInputProps>(function TextInput(props, forwardedRef) {
        const {
            value,
            enabled = true,
            autoFocus,
            constantChange,
            onChange,
            onBlur,
            onFocus,
            onKeyDown,
            className,
            tabIndex,
            stopUpDownKeyPropagation = true,
            truncate = true,
            large = false,
            ...rest
        } = props

        const { ref, internalValue, changeHandler, blurHandler, focusHandler, keyDownHandler } = useTextInput({
            ref: forwardedRef,
            value,
            enabled,
            autoFocus,
            constantChange,
            stopUpDownKeyPropagation,
            onChange,
            onBlur,
            onFocus,
            onKeyDown,
        })

        return (
            <input
                ref={ref}
                type="text"
                className={cx(
                    textInputSharedStyles,
                    styles.input,
                    truncate && truncateWithEllipsis,
                    large && styles.inputLarge,
                    className
                )}
                value={internalValue}
                onChange={changeHandler}
                disabled={!enabled}
                onBlur={blurHandler}
                onFocus={focusHandler}
                onKeyDown={keyDownHandler}
                autoFocus={autoFocus}
                autoComplete="nope" // Chrome ignores auto-complete="off"
                autoCorrect="off"
                spellCheck={false}
                tabIndex={useTabIndex(tabIndex)}
                {...rest}
            />
        )
    })
)
