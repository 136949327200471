import { componentLoader } from "@framerjs/framer-runtime"
import {
    TextNode,
    PathNode,
    SVGNode,
    PolygonShapeNode,
    CanvasNode,
    ShapeContainerNode,
    NodeID,
    isCanvasComponentNode,
    CanvasComponentNode,
} from "document/models/CanvasTree"
import {
    FrameNode,
    StarShapeNode,
    OvalShapeNode,
    RectangleShapeNode,
    BooleanShapeNode,
    ShapeGroupNode,
} from "document/models/CanvasTree"
import { nameForPathBooleanType } from "document/models/CanvasTree/traits/PathBoolean"
import CodeComponentNode, { isCodeComponentNode } from "document/models/CanvasTree/nodes/CodeComponentNode"
import { isUserComponent } from "../../models/CanvasTree/traits/utils/isUserComponent"
import { HardCodedCodeIdentifier } from "../../models/CanvasTree/traits/utils/hardCodedCodeComponentIdentifiers"
import { getFramePresetName } from "document/models/CanvasTree/traits/utils/framePresets"
import { isGestureVariant, isVariant } from "document/models/CanvasTree/traits/Variant"
import { Dictionary } from "app/dictionary"
import { isScreen } from "document/models/CanvasTree/traits/Screen"

export const getDefaultName = (node: CanvasNode): string => {
    if (node instanceof SVGNode) {
        return node.originalFilename || "Icon"
    }
    if (node instanceof TextNode) {
        return node.text.replace(/\s+/g, " ").trim()
    }
    if (node instanceof PathNode) {
        return "Path"
    }
    if (node instanceof FrameNode) {
        if (isVariant(node)) {
            if (isGestureVariant(node)) {
                const tree = node.tree()
                const canvasComponent = tree.getScopeNodeFor(node)
                if (isCanvasComponentNode(canvasComponent)) {
                    const topLevelVariant = canvasComponent.getTopLevelVariantForGesture(node)
                    return topLevelVariant.name || getDefaultName(topLevelVariant)
                }
            }
            return Dictionary.VARIANT
        }

        const deviceName = getFramePresetName(node.framePreset)
        if (deviceName) {
            // While exporting the node does not have a tree
            if (!node.cache.latest) return deviceName
            const tree = node.tree()
            const parent = tree.getParent(node.id)
            if (!parent) return deviceName
        }

        if (node.stackEnabled) return "Stack"
        if (isRoundFrame(node)) return "Round"
        if (isScreen(node)) return "Screen"
        return "Frame"
    }
    if (node instanceof ShapeContainerNode) {
        return "Graphic"
    }
    if (node instanceof BooleanShapeNode) {
        return nameForPathBooleanType(node.pathBoolean)
    }
    if (node instanceof ShapeGroupNode) {
        return "Group"
    }
    if (node instanceof StarShapeNode) {
        return "Star"
    }
    if (node instanceof PolygonShapeNode) {
        return "Polygon"
    }
    if (node instanceof OvalShapeNode) {
        return "Oval"
    }
    if (node instanceof RectangleShapeNode) {
        return "Rectangle"
    }
    if (node instanceof CodeComponentNode) {
        const component = componentLoader.componentForIdentifier(node.codeComponentIdentifier)
        if (component && component.name) {
            return component.name
        }
        return "Component"
    }
    if (node instanceof CanvasComponentNode) {
        return "Component"
    }
    return "Rectangle"
}

export const iconName = (node?: CanvasNode) => {
    if (node === undefined) {
        return "canvas"
    }
    if (isUserComponent(node)) {
        return "component"
    }
    if (isCodeComponentNode(node)) {
        switch (node.codeComponentIdentifier) {
            case HardCodedCodeIdentifier.scroll:
                return "scroll"
            case HardCodedCodeIdentifier.page:
                return "page"
        }
    }
    if (node instanceof FrameNode) {
        if (node.stackEnabled) {
            return node.stackDirection === "horizontal" ? "stack-horizontal" : "stack"
        }
        if (isRoundFrame(node)) return "frame-round"
        return "frame"
    }
    if (node instanceof TextNode) {
        return "text"
    }
    if (node instanceof SVGNode) {
        return "svg"
    }
    if (node instanceof PathNode) {
        return "path"
    }
    if (node instanceof BooleanShapeNode) {
        return "boolean"
    }
    if (node instanceof StarShapeNode) {
        return "star"
    }
    if (node instanceof PolygonShapeNode) {
        return "polygon"
    }
    if (node instanceof OvalShapeNode) {
        return "oval"
    }
    if (node instanceof RectangleShapeNode) {
        return "rectangle"
    }
    if (node instanceof ShapeGroupNode) {
        return "group"
    }
    if (node instanceof ShapeContainerNode) {
        return "graphic"
    }
    return "rectangle"
}

export const equalSelection = (a: CanvasNode[], b: CanvasNode[]) => {
    const length = a.length
    if (length !== b.length) return false

    const set = new Set<NodeID>()
    for (let i = 0; i < length; i++) {
        set.add(a[i].id)
    }
    for (let i = 0; i < length; i++) {
        if (!set.has(b[i].id)) return false
    }
    return true
}

function isRoundFrame(node: FrameNode) {
    const radius = node.radiusPerCorner
        ? Math.min(node.radiusTopLeft, node.radiusTopRight, node.radiusBottomLeft, node.radiusBottomRight)
        : node.radius
    if (radius === 0) return false

    const radiusIsRelative = !node.radiusPerCorner && node.radiusIsRelative
    if (radiusIsRelative) return node.radius > 40
    // While exporting the node does not have a tree
    if (!node.cache.latest) return false
    const rect = node.tree().getRect(node)
    const minSize = Math.min(rect.width, rect.height)
    return radius >= minSize / 2
}
