import type {
    float,
    integer,
    oneway,
    UnsafeJSON,
    ServiceMessageHelper,
    ServiceStream,
    ServiceStreamOptions,
    ServiceMethodRequest,
} from "./ServiceDefinition"
import type { ServiceChannel } from "./ServiceChannel"
import { ServiceStreamsPrivate } from "./streams/private"

/**
 * @private Infrastructure that should never be used outside the Framer Services package implementation.
 */
export namespace ServiceRuntimePrivate {
    // Capture Math.random in case some other code swaps it out
    const randomNumber = Math.random

    /** Returns a good enough unique string to identify request/response pairs across services */
    export function generateUniqueId(): string {
        return `${randomNumber()}`
    }

    /** Promise with resolve() and reject() functions that can be called given just a reference to the promise. */
    export type ResolvablePromise<T> = Promise<T> & { resolve: (result: T) => void; reject: (error: any) => void }

    export function newResolvablePromise<T>(): ResolvablePromise<T> {
        let promiseResolve: any
        let promiseReject: any
        const promise: any = new Promise((resolve, reject) => {
            promiseResolve = resolve
            promiseReject = reject
        })
        promise.resolve = promiseResolve
        promise.reject = promiseReject
        return promise
    }
}

export namespace ServiceRuntimePrivate {
    export function onewayMethodTemplate(
        method: string,
        acceptsArgument: boolean,
        helper: ServiceMessageHelper,
        argument?: ServiceChannel.MessageBody
    ): oneway {
        void helper({
            method,
            argument: acceptsArgument ? argument : undefined,
            oneway: true,
        })
    }

    export async function voidMethodTemplate(
        method: string,
        acceptsArgument: boolean,
        helper: ServiceMessageHelper,
        argument?: ServiceChannel.MessageBody
    ): Promise<void> {
        await helper({
            method,
            argument: acceptsArgument ? argument : undefined,
        })
    }

    export async function valueMethodTemplate<T extends object>(
        method: string,
        acceptsArgument: boolean,
        returnValidator: ServiceMethodRequest["returnValidator"],
        helper: ServiceMessageHelper,
        argument?: ServiceChannel.MessageBody
    ): Promise<T> {
        const result = await helper({
            method,
            argument: acceptsArgument ? argument : undefined,
            returnValidator,
        })
        return result as T
    }

    export function streamMethodTemplate<T extends object>(
        method: string,
        helper: ServiceMessageHelper,
        options?: ServiceStreamOptions
    ): ServiceStream<T> {
        return new ServiceStreamsPrivate.StreamReader(method, options, helper)
    }
}

export namespace ServiceRuntimePrivate {
    export namespace Validation {
        export function typeContextForError(error: any): string {
            if (!error) return ""

            const { stack } = error
            if (typeof stack !== "string") return ""

            // Based on the stack trace, try to figure out what type hierarchy caused the error
            const validators = stack
                .split("\n")
                .map(line => {
                    const matches = line.match(/([^.\s]*)Validation.copy([^.\s]*)/)
                    if (!matches || matches.length < 3) return { namespace: "", type: "" }
                    return { namespace: matches[1], type: matches[2] }
                })
                .filter(validator => !!validator.type)
                .reverse()

            return validators
                .map((validator, i) => (i === 0 ? validator.namespace + "." : "") + validator.type)
                .join("/")
        }

        export function copyString(value: unknown): string {
            if (typeof value !== "string") {
                throw new Error(`Expected string but received: ${value}`)
            }
            return value
        }

        export function copyFloat(value: unknown): float {
            if (typeof value !== "number") {
                throw new Error(`Expected number but received: ${value}`)
            } else if (!isFinite(value)) {
                throw new Error(`Expected finite number but received: ${value}`)
            } else if (value === 0) {
                // "Convert" negative zero for sanity's sake, even though -0 === +0
                return 0
            }
            return value
        }

        export function copyInteger(value: unknown): integer {
            const result = copyFloat(value)
            if (result !== Math.floor(result)) {
                throw new Error(`Expected integer but received: ${value}`)
            }
            return result
        }

        export function copyBoolean(value: unknown): boolean {
            if (typeof value !== "boolean") {
                throw new Error(`Expected boolean but received: ${value}`)
            }
            return value
        }

        export function copyUnsafeJSON(value: unknown): UnsafeJSON {
            if (value === null) {
                throw new Error(`Expected UnsafeJSON but received: ${value}`)
            } else if (typeof value !== "object") {
                throw new Error(`Expected UnsafeJSON but received: ${value}`)
            } else if ((value as object).constructor !== Object) {
                throw new Error(`Expected plain object UnsafeJSON but received: ${value}`)
            }
            return value as any
        }

        export function copyOptional(wrappedValidator: (wrapped: unknown) => unknown, value: unknown): any {
            if (value === undefined || value === null) return undefined
            return wrappedValidator(value)
        }

        export function copyNullable(wrappedValidator: (wrapped: unknown) => unknown, value: unknown): any {
            if (value === null) return null
            return wrappedValidator(value)
        }

        export function copyArray(elementValidator: (element: unknown) => unknown, value: unknown): readonly any[] {
            if (!Array.isArray(value)) {
                throw new Error(`Validation expected array but received: ${value}`)
            }
            return Object.freeze(value.map(element => elementValidator(element)))
        }

        export function copyMap(
            keyValidator: (key: unknown) => unknown,
            valueValidator: (value: unknown) => unknown,
            maybeMap: unknown
        ): { readonly [_: string]: any } {
            if (maybeMap === null || typeof maybeMap !== "object") {
                throw new Error(`Validation expected map object but received: ${maybeMap}`)
            }

            // Note: Object.entries limits the key type to just strings. This may need to change if the Services parser starts accepting other key types.
            const result: { [_: string]: any } = {}
            for (const [key, value] of Object.entries(maybeMap as {})) {
                try {
                    result[keyValidator(key) as string | integer] = valueValidator(value)
                } catch {
                    // Skip invalid key/value pairs and build a partial copy
                }
            }
            return Object.freeze(result)
        }
    }
}

export function assertNever(x: never, error?: any): never {
    throw error || new Error("Unexpected object: " + x)
}
