import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springAnimation } from "./utils"
import { colors } from "@framerjs/fresco/src/tokens"

export const AnimationVariables = React.memo(function AnimationVariables() {
    const textControl = useAnimation()
    const colorControl = useAnimation()
    const imageControl = useAnimation()
    const linkControl = useAnimation()

    async function animation() {
        await Promise.all([
            textControl.start({
                scale: 1,
                opacity: 1,
                transition: { type: false },
            }),
            imageControl.start({
                scale: 0,
                opacity: 0,
                transition: { type: false },
            }),
            linkControl.start({
                scale: 0,
                opacity: 0,
                transition: { type: false },
            }),
            colorControl.start({
                scale: 0,
                opacity: 0,
                transition: { type: false },
            }),
        ])
        await Promise.all([
            textControl.start({
                scale: 0,
                opacity: 0,
                transition: { ...springAnimation.default, delay: 0.4 },
            }),
            imageControl.start({
                scale: 1,
                opacity: 1,
                transition: { ...springAnimation.default, delay: 1 },
            }),
        ])
        await Promise.all([
            imageControl.start({
                scale: 0,
                opacity: 0,
                transition: { ...springAnimation.default, delay: 0.4 },
            }),
            linkControl.start({
                scale: 1,
                opacity: 1,
                transition: { ...springAnimation.default, delay: 0.4 },
            }),
        ])
        await Promise.all([
            linkControl.start({
                scale: 0,
                opacity: 0,
                transition: { ...springAnimation.default, delay: 0.4 },
            }),
            colorControl.start({
                scale: 1,
                opacity: 1,
                transition: { ...springAnimation.default, delay: 0.4 },
            }),
        ])
        return await Promise.all([
            colorControl.start({
                scale: 0,
                opacity: 0,
                transition: { ...springAnimation.default, delay: 0.4 },
            }),
            textControl.start({
                scale: 1,
                opacity: 1,
                transition: { ...springAnimation.default, delay: 0.4 },
            }),
        ])
    }

    useLoop(animation)

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 2,
                width: 40,
                height: 40,
                borderRadius: 8,
                background: "rgba(255, 255, 255, 0.4)",
            }}
        >
            <motion.div animate={textControl} style={{ position: "absolute" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
                    <path
                        d="M 4 5 C 4 4.448 4.448 4 5 4 L 13 4 C 13.552 4 14 4.448 14 5 L 14 13 C 14 13.552 13.552 14 13 14 L 5 14 C 4.448 14 4 13.552 4 13 Z"
                        fill={colors.animationForground}
                    ></path>
                    <path
                        d="M 0 2.5 C 0 1.119 1.119 0 2.5 0 L 15.5 0 C 16.881 0 18 1.119 18 2.5 L 18 15.5 C 18 16.881 16.881 18 15.5 18 L 2.5 18 C 1.119 18 0 16.881 0 15.5 Z"
                        fill={colors.animationForground}
                    ></path>
                    <path
                        d="M 5 6 C 5 5.448 5.448 5 6 5 L 12 5 C 12.552 5 13 5.448 13 6 L 13 6 C 13 6.552 12.552 7 12 7 L 6 7 C 5.448 7 5 6.552 5 6 Z"
                        fill={colors.animationForgroundLight}
                    ></path>
                    <path
                        d="M 8 6 C 8 5.448 8.448 5 9 5 L 9 5 C 9.552 5 10 5.448 10 6 L 10 12 C 10 12.552 9.552 13 9 13 L 9 13 C 8.448 13 8 12.552 8 12 Z"
                        fill={colors.animationForgroundLight}
                    ></path>
                </svg>
            </motion.div>
            <motion.div animate={imageControl} style={{ position: "absolute", opacity: 0 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
                    <path
                        d="M 0 2.5 C 0 1.119 1.119 0 2.5 0 L 15.5 0 C 16.881 0 18 1.119 18 2.5 L 18 15.5 C 18 16.881 16.881 18 15.5 18 L 2.5 18 C 1.119 18 0 16.881 0 15.5 Z"
                        fill={colors.animationForground}
                    ></path>
                    <path
                        d="M 8.216 3.99 C 8.616 3.484 9.384 3.484 9.784 3.99 L 11 5.525 C 12 6.775 13 8.4 13 9.9 C 13 12.109 11.209 13.9 9 13.9 C 6.791 13.9 5 11.9 5 9.9 C 5 8.4 6 6.775 7 5.525 Z"
                        fill={colors.animationForgroundLight}
                    ></path>
                </svg>
            </motion.div>
            <motion.div animate={linkControl} style={{ position: "absolute", opacity: 0 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
                    <path
                        d="M 0 2.5 C 0 1.119 1.119 0 2.5 0 L 15.5 0 C 16.881 0 18 1.119 18 2.5 L 18 15.5 C 18 16.881 16.881 18 15.5 18 L 2.5 18 C 1.119 18 0 16.881 0 15.5 Z"
                        fill={colors.animationForground}
                    ></path>
                    <path
                        d="M 6.147 7.396 C 6.537 6.758 7.463 6.758 7.853 7.396 L 11.803 13.859 C 12.108 14.359 11.748 15 11.163 15 L 3.283 15 C 2.502 15 2.023 14.145 2.43 13.479 Z"
                        fill={colors.animationForgroundLight}
                    ></path>
                    <path
                        d="M 12.156 10.826 C 12.549 10.208 13.451 10.208 13.844 10.826 L 15.522 13.463 C 15.946 14.129 15.467 15 14.678 15 L 10.866 15 C 10.274 15 9.916 14.347 10.234 13.847 Z"
                        fill={colors.animationForgroundLight}
                    ></path>
                    <path
                        d="M 13.5 3 C 14.328 3 15 3.672 15 4.5 C 15 5.328 14.328 6 13.5 6 C 12.672 6 12 5.328 12 4.5 C 12 3.672 12.672 3 13.5 3 Z"
                        fill={colors.animationForgroundLight}
                    ></path>
                </svg>
            </motion.div>
            <motion.div animate={colorControl} style={{ position: "absolute", opacity: 0 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
                    <path
                        d="M 0 2.5 C 0 1.119 1.119 0 2.5 0 L 15.5 0 C 16.881 0 18 1.119 18 2.5 L 18 15.5 C 18 16.881 16.881 18 15.5 18 L 2.5 18 C 1.119 18 0 16.881 0 15.5 Z"
                        fill={colors.animationForground}
                    ></path>
                    <path
                        d="M 10 4.5 C 10 4.019 9.388 3.816 9.1 4.2 L 4.6 10.2 C 4.353 10.53 4.588 11 5 11 L 7.5 11 C 7.776 11 8 11.224 8 11.5 L 8 13.575 C 8 14.047 8.595 14.256 8.89 13.887 L 13.35 8.312 C 13.612 7.985 13.379 7.5 12.96 7.5 L 10.5 7.5 C 10.224 7.5 10 7.276 10 7 Z"
                        fill={colors.animationForgroundLight}
                    ></path>
                </svg>
            </motion.div>
        </div>
    )
})
