import { V62, V63 } from "../types"
import { transform } from "../utils/transform"
import { _, exactCheck } from "../utils/exactCheck"
import { withoutProps } from "../utils/withoutProps"

export function migration_62_63(documentJSON: V62.Tree): V63.Tree {
    const migratedRoot = transform(documentJSON.root, node => {
        let duplicatedFrom = null

        if (node.duplicatedFromId) {
            duplicatedFrom = [node.duplicatedFromId]

            if (node.lastDuplicatedFromId && node.lastDuplicatedFromId !== node.duplicatedFromId) {
                duplicatedFrom.push(node.lastDuplicatedFromId)
            }
        }

        const migratedNode = withoutProps(
            {
                duplicatedFrom,
                ...node,
            },
            "duplicatedFromId",
            "lastDuplicatedFromId"
        )

        return exactCheck(migratedNode, _ as V63.TreeNode)
    })
    return { version: 63, root: migratedRoot }
}
