import { migrateDocument, isTree } from "@framerjs/document-migrations"
import { assert } from "@framerjs/shared"
import type { CanvasNode } from "./nodes/CanvasNode"
import { NodeTree } from "./NodeTree"
import { RootNode } from "./nodes/RootNode"
import { ScopeNode } from "./nodes/ScopeNode"
import { PageNode } from "./nodes/PageNode"
import { NodeID, MaybeNodeID, NullID } from "./nodes/NodeID"
import { WithChildren, withChildren, acceptsChild } from "./traits/Children"
import type { ReadonlyChildren, ImmutableNode } from "./nodes/MutableNode"
import { TreeNode, VectorNode, isDrawableNode, isVectorNode } from "./nodes/TreeNode"
import FrameNode, { isFrameNode } from "./nodes/FrameNode"
import { withClip } from "./traits/Clip"
import compareIndexArrays from "utils/compareIndexArrays"
import Matrix from "document/models/Matrix"
import { withShape } from "./traits/Shape"
import { withStroke } from "./traits/Stroke"
import { withRotation } from "./traits/Rotation"
import { getPathOperator } from "utils/pathOperator"
import Path from "document/models/Path"
import { closestCurveDistance } from "document/models/CanvasTree/utils/shapes"
import FramePoint from "document/models/FramePoint"
import { isPinnable } from "./traits/Pins"
import { withLock } from "./traits/Lock"
import { Rect, Point, Size } from "framer"
import { TemplateHelper } from "document/models/CanvasTree/nodes/TemplateHelper"
import { isCodeComponentNode } from "document/models/CanvasTree/nodes/CodeComponentNode"
import { isMaster, isReplica, isExternalMaster, IsMaster, isHiddenMaster } from "./traits/Template"
import { CanvasTreeVersion } from "document/models/CanvasTree/CanvasTreeVersion"
import type { BoundingBox } from "./nodes/CanvasNodeCache"
import { unhandledError } from "@framerjs/shared"
import { collectNodesWithIntersectingBoundingBox } from "../updateTreeCacheForVekter"
import { isVisibleNode } from "./traits/Visibility"
import { canvasNodeFromValue } from "./nodes/canvasNodeFromValue"
import { ReactComponentDefinitionProvider } from "./traits/CodeComponent"
import { isVariant } from "./traits/Variant"
import { withDOMLayout } from "./traits/DOMLayout"

export interface CanvasNodeConstructor<T extends CanvasNode> {
    new (...args: any[]): T
}

export interface CanvasTree {
    get<T extends CanvasNode>(id: MaybeNodeID): T | null
    getNodeAtStart<T extends CanvasNode>(id: MaybeNodeID): T | null
    getCurrentOrFuture<T extends CanvasNode>(id: MaybeNodeID): T | null
    forEachNode(cb: (node: CanvasNode) => void): void
    forEachNodeAtStart(cb: (node: CanvasNode) => void): void
    insertNode<T extends CanvasNode>(node: T, parentid?: MaybeNodeID, position?: number): T
    insertNodes<T extends CanvasNode>(node: T[]): this
    getNodes(ids: NodeID[]): CanvasNode[]
    withoutDescendants(nodes: CanvasNode[]): CanvasNode[]
    getTopMostChildNode<T>(nodes: (CanvasNode & T)[]): (CanvasNode & T) | null
    ancestors(id: NodeID): CanvasNode[]
    getNodesChangedByCommit(): CanvasNode[]
    /** Commit the tree, return a new copy-on-write version.
     * @onNodeChange Optional, can be used to get a callback per changed node. */
    commit(onNodeChanged?: (previous: CanvasNode | undefined, next: CanvasNode | undefined) => void): this
}

export class CanvasTree extends NodeTree {
    readonly serializationVersion = CanvasTreeVersion
    static minimumLegacySerializationVersion = 0

    readonly root: RootNode

    // both constructor and copy constructor
    private constructor(from?: NodeTree, root?: CanvasNode) {
        super(from, root)
    }

    static createEmpty() {
        return new CanvasTree()
    }

    static createWithDefaultPage() {
        const rootNode = new RootNode()
        const pageNode = new PageNode()
        rootNode.addChild(pageNode)
        return new CanvasTree(undefined, rootNode)
    }

    static createByAdoptingRoot(node: CanvasNode): CanvasTree {
        return new CanvasTree(undefined, node)
    }

    static createWithRoot(node: CanvasNode): CanvasTree {
        return new CanvasTree(undefined, node.cloneWithIds())
    }

    // used as factory by superclass
    newRootNode() {
        return new RootNode()
    }

    // used as factory constructor by superclass
    copy(root: CanvasNode): this {
        return new CanvasTree(this, root) as this
    }

    getParent(id: NodeID): (CanvasNode & WithChildren) | null {
        const parentNode = super.getParent(id) as CanvasNode | null
        if (parentNode === null || !withChildren(parentNode)) return null
        if (parentNode instanceof RootNode || parentNode instanceof ScopeNode) return null
        return parentNode
    }

    getParentId(node: CanvasNode): MaybeNodeID {
        return node.parentid
    }

    // compatibility
    static fromJS(object: unknown, componentDefinitionProvider: ReactComponentDefinitionProvider): CanvasTree | null {
        if (!isTree(object)) return null

        if (object.version < this.minimumLegacySerializationVersion) {
            return null
        }

        let documentJSON: ReturnType<typeof migrateDocument>

        try {
            // Run the new migrations.
            // This throws if the document version is higher than the latest known version.
            documentJSON = migrateDocument(object)
        } catch (err) {
            err.message = `Failed to migrate document: ${err.message}`
            unhandledError(err)
            return null
        }

        const root = canvasNodeFromValue(documentJSON.root)
        if (!root) return null

        const tree = CanvasTree.createWithRoot(root)
        return TemplateHelper.treeDidLoad(tree, []).didNonLinearMove(componentDefinitionProvider)
    }

    // compatility
    toJS(): any {
        return {
            version: this.serializationVersion,
            root: this.root.futureOrCurrent().toJS(),
        }
    }

    latestTree(): CanvasTree {
        return this.latest.tree as CanvasTree
    }

    // **** CANVAS NODE API ****

    getNode<T extends CanvasNode>(id: NodeID): (Readonly<T> & ReadonlyChildren) | null {
        if (id === this.root.id) return null
        return super.get(id) as any
    }

    getGroundNodesOfType<Node extends CanvasNode>(
        constructor: CanvasNodeConstructor<Node>,
        filter?: (node: Node) => boolean
    ) {
        const groundNodes = this.getGroundNodes(node => {
            if (node instanceof constructor) {
                return filter ? filter(node) : true
            }

            return false
        })

        return groundNodes as Node[]
    }

    getGroundNodes(filter?: (node: CanvasNode) => boolean) {
        const rootNode = this.root.futureOrCurrent()
        const groundNodes: CanvasNode[] = []

        for (const node of rootNode.children) {
            if (isHiddenMaster(node)) continue

            if (node instanceof ScopeNode) {
                const groundNodesForPage = node.getGroundNodes(filter)
                groundNodes.push(...groundNodesForPage)
                continue
            }

            if (filter ? filter(node) : true) {
                groundNodes.push(node)
            }
        }

        return groundNodes
    }

    getNodesWithPredicate(predicate: (node: CanvasNode) => boolean): CanvasNode[] {
        const result: CanvasNode[] = []
        const root = this.root
        this.root.walk(n => {
            if (n === root) return
            if (predicate(n)) result.push(n)
        })
        return result
    }

    /** Excludes variants */
    getLocalMasterComponents(): (CanvasNode & IsMaster)[] {
        return this.getNodesWithPredicate(n => isMaster(n) && !isVariant(n) && !isExternalMaster(n)) as (CanvasNode &
            IsMaster)[]
    }

    // Does a walk of the tree, starting with the top most node and walking down
    // to the start (or the root if start is null).
    //
    // Return `false` from the sideEffect function to stop.
    forEach(sideEffect: (node: TreeNode) => any, start: MaybeNodeID = NullID) {
        const start2: NodeID = start ? start : this.root.id

        function _forEach(node: ImmutableNode, _sideEffect: (node: TreeNode) => any) {
            let cont: any = undefined
            const children = node.children
            if (children) {
                for (let i = children.length - 1; i >= 0; i--) {
                    const child = children[i]
                    cont = _forEach(child, _sideEffect)
                }
            }
            if (cont === false) {
                return cont
            } // else
            return _sideEffect(node as TreeNode)
        }

        _forEach(this.getNode(start2)!, sideEffect)
    }

    getSubtreeNodes(start: MaybeNodeID, nodesToExclude?: NodeID[]) {
        const result: CanvasNode[] = []
        const toExclude = nodesToExclude ? new Set(nodesToExclude) : null

        const addTo = (nodes: CanvasNode[]) => {
            for (let i = 0, il = nodes.length; i < il; i++) {
                const node = nodes[i]
                if (toExclude && toExclude.has(node.id)) continue

                result.push(node)
                const children = node.children
                if (children) {
                    addTo(children)
                }
            }
        }

        if (!start) {
            addTo(this.getGroundNodes())
        } else {
            const startNode = this.getNode(start)
            if (startNode) {
                addTo([startNode])
            }
        }

        return result
    }

    create<T extends CanvasNode>(
        nodeClass: { new (...args: any[]): T },
        parentid: MaybeNodeID = this.root.id,
        attributes: Partial<T> = {}
    ): T {
        const node = new nodeClass()
        node.parentid = parentid
        node.set(attributes)

        this.insertNode(node, parentid)

        return node
    }

    getSiblings(node: ImmutableNode, includeSelf = true): CanvasNode[] {
        const parent = this.get(node.parentid!)
        const siblings = parent === null ? this.getGroundNodes() : parent.children

        assert(siblings, "Parent node must have children")
        return includeSelf ? siblings : siblings.filter(sibling => sibling.id !== node.id)
    }

    getGroundNodeFor(node: CanvasNode): CanvasNode {
        let groundNode = node

        while (groundNode && this.getParent(groundNode.id)) {
            groundNode = this.getParent(groundNode.id)!
        }

        return groundNode
    }

    getScopeNodeFor(node: CanvasNode): ScopeNode | null {
        let parent: CanvasNode | null = node

        while (parent) {
            if (parent instanceof ScopeNode) {
                return parent
            }

            parent = this.get(parent.parentid)
        }

        return null
    }

    /**
     * Returns the ground node common to all the nodes provided, or null if the
     * provided nodes have different ground nodes.
     *
     * Useful e.g. to determine if all selected nodes belong to the same ground
     * node.
     */
    getCommonGroundNode(nodes: CanvasNode[]): CanvasNode | null {
        if (nodes.length === 0) {
            return null
        }

        const firstGroundNode = this.getGroundNodeFor(nodes[0])
        const hasDifferentGroundNode = nodes.slice(1).some(n => this.getGroundNodeFor(n) !== firstGroundNode)

        return hasDifferentGroundNode ? null : firstGroundNode
    }

    getGroundFrameAncestor(id: NodeID, includeSelf: boolean = false): FrameNode | null {
        const node = this.get(id)

        if (node === null) {
            return null
        }

        const groundNode = this.getGroundNodeFor(node)
        if (groundNode instanceof FrameNode) {
            if (includeSelf || groundNode.id !== node.id) {
                return groundNode
            }
        }

        return null
    }

    // during commit, a node with node.cache.links set will trigger links.forEach(.asMutable())
    linkNodeChangesTo(node: CanvasNode, to: NodeID) {
        if (!node.cache.links) {
            node.cache.links = new Set<NodeID>()
        }
        node.cache.links.add(to)
    }

    // **** CANVAS API ****

    getRect(node: CanvasNode, pixelAlign: boolean = true): Rect {
        if (node.cache.parentDirectedRect) {
            return { ...node.cache.parentDirectedRect }
        }

        const parent = this.getParent(node.id)
        const parentUsesDOMRect = parent && withDOMLayout(parent) && parent.usesDOMRectCached()
        const nodeUsesDOMRect = withDOMLayout(node) && node.usesDOMRectCached()

        const shouldPixelAlign = pixelAlign && !isVectorNode(node) && !(nodeUsesDOMRect || parentUsesDOMRect)

        const parentSize = this.getParentSize(node, shouldPixelAlign)
        return node.rect(parentSize, shouldPixelAlign)
    }

    isRootVectorNode(node: VectorNode): boolean {
        const parent = this.getParent(node.id)
        return parent === null || !isVectorNode(parent)
    }

    isGroundNode(node: CanvasNode): boolean {
        const parent = super.getParent(node.id)

        if (node instanceof RootNode || node instanceof ScopeNode) {
            return false
        }

        return parent instanceof RootNode || parent instanceof ScopeNode
    }

    isAncestorOfNode(node: CanvasNode, maybeAncestorId: NodeID) {
        if (maybeAncestorId === node.parentid || maybeAncestorId === this.root.id) return true

        let ancestor = this.get(node.parentid)
        while (ancestor) {
            if (ancestor.id === maybeAncestorId) return true
            ancestor = this.get(ancestor.parentid)
        }

        return false
    }

    getAncestorThatMatches(
        node: CanvasNode,
        predicate: (node: CanvasNode) => boolean,
        includeSelf: boolean = false,
        topMost: boolean = false
    ): CanvasNode | null {
        const ancestors = this.ancestors(node.id)
        if (includeSelf && predicate(node)) {
            ancestors.unshift(node)
        }

        if (topMost) {
            ancestors.reverse()
        }

        const maybeFound: CanvasNode | undefined = ancestors.find(predicate)
        return maybeFound === undefined ? null : maybeFound
    }

    getAncestorOfType<T extends CanvasNode>(
        node: CanvasNode,
        constructor: CanvasNodeConstructor<T>,
        includeSelf: boolean = false,
        topMost: boolean = false
    ): T | null {
        const ancestors = this.ancestors(node.id)
        if (includeSelf && node instanceof constructor) {
            ancestors.unshift(node)
        }

        if (topMost) {
            ancestors.reverse()
        }

        const targetNode = ancestors.find(ancestor => {
            return ancestor instanceof constructor
        }) as T | undefined

        return targetNode ? targetNode : null
    }

    getBoundingFrameForNodes(nodes: CanvasNode[]) {
        if (nodes.length === 0) {
            return { x: 0, y: 0, width: 0, height: 0 }
        }

        let minX = Infinity
        let minY = Infinity
        let maxX = -Infinity
        let maxY = -Infinity
        for (let i = 0, il = nodes.length; i < il; i++) {
            const node = nodes[i]
            const frame = this.convertFrameToCanvas(node)

            let x = frame.x
            let y = frame.y
            let x2 = x + frame.width
            let y2 = y + frame.height

            if (Math.max(x, y, x2, y2) > Number.MAX_SAFE_INTEGER || Math.min(x, y, x2, y2) < Number.MIN_SAFE_INTEGER) {
                // eslint-disable-next-line no-console
                console.warn("Encountered bounding box with corrupted dimensions. Please contact Framer support.")
                x = y = x2 = y2 = 0
            }

            if (x < minX) {
                minX = x
            }
            if (y < minY) {
                minY = y
            }
            if (x2 > maxX) {
                maxX = x2
            }
            if (y2 > maxY) {
                maxY = y2
            }
        }
        return { x: minX, y: minY, width: maxX - minX, height: maxY - minY }
    }

    getBoundingFrame(page: ScopeNode): Rect {
        const groundNodes = page.getGroundNodes(isVisibleNode)
        return this.getBoundingFrameForNodes(groundNodes)
    }

    convertFrameToCanvas(node: CanvasNode, frame?: Rect): Readonly<Rect> {
        if (frame === undefined) return this.getCanvasRectCached(node)

        const corners = Rect.cornerPoints(frame)
        const convertedCorners = corners.map(corner => this.convertPointToCanvas(node, corner, true)) as Point[]
        return Rect.fromPoints(convertedCorners)
    }

    getNodesInRect(scope: ScopeNode, frame: Rect, partially = false): CanvasNode[] {
        const result: CanvasNode[] = []
        const points = Rect.cornerPoints(frame)

        this.getNodesInSpatialCache(scope, frame).forEach(node => {
            if (node.id !== this.root.id) {
                const drawableNode = node as CanvasNode
                const canvasFrame = this.convertFrameToCanvas(drawableNode)
                if (partially) {
                    if (Rect.intersects(canvasFrame, frame)) {
                        const localPoints = points.map(point => {
                            return this.convertPointToNode(drawableNode, point, true)
                        }) as [Point]
                        const localFrame = Rect.fromPoints(localPoints)
                        const layerFrame = Rect.atOrigin(this.getRect(drawableNode))
                        if (Rect.intersects(layerFrame, localFrame)) {
                            if (this.outOfClippedBounds(frame, drawableNode)) {
                                return
                            }
                            result.push(drawableNode)
                        }
                    }
                } else {
                    if (Rect.containsRect(frame, canvasFrame)) {
                        result.push(node)
                    }
                }
            }
        })
        return result
    }

    getNodesContainingFrame(scope: ScopeNode, frame: Rect): CanvasNode[] {
        const result: CanvasNode[] = []
        this.getNodesInSpatialCache(scope, frame).forEach(node => {
            if (!isDrawableNode(node)) {
                return
            }
            const canvasFrame = this.convertFrameToCanvas(node)
            if (Rect.containsRect(canvasFrame, frame)) {
                result.push(node)
            }
        })
        return result
    }

    outOfClippedBounds(point: Point | Rect, node: CanvasNode) {
        const clippedNodes: CanvasNode[] = this.ancestors(node.id).filter(ancestor => {
            return isDrawableNode(ancestor) && withClip(ancestor) && ancestor.clip
        }) as any

        return (
            clippedNodes.find(clippingAncestor => {
                const pointInAncestor = this.convertPointToNode(clippingAncestor, point, true)
                const nodeFrame = Rect.atOrigin(this.getRect(clippingAncestor))
                return Rect.intersects(nodeFrame, { width: 0, height: 0, ...point, ...pointInAncestor }) === false
            }) !== undefined
        )
    }

    convertRectToCanvasWithRotation(node: CanvasNode, frame?: Rect): Rect & { rotation: number } {
        if (frame === undefined) {
            frame = this.getRect(node)
            frame.x = 0
            frame.y = 0
        }
        const corners = Rect.cornerPoints(frame)
        const [c1, c2, , c4] = corners.map(corner => {
            return this.convertPointToCanvas(node, corner, true)
        }) as Point[]
        const width = Point.distance(c1, c2)
        const height = Point.distance(c1, c4)
        const rotation = Point.angle(c1, c2) + 90
        return { ...c1, width, height, rotation }
    }

    // TODO: This method returns the bounding rect instead of the rotated rect. Change the naming to make it clear which methods return bounding vs rotated rect.
    getCanvasRectCached(node: CanvasNode): Rect {
        const canvasFrame = node.cache.canvasRect
        if (canvasFrame) return canvasFrame

        const frame = this.getRect(node)
        frame.x = 0
        frame.y = 0

        const corners = Rect.cornerPoints(frame)
        const convertedCorners = corners.map(corner => this.convertPointToCanvas(node, corner, true)) as Point[]

        return (node.cache.canvasRect = Rect.fromPoints(convertedCorners))
    }

    convertFrameToNode(node: CanvasNode | null, frame: Rect, includeLayer = true): Rect {
        if (node === null) {
            return frame
        }
        const corners = Rect.cornerPoints(frame)
        const convertedCorners = corners.map(corner => {
            return this.convertPointToNode(node, corner, includeLayer)
        }) as Point[]
        return Rect.fromPoints(convertedCorners)
    }

    isNodeVisible(node: CanvasNode): boolean {
        return node.resolveValue("visible") !== false
    }

    getIndex(node: CanvasNode): number {
        const parent = this.get(node.parentid)
        const children = parent && withChildren(parent) ? parent.children : this.getGroundNodes()
        return children.indexOf(node)
    }

    // For sorting a random collection of nodes as they will be ordered on screen (first will be lowest)
    sortVisually(nodes: CanvasNode[]): CanvasNode[] {
        const indexedNodes = nodes.map(node => {
            const ancestors = this.getNodes(this.getAncestors(node.id))
            // Ancestors go from parent to grand-parent, etc
            ancestors.reverse()
            const indexes = ancestors.map(ancestor => this.getIndex(ancestor))
            indexes.push(this.getIndex(node))
            return [indexes, node]
        }) as [number[], CanvasNode][]
        // JS sorts arrays nicely, [1, 2, 3] will come before [2, 5]
        const sorted = indexedNodes.sort(([indexesA], [indexesB]) => compareIndexArrays(indexesA, indexesB))
        return sorted.map(([, node]) => node as CanvasNode)
    }

    convertPointToNode(node: CanvasNode | null, point: Point, includeNode = true): Point {
        if (node === null) return point

        if (includeNode) {
            const matrix = node.cache.matrix
            if (matrix) {
                return Matrix.convertPoint(matrix.inverse(), point)
            }
        }

        const ancestors = this.ancestors(node.id).slice(0, -1) as CanvasNode[]
        if (includeNode) {
            ancestors.unshift(node)
        }
        ancestors.reverse()
        return ancestors.reduce(
            (previousValue, ancestorNode: CanvasNode) =>
                Matrix.convertPoint(
                    ancestorNode.transformMatrix(this.getParentRect(ancestorNode)).inverse(),
                    previousValue
                ),
            point
        )
    }

    convertPointFromNodeToDescendant(
        node: CanvasNode,
        descendant: CanvasNode,
        point: Point,
        includeNode = true
    ): Point {
        let ancestorFound = false
        let convertedPoint = point
        const ancestorsReverse = [...descendant.ancestors()].reverse()
        if (includeNode) {
            ancestorsReverse.push(descendant)
        }
        for (const ancestor of ancestorsReverse) {
            if (!ancestorFound) {
                ancestorFound = ancestor.id === node.id
                continue
            }
            const matrix = ancestor.transformMatrix(this.getParentRect(ancestor)).inverse()
            convertedPoint = Matrix.convertPoint(matrix, convertedPoint)
        }
        if (!ancestorFound) throw Error("Node is not a descendant")
        return convertedPoint
    }

    getNodesAtPoint(
        scope: ScopeNode,
        point: Point,
        selectionCache: boolean = false,
        embiggen: boolean = false,
        canvasVisibleRect?: Rect
    ): CanvasNode[] {
        const result: CanvasNode[] = []

        const rect = { x: point.x, y: point.y, width: 0, height: 0 }
        if (embiggen) {
            rect.x -= 2
            rect.width += 4
            rect.y -= 2
            rect.height += 4
        }

        this.getNodesInSpatialCache(scope, rect).forEach(node => {
            if (!isDrawableNode(node)) {
                return
            }
            const localPoint = this.convertPointToNode(node, point, true)
            let nodeRect = Rect.atOrigin(this.getRect(node))

            if (embiggen) {
                if (nodeRect.width < 4) {
                    nodeRect.x -= 2
                    nodeRect.width = 4
                }
                if (nodeRect.height < 4) {
                    nodeRect.y -= 2
                    nodeRect.height = 4
                }
            }

            if (withShape(node) && withStroke(node) && node.strokeEnabled && !!node.strokeWidth) {
                nodeRect = Rect.inflate(nodeRect, node.strokeWidth / 2)
            }

            if (!Rect.containsPoint(nodeRect, localPoint)) {
                return
            }
            if (this.outOfClippedBounds(rect, node)) {
                const notAlreadySelected = !selectionCache || !node.isSelected()

                if (notAlreadySelected || !canvasVisibleRect) {
                    return
                }

                const boundingBoxEntirelyVisible = Rect.containsRect(canvasVisibleRect, nodeRect)
                if (!boundingBoxEntirelyVisible) {
                    return
                }
            }
            if (withShape(node) && withStroke(node)) {
                const calculatedPaths = node.calculatedPaths()
                const fillContainsPoint = getPathOperator().containsPoint(node, localPoint)
                const pathClosed = Path.isClosed(calculatedPaths)
                const strokeEnabled = node.strokeEnabled && !!node.strokeWidth
                if (strokeEnabled && !node.fillEnabled) {
                    // Check if stroke in range
                    const distance = closestCurveDistance(node, localPoint)
                    const strokeWidth = node.strokeWidth ? node.strokeWidth : 0
                    if (pathClosed && node.strokeAlignment === "inside") {
                        if (!fillContainsPoint || distance > Math.max(strokeWidth, 2)) {
                            return
                        }
                    } else {
                        if (distance > Math.max(0.5 * strokeWidth, 2)) {
                            return
                        }
                    }
                } else if (pathClosed && !fillContainsPoint) {
                    return
                }
            }
            result.push(node)
        })
        return result
    }

    // To speed up spatial queries, updateCache() keeps the bounding box of
    // every node up to date, such that it fits that node and all its children.
    getNodesInSpatialCache(scope: ScopeNode, rect: Rect): CanvasNode[] {
        scope = scope.futureOrCurrent()

        const results: CanvasNode[] = []
        const box: BoundingBox = {
            minX: rect.x,
            maxX: rect.x + rect.width,
            minY: rect.y,
            maxY: rect.y + rect.height,
        }

        collectNodesWithIntersectingBoundingBox(scope, box, results)

        return results
    }

    getParentSize(node: CanvasNode, pixelAlign: boolean = true): Size | null {
        return this.getParentRect(node, pixelAlign)
    }

    getParentRect(node: CanvasNode, pixelAlign: boolean = true, allowCache: boolean = true): Rect | null {
        if (allowCache) {
            const parentRect = node.cache.parentRect
            if (parentRect) {
                return parentRect
            }
        }

        const parent = this.getParent(node.id)
        return parent ? this.getRect(parent, pixelAlign) : null
    }

    convertPointToCanvas(node: CanvasNode, point: Point, includeNode = true, rectOverride?: Rect): Point {
        if (includeNode && rectOverride === undefined) {
            const matrix = node.cache.matrix
            if (matrix) {
                return Matrix.convertPoint(matrix, point)
            }
        }

        const ancestors = this.ancestors(node.id).slice(0, -1) // without CanvasNode
        if (includeNode) {
            ancestors.unshift(node)
        }
        return ancestors.reduce((previousValue, ancestor: CanvasNode) => {
            if (rectOverride && ancestor.id === node.id) {
                return Matrix.convertPoint(
                    ancestor.transformMatrix(this.getParentRect(ancestor), rectOverride),
                    previousValue
                )
            }
            return Matrix.convertPoint(ancestor.transformMatrix(this.getParentRect(ancestor)), previousValue)
        }, point)
    }

    convertFramePointsToCanvas(node: CanvasNode): FramePoint[] {
        const framePoints = FramePoint.fromFrame(this.getRect(node))
        return framePoints.map(point => ({ ...point, ...this.convertPointToCanvas(node, point, true) }))
    }

    isConstrained(node: ImmutableNode): boolean {
        if (!node) {
            return false
        }

        if (isDrawableNode(node) && isPinnable(node)) {
            const parent = this.getParent(node.id)
            return parent !== null
        } else {
            return false
        }
    }

    getTopLevelFrameNodes(nodes: CanvasNode[], includeSelf = false) {
        const topLevelFrameNodes: MaybeNodeID[] = []

        const marked = new Set<NodeID>()
        nodes.forEach(originalNode => {
            if (!originalNode) {
                return
            }
            let node = originalNode

            while (!marked.has(node.id)) {
                marked.add(node.id)
                const parent = this.getParent(node.id)
                if (!parent) {
                    if (isFrameNode(node) && (node.id !== originalNode.id || includeSelf)) {
                        topLevelFrameNodes.push(node.id)
                    } else if (!topLevelFrameNodes.includes(NullID)) {
                        topLevelFrameNodes.push(NullID)
                    }
                    return
                }
                node = parent
            }
        })
        return topLevelFrameNodes
    }

    totalAncestorRotation(node: CanvasNode, includeLayer = true) {
        const ancestorsNodes = this.getAncestors(node.id).map(id => this.getNode(id)!)
        if (includeLayer) {
            ancestorsNodes.push(node)
        }
        return ancestorsNodes.reduce((total, ancestor) => {
            if (withRotation(ancestor)) {
                const rotation = ancestor.resolveValue("rotation") ?? 0
                return Math.round(total + rotation)
            }
            return total
        }, 0)
    }

    getPotentialParents(
        scope: ScopeNode,
        canvasRect: Rect,
        mouse: Point | null,
        child?: CanvasNode,
        skipContainingFrames?: boolean
    ): CanvasNode[] {
        const layersInFrames: CanvasNode[] = []

        this.getNodesContainingFrame(scope, canvasRect).forEach((node: CanvasNode) => {
            if (this.outOfClippedBounds(canvasRect, node)) {
                return
            }
            if (!withChildren(node)) {
                return
            }
            if (isCodeComponentNode(node)) return
            layersInFrames.push(node)
        })

        const framesNodesUnderMouse: FrameNode[] = []

        scope.getGroundNodesOfType(FrameNode).forEach((node: FrameNode) => {
            const frameRect = this.getRect(node)
            const skip = skipContainingFrames && Rect.containsRect(canvasRect, frameRect)
            if (!Rect.intersects(frameRect, canvasRect) || skip) {
                return
            }
            if (mouse && Rect.containsPoint(frameRect, mouse)) {
                framesNodesUnderMouse.push(node)
            }
        })

        return [...layersInFrames, ...framesNodesUnderMouse].filter((node: FrameNode) => {
            if (node.originalid) {
                return false
            }
            if (withLock(node) && node.locked) {
                return false
            }
            if (!node.cache.visible) {
                return false
            }
            if (!this.isNodeVisible(node)) {
                return false
            }
            if (child && !acceptsChild(this, node, child, scope.id)) {
                return false
            }
            return true
        })
    }

    clearSelectionCache() {
        this.root.walk(n => (n.cache.selected = false))
    }

    didNonLinearMove(componentDefinitionProvider: ReactComponentDefinitionProvider): this {
        const nodesWithUpdatedLinks = new Set<NodeID>()
        const masters = new Set<NodeID>()
        this.root.futureOrCurrent().walk(node => {
            if (isMaster(node)) {
                if (masters.has(node.id)) return
                masters.add(node.id)
                node.cache.replicaInstances = null
            } else if (isReplica(node)) {
                const master = TemplateHelper.getMaster(this, node)
                if (!master) return
                if (!masters.has(master.id)) {
                    masters.add(master.id)
                    master.cache.replicaInstances = null
                }
                TemplateHelper.registerInInheritedNode(master, node)
            }

            if (node.cache.links) {
                if (nodesWithUpdatedLinks.has(node.id)) return
                nodesWithUpdatedLinks.add(node.id)
                node.cache.links = null
            }

            if (isCodeComponentNode(node)) {
                const ids = node.allSlotContent(componentDefinitionProvider)
                for (const id of ids) {
                    const child = this.get(id)
                    if (!child) return
                    let links = child.cache.links
                    if (!links || !nodesWithUpdatedLinks.has(id)) {
                        nodesWithUpdatedLinks.add(id)
                        links = child.cache.links = new Set<NodeID>()
                    }
                    links.add(node.id)
                }
            }
        })
        return this
    }
}
