import { css } from "linaria"
import { colors, dimensions, ZIndex } from "../tokens"

export const tooltip = css`
    --delay: 0s;
    position: absolute;
    z-index: ${ZIndex.tooltip};
    top: calc(100% + 1px);
    justify-content: center;
    padding: 14px;
    margin-top: ${dimensions.tooltipArrowHeight}px;
    animation: scale-in 400ms ease both var(--delay);
    background: ${colors.tooltipBackground};
    background-color: ${colors.tooltipBackground};
    border-radius: 8px;
    color: #fff;
    font-size: 12px;
    font-weight: 700; /* TODO: Audit (either change to fonts.weight.heading or add token) */
    pointer-events: none;
    text-align: center;
    transform-origin: top center;
    white-space: nowrap;

    @keyframes scale-in {
        0.00% {
            opacity: 0;
            transform: scale(0);
        }
        41.24% {
            transform: scale(1.04);
        }
        100.00% {
            opacity: 1;
            transform: scale(1);
        }
    }
`

export const arrow = css`
    position: absolute;
    top: calc(${dimensions.tooltipArrowHeight}px * -1);
    left: 50%;
    color: ${colors.tooltipBackground};

    /* Fix subpixel line offset caused by scale animation. */
    transform: translateY(0.4px) translateX(-50%);
`

export const container = css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`
