import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springBouncy, wait, animationDelay } from "./utils"
import { colors } from "../tokens"

const transition = { delay: 0.3, ...springBouncy }

export const AnimationComment = React.memo(function AnimationComment() {
    const bubble1 = useAnimation()
    const bubble2 = useAnimation()

    async function animation() {
        await wait(animationDelay)

        await Promise.all([
            bubble1.start({
                scale: 0.6,
                opacity: 0.5,
                transition: transition,
            }),
            bubble2.start({
                scale: 1,
                opacity: 1,
                transition: transition,
            }),
        ])

        return await Promise.all([
            bubble1.start({
                scale: 1,
                opacity: 1,
                transition: transition,
            }),
            bubble2.start({
                scale: 0.6,
                opacity: 0.5,
                transition: transition,
            }),
        ])
    }

    useLoop(animation)

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                paddingTop: 10,
                width: 40,
                height: 40,
                position: "relative",
            }}
        >
            <motion.div
                animate={bubble1}
                style={{
                    left: -7,
                    position: "absolute",
                    transformOrigin: "bottom left",
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                    <path
                        d="M 0 16 C 0 7.163 7.163 0 16 0 L 16 0 C 24.837 0 32 7.163 32 16 L 32 16 C 32 24.837 24.837 32 16 32 L 3.343 32 C 1.497 32 0 30.503 0 28.657 Z"
                        fill={colors.animationForground}
                    ></path>
                </svg>
            </motion.div>
            <motion.div
                initial={{ scale: 0.6, opacity: 0.5 }}
                animate={bubble2}
                style={{
                    left: 15,
                    position: "absolute",
                    transformOrigin: "bottom right",
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                    <path
                        d="M 32 16 C 32 7.163 24.837 0 16 0 L 16 0 C 7.163 0 0 7.163 0 16 L 0 16 C 0 24.837 7.163 32 16 32 L 28.657 32 C 30.503 32 32 30.503 32 28.657 Z"
                        fill={colors.animationForground}
                    ></path>
                </svg>
            </motion.div>
        </div>
    )
})
