import { OrderedMap } from "immutable"
import { getRootRecord } from "document/models/CanvasTree/records/RootRecord"
import { setDefaults } from "document/models/CanvasTree/nodes/MutableNode"
import { CanvasNode } from "./CanvasNode"
import type { TokenId, TokenNode, TokenClass, TokenMap } from "./TokenNode"
import { removeTokenFromNode } from "../utils/tokens"
import assert from "assert"
import type { WithChildren } from "../traits/Children"
import { withClassDiscriminator } from "utils/withClassDiscriminator"

export function isRootNode<T>(node: T): node is T & RootNode {
    return node instanceof RootNode
}

export class RootNode extends withClassDiscriminator("RootNode", CanvasNode) implements WithChildren {
    children: CanvasNode[]
    fillColor: string

    /** This functions like a sort-of preference to render background blur
     * filters, which is an experimental feature in browsers, and often very
     * demanding to render. The rendering is disabled by default except for
     * older documents. */
    disableBackdropFilters: boolean

    /** TokenNodes local to this document */
    private tokens: TokenMap = OrderedMap()

    constructor(properties?: Partial<RootNode>) {
        super(undefined)
        setDefaults<RootNode>(this, getRootRecord(), properties)
    }

    toJS() {
        const obj = super.toJS()
        // We maintain both the tokens object and an array to allow both
        // quick lookup of token values from the document JSON as well as
        // maintaining the sort order when re-creating the OrderedMap.
        obj["tokensIndex"] = Array.from(this.tokens.keys() as any)
        return obj
    }

    getToken(id: TokenId): TokenNode | null {
        return this.tokens.get(id) || null
    }

    /** Hash code representing the contents of the `tokens` map. */
    getTokensHash(): number {
        return this.tokens.hashCode()
    }

    getAllTokens(): TokenNode[] {
        return this.tokens.toArray()
    }

    getTokensOfType<T extends TokenNode>(type: TokenClass<T>): T[] {
        return this.tokens.toArray().filter((value: TokenNode): value is T => value instanceof type)
    }

    /**
     * Deletes a TokenNode from the RootNode.
     * NOTE: You must call CanvasTree.commit() before calling this method
     * again otherwise changes will be overwritten.
     */
    deleteToken(token: TokenNode) {
        // TODO: In order for this to be called multiple times in the same
        // transaction we need to be updating the "future" version of the
        // tokens map.
        const tokens = this.tokens.remove(token.id)
        this.tree().updateNode(this, { tokens })

        // Replace all references with the token value
        this.walk(node => {
            const update = removeTokenFromNode(node, token)
            if (update) this.tree().updateNode(node, update)
        })
    }

    /**
     * Adds a TokenNode to the RootNode.
     * NOTE: You must call CanvasTree.commit() before calling this method
     * again otherwise changes will be overwritten.
     */
    setToken(token: TokenNode) {
        // TODO: In order for this to be called multiple times in the same
        // transaction we need to be updating the "future" version of the
        // tokens map.
        const tokens = this.tokens.set(token.id, token)
        this.tree().updateNode(this, { tokens })
    }

    moveToken(fromIndex: number, toIndex: number) {
        const entries = Array.from(this.tokens.entries() as any)
        const size = entries.length

        assert(toIndex < size && toIndex >= 0, `toIndex is out of bounds: ${toIndex} in ${size}`)
        assert(fromIndex < size && fromIndex >= 0, `fromIndex is out of bounds: ${fromIndex} in ${size}`)

        const [token] = entries.splice(fromIndex, 1)
        entries.splice(toIndex, 0, token)

        // TODO: In order for this to be called multiple times in the same
        // transaction we need to be updating the "future" version of the
        // tokens map.
        this.tree().updateNode(this, { tokens: OrderedMap(entries) })
    }
}
