import { css } from "linaria"
import { colors, cursors, dimensions, fonts } from "../tokens"

export const button = css`
    position: relative;
    height: ${dimensions.inputHeight}px;
    padding: 0 ${dimensions.inputPaddingHorizontal}px 1px;
    border: none;
    margin: 0;
    -webkit-appearance: none;
    background-color: ${colors.buttonBackground};
    border-radius: ${dimensions.inputRadius}px;
    color: ${colors.buttonText};
    cursor: default;
    font-size: ${fonts.size.base}px;
    font-weight: ${fonts.weight.base};
    outline: none;
    text-decoration: none;
    transition: background-color 0.2s;
    -webkit-user-select: none;
    user-select: none;

    :disabled {
        opacity: 0.5;
    }

    :not(:disabled) {
        cursor: ${cursors.button};
    }

    :focus:not(:disabled),
    :hover:not(:disabled) {
        background-color: ${colors.buttonBackgroundHover};
    }

    :active:focus:not(:disabled),
    :active:hover:not(:disabled) {
        background-color: ${colors.buttonBackgroundActive};
    }
`

export const buttonLarge = css`
    height: ${dimensions.inputHeightLarge}px;
    font-weight: ${fonts.weight.baseActive};
`

export const buttonPrimary = css`
    background-color: ${colors.buttonBackgroundPrimary};
    color: ${colors.buttonTextPrimary};

    :focus:not(:disabled),
    :hover:not(:disabled) {
        background-color: ${colors.buttonBackgroundPrimaryHover};
    }

    :active:focus:not(:disabled),
    :active:hover:not(:disabled) {
        background-color: ${colors.buttonBackgroundPrimaryActive};
    }
`

export const buttonLink = css`
    min-width: auto;
    height: auto;
    padding: 0;
    border: none;
    background: none;
    border-radius: 0;
    color: ${colors.tint};

    :focus:not(:disabled),
    :hover:not(:disabled),
    :active:focus:not(:disabled),
    :active:hover:not(:disabled) {
        background: none;
        color: ${colors.tintDark};
    }

    &.${buttonLarge} {
        font-size: ${fonts.size.heading}px;
    }
`

export const buttonDestructive = css`
    background-color: ${colors.buttonBackgroundDestructive};
    color: ${colors.buttonTextDestructive};

    :focus:not(:disabled),
    :hover:not(:disabled) {
        background-color: ${colors.buttonBackgroundDestructiveHover};
    }

    :active:focus:not(:disabled),
    :active:hover:not(:disabled) {
        background-color: ${colors.buttonBackgroundDestructiveActive};
    }
`

export const titleWrapper = css`
    min-width: 0;
`

export const customElement = css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
`
