import { polygonDefaults } from "document/models/CanvasTree/traits/Polygon"
import { getShapeNodeRecord } from "./ShapeNodeRecord"
import { radiusDefaults } from "document/models/CanvasTree/traits/Radius"
import { memoRecord } from "../memoRecord"

export const getPolygonShapeRecord = memoRecord(() => {
    return {
        ...getShapeNodeRecord(),
        ...radiusDefaults,
        ...polygonDefaults,
    }
})
