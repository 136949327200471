import * as React from "react"
import { cx } from "linaria"
import * as styles from "./Spinner.styles"
import type { OverrideType, HTMLDivAttributes } from "./types"

export type SpinnerProps = OverrideType<
    HTMLDivAttributes,
    {
        /** Size of the spinner */
        size?: "normal" | "medium" | "large"
        /** Set the spinner to have a static position inline with other content */
        inline?: boolean
    }
>

function styleForSize(size: SpinnerProps["size"]) {
    switch (size) {
        case "normal":
            return styles.normalStyle
        case "medium":
            return styles.mediumStyle
        case "large":
            return styles.largeStyle
    }
}

export const Spinner = ({ size = "normal", inline = false, className, ...rest }: SpinnerProps) => {
    return (
        <div
            className={cx(
                className,
                styles.spin,
                styles.baseStyle,
                styleForSize(size),
                !inline && styles.centeredStyle
            )}
            {...rest}
        />
    )
}
