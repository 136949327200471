import { ConstraintMask, ConstraintValues, DimensionType } from "framer"
import { Pins, CenterPins, SizeDimensionType, canSetConstraint } from "document/models/ConstraintSolver"
import type { CanvasTree, CanvasNode } from "document/models/CanvasTree"
import { isNumber } from "utils/typeChecks"

export interface WithPins extends Pins, SizeDimensionType, CenterPins {
    constraintsLocked: boolean
}

export interface WithConstraints {
    constraints: () => ConstraintMask
    constraintValues: () => ConstraintValues
}

export const pinDefaults: WithPins = {
    constraintsLocked: false,
    left: 0,
    right: null,
    top: 0,
    bottom: null,
    centerAnchorX: 0,
    centerAnchorY: 0,
    widthType: DimensionType.FixedNumber,
    heightType: DimensionType.FixedNumber,
}

const key: keyof WithPins = "constraintsLocked"

export function isPinnable(node: CanvasNode): node is CanvasNode & WithPins & WithConstraints {
    return key in node
}

export function reducePins(
    tree: CanvasTree,
    node: CanvasNode,
    result: {
        constraintsLocked?: boolean | null
        pinLeft?: boolean | null
        pinRight?: boolean | null
        pinTop?: boolean | null
        pinBottom?: boolean | null
        widthFactor?: boolean | null
        heightFactor?: boolean | null
        canSetLeft?: boolean
        canSetTop?: boolean
        canSetBottom?: boolean
        canSetRight?: boolean
    }
) {
    if (!isPinnable(node)) return
    if (node.cache.parentDirected) return

    if (tree.getParent(node.id) === null) return

    if (result.constraintsLocked === undefined) {
        result.constraintsLocked = node.constraintsLocked
    } else if (result.constraintsLocked !== null && result.constraintsLocked !== node.constraintsLocked) {
        result.constraintsLocked = null
    }

    if (result.pinLeft !== null) {
        const left = isNumber(node.left) ? true : false
        if (result.pinLeft === undefined) {
            result.pinLeft = left
        } else if (result.pinLeft !== left) {
            result.pinLeft = null
        }
    }
    if (result.pinRight !== null) {
        const right = isNumber(node.right) ? true : false
        if (result.pinRight === undefined) {
            result.pinRight = right
        } else if (result.pinRight !== right) {
            result.pinRight = null
        }
    }
    if (result.pinTop !== null) {
        const top = isNumber(node.top) ? true : false
        if (result.pinTop === undefined) {
            result.pinTop = top
        } else if (result.pinTop !== top) {
            result.pinTop = null
        }
    }
    if (result.pinBottom !== null) {
        const bottom = isNumber(node.bottom) ? true : false
        if (result.pinBottom === undefined) {
            result.pinBottom = bottom
        } else if (result.pinBottom !== bottom) {
            result.pinBottom = null
        }
    }
    if (result.widthFactor !== null) {
        const widthFactor = node.widthType === DimensionType.Percentage
        if (result.widthFactor === undefined) {
            result.widthFactor = widthFactor
        } else if (result.widthFactor !== widthFactor) {
            result.widthFactor = null
        }
    }
    if (result.heightFactor !== null) {
        const heightFactor = node.heightType === DimensionType.Percentage
        if (result.heightFactor === undefined) {
            result.heightFactor = heightFactor
        } else if (result.heightFactor !== heightFactor) {
            result.heightFactor = null
        }
    }

    const constraints = node.constraints()
    if (result.canSetTop && !canSetConstraint(constraints, "top")) result.canSetTop = false
    if (result.canSetBottom && !canSetConstraint(constraints, "bottom")) result.canSetBottom = false
    if (result.canSetLeft && !canSetConstraint(constraints, "left")) result.canSetLeft = false
    if (result.canSetRight && !canSetConstraint(constraints, "right")) result.canSetRight = false
}
