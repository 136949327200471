import { css } from "linaria"
import { colors } from "../tokens/colors"
import { shadows } from "../tokens/shadows"
import { dimensions } from "../tokens/dimensions"
import { cursors, fonts } from "../tokens"

export const titleWrapper = css`
    min-width: 0;
`

export const divider = css`
    width: 1px;
    height: ${dimensions.inputDividerHeight}px;
    background-color: ${colors.segmentedControlDivider};
    border-radius: 2px;
    transition: opacity 0.2s;
`

export const hiddenDivider = css`
    opacity: 0;
`

export const segmentedControlLarge = css``

export const segmentedControlSegmentBackground = css`
    position: absolute;

    ::after {
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        left: 1px;
        background-color: ${colors.segmentedControlItemBackgroundSelected};
        border-radius: 3px;
        box-shadow: ${shadows.buttonSelected};
        content: "";
    }

    .${segmentedControlLarge} &:after {
        top: 2px;
        right: 2px;
        bottom: 2px;
        left: 2px;
    }
`

export const segmentedControlSegmentBackgroundAnimated = css``

export const segment = css`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    color: ${colors.segmentedControlItemText};
    font-size: ${fonts.size.base}px;
    font-weight: ${fonts.weight.base};
    line-height: 22px;
    transition: color 0.2s;
    -webkit-user-select: none;
    user-select: none;

    &:hover {
        color: ${colors.segmentedControlItemTextHover};
    }

    /* Fixes a rendering issue, when switching from and to active tint color */
    & svg {
        will-change: transform;
    }

    &:not(:disabled) {
        cursor: ${cursors.button};
    }
`

export const segmentDisabled = css`
    opacity: 0.2;
    pointer-events: none;
`

export const segmentSelected = css`
    color: ${colors.segmentedControlItemTextSelected};
    font-weight: ${fonts.weight.baseActive};

    &:hover {
        color: ${colors.segmentedControlItemTextSelectedHover};
    }
`

export const segmentedControl = css`
    display: flex;
    overflow: hidden;
    flex-direction: row;
    border: 1px solid ${colors.segmentedControlBorder};
    background-color: ${colors.segmentedControlBackground};
    border-radius: ${dimensions.inputRadius}px;
    cursor: default;
    text-align: center;
    -webkit-user-select: none;
    user-select: none;
`

export const verticalSegmentedControl = css`
    flex-direction: column;
`

export const verticalSegmentsWrapper = css`
    flex-direction: column;

    & .${segment} {
        width: 100%;
        height: 1px;
        justify-content: flex-start;
        padding: 0 ${dimensions.inputPaddingHorizontalLarge}px;
    }

    & .${segmentedControlSegmentBackground} {
        width: 100%;
    }

    & .${segmentedControlSegmentBackgroundAnimated} {
        transition: top 0.2s cubic-bezier(0.2, 0, 0, 1);
    }
`

export const segmentsWrapper = css`
    position: relative;
    display: flex;
    flex: 1;
    align-content: stretch;
    align-items: center;

    &:not(.${verticalSegmentsWrapper}) {
        flex-direction: row;

        & .${segment} {
            width: 1px;
            height: 100%;
        }

        & .${segmentedControlSegmentBackground} {
            height: 100%;
        }

        & .${segmentedControlSegmentBackgroundAnimated} {
            transition: left 0.2s cubic-bezier(0.2, 0, 0, 1);
        }
    }
`

export const segmentedControlDisabled = css`
    & > a {
        opacity: 0.2;
        pointer-events: none;
    }
`
