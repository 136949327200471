import type { EventAction } from "document/models/EventAction"
import type { EventActionInfo } from "document/models/CanvasTree/traits/EventActions"
import type { SetVariantType } from "../../../../runtime/src/sandbox/actions/useSetVariant"
import { HardCodedCodeIdentifier } from "document/models/CanvasTree/traits/utils/hardCodedCodeComponentIdentifiers"
import { isSetVariantAction, isSetVariantType } from "document/models/CanvasTree/actions/variantActions"
import { isString } from "utils/typeChecks"
import { wrapInlineHandler } from "./inlineValues"
import { isTriggerEventAction } from "document/models/CanvasTree/actions/triggerEventActions"

function createSetVariantAction(type: SetVariantType, variantId?: string): string | void {
    if (type === "cycle") return `setVariant(CycleVariantState)`
    if (variantId) return `setVariant("${variantId}")`
}

function extractSetVariantAction(action: EventAction, key: "controls"): string | void
function extractSetVariantAction(action: EventActionInfo, key: "info"): string | void
function extractSetVariantAction(action: EventAction | EventActionInfo, key: string): string | void {
    const type = action[key]?.type?.value
    const variantId = action[key]?.variantId?.value
    if (!isSetVariantType(type)) return

    return createSetVariantAction(type, variantId)
}

function extractTriggerEventAction(action: EventAction, key: "controls"): string | void
function extractTriggerEventAction(action: EventActionInfo, key: "info"): string | void
function extractTriggerEventAction(action: EventAction | EventActionInfo, key: string): string | void {
    const variableId = action[key].id?.value
    if (!isString(variableId)) return

    return variableId
}

const createArrowFunction = (fn: string) => `() => ${fn}`

function createLogicalHandler({ eventActions, otherActions }: { eventActions: string[]; otherActions: string[] }) {
    const hasEventActions = eventActions.length
    const hasOtherActions = otherActions.length
    const singleOtherAction = otherActions.length === 1 ? otherActions[0] : false

    // Event actions are generic callbacks that don't contain arguments, so they
    // don't need to be wrapped in an arrow function, just called if they are
    // defined by `callEach`.
    const chainedEventActions = `callEach(${eventActions.join(", ")})`
    // On the other hand we must wrap other actions in an arrow function so that
    // they are not invoked on initialization, and instead called when `calEach`
    // is invoked.
    const chainedOtherActions = `callEach(${otherActions.map(createArrowFunction).join(", ")})`

    if (hasEventActions && hasOtherActions) {
        return `${eventActions.join(" || ")} ? ${chainedEventActions} : ${chainedOtherActions}`
    } else if (hasEventActions) {
        return chainedEventActions
    } else if (singleOtherAction) {
        return singleOtherAction
    } else if (hasOtherActions) {
        return chainedOtherActions
    }
}

// createNodeEventHandler and createOverrideEventHandler are very similar, but
// are separate functions for 2 reasons.
// 1.   When we create inline actions for the primary tree, we process the
//      return value of node.getActions(), where each key has EventActionInfo[]
//      values. However, when we process actions on overrides each key has
//      EventAction[] values, because the values are readonly.
// 2.   We only wrap inline actions in the action string wrapper when we process
//      overrides.

export function createNodeEventHandler(actionInfo: EventActionInfo[]): string | void {
    const eventActions: string[] = []
    const otherActions: string[] = []
    for (const action of actionInfo) {
        if (action.identifier === HardCodedCodeIdentifier.setVariantAction) {
            const newAction = extractSetVariantAction(action, "info")
            if (newAction) otherActions.push(newAction)
        } else if (action.identifier === HardCodedCodeIdentifier.triggerEventAction) {
            const newAction = extractTriggerEventAction(action, "info")
            if (newAction) eventActions.push(newAction)
        }
    }

    const handler = createLogicalHandler({ eventActions, otherActions })
    if (handler) return createArrowFunction(handler)
}

export function createOverrideEventHandler(actionInfo?: EventAction[]): string | null {
    if (!actionInfo) return null

    const eventActions: string[] = []
    const otherActions: string[] = []

    for (const action of actionInfo) {
        if (isSetVariantAction(action)) {
            const newAction = extractSetVariantAction(action, "controls")
            if (newAction) otherActions.push(newAction)
        } else if (isTriggerEventAction(action)) {
            const newAction = extractTriggerEventAction(action, "controls")
            if (newAction) eventActions.push(newAction)
        }
    }

    const handler = createLogicalHandler({ eventActions, otherActions })
    if (handler) return wrapInlineHandler(createArrowFunction(handler))

    return null
}
