import type { List } from "immutable"
import type { CanvasTree } from "document/models/CanvasTree/CanvasTree"
import { CanvasNode } from "document/models/CanvasTree/nodes/CanvasNode"
import { isVectorNode } from "document/models/CanvasTree/nodes/TreeNode"
import ShapeGroupNode from "document/models/CanvasTree/nodes/ShapeGroupNode"
import BooleanShapeNode from "document/models/CanvasTree/nodes/shapes/BooleanShapeNode"
import { stringFromNodeID } from "document/models/CanvasTree/nodes/NodeID"
import type { WithName } from "document/models/CanvasTree/traits/Name"
import type { WithAspectRatio } from "document/models/CanvasTree/traits/AspectRatioLock"
import type { WithRotation } from "document/models/CanvasTree/traits/Rotation"
import type { WithStroke } from "document/models/CanvasTree/traits/Stroke"
import type { WithLock } from "document/models/CanvasTree/traits/Lock"
import type { WithVisibility } from "document/models/CanvasTree/traits/Visibility"
import type { WithSize } from "document/models/CanvasTree/traits/Size"
import type { WithPosition } from "document/models/CanvasTree/traits/Position"
import type { ExportOptions } from "document/models/CanvasTree"
import type { WithExport } from "document/models/CanvasTree/traits/Export"
import { VectorProperties, ConvertColor, StrokeAlignment, LineJoin, LineCap, LayerProps, ImageFit } from "framer"
import { getDefaultName } from "document/components/utils/nodes"
import type { WithBoxShadow } from "document/models/CanvasTree/traits/BoxShadow"
import type { BoxShadow } from "document/models/Shadow"
import { collectBoxShadowPropsForNode } from "document/models/CanvasTree/traits/utils/collectBoxShadowForNode"
import type { WithOptionalFill, FillType } from "document/models/CanvasTree/traits/Fill"
import { collectFillPropsForNode } from "document/models/CanvasTree/traits/utils/collectBackgroundForNode"
import type { RadialGradient, LinearGradient } from "document/models/Gradient"
import { wrapInShapeContainer } from "document/models/CanvasTree/traits/utils/wrapInShapeContainer"
import type { WithOpacity } from "../Opacity"
import type { VariableReference } from "../VariableReference"
import { isVariableReference } from "../VariableReference"
import { Attribute } from "../../utils/buildJSX"
import { TagProps } from "variants/types"
import { attributesFromProps } from "variants/utils/attributesFromProps"
import { collectPartialStroke, collectPath } from "../collectProps"

export function withBaseShapeTraits<T extends new (...args: any[]) => CanvasNode>(_Base: T) {
    return class extends CanvasNode
        implements
            WithName,
            WithVisibility,
            WithLock,
            WithAspectRatio,
            WithOptionalFill,
            WithOpacity,
            WithRotation,
            WithSize,
            WithPosition,
            WithStroke,
            WithBoxShadow,
            WithExport {
        fillEnabled: boolean
        fillColor: string | VariableReference
        fillType: FillType
        fillLinearGradient: LinearGradient | undefined
        fillRadialGradient: RadialGradient | undefined
        fillImage: string | VariableReference | null
        fillImageOriginalName: string | null
        fillImageResize: ImageFit
        fillImagePixelWidth: number | null
        fillImagePixelHeight: number | null

        name: string | null
        visible: boolean | VariableReference
        locked: boolean

        x: number
        y: number
        width: number
        height: number

        aspectRatio: number | null
        rotation: number | VariableReference

        opacity: number | VariableReference

        strokeEnabled: boolean | undefined
        strokeAlignment: StrokeAlignment | undefined
        strokeWidth: number | undefined
        strokeColor: string | undefined
        lineJoin: LineJoin | undefined
        lineCap: LineCap | undefined
        strokeMiterLimit: number | undefined
        strokeDashArray: string | undefined
        strokeDashOffset: number | undefined

        boxShadows: Readonly<BoxShadow[]> | undefined

        exportOptions: List<ExportOptions>

        isEmptyGroup() {
            if (!(this instanceof ShapeGroupNode || this instanceof BooleanShapeNode)) {
                return false
            }
            const children = this.children
            for (let i = 0, il = children.length; i < il; i++) {
                if (!children[i].isEmptyGroup()) return false
            }
            return true
        }

        preCommit(tree: CanvasTree) {
            wrapInShapeContainer(this as any, tree)
        }

        getProps(cachePath?: string[]): Partial<VectorProperties> & LayerProps {
            const props = super.getProps()
            if (!isVectorNode(this)) {
                return props
            }

            if (!this.resolveValue("visible")) {
                return props
            }

            const resolvedOpacity = this.resolveValue("opacity")
            const opacity = resolvedOpacity !== 1 ? resolvedOpacity : undefined

            const tree = this.cache.latest ? (this.cache.latest.tree as CanvasTree) : null
            const isRootVectorNode = tree ? tree.isRootVectorNode(this) : false

            const id = cachePath && cachePath.length > 0 ? this.id + "-" + cachePath.join("-") : this.id
            const svgId = stringFromNodeID(id)
            let name: string | undefined = undefined

            if (this.name) {
                name = this.name
            } else {
                name = getDefaultName(this)
            }

            const calculatedPath = this.calculatedPaths()
            const isStrokeEnabled = !!this.strokeEnabled
            const stroke: string | undefined = isStrokeEnabled && !!this.strokeWidth ? this.strokeColor : "transparent"
            const strokeAlpha = stroke ? ConvertColor.getAlpha(stroke) : 0

            const shapeId = svgId
            const strokeClipId = `${svgId}_clip`

            const shapeProps: Partial<VectorProperties> = {
                opacity: isVariableReference(opacity) ? 1 : opacity, // FIXME
                isRootVectorNode,
                calculatedPath,
                shapeId,
                strokeClipId,
                rect: { x: this.x, y: this.y, width: this.width, height: this.height },
                frame: this.rect(),
                strokeAlpha,
                key: svgId,
                name: name,
                rotation: this.resolveValue("rotation"),
                lineCap: this.lineCap,
                lineJoin: this.lineJoin,
                strokeColor: this.strokeColor,
                id: this.id,
                strokeDashArray: this.strokeDashArray,
                strokeDashOffset: this.strokeDashOffset,
                strokeMiterLimit: this.strokeMiterLimit,
            }

            collectPartialStroke(this, shapeProps)
            collectBoxShadowPropsForNode(this, shapeProps)
            collectFillPropsForNode(this, shapeProps)
            Object.assign(props, shapeProps)
            return props
        }

        getAttributes(defaultProps: Partial<TagProps>): Attribute[] {
            const props: TagProps = {
                ...defaultProps,
                style: {},
            }

            const {
                // Strip properties that are irrelevant if we are not rendering a layer on the canvas.
                // For brevity, we also use `rect` in place of `frame` since these are always the same.
                calculatedPath, // eslint-disable-line @typescript-eslint/no-unused-vars
                _canMagicMotion, // eslint-disable-line @typescript-eslint/no-unused-vars
                willChangeTransform, // eslint-disable-line @typescript-eslint/no-unused-vars
                key, // eslint-disable-line @typescript-eslint/no-unused-vars
                frame, // eslint-disable-line @typescript-eslint/no-unused-vars
                duplicatedFrom, // eslint-disable-line @typescript-eslint/no-unused-vars
                ...vectorProps
            } = this.getProps()

            collectPath(this, props)

            Object.assign(props, vectorProps)

            return attributesFromProps(props)
        }

        supportsVariables() {
            return false
        }

        resolveValue<K extends keyof this>(key: K): Exclude<this[K], VariableReference> | undefined {
            const value = this[key]
            if (isVariableReference(value)) {
                return undefined
            } else {
                return value as Exclude<this[K], VariableReference>
            }
        }
    }
}
