import { V58, V59 } from "../types"
import { transform } from "../utils/transform"
import { withoutProps } from "../utils/withoutProps"
import { exactCheck, _ } from "../utils/exactCheck"
import { PropertyEnabled } from "../types/V55"

export function migration_58_59(documentJSON: V58.Tree): V59.Tree {
    const migratedRoot = transform(documentJSON.root, node => {
        if (node.__class !== "CodeComponentNode") return exactCheck(node, _ as V59.TreeNode)
        if (node.codeComponentIdentifier !== "framer/Stack") return exactCheck(node, _ as V59.TreeNode)

        const codeComponentProps = node.codeComponentProps ?? {}

        const migratedNode = withoutProps(
            {
                ...node,
                __class: "FrameNode" as const,
                stackEnabled: true,
                stackDirection: toStackDirection(codeComponentProps.direction),
                stackDistribution: toStackDistribution(codeComponentProps.distribution),
                stackAlignment: toStackAlignment(codeComponentProps.alignment),
                stackGap: toNumber(codeComponentProps.gap),
                padding: toNumber(codeComponentProps.padding),
                paddingPerSide: toBoolean(codeComponentProps.paddingPerSide),
                paddingTop: toNumber(codeComponentProps.paddingTop),
                paddingRight: toNumber(codeComponentProps.paddingRight),
                paddingBottom: toNumber(codeComponentProps.paddingBottom),
                paddingLeft: toNumber(codeComponentProps.paddingLeft),
                blendingEnabled: PropertyEnabled.disabledHidden,
            },
            "codeComponentIdentifier",
            "codeComponentPackageVersion",
            "codeComponentProps",
            // Replica info should never be part of a code component, but old files had a bug that added the info
            "replicaInfo",
            "originalid"
        )

        return exactCheck(migratedNode, _ as V59.FrameNode)
    })

    return { version: 59, root: migratedRoot }
}

function toStackDirection(direction: unknown): V59.StackDirection {
    switch (direction) {
        case "horizontal":
        case "vertical":
            return direction
        default:
            return "horizontal"
    }
}

function toStackDistribution(distribution: unknown): V59.StackDistribution {
    switch (distribution) {
        case "start":
        case "center":
        case "end":
        case "space-between":
        case "space-around":
        case "space-evenly":
            return distribution
        default:
            return "start"
    }
}

function toStackAlignment(alignment: unknown): V59.StackAlignment {
    switch (alignment) {
        case "start":
        case "center":
        case "end":
            return alignment
        default:
            return "center"
    }
}

function toNumber(value: unknown, defaultValue = 0): number {
    return typeof value === "number" && Number.isFinite(value) ? value : defaultValue
}

function toBoolean(value: unknown, defaultValue = false) {
    return value === true || value === false ? value : defaultValue
}
