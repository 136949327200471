import * as React from "react"
import { cx } from "linaria"
import { Button, ButtonProps } from "../components/Button"
import type { OverrideType } from "./types"
import * as styles from "./ProjectBarButton.styles"

export type ProjectBarButtonProps = OverrideType<
    Omit<ButtonProps, "variant" | "ref">,
    {
        type?: "primary" | "default" | "warning" | "error"
        active?: boolean
        round?: boolean
    }
>

export const ProjectBarButton = React.memo(
    React.forwardRef<HTMLButtonElement, ProjectBarButtonProps>(function ProjectBarButton(props, forwardedRef) {
        const { title, children, className, active = false, round = false, type = "default", ...rest } = props

        return (
            <Button
                ref={forwardedRef}
                title={title}
                variant={type === "primary" ? type : undefined}
                className={cx(
                    className,
                    styles.projectBarButton,
                    getStylesByType(type),
                    active && styles.active,
                    round && styles.round
                )}
                {...rest}
            >
                {children}
            </Button>
        )
    })
)

const getStylesByType = (type: ProjectBarButtonProps["type"]) => {
    switch (type) {
        case "default":
            return styles.defaultStyles
        case "warning":
            return styles.warning
        case "error":
            return styles.error
        default:
            return undefined
    }
}
