import { useEffect } from "react"

export const springAnimation = {
    default: {
        type: "spring",
        stiffness: 200,
        damping: 18,
        delay: 0,
        velocity: 0,
    },
    originX: { type: false },
    originY: { type: false },
}

export const springAnimation2 = {
    type: "spring",
    mass: 0.6,
    delay: 0.2,
    velocity: 0,
}

export const springAnimation3 = {
    type: "spring",
    mass: 0.8,
    velocity: 0,
}

export const springBouncy = {
    type: "spring",
    stiffness: 300,
    damping: 15,
    mass: 1,
}

export const springAnimationLeft = {
    default: {
        type: "spring",
        stiffness: 200,
        damping: 18,
        delay: 0.3,
        velocity: 0,
    },
}

export const springAnimationDelay = {
    default: {
        type: "spring",
        stiffness: 200,
        damping: 18,
        delay: 1,
        velocity: 0,
    },
}

export const animationDelay = 500

export const wait = (delay: number) => new Promise(resolve => setTimeout(resolve, delay))

export function useLoop(animation: () => Promise<any>) {
    useEffect(() => {
        let run = true

        async function animate(): Promise<{}> {
            const res = await animation()
            if (run) {
                return animate()
            } else {
                return res
            }
        }

        animate().catch(e => e)

        return () => {
            run = false
        }
    }, [animation])
}
