import * as React from "react"
const { memo, useRef, useEffect, createElement } = React
import * as emoji from "../lib/emoji"

export interface EmojiProps {
    as?: React.ElementType
    children?: React.ReactNode | React.ReactNode[]
}

export const Emoji = memo(({ as = "span", children }: EmojiProps) => {
    const ref = useRef<HTMLElement>()
    useEffect(() => {
        if (!ref.current) return
        emoji.replace(ref.current)
    })
    return createElement(as, { ref }, children)
})
