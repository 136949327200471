import * as React from "react"

export function IconTickerDown() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="4">
            <path
                d="M 5.4 0.8 C 5.647 0.47 5.412 0 5 0 L 1 0 C 0.588 0 0.353 0.47 0.6 0.8 L 2.6 3.467 C 2.8 3.733 3.2 3.733 3.4 3.467 Z"
                fill="currentColor"
            ></path>
        </svg>
    )
}
