import type { CanvasNode } from "document/models/CanvasTree"
import { isVariableReference, VariableReference } from "./VariableReference"

export interface WithVisibility {
    visible: boolean | VariableReference
}

export const visibilityDefaults: WithVisibility = {
    visible: true,
}

const key: keyof WithVisibility = "visible"

export function withVisibility(node: CanvasNode): node is CanvasNode & WithVisibility {
    return key in node
}

export function isVisibleNode(node: CanvasNode) {
    return !withVisibility(node) || node.visible !== false
}

export function reduceVisibility(node: CanvasNode, result: { visible?: boolean | VariableReference | null }) {
    if (!withVisibility(node)) {
        return
    }

    if (result.visible === null) {
        return
    }

    if (result.visible === undefined) {
        result.visible = node.visible
    } else if (isVariableReference(result.visible)) {
        if (!isVariableReference(node.visible) || result.visible.id !== node.visible.id) {
            result.visible = null
        }
    } else if (result.visible !== node.visible) {
        result.visible = null
    }
}
