import { assert } from "@framerjs/shared"
import { sandboxComponentLoader } from "@framerjs/framer-runtime/sandbox"
import type { NodeID } from "./nodes/NodeID"
import { TextNode } from "./nodes/TextNode"
import { StyledTextDraft } from "../StyledText/StyledTextDraft"
import { getNodeSubclassByClassName, getNodeDefaultsByClassName } from "./nodes/classList"
import type { CanvasTree } from "./CanvasTree"
import { isReplicaChild, isReplica, isMaster, ReplicaInfo } from "./traits/Template"
import type { CanvasNode } from "./nodes/CanvasNode"
import {
    revive,
    reviveTokens,
    reviveReplicaInfo,
    reviveGuidesInfo,
    reviveCodeComponentProp,
    adaptValue,
    propertiesToAdapt,
} from "./nodes/canvasNodeFromValue"
import { TemplateHelper } from "./nodes/TemplateHelper"
import { isEqual } from "utils/isEqual"
import CodeComponentNode from "./nodes/CodeComponentNode"
import { isShallowEqualArray } from "framer"
import { isFrameEvent } from "./traits/FrameEvents"
import type { WithChildren } from "./traits/Children"
import { isCodeComponentProp } from "./traits/utils/codeComponentProps"
import { isPropertyWithPlainJavascriptData } from "./utils/isPropertyWithPlainJavascriptData"

export interface PropsDiff {
    /** represents deleted properties */
    _deleted?: string[]
    /** all other properties go here */
    [key: string]: any
}

export type NodeChange = {
    id: NodeID
    /** Indicates this node should be added by this change, will contain the
     * class name. */
    added?: string
    /** Indicates this node should be removed by this change, will contain the
     * class name (so it can be reversed into an add). */
    removed?: string

    /** Contains the properties added (and removed) */
    to: PropsDiff
    /** Contains the new ordering of children if relevant. */
    toChildren?: NodeID[]

    /** Contains the previous versions of the properties that changed. */
    from?: PropsDiff
    /** Contains the previous ordering of children. */
    fromChildren?: NodeID[]
}

export type ReversibleNodeChange = NodeChange & Required<Pick<NodeChange, "from">>

export interface TreeChange {
    changes: ReversibleNodeChange[]
}

function addNode(tree: CanvasTree, id: NodeID, name: string, props: PropsDiff): CanvasNode | null {
    if (tree.has(id)) {
        return changeNode(tree, id, props)
    }
    if (!withParentId(props) || !tree.has(props.parentid)) {
        return null
    }

    const cls = getNodeSubclassByClassName(name)
    if (!cls) throw new Error("Unknown node class: " + name)

    const replicaInfo = props.replicaInfo as ReplicaInfo | undefined
    if (replicaInfo) {
        const master = tree.get(replicaInfo.master)
        if (!master || !isMaster(master)) {
            throw Error("broken diff, replica without master: " + id + " " + replicaInfo.master)
        }
        const revived = reviveReplicaInfo(replicaInfo)
        const replica = TemplateHelper.create(tree, master, {
            overrides: revived.overrides,
            owner: id,
            fromNode: undefined,
            inheritsFrom: revived.inheritsFrom,
            duplicatedFrom: props.duplicatedFrom as string[] | null,
        })
        replica.parentid = props.parentid
        tree.insertNode(replica, replica.parentid)
        return replica
    }

    if (name === "TextNode" && withStyledText(props)) {
        const styledTextProps = { blocks: [], entityMap: {}, ...props.styledText, __class: undefined }
        const styledText = StyledTextDraft.createFromData(styledTextProps)
        props = { ...props, styledText }
    }

    const node = new cls({ ...reviveProps(props), id }) as CanvasNode
    tree.insertNode(node, node.parentid)
    return node
}

function withParentId<T extends object>(obj: T | null | undefined): obj is T & { parentid: string } {
    return obj != null && typeof obj["parentid"] === "string"
}

function withStyledText<T extends object>(
    obj: T | null | undefined
): obj is T & { styledText: { __class: "StyledTextDraft"; cached: unknown } } {
    const styledTextKey = "styledText"
    return (
        obj != null &&
        typeof obj[styledTextKey] === "object" &&
        obj[styledTextKey] !== null &&
        obj[styledTextKey]["__class"] === "StyledTextDraft"
    )
}

function removeNode(tree: CanvasTree, id: NodeID) {
    if (!tree.has(id)) return
    tree.remove(id)
}

export function reviveProps(value: PropsDiff): { [key: string]: any } {
    const props: { [key: string]: any } = {}
    for (const key in value) {
        if (key === "_deleted") {
            // Revived properties don't include the _deleted field.
        } else if (propertiesToAdapt[key]) {
            props[key] = adaptValue(value[key])
        } else if (key === "guidesX" || key === "guidesY") {
            props[key] = reviveGuidesInfo(value[key])
        } else if (isFrameEvent(key) && Array.isArray(value[key])) {
            props[key] = value[key]
        } else if (key === "replicaInfo") {
            props.replicaInfo = reviveReplicaInfo(value[key])
        } else if (key === "tokens") {
            props.tokens = reviveTokens(value[key], value["tokensIndex"])
            props.tokensIndex = undefined
        } else if (key === "tokensIndex") {
            // Do nothing, this is handled when processing "tokens"
        } else if (isCodeComponentProp(key)) {
            props[key] = reviveCodeComponentProp(value[key])
        } else if (isPropertyWithPlainJavascriptData(key)) {
            props[key] = value[key]
        } else {
            props[key] = revive(value[key])
        }
    }
    return props
}

// Mimicks what node.toJS() does, but one value at the time, and removes text caches.
function nodeValueSerialized(node: CanvasNode, key: string): any {
    let value = node[key]
    if (!value) return value

    if (key === "replicaInfo") {
        return JSON.parse(JSON.stringify(value))
    }

    const toJS = value.toJS
    if (toJS instanceof Function) {
        value = value.toJS()

        // Filter out text caching.
        if (key === "styledText") {
            delete value.cached
        }
    }
    return value
}

function changeNode(tree: CanvasTree, id: NodeID, props: PropsDiff): CanvasNode | null {
    const node = tree.get(id) as CanvasNode
    if (!node) return null

    // handle parentid change
    if (props.parentid && node.parentid !== props.parentid) {
        if (!tree.has(props.parentid)) {
            tree.remove(id)
            return null
        }
        tree.move(id, props.parentid)
    }

    // Check if there is an actual difference between the current node and the list of changes.
    let diff = false
    for (const [key, value] of Object.entries(props)) {
        if (key === "_deleted" || !isEqual(value, nodeValueSerialized(node, key))) {
            diff = true
            break
        }
    }
    if (!diff) return null

    let future: CanvasNode
    if (node instanceof TextNode) {
        const { styledText, ...textNodeProps } = props
        future = node.setIgnoringReplica(reviveProps(textNodeProps), tree)

        if (styledText) {
            const styledTextDraft = StyledTextDraft.createFromData(styledText)
            future = node.setIgnoringReplica({ styledText: styledTextDraft }, tree)
        }
    } else {
        future = node.setIgnoringReplica(reviveProps(props), tree)
    }

    if (props.replicaInfo && isReplica(future)) {
        if (isReplica(node)) {
            TemplateHelper.updateFromOverrides(tree, future, node)
        }
        // The else case is when a node becomes a replica, say after undoing a
        // detach from master, which doesn't need any help, as the normal tree
        // master replica mechanisms suffice.
    }

    if (Array.isArray(props._deleted)) {
        for (const key of props._deleted) {
            future[key] = undefined
        }
    }

    return future
}

function dependsOnFutureChange(tree: CanvasTree, id: NodeID, props: PropsDiff): boolean {
    const replicaInfo = props.replicaInfo
    if (
        replicaInfo &&
        (!tree.has(replicaInfo.master) || (replicaInfo.inheritsFrom && !tree.has(replicaInfo.inheritsFrom)))
    )
        return true

    const parentid = props.parentid
    if (!parentid) return false

    const parentNode = tree.get(parentid)
    if (parentNode) return tree.isAncestorOfNode(parentNode, id)

    return true
}

function isEqualChildrenOrder(children: CanvasNode[], to: NodeID[]) {
    const childrenlength = children.length
    if (childrenlength !== to.length) return false
    for (let i = 0; i < childrenlength; i++) {
        if (children[i].id !== to[i]) return false
    }
    return true
}

function resyncChildOrder(tree: CanvasTree, node: CanvasNode & WithChildren, to: NodeID[]) {
    // if the order is already the same, do nothing
    if (isEqualChildrenOrder(node.children, to)) return

    // create new child list in correct order, dropping any child that doesn't exist locally
    const newChildren: CanvasNode[] = []
    for (let i = 0, il = to.length; i < il; i++) {
        const child = tree.get(to[i])
        if (!child) continue
        // only take in children that have the correct parentid set
        if (child.parentid !== node.id) continue
        newChildren.push(child)
    }

    // make sure we don't drop any children that exist locally
    const seen = new Set<NodeID>(to)
    const children = node.children
    for (let i = 0, il = children.length; i < il; i++) {
        const child = children[i]
        if (seen.has(child.id)) continue
        newChildren.push(child)
    }

    // if indeed a change, set to the mutable node
    if (!isShallowEqualArray(children, newChildren)) {
        node.asMutable(tree).children = newChildren
    }
}

function mergeToDeleted(diff: PropsDiff | undefined, before: string[] | undefined, after: string[] | undefined): void {
    if (!diff) return

    if (after) {
        // Make sure newly deleted keys are not actually left as properties in the diff itself.
        for (const key of after) {
            delete diff[key]
        }
    }

    // If there are no deleted keys from before, there is nothing left to do.
    if (!before) return

    // Make sure newly defined properties are not also marked as deleted in the before list.
    const toPreserve = []
    for (const key of before) {
        if (diff[key] !== undefined) {
            toPreserve.push(key)
        }
    }

    // If that is the whole before list, it is the same as that list not existing
    if (toPreserve.length === before.length) {
        if (!after) {
            // Make sure not to leave the begin deleted list.
            delete diff._deleted
        }
        return
    }

    // Otherwise merge the two lists.
    const set = new Set(before)
    for (const key of toPreserve) {
        set.delete(key)
    }
    if (after) {
        for (const key of after) {
            set.add(key)
        }
    }
    if (set.size === 0) {
        // Should not happen, but just in case
        delete diff._deleted
    } else {
        diff._deleted = Array.from(set)
    }
}

/** Merge two node changes into one. */
export function mergeNodeChanges(computedChange: NodeChange, next: NodeChange): void {
    assert(computedChange.id === next.id, "must be the same id")

    // merge to changes, eg: {a: 1, b: 2} + {a: 2} => {a: 2, b: 2}
    const existingToDeleted = computedChange.to._deleted
    const changeToDeleted = next.to._deleted
    Object.assign(computedChange.to, next.to)
    mergeToDeleted(computedChange.to, existingToDeleted, changeToDeleted)

    // merge from changes, eg: {a: 1, b: 2} + {a: 2} => {a: 1, b: 2}
    if (computedChange.from && next.from) {
        const existingFromDeleted = computedChange.from._deleted
        const changeFromDeleted = next.from._deleted
        Object.assign(computedChange.from, next.from, {
            ...computedChange.from,
        })
        mergeToDeleted(computedChange.from, changeFromDeleted, existingFromDeleted)
    }

    if (next.toChildren) {
        computedChange.toChildren = next.toChildren
    }

    if (next.removed) {
        delete computedChange.added
        computedChange.removed = next.removed
    } else if (next.added) {
        computedChange.added = next.added
        delete computedChange.removed
    }
}

/** Collapse a sequence of forward only changes into a single change. */
export function collapseChangesForward(treeChanges: { changes: NodeChange[] }[]): NodeChange[] {
    const changes: { [key: string]: NodeChange } = {}
    for (const treeChange of treeChanges) {
        for (const change of treeChange.changes) {
            let existingChange = changes[change.id]
            if (!existingChange) {
                existingChange = { ...change }
                delete existingChange.from
                delete existingChange.fromChildren
                existingChange.to = { ...change.to }
                changes[change.id] = existingChange
            } else {
                // merge to changes
                const existingToDeleted = existingChange.to?._deleted
                const changeToDeleted = change.to?._deleted
                Object.assign(existingChange.to, change.to)
                mergeToDeleted(existingChange.to, existingToDeleted, changeToDeleted)

                if (change.toChildren) {
                    existingChange.toChildren = change.toChildren
                }
                if (change.removed) {
                    delete existingChange.added
                    existingChange.removed = change.removed
                } else if (change.added) {
                    existingChange.added = change.added
                    delete existingChange.removed
                }
            }

            // No need to hold on to these properties if we want to signify a removal.
            if (existingChange.removed) {
                existingChange.to = {}
                // TODO we can remove the toChildren as well, but createSnapshot.ts depends on this field
                // delete existingChange.toChildren
            }
        }
    }
    return Object.values(changes)
}

/** Collapse a sequence of changes into a single change. */
export function collapseChanges(treeChanges: { changes: ReversibleNodeChange[] }[]): ReversibleNodeChange[] {
    const changes: { [key: string]: ReversibleNodeChange } = {}
    for (const treeChange of treeChanges) {
        for (const change of treeChange.changes) {
            let existingChange = changes[change.id]
            if (!existingChange) {
                existingChange = { ...change }
                existingChange.to = { ...change.to }
                existingChange.from = { ...change.from }
                changes[change.id] = existingChange
            } else {
                // merge to changes
                const existingToDeleted = existingChange.to?._deleted
                const changeToDeleted = change.to?._deleted
                Object.assign(existingChange.to, change.to)
                mergeToDeleted(existingChange.to, existingToDeleted, changeToDeleted)

                // merge from changes
                const existingFromDeleted = existingChange.from?._deleted
                const changeFromDeleted = change.from?._deleted
                Object.assign(existingChange.from, change.from, { ...existingChange.from })
                mergeToDeleted(existingChange.from, changeFromDeleted, existingFromDeleted)

                if (change.toChildren) {
                    existingChange.toChildren = change.toChildren
                }
                if (change.removed) {
                    delete existingChange.added
                    existingChange.removed = change.removed
                } else if (change.added) {
                    existingChange.added = change.added
                    delete existingChange.removed
                }
            }
        }
    }
    return Object.values(changes)
}

// every move is counted, so we cannot return a Set<NodeID>
function buildParentsOfMovingNodesList(tree: CanvasTree, changes: NodeChange[]): NodeID[] {
    const parents: NodeID[] = []
    for (let i = 0, il = changes.length; i < il; i++) {
        const change = changes[i]
        if (!change.to.parentid) continue
        const node = tree.get(change.id)
        if (!node) continue
        parents.push(node.parentid!)
    }
    return parents
}

function updateParentsOfMovingNodesList(tree: CanvasTree, change: NodeChange, parents: NodeID[]) {
    if (!change.to.parentid) return
    const node = tree.get(change.id)
    if (!node) return

    const at = parents.indexOf(node.parentid!)
    if (at === -1) return
    parents.splice(at, 1)
}

export function applyChanges(tree: CanvasTree, changes: NodeChange[]): void {
    const allChanges = changes
    const parentsOfMovingNodes = buildParentsOfMovingNodesList(tree, changes)
    const changedCodeComponents: CodeComponentNode[] = []

    // We keep a list of changes that are postponed and applied later, because
    // some changes depend on others. Additions depend on a parent existing and
    // might need to be postponed until the parent is created. Removes might
    // need to be postponed if any child nodes move out.
    while (changes.length > 0) {
        const postponedChanges: NodeChange[] = []
        for (let i = 0, il = changes.length; i < il; i++) {
            const change = changes[i]
            const id: NodeID = change.id

            if (change.added) {
                if (dependsOnFutureChange(tree, change.id, change.to)) {
                    postponedChanges.push(change)
                } else {
                    const node = addNode(tree, id, change.added, change.to)
                    if (node instanceof CodeComponentNode) {
                        changedCodeComponents.push(node)
                    }
                }
            } else if (change.removed) {
                if (parentsOfMovingNodes.includes(id)) {
                    postponedChanges.push(change)
                } else {
                    removeNode(tree, id)
                }
            } else {
                if (dependsOnFutureChange(tree, change.id, change.to)) {
                    postponedChanges.push(change)
                } else {
                    updateParentsOfMovingNodesList(tree, change, parentsOfMovingNodes)
                    const node = changeNode(tree, id, change.to)
                    if (node instanceof CodeComponentNode) {
                        changedCodeComponents.push(node)
                    }
                }
            }
        }

        if (postponedChanges.length === 0) break
        if (postponedChanges.length === changes.length) {
            // This should normally not happen. But in multi-user, some nodes
            // might have been removed that unblock changes. However, applying
            // such changes would not result in any chnage to the tree.
            break
        }
        changes = postponedChanges
    }

    // changes with .toChildren might need to re-order node.children
    for (let i = 0, il = allChanges.length; i < il; i++) {
        const change = allChanges[i]
        const toChildren = change.toChildren
        if (!toChildren || toChildren.length === 0) continue
        const node = tree.get(change.id)
        if (!node) continue
        if (!node.children) throw Error("assertion failure: node has no children")
        resyncChildOrder(tree, node as CanvasNode & WithChildren, toChildren)
    }

    // changes to CodeComponentNode's might need to link to their children
    // slots, so updates in those nodes trigger a render
    for (let i = 0, il = changedCodeComponents.length; i < il; i++) {
        const node = changedCodeComponents[i]
        node.allSlotContent(sandboxComponentLoader).forEach(slotId => {
            const linkedNode = tree.get(slotId)
            if (linkedNode) tree.linkNodeChangesTo(linkedNode, node.id)
        })
    }
}

export function applyReverseChanges(tree: CanvasTree, changes: NodeChange[]): void {
    const reverse = changes.map(change => {
        const id = change.id
        const removed = change.added
        const added = change.removed
        const from = change.to
        const to = change.from
        if (!to) throw Error("Changes cannot be reversed if they lack the 'from' field.")
        const fromChildren = change.toChildren
        const toChildren = change.fromChildren
        return { id, added, removed, to, from, toChildren, fromChildren }
    })

    applyChanges(tree, reverse)
}

export function createTreeChanges(from: CanvasTree, to: CanvasTree): TreeChange {
    const changes: ReversibleNodeChange[] = []

    to.forEachNode(toNode => {
        const id = toNode.id
        const fromNode = from.getNodeAtStart(id)
        if (fromNode === toNode) return
        const change = nodeChange(fromNode as CanvasNode | undefined, toNode as CanvasNode)
        if (!change) return
        changes.push(change)
    })

    from.forEachNodeAtStart(fromNode => {
        const id = fromNode.id
        if (to.has(id)) return
        const change = nodeChange(fromNode as CanvasNode, undefined)
        if (!change) return
        changes.push(change)
    })

    return { changes }
}

const ignoreKeysForDiffing = {
    id: true,
    cache: true,
    children: true,
    mutable: true,
    update: true,
    styledText: true,
    _deleted: true,
}
const keysForReplicas = { parentid: true, replicaInfo: true, originalid: true, duplicatedFrom: true }

function isEqualChildrenArray(from: CanvasNode[], to: CanvasNode[]) {
    const fromlength = from.length
    if (fromlength !== to.length) return false
    for (let i = 0; i < fromlength; i++) {
        if (from[i].id !== to[i].id) return false
    }
    return true
}

function childrenChange(change: NodeChange, from: CanvasNode[] | undefined, to: CanvasNode[] | undefined): void {
    if (from && to && isEqualChildrenArray(from, to)) return

    if (from) {
        change.fromChildren = from.map(c => c.id)
    }
    if (to) {
        change.toChildren = to.map(c => c.id)
    }
}

export function nodeChange(
    fromNode: CanvasNode | undefined,
    toNode: CanvasNode | undefined
): ReversibleNodeChange | undefined {
    const id = toNode ? toNode.id : fromNode ? fromNode.id : ""
    const from: PropsDiff = {}
    const to: PropsDiff = {}
    const change: ReversibleNodeChange = { id, from, to }

    if (!toNode) {
        if (!fromNode) throw Error("assertion failed: at least one node must be given")
        if (isReplicaChild(fromNode)) return
        const className = fromNode.__class
        toNode = getNodeDefaultsByClassName(className) as CanvasNode
        change.removed = className
    } else if (!fromNode) {
        if (isReplicaChild(toNode)) return
        const className = toNode.__class
        fromNode = getNodeDefaultsByClassName(className) as CanvasNode
        change.added = className
    } else {
        if (isReplicaChild(toNode)) return
    }

    const replica = isReplica(fromNode) || isReplica(toNode)

    let changed = false
    // For CodeComponentNode the control prop keys are not in the node defaults (they can be any string with a prefixed),
    // so we need to get all the possible keys in the changes,
    // otherwise undo deleting code components will lose all the props.
    const allKeys: Set<string> = new Set([...Object.keys(toNode), ...Object.keys(fromNode)])
    for (const key of allKeys) {
        if (replica) {
            if (!keysForReplicas[key]) continue
        } else {
            if (key === "children") {
                childrenChange(change, fromNode.children, toNode.children)
                if (change.fromChildren || change.toChildren) {
                    changed = true
                }
                continue
            }
            if (ignoreKeysForDiffing[key]) continue
        }

        let fromValue = fromNode[key]
        let toValue = toNode[key]

        // If the values are equal by reference, it cannot be a diff.
        if (fromValue === toValue) continue

        // Skip styledText, those we deal with below.
        if (key === "styledText") continue

        if (key === "tokens") {
            // Tokens, which are only set on the root node, are a special case
            from["tokensIndex"] = Array.from(fromValue.keys() as any)
            to["tokensIndex"] = Array.from(toValue.keys() as any)
            fromValue = fromValue.toJS()
            toValue = toValue.toJS()
        } else if (key === "replicaInfo") {
            // ReplicaInfo needs some of its overrides have called toJSON() which will call toJS()
            if (fromValue) {
                fromValue = JSON.parse(JSON.stringify(fromValue))
            }
            if (toValue) {
                toValue = JSON.parse(JSON.stringify(toValue))
            }
        } else {
            // Make sure all other values are serialized
            if (fromValue && fromValue.toJS instanceof Function) {
                fromValue = fromValue.toJS()
            }
            if (toValue && toValue.toJS instanceof Function) {
                toValue = toValue.toJS()
            }
        }

        // If the values are deep equal, ignore. Notice a shallow equal is not
        // sufficient, as the change might be more then 1 level deep.
        if (isEqual(fromValue, toValue, true)) continue

        if (fromValue === undefined) {
            if (!from._deleted) from._deleted = []
            from._deleted.push(key)
        } else {
            from[key] = fromValue
        }

        if (toValue === undefined) {
            if (!to._deleted) to._deleted = []
            to._deleted.push(key)
        } else {
            to[key] = toValue
        }

        changed = true
    }

    if (toNode instanceof TextNode) {
        if (!(fromNode instanceof TextNode)) throw Error("assertion failed: nodes must be of same type")
        const fromStyledText = fromNode.styledText
        const toStyledText = toNode.styledText
        // TODO add fromStyledText.isEqual(toStyledText), and give .toJS() a no-cache option
        if (fromStyledText !== toStyledText) {
            const fromStyledTextJS = fromNode.styledText.toJS() as any
            delete fromStyledTextJS.cached
            const toStyledTextJS = toNode.styledText.toJS() as any
            delete toStyledTextJS.cached

            if (!isEqual(fromStyledTextJS, toStyledTextJS, true)) {
                from.styledText = fromStyledTextJS
                to.styledText = toStyledTextJS
                changed = true
            }
        }
    }

    if (!changed) return
    return change
}
