import type { CanvasNode } from "../nodes/CanvasNode"
import type { ControlProp } from "../traits/CodeComponent"
import type { ActionControlValues, OptionalActionControlValues } from "./actions"
import type { CanvasTree } from "../CanvasTree"
import type { EventAction } from "document/models/EventAction"
import type { MaybeNodeID } from "../nodes/NodeID"

import uuid from "uuid/v4"
import {
    SetVariantOptions,
    setVariantActionDefaults,
    setVariantActionControlTypes,
    SetVariantType,
} from "@framerjs/framer-runtime"
import { isEventAction } from "document/models/EventAction"
import { HardCodedCodeIdentifier } from "../traits/utils/hardCodedCodeComponentIdentifiers"
import { isString } from "utils/typeChecks"
import { containsActionsOfType, getActionsOfType } from "./actions"
import { isCanvasComponentNode } from "../nodes/CanvasComponentNode"

type SetVariantActionModel = SetVariantOptions
export type SetVariantActionControls = ActionControlValues<SetVariantActionModel>
type OptionalSetVariantActionControls = OptionalActionControlValues<SetVariantActionModel>

const controlDefaults: SetVariantActionControls = {
    type: {
        type: setVariantActionControlTypes.type,
        value: setVariantActionDefaults.type,
    },
    variantId: {
        type: setVariantActionControlTypes.variantId,
        value: undefined,
    },
}

export interface SetVariantAction extends EventAction {
    controls: { [key: string]: ControlProp } & OptionalSetVariantActionControls
}

const setVariantTypeMap: { [key in SetVariantType]: true } = {
    cycle: true,
    set: true,
}

export function isSetVariantType(value: unknown): value is SetVariantType {
    return isString(value) && value in setVariantTypeMap
}

export function isSetVariantAction(value: unknown): value is SetVariantAction {
    return isEventAction(value) && value.actionIdentifier === HardCodedCodeIdentifier.setVariantAction
}

export function getSetVariantTarget(action: SetVariantAction): MaybeNodeID {
    if (action.controls.type?.value !== "set") return null
    return action.controls.variantId?.value ?? null
}

export function containsSetVariantAction(value: unknown): value is Readonly<EventAction[]> {
    return Array.isArray(value) && value.some(isSetVariantAction)
}

export function containsSetVariantActionWithoutVariant(value: unknown): value is Readonly<EventAction[]> {
    return (
        Array.isArray(value) &&
        value.some(
            action =>
                isSetVariantAction(action) && action.controls.type?.value === "set" && !action.controls.variant?.value
        )
    )
}

export function hasVariantLinks(node: CanvasNode): boolean {
    return containsActionsOfType(node, isSetVariantAction)
}

export function getSetVariantActions(node: CanvasNode): SetVariantAction[] {
    return getActionsOfType(node, isSetVariantAction)
}

export function getSetVariantActionTargets(tree: CanvasTree, node: CanvasNode): string[] {
    const actions = getActionsOfType(node, isSetVariantAction)
    const targets: string[] = []

    actions.forEach(action => {
        const type = action.controls.type?.value
        switch (type) {
            case "set": {
                const variantId = action.controls.variantId?.value
                if (variantId) targets.push(variantId)
                break
            }
            case "cycle": {
                const scopeNode = tree.getScopeNodeFor(node)
                if (!isCanvasComponentNode(scopeNode)) return

                const variantNodes = scopeNode.getTopLevelVariants()
                if (variantNodes.length <= 1) return

                const groundNode = tree.getGroundNodeFor(node)
                const currentVariantIndex = variantNodes.findIndex(variantNode => variantNode.id === groundNode.id)
                if (currentVariantIndex === -1) return

                const indexPlusOne = currentVariantIndex + 1
                const nextVariantIndex = indexPlusOne >= variantNodes.length ? 0 : indexPlusOne
                const nextVariantNode = variantNodes[nextVariantIndex]
                if (nextVariantNode) targets.push(nextVariantNode.id)

                break
            }
        }
    })

    return targets
}

export function getSetVariantOptionsFromActionControls(
    controls: SetVariantAction["controls"]
): Partial<SetVariantOptions> {
    const options: Partial<SetVariantOptions> = {}

    Object.entries(controls).forEach(([key, control]) => {
        options[key] = control?.value
    })

    return options
}

export type UpdateSetVariantOptions = Partial<SetVariantActionModel>
export function updateSetVariantVariant(actions: unknown, options: UpdateSetVariantOptions): EventAction[] {
    const result: EventAction[] = Array.isArray(actions) ? [...actions] : []

    const setVariantActionIndex = result.findIndex(isSetVariantAction)
    const existingSetVariantAction = result[setVariantActionIndex]
    if (isSetVariantAction(existingSetVariantAction)) {
        const controls: Readonly<SetVariantActionControls> = {
            ...(existingSetVariantAction.controls as SetVariantActionControls),
            ...addControlTypesToModel(options),
        }
        const newAction: EventAction = {
            ...existingSetVariantAction,
            controls: controls,
        }
        result[setVariantActionIndex] = newAction
    } else {
        const action = createSetVariantAction(options)
        result.push(action)
    }

    return result
}

function addControlTypesToModel(model: Partial<SetVariantActionModel>): Partial<SetVariantActionControls> {
    const modelWithTypes: Partial<SetVariantActionControls> = {}

    Object.entries(model).forEach(([key, value]) => {
        modelWithTypes[key] = {
            type: setVariantActionControlTypes[key],
            value,
        }
    })

    return modelWithTypes
}

export function createSetVariantAction(model?: Partial<SetVariantActionModel>, id?: string) {
    const modelWithTypes = addControlTypesToModel({ ...model })

    const newAction: EventAction = {
        identifier: id ? id : uuid(),
        actionIdentifier: HardCodedCodeIdentifier.setVariantAction,
        controls: {
            ...controlDefaults,
            ...modelWithTypes,
        },
    }

    return newAction
}

export function updatedSetVariantAction(
    action: EventAction,
    controlOverrides: Partial<SetVariantActionControls>
): EventAction {
    const controls = {
        ...(action.controls as SetVariantActionControls),
        ...controlOverrides,
    }
    return { ...action, controls }
}
