import type { CanvasNode } from "document/models/CanvasTree"
import type { EventAction } from "document/models/EventAction"

export type EventActions = Readonly<EventAction[]> | undefined

export interface WithFrameEvents {
    onTap: EventActions
    onTapStart: EventActions
    onClick: EventActions
    onMouseUp: EventActions
    onMouseDown: EventActions
    onMouseEnter: EventActions
    onMouseLeave: EventActions
}

export const frameEventDefaults: Record<keyof WithFrameEvents, undefined> = {
    onTap: undefined,
    onTapStart: undefined,
    onClick: undefined,
    onMouseDown: undefined,
    onMouseUp: undefined,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
}

export const frameEventKeys: (keyof WithFrameEvents)[] = [
    "onTap",
    "onTapStart",
    "onClick",
    "onMouseDown",
    "onMouseUp",
    "onMouseEnter",
    "onMouseLeave",
]

type LegacyFrameEventKey = Exclude<keyof WithFrameEvents, "onTap" | "onTapStart" | "onMouseEnter" | "onMouseLeave">
const legacyFrameEventKeys: LegacyFrameEventKey[] = ["onClick", "onMouseDown", "onMouseUp"]

const key: keyof WithFrameEvents = "onTap"

export function withFrameEvents(node: CanvasNode): node is CanvasNode & WithFrameEvents {
    return key in node
}

const frameEventKeyMap: { [K in keyof WithFrameEvents]: boolean } = {
    onTap: true,
    onTapStart: true,
    onClick: true,
    onMouseDown: true,
    onMouseUp: true,
    onMouseEnter: true,
    onMouseLeave: true,
}

export function isFrameEvent(frameKey: string): boolean {
    return !!frameEventKeyMap[frameKey]
}

export function isLegacyFrameEventKey(frameKey: string): frameKey is LegacyFrameEventKey {
    return (legacyFrameEventKeys as string[]).includes(frameKey)
}
