import type { AnyNodeUpdate } from ".."
import { allVariableKeys } from "./allVariableKeys"

const plainJavascriptProperties: Set<keyof AnyNodeUpdate> = new Set([
    "duplicatedFrom",
    "overlayGrid",
    "variables",
    "variantTransition",
    ...allVariableKeys,
])

/** Is not an immutablejs datastructure */
export function isPropertyWithPlainJavascriptData(key: string): boolean {
    return plainJavascriptProperties.has(key as keyof AnyNodeUpdate)
}
