import { cx } from "linaria"
import * as React from "react"
import { useLayoutEffect, useRef, useState } from "react"
import { Button } from "./Button"
import * as classes from "./CopyLinkButton.styles"
import { IconCheckmark } from "../icons/IconCheckmark"
import type { ButtonProps } from "@framerjs/fresco"

enum State {
    Initial, // "copy link", not animated
    LinkCopied, // "link copied", animated
    CopyLink, // "copy link", animated
}

interface Props extends Omit<ButtonProps, "ref"> {
    title: string
    onClick: () => void
    fullWidth?: boolean
}

export function CopyLinkButton({ title, onClick, className, fullWidth = false, ...rest }: Props) {
    const [state, setState] = useState<State>(State.Initial)

    const copyTimeout = useRef<number>()

    function handleClick() {
        setState(State.LinkCopied)

        if (copyTimeout.current) clearTimeout(copyTimeout.current)
        copyTimeout.current = window.setTimeout(() => setState(State.CopyLink), 2000)

        onClick()
    }

    const ref = useRef<HTMLButtonElement>(null)
    useLayoutEffect(() => {
        // freeze the width on mount, so that the label changing from ${title}
        // to "Copied" doesn't cause the button to change width
        if (ref.current && !fullWidth) {
            // the extra pixel is to account for offsetWidth flooring the actual width
            const width = ref.current.offsetWidth + 1
            ref.current.style.width = `${width}px`
        }
    }, [fullWidth])

    function getButtonContent(): JSX.Element | undefined {
        if (state === State.Initial || state === State.CopyLink) {
            return <span className={cx(state === State.CopyLink && classes.labelAnimation)}>{title}</span>
        } else if (state === State.LinkCopied) {
            return (
                <>
                    <IconCheckmark />
                    <span className={classes.labelAnimation}>Copied</span>
                </>
            )
        }
    }

    return (
        <Button
            ref={ref}
            large
            title={title}
            className={cx(classes.button, fullWidth && classes.fullWidth, className)}
            onClick={handleClick}
            {...rest}
        >
            {getButtonContent()}
        </Button>
    )
}
