import type { Asset, Variant, AssetSize } from "./types/Asset"
import { getAssetMinSideSize } from "./getAssetMinSideSize"
import { stripExtension } from "./stripExtension"

export class AssetMap {
    private readonly assets = new Map<string, Asset>()

    // TODO: this a not a hash, and assumes that the contents of the assetmap
    // changes on every update. Ideally it would be an actual hash of the
    // contents of the map.
    private _hash = 0
    get hash() {
        return this._hash
    }

    update(assets: readonly Asset[]) {
        this.assets.clear()

        for (const asset of assets) {
            this.assets.set(asset.key, asset)
        }
        this._hash++
    }

    items(): Asset[] {
        return Array.from(this.assets.values())
    }

    urlForKey(key: string, assetSize: AssetSize | null, layerMaxSideSize: number | undefined): string | undefined {
        const asset = this.assetForKey(key)
        if (!asset) {
            return
        }
        let selectedVariant: Variant | null = null

        const minSize = getAssetMinSideSize(assetSize, layerMaxSideSize, true /* limit maximum size */)

        if (asset.variants && minSize !== undefined) {
            for (const currentVariant of asset.variants) {
                // Don't consider variants that are too small.
                if (currentVariant.maxSideSize < minSize) {
                    continue
                }
                // Pick the new variant if we don't have a variant yet, or
                // the current variant is smaller than the selected variant.
                if (selectedVariant === null || currentVariant.maxSideSize < selectedVariant.maxSideSize) {
                    selectedVariant = currentVariant
                    continue
                }
            }
        }
        if (selectedVariant) {
            return selectedVariant.url
        }
        return asset.url
    }

    private assetForKey(key: string): Asset | undefined {
        // The keys can contain an extension
        const keyWithoutExtension = stripExtension(key)
        const asset = this.assets.get(keyWithoutExtension)
        if (asset) {
            return asset
        }
        // Fall back to scanning by original filename. Some older Desktop
        // projects will not use the SHA-1 hash filename that we expect.
        for (const currentAsset of this.assets.values()) {
            if (currentAsset.name === key) {
                return currentAsset
            }
        }
    }
}
