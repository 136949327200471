import { Record, List } from "immutable"
import type { RadialGradientBase } from "framer"
import { GradientColorStop, GradientColorStops } from "document/models/GradientColorStop"

const gradientDefaults: RadialGradientBase & { stops: GradientColorStops } = {
    alpha: 1,
    widthFactor: 0.5,
    heightFactor: 0.5,
    centerAnchorX: 0.5,
    centerAnchorY: 0.5,
    stops: List([
        new GradientColorStop({ value: "rgba(0,0,0,0)", position: 0 }),
        new GradientColorStop({ value: "black", position: 1 }),
    ]),
}

export const RadialGradientRecord = Record(gradientDefaults)
