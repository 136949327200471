import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springAnimation3, springBouncy } from "./utils"
import { colors } from "../tokens"

export const AnimationZoom = React.memo(function AnimationZoom() {
    const iconControl = useAnimation()
    const iconPlusControl = useAnimation()
    const iconMinusControl = useAnimation()
    const zoomControl = useAnimation()

    async function animation() {
        // Reset at restart
        await Promise.all([
            iconControl.start({
                scale: 1,
                transition: { type: false },
            }),
            iconPlusControl.start({
                opacity: 0,
                rotate: -90,
                transition: { type: false },
            }),
            iconMinusControl.start({
                opacity: 1,
                rotate: 0,
                transition: { type: false },
            }),
            zoomControl.start({
                scale: 1,
                transition: { type: false },
            }),
        ])
        // Change icon to plus
        await Promise.all([
            iconPlusControl.start({
                opacity: 1,
                rotate: 0,
                transition: { duration: 0.3 },
            }),
            iconMinusControl.start({
                opacity: 0,
                rotate: 90,
                transition: { duration: 0.3 },
            }),
        ])
        // Click zoom in
        await iconControl.start({
            scale: 0.7,
            transition: { duration: 0.1, delay: 0.2 },
        })
        // Zoom in
        await Promise.all([
            iconControl.start({
                scale: 1,
                transition: springAnimation3,
            }),
            zoomControl.start({
                scale: 1.2,
                opacity: 1,
                transition: { delay: 0.15, ...springBouncy },
            }),
        ])
        // Click zoom in x2
        await iconControl.start({
            scale: 0.7,
            transition: { duration: 0.1, delay: 0 },
        })
        // Zoom in x2
        await Promise.all([
            iconControl.start({
                scale: 1,
                transition: springAnimation3,
            }),
            zoomControl.start({
                scale: 1.4,
                opacity: 1,
                transition: { delay: 0.15, ...springBouncy },
            }),
        ])
        // Change icon to minus
        await Promise.all([
            iconPlusControl.start({
                opacity: 0,
                rotate: -90,
                transition: { duration: 0.3 },
            }),
            iconMinusControl.start({
                opacity: 1,
                rotate: 0,
                transition: { duration: 0.3 },
            }),
        ])
        // Click zoom out
        await iconControl.start({
            scale: 0.7,
            transition: { duration: 0.1, delay: 0.2 },
        })
        // Zoom out
        await Promise.all([
            iconControl.start({
                scale: 1,
                transition: springAnimation3,
            }),
            zoomControl.start({
                scale: 1.2,
                opacity: 1,
                transition: { delay: 0.15, ...springBouncy },
            }),
        ])
        // Click zoom out x2
        await iconControl.start({
            scale: 0.7,
            transition: { duration: 0.1, delay: 0 },
        })
        // Zoom out x2
        return await Promise.all([
            iconControl.start({
                scale: 1,
                transition: springAnimation3,
            }),
            zoomControl.start({
                scale: 1,
                opacity: 1,
                transition: { delay: 0.15, ...springBouncy },
            }),
        ])
    }

    useLoop(animation)

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
            }}
        >
            <motion.div
                style={{
                    width: 40,
                    height: 40,
                    borderRadius: 8,
                    background: "rgba(255, 255, 255, 0.4)",
                }}
                animate={zoomControl}
            ></motion.div>
            <motion.div animate={iconControl} style={{ position: "absolute" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22">
                    <path
                        d="M 17.5 17.5 L 20.5 20.5"
                        fill="transparent"
                        strokeWidth="3"
                        stroke={colors.animationForground}
                        strokeLinecap="round"
                    ></path>
                    <path
                        d="M 9 0 C 13.971 0 18 4.029 18 9 C 18 13.971 13.971 18 9 18 C 4.029 18 0 13.971 0 9 C 0 4.029 4.029 0 9 0 Z"
                        fill={colors.animationForground}
                    ></path>
                </svg>
                <motion.div animate={iconPlusControl} style={{ position: "absolute", left: 5, top: 1 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8">
                        <path
                            d="M 3 1 C 3 0.448 3.448 0 4 0 C 4.552 0 5 0.448 5 1 L 5 3 L 7 3 C 7.552 3 8 3.448 8 4 C 8 4.552 7.552 5 7 5 L 5 5 L 5 7 C 5 7.552 4.552 8 4 8 C 3.448 8 3 7.552 3 7 L 3 5 L 1 5 C 0.448 5 0 4.552 0 4 C 0 3.448 0.448 3 1 3 L 3 3 Z"
                            fill={colors.animationForgroundLight}
                        ></path>
                    </svg>
                </motion.div>
                <motion.div animate={iconMinusControl} style={{ position: "absolute", left: 5, top: 1 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8">
                        <path
                            d="M 0 4 C 0 3.448 0.448 3 1 3 L 7 3 C 7.552 3 8 3.448 8 4 L 8 4 C 8 4.552 7.552 5 7 5 L 1 5 C 0.448 5 0 4.552 0 4 Z"
                            fill={colors.animationForgroundLight}
                        ></path>
                    </svg>
                </motion.div>
            </motion.div>
        </div>
    )
})
