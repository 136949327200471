import { css } from "linaria"

export const marginTopKey = "--margin-top"
export const marginBottomKey = "--margin-bottom"

export const text = css`
    ::before {
        display: block;
        margin-top: var(${marginTopKey});
        content: "";
    }

    ::after {
        display: block;
        margin-bottom: var(${marginBottomKey});
        content: "";
    }
`
