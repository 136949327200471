import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springAnimation, springAnimation3 } from "./utils"
import { colors } from "../tokens"

export const AnimationInsertMenu = React.memo(function AnimationInsertMenu() {
    const insertControl = useAnimation()
    const selectControl = useAnimation()
    const toggleControl = useAnimation()
    const knobControl = useAnimation()

    async function animation() {
        await Promise.all([
            insertControl.start({
                scale: 0,
                opacity: 0,
                transition: { type: false },
            }),
            selectControl.start({
                opacity: 0.3,
                top: 5,
                transition: { type: false },
            }),
            toggleControl.start({
                scale: 0,
                opacity: 0,
                transition: { type: false },
            }),
        ])
        await Promise.all([
            insertControl.start({
                scale: 1,
                opacity: 1,
                transition: { ...springAnimation },
            }),
            selectControl.start({
                top: 5,
                transition: { duration: 0 },
            }),
        ])
        await selectControl.start({
            top: 10,
            transition: { duration: 0, delay: 0.15 },
        })
        await selectControl.start({
            top: 15,
            transition: { duration: 0, delay: 0.15 },
        })
        await selectControl.start({
            top: 10,
            transition: { duration: 0, delay: 0.4 },
        })
        await selectControl.start({
            top: 5,
            transition: { duration: 0, delay: 0.15 },
        })
        await selectControl.start({
            opacity: 0.15,
            transition: { duration: 0, delay: 0.4 },
        })
        await selectControl.start({
            opacity: 0.3,
            transition: { duration: 0, delay: 0.05 },
        })
        await Promise.all([
            insertControl.start({
                opacity: 0,
                transition: { ...springAnimation },
            }),
            toggleControl.start({
                scale: 1,
                opacity: 1,
                transition: { ...springAnimation3, delay: 0.1 },
            }),
        ])
        await Promise.all([
            toggleControl.start({
                opacity: 0.5,
                transition: { ...springAnimation },
            }),
            knobControl.start({
                x: -8,
                transition: { ...springAnimation },
            }),
        ])
        await Promise.all([
            toggleControl.start({
                opacity: 1,
                transition: { ...springAnimation },
            }),
            knobControl.start({
                x: 0,
                transition: { ...springAnimation },
            }),
        ])
        return await toggleControl.start({
            opacity: 0,
            transition: { ...springAnimation },
        })
    }

    useLoop(animation)

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 40,
                height: 40,
                borderRadius: 8,
                background: colors.animationBackgroundLight,
            }}
        >
            <motion.div
                animate={insertControl}
                style={{
                    opacity: 0,
                    borderRadius: 2,
                    position: "absolute",
                    width: 20,
                    height: 20,
                    backgroundColor: colors.animationForground,
                    overflow: "hidden",
                }}
            >
                <motion.div
                    animate={selectControl}
                    style={{
                        position: "absolute",
                        width: 20,
                        height: 5,
                        top: 5,
                        backgroundColor: colors.tint,
                    }}
                />
            </motion.div>
            <motion.div
                animate={toggleControl}
                style={{
                    borderRadius: 7,
                    position: "absolute",
                    width: 22,
                    height: 14,
                    backgroundColor: colors.animationForground,
                    overflow: "hidden",
                }}
            >
                <motion.div
                    animate={knobControl}
                    style={{
                        borderRadius: 5,
                        position: "absolute",
                        width: 10,
                        height: 10,
                        top: 2,
                        left: 10,
                        backgroundColor: colors.animationForgroundLight,
                        overflow: "hidden",
                    }}
                />
            </motion.div>
        </div>
    )
})
