/** Used to map characters to HTML entities. */
const htmlEscapes = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;",
}

/** Used to match HTML entities and HTML characters. */
const reUnescapedHtml = /[&<>"']/g
const reHasUnescapedHtml = RegExp(reUnescapedHtml.source)

/**
 * Escape function, taken from Lodash
 * @see https://github.com/lodash/lodash/blob/e0029485ab4d97adea0cb34292afb6700309cf16/escape.js#L41
 * */
export function escapeHTML(str: string): string {
    return str && reHasUnescapedHtml.test(str) ? str.replace(reUnescapedHtml, chr => htmlEscapes[chr]) : str || ""
}
