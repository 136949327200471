import type { AssetSize } from "./types/Asset"
import { isNumber } from "./utils"

/**
 * When a number is returned the smallest asset thas is larger or equal than that number is picked.
 * A return value of undefined results in the original full asset.
 */
export function getAssetMinSideSize(
    assetSize: AssetSize | null,
    layerMaxSideSize: number | undefined,
    limitMaxSize: boolean
): number | undefined {
    switch (assetSize) {
        case "small":
            return 512
        case "medium":
            return 1024
        case "large":
            return 2048
        case "full":
            return limitMaxSize ? 4096 : undefined
        case "auto":
        default:
            if (isNumber(layerMaxSideSize)) {
                return layerMaxSideSize
            }
            return limitMaxSize ? 4096 : undefined
    }
}
