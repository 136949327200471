import * as React from "react"
import { motion, useAnimation, useMotionValue } from "framer-motion"
import { useLoop, springAnimation, springAnimation2 } from "./utils"
import { colors } from "@framerjs/fresco/src/tokens"

const width = 30
const height = 44
const radius = 4

const leftItemStyle: React.CSSProperties = {
    width: 20,
    height: 7,
    borderRadius: 2,
    backgroundColor: colors.animationForground,
    marginBottom: 2,
}

const rightItemStyle: React.CSSProperties = {
    width: 20,
    height: 34,
    borderRadius: 2,
    backgroundColor: colors.animationForground,
}

export const AnimationInteraction = React.memo(function AnimationInteraction() {
    const fullFrame = useAnimation()
    const leftFrame = useAnimation()
    const rightFrame = useAnimation()
    const contentItemLeft = useAnimation()
    const contentItemRight = useAnimation()
    const tapItem = useAnimation()
    const connector = useAnimation()
    const connectorCircleLeft = useAnimation()
    const connectorCircleRight = useAnimation()

    async function animation() {
        fullFrame.set({
            opacity: 1,
        })
        leftFrame.set({
            scaleX: 0,
            scaleY: 0,
            x: 21,
            originX: 0,
            originY: 0,
        })
        rightFrame.set({
            scaleX: 0,
            scaleY: 0,
            opacity: 1,
            x: 42,
            top: 18,
            originX: 0,
            originY: 0,
        })
        tapItem.set({
            scaleX: 0,
            height: 7,
        })
        contentItemLeft.set({
            scaleX: 0,
        })
        contentItemRight.set({
            scaleX: 0,
        })
        connector.set({
            pathLength: 0,
        })
        connectorCircleLeft.set({
            scale: 0,
            opacity: 1,
        })
        connectorCircleRight.set({
            scale: 0,
            opacity: 1,
        })

        // Animate Content
        await Promise.all([
            leftFrame.start({
                scaleX: 1,
                scaleY: 1,
                originX: 0,
                originY: 0,
                transition: { ...springAnimation.default, delay: 0.4 },
            }),
            rightFrame.start({
                scaleX: 1,
                scaleY: 1,
                originX: 0,
                originY: 0,
                transition: { ...springAnimation.default, delay: 0.8 },
            }),
        ])

        // Link Content
        await Promise.all([
            tapItem.start({
                scaleX: 1,
                transition: springAnimation2,
            }),
            contentItemLeft.start(i => ({
                scaleX: 1,
                transition: { ...springAnimation2, delay: 0.2 + 0.1 * i },
            })),
            contentItemRight.start({
                scaleX: 1,
                scaleY: 1,
                transition: { ...springAnimation.default, delay: 0.6 },
            }),
            connectorCircleLeft.start({
                scale: 1,
                transition: { delay: 1 },
            }),
            connector.start({
                pathLength: 2,
                transition: { delay: 1.1, duration: 0.5 },
            }),
            connectorCircleRight.start({
                scale: 1,
                transition: { delay: 1.3 },
            }),
        ])

        // Focus Left
        await Promise.all([
            leftFrame.start({
                x: 45,
                transition: { ...springAnimation, delay: 0.1 },
            }),
            rightFrame.start({
                scaleX: 0,
                opacity: 0,
                originY: 0.5,
                x: 45,
                transition: { ...springAnimation, delay: 0.1 },
            }),
            connectorCircleLeft.start({
                opacity: 0,
                transition: { duration: 0.1 },
            }),
            connectorCircleRight.start({
                opacity: 0,
                transition: { duration: 0.1 },
            }),
            connector.start({
                pathLength: 0,
                transition: { duration: 0.1 },
            }),
        ])

        // Tap Small Item
        await Promise.all([
            tapItem.start({
                scale: 0.9,
                opacity: 0.5,
                transition: springAnimation,
            }),
        ])

        await Promise.all([
            tapItem.start({
                scale: 1,
                opacity: 1,
                transition: springAnimation,
            }),
        ])

        await Promise.all([
            tapItem.start({
                height: 34,
                transition: springAnimation,
            }),
            contentItemLeft.start({
                opacity: 0,
                transition: springAnimation,
            }),
        ])

        // Tap Large Item
        await Promise.all([
            tapItem.start({
                scale: 0.9,
                opacity: 0.5,
                originY: 0.5,
                transition: springAnimation,
            }),
        ])

        await Promise.all([
            tapItem.start({
                scale: 1,
                opacity: 1,
                transition: springAnimation,
            }),
        ])

        await Promise.all([
            tapItem.start({
                height: 7,
                originY: 0,
                transition: springAnimation,
            }),
            contentItemLeft.start({
                opacity: 1,
                transition: springAnimation,
            }),
        ])

        // Fade Out
        return await fullFrame.start({
            opacity: 0,
        })
    }

    useLoop(animation)
    const scaleX = useMotionValue(1)
    const scaleY = useMotionValue(1)

    return (
        <motion.div
            animate={fullFrame}
            style={{
                width: 120,
                height: 40,
                marginTop: -10,
                marginBottom: 10,
                overflow: "visible",
            }}
        >
            <motion.div
                animate={leftFrame}
                style={{
                    x: 23,
                    y: 8,
                    padding: 5,
                    width: width,
                    height: height,
                    borderRadius: radius,
                    background: colors.animationForgroundLight,
                    position: "relative",
                    overflow: "hidden",
                    scaleX,
                    scaleY,
                }}
            >
                <motion.div animate={tapItem} custom={0} style={leftItemStyle} />
                <motion.div animate={contentItemLeft} custom={1} style={leftItemStyle} />
                <motion.div animate={contentItemLeft} custom={2} style={leftItemStyle} />
                <motion.div animate={contentItemLeft} custom={3} style={leftItemStyle} />
            </motion.div>
            <div
                style={{
                    display: "inline-block",
                    alignItems: "center",
                    justifyContent: "start",
                    flexDirection: "column",
                    width: 22,
                    height: 28,
                    overflow: "hidden",
                    position: "absolute",
                    left: 28,
                    top: 11,
                }}
            ></div>
            <motion.div
                animate={rightFrame}
                style={{
                    width: width,
                    height: height,
                    borderRadius: 4,
                    backgroundColor: colors.animationForgroundLight,
                    display: "inline-block",
                    alignItems: "center",
                    justifyContent: "start",
                    flexDirection: "column",
                    padding: 5,
                    position: "absolute",
                    left: 66,
                    top: 8,
                }}
            >
                <motion.div animate={contentItemRight} custom={3} style={rightItemStyle} />
            </motion.div>
            <div style={{ position: "absolute", left: 94, top: 34 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="8">
                    <motion.path
                        animate={connector}
                        d="M 2 4 L 13 4"
                        fill="transparent"
                        stroke={colors.animationForground}
                    ></motion.path>
                </svg>
            </div>
            <motion.div
                animate={connectorCircleLeft}
                style={{
                    width: 4,
                    height: 4,
                    borderRadius: "50%",
                    backgroundColor: colors.animationForground,
                    position: "absolute",
                    left: 93,
                    top: 40,
                }}
            />
            <motion.div
                animate={connectorCircleRight}
                style={{
                    width: 4,
                    height: 4,
                    borderRadius: "50%",
                    backgroundColor: colors.animationForground,
                    position: "absolute",
                    left: 107,
                    top: 40,
                }}
            />
        </motion.div>
    )
})
