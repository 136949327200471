import type { CanvasNode } from "document/models/CanvasTree"

export interface WithPolygon {
    polygonSides: number
}

export const polygonDefaults: WithPolygon = {
    polygonSides: 6,
}

const key: keyof WithPolygon = "polygonSides"

export function withPolygon(node: CanvasNode): node is CanvasNode & WithPolygon {
    return key in node
}

export function reducePolygon(node: CanvasNode, result: { polygonSides?: number | null; onlyPolygonNodes: boolean }) {
    if (!withPolygon(node)) {
        result.onlyPolygonNodes = false
        return
    }

    if (result.polygonSides === undefined) {
        result.polygonSides = node.polygonSides
    } else if (result.polygonSides !== null && result.polygonSides !== node.polygonSides) {
        result.polygonSides = null
    }
}
