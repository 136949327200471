import * as React from "react"
import { cx } from "linaria"
import type { OverrideType, HTMLDivAttributes } from "./types"
import * as styles from "./Scroll.styles"

type ScrollDirection = "horizontal" | "vertical" | "both"

export type ScrollProps = OverrideType<
    HTMLDivAttributes,
    {
        /** Defaults to vertical. */
        direction?: ScrollDirection
        /** Defaults to false. */
        hideScrollbar?: boolean
    }
>

function scrollDirectionClass(direction: ScrollDirection) {
    switch (direction) {
        case "horizontal":
            return styles.scrollHorizontal
        case "vertical":
            return styles.scrollVertical
        case "both":
            return styles.scrollBothDirections
    }
}

export const Scroll = React.forwardRef<HTMLDivElement, ScrollProps>(function Scroll(
    { className, children, direction = "vertical", hideScrollbar = true, ...rest },
    forwardedRef
) {
    return (
        <div
            ref={forwardedRef}
            className={cx(
                styles.scroll,
                scrollDirectionClass(direction),
                hideScrollbar && styles.withoutScrollbar,
                className
            )}
            {...rest}
        >
            {children}
        </div>
    )
})
