/* eslint-disable no-restricted-imports */

// This file re-exports immer with Map and Set enabled. It should always be used to import
// immer to ensure we only apply the Map/Set patch once, and that it's always enabled.

import { enableMapSet, enablePatches } from "immer"

enableMapSet()
enablePatches()

export type { Draft, Patch } from "immer"
export { produce, produceWithPatches, applyPatches, castDraft } from "immer"
