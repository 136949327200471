import type { V61, V62 } from "../types"
import { transform } from "../utils/transform"
import { _, exactCheck } from "../utils/exactCheck"

export function migration_61_62(documentJSON: V61.Tree): V62.Tree {
    const migratedRoot = transform(documentJSON.root, node => {
        const migratedTreeNode = {
            lastDuplicatedFromId: null,
            duplicatedFromId: null,
            ...node,
        }
        return exactCheck(migratedTreeNode, _ as V62.TreeNode)
    })
    return { version: 62, root: migratedRoot }
}
