import { css } from "linaria"

export const scroll = css`
    position: relative;
    overflow: auto;
    height: 100%;
    -webkit-overflow-scrolling: touch;

    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.25);
    }

    ::-webkit-scrollbar:vertical {
        width: 0;
    }
`

export const scrollHorizontal = css`
    overflow-x: overlay /* WebKit extension */;
    overflow-y: hidden;
`

export const scrollVertical = css`
    overflow-x: hidden;
    overflow-y: overlay /* WebKit extension */;
`

export const scrollBothDirections = css`
    overflow-x: overlay /* WebKit extension */;
    overflow-y: overlay /* WebKit extension */;
`

export const withoutScrollbar = css`
    ::-webkit-scrollbar {
        display: none;
    }
`
