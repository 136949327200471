import type { VekterEngine } from "document/VekterEngine"
import type { MaybeNodeID, CodeComponentNode, FrameNode } from "../.."
import type { CanvasNode } from "../CanvasNode"
import type { NodeID } from "../NodeID"
import type { IsMaster } from "../../traits/Template"
import type { IsVariant } from "../../traits/Variant"
import { isCanvasComponentNode } from "../CanvasComponentNode"
import { isCodeComponentNode } from "../CodeComponentNode"
import { generatedComponentIdentifier } from "variants/GeneratedComponent"
import { parseLocalModuleEntityIdentifier } from "modules/parseLocalModuleEntityIdentifier"
import { ModuleType } from "modules/types"
import { isString } from "utils/typeChecks"
import { platform } from "app/platform"
import { isVariableReference } from "../../traits/VariableReference"
import { withVariables } from "../../traits/Variables"

export function getPrimaryVariantForCodeComponent(
    engine: VekterEngine,
    instance: CodeComponentNode
): (FrameNode & IsMaster & IsVariant) | null {
    if (!isLocalCanvasComponentInstance(engine, instance)) return null

    const canvasComponentId = instance.getLocalCanvasComponentNodeId()
    if (!canvasComponentId) return null
    const canvasComponentNode = engine.getNode(canvasComponentId)
    if (!canvasComponentNode || !isCanvasComponentNode(canvasComponentNode)) return null

    return canvasComponentNode.getPrimaryVariant()
}

export function getActiveVariantIdForCodeComponent(engine: VekterEngine, node: CodeComponentNode): MaybeNodeID {
    if (!isLocalCanvasComponentInstance(engine, node)) return null

    const variantProp = node.getControlProp("variant")
    // If there's no variant prop, we take that the active variant is the primary variant
    if (!variantProp) {
        const canvasComponentId = node.getLocalCanvasComponentNodeId()
        if (!canvasComponentId) return null
        const canvasComponentNode = engine.getNode(canvasComponentId)
        if (!canvasComponentNode || !isCanvasComponentNode(canvasComponentNode)) return null
        return canvasComponentNode.baseVariantId ?? null
    }

    let { value } = variantProp
    if (isVariableReference(value)) {
        const scopeNode = engine.tree.getScopeNodeFor(node)
        if (!scopeNode || !withVariables(scopeNode)) return null
        value = scopeNode.getVariableValue(value.id)
    }

    if (!isString(value)) return null
    if (!engine.tree.has(value)) return null
    return value
}

export function isLocalCanvasComponentInstance(engine: VekterEngine, node: CanvasNode): node is CodeComponentNode {
    if (!platform.isOn("supportsVariantsAndVariables")) return false
    if (!isCodeComponentNode(node)) return false

    const canvasComponentNodeId = node.getLocalCanvasComponentNodeId()
    if (!canvasComponentNodeId || !engine.tree.has(canvasComponentNodeId)) return false

    return generatedComponentIdentifier(canvasComponentNodeId) === node.codeComponentIdentifier
}

export function localCanvasComponentId(identifier: string): string | null {
    const parsedIdentifier = parseLocalModuleEntityIdentifier(identifier)
    if (!parsedIdentifier) return null
    if (parsedIdentifier.type !== ModuleType.Canvas) return null
    // For canvasComponents the part after "/" in the localId is the node ID.
    const [, id] = parsedIdentifier.localId.split("/")
    return id
}

export function isActiveCanvasComponentInstance(node: CodeComponentNode, activeScopeId: NodeID): boolean {
    const canvasComponentId = localCanvasComponentId(node.codeComponentIdentifier)
    return activeScopeId === canvasComponentId
}

export function containsActiveCanvasComponentInstance(node: CanvasNode, activeScopeId: NodeID): boolean {
    let hasActiveCanvasComponentInstance = false
    node.walk(descendant => {
        if (hasActiveCanvasComponentInstance) return
        if (isCodeComponentNode(descendant) && isActiveCanvasComponentInstance(descendant, activeScopeId)) {
            hasActiveCanvasComponentInstance = true
        }
    })
    return hasActiveCanvasComponentInstance
}
