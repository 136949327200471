import type { CanvasNode } from "document/models/CanvasTree"
import type { CollectTraitOptions } from "../collectStyles"
import { withOptionalFill, withFill, WithFill } from "document/models/CanvasTree/traits/Fill"
import { withShape } from "../Shape"
import { DeprecatedCoreFrameProps as CoreFrameProps, BackgroundImage, FillProperties } from "framer"
import { withIntrinsicSize } from "../IntrinsicSize"
import { isNumber } from "utils/typeChecks"

function background(
    node: CanvasNode & WithFill,
    onlyBackgroundImage = false,
    { withInlineVariables = false }: CollectTraitOptions = {}
) {
    if (onlyBackgroundImage && node.fillType !== "image") return

    if (node.fillType === "linear-gradient" && node.fillLinearGradient) {
        // TODO: this is maybe inefficient
        return node.fillLinearGradient.toJS()
    }
    if (node.fillType === "radial-gradient" && node.fillRadialGradient) {
        return node.fillRadialGradient.toJS()
    } else if (node.fillType === "image" && node.fillImage) {
        let intrinsicWidth: number | undefined
        let intrinsicHeight: number | undefined
        if (withIntrinsicSize(node)) {
            if (isNumber(node.intrinsicWidth)) intrinsicWidth = node.intrinsicWidth
            if (isNumber(node.intrinsicHeight)) intrinsicHeight = node.intrinsicHeight
        }

        let pixelWidth: number | undefined
        let pixelHeight: number | undefined
        if (isNumber(node.fillImagePixelWidth)) pixelWidth = node.fillImagePixelWidth
        if (isNumber(node.fillImagePixelHeight)) pixelHeight = node.fillImagePixelHeight

        const resolvedImage = node.resolveValue("fillImage", withInlineVariables)
        if (!resolvedImage) return

        const backgroundImage: BackgroundImage = {
            src: resolvedImage,
            pixelWidth,
            pixelHeight,
            intrinsicWidth,
            intrinsicHeight,
            fit: node.fillImageResize,
        }

        return backgroundImage
    } else if (node.fillColor) {
        return node.resolveValue("fillColor")
    }
}

export function collectBackgroundPropsForNode(
    node: CanvasNode,
    props: Partial<CoreFrameProps>,
    onlyBackgroundImage = false,
    options?: CollectTraitOptions
) {
    if (withShape(node)) {
        return
    }
    if (withOptionalFill(node) && !node.fillEnabled) {
        props.background = null
        return
    }
    if (withFill(node)) {
        const bg = background(node, onlyBackgroundImage, options)
        if (bg) props.background = bg
    }
}

export function collectFillPropsForNode(node: CanvasNode, props: Partial<FillProperties>) {
    if (withOptionalFill(node) && !node.fillEnabled) {
        props.fill = null
        return
    }
    if (withFill(node)) {
        props.fill = background(node)
    }
}
