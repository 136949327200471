/**
 * Internal Fresco Color Palette
 *
 * --- About
 * Colors in the palette should always be solid colors in a hex format.
 * Colors in the palette are named by group and scale.
 *
 * Group: Color groups are defined by the appearance of the color e.g. red, yellow, green
 * dark" and "light" groups are strictly used for colors in the grey spectrum
 *
 * Scale: The primary scale of a group is 100. Darker colors will go above 100, and brighter colors below 100.
 *
 * Naming: Names of colors are a combination of group + scale.
 * e.g {group: green}{scale: 120} > green120
 *
 *
 * --- Adding a color
 * To add a color, choose the group and scale that makes most sense to assign to it.
 *
 * Color naming per group are mainly sorted by their brightness. When adding a new color,
 * check what scale it'll be assigned based on the brightness of colors above and below it.
 * Colors per group should have an ascending luminosity against the scale.
 *
 *
 * --- Removing a color
 * When removing a color from the palette, rename all currently used references to another palette color.
 *
 *
 * --- Usage
 * To use a color from the palette in a token, import the palette and reference the required color.
 * e.g. `{ inputBackground: palette.light90 }`
 *
 * If you need to add opacity to a color from the palette, you can use the `rgba` utility:
 * e.g. `rgba(palette.dark60, .4)`
 *
 */

export const palette = {
    dark100: "#111111",
    dark94: "#181818",
    dark93: "#1d1d1d",
    dark90: "#222222",
    dark88: "#232323",
    dark86: "#252525",
    dark84: "#282828",
    dark82: "#2B2B2B",
    dark80: "#333333",
    dark74: "#363636",
    dark70: "#444444",
    dark60: "#555555",
    dark50: "#666666",
    dark40: "#777777",
    dark30: "#888888",
    dark20: "#999999",

    light100: "#aaaaaa",
    light92: "#bababa",
    light90: "#bbbbbb",
    light80: "#cccccc",
    light70: "#dddddd",
    light62: "#e3e3e3",
    light60: "#eeeeee",
    light58: "#f3f3f3",
    light56: "#f4f4f4",
    light55: "#f5f5f5",
    light54: "#f6f6f6",
    light53: "#f7f7f7",
    light52: "#fdfdfd",
    light40: "#fafafa",

    green130: "#6D8173",
    green110: "#00cc88",
    green100: "#22cc88",
    green10: "#A9BEAF",

    blue100: "#0055ff",
    blue90: "#0066ff",
    blue80: "#0077FF",
    blue70: "#0088ff",
    blue60: "#0099ff",
    blue40: "#4FAEFF",
    blue30: "#7FC3FF",

    purple140: "#7744FF",
    purple130: "#74708c",
    purple126: "#5555ff",
    purple125: "#6666FF",
    purple120: "#6c758c",
    purple110: "#86829a",
    purple100: "#8855ff",
    purple95: "#8844FF",
    purple90: "#9966ff",
    purple60: "#6666ff",
    purple50: "#B89AFF",
    purple48: "#C399FF",
    purple30: "#9aacc0",

    red130: "#cf2e55",
    red120: "#dd1144",
    red114: "#ee2255",
    red110: "#e7315e",
    red106: "#FF0055",
    red100: "#ff3366",

    orange100: "#EE4444",

    yellow120: "#FFAA00",
    yellow110: "#FFBB00",
    yellow100: "#FFCC00",

    black100: "#000000",
    white100: "#ffffff",
}
