import type { CanvasNode } from "document/models/CanvasTree"
import type { TextVerticalAlignment } from "framer"

export interface WithTextVerticalAlignment {
    textVerticalAlignment: TextVerticalAlignment
}

const key: keyof WithTextVerticalAlignment = "textVerticalAlignment"

export const textVerticalAlignmentDefaults: WithTextVerticalAlignment = {
    textVerticalAlignment: "top",
}

export function withTextVerticalAlignment(node: CanvasNode): node is CanvasNode & WithTextVerticalAlignment {
    return key in node
}
