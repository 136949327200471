import { V68, V69 } from "../types"
import { _, exactCheck } from "../utils/exactCheck"
import { withoutProps } from "../utils/withoutProps"

export function migration_68_69(documentJSON: V68.Tree): V69.Tree {
    const root = documentJSON.root

    if (root.__class !== "RootNode") {
        return {
            version: 69,
            root: exactCheck(root, _ as V69.TreeNode),
        }
    }

    const defaultPageId = getFallbackPageId(root)

    const masterChildren: V69.BaseNode[] = []
    const otherChildren: V69.BaseNode[] = []

    for (let node of root.children ?? []) {
        let childrenArray = masterChildren

        if (node.__class !== "PageNode" && typeof node.isExternalMaster !== "string") {
            node = { ...node, parentid: defaultPageId }
            childrenArray = otherChildren
        }

        childrenArray.push(node)
    }

    const defaultPage: V69.PageNode = {
        __class: "PageNode",
        id: defaultPageId,
        name: "Page 1",
        children: otherChildren,
        homeNodeId: root.homeNodeId ?? null,
        guidesX: root.guidesX ?? [],
        guidesY: root.guidesY ?? [],
    }

    const migratedRoot = withoutProps(
        {
            ...root,
            children: [defaultPage, ...masterChildren],
        },
        "homeNodeId",
        "guidesX",
        "guidesY"
    )

    return {
        version: 69,
        root: exactCheck(migratedRoot, _ as V69.RootNode),
    }
}

export function getFallbackPageId(root: V69.BaseNode) {
    return root.id + "-page"
}
