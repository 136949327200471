import type { CanvasNode } from "document/models/CanvasTree"

export interface WithPosition {
    x: number
    y: number
}

export const positionDefaults: WithPosition = {
    x: 0,
    y: 0,
}

const key: keyof WithPosition = "x"

export function withPosition(node: CanvasNode): node is CanvasNode & WithPosition {
    return key in node
}
