import { ActionHandler, ControlType } from "framer"
import { addActionControls } from "./actionControls"

export type SetVariantType = "cycle" | "set"

export interface SetVariantOptions {
    type: SetVariantType
    variantId: string | undefined // only if type === set
}

export function useSetVariant(): ActionHandler {
    return () => {}
}

interface SetVariantActionControlTypes {
    type: ControlType.Enum
    variantId: ControlType.String
}

export const setVariantActionControlTypes: SetVariantActionControlTypes = {
    type: ControlType.Enum,
    variantId: ControlType.String,
}

export const setVariantActionDefaults: SetVariantOptions = {
    type: "set",
    variantId: undefined,
}

addActionControls(useSetVariant, "Set Variant", {
    type: {
        title: "Transition",
        type: setVariantActionControlTypes.type,
        options: ["set", "cycle"],
        optionTitles: ["Once", "Cycle"],
        displaySegmentedControl: true,
        defaultValue: setVariantActionDefaults.type,
    },
    variantId: {
        type: setVariantActionControlTypes.variantId,
        hidden: props => props.type === "cycle",
    },
})
