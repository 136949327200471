import { nameDefaults } from "document/models/CanvasTree/traits/Name"
import { instructionalChildrenDefaults } from "document/models/CanvasTree/traits/Children"
import { aspectRatioDefaults } from "document/models/CanvasTree/traits/AspectRatioLock"
import { pathBooleanDefaults } from "document/models/CanvasTree/traits/PathBoolean"
import { rotationDefaults } from "document/models/CanvasTree/traits/Rotation"
import { shapeStrokeDefaults } from "document/models/CanvasTree/traits/Stroke"
import { visibilityDefaults } from "document/models/CanvasTree/traits/Visibility"
import { lockDefaults } from "document/models/CanvasTree/traits/Lock"
import { sizeDefaults } from "document/models/CanvasTree/traits/Size"
import { withExportDefaults } from "document/models/CanvasTree/traits/Export"
import { opacityDefaults } from "document/models/CanvasTree/traits/Opacity"
import { boxShadowDefaults } from "document/models/CanvasTree/traits/BoxShadowDefaults"
import { pathFillDefaults } from "document/models/CanvasTree/traits/FillDefaults"
import { memoRecord } from "../memoRecord"

export const getBooleanShapeRecord = memoRecord(() => {
    return {
        ...nameDefaults,
        ...instructionalChildrenDefaults,
        ...pathFillDefaults,
        ...pathBooleanDefaults,
        ...rotationDefaults,
        ...aspectRatioDefaults,
        ...shapeStrokeDefaults,
        ...opacityDefaults,
        ...boxShadowDefaults,
        ...visibilityDefaults,
        ...lockDefaults,
        ...sizeDefaults,
        ...withExportDefaults,
        x: 0,
        y: 0,
    }
})
