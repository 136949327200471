import type { ServiceManager } from "./ServiceManager"

export namespace ServiceDebugging {
    export interface Logger {
        extend(name: string): Logger
        debug(...args: unknown[]): void
        trace(...args: unknown[]): void
        warn(...args: unknown[]): void
    }

    /** Customize the logger that is used to log service messages. */
    // Assigned by the runtime (e.g., in src/app/vekter/src/environment/index.ts)
    // eslint-disable-next-line prefer-const
    export let log: Logger = {
        extend: () => {
            throw Error("ServiceDebugging.log must be initialized before using any Services API")
        },
        debug: () => {
            throw Error("ServiceDebugging.log must be initialized before using any Services API")
        },
        trace: () => {
            throw Error("ServiceDebugging.log must be initialized before using any Services API")
        },
        warn: () => {
            throw Error("ServiceDebugging.log must be initialized before using any Services API")
        },
    }
}

/**
 * @private Infrastructure that should never be used outside the Framer Services package implementation.
 */
export namespace ServiceDebugging {
    /** @private Only for tests. Enables or disables testing mode. */
    // eslint-disable-next-line prefer-const
    export let _isTesting = false
    let _testingServiceManager: ServiceManager | undefined

    /** @private Only for tests. Temporarily changes the value of ServiceManager.shared(). */
    export async function _testWithShared<T>(manager: ServiceManager, fn: () => Promise<T>) {
        if (!_isTesting) throw new Error("ServiceManager.isTesting must be true to use testWithShared()")
        if (_testingServiceManager) throw new Error("ServiceManager.testWithShared() may not be nested")

        try {
            _testingServiceManager = manager
            return await fn()
            // eslint-disable-next-line no-useless-catch
        } catch (error) {
            throw error
        } finally {
            _testingServiceManager = undefined
        }
    }

    /** @private For ServiceManager */
    export function _sharedServiceManagerIfTesting(): ServiceManager | undefined {
        if (_isTesting) {
            if (!_testingServiceManager) {
                throw new Error(
                    "ServiceManager.shared() may not be used while testing. Use testWithShared() for explicitness."
                )
            } else {
                return _testingServiceManager
            }
        }

        // Just use the standard shared logic if not testing
        return undefined
    }
}
