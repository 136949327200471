import { hasStackLayout } from "document/models/CanvasTree/traits/StackLayout"
import { unsafeGetParent } from "./unsafeGetParent"

import type { CanvasNode } from "document/models/CanvasTree/nodes/CanvasNode"

export function nodeIsParentDirected(node: CanvasNode) {
    if (node.cache.parentDirected !== undefined) {
        return node.cache.parentDirected
    }

    const parent = unsafeGetParent(node)

    if (parent && hasStackLayout(parent) && parent.stackEnabled) {
        return true
    }

    return false
}
