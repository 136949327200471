import * as React from "react"
import type {
    RawDraftContentBlock,
    RawDraftContentState,
    DraftInlineStyle,
    RawDraftInlineStyleRange,
    DraftInlineStyleType,
} from "draft-js"

import { fontStore } from "../fonts/fontStore"
import { defaultFontSelector } from "../fonts/fonts"
import { TypefaceSourceNames } from "../fonts/types"

import {
    getCSSColorFromStyle,
    getCSSNumberValueWithUnit,
    getCSSNumericValueFromString,
    getCSSNumericValueAsString,
    getCSSTextAlignment,
    getCSSNumberUnit,
    cssToString,
} from "./cssUtils"
import { cached } from "./cached"
import { Range, isRangeOverlappingRange, getRangeForCharacterIndex, isRangeCollapsed, getSortedRange } from "./range"
import { escapeHTML } from "./escapeHTML"

/**
 * Converts a RawDraftContentState to plain HTML, cached
 * @param content - A {@link RawDraftContentState} representing a text draft
 * @param text - Optional override text, replacing the original draft content
 * @internal
 */
export const draftContentStateToHTML: (content: RawDraftContentState, text?: string) => string = cached(
    "html",
    draftContentStateToHTMLConverter
)

/**
 * Converts a RawDraftContentState to plain HTML
 */
function draftContentStateToHTMLConverter(content: RawDraftContentState, text?: string): string {
    let body: string
    let reactCSS: React.CSSProperties = {
        wordWrap: "break-word",
        whiteSpace: "pre-wrap",
        tabSize: 4, // NOTE: Matches value set in `stylesForHandoff`
    }
    if (text) {
        // Use override text instead of RawDraftContentState's text contents
        body = escapeHTML(text)
        reactCSS = { ...reactCSS, ...getReactStylesFromDraft(getDraftStylesAtCharacterIndex(content, 0)) }
    } else {
        body = content.blocks.map(draftContentBlockToHTML).join("")
        reactCSS = { ...reactCSS, lineHeight: 0, fontSize: 0 }
    }

    const inlineStyles = cssToString(getStylesFromReact(reactCSS))
    return `<div style='${inlineStyles}'>${body}</div>`
}

const DraftPrefixes = {
    FONT: "FONT:",
    COLOR: "COLOR:",
    SIZE: "SIZE:",
    LETTERSPACING: "LETTERSPACING:",
    LINEHEIGHT: "LINEHEIGHT:",
    ALIGN: "ALIGN:",
    BOLD: "BOLD:",
    ITALIC: "ITALIC:",
    UNDERLINE: "UNDERLINE:",
    SELECTION: "SELECTION:",
    TEXTDECORATION: "TEXTDECORATION:",
    TEXTTRANSFORM: "TEXTTRANSFORM:",
}

export type DraftPrefix = keyof typeof DraftPrefixes

/**
 * Returns if a draft style can be applied on a character range or only on a full line
 * @internal
 */
function isDraftInlineStyle(style: string) {
    return !style.includes("ALIGNMENT")
}

/**
 * A set of functions which define how to apply a specific attribute of text
 * styling (font, color, size, etc.) into CSS and from CSS
 *
 * @internal
 */
interface StyleHandler<T> {
    prefix: string
    default: T
    setCSS: (value: string | T, css: React.CSSProperties) => void
    fromCSS: (css: React.CSSProperties) => string | number | undefined
}

/** @internal */
interface DraftStyleDefinitions {
    font: StyleHandler<string>
    color: StyleHandler<string>
    size: StyleHandler<number>
    letterSpacing: StyleHandler<number>
    lineHeight: StyleHandler<string | number>
    align: StyleHandler<string | undefined>
    textTransform: StyleHandler<TextTransform>
    textDecoration: StyleHandler<TextDecoration>
}

/**
 * @internal
 */
type TextTransform = "capitalize" | "uppercase" | "lowercase" | undefined
const validTextTransform: TextTransform[] = ["capitalize", "uppercase", "lowercase", undefined]

/** @internal */
type TextDecoration = "underline" | "line-through" | undefined
const validTextDecoration = ["underline", "line-through", undefined]

/** @internal */
// Matches the `HTMLDir` type in https://github.com/facebook/fbjs/blob/master/packages/fbjs/src/unicode/UnicodeBidiDirection.js
// but adds `undefined` to indicate “neutral”
type TextDirection = "ltr" | "rtl" | undefined

function toUpperCase(value: any) {
    if (typeof value === "string") return value.toUpperCase()
    return value
}

function toLowerCase(value: any) {
    if (typeof value === "string") return value.toLowerCase()
    return value
}

/** @internal */
export const draftStyleDefinitions: DraftStyleDefinitions = {
    font: {
        prefix: "FONT:",
        default: defaultFontSelector,
        setCSS: getStyleForTypefaceOrSelector,
        fromCSS: getFontStyleStringFromCSS,
    },
    color: {
        prefix: "COLOR:",
        default: "rgb(0, 0, 0)",
        setCSS: (value, css) => (css.WebkitTextFillColor = value),
        fromCSS: css => getCSSColorFromStyle(css),
    },
    size: {
        prefix: "SIZE:",
        default: 16,
        setCSS: (value, css) => (css.fontSize = `${value}px`),
        fromCSS: css => getCSSNumericValueFromString(css.fontSize, "px"),
    },
    letterSpacing: {
        prefix: "LETTERSPACING:",
        default: 0,
        setCSS: (value, css) => (css.letterSpacing = `${value}px`),
        fromCSS: css => getCSSNumericValueFromString(css.letterSpacing, "px"), // Todo: maybe rounding
    },
    lineHeight: {
        prefix: "LINEHEIGHT:",
        default: 1.2,
        setCSS: (value, css) => {
            // Some documents might be saved with a NaN value for line height.
            // If those are found, we are making sure they fall back to their default value on the canvas.
            const lineHeight = getCSSNumericValueAsString(value)
            css.lineHeight = lineHeight === undefined ? `${draftStyleDefinitions.lineHeight.default}` : lineHeight
        },
        fromCSS: css => getCSSNumericValueFromString(css.lineHeight),
    },
    align: {
        default: undefined,
        prefix: "ALIGN:",
        setCSS: (value, css) => (css.textAlign = getCSSTextAlignment(value)),
        fromCSS: css => toUpperCase(css.textAlign),
    },
    textTransform: {
        prefix: "TEXTTRANSFORM:",
        default: undefined,
        setCSS: (value, css) =>
            validTextTransform.includes(toLowerCase(value)) && (css.textTransform = toLowerCase(value)),
        fromCSS: css => toUpperCase(css.textTransform),
    },
    textDecoration: {
        prefix: "TEXTDECORATION:",
        default: undefined,
        setCSS: (value, css) =>
            validTextDecoration.includes(toLowerCase(value)) && (css.textDecoration = toLowerCase(value)),
        fromCSS: css => toUpperCase(css.textDecoration),
    },
}

const draftDefaultStyles: React.CSSProperties = {}
Object.keys(draftStyleDefinitions).forEach(style => {
    if (style === "font") return // Don’t bother with the font
    const handler = draftStyleDefinitions[style]
    if (isStyleHandler(handler)) {
        handler.setCSS(handler.default, draftDefaultStyles)
    }
})
Object.freeze(draftDefaultStyles)

/**
 * Convert a block of Draft to html.
 * @internal
 */
function draftContentBlockToHTML(block: RawDraftContentBlock): string {
    if (!block.text.length) {
        const css = { width: "auto", ...getReactStylesFromDraft(getDraftBlockEmptyStyles(block)) }
        const style = cssToString(getStylesFromReact(css))
        return `<div style='${style}'><br></div>`
    }

    const chunkIndexes = new Set([0, block.text.length])

    for (const style of block.inlineStyleRanges) {
        if (!isDraftInlineStyle(style.style)) continue
        chunkIndexes.add(style.offset)
        chunkIndexes.add(style.offset + style.length)
    }

    const html: string[] = []

    const characters = [...block.text]

    for (let i = 0; i < characters.length; i++) {
        if (chunkIndexes.has(i)) {
            const css = {
                ...draftDefaultStyles,
                ...getReactStylesFromDraft(getDraftBlockStylesInRange(block, getRangeForCharacterIndex(i))),
            }
            // css.backgroundColor = "rgba(255, 0, 0, .2)"
            const styles = getStylesFromReact(css)

            if (i > 0) html.push(`</span>`)
            html.push(`<span style='${cssToString(styles)}'>`)
        }

        html.push(escapeHTML(characters[i]))
    }

    html.push("</span>")

    const blockStyles = getStylesFromReact({
        fontSize: 0,
        ...getReactStylesFromDraft(
            getDraftBlockStylesInRange(block, [0, block.text.length]).filter(style => !isDraftInlineStyle(style))
        ),
        ...getReactStylesForTextDirection(block.data?.["direction"]),
    })

    return `<div style='${cssToString(blockStyles)}'>${html.join("")}<br></div>`
}

/**
 * Return the styles to apply if the styles cannot be read from text (when no text was added).
 * @internal
 */
function getDraftBlockEmptyStyles(block: RawDraftContentBlock): string[] {
    if (!block.data || !block.data["emptyStyle"]) return []
    const emptyStyle = block.data["emptyStyle"] as string[]
    return emptyStyle
}

/**
 * Return the draft styles for a given character index.
 * @internal
 */
function getDraftStylesAtCharacterIndex(content: RawDraftContentState, index: number): string[] {
    return getDraftStylesInRange(content, getRangeForCharacterIndex(index))
}

/**
 * @internal
 */
function getDraftStylesInRange(content: RawDraftContentState, range?: Range): DraftInlineStyleType[] {
    return getDraftStyleRangesWithPrefix(content, undefined, range).map(inlineStyle => inlineStyle.style)
}

/**
 * @internal
 */
function getDraftRange(content: RawDraftContentState): Range {
    return [0, getDraftText(content).length - 1]
}

/**
 * Return the plain text for a given draft data structure, with an optional delimiter.
 * @internal
 */
export function getDraftText(content: RawDraftContentState, join = "") {
    return content.blocks.map(b => b.text).join(join)
}

/**
 * Returns the range for the style to insert at the next caret insertion. This is always the previous character, except for the beginning (first character) and beyond the last character (last character)
 * @internal
 */
export function getDraftRangeAtCaretIndex(content: RawDraftContentState, index: number): Range {
    const text = getDraftText(content)
    if (index === 0) return [0, 1]
    if (index > text.length) return getDraftRangeAtCaretIndex(content, text.length)
    return [index - 1, index]
}

type RawDraftInlineStyleRangeWithIndex = RawDraftInlineStyleRange & { index: number }

/**
 * Return all the draft styles in this text range. If the range is collapsed, we assume it's the caret position.
 * @internal
 */
function getDraftStyleRangesWithPrefix(
    content: RawDraftContentState,
    prefix?: DraftPrefix,
    range?: Range,
    stopAtFirst = false,
    modify?: (inlineStyle: RawDraftInlineStyleRangeWithIndex) => RawDraftInlineStyleRange
): RawDraftInlineStyleRangeWithIndex[] {
    // Use the full range if no range was given
    range = range || getDraftRange(content)

    // If this range is collapsed, we assume we are dealing with the caret position
    if (isRangeCollapsed(range)) {
        range = getDraftRangeAtCaretIndex(content, range[0])
    }

    // Make sure the range is sorted
    range = getSortedRange(range)

    let blockRangeIndex = 0
    const results: RawDraftInlineStyleRangeWithIndex[] = []
    for (const block of content.blocks) {
        if (block.text.length === 0) continue
        // We can stop if the block range exceeds the requested range
        if (blockRangeIndex > Math.max(...range)) return results

        for (let inlineStyleIndex = 0; inlineStyleIndex < block.inlineStyleRanges.length; inlineStyleIndex++) {
            const inlineStyle = block.inlineStyleRanges[inlineStyleIndex]

            // If a prefix filter was given, skip if this is not a match
            if (prefix && !inlineStyle.style.startsWith(prefix)) continue

            // Calcluate the range for this inline style so we can compare it
            const inlineStyleRangeIndex = blockRangeIndex + inlineStyle.offset
            const inlineStyleRange: Range = [inlineStyleRangeIndex, inlineStyleRangeIndex + inlineStyle.length]

            // See if the requested range overlaps with this style range and add to results
            if (isRangeOverlappingRange(range, inlineStyleRange)) {
                const inlineStyleWithIndex = { index: inlineStyleRangeIndex, ...inlineStyle }

                results.push(inlineStyleWithIndex)

                if (modify) {
                    block.inlineStyleRanges[inlineStyleIndex] = modify(inlineStyleWithIndex)
                }

                // If we're just looking for a first match, we can stop here
                if (stopAtFirst) break
            }
        }
        blockRangeIndex += block.text.length
    }

    return results
}

/** @internal */
export function getStyleForTypefaceOrSelector(value: string, css: React.CSSProperties = {}): React.CSSProperties {
    let selectors: string[] = []
    let selector = ""
    let alias = ""

    // Styled text will have the alias set as "value". See if this is the case:
    if (fontStore.local.isTypefaceAlias(value)) {
        alias = value
        // The value is an alias. Resolve it to the full selector:
        value = fontStore.local.getTypefaceSelectorByAlias(value) || ""
    }

    const typeface = fontStore.getTypeface({ source: TypefaceSourceNames.Local, family: value })
    if (typeface && typeface.fonts.length) {
        // Try to match by an exact selector since taking typeface.fonts[0]
        // relies on the order of fonts, which may arrive in a different order
        const font = typeface.fonts.find(t => t.selector === value) || typeface.fonts[0]
        selector = font.selector
    }
    if (selector) {
        // An alias comes in at this level for the font selector. See if this is the case:
        if (fontStore.local.isTypefaceAlias(selector)) {
            // The value is an alias. Resolve it to the full selector:
            selector = fontStore.local.getTypefaceSelectorByAlias(selector) || ""
        }

        selectors = selector.split("|")
    }

    if (!selector) {
        selectors = value.split("|")
        const fontProperties = fontStore.getDraftPropertiesBySelector(alias || value)

        if (fontProperties) {
            const family = fontProperties.family
            let weight = fontProperties.weight
            let style = fontProperties.style

            const isSFPro = family.startsWith("SF Pro")
            // SF Pro and other special system font selectors are not available on iOS 13
            // So we fall back on to -apple-system for SF Pro, and we have to set weight and style
            if (isSFPro) {
                if (weight) {
                    // SF style css is always applied first as a default initial value, we don't want to overwrite the weight in this case.
                    // There's an edge case for `SF Pro Text Semibold`, which is defined as font-weight: 600,
                    // but examining `/System/Library/Fonts/SFNSText.ttf` shows that it's actually 590.9791409170672
                    // If we set 600 on MacOS 10.14 or lower, it makes the font bolder
                    // Setting it to 599 is below the 600 threshold and does not mess it up.
                    // We still need the font-weight to be set for newer OSs that will fallback to -apple-system font
                    // It ain't pretty, but hopefully this holds so we won't have to test OS in this part of the code.
                    weight = weight === 400 ? undefined : weight - 1
                }
                // Currently, font-style does not get sent from the app host so we detect if it's needed by the variant name
                if (!style && /italic/i.test(fontProperties.variant || "")) {
                    style = "italic"
                }
            }
            if (!selectors.includes(family) && weight !== undefined) {
                selectors.push(family)
                if (weight) {
                    css.fontWeight = weight
                }
            }
            if (isSFPro) {
                selectors.push("-apple-system", "BlinkMacSystemFont")
            }
            if (style) {
                css.fontStyle = style
            }
        }
    }

    // remove duplicate entries in font-family
    const families = Array.from(
        new Set(
            selectors.map(t => {
                const fontProperties = fontStore.getDraftPropertiesBySelector(t)
                if (fontProperties && fontProperties.source !== TypefaceSourceNames.Local) {
                    return fontProperties.family
                }
                return t
            })
        )
    )

    css.fontFamily = families.map(quoteFontFamilyIfNeeded).join(", ")

    // add monospace, sans-serif, or serif, based on some lists of known fonts
    if (value.match(/mono|consolas|console|courier|menlo|monaco/i)) {
        css.fontFamily += ", monospace"
    } else if (value.match(/serif|roboto.slab/i)) {
        css.fontFamily += ", serif"
    } else if (value.match(/sans|arial|roboto|sfui|futura|helvetica|grande|tahoma|verdana|inter/i)) {
        css.fontFamily += ", sans-serif"
    } else {
        css.fontFamily += ", serif"
    }

    return css
}

/** These font families should never be quoted in css, if they do they won't have any effect */
const GENERIC_FAMILIES = ["cursive", "fantasy", "monospace", "serif", "sans-serif"]

function quoteFontFamilyIfNeeded(s: string): string {
    return GENERIC_FAMILIES.includes(s) ? s : `"${s}"`
}

function isStyleHandler<T = any>(object: any): object is StyleHandler<T> {
    return object.setCSS !== undefined
}

function getReactStylesFromDraft(draftStyle: string[], ignore: DraftPrefix[] = []): React.CSSProperties {
    const styles: React.CSSProperties = {}

    draftStyle.forEach((style: DraftPrefix) => {
        if (ignore.includes(style)) {
            return
        } else if (style === "BOLD") {
            styles.fontWeight = getCSSBolderFontWeight(styles.fontWeight)
        } else if (style === "ITALIC") {
            styles.fontStyle = "italic"
        } else if (style === "SELECTION") {
            // If you don't want this, you can add "SELECTION" to the ignore list
            // TODO: this should really not be a static value here, but that's how I found it
            styles.backgroundColor = "rgba(128,128,128,0.33)"
        } else {
            // Todo: we can make this lookup a lot faster
            for (const styleType in draftStyleDefinitions) {
                const styleHandler = draftStyleDefinitions[styleType]
                // TODO: Do we need this check (since it's our own datastructure)
                if (!isStyleHandler(styleHandler)) continue
                if (style.startsWith(styleHandler.prefix)) {
                    styleHandler.setCSS(style.slice(styleHandler.prefix.length), styles)
                    break
                }
            }
        }
    })

    return styles
}

/**
 * Convert React styles to a regular css style object (camel case to dash case)
 * @internal
 */
function getStylesFromReact(reactCSS: React.CSSProperties): Partial<CSSStyleDeclaration> {
    const css = {}

    // We sort the keys for consistent results, I know you technically can't sort object keys, but practically you totally can.
    const keys = Object.keys(reactCSS).sort()

    for (const key of keys) {
        // Convert key to dash case
        let property = key.replace(/([a-zA-Z])(?=[A-Z])/g, "$1-").toLowerCase()

        if (property.startsWith("webkit")) {
            property = `-${property}`
        }

        css[property] = reactCSS[key]
    }

    return css
}

function getFontStyleStringFromCSS(css: React.CSSProperties): string | undefined {
    if (typeof css.fontFamily !== "string") {
        return
    }

    const familyMembers = css.fontFamily.split(/['"]?, ['"]?/)
    if (familyMembers.length === 0) {
        return
    }

    if (familyMembers.length > 1) {
        familyMembers.pop() // Remove fallback
    }

    familyMembers[0] = familyMembers[0].replace(/^['"]/, "")

    let selector = familyMembers.join("|")
    // Note: this is an assumption, because copying from another document with a missing font
    // might also end up here, that’s why we’ll keep it intact if we can’t find it.
    let font = fontStore.getFontBySelector(selector)
    if (!font) {
        familyMembers.pop()
        const possibleSelector = familyMembers.join("|")
        if (fontStore.getFontBySelector(possibleSelector)) {
            selector = possibleSelector
        }
    }

    // Resolve aliases
    const aliasSelector = fontStore.local.getTypefaceAliasBySelector(selector)
    if (aliasSelector) {
        selector = aliasSelector
    }

    // Clear font weight, if we have a selector this is already set and matches the weight
    // NOTE: This is a hack! It modifies the parameter passed in because it “knows” that font weight will
    // be processed after getting the font.
    font = fontStore.getFontBySelector(selector)
    if (font) {
        const weight = font.weight
        if (weight && `${weight}` === css.fontWeight) {
            css.fontWeight = "normal"
        }
    }

    return selector
}

/** Returns a bolder number if it's a number font weight, otherwise simply `bold` */
function getCSSBolderFontWeight(value?: string | number, increase = 300, max = 900) {
    const [num] = getCSSNumberUnit(value || "")
    return num !== null ? Math.min(num + increase, max) : "bold"
}

/**
 * @internal
 */
function getDraftBlockStylesInRange(block: RawDraftContentBlock, range: Range) {
    return block.inlineStyleRanges
        .filter(inlineStyle => isRangeOverlappingRange(getDraftBlockInlineStyleRange(inlineStyle), range))
        .map(styleRange => styleRange.style)
}

/**
 * @internal
 */
function getDraftBlockInlineStyleRange(inlineStyle: RawDraftInlineStyleRange): Range {
    return [inlineStyle.offset, inlineStyle.offset + inlineStyle.length]
}

/**
 * @internal
 */
function getReactStylesForTextDirection(direction: TextDirection): React.CSSProperties {
    if (direction === "ltr") return { direction: "ltr" }
    if (direction === "rtl") return { direction: "rtl", textAlign: "right" }
    return {}
}
