import type { Latest } from "@framerjs/document-migrations"

export type FramePresetID = Latest.FramePresetID

export type FramePresetCategory = "Phone" | "Tablet" | "Desktop" | "Watch" | "TV" | "Package"

export interface FramePreset {
    id: FramePresetID
    title: string
    screenWidth: number
    screenHeight: number
    canRotate: boolean
    category: FramePresetCategory
}

type FramePresetWithoutCategory = Omit<FramePreset, "category">[]

const _framePresetsByCategories: Record<FramePresetCategory, FramePresetWithoutCategory> = {
    Phone: [
        {
            id: "iPhone_390_844",
            title: "iPhone 12",
            screenWidth: 390,
            screenHeight: 844,
            canRotate: true,
        },
        {
            id: "iPhone_375_812_mini",
            title: "iPhone 12 Mini",
            screenWidth: 375,
            screenHeight: 812,
            canRotate: true,
        },
        {
            id: "iPhone_390_844_pro",
            title: "iPhone 12 Pro",
            screenWidth: 390,
            screenHeight: 844,
            canRotate: true,
        },
        {
            id: "iPhone_428_926_pro",
            title: "iPhone 12 Pro Max",
            screenWidth: 428,
            screenHeight: 926,
            canRotate: true,
        },
        {
            id: "iPhone_414_896",
            title: "iPhone 11",
            screenWidth: 414,
            screenHeight: 896,
            canRotate: true,
        },
        {
            id: "iPhone_375_812",
            title: "iPhone 11 Pro / X",
            screenWidth: 375,
            screenHeight: 812,
            canRotate: true,
        },
        {
            id: "iPhone_414_896_pro",
            title: "iPhone 11 Pro Max",
            screenWidth: 414,
            screenHeight: 896,
            canRotate: true,
        },
        {
            id: "iPhone_375_667",
            title: "iPhone 8",
            screenWidth: 375,
            screenHeight: 667,
            canRotate: true,
        },
        {
            id: "iPhone_414_736",
            title: "iPhone 8 Plus",
            screenWidth: 414,
            screenHeight: 736,
            canRotate: true,
        },
        {
            id: "iPhone_320_568",
            title: "iPhone SE",
            screenWidth: 320,
            screenHeight: 568,
            canRotate: true,
        },
        {
            id: "Android_360_640",
            title: "Android",
            screenWidth: 360,
            screenHeight: 640,
            canRotate: true,
        },
        {
            id: "GooglePixel_360_780",
            title: "Google Pixel 5",
            screenWidth: 360,
            screenHeight: 780,
            canRotate: true,
        },
        {
            id: "GooglePixel_360_760",
            title: "Google Pixel 4",
            screenWidth: 360,
            screenHeight: 760,
            canRotate: true,
        },
        {
            id: "GooglePixel_360_760_xl",
            title: "Google Pixel 4XL",
            screenWidth: 360,
            screenHeight: 760,
            canRotate: true,
        },
    ],
    Desktop: [
        {
            id: "MacBook_1440_900",
            title: "MacBook Air",
            screenWidth: 1440,
            screenHeight: 900,
            canRotate: false,
        },
        {
            id: "MacBook_1440_900_pro",
            title: `MacBook Pro 13"`,
            screenWidth: 1440,
            screenHeight: 900,
            canRotate: false,
        },
        {
            id: "MacBook_Pro_16_1536_960",
            title: `MacBook Pro 16"`,
            screenWidth: 1536,
            screenHeight: 960,
            canRotate: false,
        },
        {
            id: "iMac_215_2048_1152",
            title: `iMac 21.5"`,
            screenWidth: 2048,
            screenHeight: 1152,
            canRotate: false,
        },
        {
            id: "iMac_2560_1440",
            title: `iMac 27"`,
            screenWidth: 2560,
            screenHeight: 1440,
            canRotate: false,
        },
        {
            id: "Pro_Display_XDR_3008_1692",
            title: "Pro Display XDR",
            screenWidth: 3008,
            screenHeight: 1692,
            canRotate: false,
        },
        {
            id: "DellXPS_1920_1080",
            title: "Dell XPS",
            screenWidth: 1920,
            screenHeight: 1080,
            canRotate: false,
        },
        {
            id: "SurfaceBook_1500_1000",
            title: "Microsoft Surface Book",
            screenWidth: 1500,
            screenHeight: 1000,
            canRotate: false,
        },
    ],
    Tablet: [
        {
            id: "iPad_810_1080",
            title: `iPad`,
            screenWidth: 810,
            screenHeight: 1080,
            canRotate: true,
        },
        {
            id: "iPad_768_1024",
            title: "iPad Mini",
            screenWidth: 768,
            screenHeight: 1024,
            canRotate: true,
        },
        {
            id: "iPad_820_1180",
            title: `iPad Air`,
            screenWidth: 820,
            screenHeight: 1180,
            canRotate: true,
        },
        {
            id: "iPad_834_1194",
            title: `iPad Pro 11"`,
            screenWidth: 834,
            screenHeight: 1194,
            canRotate: true,
        },
        {
            id: "iPad_1024_1366",
            title: `iPad Pro 12.9"`,
            screenWidth: 1024,
            screenHeight: 1366,
            canRotate: true,
        },
        {
            id: "Surface_1440_960",
            title: "Surface 3",
            screenWidth: 1440,
            screenHeight: 960,
            canRotate: true,
        },
        {
            id: "Surface_1368_912",
            title: "Surface Pro 4",
            screenWidth: 1368,
            screenHeight: 912,
            canRotate: true,
        },
    ],
    Watch: [
        {
            id: "AppleWatch_44",
            title: "Apple Watch 44mm",
            screenWidth: 184,
            screenHeight: 224,
            canRotate: false,
        },
        {
            // Deprecated
            id: "AppleWatch_42",
            title: "Apple Watch 42mm",
            screenWidth: 156,
            screenHeight: 195,
            canRotate: false,
        },
        {
            id: "AppleWatch_40",
            title: "Apple Watch 40mm",
            screenWidth: 162,
            screenHeight: 197,
            canRotate: false,
        },
        {
            // Deprecated
            id: "AppleWatch_38",
            title: "Apple Watch 38mm",
            screenWidth: 136,
            screenHeight: 170,
            canRotate: false,
        },
    ],
    TV: [
        {
            id: "FullHD_TV_1920_1080",
            title: "Full HD",
            screenWidth: 1920,
            screenHeight: 1080,
            canRotate: false,
        },
        {
            id: "4K_TV_3840_2160",
            title: "4K",
            screenWidth: 3840,
            screenHeight: 2160,
            canRotate: false,
        },
    ],
    Package: [
        {
            id: "PackageArtwork_800_600",
            title: "Artwork",
            screenWidth: 800,
            screenHeight: 600,
            canRotate: false,
        },
        {
            id: "PackageIcon_50_50",
            title: "Icon",
            screenWidth: 50,
            screenHeight: 50,
            canRotate: false,
        },
    ],
}

export const framePresetsByCategory: Record<FramePresetCategory, FramePreset[]> = _framePresetsByCategories as Record<
    FramePresetCategory,
    FramePreset[]
>

export const framePresetCategories: FramePresetCategory[] = []

const framePresetMap: Record<FramePresetID, FramePreset> = {} as Record<FramePresetID, FramePreset>

export const allFramePresets: FramePreset[] = []

// Populate framePresetCategories, framePresetMap, and allFramePresets
Object.entries(_framePresetsByCategories).forEach(([presetCategory, presets]: [FramePresetCategory, FramePreset[]]) => {
    framePresetCategories.push(presetCategory)
    presets.forEach(preset => {
        framePresetMap[preset.id] = preset
        allFramePresets.push(preset)
        preset.category = presetCategory // Was ommitted
    })
})

export function getFramePreset(id: FramePresetID | null): FramePreset | null {
    if (!id) return null
    return framePresetMap[id] ?? null
}

export function getFramePresetName(id: FramePresetID | null): string | null {
    return getFramePreset(id)?.title ?? null
}

export function isFramePresetID(value: unknown): value is FramePresetID {
    return typeof value === "string" && value in framePresetMap
}
