import type { CanvasTree } from "../CanvasTree"
import type { CanvasNode } from "../nodes/CanvasNode"
import FrameNode, { isStackComponent } from "../nodes/FrameNode"
import { isDeviceNode } from "../nodes/DeviceNode"
import { isShapeContainerNode } from "../nodes/ShapeContainerNode"
import { isVectorNode } from "../nodes/TreeNode"
import { isTextNode } from "../nodes/TextNode"
import { isCodeComponentNode } from "../nodes/CodeComponentNode"
import { Rect, Point } from "framer"

import { paddingFromProps } from "document/utils/StackComponent/paddingFromProps"
import {
    collectBoundingBox,
    getRectContainingBoundingBox,
    getTextContentRect,
    expandBoundingBoxWithRect,
} from "utils/collectBoundingBox"
import { withAspectRatio, WithAspectRatio } from "document/models/CanvasTree/traits/AspectRatioLock"
import { withClip } from "document/models/CanvasTree/traits/Clip"
import type { LayoutCache } from "document/LayoutCache"
import Matrix from "document/models/Matrix"

export enum ContentEdgeSnap {
    none,
    top,
    bottom,
    left,
    right,
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
    horizontal,
    vertical,
}

// Controls whether the "Resize to Fit Content" context menu option shows up at all
export function sizeToFitContentAvailableForNode(node: CanvasNode) {
    return !(isVectorNode(node) || isDeviceNode(node))
}

export function sizeToFitContentAvailable(nodes: CanvasNode[]) {
    return nodes.length > 0 && nodes.some(sizeToFitContentAvailableForNode)
}

export function sizeToFitContentEnabledForNode(node: CanvasNode) {
    if (!sizeToFitContentAvailableForNode(node)) {
        return false
    }

    if (isCodeComponentNode(node)) {
        return true
    }

    if (isTextNode(node) && !node.autoSize) {
        return true
    }

    return !!node.children && node.children.some(child => child.visible)
}

// Controls whether "Resize to Fit" should be enabled for the nodes it's available on
export function sizeToFitContentEnabled(nodes: CanvasNode[]) {
    return nodes.some(sizeToFitContentEnabledForNode)
}

export function sizeToFitContent(
    tree: CanvasTree,
    nodes: CanvasNode[],
    layoutCache?: LayoutCache,
    edgeSnap: ContentEdgeSnap = ContentEdgeSnap.none
) {
    nodes.forEach(node => {
        if (!sizeToFitContentEnabledForNode(node)) {
            return
        }

        // we are not allowing the use of a cache because this might be executed in the prefreeze phase.
        const parentRect = tree.getParentRect(node, true, false)
        const nodeRect = tree.getRect(node)
        const transformMatrix = node.transformMatrix(parentRect)

        let contentRect = Rect.atOrigin(nodeRect)

        if (isCodeComponentNode(node) && layoutCache) {
            const contentSize = layoutCache.getContentSize(node.id)

            if (contentSize) {
                contentRect.width = contentSize.width
                contentRect.height = contentSize.height
            }
        } else if (isTextNode(node)) {
            contentRect = getTextContentRect(node)
        } else if (node.children && node.children.length > 0) {
            const bbox = { minX: Infinity, maxX: -Infinity, minY: Infinity, maxY: -Infinity }
            const ignoreShadows = !(isShapeContainerNode(node) || isVectorNode(node) || (withClip(node) && node.clip))
            const ignoreInvisible = !(isShapeContainerNode(node) || isVectorNode(node))

            node.children!.forEach(child => {
                collectBoundingBox({
                    node: child,
                    box: bbox,
                    parentRect: nodeRect,
                    layoutCache,
                    ignoreInvisible,
                    ignoreShadows,
                })
            })

            contentRect = getRectContainingBoundingBox(bbox)
        }

        if (isStackComponent(node)) {
            contentRect = adjustedRectForStackFittingContentRect(node, nodeRect, contentRect)
            edgeSnap = contentEdgeSnapForStack(node, edgeSnap)
        }

        contentRect = adjustContentRectForContentEdgeSnap(contentRect, nodeRect, edgeSnap)

        const offset = Matrix.convertPoint(transformMatrix, contentRect)
        const normalizedMatrix = node.transformMatrix(parentRect, Rect.atOrigin(contentRect))
        const rotationOffset = Matrix.convertPoint(normalizedMatrix, { x: 0, y: 0 })

        const rect = {
            ...Point.subtract(offset, rotationOffset),
            width: contentRect.width,
            height: contentRect.height,
        }

        let resetAspectRatio: WithAspectRatio | undefined
        if (withAspectRatio(node)) {
            resetAspectRatio = { aspectRatio: null }
        }

        const update = { ...resetAspectRatio, ...node.updateForRect(rect, parentRect, true) }
        tree.updateNode(node, update)

        if (!isStackComponent(node) && !isCodeComponentNode(node)) {
            node.children?.forEach(child => {
                const childRect = tree.getRect(child)
                childRect.x -= contentRect.x
                childRect.y -= contentRect.y

                tree.updateNode(child, child.updateForRect(childRect, contentRect, true))
            })
        }
    })
}

/**
 * Adjusts the content bounds rect of a Stack to account for stack direction / distribution and alignment,
 * so that when the Stack's rect is updated, only the Stack container would appear to resize without moving
 * any of its children.
 *
 * @param node the stack node
 * @param nodeRect the stack rect relative to its parent
 * @param contentRect the collected bounds of the stack's children
 * @returns a rect that will snugly fit around the stack's contents without moving its children
 */
function adjustedRectForStackFittingContentRect(node: FrameNode, nodeRect: Rect, contentRect: Rect): Rect {
    let adjustedContentRect = { ...contentRect }

    // Instead of directly using the content bounds, we take a more conservative approach by starting from the
    // untransformed size of the Stack's immediate children and then progressively expanding it to fit whatever
    // it can from the total content bounds without causing any of the children to move.

    const immediateChildrenBbox = { minX: Infinity, maxX: -Infinity, minY: Infinity, maxY: -Infinity }
    node.children.forEach(child => {
        expandBoundingBoxWithRect(immediateChildrenBbox, child.rect(nodeRect))
    })

    const immediateChildrenBounds = getRectContainingBoundingBox(immediateChildrenBbox)

    const isVerticalStack = node.stackDirection === "vertical"
    const horizontalAlignment = node[isVerticalStack ? "stackAlignment" : "stackDistribution"]
    const verticalAlignment = node[isVerticalStack ? "stackDistribution" : "stackAlignment"]
    const center = Rect.center(immediateChildrenBounds)

    // When a stack is center-aligned along an axis, we'll attempt to symmetrically expand it
    // along that axis, so it can fit its content while making it seem like the content didn't move.
    //
    // We do this by expanding resizedRect to also fit its vertical / horizontal reflection over the
    // center of the bounds.

    if (verticalAlignment === "center") {
        const reflectedVertically = {
            ...adjustedContentRect,
            y: center.y + (center.y - adjustedContentRect.y) - adjustedContentRect.height,
        }

        adjustedContentRect = Rect.merge(adjustedContentRect, reflectedVertically)
    }

    if (horizontalAlignment === "center") {
        const reflectedHorizontally = {
            ...adjustedContentRect,
            x: center.x + (center.x - adjustedContentRect.x) - adjustedContentRect.width,
        }

        adjustedContentRect = Rect.merge(adjustedContentRect, reflectedHorizontally)
    }

    // In the case where nested children stick out of the immediate children bounds, we need to
    // decide if expanding the Stack in a particular direction is even possible. E.g. for a vertical Stack
    // with a Start distribution, and content sticking out from the top, no amount of resizing
    // will make the content fit inside the Stack - it will always be offset from the top.
    // To constrain the problem, we modify the bounds to only include expansion in the directions
    // we can be sure we can grow in.

    const shouldExpandBottom = ["start", "center"].includes(verticalAlignment as string)
    const shouldExpandTop = ["end", "center"].includes(verticalAlignment as string)
    const shouldExpandRight = ["start", "center"].includes(horizontalAlignment as string)
    const shouldExpandLeft = ["end", "center"].includes(horizontalAlignment as string)

    adjustedContentRect = Rect.fromTwoPoints(
        {
            x: shouldExpandLeft ? adjustedContentRect.x : immediateChildrenBounds.x,
            y: shouldExpandTop ? adjustedContentRect.y : immediateChildrenBounds.y,
        },
        {
            x: shouldExpandRight ? Rect.maxX(adjustedContentRect) : Rect.maxX(immediateChildrenBounds),
            y: shouldExpandBottom ? Rect.maxY(adjustedContentRect) : Rect.maxY(immediateChildrenBounds),
        }
    )

    // Increase the resulting bounds by the amount of padding we have on each side.

    const padding = paddingFromProps(node)
    const paddingWidth = padding.left + padding.right
    const paddingHeight = padding.top + padding.bottom

    adjustedContentRect.width += paddingWidth
    adjustedContentRect.height += paddingHeight

    adjustedContentRect.x -= padding.left
    adjustedContentRect.y -= padding.top

    // In the space-around or space-evenly distribution case, shrinking / expanding the container can move
    // the children as free space is redistributed. To avoid this, we'll only change the container bounds
    // if there's no free space to distribute (i.e. when the container is smaller than its content)

    if (node.stackDistribution === "space-around" || node.stackDistribution === "space-evenly") {
        if (isVerticalStack && immediateChildrenBounds.height + paddingHeight <= nodeRect.height) {
            adjustedContentRect.y = 0
            adjustedContentRect.height = nodeRect.height
        }

        if (!isVerticalStack && immediateChildrenBounds.width + paddingWidth <= nodeRect.width) {
            adjustedContentRect.x = 0
            adjustedContentRect.width = nodeRect.width
        }
    }

    return adjustedContentRect
}

/**
 * Stack content can't always snap to an individual edge. This function converts individual edge
 * options to whatever makes most sense given the alignment/distribution of the Stack. For content
 * that's centered horizontally, for example, it will convert a left/right edge preference to a
 * symmetrical `horizontal` one, because only resizing from one edge would have resulted in children
 * moving around in the resized stack.
 *
 * @param node
 * @param edge
 */
function contentEdgeSnapForStack(node: FrameNode, edge: ContentEdgeSnap) {
    const isVerticalStack = node.stackDirection === "vertical"
    const horizontalAlignment = node[isVerticalStack ? "stackAlignment" : "stackDistribution"]
    const verticalAlignment = node[isVerticalStack ? "stackDistribution" : "stackAlignment"]

    switch (edge) {
        case ContentEdgeSnap.left:
        case ContentEdgeSnap.right: {
            if (horizontalAlignment === "center") {
                return ContentEdgeSnap.horizontal
            }
            break
        }
        case ContentEdgeSnap.top:
        case ContentEdgeSnap.bottom: {
            if (verticalAlignment === "center") {
                return ContentEdgeSnap.vertical
            }
            break
        }
        case ContentEdgeSnap.topLeft:
        case ContentEdgeSnap.bottomLeft:
        case ContentEdgeSnap.topRight:
        case ContentEdgeSnap.bottomRight: {
            if (horizontalAlignment === "center" || verticalAlignment === "center") {
                // You can represent most layout configurations of a stack in a 3x3 grid:
                //  ___ ___ ___
                // |   |ccc|   |
                // |___|___|___|
                // |ccc|ccc|ccc|
                // |___|___|___|
                // |   |ccc|   |
                // |___|___|___|
                //
                // For example, in a stack with distribution start and alignment center, the
                // content will occupy the middle cell of the top row.
                //
                // Out of all these configurations, we only need to special-case behavior when
                // our content is in one of the cells marked with "ccc" above, because they're
                // the ones that will require our stack to grow/shrink symmetrically in order
                // to not move its children around. They also happen to be all the centered ones.
                //
                // So we will use the following test - if the preferred edge (the resize handle
                // that was clicked) is aligned on the same side as the content, we will only resize
                // on a single axis (horizontal for horizontally-aligned content and vertical otherwise).
                // Otherwise we'll resize on all sides (default behavior).
                //
                // Since this might be hard to picture, here's an example:
                //
                // Let's say that our content is in the first cell of row 2 so it's either in a vertical
                // stack with center distribution and start alignment, or a horizontal stack with start
                // distribution and center alignment. Let's say the user clicks the  the top-left resize handle.
                // The content cell is left-aligned, which is the same side the handle is on. This means we'll
                // only resize along one axis. The horizontal alignment is "start", which means this is a
                // vertically-centered stack, so we'll resize vertically.

                const edgeMatchesAlignmentSide =
                    ([ContentEdgeSnap.topLeft, ContentEdgeSnap.topRight].includes(edge) &&
                        verticalAlignment === "start") ||
                    ([ContentEdgeSnap.bottomLeft, ContentEdgeSnap.bottomRight].includes(edge) &&
                        verticalAlignment === "end") ||
                    ([ContentEdgeSnap.topLeft, ContentEdgeSnap.bottomLeft].includes(edge) &&
                        horizontalAlignment === "start") ||
                    ([ContentEdgeSnap.topRight, ContentEdgeSnap.bottomRight].includes(edge) &&
                        horizontalAlignment === "end")

                if (edgeMatchesAlignmentSide) {
                    return horizontalAlignment === "center" ? ContentEdgeSnap.horizontal : ContentEdgeSnap.vertical
                }

                return ContentEdgeSnap.none
            }
            break
        }
    }

    return edge
}

/**
 * Adjusts the size of the content rect to account for the ContentEdgeSnap option, triggered by double-clicking
 * one of the resize handles. Depending on the ContentEdgeSnap value, the content rect will be inflated or clipped,
 * so it appears as if "dead air" is sliced off between the resize handle and the closest edge of the content inside
 * the container
 *
 * @param contentRect
 * @param nodeRect
 * @param edge
 */
function adjustContentRectForContentEdgeSnap(contentRect: Rect, nodeRect: Rect, edge: ContentEdgeSnap) {
    if (edge === ContentEdgeSnap.none) {
        return contentRect
    }

    let expandedRect: Rect | null = null

    // Expand the content rect in the direction opposite from that of the edge it's supposed to snap to
    // (e.g. when snapping to the right edge, the content rect will inflate to the left), until it meets
    // the edges of the container essentially filling in the empty space around the content in certain
    // directions with imaginary content. Content that overflows in the opposite direction will essentially
    // be clipped (from the sizing calculation only, not visually)
    switch (edge) {
        case ContentEdgeSnap.bottom: {
            expandedRect = Rect.fromTwoPoints(
                { x: 0, y: 0 },
                { x: nodeRect.width, y: Math.max(0, Rect.maxY(contentRect)) }
            )
            break
        }
        case ContentEdgeSnap.top: {
            expandedRect = Rect.fromTwoPoints(
                { x: 0, y: Math.min(nodeRect.height, contentRect.y) },
                { x: nodeRect.width, y: nodeRect.height }
            )
            break
        }
        case ContentEdgeSnap.left: {
            expandedRect = Rect.fromTwoPoints(
                { x: Math.min(contentRect.x, nodeRect.width), y: 0 },
                { x: nodeRect.width, y: nodeRect.height }
            )
            break
        }
        case ContentEdgeSnap.right: {
            expandedRect = Rect.fromTwoPoints(
                { x: 0, y: 0 },
                { x: Math.max(0, Rect.maxX(contentRect)), y: nodeRect.height }
            )
            break
        }
        case ContentEdgeSnap.bottomRight: {
            expandedRect = Rect.fromTwoPoints(
                { x: 0, y: 0 },
                { x: Math.max(0, Rect.maxX(contentRect)), y: Math.max(0, Rect.maxY(contentRect)) }
            )
            break
        }
        case ContentEdgeSnap.bottomLeft: {
            expandedRect = Rect.fromTwoPoints(
                { x: Math.min(contentRect.x, nodeRect.width), y: 0 },
                { x: nodeRect.width, y: Math.max(0, Rect.maxY(contentRect)) }
            )
            break
        }
        case ContentEdgeSnap.topRight: {
            expandedRect = Rect.fromTwoPoints(
                { x: 0, y: Math.min(nodeRect.height, contentRect.y) },
                { x: Math.max(0, Rect.maxX(contentRect)), y: nodeRect.height }
            )
            break
        }
        case ContentEdgeSnap.topLeft: {
            expandedRect = Rect.fromTwoPoints(
                { x: Math.min(contentRect.x, nodeRect.width), y: Math.min(nodeRect.height, contentRect.y) },
                { x: nodeRect.width, y: nodeRect.height }
            )
            break
        }
        case ContentEdgeSnap.horizontal: {
            expandedRect = Rect.fromTwoPoints(
                { x: contentRect.x, y: 0 },
                { x: Rect.maxX(contentRect), y: nodeRect.height }
            )
            break
        }
        case ContentEdgeSnap.vertical: {
            expandedRect = Rect.fromTwoPoints(
                { x: 0, y: contentRect.y },
                { x: nodeRect.width, y: Rect.maxY(contentRect) }
            )
            break
        }
    }

    // When the content is entirely outside of the container, the expanded rect
    // will have 0-width/0-height, which Framer doesn't allow. To avoid this,
    // we'll make sure the width/height is at least 1.
    if (expandedRect) {
        expandedRect.width = Math.max(1, expandedRect.width)
        expandedRect.height = Math.max(1, expandedRect.height)
    }

    return expandedRect || contentRect
}
