import { V70, V71 } from "../types"
import { withoutProps } from "../utils/withoutProps"
import { transform } from "../utils/transform"
import { _, exactCheck } from "../utils/exactCheck"
import {
    ReplicaFromNode,
    transformReplicaOverrides,
    UpdateOverrides,
    GetInheritedPropValue,
} from "../utils/transformReplicaOverrides"

function hasId<T extends Record<string, unknown>>(value: T): value is T & { id: string } {
    return "id" in value
}

function addIdsIfNecessary<T extends Record<string, unknown>>(values: T[], id: string): (T & { id: string })[] {
    return values.map((value, idx) => (hasId(value) ? value : { ...value, id: `s-${id}-${idx}` }))
}

function migrateBoxShadows(boxShadows: V70.WithBoxShadow["boxShadows"], id: string): V71.WithBoxShadow | undefined {
    return boxShadows ? { boxShadows: addIdsIfNecessary(boxShadows, id) } : undefined
}

function migrateShadows(shadows: V70.WithShadow["shadows"], id: string): V71.WithShadow | undefined {
    return shadows ? { shadows: addIdsIfNecessary(shadows, id) } : undefined
}

// Replicas

function migrateReplicaBoxShadow<T>(overrides: T & V70.WithBoxShadow, id: string): T & V71.WithBoxShadow {
    const { boxShadows, ...rest } = overrides

    const result = rest as T

    return {
        ...result,
        ...migrateBoxShadows(boxShadows, id),
    }
}

function migrateReplicaShadow<T>(overrides: T & V70.WithShadow, id: string): T & V71.WithShadow {
    const { shadows, ...rest } = overrides

    const result = rest as T

    return {
        ...result,
        ...migrateShadows(shadows, id),
    }
}

type BoxShadowReplica = ReplicaFromNode<
    | V71.FrameNode
    | V71.CodeComponentNode
    | V71.BooleanShapeNode
    | V71.OvalShapeNode
    | V71.PathNode
    | V71.PolygonShapeNode
    | V71.RectangleShapeNode
    | V71.StarShapeNode
>
type ShadowReplica = ReplicaFromNode<V71.TextNode | V71.SVGNode>

function replicaMigration(
    originalNode: V70.TreeNode,
    _getInheritedValue: GetInheritedPropValue,
    updateOverrides: UpdateOverrides
) {
    switch (originalNode.__class) {
        case "FrameNode":
        case "CodeComponentNode":
        case "BooleanShapeNode":
        case "OvalShapeNode":
        case "PathNode":
        case "PolygonShapeNode":
        case "RectangleShapeNode":
        case "StarShapeNode": {
            updateOverrides(originalNode, _ as BoxShadowReplica, migrateReplicaBoxShadow)
            break
        }
        case "TextNode":
        case "SVGNode": {
            updateOverrides(originalNode, _ as ShadowReplica, migrateReplicaShadow)
            break
        }
    }
}

export function migration_70_71(documentJSON: V70.Tree): V71.Tree {
    const migratedRoot = transform(documentJSON.root, (node, idToNode) => {
        switch (node.__class) {
            case "FrameNode":
            case "CodeComponentNode":
            case "BooleanShapeNode":
            case "OvalShapeNode":
            case "PathNode":
            case "PolygonShapeNode":
            case "RectangleShapeNode":
            case "StarShapeNode": {
                const migratedNode = {
                    ...withoutProps({ ...node }, "boxShadows"),
                    ...migrateBoxShadows(node.boxShadows, node.id),
                }

                if (migratedNode.__class === "FrameNode") {
                    transformReplicaOverrides(migratedNode, idToNode, replicaMigration)
                }
                return exactCheck(
                    migratedNode,
                    _ as
                        | V71.FrameNode
                        | V71.CodeComponentNode
                        | V71.BooleanShapeNode
                        | V71.OvalShapeNode
                        | V71.PathNode
                        | V71.PolygonShapeNode
                        | V71.RectangleShapeNode
                        | V71.StarShapeNode
                )
            }
            case "TextNode":
            case "SVGNode": {
                const migratedNode = {
                    ...withoutProps({ ...node }, "shadows"),
                    ...migrateShadows(node.shadows, node.id),
                }

                return exactCheck(migratedNode, _ as V71.TextNode | V71.SVGNode)
            }
            default:
                return exactCheck(node, _ as V71.TreeNode)
        }
    })

    return { version: 71, root: migratedRoot }
}
