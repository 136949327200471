import type { CanvasNode } from "document/models/CanvasTree"
import { VariableReference, isVariableReference } from "./VariableReference"

export interface WithOpacity {
    opacity: number | VariableReference
}

export const opacityDefaults: WithOpacity = {
    opacity: 1,
}

const key: keyof WithOpacity = "opacity"

export function withOpacity(node: CanvasNode): node is CanvasNode & WithOpacity {
    return key in node
}

export function reduceOpacity(node: CanvasNode, result: { opacity?: number | VariableReference | null }) {
    if (!withOpacity(node)) {
        return
    }

    if (result.opacity === null) {
        return
    }

    if (isVariableReference(node.opacity)) {
        if (result.opacity === undefined) {
            result.opacity = node.opacity
        } else if (!isVariableReference(result.opacity) || result.opacity.id !== node.opacity.id) {
            result.opacity = null
        }
        return
    }

    if (result.opacity === undefined) {
        result.opacity = node.opacity
    } else if (result.opacity !== null && result.opacity !== node.opacity) {
        result.opacity = null
    }
}
