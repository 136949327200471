import * as Framer from "framer"
import { DeviceCodeComponent } from "../../components/Device/DeviceCodeComponent"
import {
    BuiltInFramerComponentIdentifier,
    BuiltInPackageIdentifier,
} from "../../sandbox/componentLoader/BuiltInFramerComponentIdentifier"
import type { EntityMap, HostPropertyControls } from "./types"

// Hardcoded because bundlers rename components, and we can’t rely on `Function.name`
export const stackComponentName = "Stack"
export const scrollComponentName = "Scroll"
export const pageComponentName = "Page"
export const deviceComponentName = "Device"

export const defaultComponentDefinitions: EntityMap = {
    [BuiltInFramerComponentIdentifier.Stack]: {
        depth: 1,
        file: "",
        identifier: BuiltInFramerComponentIdentifier.Stack,
        name: stackComponentName,
        packageIdentifier: "framer",
        // Because the Stack comes from "framer", but the ComponentMap from "framer/render",
        // the PropertyControl types don't match up and we need this cast
        properties: serializable(Framer.getPropertyControls(Framer.Stack)) as HostPropertyControls,
        type: "component",
    },
    [BuiltInFramerComponentIdentifier.Scroll]: {
        depth: 1,
        file: "",
        identifier: BuiltInFramerComponentIdentifier.Scroll,
        name: scrollComponentName,
        packageIdentifier: "framer",
        // Because the Stack comes from "framer", but the ComponentMap from "framer/render",
        // the PropertyControl types don't match up and we need this cast
        properties: {
            ...serializable(Framer.getPropertyControls(Framer.Scroll)),
            children: { title: "Content", type: Framer.ControlType.ComponentInstance },
        } as HostPropertyControls,
        type: "component",
    },
    [BuiltInFramerComponentIdentifier.Page]: {
        depth: 1,
        file: "",
        identifier: BuiltInFramerComponentIdentifier.Page,
        name: pageComponentName,
        packageIdentifier: "framer",
        // Because the Stack comes from "framer", but the ComponentMap from "framer/render",
        // the PropertyControl types don't match up and we need this cast
        properties: serializable(Framer.getPropertyControls(Framer.Page)) as HostPropertyControls,
        type: "component",
    },
    [BuiltInFramerComponentIdentifier.Device]: {
        depth: 1,
        file: "",
        identifier: BuiltInFramerComponentIdentifier.Device,
        name: deviceComponentName,
        packageIdentifier: BuiltInPackageIdentifier,
        properties: serializable(Framer.getPropertyControls(DeviceCodeComponent)) as HostPropertyControls,
        type: "component",
    },
}

function serializable(obj: {} | undefined): {} | undefined {
    return JSON.parse(JSON.stringify(obj))
}
