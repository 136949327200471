import type { List, Set } from "immutable"
import type {
    MotionStyle,
    Rect,
    Size,
    ConstraintValues,
    BorderStyle,
    BlendingMode,
    LayerProps,
    ImageFit,
    FrameProps,
    StackDirection,
    StackDistribution,
    StackAlignment,
    DeprecatedVisualProperties,
    StackProperties,
} from "framer"
import { DimensionType } from "framer"
import type { DrawableNode } from ".."
import { CanvasNode } from "./CanvasNode"
import { withPinsSizeRatioConstraints } from "../traits/mixins/withPinsSizeRatioConstraints"
import type { WithRect } from "../traits/Frame"
import { getFrameRecord } from "../records/FrameRecord"
import { updateConstrainedFrame, updateConstrainedSize } from "document/models/ConstraintSolver"
import type { WithName } from "document/models/CanvasTree/traits/Name"
import type { WithChildren } from "document/models/CanvasTree/traits/Children"
import type { WithIntrinsicSize } from "document/models/CanvasTree/traits/IntrinsicSize"
import type { WithRotation } from "document/models/CanvasTree/traits/Rotation"
import type { WithBorder } from "document/models/CanvasTree/traits/Border"
import type { WithBorderPerSide } from "document/models/CanvasTree/traits/BorderPerSide"
import type { WithVisibility } from "document/models/CanvasTree/traits/Visibility"
import type { WithLock } from "document/models/CanvasTree/traits/Lock"
import type { WithExport } from "document/models/CanvasTree/traits/Export"
import type { WithFramePreset } from "document/models/CanvasTree/traits/FramePreset"
import { WithTemplate, ReplicaInfo, isMaster, isReplica } from "../traits/Template"
import { setDefaults } from "./MutableNode"
import type { ExportOptions } from "./ExportOptions"
import { collectBackgroundPropsForNode } from "document/models/CanvasTree/traits/utils/collectBackgroundForNode"
import type { WithFill, WithOptionalFill, FillType } from "document/models/CanvasTree/traits/Fill"
import type { WithOpacity } from "document/models/CanvasTree/traits/Opacity"
import { borderPropsForNode } from "document/models/CanvasTree/traits/utils/borderForNode"
import type { WithBoxShadow } from "document/models/CanvasTree/traits/BoxShadow"
import type { BoxShadow } from "document/models/Shadow"
import { collectNameForNode } from "document/models/CanvasTree/traits/utils/collectNameForNode"
import type { WithCodeOverride } from "../traits/CodeOverride"
import type { PreviewSettings } from "preview-next/PreviewSettings"
import type { WithFilters } from "document/models/CanvasTree/traits/Filters"
import type { WithRelativeRadius } from "document/models/CanvasTree/traits/Radius"
import type { WithRadiusPerCorner } from "document/models/CanvasTree/traits/RadiusPerCorner"
import type { WithClip } from "../traits/Clip"
import type { WithBlending } from "../traits/Blending"
import type { RadialGradient, LinearGradient } from "document/models/Gradient"
import type { WithTarget } from "../traits/Target"
import type { WithPreviewSettings } from "../traits/PreviewSettings"
import { safeName } from "utils/names"
import { stringFromNodeID } from "document/models/CanvasTree"
import type { WithGuides } from "../traits/Guides"
import type { WithOverlayGrid, OverlayGrid } from "../traits/OverlayGrid"
import { EventActions, WithFrameEvents, frameEventKeys } from "../traits/FrameEvents"
import { WithEventActions, EventActionInfoMap } from "../traits/EventActions"
import type { WithStackLayout } from "../traits/StackLayout"
import type { WithPadding } from "../traits/Padding"
import { cssExport } from "document/utils/StackComponent/cssExport"
import type { FramePresetID } from "../traits/utils/framePresets"
import { withClassDiscriminator } from "utils/withClassDiscriminator"
import { collectBorderVariables, collectStyle } from "../traits/collectStyles"
import { GestureType, WithVariant } from "../traits/Variant"
import type { VariableReference } from "../traits/VariableReference"
import type { TagProps } from "variants/types"
import type { Transition } from "document/models/Transition"
import { Attribute } from "../utils/buildJSX"
import { attributesFromProps } from "variants/utils/attributesFromProps"
import { createNodeEventHandler } from "variants/utils/createEventHandler"
import { WithScreen } from "../traits/Screen"
import type { CanvasTree } from "../CanvasTree"
import { withDOMLayoutTraits } from "document/models/CanvasTree/traits/mixins/withDOMLayoutTraits"
import { collectCentering, collectStackLayout } from "../traits/collectProps"
import { isNumber } from "utils/typeChecks"
import { needsMinSize } from "../traits/utils/needsMinSize"

export function isFrameNode(node: CanvasNode): node is DrawableNode & FrameNode {
    return node instanceof FrameNode
}

export interface StackComponentNode extends FrameNode {
    stackEnabled: true
}

export function isStackComponent(node: CanvasNode | null): node is StackComponentNode {
    return !!node && isFrameNode(node) && node.stackEnabled === true
}

// eslint-disable-next-line import/no-default-export
export default class FrameNode
    extends withClassDiscriminator("FrameNode", withDOMLayoutTraits(withPinsSizeRatioConstraints(CanvasNode)))
    implements
        WithScreen,
        WithRect,
        WithName,
        WithFramePreset,
        WithPreviewSettings,
        WithVisibility,
        WithLock,
        WithChildren,
        WithIntrinsicSize,
        WithFill,
        WithOptionalFill,
        WithOpacity,
        WithRotation,
        WithClip,
        WithBorder,
        WithBorderPerSide,
        WithRelativeRadius,
        WithRadiusPerCorner,
        WithBoxShadow,
        WithBlending,
        WithFilters,
        WithExport,
        WithTemplate,
        WithVariant,
        WithTarget,
        WithCodeOverride,
        WithGuides,
        WithOverlayGrid,
        WithStackLayout,
        WithPadding,
        WithFrameEvents,
        WithEventActions {
    children: CanvasNode[]
    name: string | null
    visible: boolean | VariableReference
    locked: boolean
    framePreset: FramePresetID | null
    previewSettings: PreviewSettings | null

    stackEnabled: boolean | undefined
    stackDirection: StackDirection | undefined
    stackDistribution: StackDistribution | undefined
    stackAlignment: StackAlignment | undefined
    stackGap: number | undefined

    padding: number | undefined
    paddingPerSide: boolean | undefined
    paddingTop: number | undefined
    paddingRight: number | undefined
    paddingBottom: number | undefined
    paddingLeft: number | undefined

    isScreen: boolean | undefined

    isMaster: boolean
    isVariant: boolean | undefined
    gesture: GestureType | undefined
    variantTransition: Transition | undefined
    isExternalMaster: string | null
    replicaInfo: ReplicaInfo | null
    isTarget: boolean

    onTap: EventActions
    onTapStart: EventActions
    onClick: EventActions
    onMouseDown: EventActions
    onMouseUp: EventActions
    onMouseEnter: EventActions
    onMouseLeave: EventActions

    fillEnabled: boolean
    fillType: FillType
    fillColor: string | VariableReference
    fillLinearGradient: LinearGradient | undefined
    fillRadialGradient: RadialGradient | undefined
    fillImage: string | VariableReference | null
    fillImageOriginalName: string | null
    fillImageResize: ImageFit
    fillImagePixelWidth: number | null
    fillImagePixelHeight: number | null

    blendingMode: BlendingMode | undefined

    blur: number | VariableReference | undefined
    backgroundBlur: number | VariableReference | undefined
    brightness: number | VariableReference | undefined
    contrast: number | VariableReference | undefined
    grayscale: number | VariableReference | undefined
    hueRotate: number | VariableReference | undefined
    invert: number | VariableReference | undefined
    saturate: number | VariableReference | undefined
    sepia: number | VariableReference | undefined

    intrinsicWidth: number | null
    intrinsicHeight: number | null

    radiusPerCorner: boolean
    radius: number | VariableReference
    radiusIsRelative: boolean
    radiusTopLeft: number
    radiusTopRight: number
    radiusBottomLeft: number
    radiusBottomRight: number

    clip: boolean
    rotation: number | VariableReference
    opacity: number | VariableReference

    borderEnabled: boolean | undefined
    borderColor: string | undefined
    borderWidth: number | undefined
    borderStyle: BorderStyle | undefined
    borderPerSide: boolean | undefined
    borderTop: number | undefined
    borderRight: number | undefined
    borderBottom: number | undefined
    borderLeft: number | undefined

    boxShadows: Readonly<BoxShadow[]> | undefined

    exportOptions: List<ExportOptions>

    codeOverrideEnabled: boolean
    codeOverrideIdentifier: string | undefined
    codeOverrideFile: string | undefined
    codeOverrideName: string | undefined

    guidesX: Set<number>
    guidesY: Set<number>

    overlayGrid: OverlayGrid | undefined

    constructor(properties?: Partial<FrameNode>) {
        super()
        setDefaults<FrameNode>(this, getFrameRecord(), properties)
    }

    updateForRect(frame: Rect, parentSize: Size | null, constraintsLocked: boolean): any {
        const update = updateConstrainedFrame(
            frame,
            parentSize,
            this.constraints(),
            this.constraintsLocked || constraintsLocked,
            // pixelAlign
            !this.usesDOMRectCached()
        )
        if ((frame.width !== this.width || frame.height !== this.height) && this.framePreset !== null) {
            return { ...update, framePreset: null }
        }
        return update
    }

    updateForSize(size: Partial<Size>, parentSize: Size | null): Partial<ConstraintValues & WithFramePreset> {
        const update = updateConstrainedSize(size, parentSize, this.constraintValues())
        if (this.framePreset !== null) {
            return { ...update, framePreset: null }
        }
        return update
    }

    getProps(): Partial<FrameProps> & LayerProps {
        const style: MotionStyle = {}

        const props: Partial<FrameProps> = {
            ...super.getProps(),
            ...this.newConstraintProperties(),
            _border: borderPropsForNode(this),
            visible: this.resolveValue("visible"),
            style,
        }

        if (this.__unsafeIsGroundNode()) {
            props.left = 0
            props.top = 0
            if (isReplica(this) && props._constraints) {
                /* Replicas can receive the constraint props from their masters,
                 * which can break their size if they are ground nodes.
                 * Therefor we set the intrinsic width and height instead.
                 */
                if (isNumber(props._constraints.intrinsicWidth) && !isNumber(props.width)) {
                    props.width = props._constraints.intrinsicWidth
                }
                if (isNumber(props._constraints.intrinsicHeight) && !isNumber(props.height)) {
                    props.height = props._constraints.intrinsicHeight
                }
            }
        }

        const collectStyleTarget: DeprecatedVisualProperties = {}
        collectBackgroundPropsForNode(this, collectStyleTarget, true)
        props.background = collectStyleTarget.background
        collectStyle(this, style)

        /* XXX: This is a really specific fix for https://github.com/framer/company/issues/13080,
         * It might turn up in other cases as well, but it was created just before release,
         * so the goal was to not change too much.
         */
        let hasNameOverride = false

        if (isMaster(this) || isReplica(this)) {
            let added = false
            const _overrideForwardingDescription = {}
            this.walk(child => {
                if (child === this) return
                if (child.name) {
                    added = true
                    const name = safeName(child.name)
                    _overrideForwardingDescription[stringFromNodeID(child.id)] = name
                }
            })
            if (added) {
                hasNameOverride = Object.values(_overrideForwardingDescription).includes("name")
                props._overrideForwardingDescription = _overrideForwardingDescription
            }
        }
        if (!hasNameOverride) {
            collectNameForNode(this, props)
        }

        if (this.stackEnabled) {
            const stackProps: Partial<StackProperties> = {
                direction: this.stackDirection,
                distribution: this.stackDistribution,
                alignment: this.stackAlignment,
                gap: this.stackGap,
                padding: this.padding,
                paddingPerSide: this.paddingPerSide,
                paddingTop: this.paddingTop,
                paddingRight: this.paddingRight,
                paddingBottom: this.paddingBottom,
                paddingLeft: this.paddingLeft,
            }

            // Make sure empty stacks get minWidth/minHeight, so they look like
            // they do on the canvas. Whenever a stack update will result in the
            // stack being visually empty, the last calculated size will be
            // synced to the node's width/height property. This is the value
            // we'll use for minWidth/Height. Node that the stack can be
            // visually empty even when it might have hidden children.
            if (needsMinSize(this)) {
                if (this.widthType === DimensionType.Auto) {
                    stackProps.minWidth = this.width
                }

                if (this.heightType === DimensionType.Auto) {
                    stackProps.minHeight = this.height
                }
            }

            Object.assign(props, stackProps)
        }

        return props
    }

    /**
     * Get props and style for a Frame when compiled into a variant in an
     * Attribute format for serializing to JSX.
     */
    getAttributes(defaultProps: Partial<TagProps>): Attribute[] {
        const props: TagProps = {
            layoutId: this.id,
            style: {},
            ...defaultProps,
        }

        collectStyle(this, props.style, { withInlineVariables: true }, true)
        collectBorderVariables(this, props.style)
        collectBackgroundPropsForNode(this, props as any, true, { withInlineVariables: true })
        collectStackLayout(this, props)
        collectCentering(this, props)

        if (this.borderEnabled) props["data-border"] = true

        const attributes = attributesFromProps(props)
        const actions = this.getActions()

        // Add an attribute with each event handler for this node to the node's
        // attributes. This relies on useVariantState being in scope in the
        // generated component.
        for (const key in actions) {
            const handler = createNodeEventHandler(actions[key])
            if (!handler) continue

            attributes.push({
                type: "variable",
                name: key,
                value: handler,
            })
        }

        return attributes
    }

    providesFreeSpace(): boolean {
        return !!this.stackEnabled
    }

    getActions(): EventActionInfoMap {
        const actions: EventActionInfoMap = {}

        for (const actionKey of frameEventKeys) {
            const value = this[actionKey] as EventActions
            if (!value || value.length === 0) continue
            actions[actionKey] = value.map(eventAction => ({
                identifier: eventAction.actionIdentifier,
                info: { ...eventAction.controls },
            }))
        }

        return actions
    }

    hasActions(): boolean {
        return frameEventKeys.some(actionKey => {
            const value = this[actionKey]
            if (value && value.length > 0) return true
        })
    }

    collectCSS(style: React.CSSProperties) {
        if (!isStackComponent(this)) return
        const css = cssExport(this)
        const cssKeys = Object.keys(css)
        for (let i = 0, il = cssKeys.length; i < il; i++) {
            const key = cssKeys[i]
            const value = css[key]
            style[key] = value
        }
    }

    preFreeze(tree: CanvasTree) {
        // Make sure that the size stored in the tree of an empty auto-sized
        // stack is the latest calculated size, so the stack won't collapse.
        // This size will be passed in as a minWidth/minHeight to the rendered
        // stack when it's empty.
        const isAutoSized = this.widthType === DimensionType.Auto || this.heightType === DimensionType.Auto
        if (!isAutoSized) return

        const anyVisibleChildren = this.children.some(child => child.isVisible())
        if (anyVisibleChildren) return

        const domRect = this.getDOMRect()
        if (!domRect) return

        const size: Size = { width: this.width, height: this.height }

        if (this.widthType === DimensionType.Auto) {
            size.width = domRect.width
        }

        if (this.heightType === DimensionType.Auto) {
            size.height = domRect.height
        }

        // This update will end up in the same undo group that made the stack
        // visually empty (e.g. a hide or delete change), so the size sync
        // shouldn't be undoable on its own.
        tree.updateNode(this, size)
    }
}
