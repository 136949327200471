import { Set } from "immutable"
import { CanvasNode } from "./CanvasNode"
import { withClassDiscriminator } from "utils/withClassDiscriminator"
import type { WithChildren } from "../traits/Children"
import type { WithGuides } from "../traits/Guides"
import type { WithName } from "../traits/Name"
import type { CanvasNodeCache } from "./CanvasNodeCache"

interface CanvasNodeConstructor<Node extends CanvasNode> {
    new (...args: any[]): Node
}

export function isScopeNode<T>(node: T): node is T & ScopeNode {
    return node instanceof ScopeNode
}

/**
 * The scope node (and its subtree) is an entity that can be exported to code without having any dependencies to sibling scopes.
 * Each scope is rendered as an isolated canvas.
 */
export class ScopeNode extends withClassDiscriminator("ScopeNode", CanvasNode)
    implements WithGuides, WithChildren, WithName {
    children: CanvasNode[]
    guidesX: Set<number>
    guidesY: Set<number>

    constructor(properties?: Record<string, unknown>, cache?: CanvasNodeCache) {
        super(properties, cache)
    }

    getGroundNodes(filter?: (node: CanvasNode) => boolean): CanvasNode[] {
        const { children } = this.futureOrCurrent()
        return filter ? children.filter(filter) : children.slice()
    }

    getGroundNodesOfType<Node extends CanvasNode>(
        constructor: CanvasNodeConstructor<Node>,
        filter?: (node: Node) => boolean
    ): Node[] {
        const groundNodes = this.getGroundNodes(node => {
            if (node instanceof constructor) {
                return filter ? filter(node) : true
            }

            return false
        })

        return groundNodes as Node[]
    }
}
