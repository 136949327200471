import type { CanvasTree } from "document/models/CanvasTree"
import type { OverlayGridNode } from "../OverlayGrid"
import type { GuideSelector, GuideNode } from "../Guides"

export const getAbsoluteOffset = (
    tree: CanvasTree,
    node: GuideNode | OverlayGridNode,
    axis: GuideSelector["axis"],
    relativeOffset: GuideSelector["offset"]
): number => {
    const canvasRect = tree.convertFrameToCanvas(node)
    return canvasRect[axis] + relativeOffset
}
