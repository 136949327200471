import type { AssetService } from "@framerjs/assets"
import { FrameNode, CanvasNode } from "../document/models/CanvasTree"
import {
    PreviewDataSource,
    PreviewSettings,
    ServiceEventEmitter,
    PreviewWrapper as PreviewWrapperService,
    UnsafeJSON,
} from "@framerjs/framer-services"
import { assert, unhandledError, ResolvablePromise, newResolvablePromise } from "@framerjs/shared"
import * as React from "react"
import { PreviewWrapper, Props as PreviewWrapperProps } from "./PreviewWrapper"
import { loadDocumentJSON } from "../document/serialization"
import { isDeviceNode } from "../document/models/CanvasTree/nodes/DeviceNode"
import { getProjectHomeNode } from "../document/models/CanvasTree/utils/homeNode"
import {
    PreviewMetadata,
    PreviewSettings as FramePreviewSettings,
    PreviewSettingsDefaults,
} from "preview-next/PreviewSettings"
import { getDefaultName } from "../document/components/utils/nodes"
import { fetchDocumentWithUpdates, DocumentWithUpdates } from "./utils/fetchDocument"
import { fetchBundle, Bundle, basename } from "./utils/fetchBundle"
import { getLastValueAssetService } from "./utils/getLastValueAssetService"
import { borderRadiusForNode } from "./utils/borderRadiusForNode"
import { deviceConfigForNode } from "./utils/deviceConfigForNode"
import { useReadOnlyModulesStateService } from "./useReadOnlyModulesStateService"
import { withPreviewSettings } from "document/models/CanvasTree/traits/PreviewSettings"
import { useDarkMode } from "../../../web/src/lib/useDarkMode"
import { TreeUpdate } from "../document/RemoteDocumentInterface"
import { applyChanges } from "../document/models/CanvasTree/TreeDiff"

export interface StandalonePreviewWrapperProps extends PreviewWrapperProps {
    treeURL: string
    bundleURLs: string[]
    assetsEndpointURL?: string
    modulesEndpointURL?: string
}

const previewLoadEmitter = new ServiceEventEmitter<PreviewWrapperService.LoadEvent>()

/**
 * A PreviewWrapper variant that fetches the project resources on its own from
 * the provided URLs.
 */
export const StandalonePreviewWrapper = ({
    previewURL,
    treeURL,
    bundleURLs,
    assetsEndpointURL,
    modulesEndpointURL,
    highlightsDisabled,
    ...rest
}: StandalonePreviewWrapperProps) => {
    const handleLoadUpdate = React.useCallback((update: PreviewWrapperService.LoadEvent) => {
        previewLoadEmitter.emit(update)
    }, [])

    const assetService = useAssetService({ assetsEndpointURL })
    const [previewDataSourceService, { document, updates }] = usePreviewDataSourceService({ treeURL, bundleURLs })
    const nodeId = getNodeIdFromURL()
    const [previewSettingsService, status] = usePreviewSettingsService({
        document,
        updates,
        nodeId,
        highlightsDisabled,
    })
    const modulesStateService = useReadOnlyModulesStateService(modulesEndpointURL)

    React.useEffect(() => {
        handleLoadUpdate(status)
    }, [handleLoadUpdate, status])

    return (
        <PreviewWrapper
            {...rest}
            previewURL={previewURL}
            previewSettingsService={previewSettingsService}
            previewDataSourceService={previewDataSourceService}
            modulesStateService={modulesStateService}
            assetsService={assetService}
            loadStream={previewLoadEmitter.newStream}
        />
    )
}

async function notImplemented() {
    throw new Error("not implemented")
}

function getNodeIdFromURL(): string | undefined {
    return window.location.hash.slice(1) || undefined
}

function getPreviewMetadataForNode(canvasNode: CanvasNode): PreviewMetadata {
    const name = canvasNode.name || getDefaultName(canvasNode)
    const rect = canvasNode.rect()

    let settings = (canvasNode as FrameNode).previewSettings
    if (settings) {
        // The record might be old, not including all members that we expect there to be:
        settings = new FramePreviewSettings(settings)
    } else {
        settings = new FramePreviewSettings({ ...PreviewSettingsDefaults })
    }

    let responsive = settings.responsive
    let canChangeResponsive = true

    if (isDeviceNode(canvasNode)) {
        responsive = false
        canChangeResponsive = false
    }

    return {
        name,
        id: canvasNode.id,
        width: rect ? rect.width : null || undefined,
        height: rect ? rect.height : null || undefined,
        highlights: true,
        responsive,
        canChangeResponsive,
        touchCursor: settings.touch,
        selectionLock: false,
        settings,
    }
}

function useAssetService({ assetsEndpointURL }: { assetsEndpointURL?: string }): Promise<AssetService> | undefined {
    const assetService = React.useRef<Promise<AssetService> | undefined>()

    React.useEffect(() => {
        if (!assetsEndpointURL) return

        assetService.current = getLastValueAssetService(assetsEndpointURL).initializedAssetsService
    }, [assetsEndpointURL])

    return assetService.current
}

export interface Project {
    document?: UnsafeJSON
    updates?: TreeUpdate[]
    bundles: Record<string, string>
}

const treeUpdateEmitter = new ServiceEventEmitter<PreviewDataSource.TreeUpdateEvent>()
const scriptUpdateEmitter = new ServiceEventEmitter<PreviewDataSource.ScriptUpdateEvent>()

function usePreviewDataSourceService({
    treeURL,
    bundleURLs,
}: {
    treeURL: string
    bundleURLs: string[]
}): [PreviewDataSource.Interface, Project] {
    const didLoadDocument = React.useRef(false)

    const [document, setDocument] = React.useState<DocumentWithUpdates>()
    const documentRef = React.useRef<DocumentWithUpdates>()
    const documentReady = React.useRef(newResolvablePromise<DocumentWithUpdates>())
    React.useEffect(() => {
        fetchDocumentWithUpdates(treeURL)
            .then(doc => {
                setDocument(doc)
                documentRef.current = doc
                documentReady.current.resolve(doc)
            })
            .catch(unhandledError)
    }, [treeURL])

    const [bundles, setBundles] = React.useState<Record<string, string>>({})
    const bundlesRef = React.useRef<Record<string, string>>({})
    const bundlesReady = React.useRef<Record<string, ResolvablePromise<undefined>>>(
        bundleURLs.reduce(
            (ready, bundleURL) => ({
                ...ready,
                [basename(bundleURL)]: newResolvablePromise<undefined>(),
            }),
            {}
        )
    )

    const handleBundleResponse = React.useCallback((bundle: Bundle) => {
        setBundles(currentBundles => ({
            ...currentBundles,
            [bundle.name]: bundle.content,
        }))
        bundlesRef.current[bundle.name] = bundle.content
        bundlesReady.current[bundle.name].resolve(undefined)
    }, [])

    React.useEffect(() => {
        bundleURLs.forEach(bundleURL => {
            fetchBundle(bundleURL, { credentials: "include" }).then(handleBundleResponse).catch(unhandledError)
        })
    }, [bundleURLs, handleBundleResponse])

    const loadDocument = React.useCallback(async () => {
        await Promise.all([documentReady.current, ...Object.values(bundlesReady.current)])
        assert(documentRef.current, "documentReady resolved without setting a documentRef")
        didLoadDocument.current = true
        return {
            document: documentRef.current.document as UnsafeJSON,
            initialUpdates: documentRef.current.updates.flatMap(u => u.changes),
            scripts: bundlesRef.current,
        }
    }, [])

    const previewDataSourceService = React.useRef<PreviewDataSource.Interface>({
        loadDocument,
        treeUpdateStream: treeUpdateEmitter.newStream,
        scriptUpdateStream: scriptUpdateEmitter.newStream,
    })

    return [
        previewDataSourceService.current,
        { document: document?.document as UnsafeJSON, updates: document?.updates, bundles: bundles },
    ]
}

const previewMetadataUpdateEmitter = new ServiceEventEmitter<PreviewSettings.MetadataUpdateEvent>()

export function usePreviewSettingsService({
    document,
    updates,
    nodeId,
    highlightsDisabled,
}: {
    document?: UnsafeJSON
    updates?: TreeUpdate[]
    nodeId?: string
    highlightsDisabled?: boolean
}): [PreviewSettings.Interface, PreviewWrapperService.LoadEvent] {
    const [status, setStatus] = React.useState<PreviewWrapperService.LoadEvent>({ status: "loading" })

    const previewSettingsService = React.useRef<PreviewSettings.Interface>({
        toggleSelectionLock: notImplemented,
        toggleResponsive: notImplemented,
        toggleTouchCursor: notImplemented,
        setAppearance: notImplemented,
        toggleHighlights: notImplemented,
        setProjectTitle: notImplemented,
        openInExternalWindow: notImplemented,
        openFullScreen: notImplemented,
        addDevice: notImplemented,
        previewMetadataUpdateStream: previewMetadataUpdateEmitter.newStream,
    })

    // Ensure metadata is available for replay using LayoutEffect
    React.useLayoutEffect(() => {
        previewMetadataUpdateEmitter.emit({
            responsive: true,
            canChangeResponsive: false,
            touchCursor: false,
            highlights: !highlightsDisabled,
            selectionLock: false,
        })
        // This is only the initial emit, and we don't want it to re-run ever.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { isDarkMode } = useDarkMode()

    React.useEffect(() => {
        if (!document) return

        // TODO: This means that we're parsing and storing the document in
        // memory twice, once in the preview wrapper, and then in the preview
        // sandbox. We should re-architect this so that only the sandbox ever
        // loads the document.
        let previewTree = loadDocumentJSON(document)
        if (updates) {
            applyChanges(
                previewTree,
                updates.flatMap(u => u.changes)
            )
            previewTree = previewTree.commit()
        }
        const homeNode = getProjectHomeNode(previewTree)
        const nodeToRender = nodeId ? previewTree.getNode(nodeId) : homeNode

        if (nodeToRender) {
            const metadata = getPreviewMetadataForNode(nodeToRender)
            if (!metadata) return
            const previewSettings =
                nodeToRender && withPreviewSettings(nodeToRender) ? nodeToRender.previewSettings : null
            const node: PreviewSettings.Node = {
                type: "canvas",
                name: metadata.name,
                id: metadata.id || "",
                width: metadata.width,
                height: metadata.height,
                borderRadius: borderRadiusForNode(nodeToRender),
                hasLegacyDevice: metadata.settings?.skin !== undefined,
                backgroundEnabled: previewSettings?.backgroundEnabled,
                backgroundType: previewSettings?.backgroundType,
                backgroundColor: previewSettings?.backgroundColor,
                backgroundImage: previewSettings?.backgroundImage,
                backgroundImageResize: previewSettings?.backgroundImageResize,
            }
            const { deviceOptions } = deviceConfigForNode(nodeToRender, isDarkMode ? "dark" : "light")

            previewMetadataUpdateEmitter.emit({
                deviceOptions,
                node,
                responsive: metadata.settings.responsive,
                highlights: highlightsDisabled !== undefined ? !highlightsDisabled : metadata.highlights,
                canChangeResponsive: metadata.canChangeResponsive,
                touchCursor: metadata.settings.touch,
                selectionLock: false,
            })

            setStatus({ status: "loaded" })
        } else {
            if (previewTree.getGroundNodes().length === 0) {
                setStatus({ status: "error", error: { type: "documentEmpty" } })
            } else {
                setStatus({ status: "error", error: { type: "frameNotFound" } })
            }
        }
    }, [document, updates, nodeId, highlightsDisabled, isDarkMode])

    return [previewSettingsService.current, status]
}
