import type { Rect, Point } from "framer"

interface FramePoint extends Point {
    fx: number
    fy: number
}

namespace FramePoint {
    export const fromFrame = (frame: Rect): FramePoint[] => {
        const result: FramePoint[] = []
        const steps = [0, 0.5, 1]
        for (const fy of steps) {
            for (const fx of steps) {
                result.push({ x: fx * frame.width, y: fy * frame.height, fx: fx, fy: fy })
            }
        }
        return result
    }
}

export default FramePoint
