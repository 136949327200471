import { ControlType, ControlIcon, PropertyControls, ActionControls, Transition } from "framer"
import type {
    ArrayControlDescription,
    BooleanControlDescription,
    ColorControlDescription,
    ComponentInstanceDescription,
    ControlDescription,
    EnumControlDescription,
    FileControlDescription,
    EventHandlerControlDescription,
    FusedNumberControlDescription,
    ImageControlDescription,
    NumberControlDescription,
    SegmentedEnumControlDescription,
    StringControlDescription,
    BaseControlDescription,
    TransitionControlDescription,
    ObjectControlDescription,
} from "framer"
import { isFiniteNumber } from "../../utils/isFiniteNumber"
import { deprecationWarning } from "@framerjs/shared"
import { DefaultAssetReferenceKey, OptionalDefaultAssetReference } from "./types"

interface DeprecatedFusedNumberControlDescription<P = any> extends BaseControlDescription<P> {
    type: ControlType.FusedNumber
    valueKeys: [keyof P, keyof P, keyof P, keyof P]
    valueLabels: [string, string, string, string]
    min?: number
    // deprecated
    splitKey: keyof P
    splitLabels: [string, string]
}

type ControlDescriptionLocal =
    | BooleanControlDescription
    | NumberControlDescription
    | StringControlDescription
    | FusedNumberControlDescription
    | DeprecatedFusedNumberControlDescription
    | EnumControlDescription
    | SegmentedEnumControlDescription
    | ColorControlDescription
    | (ImageControlDescription & OptionalDefaultAssetReference)
    | FileControlDescription
    | ComponentInstanceDescription
    | ArrayControlDescription
    | EventHandlerControlDescription
    | TransitionControlDescription
    | ObjectControlDescription

type ObjectPropertyControlDescriptionLocal =
    | NumberControlDescription
    | EnumControlDescription
    | BooleanControlDescription
    | StringControlDescription
    | ColorControlDescription
    | SegmentedEnumControlDescription
    | ImageControlDescription
    | FileControlDescription
    | TransitionControlDescription

const ControlTypeNames: { [controlType: string]: boolean } = {
    boolean: true,
    number: true,
    string: true,
    fusednumber: true,
    enum: true,
    segmentedenum: true,
    color: true,
    image: true,
    file: true,
    componentinstance: true,
    component: true,
    array: true,
    eventhandler: true,
    transition: true,
    object: true,
}

export function isControlDescription(value: any): value is ControlDescription {
    return !!value && typeof value === "object" && "type" in value && ControlTypeNames[value.type]
}

export function verifyPropertyControls(desc: { [key: string]: any }): PropertyControls<any> {
    const controls: PropertyControls<any> = {}

    for (const key in desc) {
        const value = desc[key]
        const controlDescription = getControlDescription(value)
        if (!controlDescription) continue
        const d = defaultControl(controlDescription)
        if (!d) continue
        controls[key] = d
    }

    return controls
}

export function verifyActionControls(desc: { [key: string]: any }): ActionControls<any> {
    const controls: ActionControls<any> = {}
    for (const key in desc) {
        const value = desc[key]
        const controlDescription = getControlDescription(value)
        if (!controlDescription) continue
        const d = defaultControl(controlDescription)
        if (!d) continue
        if (d.type === ControlType.Array) continue
        if (d.type === ControlType.Object) continue
        if (d.type === ControlType.EventHandler) continue
        controls[key] = d
    }
    return controls
}

function getControlDescription(value: string | ControlDescriptionLocal): ControlDescriptionLocal | undefined {
    if (typeof value === "string") {
        if (isControlType(value)) {
            return { type: value } as ControlDescriptionLocal
        }
        return undefined
    } else if (isObject(value)) {
        const type = value.type
        if (typeof type === "string" && isControlType(type)) {
            return value
        }
    }

    return undefined
}

function isControlType(name: string): name is ControlType {
    return ControlTypeNames[name] !== null && ControlTypeNames[name] !== undefined
}

function defaultControl(
    // type: ControlType,
    values: ControlDescriptionLocal
): ControlDescription | ArrayControlDescription | null {
    const title = toString(values.title)
    const hidden = toHiddenFunction(values.hidden)
    switch (values.type) {
        case ControlType.Boolean: {
            const disabledTitle = toString(values.disabledTitle)
            const enabledTitle = toString(values.enabledTitle)
            const defaultValue = values.defaultValue
            const type = values.type
            return {
                title,
                hidden,
                defaultValue: toBoolean(defaultValue),
                type,
                disabledTitle,
                enabledTitle,
            }
        }
        case ControlType.Number: {
            const min = toNumber(values.min)
            const max = toNumber(values.max)
            const unit = toString(values.unit)
            const step = toNumber(values.step)
            const displayStepper = toBoolean(values.displayStepper)
            const { defaultValue, type } = values
            return { title, hidden, defaultValue: toNumber(defaultValue), type, min, max, unit, step, displayStepper }
        }
        case ControlType.String: {
            const { type, defaultValue } = values
            const placeholder = toString(values.placeholder)
            const obscured = toBoolean(values.obscured)
            const displayTextArea = toBoolean(values.displayTextArea)
            return { title, hidden, defaultValue, type, placeholder, obscured, displayTextArea }
        }
        case ControlType.FusedNumber: {
            const { type } = values
            const controlDescription = values as Partial<
                DeprecatedFusedNumberControlDescription & FusedNumberControlDescription
            >
            const defaultValue = controlDescription.defaultValue
            const valueKeys = toString4(controlDescription.valueKeys)
            const valueLabels = toString4(controlDescription.valueLabels)
            const min = toNumber(controlDescription.min)

            let toggleKey = toString(controlDescription.toggleKey)
            let toggleTitles = toString2(controlDescription.toggleTitles)

            if (!toggleKey) {
                toggleKey = toString(controlDescription.splitKey)
                if (!toggleKey) return null
                deprecationWarning("splitKey option of FusedNumber control type", "1.0.0", "toggleKey")
            }

            if (!toggleTitles) {
                toggleTitles = toString2(controlDescription.splitLabels)
                if (!toggleTitles) return null
                deprecationWarning("splitLabels option of FusedNumber control type", "1.0.0", "toggleTitles")
            }

            if (!toggleKey || !toggleTitles || !valueKeys || !valueLabels) return null
            return {
                title,
                hidden,
                defaultValue: toNumber(defaultValue),
                type,
                toggleKey,
                toggleTitles,
                valueKeys,
                valueLabels,
                min,
            }
        }
        case ControlType.Enum: {
            const { defaultValue, type, displaySegmentedControl } = values
            const options = toPrimitiveList(values.options)
            if (!options) return null

            return {
                title,
                hidden,
                defaultValue: toPrimitive(defaultValue),
                type,
                options,
                optionTitles: toStringList(values.optionTitles),
                displaySegmentedControl: toBoolean(displaySegmentedControl),
                optionIcons: toOptionIcons(values.optionIcons),
            }
        }
        case ControlType.SegmentedEnum: {
            const { defaultValue, type } = values
            const options = toStringList(values.options)
            if (!options) return null
            const optionTitles = toStringList(values.optionTitles)
            return { title, hidden, defaultValue: toString(defaultValue), type, options, optionTitles }
        }
        case ControlType.Color: {
            const { type, defaultValue } = values
            return { title, hidden, defaultValue: toString(defaultValue), type }
        }

        case ControlType.Image: {
            const { type, __defaultAssetReference } = values
            const control = { title, hidden, type }

            if (__defaultAssetReference) control[DefaultAssetReferenceKey] = toString(__defaultAssetReference)

            return control
        }
        case ControlType.File: {
            const { type } = values
            const allowedFileTypes = toStringList(values.allowedFileTypes)
            if (!allowedFileTypes) return null
            return { title, hidden, type, allowedFileTypes }
        }
        case ControlType.ComponentInstance:
            return { title, hidden, type: values.type }
        case ControlType.Array: {
            // Fallback to deprecated propertyControl if control isn't defined
            const controlValues = values.control ?? values.propertyControl
            if (!isObject(controlValues)) return null
            const control = defaultControl(controlValues)
            if (!control) return null
            switch (control.type) {
                case ControlType.Array:
                case ControlType.Object:
                case ControlType.EventHandler:
                    // Unsupported array element types
                    return null
            }
            const maxCount = toNumber(values.maxCount, 1)
            return {
                title,
                hidden,
                type: values.type,
                control,
                maxCount,
                defaultValue: toArray(values.defaultValue, control.type),
            }
        }
        case ControlType.Object: {
            if (!isObject(values.controls)) return null

            const objectControls: { [key: string]: ObjectPropertyControlDescriptionLocal } = {}

            Object.entries(values.controls).forEach(([key, value]) => {
                const controlDescription = getControlDescription(value)
                if (!controlDescription) return
                const d = defaultControl(controlDescription)
                if (!d) return
                switch (d.type) {
                    case ControlType.Array:
                    case ControlType.Object:
                    case ControlType.EventHandler:
                    case ControlType.FusedNumber:
                    case ControlType.ComponentInstance:
                        // Unsupported object property types
                        return
                }
                objectControls[key] = d
            })

            const objectControlCount = Object.keys(objectControls)
            if (objectControlCount.length === 0) return null

            return {
                title,
                hidden,
                type: values.type,
                controls: objectControls,
                defaultValue: toObjectDefault(values.defaultValue, values.controls),
            }
        }
        case ControlType.EventHandler:
            return { title, hidden, type: values.type }
        case ControlType.Transition: {
            let { defaultValue } = values

            if (isObject(defaultValue)) {
                const type = inferDefaultTransitionType(defaultValue)
                defaultValue = { type, ...defaultValue }
            }

            return { title, hidden, type: values.type, defaultValue }
        }
    }
    return null
}

function toBoolean(o: any): boolean | undefined {
    if (o === true || o === false) {
        return o
    } else {
        return undefined
    }
}

function toNumber(o: any, min?: number): number | undefined {
    if (typeof o === "number" && (!isFiniteNumber(min) || o >= min)) {
        return o
    } else {
        return undefined
    }
}

function toString(o: any): string | undefined {
    if (typeof o !== "string") return undefined
    return o
}

type Primitive = string | number | boolean | null | undefined

function toPrimitive(o: any): Primitive {
    if (o === null) return o
    if (o === undefined) return o
    if (isFiniteNumber(o)) return o
    if (typeof o === "string") return o
    if (o === true || o === false) return o
    return null
}

function toStringList(o: any): string[] | undefined {
    if (!(o instanceof Array)) return undefined
    return o.map(v => toString(v)).filter(v => v) as string[]
}

function toPrimitiveList(o: any): Primitive[] | undefined {
    if (!(o instanceof Array)) return undefined
    return o.map(toPrimitive) as Primitive[]
}

function toString2(o: any): [string, string] | undefined {
    if (!(o instanceof Array)) return undefined
    const s1 = toString(o[0])
    const s2 = toString(o[1])
    if (!s1 || !s2) return undefined
    return [s1, s2]
}

function toString4(o: unknown): [string, string, string, string] | undefined {
    if (!(o instanceof Array)) return undefined
    const s1 = toString(o[0])
    const s2 = toString(o[1])
    const s3 = toString(o[2])
    const s4 = toString(o[3])
    if (!s1 || !s2 || !s3 || !s4) return undefined
    return [s1, s2, s3, s4]
}

function toHiddenFunction<P = any>(o: unknown): ((props: P) => boolean) | undefined {
    if (typeof o !== "function") return undefined
    return o as (props: P) => boolean
}

interface StaticSwitch<T> {
    [expressionKey: string]: {
        [caseKey: string]: T
    }
}

function isStaticSwitch<T>(o: unknown, isValidResult: (r: unknown) => r is T): o is StaticSwitch<T> {
    if (!isObject(o)) return false
    const keys = Object.keys(o)
    if (keys.length !== 1) return false
    const expressionKey = keys[0]
    const expressionValue = o[expressionKey]
    if (!isObject(expressionValue)) return false
    const caseKeys = Object.keys(expressionValue)
    if (caseKeys.length === 0) return false
    for (const caseKey of caseKeys) {
        const caseResult = expressionValue[caseKey]
        if (!isValidResult(caseResult)) {
            return false
        }
    }
    return true
}

function toOptionIcons(o: unknown): ControlIcon[] | StaticSwitch<ControlIcon[]> | undefined {
    if (o instanceof Array) {
        return toStringList(o) as ControlIcon[]
    } else if (isStaticSwitch(o, isArrayContainingControlIcons)) {
        return o
    } else {
        return undefined
    }
}

function toArray(o: unknown, type: ControlType): unknown[] | undefined {
    if (!Array.isArray(o)) return undefined

    switch (type) {
        case ControlType.Boolean:
            return o.filter(item => item === true || item === false)
        case ControlType.Number:
            return o.filter(isFiniteNumber)
        case ControlType.String:
        case ControlType.Enum:
        case ControlType.SegmentedEnum:
        case ControlType.Color:
            return toStringList(o)
        default:
            return undefined
    }
}

function toObjectDefault(
    o: unknown,
    controls: ObjectControlDescription["controls"]
): { [key: string]: unknown } | undefined {
    if (!isObject(o)) return undefined

    const result: { [key: string]: unknown } = {}

    Object.entries(controls).forEach(([key, control]) => {
        if (!isObject(control)) return
        switch (control.type) {
            case ControlType.Boolean:
                result[key] = toBoolean(o[key])
                break
            case ControlType.Color:
            case ControlType.Enum:
            case ControlType.String:
                result[key] = toString(o[key])
                break
            case ControlType.Number:
                result[key] = toNumber(o[key])
                break
            case ControlType.Transition:
                result[key] = isObject(o[key]) ? o[key] : undefined
        }
    })

    return Object.keys(result).length > 0 ? result : undefined
}

function inferDefaultTransitionType(transition: Transition): "spring" | "tween" {
    const transitionKeys = new Set(Object.keys(transition))

    switch (transition.type) {
        case "spring":
        case "tween": {
            return transition.type
        }
        default: {
            if (transitionKeys.has("duration") || transitionKeys.has("ease")) {
                return "tween"
            } else {
                return "spring"
            }
        }
    }
}

function isObject<T = unknown>(value: T): value is T & { [key: string]: unknown } {
    return typeof value === "object" && value !== null && !Array.isArray(value)
}

function isArrayContainingControlIcons(value: unknown): value is ControlIcon[] {
    if (!Array.isArray(value)) return false
    return value.every(el => typeof el === "string")
}
