import type { CanvasNode } from "document/models/CanvasTree"
import { isDeviceNode } from "document/models/CanvasTree/nodes/DeviceNode"
import { VariableReference, isVariableReference } from "./VariableReference"

export interface WithRotation {
    rotation: number | VariableReference
}

export const rotationDefaults: WithRotation = {
    rotation: 0,
}

const key: keyof WithRotation = "rotation"

export function withRotation(node: CanvasNode): node is CanvasNode & WithRotation {
    return key in node && !isDeviceNode(node)
}

export function isRotated(node: CanvasNode): boolean {
    if (!withRotation(node)) return false
    if (node.cache && node.cache.matrix) {
        const angle = getRotationAngleFromMatrix(node.cache.matrix)
        return angle % 90 !== 0
    }

    const resolvedRotation = node.resolveValue("rotation") ?? 0
    return resolvedRotation % 90 !== 0
}

function getRotationAngleFromMatrix(matrix: DOMMatrixReadOnly) {
    const { a, b } = matrix
    return Math.round(Math.atan2(b, a) * (180 / Math.PI))
}

export function reduceRotation(node: CanvasNode, result: { rotation?: number | VariableReference | null }) {
    if (!withRotation(node)) {
        return
    }

    if (isVariableReference(node.rotation)) {
        if (result.rotation === undefined) {
            result.rotation = node.rotation
        } else if (!isVariableReference(result.rotation) || result.rotation.id !== node.rotation.id) {
            result.rotation = null
        }
        return
    }

    if (result.rotation === undefined) {
        result.rotation = node.rotation
    } else if (result.rotation !== null && node.rotation !== result.rotation) {
        result.rotation = null
    }
}
