import type { CSSProperties } from "react"
import type { Size } from "framer"
import type { TextProperties } from "framer"
import { StyledTextCache } from "./StyledTextCache" // XXX:DRAFT Remove
import type { WithTokenVariables } from "../CanvasTree/traits/TokenVariables"

export interface SerializedStyledTextStyles {
    css: CSSProperties
    startIndex: number
    endIndex: number
}

export interface SerializedStyledText {
    alignment: string | undefined
    blocks: { text: string; inlineStyles: SerializedStyledTextStyles[] }[]
}

export interface FontChangedListener {
    fontChanged(): void
}

export abstract class StyledText<T> implements WithTokenVariables<StyledText<T>> {
    private _text: string | undefined
    protected _styledText: T | undefined
    _cached: StyledTextCache | undefined // Only used when !experiments.isOn("rawDraftRendering")

    // XXX:DRAFT Remove `cached`
    constructor(text?: string | T, cached?: StyledTextCache) {
        if (typeof text === "string") {
            this._text = text
        } else if (text !== undefined) {
            this._styledText = text
        }
        this._cached = cached
    }

    // Public Properties

    // XXX:DRAFT Change to be `abstract`
    get text(): string {
        if (this._text === undefined) {
            if (this._cached) {
                const text = this._cached.text()
                if (text) return text
            }
            this.revive()
            this._text = this._styledText === undefined ? "" : this.styledTextToText(this._styledText)
        }
        return this._text
    }

    // XXX:DRAFT Change to be `abstract`
    get styledText(): T {
        this.revive()
        if (this._styledText === undefined) {
            this._styledText = this._text === undefined ? this.emptyStyledText() : this.textToStyledText(this._text)
        }
        return this._styledText
    }

    // XXX:DRAFT Remove
    cached(): StyledTextCache | null {
        return this._cached || null
    }

    // XXX:DRAFT Remove
    revive() {
        const cached = this._cached
        if (!cached) return
        if (!cached._rawLoadedValue) return
        this._styledText = this.reviveFromCached(cached)
        this._cached = new StyledTextCache(cached.html, cached.width, cached.size)
    }

    // WithTokenVariables

    abstract tokenVariables(): string[]

    abstract removeTokenVariables(variables: { [tokenId: string]: string }): StyledText<T> | undefined

    // Public Abstract Methods

    abstract withUpdatedText(text: string): StyledText<T>

    abstract calculateSize(maximumWidth?: number, recalculateCallback?: () => void): Size | null

    abstract scaledToHeight(height: number): this

    abstract toJS(): Record<string, any>

    abstract toHTML(): string

    abstract updateStyledText(styledText: T): StyledText<T>

    // XXX:DRAFT Remove
    abstract serialize(autoSize: boolean): SerializedStyledText

    abstract alignment(): "left" | "right" | "center" | undefined

    abstract getProps(fontChangedListener: FontChangedListener): Partial<TextProperties>

    // Protected Abstract Methods

    protected abstract emptyStyledText(): T

    protected abstract textToStyledText(text: string): T

    protected abstract styledTextToText(styledText: T): string

    // XXX:DRAFT Remove
    protected abstract reviveFromCached(cached: StyledTextCache): T
}
