import { AssetMap } from "@framerjs/assets"
import { createElement, FC } from "react"
import { initializeAssetResolver } from "../renderer/getAssetResolver"
import { AssetContext } from "./AssetContext"

export function bootstrapAssets({
    assetResolverType,
    imageBaseURL,
}: {
    assetResolverType: "web" | "studio"
    imageBaseURL: string
}) {
    const assetMap = new AssetMap()
    const assetResolver = initializeAssetResolver(assetMap, imageBaseURL, assetResolverType)
    const AssetProvider: FC = ({ children }) => createElement(AssetContext.Provider, { value: assetMap }, children)
    return { AssetProvider, assetResolver }
}
