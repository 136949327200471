import type { Transition } from "framer-motion"
import * as React from "react"
import { cx } from "./cx"
import { useForceUpdate } from "./useForceUpdate"

function createGestureVariant(variant: string, type: "hover" | "pressed") {
    return `${variant}-${type}`
}

function nextVariant(allVariants: string[], currentVariant: string): string {
    const index = allVariants.indexOf(currentVariant)
    let nextIndex = index + 1
    if (nextIndex >= allVariants.length) {
        nextIndex = 0
    }
    return allVariants[nextIndex]
}

type UnknownProps = Record<string, unknown>
/**
 * Variant / Node Id / React Prop / Val
 */
type VariantProps = Record<string, Record<string, UnknownProps>>

type GestureState = Partial<{
    isHovered: boolean
    isPressed: boolean
}>

// EnabledGestures and EnabledVariantGestures are mirrored from Framer in VariantsStore.ts
interface EnabledGestures {
    hover: boolean
    pressed: boolean
}

type EnabledVariantGestures = Record<string, EnabledGestures>
type VariantNames = string[]
interface VariantState {
    variants: VariantNames
    baseVariant: string | undefined
    gestureVariant: string | undefined
    classNames: string
    transition: Partial<Transition> | undefined
    addVariantProps: (elementId: string) => UnknownProps
    setVariant: (variant: string | typeof CycleVariantState) => void
    setGestureState: (gestureState: GestureState) => void
}

/**
 * @internal
 */
export const enum VariantSelector {
    Variant = "v",
}

function getGesture(
    enabledGestures: EnabledGestures | undefined,
    isHovered: boolean,
    isPressed: boolean
): "hover" | "pressed" | void {
    const { hover, pressed } = enabledGestures || {}
    if (pressed && isPressed) return "pressed"
    if (hover && isHovered) return "hover"
}

interface InternalState {
    isHovered: boolean
    isPressed: boolean
    baseVariant: string
    defaultVariant: string
    gestureVariant?: string
    enabledGestures?: EnabledVariantGestures
    cycleOrder?: string[]
    transitions?: Record<string, Partial<Transition>>
}

/**
 * Flag setVariantState as cycling variants.
 * @public
 */
export const CycleVariantState = Symbol("cycle")

/**
 * Handle stateful logic in Framer Canvas Components.
 *
 * @beta
 */
export function useVariantState({
    variant,
    variantProps,
    defaultVariant: externalDefaultVariant,
    transitions: externalTransitions,
    enabledGestures: externalEnabledGestures,
    cycleOrder: externalCycleOrder = [],
}: {
    defaultVariant: string
    cycleOrder: string[]
    variant?: string
    transitions?: Record<string, Partial<Transition>>
    variantProps?: VariantProps
    enabledGestures?: EnabledVariantGestures
}): VariantState {
    const forceUpdate = useForceUpdate()

    const internalState = React.useRef<InternalState>({
        isHovered: false,
        isPressed: false,
        baseVariant: variant ?? externalDefaultVariant,
        gestureVariant: undefined,

        // When used in generated components, these are static values defined
        // outside of the component function that also need to not result in
        // memoized values being recalculated, so we dump them into the ref.
        defaultVariant: externalDefaultVariant,
        enabledGestures: externalEnabledGestures,
        cycleOrder: externalCycleOrder,
        transitions: externalTransitions,
    })

    const resolveNextVariant = React.useCallback(
        (nextBaseVariant: string | undefined = internalState.current.defaultVariant) => {
            const {
                baseVariant,
                gestureVariant,
                isPressed,
                isHovered,
                defaultVariant,
                enabledGestures,
            } = internalState.current

            const gesture = getGesture(enabledGestures?.[nextBaseVariant], isHovered, isPressed)
            const nextGestureVariant = gesture ? createGestureVariant(nextBaseVariant, gesture) : undefined

            // Only force a render if the new active variants have changed.
            if (nextBaseVariant !== baseVariant || nextGestureVariant !== gestureVariant) {
                internalState.current.baseVariant = nextBaseVariant || defaultVariant
                internalState.current.gestureVariant = nextGestureVariant
                forceUpdate()
            }
        },
        [forceUpdate]
    )

    const setGestureState: VariantState["setGestureState"] = React.useCallback(
        ({ isHovered, isPressed }) => {
            if (isHovered !== undefined) internalState.current.isHovered = isHovered
            if (isPressed !== undefined) internalState.current.isPressed = isPressed

            resolveNextVariant(internalState.current.baseVariant)
        },
        [resolveNextVariant]
    )

    const setVariant = React.useCallback(
        (proposedVariant?: typeof CycleVariantState | string) => {
            const { defaultVariant, cycleOrder, baseVariant } = internalState.current
            const nextBaseVariant =
                proposedVariant === CycleVariantState
                    ? nextVariant(cycleOrder || [], baseVariant || defaultVariant)
                    : proposedVariant

            resolveNextVariant(nextBaseVariant || defaultVariant)
        },
        [resolveNextVariant]
    )

    /**
     * @TODO
     * This is not production ready. `variantProps` can include React props as
     * values. Those props can change when the variant doesn't change, and this
     * callback may not be memoized correctly to account for that. One approach
     * might be to use `useMemo` on `variantProps` in the GeneratedComponent
     * template.
     */
    const addVariantProps: VariantState["addVariantProps"] = React.useCallback(
        id => {
            if (!variantProps) return {}
            const { baseVariant, gestureVariant } = internalState.current

            if (!baseVariant) return {}

            // Create an object with all valid props for the target node. Values
            // in higher priority variants override same values in lower
            // priority variants.
            if (gestureVariant) {
                return Object.assign({}, variantProps[baseVariant]?.[id], variantProps[gestureVariant]?.[id])
            }

            return variantProps[baseVariant]?.[id]
        },

        [variantProps]
    )

    React.useEffect(() => {
        if (variant !== internalState.current.baseVariant) setVariant(variant)
    }, [variant, setVariant])

    const { baseVariant } = internalState.current
    const transition = React.useMemo(() => {
        const { transitions } = internalState.current
        if (!transitions) return undefined

        if (baseVariant) {
            const variantTransition = transitions[baseVariant]
            if (variantTransition) return variantTransition
        }

        return transitions.default
    }, [baseVariant])

    const variants = []
    const { gestureVariant, defaultVariant, enabledGestures, isHovered, isPressed } = internalState.current
    if (baseVariant && baseVariant !== defaultVariant) variants.push(baseVariant)
    if (gestureVariant) variants.push(gestureVariant)

    return {
        variants,
        baseVariant,
        gestureVariant,
        transition,
        setVariant,
        setGestureState,
        addVariantProps,
        classNames: cx(
            `framer-${VariantSelector.Variant}-${baseVariant}`,
            getGesture(enabledGestures?.[baseVariant], isHovered, isPressed)
        ),
    }
}
