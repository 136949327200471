import { SharedLayoutAnimationConfig, VisualElement, AxisBox2D, MotionValue } from "framer-motion"

/**
 * @internal
 */
export interface Config {
    lead?: VisualElement
    transition?: any
    prevViewportBox?: AxisBox2D
    current?: { [key: string]: string | number }
    shouldStackAnimate?: boolean
    onComplete?: () => void
}

/**
 * @internal
 */
export interface AutoAnimationConfig extends SharedLayoutAnimationConfig {
    current?: { [key: string]: string | number }
    target?: { [key: string]: string | number | MotionValue }
}

/**
 * Because the lead is always the target, and the follow is always the origin,
 * createAnimation can be much simpler than createCrossfadeAnimation in framer-motion.
 *
 * @internal
 */
export function createAnimation(animation: Config): SharedLayoutAnimationConfig {
    const { shouldStackAnimate, transition, current, prevViewportBox, onComplete } = animation

    const config: AutoAnimationConfig = {
        shouldStackAnimate,
        transition,
        current,
        onComplete,
    }

    if (!shouldStackAnimate) return config

    config.originBox = prevViewportBox

    return config
}
