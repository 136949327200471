import type { CanvasNode } from "document/models/CanvasTree"

export interface WithRadius {
    radius: number | VariableReference
}

export interface WithRelativeRadius extends WithRadius {
    radiusIsRelative: boolean
}

export const radiusDefaults: WithRadius = {
    radius: 0,
}

export const relativeRadiusDefaults: WithRelativeRadius = {
    ...radiusDefaults,
    radiusIsRelative: false,
}

import { stylableNode } from "./utils/stylableNode"
import { VariableReference, isVariableReference } from "./VariableReference"
const key: keyof WithRadius = "radius"

export function withRadius(node: CanvasNode): node is CanvasNode & WithRadius {
    return key in node && stylableNode(node)
}

export function withRelativeRadius(node: CanvasNode): node is CanvasNode & WithRelativeRadius {
    return "radiusIsRelative" in node && stylableNode(node)
}

export function reduceRadius(
    node: CanvasNode,
    result: {
        radius?: number | string | VariableReference | null
    }
) {
    if (!withRadius(node)) {
        return
    }
    let radius: string | number | VariableReference = node.radius

    if (isVariableReference(radius)) {
        if (result.radius === undefined) {
            result.radius = radius
        } else if (!isVariableReference(result.radius) || radius.id !== result.radius.id) {
            result.radius = null
        }
    } else if (withRelativeRadius(node) && node.radiusIsRelative) {
        radius = `${node.radius}%`
    }

    if (result.radius === undefined) {
        result.radius = radius
    } else if (result.radius !== null && result.radius !== radius) {
        result.radius = null
    }
}
