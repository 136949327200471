import { css } from "linaria"

export const container = css`
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
        display: block;
        margin: 0;
    }
`
