import { ContentBlock, ContentState, convertFromRaw, convertToRaw, Editor, EditorState } from "draft-js"
import type { RawDraftContentState } from "draft-js"
import assert from "assert"

import {
    ConvertColor,
    draftBlockRendererFunction,
    draftStyleFunction,
    draftStyleDefinitions,
    frameFromElement,
    frameFromElements,
    Size,
    TextAlignment,
    TextProperties,
    TextDecoration,
    fontStore,
    TextTransform,
    TextLineHeightUnit,
    draftContentStateToHTML,
    getDraftContent,
    getReactStylesFromDraft,
    getDraftText,
    getDraftFirstTextAlignment,
    getDraftStylesWithPrefix,
    getDraftEmptyStyles,
    getDraftRangeFromSelection,
    getDraftStylesWithPrefixCoverRange,
    getHTMLSizeCached,
} from "framer"
import { Map, OrderedSet } from "immutable"
import * as React from "react"
import * as ReactDOMServer from "react-dom/server"
import { isTokenCSSVariable } from "../CanvasTree/nodes/TokenNode"
import { findValueForTokenCSSVariable } from "../CanvasTree/utils/findValueForTokenCSSVariable"
import { SerializedStyledText, SerializedStyledTextStyles, StyledText, FontChangedListener } from "./StyledText"
import { StyledTextCache } from "./StyledTextCache"
import { unhandledError } from "@framerjs/shared"
import { patchClassDiscriminator, ClassDiscriminator } from "utils/withClassDiscriminator"
import { experiments } from "app/experiments"
import {
    getBlockEmptyStyle,
    setBlockEmptyStyle,
    ensureBlockEmptyStyles,
    getUpdatedEditorWithStyle,
    getUpdatedEditorWithToggledStyle,
    getEditorWithAdjustedStyle,
    getEditorFullSelection,
    hasStylePrefix,
    getEditorWithUpdatedText,
} from "./DraftEditor"

export type TextTransformValue = Exclude<TextTransform, undefined> | "none"
export type TextDecorationValue = Exclude<TextDecoration, undefined> | "none"

// XXX:DRAFT Remove
const noop = () => {}

// XXX:DRAFT Remove all unused functions
export class StyledTextDraft extends StyledText<EditorState> {
    readonly __class: ClassDiscriminator

    private _data: RawDraftContentState // Only used when experiments.isOn("rawDraftRendering")

    private _alignment: string | undefined
    private _sizeCache: Map<number, Size>
    private _blockSizeCache: Map<string, Map<number, Size>>
    private _measureElement: HTMLElement
    private _toJS: unknown | undefined
    private _fontSelectors: string[] | undefined // Cache for getProps

    constructor(text?: string | EditorState | ContentState, rawLoadedValue?: object) {
        if (experiments.isOn("rawDraftRendering")) {
            super()

            if (text instanceof EditorState) {
                // The editor state gets passed when we are editing the text and make an update
                this._styledText = text
                this._data = StyledTextDraft.getDraftContentData(this._styledText.getCurrentContent())
            } else if (text instanceof ContentState) {
                this._data = StyledTextDraft.getDraftContentData(
                    EditorState.createWithContent(text).getCurrentContent()
                )
            } else {
                this._data = getDraftContent(text, StyledTextDraft.defaultStyleOverride.toArray())
            }
        } else {
            let cache = null

            if (rawLoadedValue) {
                cache = StyledTextCache.fromRawValue(rawLoadedValue)
                if (cache) {
                    super(undefined, cache)
                } else {
                    let contentState = convertFromRaw(rawLoadedValue as any)
                    contentState = StyledTextDraft.convertInternalStateFromRaw(contentState)
                    super(EditorState.createWithContent(contentState))
                }
            } else if (typeof text === "string" || text instanceof EditorState || text === undefined) {
                super(text)
            } else if (text instanceof ContentState) {
                super(EditorState.createWithContent(text))
            } else {
                throw Error("state error")
            }

            this.blockRendererFn = this.blockRendererFn.bind(this)
            this._sizeCache = Map<number, Size>()
            this._blockSizeCache = Map<string, Map<number, Size>>()
            if (cache) {
                this._sizeCache = this._sizeCache.set(cache.width, cache.size)
            }
        }

        this.__class = ClassDiscriminator.StyledTextDraft
    }

    reviveFromCached(cached: StyledTextCache): EditorState {
        assert(!experiments.isOn("rawDraftRendering"))
        if (!cached._rawLoadedValue) throw Error("invalid use of lazy loading StyledTextCache")
        let contentState = convertFromRaw(cached._rawLoadedValue)
        contentState = StyledTextDraft.convertInternalStateFromRaw(contentState)
        return EditorState.createWithContent(contentState)
    }

    // Convenience constructor
    static createEmptyWithStyle(style: OrderedSet<string>) {
        if (experiments.isOn("rawDraftRendering")) {
            return StyledTextDraft.createFromData(getDraftContent("", style.toArray()))
        } else {
            return new StyledTextDraft(
                EditorState.setInlineStyleOverride(
                    EditorState.createWithContent(ensureBlockEmptyStyles(ContentState.createFromText(""), style)),
                    style
                )
            )
        }
    }

    // Convenience constructor
    static createFromData(data: RawDraftContentState) {
        if (experiments.isOn("rawDraftRendering")) {
            const styledDraft = new StyledTextDraft()
            styledDraft.setData(data)
            return styledDraft
        }
        return new StyledTextDraft(undefined, data)
    }

    private static getDraftEditorState(data: RawDraftContentState) {
        let editorState = EditorState.createWithContent(this.convertInternalStateFromRaw(convertFromRaw(data)))
        const currentBlock = editorState.getCurrentContent().getFirstBlock()
        if (currentBlock.getLength() === 0) {
            editorState = EditorState.setInlineStyleOverride(editorState, getBlockEmptyStyle(currentBlock))
        }
        return editorState
    }

    private static getDraftContentData(content: ContentState): RawDraftContentState {
        const data = convertToRaw(content)
        data.blocks.forEach(block => {
            for (const key in block.data) {
                const value = block.data[key]
                // This is needed to convert items in the `block.data` property (like emptyStyle)
                if (typeof value.toJS === "function") {
                    block.data[key] = value.toJS()
                }
            }
        })
        return data
    }

    get text() {
        if (experiments.isOn("rawDraftRendering")) {
            return getDraftText(this._data)
        } else {
            return super.text
        }
    }

    /** Returns an editor, creating one if there isn't one yet. This method should only be called when actually editing starts to happen. */
    get styledText() {
        if (experiments.isOn("rawDraftRendering")) {
            if (!this._styledText) {
                this._styledText = StyledTextDraft.getDraftEditorState(this._data)
            }
            return this._styledText
        } else {
            return super.styledText
        }
    }

    setData(data: RawDraftContentState) {
        assert(experiments.isOn("rawDraftRendering"))
        this._data = {
            blocks: data.blocks.map(block => ({
                text: block.text,
                type: block.type,
                key: block.key,
                inlineStyleRanges: block.inlineStyleRanges,
                depth: block.depth,
                data: block.data,
                entityRanges: block.entityRanges,
            })),
            entityMap: data.entityMap,
        }
    }

    stylesForHandoff() {
        if (experiments.isOn("rawDraftRendering")) {
            const styles = this.text.length
                ? getReactStylesFromDraft(getDraftStylesWithPrefix(this._data))
                : getReactStylesFromDraft(getDraftEmptyStyles(this._data))

            styles.tabSize = 4 // NOTE: This matches the value set in `draftContentStateToHTMLConverter`
            styles.textAlign = styles.textAlign || this.alignment()

            // Remove the userSelect: "none" property.
            delete styles.userSelect

            return styles
        } else {
            const styles = this.styledText.getCurrentContent().getFirstBlock().getInlineStyleAt(0)
            const textStyle = draftStyleFunction(styles)

            // Remove the userSelect: "none" property.
            delete textStyle.userSelect

            const textAlign = this.alignment()
            if (textAlign) {
                textStyle.textAlign = textAlign
            }
            return textStyle
        }
    }

    withUpdatedText(text: string) {
        return new StyledTextDraft(getEditorWithUpdatedText(this.styledText, text))
    }

    protected styledTextToText(text: EditorState) {
        return text.getCurrentContent().getPlainText()
    }

    toJS() {
        if (experiments.isOn("rawDraftRendering")) {
            return { __class: this.__class, ...this._data }
        } else {
            // Cache the quite heave toJS operation, it is called a lot more now, because diffing uses it too.
            if (this._toJS) return this._toJS

            const cached = this.cached()
            if (cached && cached._rawLoadedValue) {
                return cached._rawLoadedValue
            }

            const JS = convertToRaw(this.styledText.getCurrentContent())
            JS["__class"] = this.__class
            if (cached) {
                JS["cached"] = cached.toJS()
            }

            JS.blocks.forEach(block => {
                for (const key in block.data) {
                    const value = block.data[key]
                    if (typeof value.toJS === "function") {
                        block.data[key] = value.toJS()
                    }
                }
            })

            this._toJS = JS
            return JS
        }
    }

    toJSON() {
        return this.toJS()
    }

    toHTML() {
        if (experiments.isOn("rawDraftRendering")) {
            return draftContentStateToHTML(this._data) || ""
        } else {
            const alignment = this.alignment()
            const editorElement = React.createElement(
                Editor,
                {
                    editorState: this.styledText,
                    onChange: noop,
                    customStyleFn: draftStyleFunction,
                    textAlignment: alignment,
                    readOnly: true,
                    blockRendererFn: this.blockRendererFn,
                },
                null
            )
            return ReactDOMServer.renderToStaticMarkup(editorElement)
        }
    }

    // WithTokenVariables

    tokenVariables(): string[] {
        return this.colors(false).filter(isTokenCSSVariable)
    }

    removeTokenVariables(variables: { [tokenId: string]: string }): StyledText<EditorState> | undefined {
        const prevContent = this.styledText.getCurrentContent()

        // NOTE: callback passed to adjustStyle must always return a value
        // otherwise the style will be removed.
        const updated = this.adjustStyle(draftStyleDefinitions.color.prefix, current => {
            return findValueForTokenCSSVariable(current, variables) || current
        })

        // Return updated only if changed.
        return prevContent === updated.styledText.getCurrentContent() ? undefined : updated
    }

    // Same as toHTML(), but for only the blocks we want to use for size calculation
    private uncachedBlocksHTML(width: number) {
        const uncachedBlocks: ContentBlock[] = []
        this.styledText
            .getCurrentContent()
            .getBlockMap()
            .forEach((contentBlock: ContentBlock, blockId: string) => {
                if (!this._blockSizeCache.has(blockId)) {
                    uncachedBlocks.push(contentBlock)
                } else if (!this._blockSizeCache.get(blockId).get(width)) {
                    uncachedBlocks.push(contentBlock)
                }
            })

        if (uncachedBlocks.length === 0) {
            return null
        }

        const editorState = EditorState.createWithContent(ContentState.createFromBlockArray(uncachedBlocks))

        const editorElement = React.createElement(
            Editor,
            {
                editorState: editorState,
                onChange: noop,
                customStyleFn: draftStyleFunction,
                textAlignment: "left",
                readOnly: true,
                blockRendererFn: this.blockRendererFn,
            },
            null
        )

        return ReactDOMServer.renderToStaticMarkup(editorElement)
    }

    private blockRendererFn(block: ContentBlock) {
        return draftBlockRendererFunction({ editable: false, alignment: this.alignment() })(block)
    }

    updateStyledText(styledText: EditorState): StyledTextDraft {
        if (experiments.isOn("rawDraftRendering")) {
            return new StyledTextDraft(styledText)
        } else {
            const update = new StyledTextDraft(styledText)
            update._measureElement = this._measureElement

            const oldContent = this.styledText.getCurrentContent()
            const newContent = styledText.getCurrentContent()
            if (newContent === oldContent) {
                update._sizeCache = this._sizeCache
                update._blockSizeCache = this._blockSizeCache
                update._alignment = this._alignment
                update._cached = this._cached
            } else {
                const newBlocks = newContent.getBlocksAsArray()
                let blockSizeCache = this._blockSizeCache
                oldContent.getBlockMap().forEach((contentBlock: ContentBlock, blockId: string) => {
                    if (!newBlocks.includes(contentBlock)) {
                        blockSizeCache = blockSizeCache.delete(blockId)
                    }
                })
                update._blockSizeCache = blockSizeCache
            }
            return update
        }
    }

    calculateSize(width: number = 0, recalculateCallback?: () => void) {
        if (experiments.isOn("rawDraftRendering")) {
            // If there was no text set, we return a size based on the empty styles
            if (!this.text.length) {
                const content = getDraftContent("a", this.styles(true))
                const size = getHTMLSizeCached(draftContentStateToHTML(content))
                return { width: 0, height: size.height }
            }
            const fonts = this.fonts(false)
            const fontsReady = fonts.every(s => fontStore.isSelectorLoaded(s))
            if (fontsReady) {
                return getHTMLSizeCached(this.toHTML(), width)
            } else {
                // Try loading missing fonts.
                this.loadMissingFonts(
                    fonts,
                    recalculateCallback ? { fontChanged: recalculateCallback } : undefined
                ).catch(unhandledError)

                return null
            }
        } else {
            const cachedSize = this._sizeCache.get(width)
            if (cachedSize !== undefined) {
                return cachedSize
            }
            // Measure all new blocks, handling any potential errors.
            const result = this.updateUncachedBlockSizes(width)

            // If unable to compute the cached value we just return a temp value
            // of zero and wait for a re-render.
            if (result === "invalid") {
                return Size(0, 0)
            }

            // Now the block size cache should be filled completely and we can use it to calculate the size
            const size = Size(width, 0)

            const blockMap = this.styledText.getCurrentContent().getBlockMap()
            blockMap.keySeq().forEach((blockId: string) => {
                const blockCache = this._blockSizeCache.get(blockId)
                if (blockCache === undefined) {
                    throw Error(`Did not find cache for block ${blockId}`)
                } // else
                const blockSize = blockCache.get(width)
                if (blockSize === undefined) {
                    throw Error(`Did not find size for block ${blockId}`)
                } // else

                size.height += blockSize.height
                size.width = Math.max(blockSize.width, size.width)
            })

            // Round up to make sure it fits while snapping to the pixel
            size.height = Math.ceil(size.height)
            // Add 1px because of a hack DraftJS uses to make sure the cursor displays (see the CSS file)
            size.width = Math.ceil(size.width + 1)

            // Cache it
            this._sizeCache = this._sizeCache.set(width, size)

            // if rendered and there is one block, and two spans (<span><span>text</span></span>), then this is a simple text element that we can cache
            if (result === "updated" && blockMap.size === 1) {
                const spans = this._measureElement.querySelectorAll("span")
                if (spans.length === 2 && spans[0].parentElement) {
                    let html = spans[0].parentElement!.outerHTML
                    const textAlignment = this.alignment()
                    if (textAlignment) {
                        // Wrap it with a div to support Draft’s alignment
                        html = `<div class='DraftEditor-align${textAlignment[0].toUpperCase()}${textAlignment.substring(
                            1
                        )}'>${html}</div>`
                    }
                    this._cached = new StyledTextCache(html, width, size)
                }
            }

            return size
        }
    }

    /**
     * Updates the _blockSizeCache and _measureElement properties with the
     * latest values. Returns "updated" if the cache has changed, "unchanged"
     * if the blocks are all up-to-date and "invalid" if the calculation
     * was unable to be performed correctly.
     */
    private updateUncachedBlockSizes(width: number): "updated" | "unchanged" | "invalid" {
        const html = this.uncachedBlocksHTML(width)

        // Nothing to update.
        if (html === null) {
            return "unchanged"
        }

        if (!this._measureElement) {
            this._measureElement = document.createElement("div")
        }

        try {
            // "auto" might seem more correct, but that will restrict the width of the window
            // so we make it a huge ‘magic’ value
            this._measureElement.style.width = width === 0 ? "10000px" : `${width}px`
            this._measureElement.innerHTML = html
            document.body.appendChild(this._measureElement)

            const blockElements = [].slice.call(
                this._measureElement.querySelectorAll('[data-block="true"]')
            ) as HTMLElement[]

            for (const blockElement of blockElements) {
                // We need two different elements to calculate the size

                // 1. Calculate the height using the blocks
                const blockHeight = frameFromElement(blockElement).height
                if (blockHeight === 0 && blockElement.offsetParent === null) {
                    // If iframe is hidden in the parent document then the
                    // calculation will be invalid and both width & height will
                    // be zero. In this instance we discard the cached value.
                    this._blockSizeCache.delete(this.blockId(blockElement)!)
                    return "invalid"
                }

                // 2. Calculate the width using the spans
                const textSpans = [].slice.call(blockElement.querySelectorAll('[data-text="true"]')) as HTMLElement[]
                if (textSpans.length === 0) {
                    throw Error("Found block without expected text span")
                } // else
                const textWidth = frameFromElements(textSpans).width

                const blockSize = Size(textWidth, blockHeight)
                const blockId = this.blockId(blockElement)
                if (blockId !== undefined) {
                    let blockCache = this._blockSizeCache.get(blockId, Map<number, Size>())
                    blockCache = blockCache.set(width, blockSize)
                    this._blockSizeCache = this._blockSizeCache.set(blockId, blockCache)
                }
            }
        } finally {
            document.body.removeChild(this._measureElement)
        }

        return "updated"
    }

    scaledToHeight(height: number) {
        const currentSize = this.calculateSize()
        if (currentSize === null) {
            assert(experiments.isOn("rawDraftRendering"))
            return this
        }
        const scale = height / currentSize.height

        return this.adjustStyle(draftStyleDefinitions.size.prefix, currentValue => {
            // Scale up font size, as naive as humanly possible
            const textSize = currentValue ? Number(currentValue) : draftStyleDefinitions.size.default
            const newTextSize = Math.round(textSize * scale)
            return `${newTextSize}`
        })
    }

    updateStyle(prefix: string, style: string | undefined, currentSelection: boolean) {
        return new StyledTextDraft(
            getUpdatedEditorWithStyle(this.styledText, this.styles(currentSelection), prefix, style, currentSelection)
        )
    }

    toggleStyle(style: string, currentSelection: boolean) {
        return new StyledTextDraft(
            getUpdatedEditorWithToggledStyle(this.styledText, this.styles(currentSelection), style, currentSelection)
        )
    }

    adjustStyle(prefix: string, updateValue: (current?: string) => string | undefined, onlySelection: boolean = false) {
        return new StyledTextDraft(
            getEditorWithAdjustedStyle(this.styledText, this.styles(onlySelection), prefix, updateValue, onlySelection)
        ) as this
    }

    protected emptyStyledText() {
        return this.textToStyledText("")
    }

    protected textToStyledText(text: string) {
        return EditorState.setInlineStyleOverride(
            EditorState.createWithContent(ContentState.createFromText(text)),
            StyledTextDraft.defaultStyleOverride
        )
    }

    static defaultStyleOverride = OrderedSet([
        draftStyleDefinitions.font.prefix + draftStyleDefinitions.font.default,
        draftStyleDefinitions.color.prefix + draftStyleDefinitions.color.default,
        draftStyleDefinitions.size.prefix + draftStyleDefinitions.size.default,
        draftStyleDefinitions.letterSpacing.prefix + draftStyleDefinitions.letterSpacing.default,
        draftStyleDefinitions.lineHeight.prefix + draftStyleDefinitions.lineHeight.default,
    ])

    fonts(currentSelection: boolean) {
        if (this._cached) {
            assert(!experiments.isOn("rawDraftRendering"))
            const fonts = this._cached.fonts()
            if (fonts) return fonts
        }
        return this.stylesPrefixedWith(draftStyleDefinitions.font.prefix, currentSelection)
    }

    colors(currentSelection: boolean) {
        return this.stylesPrefixedWith(draftStyleDefinitions.color.prefix, currentSelection)
    }

    sizes(currentSelection: boolean) {
        return this.stylesPrefixedWith(draftStyleDefinitions.size.prefix, currentSelection)
    }

    letterSpacings(currentSelection: boolean) {
        return this.stylesPrefixedWith(draftStyleDefinitions.letterSpacing.prefix, currentSelection)
    }

    lineHeight(): string {
        // Because line height applies to the whole selection, we can simply take it from any selection
        const lineHeight = this.stylesPrefixedWith(draftStyleDefinitions.lineHeight.prefix, true)[0] || ""

        // Some documents might be saved with a NaN value for line height.
        // If those are found, we are making sure they fall back to their default value in the properties panel.
        if (Number.isNaN(parseFloat(lineHeight))) return `${draftStyleDefinitions.lineHeight.default}`

        return lineHeight
    }

    lineHeightAndUnit(): { value: number; unit: TextLineHeightUnit } {
        // The default is a number, but due to Draft, since lineHeight can be a number or string, needs type casting.
        const defaultValue = draftStyleDefinitions.lineHeight.default as number
        const defaults: { value: number; unit: TextLineHeightUnit } = {
            value: defaultValue,
            unit: TextLineHeightUnit.Em,
        }

        const lineHeight = this.lineHeight()

        const match = /([\d.]+)(px|em|%)?/g.exec(lineHeight)

        // Won't match NaN.
        if (!match) return defaults

        let value = Number(parseFloat(match[1]).toFixed(4))
        const unit = match[2] || TextLineHeightUnit.Em

        if (Number.isNaN(value)) value = defaultValue

        return { value, unit: unit as TextLineHeightUnit }
    }

    alignment(): TextAlignment {
        if (experiments.isOn("rawDraftRendering")) {
            const alignment = getDraftFirstTextAlignment(this._data) || ""
            return ["left", "right", "center"].includes(alignment) ? (alignment as TextAlignment) : undefined
        } else {
            // Because alignment applies to the whole selection, we can simply take it from any selection
            // this is quite a heavy operation, so we cache the result
            let alignment = this._alignment
            if (!alignment) {
                alignment = this.findFirstAlignment()
                if (!alignment) {
                    alignment = "undefined" // need an explicit marker for undefined
                }
                this._alignment = alignment
            }
            return this.stringToTextAlignment(alignment)
        }
    }

    // Optimized function to find alignment without processing all content blocks
    private findFirstAlignment(): TextAlignment {
        // Empty blocks don’t have alignment, so we find the first block with content first
        const blockWithContent = this.styledText
            .getCurrentContent()
            .getBlockMap()
            .find((block: ContentBlock) => block.getLength() > 0)
        if (!blockWithContent) return

        const aligmentStyle = blockWithContent
            .getInlineStyleAt(0)
            .find((style: string) => style.startsWith(draftStyleDefinitions.align.prefix))
        if (!aligmentStyle) return

        return this.stringToTextAlignment(aligmentStyle.slice(draftStyleDefinitions.align.prefix.length))
    }

    private stringToTextAlignment(alignment: string): TextAlignment {
        if (alignment !== "left" && alignment !== "right" && alignment !== "center") {
            return undefined
        } else {
            return alignment
        }
    }

    textTransform(currentSelection: boolean): TextTransformValue[] {
        const result = this.stylesPrefixedWith(
            draftStyleDefinitions.textTransform.prefix,
            currentSelection
        ) as TextTransformValue[]
        return result.length ? result : ["none"]
    }

    textDecoration(currentSelection: boolean): TextDecorationValue[] {
        const result = this.stylesPrefixedWith(
            draftStyleDefinitions.textDecoration.prefix,
            currentSelection
        ) as TextDecorationValue[]
        return result.length ? result : ["none"]
    }

    serialize(): SerializedStyledText {
        assert(!experiments.isOn("rawDraftRendering"))
        if (this._cached) {
            const serialize = this._cached.serialize()
            if (serialize) return serialize
        }

        const content = this.styledText.getCurrentContent()
        const blocks: any[] = []
        content.getBlocksAsArray().forEach(contentBlock => {
            const block: { text: string; inlineStyles: SerializedStyledTextStyles[] } = {
                text: contentBlock.getText(),
                inlineStyles: [],
            }
            let nextStyle: OrderedSet<string>
            // This is a weird API, but we’ll make due
            contentBlock.findStyleRanges(
                characterMetaData => {
                    nextStyle = characterMetaData.getStyle()
                    return true
                },
                (startIndex: number, endIndex: number) => {
                    const css = draftStyleFunction(nextStyle, undefined, false)
                    block.inlineStyles.push({
                        startIndex: startIndex,
                        endIndex: endIndex,
                        css: css,
                    })
                }
            )
            if (block.inlineStyles.length === 0) {
                const style = getBlockEmptyStyle(contentBlock)
                const css = draftStyleFunction(style, undefined, false)
                block.inlineStyles.push({
                    startIndex: 0,
                    endIndex: 0,
                    css: css,
                })
            }
            blocks.push(block)
        })
        return { blocks: blocks, alignment: this.alignment() }
    }

    static setFont = (styledText: StyledTextDraft, fontName: string, currentSelection: boolean): StyledTextDraft => {
        return styledText.updateStyle(draftStyleDefinitions.font.prefix, fontName, currentSelection)
    }

    static setColor = (styledText: StyledTextDraft, color: string, currentSelection: boolean): StyledTextDraft => {
        return styledText.updateStyle(draftStyleDefinitions.color.prefix, color, currentSelection)
    }

    static setColorAlpha = (styledText: StyledTextDraft, alpha: number, currentSelection: boolean): StyledTextDraft => {
        return styledText.adjustStyle(
            draftStyleDefinitions.color.prefix,
            color => {
                return ConvertColor.setAlpha(color ? color : draftStyleDefinitions.color.default, alpha / 100)
            },
            currentSelection
        )
    }

    static setSize = (styledText: StyledTextDraft, size: number, currentSelection: boolean): StyledTextDraft => {
        return styledText.updateStyle(draftStyleDefinitions.size.prefix, `${size}`, currentSelection)
    }

    static setLetterSpacing = (
        styledText: StyledTextDraft,
        letterSpacing: number,
        currentSelection: boolean
    ): StyledTextDraft => {
        return styledText.updateStyle(draftStyleDefinitions.letterSpacing.prefix, `${letterSpacing}`, currentSelection)
    }

    static setLineHeight = (styledText: StyledTextDraft, lineHeight: string): StyledTextDraft => {
        return styledText.updateStyle(draftStyleDefinitions.lineHeight.prefix, lineHeight, false)
    }

    static setLineHeightValue = (styledText: StyledTextDraft, value: number): StyledTextDraft => {
        const { unit } = styledText.lineHeightAndUnit()
        const lineHeight = `${value}${unit === TextLineHeightUnit.Em ? "" : unit}`
        return styledText.updateStyle(draftStyleDefinitions.lineHeight.prefix, lineHeight, false)
    }

    static setLineHeightUnit = (styledText: StyledTextDraft, unit: TextLineHeightUnit): StyledTextDraft => {
        const { value } = styledText.lineHeightAndUnit()
        const lineHeight = `${value}${unit === TextLineHeightUnit.Em ? "" : unit}`
        return styledText.updateStyle(draftStyleDefinitions.lineHeight.prefix, lineHeight, false)
    }

    static setAlignment = (styledText: StyledTextDraft, alignment: "left" | "center" | "right"): StyledTextDraft => {
        return styledText.updateStyle(draftStyleDefinitions.align.prefix, alignment, false)
    }

    static setTextTransform = (
        styledText: StyledTextDraft,
        transform: TextTransform,
        currentSelection: boolean
    ): StyledTextDraft => {
        return styledText.updateStyle(draftStyleDefinitions.textTransform.prefix, transform, currentSelection)
    }

    static toggleBold = (styledText: StyledTextDraft, currentSelection: boolean = false): StyledTextDraft => {
        return styledText.toggleStyle("BOLD", currentSelection)
    }

    static toggleItalic = (styledText: StyledTextDraft, currentSelection: boolean = false): StyledTextDraft => {
        return styledText.toggleStyle("ITALIC", currentSelection)
    }

    // used by keyboard shortcut only
    static toggleUnderline = (styledText: StyledTextDraft, currentSelection: boolean = false): StyledTextDraft => {
        return styledText.adjustStyle(
            draftStyleDefinitions.textDecoration.prefix,
            textDecoration => {
                // Clear text decoration if it's already underline
                if (textDecoration === "underline") return undefined
                return "underline"
            },
            currentSelection
        )
    }

    static setTextDecoration = (
        styledText: StyledTextDraft,
        textDecoration: TextDecoration,
        currentSelection: boolean = false
    ): StyledTextDraft => {
        return styledText.updateStyle(draftStyleDefinitions.textDecoration.prefix, textDecoration, currentSelection)
    }

    private styles(currentSelection: boolean) {
        if (experiments.isOn("rawDraftRendering")) {
            if (!this.text.length) {
                return getDraftEmptyStyles(this._data)
            }

            // Todo: if we are currently editing, this function gets called
            // multiple time on the same editor instance, re-calculating the
            // data from the editot state multiple times. This can be a heavy
            // operation if there is a lot of text. This currently happens
            // inside the property panel reducer for text while you are typing.
            // We can maybe cache this per engine frame in the future to make
            // this a bit cheaper.
            const selection = currentSelection ? this.styledText.getSelection() : null

            // Get the styles from the editor
            if (selection && selection.isCollapsed()) {
                return this.styledText.getCurrentInlineStyle().toArray()
            }

            const content = currentSelection ? convertToRaw(this.styledText.getCurrentContent()) : this._data

            // Either get the current selection range or use the full text range.
            const range: [number, number] = selection
                ? (getDraftRangeFromSelection(content, selection) as any)
                : [0, getDraftText(content).length]
            const styles = getDraftStylesWithPrefix(content, undefined, range)

            // For text decoration and transform, we see if there were any styles defined and if not we add "none".
            for (const prefix of [
                draftStyleDefinitions.textTransform.prefix,
                draftStyleDefinitions.textDecoration.prefix,
            ]) {
                if (!getDraftStylesWithPrefixCoverRange(content, [prefix as any], range)) {
                    styles.push(`${prefix}none`)
                }
            }

            return styles
        } else {
            let styles = OrderedSet<string>()

            const editorState = this.styledText
            const content = editorState.getCurrentContent()
            const selection = currentSelection ? editorState.getSelection() : getEditorFullSelection(this.styledText)

            if (selection.isCollapsed()) {
                let inlineStyles = editorState.getCurrentInlineStyle()
                // If no text transform/decoration set at the caret position, we translate the undefined value to "none"
                if (!inlineStyles.some(value => !!value?.startsWith(draftStyleDefinitions.textTransform.prefix))) {
                    inlineStyles = inlineStyles.add(`${draftStyleDefinitions.textTransform.prefix}none`)
                }
                if (!inlineStyles.some(value => !!value?.startsWith(draftStyleDefinitions.textDecoration.prefix))) {
                    inlineStyles = inlineStyles.add(`${draftStyleDefinitions.textDecoration.prefix}none`)
                }
                return inlineStyles.toArray()
            } // else

            const endKey = selection.getEndKey()
            const endOffset = selection.getEndOffset()

            const startKey = selection.getStartKey()
            const startOffset = selection.getStartOffset()

            let block = content.getBlockForKey(startKey)

            let rangesCount = 0
            let rangesWithTransform = 0
            let rangesWithDecoration = 0

            for (;;) {
                let nextStyle: OrderedSet<string>
                // This is a weird API, but we’ll make due
                block.findStyleRanges(
                    characterMetaData => {
                        nextStyle = characterMetaData.getStyle()
                        return true
                    },
                    (startIndex: number, endIndex: number) => {
                        if (nextStyle === undefined) {
                            return
                        }

                        if (block.getKey() === startKey && endIndex <= startOffset) {
                            return
                        }
                        if (block.getKey() === endKey && startIndex >= endOffset) {
                            return
                        }

                        rangesCount += 1
                        if (hasStylePrefix(nextStyle, draftStyleDefinitions.textTransform.prefix)) {
                            rangesWithTransform += 1
                        }

                        if (hasStylePrefix(nextStyle, draftStyleDefinitions.textDecoration.prefix)) {
                            rangesWithDecoration += 1
                        }

                        styles = styles.merge(nextStyle)
                    }
                )

                if (block.getKey() === endKey) {
                    break
                } // else
                block = content.getBlockAfter(block.getKey())
            }

            // For inline style like text-transform, we don't set the style if the value is "none",
            // because this helps save bytes in the file format and data transfer.
            // But we also need to check for the lack of style to identify multiple inline styles (e.g. mixed none + uppercase in one block) when rendering the text panel.
            // So when collecting the styles from the draft, we check if there's a range in the selection that's not covered by any meaningful text-transform style,
            // if there is, we manually insert the none value here. It should only be consumed by the text panel,
            // when updating the styledText we translate "none" to undefined (see onChangeTextTransform in TextPanel.tsx).
            const hasInlineTextTransformNone = rangesWithTransform < rangesCount
            if (hasInlineTextTransformNone) {
                styles = styles.add(`${draftStyleDefinitions.textTransform.prefix}none`)
            }

            const hasInlineTextDecorationNone = rangesWithDecoration < rangesCount
            if (hasInlineTextDecorationNone) {
                styles = styles.add(`${draftStyleDefinitions.textDecoration.prefix}none`)
            }

            return styles.toArray()
        }
    }

    private stylesPrefixedWith(prefix: string, currentSelection: boolean): string[] {
        return this.styles(currentSelection)
            .filter(style => (style as string).startsWith(prefix))
            .map(style => (style as string).slice(prefix.length))
    }

    private blockId(element: HTMLElement): string | undefined {
        const offsetKey = element.getAttribute("data-offset-key")
        if (offsetKey === null) {
            return undefined
        }

        const blockId = offsetKey.split("-")[0]
        if (blockId.length === 0) {
            return undefined
        }

        return blockId
    }

    static convertInternalStateFromRaw(contentState: ContentState): ContentState {
        const updatedBlocks = contentState.getBlockMap().map((block: ContentBlock) => {
            const rawEmptyStyle = getBlockEmptyStyle(block)
            return setBlockEmptyStyle(block, OrderedSet<string>(rawEmptyStyle))
        })
        return contentState.merge({ blockMap: updatedBlocks }) as ContentState
    }

    /**
     * Loads missing fonts (by selectors) and clears the size cache
     * This will force the node to re-measure the fonts using the newly loaded font
     */
    async loadMissingFonts(fontSelectors: string[], listener?: FontChangedListener) {
        const selectors = fontSelectors.filter(s => !fontStore.isSelectorLoaded(s))
        if (!selectors.length) {
            return
        }

        await fontStore.loadWebFontsFromSelectors(selectors)

        // Once all fonts are loaded, clear the caches and let the listener know
        if (selectors.every(s => fontStore.isSelectorLoaded(s))) {
            if (!experiments.isOn("rawDraftRendering")) {
                this._sizeCache = this._sizeCache.clear()
                this._blockSizeCache = this._blockSizeCache.clear()
            }

            listener && listener.fontChanged()
        }
    }

    /**
     * @param changedCallback a callback to invoke if there are changes that could affect this node's dimensions.
     * This happens when missing fonts being loaded, and hosts might want to re-calculate the alignment/position according to the new node size
     */
    public getProps(listener?: FontChangedListener): Partial<TextProperties> {
        if (experiments.isOn("rawDraftRendering")) {
            if (this._fontSelectors === undefined) {
                this._fontSelectors = this.fonts(false)
                this.loadMissingFonts(this._fontSelectors, listener).catch(unhandledError)
            }

            return {
                alignment: this.alignment(),
                rawHTML: this.toHTML(),
                fonts: this._fontSelectors,
            }
        } else {
            const cached = this._cached
            const fontSelectors = this.fonts(false)
            this.loadMissingFonts(fontSelectors, listener).catch(unhandledError)

            if (cached) {
                const rawHTML = cached.html
                // if (cached._rawLoadedValue) {
                //     rawHTML = `<div style="background-color:red">${rawHTML}</div>`
                // }
                const alignment = cached.alignment(this)
                return { rawHTML, alignment, fonts: fontSelectors }
            } // else

            return {
                alignment: this.alignment(),
                contentState: this.styledText.getCurrentContent(),
                fonts: fontSelectors,
            }
        }
    }
}

patchClassDiscriminator(StyledTextDraft, ClassDiscriminator.StyledTextDraft)

export const draftBlockRenderMap = Map<any, any>({ unstyled: { element: "div", aliasedElements: ["p"] } })
