import type { V55, V56 } from "../types"
import { transform } from "../utils/transform"
import { _, exactCheck } from "../utils/exactCheck"

export function migration_55_56(documentJSON: V55.Tree): V56.Tree {
    const migratedRoot = transform(documentJSON.root, node => {
        if (node.__class === "RootNode") {
            const migratedTreeNode = {
                homeNodeId: null,
                ...node,
            }
            return exactCheck(migratedTreeNode, _ as V56.TreeNode)
        }
        return exactCheck(node, _ as V56.TreeNode)
    })
    return { version: 56, root: migratedRoot }
}
