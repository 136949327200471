import { V57, V58 } from "../types"
import { transform } from "../utils/transform"
import { _, exactCheck } from "../utils/exactCheck"

export function migration_57_58(documentJSON: V57.Tree): V58.Tree {
    const migratedRoot = transform(documentJSON.root, node => {
        if (node.__class !== "TextNode") return exactCheck(node, _ as V58.TreeNode)

        return exactCheck(
            {
                clip: true,
                ...node,
            },
            _ as V58.TextNode
        )
    })
    return { version: 58, root: migratedRoot }
}
