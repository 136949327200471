import { isTokenCSSVariable, tokenIdFromCSSVariable } from "../nodes/TokenNode"

// TODO: move to ./tokens file
// This function should be moved to the ./tokens file, but that currently results in a circular dependency
export function findValueForTokenCSSVariable(value: unknown, map: { [tokenId: string]: string }): string | undefined {
    if (!isTokenCSSVariable(value)) return
    const tokenId = tokenIdFromCSSVariable(value)
    if (!tokenId) return
    return map[tokenId]
}
