import type { List } from "immutable"
import { CanvasNode } from "../CanvasNode"
import type { NodeUpdate } from "../.."
import { getPathRecord } from "../../records/shapes/PathRecord"
import { Rect, Point, Size } from "framer"
import { isStraightCurve, PathSegment, WithPaths, WithPath, WithShape, ConstraintValues } from "framer"
import Path from "document/models/Path"
import modulate from "utils/modulate"
import type { WithFramePreset } from "document/models/CanvasTree/traits/FramePreset"
import { withFrameForShape } from "document/models/CanvasTree/traits/mixins/withFrameForShape"
import { withBaseShapeTraits } from "document/models/CanvasTree/traits/mixins/withBaseShapeTraits"
import { convertPoint } from "document/models/CanvasTree/utils/shapes"

import { setDefaults } from "../MutableNode"
import { withClassDiscriminator } from "utils/withClassDiscriminator"

export function isPathNode(node: CanvasNode): node is PathNode {
    return node instanceof PathNode
}

// eslint-disable-next-line import/no-default-export
export default class PathNode
    extends withClassDiscriminator("PathNode", withFrameForShape(withBaseShapeTraits(CanvasNode)))
    implements WithPath, WithShape {
    pathSegments: List<PathSegment>
    pathClosed: boolean

    constructor(properties?: Partial<PathNode>) {
        super()
        setDefaults<PathNode>(this, getPathRecord(), properties)
        delete this.children // See comment in `MutableNode.ts`
    }

    rect(_parentSize: Size | null) {
        return {
            x: this.x,
            y: this.y,
            width: this.width,
            height: this.height,
        }
    }

    matrix(_parentSize?: Size | null, rect?: Rect) {
        const point = { x: this.x, y: this.y }
        if (rect) {
            point.x = rect.x
            point.y = rect.y
        }
        return new DOMMatrix().translateSelf(point.x, point.y).rotateSelf(0, 0, this.resolveValue("rotation") ?? 0)
    }

    transformMatrix(parentSize: Size | null, rect?: Rect) {
        const size = { width: this.width, height: this.height }
        if (rect) {
            size.width = rect.width
            size.height = rect.height
        }
        return new DOMMatrix()
            .translateSelf(0.5 * size.width, 0.5 * size.height)
            .multiplySelf(this.matrix(parentSize, rect))
            .translateSelf(-0.5 * size.width, -0.5 * size.height)
    }

    isRotated() {
        return (this.resolveValue("rotation") ?? 0) % 360 !== 0
    }

    calculatedPaths(): WithPaths {
        if (this.mutable) {
            return this.calculatePaths()
        }

        if (!this.cache.calculatedPaths) {
            this.cache.calculatedPaths = this.calculatePaths()
        }

        return this.cache.calculatedPaths
    }

    calculatePaths(): WithPaths {
        const { pathSegments, pathClosed } = this
        return [Path.applyRadius(pathSegments, pathClosed)]
    }

    updateForRect(rect: Rect, parentSize: Size | null) {
        rect.width = Math.max(1, rect.width)
        rect.height = Math.max(1, rect.height)
        const startRect = this.rect(parentSize)
        const xFromRange: [number, number] = [startRect.x, startRect.x + startRect.width]
        const xToRange: [number, number] = [rect.x, rect.x + rect.width]
        const yFromRange: [number, number] = [startRect.y, startRect.y + startRect.height]
        const yToRange: [number, number] = [rect.y, rect.y + rect.height]
        const widthRatio = startRect.width === 0 ? 1 : rect.width / startRect.width
        const heightRatio = startRect.height === 0 ? 1 : rect.height / startRect.height

        let pathSegments = this.pathSegments.map((vectorPoint: PathSegment) => {
            return new PathSegment(
                vectorPoint.merge({
                    x: modulate(vectorPoint.x + startRect.x, xFromRange, xToRange, false),
                    y: modulate(vectorPoint.y + startRect.y, yFromRange, yToRange, false),
                    handleOutX: vectorPoint.handleOutX * widthRatio,
                    handleOutY: vectorPoint.handleOutY * heightRatio,
                    handleInX: vectorPoint.handleInX * widthRatio,
                    handleInY: vectorPoint.handleInY * heightRatio,
                })
            )
        }) as List<PathSegment>
        const bbox = Path.boundingBox({ pathSegments, pathClosed: this.pathClosed })

        pathSegments = pathSegments.map((vectorPoint: PathSegment) => {
            return new PathSegment(vectorPoint.merge(Point.subtract(vectorPoint, bbox)))
        }) as List<PathSegment>

        return {
            pathSegments,
            x: bbox.x,
            y: bbox.y,
            width: bbox.width,
            height: bbox.height,
        }
    }

    updateForSize(size: Partial<Size>, parentSize: Size | null): Partial<ConstraintValues & WithFramePreset> {
        const currentRect = this.rect(parentSize)
        return this.updateForRect({ ...currentRect, ...size }, parentSize)
    }

    updateForPath(update: Partial<WithPath>): NodeUpdate {
        const segments = update.pathSegments !== undefined ? update.pathSegments : this.pathSegments
        let pathClosed = update.pathClosed !== undefined ? update.pathClosed : this.pathClosed

        if (update.pathSegments !== undefined && pathClosed) {
            const pathSegmentCount = update.pathSegments.count()
            if (pathSegmentCount === 2) {
                // Only open up path if both curves are straight
                const segment1 = update.pathSegments.get(0)
                const segment2 = update.pathSegments.get(1)
                const curve1Straight = isStraightCurve(segment1, segment2)
                const curve2Straight = isStraightCurve(segment2, segment1)
                if (curve1Straight && curve2Straight) {
                    pathClosed = false
                }
            } else if (pathSegmentCount < 2) {
                pathClosed = false
            }
        }

        const bbox = Path.boundingBox({ pathSegments: segments, pathClosed })
        const minX = bbox.x
        const minY = bbox.y

        const newRect = {
            x: this.x + minX,
            y: this.y + minY,
            width: bbox.width,
            height: bbox.height,
        }

        const rotation = this.resolveValue("rotation") ?? 0
        const origin = convertPoint({ x: 0, y: 0 }, this, rotation)
        const newOrigin = convertPoint({ x: -minX, y: -minY }, newRect, rotation)
        const delta = Point.subtract(origin, newOrigin)
        newRect.x += delta.x
        newRect.y += delta.y

        const pathSegments = segments.map((segment: PathSegment) => {
            const newPoint = Point.subtract(segment, { x: minX, y: minY })
            return new PathSegment(segment.merge(newPoint))
        }) as List<PathSegment>

        const strokeAlignment = pathClosed ? this.strokeAlignment : "center"

        return { ...newRect, pathSegments, pathClosed, strokeAlignment }
    }
}
