import type { CanvasNode } from "document/models/CanvasTree"

export interface WithSpikes {
    spikeCount: number
    spikeDepth: number
}

export const spikesDefaults: WithSpikes = {
    spikeCount: 5,
    spikeDepth: 50,
}

const key: keyof WithSpikes = "spikeCount"

export function withSpikes(node: CanvasNode): node is CanvasNode & WithSpikes {
    return key in node
}

export function reduceSpikes(
    node: CanvasNode,
    result: { spikeCount?: number | null; spikeDepth?: number | null; onlyNodesWithSpikes: boolean }
) {
    if (!withSpikes(node)) {
        result.onlyNodesWithSpikes = false
        return
    }

    if (result.spikeCount === undefined) {
        result.spikeCount = node.spikeCount
    } else if (result.spikeCount !== null && result.spikeCount !== node.spikeCount) {
        result.spikeCount = null
    }

    if (result.spikeDepth === undefined) {
        result.spikeDepth = node.spikeDepth
    } else if (result.spikeDepth !== null && result.spikeDepth !== node.spikeDepth) {
        result.spikeDepth = null
    }
}
