import type { CanvasNode } from "document/models/CanvasTree"
import { withBorder } from "document/models/CanvasTree/traits/Border"
import { withBorderPerSide } from "document/models/CanvasTree/traits/BorderPerSide"
import type { BorderProperties } from "framer"

export function borderPropsForNode(node: CanvasNode): Partial<BorderProperties> {
    if (!withBorder(node) || !node.borderEnabled) return {}

    let borderTop = node.borderWidth
    let borderBottom = node.borderWidth
    let borderLeft = node.borderWidth
    let borderRight = node.borderWidth

    if (withBorderPerSide(node) && node.borderPerSide) {
        borderTop = node.borderTop
        borderBottom = node.borderBottom
        borderLeft = node.borderLeft
        borderRight = node.borderRight
    }

    if (borderTop === 0 && borderBottom === 0 && borderLeft === 0 && borderRight === 0) return {}
    let borderWidth: number | Partial<{ top: number; bottom: number; left: number; right: number }> | undefined
    if (borderTop === borderBottom && borderTop === borderLeft && borderTop === borderRight) {
        borderWidth = borderTop
    } else {
        borderWidth = {
            top: borderTop,
            right: borderRight,
            bottom: borderBottom,
            left: borderLeft,
        }
    }
    return {
        borderWidth,
        borderStyle: node.borderStyle,
        borderColor: node.borderColor,
    }
}
