import { isVectorNode } from "../../nodes/TreeNode"
import type { CanvasNode } from "../../nodes/CanvasNode"
import { withFill, withOptionalFill } from "document/models/CanvasTree/traits/Fill"
import { withRadius } from "../Radius"
import { withRadiusPerCorner } from "../RadiusPerCorner"
import { hasBoxShadow } from "../BoxShadow"
import { withSVG } from "../SVG"

export function shouldRenderShadowUsingFilter(node: CanvasNode) {
    if (isVectorNode(node)) return false

    if (withSVG(node)) return true

    // Only use filter shadows when the node "is" an image
    if (!withFill(node) || node.fillType !== "image" || (withOptionalFill(node) && !node.fillEnabled)) return false

    // When specifying a radius, assume a box shadow makes more sense
    if (withRadius(node) && node.radius) {
        return false
    } else if (
        withRadiusPerCorner(node) &&
        (node.radiusTopLeft || node.radiusTopRight || node.radiusBottomLeft || node.radiusBottomLeft)
    ) {
        return false
    }

    // Because most browsers don't support the spread value for filter: drop-shadow, default to a box shadow if a spread is specified
    if (hasBoxShadow(node) && node.boxShadows) {
        const shadows = node.boxShadows
        for (let i = 0, count = shadows.length; i < count; i++) {
            const shadow = shadows[i]
            if (shadow.spread > 0) return false
        }
    }
    return true
}
