import type { Background, BackgroundProperties, DeprecatedBackgroundProperties } from "../traits/Background"
import type { LayerProps } from "../presentation/Layer"
import { BackgroundImage } from "../types/BackgroundImage"
import { Animatable } from "../../animation/Animatable"
import { isString } from "../../utils/utils"

function applyForwardOverrides(
    background: BackgroundImage,
    props: Partial<DeprecatedBackgroundProperties & LayerProps>
): BackgroundImage {
    const { _forwardedOverrides, id } = props
    const src = _forwardedOverrides && id ? _forwardedOverrides[id] : undefined
    if (src && typeof src === "string") {
        background = { ...background, src }
    }
    return background
}

/** @internal */
export function backgroundImageFromProps(
    props: Partial<DeprecatedBackgroundProperties & BackgroundProperties>
): BackgroundImage | undefined {
    const { background, image } = props
    if (image !== undefined && background && !BackgroundImage.isImageObject(background)) {
        // Give precedence to background prop that is not set to an image
        return
    }
    let backgroundImage: Background | null = null
    if (isString(image)) {
        backgroundImage = { src: image }
    } else {
        backgroundImage = Animatable.get(background, null)
    }
    if (!BackgroundImage.isImageObject(backgroundImage)) {
        return
    }
    return applyForwardOverrides(backgroundImage, props)
}
