import type { MotionStyle } from "framer-motion"
import type { CanvasNode } from "../../nodes/CanvasNode"
import type { CollectTraitOptions } from "."
import { withBoxShadow } from "document/models/CanvasTree/traits/BoxShadow"
import { shouldRenderShadowUsingFilter } from "document/models/CanvasTree/traits/utils/wantsFilterShadow"
import { isTextNode } from "../../nodes/TextNode"
import { isUndefined } from "utils/typeChecks"

export function collectBoxShadow(
    node: CanvasNode,
    style: MotionStyle,
    { unset = false }: CollectTraitOptions = {}
): void {
    if (!withBoxShadow(node) || isTextNode(node)) return
    if (isUndefined(node.boxShadows)) {
        if (unset) style.boxShadow = "none"
        return
    }

    const boxShadow: string[] = []
    const onlyInsetShadows = shouldRenderShadowUsingFilter(node)
    for (const shadow of node.boxShadows) {
        if (onlyInsetShadows && !shadow.inset) continue

        const inset = shadow.inset ? "inset " : ""
        boxShadow.push(`${inset}${shadow.x}px ${shadow.y}px ${shadow.blur}px ${shadow.spread}px ${shadow.color}`)
    }

    if (!boxShadow.length) {
        if (unset) style.boxShadow = "none"
        return
    }

    style.boxShadow = boxShadow.join(", ")
}
