export default function modulate(
    value: number,
    rangeA: [number, number],
    rangeB: [number, number],
    limit: boolean
): number {
    const fromLow = rangeA[0]
    const fromHigh = rangeA[1]
    const toLow = rangeB[0]
    const toHigh = rangeB[1]

    const fromDelta = fromHigh - fromLow
    if (fromDelta === 0) return (toHigh + toLow) / 2
    const toDelta = toHigh - toLow
    if (toDelta === 0) return toLow

    const result = toLow + ((value - fromLow) / (fromHigh - fromLow)) * (toHigh - toLow)

    if (limit) {
        if (toLow < toHigh) {
            if (result < toLow) return toLow
            if (result > toHigh) return toHigh
        } else {
            if (result > toLow) return toLow
            if (result < toHigh) return toHigh
        }
    }

    return result
}
