import type { MotionStyle } from "framer-motion"
import { isUndefined } from "utils/typeChecks"
import type { CanvasNode } from "../../nodes/CanvasNode"
import { withRotation } from "../Rotation"
import type { CollectTraitOptions } from "."

export function collectRotate(
    node: CanvasNode,
    style: MotionStyle,
    { withInlineVariables = false }: CollectTraitOptions = {}
): void {
    if (!withRotation(node) || isUndefined(node.rotation)) return

    style.rotate = node.resolveValue("rotation", withInlineVariables)
}
