// You should probably never remove presets from here, or we risk breaking

// projects, unless we also migrate the obsolete presets.
export type DevicePresetID =
    // Phones
    | "iphone-11"
    | "iphone-12-pro"
    | "iphone-12-pro-max"
    | "iphone-12"
    | "iphone-12-mini"
    | "iphone-11-pro"
    | "iphone-11-pro-max"
    | "iphone-8"
    | "iphone-8-plus"
    | "iphone-se"
    | "samsung-note-10"
    | "samsung-galaxy-s7"
    | "pixel-4"
    | "pixel-4-xl"
    | "pixel-5"
    // Desktops and Laptops
    | "macbook-air"
    | "macbook-pro-13"
    | "macbook-pro-16"
    | "imac-21-5"
    | "imac-27"
    | "pro-display-xdr"
    | "dell-xps"
    | "surface-book"
    // Tablets
    | "ipad"
    | "ipad-mini"
    | "ipad-air"
    | "ipad-pro-11"
    | "ipad-pro-12-9"
    | "surface-3"
    | "surface-pro-4"
    // Watches
    | "apple-watch-44"
    | "apple-watch-40"
    // TVs
    | "tv-4k"
    | "tv-full-hd"
    // Deprecated
    | "720p"
    | "900p"
    | "1080p"
    | "1440p"
    | "4k"

export const defaultPresetId: DevicePresetID = "iphone-12-pro"
export interface DeviceColor {
    colorValue: string
    title: string
    id: string
}
export interface DevicePreset {
    id: DevicePresetID
    title: string

    disableRotation?: boolean

    screenWidth: number
    screenHeight: number
    screenRadius?: number
    screenMask?: string

    clayBezelTop?: number
    clayBezelRight?: number
    clayBezelBottom?: number
    clayBezelLeft?: number
    clayBezelRadius?: number
    externalClay?: {
        width: number
        height: number
        screenOffsetTop: number
        screenOffsetLeft: number
    }

    realisticImage?: {
        width: number
        height: number
        screenOffsetTop: number
        screenOffsetLeft: number
        availableColors?: DeviceColor[]
        handOffset?: {
            left?: number
            right?: number
            bottom?: number
        }
    }
}

// Phones ------------------------------------------------------------------------------------------------------------------------
const presetsBase = {
    iPhonePro: {
        // Screen mask takes care of the rounded screen corners for iPhones Pro,
        // and actually using a radius here produces visual artifacts in Chrome.
        screenRadius: /* 38 */ 0,
        clayBezelLeft: 21,
        clayBezelRight: 21,
        clayBezelTop: 21,
        clayBezelBottom: 21,
        clayBezelRadius: 38 + 21,
    },
    iPhone8: {
        screenRadius: 0,
        clayBezelLeft: 24,
        clayBezelRight: 24,
        clayBezelTop: 96,
        clayBezelBottom: 96,
        clayBezelRadius: 38 * 1.5,
    },
    iPadPro: {
        screenRadius: 25,
        clayBezelLeft: 38,
        clayBezelRight: 38,
        clayBezelTop: 38,
        clayBezelBottom: 38,
        clayBezelRadius: 25 + 38,
    },
    desktop: {
        clayBezelLeft: 20,
        clayBezelRight: 20,
        clayBezelTop: 20,
        clayBezelBottom: 20,
        clayBezelRadius: 20,
    },
}

export const devicePresets: DevicePreset[] = [
    {
        id: "iphone-12",
        title: "iPhone 12",
        screenRadius: 0,
        clayBezelLeft: 22,
        clayBezelRight: 22,
        clayBezelTop: 22,
        clayBezelBottom: 22,
        clayBezelRadius: 66,
        screenWidth: 390,
        screenHeight: 844,
        externalClay: {
            width: 500,
            height: 974,
            screenOffsetTop: 65,
            screenOffsetLeft: 55,
        },
        screenMask:
            '<g style="transform: scale(0.5);"><path d="M171.2 0c2.3 0 4 .5 5.4 1.3 1.6 1 2.8 2.2 3.7 3.8.8 1.6 1.2 2.3 1.2 4.9 0 12 2.2 19 6.2 26.5s9.8 13.3 17.3 17.4c7.5 4 15.8 6.1 30.6 6.1h311.5c14.3 0 22.5-2.2 29.9-6.1 7.5-4 13.3-10 17.3-17.4 4-7.5 6.2-14.5 6.2-26.5 0-2.6.4-3.2 1.1-4.9.8-1.6 2-2.9 3.4-3.8 1.4-.8 3.2-1.3 5.4-1.3h54.2c40.1 0 54.7 4.2 69.4 12a81.8 81.8 0 0134 34c7.8 14.7 12 29.3 12 69.4v1457.2c0 40.1-4.2 54.7-12 69.4a81.8 81.8 0 01-34 34c-14.7 7.8-29.3 12-69.4 12H115.4c-40.1 0-54.7-4.2-69.4-12a81.8 81.8 0 01-34-34c-7.8-14.7-12-29.3-12-69.4V115.4C0 75.3 4.2 60.7 12 46a81.8 81.8 0 0134-34C60.7 4.2 75.3 0 115.4 0h55.4z" fill="#000" fill-rule="evenodd"/></g>',
        realisticImage: {
            width: 490,
            height: 944,
            screenOffsetLeft: 50,
            screenOffsetTop: 50,
            availableColors: [
                { id: "black", title: "Black", colorValue: "#2E2C36" },
                { id: "white", title: "White", colorValue: "#F7F3F0" },
                { id: "blue", title: "Blue", colorValue: "#14496D" },
                { id: "green", title: "Green", colorValue: "#DAF0D9" },
                { id: "red", title: "Red", colorValue: "#DB4141" },
            ],
            handOffset: { left: 29, right: 29, bottom: 29 },
        },
    },
    {
        id: "iphone-12-mini",
        title: "iPhone 12 Mini",
        screenRadius: 0,
        clayBezelLeft: 22,
        clayBezelRight: 22,
        clayBezelTop: 22,
        clayBezelBottom: 22,
        clayBezelRadius: 66,
        screenWidth: 360,
        screenHeight: 780,
        externalClay: {
            width: 450,
            height: 890,
            screenOffsetTop: 55,
            screenOffsetLeft: 45,
        },
        screenMask:
            '<g style="transform: scale(0.5);"><path d="M142 18c0 19 14 47 43 48h349c31 0 44-29 44-48 0-12 4-18 14-18h18c38 0 52 4 66 11 14 8 25 19 33 33v1c7 14 11 28 11 65v1340c0 38-4 52-11 66-8 14-19 25-33 33h-1c-14 7-28 11-65 11H110c-38 0-52-4-66-11-14-8-25-19-33-33v-1c-7-13-11-27-11-64V110c0-38 4-52 11-66 8-14 19-25 33-33h1C58 4 72 0 109 0h16c11 0 17 6 17 18z" fill="#000" fill-rule="evenodd"/></g>',
        realisticImage: {
            width: 460,
            height: 880,
            screenOffsetLeft: 50,
            screenOffsetTop: 50,
            availableColors: [
                { id: "black", title: "Black", colorValue: "#2E2C36" },
                { id: "white", title: "White", colorValue: "#F7F3F0" },
                { id: "blue", title: "Blue", colorValue: "#14496D" },
                { id: "green", title: "Green", colorValue: "#DAF0D9" },
                { id: "red", title: "Red", colorValue: "#DB4141" },
            ],
            handOffset: { left: 31.5, right: 30.5 /* 1t */, bottom: 30 },
        },
    },
    {
        id: "iphone-12-pro",
        title: "iPhone 12 Pro",
        screenRadius: 0,
        clayBezelLeft: 22,
        clayBezelRight: 22,
        clayBezelTop: 22,
        clayBezelBottom: 22,
        clayBezelRadius: 66,
        screenWidth: 390,
        screenHeight: 844,
        externalClay: {
            width: 494,
            height: 968,
            screenOffsetTop: 62,
            screenOffsetLeft: 52,
        },
        screenMask:
            '<g style="transform: scale(0.5);"><path d="M171.2 0c2.3 0 4 .5 5.4 1.3 1.6 1 2.8 2.2 3.7 3.8.8 1.6 1.2 2.3 1.2 4.9 0 12 2.2 19 6.2 26.5s9.8 13.3 17.3 17.4c7.5 4 15.8 6.1 30.6 6.1h311.5c14.3 0 22.5-2.2 29.9-6.1 7.5-4 13.3-10 17.3-17.4 4-7.5 6.2-14.5 6.2-26.5 0-2.6.4-3.2 1.1-4.9.8-1.6 2-2.9 3.4-3.8 1.4-.8 3.2-1.3 5.4-1.3h54.2c40.1 0 54.7 4.2 69.4 12a81.8 81.8 0 0134 34c7.8 14.7 12 29.3 12 69.4v1457.2c0 40.1-4.2 54.7-12 69.4a81.8 81.8 0 01-34 34c-14.7 7.8-29.3 12-69.4 12H115.4c-40.1 0-54.7-4.2-69.4-12a81.8 81.8 0 01-34-34c-7.8-14.7-12-29.3-12-69.4V115.4C0 75.3 4.2 60.7 12 46a81.8 81.8 0 0134-34C60.7 4.2 75.3 0 115.4 0h55.4z" fill="#000" fill-rule="evenodd"/></g>',
        realisticImage: {
            width: 490,
            height: 944,
            screenOffsetLeft: 50,
            screenOffsetTop: 50,
            availableColors: [
                { id: "graphite", title: "Graphite", colorValue: "#585753" },
                { id: "silver", title: "Silver", colorValue: "#E5E6E1" },
                { id: "pacific-blue", title: "Pacific Blue", colorValue: "#415D6C" },
                { id: "gold", title: "Gold", colorValue: "#FCECD5" },
            ],
            handOffset: { left: 29, right: 29, bottom: 29 },
        },
    },
    {
        id: "iphone-12-pro-max",
        title: "iPhone 12 Pro Max",
        screenRadius: 50, // to prevent leaking pixel in the corners, see https://github.com/framer/company/issues/20429
        clayBezelLeft: 22,
        clayBezelRight: 22,
        clayBezelTop: 22,
        clayBezelBottom: 22,
        clayBezelRadius: 66,
        screenWidth: 428,
        screenHeight: 926,
        externalClay: {
            width: 532,
            height: 1050,
            screenOffsetTop: 62,
            screenOffsetLeft: 52,
        },
        screenMask:
            '<path d="M102 0c6 0 7 3 7 9 0 10 7 23 24 23h164c13 0 22-12 22-23 0-6 1-9 7-9h34c24 0 32 2 41 7s15 11 20 20 7 17 7 41v790c0 24-2 32-7 41s-11 15-20 20-17 7-41 7H68c-24 0-32-2-41-7s-15-11-20-20-7-17-7-41V68c0-24 2-32 7-41S18 12 27 7s17-7 41-7h34z" fill="#000" fill-rule="evenodd"/>',
        realisticImage: {
            width: 528,
            height: 1026,
            screenOffsetLeft: 50,
            screenOffsetTop: 50,
            availableColors: [
                { id: "graphite", title: "Graphite", colorValue: "#585753" },
                { id: "silver", title: "Silver", colorValue: "#E5E6E1" },
                { id: "pacific-blue", title: "Pacific Blue", colorValue: "#415D6C" },
                { id: "gold", title: "Gold", colorValue: "#FCECD5" },
            ],
            handOffset: { left: 28.5, right: 28, bottom: 29 },
        },
    },
    {
        id: "iphone-11",
        title: "iPhone 11",
        // Screen mask takes care of the rounded screen corners for iPhones Pro,
        // and actually using a radius here produces visual artifacts in Chrome.
        screenRadius: /* 38 */ 0,
        clayBezelLeft: 35.5,
        clayBezelRight: 35.5,
        clayBezelTop: 35.5,
        clayBezelBottom: 35.5,
        clayBezelRadius: 77,
        screenWidth: 414,
        screenHeight: 896,
        externalClay: {
            width: 524,
            height: 1026,
            screenOffsetTop: 65,
            screenOffsetLeft: 55,
        },
        screenMask:
            '<path d="M85.5 0C89.1 0 92 3 92 6.5c.3 6 1.5 10 3.4 13.5 2.2 4.1 5.5 7.4 9.6 9.6 4.2 2.2 8.9 3.4 17 3.4h170c8.1 0 12.8-1.2 17-3.4 4.1-2.2 7.4-5.5 9.6-9.6A31 31 0 00322 6.5c0-3.6 3-6.5 6.5-6.5h32.3c18.5 0 25.2 2 32 5.5 6.7 3.7 12 9 15.7 15.7 3.6 6.8 5.5 13.5 5.5 32v789.6c0 18.5-2 25.2-5.5 32-3.7 6.7-9 12-15.7 15.7-6.8 3.6-13.5 5.5-32 5.5H53.2c-18.5 0-25.2-2-32-5.5-6.7-3.7-12-9-15.7-15.7C2 868 0 861.3 0 842.8V53.2c0-18.5 2-25.2 5.5-32 3.7-6.7 9-12 15.7-15.7C28 2 34.7 0 53.2 0h32.3z" fill="#000" fill-rule="nonzero"/>',
        realisticImage: {
            width: 514,
            height: 996,
            screenOffsetLeft: 50,
            screenOffsetTop: 50,
            availableColors: [
                { id: "black", title: "Black", colorValue: "#202120" },
                { id: "white", title: "White", colorValue: "#F9F6EF" },
                { id: "purple", title: "Purple", colorValue: "#D1CDDB" },
                { id: "green", title: "Green", colorValue: "#ADE0CD" },
                { id: "red", title: "Red", colorValue: "#B90D2E" },
                { id: "yellow", title: "Yellow", colorValue: "#FFE680" },
            ],
            handOffset: { left: 14.5, right: 14.5, bottom: 14.5 },
        },
    },
    {
        id: "iphone-11-pro",
        title: "iPhone 11 Pro",
        ...presetsBase.iPhonePro,
        screenWidth: 375,
        screenHeight: 812,
        externalClay: {
            width: 485,
            height: 942,
            screenOffsetTop: 65,
            screenOffsetLeft: 55,
        },
        screenMask:
            '<path d="M292 8.668V9c0 9.266-7.07 21-23.332 21h-162C90.402 30 83.332 18.266 83.332 9v-.332c0-4.285 0-8.668-7.664-8.668H43.332C16.312 0 0 16.313 0 43.332v725.336C0 795.688 16.313 812 43.332 812h288.336c27.02 0 43.332-16.313 43.332-43.332V43.332C375 16.312 358.687 0 331.668 0h-32C292 0 292 4.383 292 8.668zm0 0"/>',
        realisticImage: {
            width: 475,
            height: 912,
            screenOffsetLeft: 50,
            screenOffsetTop: 50,
            availableColors: [
                { id: "space-grey", title: "Space Grey", colorValue: "#52514F" },
                { id: "silver", title: "Silver", colorValue: "#EBEBE3" },
                { id: "gold", title: "Gold", colorValue: "#FBD7BD" },
                { id: "midnight-green", title: "Midnight Green", colorValue: "#4F5850" },
            ],
            handOffset: { left: 24.5 /* 1t */, right: 24.5 /* 1t */, bottom: 23.5 },
        },
    },
    {
        id: "iphone-11-pro-max",
        title: "iPhone 11 Pro Max",
        ...presetsBase.iPhonePro,
        screenWidth: 414,
        screenHeight: 896,
        externalClay: {
            width: 524,
            height: 1026,
            screenOffsetTop: 65,
            screenOffsetLeft: 55,
        },
        screenMask:
            '<path d="M96 0c3.313 0 5.91 2.688 6 6 .18 6.645 1.191 10.148 2.938 13.41 1.917 3.586 4.73 6.402 8.316 8.317 3.586 1.918 7.441 2.941 15.445 2.941h156.602c8.004 0 11.86-1.023 15.445-2.941 3.586-1.915 6.399-4.73 8.317-8.317 1.746-3.265 2.746-6.758 2.937-13.41.094-3.313 2.688-6 6-6h46.004c17.387 0 23.687 1.809 30.043 5.21 6.355 3.4 11.344 8.388 14.742 14.743C412.191 26.31 414 32.61 414 49.996v796.008c0 17.387-1.809 23.687-5.21 30.043-3.4 6.355-8.388 11.344-14.743 14.742-6.356 3.402-12.656 5.211-30.043 5.211H49.996c-17.387 0-23.687-1.809-30.043-5.21-6.355-3.4-11.344-8.388-14.742-14.743C1.809 869.69 0 863.39 0 846.004V49.996C0 32.61 1.809 26.31 5.21 19.953c3.4-6.355 8.388-11.344 14.743-14.742C26.31 1.809 32.61 0 49.996 0zm0 0"/>',
        realisticImage: {
            width: 514,
            height: 996,
            screenOffsetLeft: 50,
            screenOffsetTop: 50,
            availableColors: [
                { id: "space-grey", title: "Space Grey", colorValue: "#52514F" },
                { id: "silver", title: "Silver", colorValue: "#EBEBE3" },
                { id: "gold", title: "Gold", colorValue: "#FBD7BD" },
                { id: "midnight-green", title: "Midnight Green", colorValue: "#4F5850" },
            ],
            handOffset: { left: 23.5, right: 24.5, bottom: 24 },
        },
    },
    {
        id: "iphone-8",
        title: "iPhone 8",
        ...presetsBase.iPhone8,
        screenWidth: 375,
        screenHeight: 667,
        externalClay: {
            width: 491,
            height: 971,
            screenOffsetLeft: 58,
            screenOffsetTop: 152,
        },
        realisticImage: {
            width: 475,
            height: 927,
            screenOffsetLeft: 50,
            screenOffsetTop: 130,
            availableColors: [
                { id: "space-grey", title: "Space Grey", colorValue: "#28282A" },
                { id: "silver", title: "Silver", colorValue: "#DFE1E2" },
                { id: "gold", title: "Gold", colorValue: "#F6E6DB" },
            ],
            handOffset: { left: 22, right: 22, bottom: 18.5 },
        },
    },
    {
        id: "iphone-8-plus",
        title: "iPhone 8 Plus",
        ...presetsBase.iPhone8,
        screenWidth: 414,
        screenHeight: 736,
        externalClay: {
            width: 530,
            height: 1064,
            screenOffsetLeft: 58,
            screenOffsetTop: 164,
        },
        realisticImage: {
            width: 514,
            height: 996,
            screenOffsetLeft: 50,
            screenOffsetTop: 130,
            availableColors: [
                { id: "space-grey", title: "Space Grey", colorValue: "#28282A" },
                { id: "silver", title: "Silver", colorValue: "#DFE1E2" },
                { id: "gold", title: "Gold", colorValue: "#F6E6DB" },
            ],
            handOffset: { left: 21, right: 20.5 /* 1t */, bottom: 19 },
        },
    },
    {
        id: "iphone-se",
        title: "iPhone SE",
        screenWidth: 320,
        screenHeight: 568,
        screenRadius: 0,
        clayBezelLeft: 20,
        clayBezelRight: 20,
        clayBezelTop: 112,
        clayBezelBottom: 112,
        clayBezelRadius: 38 * 1.5,
        externalClay: {
            width: 436,
            height: 872,
            screenOffsetLeft: 58,
            screenOffsetTop: 152,
        },
        realisticImage: {
            width: 420,
            height: 828,
            screenOffsetLeft: 50,
            screenOffsetTop: 130,
            availableColors: [
                { id: "space-grey", title: "Space Grey", colorValue: "#C3C4C8" },
                { id: "silver", title: "Silver", colorValue: "#E1E2E4" },
                { id: "gold", title: "Gold", colorValue: "#EFD8BD" },
                { id: "rose-gold", title: "Rose Gold", colorValue: "#F7CFCA" },
            ],
            handOffset: { left: 22, right: 22, bottom: 26.5 },
        },
    },
    {
        id: "samsung-galaxy-s7",
        title: "Samsung Galaxy S7",
        screenRadius: 0,
        clayBezelLeft: 22,
        clayBezelRight: 22,
        clayBezelTop: 22,
        clayBezelBottom: 22,
        clayBezelRadius: 66,
        screenWidth: 360,
        screenHeight: 640,
        externalClay: {
            width: 454,
            height: 880,
            screenOffsetTop: 120,
            screenOffsetLeft: 47,
        },
        realisticImage: {
            width: 440,
            height: 860,
            screenOffsetLeft: 40,
            screenOffsetTop: 110,
            availableColors: [
                { id: "black", title: "Black", colorValue: "#2E2C36" },
                { id: "white", title: "White", colorValue: "#F7F3F0" },
                { id: "silver", title: "Silver", colorValue: "#E5E6E1" },
                { id: "gold", title: "Gold", colorValue: "#FCECD5" },
            ],
            handOffset: { left: 26 /* 1t */, right: 25.5 /* 1t */, bottom: 32 },
        },
    },
    // deprecated
    {
        id: "samsung-note-10",
        title: "Samsung Note 10",
        screenWidth: 360,
        screenHeight: 760,
        screenRadius: 10,
        clayBezelLeft: 7,
        clayBezelRight: 7,
        clayBezelTop: 15,
        clayBezelBottom: 15,
        clayBezelRadius: 15,
    },
    {
        id: "pixel-5",
        title: "Google Pixel 5",
        screenRadius: 31,
        clayBezelLeft: 22,
        clayBezelRight: 22,
        clayBezelTop: 22,
        clayBezelBottom: 22,
        clayBezelRadius: 66,
        screenWidth: 360,
        screenHeight: 780,
        externalClay: {
            width: 460,
            height: 900,
            screenOffsetTop: 60,
            screenOffsetLeft: 50,
        },
        realisticImage: {
            width: 920 / 2,
            height: 1760 / 2,
            screenOffsetLeft: 100 / 2,
            screenOffsetTop: 100 / 2,
            availableColors: [
                { id: "just-black", title: "Just Black", colorValue: "#2E2C36" },
                { id: "sorta-sage", title: "Sorta Sage", colorValue: "#B7C9C0" },
            ],
            handOffset: { left: 31.5, right: 31 /* 1t */, bottom: 31 /* 1t */ },
        },
    },
    {
        id: "pixel-4",
        title: "Google Pixel 4",
        screenWidth: 360,
        screenHeight: 760,
        screenRadius: 34,
        clayBezelLeft: 10,
        clayBezelRight: 10,
        clayBezelTop: 50,
        clayBezelBottom: 25,
        clayBezelRadius: 50,
        externalClay: {
            width: 460,
            height: 938,
            screenOffsetLeft: 50,
            screenOffsetTop: 89,
        },
        realisticImage: {
            width: 460,
            height: 920,
            screenOffsetLeft: 50,
            screenOffsetTop: 80,
            availableColors: [
                { id: "clearly-white", title: "Clearly White", colorValue: "#EAEDF2" },
                { id: "just-black", title: "Just Black", colorValue: "#1A1A1A" },
                { id: "oh-so-orange", title: "Oh So Orange", colorValue: "#FF7A68" },
            ],
            handOffset: { left: 35.5, right: 35.5 /* 1t */, bottom: 57 /* 1t */ },
        },
    },

    // Desktop ------------------------------------------------------------------------------------------------------------------------
    {
        id: "macbook-air",
        title: "MacBook Air",
        screenWidth: 1440,
        screenHeight: 900,
        externalClay: {
            width: 1890,
            height: 1125,
            screenOffsetLeft: 225,
            screenOffsetTop: 98,
        },
        realisticImage: {
            width: 3848 / 2,
            height: 2240 / 2,
            screenOffsetLeft: 484 / 2,
            screenOffsetTop: 196 / 2,
            availableColors: [
                { id: "silver", title: "Silver", colorValue: "#E5E6E1" },
                { id: "space-grey", title: "Space Grey", colorValue: "#B1B5B7" },
                { id: "gold", title: "Gold", colorValue: "#FCECD5" },
            ],
        },
    },
    {
        id: "macbook-pro-13",
        title: `MacBook Pro 13"`,
        screenWidth: 1440,
        screenHeight: 900,
        externalClay: {
            width: 1914,
            height: 1169,
            screenOffsetLeft: 236,
            screenOffsetTop: 109,
        },
        realisticImage: {
            width: 3916 / 2,
            height: 2330 / 2,
            screenOffsetLeft: 518 / 2,
            screenOffsetTop: 218 / 2,
            availableColors: [
                { id: "silver", title: "Silver", colorValue: "#E5E6E1" },
                { id: "space-grey", title: "Space Grey", colorValue: "#B1B5B7" },
            ],
        },
    },
    {
        id: "macbook-pro-16",
        title: `MacBook Pro 16"`,
        screenWidth: 1536,
        screenHeight: 960,
        externalClay: {
            width: 1984,
            height: 1179,
            screenOffsetLeft: 225,
            screenOffsetTop: 78,
        },
        realisticImage: {
            width: 4032 / 2,
            height: 2348 / 2,
            screenOffsetLeft: 480 / 2,
            screenOffsetTop: 148 / 2,
            availableColors: [
                { id: "silver", title: "Silver", colorValue: "#E5E6E1" },
                { id: "space-grey", title: "Space Grey", colorValue: "#B1B5B7" },
            ],
        },
    },
    {
        id: "imac-21-5",
        title: `iMac 21.5"`,
        screenWidth: 2048,
        screenHeight: 1152,
        externalClay: {
            width: 2288,
            height: 1892,
            screenOffsetLeft: 120,
            screenOffsetTop: 120,
        },
        realisticImage: {
            width: 4562 / 2,
            height: 3796 / 2,
            screenOffsetLeft: 232 / 2,
            screenOffsetTop: 244 / 2,
        },
    },
    {
        id: "imac-27",
        title: `iMac 27"`,
        screenWidth: 2560,
        screenHeight: 1440,
        externalClay: {
            width: 2848,
            height: 2351,
            screenOffsetLeft: 144,
            screenOffsetTop: 151,
        },
        realisticImage: {
            width: 5676 / 2,
            height: 4720 / 2,
            screenOffsetLeft: 278 / 2,
            screenOffsetTop: 292 / 2,
            availableColors: [
                { id: "silver", title: "Silver", colorValue: "#E5E6E1" },
                { id: "pro", title: "Pro", colorValue: "#5F5E63" },
            ],
        },
    },
    {
        id: "pro-display-xdr",
        title: `Pro Display XDR`,
        screenWidth: 3008,
        screenHeight: 1692,
        externalClay: {
            width: 3148,
            height: 2325,
            screenOffsetLeft: 70,
            screenOffsetTop: 60,
        },
        realisticImage: {
            width: 6276 / 2,
            height: 4695 / 2,
            screenOffsetLeft: 130 / 2,
            screenOffsetTop: 130 / 2,
        },
    },
    {
        id: "dell-xps",
        title: `Dell XPS`,
        screenWidth: 1920,
        screenHeight: 1080,
        externalClay: {
            width: 2624,
            height: 1381,
            screenOffsetLeft: 352,
            screenOffsetTop: 57,
        },
        realisticImage: {
            width: 5320 / 2,
            height: 2746 / 2,
            screenOffsetLeft: 740 / 2,
            screenOffsetTop: 108 / 2,
        },
    },
    {
        id: "surface-book",
        title: `Microsoft Surface Book`,
        screenWidth: 1500,
        screenHeight: 1000,
        externalClay: {
            width: 2089,
            height: 1234,
            screenOffsetLeft: 296,
            screenOffsetTop: 93,
        },
        realisticImage: {
            width: 4200 / 2,
            height: 2508 / 2,
            screenOffsetLeft: 600 / 2,
            screenOffsetTop: 210 / 2,
        },
    },

    // Tablets ------------------------------------------------------------------------------------------------------------------------
    {
        id: "ipad",
        title: "iPad",
        screenRadius: 0,
        screenWidth: 810,
        screenHeight: 1080,
        clayBezelLeft: 30,
        clayBezelRight: 30,
        clayBezelTop: 95,
        clayBezelBottom: 95,
        clayBezelRadius: 0,
        externalClay: {
            width: 966,
            height: 1378,
            screenOffsetLeft: 78,
            screenOffsetTop: 149,
        },
        realisticImage: {
            width: 1920 / 2,
            height: 2720 / 2,
            screenOffsetLeft: 75,
            screenOffsetTop: 140,
            availableColors: [
                { id: "space-grey", title: "Space Grey", colorValue: "#C3C4C8" },
                { id: "silver", title: "Silver", colorValue: "#E1E2E4" },
                { id: "gold", title: "Gold", colorValue: "#EFD8BD" },
            ],
        },
    },
    {
        id: "ipad-mini",
        title: "iPad Mini",
        screenRadius: 0,
        clayBezelLeft: 49,
        clayBezelRight: 49,
        clayBezelTop: 49,
        clayBezelBottom: 49,
        clayBezelRadius: 49,
        screenWidth: 768,
        screenHeight: 1024,
        externalClay: {
            width: 924,
            height: 1384,
            screenOffsetLeft: 78,
            screenOffsetTop: 180,
        },
        realisticImage: {
            width: 1856 / 2,
            height: 2728 / 2,
            screenOffsetLeft: 160 / 2,
            screenOffsetTop: 340 / 2,
            availableColors: [
                { id: "space-grey", title: "Space Grey", colorValue: "#C3C4C8" },
                { id: "silver", title: "Silver", colorValue: "#E1E2E4" },
                { id: "gold", title: "Gold", colorValue: "#EFD8BD" },
            ],
        },
    },
    {
        id: "ipad-air",
        title: "iPad Air",
        screenRadius: 18,
        clayBezelLeft: 49,
        clayBezelRight: 49,
        clayBezelTop: 49,
        clayBezelBottom: 49,
        clayBezelRadius: 49,
        screenWidth: 820,
        screenHeight: 1180,
        externalClay: {
            width: 994,
            height: 1374,
            screenOffsetLeft: 87,
            screenOffsetTop: 97,
        },
        realisticImage: {
            width: 1960 / 2,
            height: 2680 / 2,
            screenOffsetLeft: 160 / 2,
            screenOffsetTop: 160 / 2,
            availableColors: [
                { id: "space-grey", title: "Space Grey", colorValue: "#C3C4C8" },
                { id: "silver", title: "Silver", colorValue: "#E1E2E4" },
                { id: "rose-gold", title: "Rose Gold", colorValue: "#ECCBC4" },
                { id: "blue", title: "Blue", colorValue: "#CBDAE6" },
                { id: "green", title: "Green", colorValue: "#DAF0D9" },
            ],
        },
    },
    {
        id: "ipad-pro-11",
        title: "iPad Pro 11″",
        screenRadius: 17,
        clayBezelLeft: 49,
        clayBezelRight: 49,
        clayBezelTop: 49,
        clayBezelBottom: 49,
        clayBezelRadius: 49,
        screenWidth: 834,
        screenHeight: 1194,
        externalClay: {
            width: 990,
            height: 1370,
            screenOffsetLeft: 78,
            screenOffsetTop: 88,
        },
        realisticImage: {
            width: 1968 / 2,
            height: 2688 / 2,
            screenOffsetLeft: 75,
            screenOffsetTop: 75,
            availableColors: [
                { id: "space-grey", title: "Space Grey", colorValue: "#C3C4C8" },
                { id: "silver", title: "Silver", colorValue: "#E1E2E4" },
            ],
        },
    },
    {
        id: "ipad-pro-12-9",
        title: "iPad Pro 12.9″",
        ...presetsBase.iPadPro,
        screenRadius: 17,
        screenWidth: 1024,
        screenHeight: 1366,
        externalClay: {
            width: 1180,
            height: 1542,
            screenOffsetLeft: 78,
            screenOffsetTop: 88,
        },
        realisticImage: {
            width: 2348 / 2,
            height: 3032 / 2,
            screenOffsetLeft: 75,
            screenOffsetTop: 75,
            availableColors: [
                { id: "space-grey", title: "Space Grey", colorValue: "#C3C4C8" },
                { id: "silver", title: "Silver", colorValue: "#E1E2E4" },
            ],
        },
    },
    {
        id: "surface-3",
        title: "Microsoft Surface 3",
        screenRadius: 0,
        clayBezelLeft: 49,
        clayBezelRight: 49,
        clayBezelTop: 49,
        clayBezelBottom: 49,
        clayBezelRadius: 49,
        screenWidth: 960,
        screenHeight: 640,
        externalClay: {
            width: 1184,
            height: 864,
            screenOffsetLeft: 112,
            screenOffsetTop: 112,
        },
        realisticImage: {
            width: 2280 / 2,
            height: 1580 / 2,
            screenOffsetLeft: 180 / 2,
            screenOffsetTop: 150 / 2,
        },
    },
    {
        id: "surface-pro-4",
        title: "Microsoft Surface Pro 4",
        screenRadius: 0,
        clayBezelLeft: 49,
        clayBezelRight: 49,
        clayBezelTop: 49,
        clayBezelBottom: 49,
        clayBezelRadius: 49,
        screenWidth: 1368,
        screenHeight: 912,
        externalClay: {
            width: 1592,
            height: 1136,
            screenOffsetLeft: 112,
            screenOffsetTop: 112,
        },
        realisticImage: {
            width: 3176 / 2,
            height: 2224 / 2,
            screenOffsetLeft: 220 / 2,
            screenOffsetTop: 200 / 2,
        },
    },

    // Watches ------------------------------------------------------------------------------------------------------------------------
    {
        id: "apple-watch-44",
        title: `Apple Watch 44mm`,
        screenRadius: 33,
        screenWidth: 184,
        screenHeight: 224,
        externalClay: {
            width: 298,
            height: 502,
            screenOffsetLeft: 57,
            screenOffsetTop: 129,
        },
        realisticImage: {
            width: 548 / 2,
            height: 908 / 2,
            screenOffsetLeft: 90 / 2,
            screenOffsetTop: 230 / 2,
            availableColors: [
                { id: "black", title: "Black", colorValue: "#2E2C36" },
                { id: "white", title: "White", colorValue: "#F7F3F0" },
                { id: "yellow", title: "Yellow", colorValue: "#FDDC6C" },
                { id: "orange", title: "Orange", colorValue: "#F35C56" },
            ],
        },
    },
    {
        id: "apple-watch-40",
        title: `Apple Watch 40mm`,
        screenRadius: 29,
        screenWidth: 162,
        screenHeight: 197,
        externalClay: {
            width: 280,
            height: 463,
            screenOffsetLeft: 59,
            screenOffsetTop: 124,
        },
        realisticImage: {
            width: 504 / 2,
            height: 854 / 2,
            screenOffsetLeft: 90 / 2,
            screenOffsetTop: 230 / 2,
            availableColors: [
                { id: "black", title: "Black", colorValue: "#2E2C36" },
                { id: "white", title: "White", colorValue: "#F7F3F0" },
                { id: "yellow", title: "Yellow", colorValue: "#FDDC6C" },
                { id: "orange", title: "Orange", colorValue: "#F35C56" },
            ],
        },
    },

    // TVs ------------------------------------------------------------------------------------------------------------------------
    {
        id: "tv-full-hd",
        title: `Full HD`,
        screenRadius: 0,
        screenWidth: 1920,
        screenHeight: 1080,
        externalClay: {
            width: 1968,
            height: 1168,
            screenOffsetLeft: 24,
            screenOffsetTop: 12,
        },
        realisticImage: {
            width: 4040 / 2,
            height: 2360 / 2,
            screenOffsetLeft: 100 / 2,
            screenOffsetTop: 100 / 2,
        },
    },
    {
        id: "tv-4k",
        title: `4K`,
        screenRadius: 0,
        screenWidth: 3840,
        screenHeight: 2160,
        externalClay: {
            width: 3908,
            height: 2308,
            screenOffsetLeft: 34,
            screenOffsetTop: 24,
        },
        realisticImage: {
            width: 7960 / 2,
            height: 4600 / 2,
            screenOffsetLeft: 140 / 2,
            screenOffsetTop: 140 / 2,
        },
    },

    // Old devices ------------------------------------------------------------------------------------------------------------------------

    // deprecated
    {
        id: "720p",
        title: "720p",
        ...presetsBase.desktop,
        screenWidth: 720,
        screenHeight: 1280,
    },

    // deprecated
    {
        id: "900p",
        title: "900p",
        ...presetsBase.desktop,
        screenWidth: 900,
        screenHeight: 1440,
    },

    // deprecated
    {
        id: "1080p",
        title: "1080p",
        ...presetsBase.desktop,
        screenWidth: 1080,
        screenHeight: 1920,
    },

    // deprecated
    {
        id: "1440p",
        title: "1440p",
        ...presetsBase.desktop,
        screenWidth: 1440,
        screenHeight: 2560,
    },

    // deprecated
    {
        id: "4k",
        title: "4K",
        ...presetsBase.desktop,
        screenWidth: 2160,
        screenHeight: 3840,
    },
]

const deviceCodeComponentPresetIds = [
    "iphone-12",
    "iphone-12-mini",
    "iphone-12-pro",
    "iphone-11",
    "iphone-11-pro",
    "iphone-11-pro-max",
    "iphone-8",
    "iphone-8-plus",
    "iphone-se",
    "samsung-note-10",
    "pixel-4",
    "ipad",
    "ipad-pro-11",
    "ipad-pro-12-9",
    "720p",
    "900p",
    "1080p",
    "1440p",
    "4k",
]

export const deviceCodeComponentPresets = devicePresets.filter(preset =>
    deviceCodeComponentPresetIds.includes(preset.id)
)

const devicePresetsMap = devicePresets.reduce((map, preset) => {
    map[preset.id] = preset
    return map
}, {} as Record<DevicePresetID, DevicePreset>)

export function getDevicePreset(presetId: DevicePresetID): DevicePreset {
    return devicePresetsMap[presetId] ?? devicePresetsMap[defaultPresetId]
}
