import type { ActionControlValues, OptionalActionControlValues } from "./actions"

import { ControlType } from "framer"
import { TriggerEventOptions } from "@framerjs/framer-runtime"
import { isEventAction, EventAction } from "document/models/EventAction"
import { ControlProp } from "../traits/CodeComponent"
import { HardCodedCodeIdentifier } from "../traits/utils/hardCodedCodeComponentIdentifiers"
import { randomID } from "../nodes/NodeID"

type TriggerEventActionControls = ActionControlValues<TriggerEventOptions>
type OptionalTriggerEventActionControls = OptionalActionControlValues<TriggerEventOptions>

export interface TriggerEventAction extends EventAction {
    controls: { [key: string]: ControlProp } & OptionalTriggerEventActionControls
}

export function isTriggerEventAction(value: unknown): value is TriggerEventAction {
    return isEventAction(value) && value.actionIdentifier === HardCodedCodeIdentifier.triggerEventAction
}

export function updatedTriggerEventAction(
    action: TriggerEventAction,
    controlOverrides: TriggerEventActionControls
): EventAction {
    const controls = {
        ...action.controls,
        ...controlOverrides,
    }
    return { ...action, controls }
}

export function createTriggerEventAction(variableId: string, id?: string): EventAction {
    return {
        identifier: id ? id : randomID(),
        actionIdentifier: HardCodedCodeIdentifier.triggerEventAction,
        controls: {
            id: {
                type: ControlType.String,
                value: variableId,
            },
        },
    }
}
