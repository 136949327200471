import * as React from "react"
import type { SandboxErrorDefinition } from "./types"
import { extractFileNameFromIdentifier } from "./extractFileNameFromIdentifier"

export function createErrorDefinition(identifier: string, error: string, custom?: Partial<SandboxErrorDefinition>) {
    const file = extractFileNameFromIdentifier(identifier)
    const cleanedFile = cleanFilename(file)
    const definition: SandboxErrorDefinition = {
        class: createErrorComponentClass(error),
        depth: identifier.startsWith(".") ? 0 : 1, // Assume a depth of 1 when non-local.
        error,
        file,
        identifier,
        name: cleanedFile,
        packageIdentifier: "<unknown>",
        properties: {},
        type: "component",
        ...custom,
    }
    return definition
}

function cleanFilename(filename: string) {
    if (!filename.startsWith("./")) return filename
    return filename.slice(2)
}

function createErrorComponentClass(error: string | Error): React.ComponentClass {
    return class ErrorComponent extends React.Component {
        // eslint-disable-next-line react/require-render-return
        render(): React.ReactNode {
            throw error
        }
    }
}
