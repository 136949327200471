import type { CanvasNode } from "document/models/CanvasTree"

export interface WithClip {
    clip: boolean
}

export const clipDefaults: WithClip = {
    clip: true,
}

import { stylableNode } from "./utils/stylableNode"
const key: keyof WithClip = "clip"

export function withClip(node: CanvasNode): node is CanvasNode & WithClip {
    return key in node && stylableNode(node)
}
