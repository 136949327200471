import type { ErrorDefinition } from "./types"

export function createErrorDefinition({
    identifier,
    file,
    error,
    custom,
}: {
    identifier: string
    file: string
    error: string
    custom?: Partial<ErrorDefinition>
}) {
    const cleanedFile = cleanFilename(file)
    const definition: ErrorDefinition = {
        depth: identifier.startsWith(".") ? 0 : 1, // Assume a depth of 1 when non-local.
        error,
        file,
        identifier,
        name: cleanedFile,
        packageIdentifier: "<unknown>",
        properties: {},
        type: "component",
        ...custom,
    }
    return definition
}

function cleanFilename(filename: string) {
    if (!filename.startsWith("./")) return filename
    return filename.slice(2)
}
