import * as React from "react"

export const IconPreviewReloadBigger = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
    function IconPreviewReloadBigger({ style, ...props }, forwardedRef) {
        return (
            <svg
                ref={forwardedRef}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                style={{ transformOrigin: "8px 8px", ...style }}
                {...props}
            >
                <path
                    d="M 13 2.5 C 13 1.948 13.448 1.5 14 1.5 L 14 1.5 C 14.552 1.5 15 1.948 15 2.5 L 15 8 L 13 8 Z"
                    fill="currentColor"
                />
                <path
                    d="M 8.5 7 C 8.5 6.448 8.948 6 9.5 6 L 15 6 L 15 8 L 9.5 8 C 8.948 8 8.5 7.552 8.5 7 Z"
                    fill="currentColor"
                />
                <path
                    d="M 8 2 C 10.612 2 12.835 3.67 13.659 6 L 11.465 6 C 10.773 4.804 9.481 4 8 4 C 5.791 4 4 5.791 4 8 C 4 10.209 5.791 12 8 12 C 9.481 12 10.773 11.196 11.465 10 L 13.659 10 C 12.835 12.33 10.612 14 8 14 C 4.686 14 2 11.314 2 8 C 2 4.686 4.686 2 8 2 Z"
                    fill="currentColor"
                />
            </svg>
        )
    }
)
