import type { EntityIdentifier, EntityDefinition } from "@framerjs/framer-runtime"
import { asLocalID, LocalModuleID, ModuleType } from "./types"
import { ExportSpecifier } from "./compiler"

const LOCAL_MODULE_ENTITY_IDENTIFIER_REGEX = /^local-module:(([a-zA-Z0-9]+)\/.+):(.+)$/

interface ParsedIdentifier {
    localId: LocalModuleID
    type: string
    exportSpecifier: ExportSpecifier
}

export function isLocalCanvasComponent(entity: EntityDefinition): boolean {
    const info = parseLocalModuleEntityIdentifier(entity.identifier)
    return info?.type === ModuleType.Canvas
}

export function isLocalModule(id: EntityIdentifier) {
    return !!id.match(LOCAL_MODULE_ENTITY_IDENTIFIER_REGEX)
}

export function parseLocalModuleEntityIdentifier(id: EntityIdentifier): ParsedIdentifier | undefined {
    const match = id.match(LOCAL_MODULE_ENTITY_IDENTIFIER_REGEX)
    if (!match) return undefined

    return {
        localId: asLocalID(match[1]),
        type: match[2],
        exportSpecifier: match[3],
    }
}
