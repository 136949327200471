import * as React from "react"
import type { RawDraftContentState, RawDraftContentBlock, RawDraftInlineStyleRange, SelectionState } from "draft-js"
import { Range, isRangeCovered, isRangeOverlappingRange, getSortedRange, isRangeCollapsed } from "../range"
import { draftStyleDefinitions, DraftPrefix } from "../."
import { getStylesFromDraft } from "./styles"
export { draftStyleFunction, TextTransform, TextDecoration, TextLineHeightUnit, TextDirection } from "./styles"

export { getHTMLSizeCached } from "./html"

/**
 * @internal
 */
export function getDraftStylesWithPrefixCoverRange(
    content: RawDraftContentState,
    prefixes: DraftPrefix[],
    range?: Range
) {
    range = range || getDraftRange(content)

    for (const prefix of prefixes) {
        const inlineStyleRanges = getDraftStyleRangesWithPrefix(content, prefix)
        const ranges: Range[] = inlineStyleRanges.map(inlineStyleRange => [
            inlineStyleRange.index,
            inlineStyleRange.index + inlineStyleRange.length,
        ])
        if (!isRangeCovered(range, ranges)) return false
    }

    return true
}

/**
 * @internal
 */
export function getReactStylesFromDraft(inlineStyleRanges: string[]): React.CSSProperties {
    // TODO: This function below needs to be split up between draft and vanilla
    return getStylesFromDraft(draftStyleDefinitions, inlineStyleRanges)
}

/**
 * Return the plain text for a given draft data structure, with an optional delimiter.
 * @internal
 */
export function getDraftText(content: RawDraftContentState, join = "") {
    return content.blocks.map(b => b.text).join(join)
}

/**
 * Return the draft styles filtered by a specific prefix for an optional character range.
 * @internal
 */
export function getDraftStylesWithPrefix(
    content: RawDraftContentState,
    prefix?: DraftPrefix,
    range?: Range,
    stopAtFirst = false
): string[] {
    return getDraftStyleRangesWithPrefix(content, prefix, range, stopAtFirst).map(styleRange => styleRange.style)
}

/**
 * Returns the range for the style to insert at the next caret insertion. This is always the previous character, except for the beginning (first character) and beyond the last character (last character)
 * @internal
 */
export function getDraftRangeAtCaretIndex(content: RawDraftContentState, index: number): Range {
    const text = getDraftText(content)
    if (index === 0) return [0, 1]
    if (index > text.length) return getDraftRangeAtCaretIndex(content, text.length)
    return [index - 1, index]
}

export function getDraftRange(content: RawDraftContentState): Range {
    return [0, getDraftText(content).length - 1]
}

type RawDraftInlineStyleRangeWithIndex = RawDraftInlineStyleRange & { index: number }

/**
 * Return all the draft styles in this text range. If the range is collapsed, we assume it's the caret position.
 * @internal
 */
function getDraftStyleRangesWithPrefix(
    content: RawDraftContentState,
    prefix?: DraftPrefix,
    range?: Range,
    stopAtFirst = false,
    modify?: (inlineStyle: RawDraftInlineStyleRangeWithIndex) => RawDraftInlineStyleRange
): RawDraftInlineStyleRangeWithIndex[] {
    // Use the full range if no range was given
    range = range || getDraftRange(content)

    // If this range is collapsed, we assume we are dealing with the caret position
    if (isRangeCollapsed(range)) {
        range = getDraftRangeAtCaretIndex(content, range[0])
    }

    // Make sure the range is sorted
    range = getSortedRange(range)

    let blockRangeIndex = 0
    const results: RawDraftInlineStyleRangeWithIndex[] = []
    for (const block of content.blocks) {
        if (block.text.length === 0) continue
        // We can stop if the block range exceeds the requested range
        if (blockRangeIndex > Math.max(...range)) return results

        for (let inlineStyleIndex = 0; inlineStyleIndex < block.inlineStyleRanges.length; inlineStyleIndex++) {
            const inlineStyle = block.inlineStyleRanges[inlineStyleIndex]

            // If a prefix filter was given, skip if this is not a match
            if (prefix && !inlineStyle.style.startsWith(prefix)) continue

            // Calcluate the range for this inline style so we can compare it
            const inlineStyleRangeIndex = blockRangeIndex + inlineStyle.offset
            const inlineStyleRange: Range = [inlineStyleRangeIndex, inlineStyleRangeIndex + inlineStyle.length]

            // See if the requested range overlaps with this style range and add to results
            if (isRangeOverlappingRange(range, inlineStyleRange)) {
                const inlineStyleWithIndex = { index: inlineStyleRangeIndex, ...inlineStyle }

                results.push(inlineStyleWithIndex)

                if (modify) {
                    block.inlineStyleRanges[inlineStyleIndex] = modify(inlineStyleWithIndex)
                }

                // If we're just looking for a first match, we can stop here
                if (stopAtFirst) break
            }
        }
        blockRangeIndex += block.text.length
    }

    return results
}

/**
 * @internal
 */
export function getDraftReplacedStyles(styles: string[], replacements: string[]): string[] {
    return styles.map(style => {
        const [prefix, value] = style.split(":")
        for (const replacement of replacements) {
            const [replacementPrefix, replacementValue] = replacement.split(":")
            if (value && prefix.toUpperCase() === replacementPrefix.toUpperCase()) {
                return `${prefix.toUpperCase()}:${replacementValue}`
            }
        }
        return style
    })
}

/**
 * Return the text range for a give draft block by key.
 * @internal
 */
function getDraftBlockRange(content: RawDraftContentState, key: string): Range | undefined {
    let index = 0

    for (const block of content.blocks) {
        if (block.key === key) {
            return [index, index + block.text.length]
        }
        index += block.text.length
    }
}

/**
 * Return the text range for a give draft selection object.
 * @internal
 */
export function getDraftRangeFromSelection(
    content: RawDraftContentState,
    selection: SelectionState
): Range | undefined {
    const rangeA = getDraftBlockRange(content, selection.getAnchorKey())
    const rangeB = getDraftBlockRange(content, selection.getFocusKey())

    if (rangeA && rangeB) {
        const a = rangeA[0] + selection.getAnchorOffset()
        const b = rangeB[0] + selection.getFocusOffset()
        const range: Range = [Math.min(a, b), Math.max(a, b)]
        return range
    }
}

/**
 * Return a new draft content structure with the given text and styles applied. If there is no text contents, the styles get set in the `data.emptyStyle` property on the block so they can get picked up when text insertion is started.
 * @internal
 */
export function getDraftContent(text?: string, styles: string[] = []): RawDraftContentState {
    return {
        blocks: (text || "").split("\n").map(line => getDraftContentBlock(line, styles)),
        entityMap: {},
    }
}

function randomString(n: number, characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789") {
    let result = ""
    const charactersLength = characters.length
    for (let i = 0; i < n; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

/**
 * Return a new draft content block with the given text and styles applied.
 * @internal
 */
function getDraftContentBlock(text: string, styles: string[]): RawDraftContentBlock {
    return {
        // TODO: could this just be an ever incrementing number instead?
        key: randomString(5), // Every
        text: text,
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: styles.map(
            style =>
                ({
                    offset: 0,
                    length: text.length,
                    style: style,
                } as any)
        ),
        entityRanges: [],
        // When the text is empty, make sure to set the “emptyStyle” data, so
        // the editor knows what style to give newly typed text
        data: text.length ? {} : { emptyStyle: styles },
    }
}

/**
 * Return the styles to apply if the styles cannot be read from text (when no text was added). Returns for the first block.
 * @internal
 */
export function getDraftEmptyStyles(content: RawDraftContentState, blockIndex: number = 0): string[] {
    if (!content.blocks.length) return []
    const emptyStyle = getDraftBlockEmptyStyles(content.blocks[0])
    if (emptyStyle.length) return emptyStyle
    return []
}

/**
 * Return the styles to apply if the styles cannot be read from text (when no text was added).
 * @internal
 */
function getDraftBlockEmptyStyles(block: RawDraftContentBlock): string[] {
    if (!block.data || !block.data["emptyStyle"]) return []
    const emptyStyle = block.data["emptyStyle"] as string[]
    return emptyStyle
}

/**
 * @internal
 */
export function getDraftFirstTextAlignment(content: RawDraftContentState): string | undefined {
    return getReactStylesFromDraft(getDraftStylesWithPrefix(content, "ALIGN", undefined, true)).textAlign
}
