import { getShapeNodeRecord } from "./ShapeNodeRecord"
import { spikesDefaults } from "document/models/CanvasTree/traits/Spikes"
import { radiusDefaults } from "document/models/CanvasTree/traits/Radius"
import { memoRecord } from "../memoRecord"

export const getStarShapeRecord = memoRecord(() => {
    return {
        ...getShapeNodeRecord(),
        ...radiusDefaults,
        ...spikesDefaults,
    }
})
