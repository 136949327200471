export const enum TourId {
    LayersTab = "LayersTab",
    AssetsTab = "AssetsTab",
    CodeTab = "CodeTab",
    PackagesTab = "PackagesTab",
    InsertMenuIcon = "InsertMenuIcon",
    BarLayout = "BarLayout",
    BarPrototyping = "BarPrototyping",
    CommentsIcon = "CommentsIcon",
    InviteMembers = "InviteMembers",
    ShareProject = "ShareProject",
    PreviewButton = "PreviewButton",
}
