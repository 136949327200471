import * as React from "react"
import { motion } from "framer-motion"
import { colors } from "../tokens"

function Cursor() {
    return (
        <motion.div
            animate={{ opacity: [1, 0] }}
            transition={{
                times: [0.4, 0.5],
                repeat: Infinity,
                repeatType: "reverse",
                duration: 0.5,
            }}
            style={{
                width: 3,
                right: 4,
                top: 6,
                bottom: 6,
                borderRadius: 2,
                background: colors.animationForground,
                position: "absolute",
            }}
        />
    )
}

export const AnimationText = React.memo(function AnimationText() {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: 40,
                height: 40,
                position: "relative",
                borderRadius: 8,
                background: colors.animationForgroundLight,
            }}
        >
            <Cursor />
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                <path
                    d="M 13 18 C 13 19.105 12.105 20 11 20 L 9 20 C 7.895 20 7 19.105 7 18 L 7 6 L 2 6 C 0.895 6 0 5.105 0 4 L 0 2 C 0 0.895 0.895 0 2 0 L 18 0 C 19.105 0 20 0.895 20 2 L 20 4 C 20 5.105 19.105 6 18 6 L 13 6 Z"
                    fill={colors.animationForground}
                ></path>
            </svg>
        </div>
    )
})
