// A subset of the FeatureSet in Vekter. We probably want this to be a shared type eventually.
let featureSet: { isOn(name: string): boolean } | undefined

export function isOn(name: string) {
    return featureSet?.isOn(name) ?? false
}

// This function is used in initializeRuntime.ts.
export function setFeatureSet(fs: typeof featureSet) {
    featureSet = fs
}
