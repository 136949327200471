import * as React from "react"
import { createPortal } from "react-dom"
import { cx } from "linaria"
import type { OverrideType } from "./types"
import * as styles from "./Modal.styles"
import { isMacOS } from "./utils/isMacOS"
import { customScrollbar } from "./CustomScrollbar.styles"
import { FocusTrap } from "./FocusTrap"

export type ModalProps = OverrideType<
    React.ComponentPropsWithoutRef<"div">,
    {
        onDismiss: () => void
        visible?: boolean
        children?: React.ReactNode
        focusTrapEnabled?: boolean
        hasBackdrop?: boolean
        noPointerEvents?: boolean
    }
>

export const Modal = React.forwardRef<HTMLDivElement, ModalProps>(function Modal(
    {
        visible,
        onDismiss: dismiss,
        children,
        focusTrapEnabled = true,
        style,
        noPointerEvents,
        className,
        hasBackdrop,
        ...rest
    },
    forwardedRef
) {
    const keyDownHandler = React.useCallback(
        (event: React.KeyboardEvent) => {
            // escape
            if (event.keyCode === 27) {
                dismiss()
            }
        },
        [dismiss]
    )

    if (!visible) return null

    return createPortal(
        <div
            className={cx(
                styles.modalBackdrop,
                hasBackdrop && styles.hasBackdrop,
                noPointerEvents && styles.modalWithoutPointerEvents
            )}
            onClick={dismiss}
            onKeyDown={keyDownHandler}
        >
            <FocusTrap
                focusTrapActive={focusTrapEnabled}
                ref={forwardedRef}
                className={cx(styles.modal, className, !isMacOS && customScrollbar)}
                style={style}
                onClick={stopPropagation}
                {...rest}
            >
                {children}
            </FocusTrap>
        </div>,
        document.body // TODO: make it `window.top.document.body` once we can style the modal in Framer Web
    )
})

function stopPropagation(event: React.MouseEvent) {
    event.stopPropagation()
}
