import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springAnimation } from "./utils"
import { colors } from "../tokens"

const pageComponentStyle: React.CSSProperties = {
    width: 30,
    height: 48,
    borderRadius: 4,
    position: "absolute",
    left: 24,
    top: 6,
}

const pageComponentContentStyle: React.CSSProperties = {
    width: 22,
    height: 40,
    borderRadius: 2,
    background: colors.animationForground,
    position: "absolute",
    top: 4,
}

const pageStyle: React.CSSProperties = {
    width: 22,
    height: 22,
    borderRadius: 3,
    background: colors.animationForgroundLight,
    position: "absolute",
    left: 66,
}

const pageContentStyle: React.CSSProperties = {
    width: 14,
    height: 14,
    borderRadius: 2,
    background: colors.animationForground,
    position: "absolute",
    left: 4,
    top: 4,
}

const connectorStyle: React.CSSProperties = {
    position: "absolute",
    left: 54,
}

export function AnimationPage() {
    const fullFrame = useAnimation()
    const pageComponent = useAnimation()
    const pageConnector = useAnimation()
    const pageContentOne = useAnimation()
    const pageContentTwo = useAnimation()
    const pageOne = useAnimation()
    const pageTwo = useAnimation()
    const connectorOne = useAnimation()
    const connectorTwo = useAnimation()

    async function animation() {
        fullFrame.set({ opacity: 1 })
        pageComponent.set({ scale: 0 })
        pageContentOne.set({ scale: 0, left: 4 })
        pageContentTwo.set({ scale: 0.4, opacity: 0.7, left: 26 })
        pageOne.set({ scale: 0 })
        pageTwo.set({ scale: 0 })
        connectorOne.set({ pathLength: 0 })
        connectorTwo.set({ pathLength: 0 })

        await pageComponent.start({
            scale: 1,
            originX: 0,
            originY: 0,
            transition: springAnimation,
        })

        await Promise.all([
            pageOne.start({
                scale: 1,
                originX: 0,
                originY: 0,
                transition: springAnimation,
            }),
            pageTwo.start({
                scale: 1,
                originX: 0,
                originY: 0,
                transition: { ...springAnimation.default, delay: 0.2 },
            }),
            connectorOne.start({
                pathLength: 1,
                transition: { duration: 0.2, delay: 0.6 },
            }),
            connectorTwo.start({
                pathLength: 1,
                transition: { duration: 0.2, delay: 0.7 },
            }),
            pageContentOne.start({
                scale: 1,
                transition: { ...springAnimation.default, delay: 0.6 },
            }),
        ])

        await Promise.all([
            pageContentOne.start({
                left: -22,
                transition: { duration: 0.3 },
            }),
            pageContentTwo.start({
                scale: 1,
                opacity: 1,
                left: 4,
                transition: { duration: 0.3 },
            }),
        ])

        return await fullFrame.start({
            opacity: 0,
            transition: { delay: 0.4, duration: 1 },
        })
    }

    useLoop(animation)

    return (
        <motion.div
            animate={fullFrame}
            style={{
                width: 120,
                height: 60,
                position: "relative",
            }}
        >
            <motion.div
                animate={pageComponent}
                style={{
                    ...pageComponentStyle,
                    background: colors.animationForgroundLight,
                }}
            >
                <motion.div
                    animate={pageConnector}
                    style={{
                        width: 4,
                        height: 10,
                        borderRadius: 30,
                        background: colors.animationForground,
                        position: "absolute",
                        left: 28,
                        top: 19,
                    }}
                />
            </motion.div>
            <motion.div
                style={{
                    ...pageComponentStyle,
                    overflow: "hidden",
                }}
            >
                <motion.div animate={pageContentOne} style={pageComponentContentStyle} />
                <motion.div animate={pageContentTwo} style={pageComponentContentStyle} />
            </motion.div>
            <motion.div
                animate={pageOne}
                style={{
                    ...pageStyle,
                    top: 6,
                }}
            >
                <motion.div style={pageContentStyle} />
            </motion.div>
            <motion.div
                animate={pageTwo}
                style={{
                    ...pageStyle,
                    top: 32,
                }}
            >
                <motion.div style={pageContentStyle} />
            </motion.div>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12.53" style={{ ...connectorStyle, top: 17 }}>
                <motion.path
                    animate={connectorOne}
                    d="M 0 12.515 C 0 12.515 6 13.14 6 6.265 C 6 -0.61 12 0.015 12 0.015"
                    fill="transparent"
                    stroke={colors.animationForground}
                    stroke-linecap="round"
                ></motion.path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12.53" style={{ ...connectorStyle, top: 30 }}>
                <motion.path
                    animate={connectorTwo}
                    d="M 0 0.015 C 0 0.015 6 -0.61 6 6.265 C 6 13.14 12 12.515 12 12.515"
                    fill="transparent"
                    stroke={colors.animationForground}
                    stroke-linecap="round"
                ></motion.path>
            </svg>
        </motion.div>
    )
}
