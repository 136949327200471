import type { CanvasNode } from ".."
import type { Rect } from "framer"
import { withPosition } from "../traits/Position"
import { withSize } from "../traits/Size"
import { isPinnable } from "../traits/Pins"
import { withDOMLayout } from "../traits/DOMLayout"

export function getGroundNodeRect(node: CanvasNode): Rect {
    if (node.cache.groundNodeRect) {
        return node.cache.groundNodeRect
    }

    const rect: Rect = { x: 0, y: 0, width: 0, height: 0 }

    if (withPosition(node)) {
        rect.x = node.x
        rect.y = node.y
    }

    if (withSize(node)) {
        rect.width = node.width
        rect.height = node.height
    }

    if (withDOMLayout(node) && node.usesDOMRectCached()) {
        // Can't use the getDOMRect() getter here, because it uses this function
        // internally to get ground node position
        if (node.cache.domRect) {
            rect.width = node.cache.domRect.width
            rect.height = node.cache.domRect.height
        }
    }

    if (isPinnable(node)) {
        rect.x = node.left ? node.left : 0
        rect.y = node.top ? node.top : 0
    }

    return rect
}
