import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springAnimationDelay } from "./utils"
import { colors } from "../tokens"

const width = 50
const height = 50

export const AnimationInsert = React.memo(function AnimationInsert() {
    const toggle = useAnimation()
    const video = useAnimation()
    const slider = useAnimation()

    async function animation() {
        await Promise.all([
            toggle.start({
                opacity: 0,
                scale: 0,
                transition: springAnimationDelay,
            }),
            video.start({
                opacity: 0,
                scale: 0,
                transition: springAnimationDelay,
            }),
            slider.start({
                opacity: 1,
                scale: 0.8,
                transition: springAnimationDelay,
            }),
        ])
        await Promise.all([
            toggle.start({
                opacity: 1,
                scale: 0.8,
                transition: springAnimationDelay,
            }),
            video.start({
                opacity: 0,
                scale: 0,
                transition: springAnimationDelay,
            }),
            slider.start({
                opacity: 0,
                scale: 0,
                transition: springAnimationDelay,
            }),
        ])
        return Promise.all([
            toggle.start({
                opacity: 0,
                scale: 0,
                transition: springAnimationDelay,
            }),
            video.start({
                opacity: 1,
                scale: 0.8,
                transition: springAnimationDelay,
            }),
            slider.start({
                opacity: 0,
                scale: 0,
                transition: springAnimationDelay,
            }),
        ])
    }

    useLoop(animation)

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                width: width,
                height: height,
                borderRadius: 8,
                background: colors.animationBackgroundLight,
            }}
        >
            <motion.div
                animate={toggle}
                style={{
                    position: "absolute",
                    opacity: 1,
                    scale: 0,
                    left: 9,
                    top: 14,
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="22">
                    <path
                        d="M 11 0 L 23 0 C 29.075 0 34 4.925 34 11 C 34 17.075 29.075 22 23 22 L 11 22 C 4.925 22 0 17.075 0 11 C 0 4.925 4.925 0 11 0 Z M 14 11 C 14 15.971 18.029 20 23 20 C 27.971 20 32 15.971 32 11 C 32 6.029 27.971 2 23 2 C 18.029 2 14 6.029 14 11 Z"
                        fill={colors.animationForground}
                    ></path>
                </svg>
            </motion.div>
            <motion.div
                animate={video}
                style={{
                    position: "absolute",
                    opacity: 0,
                    scale: 0,
                    left: 17,
                    top: 12,
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="26">
                    <path
                        d="M 3.053 1.89 C 1.72 1.065 0 2.023 0 3.59 L 0 22.41 C 0 23.977 1.72 24.935 3.053 24.11 L 18.253 14.701 C 19.516 13.919 19.516 12.081 18.253 11.299 Z"
                        fill={colors.animationForground}
                    ></path>
                </svg>
            </motion.div>
            <motion.div animate={slider} style={{ position: "absolute", marginTop: 1, opacity: 0, scale: 0 }}>
                <svg width="60" height="60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        opacity=".5"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M41.83 32H48a2 2 0 100-4h-6.17a12.07 12.07 0 010 4z"
                        fill={colors.animationForground}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.17 28H12a2 2 0 100 4h6.17a12.08 12.08 0 010-4z"
                        fill={colors.animationForground}
                    />
                    <path d="M30 20a10 10 0 110 20 10 10 0 010-20z" fill={colors.animationForground} />
                </svg>
            </motion.div>
        </div>
    )
})
