import { environment } from "../environment"
import { createFeatureComponent, FeatureSet, useCondition } from "./FeatureSet"

// Copied from Library → src/utils/environment.ts to avoid pulling in Library in this file (which causes build issues).
const isPhone = /(mobi)/i.test(environment.userAgent)
const isFramerX = environment.userAgent.includes("FramerX")

export function isAutomationEditorSession() {
    // See @framerjs/framer-automation
    return global["__isAutomationEditorSession"] === true
}

const supportedPlatforms = ["studio", "web"] as const

export type PlatformName = keyof ReturnType<typeof getConfig>
export type PlatformType = typeof supportedPlatforms[number]

function getConfig(current: PlatformType, allowPlatformInspection = false) {
    const config = {
        current,
        // Quick-fix for https://github.com/framer/company/issues/20249
        supportsLoadingBar: current === "web",
        supportsCopyCode: current === "studio",
        supportsFinder: current === "studio",
        disablePublishing: current !== "studio",
        supportsFeedback: current !== "studio",
        // TODO: Create a specific supportsWebExport property when exporting is enabled in Web.
        supportsExport: current === "studio",
        supportsExternalCodeEditor: current === "studio",
        supportsViewOnlyMode: current !== "studio",
        // Automation sessions run as "studio", but don't use the project server.
        usesProjectServer: current === "studio" && !isAutomationEditorSession(),
        supportsWebHelpOptions: current !== "studio",
        // Framer Desktop support will be added in https://github.com/framer/company/issues/15933
        supportsFigmaImporter: current !== "studio",
        showsWebShortcuts: current !== "studio",
        showsFloatingHelpMenu: current !== "studio",
        supportsProjectBar: current !== "studio",
        supportsPreviewWithinEditor: current !== "studio",
        supportsExternalPreviewWindow: current === "studio",
        supportsCustomFonts: current !== "studio",
        supportsTeamFonts: current !== "studio",
        usesAssetService: current !== "studio" || isAutomationEditorSession(),
        supportsVariantsAndVariables: current !== "studio",
        supportsStatusBar: current !== "studio",
        supportsImageUpload: current !== "studio",
    }
    if (allowPlatformInspection) {
        if (isPhone) {
            config.supportsPreviewWithinEditor = false
        }
        if (!isFramerX || environment.isTest) {
            config.supportsExternalPreviewWindow = false
        }
    }
    return config
}

const currentPlatform = process.env.PLATFORM ?? "unknown"

// Debugging
if (typeof window !== "undefined") window["platform"] = currentPlatform

function isSupportedPlatform(p: unknown): p is PlatformType {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return supportedPlatforms.includes(p as any)
}

// Throw to avoid subtle environment bugs.
if (!isSupportedPlatform(currentPlatform)) {
    throw Error(`${currentPlatform} is not a supported platform`)
}

// Initialize platform with a configuration based on the build environment.
const platformFeatureSet = new FeatureSet(getConfig(currentPlatform))
// Update the platform configuration at runtime (this will re-render after hydration has occurred).
platformFeatureSet.update(getConfig(currentPlatform, true))

// Export APIs to test the platform.
export const platform: Omit<typeof platformFeatureSet, "get"> = platformFeatureSet
export const usePlatformIsOn = (name: PlatformName) =>
    useCondition(platformFeatureSet, name, variant => variant === "on")
export const Platform = createFeatureComponent(platformFeatureSet)
