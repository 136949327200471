import type { CanvasNode } from "document/models/CanvasTree"
import type { BoxShadowProperties } from "framer"
import type { WithBoxShadow } from "../BoxShadow"
import { shouldRenderShadowUsingFilter } from "document/models/CanvasTree/traits/utils/wantsFilterShadow"

export function collectBoxShadowPropsForNode(node: CanvasNode & WithBoxShadow, props: Partial<BoxShadowProperties>) {
    if (!node.boxShadows) return

    const onlyInsetShadows = shouldRenderShadowUsingFilter(node)
    if (onlyInsetShadows) {
        props.shadows = node.boxShadows.filter(shadow => shadow.inset)
    } else {
        props.shadows = node.boxShadows
    }
}
