import { assert } from "@framerjs/shared"

import type { CanvasNode } from "./CanvasNode"
import type { CanvasTree } from "../CanvasTree"
import {
    IsReplica,
    IsMaster,
    ReplicaOverrides,
    ReplicaNodeOverrides,
    isReplica,
    isMaster,
    HIDDEN_MASTER,
    withTemplate,
} from "../traits/Template"
import { withStyledText, WithStyledText } from "../traits/StyledText"
import type { AnyNodeUpdate, FrameNode, NodeID } from "document/models/CanvasTree"
import { WithPins, isPinnable } from "document/models/CanvasTree/traits/Pins"
import { StyledTextDraft } from "../../StyledText/StyledTextDraft"
import { isEqual } from "utils/isEqual"
import { isGestureVariant, isTopLevelVariant, IsVariant, isVariant, withGesture } from "../traits/Variant"
import { getPatchedEditor } from "document/models/StyledText/DraftEditor"
import { hasStackLayout } from "../traits/StackLayout"
import { prefixControlKey } from "../traits/utils/codeComponentProps"

const hasOwnProperty = Object.prototype.hasOwnProperty

/**
 * Generate a mix id for the replica child node
 * @param replicaId Replica root
 * @param originalId Id of the original node in the master
 */
export function generateReplicaChildId(replicaId: NodeID, originalId: NodeID): NodeID {
    return replicaId + originalId
}

export function isGeneratedReplicaChildId(id: NodeID, originalid: NodeID): boolean {
    return id.length > originalid.length && id.endsWith(originalid)
}

export function duplicatedFromForNode(duplicatedFrom: string[] | null | undefined, newId: NodeID): string[] {
    if (duplicatedFrom) {
        if (!newId) return duplicatedFrom
        return duplicatedFrom[duplicatedFrom.length - 1] === newId ? duplicatedFrom : [...duplicatedFrom, newId]
    }

    return [newId]
}

export const ignoredOverrideKeys = {
    mutable: true,
    cache: true,
    update: true,
    children: true,
    id: true,
    parentid: true,
    originalid: true,
    replicaInfo: true,
    isMaster: true,
    isExternalMaster: true,
    _deleted: true,
}

/** Turns {_deleted: ["property"]} into {property: undefined}. */
function objectFromOverrides(override: ReplicaNodeOverrides | undefined): { [key: string]: unknown } | undefined {
    if (!override) return undefined
    const hasOriginalid = !hasOwnProperty.call(override, "originalid")
    const hasDeleted = !hasOwnProperty.call(override, "_deleted")
    if (!(hasOriginalid || hasDeleted)) return override

    const { originalid: _, _deleted, ...result } = override
    if (_deleted) {
        for (const key of _deleted) {
            /**
             * For each _deleted key, add [key]: undefined to the overrides
             * so that the node is built with an undefined value for that property, instead of the master's value.
             *
             * However, in the case where a replica has inherited some _deleted properties that it overrides,
             * only set [key]: undefined if the replica does not override the property.
             * This can happen when overrides from two replicas are merged when using replica inheritance with `inheritsFrom`.
             */
            const safeToUnsetDeletedKey = !(key in result)
            if (safeToUnsetDeletedKey) result[key] = undefined
        }
    }
    return result
}

/** Turns {property: undefined} into {_deleted: ["property"]}. */
function overridesFromObject(override: { [key: string]: unknown }): ReplicaNodeOverrides {
    let deleted: Set<string> | undefined
    let result: ReplicaNodeOverrides | undefined
    for (const [key, value] of Object.entries(override)) {
        if (value !== undefined) continue

        if (!deleted || !result) {
            deleted = new Set<string>()
            result = { ...override }
        }
        deleted.add(key)
        delete result[key]
    }

    // If there were no undefined values, we can return the object as is.
    if (!result || !deleted) return override
    result._deleted = Array.from(deleted)
    return result
}

function mergeDeletedKeys(deletedInheritedOverrides: string[], deletedOverrides?: string[]) {
    if (deletedOverrides) {
        return [...deletedInheritedOverrides, ...deletedOverrides]
    } else {
        return deletedInheritedOverrides
    }
}

function hasInheritedDeletedKeys(
    deletedNodeOverrides: ReplicaNodeOverrides["_deleted"]
): deletedNodeOverrides is string[] {
    return deletedNodeOverrides !== undefined
}

function mergeNodeOverrides(
    overrides: ReplicaOverrides,
    inheritedOverrides: ReplicaOverrides | undefined,
    nodeId: NodeID
) {
    const inheritedDeletedOverrides = inheritedOverrides?.[nodeId]?._deleted

    let mergedDeletedProperties = {}

    if (inheritedOverrides && hasInheritedDeletedKeys(inheritedDeletedOverrides)) {
        mergedDeletedProperties = { _deleted: mergeDeletedKeys(inheritedDeletedOverrides, overrides[nodeId]?._deleted) }
    }

    return Object.assign({}, inheritedOverrides?.[nodeId], overrides[nodeId], mergedDeletedProperties)
}

function mergeOverrides(overrides: ReplicaOverrides, inheritedOverrides?: ReplicaOverrides | null) {
    if (!inheritedOverrides) return overrides

    const originalids = new Set([...Object.keys(overrides), ...Object.keys(inheritedOverrides || {})])

    const mergedOverrides = {}
    for (const originalid of originalids) {
        mergedOverrides[originalid] = mergeNodeOverrides(overrides, inheritedOverrides, originalid)
    }

    return mergedOverrides
}

function resetReplicaStackEnabled(nodeInMasterVariant: CanvasNode, nodeInReplicaVariant: CanvasNode) {
    if (!hasStackLayout(nodeInMasterVariant) || !hasStackLayout(nodeInReplicaVariant)) return
    nodeInReplicaVariant.stackEnabled = nodeInMasterVariant.stackEnabled
}

const CHILDREN_CONTROL_KEY = prefixControlKey("children")
const isCodeComponentNodeWithControlChildren = (node: CanvasNode) => {
    return CHILDREN_CONTROL_KEY in node
}

function resetReplicaCodeComponentChildren(nodeInMasterVariant: CanvasNode, nodeInReplicaVariant: CanvasNode) {
    if (!isCodeComponentNodeWithControlChildren(nodeInMasterVariant)) return
    nodeInReplicaVariant[CHILDREN_CONTROL_KEY] = nodeInMasterVariant[CHILDREN_CONTROL_KEY]
}

export function updateNodeWithMaster(
    existing: CanvasNode,
    master: CanvasNode,
    overrides: ReplicaNodeOverrides,
    isVariantReplica: boolean
): void {
    existing = existing.asMutable()
    const keep: Partial<Record<keyof AnyNodeUpdate, unknown>> = {
        id: existing.id,
        cache: existing.cache,
        mutable: true,
        update: existing.update + 1,
        parentid: existing.parentid,
        originalid: existing.originalid,
        duplicatedFrom: existing.duplicatedFrom,
        children: existing.children,
        replicaInfo: existing.replicaInfo,
    }
    if (withGesture(existing)) {
        keep.gesture = existing.gesture
    }
    const existingName = existing.name
    if (withTemplate(existing)) {
        keep.isMaster = false
        keep.isExternalMaster = null
    }
    Object.assign(existing, master, keep, objectFromOverrides(overrides))
    if (isVariant(existing)) existing.name = existingName
    if (isVariantReplica) {
        resetReplicaStackEnabled(master, existing)
        resetReplicaCodeComponentChildren(master, existing)
    }
}

function updateVariantName(
    replica: CanvasNode & IsVariant,
    overrides: ReplicaOverrides,
    master: CanvasNode & IsMaster
) {
    if (isGestureVariant(replica)) {
        replica.name = null
    } else if (isTopLevelVariant(replica)) {
        replica.name = overrides[master.id]?.name ?? null
    }
}

function rebuildTemplate({
    tree,
    ownerid,
    parentid,
    originalNode,
    overrides,
    previousChildren,
    fromNode,
    inheritsFromReplica,
    recordDuplicatedFrom,
    resetUnsupportedProperties,
}: {
    tree: CanvasTree
    ownerid: NodeID
    parentid: NodeID
    originalNode: CanvasNode
    overrides: ReplicaOverrides
    previousChildren: Set<NodeID>
    fromNode?: CanvasNode
    inheritsFromReplica?: boolean
    recordDuplicatedFrom?: boolean
    resetUnsupportedProperties?: boolean
}): CanvasNode {
    const id = generateReplicaChildId(ownerid, originalNode.id)

    // When we copy/paste a design component we reset it's children's ids so they are not mix ids (to solve some bugs).
    // In that case, we pre-set it's duplicatedFrom to include it's original id.
    // Because of that, we need to detect if a child id is ever not a mixID, and in that case,
    // use it's preset duplicatedFrom instead of it's useless reset id.

    let duplicatedFrom = undefined

    if (recordDuplicatedFrom) {
        if (fromNode && fromNode?.id !== originalNode.id && isGeneratedReplicaChildId(fromNode?.id, originalNode.id)) {
            duplicatedFrom = duplicatedFromForNode(fromNode.duplicatedFrom, fromNode?.id)
        } else if (fromNode && fromNode?.id !== originalNode.id) {
            duplicatedFrom = fromNode?.duplicatedFrom
        } else {
            duplicatedFrom = duplicatedFromForNode(originalNode.duplicatedFrom, originalNode.id)
        }
    }

    // make sure we have latest master
    if (originalNode.cache.future) {
        originalNode = originalNode.cache.future
    }

    // if there is an existing node, update it if needed
    let existing = tree.get(id)
    if (existing) {
        assert(existing.originalid === originalNode.id, "`templateid` must match master’s `id`", id)

        // no change, mark all existing children as seen
        if (!originalNode.mutable && !inheritsFromReplica) {
            existing.walk(n => {
                if (n === existing) return
                previousChildren.delete(n.id)
            })
            return existing
        }

        existing = existing.asMutable()
        const keep: Partial<Record<keyof AnyNodeUpdate, unknown>> = {
            id: existing.id,
            cache: existing.cache,
            mutable: true,
            update: existing.update + 1,
            parentid: parentid,
            originalid: existing.originalid,
            duplicatedFrom,
            children: rebuildChildren({
                tree,
                ownerid,
                parentid: existing.id,
                children: originalNode.children,
                overrides,
                previousChildren,
                fromChildren: fromNode?.children,
                inheritsFromReplica,
                recordDuplicatedFrom,
                resetUnsupportedProperties,
            }),
        }
        if (withTemplate(existing)) {
            keep.isMaster = false
            keep.isExternalMaster = null
            keep.replicaInfo = null
        }

        Object.assign(existing, originalNode, keep, objectFromOverrides(overrides[originalNode.id]))

        if (isVariant(existing) && isMaster(originalNode)) updateVariantName(existing, overrides, originalNode)
        if (resetUnsupportedProperties) {
            resetReplicaStackEnabled(originalNode, existing)
            resetReplicaCodeComponentChildren(originalNode, existing)
        }

        return existing
    }

    // if it doesn't exist, create a node
    const keep: any = {}
    if (withTemplate(originalNode)) {
        keep.isMaster = false
        keep.isExternalMaster = null
        keep.replicaInfo = null
    }
    const clone = originalNode.clone({
        id,
        parentid,
        originalid: originalNode.id,
        duplicatedFrom,
        ...keep,
        children: rebuildChildren({
            tree,
            ownerid,
            parentid: id,
            children: originalNode.children,
            overrides,
            previousChildren,
            fromChildren: fromNode?.children,
            inheritsFromReplica,
            recordDuplicatedFrom,
            resetUnsupportedProperties,
        }),
        ...objectFromOverrides(overrides[originalNode.id]),
    })
    if (resetUnsupportedProperties) {
        resetReplicaStackEnabled(originalNode, clone)
        resetReplicaCodeComponentChildren(originalNode, clone)
    }
    return clone
}

function rebuildChildren({
    tree,
    ownerid,
    parentid,
    children,
    overrides,
    previousChildren,
    fromChildren,
    inheritsFromReplica,
    recordDuplicatedFrom = true,
    resetUnsupportedProperties = false,
}: {
    tree: CanvasTree
    ownerid: NodeID
    parentid: NodeID
    children: CanvasNode[] | undefined
    overrides: ReplicaOverrides
    previousChildren: Set<NodeID>
    fromChildren?: CanvasNode[]
    inheritsFromReplica?: boolean
    recordDuplicatedFrom?: boolean
    resetUnsupportedProperties?: boolean
}) {
    if (!children) return
    // Since Design Component's can't have differing trees, mapping fromChildren by index should be stable.
    return children.map((n, i) => {
        const child = rebuildTemplate({
            tree,
            ownerid,
            parentid,
            originalNode: n,
            overrides,
            previousChildren,
            fromNode: fromChildren?.[i],
            inheritsFromReplica,
            recordDuplicatedFrom,
            resetUnsupportedProperties,
        })
        if (previousChildren.has(child.id)) {
            previousChildren.delete(child.id)
        } else if (previousChildren.has(parentid)) {
            tree.unsafeInsertNode(child, parentid)
        }
        return child
    })
}

function createDuplicatedFromForMasterOrNode(
    master: CanvasNode & IsMaster,
    fromNode?: CanvasNode,
    preset?: string[] | null
) {
    if (preset) return preset
    if (!fromNode) return duplicatedFromForNode(master.duplicatedFrom, master.id)
    return duplicatedFromForNode(fromNode.duplicatedFrom, fromNode.id)
}

function getLatestDuplicatedFromNode(tree: CanvasTree, fromNode?: CanvasNode, preset?: string[] | null) {
    if (fromNode) return fromNode
    if (preset) {
        return tree.getNode(preset[preset.length - 1])
    }
}

export namespace TemplateHelper {
    export function create<T extends CanvasNode>(
        tree: CanvasTree,
        master: T & IsMaster,
        options: {
            overrides?: ReplicaOverrides
            owner?: NodeID
            fromNode?: CanvasNode
            inheritsFrom?: NodeID
            duplicatedFrom?: string[] | null
        } = {}
    ): T & IsReplica {
        assert(master.isMaster, "Node is not a master node")
        const { overrides = {}, owner = tree.newId(), fromNode, inheritsFrom, duplicatedFrom } = options
        // Make sure the replica position is unlinked from the master
        if (isPinnable(master)) {
            const replicaOverrides = overrides[master.id]
            const { top, left, right, bottom, centerAnchorX, centerAnchorY } = master
            overrides[master.id] = { top, left, right, bottom, centerAnchorX, centerAnchorY, ...replicaOverrides }
        }

        // If the replica is created with a value for inheritsFrom,
        // we extend it's overrides with the overrides of the inherited replica.
        const inheritedReplica = inheritedReplicaNode(tree, inheritsFrom)
        const allOverrides = mergeOverrides(overrides, inheritedReplica?.replicaInfo.overrides)

        const recordDuplicatedFrom = !isVariant(master)
        const resetUnsupportedProperties = isVariant(master)

        // On Web, when a Design Component is duplicated, we immediately recreate it from a `change` object.
        // In that case, we just have the duplicatedFrom of the replica,
        // so we use that here, to get the full reference to the node.
        const replica = master.clone({
            id: owner,
            originalid: master.id,
            isMaster: false,
            isExternalMaster: null,
            replicaInfo: { master: master.id, overrides, inheritsFrom },
            duplicatedFrom: recordDuplicatedFrom
                ? createDuplicatedFromForMasterOrNode(master, fromNode, duplicatedFrom)
                : undefined,
            children: rebuildChildren({
                tree,
                ownerid: owner,
                parentid: owner,
                children: master.children,
                overrides: allOverrides,
                previousChildren: new Set(),
                fromChildren: getLatestDuplicatedFromNode(tree, fromNode, duplicatedFrom)?.children,
                inheritsFromReplica: !!inheritedReplica,
                recordDuplicatedFrom,
                resetUnsupportedProperties,
            }),
            ...objectFromOverrides(allOverrides[master.id]),
            name: isVariant(master) ? null : master.name,
        }) as T & IsReplica

        if (resetUnsupportedProperties) {
            resetReplicaStackEnabled(master, replica)
            resetReplicaCodeComponentChildren(master, replica)
        }

        if (master.cache.latest) {
            registerInInheritedNode(master, replica)
        }

        if (inheritedReplica?.cache.latest) {
            registerInInheritedNode(inheritedReplica, replica)
        }

        assert(replica.id === owner, "Replica must be owned by the tree")
        replica.walk(n => assert(n.originalid, "Node must have a original id"))
        return replica
    }

    function nodeDidLoad(tree: CanvasTree, node: CanvasNode, seen: Set<NodeID>, errors: string[]) {
        // Make sure that all dependent masters have been made mutable before
        // making the current master mutable. This ensures master will have
        // expanded their internal templates before they are used to expand
        // their own replicas.
        node.walkWithPredicate((n: CanvasNode & IsReplica) => {
            if (seen.has(n.id)) return false
            seen.add(n.id)
            if (!n.replicaInfo) return true
            const master = getMaster(tree, n)
            if (!master) return true

            nodeDidLoad(tree, master, seen, errors)
            master.asMutable(tree)
            registerInInheritedNode(master, n)

            expandReplicasOnLoad(tree, n, errors)
            return true
        })
    }

    function expandReplicasOnLoad(tree: CanvasTree, n: CanvasNode & IsReplica, errors: string[]) {
        if (!n.replicaInfo) return true
        const master = getMaster(tree, n)
        if (!master) return true
        const replica = n.asMutable()

        const inheritedReplica = inheritedReplicaNode(tree, replica.replicaInfo.inheritsFrom)
        if (inheritedReplica) registerInInheritedNode(inheritedReplica, n)

        // Capture the current futures list and length, so we can undo any unsafeInsertNode's.
        const futures = tree.unsafeGetFutures()
        const futureslength = futures.length
        try {
            withChanges(tree, replica, master, inheritedReplica)
        } catch {
            // If creating the template throws errors, don't just bail,
            // instead clear out and detatch the replica, and rewind the
            // tree.futures.
            errors.push(`${replica.id}: cannot build replica`)
            const r: CanvasNode = replica
            assert(r.children!.length === 0, "Must have empty children.")
            r.originalid = null
            r.replicaInfo = null
            for (let i = futureslength; i < futures.length; i++) {
                tree.unsafeRemoveId(futures[i].id)
            }
            // Remove the future nodes this operation added.
            futures.length = futureslength
        }
    }

    export function treeDidLoad(tree: CanvasTree, errors: string[]): CanvasTree {
        const seen = new Set<NodeID>()
        nodeDidLoad(tree, tree.root, seen, errors)
        return tree.commit()
    }

    export function registerInInheritedNode(
        masterOrReplica: CanvasNode & (IsMaster | IsReplica),
        replica: CanvasNode & IsReplica
    ) {
        isMaster(masterOrReplica)
            ? assert(
                  masterOrReplica.id === replica.replicaInfo.master,
                  "The provided master must be the replica's master"
              )
            : assert(
                  masterOrReplica.id === replica.replicaInfo.inheritsFrom,
                  "The provided replica must be the replica's inherited replica"
              )

        if (!masterOrReplica.cache.replicaInstances) {
            masterOrReplica.cache.replicaInstances = []
        }

        // for undo/redo we don't track replica instances going away, make sure not to add twice
        if (masterOrReplica.cache.replicaInstances.includes(replica.id)) return
        masterOrReplica.cache.replicaInstances.push(replica.id)
    }

    export function getMaster(tree: CanvasTree, replica: CanvasNode & IsReplica): CanvasNode & IsMaster {
        // TODO: Remove as any
        return tree.get(replica.replicaInfo.master) as any
    }

    export function replicaWithChanges(tree: CanvasTree, replica: CanvasNode & IsReplica) {
        const master = getMaster(tree, replica)
        if (!master) return
        const inheritedReplicaFuture = inheritedReplicaNode(tree, replica.replicaInfo.inheritsFrom)
        withChanges(tree, replica, master, inheritedReplicaFuture)
    }

    export function withChanges(
        tree: CanvasTree,
        replica: CanvasNode & IsReplica,
        master: CanvasNode & IsMaster,
        inheritedReplica: (CanvasNode & IsReplica) | null = null
    ) {
        const replicaHasChanges = !!replica.cache.future
        const masterHasChanges = !!master.cache.future
        const inheritedHasChanges = !!inheritedReplica?.cache.future

        // Update the replicaInfo.overrides if needed, before computing allOverrides
        if (replicaHasChanges && !inheritedHasChanges) {
            assert(replica === replica.cache.future)
            updateOverrides(replica)
        }

        const allOverrides = mergeOverrides(replica.replicaInfo.overrides, inheritedReplica?.replicaInfo.overrides)

        if (inheritedHasChanges && !masterHasChanges) {
            assert(inheritedReplica!.mutable, "Inherited replica must be mutable")
            assert(inheritedReplica === inheritedReplica?.cache.future)
            rebuildFromMaster(tree, replica, master, allOverrides, inheritedReplica)
        }

        if (masterHasChanges) {
            assert(master.mutable, "Master must be mutable")
            assert(master === master.cache.future)
            rebuildFromMaster(tree, replica, master, allOverrides, inheritedReplica)
        }
    }

    export function rebuildFromMaster(
        tree: CanvasTree,
        replica: CanvasNode & IsReplica,
        master: CanvasNode & IsMaster,
        allOverrides: ReplicaOverrides = replica.replicaInfo.overrides,
        inherited?: (CanvasNode & IsReplica) | null
    ) {
        let replicaInfo = replica.replicaInfo
        let overrides = replica.replicaInfo.overrides

        replica = replica.asMutable()
        const originalChildren = new Set<NodeID>()
        replica.walk(node => {
            originalChildren.add(node.id)
            // check if a textnode can be incrementally patched
            // we use the similar logic in updateExternalMasterInDocument in externalMasters,
            // make sure to keep them in sync when making changes
            if (withStyledText(node)) {
                const override = overrides[node.originalid!]
                if (override && override.styledText) {
                    const masterNode = tree.getCurrentOrFuture(node.originalid) as CanvasNode & WithStyledText
                    if (!masterNode) return
                    const futureNode = masterNode.cache.future as CanvasNode & WithStyledText
                    if (!futureNode) return
                    overrides = maybePatchText(
                        masterNode,
                        futureNode,
                        node as CanvasNode & WithStyledText,
                        overrides,
                        allOverrides
                    )
                }
            }
        })
        if (replicaInfo.overrides !== overrides) {
            replicaInfo = { ...replicaInfo, overrides }
        }

        const recordDuplicatedFrom = !isVariant(master)
        const resetUnsupportedProperties = isVariant(master)
        const keep: Partial<FrameNode> = {
            id: replica.id,
            cache: replica.cache,
            mutable: true,
            update: replica.update + 1,
            parentid: replica.parentid,
            originalid: replica.originalid,
            duplicatedFrom: recordDuplicatedFrom ? replica.duplicatedFrom : undefined,
            isMaster: false,
            isExternalMaster: null,
            replicaInfo,
            children: rebuildChildren({
                tree,
                ownerid: replica.id,
                parentid: replica.id,
                children: master.children,
                overrides: allOverrides,
                previousChildren: originalChildren,
                fromChildren: getLatestDuplicatedFromNode(tree, undefined, replica?.duplicatedFrom)?.children,
                inheritsFromReplica: !!inherited,
                recordDuplicatedFrom,
                resetUnsupportedProperties,
            }),
        }

        originalChildren.delete(replica.id)
        originalChildren.forEach(id => tree.unsafeRemoveId(id))
        Object.assign(replica, master, keep, objectFromOverrides(allOverrides[replica.originalid]))
        if (isVariant(replica)) updateVariantName(replica, overrides, master)
        if (resetUnsupportedProperties) {
            resetReplicaStackEnabled(master, replica)
            resetReplicaCodeComponentChildren(master, replica)
        }
    }

    export function updateFromOverrides(
        tree: CanvasTree,
        replica: CanvasNode & IsReplica,
        previous: CanvasNode & IsReplica
    ) {
        const replicaInfo = replica.replicaInfo
        const overrides = replicaInfo.overrides
        const previousOverrides = previous.replicaInfo.overrides
        const inheritedOverrides = inheritedReplicaNode(tree, replicaInfo.inheritsFrom)?.replicaInfo.overrides
        const isReplicaVariant = isVariant(replica)

        replica.walk(n => {
            const originalid = n.originalid! // is always there for nodes of a replica
            const override = overrides[originalid]
            const previousOverride = previousOverrides[originalid]

            // replica nodes that without any new properties don't need to do anything
            if (isEqual(override, previousOverride, true)) return

            // update the replica node using the original node
            const original = tree.get(originalid)
            if (!original) return
            updateNodeWithMaster(
                n,
                original,
                mergeNodeOverrides(overrides, inheritedOverrides, originalid),
                isReplicaVariant
            )

            // if the updated node is the replica, we have to restore the replicaInfo
            if (n === replica) {
                replica.replicaInfo = replicaInfo
            }
        })
    }

    export function updateOverrides(replica: CanvasNode & IsReplica) {
        assert(replica.mutable, "Replica must be mutable")
        assert(replica.originalid, "Replica must have original id")
        let overrides: ReplicaOverrides | null = null
        replica.walk(n => {
            const mutable = n.mutable ? n : n.cache.future
            if (!mutable) return
            const originalid = mutable.originalid!
            assert(originalid, "Original id must not be empty")

            // we need to be careful to treat replicaInfo and overrides as immutables
            const props = mutable.cache.templateProperties
            if (props && Object.keys(props).length > 0) {
                if (!overrides) {
                    overrides = Object.assign({}, replica.replicaInfo.overrides)
                    replica.replicaInfo = {
                        master: replica.replicaInfo.master,
                        inheritsFrom: replica.replicaInfo.inheritsFrom,
                        overrides,
                    }
                }
                // used by the move tool to signal it wants to restore a previous override, eg for alt+drag
                if (props.restore) {
                    if (!props.overrides) {
                        delete overrides[originalid]
                    } else {
                        overrides[originalid] = props.overrides
                    }
                    mutable.cache.templateProperties = null
                    return
                }
                // when pathsegments are change, always copy over x, y, width, height
                // otherwise, bounding box changes of the master will have weird effects in this replica
                if (props["pathSegments"]) {
                    props["x"] = n["x"]
                    props["y"] = n["y"]
                    props["width"] = n["width"]
                    props["height"] = n["height"]
                }

                overrides[originalid] = overridesFromObject(
                    Object.assign({}, objectFromOverrides(overrides[originalid]), props)
                )
                mutable.cache.templateProperties = null
            }
        })
    }

    export function resetOverrides(tree: CanvasTree, replica: CanvasNode & IsReplica, node: CanvasNode) {
        if (!node.originalid) return
        const master = tree.get(node.originalid)
        if (!master) return

        const overrides = Object.assign({}, replica.replicaInfo.overrides)
        const keysToClear = TemplateHelper.overrideKeys(tree, [node])
        keysToClear.add("_deleted")

        const nodeOverrides = overrides[node.originalid]
        const keep = {}
        for (const key in nodeOverrides) {
            if (!keysToClear.has(key)) {
                keep[key] = nodeOverrides[key]
            }
        }

        if (Object.keys(keep).length === 0) {
            delete overrides[node.originalid]
        } else {
            overrides[node.originalid] = keep
        }
        const masterid = replica.replicaInfo.master
        const inheritsFrom = replica.replicaInfo.inheritsFrom
        const inheritedReplicaOverrides = inheritedReplicaNode(tree, inheritsFrom)?.replicaInfo?.overrides

        updateNodeWithMaster(
            node,
            master,
            mergeNodeOverrides(overrides, inheritedReplicaOverrides, node.originalid),
            isVariant(replica)
        )

        replica = replica.asMutable()
        replica.replicaInfo = { master: masterid, overrides, inheritsFrom }
    }

    export function resetOverridesByProperty(
        tree: CanvasTree,
        replica: CanvasNode & IsReplica,
        node: CanvasNode,
        properties: string[]
    ) {
        if (!node.originalid) return

        const master = tree.get(node.originalid)
        if (!master) return

        const overrides = Object.assign({}, replica.replicaInfo.overrides)
        const keysToClear = TemplateHelper.overrideKeys(tree, [node])
        const newProperties = objectFromOverrides(overrides[node.originalid]) ?? {}

        for (const key of properties) {
            if (keysToClear.has(key)) {
                delete newProperties[key]
            }
        }

        overrides[node.originalid] = overridesFromObject(newProperties)
        const masterid = replica.replicaInfo.master
        const inheritsFrom = replica.replicaInfo.inheritsFrom
        const inheritedReplicaOverrides = inheritedReplicaNode(tree, inheritsFrom)?.replicaInfo?.overrides

        updateNodeWithMaster(
            node,
            master,
            mergeNodeOverrides(overrides, inheritedReplicaOverrides, node.originalid),
            isVariant(replica)
        )
        replica = replica.asMutable()
        replica.replicaInfo = { master: masterid, overrides, inheritsFrom }
    }

    const keyToIgnore = ["name", "gesture"]

    export const positionKeysToIgnore: (keyof WithPins)[] = [
        "bottom",
        "top",
        "left",
        "right",
        "centerAnchorX",
        "centerAnchorY",
    ]

    export function overrideKeys(tree: CanvasTree, nodes: CanvasNode[]): Set<string> {
        const keys: Set<string> = new Set<string>()

        for (let i = 0, il = nodes.length; i < il; i++) {
            const node = nodes[i]
            const replica = TemplateHelper.getReplicaForTemplateNode(tree, node)
            // There should always be a replica
            if (!node.originalid || !replica || !replica.replicaInfo) continue
            const overridesForNode = objectFromOverrides(replica.replicaInfo.overrides[node.originalid])
            if (!overridesForNode) continue

            const ignorePosition = isReplica(node)

            for (const overrideKey in overridesForNode) {
                if (ignorePosition && positionKeysToIgnore.includes(overrideKey as any)) {
                    continue
                }
                if (keyToIgnore.includes(overrideKey)) {
                    continue
                }

                keys.add(overrideKey)
            }
        }

        return keys
    }

    export function isKeyOverridden(replica: CanvasNode & IsReplica, originalId: NodeID, key: string) {
        const { overrides } = replica.replicaInfo
        if (!overrides[originalId]) return false
        if (key in overrides[originalId]) return true
        return overrides._deleted?.includes(key)
    }

    export function isInMasterOfReplica(tree: CanvasTree, parent: CanvasNode | null, child: CanvasNode): boolean {
        if (!parent) return false
        if (!isReplica(child)) return isAnyChildInMasterOfReplica(tree, parent, child)

        while (parent) {
            if (isMaster(parent) && isMasterOfReplica(tree, parent, child)) return true
            parent = tree.get(parent.parentid) as CanvasNode | null
        }

        return false
    }

    export function getReplicaForTemplateNode(
        tree: CanvasTree,
        node: CanvasNode | undefined
    ): (CanvasNode & IsReplica) | null {
        if (!node || !node.originalid) return null
        let replica: any = node
        while (replica) {
            if (isReplica(replica)) break
            replica = tree.get(replica.parentid)
        }
        return replica
    }

    function isAnyChildInMasterOfReplica(tree: CanvasTree, parent: CanvasNode, node: CanvasNode) {
        let result = false
        node.walk((child: CanvasNode) => {
            if (!result && isReplica(child)) {
                result = isInMasterOfReplica(tree, parent, child)
            }
        })
        return result
    }

    function isMasterOfReplica(
        tree: CanvasTree,
        master: CanvasNode & IsMaster,
        child: CanvasNode & IsReplica
    ): boolean {
        if (child.replicaInfo.master === master.id) return true

        // recursively check if master.templateInstances point to the master of this replica
        const templateInstances = master.cache.replicaInstances
        if (!templateInstances) return false
        for (let i = 0, il = templateInstances.length; i < il; i++) {
            const id = templateInstances[i]
            if (isInMasterOfReplica(tree, tree.get(id) as CanvasNode | null, child)) return true
        }

        return false
    }

    export function hasReplicas(tree: CanvasTree, master: CanvasNode & IsMaster): boolean {
        const instances = master.cache.replicaInstances
        if (!instances) return false
        for (let i = 0, il = instances.length; i < il; i++) {
            const id = instances[i]
            if (tree.getFuture(id)) return true
        }
        return false
    }

    export function hideMaster(tree: CanvasTree, master: CanvasNode & IsMaster): void {
        if (master.isExternalMaster) return
        master.set({ isExternalMaster: HIDDEN_MASTER }, tree)
    }

    export function showMaster(tree: CanvasTree, master: CanvasNode & IsMaster): void {
        if (master.isExternalMaster !== HIDDEN_MASTER) return
        master.set({ isExternalMaster: null }, tree)
    }

    // if we find a changed styled text in master, try to patch what wasn't overriden in the styled text
    export function maybePatchText(
        master: CanvasNode & WithStyledText,
        future: CanvasNode & WithStyledText,
        instance: CanvasNode & WithStyledText,
        overrides: ReplicaOverrides,
        allOverrides: ReplicaOverrides = {}
    ): ReplicaOverrides {
        if (!instance.originalid) return overrides
        if (!(instance.styledText instanceof StyledTextDraft)) return overrides

        const from = master.styledText
        const to = future.styledText
        if (from !== to) {
            const toUpdate = instance.styledText
            const patched = new StyledTextDraft(getPatchedEditor(from.styledText, to.styledText, toUpdate.styledText))

            if (patched !== toUpdate) {
                overrides = { ...overrides }
                const patchedOverrides = overridesFromObject({
                    ...objectFromOverrides(overrides[master.id]),
                    styledText: patched,
                })
                overrides[master.id] = allOverrides[master.id] = patchedOverrides
            }
        }
        return overrides
    }

    export function canMakeMaster(node: CanvasNode) {
        if (!withTemplate(node)) return false
        if (isMaster(node)) return false
        if (node.originalid) return false
        return true
    }

    export function inheritedReplicaNode(tree: CanvasTree, inheritsFrom: NodeID | null = null) {
        if (!inheritsFrom) return null
        const inherited = tree.get(inheritsFrom)

        if (!inherited || !isReplica(inherited)) return null

        return inherited.futureOrCurrent()
    }

    export function allReplicaOverrides(tree: CanvasTree, overrides: ReplicaOverrides, inheritsFrom: NodeID | null) {
        if (!inheritsFrom) return overrides
        const inheritedReplica = inheritedReplicaNode(tree, inheritsFrom)

        if (!inheritedReplica) return overrides

        return mergeOverrides(overrides, inheritedReplica.replicaInfo.overrides)
    }
}
