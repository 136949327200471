import assert from "assert"
import { NodeID, MaybeNodeID, NullID, randomID } from "./NodeID"
import { NodeCache } from "./NodeCache"
import type { NodeTree } from "../NodeTree"
import type { ReplicaInfo } from "../traits/Template"
import { withClassDiscriminator } from "utils/withClassDiscriminator"

export const USE_FREEZE = process.env.NODE_ENV !== "production"

export type ReadonlyChildren = {
    readonly children?: readonly ImmutableNode[]
}
export type ImmutableNode = Readonly<MutableNode> & ReadonlyChildren

export function setDefaults<T extends ImmutableNode>(node: T, defaults: Partial<T>, properties?: Partial<T>): void {
    Object.assign(node, defaults, properties)
}

const cloneOverrides = { mutable: true, update: 0 }

interface SerializableMutableNode {
    id: NodeID
    __class: string
    [key: string]: unknown
}

export class MutableNode extends withClassDiscriminator("MutableNode") {
    readonly id: NodeID
    readonly cache: NodeCache
    duplicatedFrom: string[] | null = null
    mutable: boolean = true
    update: number = 0

    parentid: MaybeNodeID = NullID
    originalid: NodeID | null = null
    replicaInfo: ReplicaInfo | null | undefined
    // N.B.: setting a default value for children here, although it might be undefined.
    // Previously we set the same empty array on all nodes, but since this is mutable now
    // we can’t do this (or all nodes would keep sharing the array).
    // The `WithChildren` trait *relies* on children being `undefined` though, so subclasses
    // that don’t support children must delete the default value in their constructor!
    children?: ImmutableNode[] = []

    constructor(props?: Partial<MutableNode>, cache?: NodeCache) {
        super()
        if (props) {
            Object.assign(this, props)
            if (!this.id) {
                this.id = randomID()
            }
            if (!this.cache) {
                this.cache = cache || new NodeCache()
            }
        } else {
            this.id = randomID()
            this.cache = cache || new NodeCache()
        }
    }

    // compatibility
    toJS(): SerializableMutableNode {
        const serializableValue = cloneOmitting(this, "cache", "update", "mutable", "__class")

        for (const key in serializableValue) {
            if (key === "children") {
                continue
            }

            const value = serializableValue[key]
            if (value === undefined) {
                continue
            }

            if (key === "replicaInfo" && value) {
                // We force a serialization of replicaInfo here, that way
                // overrides of styledText and others have their toJSON()
                // called, and trees can be sent via postMessage.
                serializableValue[key] = JSON.parse(JSON.stringify(value))
            }

            const toJS = value && value.toJS
            if (toJS instanceof Function) {
                serializableValue[key] = value.toJS()
            }
        }

        const serializableValueWithClass = Object.assign(serializableValue, { __class: this.__class })

        if (!this.children) return serializableValueWithClass

        // replica's don't need to store their children
        const children = this.replicaInfo
            ? []
            : this.children.map((child: ImmutableNode) => child.futureOrCurrent().toJS())

        return Object.assign(serializableValueWithClass, { children })
    }

    // see also CanvasNode.set()
    set(properties: { [key: string]: any }, tree?: NodeTree): this {
        // console.log("set", this.id, this.parentid, properties)
        const future = this.asMutable(tree)
        future.verifyPropertiesToSet(properties)
        Object.assign(future, properties)
        return future
    }

    verifyPropertiesToSet(props: { [key: string]: any }): void {
        // if not in a tree, we allow anything
        if (!this.cache.latest) return

        assert(!props.id, "`id` should not be set")
        assert(!props.parentid || this.parentid === props.parentid, "`parentid` should not be set")
        assert(!props.children, "`children` should not be set")
        assert(!props.mutable, "`mutable` should not be set")
        assert(!props.update, "`update` should not be set")
        assert(!props.cache, "`cache` should not be set")
    }

    // returns a similar deep clone of the node, but with new ids etc
    clone(props?: { [key: string]: any }): this {
        const clone = new (this.constructor as any)() as this
        Object.assign(clone, this, cloneOverrides, { cache: clone.cache, id: clone.id }, props)
        if (props && props.children) return clone

        const children = this.children
        if (children) {
            clone.children = children.map(c => c.clone({ parentid: clone.id }))
        }
        return clone
    }

    // preserve id while cloning, for copy/paste or such
    cloneWithIds(props?: { [key: string]: any }): this {
        const clone = new (this.constructor as any)() as this
        Object.assign(clone, this, cloneOverrides, { cache: clone.cache }, props)
        const children = this.children
        if (children) {
            clone.children = children.map(c => c.cloneWithIds())
        }
        return clone
    }

    freeze(): Readonly<this> {
        assert(this.mutable, "Node must be mutable when freezing")
        this.mutable = false
        this.cache.future = null
        if (USE_FREEZE) Object.freeze(this)
        if (USE_FREEZE) Object.freeze(this.children)
        return this
    }

    preCommit(_tree: NodeTree): void {}

    preFreeze(_tree: NodeTree): void {}

    // will throw if not in a tree
    tree(): NodeTree {
        return this.cache.tree()
    }

    // returns the latest committed version of this node
    // will throw if not in a tree
    latest(tree?: NodeTree): (Readonly<this> & ReadonlyChildren) | null {
        return (tree || this.tree()).getCurrentOrFuture(this.id) as this
    }

    isMutable(): this is MutableNode {
        return this.mutable
    }

    // returns the latest mutable version of this node, creating it if needed
    asMutable(tree?: NodeTree): this {
        if (this.mutable) return this

        return (tree || this.tree()).mutableFutureNode(this) as this
    }

    /** Force re-render without having to update props. */
    forceRender() {
        // TODO: skip if we are not in a rendering environment like the sandbox or preview
        this.asMutable(undefined)
    }

    futureOrCurrent(): this {
        if (this.cache.future) return this.cache.future as this
        return this
    }

    getChild<T extends ImmutableNode>(index: number): T | null {
        if (!this.children) return null
        if (index < 0 || index >= this.children.length) return null
        const child = this.children[index]
        return child as T
    }

    addChild<T extends MutableNode>(child: T): T {
        assert(this.mutable, "Node must be mutable when adding children")
        assert(this.children, "Node must have children")
        if (this.children) {
            child.parentid = this.id
            this.children.push(child)
        }
        return child
    }

    walk(cb: (n: ImmutableNode) => void) {
        const n = this.futureOrCurrent()
        cb(n)
        const children = n.children
        if (children) {
            for (let i = 0, il = children.length; i < il; i++) {
                children[i].walk(cb)
            }
        }
    }

    walkWithPredicate(cb: (n: ImmutableNode) => boolean) {
        const n = this.futureOrCurrent()
        if (!cb(n)) return
        const children = n.children
        if (!children) return
        for (let i = 0, il = children.length; i < il; i++) {
            children[i].walkWithPredicate(cb)
        }
    }

    find(cb: (n: ImmutableNode) => boolean): ImmutableNode | null {
        const n = this.futureOrCurrent()
        if (cb(n)) return this
        const children = n.children
        if (children) {
            for (let i = 0, il = children.length; i < il; i++) {
                const res = children[i].find(cb)
                if (res) return res
            }
        }
        return null
    }

    // will throw if not in a tree
    *ancestors(): IterableIterator<ImmutableNode> {
        const tree = this.tree()
        let ancestor = tree.get(this.parentid) as this
        while (ancestor) {
            yield ancestor
            ancestor = tree.get(ancestor.parentid) as this
        }
    }
}

function cloneOmitting<T extends object, K extends keyof T>(source: T, ...fieldsToOmit: K[]): Omit<T, K> {
    const clone = Object.assign({}, source)
    for (const field of fieldsToOmit) {
        delete clone[field]
    }
    return clone
}
