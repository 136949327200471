import { V74, V75 } from "../types"
import { transform } from "../utils/transform"
import { _, exactCheck } from "../utils/exactCheck"

export function migration_74_75(documentJSON: V74.Tree): V75.Tree {
    const migratedRoot = transform(documentJSON.root, node => {
        switch (node.__class) {
            case "FrameNode": {
                return exactCheck(migrateFramePreset(node), _ as V75.FrameNode)
            }
            default:
                return exactCheck(node, _ as V75.TreeNode)
        }
    })

    return { version: 75, root: migratedRoot }
}

const removedPresets = [
    "iPad_834_1112",
    "GooglePixel_411_822",
    "GooglePixel_411_846",
    "Twitter_1024_512",
    "Twitter_1500_500",
    "Facebook_1200_630",
    "Facebook_820_312",
    "Instagram_1080_1080",
    "Instagram_1080_1920",
    "Youtube_2560_1440",
    "Youtube_1280_720",
    "LinkedIn_1584_396",
    "Dribbble_1600_1200",
]

function migrateFramePreset(node: V74.FrameNode): V75.FrameNode {
    if (node.framePreset) {
        const newNode = { ...node } as V75.FrameNode

        if (removedPresets.includes(node.framePreset)) {
            newNode.framePreset = null
        } else {
            newNode.isScreen = true
        }

        return newNode
    } else {
        return node as V75.FrameNode
    }
}
