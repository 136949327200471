import type { StyledText } from "document/models/StyledText/StyledText"
import { CanvasNodeCache } from "./CanvasNodeCache"

export class TextNodeCache extends CanvasNodeCache {
    // when actually editing, the editor state is tracked in the cache, so
    // property panels update based on this, and multi user edits don't destroy
    // the editor state
    styledText: unknown | null = null

    private styledTextOverride: {
        styledText: StyledText<any>
        textContent: string
        styledTextWithUpdatedText: StyledText<any>
    } | null = null

    /** Used for overriding the styled text content with variables */
    getStyledTextWithUpdatedText<T>(styledText: StyledText<T>, textContent: string): StyledText<T> {
        if (
            this.styledTextOverride &&
            this.styledTextOverride.styledText === styledText &&
            this.styledTextOverride.textContent === textContent
        ) {
            return this.styledTextOverride.styledTextWithUpdatedText
        }

        const styledTextWithUpdatedText = styledText.withUpdatedText(textContent)

        this.styledTextOverride = {
            styledText: styledText,
            textContent: textContent,
            styledTextWithUpdatedText,
        }
        return styledTextWithUpdatedText
    }
}
