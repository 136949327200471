import { ActionHandler, ControlType } from "framer"
import { addActionControls } from "./actionControls"

export interface LogOptions {
    type: "event" | "message" | "count"
    message: string
}

export function useLog(options: LogOptions): ActionHandler {
    return (event: unknown) => {
        switch (options.type) {
            case "event":
                // Because our custom console is async, we need to persist the event. Otherwise React will re-use
                // the event object between events. See https://reactjs.org/docs/events.html#event-pooling for details.
                if (isReactEvent(event)) {
                    event.persist()
                }
                // eslint-disable-next-line no-console
                console.log(event)
                break
            case "message":
                // eslint-disable-next-line no-console
                console.log(options.message)
                break
            case "count":
                // eslint-disable-next-line no-console
                console.count(options.message)
                break
        }
    }
}

addActionControls(useLog, "Console Log", {
    type: {
        type: ControlType.Enum,
        options: ["event", "message", "count"],
        optionTitles: ["Event", "Message", "Count"],
        defaultValue: "event",
        title: "Type",
    },
    message: {
        type: ControlType.String,
        title: "Message",
        hidden(props) {
            return props.type === "event"
        },
    },
})

function isObject(value: unknown): value is { [key: string]: unknown } {
    return !!value && typeof value === "object"
}

interface ReactEvent {
    persist: () => void
}

function isReactEvent(value: unknown): value is ReactEvent {
    if (!isObject(value)) return false
    return typeof value["persist"] === "function"
}
