import * as React from "react"
import { cx } from "linaria"
import type { FontSize } from "../tokens/fonts"
import { fonts } from "../tokens"
import type { HTMLDivAttributes, OverrideType } from "./types"
import * as styles from "./Text.styles"
import type { CSSProperties } from "./types"

export type TextProps = OverrideType<
    HTMLDivAttributes,
    {
        /** Font size. */
        size?: FontSize
        /** Line height, will be multiplied with the font size. */
        lineHeight?: number
        /** Allows for overriding the style. */
        style?: Omit<React.CSSProperties, "fontSize" | "lineHeight">
        /** Expects text as children. */
        children?: React.ReactNode
    }
>

export function Text({
    size = fonts.size.base,
    lineHeight = 1.5,
    children,
    className,
    style: styleOverride,
    ...rest
}: TextProps) {
    const marginIncrease = Math.floor((size * lineHeight - size) / 2)
    const baseOffset = size / 12

    const style: CSSProperties = {
        fontSize: `${size}px`,
        lineHeight: `${size * lineHeight}px`,
        [styles.marginTopKey]: `-${baseOffset + marginIncrease}px`,
        [styles.marginBottomKey]: `-${1 + baseOffset + marginIncrease}px`,
        ...(styleOverride as React.CSSProperties),
    }

    return (
        <div className={cx(styles.text, className)} style={style} {...rest}>
            {children}
        </div>
    )
}
