const maxObjectSize = 10000

/**
 * For use in `JSON.stringify` as the replacer function to handle circular
 * structures and limit the size of the object
 */
const getReplacer = () => {
    const seen = new WeakSet()
    let counter = 0
    return (_key: string, value: unknown) => {
        if (counter++ > maxObjectSize) {
            return "…"
        }
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return "…"
            }
            seen.add(value)
        }
        return value
    }
}

export function encode(data: unknown): string {
    try {
        return JSON.stringify(data, getReplacer())
    } catch (err) {
        throw new Error("Unable to serialize")
    }
}

export function decode(encodedData: string | undefined): unknown {
    if (encodedData === undefined) return undefined
    return JSON.parse(encodedData)
}
