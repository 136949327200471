import { CompoundShape } from "document/models/CanvasTree/traits/utils/compoundShape"
import type { WithFramePreset } from "document/models/CanvasTree/traits/FramePreset"
import { getShapeGroupRecord } from "document/models/CanvasTree/records/ShapeGroupRecord"
import { PathBooleanType } from "document/models/CanvasTree/traits/PathBoolean"
import type { Rect, Size } from "framer"
import type { WithPaths, WithShape, LayerProps, ConstraintValues } from "framer"
import type { WithExport } from "document/models/CanvasTree/traits/Export"
import type { List } from "immutable"
import type { ExportOptions } from "./ExportOptions"
import { setDefaults } from "./MutableNode"
import { CanvasNode } from "./CanvasNode"
import type { VectorGroupProperties } from "framer"
import { getDefaultName } from "document/components/utils/nodes"
import type { DrawableNode, VectorNode } from "./TreeNode"
import type { CanvasTree } from "../CanvasTree"
import { stringFromNodeID } from "./NodeID"
import { wrapInShapeContainer } from "document/models/CanvasTree/traits/utils/wrapInShapeContainer"
import { collectNameForNode } from "document/models/CanvasTree/traits/utils/collectNameForNode"
import { withClassDiscriminator } from "utils/withClassDiscriminator"
import { VariableReference, isVariableReference } from "../traits/VariableReference"
import { WithRotation } from "../traits/Rotation"

export function isShapeGroupNode(node: CanvasNode | null | undefined): node is DrawableNode & ShapeGroupNode {
    return node instanceof ShapeGroupNode
}

// eslint-disable-next-line import/no-default-export
export default class ShapeGroupNode extends withClassDiscriminator("ShapeGroupNode", CanvasNode)
    implements WithShape, WithExport, WithRotation {
    children: VectorNode[]

    visible: boolean | VariableReference
    locked: boolean

    x: number
    y: number
    width: number
    height: number

    opacity: number | VariableReference

    aspectRatio: number | null
    rotation: number | VariableReference

    exportOptions: List<ExportOptions>

    constructor(properties?: Partial<ShapeGroupNode>) {
        super()
        setDefaults<ShapeGroupNode>(this, getShapeGroupRecord(), properties)
    }

    // Note: a ShapeGroupNode's `calculatedPaths` result is only usable to get the its
    // bounds; the joined shape will lose all individual stoke + fill settings:
    calculatedPaths(): WithPaths {
        const cache = this.cache
        if (cache.calculatedPaths) return cache.calculatedPaths

        const sourceSegments = CompoundShape.sourcePaths(this.children)
        cache.calculatedPaths = CompoundShape.executePathBoolean(sourceSegments, PathBooleanType.Join)
        return cache.calculatedPaths
    }

    normalizedPath() {
        this.calculatedPaths()
    }

    rect(_parentSize: Size | null) {
        return { x: this.x, y: this.y, width: this.width, height: this.height }
    }

    matrix(_parentSize?: Size | null, frame?: Rect) {
        const point = { x: this.x, y: this.y }
        if (frame) {
            point.x = frame.x
            point.y = frame.y
        }
        return new DOMMatrix().translateSelf(point.x, point.y).rotateSelf(0, 0, this.resolveValue("rotation") ?? 0)
    }

    transformMatrix(parentSize: Size | null, frame?: Rect) {
        const size = { width: this.width, height: this.height }
        if (frame) {
            size.width = frame.width
            size.height = frame.height
        }
        return new DOMMatrix()
            .translateSelf(0.5 * size.width, 0.5 * size.height)
            .multiply(this.matrix(parentSize, frame))
            .translateSelf(-0.5 * size.width, -0.5 * size.height)
    }

    isRotated() {
        return (this.resolveValue("rotation") ?? 0) % 360 !== 0
    }

    updateForRect(frame: Rect, _parentSize: Size | null) {
        return frame
    }

    updateForSize(size: Partial<Size>, _parentSize: Size | null): Partial<ConstraintValues & WithFramePreset> {
        return size
    }

    getProps(): Partial<VectorGroupProperties> & LayerProps {
        const svgId = stringFromNodeID(this.id)
        const idAttribute = undefined

        const defaultName = getDefaultName(this)

        const resolvedOpacity = this.resolveValue("opacity")

        let isRootVectorNode = true
        if (this.cache.latest) {
            isRootVectorNode = this.tree().isRootVectorNode(this)
        }

        const frame = this.rect(null)

        const props: Partial<VectorGroupProperties> = super.getProps()
        const vectorGroupProps: Partial<VectorGroupProperties> = {
            key: svgId,
            id: idAttribute,
            opacity: resolvedOpacity === 1 ? undefined : resolvedOpacity,
            visible: this.resolveValue("visible"),
            x: this.x,
            y: this.y,
            rotation: this.resolveValue("rotation"),
            width: this.width,
            height: this.height,
            defaultName: defaultName,
            isRootVectorNode,
            frame,
        }
        Object.assign(props, vectorGroupProps)

        collectNameForNode(this, props)

        return props
    }

    preCommit(tree: CanvasTree) {
        if (this.isEmptyGroup()) {
            tree.removeNode(this)
        } else {
            wrapInShapeContainer(this as any, tree)
        }
    }

    supportsVariables() {
        return false
    }

    resolveValue<K extends keyof this>(key: K): Exclude<this[K], VariableReference> | undefined {
        const value = this[key]
        if (isVariableReference(value)) {
            return undefined
        } else {
            return value as Exclude<this[K], VariableReference>
        }
    }
}
