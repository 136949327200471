import type { ActionControlDescription, ArrayControlDescription, ControlDescription } from "framer"

export type PackageIdentifier = string
export type EntityIdentifier = string
export type TokenIdentifier = string
/**
 * NOTE: Also defined as EntityType in the Server project.
 */
export type EntityType = "component" | "master" | "override" | "action"

export type HostArrayControlDescription = Omit<ArrayControlDescription, "hidden">

export type HostControlDescription<ComponentProps = any> = OverrideUnionElements<
    ControlDescription<Partial<ComponentProps>>,
    { hidden?: never }
>

export type HostPropertyControls<ComponentProps = any> = {
    [K in keyof ComponentProps]?: HostControlDescription<ComponentProps>
}

export type HostActionControls<ActionProps = any> = {
    [K in keyof ActionProps]?: OverrideUnionElements<ActionControlDescription<Partial<ActionProps>>, { hidden?: never }>
}

export interface EntityDefinition {
    /** Package depth of this component. 0 if part of project, 1 if a direct dependency, greater otherwise. */
    depth: number
    file: string
    identifier: EntityIdentifier
    name: string
    /** Identifier of the package that contains this component (one package can contain multiple components). */
    packageIdentifier: PackageIdentifier
    type: EntityType
    properties: HostPropertyControls | HostActionControls
}

export interface ReactComponentDefinition extends EntityDefinition {
    type: "component"
    defaultProps: { [propName: string]: unknown } | undefined
    /**
     * Map of Framer metadata annotations extracted from JSDoc, e.g:
     * ```js
     * { "framerversion": "42", "framervariables": "{}" }
     * ```
     * NOTE! It might only be present for the module-backed React components.
     * MODULES-TODO: Remove this field when we switch to a fully static metadata extraction
     *               (one that doesn't require evaluating the module in a sandbox).
     */
    annotations?: Record<string, string> | null
}

export function isReactComponentDefinition(d: EntityDefinition): d is ReactComponentDefinition {
    return d.type === "component"
}

export interface DesignComponentDefinition extends EntityDefinition {
    type: "master"
    class: { isExternalMaster: string; id: string | null; name?: string | null }
}

export function isDesignDefinition(d: EntityDefinition): d is DesignComponentDefinition {
    return d.type === "master"
}

export interface OverrideDefinition extends EntityDefinition {
    type: "override"
}

export function isOverrideDefinition(definition: EntityDefinition): definition is OverrideDefinition {
    return definition.type === "override"
}

export interface ErrorDefinition extends EntityDefinition {
    error: string
    fileDoesNotExist?: boolean
}

export function isErrorDefinition(def: EntityDefinition | ErrorDefinition | undefined): def is ErrorDefinition {
    return def !== undefined && (def as ErrorDefinition).error !== undefined
}

export type DesignJSON =
    // Legacy design JSON value containing the entire document.json object.
    | { root: any; version: number }
    // Filtered design component primary nodes from the document.json object, reducing the size by a lot.
    | { components: any[]; tokens: TokenMap; version: number }

export interface PackageInfo {
    depth: number
    displayName: string
    name: string
    componentsJson?: any
    designJson?: DesignJSON
}

export interface EntityMap {
    [name: string]: EntityDefinition
}

export interface HostPackageMap {
    [name: string]: PackageInfo
}

export interface TokenDefinition {
    __class: string
    id: TokenIdentifier
    name: string
    value: string
}

export interface TokenMap {
    [tokenId: string]: TokenDefinition
}

// Utility types

/**
 * Overrides the specified field types of the provided type
 */
export type OverrideType<T, O extends object> = Omit<T, keyof O> & O

/**
 * Overrides every element of the union: more info https://stackoverflow.com/a/51691257
 */
type OverrideUnionElements<U, O extends object> = U extends any ? OverrideType<U, O> : never
