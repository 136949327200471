// First pass: whitelist characters.
const MathCharMatcher = /^[\d.+\-*/]+$/

// Second pass: support numbers, decimals without leading 0 or plus, minus, times, divide
const MathExprMatcher = /^(\d+(?:\.\d+)?|\.\d+|[-+/*])+$/

export function evalMath(expr: string): number {
    // Remove all whitespace
    const stripped = expr.replace(/\s/g, "")

    // Return if string contains non-expression characters.
    if (!MathCharMatcher.test(stripped) || !MathExprMatcher.test(stripped)) {
        return NaN
    }
    // Insert spaces between all numbers and math charaters
    // to avoid inserting comments or postfix/prefix operators
    const spaced = stripped.split(/([^\d.])/).join(" ")

    try {
        const result = Function(`return ${spaced}`)()
        return typeof result === "number" ? result : NaN
    } catch (e) {
        // Ignore errors.
    }

    return NaN
}
