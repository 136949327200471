import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springAnimation, wait, animationDelay } from "./utils"
import { colors } from "../tokens"

const stackItem: React.CSSProperties = {
    width: 26,
    height: 7,
    borderRadius: 2,
    background: colors.animationForground,
    margin: 1,
}

export const AnimationStack = React.memo(function AnimationStack() {
    const row1 = useAnimation()
    const row2 = useAnimation()
    const row3 = useAnimation()

    async function animation() {
        await wait(animationDelay)

        await Promise.all([
            row1.start({
                y: 0,
                transition: { type: false },
            }),
            row2.start({
                y: 0,
                transition: { type: false },
            }),
            row3.start({
                y: 0,
                transition: { type: false },
            }),
        ])
        await row1.start({
            scale: 1.2,
            y: -1,
            transition: springAnimation,
        })
        await Promise.all([
            row1.start({
                y: 19,
                transition: { ease: "easeInOut", duration: 0.4 },
            }),
            row2.start({
                y: -9,
                transition: { ease: "easeInOut", duration: 0.2, delay: 0.2 },
            }),
            row3.start({
                y: -9,
                transition: { ease: "easeInOut", duration: 0.2, delay: 0.25 },
            }),
        ])
        return await row1.start({
            scale: 1,
            y: 18,
            transition: springAnimation,
        })
    }

    useLoop(animation)

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: 40,
                height: 40,
                borderRadius: 8,
                background: colors.animationForgroundLight,
            }}
        >
            <motion.div animate={row1} style={stackItem} />
            <motion.div animate={row2} style={stackItem} />
            <motion.div animate={row3} style={stackItem} />
        </div>
    )
})
