import { paddingFromProps } from "./paddingFromProps"
import type { StackComponentNode } from "document/models/CanvasTree/nodes/FrameNode"

export function cssExport(stackComponentNode: StackComponentNode): React.CSSProperties {
    const style: React.CSSProperties = {
        display: "flex",
        flexDirection: stackComponentNode.stackDirection === "horizontal" ? "row" : "column",
        justifyContent: stackComponentNode.stackDistribution
            ? stackDistributionAndAlignmentToFlexbox(stackComponentNode.stackDistribution)
            : undefined,
        alignItems: stackComponentNode.stackAlignment
            ? stackDistributionAndAlignmentToFlexbox(stackComponentNode.stackAlignment)
            : undefined,
    }

    const { top, left, right, bottom } = paddingFromProps(stackComponentNode)
    if (top === left && top === right && top === bottom) {
        if (top) style.padding = `${top}px`
    } else {
        style.padding = `${top}px ${right}px ${bottom}px ${left}px`
    }

    return style
}

function stackDistributionAndAlignmentToFlexbox(
    alignment: "start" | "center" | "end" | "space-between" | "space-around" | "space-evenly"
) {
    switch (alignment) {
        case "start":
            return "flex-start"
        case "end":
            return "flex-end"
        default:
            return alignment
    }
}
