import { nameDefaults } from "../traits/Name"
import { sizeDefaults } from "../traits/Size"
import { intrinsicSizeDefaults } from "../traits/IntrinsicSize"
import { aspectRatioDefaults } from "../traits/AspectRatioLock"
import { pinDefaults } from "../traits/Pins"
import { rotationDefaults } from "../traits/Rotation"
import { visibilityDefaults } from "../traits/Visibility"
import { lockDefaults } from "../traits/Lock"
import { codeComponentDefaults } from "../traits/CodeComponent"
import { opacityDefaults } from "../traits/Opacity"
import { codeOverrideDefaults } from "../traits/CodeOverride"
import { borderDefaults } from "document/models/CanvasTree/traits/Border"
import { borderPerSideDefaults } from "document/models/CanvasTree/traits/BorderPerSide"
import { boxShadowDefaults } from "document/models/CanvasTree/traits/BoxShadowDefaults"
import { codeComponentFillDefaults } from "document/models/CanvasTree/traits/FillDefaults"
import { clipDefaults } from "document/models/CanvasTree/traits/Clip"
import { relativeRadiusDefaults } from "document/models/CanvasTree/traits/Radius"
import { radiusPerCornerDefaults } from "document/models/CanvasTree/traits/RadiusPerCorner"
import { filterDefaults } from "document/models/CanvasTree/traits/Filters"
import { withExportDefaults } from "document/models/CanvasTree/traits/Export"
import { previewSettingsDefaults } from "../traits/PreviewSettings"
import { memoRecord } from "./memoRecord"

export const getCodeComponentRecord = memoRecord(() => {
    return {
        ...nameDefaults,
        ...sizeDefaults,
        ...intrinsicSizeDefaults,
        ...aspectRatioDefaults,
        ...pinDefaults,
        ...opacityDefaults,
        ...rotationDefaults,
        ...visibilityDefaults,
        ...lockDefaults,
        ...codeComponentDefaults,
        ...codeOverrideDefaults,
        ...codeComponentFillDefaults,
        ...clipDefaults,
        ...borderDefaults,
        ...borderPerSideDefaults,
        ...relativeRadiusDefaults,
        ...radiusPerCornerDefaults,
        ...boxShadowDefaults,
        ...filterDefaults,
        ...withExportDefaults,
        ...previewSettingsDefaults,
    }
})
