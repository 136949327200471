import type { CanvasNode, NodeID } from "document/models/CanvasTree"

export interface ReplicaNodeOverrides {
    [key: string]: any
    _deleted?: string[]
}
export interface ReplicaOverrides {
    [key: string]: ReplicaNodeOverrides
}
export interface ReplicaInfo {
    // The masterid is the reference to the node that is the master of this replica
    master: NodeID
    overrides: ReplicaOverrides
    inheritsFrom?: NodeID
}

// we misuse the isExternalMaster field to also hide local masters
export const HIDDEN_MASTER = "hidden master"

export interface WithTemplate {
    isMaster: boolean
    isExternalMaster: string | null
    replicaInfo: ReplicaInfo | null
}

export interface IsMaster {
    isMaster: true
    isExternalMaster: string | null
}

export interface IsExternalMaster {
    isMaster: true
    isExternalMaster: string
}

export interface IsReplica {
    isMaster: false
    // originalid is the reference to the same node in the master tree
    originalid: NodeID
    replicaInfo: ReplicaInfo
}

export interface WithInheritedOverrides extends IsReplica {
    replicaInfo: Required<ReplicaInfo>
}

export const templateDefaults: WithTemplate = {
    isMaster: false,
    isExternalMaster: null,
    replicaInfo: null,
}

const key: keyof WithTemplate = "isMaster"

export function withTemplate(node: CanvasNode): node is CanvasNode & WithTemplate {
    return key in node
}

export function isMaster(node: CanvasNode): node is CanvasNode & IsMaster {
    return withTemplate(node) && node.isMaster
}

// both external masters, and explicitly deleted local masters are hidden
export function isHiddenMaster(node: CanvasNode): node is CanvasNode & IsMaster {
    return (node as any).isExternalMaster
}

export function isExternalMaster(node: CanvasNode): node is CanvasNode & IsMaster {
    const externalMaster = (node as any).isExternalMaster
    return externalMaster && externalMaster !== HIDDEN_MASTER
}

export function isInternalHiddenMaster(node: CanvasNode): node is CanvasNode & IsMaster {
    return (node as any).isExternalMaster === HIDDEN_MASTER
}

export function isReplica(node: CanvasNode): node is CanvasNode & IsReplica {
    return withTemplate(node) && !!node.replicaInfo
}

export function withInheritedOverrides(node: CanvasNode): node is CanvasNode & WithInheritedOverrides {
    return !!node.replicaInfo?.inheritsFrom
}

export function isReplicaChild(node: CanvasNode): boolean {
    return !!node.originalid && !isReplica(node)
}

export function isReplicaOrReplicaChild(node: CanvasNode): node is CanvasNode & WithTemplate {
    return !!node.originalid
}

export function isMasterChild(node: CanvasNode): boolean {
    for (const ancestor of node.ancestors()) {
        if (isMaster(ancestor)) return true
    }

    return false
}

export function isMasterOrReplicaChild(node: CanvasNode): boolean {
    return isReplicaChild(node) || isMasterChild(node)
}
