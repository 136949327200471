import type { ComponentType as ReactComponentType } from "react"
import type { ActionControls, PropertyControls } from "framer"
import type {
    EntityIdentifier,
    EntityType,
    EntityDefinition,
    PackageInfo,
    OverrideType,
} from "../../host/componentLoader/types"

// Sandbox types are mostly mirroring their Host counterparts,
// but also include some fields that are not serializable and cannot
// be sent between Sandbox iframe and Host window

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Override {}

export type SandboxEntityDefinition<P = any> = OverrideType<
    EntityDefinition,
    {
        class: ReactComponentType<P> | JSON | Override
        properties: PropertyControls<P> | ActionControls<P>
    }
>

export interface ActionDefinition<P = any> extends SandboxEntityDefinition<P> {
    properties: ActionControls<P>
    type: "action"
}

export function isActionDefinition(d: SandboxEntityDefinition): d is ActionDefinition {
    return d.type === "action"
}

export interface SandboxEntityMap {
    [name: string]: SandboxEntityDefinition
}

export interface SandboxEntityIdsByPackageIdMap {
    [packageIdentifier: string]: EntityIdentifier[]
}

/**
 * EntityInfo is generated by analyzer.ts in build-library
 * and injected into the bundle as exported `__info__` variable in every code file
 */
export interface EntityInfo {
    name: string
    /**
     * Indicates whether the component uses `children` prop
     */
    children: boolean | undefined
    type: EntityType | undefined
    /**
     * Map of Framer metadata annotations extracted from JSDoc, e.g:
     * ```js
     * { "framerversion": "42", "framervariables": "{}" }
     * ```
     * NOTE! It might only be present for the module-backed React components.
     * MODULES-TODO: Remove this field when we switch to a fully static metadata extraction
     *               (one that doesn't require evaluating the module in a sandbox).
     */
    annotations?: Record<string, string> | null
}

export interface LazyMap<T> {
    [key: string]: () => T
}

export interface SourceModuleExports {
    __info__?: EntityInfo[]
    error?: { message: string } | string
}

interface FramerMetadata {
    packageJson: {
        name?: string // Can be undefined for the local package.
        framer?: {
            components?: EntityInfo[]
            displayName?: string
        }
        design?: any
    }
    dependencies?: LazyMap<FramerModuleExports>
    sourceModules?: LazyMap<SourceModuleExports>
}

export interface FramerModuleExports {
    __framer__?: FramerMetadata
}

export interface FramerIndexModule {
    exports: FramerModuleExports
}

export interface SandboxPackageInfo extends PackageInfo {
    /** The object exported from the index module of this package. */
    exportsObject: FramerModuleExports

    /**
     * Map of functions that will import the associated package when called.
     * Key is the name of another package that is a dependency of this package.
     */
    dependencies: LazyMap<FramerModuleExports>
    /**
     * Map of functions that will import the associated module when called.
     * Key is a filename relative to the "code" directory of the package.
     */
    sourceModules: LazyMap<SourceModuleExports>
}

export interface SandboxPackageMap {
    [name: string]: SandboxPackageInfo
}

export interface SandboxReactComponentDefinition<P = any> extends SandboxEntityDefinition<P> {
    type: "component"
    class: ReactComponentType<P>
    defaultProps: Partial<P> | undefined
    /**
     * Map of Framer metadata annotations extracted from JSDoc, e.g:
     * ```js
     * { "framerversion": "42", "framervariables": "{}" }
     * ```
     * NOTE! It might only be present for the module-backed React components.
     * MODULES-TODO: Remove this field when we switch to a fully static metadata extraction
     *               (one that doesn't require evaluating the module in a sandbox).
     */
    annotations?: Record<string, string> | null
}

export function isSandboxReactComponentDefinition<P = any>(
    d: SandboxEntityDefinition<P>
): d is SandboxReactComponentDefinition<P> {
    return d.type === "component"
}

export interface SandboxDesignComponentDefinition extends SandboxEntityDefinition {
    type: "master"
    class: Record<string, unknown>
}

export function isSandboxDesignDefinition(d: SandboxEntityDefinition): d is SandboxDesignComponentDefinition {
    return d.type === "master"
}

export interface SandboxOverrideDefinition extends SandboxEntityDefinition {
    type: "override"
}

export function isSandboxOverrideDefinition(d: SandboxEntityDefinition): d is SandboxOverrideDefinition {
    return d.type === "override"
}

export interface SandboxErrorDefinition extends SandboxEntityDefinition<{}> {
    error: string
    fileDoesNotExist?: boolean
}

export function isSandboxErrorDefinition(
    def: SandboxEntityDefinition | SandboxErrorDefinition
): def is SandboxErrorDefinition {
    return def !== undefined && (def as SandboxErrorDefinition).error !== undefined
}
export interface ProjectBundleScriptsMap {
    "index.js"?: string
    "vendors.js"?: string
}

export type ProjectBundleGlobalEnvironment = {
    componentHash?: string | number
    componentScript?: string
    componentSrc?: string
} & {
    [evalScriptFunctionName: string]: (module: FramerIndexModule, exports: {}, require: (name: string) => any) => void
}

export const DefaultAssetReferenceKey = "__defaultAssetReference"
export interface OptionalDefaultAssetReference {
    __defaultAssetReference?: string
}
