import { ReplicaInfo } from "../types/V57"
import { AnyTreeNode } from "../types"
import { IdToNodeMap } from "./transform"

interface WithReplicaInfo {
    replicaInfo?: ReplicaInfo | null
}

function withReplicaInfo<N extends AnyTreeNode>(node: N): node is N & WithReplicaInfo {
    return "replicaInfo" in node
}

export type ReplicaFromNode<N> = Partial<N> & { _deleted?: (keyof N)[] }

export type GetInheritedPropValue = <N extends AnyTreeNode, T extends keyof N>(node: N, prop: T) => N[T]

export type UpdateOverrides = <N, ReplicaType extends ReplicaFromNode<any>>(
    node: N,
    _newT: ReplicaType,
    updateOverride: (override: ReplicaFromNode<N>, id: string) => Partial<ReplicaType>
) => void

export function transformReplicaOverrides<NodeIn extends AnyTreeNode, OriginalNode extends AnyTreeNode>(
    node: NodeIn & WithReplicaInfo,
    idToNode: IdToNodeMap<AnyTreeNode>,
    visitor: (
        // The originalNode is provided only for typings and checking the node class,
        // please use getInheritedPropValue to check the original value.
        _originalNode: OriginalNode,
        getInheritedPropValue: GetInheritedPropValue,
        updateOverrides: UpdateOverrides
    ) => void
) {
    if (!node.replicaInfo) {
        return
    }

    const replicaInfo = { ...node.replicaInfo, overrides: { ...node.replicaInfo.overrides } }
    node.replicaInfo = replicaInfo

    const replicaNodeIds = Object.keys(replicaInfo.overrides)
    for (const replicaNodeId of replicaNodeIds) {
        const { originalNode, upstreamOverrides } = findOriginalNodeAndCollectUpstreamOverrides(
            replicaNodeId,
            replicaInfo.master,
            {},
            idToNode
        )
        if (!originalNode) continue

        visitor(
            originalNode as OriginalNode,
            (original, prop) => {
                if (prop in upstreamOverrides) return upstreamOverrides[prop as string]
                return original[prop] as any
            },
            (_n, _t, updateOverride) => {
                replicaInfo.overrides[replicaNodeId] = updateOverride(
                    replicaInfo.overrides[replicaNodeId] as any,
                    `${node.id}_${originalNode.id}`
                ) as any
            }
        )
    }
}

type ValueOf<T> = T[keyof T]
type UpstreamOverrides = ValueOf<ReplicaInfo["overrides"]>
// Normally we just need to search the idToNode map for the original node, but we generate mixIds
// for replica children (see rebuildTemplate in TemplateHelper.ts). These nodes do not exist in the
// serialized data, and are only rebuilt when we expand the template, but their mixIds are used in
// replicaInfo to track overrides.
// The mixIds are quite stable, they follow a pattern of `owner(replica) Id + originalNode Id`
// (see mixId in TemplateHelper.ts), so by tracing the mixId step by step, we should be able to find
// the actual original node.
// Note that when comparing the overridden value with the original value, we need to take upstream
// overrides into account to replicate the pre-migrated data accurately. If the node is already
// overridden in one of the upstream replicas, the replicaInfo on that upstream replica should be
// our source of truth (what "reset overrides" should reset to), not the value on the original node.
export function findOriginalNodeAndCollectUpstreamOverrides(
    mixId: string,
    masterId: string,
    upstreamOverrides: UpstreamOverrides,
    idToNode: IdToNodeMap<AnyTreeNode>
): { originalNode: AnyTreeNode | undefined; upstreamOverrides: UpstreamOverrides } {
    if (!mixId) return { originalNode: undefined, upstreamOverrides }

    const originalNode = idToNode[mixId]
    if (originalNode) return { originalNode, upstreamOverrides }

    const master = idToNode[masterId]
    if (!master) return { originalNode: undefined, upstreamOverrides }

    const replica = findChildInNode(master, child => mixId.startsWith(child.id))
    if (!replica || !withReplicaInfo(replica) || !replica.replicaInfo)
        return { originalNode: undefined, upstreamOverrides }

    // collect the overrides of the same node in the current replica
    const { replicaInfo } = replica
    for (const overriddenNodeId in replicaInfo.overrides) {
        if (!mixId.endsWith(overriddenNodeId)) continue

        const { _deleted, ...overrides } = replicaInfo.overrides[overriddenNodeId]

        if (Array.isArray(_deleted)) {
            for (const key of _deleted) {
                // If already overridden by downstream replicas, ignore.
                // Otherwise we set the key to undefined because the same prop in the upstream
                // shouldn't affect the overrides anymore.
                if (!(key in upstreamOverrides)) {
                    upstreamOverrides[key] = undefined
                }
            }
        }

        for (const propKey in overrides) {
            // already overridden by downstream replicas, ignore
            if (!(propKey in upstreamOverrides)) {
                upstreamOverrides[propKey] = overrides[propKey]
            }
        }
    }

    const nextMixId = mixId.slice(replica.id.length)

    return findOriginalNodeAndCollectUpstreamOverrides(nextMixId, replicaInfo.master, upstreamOverrides, idToNode)
}

function hasChildren<T extends AnyTreeNode>(node: T): node is T & { children: T[] } {
    return Array.isArray(node.children) && node.children.length > 0
}

function findChildInNode(node: AnyTreeNode, predicate: (node: AnyTreeNode) => boolean): AnyTreeNode | undefined {
    if (predicate(node)) return node
    if (!hasChildren(node)) return undefined

    for (const child of node.children) {
        const result = findChildInNode(child, predicate)
        if (result) return result
    }
}
