import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springAnimation, springAnimation2 } from "./utils"
import { colors } from "../tokens"

const scrollItemStyle: React.CSSProperties = {
    width: 22,
    height: 8,
    borderRadius: 2,
    backgroundColor: colors.animationForground,
    marginBottom: 2,
}

const scrollWrapperStyle: React.CSSProperties = {
    display: "inline-block",
    alignItems: "center",
    justifyContent: "start",
    flexDirection: "column",
    position: "absolute",
}

export function AnimationScroll() {
    const fullFrame = useAnimation()
    const scrollFrame = useAnimation()
    const scrollItem = useAnimation()
    const contentFrame = useAnimation()
    const contentItem = useAnimation()
    const connector = useAnimation()
    const connectorCircle = useAnimation()

    async function animation() {
        fullFrame.set({
            opacity: 1,
        })
        scrollFrame.set({
            scaleX: 0,
            scaleY: 0,
        })
        contentFrame.set({
            scaleX: 0,
            scaleY: 0,
        })
        scrollItem.set({
            y: 0,
            scaleX: 0,
        })
        contentItem.set({
            scaleX: 0,
        })
        connector.set({
            pathLength: 0,
        })
        connectorCircle.set({
            scale: 0,
        })

        await Promise.all([
            scrollFrame.start({
                scaleX: 1,
                scaleY: 1,
                originX: 0,
                originY: 0,
                transition: springAnimation,
            }),
            contentFrame.start({
                scaleX: 1,
                scaleY: 1,
                originX: 0,
                originY: 0,
                transition: { ...springAnimation.default, delay: 0.4 },
            }),
        ])

        await Promise.all([
            contentItem.start(i => ({
                scaleX: 1,
                transition: { ...springAnimation2, delay: 0.1 * i },
            })),
            connector.start({
                pathLength: 1,
                transition: { delay: 0.3, duration: 0.4 },
            }),
            connectorCircle.start({
                scale: 1,
                transition: { delay: 0.5 },
            }),
            scrollItem.start(i => ({
                scaleX: 1,
                transition: { ...springAnimation2, delay: 0.1 * i + 0.7 },
            })),
            scrollItem.start({
                y: -20,
                transition: { ...springAnimation.default, delay: 1.3 },
            }),
        ])

        return await fullFrame.start({
            opacity: 0,
        })
    }

    useLoop(animation)

    return (
        <motion.div
            animate={fullFrame}
            style={{
                width: 120,
                height: 60,
                position: "relative",
                background: "",
                overflow: "hidden",
            }}
        >
            <motion.div animate={scrollFrame} style={{ position: "absolute", left: 24, top: 7 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="36">
                    <path
                        d="M 0 4 C 0 1.791 1.791 0 4 0 L 26 0 C 28.209 0 30 1.791 30 4 L 30 32 C 30 34.209 28.209 36 26 36 L 4 36 C 1.791 36 0 34.209 0 32 Z"
                        fill={colors.animationForgroundLight}
                    ></path>
                    <path
                        d="M 30 16 C 31.105 16 32 16.895 32 18 C 32 19.105 31.105 20 30 20 C 28.895 20 28 19.105 28 18 C 28 16.895 28.895 16 30 16 Z"
                        fill={colors.animationForground}
                    ></path>
                </svg>
            </motion.div>
            <div
                style={{
                    ...scrollWrapperStyle,
                    width: 22,
                    height: 28,
                    overflow: "hidden",
                    left: 28,
                    top: 11,
                }}
            >
                <motion.div animate={scrollItem} custom={0} style={scrollItemStyle} />
                <motion.div animate={scrollItem} custom={1} style={scrollItemStyle} />
                <motion.div animate={scrollItem} custom={2} style={scrollItemStyle} />
                <motion.div animate={scrollItem} custom={3} style={scrollItemStyle} />
                <motion.div animate={scrollItem} custom={0} style={scrollItemStyle} />
            </div>
            <motion.div
                animate={contentFrame}
                style={{
                    ...scrollWrapperStyle,
                    width: 30,
                    height: 47,
                    borderRadius: 4,
                    backgroundColor: colors.animationForgroundLight,
                    padding: 4,
                    left: 66,
                    top: 7,
                }}
            >
                <motion.div animate={contentItem} custom={0} style={scrollItemStyle} />
                <motion.div animate={contentItem} custom={1} style={scrollItemStyle} />
                <motion.div animate={contentItem} custom={2} style={scrollItemStyle} />
                <motion.div animate={contentItem} custom={3} style={scrollItemStyle} />
            </motion.div>
            <div style={{ position: "absolute", left: 54, top: 20 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8">
                    <motion.path
                        animate={connector}
                        d="M 0 0.085 C 0 0.085 5.063 -0.79 6 3.085 C 6.938 6.96 12 6.085 12 6.085"
                        fill="transparent"
                        stroke={colors.animationForground}
                    ></motion.path>
                </svg>
            </div>
            <motion.div
                animate={connectorCircle}
                style={{
                    width: 4,
                    height: 4,
                    borderRadius: "50%",
                    backgroundColor: colors.animationForground,
                    position: "absolute",
                    left: 64,
                    top: 29,
                }}
            />
        </motion.div>
    )
}
