// actually more like ConstraintFixer

import { Rect, Size } from "framer"
import { ConstraintMask, ConstraintValues, DimensionType } from "framer"
import { commonValue } from "utils/commonValue"
import { isNumber } from "utils/typeChecks"

export interface Pins {
    left: number | null
    right: number | null
    top: number | null
    bottom: number | null
}

export interface CenterPins {
    centerAnchorX: number
    centerAnchorY: number
}

export interface SizeDimensionType {
    widthType: DimensionType
    heightType: DimensionType
}

export const updateConstrainedFrame = (
    frame: Rect,
    parentSize: Size | null,
    currentConstraints: ConstraintMask,
    constraintsLocked: boolean,
    pixelAlign: boolean = true
): Partial<ConstraintValues> => {
    const rect = pixelAlign ? Rect.pixelAligned(frame) : frame
    if (constraintsLocked) {
        return constrainedFrame(currentConstraints, rect, parentSize)
    } else {
        return guessConstrainedFrame(rect, parentSize, currentConstraints)
    }
}

export const updateConstrainedSize = (
    size: Partial<Size>,
    parentSize: Size | null,
    constraintValues: ConstraintValues
): Partial<ConstraintValues> => {
    // TODO: use autoSize and freeSpace ??
    const currentRect = ConstraintValues.toRect(constraintValues, parentSize, null, true)

    const result: Partial<ConstraintValues> = {}

    if (isNumber(constraintValues.aspectRatio)) {
        if (isNumber(size.width)) {
            size.height = size.width / constraintValues.aspectRatio
        } else if (isNumber(size.height)) {
            size.width = size.height * constraintValues.aspectRatio
        }
    }

    if (isNumber(size.width)) {
        if (parentSize && constraintValues.widthType === DimensionType.Percentage) {
            result.width = size.width / parentSize.width
        } else {
            result.widthType = DimensionType.FixedNumber
            result.width = size.width
        }
    }

    if (isNumber(size.height)) {
        if (parentSize && constraintValues.heightType === DimensionType.Percentage) {
            result.height = size.height / parentSize.height
        } else {
            result.heightType = DimensionType.FixedNumber
            result.height = size.height
        }
    }

    if (isNumber(result.width) && constraintValues.left !== null && constraintValues.right !== null) {
        result.right = constraintValues.right - (result.width - currentRect.width)
        result.widthType = DimensionType.FixedNumber
    }

    if (isNumber(result.height) && constraintValues.top !== null && constraintValues.bottom !== null) {
        result.bottom = constraintValues.bottom - (result.height - currentRect.height)
        result.heightType = DimensionType.FixedNumber
    }

    return result
}

export const topLeftConstrainedFrame = (rect: Rect, aspectRatio: number | null = null): Partial<ConstraintValues> => {
    return {
        top: rect.y,
        left: rect.x,
        width: rect.width,
        widthType: DimensionType.FixedNumber,
        height: rect.height,
        heightType: DimensionType.FixedNumber,
        aspectRatio,
    }
}

export const guessConstrainedFrame = (
    frame: Rect,
    parentSize: Size | null,
    currentConstraints?: ConstraintMask,
    fixedSize?: boolean,
    aspectRatioOverride?: number
): Partial<ConstraintValues> => {
    const constraints = guessConstraints(frame, parentSize, currentConstraints, fixedSize, aspectRatioOverride)
    return constrainedFrame(constraints, frame, parentSize)
}

const isFlex = (constraints: ConstraintMask) => {
    return (
        constraints.left === false &&
        constraints.right === false &&
        constraints.top === false &&
        constraints.bottom === false &&
        constraints.widthType === DimensionType.Percentage &&
        constraints.heightType === DimensionType.Percentage
    )
}

export const guessConstraints = (
    rect: Rect,
    parentSize: Size | null,
    currentConstraints?: ConstraintMask,
    isFixedSize?: boolean,
    aspectRatioOverride?: number
): ConstraintMask => {
    const aspectRatio = (currentConstraints && currentConstraints.aspectRatio) || aspectRatioOverride || null
    let fixedSize = (currentConstraints && currentConstraints.fixedSize) || false

    if (parentSize === null) {
        const { widthType = DimensionType.FixedNumber, heightType = DimensionType.FixedNumber } =
            currentConstraints ?? {}

        return {
            left: true,
            top: true,
            bottom: false,
            right: false,
            widthType: widthType === DimensionType.Auto ? widthType : DimensionType.FixedNumber,
            heightType: heightType === DimensionType.Auto ? heightType : DimensionType.FixedNumber,
            aspectRatio,
            fixedSize,
        }
    }

    if (currentConstraints !== undefined && isFlex(currentConstraints)) {
        return currentConstraints
    }

    const centerThreshold = 2
    const horizontalEdgeTreshold = 0.2 * parentSize.width
    const verticalEdgeTreshold = 0.2 * parentSize.height

    const center = Rect.center(rect)
    const centerX = Math.abs(center.x - parentSize.width / 2) < centerThreshold
    const centerY = Math.abs(center.y - parentSize.height / 2) < centerThreshold

    const pins = pinValues(rect, parentSize)

    let left = (pins.left || 0) < horizontalEdgeTreshold
    let right = (pins.right || 0) < horizontalEdgeTreshold
    let top = (pins.top || 0) < verticalEdgeTreshold
    let bottom = (pins.bottom || 0) < verticalEdgeTreshold

    if (!(left || right) && !centerX) {
        if ((pins.left || 0) <= (pins.right || 0)) {
            left = true
        } else {
            right = true
        }
    }

    if (!(top || bottom) && !centerY) {
        if ((pins.top || 0) <= (pins.bottom || 0)) {
            top = true
        } else {
            bottom = true
        }
    }

    const widthType = currentConstraints ? currentConstraints.widthType : DimensionType.FixedNumber
    const heightType = currentConstraints ? currentConstraints.heightType : DimensionType.FixedNumber

    const widthIsFlexible = widthType !== DimensionType.FixedNumber
    const heightIsFlexible = heightType !== DimensionType.FixedNumber

    if (widthIsFlexible && left && right) {
        if (centerX) {
            right = left = false
        } else {
            pins.left <= pins.right ? (right = false) : (left = false)
        }
    }

    if (heightIsFlexible && top && bottom) {
        if (centerY) {
            bottom = top = false
        } else {
            pins.top <= pins.bottom ? (bottom = false) : (top = false)
        }
    }

    if (isFixedSize !== undefined) {
        fixedSize = isFixedSize
    }

    if (fixedSize && left && right) {
        right = false
        left = false
    }
    if (fixedSize && top && bottom) {
        bottom = false
        top = false
    }
    if (isNumber(aspectRatio) && top && bottom && left && right) {
        if ((pins.top || 0) <= (pins.bottom || 0)) {
            bottom = false
        } else {
            top = false
        }
    }

    return { left, top, right, bottom, widthType, heightType, aspectRatio, fixedSize }
}

const constrainedFrame = (
    constraints: ConstraintMask,
    rect: Rect,
    parentSize: Size | null
): Partial<ConstraintValues> => {
    const { width, height } = rect
    const { widthType, heightType } = constraints

    if (parentSize === null) {
        return {
            width,
            height,
            left: rect.x,
            top: rect.y,
            right: null,
            bottom: null,
            widthType: widthType === DimensionType.Auto ? widthType : DimensionType.FixedNumber,
            heightType: heightType === DimensionType.Auto ? heightType : DimensionType.FixedNumber,
        }
    }

    const anchor = centerAnchor(rect, parentSize)
    const pins = pinValues(rect, parentSize)

    const update: Partial<ConstraintValues> = {
        centerAnchorX: anchor.x,
        centerAnchorY: anchor.y,
        top: constraints.top ? pins.top : null,
        bottom: constraints.bottom ? pins.bottom : null,
        left: constraints.left ? pins.left : null,
        right: constraints.right ? pins.right : null,
        widthType,
        heightType,
    }

    if (widthType === DimensionType.FixedNumber) {
        update.width = width
    } else if (widthType === DimensionType.Percentage) {
        update.width = rect.width / parentSize.width
    }

    if (heightType === DimensionType.FixedNumber) {
        update.height = height
    } else if (heightType === DimensionType.Percentage) {
        update.height = rect.height / parentSize.height
    }

    return update
}

export const canSetConstraint = (constraints: ConstraintMask, constraintKey: keyof ConstraintMask): boolean => {
    if (constraints.fixedSize) {
        if (["widthFactor", "heightFactor", "aspectRatio"].includes(constraintKey)) {
            return false
        }
    }

    return true
}

export const setConstraint = (
    constraints: ConstraintMask,
    constraintKey: "left" | "right" | "top" | "bottom",
    enable: boolean
): ConstraintMask => {
    if (!canSetConstraint(constraints, constraintKey)) {
        throw Error("Trying to solve constraint that can't be solved")
    }

    const result = { ...constraints, [constraintKey]: enable }

    if (!enable) {
        return result
    }

    const widthIsFlexible = constraints.widthType !== DimensionType.FixedNumber
    const heightIsFlexible = constraints.heightType !== DimensionType.FixedNumber

    if (
        (result.widthType === DimensionType.Auto && ["left", "right"].includes(constraintKey)) ||
        (result.heightType === DimensionType.Auto && ["top", "bottom"].includes(constraintKey)) ||
        result.fixedSize
    ) {
        inactivateOppsingPin(result, constraintKey)
    } else if (isNumber(result.aspectRatio)) {
        // if other three pins are active, fix by flipping opposing pin
        if (otherThreePinsAreActive(result, constraintKey)) {
            inactivateOppsingPin(result, constraintKey)
        }
    }
    if (result.left && result.right && widthIsFlexible) {
        result.widthType = DimensionType.FixedNumber
    }
    if (result.top && result.bottom && heightIsFlexible) {
        result.heightType = DimensionType.FixedNumber
    }

    return result
}

// Helpers

const inactivateOppsingPin = (constraints: ConstraintMask, constraintKey: keyof ConstraintMask) => {
    switch (constraintKey) {
        case "left":
            constraints.right = false
            break
        case "right":
            constraints.left = false
            break
        case "top":
            constraints.bottom = false
            break
        case "bottom":
            constraints.top = false
            break
    }
    return constraints
}

const otherThreePinsAreActive = (constraints: ConstraintMask, constraintKey: keyof ConstraintMask): boolean => {
    const sides = ["top", "bottom", "left", "right"].filter(side => {
        return side !== constraintKey
    })
    const values = sides.map(side => {
        return constraints[side]
    })
    const common = commonValue(values)
    return common === true
}

export const pinValues = (rect: Rect, parentSize: Size) => {
    return {
        top: rect.y,
        bottom: parentSize.height - rect.y - rect.height,
        left: rect.x,
        right: parentSize.width - rect.x - rect.width,
    }
}

const centerAnchor = (rect: Rect, parentSize: Size) => {
    const centerPoint = Rect.center(rect)

    // When a DOM layout-enabled node is centered, it will use the center anchor
    // for a left/top absolute position. In this case, it's not preferable to
    // have the center point sit on a half-pixel, because this could result in a
    // shift in position AND size between layout and painted rect. To minimize
    // the chance of this happening, we truncate the center point.
    centerPoint.x = Math.trunc(centerPoint.x)
    centerPoint.y = Math.trunc(centerPoint.y)

    // The calculated center anchor needs to have epsilon added, because later
    // it will go through multiplication by 100 to turn it into a percentage,
    // which will cause it to lose enough precision to make it unusable when
    // pixel-aligning rects computed using this anchor.
    //
    // Here's a concrete example:
    //
    // The center anchor for a frame with width: 43, positioned at x: 80 in a
    // parent of width: 186 is 0.43010752688172044. When that gets turned into a
    // string later, it will be multiplied by 100, which will result in the
    // string "43.01075268817204%". Notice that the last 4 is now missing. When
    // this gets parsed back into a float and divided by 100, it will result in
    // 0.4301075268817204, which is ever so slightly smaller than the original
    // number. When we compute a position based on this number, we do the
    // following:
    //      x = round(centerAnchorX * parentWidth - width / 2)
    // For the numbers we have above, this yields:
    //      x = round(79.99999999999999 - 43 / 2) = round(58.499999999999986)
    // Since this number is under 58.5, it will get rounded to 58, not 59. If we
    // do the same math using the original numbers, we'll get 58.5, which will
    // get rounded to 59. To prevent this situation, we add epsilon to the
    // anchor values, which will increase them just enough for the loss in
    // precision not to matter.
    return {
        x: centerPoint.x / parentSize.width + Number.EPSILON,
        y: centerPoint.y / parentSize.height + Number.EPSILON,
    }
}
