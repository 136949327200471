export type NodeID = string
export type MaybeNodeID = NodeID | null
export const NullID = null
export const InvalidID = "i-invalid"

const first = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"
const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_"

function f(): string {
    return first[Math.floor(Math.random() * first.length)]
}

function l(): string {
    return letters[Math.floor(Math.random() * letters.length)]
}

// most js engines should be able to inline upto 64 / 8 - 1 = 7 characters
export function randomID(): NodeID {
    return f() + l() + l() + l() + l() + l() + l() + l() + l()
}

export function nodeIDFromString(id: string): NodeID {
    if (id.startsWith("id_")) {
        id = id.slice(3)
    }
    return id.replace(/\\/g, "")
}

function fallbackEscape(id: NodeID): string {
    return id
}

let cssEscape = fallbackEscape
if (window["CSS"] && window["CSS"].escape) {
    cssEscape = window["CSS"].escape
}

export function stringFromNodeID(id: NodeID): string {
    return cssEscape("id_" + id)
}
