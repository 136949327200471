import { css } from "linaria"

export const customScrollbar = css`
    & > ::-webkit-scrollbar,
    * ::-webkit-scrollbar {
        width: 15px;
        background-color: inherit;
    }
    & > ::-webkit-scrollbar-track,
    * ::-webkit-scrollbar-track {
        background-color: inherit;
    }
    & > ::-webkit-scrollbar-thumb,
    * ::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
        background-clip: content-box;
        background-color: var(--scrollbar-thumb-background-color);
        border-radius: 1em;

        &:hover {
            background-color: var(--scrollbar-thumb-background-color-hover);
        }
    }
`
