import type { CanvasNode } from "document/models/CanvasTree"
import type { Shadow } from "document/models/Shadow"

export const shadowsKey: keyof WithShadow = "shadows"
export function withShadow(node: CanvasNode): node is CanvasNode & WithShadow {
    return shadowsKey in node
}
export interface WithShadow {
    shadows: readonly Shadow[] | undefined
}

export const shadowDefaults: WithShadow = {
    shadows: undefined,
}
