import type { ComponentType } from "react"
import { PropertyControls, ControlType, getPropertyControls, ActionControls, withMeasuredSize } from "framer"
import { getActionControls } from "../actions/actionControls"
import type {
    SandboxEntityMap,
    SandboxPackageInfo,
    SourceModuleExports,
    EntityInfo,
    FramerModuleExports,
    SandboxEntityDefinition,
    SandboxReactComponentDefinition,
} from "./types"
import { rewordCompileError } from "./rewordCompileError"
import { createErrorDefinition } from "./createErrorDefinition"
import { warn } from "./warn"
import { collectDesignComponents } from "./externalDocuments"
import { verifyPropertyControls, verifyActionControls } from "./verifyPropertyControls"
import { BUILT_IN_COMPONENT_IDENTIFIERS } from "./BuiltInFramerComponentIdentifier"
import type { EntityType, PackageIdentifier, DesignJSON, EntityIdentifier } from "../../host/componentLoader/types"
import { migrateDocument, minimumMigratableVersion } from "@framerjs/document-migrations"
import * as experiments from "../../utils/experiments"
import { isComponentUsingDeprecatedSizeProps } from "./isComponentUsingDeprecatedSizeProps"
import { warnOnce } from "@framerjs/shared"
import { localPackageFallbackIdentifier } from "./localPackageFallbackIdentifier"

/** Information representing the context of one or more components. */
export interface ComponentsContext {
    packageInfo: SandboxPackageInfo

    file?: string
    identifierPrefix?: string
    /** LocalID of a local module exporting this entity. */
    localId?: string
}

function isNumber(value: unknown): value is number {
    return typeof value === "number" && Number.isFinite(value)
}

export function collectEntities(packageInfo: SandboxPackageInfo): SandboxEntityMap {
    const entityDefinitions: SandboxEntityMap = {}

    const { componentsJson, depth, designJson, exportsObject, sourceModules } = packageInfo

    // TODO-MODULES: Remove this condition once we can load both legacy components and modules together.
    if (experiments.isOn("codeModules") && depth === 0) {
        // Ignore the "local" entities coming from the bundle, they will be fulfilled by the Modules
        return entityDefinitions
    }

    // Pull in code components defined in the package.
    const files = Object.keys(sourceModules)
    if (files.length > 0) {
        for (const file of files) {
            Object.assign(entityDefinitions, loadSourceModule(packageInfo, file))
        }
    } else if (componentsJson) {
        // If there were no sourceModules, inspect the manual info instead.
        // TODO: Merge manual info to find more properties.
        const context: ComponentsContext = { packageInfo }
        Object.assign(entityDefinitions, entitiesFromExports(exportsObject, componentsJson, context))
    }

    // Pull in design components that are *not* from the local package.
    if (depth > 0 && designJson) {
        const components = designComponentsFromPackage(designJson, packageInfo.name)
        const treeVersion = isNumber(designJson.version) ? designJson.version : 0
        const migratableVersion = Math.max(treeVersion, minimumMigratableVersion)

        for (const component of components) {
            const identifier = component.id

            try {
                const migrated = migrateDocument({
                    root: component,
                    version: migratableVersion,
                })

                entityDefinitions[identifier] = {
                    class: migrated.root,
                    depth,
                    file: "design/document.json",
                    identifier,
                    name: component.name || "",
                    packageIdentifier: packageInfo.name,
                    properties: {},
                    type: "master",
                }
            } catch {
                warn(`Failed to migrate component from package: ${packageInfo.name}`)
            }
        }
    }

    return entityDefinitions
}

function loadSourceModule(packageInfo: SandboxPackageInfo, file: string): SandboxEntityMap {
    let moduleExports: SourceModuleExports
    try {
        moduleExports = packageInfo.sourceModules[file]() || {}
    } catch (error) {
        moduleExports = { error }
    }

    // When we have a compile error in the build service we export a single variable "error" from the module describing the error.
    // The problem is that if the user wants to export a variable "error" we would need to distinguish that from the error message of the build service.
    // Luckily if we successfully compile a module from the user we also add an "__info__" export which we can use to check if we really have a compile error.
    if (moduleExports.error && !moduleExports.__info__) {
        const { error } = moduleExports
        let errorMessage = typeof error === "string" ? error : error.message
        errorMessage = rewordCompileError(errorMessage)
        warn(`Error in file '${file}':`, error)
        const identifier = prefixIdentifier(packageInfo, file)
        const def = createErrorDefinition(identifier, errorMessage, {
            depth: packageInfo.depth,
            packageIdentifier: packageInfo.name,
        })
        return { [identifier]: def }
    }

    if (!moduleExports.__info__) return {}

    return entitiesFromExports(moduleExports, moduleExports.__info__, {
        packageInfo,
        file,
        identifierPrefix: `${file}_`,
    })
}

function entitiesFromExports(
    exportsObject: (FramerModuleExports | SourceModuleExports) & { [componentName: string]: any },
    entityInfos: EntityInfo[],
    context: ComponentsContext
): SandboxEntityMap {
    const entityDefinitions: SandboxEntityMap = {}

    for (const entityInfo of entityInfos) {
        const entity = exportsObject[entityInfo.name]
        if (!entity) {
            warn(`Entity '${entityInfo.name}' defined but not found in exports`)
            continue
        }

        const entityDefinition = entityDefinitionFromInfo(entityInfo, entity, context)
        entityDefinitions[entityDefinition.identifier] = entityDefinition
    }

    return entityDefinitions
}

function prefixIdentifier(packageInfo: SandboxPackageInfo, identifier: string, prefix: string = "") {
    // Prefix all non-local identifiers with the package name followed by a slash.
    if (packageInfo.depth > 0) {
        prefix = `${packageInfo.name}/${prefix}`
    }
    return `${prefix}${identifier}`
}

function designComponentsFromPackage(json: DesignJSON, packageIdentifier: PackageIdentifier) {
    // Extraction of design components happens in the build process and sets the `components` key.
    if ("components" in json) {
        return json.components
    }
    // If we get here, we're dealing with a legacy vendors.js.
    const components: any[] = []
    collectDesignComponents(json.root, packageIdentifier, components)
    return components
}

export function entityDefinitionFromInfo(
    info: EntityInfo,
    entity: any,
    { identifierPrefix, packageInfo, file, localId }: ComponentsContext
): SandboxEntityDefinition {
    let controls: PropertyControls<any> | ActionControls<any> = {}
    let userInterfaceName = entity.userInterfaceName || info.name
    let identifier
    if (localId) {
        // Handle local modules
        identifier = `local-module:${localId}:${info.name}`
    } else {
        identifier = prefixIdentifier(packageInfo, info.name, identifierPrefix)
    }
    let { type } = info

    // Built-in components aren't processed with Analyzer during the build
    // instead their entityInfo is taken from the Library's package.json
    // that info may lack "type" field for some Library versions,
    // so if that is the case we manually assign `type` to such components
    if (!type) {
        if (!BUILT_IN_COMPONENT_IDENTIFIERS.includes(identifier)) {
            warn(`Entity info '${info.name}' doesn't have "type", assuming "component"`)
        }
        type = "component"
    }

    if (type === "action") {
        const actionInfo = getActionControls(entity)
        if (actionInfo && actionInfo.controls) {
            controls = verifyActionControls(actionInfo.controls)
        }
        if (actionInfo && actionInfo.title) {
            userInterfaceName = actionInfo.title
        }
    } else {
        const componentControls = getPropertyControls(entity)
        if (componentControls) {
            controls = verifyPropertyControls(componentControls)
        }
        if (withDisplayName(entity)) {
            userInterfaceName = entity.displayName
        }
        if (info.children && !controls["children"]) {
            controls["children"] = { title: "Content", type: ControlType.ComponentInstance }
        }
    }

    const commonFields = {
        class: entity,
        depth: packageInfo.depth,
        file: file || "",
        identifier,
        name: userInterfaceName,
        packageIdentifier: packageInfo.name,
        properties: controls,
        type,
    }

    if (isReactComponent(entity, type)) {
        const reactComponentDefinition: SandboxReactComponentDefinition = Object.assign(commonFields, {
            type: "component" as const,
            defaultProps: entity.defaultProps,
            annotations: info.annotations,
        })

        // Legacy (build-service-based) components will be automatically wrapped
        // in the withMeasuredSize HOC, and may therefore render twice to ensure
        // that a numeric value can be provided as the `width` and `height` prop
        // to the component. This ensures that we don't break existing
        // components as users transition away from using these props.
        //
        // If we manage to successfully detect that the component is using the
        // width and height prop in its source, we'll show a warning in the
        // console.

        if (shouldUseMeasuredSizeWrapper(identifier)) {
            if (
                experiments.isOn("deprecatedCodeComponentPropsCheck") &&
                packageInfo.name !== "@framer/framer.default" &&
                isComponentUsingDeprecatedSizeProps(entity, identifier)
            ) {
                warnOnce(
                    `Deprecation notice: the component "${userInterfaceName}" from ${
                        packageInfo.name === localPackageFallbackIdentifier
                            ? `this project`
                            : `the package "${packageInfo.displayName}"`
                    } seems to be using the "width" or "height" props. These props are now deprecated and will no longer be passed into component instances. This component will run in compatibility mode, which may affect its performance. See https://www.framer.com/support/using-framer/deprecated-width-height/ for details.`
                )
            }
            reactComponentDefinition.class = withMeasuredSize(entity)
        }

        return reactComponentDefinition as SandboxEntityDefinition
    }

    return commonFields
}

function isReactComponent(entity: unknown, type: EntityType | undefined): entity is ComponentType<unknown> {
    return type === "component"
}

interface WithDisplayName {
    displayName: string
}

function isObject(value: unknown): value is { [key: string]: unknown } {
    return !!value && typeof value === "object"
}

function isFunction(value: unknown): value is Function {
    return typeof value === "function"
}

function withDisplayName<T>(entity: T): entity is T & WithDisplayName {
    if (!isObject(entity) && !isFunction(entity)) return false
    if (!("displayName" in entity)) return false
    const displayName = entity.displayName
    return typeof displayName === "string" && displayName.trim().length > 0
}

function shouldUseMeasuredSizeWrapper(identifier: EntityIdentifier) {
    if (!experiments.isOn("deprecatedCodeComponentSizeWrapper")) return false

    // Skip module-backed components
    if (identifier.startsWith("local-module:")) return false

    // Skip built-ins
    return !BUILT_IN_COMPONENT_IDENTIFIERS.includes(identifier)
}
