import { css } from "linaria"
import { colors, dimensions, cursors } from "../tokens"

export const numberTicker = css`
    display: flex;
    width: 17px;
    flex-direction: column;
    align-self: stretch;

    & div {
        display: flex;
        height: 1px;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        border-radius: 0 ${dimensions.inputRadius - 1}px ${dimensions.inputRadius - 1}px 0;
        color: ${colors.inputIcon};
        cursor: ${cursors.button};
        transform: translateY(0);
        transition: transform 0.2s ease, color 0.2s ease;
        will-change: transform;

        :hover,
        :active {
            color: ${colors.inputIconHover};
        }
    }
`

export const tickerUp = css`
    :active:hover {
        transform: translateY(-2px);
    }
`

export const tickerDown = css`
    :active:hover {
        transform: translateY(2px);
    }
`

export const numberTickerHidden = css`
    display: none;
`
