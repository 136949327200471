import BooleanShapeNode from "./shapes/BooleanShapeNode"
import ShapeGroupNode from "./ShapeGroupNode"
import RectangleShapeNode from "./shapes/RectangleShapeNode"
import OvalShapeNode from "./shapes/OvalShapeNode"
import StarShapeNode from "./shapes/StarShapeNode"
import PolygonShapeNode from "./shapes/PolygonShapeNode"
import FrameNode from "./FrameNode"
import ShapeContainerNode from "./ShapeContainerNode"
import PathNode from "./shapes/PathNode"
import { SVGNode } from "./SVGNode"
import { TextNode } from "./TextNode"
import { RootNode } from "./RootNode"
import type { ImmutableNode } from "./MutableNode"
import CodeComponentNode from "./CodeComponentNode"

export type VectorNode =
    | PathNode
    | BooleanShapeNode
    | ShapeGroupNode
    | RectangleShapeNode
    | OvalShapeNode
    | StarShapeNode
    | PolygonShapeNode
export type DrawableNode = FrameNode | ShapeContainerNode | SVGNode | TextNode<any> | VectorNode | CodeComponentNode
export type TreeNode = RootNode | DrawableNode

export function isTreeNode(node: ImmutableNode): node is TreeNode {
    return isDrawableNode(node) || node instanceof RootNode
}

// Helper function for compiler, works around a typescript design limitation, c.f.,
// https://github.com/Microsoft/TypeScript/issues/10422#issuecomment-265311779
export function isDrawableNode(node: ImmutableNode): node is DrawableNode {
    return (
        node &&
        (node instanceof FrameNode ||
            node instanceof ShapeContainerNode ||
            node instanceof SVGNode ||
            node instanceof TextNode ||
            node instanceof CodeComponentNode ||
            isVectorNode(node))
    )
}

export function isVectorNode(node: ImmutableNode): node is VectorNode {
    return (
        node &&
        (node instanceof PathNode ||
            node instanceof BooleanShapeNode ||
            node instanceof ShapeGroupNode ||
            node instanceof RectangleShapeNode ||
            node instanceof OvalShapeNode ||
            node instanceof StarShapeNode ||
            node instanceof PolygonShapeNode)
    )
}
