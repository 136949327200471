import type { PreviewSettings } from "@framerjs/framer-services"

export function hasNodeChanged(
    prev: PreviewSettings.Node | undefined,
    next: PreviewSettings.Node | undefined
): boolean {
    if (!prev || !next) return true

    // Check for change in canvas nodes
    if (prev.type === "canvas" && next.type === "canvas" && prev.id === next.id) return false

    // Check for change in code editor nodes
    if (prev.type === "codeEditor" && next.type === "codeEditor" && prev.componentId === next.componentId) return false

    return true
}
