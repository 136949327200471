import { FeatureSet, createFeatureComponent, useCondition } from "./FeatureSet"
import { platform } from "./platform"

import { defaultExperiments, desktopExperiments, employeeExperiments, ExperimentName } from "@framerjs/app-config"

const currentExperiments = platform.is("current", "studio") ? desktopExperiments : defaultExperiments
export const experiments = new FeatureSet<ExperimentName>(currentExperiments)
export const Experiment = createFeatureComponent(experiments)

export function useExperimentIsOn(experiment: ExperimentName): boolean {
    return useCondition(experiments, experiment, variant => variant === "on")
}

// Experiment overrides for Framer employees.
if (window?.["user"]?.isFramerEmployee === true) {
    experiments.update(employeeExperiments)
}

// Dynamic experiments injected by Framer Desktop.
experiments.update(window?.["experiments"] ?? {})

// Experiment overrides on the local machine.
try {
    const config = JSON.parse(localStorage.experiments || "{}")
    experiments.update(config)
} catch (e) {
    // Ignore errors.
}

// Experiment overrides for the current session.
try {
    const config = JSON.parse(sessionStorage.experiments || "{}")
    let hasExperimentsInQS = false
    for (const item of new URLSearchParams(window.top.location.search).getAll("experiment")) {
        const [experiment, value = "on"] = item.split("=")
        config[experiment] = value
        hasExperimentsInQS = true
    }
    if (hasExperimentsInQS) {
        // Update session storage.
        sessionStorage.experiments = JSON.stringify(config)
    }
    experiments.update(config)
} catch (e) {
    // Ignore errors.
}
