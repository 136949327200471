import { ControlType, PropertyControls, Rect } from "framer"
import type { NodeID } from "document/models/CanvasTree/nodes/NodeID"
import type { ComponentVariant, EnabledVariantGestures, Imports, ImportBinding, PropertyMap } from "./types"
import type { ExportSpecifier } from "modules/compiler"
import type { Transition } from "document/models/Transition"
import { buildJSX, Tag } from "document/models/CanvasTree/utils/buildJSX"
import { gestureKey } from "./utils/helpers"
import { upgradeTag } from "./utils/upgradeTag"
import { initialVariantKey, serializePropertyControls } from "./utils/serializePropertyControls"
import { assert } from "@framerjs/document-migrations/src/utils/assert"
import { escapeControlTypes, unwrapAndEscapeInlineHandlers, unwrapAndEscapeInlineVariables } from "./utils/inlineValues"
import { localModuleEntityIdentifierForStableName } from "modules/localModuleEntityIdentifierForStableName"
import { isUndefined } from "utils/typeChecks"
import { activeVariantKey } from "document/models/CanvasTree/nodes/CanvasComponentNode"

// Exported for tests
export const borderRules =
    '[data-border="true"]::after { content: ""; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}'

export const enabledGestureKey = "enabledGestures"
export const variantPropsKey = "variantProps"
export const transitionsMapKey = "transitions"
export const cycleOrderKey = "cycleOrder"

export const MEASURED_PREFIX = "Measured"

const DEFAULT_VARIANT_MAP = "const humanReadableVariantMap = {};"

const DEFAULT_IMPORTS = [
    `import * as React from "react";`,
    `import { addPropertyControls, withCSS, callEach, cx, useVariantState, withMeasuredSize, LayoutGroup, Image, CycleVariantState, ControlType } from "framer";`,
].join("\n")

const GESTURE_HANDLERS = [
    "onHoverStart={() => setGestureState({isHovered: true})}",
    "onHoverEnd={() => setGestureState({isHovered: false})}",
    "onTapStart={() => setGestureState({isPressed: true})}",
    "onTap={() => setGestureState({isPressed: false})}",
].join(" ")

type Templates = (string | boolean | void)[]

function joinWithLinebreak(templates: Templates, lineBreak: string = "\n") {
    return templates.filter(v => Boolean(v)).join(lineBreak)
}

const combineTemplates = (...templates: Templates) => ({
    doubleSpaced: () => joinWithLinebreak(templates, "\n\n"),
    singleSpaced: () => joinWithLinebreak(templates),
})

export function generatedComponentIdentifier(canvasComponentNodeId: NodeID) {
    return localModuleEntityIdentifierForStableName({
        name: canvasComponentNodeId,
        type: "canvasComponent",
        exportSpecifier: "default",
    })
}

function escapeAndUnwrap(template: string): string {
    return unwrapAndEscapeInlineVariables(unwrapAndEscapeInlineHandlers(template))
}

/**
 * Properties of SnippetData can be updated on the GeneratedComponent, and
 * instantly atomically serialized into snippets stored on
 * `GeneratedComponent.SerializedSnippets`. However, serializing to a snippet
 * may not be side-effect free, and can result in `SerializationData` being
 * updated.
 */
interface SnippetData {
    dynamicImports?: Imports
    defaultProps?: Partial<{ rect: Rect }>
    legacyExportIdentifiers?: Set<string>
    displayName?: string
    controls?: PropertyControls
}

/**
 * Properties of SerializationData can be updated on the GeneratedComponent but
 * won't be used until the component is serialized by calling
 * `GeneratedComponent.serialize()`. Properties on SerializationData can also be
 * updated as a side effect of creating SnippetData when creating the snippet
 * creates additional data that can't be serialized until
 * `GeneratedComponent.serialize()` is called because it can only be serialized
 * when all the data is known.
 */
interface SerializationData {
    packageDependencies?: Set<string>
    transition?: Partial<Transition>
    reactProps?: string
    variableMap?: Record<string, string>
    cssRules?: string[]
    cycleOrder?: string[]
}

/**
 * For consumers of GeneratedComponent, allow them to update any data type into
 * the component without separating the different types. GeneratedComponent owns
 * this logic.
 */
export interface ComponentData extends SnippetData, SerializationData {}

interface SerializedSnippets extends Record<keyof Omit<SnippetData, "legacyExportIdentifiers">, string> {
    variantMap: string
    defaultExport: string
    measuredComponents: string
}
export class GeneratedComponent {
    #tag: Tag
    #initialVariant: string
    #variants = new Map<NodeID, ComponentVariant>()

    data: SerializationData = {}

    /**
     * Some ComponentData does not need to be stored on the GeneratedComponent,
     * but can be immediately serialized to a simple snippet string when it's
     * updated. These snippets will only be updated if the property associated
     * the snippet is updated by calling `GeneratedComponent.updateComponentData()`.
     */
    serializedSnippets: Partial<SerializedSnippets> = {}

    constructor(private readonly identifier: string, initialVariant: string) {
        this.#initialVariant = initialVariant
    }

    get name() {
        return `Framer${this.identifier}`
    }

    updateTag(tag: Tag) {
        this.#tag = tag
    }

    #lastControlKeys = new Set<string>()
    #lastVariantTitles = ""

    /**
     * VariantsStore processes the CanvasComponentNode's PropertyControls
     * atomically from both the Primary Variant's tree and Replica Variants'
     * overrides. Since generation of components in the VariantsStore is
     * asynchronous and heavily debounced, it's possible for the VariantsStore
     * to attempt to serialize a generated component after a variable is added
     * to a variant, but before the VariantsStore has generated new Property
     * Controls for the component. If this happens, the result of serialization
     * will not compile.
     *
     * To ensure this doesn't happen, whenever VariantsStore performs a complete
     * serialization, we check that the PropertyControls the component was last
     * serialized with are backwards compatible to the current controls. In this
     * case "backwards compatible" means that every key of the new property
     * controls was present in the previous property controls. If they are not
     * compatible, we will serialize new controls into the component output to
     * ensure that every variable used in the template has a React prop
     * declaration.
     *
     * Additionally, name of a variant has changed between serializations it
     * will not have triggered the CanvasComponentNode to be reprocessed by the
     * VariantsStore. To account for this we also have to regenerate the
     * Property Controls snippet if the optionTitles for each variant have
     * changed since our last serialization of the Property Controls snippet.
     *
     * Finally, we also ensure that the property controls actual variant ids
     * haven't changed. This should never happen, because unlike variant names
     * (which only trigger rebuilds for the relevant variant nodes) changes to
     * the children of a CanvasComponentNode do trigger the Property Controls to
     * be regenerated, but we make sure they're up to date just to be safe.
     */
    areNextControlsSubset(nextControls: PropertyControls = {}) {
        const hasEveryProp = Object.keys(nextControls).every(key => this.#lastControlKeys.has(key))

        const variantControl = nextControls[activeVariantKey]
        if (variantControl?.type !== ControlType.Enum || !variantControl.optionTitles) return hasEveryProp

        const hasEveryVariantTitle = variantControl.optionTitles.join("") === this.#lastVariantTitles

        return hasEveryProp && hasEveryVariantTitle
    }

    updateComponentData(attributes: Partial<ComponentData>) {
        for (const key in attributes) {
            switch (key) {
                case "dynamicImports":
                    this.generateDynamicImportsSnippet(attributes.dynamicImports)
                    break
                case "legacyExportIdentifiers":
                    this.generateMeasuredComponentsSnippet(attributes.legacyExportIdentifiers)
                    break
                case "displayName":
                    this.generateDisplayNameSnippet(attributes.displayName)
                    break
                case "defaultProps":
                    this.generateDefaultPropsSnippet(attributes.defaultProps)
                    break
                case "controls":
                    this.generatePropertyControlsSnippet(attributes.controls)
                    break
                default:
                    this.data[key] = attributes[key]
                    break
            }
        }
    }

    updateVariant(variantId: NodeID, variant: ComponentVariant): void {
        this.#variants.set(variantId, variant)
    }

    removeVariant(variantId: NodeID): void {
        this.#variants.delete(variantId)
    }

    hasTag = (): boolean => !!this.#tag
    hasVariant = (variantId: NodeID): boolean => this.#variants.has(variantId)
    hasDisplayName = (): boolean => !!this.serializedSnippets.displayName

    serialize(): string | undefined {
        assert(this.#tag, "GeneratedComponent: A generated component must have a Tag")

        // Even if we haven't processed property controls, we need to ensure a
        // defaultExport is serialized.
        const defaultExport = this.serializedSnippets.defaultExport || this.serializeDefaultExport()

        // Always serialize cycleOrder. It's cheaper to regenerate than track if
        // it's changed.
        const cycleOrder = this.serializeCycleOrder()
        const hasCycleOrder = !isUndefined(cycleOrder)

        // Since all serialization that is atomic should have already been
        // performed and cached, now we only have to serialize data that relies
        // on the results of getSharedVariantData.
        const { enabledGestures, combinedTransitions, componentFunction, css } = this.serializeWithSharedVariantData(
            hasCycleOrder
        )

        return combineTemplates(
            DEFAULT_IMPORTS,
            this.serializedSnippets.dynamicImports,
            this.serializedSnippets.measuredComponents,
            enabledGestures,
            cycleOrder,
            this.serializedSnippets.variantMap || DEFAULT_VARIANT_MAP,
            combinedTransitions,
            componentFunction,
            css,
            defaultExport,
            this.serializedSnippets.displayName,
            this.serializedSnippets.defaultProps,
            this.serializedSnippets.controls
        ).doubleSpaced()
    }

    serializeWithSharedVariantData(hasCycleOrder: boolean = false) {
        const {
            cssRules: additionalCSSRules,
            enabledVariantGestures,
            variantProps,
            variantTransitions,
        } = getSharedVariantData(this.#variants)

        return {
            enabledGestures: this.serializeEnabledGestures(enabledVariantGestures),
            combinedTransitions: this.serializeTransitions(variantTransitions),
            componentFunction: this.serializeComponent(
                this.serializeLocalState(variantProps, enabledVariantGestures, hasCycleOrder),
                this.serializeJSX()
            ),
            css: this.serializeCSS(additionalCSSRules),
        }
    }

    private generateDynamicImportsSnippet(imports: Imports | undefined): void {
        const dynamicImports = buildImports(imports)

        this.serializedSnippets.dynamicImports =
            dynamicImports.length > 0 ? joinWithLinebreak(dynamicImports) : undefined
    }

    private generateMeasuredComponentsSnippet(legacyExportIdentifiers: Set<string> | undefined): void {
        this.serializedSnippets.measuredComponents = undefined

        if (legacyExportIdentifiers && legacyExportIdentifiers.size > 0) {
            const measuredHoCs = []
            for (const exportSpecifier of legacyExportIdentifiers) {
                measuredHoCs.push(`const ${MEASURED_PREFIX}${exportSpecifier} = withMeasuredSize(${exportSpecifier});`)
            }
            if (measuredHoCs.length > 0) {
                this.serializedSnippets.measuredComponents = joinWithLinebreak(measuredHoCs)
            }
        }
    }

    private generateDefaultPropsSnippet(defaultProps: SnippetData["defaultProps"]): void {
        if (!defaultProps) {
            this.serializedSnippets.defaultProps = undefined
            return
        }

        const { rect } = defaultProps

        this.serializedSnippets.defaultProps = rect
            ? `${this.name}.defaultProps = { width: ${rect.width}, height: ${rect.height} };`
            : undefined
    }

    private generatePropertyControlsSnippet(controls: PropertyControls | undefined): void {
        this.#lastControlKeys = controls ? new Set(Object.keys(controls)) : new Set()
        const variantControl = controls?.[activeVariantKey]
        if (variantControl?.type === ControlType.Enum && variantControl.optionTitles) {
            this.#lastVariantTitles = variantControl.optionTitles.join("")
        } else {
            this.#lastVariantTitles = ""
        }

        const {
            controls: modifiedControls,
            props,
            propVariables,
            humanReadableVariantMap: variantMap,
        } = serializePropertyControls(controls)

        this.data.reactProps = props

        const hasControls = Object.keys(modifiedControls).length > 0
        const hasVariantMap = Object.keys(variantMap).length > 0

        this.serializedSnippets.defaultExport = this.serializeDefaultExport(propVariables)

        this.serializedSnippets.controls = hasControls
            ? `addPropertyControls(${this.name}, ${escapeControlTypes(modifiedControls)});`
            : undefined

        this.serializedSnippets.variantMap = hasVariantMap
            ? `const humanReadableVariantMap = ${JSON.stringify(variantMap)};`
            : undefined
    }

    private generateDisplayNameSnippet(name: string = "Generated Component"): void {
        this.serializedSnippets.displayName = `${this.name}.displayName = "${name}";`
    }

    private serializeCycleOrder() {
        const { cycleOrder } = this.data
        const hasCycleOrder = cycleOrder && cycleOrder.length >= 2

        return hasCycleOrder ? `const ${cycleOrderKey} = ${JSON.stringify(cycleOrder)};` : undefined
    }

    private serializeTransitions(variantTransitions: Record<NodeID, Partial<Transition>>): string {
        return `const ${transitionsMapKey} = ${JSON.stringify({
            default: this.data.transition,
            ...variantTransitions,
        })};`
    }

    private serializeJSX(): string {
        const jsx = buildJSX(this.#tag, upgradeTag(this.#variants))
        return combineTemplates(
            `<LayoutGroup id={layoutId}>`,
            `<motion.div {...restProps} initial={${initialVariantKey}} animate={variants} className={cx(className, classNames)} style={style} ${GESTURE_HANDLERS} ref={ref}>`,
            escapeAndUnwrap(jsx),
            `</motion.div>`,
            `</LayoutGroup>`
        ).singleSpaced()
    }

    private serializeLocalState(
        variantProps: PropertyMap,
        enabledVariantGestures: EnabledVariantGestures,
        hasCycleOrder: boolean
    ): string {
        const hasVariantProps = !!Object.keys(variantProps).length
        const hasEnabledGestures = !!Object.keys(enabledVariantGestures).length
        const hookArguments = [`defaultVariant: "${this.#initialVariant}"`, "variant", "transitions"]

        if (hasVariantProps) hookArguments.push(variantPropsKey)
        if (hasEnabledGestures) hookArguments.push(enabledGestureKey)
        if (hasCycleOrder) hookArguments.push(cycleOrderKey)

        const stateHook = `useVariantState({ ${hookArguments.join(", ")} });`
        return combineTemplates(
            hasVariantProps && `const ${variantPropsKey} = ${escapeAndUnwrap(JSON.stringify(variantProps))};`,
            `const { variants, baseVariant, gestureVariant, classNames, transition, setVariant, setGestureState, addVariantProps } = ${stateHook}`
        ).singleSpaced()
    }

    private serializeComponent(localState: string, jsx: string): string {
        const props = [
            `style = { width: "100%", height: "100%", position: "relative" }`,
            "className",
            "layoutId",
            "width", // Width and height are unused, but named here to prevent being spread onto the wrapping element.
            "height",
            `${initialVariantKey}: outerVariant = "${this.#initialVariant}"`,
        ]

        const { reactProps } = this.data
        if (reactProps) props.push(reactProps)

        const mapVariantNameToId = combineTemplates(
            `const outerVariantId = humanReadableVariantMap[outerVariant];`,
            `const variant = outerVariantId || outerVariant;`
        ).singleSpaced()

        return combineTemplates(
            `const Component = React.forwardRef(function({ ${props.join(", ")}, ...restProps }, ref) {`,
            mapVariantNameToId,
            localState,
            `return (${jsx});`,
            `});`
        ).doubleSpaced()
    }

    private serializeCSS(additionalCSSRules: string[] | undefined): string | void {
        /**
         * @FIXME
         *
         * Border can also be effected by multiple components. Consider using
         * `CSS.setDocumentStyles()`.
         */
        const css = [borderRules]

        if (this.data.cssRules) css.push(...this.data.cssRules)
        if (additionalCSSRules) css.push(...additionalCSSRules)

        return `const css = \`${joinWithLinebreak(css)}\``
    }

    private serializeDefaultExport(propVariables?: Record<string, string>) {
        const hasVariables = propVariables && Object.keys(propVariables).length > 0

        return combineTemplates(
            `/**`,
            ` * This is a generated Framer component.`,
            hasVariables && ` * @framervariables ${JSON.stringify(propVariables)}`,
            ` */`,
            `const ${this.name}: React.ComponentType = withCSS(Component, css);`,
            `export default ${this.name};`
        ).singleSpaced()
    }

    private serializeEnabledGestures(enabledVariantGestures: EnabledVariantGestures): string | void {
        if (!enabledVariantGestures || Object.keys(enabledVariantGestures).length === 0) return

        return `const ${enabledGestureKey} = ${JSON.stringify(enabledVariantGestures)};`
    }
}

function getSharedVariantData(variants: Map<NodeID, ComponentVariant>) {
    const cssRules: string[] = []
    const enabledVariantGestures: EnabledVariantGestures = {}
    const variantProps: PropertyMap = {}
    const variantTransitions: Record<NodeID, Partial<Transition>> = {}

    for (const [variantId, variant] of variants) {
        const gesture = variant.gesture

        // Handle gestures
        if (gesture) {
            const into = enabledVariantGestures[gesture.topLevel] ?? {}
            into[gesture.type] = true
            enabledVariantGestures[gesture.topLevel] = into
        }

        // Handle layout styles as css rules.
        if (variant.cssRules) cssRules.push(...variant.cssRules)

        const key = gestureKey(gesture, variantId)

        // Handle the shared variant transition.
        if (variant.transition) variantTransitions[key] = variant.transition

        // Handle component props and event handlers.
        if (variant.nodeProps && Object.keys(variant.nodeProps).length) variantProps[key] = variant.nodeProps
    }

    // Remove duplicate cssRules before returning an array.
    return { cssRules: Array.from(new Set(cssRules)), enabledVariantGestures, variantProps, variantTransitions }
}

function buildImports(importMap: Imports | undefined) {
    if (!importMap || importMap.size === 0) return []
    const imports = new Set<string>()

    for (const [url, mapping] of importMap) {
        let defaultImportSpecifier: string | undefined
        const namedImportParts = []
        for (const [exportSpecifier, importBinding] of mapping) {
            if (exportSpecifier === "default") {
                defaultImportSpecifier = importBinding
            } else {
                namedImportParts.push(
                    exportSpecifier === importBinding ? exportSpecifier : `${exportSpecifier} as ${importBinding}`
                )
            }
        }
        const namedImportSpecifiers = namedImportParts.length > 0 ? `{ ${namedImportParts.join(", ")} }` : undefined

        const importSpecifiers: string[] = []
        if (defaultImportSpecifier) importSpecifiers.push(defaultImportSpecifier)
        if (namedImportSpecifiers) importSpecifiers.push(namedImportSpecifiers)

        imports.add(`import ${importSpecifiers.join(", ")} from "${url}";`)
    }

    return Array.from(imports)
}

export function addImport(
    imports: Imports | undefined,
    url: string,
    { exportSpecifier, importBinding }: { exportSpecifier: ExportSpecifier; importBinding?: ImportBinding }
) {
    if (!imports) return
    const mapping = imports.get(url) || new Map([[exportSpecifier, importBinding ?? exportSpecifier]])
    imports.has(url) ? mapping.set(exportSpecifier, importBinding ?? exportSpecifier) : imports.set(url, mapping)
}
