import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springAnimation } from "./utils"
import { colors } from "../tokens"

const width = 50
const height = 50
const cardHeight = 6
const borderRadius = 4

export const AnimationLayers = React.memo(function AnimationLayers() {
    const layerA = useAnimation()
    const layerB = useAnimation()
    const layerC = useAnimation()

    async function animation() {
        await Promise.all([
            layerA.start({
                opacity: 1,
                scale: 1,
                transition: springAnimation,
            }),
            layerB.start({
                opacity: 1,
                scale: 1,
                transition: springAnimation,
            }),
            layerC.start({
                opacity: 1,
                scale: 1,
                transition: springAnimation,
            }),
        ])
        await Promise.all([
            layerA.start({
                opacity: 0.7,
                scale: 0.95,
                transition: springAnimation,
            }),
            layerB.start({
                opacity: 1,
                scale: 1.2,
                transition: springAnimation,
            }),
            layerC.start({
                opacity: 0.7,
                scale: 0.95,
                transition: springAnimation,
            }),
        ])
        await Promise.all([
            layerA.start({
                top: 21,
                opacity: 1,
                scale: 1,
                transition: springAnimation,
            }),
            layerB.start({
                opacity: 1,
                top: 10,
                scale: 1,
                transition: springAnimation,
            }),
            layerC.start({
                opacity: 1,
                scale: 1,
                transition: springAnimation,
            }),
        ])
        await Promise.all([
            layerA.start({
                opacity: 1,
                scale: 1.2,
                transition: springAnimation,
            }),
            layerB.start({
                opacity: 0.7,
                scale: 0.95,
                transition: springAnimation,
            }),
            layerC.start({
                opacity: 0.7,
                scale: 0.95,
                transition: springAnimation,
            }),
        ])
        return await Promise.all([
            layerA.start({
                top: 10,
                opacity: 1,
                scale: 1,
                transition: springAnimation,
            }),
            layerB.start({
                opacity: 1,
                top: 21,
                scale: 1,
                transition: springAnimation,
            }),
            layerC.start({
                opacity: 1,
                scale: 1,
                transition: springAnimation,
            }),
        ])
    }

    useLoop(animation)

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                position: "relative",
                width: width,
                height: height,
                borderRadius: 8,
                background: colors.animationBackgroundLight,
            }}
        >
            <motion.div
                animate={layerA}
                style={{
                    width: width - 22,
                    height: cardHeight,
                    background: colors.animationForground,
                    borderRadius: borderRadius,
                    position: "absolute",
                    top: 10,
                    opacity: 1,
                }}
            />
            <motion.div
                animate={layerB}
                style={{
                    width: width - 22,
                    height: cardHeight,
                    background: colors.animationForground,
                    borderRadius: borderRadius,
                    position: "absolute",
                    opacity: 1,
                }}
            />
            <motion.div
                animate={layerC}
                style={{
                    width: width - 22,
                    height: cardHeight,
                    background: colors.animationForground,
                    borderRadius: borderRadius,
                    position: "absolute",
                    top: 32,
                    opacity: 1,
                }}
            />
        </div>
    )
})
