import { Record } from "immutable"
import type { Export } from "document/models/CanvasTree"

const exportOptionsDefaults: Export = {
    id: "", // will be generated on creation
    enabled: true,
    type: "png",
    scale: 1,
    nameExtension: "",
    nameExtensionMode: "Suffix",
}

export const ExportOptionsRecord = Record({
    ...exportOptionsDefaults,
})
