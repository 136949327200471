let patchedReactDOMUnstableNativeDependencies: any = undefined

export function optionalReactDOMUnstableNativeDependencies() {
    if (typeof window === "undefined") return undefined

    const { ReactDOMUnstableNativeDependencies } = window as any
    if (!ReactDOMUnstableNativeDependencies) {
        return undefined
    }

    if (!patchedReactDOMUnstableNativeDependencies) {
        const original = ReactDOMUnstableNativeDependencies.injectEventPluginsByName

        // We need to patch `injectEventPluginsByName` because it throws when called
        // multiple times with the same arguments and libraries like `react-native-web`
        // are doing it as a part of their initialization process.
        // This happens when we re-evaluate user code while keeping the same instance of ReactDOM
        // FIXME: delete this monkey-patching if https://github.com/necolas/react-native-web/pull/1539 is merged
        patchedReactDOMUnstableNativeDependencies = {
            ...ReactDOMUnstableNativeDependencies,
            injectEventPluginsByName: function (...args: any) {
                try {
                    original(...args)
                } catch (e) {
                    // ignore the error, it should be harmless
                }
            },
        }
    }

    return patchedReactDOMUnstableNativeDependencies
}
