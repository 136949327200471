import { ControlDescription, ControlType, EnumControlDescription } from "framer"
import { isEventAction } from "document/models/EventAction"
import { isBoolean, isNumber, isObject, isString } from "utils/typeChecks"
import {
    FusedNumberControlProp,
    FUSEDNUMBER_SINGLE_KEY,
    FUSEDNUMBER_FUSED_KEY,
    FUSEDNUMBER_TOGGLE_KEY,
} from "document/models/CanvasTree/traits/CodeComponent"
import { isVariableReference } from "document/models/CanvasTree/traits/VariableReference"
import type { Transition } from "document/models/Transition"
import { isPartialTransition } from "document/models/Transition"

export function isValidPropertyValue(property: ControlDescription, value: unknown) {
    if (!isValidPropertyValueType(property.type, value)) return false

    switch (property.type) {
        case ControlType.Enum:
        case ControlType.SegmentedEnum:
            if (isVariableReference(value)) return true
            return property.options.some(option => option === value)
    }

    return true
}

type EnumType = EnumControlDescription["defaultValue"]

export function isValidPropertyValueType(type: ControlType.Boolean, value: unknown): value is boolean
export function isValidPropertyValueType(type: ControlType.Number, value: unknown): value is number
export function isValidPropertyValueType(type: ControlType.String, value: unknown): value is string
export function isValidPropertyValueType(type: ControlType.Color, value: unknown): value is string
export function isValidPropertyValueType(type: ControlType.File, value: unknown): value is string | undefined
export function isValidPropertyValueType(type: ControlType.Image, value: unknown): value is string | undefined
export function isValidPropertyValueType(type: ControlType.Enum, value: unknown): value is EnumType
export function isValidPropertyValueType(type: ControlType.Transition, value: unknown): value is Partial<Transition>
export function isValidPropertyValueType(type: ControlType | null, value: unknown): boolean
export function isValidPropertyValueType(type: ControlType | null, value: unknown) {
    if (isVariableReference(value)) return true

    switch (type) {
        case ControlType.Boolean:
            return isBoolean(value)
        case ControlType.Number:
            return isNumber(value)
        case ControlType.String:
        case ControlType.Color:
        case ControlType.ComponentInstance:
            return isString(value)
        case ControlType.File:
        case ControlType.Image:
            return isString(value) || value === undefined
        case ControlType.Enum:
        case ControlType.SegmentedEnum:
            if (value === undefined) return true
            if (value === null) return true
            if (isBoolean(value)) return true
            if (isNumber(value)) return true
            if (isString(value)) return true
            return false
        case ControlType.FusedNumber:
            return isValidFusedNumberValue(value)
        case ControlType.EventHandler:
            return Array.isArray(value) && value.every(isEventAction)
        case ControlType.Transition:
            return isPartialTransition(value)
        case ControlType.Object:
            return isObject(value)
        default:
            return false
    }
}

export function isValidFusedNumberValue(value: unknown): value is FusedNumberControlProp["value"] {
    if (!isObject(value)) return false
    if (!(FUSEDNUMBER_SINGLE_KEY in value && FUSEDNUMBER_FUSED_KEY in value)) return false
    if (!isNumber(value.single)) return false
    const fusedValues = value.fused
    if (!Array.isArray(fusedValues) || fusedValues.length !== 4 || !fusedValues.every(isNumber)) return false
    return true
}

export function isValidFusedNumberPropertyValue(value: unknown): value is Omit<FusedNumberControlProp, "type"> {
    if (!isObject(value)) return false
    if (!(FUSEDNUMBER_TOGGLE_KEY in value && "value" in value)) return false
    return isValidFusedNumberValue(value.value)
}
