import { StrictOmit } from "ts-essentials"

/**
 * Shallowly deletes properties from the object
 */
export function withoutProps<T extends Record<string, unknown>, K extends keyof T>(
    // if T is a union this will unwrap it
    obj: T extends Record<string, unknown> ? T : never,
    ...propsToOmit: K[]
): T extends Record<string, unknown> ? StrictOmit<T, K> : never {
    for (const prop of propsToOmit) {
        delete obj[prop]
    }
    return obj
}
