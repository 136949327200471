import { css } from "linaria"
import { cursors, colors, dimensions, fonts } from "../tokens"

export const backgroundImageLight = "--backgroundImageLight"
export const backgroundImageLightLarge = "--backgroundImageLightLarge"
export const backgroundImageDark = "--backgroundImageDark"
export const backgroundImageDarkLarge = "--backgroundImageDarkLarge"
export const backgroundImageLightDisabled = "--backgroundImageLightDisabled"
export const backgroundImageLightLargeDisabled = "--backgroundImageLightLargeDisabled"
export const backgroundImageDarkDisabled = "--backgroundImageDarkDisabled"
export const backgroundImageDarkLargeDisabled = "--backgroundImageDarkLargeDisabled"

// Styling the background properties of a wrapper div to a select allows customization
// with cross-browser support. Styling the select element directly causes unpredictable
// behavior across different browsers.
// For more details see https://scottaohara.github.io/a11y_styled_form_controls/src/select/
export const popupButtonWrapper = css`
    position: relative;
    width: 100%;
    background-color: ${colors.popupButtonBackground};
    background-image: var(${backgroundImageLight});
    background-position: center;
    background-position-x: calc(100% - 6px);
    background-repeat: no-repeat;
    border-radius: ${dimensions.inputRadius}px;

    &.disabled {
        background-image: var(${backgroundImageLightDisabled});
    }

    body.dark & {
        background-image: var(${backgroundImageDark});
        &.disabled {
            background-image: var(${backgroundImageDarkDisabled});
        }
    }
`

export const popupButtonWrapperLarge = css`
    background-image: var(${backgroundImageLightLarge});
    background-position-x: calc(100% - 7px);

    .disabled {
        background-image: var(${backgroundImageLightLargeDisabled});
    }

    body.dark & {
        background-image: var(${backgroundImageDarkLarge});

        &.disabled {
            background-image: var(${backgroundImageDarkLargeDisabled});
        }
    }
`

export const popupButton = css`
    width: 100%;
    height: ${dimensions.inputHeight}px;
    padding: 0 16px 1px ${dimensions.inputPaddingHorizontal}px;
    border: 1px solid ${colors.popupButtonBorder};
    -webkit-appearance: none;
    background: transparent;
    border-radius: ${dimensions.inputRadius}px;
    color: ${colors.popupButtonText};
    font-size: ${fonts.size.base}px;
    font-weight: ${fonts.weight.base};

    :focus {
        border-color: ${colors.inputBorderActive};
        outline: none;
    }

    &:not(:disabled) {
        cursor: ${cursors.button};
    }

    optgroup,
    option {
        color: initial;
    }
`

export const popupButtonLarge = css`
    height: ${dimensions.inputHeightLarge}px;
    font-size: ${fonts.size.base}px;
    font-weight: ${fonts.weight.baseActive};
`

export const popupButtonDisabled = css`
    color: ${colors.inputTextDisabled};
`
