import type { UnknownProps } from "document/models/CanvasTree/traits/utils/collectVariantDefinitionForNode"
import type { Attribute } from "document/models/CanvasTree/utils/buildJSX"
import { isString } from "utils/typeChecks"
import { isInlineVariable, unwrapInlineVariable, isInlineHandler, unwrapInlineHandler } from "./inlineValues"

export function attributesFromProps(props: UnknownProps): Attribute[] {
    const attributes: Attribute[] = []

    for (const key in props) {
        let value = props[key]
        let type: Attribute["type"] = "object"

        if (isString(value)) {
            type = "string"

            if (isInlineVariable(value)) {
                type = "variable"
                value = unwrapInlineVariable(value)
            } else if (isInlineHandler(value)) {
                type = "variable"
                value = unwrapInlineHandler(value)
            }
        }

        attributes.push({
            type,
            name: key,
            value,
        })
    }

    return attributes
}
