import uuid from "uuid/v4"
import type { OrderedMap } from "immutable"
import type { PackageIdentifier } from "@framerjs/framer-runtime"
import { Color } from "framer"
import { localPackageFallbackIdentifier } from "framer"
import { withClassDiscriminator } from "utils/withClassDiscriminator"

export type TokenId = string
export type TokenClass<T extends TokenNode = TokenNode> = { new (): T }

export type TokenMap = OrderedMap<TokenId, TokenNode>

interface TokenMetadata {
    name: string
    packageIdentifier?: string
}

const tokenPrefix = "--token-"

/**
 * Returns a CSS Custom Property for the TokenNode provided.
 * This is used for the custom property declaration somewhere on the page.
 */
export function tokenToCSSCustomProperty(node: { id: TokenId }): string {
    return `${tokenPrefix}${node.id}`
}

/**
 * Returns a CSS variable for the TokenNode provided. This
 * is used to refer to a CSS custom property as a value on a CanvasNode.
 * By default will include a CSS comment containing origin information about
 * the token. Pass the `excludeMetadata` option to disable this.
 */
export function tokenToCSSVariable(
    node: { id: TokenId; value: string; name: string },
    options: { packageIdentifier?: PackageIdentifier; excludeMetadata?: boolean } = {}
): string {
    const value = Color.isColor(node.value) ? Color.toRgbString(Color(node.value)) : node.value
    const variable = `var(${tokenToCSSCustomProperty(node)}, ${value})`
    if (options.excludeMetadata === true) {
        return variable
    }
    return `${variable} ${tokenMetadata(node.name, options.packageIdentifier)}`
}

/**
 * Strip away the metadata from a CSS variable
 */
export function stripMetadataFromCSSVariable(variable: string) {
    const { customProperty, fallback } = parseCSSVariable(variable)
    if (customProperty && fallback) return `var(${customProperty}, ${fallback})`
    return undefined
}

/**
 * Returns a CSS comment containing the origins of the node
 */
function tokenMetadata(name: string, packageIdentifier?: PackageIdentifier) {
    const metadata: TokenMetadata = { name: name }

    if (packageIdentifier && packageIdentifier !== localPackageFallbackIdentifier) {
        metadata.packageIdentifier = packageIdentifier
    }

    return `/* ${JSON.stringify(metadata)} */`
}

/**
 * Parses out the parameters from a CSS variable.
 * NOTE: Does not support nested CSS variables.
 */
function parseCSSVariable(str: string): { customProperty?: string; fallback?: string } {
    const match = /var\((.+)\)/.exec(str)
    if (!match) return {}

    const contents = match[1]

    // Split at the first comma, var(<custom property>, <fallback>)
    const [prop, ...rest] = contents.split(",")
    return { customProperty: prop.trim(), fallback: rest.join(",").trim() || undefined }
}

/**
 * Parses out the TokenId from a CSS variable.
 */
export function tokenIdFromCSSVariable(str: string): TokenId | null {
    const { customProperty } = parseCSSVariable(str)
    return customProperty ? customProperty.slice(tokenPrefix.length) : null
}

/**
 * Parses out the fallback value from a CSS variable.
 */
export function fallbackValueFromCSSVariable(str: string): string | null {
    return parseCSSVariable(str).fallback || null
}

/**
 * Returns true if the CSS variable provided references a TokenNode.
 */
export function isTokenCSSVariable(value: unknown): value is string {
    if (typeof value !== "string") return false
    return value.trim().startsWith(`var(${tokenPrefix}`)
}

export class TokenNode extends withClassDiscriminator("TokenNode") {
    readonly id: TokenId

    name: string = ""
    value: string = ""

    constructor(id?: TokenId) {
        super()
        this.id = id || uuid()
    }

    toJS() {
        const object: any = Object.assign({}, this)
        for (const key in object) {
            const value = object[key]
            if (value === undefined) {
                delete object[key]
            }

            const tojs = value && value.toJS
            if (tojs instanceof Function) {
                object[key] = value.toJS()
            }
        }
        object["__class"] = this.__class
        return object
    }
}
