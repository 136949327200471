import type { DeviceOptions } from "@framerjs/framer-runtime/components/Device"
import { backgroundForPreviewSettings } from "./backgroundForPreviewSettings"

/**
 * Provides a fake device for scale to fit functionality
 */
export function scaleToFitConfig({
    width,
    height,
    borderRadius,
    backgroundEnabled,
    backgroundType,
    backgroundColor,
    backgroundImage,
    backgroundImageResize,
}: {
    width: number
    height: number
    borderRadius?: { topLeft: number; topRight: number; bottomRight: number; bottomLeft: number }
    backgroundEnabled?: boolean
    backgroundType?: "color" | "image"
    backgroundColor?: string
    backgroundImage?: string
    backgroundImageResize?: "fill" | "fit" | "stretch"
}): DeviceOptions {
    return {
        deviceWidth: width,
        deviceHeight: height,
        screenWidth: width,
        screenHeight: height,
        appearance: {
            type: "clay",
            bezelRadius:
                borderRadius &&
                `${borderRadius.topLeft}px ${borderRadius.topRight}px ${borderRadius.bottomRight}px ${borderRadius.bottomLeft}px`,
        },
        background: backgroundForPreviewSettings({
            backgroundEnabled,
            backgroundType,
            backgroundColor,
            backgroundImage,
            backgroundImageResize,
        }),
        theme: "dark",
        noPadding: true,
    }
}
