import type { V55, Latest } from "./types"

export { Latest, isTree } from "./types"
export { migrateDocument } from "./migrateDocument"

// NOTE! Do NOT change the names of the constants below, there are build scripts that rely on them
// See build/minimumMigratableVersion.txt and build/latestVersion.txt targets in Makefile
export const minimumMigratableVersion: V55.Tree["version"] = 55
export const latestVersion: Latest.Tree["version"] = 75

// Old anchors of the feedback UI use this as a fallback.
export { getFallbackPageId } from "./migrations/migration_68_69"
