import type { MotionStyle } from "framer-motion"
import type { CanvasNode } from "document/models/CanvasTree/nodes/CanvasNode"
import type { CollectTraitOptions } from "."
import { withOpacity, opacityDefaults } from "document/models/CanvasTree/traits/Opacity"
import { isUndefined } from "utils/typeChecks"

export function collectOpacity(
    node: CanvasNode,
    style: MotionStyle,
    { ignoreDefaults = true, withInlineVariables = false }: CollectTraitOptions = {}
): void {
    if (!withOpacity(node)) return

    const opacity = node.resolveValue("opacity", withInlineVariables)
    if (isUndefined(opacity)) return
    if (ignoreDefaults && opacity === opacityDefaults.opacity) return

    style.opacity = opacity
}
