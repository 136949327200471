import { getAssetResolver } from "../../renderer/getAssetResolver"
import { BackgroundPreviewSettings } from "../PreviewSettings"

/**
 * Returns a `background` CSS string for the given background preview settings.
 */
export function backgroundForPreviewSettings(
    backgroundPreviewSettings: BackgroundPreviewSettings | null
): string | undefined {
    if (!backgroundPreviewSettings) return

    const {
        backgroundEnabled,
        backgroundType,
        backgroundColor,
        backgroundImage,
        backgroundImageResize,
    } = backgroundPreviewSettings

    if (!backgroundEnabled) return

    if (backgroundType === "color") {
        return backgroundColor
    } else if (backgroundType === "image" && backgroundImage) {
        return `no-repeat center / ${cssBackgroundSize(backgroundImageResize)} url("${backgroundImage}")`
    }
}

function cssBackgroundSize(size: BackgroundPreviewSettings["backgroundImageResize"]) {
    switch (size) {
        case "fit":
            return "contain"
        case "stretch":
            return "100% 100%"
        case "fill":
        default:
            return "cover"
    }
}

export function resolveBackground(background: string): string {
    const matches = background.match(/url\("(.*)"\)/)
    if (!matches) return background

    const backgroundURL = matches[1]
    if (!backgroundURL) return background

    const resolvedURL = resolveBackgroundURL(backgroundURL)
    if (!resolvedURL) return background

    return background.replace(backgroundURL, resolvedURL)
}

export function resolveBackgroundURL(backgroundURL: string): string {
    const assetResolver = getAssetResolver()
    if (!assetResolver) return backgroundURL

    const resolvedURL = assetResolver(backgroundURL, {})
    if (!resolvedURL) return backgroundURL

    return resolvedURL
}
