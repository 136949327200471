import { V73, V74 } from "../types"
import { transform } from "../utils/transform"
import { _, exactCheck } from "../utils/exactCheck"
import { withoutProps } from "../utils/withoutProps"
import {
    ReplicaFromNode,
    transformReplicaOverrides,
    UpdateOverrides,
    GetInheritedPropValue,
} from "../utils/transformReplicaOverrides"
import { isEqual } from "../utils/isEqual"

export function migration_73_74(documentJSON: V73.Tree): V74.Tree {
    const migratedRoot = transform(documentJSON.root, (node, idToNode) => {
        switch (node.__class) {
            case "FrameNode": {
                const migratedNode = { ...node }
                transformReplicaOverrides(migratedNode, idToNode, replicaMigration)
                return exactCheck(migratedNode, _ as V74.FrameNode)
            }
            case "CodeComponentNode": {
                const migratedCodeComponentNode = migrateCodeComponentNode(node)
                return exactCheck(migratedCodeComponentNode, _ as V74.CodeComponentNode)
            }
            default:
                return exactCheck(node, _ as V74.TreeNode)
        }
    })

    return { version: 74, root: migratedRoot }
}

// copied from CodeComponentProps trait in Vekter
const CONTROL_PREFIX = "$control__"

/**
 * The migration does two things:
 * 1. Raise all props defined in codeComponentProps to top-level node property prefixed with $control__;
 *  delete the codeComponentProps key
 * 2. Populate the code component prop value to {type: ..., value: ...} (type is set to null since we can't infer it from the value)
 */
function migrateCodeComponentNode(node: V73.CodeComponentNode): V74.CodeComponentNode {
    if (!withDeprecatedCodeComponentProps(node)) return node as V74.CodeComponentNode
    const migratedProps = migrateCodeComponentProps(node.codeComponentProps)
    const migratedNode: V74.CodeComponentNode = withoutProps({ ...node, ...migratedProps }, "codeComponentProps")
    return migratedNode
}

function migrateCodeComponentProps(
    codeComponentProps: V73.CodeComponentNode["codeComponentProps"]
): CodeComponentProps {
    const migratedProps: CodeComponentProps = {}
    for (const key in codeComponentProps) {
        migratedProps[`${CONTROL_PREFIX}${key}`] = { type: null, value: codeComponentProps[key] }
    }
    return migratedProps
}

type CodeComponentReplica = ReplicaFromNode<V74.CodeComponentNode>

function replicaMigration(
    _originalNode: V73.TreeNode,
    getInheritedValue: GetInheritedPropValue,
    updatedOverrides: UpdateOverrides
) {
    if (_originalNode.__class !== "CodeComponentNode") return

    updatedOverrides(_originalNode, _ as CodeComponentReplica, overrides => {
        const { codeComponentProps, _deleted, ...keep } = overrides
        const result: CodeComponentReplica = keep

        // exclude the "codeComponentProps" key from _deleted.
        const deleted = _deleted ? _deleted.filter(key => key !== "codeComponentProps") : []

        const originalCodeComponentProps = getInheritedValue(_originalNode, "codeComponentProps")
        if (!codeComponentProps || !originalCodeComponentProps) {
            return result
        }

        // replicas might have keys that masters don't have since we don't store undefined values,
        // same for the other way around. So we need to merge keys from both and remove duplication.
        const allKeys = Array.from(
            new Set<string>([...Object.keys(codeComponentProps), ...Object.keys(originalCodeComponentProps)])
        )

        // Previously when override a code component, we had to override the whole codeComponentProps object,
        // now we only pick the props they are actually overriden compared with the original node.
        for (const key of allKeys) {
            const originalValue = originalCodeComponentProps[key]
            const newValue = codeComponentProps[key]

            if (isEqual(originalValue, newValue)) {
                continue
            }
            ;(result as CodeComponentProps)[`${CONTROL_PREFIX}${key}`] = { type: null, value: newValue }
        }

        if (deleted.length > 0) {
            result._deleted = deleted as CodeComponentReplica["_deleted"]
        }
        return result
    })
}

// @FIXME: could improve the typing here once we have support for template literal in typescript.
interface CodeComponentProps {
    [propName: string]: unknown
}

interface WithDeprecatedCodeComponentProps {
    codeComponentProps: CodeComponentProps
}

const deprecatedCodeComponentPropsKey = "codeComponentProps"
function withDeprecatedCodeComponentProps(
    node: V73.TreeNode
): node is V73.CodeComponentNode & WithDeprecatedCodeComponentProps {
    return deprecatedCodeComponentPropsKey in node
}
