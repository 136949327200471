import {
    convertPropsToDeviceOptions,
    DeviceCodeComponentProps,
    DevicePresetID,
    getComponentSize,
} from "@framerjs/framer-runtime/components/Device"
import type { CanvasNode, NodeUpdate } from "document/models/CanvasTree"
import CodeComponentNode from "document/models/CanvasTree/nodes/CodeComponentNode"
import { ControlType, Size } from "framer"
import { withFramePreset } from "../traits/FramePreset"
import { HardCodedCodeIdentifier } from "../traits/utils/hardCodedCodeComponentIdentifiers"
import type { FramePresetID } from "../traits/utils/framePresets"
import { randomID } from "./NodeID"
import { prefixControlProps, getControlPropsValues } from "../traits/utils/codeComponentProps"
import type { CodeComponentProps, ControlProp } from "../traits/CodeComponent"
import { withCodeComponent } from "../traits/CodeComponent"

/**
 * DeviceNode is just a CodeComponentNode injected into the component loader
 * under its hardcoded identifier.
 */
export interface DeviceNode extends CodeComponentNode {
    codeComponentIdentifier: HardCodedCodeIdentifier.device
    getControlProps: () => DeviceControlPropsWithTypes
}

type DeviceControlPropKeys = keyof Omit<DeviceCodeComponentProps, "width" | "height" | "children">
type DeviceControlPropsWithTypes = CodeComponentProps &
    {
        [key in DeviceControlPropKeys]: ControlProp
    }

export function isDeviceNode(node: CanvasNode): node is DeviceNode {
    // It would be more correct to check for isCodeComponentNode here, but that causes a circular dependency:
    // Circular dependency is  isDeviceNode -> isCodeComponentNode -> withPinSizeRatioConstraints -> withRotation -> isDeviceNode
    return withCodeComponent(node) && node.codeComponentIdentifier === HardCodedCodeIdentifier.device
}

export function getDeviceNodeChildId(node: DeviceNode): string | undefined {
    const children = node.getRawChildrenSlotValues()
    const childId = children[0].value
    if (typeof childId !== "string") return undefined
    return childId
}

/**
 * Resizes and repositions the device node when preset or orientation changes.
 *
 * Above everything else, it'll ensure that the scale factor remains the same,
 * e.g., if the device is an iPhone scaled down to 50%, then updating it to an
 * iPad will resize the device frame so that the iPad is also at 50% scale.
 *
 * It'll also attempt to preserve any horizontal/vertical space around the
 * device. The extra space might be shrunk down if necessary to preserve aspect
 * ratio. (Imagine: an iPhone with locked aspect ratio, change it to an iPad,
 * extra space is inserted vertically to match aspect ratio, then you change it
 * back to an iPhone => the extra space is taken away.)
 *
 * Changing orientation will essentially flip the width/height of the device,
 * so that it behaves similar to changing orientation of an ordinary Frame.
 *
 * @see deviceNodeUpdateForSize
 */
export function deviceNodeUpdateForCodeComponentProps(
    node: DeviceNode,
    newPropValues: Omit<DeviceCodeComponentProps, "width" | "height" | "children">
): NodeUpdate {
    const curProps = node.getControlProps()
    let aspectRatio = node.aspectRatio
    const newProps: Partial<DeviceControlPropsWithTypes> = {}

    for (const key in newPropValues) {
        const curProp = curProps[key]
        if (curProp === undefined) continue
        newProps[key] = { type: curProp.type, value: newPropValues[key] }
    }

    const update: Partial<CodeComponentNode> = { ...prefixControlProps(newProps) }

    const curPropValues = getControlPropsValues(curProps)

    const { componentWidth: curDeviceWidth, componentHeight: curDeviceHeight } = getComponentSize(
        convertPropsToDeviceOptions(curPropValues)
    )
    const { componentWidth: newDeviceWidth, componentHeight: newDeviceHeight } = getComponentSize(
        convertPropsToDeviceOptions(newPropValues)
    )

    const sizeChanged = newDeviceWidth !== curDeviceWidth || newDeviceHeight !== curDeviceHeight
    const orientationChanged = newPropValues.orientation !== curPropValues.orientation

    if (sizeChanged) {
        const parentSize = null // The device node never has a parent.
        // This is actual size of the frame, which may be scaled up or down.
        const rect = node.rect(parentSize)

        const scaleFactor = Math.min(1, Math.min(rect.width / curDeviceWidth, rect.height / curDeviceHeight))

        let extraHorizontalSpace = rect.width - curDeviceWidth * scaleFactor
        let extraVerticalSpace = rect.height - curDeviceHeight * scaleFactor

        if (orientationChanged) {
            if (aspectRatio) {
                aspectRatio = 1 / aspectRatio
                update.aspectRatio = aspectRatio
            }
            ;[extraHorizontalSpace, extraVerticalSpace] = [extraVerticalSpace, extraHorizontalSpace]
        }

        // Retain the scale factor and the extra space
        const newWidth = newDeviceWidth * scaleFactor + extraHorizontalSpace
        const newHeight = newDeviceHeight * scaleFactor + extraVerticalSpace

        Object.assign(
            update,
            deviceNodeUpdateForSize(
                node,
                { width: newWidth, height: newHeight },
                {
                    aspectRatio,
                    minSize: { width: newWidth - extraHorizontalSpace, height: newHeight - extraVerticalSpace },
                }
            ),
            {
                // Intrinsic size = device size at 100%.
                intrinsicWidth: newDeviceWidth,
                intrinsicHeight: newDeviceHeight,
            }
        )
    }

    return update
}

/**
 * Resizes device node while preserving the given aspect ratio.
 *
 * The node will also be repositioned so that the slot connector (i.e., the
 * middle-right point) stays in the same place on the canvas post-resize.
 *
 * If the given size doesn't match the aspect ratio, it'll shrink it along the
 * outstanding dimension (i.e., width if the size is too landscapish, and height
 * if the size is too portraitish), but not below the minSize. If the aspect
 * ratio still doesn't match, it'll then grow the node along the other dimension.
 *
 * If no aspect ratio is given, it'll use the node's current aspect ratio.
 *
 * If no minSize is given, it'll behave as if the size is given is also minSize.
 *
 * Returns an update that needs to be applied to the node.
 */
export function deviceNodeUpdateForSize(
    node: DeviceNode,
    size: Size,
    options: { aspectRatio?: number | null; minSize?: Size } = {}
): NodeUpdate {
    let newWidth = size.width
    let newHeight = size.height

    const aspectRatio = options?.aspectRatio || node.aspectRatio
    const minSize = options?.minSize || size

    if (aspectRatio) {
        const newAspectRatio = newWidth / newHeight
        if (newAspectRatio > aspectRatio) {
            // The new ratio is too landscapish, so let's shrink the width, but
            // not below minSize.width, and if that's not enough, let's grow the
            // height to match.
            const targetWidth = newHeight * aspectRatio
            newWidth = Math.max(targetWidth, minSize.width)
            newHeight = newWidth / aspectRatio
        } else {
            // Ditto.
            const targetHeight = newWidth / aspectRatio
            newHeight = Math.max(targetHeight, minSize.height)
            newWidth = newHeight * aspectRatio
        }
    }

    const parentSize = null // The device node never has a parent.
    const rect = node.rect(parentSize)

    const deltaX = newWidth - rect.width
    const deltaY = newHeight - rect.height

    return node.updateForRect(
        {
            width: newWidth,
            height: newHeight,
            // Adjust the position so that the slot connector stays in the same place.
            x: rect.x - deltaX,
            y: rect.y - deltaY / 2,
        },
        parentSize,
        false
    )
}

// Mappings included here will have the Device component infer the device preset from the frame preset.
// Mappings not included here will have the Device component use its default preset instead.
/* eslint-disable @typescript-eslint/naming-convention */
const frameDeviceMappings: Partial<Record<FramePresetID, DevicePresetID>> = {
    iPhone_320_568: "iphone-se",
    iPhone_375_667: "iphone-8",
    iPhone_414_736: "iphone-8-plus",
    iPhone_414_896: "iphone-11",
    iPhone_375_812: "iphone-11-pro",
    iPhone_414_896_pro: "iphone-11-pro-max",
    GooglePixel_360_760: "pixel-4",

    iPad_768_1024: "ipad",
    iPad_834_1194: "ipad-pro-11",
    iPad_1024_1366: "ipad-pro-12-9",
    Surface_1440_960: "ipad-pro-12-9", // non-exact match
    Surface_1368_912: "ipad-pro-12-9", // non-exact match

    iMac_2560_1440: "1440p",
    MacBook_1440_900: "900p",
    MacBook_1440_900_pro: "900p",
    DellXPS_1920_1080: "1080p",
    SurfaceBook_1500_1000: "900p", // non-exact match
}
/* eslint-enable @typescript-eslint/naming-convention */

/**
 * Infers device options (device type, orientation) and Device component's
 * size and position based on the given node, and auto-connects the Device
 * component to that node.
 *
 * @param targetNode Contract: must be a ground node!
 */
export function inferDeviceNodeProps(deviceNode: DeviceNode, targetNode: CanvasNode): NodeUpdate | undefined {
    if (isDeviceNode(targetNode)) return

    const updatedProps = {
        ...deviceNode.getControlProps(),
        children: {
            type: ControlType.Array,
            value: [{ id: randomID(), value: targetNode.id, type: ControlType.ComponentInstance }],
        },
    }

    const parentSize = null // Contract: targetNode is a ground node
    const targetNodeRect = targetNode.rect(parentSize)

    if (targetNodeRect.width > targetNodeRect.height) {
        updatedProps.orientation.value = "landscape"
    }

    if (withFramePreset(targetNode) && targetNode.framePreset) {
        const devicePresetFromFramePreset = frameDeviceMappings[targetNode.framePreset]
        if (devicePresetFromFramePreset) {
            updatedProps.preset.value = devicePresetFromFramePreset
        }
    }

    const { componentWidth, componentHeight } = getComponentSize(
        convertPropsToDeviceOptions(getControlPropsValues(updatedProps))
    )

    const x = targetNodeRect.x - componentWidth - 100
    const y = targetNodeRect.y - (componentHeight - targetNodeRect.height) / 2

    const updatedCodeComponentProps = prefixControlProps(updatedProps)
    return { left: x, top: y, width: componentWidth, height: componentHeight, ...updatedCodeComponentProps }
}
