// TODO: This is duplicated between Web and Vekter, clean it up (but until then,
// try to keep these two in sync)

import { Device, DeviceOptions, DeviceProps } from "@framerjs/framer-runtime/components/Device"
import { cx } from "linaria"
import * as React from "react"
import { forwardRef } from "react"
import ResizeObserver from "resize-observer-polyfill"
import { isSimilarDeviceResolution } from "../utils/isSimilarDeviceResolution"
import * as classes from "./PreviewContainer.styles"

interface Props {
    /** Enable to prevent device UI showing on mobile devices */
    allowMobileOverride?: boolean
    hidden?: boolean
    overrideTheme?: "dark" | "light"
    deviceOptions?: DeviceOptions
    onScaleChange?: DeviceProps["onScaleChange"]
    children: React.ReactNode
    onClickCapture?: (e: React.MouseEvent<HTMLDivElement>) => void
}

export const PreviewContainer = forwardRef(function PreviewContainer(
    {
        allowMobileOverride,
        hidden,
        deviceOptions: deviceOptionsProp,
        onScaleChange,
        children,
        onClickCapture,
        overrideTheme,
    }: Props,
    ref: React.Ref<HTMLDivElement>
) {
    let deviceOptions = deviceOptionsProp
    // If the current environment is a matching mobile browser
    // check to see if we should hide the device UI.
    if (allowMobileOverride && deviceOptions && shouldOverrideDevice(deviceOptions)) {
        deviceOptions = undefined
    }

    return (
        <div
            ref={ref}
            className={cx(classes.container, hidden && classes.hidden, !hidden && !deviceOptions && classes.responsive)}
            onClickCapture={onClickCapture}
        >
            <Device
                overrideTheme={overrideTheme}
                deviceOptions={deviceOptions}
                scaleTo="dynamic"
                onScaleChange={onScaleChange}
                ResizeObserver={ResizeObserver}
            >
                {children}
            </Device>
        </div>
    )
})

/**
 * Returns true if the current browser viewport is similar enough to the selected
 * device that we can hide the device chrome. For example to avoid having a
 * mock iPhone UI appearing inside an mobile device.
 */
function shouldOverrideDevice(deviceOptions: DeviceOptions, browser = browserDeviceResolution()): boolean {
    if (isLikelyUsingMobileDevice()) {
        return isSimilarDeviceResolution(browser, {
            width: deviceOptions.screenWidth,
            height: deviceOptions.screenHeight,
            pixelRatio: 1,
        })
    }

    return false
}

function isLikelyUsingMobileDevice(matchMedia = window.matchMedia): boolean {
    if (!matchMedia) return false
    return matchMedia("(hover: none) and (pointer: coarse)").matches
}

function browserDeviceResolution(
    screen: Screen = window.screen,
    devicePixelRatio: number | undefined = window.devicePixelRatio
): { width: number; height: number; pixelRatio: number } {
    const pixelRatio = devicePixelRatio || 1
    return {
        width: screen.width * pixelRatio,
        height: screen.height * pixelRatio,
        pixelRatio,
    }
}
