import * as React from "react"
import { useLayoutEffect, useReducer, useRef, FunctionComponent } from "react"
import { getLogger } from "@framerjs/shared"
import { __fixmeRenderingOutsideEngineLifecycle } from "../document/unstableRendering"

const log = getLogger("FeatureSet")

// True when React is done hydrating the app (after which it's safe to inspect features).
let didFinishHydrating = false

/**
 * Call this when hydration is known to have completed. This will help avoid re-rendering
 * features on mount, which is necessary during hydration to avoid DOM tree differences.
 */
export function didHydrate() {
    didFinishHydrating = true
}

// True when fetching the state of a feature from within the useCondition hook (making it safe).
let isInSafeContext = false

function safeToCheckFeatures() {
    // Skip this code for production builds.
    if (process.env.NODE_ENV === "production") return true
    if (didFinishHydrating || isInSafeContext) return true
    if (new Error().stack?.includes("react")) return false
    return true
}

type Config<Name extends string> = { [name in Name]: string }
type ConfigWithBools<Name extends string> = { [name in Name]: string | boolean }
type Listener = (variant: string) => void

export class FeatureSet<Name extends string> {
    private readonly activeConfig: Config<Name>
    private readonly activeOverrides: Partial<Config<Name>>[] = []
    private readonly initialConfig: Config<Name>
    private readonly listeners = new Map<Name, Set<Listener>>()

    constructor(config: ConfigWithBools<Name>) {
        this.initialConfig = convertBools(config)
        this.activeConfig = { ...this.initialConfig }
    }

    addListener(name: Name, listener: Listener) {
        let set = this.listeners.get(name)
        if (!set) {
            set = new Set()
            this.listeners.set(name, set)
        }
        set.add(listener)
    }

    get(name: Name): string {
        if (!safeToCheckFeatures()) log.warnOncePerMinute(`React render depends on feature "${name}" before hydrating`)
        for (let i = this.activeOverrides.length - 1; i >= 0; i--) {
            const variant = this.activeOverrides[i][name]
            if (typeof variant !== "string") continue
            return variant
        }
        return this.activeConfig[name]
    }

    getInitial(name: Name): string {
        return this.initialConfig[name]
    }

    is(name: Name, variant: string): boolean {
        if (!safeToCheckFeatures()) log.warnOncePerMinute(`React render depends on feature "${name}" before hydrating`)
        // TODO: Only do this check when in a testing environment.
        const isActiveByOverrides = this.isActiveByOverrides(name, variant)
        if (isActiveByOverrides !== undefined) return isActiveByOverrides
        return this.activeConfig[name] === variant
    }

    isOn(name: Name): boolean {
        if (!safeToCheckFeatures()) log.warnOncePerMinute(`React render depends on feature "${name}" before hydrating`)
        return this.is(name, "on")
    }

    /**
     * Dynamically changes the active features by overriding them until the
     * returned `reset()` function is called.
     *
     * NOTE: Use the helper exported from "test/features" as this will manage
     * setup and teardown.
     *
     * For simpler overrides prefer `withOverridesForTest()` which will handle
     * the clean-up for you.
     *
     * @param overrides Feature/variant pairs to override.
     * @returns A function to reset the override back to the previous state
     * @example
     * let reset: () => void
     * beforeEach(() => reset = experiments.overrideForTest({ feedback: "on" }))
     * afterEach(() => reset())
     */
    overrideForTest(overrides: Partial<Config<Name>>): () => void {
        this.activeOverrides.push(overrides)
        return () => {
            // Verify reset has been called in correct order.
            if (this.activeOverrides.pop() !== overrides) {
                throw Error("Something went wrong with experiment overrides")
            }
        }
    }

    removeListener(name: Name, listener: Listener) {
        this.listeners.get(name)?.delete(listener)
    }

    update(changes: Partial<ConfigWithBools<Name>>) {
        for (const name in changes) {
            const variant = convertBool(changes[name])
            if (typeof variant !== "string") continue
            if (variant === this.activeConfig[name]) continue
            this.activeConfig[name] = variant
            const set = this.listeners.get(name)
            if (!set) continue
            set.forEach(listener => listener(variant))
        }
    }

    /** Same as is(…), but compares to the initial configuration (the build time one). */
    wasInitially(name: Name, variant: string): boolean {
        return this.initialConfig[name] === variant
    }

    /**
     * Dynamically changes the active features by overriding them within the
     * provided scope. This should only be used in tests, not in production code!
     * NOTE: Use the helper exported from "test/features"
     *
     * @param overrides Feature/variant pairs to override.
     * @param scope An immediately executed function within which the overrides are active.
     * @example
     * experiments.withOverridesForTest({ feedback: "on" }, () => {
     *    feedbackStore.initialize()
     * })
     */
    withOverridesForTest<T>(overrides: Config<Name>, scope: () => T): T {
        const reset = this.overrideForTest(overrides)
        try {
            return scope()
        } finally {
            reset()
        }
    }

    /**
     * Checks all active overrides for the feature and if one is found, returns if the variant is active.
     */
    private isActiveByOverrides(name: Name, variant: string): boolean | undefined {
        if (!safeToCheckFeatures()) log.warnOncePerMinute(`React render depends on feature "${name}" before hydrating`)
        for (let i = this.activeOverrides.length - 1; i >= 0; i--) {
            const overrides = this.activeOverrides[i]
            if (!(name in overrides)) continue
            return overrides[name] === variant
        }
        return undefined
    }
}

function convertBool(variant: string | boolean | undefined): string | undefined {
    if (typeof variant === "boolean") return variant ? "on" : "off"
    return variant
}

function convertBools<Name extends string>(input: ConfigWithBools<Name>): Config<Name> {
    const result: Partial<Config<Name>> = {}
    for (const name in input) {
        result[name] = convertBool(input[name])
    }
    return result as Config<Name>
}

type Props<Name extends string> =
    | {
          /** Renders contents if the variant of the feature with the provided name is `"on"`. */
          isOn: Name
      }
    | {
          /** Renders contents if the variant of the feature with the provided name is *not* `"on"`. */
          isNotOn: Name
      }
    | {
          /** Renders contents if the variant of the feature with the provided name is equal to `variant`. */
          isActive: Name
          /** The variant to test for. */
          variant: string
      }
    | {
          /** Renders contents if the variant of the feature with the provided name is *not* equal to `variant`. */
          isNotActive: Name
          /** The variant to test for. */
          variant: string
      }
    | {
          name: Name
          condition: (variant: string) => boolean
      }

/**
 * Converts `Props` to `[feature, variant, expectedCondition]`.
 */
function propsToParameters<Name extends string>(
    props: Props<Name>
): [Name, string, boolean] | [Name, (variant?: string) => boolean] {
    if ("isOn" in props) {
        return [props.isOn, "on", true]
    } else if ("isNotOn" in props) {
        return [props.isNotOn, "on", false]
    } else if ("isActive" in props) {
        return [props.isActive, props.variant, true]
    } else if ("isNotActive" in props) {
        return [props.isNotActive, props.variant, false]
    } else if ("condition" in props) {
        return [props.name, props.condition]
    }
    throw Error("invalid props")
}

/**
 * Creates a React component that conditionally renders its contents based on the
 * currently active features. Prefer this component over the `isOn` and `isActive`
 * methods in React components because this component is able to handle server-side
 * rendering correctly.
 *
 * @example
 * const Feature = createFeatureComponent(abTestFeatures)
 * // …
 * <Feature isOn="prize"><p>Click for a chance to win cash!</p></Feature>
 */
export function createFeatureComponent<Name extends string>(
    features: FeatureSet<Name>
): FunctionComponent<Props<Name>> {
    return function Feature({ children, ...props }) {
        let condition: (variant: string | undefined) => boolean
        let featureName: Name
        if ("condition" in props) {
            condition = props.condition
            featureName = props.name
        } else {
            const [name, parameterVariant, expectedCondition] = propsToParameters(props)
            condition = variant => (variant === parameterVariant) === expectedCondition
            featureName = name
        }
        const shouldRender = useCondition(features, featureName, condition)
        return shouldRender ? React.createElement(React.Fragment, null, children) : null
    }
}

function getVariantInSafeContext<Name extends string>(features: FeatureSet<Name>, name: Name): string {
    isInSafeContext = true
    try {
        return features.get(name)
    } finally {
        isInSafeContext = false
    }
}

export function useCondition<Name extends string>(
    features: FeatureSet<Name>,
    name: Name,
    condition: (variant?: string) => boolean
): boolean {
    // This reducer is only used to force this hook to reevaluate if we detect
    // that the feature/variant tested has changed since SSR rendering.
    const [, forceRerender] = useReducer((state: number) => {
        __fixmeRenderingOutsideEngineLifecycle.inRendering = true
        return state + 1
    }, 0)

    const hasMounted = useRef(false)

    const isActive = useRef(false)
    const conditionRef = useRef(condition)
    conditionRef.current = condition

    let variant: string | undefined
    if (didFinishHydrating || hasMounted.current) {
        variant = getVariantInSafeContext(features, name)
    } else {
        variant = features.getInitial(name)
    }

    isActive.current = conditionRef.current(variant)

    useLayoutEffect(() => {
        __fixmeRenderingOutsideEngineLifecycle.inRendering = false

        const safeToUseRuntimeValues = hasMounted.current || didFinishHydrating
        hasMounted.current = true

        // If first mount occurred during hydration and the experiment setting is different
        // from the value at build time, force a re-render to use the runtime value.
        if (
            !safeToUseRuntimeValues &&
            isActive.current !== conditionRef.current(getVariantInSafeContext(features, name))
        ) {
            forceRerender()
        }

        const listener = (newVariant: string) => {
            const newCondition = conditionRef.current(newVariant)
            if (newCondition === isActive.current) return
            // Variant active state changed, trigger a rerender.
            isActive.current = newCondition
            forceRerender()
        }

        features.addListener(name, listener)
        return () => features.removeListener(name, listener)
    }, [features, name])

    return isActive.current
}
