import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springAnimation } from "./utils"
import { colors } from "@framerjs/fresco/src/tokens"

export const AnimationComponent = React.memo(function AnimationComponent() {
    const button = useAnimation()
    const squares = useAnimation()
    const plus = useAnimation()
    const toggleLeft = useAnimation()
    const toggleRight = useAnimation()
    const connectorCircleLeft = useAnimation()
    const connectorCircleRight = useAnimation()
    const connector = useAnimation()
    const toggleControl = useAnimation()
    const knobControl = useAnimation()
    const circle = useAnimation()

    async function animation() {
        button.set({
            scale: 0,
            opacity: 0,
            background: "rgba(255, 255, 255, 0.4)",
        })
        squares.set({
            opacity: 0,
        })
        plus.set({
            scale: 0.6,
            opacity: 0,
            left: 16,
            top: 14,
            originY: 1,
            originX: 1,
        })
        toggleLeft.set({
            scale: 0.5,
            opacity: 0,
        })
        toggleRight.set({
            scale: 0.5,
            opacity: 0,
        })
        connector.set({
            pathLength: 0,
            opacity: 1,
        })
        connectorCircleLeft.set({
            scale: 0,
            opacity: 1,
        })
        connectorCircleRight.set({
            scale: 0,
            opacity: 1,
        })
        connector.set({
            pathLength: 0,
        })
        toggleControl.set({
            opacity: 0,
        })
        knobControl.set({
            opacity: 0,
        })
        circle.set({
            scale: 0,
            opacity: 0,
        })

        // Animate In
        await Promise.all([
            button.start({
                opacity: 1,
                scale: 1,
                transition: springAnimation,
            }),
            plus.start({
                opacity: 1,
                transition: { ...springAnimation.default, delay: 0.3 },
            }),
            squares.start({
                opacity: 1,
                transition: { ...springAnimation.default, delay: 0.3 },
            }),
        ])

        // Animate Plus
        await Promise.all([
            plus.start({
                scale: 1,
                left: 13,
                top: 13,
                transition: {
                    type: "spring",
                    delay: 0.6,
                    stiffness: 400,
                    damping: 20,
                },
            }),
            squares.start({
                opacity: 0,
                transition: { duration: 0.2, delay: 0.4 },
            }),
            button.start({
                scale: 0.9,
                opacity: 0.5,
                transition: { ...springAnimation.default, delay: 1.1 },
            }),
        ])

        // Press Plus
        await button.start({
            scale: 1,
            opacity: 1,
            transition: { ...springAnimation },
        })

        // Show Toggles
        await Promise.all([
            button.start({
                opacity: 0,
                scale: 0.5,
                transition: { ...springAnimation },
            }),
            plus.start({
                opacity: 0,
                transition: { ...springAnimation },
            }),
            toggleLeft.start({
                scale: 1,
                opacity: 1,
                transition: { ...springAnimation.default, delay: 0.1 },
            }),
            toggleRight.start({
                scale: 1,
                opacity: 1,
                transition: { ...springAnimation.default, delay: 0.2 },
            }),
        ])

        // Link Content
        await Promise.all([
            connectorCircleLeft.start({
                scale: 1,
                transition: { ...springAnimation.default, delay: 0.1 },
            }),
            connector.start({
                pathLength: 1,
                transition: { delay: 0.2, duration: 0.2 },
            }),
            connectorCircleRight.start({
                scale: 1,
                transition: { ...springAnimation.default, delay: 0.3 },
            }),
        ])

        // Show Component
        await Promise.all([
            button.start({
                opacity: 1,
                scale: 1,
                transition: { ...springAnimation.default, delay: 0.3 },
            }),
            knobControl.start({
                opacity: 1,
                transition: { ...springAnimation.default, delay: 0.3 },
            }),
            toggleControl.start({
                opacity: 1,
                transition: { ...springAnimation.default, delay: 0.3 },
            }),
            toggleLeft.start({
                opacity: 0,
                scale: 0,
                transition: { ...springAnimation.default, delay: 0.05 },
            }),
            toggleRight.start({
                opacity: 0,
                scale: 0,
                transition: { ...springAnimation.default, delay: 0.1 },
            }),
            connectorCircleLeft.start({
                scale: 0,
                transition: { duration: 0 },
            }),
            connector.start({
                opacity: 0,
                transition: { duration: 0 },
            }),
            connectorCircleRight.start({
                scale: 0,
                transition: { duration: 0 },
            }),
        ])

        // Animate Component
        await Promise.all([
            knobControl.start({
                x: 9,
                transition: { ...springAnimation.default, delay: 0.2 },
            }),
            toggleControl.start({
                background: colors.tint,
                transition: { ...springAnimation.default, delay: 0.2 },
            }),
            circle.start({
                scale: 3,
                opacity: 1,
                transition: { ...springAnimation.default, delay: 0.2 },
            }),
        ])

        await Promise.all([
            knobControl.start({
                x: 1,
                transition: { ...springAnimation },
            }),
            toggleControl.start({
                background: colors.animationForgroundDimmed,
                transition: { ...springAnimation },
            }),
            circle.start({
                scale: 0,
                opacity: 0,
                transition: { ...springAnimation },
            }),
        ])

        // Fade Out
        return await Promise.all([
            knobControl.start({
                opacity: 0,
                transition: { ...springAnimation },
            }),
            toggleControl.start({
                opacity: 0,
                transition: { ...springAnimation },
            }),
            button.start({
                scale: 0,
                opacity: 0,
                transition: { ...springAnimation },
            }),
        ])
    }

    useLoop(animation)

    return (
        <div
            style={{
                width: 120,
                height: 40,
                marginTop: -10,
                marginBottom: 10,
                overflow: "visible",
            }}
        >
            <div
                style={{
                    width: 58,
                    height: 24,
                    position: "absolute",
                    top: 18,
                    left: 31,
                    background: "",
                }}
            >
                <motion.div
                    animate={toggleLeft}
                    style={{
                        position: "absolute",
                        top: 10,
                        left: 44,
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16">
                        <path
                            d="M 0 8 C 0 3.582 3.582 0 8 0 L 16 0 C 20.418 0 24 3.582 24 8 L 24 8 C 24 12.418 20.418 16 16 16 L 8 16 C 3.582 16 0 12.418 0 8 Z"
                            fill={colors.animationForgroundLight}
                        ></path>
                        <path
                            d="M 8 2 C 11.314 2 14 4.686 14 8 C 14 11.314 11.314 14 8 14 C 4.686 14 2 11.314 2 8 C 2 4.686 4.686 2 8 2 Z"
                            fill={colors.animationForground}
                        ></path>
                    </svg>
                </motion.div>
                <motion.div
                    animate={toggleRight}
                    style={{
                        position: "absolute",
                        top: 18,
                        left: 78,
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16">
                        <path
                            d="M 0 8 C 0 3.582 3.582 0 8 0 L 16 0 C 20.418 0 24 3.582 24 8 L 24 8 C 24 12.418 20.418 16 16 16 L 8 16 C 3.582 16 0 12.418 0 8 Z"
                            fill={colors.animationForgroundLight}
                        ></path>
                        <path
                            d="M 16 2 C 19.314 2 22 4.686 22 8 C 22 11.314 19.314 14 16 14 C 12.686 14 10 11.314 10 8 C 10 4.686 12.686 2 16 2 Z"
                            fill={colors.animationForground}
                        ></path>
                    </svg>
                </motion.div>
                <div style={{ position: "absolute", left: 66, top: 16 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12">
                        <motion.path
                            animate={connector}
                            d="M 2.5 2 C 6.375 2 5.813 10 9.5 10"
                            fill="transparent"
                            stroke="hsl(0, 0%, 100%)"
                        ></motion.path>
                    </svg>
                </div>
                <motion.div
                    animate={connectorCircleLeft}
                    style={{
                        width: 4,
                        height: 4,
                        borderRadius: "50%",
                        backgroundColor: colors.animationForground,
                        position: "absolute",
                        left: 66,
                        top: 16,
                    }}
                />
                <motion.div
                    animate={connectorCircleRight}
                    style={{
                        width: 4,
                        height: 4,
                        borderRadius: "50%",
                        backgroundColor: colors.animationForground,
                        position: "absolute",
                        left: 76,
                        top: 24,
                    }}
                />
            </div>

            <motion.div
                animate={button}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    width: 40,
                    height: 40,
                    borderRadius: 8,
                    top: 10,
                    left: 40,
                    position: "relative",
                    overflow: "hidden",
                    background: "rgba(255, 255, 255, 0.15)",
                }}
            >
                <motion.div
                    animate={circle}
                    style={{
                        position: "absolute",
                        width: 50,
                        height: 50,
                        borderRadius: "50%",
                        backgroundColor: colors.animationForground,
                    }}
                />
                <motion.div
                    animate={toggleControl}
                    style={{
                        borderRadius: 7,
                        position: "absolute",
                        width: 22,
                        height: 14,
                        backgroundColor: colors.animationForgroundDimmed,
                        overflow: "hidden",
                    }}
                >
                    <motion.div
                        animate={knobControl}
                        style={{
                            borderRadius: 5,
                            position: "absolute",
                            width: 10,
                            height: 10,
                            top: 2,
                            left: 1,
                            backgroundColor: colors.animationForground,
                            overflow: "hidden",
                        }}
                    />
                </motion.div>

                <motion.div
                    animate={squares}
                    style={{
                        left: 10,
                        top: 10,
                        position: "absolute",
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                        <path
                            d="M 0 3.5 C 0 1.567 1.567 0 3.5 0 L 6.5 0 C 7.881 0 9 1.119 9 2.5 L 9 6.5 C 9 7.881 7.881 9 6.5 9 L 2.5 9 C 1.119 9 0 7.881 0 6.5 Z"
                            fill={colors.animationForground}
                        ></path>
                        <path
                            d="M 10.5 2.5 C 10.5 1.119 11.619 0 13 0 L 16 0 C 17.933 0 19.5 1.567 19.5 3.5 L 19.5 6.5 C 19.5 7.881 18.381 9 17 9 L 13 9 C 11.619 9 10.5 7.881 10.5 6.5 Z"
                            fill={colors.animationForground}
                        ></path>
                        <path
                            d="M 0 13 C 0 11.619 1.119 10.5 2.5 10.5 L 6.5 10.5 C 7.881 10.5 9 11.619 9 13 L 9 17 C 9 18.381 7.881 19.5 6.5 19.5 L 3.5 19.5 C 1.567 19.5 0 17.933 0 16 Z"
                            fill={colors.animationForground}
                        ></path>
                    </svg>
                </motion.div>
                <motion.div
                    animate={plus}
                    style={{
                        left: 16,
                        top: 14,
                        position: "absolute",
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                        <path
                            d="M 7 2 L 7 12"
                            fill="transparent"
                            strokeWidth="3"
                            stroke={colors.animationForground}
                            strokeLinecap="round"
                        ></path>
                        <path
                            d="M 12 7 L 2 7"
                            fill="transparent"
                            strokeWidth="3"
                            stroke={colors.animationForground}
                            strokeLinecap="round"
                        ></path>
                    </svg>
                </motion.div>
            </motion.div>
        </div>
    )
})
