import Paper from "paper"
import { List } from "immutable"
import {
    CanvasTree,
    ShapeGroupNode,
    PathNode,
    BooleanShapeNode,
    DrawableNode,
    ShapeContainerNode,
} from "document/models/CanvasTree"
import { Point } from "framer"
import { PathSegment, WithPaths, WithPath, LineCap, LineJoin } from "framer"
import type { WithStroke } from "document/models/CanvasTree/traits/Stroke"
import type { WithPosition } from "document/models/CanvasTree/traits/Position"
import type { WithSize } from "document/models/CanvasTree/traits/Size"
import normalizeWithPath from "utils/paperjs/utils/normalizeWithPath"
import { NullID } from "document/models/CanvasTree"
import { PathBooleanType } from "document/models/CanvasTree/traits/PathBoolean"
import type { WithOpacity } from "document/models/CanvasTree/traits/Opacity"
import type { WithFill, FillType, WithOptionalFill } from "document/models/CanvasTree/traits/Fill"
import { LinearGradient, RadialGradient } from "document/models/Gradient"
import { GradientColorStops, GradientColorStop } from "document/models/GradientColorStop"

/**
 * This file provides functions that convert Paperjs modelled paths into
 * Framer modelled equivalents.
 */

type MaybeDrawableNode = null | DrawableNode

export interface PathItemToWithPathsOptions {
    matrix: Paper.Matrix | null
    // Previous versions held additional options; and I expect them
    // to be expanded again, so leaving in the options object for
    // now.
}

const defaultPathItemToWithPathsOptions: PathItemToWithPathsOptions = {
    matrix: null,
}

export function convertPaperPathItemToWithPaths({
    item,
    parentAbsolutePosition = Point(0, 0),
    options = defaultPathItemToWithPathsOptions,
}: {
    item: Paper.PathItem
    parentAbsolutePosition?: Point
    options?: PathItemToWithPathsOptions
}): WithPaths {
    if (item instanceof Paper.CompoundPath) {
        return convertPaperCompoundPathToWithPaths({ compoundPath: item, parentAbsolutePosition, options })
    } else if (item instanceof Paper.Path) {
        return [convertPaperPathToWithPath({ path: item, parentAbsolutePosition, options })]
    } else {
        throw new Error("Unexpected path type")
    }
}

export function convertPaperPathToWithPath({
    path,
    parentAbsolutePosition = Point(0, 0),
    options = defaultPathItemToWithPathsOptions,
}: {
    path: Paper.Path
    parentAbsolutePosition?: Point
    options?: PathItemToWithPathsOptions
}): WithPath {
    if (options.matrix !== null) {
        path.segments.forEach(paperSegment => {
            paperSegment.transform(options.matrix as Paper.Matrix)
        })
    }

    const segments = path.segments.map(segment => {
        const { point, handleIn, handleOut } = segment
        return new PathSegment({
            // Paper's point coordinates are absolute, but we need x/y to be
            // relative to our parent.
            x: point.x - parentAbsolutePosition.x,
            y: point.y - parentAbsolutePosition.y,
            // handleIn/handleOut are relative to "point", so no need to offset
            handleInX: handleIn.x,
            handleInY: handleIn.y,
            handleOutX: handleOut.x,
            handleOutY: handleOut.y,
            handleMirroring: inferHandleMirroring(segment),
        })
    })

    return { pathSegments: List(segments), pathClosed: path.closed }
}

export function convertPaperCompoundPathToWithPaths({
    compoundPath,
    parentAbsolutePosition = Point(0, 0),
    options = defaultPathItemToWithPathsOptions,
}: {
    compoundPath: Paper.CompoundPath
    parentAbsolutePosition?: Point
    options?: PathItemToWithPathsOptions
}): WithPaths {
    let withPaths: WithPaths = []

    compoundPath.children.forEach(paperPath => {
        if (paperPath instanceof Paper.PathItem) {
            withPaths = withPaths.concat(
                convertPaperPathItemToWithPaths({ item: paperPath, parentAbsolutePosition, options })
            )
        }
    })

    return withPaths
}

export function inferHandleMirroring(segment: Paper.Segment): PathSegment.HandleMirroring {
    const { handleIn, handleOut } = segment

    if (handleIn.x === 0 && handleIn.y === 0 && handleOut.x === 0 && handleOut.y === 0) {
        return "straight"
    }

    if (handleIn.x === -handleOut.x && handleIn.y === -handleOut.y) {
        return "symmetric"
    }

    return "disconnected"
}

export function convertPaperColorToString(color: Paper.Color | string | undefined) {
    if (color instanceof Paper.Color) {
        return color.toCSS(false)
    } else if (!color) {
        return "rgba(0,0,0,1)"
    } else {
        throw Error(`Paper.Color unexpectedly passed as a string: ${color}`)
    }
}

export function convertPaperItemToNodes({
    item,
    nodes = [],
    parent = null,
    parentAbsolutePosition = Point(0, 0),
    tree = CanvasTree.createEmpty(),
    fillOverrides,
}: {
    item: Paper.Item
    nodes?: DrawableNode[]
    parent?: MaybeDrawableNode
    parentAbsolutePosition?: Point
    tree?: CanvasTree
    fillOverrides?: Partial<WithFill>
}): DrawableNode[] {
    if (item instanceof Paper.Group) {
        return convertPaperGroupToNodes({ group: item, nodes, parent, parentAbsolutePosition, tree, fillOverrides })
    } else if (item instanceof Paper.PathItem) {
        return convertPaperPathItemToNodes({ item, nodes, parent, parentAbsolutePosition, fillOverrides })
    } else if (process.env.NODE_ENV !== "test") {
        // eslint-disable-next-line no-console
        console.warn(`Unhandled Paper item:`, item)
    }
    return nodes
}

export function convertPaperGroupToNodes({
    group,
    nodes = [],
    parent = null,
    parentAbsolutePosition = Point(0, 0),
    tree = CanvasTree.createEmpty(),
    fillOverrides,
}: {
    group: Paper.Group
    nodes?: DrawableNode[]
    parent?: MaybeDrawableNode
    parentAbsolutePosition?: Point
    tree?: CanvasTree
    fillOverrides?: Partial<WithFill>
}): DrawableNode[] {
    const bounds = convertPaperItemBounds(group)
    const parentid = parent ? parent.id : NullID

    let node: DrawableNode
    let nodeAbsolutePosition: Point

    if (parentid) {
        node = new ShapeGroupNode({
            parentid,
            // Paper's bounds coordinates are absolute, but we need x/y to be
            // relative to our parent.
            x: bounds.x - parentAbsolutePosition.x,
            y: bounds.y - parentAbsolutePosition.y,
            width: bounds.width,
            height: bounds.height,
        })
        nodeAbsolutePosition = Point(bounds.x, bounds.y)
    } else {
        node = new ShapeContainerNode({
            parentid,
            left: 0,
            top: 0,
            width: bounds.x + bounds.width,
            height: bounds.y + bounds.height,
            fillEnabled: false,
        })
        nodeAbsolutePosition = Point(0, 0)
    }

    nodes.push(node)

    group.children.forEach(groupChild => {
        convertPaperItemToNodes({
            item: groupChild,
            nodes,
            parent: node,
            parentAbsolutePosition: nodeAbsolutePosition,
            tree,
            fillOverrides,
        })
    })

    return nodes
}

export function convertPaperPathItemToNodes({
    item,
    nodes = [],
    parent = null,
    parentAbsolutePosition = Point(0, 0),
    fillOverrides,
}: {
    item: Paper.PathItem
    nodes?: DrawableNode[]
    parent?: MaybeDrawableNode
    parentAbsolutePosition?: Point
    fillOverrides?: Partial<WithFill>
}): DrawableNode[] {
    const withPaths = convertPaperPathItemToWithPaths({ item, parentAbsolutePosition })
    const pathAttributes = convertPaperPathItemAttributes(item)
    const parentid = parent ? parent.id : NullID

    const isSingular = withPaths.length === 1
    if (isSingular) {
        const [{ pathSegments, pathClosed }, frame] = normalizeWithPath(withPaths[0])

        const node = new PathNode({
            parentid,
            pathSegments,
            pathClosed,
            ...pathAttributes,
            ...fillOverrides,
            ...frame,
        })

        nodes.push(node)
    } else if (withPaths.length) {
        const bounds = convertPaperItemBounds(item)
        // Paper's bounds coordinates are absolute, but we need x/y to be
        // relative to our parent.
        const booleanContainerX = bounds.x - parentAbsolutePosition.x
        const booleanContainerY = bounds.y - parentAbsolutePosition.y

        const node = new BooleanShapeNode({
            parentid,
            pathBoolean: PathBooleanType.Join,
            ...pathAttributes,
            ...fillOverrides,
            x: booleanContainerX,
            y: booleanContainerY,
            width: bounds.width,
            height: bounds.height,
        })
        nodes.push(node)

        withPaths.forEach(withPath => {
            const [{ pathSegments, pathClosed }, frame] = normalizeWithPath(withPath)

            const childNode = new PathNode({
                parentid: node.id,
                pathSegments,
                pathClosed,
                ...fillOverrides,
                // frame.x/y coordinates are relative to "parent", but since we're
                // inserting a BooleanShapeNode "container" in between, we need to
                // offset by that container's x/y.
                x: frame.x - booleanContainerX,
                y: frame.y - booleanContainerY,
                width: frame.width,
                height: frame.height,
            })

            nodes.push(childNode)
        })
    }

    return nodes
}

export function convertPaperItemBounds(item: Paper.Item): WithPosition & WithSize {
    const { x, y, width, height } = item.bounds
    return {
        x,
        y,
        width,
        height,
    }
}

export function convertPaperPathItemAttributes(
    item: Paper.PathItem
): WithStroke & Partial<WithOptionalFill> & WithOpacity {
    const paperFillColor = item.fillColor as Paper.Color

    let fillType: FillType = "color"
    let fillLinearGradient: LinearGradient | undefined
    let fillRadialGradient: RadialGradient | undefined

    const gradientColor = paperFillColor as Paper.IGradientColor
    if (gradientColor && gradientColor.origin && gradientColor.destination) {
        const paperGradient = paperFillColor.gradient
        if (paperGradient) {
            // We currently don't support color stops, so we take the first and the last stop:
            const firstStop = paperGradient.stops[0]
            const lastStop = paperGradient.stops[paperGradient.stops.length - 1]
            const stops: GradientColorStops = List([
                new GradientColorStop({ value: convertPaperColorToString(firstStop.color), position: 0 }),
                new GradientColorStop({ value: convertPaperColorToString(lastStop.color), position: 1 }),
            ])

            let angle = 180
            let radialGradientOptions: Partial<RadialGradient> | undefined

            if (paperGradient.radial) {
                const width = Math.max(item.bounds.width, 1)
                const height = Math.max(item.bounds.height, 1)
                const centerAnchorX = gradientColor.origin.x / width
                const centerAnchorY = gradientColor.origin.y / height
                const startPoint = { x: centerAnchorX, y: centerAnchorY }
                const endPoint = {
                    x: gradientColor.destination.x / width,
                    y: gradientColor.destination.y / height,
                }

                const mainLength = Point.distance(startPoint, endPoint)
                // I'm not sure how to get the cross length out of the paper data
                const crossLength = mainLength
                // We guess the main axis because we don't support rotated radial gradients
                // We don't support rotated radial gradients because that's not possible with CSS
                const isVertical = Math.abs(startPoint.y - endPoint.y) > Math.abs(startPoint.x - endPoint.x)
                const widthFactor = isVertical ? crossLength : mainLength
                const heightFactor = isVertical ? mainLength : crossLength

                fillType = "radial-gradient"
                radialGradientOptions = {
                    centerAnchorX,
                    centerAnchorY,
                    widthFactor,
                    heightFactor,
                }
            } else {
                fillType = "linear-gradient"
                angle = (90 + gradientColor.destination.subtract(gradientColor.origin).angle) % 360
            }
            fillLinearGradient = new LinearGradient({
                stops,
                angle,
            })
            fillRadialGradient = new RadialGradient({
                stops,
                ...radialGradientOptions,
            })
        }
    }

    return {
        strokeEnabled: item.hasStroke(),
        strokeAlignment: "center",
        strokeWidth: item.strokeWidth,
        strokeColor: convertPaperColorToString(item.strokeColor),
        strokeMiterLimit: item.miterLimit,
        strokeDashArray: item.dashArray.join(","),
        strokeDashOffset: item.dashOffset,
        lineJoin: item.strokeJoin as LineJoin,
        lineCap: item.strokeCap as LineCap,
        fillEnabled: item.hasFill(),
        fillColor: convertPaperColorToString(item.fillColor),
        fillType,
        fillLinearGradient,
        fillRadialGradient,
        opacity: item.opacity,
    }
}
