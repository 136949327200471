import * as React from "react"
import { VisualElement, FramerTreeLayoutContext, SharedLayoutSyncMethods } from "framer-motion"
import { LayoutTreeContext, LayoutTreeContextProps } from "./TreeContext"

/**
 * @internal
 */
export interface LayoutTreeProps {
    /**
     * Mark the tree as being the lead tree. The lead tree in the NavigationStack will perform a
     * shared layout animation if `animatesLayout` is true.
     */
    isLead?: boolean
    /**
     * Mark the tree as being removed.
     * For example if the parent NavigationContainer is being removed, or is simulating removal.
     */
    isExiting?: boolean
    /**
     * If performing a layout animation, use this transition.
     */
    transition?: any
    /**
     * Should updating `isLead` to `true` trigger a shared layout animation.
     */
    animatesLayout?: boolean
    children?: React.ReactNode
    id?: string
}

interface ContextLayoutTreeProps extends LayoutTreeProps {
    treeContext: LayoutTreeContextProps
}

/**
 * @internal
 */
export class LayoutTree extends React.Component<ContextLayoutTreeProps> {
    /**
     * The VisualElement with the smallest depth in the tree's children.
     */
    rootChild?: VisualElement

    /**
     * When a tree is tagged as being removed, either by usePresence, or by Navigation,
     * we set it on `this` in shouldComponentUpdate so that it's accessible
     * by AnimateLayoutTrees before the LayoutTree has updated.
     */
    isExiting?: boolean

    /**
     * A list of all the children in the tree
     */
    children = new Map<string, VisualElement>()

    /**
     * SharedLayoutTrees need to run an animation to reset their boxes if they are becoming lead
     * but not animating.
     */
    layoutMayBeMutated = false

    /**
     * Hijack the syncContext that would otherwise be created by AnimateSharedLayout,
     * and use it to register to the LayoutTree's children, instead.
     */
    syncContext: SharedLayoutSyncMethods = {
        add: () => {},
        flush: () => {},
        syncUpdate: force => {},
        forceUpdate: () => {
            this.syncContext = { ...this.syncContext }
            this.forceUpdate()
        },
        register: child => this.addChild(child),
        remove: child => this.removeChild(child),
    }

    addChild(child: VisualElement): void {
        const layoutId = child.getLayoutId()
        if (!layoutId) return

        this.children.set(layoutId, child)
        this.setRootChild(child)
    }

    /**
     * As children are added, make sure that `this.rootChild` is always the child with the smallest depth.
     */
    setRootChild(child: VisualElement) {
        if (!this.rootChild) return (this.rootChild = child)

        this.rootChild = this.rootChild.depth < child.depth ? this.rootChild : child
    }

    removeChild(child: VisualElement) {
        const layoutId = child.getLayoutId()
        if (layoutId) this.children.delete(layoutId)
    }

    componentDidMount() {
        const { isLead, animatesLayout, transition } = this.props

        if (isLead !== undefined && isLead) this.props.treeContext.promoteTree(this, !!animatesLayout, transition)
    }

    shouldComponentUpdate({ isLead, isExiting, animatesLayout, transition }: LayoutTreeProps) {
        this.isExiting = isExiting
        /**
         * Since Navigation wraps it's child NavigationContainers in a NavigationContainer,
         * we need to ensure that we don't prevent updates if we are not handling layout animations.
         */
        if (this.props.isLead === undefined) return true

        const hasBecomeLead = !this.props.isLead && isLead
        const hasExitBeenCancelled = this.props.isExiting && !isExiting

        const shouldPromote = hasBecomeLead || hasExitBeenCancelled
        if (this.layoutMayBeMutated && shouldPromote && !animatesLayout) {
            /**
             * Trees that previously performed some magic motion animation,
             * and become the lead tree but don't perform a magic motion animation
             * this time, need to run a reset animation.
             */
            return this.props.treeContext.promoteTree(this, true, { type: false }, true)
        } else if (shouldPromote) {
            /**
             * Tree's get promoted to lead even if they aren't going to animate with Magic Motion,
             * since we need to ensure that subsequent transitions that might animate with Magic Motion
             * are able to determine the appropriate follow tree.
             */
            return this.props.treeContext.promoteTree(this, !!animatesLayout, transition)
        } else if (isExiting && !animatesLayout) {
            this.props.treeContext.markTreeAsSafeToRemove(this)
        }

        /**
         * LayoutTrees should not update unless they've become the lead tree.
         */
        return false
    }

    render() {
        return (
            <FramerTreeLayoutContext.Provider value={this.syncContext}>
                {this.props.children}
            </FramerTreeLayoutContext.Provider>
        )
    }
}

export const SharedLayoutTree = (props: LayoutTreeProps) => {
    const treeContext = React.useContext(LayoutTreeContext)
    return <LayoutTree {...props} treeContext={treeContext} />
}
