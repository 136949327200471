import type { CanvasNode } from "document/models/CanvasTree"
import type { StackDirection, StackDistribution, StackAlignment } from "framer"

export interface WithStackLayout {
    stackEnabled: boolean | undefined
    stackDirection: StackDirection | undefined
    stackDistribution: StackDistribution | undefined
    stackAlignment: StackAlignment | undefined
    stackGap: number | undefined
}

export const stackLayoutDefaults: WithStackLayout = {
    stackEnabled: undefined,
    stackDirection: undefined,
    stackDistribution: undefined,
    stackAlignment: undefined,
    stackGap: undefined,
}

const key: keyof WithStackLayout = "stackEnabled"

export function hasStackLayout(node: CanvasNode): node is CanvasNode & WithStackLayout {
    return key in node
}
