// ♻️
// IMPORTANT: Take care to sync changes with the Swift counterpart.

import { ServiceChannel } from "../ServiceChannel"
import { ServiceDebugging } from "../ServiceDebugging"
import type { Window, MessageEvent } from "../environment"

// ❗️
// The Services package needs to work in any TypeScript environment. Reference DOM types without importing or depending
// on them so that the package compiles, but fails or skips functionality when used in a non-DOM environment.
declare const window: never
const windowIfExists = typeof window === "undefined" ? undefined : (window as Window)

/**
 * Channel that communicates with another `PostMessageChannel`, either using a `postMessage` entry point (e.g.
 * an iframe or parent frame), with PlaywrightPageChannel, WebContentsChannel, or PostMessageChannel on the Swift side.
 */
export class PostMessageChannel implements ServiceChannel {
    private lazyLog?: ServiceDebugging.Logger
    private get log() {
        const log = this.lazyLog ?? ServiceDebugging.log.extend("PostMessageChannel")
        this.lazyLog = log
        return log
    }

    static get toParentFrame() {
        isConstructingParentFrameChannel = true
        parentFrameChannel =
            parentFrameChannel ??
            new PostMessageChannel(PostMessageChannel.targetRepresentingParentFrame, __originInitializeLater)
        isConstructingParentFrameChannel = false
        return parentFrameChannel
    }

    // Fallback for frames that don't have a window.parent: detect an external controller (Swift, Electron or Playwright)
    private static targetRepresentingParentFrame = (() => {
        const messageHandlerKey = "__targetRepresentingParentFrame"

        // Find a magical proxy to the controller, which must be set up before any other script runs.
        // Note that this is "secure" because any actor that can assign something to the window global
        // already has access to the entire runtime anyway.
        const messageHandler =
            (windowIfExists as any)?.[messageHandlerKey] ??
            (windowIfExists as any)?.["webkit"]?.messageHandlers?.[messageHandlerKey]

        return {
            disabled: !messageHandler,
            postMessage: (...args: any[]) => {
                if (!windowIfExists) {
                    throw new Error("PostMessageChannel requires a DOM environment")
                } else if (!messageHandler) {
                    throw new Error(`Can't find window.parent or ${messageHandlerKey} message handler`)
                }
                messageHandler.postMessage(...args)
            },
        }
    })()

    constructor(private readonly target: { postMessage: Window["postMessage"] }, targetOrigin: string) {
        // When targeting the parent frame, either use the actual parent or the messageHandler set up by Swift, Playwright, or Electron.
        const targetRepresentingParentFrame = PostMessageChannel.targetRepresentingParentFrame
        if (
            target === (windowIfExists ? windowIfExists.parent : undefined) ||
            target === targetRepresentingParentFrame
        ) {
            if (!isConstructingParentFrameChannel || parentFrameChannel !== undefined) {
                throw new Error(
                    "PostMessageChannel.toParentFrame must be used instead of initializing with window.parent."
                )
            } else if (!windowIfExists) {
                // Running in a test environment
                this.target = {
                    postMessage: (...args: any[]) => {
                        this.log.debug("postMessage to parent channel not running in a DOM environment: ", args)
                    },
                }
                return
            } else if (windowIfExists.parent !== windowIfExists) {
                // Use the actual parent if it exists
                this.target = windowIfExists.parent
            } else {
                // Use the messageHandler set up by Swift, Playwright, or Electron.
                this.target = targetRepresentingParentFrame
                this.disabled = targetRepresentingParentFrame.disabled
            }
        }

        if (targetOrigin !== __originInitializeLater) {
            this.initializeTrustedOrigin(targetOrigin)
        }
    }

    private trustedOrigin = __originInitializeLater
    initializeTrustedOrigin(origin: string) {
        if (this.trustedOrigin !== __originInitializeLater) {
            if (this === parentFrameChannel && origin === this.trustedOrigin) {
                // Allow setting the same origin twice, but only on channelToParentFrame. This exception only exists
                // to make it easier to initialize both channelToParentFrame and channelToOpenerFrame, which falls
                // back to channelToParentFrame if there's no window opener.
            } else {
                throw new Error(`PostMessageChannel can only be initialized with a trusted origin once`)
            }
        }

        if (origin === "*") {
            // FIXME: continue, but this should probably not be allowed on production
        } else if (!origin.includes("://")) {
            throw new Error(
                `PostMessageChannel can only be initialized with a concrete origin (https://...); received ${origin}`
            )
        }

        this.trustedOrigin = origin
    }

    readonly disabled?: boolean

    postMessage(message: ServiceChannel.Message): void {
        this.log.trace("↗︎", message)

        // Note: if the origin hasn't been initialized, just let the browser fail with the default empty string
        this.target.postMessage(message, this.trustedOrigin)
    }

    postMessageRaw(message: unknown): void {
        // Note: if the origin hasn't been initialized, just let the browser fail with the default empty string
        this.target.postMessage(message, this.trustedOrigin)
    }

    addMessageListener(callback: (message: ServiceChannel.Message) => void): void {
        if (this.listeners.size === 0) {
            // Note: message events sent by `target` arrive on `window`
            windowIfExists?.addEventListener("message", this.onMessageEvent, false)
        }

        this.listeners.add(callback)
    }

    removeMessageListener(callback: (message: ServiceChannel.Message) => void): void {
        this.listeners.delete(callback)

        if (this.listeners.size === 0) {
            windowIfExists?.removeEventListener("message", this.onMessageEvent, false)
        }
    }

    private readonly listeners: Set<(message: ServiceChannel.Message) => void> = new Set()

    private onMessageEvent = (event: MessageEvent) => {
        this.log.trace(event)

        // Ignore events that don't come from the postMessage target
        let isTrustedSource = false
        if (event.source !== this.target) {
            if (
                // But, if the "parent frame" is actually an external controller
                this === parentFrameChannel &&
                // ...which produces a local postMessage (see PostMessageChannel.swift, PlaywrightPageChannel.ts, WebContentsChannel.ts)
                event.source === windowIfExists &&
                // ...marking the event with this special attribute
                (event.data as any)?.__sourceRepresentsParentFrame
            ) {
                // ...then we need to handle it regardless of origin
                isTrustedSource = true
            } else {
                // ...if not, ignore
                return
            }
        }

        // Ignore events with an unexpected origin. Note we should only perform this check after the source has been
        // verified, otherwise we'd be throwing errors for random incoming messages not intended for this channel.
        if (!isTrustedSource && event.origin !== this.trustedOrigin) {
            if (this.trustedOrigin === "*") {
                // FIXME: continue, but this should probably not be allowed on production
            } else {
                if (this.trustedOrigin) {
                    throw new Error(
                        `PostMessageChannel received a message with origin ${event.origin}, expected ${this.trustedOrigin}`
                    )
                } else {
                    throw new Error(
                        `PostMessageChannel received a message with origin ${event.origin}, but has not been configured with initializeTrustedOrigin`
                    )
                }
            }
        }

        // Ignore externally handled events
        if (this.interceptor?.handleRawEvent(event)) {
            return
        }

        // Ignore things that are certainly not service messages
        const message = event.data
        if (!ServiceChannel.isMessage(message)) {
            return
        }

        // Notify
        for (const listener of this.listeners) {
            listener(message)
        }
    }

    /**
     * Registers the callback as a filter for incoming messages from the opener frame. Useful when
     * handling postMessage events manually as well as using services on the opener.
     */
    static interceptMessageEventsFromOpenerFrame(interceptor?: (_: MessageEvent) => boolean) {
        channelToOpenerFrame.setInterceptor(interceptor)
    }

    private interceptor?: {
        handleRawEvent: (_: MessageEvent) => boolean
        unusedMessageListenerOnlyForCounting: (_: ServiceChannel.Message) => void
    }

    private setInterceptor(callback?: (_: MessageEvent) => boolean) {
        if (this.interceptor) {
            this.removeMessageListener(this.interceptor.unusedMessageListenerOnlyForCounting)
        }

        this.interceptor = callback
            ? {
                  handleRawEvent: callback,
                  unusedMessageListenerOnlyForCounting: () => {},
              }
            : undefined

        if (this.interceptor) {
            this.addMessageListener(this.interceptor.unusedMessageListenerOnlyForCounting)
        }
    }
}

// Special channel to the parent frame
let isConstructingParentFrameChannel = false
let parentFrameChannel: PostMessageChannel | undefined

// Magic constant to allow the channels below to be constructed before the app can configure them
const __originInitializeLater = "data:origin-not-initialized"

/**
 * The channel to either the parent frame, or a Playwright, Electron or Swift controller.
 * Should be used in favor of PostMessageChannel.toParentFrame.
 */
export const channelToParentFrame: PostMessageChannel = PostMessageChannel.toParentFrame

/**
 * The channel to window.opener. Defaults to channelToParentFrame if there's no
 * opener, or if the opener is the current window.
 */
export const channelToOpenerFrame: PostMessageChannel =
    windowIfExists &&
    windowIfExists.opener &&
    windowIfExists !== windowIfExists.opener &&
    windowIfExists.parent === windowIfExists
        ? new PostMessageChannel(windowIfExists.opener, __originInitializeLater)
        : channelToParentFrame
