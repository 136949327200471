import type { AssetSize } from "../types/Asset"
import type { AssetContext, AssetResolver } from "../types/AssetResolver"
import { parseAssetReference } from "../assetReference"
import { getAssetMinSideSize } from "../getAssetMinSideSize"
import { stripExtension } from "../stripExtension"

// This is a duplication of the dimensions that are defined in
// FXDocumentContainer.swift. Should be ordered from small to large
const cachedImageDimensions = [512, 1024, 2048, 4096]
function imagePathForSize(
    imageName: string,
    assetSize: AssetSize | null,
    layerMaxSideSize: number | undefined
): string {
    let minSize = getAssetMinSideSize(assetSize, layerMaxSideSize, false /* limit max size */)

    if (minSize === undefined || imageName.startsWith("node_modules")) {
        // don't apply cache size for third party images
        // HOTFIX. Proper fix: regenerate sizes for third party images
        return imageName
    }
    for (const dimension of cachedImageDimensions) {
        if (dimension >= minSize) {
            minSize = dimension
            break
        }
    }

    const cacheDir = `../../.cache/images/${minSize}/`
    // Prevent ./cache/images/512/design/images/xxx.png
    imageName = imageName.replace(/^(?:\.\/)?design\/images\//, "")
    return cacheDir + imageName
}

function resolveImagePath({
    imagePath,
    imageBaseURL,
    isExport,
    relativeToProjectRoot = false,
}: {
    imagePath: string
    imageBaseURL: string
    isExport: boolean
    relativeToProjectRoot?: boolean
}): string {
    // absolute paths
    if (imagePath.startsWith("http://") || imagePath.startsWith("https://") || imagePath.startsWith("file://")) {
        return imagePath
    }

    let baseURL = imageBaseURL.replace(/\/$/, "")

    if (relativeToProjectRoot || imagePath.startsWith("node_modules/")) {
        // make sure baseURL is NOT relative to design/images
        baseURL = baseURL.replace(/\/design\/images$/, "")
    } else {
        // make sure baseURL IS relative to design/images
        if (!baseURL.endsWith("/design/images")) {
            baseURL += "/design/images"
        }
        // ... and that we won't end up with design/images/design/images
        imagePath = imagePath.replace(/^(?:\.\/)?design\/images\//, "")
    }

    if (isExport) {
        return `##base64-${baseURL}/${imagePath}##`
    }
    return `${baseURL}/${imagePath}`
}

/**
 * @internal
 * @param reference
 * @param context
 */
export function desktopAssetResolver(imageBaseURL: string) {
    const assetResolver: AssetResolver = (reference: string | undefined, context: AssetContext): string | undefined => {
        if (!reference) {
            return undefined
        }

        // "resource URLs" must resolve relative to the project root
        // - this is the "url" function from framer/resource (which is actually the
        //   "serverURL" function from ./serverURL.ts)
        // - or old code components with file props, which are "design/assets/xxx"
        let relativeToProjectRoot = context.isFramerResourceURL

        let path: string
        const details = parseAssetReference(reference)
        if (details) {
            const { identifier, packageIdentifier } = details
            if (packageIdentifier) {
                path = `node_modules/${packageIdentifier}/${identifier}`
            } else {
                path = identifier
            }
            // asset references always resolve relative to design/images
            relativeToProjectRoot = false
        } else {
            path = reference
        }

        path = stripExtension(path)
        const { size, isExport = false } = context
        const prefferedSize: AssetSize | null = context.preferredSize ?? details?.preferredSize ?? null
        const imagePath = imagePathForSize(path, prefferedSize, size)
        const resolvedImage = resolveImagePath({
            imagePath,
            imageBaseURL,
            isExport,
            relativeToProjectRoot,
        })
        return resolvedImage
    }
    return assetResolver
}
