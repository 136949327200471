import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springAnimation } from "./utils"
import { colors } from "../tokens"

const width = 50
const height = 50

export const AnimationCodeEditor = React.memo(function AnimationCodeEditor() {
    const blink = useAnimation()

    async function animation() {
        await blink.start({
            opacity: 1,
            transition: springAnimation,
        })

        return await blink.start({
            opacity: 0,
            transition: springAnimation,
        })
    }

    useLoop(animation)

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                overflow: "hidden",
                width: width,
                height: height,
                borderRadius: 8,
                background: colors.animationBackgroundLight,
            }}
        >
            <motion.div
                style={{
                    position: "absolute",
                    opacity: 1,
                    scale: 0.9,
                    top: 12,
                    left: 10,
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26">
                    <path
                        d="M 5 5 L 12 12 L 5 20"
                        fill="transparent"
                        strokeWidth="3"
                        stroke={colors.animationForground}
                        strokeLinecap="round"
                    ></path>
                </svg>
            </motion.div>
            <motion.div animate={blink} style={{ position: "absolute", opacity: 1, left: 26, top: 21 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="3">
                    <path
                        d="M 0 1.75 C 0 0.784 0.784 0 1.75 0 L 8.25 0 C 9.216 0 10 0.784 10 1.75 L 10 1.75 C 10 2.716 9.216 3.5 8.25 3.5 L 1.75 3.5 C 0.784 3.5 0 2.716 0 1.75 Z"
                        fill={colors.animationForground}
                    ></path>
                </svg>
            </motion.div>
        </div>
    )
})
