import type { CanvasNode } from "../../nodes/CanvasNode"
import type { MotionStyle } from "framer-motion"
import type { CollectTraitOptions } from "."
import { withBlending } from "document/models/CanvasTree/traits/Blending"

export function collectBlending(
    node: CanvasNode,
    style: MotionStyle,
    { ignoreDefaults = true, unset = false }: CollectTraitOptions = {}
) {
    if (!withBlending(node)) return
    if (!node.blendingMode) {
        if (unset) style.mixBlendMode = "normal"
        return
    }

    if (ignoreDefaults && node.blendingMode === "normal") return

    style.mixBlendMode = node.blendingMode
}
