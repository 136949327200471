import * as React from "react"

// Todo: remove all draftjs
import { DraftInlineStyle, ContentBlock } from "draft-js"
import { getCSSNumberUnit } from "../cssUtils"
import { draftStyleDefinitions, DraftPrefix } from "../."

// Style queries and changes

function isStyleHandler<T = any>(object: any): object is StyleHandler<T> {
    return object.setCSS !== undefined
}

/** Returns a bolder number if it's a number font weight, otherwise simply `bold` */
function getCSSBolderFontWeight(value?: string | number, increase = 300, max = 900) {
    const [num] = getCSSNumberUnit(value || "")
    return num !== null ? Math.min(num + increase, max) : "bold"
}

function _getDefaultStyles(definitions: DraftStyleDefinitions): React.CSSProperties {
    const styles: React.CSSProperties = {}
    for (const styleType in definitions) {
        const styleHandler = definitions[styleType]
        if (isStyleHandler(styleHandler)) {
            styleHandler.setCSS(styleHandler.default, styles)
        }
    }
    return styles
}

export function getStylesFromDraft(
    definitions: DraftStyleDefinitions,
    draftStyle: DraftInlineStyle | string[], // Todo: this should be cleaned up
    ignore: DraftPrefix[] = []
): React.CSSProperties {
    const styles: React.CSSProperties = {}

    // Todo: this only _accidently_ also works for arrays
    draftStyle.forEach((style: DraftPrefix) => {
        if (ignore.includes(style)) {
            return
        } else if (style === "BOLD") {
            styles.fontWeight = getCSSBolderFontWeight(styles.fontWeight)
        } else if (style === "ITALIC") {
            styles.fontStyle = "italic"
        } else if (style === "SELECTION") {
            // If you don't want this, you can add "SELECTION" to the ignore list
            // TODO: this should really not be a static value here, but that's how I found it
            styles.backgroundColor = "rgba(128,128,128,0.33)"
        } else {
            // Todo: we can make this lookup a lot faster
            for (const styleType in definitions) {
                const styleHandler = definitions[styleType]
                if (!isStyleHandler(styleHandler)) continue
                if (style.startsWith(styleHandler.prefix)) {
                    styleHandler.setCSS(style.slice(styleHandler.prefix.length), styles)
                    break
                }
            }
        }
    })

    return styles
}

/**
 * @internal
 * This is the custom draft render function used everywhere
 */
export const draftStyleFunction = (
    draftStyles: DraftInlineStyle | string[],
    block?: ContentBlock,
    styleSelection: boolean = true
): React.CSSProperties => {
    return {
        // XXX:DRAFT Todo: we should really cache the defaults here to save some compute
        ..._getDefaultStyles(draftStyleDefinitions),
        ...getStylesFromDraft(draftStyleDefinitions, draftStyles, styleSelection ? [] : ["SELECTION"]),
        tabSize: 4,
    }
}

/**
 * A set of functions which define how to apply a specific attribute of text
 * styling (font, color, size, etc.) into CSS and from CSS
 *
 * @internal
 */
interface StyleHandler<T> {
    prefix: string
    default: T
    setCSS: (value: string | T, css: React.CSSProperties) => void
    fromCSS: (css: React.CSSProperties) => string | number | undefined
}

/** @internal */
interface DraftStyleDefinitions {
    font: StyleHandler<string>
    color: StyleHandler<string>
    size: StyleHandler<number>
    letterSpacing: StyleHandler<number>
    lineHeight: StyleHandler<string | number>
    align: StyleHandler<string | undefined>
    textTransform: StyleHandler<TextTransform>
    textDecoration: StyleHandler<TextDecoration>
}

/**
 * @internal
 */
export type TextTransform = "capitalize" | "uppercase" | "lowercase" | undefined

/** @internal */
export type TextDecoration = "underline" | "line-through" | undefined

/** @internal */
export const enum TextLineHeightUnit {
    Pixels = "px",
    Em = "em",
    Percent = "%",
}

/** @internal */
// Matches the `HTMLDir` type in https://github.com/facebook/fbjs/blob/master/packages/fbjs/src/unicode/UnicodeBidiDirection.js
// but adds `undefined` to indicate “neutral”
export type TextDirection = "ltr" | "rtl" | undefined
