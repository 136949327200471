import { localPackageFallbackIdentifier } from "./localPackageFallbackIdentifier"
import type { FramerModuleExports, SandboxPackageInfo, SandboxPackageMap } from "./types"
import { warn } from "./warn"
import type { PackageInfo, HostPackageMap } from "../../host/componentLoader"

export function collectPackages(localExports: FramerModuleExports): [SandboxPackageMap, HostPackageMap] {
    const packages: SandboxPackageMap = {}
    const serializablePackages: HostPackageMap = {}

    // Queue of packages to visit (breadth-first traversal).
    const queue = [{ exportsObject: localExports, depth: 0 }]
    const seenPackages = new Set<string>()
    while (isNotEmpty(queue)) {
        const { exportsObject, depth } = queue.shift()

        const packageInfo = getPackageInfo(exportsObject, depth)
        if (!packageInfo) continue

        // Remove runtime references that can't be serialized
        const serializablePackageInfo: PackageInfo & Partial<SandboxPackageInfo> = { ...packageInfo }
        delete serializablePackageInfo.exportsObject
        delete serializablePackageInfo.dependencies
        delete serializablePackageInfo.sourceModules

        packages[packageInfo.name] = packageInfo
        serializablePackages[packageInfo.name] = serializablePackageInfo
        seenPackages.add(packageInfo.name)

        // Queue up all the dependencies for processing as well.
        for (const dependencyName of Object.keys(packageInfo.dependencies)) {
            if (seenPackages.has(dependencyName)) {
                // We already saw this package once so it doesn't need to be reprocessed.
                // TODO: We should check version and possibly even an integrity hash here.
                continue
            }
            seenPackages.add(dependencyName)
            queue.push({
                exportsObject: packageInfo.dependencies[dependencyName](),
                depth: depth + 1,
            })
        }
    }

    return [packages, serializablePackages]
}

function getPackageInfo(exportsObject: FramerModuleExports, depth: number): SandboxPackageInfo | null {
    // The module exports should contain a Framer-specific object – extract it.
    const info = exportsObject.__framer__
    if (!info) {
        // No extra info, nothing we can do.
        return null
    }

    const { packageJson, dependencies, sourceModules } = info
    let { name } = packageJson
    if (depth === 0 && !name) {
        name = localPackageFallbackIdentifier
    }

    if (!name) {
        // TODO: Log some more information.
        warn("Failed to identify package")
        return null
    }

    return {
        depth,
        displayName: (packageJson.framer && packageJson.framer.displayName) || name,
        exportsObject,
        name,

        componentsJson: packageJson.framer && packageJson.framer.components,
        designJson: packageJson.design,

        dependencies: dependencies || {},
        sourceModules: sourceModules || {},
    }
}

function isNotEmpty<T>(arr: T[]): arr is { shift(): T } & T[] {
    return arr.length > 0
}
