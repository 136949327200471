import * as React from "react"
import { cx } from "linaria"
import type { OverrideType } from "./types"
import * as styles from "./TextArea.styles"
import { useTextInput } from "./utils/useTextInput"
import type { TextInputSharedProps } from "./utils/textInputShared"
import { textInputSharedStyles } from "./utils/textInputShared.styles"

const MIN_ROWS = 2
const MAX_ROWS = 12

const autosize = (el: HTMLTextAreaElement) => {
    const prevOverflow = el.style.overflow
    // switch overflow to hidden for the duration of autosizing, otherwise the
    // scrollbar might skew the results, and we'll end up with one row more than
    // we need
    el.style.overflow = "hidden"
    el.rows = MIN_ROWS
    while (el.scrollHeight > el.offsetHeight && el.rows < MAX_ROWS) {
        el.rows = el.rows + 1
    }
    el.style.overflow = prevOverflow
}

function useAutosizing(ref: React.RefObject<HTMLTextAreaElement>) {
    React.useLayoutEffect(() => {
        if (ref.current) {
            autosize(ref.current)
        }
    }, [ref])
}

export type TextAreaProps = OverrideType<
    Omit<React.ComponentPropsWithoutRef<"textarea">, "disabled">,
    TextInputSharedProps
>

export const TextArea = React.memo(
    React.forwardRef<HTMLTextAreaElement, TextAreaProps>(function TextArea(props, forwardedRef) {
        const { value, enabled = true, autoFocus, onChange, onBlur, onFocus, onKeyDown, className, ...rest } = props

        const { ref, internalValue, changeHandler, blurHandler, focusHandler, keyDownHandler } = useTextInput({
            ref: forwardedRef,
            value,
            enabled,
            autoFocus,
            constantChange: true,
            stopUpDownKeyHandling: false,
            onChange,
            onBlur,
            onFocus,
            onKeyDown,
        })

        // TODO: Currently only tries to auto size on load, needs to take in value
        useAutosizing(ref as React.RefObject<HTMLTextAreaElement>)

        return (
            <textarea
                ref={ref}
                className={cx(textInputSharedStyles, styles.textArea, className)}
                value={internalValue}
                onChange={changeHandler}
                disabled={!enabled}
                onBlur={blurHandler}
                onFocus={focusHandler}
                onKeyDown={keyDownHandler}
                autoFocus={autoFocus}
                autoComplete="nope" // Chrome ignores auto-complete="off"
                autoCorrect="off"
                spellCheck={false}
                {...rest}
            />
        )
    })
)
