import * as React from "react"
import { Transition, AnimatePresence } from "framer-motion"
import { FrameWithMotion, FrameProps } from "../render/presentation/Frame"
import {
    TransitionDefaults,
    NavigationTransition,
    pushTransition,
    overlayTransition,
    flipTransition,
    FadeTransitionOptions,
    PushTransitionOptions,
    ModalTransitionOptions,
    OverlayTransitionOptions,
    FlipTransitionOptions,
    NavigationTransitionAnimation,
    NavigationTransitionBackdropColor,
} from "./NavigationTransitions"
import { NavigationContainer } from "./NavigationContainer"
import { isReactChild, isReactElement } from "../utils/type-guards"
import { injectComponentCSSRules } from "../render/utils/injectComponentCSSRules"
import { navigatorMock } from "./NavigatorMock"
import { LayoutIdProvider } from "./AnimateLayout/LayoutIdContext"
import {
    defaultState,
    reduceNavigationStateForAction,
    NavigationState,
    NavigationAction,
} from "./reduceNavigationStateForAction"
import { AnimateLayoutTrees } from "./AnimateLayout"

/**
 * The navigator allows control over the built-in navigation component in Framer.
 * @public
 */
export interface NavigationInterface {
    /**
     * Go back to the previous screen. If a stack of overlays is presented, all overlays are dismissed.
     * @public
     * */
    goBack: () => void
    /**
     * Show new screen instantly.
     * @param component - The incoming component
     * @public
     */
    instant: (component: React.ReactNode) => void
    /**
     * Fade in new screen.
     * @param component - The incoming component
     * @param options - {@link FadeTransitionOptions}
     * @public
     */
    fade: (component: React.ReactNode, options?: FadeTransitionOptions) => void
    /**
     * Push new screen. Defaults from right to left, the direction can be changed using the {@link NavigationTransitionOptions}.
     * @param component - The incoming component
     * @param options - {@link PushTransitionOptions}
     * @public
     */
    push: (component: React.ReactNode, options?: PushTransitionOptions) => void
    /**
     * Present modal overlay in the center.
     * @param component - The incoming component
     * @param options - {@link ModalTransitionOptions}
     * @public
     */
    modal: (component: React.ReactNode, options?: ModalTransitionOptions) => void
    /**
     * Present overlay from one of four edges. The direction can be changed using the {@link NavigationTransitionOptions}.
     * @param component - The incoming component
     * @param options - {@link OverlayTransitionOptions}
     * @public
     */
    overlay: (component: React.ReactNode, options?: OverlayTransitionOptions) => void
    /**
     * Flip incoming and outgoing screen in 3D. The flip direction can be changed using the {@link NavigationTransitionOptions}.
     * @param component - The incoming component
     * @param options - {@link FlipTransitionOptions}
     * @public
     */
    flip: (component: React.ReactNode, options?: FlipTransitionOptions) => void
    /**
     * Present a screen using a custom {@link NavigationTransition}.
     * @param component - The incoming component
     * @param transition - {@link NavigationTransition}
     * @public
     */
    customTransition: (component: React.ReactNode, transition: NavigationTransition) => void
    /**
     * Animate layers with matching magicIds between screens. Layers are assigned matching IDs if they share a name, or were copied from one another.
     * The transition can be changed using a custom {@link NavigationTransition}.
     * @param component - The incoming component
     * @param transition - {@link NavigationTransition}
     * @public
     */
    magicMotion: (component: React.ReactNode, transition: NavigationTransition) => void
}

/**
 * @internal
 */
export const NavigationContext = React.createContext<NavigationInterface>(navigatorMock)

/**
 * Provides {@link NavigationInterface} that can be used to start transitions in Framer.
 * @public
 */
export const NavigationConsumer = NavigationContext.Consumer

type NavigationCallback = (key: string) => void
const NavigationCallbackContext = React.createContext<NavigationCallback | undefined>(undefined)
/**
 * @internal
 */
export const NavigationCallbackProvider = NavigationCallbackContext.Provider

/**
 * @internal
 */
export interface NavigationProps {
    /** @deprecated - still used by the old library */
    width?: number
    /** @deprecated - still used by the old library */
    height?: number
    style?: React.CSSProperties
}
interface StackState {
    current: number
    previous: number
    history: HistoryItem[]
}

type ContainerKey = string

interface HistoryItem {
    key: ContainerKey
    transition: NavigationTransition
    component?: React.ReactNode
    visualIndex?: number
}

/**
 * @internal
 */
export class Navigation extends React.Component<NavigationProps, NavigationState> implements NavigationInterface {
    private lastEventTimeStamp: number | null = null

    state: NavigationState = defaultState()

    static contextType = NavigationCallbackContext
    declare context: React.ContextType<typeof NavigationCallbackContext>

    componentDidMount() {
        if (this.state.history.length === 0) {
            this.transition(this.props.children, TransitionDefaults.Instant)
        }
        injectComponentCSSRules()
    }

    UNSAFE_componentWillReceiveProps(props: NavigationProps) {
        const component: React.ReactNode = props["children"]
        if (!isReactChild(component) || !isReactElement(component)) return

        const key = component.key?.toString()

        if (!key) return

        if (this.state.history.length === 0) {
            this.transition(component, TransitionDefaults.Instant)
        } else {
            this.navigationAction({ type: "update", key, component })
        }
    }

    private getStackState(options: { overCurrentContext: boolean }): StackState {
        const { current, previous, currentOverlay, previousOverlay } = this.state
        if (options.overCurrentContext) {
            return {
                current: currentOverlay,
                previous: previousOverlay,
                history: this.state.overlayStack,
            }
        }

        return {
            current,
            previous,
            history: this.state.history,
        }
    }

    /**
     * To prevent bubbling events from triggering multiple transitions,
     * we ensure that the current event has a different timestamp then the event that triggered the last transition.
     * We use Window.event to ensure that even transitions invoked by code components - and may not pass a reference to the event - are caught.
     * This works better than measuring the time of transition calls with performance.now()
     * because the time between calls can get longer and longer as more screens are added to the stack,
     * preventing a deterministic time between transitions to be used to determine if they were triggered at the same time or not.
     */
    private isSameEventTransition() {
        // If for some reason window.event is undefined, don't block transitions.
        if (!window.event) return false
        return this.lastEventTimeStamp === window.event.timeStamp
    }

    private navigationAction = (action: NavigationAction) => {
        const newState = reduceNavigationStateForAction(this.state, action)
        if (newState) {
            this.setState(newState)

            const currentItem = newState.history[newState.current]
            this.context?.(currentItem.key)
        }
    }

    private transition(
        component: React.ReactNode,
        transitionTraits: NavigationTransition,
        transitionOptions?: NavigationTransitionAnimation & NavigationTransitionBackdropColor
    ) {
        if (this.isSameEventTransition()) return
        this.lastEventTimeStamp = window.event?.timeStamp || null

        if (!component || !isReactChild(component) || !isReactElement(component)) return

        const transition = { ...transitionTraits, ...transitionOptions }
        const overCurrentContext = !!transition.overCurrentContext

        if (overCurrentContext) return this.navigationAction({ type: "addOverlay", transition, component })

        // If for some reason Navigation is being used in code, and a component instance isn't supplied,
        // generate a unique key to ensure the screen is added.
        const key = component?.key?.toString() || `stack-${this.state.historyItemId + 1}`

        this.navigationAction({ type: "add", key, transition, component })
    }

    goBack = () => {
        if (this.isSameEventTransition()) return
        this.lastEventTimeStamp = window.event?.timeStamp || null

        if (this.state.currentOverlay !== -1) return this.navigationAction({ type: "removeOverlay" })

        return this.navigationAction({ type: "remove" })
    }

    instant(component: React.ReactNode) {
        this.transition(component, TransitionDefaults.Instant)
    }

    fade(component: React.ReactNode, options?: FadeTransitionOptions) {
        this.transition(component, TransitionDefaults.Fade, options)
    }

    push(component: React.ReactNode, options?: PushTransitionOptions) {
        this.transition(component, pushTransition(options), options)
    }

    modal(component: React.ReactNode, options?: ModalTransitionOptions) {
        this.transition(component, TransitionDefaults.Modal, options)
    }

    overlay(component: React.ReactNode, options?: OverlayTransitionOptions) {
        this.transition(component, overlayTransition(options), options)
    }

    flip(component: React.ReactNode, options?: FlipTransitionOptions) {
        this.transition(component, flipTransition(options), options)
    }

    magicMotion(component: React.ReactNode, options?: NavigationTransitionAnimation) {
        this.transition(component, TransitionDefaults.MagicMotion, options)
    }

    customTransition(component: React.ReactNode, transition: NavigationTransition) {
        this.transition(component, transition)
    }

    render() {
        const stackState = this.getStackState({ overCurrentContext: false })
        const overlayStackState = this.getStackState({ overCurrentContext: true })
        const activeOverlay = activeOverlayItem(overlayStackState)

        return (
            <FrameWithMotion
                top={0}
                left={0}
                width="100%"
                height="100%"
                position="relative"
                style={{ overflow: "hidden", backgroundColor: "unset", ...this.props.style }}
            >
                <NavigationContext.Provider value={this}>
                    <NavigationContainer
                        isLayeredContainer
                        position={undefined}
                        initialProps={{}}
                        instant={false}
                        transitionProps={transitionPropsForStackWrapper(activeOverlay)}
                        animation={animationForStackWrapper(activeOverlay)}
                        backfaceVisible={backfaceVisibleForStackWrapper(activeOverlay)}
                        visible
                        backdropColor={undefined}
                        onTapBackdrop={undefined}
                        index={0}
                    >
                        <LayoutIdProvider>
                            <AnimateLayoutTrees>
                                <AnimatePresence presenceAffectsLayout={false}>
                                    {Object.keys(this.state.containers).map(key => {
                                        const component = this.state.containers[key]
                                        const index = this.state.containerIndex[key]
                                        const visualIndex = this.state.containerVisualIndex[key]
                                        const removed = this.state.containerIsRemoved[key]
                                        const historyItem = this.state.history[index]
                                        const transitionProps = this.state.transitionForContainer[key]
                                        const isCurrent = index === this.state.current
                                        const isPrevious = index === this.state.previous
                                        const areMagicMotionLayersPresent = isCurrent ? false : removed

                                        // Since the "lead" always determines which layers can animate,
                                        // and when a "transition back" is performed the "lead" is the new current container,
                                        // we need to ensure that calculations are performed by the new current container
                                        // as if it was being transitioned to with magic motion.
                                        // Since this.state.previousTransition is null unless we are animating a magic motion removal,
                                        // this is a safe way to infer this case.
                                        const withMagicMotion =
                                            historyItem?.transition?.withMagicMotion ||
                                            (isCurrent && !!this.state.previousTransition)

                                        return (
                                            <NavigationContainer
                                                key={key}
                                                id={key}
                                                index={visualIndex}
                                                isCurrent={isCurrent}
                                                isPrevious={isPrevious}
                                                visible={isCurrent || isPrevious}
                                                position={historyItem?.transition?.position}
                                                instant={isInstantContainerTransition(index, stackState)}
                                                transitionProps={transitionProps}
                                                animation={animationPropsForContainer(index, stackState)}
                                                backfaceVisible={getBackfaceVisibleForScreen(index, stackState)}
                                                exitAnimation={historyItem?.transition?.animation}
                                                exitBackfaceVisible={historyItem?.transition?.backfaceVisible}
                                                exitProps={historyItem?.transition?.enter}
                                                withMagicMotion={withMagicMotion}
                                                areMagicMotionLayersPresent={
                                                    areMagicMotionLayersPresent ? false : undefined
                                                }
                                            >
                                                {containerContent({
                                                    component,
                                                    transition: historyItem?.transition,
                                                })}
                                            </NavigationContainer>
                                        )
                                    })}
                                </AnimatePresence>
                            </AnimateLayoutTrees>
                        </LayoutIdProvider>
                    </NavigationContainer>
                    <AnimatePresence>
                        {this.state.overlayStack.map((item, stackIndex) => {
                            return (
                                <NavigationContainer
                                    isLayeredContainer
                                    key={item.key}
                                    isCurrent={stackIndex === this.state.currentOverlay}
                                    position={item.transition.position}
                                    initialProps={initialPropsForOverlay(stackIndex, overlayStackState)}
                                    transitionProps={transitionPropsForOverlay(stackIndex, overlayStackState)}
                                    instant={isInstantContainerTransition(stackIndex, overlayStackState, true)}
                                    animation={animationPropsForContainer(stackIndex, overlayStackState)}
                                    exitProps={item.transition.enter}
                                    visible={containerIsVisible(stackIndex, overlayStackState)}
                                    backdropColor={backdropColorForTransition(item.transition)}
                                    backfaceVisible={getBackfaceVisibleForOverlay(stackIndex, overlayStackState)}
                                    onTapBackdrop={backdropTapAction(item.transition, this.goBack)}
                                    index={this.state.current + 1 + stackIndex}
                                >
                                    {containerContent({ component: item.component, transition: item.transition })}
                                </NavigationContainer>
                            )
                        })}
                    </AnimatePresence>
                </NavigationContext.Provider>
            </FrameWithMotion>
        )
    }
}

const animationDefault: Transition = {
    stiffness: 500,
    damping: 50,
    restDelta: 1,
    type: "spring",
}

interface ActiveOverlay {
    currentOverlayItem: HistoryItem | undefined
    previousOverlayItem: HistoryItem | undefined
}

function activeOverlayItem(overlayStack: StackState): ActiveOverlay {
    let currentOverlayItem: HistoryItem | undefined
    let previousOverlayItem: HistoryItem | undefined
    if (overlayStack.current !== -1) {
        currentOverlayItem = overlayStack.history[overlayStack.current]
    } else {
        previousOverlayItem = overlayStack.history[overlayStack.previous]
    }
    return { currentOverlayItem, previousOverlayItem }
}

function transitionPropsForStackWrapper({ currentOverlayItem }: ActiveOverlay) {
    return currentOverlayItem && currentOverlayItem.transition.exit
}

function animationForStackWrapper({ currentOverlayItem, previousOverlayItem }: ActiveOverlay): Transition {
    if (currentOverlayItem && currentOverlayItem.transition.animation) {
        return currentOverlayItem.transition.animation
    }
    if (previousOverlayItem && previousOverlayItem.transition.animation) {
        return previousOverlayItem.transition.animation
    }
    return animationDefault
}

function backfaceVisibleForStackWrapper({ currentOverlayItem, previousOverlayItem }: ActiveOverlay) {
    if (currentOverlayItem) return currentOverlayItem.transition.backfaceVisible
    return previousOverlayItem && previousOverlayItem.transition.backfaceVisible
}

function backdropColorForTransition(transition: NavigationTransition): string | undefined {
    if (transition.backdropColor) return transition.backdropColor
    if (transition.overCurrentContext) return "rgba(4,4,15,.4)" // iOS dim color
    return undefined
}

function getBackfaceVisibleForOverlay(containerIndex: number, stackState: StackState): boolean | undefined {
    const { current, history } = stackState
    if (containerIndex === current) {
        // current
        const navigationItem = history[containerIndex]
        if (navigationItem && navigationItem.transition) {
            return navigationItem.transition.backfaceVisible
        }
        return true
    } else if (containerIndex < current) {
        // old
        const navigationItem = history[containerIndex + 1]
        if (navigationItem && navigationItem.transition) {
            return navigationItem.transition.backfaceVisible
        }
        return true
    } else {
        // future
        const navigationItem = history[containerIndex]
        if (navigationItem && navigationItem.transition) {
            return navigationItem.transition.backfaceVisible
        }
        return true
    }
}

function initialPropsForOverlay(containerIndex: number, stackState: StackState): Partial<FrameProps> | undefined {
    const navigationItem = stackState.history[containerIndex]

    if (navigationItem) return navigationItem.transition.enter
}

function getBackfaceVisibleForScreen(screenIndex: number, stackState: StackState): boolean | undefined {
    const { current, previous, history } = stackState

    // Entering going backwards || exiting going forward
    if ((screenIndex === previous && current > previous) || (screenIndex === current && current < previous)) {
        return history[screenIndex + 1]?.transition?.backfaceVisible
    }

    // Entering going forward, exiting going backwards, or all other screens.
    return history[screenIndex]?.transition?.backfaceVisible
}

function transitionPropsForOverlay(overlayIndex: number, stackState: StackState): Partial<FrameProps> | undefined {
    const { current, history } = stackState

    if (overlayIndex === current) {
        // current
        return
    } else if (overlayIndex < current) {
        // old
        const navigationItem = history[overlayIndex + 1]
        if (navigationItem && navigationItem.transition) {
            return navigationItem.transition.exit
        }
    } else {
        // future
        const navigationItem = history[overlayIndex]
        if (navigationItem && navigationItem.transition) {
            return navigationItem.transition.enter
        }
    }
}

function animationPropsForContainer(containerIndex: number, stackState: StackState): Transition {
    const { current, previous, history } = stackState
    const containerCurrent = previous > current ? previous : current
    if (containerIndex < containerCurrent) {
        // old
        const navigationItem = history[containerIndex + 1]
        if (navigationItem && navigationItem.transition.animation) {
            return navigationItem.transition.animation
        }
    } else if (containerIndex !== containerCurrent) {
        // future
        const navigationItem = history[containerIndex]
        if (navigationItem && navigationItem.transition.animation) {
            return navigationItem.transition.animation
        }
    } else {
        // current
        const navigationItem = history[containerIndex]
        if (navigationItem.transition.animation) {
            return navigationItem.transition.animation
        }
    }

    return animationDefault
}

function isInstantContainerTransition(
    containerIndex: number,
    stackState: StackState,
    overCurrentContext?: boolean
): boolean {
    const { current, previous, history } = stackState
    if (overCurrentContext && history.length > 1) return true
    if (containerIndex !== previous && containerIndex !== current) return true
    if (current === previous) return true
    return false
}

function containerIsVisible(containerIndex: number, stackState: StackState) {
    const { current, previous } = stackState
    if (containerIndex > current && containerIndex > previous) return false
    if (containerIndex === current) return true

    return false
}

function containerContent(item: { component: React.ReactNode; transition: NavigationTransition }) {
    return React.Children.map(item.component, (child: React.ReactElement<{ [key: string]: any } | undefined>) => {
        if (!isReactChild(child) || !isReactElement(child) || !child.props) {
            return child
        }

        const props: Partial<{ width: number | string; height: number | string }> = {}

        const position = item?.transition?.position
        const shouldStretchWidth = !position || (position.left !== undefined && position.right !== undefined)
        const shouldStretchHeight = !position || (position.top !== undefined && position.bottom !== undefined)

        const canStretchWidth = "width" in child.props
        const canStretchHeight = "height" in child.props
        if (shouldStretchWidth && canStretchWidth) {
            props.width = "100%"
        }
        if (shouldStretchHeight && canStretchHeight) {
            props.height = "100%"
        }

        return React.cloneElement(child, props)
    })
}

function backdropTapAction(transition: NavigationTransition, goBackAction: () => void) {
    if (transition.goBackOnTapOutside !== false) return goBackAction
}
