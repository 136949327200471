import { cached } from "../cached"

let measureNode: null | HTMLDivElement = null
let innerMeasureNode: null | HTMLDivElement = null

/**
 * Measure the size for a given html string. This is an expensive operation.
 * @internal
 */
function getHTMLSize(html: string, width: null | number = null): { width: number; height: number } {
    // Create the node to measure offscreen
    if (!measureNode || !innerMeasureNode) {
        measureNode = document.createElement("div")
        measureNode.style.position = "fixed"
        measureNode.style.left = "400px"
        measureNode.style.bottom = "40px"
        measureNode.style.visibility = "hidden"
        measureNode.style.pointerEvents = "none"

        // Add a div that will tightly wrap around the content, which we’ll use for actual measuring
        innerMeasureNode = document.createElement("div")
        innerMeasureNode.style.display = "inline-block"
        measureNode.appendChild(innerMeasureNode)

        document.body.appendChild(measureNode)
    }

    // Set the width and content to the node. While `"auto"` might seems more
    // correct for the case where the width is unset, that will restrict the
    // width of the window. As a workaround we’re using a huge ‘magic’ value.
    measureNode.style.width = width ? `${width}px` : "10000px"
    measureNode.style.tabSize = "4"
    innerMeasureNode.innerHTML = html

    // Assume the HTML always has an outer wrapper, which is what we really want to measure.
    const rect = innerMeasureNode.getBoundingClientRect()
    const size = {
        // The rounding here mostly helps Safari, where the letter `l` at 16px might just fall of due to some internal rounding.
        width: Math.ceil(rect.width) + 1,
        height: Math.ceil(rect.height),
    }

    return size
}

/**
 * Get the size for this rendered html element, cached
 * @internal
 */
export const getHTMLSizeCached: (html: string, width?: number) => { width: number; height: number } = cached(
    "html-size",
    getHTMLSize
)
