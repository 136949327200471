import { withPosition } from "document/models/CanvasTree/traits/Position"
import { withSize } from "document/models/CanvasTree/traits/Size"
import type { DrawableNode, PathNode } from "document/models/CanvasTree"
import type { List } from "immutable"
import { PathSegment } from "framer"

export function scaleMutableNodes(nodes: DrawableNode[], scaleX: number, scaleY: number) {
    // Bail if there's no scaling to be done:
    if (scaleX === 1 && scaleX === scaleY) {
        return
    }

    nodes.forEach(node => {
        if (withPosition(node)) {
            node.x = node.x * scaleX
            node.y = node.y * scaleY
        }
        if (withSize(node)) {
            node.width = node.width * scaleX
            node.height = node.height * scaleY
        }
        const pathNode = node as PathNode
        if (pathNode && pathNode.pathSegments) {
            pathNode.pathSegments = <List<PathSegment>>pathNode.pathSegments.map(segment => {
                return new PathSegment({
                    x: segment!.x * scaleX,
                    y: segment!.y * scaleY,
                    handleMirroring: segment!.handleMirroring,
                    handleOutX: segment!.handleOutX * scaleX,
                    handleOutY: segment!.handleOutY * scaleY,
                    handleInX: segment!.handleInX * scaleX,
                    handleInY: segment!.handleInY * scaleY,
                    // We can forgo "radius" for this a "Framer only" property that will never
                    // be set on import.
                })
            })

            // This section scales the numerical stroke values. It only makes sense to do so when
            // scaling is monotonous.
            if (scaleX === scaleY) {
                const scale = scaleX

                if (pathNode.strokeDashOffset !== undefined) {
                    pathNode.strokeDashOffset = pathNode.strokeDashOffset * scale
                }

                if (pathNode.strokeWidth !== undefined) {
                    pathNode.strokeWidth = pathNode.strokeWidth * scale
                }

                if (pathNode.strokeMiterLimit !== undefined) {
                    pathNode.strokeMiterLimit = pathNode.strokeMiterLimit * scale
                }
            }
        }
    })
}
