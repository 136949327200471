import * as React from "react"
import type { CSSProperties } from "react"

// This is the "plain" component part of the Device Component.

export interface DeviceOptions {
    deviceWidth: number
    deviceHeight: number
    noPadding?: boolean
    appearance:
        | {
              type: "clay"
              bezelRadius?: string
              bezelColor?: string
              bezelShadeColor?: string
          }
        | {
              type: "realistic"
              imageUrl: string
              imageWidth: number
              imageHeight: number
              rotateImage?: boolean
          }
        | {
              type: "external-clay"
              imageUrl: string
              imageWidth: number
              imageHeight: number
          }
    screenWidth: number
    screenHeight: number
    screenOffsetTop?: number
    screenOffsetLeft?: number
    screenRadius?: string
    screenMaskImage?: string
    screenColor?: string
    shadow?: string
    hand?: {
        imageUrl: string
        offsetLeft?: number
        offsetRight?: number
        offsetBottom?: number
    }
    background?: string
    theme?: "dark" | "light"

    // FIXME: This doesn't really belong here, as the colorId is already baked
    // into the realistic imageUrl. We should consider refactoring the code that
    // uses this property, and then removing it.
    colorId?: string
}

interface ResizeObserver {
    observe(target: Element): void
    unobserve(target: Element): void
    disconnect(): void
}

interface ScaleData {
    scale: number
    screenScalePixelFix: number
    scaledComponentWidth: number
    scaledComponentHeight: number
    scaledDeviceWidth: number
    scaledDeviceHeight: number
}

export interface DeviceProps {
    overrideTheme?: "dark" | "light"
    children?: React.ReactNode
    deviceOptions?: DeviceOptions
    /**
     * - setting width + height will scale the component to that size
     * - "dynamic" will scale the component to the size of its container
     * - leaving this undefined will render the component at its intrinsic size
     */
    scaleTo?: { width: number; height: number } | "dynamic"
    onScaleChange?: (_: ScaleData) => void
    ResizeObserver?: {
        prototype: ResizeObserver
        new (callback: () => void): ResizeObserver
    }
}

function getScaleData(deviceOptions: DeviceOptions, containerSize: { width: number; height: number }): ScaleData {
    const { componentWidth, componentHeight } = getComponentSize(deviceOptions)
    const scaleX = containerSize.width / componentWidth
    const scaleY = containerSize.height / componentHeight
    const scale = Math.min(scaleX, scaleY, 1)

    // temp fix for leaking pixel, see more https://github.com/framer/company/issues/20377
    let screenScalePixelFix = 1
    if (scale < 1) {
        const actualScreenWidth = deviceOptions.screenWidth * scale
        const desiredScreenWidth = actualScreenWidth + 1
        const screenScaleX = desiredScreenWidth / actualScreenWidth
        const actualScreenHeight = deviceOptions.screenHeight * scale
        const desiredScreenHeight = actualScreenHeight + 1
        const screenScaleY = desiredScreenHeight / actualScreenHeight
        const screenScale = Math.max(screenScaleX, screenScaleY)
        screenScalePixelFix = screenScale
    }

    return {
        scale,
        screenScalePixelFix,
        scaledComponentWidth: componentWidth * scale,
        scaledComponentHeight: componentHeight * scale,
        scaledDeviceWidth: deviceOptions.deviceWidth * scale,
        scaledDeviceHeight: deviceOptions.deviceHeight * scale,
    }
}
export function getColorsFromTheme(theme?: "dark" | "light") {
    if (!theme) return {}
    const isDarkTheme = theme === "dark"
    return {
        shadowColor: isDarkTheme ? "rgba(0, 0, 0, 0.55)" : "rgba(0, 0, 0, 0.15)",
        bezelColor: isDarkTheme ? "#222" : "#fff",
        bezelShadeColor: isDarkTheme ? "#000" : "rgba(0, 0, 0, 0.2)",
        screenColor: isDarkTheme ? "#333" : "#eee",
    }
}
export function Device({
    children,
    deviceOptions,
    scaleTo,
    onScaleChange,
    ResizeObserver,
    overrideTheme,
}: DeviceProps) {
    const noDeviceStyle: DeviceStyle = { containerStyle: {}, deviceStyle: {}, screenStyle: {} }
    const { containerStyle, handStyle, deviceStyle, deviceImageStyle, screenStyle } = deviceOptions
        ? getDeviceStyle(scaleTo, deviceOptions, overrideTheme)
        : noDeviceStyle

    // Static scaling

    if (deviceOptions && scaleTo && scaleTo !== "dynamic") {
        const scaleData = getScaleData(deviceOptions, scaleTo)
        deviceStyle.transform = `scale(${scaleData.scale})`
    }

    // Dynamic scaling

    const containerRef = React.useRef<HTMLDivElement | null>(null)
    const deviceRef = React.useRef<HTMLDivElement | null>(null)
    const screenRef = React.useRef<HTMLDivElement | null>(null)

    React.useLayoutEffect(() => {
        if (!deviceOptions || !scaleTo || scaleTo !== "dynamic" || !containerRef.current || !deviceRef.current) return
        if (containerRef.current.offsetWidth === 0 || containerRef.current.offsetHeight === 0) return

        const scaleData = getScaleData(deviceOptions, {
            width: containerRef.current.offsetWidth,
            height: containerRef.current.offsetHeight,
        })
        // onScaleChange will happen in the effect below
        deviceRef.current.style.transform = `scale(${scaleData.scale})`
        if (screenRef.current) {
            screenRef.current.style.transform = `scale(${scaleData.screenScalePixelFix})`
        }
    }, [deviceOptions, scaleTo])

    React.useEffect(() => {
        if (!deviceOptions || !scaleTo || scaleTo !== "dynamic" || !ResizeObserver || !containerRef.current) return

        const resizeObserver = new ResizeObserver(() => {
            if (!containerRef.current || !deviceRef.current) return
            if (containerRef.current.offsetWidth === 0 || containerRef.current.offsetHeight === 0) return

            const scaleData = getScaleData(deviceOptions, {
                width: containerRef.current.offsetWidth,
                height: containerRef.current.offsetHeight,
            })
            onScaleChange?.(scaleData)
            deviceRef.current.style.transform = `scale(${scaleData.scale})`

            if (screenRef.current) {
                screenRef.current.style.transform = `scale(${scaleData.screenScalePixelFix})`
            }
        })
        resizeObserver.observe(containerRef.current)

        return () => resizeObserver.disconnect()
    }, [deviceOptions, scaleTo, onScaleChange, ResizeObserver])

    return (
        <div style={{ ...containerStyle, pointerEvents: "none" }} ref={containerRef}>
            <div style={{ ...deviceStyle, pointerEvents: "none" }} ref={deviceRef}>
                {handStyle && <div style={handStyle} />}
                {deviceOptions?.appearance.type === "external-clay" && deviceImageStyle && (
                    <div style={deviceImageStyle} />
                )}
                <div style={{ ...screenStyle, pointerEvents: "auto" }} ref={screenRef}>
                    {children}
                </div>
                {deviceOptions?.appearance.type === "realistic" && deviceImageStyle && <div style={deviceImageStyle} />}
            </div>
        </div>
    )
}

const DEVICE_PADDING = 45

const HAND_IMG_WIDTH = 2400
const HAND_IMG_HEIGHT = 3740
const HAND_IMG_GAP_WIDTH = 859
const HAND_IMG_GAP_LEFT = 772
// 992 is the actual measurement, but we subtract some buffer, determined empirically
const HAND_IMG_GAP_BOTTOM = 992 - 5

export function getComponentSize({
    deviceWidth,
    deviceHeight,
    noPadding,
}: Pick<DeviceOptions, "deviceWidth" | "deviceHeight" | "noPadding">): {
    componentWidth: number
    componentHeight: number
} {
    const padding = noPadding ? 0 : DEVICE_PADDING * 2
    return {
        componentWidth: deviceWidth + padding,
        componentHeight: deviceHeight + padding,
    }
}

interface DeviceStyle {
    containerStyle: CSSProperties
    handStyle?: CSSProperties
    deviceStyle: CSSProperties
    deviceImageStyle?: CSSProperties
    screenStyle: CSSProperties
}

function getDeviceStyle(
    scaleTo: DeviceProps["scaleTo"],
    deviceOptions: DeviceOptions,
    overrideTheme: DeviceProps["overrideTheme"]
): DeviceStyle {
    const { componentWidth, componentHeight } = getComponentSize(deviceOptions)
    const overriddenColors = getColorsFromTheme(overrideTheme)
    const {
        deviceWidth,
        deviceHeight,
        appearance,
        screenWidth,
        screenHeight,
        screenOffsetTop,
        screenOffsetLeft,
        screenRadius,
        screenMaskImage,
        screenColor,
        shadow,
        background,
        hand,
    } = deviceOptions

    const boxShadows = []
    // At this time, we only support shadow for clay devices.
    if (appearance.type === "clay" && shadow) {
        boxShadows.push(shadow)
    }

    let bezelStyle: CSSProperties | undefined = undefined
    if (appearance.type === "clay") {
        bezelStyle = {
            borderRadius: appearance.bezelRadius,
            backgroundColor: overriddenColors.bezelColor || appearance.bezelColor,
        }
        if (overriddenColors.bezelShadeColor || appearance.bezelShadeColor) {
            boxShadows.push(`inset 0 0 15px ${overriddenColors.bezelShadeColor || appearance.bezelShadeColor}`)
        }
    }

    const handOffsetLeft = hand?.offsetLeft ?? 0
    const handOffsetRight = hand?.offsetRight ?? 0
    const handOffsetBottom = hand?.offsetBottom ?? 0
    const handScale = (deviceWidth - handOffsetLeft - handOffsetRight) / HAND_IMG_GAP_WIDTH

    return {
        containerStyle: {
            width: scaleTo ? "100%" : componentWidth,
            height: scaleTo ? "100%" : componentHeight,
            flex: "1 1 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            background,
        },
        handStyle: hand && {
            width: HAND_IMG_WIDTH * handScale,
            height: HAND_IMG_HEIGHT * handScale,
            position: "absolute",
            pointerEvents: "none",
            backgroundImage: `url("${hand.imageUrl}")`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            left: -HAND_IMG_GAP_LEFT * handScale + handOffsetLeft,
            bottom: -HAND_IMG_GAP_BOTTOM * handScale + handOffsetBottom,
        },
        deviceStyle: {
            width: deviceWidth,
            height: deviceHeight,
            flexShrink: 0,
            position: "absolute",
            boxShadow: boxShadows.join(","),
            ...bezelStyle,
        },
        deviceImageStyle:
            appearance.type === "realistic" || appearance.type === "external-clay"
                ? {
                      width: appearance.imageWidth,
                      height: appearance.imageHeight,
                      position: "absolute",
                      pointerEvents: "none",
                      overflow: "hidden",
                      backgroundImage: `url("${appearance.imageUrl}")`,
                      backgroundPosition: "top left",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: `${appearance.imageWidth}px ${appearance.imageHeight}px`,
                      // Rotate 90 degrees counter-clockwise around (0,0), then move the
                      // result down into the viewport (rightmost transform is applied first).
                      transformOrigin: "top left",
                      transform:
                          appearance.type === "realistic" && appearance.rotateImage
                              ? `translateY(${appearance.imageWidth}px) rotate(-90deg)`
                              : undefined,
                  }
                : undefined,
        screenStyle: {
            width: screenWidth,
            height: screenHeight,
            position: "absolute",
            top: screenOffsetTop,
            left: screenOffsetLeft,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            borderRadius: screenRadius,
            backgroundColor: overriddenColors.screenColor || screenColor,
            ...(screenMaskImage && {
                maskImage: screenMaskImage,
                WebkitMaskImage: screenMaskImage,
                maskSize: "contain",
                WebkitMaskSize: "contain",
            }),
        },
    }
}
