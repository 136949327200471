import { css } from "linaria"
import { dimensions } from "../tokens"

export const searchBarWrapper = css`
    padding-right: ${dimensions.inputPaddingHorizontal - 1}px;
`

export const searchInput = css`
    padding-right: 4px;
`
