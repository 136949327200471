import type { CanvasTree } from "../../CanvasTree"
import { VectorNode, isVectorNode } from "../../nodes/TreeNode"
import ShapeContainerNode, { isShapeContainerNode } from "../../nodes/ShapeContainerNode"
import type { AnyNodeUpdate } from "document/models/CanvasTree"
import { collectBoundingBox, isValidBoundingBox, getRectContainingBoundingBox } from "utils/collectBoundingBox"

export function wrapInShapeContainer(node: VectorNode, tree: CanvasTree) {
    const parent = tree.getParent(node.id)
    if (parent && (isVectorNode(parent) || isShapeContainerNode(parent))) {
        return
    }
    const boundingBox = { minX: Infinity, maxX: -Infinity, minY: Infinity, maxY: -Infinity }
    collectBoundingBox({ node, box: boundingBox, ignoreInvisible: false })
    if (!isValidBoundingBox(boundingBox)) {
        // eslint-disable-next-line no-console
        console.error("Error converting document: could not wrap shape in container because of invalid bounding box")
        return null
    }

    const rect = getRectContainingBoundingBox(boundingBox)

    const insertIndex = tree.getIndex(node)
    const properties: AnyNodeUpdate = {
        top: rect.y,
        left: rect.x,
        width: rect.width,
        height: rect.height,
        fillEnabled: false,
        sizeToFit: true,
    }
    const container = tree.create(ShapeContainerNode, node.parentid, properties)
    tree.move(container.id, node.parentid, insertIndex)
    tree.move(node.id, container.id)
    node.set({ x: node.x - boundingBox.minX, y: node.y - boundingBox.minY })
}
