export class OutdatedValue extends Error {}

/**
 * This queue will reject any queued promises when a newer promise resolves.
 */
export class LastValuePromiseQueue {
    private nextSeq = 0
    private lastValueSeq = -1

    /**
     * Adds a promise to the queue, and returns a promise that's guaranteed
     * to resolve only if no newer promise in the queue has already resolved.
     */
    add<T>(promise: Promise<T>): Promise<T> {
        return new Promise((resolve, reject) => {
            const seq = this.nextSeq++
            promise
                .then(value => {
                    if (seq < this.lastValueSeq) {
                        reject(new OutdatedValue("A newer value has already resolved"))
                    }
                    this.lastValueSeq = seq
                    resolve(value)
                })
                .catch(error => reject(error))
        })
    }
}
