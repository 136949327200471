import { css } from "linaria"
import { dimensions, ZIndex } from "../tokens"
import { colors } from "../tokens/colors"
import { shadows } from "../tokens/shadows"

export const modalBackdrop = css`
    position: fixed;
    z-index: ${ZIndex.modal};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const hasBackdrop = css`
    background-color: ${colors.modalBackdrop};
`

export const modalWithoutPointerEvents = css`
    pointer-events: none;
`

export const modal = css`
    overflow: hidden;
    width: 300px;
    height: auto;
    padding: ${dimensions.panelPadding}px;
    animation: enter 200ms cubic-bezier(0.2, 0, 0, 1) both;
    background: ${colors.modalBackground};
    border-radius: 10px;
    box-shadow: ${shadows.modal};
    outline: none;

    @keyframes enter {
        0% {
            opacity: 0;
            transform: translateY(16px) scale(0.9);
        }
        100% {
            opacity: 1;
            transform: none;
        }
    }
`
