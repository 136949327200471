import { useLayoutEffect } from "react"

export class TimeBudget {
    private _current = Infinity
    private deadline = Infinity
    private limits = new Map<string, number>()

    constructor(private readonly name: string) {
        this.limits.set("default", Infinity)
    }

    private updateCurrent() {
        const values = this.limits.values()
        this._current = Math.max(...values)
    }

    get current() {
        return this._current
    }

    setDefault(ms: number) {
        this.addScope("default", ms)
    }

    addScope(name: string, ms: number) {
        const oldLimit = this._current
        this.limits.set(name, ms)
        // Only reset the deadline if the new limit is higher than the old limit.
        if (oldLimit < ms || oldLimit === Infinity) {
            this.updateCurrent()
            this.resetDeadline()
        }
    }

    removeScope(name: string) {
        this.limits.delete(name)
        this.updateCurrent()
    }

    extendDeadlineBy(ms: number) {
        this.deadline += ms
    }

    resetDeadline() {
        this.deadline = Date.now() + this._current
    }

    checkDeadline() {
        const now = Date.now()

        if (now > this.deadline) {
            const message = `${this.name} exceeded time limit of ${this._current}ms by ${now - this.deadline}ms.`
            // eslint-disable-next-line no-console
            console.warn(message)
            // FIXME: This should throw an error to prevent user code from slowing down
            //        the editor, but we have a measurement bug causing a lot of errors.
            //        Issue: https://github.com/framer/company/issues/20879
            // throw new Error(message)
        }
    }
}

const frameBudget = new TimeBudget("Frame")
const componentBudget = new TimeBudget("Component")

export const executionTimeBudgets = {
    frame: frameBudget,
    component: componentBudget,
}

// We only do the more elaborate checks every so often.
const COUNTER_START_VALUE = 200

// Quick check counter:
let budgetCounter = COUNTER_START_VALUE
// Reset counters and deadlines on every frame.
let shouldResetFrameBudget = true
// Only use the frame budget when we are rendering the code component.
let isFramerRender = false

// Called when we load each file to increase the global time limit slightly.
function increaseFrameBudget() {
    frameBudget.extendDeadlineBy(frameBudget.current / 2)
}

// Called in checkBudget to reset the frame budget on the next frame.
function requestFrameBudgetReset() {
    if (!shouldResetFrameBudget) return

    shouldResetFrameBudget = false
    frameBudget.resetDeadline()

    // requestAnimationFrame doesn't run if the window is in the background.
    setTimeout(() => {
        shouldResetFrameBudget = true
    }, 0)
}

// Called whenever we render or re-render a component to reset the counters and deadlines.
function resetComponentBudget() {
    requestFrameBudgetReset()

    budgetCounter = COUNTER_START_VALUE
    componentBudget.resetDeadline()
}

// All component code (but not their libraries) will call this per function or loop entry.
// We only do an expensive check once in a while and otherwise just skip the calls.
function checkBudget() {
    if (--budgetCounter < 0) checkBudgetFull()
}

function checkBudgetFull() {
    requestFrameBudgetReset()
    budgetCounter = COUNTER_START_VALUE

    // Only check the component budget if framer initiated the render.
    if (isFramerRender) {
        componentBudget.checkDeadline()
    }

    frameBudget.checkDeadline()
}

export function initializeExecutionTimeBudgets(frameLimit: number = 5000, componentLimit: number = 5000) {
    frameBudget.setDefault(frameLimit)
    componentBudget.setDefault(componentLimit)

    installExecutionTimeBudgets()
}

function installExecutionTimeBudgets() {
    // Install the checks on the window so we can access them from the instrumenting code that is inserted by the build service.
    window["__checkBudget__"] = checkBudget
    window["__checkComponentBudget__"] = resetComponentBudget
    window["__checkFileBudget__"] = increaseFrameBudget
}

export function useExecutionTimeBudgetsWhileRendering() {
    installExecutionTimeBudgets()

    isFramerRender = true
    useLayoutEffect(() => {
        isFramerRender = false
    })
}
