import * as React from "react"
import { ElectronThemeListener, channelToParentFrame } from "@framerjs/framer-services"
import { FrescoSettings } from "@framerjs/fresco"
import { createContext, useEffect, useLayoutEffect, useMemo } from "react"
import { useLocalStorage } from "../lib/useLocalStorage"
import { useMediaQuery } from "../lib/useMediaQuery"

export interface DarkModeContextValue {
    isDarkMode: boolean
    isOverridingSystem: boolean
    setDarkMode: (enabled: boolean) => void
    setOverrideSystem: (override: boolean) => void
}

export const DarkModeContext = createContext<DarkModeContextValue>({
    isDarkMode: false,
    isOverridingSystem: false,
    setDarkMode: () => {},
    setOverrideSystem: () => {},
})

interface Props {
    children: React.ReactNode
}

const enum LocalStorageKey {
    DarkMode = "dark-mode",
    OverrideSystem = "dark-mode-override-system",
    DeprecatedAppearance = "appearance",
    DepreactedOverrideSystemAppearance = "appearanceOverrideSystem",
}

async function updateElectronTheme(theme: ElectronThemeListener.Theme) {
    try {
        return await ElectronThemeListener.on(channelToParentFrame).expect().onThemeChange(theme)
    } catch {
        // Service errors are harmless because there's not an Electron client present.
    }
}

export function DarkModeProvider({ children }: Props) {
    const [userDarkMode, setUserDarkMode] = useLocalStorage(
        LocalStorageKey.DarkMode,
        localStorage.getItem(LocalStorageKey.DeprecatedAppearance) === "dark"
    )

    const [overrideSystem, setOverrideSystem] = useLocalStorage(
        LocalStorageKey.OverrideSystem,
        localStorage.getItem(LocalStorageKey.DepreactedOverrideSystemAppearance) === "true"
    )

    const systemDarkMode = useMediaQuery("(prefers-color-scheme: dark)")

    const isDarkMode = overrideSystem ? userDarkMode : systemDarkMode

    useLayoutEffect(() => {
        document.body.classList.toggle("dark", isDarkMode)

        const frames = window.frames
        for (let index = 0; index < frames.length; index++) {
            frames[index].postMessage(
                {
                    appearance: isDarkMode ? "dark" : "light",
                },
                "*"
            )
        }
    }, [isDarkMode])

    useEffect(() => {
        void updateElectronTheme({ isDarkMode })
    }, [isDarkMode])

    const contextValue = useMemo(() => {
        function setDarkMode(isEnabled: boolean) {
            setUserDarkMode(isEnabled)
            setOverrideSystem(true)
        }

        return {
            isDarkMode,
            isOverridingSystem: overrideSystem,
            setDarkMode,
            setOverrideSystem,
        }
    }, [isDarkMode, setUserDarkMode, overrideSystem, setOverrideSystem])

    return (
        <DarkModeContext.Provider value={contextValue}>
            <FrescoSettings>{children}</FrescoSettings>
        </DarkModeContext.Provider>
    )
}
