import { css } from "linaria"
import { dimensions, fonts } from "../tokens"

export const input = css`
    height: ${dimensions.inputHeight}px;
    padding: 0 ${dimensions.inputPaddingHorizontal}px 1px;
`

export const inputLarge = css`
    height: ${dimensions.inputHeightLarge}px;
    padding: 0 ${dimensions.inputPaddingHorizontalLarge}px 1px;
    font-weight: ${fonts.weight.baseActive};
`
