import type { List } from "immutable"
import { Point, Rect, WithShape, WithPaths, PathSegment, WithPath } from "framer"
import type { CanvasTree } from "../CanvasTree"
import type { CanvasNode } from "../nodes/CanvasNode"
import type { DrawableNode, VectorNode } from "../nodes/TreeNode"
import { isBooleanShapeNode } from "../nodes/shapes/BooleanShapeNode"
import { isShapeGroupNode } from "../nodes/ShapeGroupNode"
import OvalShapeNode from "../nodes/shapes/OvalShapeNode"
import StarShapeNode from "../nodes/shapes/StarShapeNode"
import PolygonShapeNode from "../nodes/shapes/PolygonShapeNode"
import type { NodeUpdate } from "document/models/CanvasTree"
import type PathNode from "../nodes/shapes/PathNode"
import RectangleShapeNode from "../nodes/shapes/RectangleShapeNode"
import Matrix from "document/models/Matrix"
import Path from "document/models/Path"
import { getChildren } from "document/models/CanvasTree/traits/Children"
import { PathBooleanType } from "document/models/CanvasTree/traits/PathBoolean"
import { WithRotation, withRotation } from "document/models/CanvasTree/traits/Rotation"
import { BasicShape } from "document/models/CanvasTree/traits/utils/basicShape"
import { CompoundShape } from "document/models/CanvasTree/traits/utils/compoundShape"
import { withPath } from "../traits/Path"
import { isVariableReference } from "../traits/VariableReference"

interface OutUpdates {
    [id: string]: NodeUpdate
}

export function closestCurveDistance(node: DrawableNode & WithShape, localMouse: Point): number {
    const bezierCurves = Path.toBezierJS(node.calculatedPaths())
    let shortestDistance = Infinity

    for (let i = 0, il = bezierCurves.length; i < il; i++) {
        const curve = bezierCurves[i]
        const projection = curve.project(localMouse)
        const distance = projection.d

        if (distance < shortestDistance) {
            shortestDistance = distance
        }
    }

    return shortestDistance
}

export function reverseSegment(segment: PathSegment) {
    return segment.merge({
        handleOutX: segment.handleInX,
        handleOutY: segment.handleInY,
        handleInX: segment.handleOutX,
        handleInY: segment.handleOutY,
    }) as PathSegment
}

export function convertPoint(point: Point, layerRect: Rect, rotation: number) {
    const matrix = new DOMMatrix().translateSelf(layerRect.x, layerRect.y).rotateSelf(0, 0, rotation)

    const transformMatrix = new DOMMatrix()
        .translateSelf(0.5 * layerRect.width, 0.5 * layerRect.height)
        .multiplySelf(matrix)
        .translateSelf(-0.5 * layerRect.width, -0.5 * layerRect.height)

    return Matrix.convertPoint(transformMatrix, point)
}
// NOTE: these path to parent conversions functions are incomplete on purpose
export function convertPathToParent(
    node: DrawableNode & Rect,
    withPaths: WithPaths,
    overrides?: Partial<Rect & WithRotation>
) {
    let matrix: DOMMatrix
    if (overrides) {
        const frameAndRotation = { ...node, ...overrides }
        // Shapes shouldn't support variables
        const rotation = isVariableReference(frameAndRotation.rotation) ? 0 : frameAndRotation.rotation
        matrix = new DOMMatrix().translateSelf(frameAndRotation.x, frameAndRotation.y).rotateSelf(0, 0, rotation)

        matrix = new DOMMatrix()
            .translateSelf(0.5 * frameAndRotation.width, 0.5 * frameAndRotation.height)
            .multiplySelf(matrix)
            .translateSelf(-0.5 * frameAndRotation.width, -0.5 * frameAndRotation.height)
    } else {
        matrix = node.transformMatrix(null, node)
    }

    return withPaths.map(({ pathSegments, pathClosed }) => {
        return {
            pathSegments: pathSegments.map((segment: PathSegment) => {
                return convertSegmentToParent(node, segment, overrides, matrix)
            }) as List<PathSegment>,
            pathClosed,
        }
    })
}

export function convertSegmentToParent(
    node: DrawableNode & Rect,
    segment: PathSegment,
    overrides?: Partial<Rect & WithRotation>,
    reuseMatrix?: DOMMatrixReadOnly
) {
    let matrix: DOMMatrixReadOnly

    if (reuseMatrix) {
        matrix = reuseMatrix
    } else if (overrides) {
        const frameAndRotation = { ...node, ...overrides }
        const rotation = isVariableReference(frameAndRotation.rotation) ? 0 : frameAndRotation.rotation

        matrix = new DOMMatrix().translateSelf(frameAndRotation.x, frameAndRotation.y).rotateSelf(0, 0, rotation)

        matrix = new DOMMatrix()
            .translateSelf(0.5 * frameAndRotation.width, 0.5 * frameAndRotation.height)
            .multiplySelf(matrix)
            .translateSelf(-0.5 * frameAndRotation.width, -0.5 * frameAndRotation.height)
    } else {
        matrix = node.transformMatrix(null, node)
    }

    const anchor = Matrix.convertPoint(matrix, segment)
    const handleIn = Matrix.convertPoint(matrix, PathSegment.calculatedHandleIn(segment))
    const handleOut = Matrix.convertPoint(matrix, PathSegment.calculatedHandleOut(segment))
    const localHandleIn = Point.subtract(handleIn, anchor)
    const localHandleOut = Point.subtract(handleOut, anchor)

    return segment.merge({
        ...anchor,
        handleOutX: localHandleOut.x,
        handleOutY: localHandleOut.y,
        handleInX: localHandleIn.x,
        handleInY: localHandleIn.y,
    }) as PathSegment
}

export function convertPointToParent(node: DrawableNode & Rect, point: Point, newFrame?: Rect) {
    return Matrix.convertPoint(node.transformMatrix(null, newFrame ? newFrame : node), point)
}

export function validBoundingBoxUpdatesForFrameNode(tree: CanvasTree, node: VectorNode) {
    const updatesOut: OutUpdates = {}
    collectSubtreeShapeTransformUpdates(tree, node, {}, updatesOut)
    return updatesOut
}

export function collectSubtreeShapeTransformUpdates(
    tree: CanvasTree,
    node: VectorNode,
    updates: { [id: string]: Partial<Rect & WithRotation> },
    finalUpdates: OutUpdates
): WithPaths {
    let calculatedPath: WithPaths

    const update = updates[node.id]

    // This is here so we can get more information on
    // https://github.com/framer/company/issues/20712
    if (node && typeof node.calculatedPaths !== "function") {
        // eslint-disable-next-line no-console
        console.error(
            node.id,
            node.__class,
            node.getProps(),
            node.calculatedPaths,
            typeof node,
            typeof node.calculatedPaths
        )
    }

    if (update) {
        finalUpdates[node.id] = { x: node.x, y: node.y, ...update }
        calculatedPath = node.calculatedPaths()
    } else if (!(isShapeGroupNode(node) || isBooleanShapeNode(node))) {
        finalUpdates[node.id] = { x: node.x, y: node.y }
        calculatedPath = node.calculatedPaths()
    } else {
        const childPaths = getChildren(node).map((child: VectorNode) => {
            return collectSubtreePathSegmentUpdates(tree, child, updates, finalUpdates)
        })

        let pathBoolean: PathBooleanType
        if (isBooleanShapeNode(node)) {
            pathBoolean = node.pathBoolean
        } else {
            pathBoolean = PathBooleanType.Join
        }

        calculatedPath = CompoundShape.executePathBoolean(childPaths, pathBoolean)
        const [newFrame, offset] = updatedRectForBoundingBox(
            node,
            withRotation(node) && !isVariableReference(node.rotation) ? node.rotation : 0,
            calculatedPath
        )

        finalUpdates[node.id] = newFrame

        const dx = offset.x
        const dy = offset.y
        offsetAndFilterChildren(node.children, finalUpdates, dx, dy)
    }

    return convertPathToParent(node as PathNode, calculatedPath, update)
}

function collectSubtreePathSegmentUpdates(
    tree: CanvasTree,
    node: DrawableNode & WithShape & Rect,
    updates: { [id: string]: Partial<WithPath & Rect> },
    finalUpdates: OutUpdates
): WithPaths {
    let calculatedPath: WithPaths

    const update = updates[node.id]

    if (!(isShapeGroupNode(node) || isBooleanShapeNode(node))) {
        if (update && withPath(node)) {
            const nodeUpdate = node.updateForPath(update)
            finalUpdates[node.id] = nodeUpdate
            calculatedPath = [{ pathSegments: node.pathSegments, pathClosed: node.pathClosed, ...update }] as any
        } else if (
            update &&
            (node instanceof RectangleShapeNode ||
                node instanceof OvalShapeNode ||
                node instanceof StarShapeNode ||
                node instanceof PolygonShapeNode)
        ) {
            finalUpdates[node.id] = update
            const shapePath = BasicShape.calculatedPath(node, update as Rect)[0]
            return [Path.offset(shapePath, update as Rect)]
        } else {
            finalUpdates[node.id] = { x: node.x, y: node.y }
            calculatedPath = node.calculatedPaths()
        }
    } else {
        const childPaths = getChildren(node).map((child: VectorNode) => {
            return collectSubtreePathSegmentUpdates(tree, child, updates, finalUpdates)
        })

        let pathBoolean: PathBooleanType
        if (isBooleanShapeNode(node)) {
            pathBoolean = node.pathBoolean
        } else {
            pathBoolean = PathBooleanType.Join
        }

        calculatedPath = CompoundShape.executePathBoolean(childPaths, pathBoolean)
        const [newFrame, offset] = updatedRectForBoundingBox(
            node,
            withRotation(node) && !isVariableReference(node.rotation) ? node.rotation : 0,
            calculatedPath
        )

        finalUpdates[node.id] = newFrame

        // Offset children
        const dx = offset.x
        const dy = offset.y
        offsetAndFilterChildren(node.children, finalUpdates, dx, dy)
    }

    return convertPathToParent(node as PathNode, calculatedPath)
}

function offsetAndFilterChildren(children: CanvasNode[], updatesOut: OutUpdates, dx: number, dy: number) {
    for (let i = 0, il = children.length; i < il; i++) {
        const child = children[i]
        const childUpdate = updatesOut[child.id] as Point
        childUpdate.x -= dx
        childUpdate.y -= dy

        // if all updateOut properties are same as the childs, we remove the child from the update
        let different = false
        for (const key in childUpdate as any) {
            if (childUpdate[key] === child[key]) continue
            different = true
            break
        }
        if (different) continue
        delete updatesOut[child.id]
    }
}

export function updatedRectForBoundingBox(rect: Rect, rotation: number, paths: WithPath[]): [Rect, Point] {
    const bbox = Path.boundingBox(paths)

    const minX = bbox.x
    const minY = bbox.y

    const newRect = {
        x: rect.x + minX,
        y: rect.y + minY,
        width: bbox.width,
        height: bbox.height,
    }

    const origin = convertPoint({ x: 0, y: 0 }, rect, rotation)
    const newOrigin = convertPoint({ x: -minX, y: -minY }, newRect, rotation)
    const delta = Point.subtract(origin, newOrigin)
    newRect.x += delta.x
    newRect.y += delta.y

    return [newRect, { x: minX, y: minY }]
}
