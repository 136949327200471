import { setDefaults } from "./MutableNode"
import { ScopeNode } from "./ScopeNode"
import { getPageRecord } from "../records/PageRecord"
import { withClassDiscriminator } from "utils/withClassDiscriminator"
import { NodeID } from "./NodeID"

export function isPageNode<T>(node: T): node is T & PageNode {
    return node instanceof PageNode
}

export class PageNode extends withClassDiscriminator("PageNode", ScopeNode) {
    homeNodeId: NodeID | null = null

    constructor(properties?: Partial<PageNode>) {
        super()
        setDefaults<PageNode>(this, getPageRecord(), properties)
    }
}
