import * as React from "react"
import { cx } from "linaria"
import * as styles from "./CenterChild.styles"

/** Centers a single child element. */
export const CenterChild = React.forwardRef<HTMLDivElement, React.HtmlHTMLAttributes<HTMLDivElement>>(
    function CenterChild({ children, className, ...props }, forwardedRef) {
        return (
            <div ref={forwardedRef} {...props} className={cx(className, styles.container)}>
                {React.Children.only(children)}
            </div>
        )
    }
)
