import { css } from "linaria"
import { colors, dimensions, cursors } from "../tokens"

const hitTargetInflation = 6
export const checkboxWrapper = css`
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    background-color: ${colors.checkboxBackground};
    border-radius: ${dimensions.checkboxRadius}px;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: ${colors.checkboxBackgroundActive};
    }

    /* a workaround to increase the hit target size */
    & > input::before {
        position: absolute;
        top: -${hitTargetInflation}px;
        right: -${hitTargetInflation}px;
        bottom: -${hitTargetInflation}px;
        left: -${hitTargetInflation}px;
        content: "";
    }
`

export const checkboxWrapperLarge = css`
    width: 16px;
    height: 16px;
    border-radius: ${dimensions.checkboxRadiusLarge}px;
`

export const input = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    -webkit-appearance: none;

    :focus {
        outline: none;
    }

    &:not(:disabled) {
        cursor: ${cursors.button};
    }
`

export const checkedOrIndeterminate = css`
    background-color: ${colors.checkboxBackgroundChecked};

    &:hover {
        background-color: ${colors.checkboxBackgroundCheckedActive};
    }
`

export const checkboxDisabled = css`
    opacity: 0.3;
    pointer-events: none;

    &:hover {
        background-color: ${colors.checkboxBackground};
    }

    &.${checkedOrIndeterminate}:hover {
        background-color: ${colors.checkboxBackgroundChecked};
    }
`

export const path = css`
    transition: stroke-dashoffset 0.1s ease;
`

export const pathChecked = css`
    transition-delay: 0.1s;
`
