import * as React from "react"
import { motion } from "framer-motion"
import { colors } from "../tokens"

const width = 50
const height = 50

export const AnimationShare = React.memo(function AnimationShare() {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                position: "relative",
                width: width,
                height: height,
                borderRadius: 8,
                background: colors.animationBackgroundLight,
            }}
        >
            <svg width="30" height="30" viewBox="0 0 12 12" style={{ margin: 0 }}>
                <motion.path
                    d="M3 6l2 2 4-4"
                    fill="transparent"
                    strokeWidth="1.5"
                    stroke={colors.animationForground}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="8.5"
                    transition={{
                        ease: "easeInOut",
                        duration: 0.7,
                        repeat: Infinity,
                        repeatType: "loop",
                        repeatDelay: 2,
                    }}
                    initial={{
                        opacity: 0,
                        strokeDashoffset: 8.5,
                    }}
                    animate={{
                        strokeDashoffset: 0,
                        opacity: 1,
                    }}
                />
            </svg>
        </div>
    )
})
