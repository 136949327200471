import * as React from "react"
import { RenderEnvironment, RenderTarget } from "../render/types/RenderEnvironment"
import { FrameWithMotion } from "../render/presentation/Frame"
import { Size } from "../render/types/Size"

export interface Props {
    children: React.ReactNode
    size: { width?: string | number; height?: string | number }
    title?: string
    description?: string
    hide?: boolean
    insideUserCodeComponent?: boolean
}

/** @internal */
export function EmptyState({
    title = "",
    description = "Click and drag the connector to any frame on the canvas →",
    children,
    size,
    hide,
    insideUserCodeComponent = false,
}: Props) {
    const { target } = RenderEnvironment
    const childCount = React.Children.count(children)
    const width = size.width
    const height = size.height

    if (insideUserCodeComponent && childCount === 0) {
        return <FrameWithMotion width={width} height={height} data-name="placeholder" />
    }

    if (target !== RenderTarget.canvas) return null
    if (hide) return null
    if (childCount !== 0) return null

    // Determine when the frame is too small to show the text
    const minHeight = 24
    const hasAvailableHeight = height !== undefined && height >= minHeight
    const shouldShowContent = hasAvailableHeight

    return (
        <FrameWithMotion
            key="empty-state"
            className="framerInternalUI-canvasPlaceholder"
            top={0}
            left={0}
            bottom={0}
            right={0}
            style={{ position: "absolute" }}
        >
            {shouldShowContent && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        lineHeight: "1.4",
                        height: "100%",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            width: "100%",
                            // Use a mask to fade out the right edge of the content as it gets cropped.
                            WebkitMaskImage: `linear-gradient(90deg, black, black calc(100% - 12px * ${scaleFactor}), transparent)`,
                        }}
                    >
                        <Title>{title}</Title>
                        <Description>{description}</Description>
                    </div>
                </div>
            )}
        </FrameWithMotion>
    )
}

const scaleFactor = "var(--framerInternalCanvas-canvasPlaceholderContentScaleFactor, 1)"

const Title: React.SFC = ({ children }) => {
    return (
        <span
            style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                flexGrow: 1,
                flexShrink: 0,
                fontWeight: 600,
                marginBottom: "5px",
            }}
        >
            {children}
        </span>
    )
}

const Description: React.SFC = ({ children }) => {
    return (
        <span
            style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                flexGrow: 1,
                flexShrink: 0,
                fontWeight: 400,
                maxWidth: "200px",
            }}
        >
            {children}
        </span>
    )
}
