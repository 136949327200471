import { withChildren } from "document/models/CanvasTree/traits/Children"

import type { CanvasNode } from "document/models/CanvasTree/nodes/CanvasNode"

export function needsMinSize(node: CanvasNode) {
    if (!withChildren(node)) return false

    const hasPlaceholders = Boolean(node.cache.placeholders)
    const allChildrenInvisible = node.children.every(child => !child.isVisible())

    return !hasPlaceholders && allChildrenInvisible
}
