import { init as initLexer, parse } from "es-module-lexer"
import { ModuleSpecifier, RelativeFilePath } from "./types"

const ARGUMENT_IS_RELATIVE_MODULE_SPECIFIER_REGEXP = /^\s*(['"](\..*)['"])\s*$/m

export async function rewriteRelativeImports(
    code: string,
    replacementMap: Record<RelativeFilePath, ModuleSpecifier | undefined>
) {
    await initLexer
    const [imports] = parse(code)

    let transformedCode = code
    for (const { s, e, d } of imports.reverse()) {
        // d = -2 = import.meta.url = we can skip this
        if (d === -2) continue

        // static import statement
        if (d === -1) {
            const moduleSpecifier = code.substring(s, e)
            const replacementSpecifier = replacementMap[moduleSpecifier]
            if (replacementSpecifier === undefined) {
                if (moduleSpecifier.startsWith(".")) {
                    // eslint-disable-next-line no-console
                    console.warn("Couldn't find replacement specifier for ", moduleSpecifier)
                }

                continue
            }
            transformedCode = spliceString(transformedCode, replacementSpecifier, s, e)
            continue
        }

        // dynamic import
        // in case of dynamic import the substring below returns everything between import()'s parenthesis.
        const importArgumentUnsanitized = code.substring(s, e)
        const importArgument = importArgumentUnsanitized
            // strip comments, see https://stackoverflow.com/a/15123777
            .replace(/\/\*[\s\S]*?\*\/|([^\\:]|^)\/\/.*$/gm, "$1")
            .trim()

        const match = importArgument.match(ARGUMENT_IS_RELATIVE_MODULE_SPECIFIER_REGEXP)
        if (!match) continue

        const moduleSpecifierWithQuotes = match[1]
        const moduleSpecifier = match[2]
        const replacementSpecifier = replacementMap[moduleSpecifier]
        if (replacementSpecifier === null) {
            // eslint-disable-next-line no-console
            console.warn("Couldn't find replacement specifier for ", moduleSpecifier)
            continue
        }

        // This will replace all the occurrences in the argument string,
        // so might affect comments if they contain that string but we are ok with that
        const replacementImportArgument = importArgumentUnsanitized.replace(
            new RegExp(escapeRegExp(moduleSpecifierWithQuotes), "g"),
            JSON.stringify(replacementSpecifier)
        )
        transformedCode = spliceString(transformedCode, replacementImportArgument, s, e)
        continue
    }

    return transformedCode
}

function spliceString(source: string, withSlice: string, start: number, end: number): string {
    return source.slice(0, start) + withSlice + source.slice(end)
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
function escapeRegExp(str: string) {
    return str.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
}
