import { CanvasNode } from "document/models/CanvasTree/nodes/CanvasNode"
import { getRectangleShapeRecord } from "document/models/CanvasTree/records/shapes/RectangleShapeRecord"
import { BasicShape } from "document/models/CanvasTree/traits/utils/basicShape"
import { withFrameForShape } from "document/models/CanvasTree/traits/mixins/withFrameForShape"
import { withBaseShapeTraits } from "document/models/CanvasTree/traits/mixins/withBaseShapeTraits"
import { setDefaults } from "../MutableNode"
import type { WithPaths, WithShape } from "framer"
import type { WithRadiusPerCorner } from "document/models/CanvasTree/traits/RadiusPerCorner"
import type { WithRadius } from "document/models/CanvasTree/traits/Radius"
import type { DrawableNode } from "../TreeNode"
import { withClassDiscriminator } from "utils/withClassDiscriminator"
import { VariableReference } from "../../traits/VariableReference"

export default class RectangleShapeNode
    extends withClassDiscriminator("RectangleShapeNode", withFrameForShape(withBaseShapeTraits(CanvasNode)))
    implements WithShape, WithRadiusPerCorner, WithRadius {
    constructor(properties?: Partial<RectangleShapeNode>) {
        super()
        setDefaults<RectangleShapeNode>(this, getRectangleShapeRecord(), properties)
        delete this.children // See comment in `MutableNode.ts`
    }

    radius: number | VariableReference
    radiusPerCorner: boolean
    radiusTopLeft: number
    radiusTopRight: number
    radiusBottomLeft: number
    radiusBottomRight: number

    calculatedPaths(): WithPaths {
        return BasicShape.calculatedPath(this)
    }
}

export function isRectangleShapeNode(node: CanvasNode | null | undefined): node is DrawableNode & RectangleShapeNode {
    return node instanceof RectangleShapeNode
}
