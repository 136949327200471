import type { WithCodeOverride } from "document/models/CanvasTree/traits/CodeOverride"
import type { WithFilters } from "document/models/CanvasTree/traits/Filters"
import {
    newConstraintProperties,
    sizeValue,
} from "document/models/CanvasTree/traits/mixins/withPinsSizeRatioConstraints"
import type { WithShadow } from "document/models/CanvasTree/traits/Shadow"
import type { WithSizeFunctions } from "document/models/CanvasTree/traits/sizeFunctions"
import { collectFilterPropsForNode } from "document/models/CanvasTree/traits/utils/collectEffectsForNode"
import { collectShadowPropsForNode } from "document/models/CanvasTree/traits/utils/collectShadowForNode"
import { collectNameForNode } from "document/models/CanvasTree/traits/utils/collectNameForNode"
import { updateConstrainedFrame, updateConstrainedSize } from "document/models/ConstraintSolver"
import type { Shadow } from "document/models/Shadow"
import type { StyledText, FontChangedListener } from "document/models/StyledText/StyledText"
import {
    Size,
    Rect,
    draftContentStateToHTML,
    ConstraintMask,
    ConstraintValues,
    DimensionType,
    LayerProps,
    TextProperties,
    NewConstraintProperties,
    WithFractionOfFreeSpace,
    RenderTarget,
} from "framer"
import type { List } from "immutable"
import type { CanvasTree } from "../CanvasTree"
import { getTextRecord } from "../records/TextRecord"
import type { WithAutoSize } from "../traits/AutoSize"
import type { WithExport } from "../traits/Export"
import type { WithRect } from "../traits/Frame"
import type { WithLock } from "../traits/Lock"
import type { WithName } from "../traits/Name"
import type { WithPins } from "../traits/Pins"
import type { WithRotation } from "../traits/Rotation"
import type { WithSize } from "../traits/Size"
import type { WithStyledText } from "../traits/StyledText"
import type { WithTarget } from "../traits/Target"
import { collectVisualPropertiesForNode } from "../traits/utils/collectVisualPropertiesForNode"
import type { WithVisibility } from "../traits/Visibility"
import { CanvasNode } from "./CanvasNode"
import type { ExportOptions } from "./ExportOptions"
import { setDefaults } from "./MutableNode"
import { assert } from "@framerjs/shared"
import type { WithClip } from "../traits/Clip"
import { WithAspectRatio, withAspectRatio } from "../traits/AspectRatioLock"
import type { WithTextVerticalAlignment } from "../traits/TextVerticalAlignment"
import type { TextVerticalAlignment } from "framer"
import { withClassDiscriminator } from "utils/withClassDiscriminator"
import { isNumber, isString } from "utils/typeChecks"
import { isVariableReference, VariableReference } from "../traits/VariableReference"
import type { WithOpacity } from "../traits/Opacity"
import { TextNodeCache } from "./TextNodeCache"
import { Attribute } from "../utils/buildJSX"
import { TagProps } from "variants/types"
import { collectStyle } from "../traits/collectStyles"
import { attributesFromProps } from "variants/utils/attributesFromProps"
import { RawDraftContentState } from "draft-js"
import { WithTextContent } from "../traits/TextContent"
import { withDOMLayoutTraits } from "document/models/CanvasTree/traits/mixins/withDOMLayoutTraits"

export function isTextNode(node: CanvasNode): node is TextNode<any> {
    return node instanceof TextNode
}

export class TextNode<T> extends withClassDiscriminator("TextNode", withDOMLayoutTraits(CanvasNode))
    implements
        WithRect,
        WithName,
        WithVisibility,
        WithLock,
        WithClip,
        WithStyledText,
        WithTextVerticalAlignment,
        WithAutoSize,
        WithSize,
        WithSizeFunctions,
        WithPins,
        WithOpacity,
        WithRotation,
        WithShadow,
        WithFilters,
        WithExport,
        WithTarget,
        WithTextContent,
        WithCodeOverride,
        FontChangedListener {
    cache: TextNodeCache

    name: string | null
    visible: boolean | VariableReference
    locked: boolean
    clip: boolean

    width: number
    height: number

    constraintsLocked: boolean
    left: number | null
    right: number | null
    top: number | null
    bottom: number | null
    centerAnchorX: number
    centerAnchorY: number
    widthType: DimensionType
    heightType: DimensionType

    autoSize: boolean
    rotation: number | VariableReference
    opacity: number | VariableReference

    shadows: readonly Shadow[] | undefined

    // The text content variable overrides the styledText content
    textContent: VariableReference | undefined

    styledText: StyledText<T>
    textVerticalAlignment: TextVerticalAlignment

    blur: number | VariableReference | undefined
    backgroundBlur: number | VariableReference | undefined
    brightness: number | VariableReference | undefined
    contrast: number | VariableReference | undefined
    grayscale: number | VariableReference | undefined
    hueRotate: number | VariableReference | undefined
    invert: number | VariableReference | undefined
    saturate: number | VariableReference | undefined
    sepia: number | VariableReference | undefined

    exportOptions: List<ExportOptions>

    isTarget: boolean

    codeOverrideEnabled: boolean
    codeOverrideIdentifier: string | undefined
    codeOverrideFile: string | undefined
    codeOverrideName: string | undefined

    constructor(properties?: Partial<TextNode<T>>) {
        super(undefined, new TextNodeCache())
        setDefaults<TextNode<T>>(this, getTextRecord(), properties)
        delete this.children // See comment in `MutableNode.ts`
    }

    /**
     * Determine if the text node should perform size calculations.
     */
    shouldCalculateSize(autoSize: boolean) {
        const renderTarget = RenderTarget.current()
        return autoSize && (renderTarget === RenderTarget.canvas || renderTarget === RenderTarget.export)
    }

    set(properties: { [key: string]: any }, tree?: CanvasTree): this {
        const clone = super.set(properties, tree)
        return clone as this
    }

    // FIXME: return Latest.TextNode
    toJS() {
        const object = super.toJS()
        if (withAspectRatio(object)) {
            delete (object as Partial<WithAspectRatio>).aspectRatio
        }
        return object
    }

    minSize(parentSize: Size | null): Size {
        return ConstraintValues.toMinSize(this.constraintValues(), parentSize)
    }

    size(parentSize: Size | null, freeSpace: WithFractionOfFreeSpace | null, allowCache = true): Size {
        const { parentDirectedRect } = this.cache
        if (parentDirectedRect && allowCache) {
            return { width: parentDirectedRect.width, height: parentDirectedRect.height }
        }
        return ConstraintValues.toSize(this.constraintValues(), parentSize, null, freeSpace)
    }

    rect(parentSize: Size | null, pixelAlign = true): Rect {
        if (this.usesDOMRectCached()) {
            const domRect = this.getDOMRect()
            if (domRect) return domRect
        }
        if (this.cache.parentDirectedRect) {
            return { ...this.cache.parentDirectedRect }
        }
        return ConstraintValues.toRect(this.constraintValues(), parentSize, null, pixelAlign)
    }

    matrix(parentSize?: Size | null, frame?: Rect) {
        if (!frame && parentSize !== undefined) {
            frame = this.rect(parentSize)
        }
        if (!frame) {
            throw Error("Could not determine frame to build matrix from")
        }
        return new DOMMatrix()
            .translateSelf(frame.x || 0, frame.y || 0)
            .rotateSelf(0, 0, this.resolveValue("rotation") ?? 0)
    }

    transformMatrix(parentSize: Size | null, frame?: Rect) {
        if (!frame) {
            frame = this.rect(parentSize)
        }
        if (!frame) {
            throw Error("Could not determine frame to build matrix from")
        }
        return new DOMMatrix()
            .translateSelf(0.5 * frame.width, 0.5 * frame.height)
            .multiplySelf(this.matrix(parentSize, frame))
            .translateSelf(-0.5 * frame.width, -0.5 * frame.height)
    }

    get text(): string {
        return this.getStyledText().text
    }

    set text(value: string) {
        // The correct way to update a text node in production code:
        // > tree.update(textNode, {styledText: textNode.styledText.withUpdatedText(value)})
        assert(process.env.NODE_ENV === "test", "Only works in unit tests.")
        this.styledText = this.styledText.withUpdatedText(value)
    }

    isRotated() {
        return (this.resolveValue("rotation") ?? 0) % 360 !== 0
    }

    constraints(): ConstraintMask {
        if (this.cache.parentDirected) {
            return ConstraintMask.quickfix({
                left: false,
                right: false,
                top: false,
                bottom: false,
                widthType: this.widthType,
                heightType: this.heightType,
                aspectRatio: null,
                fixedSize: false,
            })
        }

        return ConstraintMask.quickfix({
            left: isNumber(this.left),
            right: isNumber(this.right),
            top: isNumber(this.top),
            bottom: isNumber(this.bottom),
            widthType: this.widthType,
            heightType: this.heightType,
            aspectRatio: null,
            fixedSize: this.autoSize,
        })
    }

    calculateSize(width?: number): Size {
        const styledText = this.getStyledText()

        if (this.shouldCalculateSize(this.autoSize)) {
            const calculatedSize = styledText.calculateSize(width, () => this.fontChanged())
            if (calculatedSize) {
                return calculatedSize
            }
        }

        return { width: this.width, height: this.height }
    }

    constraintValues(): ConstraintValues {
        const constraints = this.constraints()

        // If the fonts are not loaded we used the cached size from the document
        const { width, height } = this.calculateSize()

        return {
            left: constraints.left ? this.left : null,
            right: constraints.right ? this.right : null,
            top: constraints.top ? this.top : null,
            bottom: constraints.bottom ? this.bottom : null,
            widthType: constraints.widthType,
            heightType: constraints.heightType,
            width,
            height,
            aspectRatio: null,
            centerAnchorX: this.centerAnchorX,
            centerAnchorY: this.centerAnchorY,
        }
    }

    newConstraintProperties(): NewConstraintProperties {
        // If we're not mutable and we have a cache return it directly.
        if (!this.mutable && this.cache.newConstraintProps) {
            return this.cache.newConstraintProps
        }

        // Otherwise we want to update the cache as needed and return
        // the latest version.
        return this.updateCachedNewConstraintProperties()
    }

    updateCachedNewConstraintProperties(): NewConstraintProperties {
        // This will create or update the version of the
        // NewConstraintProperties stored in node.cache.newConstraintProps
        // and return them. We then mutate the cached object to override the
        // width/height based on the text size calculations.
        const constraintProperties = newConstraintProperties(this)
        constraintProperties._constraints.autoSize = this.autoSize

        if (this.autoSize) {
            const calculatedSize = this.calculateSize()
            constraintProperties.width = calculatedSize.width
            constraintProperties.height = calculatedSize.height
        } else {
            constraintProperties.width = sizeValue(this.width, this.widthType)
            constraintProperties.height = sizeValue(this.height, this.heightType)
        }

        // When using styledText.calculateSize() it's possible to get back
        // an invalid "zero" size when unable to calculate the dimensions. In
        // this case we want to invalidate the cache and just return the
        // existing value so that it can be recalculated on the next render.
        const isInvalidSize = this.autoSize && constraintProperties.width === 0 && constraintProperties.height === 0
        if (isInvalidSize) {
            this.cache.newConstraintProps = null
        }

        return constraintProperties
    }

    updateForRect(rect: Rect, parentSize: Size | null, lockedConstraints: boolean) {
        let update: Partial<Rect> & Partial<WithPins> = {
            width: rect.width,
            height: rect.height,
        }

        let styledText
        if (this.autoSize) {
            const currentRect = this.rect(parentSize)
            if (currentRect.height !== update.height) {
                styledText = this.getStyledText().scaledToHeight(rect.height)
            }
        }

        const lock = this.constraintsLocked || (lockedConstraints !== undefined && lockedConstraints)
        update = updateConstrainedFrame(
            rect,
            parentSize,
            this.constraints(),
            lock,
            // pixelAlign
            !this.usesDOMRectCached()
        )

        if (styledText) {
            return { ...update, styledText }
        } else {
            return update
        }
    }

    updateForSize(size: Partial<Size>, parentSize: Size | null, resize: boolean = true) {
        const update: Partial<WithStyledText> = {}
        const currentRect = this.rect(parentSize)

        let styledText = this.getStyledText()
        if (this.autoSize && resize) {
            if (size.height !== undefined && currentRect.height !== size.height) {
                styledText = styledText.scaledToHeight(size.height)
                const calculatedSize = this.calculateSize()
                size.width = calculatedSize.width
                size.height = calculatedSize.height
                update.styledText = styledText
            }
        }

        const constraintValues = this.constraintValues()

        if (this.autoSize && this.cache.isEditable) {
            const constraintsUpdate: Partial<ConstraintValues> = { width: size.width, height: size.height }

            if (size.width && "alignment" in styledText) {
                const alignment = styledText["alignment"]() || "left"

                if (alignment === "left" && constraintValues.left === null) {
                    if (constraintValues.right !== null) {
                        constraintsUpdate.right = constraintValues.right - (size.width - currentRect.width)
                    }
                    if (parentSize) {
                        constraintsUpdate.centerAnchorX = (currentRect.x + size.width / 2) / parentSize.width
                    }
                }
                if (alignment === "right" && constraintValues.right === null) {
                    if (constraintValues.left !== null) {
                        constraintsUpdate.left = constraintValues.left - (size.width - currentRect.width)
                    }
                    if (parentSize) {
                        constraintsUpdate.centerAnchorX =
                            (currentRect.x - (size.width - currentRect.width) + size.width / 2) / parentSize.width
                    }
                }
                if (alignment === "center") {
                    const delta = Math.round((size.width - currentRect.width) / 2)
                    if (constraintValues.left !== null) {
                        constraintsUpdate.left = constraintValues.left - delta
                    } else if (constraintValues.right !== null) {
                        constraintsUpdate.right = constraintValues.right - delta
                    }
                }
            }

            if (size.height && constraintValues.top === null) {
                if (constraintValues.bottom !== null) {
                    constraintsUpdate.bottom = constraintValues.bottom - (size.height - currentRect.height)
                }
                if (parentSize) {
                    constraintsUpdate.centerAnchorY = (currentRect.y + size.height / 2) / parentSize.height
                }
            }
            return { ...update, ...constraintsUpdate }
        } else {
            return { ...update, ...updateConstrainedSize(size, parentSize, constraintValues) }
        }
    }

    getAllFonts(): string[] {
        return []
    }

    static fontChangedCallback?: (node: TextNode<unknown>) => void

    fontChanged() {
        TextNode.fontChangedCallback && TextNode.fontChangedCallback(this)
    }

    getStyledText(): StyledText<T> {
        if (isVariableReference(this.textContent)) {
            const textContentValue = this.resolveValue("textContent")
            if (isString(textContentValue)) {
                return this.cache.getStyledTextWithUpdatedText(this.styledText, textContentValue)
            }
        }

        // While using the TextEditor we use the cache.styledText
        // As you cannot use the TextEditor when a variable is assigned for text content, these caches are mutually exclusive
        if (this.cache.styledText) return this.cache.styledText as StyledText<T>
        return this.styledText
    }

    getProps(): Partial<TextProperties> & LayerProps {
        const styledText = this.getStyledText()
        const constraintProperties = this.newConstraintProperties()
        const props: Partial<TextProperties> = {
            ...super.getProps(),
            style: {
                overflow: this.clip && !this.autoSize ? "hidden" : "visible",
            },
            ...constraintProperties,
            ...styledText.getProps(this),
            ...collectVisualPropertiesForNode(this),
            rotation: this.resolveValue("rotation"),
            visible: this.resolveValue("visible"),
            autoSize: this.autoSize,
            opacity: this.resolveValue("opacity"),
            verticalAlignment: this.textVerticalAlignment,
            text: isVariableReference(this.textContent) ? this.resolveValue("textContent") : undefined,
        }

        if (this.__unsafeIsGroundNode()) {
            props.left = 0
            props.top = 0
        }

        collectNameForNode(this, props)
        collectFilterPropsForNode(this, props)
        collectShadowPropsForNode(this, props)

        return props
    }

    getAttributes(defaultProps: Partial<TagProps>): Attribute[] {
        // NOTE: Once the rawDraftRendering experiment ships, this can also be
        // used to replace this.getRawHTML() below.
        const { alignment, fonts } = this.getStyledText().getProps(this)
        const constraintProperties = this.newConstraintProperties()

        const props: TagProps = {
            layoutId: this.id,
            style: {},
            withExternalLayout: true,
            verticalAlignment: this.textVerticalAlignment,
            __fromCanvasComponent: true,
            alignment,
            fonts,
            autoSize: this.autoSize,
            center: constraintProperties.center,
            ...defaultProps,
        }

        collectStyle(this, props.style, { withInlineVariables: true }, true)

        const attributes = attributesFromProps(props)

        const rawHTML = this.getRawHTML()
        // Add the rawHTML to the Tag attributes as a string variable so that
        // the html characters aren't escaped.
        if (rawHTML) attributes.push({ type: "variable", name: "rawHTML", value: `\`${rawHTML}\`` })
        if (this.textContent && isVariableReference(this.textContent)) {
            attributes.push({
                value: `"${this.resolveValue("textContent", true)}"`,
                type: "variable",
                name: "text",
            })
        }
        return attributes
    }

    getRawHTML(): string {
        /** @TODO future improvements to the DraftJS refactor should remove the need for this cast */
        return draftContentStateToHTML(this.getStyledText().toJS() as RawDraftContentState)
    }
}
