import type { Shadow as ShadowInterface, BoxShadow as BoxShadowInterface } from "framer"
import { randomID } from "./CanvasTree/nodes/NodeID"
import type { WithTokenVariables } from "./CanvasTree/traits/TokenVariables"
import { isTokenCSSVariable } from "./CanvasTree/nodes/TokenNode"
import { findValueForTokenCSSVariable } from "./CanvasTree/utils/findValueForTokenCSSVariable"
import { withClassDiscriminator } from "utils/withClassDiscriminator"
import { ValueObject, Writeable } from "utils/readonly"

export class Shadow extends withClassDiscriminator("Shadow") implements ShadowInterface, WithTokenVariables<Shadow> {
    readonly id: string = "__shadow__"
    readonly color: string = "rgba(0,0,0,0.25)"
    readonly x: number = 0
    readonly y: number = 2
    readonly blur: number = 5

    private constructor(values?: Partial<Shadow>) {
        super()
        ValueObject.writeOnce(this, values)
    }

    static create(values: Writeable<Partial<Shadow>>): Shadow {
        if (!values.id) {
            values.id = randomID()
        }
        return new Shadow(values)
    }

    updatedShadow(values: Partial<Shadow>): Shadow {
        return ValueObject.update(this, values)
    }

    duplicatedShadow(): Shadow {
        return ValueObject.update(this, { id: randomID() })
    }

    tokenVariables(): string[] {
        return isTokenCSSVariable(this.color) ? [this.color] : []
    }

    removeTokenVariables(variables: { [tokenId: string]: string }): Shadow | undefined {
        const color = findValueForTokenCSSVariable(this.color, variables)
        if (color) return ValueObject.update(this, { color })
    }
}

export class BoxShadow extends withClassDiscriminator("BoxShadow")
    implements BoxShadowInterface, WithTokenVariables<BoxShadow> {
    readonly id: string = ""
    readonly color: string = "rgba(0,0,0,0.25)"
    readonly x: number = 0
    readonly y: number = 2
    readonly blur: number = 5
    readonly inset: boolean = false
    readonly spread: number = 0

    private constructor(values?: Partial<BoxShadow>) {
        super()
        ValueObject.writeOnce(this, values)
    }

    static create(values: Writeable<Partial<BoxShadow>>): BoxShadow {
        if (!values.id) {
            values.id = randomID()
        }
        return new BoxShadow(values)
    }

    updatedBoxShadow(values: Partial<BoxShadow>): BoxShadow {
        return ValueObject.update(this, values)
    }

    duplicatedShadow(): BoxShadow {
        return ValueObject.update(this, { id: randomID() })
    }

    tokenVariables(): string[] {
        return isTokenCSSVariable(this.color) ? [this.color] : []
    }

    removeTokenVariables(variables: { [tokenId: string]: string }): BoxShadow | undefined {
        const color = findValueForTokenCSSVariable(this.color, variables)
        if (color) return ValueObject.update(this, { color })
    }
}

export function isShadow(obj: unknown): obj is Shadow {
    return obj instanceof Shadow
}

export function isBoxShadow(obj: unknown): obj is BoxShadow {
    return obj instanceof BoxShadow
}
