import { cx } from "linaria"
import * as React from "react"
import * as classes from "./Bar.styles"

interface Props {
    className?: string

    // Start comes right after the brand.
    start?: React.ReactNode
    // Center is laid out separately on top of the bar.
    center?: React.ReactNode
    // Spanner is the space between start and end.
    spanner?: React.ReactNode
    // Children will be placed at the end.
    children?: React.ReactNode
}

export const Bar = React.memo(
    React.forwardRef<HTMLDivElement, Props>(function Bar(
        { center, children: end, className, spanner, start }: Props,
        forwardedRef
    ) {
        return (
            <div className={cx(classes.bar, className)} ref={forwardedRef}>
                {start}
                <div className="spanner">{spanner}</div>
                {center && <div className="center">{center}</div>}
                {end}
            </div>
        )
    })
)
