import { css } from "linaria"
import { cursors, colors, dimensions, shadows } from "../tokens"
import type { ValidCSSProperties } from "./types"

const sliderTrack: ValidCSSProperties = {
    background: `linear-gradient(
        to right,
        ${colors.sliderTrackActive} calc(var(--progress)),
        ${colors.sliderTrack} calc(var(--progress))
    )`,
    borderRadius: 2,
    height: 2,
    willChange: "transform",
}

const sliderTrackDisabled: ValidCSSProperties = {
    background: `${colors.sliderTrackDisabled}`,
}

const sliderThumb: ValidCSSProperties = {
    "-webkit-appearance": "none",
    backgroundColor: `${colors.sliderKnob}`,
    border: "none",
    borderRadius: "50%",
    boxShadow: `${shadows.sliderKnob}`,
    cursor: `${cursors.button}`,
    height: 10,
    marginTop: -4,
    opacity: 1,
    width: 10,
    willChange: "transform",
}

const sliderThumbDisabled: ValidCSSProperties = {
    display: "none",
}

export const slider = css`
    height: ${dimensions.inputHeight}px;
    -webkit-appearance: none;
    background-color: transparent;

    ::-moz-focus-outer {
        border: 0;
    }

    :focus {
        outline: none;
    }

    ::-webkit-slider-runnable-track {
        ${sliderTrack}
    }
    ::-moz-range-track {
        ${sliderTrack}
    }

    :disabled::-webkit-slider-runnable-track {
        ${sliderTrackDisabled}
    }
    :disabled::-moz-range-track {
        ${sliderTrackDisabled}
    }

    ::-webkit-slider-thumb {
        ${sliderThumb}
    }
    ::-moz-range-thumb {
        ${sliderThumb}
    }

    :disabled::-webkit-slider-thumb {
        ${sliderThumbDisabled}
    }
    :disabled::-moz-range-thumb {
        ${sliderThumbDisabled}
    }
`
