import type { ActionControls, Action, ActionInfo } from "framer"

/**
 * Used to get and set action metadata
 * @private
 */
const actionControlsKey = Symbol("actionControls")

/**
 * Provide a title and controls for an action, used by Framer X
 * @param action - a reference to an {@link Action}
 * @param title - the display title of the action
 * @param controls - the action controls
 * @internal
 */
export function addActionControls<Options extends { [key: string]: any }>(
    action: Action<Options>,
    title: string,
    controls: ActionControls<Options>
) {
    const info: ActionInfo = { title, controls }
    ;(action as any)[actionControlsKey] = info
}

/**
 * Retrieve the title and controls of an action
 * @param action - a reference to an {@link Action}
 * @returns an object containing the title and controls, or undefined
 * @internal
 */
export function getActionControls<Options extends { [key: string]: any }>(
    action: Action<Options>
): Partial<ActionInfo> | undefined {
    return (action as any)[actionControlsKey]
}
