import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { springAnimation, springAnimation2, useLoop } from "./utils"
import { colors } from "../tokens"

const phone: React.CSSProperties = {
    width: 68,
    height: 108,
    display: "grid",
    gap: 5,
    padding: 10,
    backgroundColor: colors.animationTriggerFrame,
    boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.05)",
    borderRadius: 6,
    overflow: "hidden",
}

const card: React.CSSProperties = {
    backgroundColor: colors.animationTriggerCard,
    borderRadius: 4,
    height: 26,
}

const cardLarge: React.CSSProperties = {
    backgroundColor: colors.animationTriggerCard,
    borderRadius: 4,
    height: 88,
}

const dotLeft: React.CSSProperties = {
    width: 8,
    height: 8,
    backgroundColor: "#ffffff",
    border: "1px solid #8855ff",
    borderRadius: "50%",
    position: "absolute",
    left: 54,
}

const dotRight: React.CSSProperties = {
    width: 8,
    height: 8,
    backgroundColor: "#ffffff",
    border: "1px solid #8855ff",
    borderRadius: "50%",
    position: "absolute",
    left: 94,
}

export function AnimationTrigger() {
    // Animations
    const fullFrame = useAnimation()
    const leftFrame = useAnimation()
    const rightFrame = useAnimation()
    const itemTop = useAnimation()
    const itemBottom = useAnimation()
    const itemTap = useAnimation()
    const itemLarge = useAnimation()
    const dot1 = useAnimation()
    const dot2 = useAnimation()
    const arrow = useAnimation()
    const sceneDelay = 0.5

    async function animation() {
        // Set
        fullFrame.set({
            opacity: 1,
        })
        leftFrame.set({
            scaleX: 0,
            scaleY: 0,
            originX: 0,
            originY: 0,
            x: 0,
        })
        rightFrame.set({
            scaleX: 0,
            scaleY: 0,
            originX: 0,
            originY: 0,
            border: "none",
            opacity: 1,
            x: 0,
        })
        itemTop.set({
            scaleX: 0,
        })
        itemBottom.set({
            scaleX: 0,
        })
        itemLarge.set({
            scaleX: 0,
        })
        itemTap.set({
            scaleX: 0,
            height: 26,
            y: 0,
            originX: 0.5,
            originY: 0.5,
            border: "none",
        })
        dot1.set({
            scale: 0,
            opacity: 1,
        })
        dot2.set({
            scale: 0,
            opacity: 1,
        })
        arrow.set({
            pathLength: 0,
            opacity: 1,
        })

        // Animate Phones
        await Promise.all([
            leftFrame.start({
                scaleX: 1,
                scaleY: 1,
                transition: { ...springAnimation.default, delay: 0.5 },
            }),
            rightFrame.start({
                scaleX: 1,
                scaleY: 1,
                transition: { ...springAnimation.default, delay: 0.75 },
            }),
        ])

        // Animate Cards
        await Promise.all([
            itemTop.start({
                scaleX: 1,
                transition: { ...springAnimation2, delay: 0.1 },
            }),
            itemTap.start({
                scaleX: 1,
                transition: { ...springAnimation2, delay: 0.2 },
            }),
            itemBottom.start({
                scaleX: 1,
                transition: { ...springAnimation2, delay: 0.3 },
            }),
            itemLarge.start({
                scaleX: 1,
                transition: { ...springAnimation2, delay: 0.4 },
            }),
        ])

        // Link Cards
        await Promise.all([
            itemTap.start({
                border: "1px solid #8955FF",
                transition: { duration: 0, delay: 0.5 },
            }),
            dot1.start({
                scale: 1,
                transition: { ...springAnimation2, delay: 0.4 },
            }),
            arrow.start({
                pathLength: 1,
                transition: { delay: 0.75, duration: 0.1 },
            }),
            dot2.start({
                scale: 1,
                transition: { ...springAnimation2, delay: 0.85 },
            }),
            rightFrame.start({
                border: "1px solid #8955FF",
                transition: { duration: 0, delay: 1 },
            }),
        ])

        // Focus Card
        await Promise.all([
            leftFrame.start({
                x: 45,
                transition: { springAnimation, delay: sceneDelay },
            }),
            rightFrame.start({
                scaleX: 0.5,
                opacity: 0,
                originY: 0.5,
                x: -5,
                transition: { duration: 0.2, delay: sceneDelay },
            }),
            itemTap.start({
                border: "none",
                transition: { duration: 0, delay: sceneDelay },
            }),
            dot1.start({
                opacity: 0,
                transition: { duration: 0, delay: sceneDelay },
            }),
            dot2.start({
                opacity: 0,
                transition: { duration: 0, delay: sceneDelay },
            }),
            arrow.start({
                pathLength: 0,
                transition: { duration: 0, delay: sceneDelay },
            }),
        ])

        // Expand
        await itemTap.start({
            scale: 0.9,
            opacity: 0.5,
            transition: { duration: 0.2, delay: sceneDelay },
        })

        await itemTap.start({
            scale: 1,
            opacity: 1,
            transition: { duration: 0.2 },
        })
        await Promise.all([
            itemTap.start({
                scale: 1,
                height: 90,
                y: -32,
                opacity: 1,
                transition: springAnimation,
            }),
            itemTop.start({
                y: -32,
                opacity: 0,
                transition: springAnimation,
            }),
            itemBottom.start({
                opacity: 0,
                transition: springAnimation,
            }),
        ])

        // Contract
        await itemTap.start({
            scale: 0.9,
            opacity: 0.5,
            transition: { duration: 0.2 },
        })

        await Promise.all([
            itemTap.start({
                scale: 1,
                height: 26,
                y: 0,
                opacity: 1,
                transition: springAnimation,
            }),
            itemTop.start({
                y: 0,
                opacity: 1,
                transition: springAnimation,
            }),
            itemBottom.start({
                opacity: 1,
                transition: springAnimation,
            }),
        ])

        await itemTap.start({
            scale: 1,
            height: 26,
            y: 0,
            opacity: 1,
            transition: springAnimation,
        })

        // Fade Out
        return await fullFrame.start({
            opacity: 0,
        })
    }

    useLoop(animation)

    return (
        <motion.div
            animate={fullFrame}
            style={{
                width: 166,
                height: 108,
                position: "relative",
                background: colors.inputBackground,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <motion.div style={phone} animate={leftFrame}>
                <motion.div style={card} animate={itemTop} custom={0} />
                <motion.div style={card} animate={itemTap} custom={1} />
                <motion.div style={card} animate={itemBottom} custom={2} />
            </motion.div>
            <motion.div style={phone} animate={rightFrame}>
                <motion.div style={cardLarge} animate={itemLarge} custom={3} />
            </motion.div>
            <div style={{ position: "absolute", left: 61, top: 43 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="1">
                    <motion.path
                        animate={arrow}
                        d="M 0 0.5 L 34 0.5"
                        fill="transparent"
                        stroke="rgba(136, 85, 255, 1.00)"
                    ></motion.path>
                </svg>
            </div>
            <motion.div style={dotLeft} animate={dot1} custom={0} />
            <motion.div style={dotRight} animate={dot2} custom={1} />
        </motion.div>
    )
}
