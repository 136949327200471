import { css } from "linaria"
import { colors, dimensions, fonts } from "../../tokens"

export const textInputSharedStyles = css`
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: ${colors.inputBorder};
    background: ${colors.inputBackground};
    border-radius: ${dimensions.inputRadius}px;
    color: ${colors.inputText};
    font-size: ${fonts.size.base}px;
    font-weight: ${fonts.weight.base};

    &::-webkit-input-placeholder {
        color: ${colors.inputPlaceholder};
        font-weight: ${fonts.weight.base};
        -webkit-text-fill-color: ${colors.inputPlaceholder};
    }

    /* This fixes autocomplete styling in Chrome. */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-animation: autofill 0s forwards;
        animation: autofill 0s forwards;

        /* For Chrome 83+ (font-size & font-family are still broken) */
        box-shadow: 0 0 0 50px ${colors.inputBackground} inset;
        -webkit-text-fill-color: ${colors.inputText};
    }

    @keyframes autofill {
        100% {
            background: transparent;
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
        }
    }

    @-webkit-keyframes autofill {
        100% {
            background: transparent;
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
        }
    }

    :enabled {
        -webkit-user-select: text;
        user-select: text;
    }

    :disabled {
        color: ${colors.inputTextDisabled};
        -webkit-text-fill-color: ${colors.inputTextDisabled};
    }

    :focus {
        border-color: ${colors.inputBorderActive};
        outline: none;
    }
`
