// ❗️
// The Services package needs to work in any TypeScript environment, so dependencies should be limited to things that
// are available in all the client subsystems. These types server as a failable import. If the reader knows of a better
// solution to only reference types without creating a tsc/webpack dependency, definitely change this up.

// lib.dom

export interface Window extends MessageEventTarget {
    readonly parent: Window
    readonly opener: Window | null
    postMessage(message: any, targetOrigin: string): void
}

export interface MessagePort extends MessageEventTarget {
    postMessage(message: any): void
    start(): void
    close(): void
}

export interface MessageEvent {
    readonly data: unknown
    readonly source: unknown
    readonly origin: unknown
}

interface MessageEventTarget {
    addEventListener(
        type: "message",
        listener: (evt: MessageEvent) => void,
        options?: boolean | AddEventListenerOptions
    ): void
    removeEventListener(type: "message", listener: (evt: MessageEvent) => void, options?: boolean): void
}

interface AddEventListenerOptions {
    once?: boolean
    passive?: boolean
}

// socket.io

export interface SocketClient {
    on(event: "connect" | string, fn: Function): void
    emit(event: string, ...args: any[]): void
    close(): void
}

export interface SocketServer {
    on(event: "connect" | string, fn: Function): void
    emit(event: string, ...args: any[]): void
}

export interface SocketServerClient {
    on(event: "connect" | string, fn: Function): void
    emit(event: string, ...args: any[]): void
}

export function isSocketServerClient(socket: any): socket is SocketServerClient {
    return !!socket && typeof socket.on === "function" && typeof socket.emit === "function"
}

/**
 * Node and Browser have slightly different setTimeout definitions and we
 * import neither library into the Services TypeScript configuration. So to
 * avoid conflicts we just pull them off the global object and redeclare them
 * in this local module.
 */
const ctx = new Function("return this")()
export const setTimeout: (handler: Function, timeout?: number, ...args: any[]) => number = (...args) =>
    ctx.setTimeout(...args)
export const clearTimeout: (handle?: number) => void = (...args) => ctx.clearTimeout(...args)
