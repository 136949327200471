import { V63, V64 } from "../types"
import { transform } from "../utils/transform"
import { _, exactCheck } from "../utils/exactCheck"
import { withoutProps } from "../utils/withoutProps"

export function migration_63_64(documentJSON: V63.Tree): V64.Tree {
    const migratedRoot = transform(documentJSON.root, node => {
        if (node.__class === "FrameNode") {
            const framePreset = withDeviceType(node) ? toFramePreset(node.deviceType) : null
            const migratedNode = withoutProps({ framePreset, ...node }, "deviceType")
            return exactCheck(migratedNode, _ as V64.FrameNode)
        }

        if (node.__class === "ShapeContainerNode") {
            const migratedNode = withoutProps({ ...node }, "deviceType")
            return exactCheck(migratedNode, _ as V64.ShapeContainerNode)
        }

        if (node.__class === "CodeComponentNode") {
            const migratedNode = withoutProps({ ...node }, "deviceType")
            return exactCheck(migratedNode, _ as V64.CodeComponentNode)
        }

        return exactCheck(node, _ as V64.TreeNode)
    })
    return { version: 64, root: migratedRoot }
}

interface WithDeviceType {
    deviceType: DeprecatedDeviceID
}

const key: keyof WithDeviceType = "deviceType"

function withDeviceType(value: V63.TreeNode): value is V63.TreeNode & WithDeviceType {
    return key in value
}

export type DeprecatedDeviceID =
    | "framer/FramerAppleWatch38"
    | "framer/FramerAppleWatch42"
    | "framer/FramerSonySmartWatch"
    | "framer/FramerAppleIPhoneSE"
    | "framer/FramerAppleIPhone8"
    | "framer/FramerAppleIPhone8Plus"
    | "framer/FramerAppleIPhoneXR"
    | "framer/FramerAppleIPhoneXS"
    | "framer/FramerAppleIPhoneXSMax"
    | "framer/FramerGooglePixel2"
    | "framer/FramerGooglePixel2XL"
    | "framer/FramerGooglePixel3"
    | "framer/FramerGooglePixel3XL"
    | "framer/FramerSamsungNote5"
    | "framer/FramerSamsungGalaxyS9"
    | "framer/FramerAppleIPadAir"
    | "framer/FramerAppleIPadMini"
    | "framer/FramerAppleIPadPro"
    | "framer/FramerGoogleNexusTablet"
    | "framer/FramerMicrosoftSurfacePro3"
    | "framer/FramerMicrosoftSurfacePro4"
    | "framer/FramerAppleIMac"
    | "framer/FramerAppleThunderboltDisplay"
    | "framer/FramerAppleMacBook"
    | "framer/FramerAppleMacBookAir"
    | "framer/FramerAppleMacBookPro"
    | "framer/FramerDellXPS"
    | "framer/FramerMicrosoftSurfaceBook"
    | "framer/FramerSonyW850C"
    | "framer/FramerStoreArtwork"
    | "framer/FramerStoreIcon"

const deviceToFramePresetMigration: Record<DeprecatedDeviceID, V64.FramePresetID | null> = {
    "framer/FramerAppleWatch38": "AppleWatch_38",
    "framer/FramerAppleWatch42": "AppleWatch_42",
    "framer/FramerSonySmartWatch": null, // old
    "framer/FramerAppleIPhoneSE": "iPhone_320_568",
    "framer/FramerAppleIPhone8": "iPhone_375_667",
    "framer/FramerAppleIPhone8Plus": "iPhone_414_736",
    "framer/FramerAppleIPhoneXR": "iPhone_414_896",
    "framer/FramerAppleIPhoneXS": "iPhone_375_812",
    "framer/FramerAppleIPhoneXSMax": "iPhone_414_896_pro",
    "framer/FramerGooglePixel2": null,
    "framer/FramerGooglePixel2XL": null,
    "framer/FramerGooglePixel3": "GooglePixel_411_822",
    "framer/FramerGooglePixel3XL": "GooglePixel_411_846",
    "framer/FramerSamsungNote5": null, // old
    "framer/FramerSamsungGalaxyS9": null, // old
    "framer/FramerAppleIPadAir": "iPad_768_1024", // screen size changed, mapping to mini
    "framer/FramerAppleIPadMini": "iPad_768_1024",
    "framer/FramerAppleIPadPro": "iPad_1024_1366",
    "framer/FramerGoogleNexusTablet": null, // old
    "framer/FramerMicrosoftSurfacePro3": "Surface_1440_960",
    "framer/FramerMicrosoftSurfacePro4": "Surface_1368_912",
    "framer/FramerAppleIMac": "iMac_2560_1440",
    "framer/FramerAppleThunderboltDisplay": null, // old
    "framer/FramerAppleMacBook": null, // discontinueed
    "framer/FramerAppleMacBookAir": "MacBook_1440_900",
    "framer/FramerAppleMacBookPro": "MacBook_1440_900_pro",
    "framer/FramerDellXPS": "DellXPS_1920_1080",
    "framer/FramerMicrosoftSurfaceBook": "SurfaceBook_1500_1000",
    "framer/FramerSonyW850C": "SonyTV_1280_720",
    "framer/FramerStoreArtwork": "PackageArtwork_800_600",
    "framer/FramerStoreIcon": "PackageIcon_50_50",
}

function toFramePreset(device: DeprecatedDeviceID): V64.FramePresetID | null {
    return deviceToFramePresetMigration[device] || null
}
