import { ControlType } from "framer"
import { addActionControls } from "./actionControls"

export interface TriggerEventOptions {
    id: string
}

// This is a no-op hook that is implemented in code generation
// The only purpose of this hook is to become available in the actions UI
export function useTriggerEvent(_: TriggerEventOptions) {
    return () => {}
}

addActionControls(useTriggerEvent, "Event", {
    id: { type: ControlType.String },
})
