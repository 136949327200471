import * as React from "react"
import { Size } from "../../render/types/Size"
import ResizeObserver from "resize-observer-polyfill"

const DEFAULT_SIZE = 200

type ObserverCallback = (size: Size) => void
class SharedObserver {
    #sharedResizeObserver
    #callbacks = new WeakMap<Element, ObserverCallback>()

    constructor() {
        this.#sharedResizeObserver = new ResizeObserver(this.updateResizedElements.bind(this))
    }

    private updateResizedElements(entries: ResizeObserverEntry[]) {
        for (const entry of entries) {
            const callbackForElement = this.#callbacks.get(entry.target)
            if (callbackForElement) callbackForElement(entry.contentRect)
        }
    }

    observeElementWithCallback(element: HTMLElement, callback: ObserverCallback) {
        this.#sharedResizeObserver.observe(element)
        this.#callbacks.set(element, callback)
    }

    unobserve(element: HTMLElement) {
        this.#sharedResizeObserver.unobserve(element)
        this.#callbacks.delete(element)
    }
}

const sharedResizeObserver = new SharedObserver()

/**
 * Uses a globally shared resize observer, and returns an updated
 * size object when the element's size changes. This is the recommended way to
 * use a Resize Observer: https://github.com/WICG/resize-observer/issues/59.
 */
function useMeasuredSize(ref: React.MutableRefObject<HTMLDivElement | null>) {
    const [size, setSize] = React.useState<Size | null>(null)

    function updateSize(newSize: Size) {
        if (!size || newSize.height !== size.height || newSize.width !== size.width) {
            setSize({ width: newSize.width, height: newSize.height })
        }
    }

    // On mount, immediately measure and set a size. This will defer paint until
    // no more updates are scheduled. Additionally add our element to the shared
    // ResizeObserver with a callback to perform when the element resizes.
    // Finally, remove the element from the observer when the component is unmounted.
    React.useLayoutEffect(() => {
        if (!ref.current) return
        const { offsetWidth, offsetHeight } = ref.current

        // Defer paint until initial size is added.
        updateSize({
            width: offsetWidth,
            height: offsetHeight,
        })

        // Resize observer will race to add the initial size, but since the size
        // is set above, it won't trigger a render on mount since it should
        // match the measured size. Future executions of the callback will
        // trigger renders if the size changes.
        sharedResizeObserver.observeElementWithCallback(ref.current, updateSize)

        return () => {
            if (!ref.current) return
            sharedResizeObserver.unobserve(ref.current)
        }
    }, [])

    return size
}

/**
 * A HoC to enhance code components that depend on being rendered with exact
 * width and height props with width and height props determined via a shared
 * ResizeObserver.
 *
 * @FIXME Do not depend on this HoC. The current plan is to turn it into a no-op
 * after a deprecation period. If we need to provide this functionality to
 * customers after we migrate to a modules-first ecosystem, then we can provide
 * a new copy of this HoC or the `useMeasuredSize` hook, and recommend use
 * without a module version, allowing everyone to share the same ResizeObserver
 * on a single canvas.
 *
 * @internal
 */
export const withMeasuredSize = <T extends object>(Component: React.ComponentType<T>) => (props: T) => {
    const ref = React.useRef<HTMLDivElement>(null)
    const size = useMeasuredSize(ref)

    return (
        <div style={{ width: "100%", height: "100%" }} ref={ref}>
            <Component {...props} width={size?.width ?? DEFAULT_SIZE} height={size?.height ?? DEFAULT_SIZE} />
        </div>
    )
}
