import { css } from "linaria"
import { createCSSVariableValues, TokenCategory } from "../tokens/utils/variables"
import { shadowTokensDark, shadowTokensLight } from "../tokens/shadows"
import { colorsLight } from "../tokens/colors/colorsLight"
import { colorsScope } from "../tokens/colors/colorsScope"
import {
    colorsDark,
    popoverColorsDark,
    dashboardColorsDark,
    prototypeViewColorsDark,
} from "../tokens/colors/colorsDark"

export const colorTokensLight: TokenCategory = { tokens: colorsLight, scope: colorsScope }
export const colorTokensDark: TokenCategory = { tokens: colorsDark, scope: colorsScope }
export const popoverColorTokensDark: TokenCategory = { tokens: popoverColorsDark, scope: colorsScope }
export const dashboardColorTokensDark: TokenCategory = { tokens: dashboardColorsDark, scope: colorsScope }
export const prototypeViewColorTokensDark: TokenCategory = { tokens: prototypeViewColorsDark, scope: colorsScope }

const lightCSSVariables = createCSSVariableValues([colorTokensLight, shadowTokensLight])
const darkCSSVariables = createCSSVariableValues([colorTokensDark, shadowTokensDark])

export const themeCSSVariables = css`
    :global() {
        body {
            ${lightCSSVariables}
        }

        body.dark {
            ${darkCSSVariables}
        }
    }
`
