import type { PathBooleanType } from "document/models/CanvasTree/traits/PathBoolean"
import type { ShapeContainerNode, VectorNode } from "document/models/CanvasTree"
import { PaperjsPathOperator } from "utils/paperjs/paperjsPathOperator"
import type { WithColorable } from "document/models/CanvasTree/traits/Colorable"
import type { Size } from "framer"
import type { WithPaths } from "framer"
import type { WithFill } from "document/models/CanvasTree/traits/Fill"

/**
 * PathOperator is an interface that declares a number of operations that
 * involve paths, that we currently delegate to the Paperjs library.
 *
 * The PaperjsPathOperator class holds the actual implementations, but the
 * idea is that we can easily replace it with another implementation at
 * a later time, at this level - for no Paperjs specifics bleed into our
 * 'internal' classes here.
 */

export interface PathOperator {
    importSvg(svg: string, size?: Size, fill?: WithFill & WithColorable): ShapeContainerNode | null
    containsPoint(node: VectorNode, point: { x: number; y: number }): boolean
    booleanJoin(paths: WithPaths[], type: PathBooleanType): WithPaths
}

let instance: PathOperator

export function getPathOperator() {
    if (!instance) {
        instance = new PaperjsPathOperator()
    }
    return instance
}
