import { css } from "linaria"
import { colors, dimensions } from "../tokens"

const resizeIconLight = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='9' height='9'><path d='M 1.5 7.5 L 7.5 1.5' fill='transparent' stroke-width='1.5' stroke='%23CCCCCC' stroke-linecap='round'></path><path d='M 5.5 7.5 L 7.5 5.5' fill='transparent' stroke-width='1.5' stroke='%23CCCCCC' stroke-linecap='round'></path></svg>")`
const resizeIconDark = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='9' height='9'><path d='M 1.5 7.5 L 7.5 1.5' fill='transparent' stroke-width='1.5' stroke='%23555555' stroke-linecap='round'></path><path d='M 5.5 7.5 L 7.5 5.5' fill='transparent' stroke-width='1.5' stroke='%23555555' stroke-linecap='round'></path></svg>")`

export const textArea = css`
    min-height: ${dimensions.inputHeight}px;
    padding: ${dimensions.textAreaPaddingVertical}px ${dimensions.inputPaddingHorizontal}px
        ${dimensions.textAreaPaddingVertical + 1}px;
    resize: vertical;

    &::-webkit-scrollbar {
        width: 12px;
        background: transparent;
        cursor: pointer;
    }

    &::-webkit-scrollbar-thumb {
        border: 4px solid transparent;
        background-clip: content-box;
        background-color: ${colors.textAreaScrollbar};
        border-radius: 10px;
    }

    &::-webkit-scrollbar-corner {
        background: none;
        background-color: transparent;
        outline: none;
    }

    &::-webkit-resizer {
        border: 1px solid transparent;
        background: no-repeat ${resizeIconLight};
    }

    &::-webkit-scrollbar-thumb:window-inactive {
        opacity: 0;
    }

    .dark &::-webkit-resizer {
        border: 1px solid transparent;
        background: no-repeat ${resizeIconDark};
    }
`
