import type { WithPosition } from "document/models/CanvasTree/traits/Position"
import type { WithSize } from "document/models/CanvasTree/traits/Size"
import type { WithRotation } from "document/models/CanvasTree/traits/Rotation"
import type { WithRect } from "document/models/CanvasTree/traits/Frame"
import type { Rect, Size } from "framer"
import type { ConstraintValues } from "framer"
import type { CanvasNode } from "../.."

export function withFrameForShape<
    T extends new (...args: any[]) => CanvasNode & WithPosition & WithSize & WithRotation
>(Base: T) {
    return class extends Base implements WithRect {
        rect(_parentSize: Size | null) {
            return {
                x: this.x,
                y: this.y,
                width: this.width,
                height: this.height,
            }
        }

        matrix(_parentSize?: Size | null, frame?: Rect) {
            const point = { x: this.x, y: this.y }
            if (frame) {
                point.x = frame.x
                point.y = frame.y
            }
            return new DOMMatrix().translateSelf(point.x, point.y).rotateSelf(0, 0, this.resolveValue("rotation") ?? 0)
        }

        transformMatrix(parentSize: Size | null, frame?: Rect) {
            const size = { width: this.width, height: this.height }
            if (frame) {
                size.width = frame.width
                size.height = frame.height
            }
            return new DOMMatrix()
                .translateSelf(0.5 * size.width, 0.5 * size.height)
                .multiplySelf(this.matrix(parentSize, frame))
                .translateSelf(-0.5 * size.width, -0.5 * size.height)
        }

        isRotated() {
            return (this.resolveValue("rotation") ?? 0) % 360 !== 0
        }

        updateForRect(frame: Rect, _parentSize: Size | null) {
            return frame
        }

        updateForSize(size: Partial<Size>, _parentSize: Size | null): Partial<ConstraintValues> {
            return size
        }
    }
}
