import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springAnimation, springAnimationLeft } from "./utils"
import { colors } from "../tokens"

const width = 50
const height = 50
const size = 5

const row: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: width,
    height: size,
    background: "none",
    marginBottom: 6,
}

const dot: React.CSSProperties = {
    width: size,
    height: size,
    background: colors.animationForground,
    borderRadius: 4,
}

const data: React.CSSProperties = {
    width: 20,
    height: size,
    background: colors.animationForground,
    borderRadius: 4,
    marginLeft: 5,
}

export const AnimationAssets = React.memo(function () {
    const dot1 = useAnimation()
    const dot2 = useAnimation()
    const dot3 = useAnimation()

    const row1 = useAnimation()
    const row2 = useAnimation()
    const row3 = useAnimation()

    async function animation() {
        await Promise.all([
            dot1.start({
                opacity: 0,
                scale: 0,
                transition: { type: false },
            }),
            dot2.start({
                opacity: 0,
                scale: 0,
                transition: { type: false },
            }),
            dot3.start({
                opacity: 0,
                scale: 0,
                transition: { type: false },
            }),
            row1.start({
                opacity: 0,
                scaleX: 0,
                originX: 0,
                transition: { type: false },
            }),
            row2.start({
                opacity: 0,
                scaleX: 0,
                originX: 0,
                transition: { type: false },
            }),
            row3.start({
                opacity: 0,
                scaleX: 0,
                originX: 0,
                transition: { type: false },
            }),
        ])
        await Promise.all([
            dot1.start({
                opacity: 1,
                scale: 1,
                transition: springAnimation,
            }),
            row1.start({
                opacity: 1,
                scaleX: 1,
                transition: springAnimationLeft,
            }),
        ])
        await Promise.all([
            dot2.start({
                opacity: 1,
                scale: 1,
                transition: springAnimation,
            }),
            row2.start({
                opacity: 1,
                scaleX: 1,
                transition: springAnimationLeft,
            }),
        ])
        await Promise.all([
            dot3.start({
                opacity: 1,
                scale: 1,
                transition: springAnimation,
            }),
            row3.start({
                opacity: 1,
                scaleX: 1,
                transition: springAnimationLeft,
            }),
        ])
        return await Promise.all([
            dot1.start({
                opacity: 0,
                transition: springAnimation,
            }),
            dot2.start({
                opacity: 0,
                transition: springAnimation,
            }),
            dot3.start({
                opacity: 0,
                transition: springAnimation,
            }),
            row1.start({
                opacity: 0,
                transition: springAnimation,
            }),
            row2.start({
                opacity: 0,
                transition: springAnimation,
            }),
            row3.start({
                opacity: 0,
                transition: springAnimation,
            }),
        ])
    }

    useLoop(animation)

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                overflow: "hidden",
                position: "relative",
                width: width,
                height: height,
                borderRadius: size,
                background: colors.animationBackgroundLight,
                paddingTop: 6,
            }}
        >
            <div style={row}>
                <motion.div animate={dot1} style={dot} />
                <motion.div animate={row1} style={data} />
            </div>
            <div style={row}>
                <motion.div animate={dot2} style={dot} />
                <motion.div animate={row2} style={data} />
            </div>
            <div style={row}>
                <motion.div animate={dot3} style={dot} />
                <motion.div animate={row3} style={data} />
            </div>
        </div>
    )
})
