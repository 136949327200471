import { V56, V57 } from "../types"
import { transform } from "../utils/transform"
import { _, exactCheck } from "../utils/exactCheck"
import { withoutProps } from "../utils/withoutProps"
import {
    UpdateOverrides,
    transformReplicaOverrides,
    ReplicaFromNode,
    GetInheritedPropValue,
} from "../utils/transformReplicaOverrides"

export function migration_56_57(documentJSON: V56.Tree): V57.Tree {
    const migratedRoot = transform(documentJSON.root, (node, idToNode) => {
        switch (node.__class) {
            case "FrameNode":
            case "ShapeContainerNode": {
                const onTap = navigationToOnTap(node)
                const migratedNode = withoutProps(
                    { ...node, ...onTap },
                    "navigationTarget",
                    "navigationTransition",
                    "navigationTransitionDirection",
                    "navigationTransitionBackdropColor"
                )
                if (migratedNode.__class === "FrameNode") {
                    transformReplicaOverrides(migratedNode, idToNode, replicaMigration)
                }
                return exactCheck(migratedNode, _ as V57.FrameNode | V57.ShapeContainerNode)
            }
            default:
                return exactCheck(node, _ as V57.TreeNode)
        }
    })
    return { version: 57, root: migratedRoot }
}

type FrameReplica57 = ReplicaFromNode<V57.FrameNode>
type ShapeContainerNodeReplica57 = ReplicaFromNode<V57.ShapeContainerNode>
type FrameOrShapeContainerReplica57 = FrameReplica57 | ShapeContainerNodeReplica57

type NavigationKey =
    | "navigationTarget"
    | "navigationTransition"
    | "navigationTransitionDirection"
    | "navigationTransitionBackdropColor"

type NavigationControls = Pick<V56.FrameNode, NavigationKey>

function replicaMigration(
    originalNode: V56.TreeNode,
    getInheritedValue: GetInheritedPropValue,
    updateOverrides: UpdateOverrides
) {
    switch (originalNode.__class) {
        case "FrameNode":
        case "ShapeContainerNode": {
            updateOverrides(originalNode, _ as FrameOrShapeContainerReplica57, overrides => {
                const {
                    navigationTarget,
                    navigationTransition,
                    navigationTransitionBackdropColor,
                    navigationTransitionDirection,
                    _deleted,
                    ...keep
                } = overrides

                const result: FrameOrShapeContainerReplica57 = keep
                const deleted = (_deleted || []) as NonNullable<FrameOrShapeContainerReplica57["_deleted"]>

                // If there is an override for any of the properties…
                if (
                    navigationTarget !== undefined ||
                    navigationTransition ||
                    navigationTransitionBackdropColor ||
                    navigationTransitionDirection
                ) {
                    const originalNavigationControls: NavigationControls = {
                        navigationTarget: getInheritedValue(originalNode, "navigationTarget"),
                        navigationTransition: getInheritedValue(originalNode, "navigationTransition"),
                        navigationTransitionDirection: getInheritedValue(originalNode, "navigationTransitionDirection"),
                        navigationTransitionBackdropColor: getInheritedValue(
                            originalNode,
                            "navigationTransitionBackdropColor"
                        ),
                    }
                    const originalOnTap = navigationToOnTap(originalNavigationControls)

                    const newNavigationControls = {
                        ...originalNavigationControls,
                        // use overrides instead of the navigation keys explicitly to avoid overriding the original values with undefined
                        ...overrides,
                    } as NavigationControls

                    const onTap = navigationToOnTap(newNavigationControls)
                    if (onTap) {
                        result.onTap = onTap.onTap
                    } else if (originalOnTap && !deleted.includes("onTap")) {
                        deleted.push("onTap")
                    }
                }

                if (deleted.length) result._deleted = deleted
                return result
            })
            break
        }
    }
}

/**
 * WARNING: Don’t use this is an example of a great migration. This structure uses
 * types coming from Library, Runtime and Vekter all mixed together, which
 * makes it super hard to import them (without creating a cyclic web of
 * imports).
 *
 * One solution is to extract all of the types (and model default values, etc)
 * in another “source of truth”, but that was out of scope during the creation
 * of this migration, because it would be a big overhaul (e.g., it would need
 * to support versioning for those types).
 */
function navigationToOnTap(navigationControls: NavigationControls): { onTap: V57.EventAction[] } | undefined {
    if (!navigationControls.navigationTarget) {
        return
    }
    const eventAction: V57.EventAction = {
        identifier: "action-migrate-1",
        actionIdentifier: "framer/useNavigate",
        // Default values, copied from `navigationActions.ts`
        controls: {
            type: { type: "segmentedenum", value: "next" },
            target: { type: "componentinstance", value: "" },
            transition: { type: "enum", value: "magicMotion" },
            appearsFrom: { type: "segmentedenum", value: "right" },
            backdropColor: { type: "color", value: "rgba(4,4,15,.4)" },
            animation: {
                type: "transition",
                value: {
                    type: "spring",
                    ease: [0.44, 0, 0.56, 1],
                    duration: 0.3,
                    delay: 0,
                    stiffness: 500,
                    damping: 60,
                    mass: 1,
                },
            },
        },
    }

    if (navigationControls.navigationTarget === "@Previous") {
        eventAction.controls.type.value = "previous"
    } else {
        eventAction.controls.target.value = navigationControls.navigationTarget
    }
    eventAction.controls.transition.value = navigationControls.navigationTransition
    eventAction.controls.appearsFrom.value = transitionDirectionToAppearsFrom(
        navigationControls.navigationTransitionDirection
    )
    eventAction.controls.backdropColor.value = navigationControls.navigationTransitionBackdropColor

    return { onTap: [eventAction] }
}

// Copied and adjusted from navigationActions.ts transitionDirectionToActionAppearsFrom
function transitionDirectionToAppearsFrom(direction: string): string {
    switch (direction) {
        case "right":
            return "left"
        case "up":
            return "bottom"
        case "down":
            return "top"
        case "left":
        default:
            return "right"
    }
}
