import { getLogger } from "@framerjs/shared"
import {
    EntityIdentifier,
    PackageIdentifier,
    DesignComponentDefinition,
    EntityDefinition,
    EntityMap,
    ErrorDefinition,
    HostPackageMap,
    ReactComponentDefinition,
    TokenMap,
    isDesignDefinition,
    isErrorDefinition,
    isReactComponentDefinition,
} from "./types"
import { localPackageFallbackIdentifier } from "../../sandbox/componentLoader/localPackageFallbackIdentifier"
import { extractFileNameFromIdentifier } from "../../sandbox/componentLoader/extractFileNameFromIdentifier"
import { createErrorDefinition } from "./createErrorDefinition"
import { defaultComponentDefinitions } from "./defaultComponentDefinitions"

const log = getLogger("componentloader")

interface HostEntityIdsByPackageIdMap {
    [packageIdentifier: string]: EntityIdentifier[]
}
interface HostTokensByPackageIdMap {
    [packageId: string]: TokenMap
}

export interface ComponentLoaderState {
    readonly localPackageIdentifier: string
    // All the packages referenced by the local package and any dependency under it.
    readonly packages: HostPackageMap
    // A lookup table of all the entities from all the packages
    readonly entities: EntityMap
    // Various optimized data structures to avoid lots of repeated computation
    readonly entityIdsByPackage: HostEntityIdsByPackageIdMap
    readonly visibleComponentIdentifiers: EntityIdentifier[]
    readonly visiblePackageNames: PackageIdentifier[]
    readonly visiblePackageFileNames: { [identifier: string]: string[] }
    readonly tokensByPackage: HostTokensByPackageIdMap
}

export interface ComponentLoaderInterface {
    componentForIdentifier(identifier: EntityIdentifier): EntityDefinition | null
    reactComponentForIdentifier(identifier: EntityIdentifier): ReactComponentDefinition | null
    componentsForPackage(packageIdentifier: PackageIdentifier): EntityDefinition[] | undefined
    componentIdentifiers(): EntityIdentifier[]
    errorForIdentifier(identifier: EntityIdentifier): ErrorDefinition | null
    forEachComponent(callback: (component: EntityDefinition) => boolean | void): void
    forEachDesignComponent(callback: (component: DesignComponentDefinition) => boolean | void): void
    localPackageIdentifier(): string
    packageDisplayName(packageIdentifier: PackageIdentifier): string | undefined
    packageIdentifiers(): PackageIdentifier[]
    packageFileNames(packageIdentifier: PackageIdentifier): string[]
    // only returns tokens from the dependency packages, doesn't include "local" ones
    tokensForPackage(packageIdentifier: PackageIdentifier): TokenMap
    isLocalComponentDefinition(component: EntityDefinition): boolean
}

class ComponentLoader implements ComponentLoaderInterface {
    private state: ComponentLoaderState = {
        localPackageIdentifier: localPackageFallbackIdentifier,
        packages: {},
        entities: defaultComponentDefinitions,
        entityIdsByPackage: {},
        visibleComponentIdentifiers: [],
        visiblePackageNames: [],
        visiblePackageFileNames: {},
        tokensByPackage: {},
    }

    setState(state: ComponentLoaderState) {
        log.trace("setState:", state)
        this.state = state
    }

    componentForIdentifier(identifier: EntityIdentifier): EntityDefinition | null {
        return this.state.entities[identifier] || null
    }

    reactComponentForIdentifier(identifier: EntityIdentifier): ReactComponentDefinition | null {
        const definition = this.componentForIdentifier(identifier)
        if (!definition || !isReactComponentDefinition(definition)) return null
        return definition
    }

    componentsForPackage(packageIdentifier: PackageIdentifier): EntityDefinition[] | undefined {
        const entityIds = this.state.entityIdsByPackage[packageIdentifier] || []
        return entityIds.map(entityId => this.state.entities[entityId])
    }

    // TODO: Allow more specific filtering across the entire tree of dependencies.
    componentIdentifiers(): EntityIdentifier[] {
        return this.state.visibleComponentIdentifiers
    }

    errorForIdentifier(identifier: EntityIdentifier): ErrorDefinition | null {
        const { entities, visiblePackageFileNames } = this.state
        // All errors will be stored under the filename, not the component's identifier.
        const file = extractFileNameFromIdentifier(identifier)
        let errorDefinition: ErrorDefinition | null = null

        // We only check once we have visiblePackageFileNames as this means
        // the components are loaded. Otherwise we return null which the
        // renderer takes to mean "loading".
        if (Object.entries(visiblePackageFileNames).length) {
            let packageFileExists = false

            for (const packageIdentifier in visiblePackageFileNames) {
                if (visiblePackageFileNames[packageIdentifier].includes(file)) {
                    // Check for the component && verify that the file hasn't errored.
                    if (entities[identifier] === undefined && !isErrorDefinition(entities[file])) {
                        // The file exists but the component doesn't, return that as an error
                        errorDefinition = createErrorDefinition({
                            identifier,
                            file,
                            error: "Component cannot be found.",
                        })
                    }
                    packageFileExists = true
                    break
                }
            }

            if (!packageFileExists) {
                // The file doesn't exist, return that as error.
                // NOTE: In the current setup, it is not possible that the component is still
                // loading but if that is the case that will need to be detected here.
                // TODO: Show a more user friendly error if component class changes name.
                errorDefinition = createErrorDefinition({
                    identifier,
                    file,
                    error: "Component file does not exist.",
                    custom: { fileDoesNotExist: true },
                })
            }
            if (errorDefinition) return errorDefinition
        }

        const definition = entities[file]
        if (!isErrorDefinition(definition)) {
            // This is a valid component definition.
            return null
        }
        return definition
    }

    forEachComponent(callback: (component: EntityDefinition) => boolean | void): void {
        for (const identifier of this.state.visibleComponentIdentifiers) {
            const entity = this.state.entities[identifier]
            if (isErrorDefinition(entity)) continue
            const abort = callback(entity)
            if (abort) break
        }
    }

    forEachDesignComponent(callback: (component: DesignComponentDefinition) => boolean | void): void {
        this.forEachComponent((entity: EntityDefinition) => {
            if (isDesignDefinition(entity)) return callback(entity)
        })
    }

    localPackageIdentifier(): string {
        return this.state.localPackageIdentifier
    }

    packageDisplayName(packageIdentifier: PackageIdentifier): string | undefined {
        if (packageIdentifier === "framer") return "Framer"
        const packageInfo = this.state.packages[packageIdentifier]
        return packageInfo && packageInfo.displayName
    }

    // TODO: This is currently a list of package names, not identifiers.
    packageIdentifiers(): PackageIdentifier[] {
        return this.state.visiblePackageNames
    }

    packageFileNames(packageIdentifier: PackageIdentifier): string[] {
        return this.state.visiblePackageFileNames[packageIdentifier]
    }

    tokensForPackage(packageIdentifier: PackageIdentifier): TokenMap {
        return this.state.tokensByPackage[packageIdentifier]
    }

    isLocalComponentDefinition(component: EntityDefinition): boolean {
        return component.packageIdentifier === this.localPackageIdentifier() && component.identifier.startsWith("./")
    }
}

export const componentLoader = new ComponentLoader()
