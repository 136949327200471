import * as React from "react"
import { cx } from "linaria"
import type { OverrideType, HTMLDivAttributes } from "./types"
import * as styles from "./CheckBox.styles"

export type CheckboxProps = OverrideType<
    HTMLDivAttributes,
    {
        /** State of the checkbox. When set to null the checkbox displays as indeterminate. */
        value: boolean | null
        /** The event handler receives the checked state. */
        onChange: (checked: boolean) => void
        /** Allows disabling the entire control. */
        enabled?: boolean
        /** Increases the checkbox size. */
        large?: boolean
    }
>

export const CheckBox = React.memo(function CheckBox(props: CheckboxProps) {
    const { value, className, enabled = true, onChange, id, large = false, ...rest } = props

    const checkboxRef = React.useRef<HTMLInputElement>(null)

    const isChecked = value === true
    const isIndeterminate = value === null

    const onChangeHandler = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!enabled) return
            const target = event.target as HTMLInputElement
            onChange(target.checked)
        },
        [onChange, enabled]
    )

    React.useEffect(() => {
        const inputElement = checkboxRef.current
        if (!inputElement) return
        inputElement.indeterminate = isIndeterminate
    }, [isIndeterminate])

    return (
        <div
            draggable={false}
            className={cx(
                className,
                styles.checkboxWrapper,
                large && styles.checkboxWrapperLarge,
                (isChecked || isIndeterminate) && styles.checkedOrIndeterminate,
                !enabled && styles.checkboxDisabled
            )}
            {...rest}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width={large ? 16 : 12} height={large ? 16 : 12}>
                <path
                    d={large ? "M 4 7.833 L 6.667 10.333 L 12 5.333" : "M3 6l2 2 4-4"}
                    fill="transparent"
                    strokeWidth={large ? 2 : 1.5}
                    stroke="#FFF"
                    strokeLinecap="round"
                    strokeDasharray={large ? 11 : 8.5}
                    strokeDashoffset={isChecked && !isIndeterminate ? 0 : large ? 11 : 8.5}
                    className={cx(styles.path, isChecked && styles.pathChecked)}
                />
                <path
                    d={large ? "M 5 8 L 11 8" : "M3 6l6 0"}
                    fill="transparent"
                    strokeWidth={large ? 2 : 1.5}
                    stroke="#FFF"
                    strokeLinecap="round"
                    strokeDasharray={6}
                    strokeDashoffset={isIndeterminate ? 0 : 6}
                    className={styles.path}
                />
            </svg>
            <input
                ref={checkboxRef}
                id={id}
                type="checkbox"
                checked={isChecked}
                disabled={!enabled}
                onChange={onChangeHandler}
                tabIndex={-1}
                className={styles.input}
            />
        </div>
    )
})
