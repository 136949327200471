import type { CanvasNode } from "document/models/CanvasTree"
import type { WithFilters } from "document/models/CanvasTree/traits/Filters"
import type { FilterProperties } from "framer"
import { shouldRenderShadowUsingFilter } from "document/models/CanvasTree/traits/utils/wantsFilterShadow"
import { hasBoxShadow } from "../BoxShadow"
import { withShadow } from "../Shadow"
import { getFilterBlurForBoxBlur } from "./getFilterBlurForBoxBlur"

export function collectFilterPropsForNode(node: CanvasNode & WithFilters, props: Partial<FilterProperties>) {
    props.blur = node.resolveValue("blur")
    props.brightness = node.resolveValue("brightness")
    props.contrast = node.resolveValue("contrast")
    props.grayscale = node.resolveValue("grayscale")
    props.hueRotate = node.resolveValue("hueRotate")
    props.invert = node.resolveValue("invert")
    props.saturate = node.resolveValue("saturate")
    props.sepia = node.resolveValue("sepia")

    if (shouldRenderShadowUsingFilter(node)) {
        if (hasBoxShadow(node) && node.boxShadows) {
            props.dropShadows = node.boxShadows
                .filter(shadow => !shadow.inset)
                .map(shadow => shadow.updatedBoxShadow({ blur: getFilterBlurForBoxBlur(shadow.blur) }))
        } else if (withShadow(node) && node.shadows) {
            props.dropShadows = node.shadows
        }
    }
}
