import { nameDefaults } from "document/models/CanvasTree/traits/Name"
import { sizeDefaults } from "document/models/CanvasTree/traits/Size"
import { pinDefaults } from "document/models/CanvasTree/traits/Pins"
import { rotationDefaults } from "document/models/CanvasTree/traits/Rotation"
import { visibilityDefaults } from "document/models/CanvasTree/traits/Visibility"
import { lockDefaults } from "document/models/CanvasTree/traits/Lock"
import { withExportDefaults } from "document/models/CanvasTree/traits/Export"
import { autoSizeDefaults } from "document/models/CanvasTree/traits/AutoSize"
import { textVerticalAlignmentDefaults } from "document/models/CanvasTree/traits/TextVerticalAlignment"
import { opacityDefaults } from "document/models/CanvasTree/traits/Opacity"
import { styledTextDefaults } from "document/models/CanvasTree/traits/StyledText"
import { shadowDefaults } from "document/models/CanvasTree/traits/Shadow"
import { filterDefaults } from "document/models/CanvasTree/traits/Filters"
import { targetDefaults } from "document/models/CanvasTree/traits/Target"
import { codeOverrideDefaults } from "document/models/CanvasTree/traits/CodeOverride"
import { clipDefaults } from "../traits/Clip"
import { memoRecord } from "./memoRecord"

export const getTextRecord = memoRecord(() => {
    return {
        ...nameDefaults,
        ...pinDefaults,
        ...sizeDefaults,
        ...rotationDefaults,
        ...opacityDefaults,
        ...shadowDefaults,
        ...styledTextDefaults,
        ...textVerticalAlignmentDefaults,
        ...autoSizeDefaults,
        ...visibilityDefaults,
        ...lockDefaults,
        ...clipDefaults,
        ...filterDefaults,
        ...withExportDefaults,
        ...targetDefaults,
        ...codeOverrideDefaults,
    }
})
