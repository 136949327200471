import type { CanvasNode } from "../../nodes/CanvasNode"
import { withClip } from "document/models/CanvasTree/traits/Clip"
import { MotionStyle } from "framer-motion"
import { isUndefined } from "utils/typeChecks"
import { isTextNode } from "../../nodes/TextNode"

export function collectOverflow(node: CanvasNode, style: MotionStyle) {
    if (!withClip(node) || isUndefined(node.clip)) return

    const isAutoSizingTextNode = isTextNode(node) && node.autoSize
    style.overflow = node.clip && !isAutoSizingTextNode ? "hidden" : "visible"
}
