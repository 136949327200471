import assert from "assert"
import type { OrderedMap } from "immutable"
import type { Rect, Size, ConstraintValues, LayerProps } from "framer"
import type { CanvasTree } from "../CanvasTree"
import { isEqual } from "framer"
import { MutableNode } from "./MutableNode"
import { stringFromNodeID } from "./NodeID"
import { CanvasNodeCache } from "./CanvasNodeCache"
import { TemplateHelper, ignoredOverrideKeys } from "./TemplateHelper"
import { isMaster, isReplica, IsReplica } from "../traits/Template"
import type { ReplicaInfo } from "document/models/CanvasTree/traits/Template"
import { withClassDiscriminator } from "utils/withClassDiscriminator"
import { isVariableReference } from "../traits/VariableReference"
import type { VariableReference } from "../traits/VariableReference"
import { createInlineVariable } from "variants/utils/inlineValues"
import type { WithVariableSupport } from "../traits/Variables"

export interface CanvasNode {
    tree(): CanvasTree
    walk(cb: (n: CanvasNode) => void): void
    /** Returning false will prevent walking the children of the current node. */
    walkWithPredicate(cb: (n: CanvasNode) => boolean): void
    find(cb: (n: CanvasNode) => boolean): CanvasNode | null
    ancestors(): IterableIterator<CanvasNode>
}

export class CanvasNode extends withClassDiscriminator("CanvasNode", MutableNode) implements WithVariableSupport {
    children?: CanvasNode[]

    /** @deprecated Use node.isVisible() instead, if possible. */
    visible: boolean | VariableReference = false
    name: string | null = null
    cache: CanvasNodeCache

    isVisible() {
        return this.resolveValue("visible") !== false
    }

    constructor(properties?: Record<string, unknown>, cache: CanvasNodeCache = new CanvasNodeCache()) {
        super(properties, cache)
    }

    // all mutations to nodes should go through here, so master/replica's can capture changes
    set(properties: { [key: string]: unknown }, tree?: CanvasTree): this {
        if (this.originalid) {
            if (!this.cache.templateProperties) {
                this.cache.templateProperties = {}
            }
            const nodePropertyKeys = Object.keys(properties)
            for (const nodePropertyKey of nodePropertyKeys) {
                if (ignoredOverrideKeys[nodePropertyKey]) continue
                const newValue = properties[nodePropertyKey]
                // Only override symbol properties when values are different
                if (isEqual(this[nodePropertyKey], newValue)) continue
                this.cache.templateProperties[nodePropertyKey] = newValue
            }
        }
        const future = this.asMutable(tree)
        future.verifyPropertiesToSet(properties)
        Object.assign(future, properties)
        return future
    }

    // some mutation should sidestep master/replica mechanisms, they can use this
    setIgnoringReplica(properties: { [key: string]: unknown }, tree?: CanvasTree): this {
        const future = this.asMutable(tree)
        future.verifyPropertiesToSet(properties)
        Object.assign(future, properties)
        return future
    }

    preCommit(tree: CanvasTree) {
        assert(this === this.cache.future, "Pre-commit node must be its future")
        if (isReplica(this)) {
            TemplateHelper.replicaWithChanges(tree, this)
        }
        /**
         * All of this master's replicas need to be rebuilt when the node changes,
         * and some of this replica's replicas need to be rebuilt if they inherit overrides from this replica.
         */
        if (isMaster(this) || isReplica(this)) {
            const replicaInstances = this.cache.replicaInstances!
            if (replicaInstances) {
                for (let i = 0, il = replicaInstances.length; i < il; i++) {
                    const replica = tree.getFuture(replicaInstances[i]) as (CanvasNode & IsReplica) | null
                    if (!replica || !replica.replicaInfo) continue // deleted or undo can cause this
                    isMaster(this)
                        ? assert(replica.replicaInfo.master === this.id, "A replica cannot be it's own master")
                        : assert(replica.replicaInfo.inheritsFrom === this.id, "A replica cannot inherit from itself")

                    assert(replica.originalid, "Replica does not have original id")
                    // Add the template to the future list
                    replica.asMutable(tree)
                }
            }
        }
        const links = this.cache.links
        if (links) {
            links.forEach(id => {
                const node = tree.getFuture(id)
                if (node) node.asMutable()
            })
        }
    }

    supportsVariables() {
        return true
    }

    /** Omits variable references and tries to lookup their value instead. */
    resolveValue<K extends keyof this>(key: K): Exclude<this[K], VariableReference> | undefined
    resolveValue<K extends keyof this>(
        key: K,
        withInlineVariables: boolean
    ): Exclude<this[K], VariableReference> | string | undefined
    resolveValue<K extends keyof this>(key: K, withInlineVariables: boolean = false) {
        const value = this[key]
        if (isVariableReference(value)) {
            if (withInlineVariables) {
                return this.cache.hasVariable(value.id) ? createInlineVariable(value.id) : undefined
            }
            return this.cache.getVariableValue(value.id) as Exclude<this[K], VariableReference>
        } else {
            return value as Exclude<this[K], VariableReference>
        }
    }

    rect(_parentSize: Size | null = null, _pixelAlign = true): Rect {
        return { x: 0, y: 0, width: 0, height: 0 }
    }

    updateForRect(_frame: Rect, _parentSize: Size | null, _constraintsLocked: boolean): any {
        return { x: 0, y: 0, width: 0, height: 0 }
    }

    updateForSize(_size: Partial<Size>, _parentSize: Size | null): Partial<ConstraintValues> {
        return { left: 0, top: 0, width: 0, height: 0 }
    }

    transformMatrix(_parentSize: Size | null, _rect?: Rect): DOMMatrix {
        return new DOMMatrix()
    }

    isEmptyGroup() {
        return false
    }

    getProps(_cachePath?: string[]): LayerProps {
        return {
            key: this.id,
            id: stringFromNodeID(this.id),
            duplicatedFrom: this.duplicatedFrom ? this.duplicatedFrom.map(stringFromNodeID) : undefined,
            willChangeTransform: false,
            _canMagicMotion: true,
        }
    }

    canvasMatrix() {
        const matrix = this.cache.matrix
        if (!matrix) {
            throw Error("shape matrix requested but does not exist")
        }
        return matrix
    }

    providesFreeSpace() {
        return false
    }

    hasAutoSize() {
        return false
    }

    isSelected(): boolean {
        return this.cache.selected
    }

    // It's unsafe because the node might not be in the tree
    // TODO: this function should be removed when we add canvas sandboxing
    __unsafeIsGroundNode() {
        if (this.cache.latest) {
            return this.tree().isGroundNode(this)
        } else {
            return false
        }
    }
}

export type CanvasNodeWithTemplate = CanvasNode & { replicaInfo: ReplicaInfo | null }
export type CanvasNodeWithTokens = CanvasNode & { tokens: OrderedMap<string, CanvasNode> }
