import { RenderTarget } from "framer"
import { CanvasNode } from "document/models/CanvasTree/nodes/CanvasNode"
import { isDOMLayoutEnabled } from "utils/domLayoutExperiment"
import { withDOMLayout, WithDOMLayout } from "document/models/CanvasTree/traits/DOMLayout"
import { hasStackLayout } from "document/models/CanvasTree/traits/StackLayout"
import { nodeLayoutDependsOnParent } from "document/models/CanvasTree/nodes/utils/nodeLayoutDependsOnParent"
import { getGroundNodeRect } from "document/models/CanvasTree/utils/getGroundNodeRect"
import { unsafeGetParent } from "document/models/CanvasTree/nodes/utils/unsafeGetParent"
import { nodeIsParentDirected } from "document/models/CanvasTree/nodes/utils/nodeIsParentDirected"

import type { Size, Rect, LayerProps } from "framer"

export function withDOMLayoutTraits<T extends new (...args: any[]) => CanvasNode>(Base: T) {
    return class extends Base implements WithDOMLayout {
        usesDOMRectCached() {
            if (this.cache.usesDOMRect !== undefined) {
                return this.cache.usesDOMRect
            }

            return (this.cache.usesDOMRect = this.usesDOMRect())
        }

        usesDOMRect() {
            if (!isDOMLayoutEnabled()) return false
            const isParentDirected = nodeIsParentDirected(this)

            const parent = unsafeGetParent(this)
            const parentUsesDOMRect = Boolean(parent && withDOMLayout(parent) && parent.usesDOMRectCached())

            const layoutDependsOnParent = nodeLayoutDependsOnParent(this)
            const isStack = hasStackLayout(this) && this.stackEnabled

            return isParentDirected || isStack || (layoutDependsOnParent && parentUsesDOMRect)
        }

        invalidateDOMRect() {
            this.cache.usesDOMRect = undefined
            this.cache.domRect = null
        }

        forceNeedsMeasure() {
            if (!this.usesDOMRectCached()) return

            this.cache.needsMeasure = true

            if (nodeIsParentDirected(this)) {
                const parent = unsafeGetParent(this)
                if (parent && withDOMLayout(parent)) {
                    parent.forceNeedsMeasure()
                }
            }
        }

        getDOMRect(): Rect | null {
            if (!isDOMLayoutEnabled()) return null
            if (this.cache.domRect) {
                // Ground nodes are special-cased to always use the x/y from the
                // information stored directly on the node. This is because if
                // we rely on DOM layout for measurements/positioning of ground
                // nodes, we can end up in a situation where the user enters in
                // a position outside of the current viewport, where the ground
                // node will be hidden by the renderer, and its position won't
                // be able to be measured, because we can't measure hidden nodes.
                if (this.__unsafeIsGroundNode()) {
                    return {
                        ...getGroundNodeRect(this),
                        width: this.cache.domRect.width,
                        height: this.cache.domRect.height,
                    }
                }
                return { ...this.cache.domRect }
            }
            return null
        }

        rect(_parentSize: Size | null, _pixelAlign = true) {
            if (this.usesDOMRectCached()) {
                const domRect = this.getDOMRect()
                if (domRect) return domRect
            }

            return super.rect(_parentSize, _pixelAlign)
        }

        getProps(_cachePath?: string[]): Partial<LayerProps> {
            const props = super.getProps(_cachePath)
            if (!isDOMLayoutEnabled() || RenderTarget.current() !== RenderTarget.canvas) return props

            if (this.cache.needsMeasure) {
                props._needsMeasure = true
            }

            if (this.usesDOMRectCached()) {
                props._usesDOMRect = true
                props._domRect = this.getDOMRect()
            }

            return props
        }
    }
}
