import * as V55 from "./V55"
import * as V56 from "./V56"
import * as V57 from "./V57"
import * as V58 from "./V58"
import * as V59 from "./V59"
import * as V60 from "./V60"
import * as V61 from "./V61"
import * as V62 from "./V62"
import * as V63 from "./V63"
import * as V64 from "./V64"
import * as V65 from "./V65"
import * as V66 from "./V66"
import * as V67 from "./V67"
import * as V68 from "./V68"
import * as V69 from "./V69"
import * as V70 from "./V70"
import * as V71 from "./V71"
import * as V72 from "./V72"
import * as V73 from "./V73"
import * as V74 from "./V74"
import * as V75 from "./V75"

export {
    V55,
    V56,
    V57,
    V58,
    V59,
    V60,
    V61,
    V62,
    V63,
    V64,
    V65,
    V66,
    V67,
    V68,
    V69,
    V70,
    V71,
    V72,
    V73,
    V74,
    V75,
    V75 as Latest,
}

export type MigratableTree =
    | V55.Tree
    | V56.Tree
    | V57.Tree
    | V58.Tree
    | V59.Tree
    | V60.Tree
    | V61.Tree
    | V62.Tree
    | V63.Tree
    | V64.Tree
    | V65.Tree
    | V66.Tree
    | V67.Tree
    | V68.Tree
    | V69.Tree
    | V70.Tree
    | V71.Tree
    | V72.Tree
    | V73.Tree
    | V74.Tree
    | V75.Tree

export function isMigratableTree(tree: AnyTree): tree is MigratableTree {
    // NOTE! This is an unsafe coercion because tree.version can actually be any number
    // but we do it deliberately to perform an exhaustive check for all valid migratable versions
    const version = tree.version as MigratableTree["version"]
    switch (version) {
        case 55:
        case 56:
        case 57:
        case 58:
        case 59:
        case 60:
        case 61:
        case 62:
        case 63:
        case 64:
        case 65:
        case 66:
        case 67:
        case 68:
        case 69:
        case 70:
        case 71:
        case 72:
        case 73:
        case 74:
        case 75:
            return true
        default:
            // this doesn't mean it never actually happens
            // but it makes sure all the valid versions are checked
            // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-case-declarations
            const exhaustivenessCheck: never = version
            return false
    }
}

interface AnyTree {
    version: number
    root: AnyTreeNode
}
export function isTree(tree: unknown): tree is AnyTree {
    return isObject(tree) && typeof tree.version === "number" && isObject(tree.root)
}

export interface AnyTreeNode {
    id: string
    __class: string
    children?: AnyTreeNode[] | null | undefined
}

function isObject(obj: unknown): obj is Record<string, unknown> {
    return typeof obj === "object" && obj !== null
}
