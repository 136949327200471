import { nameDefaults } from "document/models/CanvasTree/traits/Name"
import { sizeDefaults } from "document/models/CanvasTree/traits/Size"
import { intrinsicSizeDefaults } from "document/models/CanvasTree/traits/IntrinsicSize"
import { withFramePresetDefaults } from "document/models/CanvasTree/traits/FramePreset"
import { aspectRatioDefaults } from "document/models/CanvasTree/traits/AspectRatioLock"
import { pinDefaults } from "document/models/CanvasTree/traits/Pins"
import { rotationDefaults } from "document/models/CanvasTree/traits/Rotation"
import { borderDefaults } from "document/models/CanvasTree/traits/Border"
import { borderPerSideDefaults } from "document/models/CanvasTree/traits/BorderPerSide"
import { basicChildrenDefaults } from "document/models/CanvasTree/traits/Children"
import { visibilityDefaults } from "document/models/CanvasTree/traits/Visibility"
import { lockDefaults } from "document/models/CanvasTree/traits/Lock"
import { withExportDefaults } from "document/models/CanvasTree/traits/Export"
import { opacityDefaults } from "document/models/CanvasTree/traits/Opacity"
import { boxShadowDefaults } from "document/models/CanvasTree/traits/BoxShadowDefaults"
import { codeOverrideDefaults } from "document/models/CanvasTree/traits/CodeOverride"
import { previewSettingsDefaults } from "document/models/CanvasTree/traits/PreviewSettings"
import { relativeRadiusDefaults } from "document/models/CanvasTree/traits/Radius"
import { radiusPerCornerDefaults } from "document/models/CanvasTree/traits/RadiusPerCorner"
import { clipDefaults } from "document/models/CanvasTree/traits/Clip"
import { fillDefaults } from "document/models/CanvasTree/traits/FillDefaults"
import { templateDefaults } from "document/models/CanvasTree/traits/Template"
import { targetDefaults } from "document/models/CanvasTree/traits/Target"
import { guidesDefaults } from "document/models/CanvasTree/traits/Guides"
import { frameEventDefaults } from "document/models/CanvasTree/traits/FrameEvents"
import { stackLayoutDefaults } from "document/models/CanvasTree/traits/StackLayout"
import { paddingDefaults } from "document/models/CanvasTree/traits/Padding"
import { filterDefaults } from "document/models/CanvasTree/traits/Filters"
import { blendingDefaults } from "document/models/CanvasTree/traits/Blending"
import { memoRecord } from "./memoRecord"
import { variantDefaults } from "../traits/Variant"
import { screenFrameDefaults } from "../traits/Screen"

export const getFrameRecord = memoRecord(() => {
    return {
        ...nameDefaults,
        ...sizeDefaults,
        ...intrinsicSizeDefaults,
        ...basicChildrenDefaults,
        ...withFramePresetDefaults,
        ...aspectRatioDefaults,
        ...pinDefaults,
        ...stackLayoutDefaults,
        ...paddingDefaults,
        ...fillDefaults,
        ...opacityDefaults,
        ...rotationDefaults,
        ...clipDefaults,
        ...borderDefaults,
        ...borderPerSideDefaults,
        ...relativeRadiusDefaults,
        ...radiusPerCornerDefaults,
        ...boxShadowDefaults,
        ...visibilityDefaults,
        ...lockDefaults,
        ...filterDefaults,
        ...blendingDefaults,
        ...withExportDefaults,
        ...templateDefaults,
        ...variantDefaults,
        ...codeOverrideDefaults,
        ...previewSettingsDefaults,
        ...targetDefaults,
        ...guidesDefaults,
        ...frameEventDefaults,
        ...screenFrameDefaults,
    }
})
