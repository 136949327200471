import * as React from "react"
import { useEffect, useState } from "react"

import { FrameWithMotion } from "./Frame"

/**
 * @internal
 */
export enum PlaceholderType {
    Loading,
    Error,
}

const baseStyle: React.CSSProperties = {
    background: undefined,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "1.4em",
    textOverflow: "ellipsis",
    overflow: "hidden",
    minHeight: 0,
}

const textStyle: React.CSSProperties = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    flexShrink: 0,
    padding: `0 10px`,
}

const titleStyle: React.CSSProperties = {
    ...textStyle,
    fontWeight: 500,
}

const messageStyle: React.CSSProperties = {
    ...textStyle,
    whiteSpace: "pre",
    maxHeight: "calc(50% - calc(20px * var(--framerInternalCanvas-canvasPlaceholderContentScaleFactor, 1)))",
    WebkitMaskImage: "linear-gradient(to bottom, black 80%, transparent 100%)",
}

interface Props {
    type: PlaceholderType
    title?: string
    message?: string
    props?: any
}

/**
 * @internal
 */
export function ComponentPlaceholder({ type, title, message, props }: Props): React.ReactElement<any> {
    // Wait a bit before considering the placeholder "settled". Only show
    // error state etc. once it settles to avoid flashing errors.
    const [isSettled, setIsSettled] = useState(false)
    useEffect(() => {
        const timer = window.setTimeout(() => {
            setIsSettled(true)
        }, 1000)
        return () => clearTimeout(timer)
    }, [type])
    return (
        <FrameWithMotion className={className(type)} style={baseStyle} width="100%" height="100%" {...props}>
            <div className={isSettled ? "text" : "pending text"} style={titleStyle}>
                {placeholderTitle(type, title)}
            </div>
            {message && (
                <div className={isSettled ? "text" : "pending text"} style={messageStyle}>
                    {message}
                </div>
            )}
        </FrameWithMotion>
    )
}

function className(type: PlaceholderType): string {
    switch (type) {
        case PlaceholderType.Error:
            return "framerInternalUI-errorPlaceholder"
        case PlaceholderType.Loading:
            return "framerInternalUI-componentPlaceholder"
    }
}

function placeholderTitle(type: PlaceholderType, title: string | undefined) {
    switch (type) {
        case PlaceholderType.Loading:
            return "Loading"
        case PlaceholderType.Error:
            if (!title) {
                return "Error"
            }
            return `Error in ${stripSlash(title)}`
    }
}

function stripSlash(title: string) {
    if (title.startsWith("./")) {
        return title.replace("./", "")
    }
    return title
}
