import * as React from "react"

export function IconClose(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" width="10" height="10" {...props}>
            <path
                d="M 8.5 1.5 L 1.5 8.5 M 1.5 1.5 L 8.5 8.5"
                fill="transparent"
                strokeWidth="1.5"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    )
}
