import type * as React from "react"
import type { Spring, Tween } from "framer-motion"
import type { ActionHandler, ControlIcon } from "framer"

import { ControlType, useNavigation } from "framer"
import { addActionControls } from "./actionControls"

export type NavigateActionTransitionType = "push" | "instant" | "fade" | "modal" | "overlay" | "flip" | "magicMotion"
export type NavigateActionAppearsFrom = "left" | "right" | "top" | "bottom"
const transitions: NavigateActionTransitionType[] = [
    "instant",
    "magicMotion",
    "push",
    "modal",
    "overlay",
    "fade",
    "flip",
]

function camelCaseToTitleCase(name: string): string {
    const result = name.replace(/([A-Z])/g, " $1")
    return result.charAt(0).toUpperCase() + result.slice(1)
}

export function transitionToTitle(transition: NavigateActionTransitionType) {
    if (transition === "magicMotion") {
        return "Magic"
    }
    return camelCaseToTitleCase(transition)
}

const transitionTitles: string[] = transitions.map(transitionToTitle)

const transitionIcons: ControlIcon[] = transitions.map(
    transition => `navigation-transition-${transition}`
) as ControlIcon[]
const appearsFromOptions: NavigateActionAppearsFrom[] = ["right", "left", "bottom", "top"]

/* Note this structure is inspected by the Preview in createActionsOverride to determine
 * if an action has a target when of type "next" before the event is added to the component,
 * which may need updating if this changes */
export interface NavigateOptions {
    type: "previous" | "next"
    target: React.ReactNode | undefined
    transition: NavigateActionTransitionType
    appearsFrom: NavigateActionAppearsFrom
    backdropColor: string
    animation: Spring & Omit<Tween, "type">
}

export function useNavigate(options: NavigateOptions): ActionHandler {
    const navigation = useNavigation()
    if (!navigation) {
        return () => {}
    }
    return () => {
        if (options.type === "previous") {
            navigation.goBack()
            return
        }
        const { target, appearsFrom, backdropColor, animation } = options
        if (!target) return

        switch (options.transition) {
            case "instant":
                navigation.instant(target)
                break
            case "fade":
                navigation.fade(target, { animation })
                break
            case "push":
                navigation.push(target, { appearsFrom, animation })
                break
            case "modal":
                navigation.modal(target, { backdropColor, animation })
                break
            case "overlay":
                navigation.overlay(target, { appearsFrom, backdropColor, animation })
                break
            case "flip":
                navigation.flip(target, { appearsFrom, animation })
                break
            case "magicMotion":
                navigation.magicMotion(target, { animation })
                break
        }
    }
}

interface NavigateActionControlTypes {
    type: ControlType.SegmentedEnum
    target: ControlType.ComponentInstance
    transition: ControlType.Enum
    appearsFrom: ControlType.SegmentedEnum
    backdropColor: ControlType.Color
    animation: ControlType.Transition
}

export const navigateActionControlTypes: NavigateActionControlTypes = {
    type: ControlType.SegmentedEnum,
    target: ControlType.ComponentInstance,
    transition: ControlType.Enum,
    appearsFrom: ControlType.SegmentedEnum,
    backdropColor: ControlType.Color,
    animation: ControlType.Transition,
}

export const navigateActionDefaults: NavigateOptions = {
    type: "next",
    target: undefined,
    transition: "instant",
    appearsFrom: "right",
    backdropColor: "rgba(4,4,15,.4)",
    animation: {
        type: "spring",
        ease: [0.44, 0, 0.56, 1],
        duration: undefined,
        delay: 0,
        stiffness: 500,
        damping: 60,
        mass: 1,
    },
}

addActionControls(useNavigate, "Interaction", {
    type: {
        type: navigateActionControlTypes.type,
        options: ["previous", "next"],
        optionTitles: ["Previous", "Next"],
        defaultValue: navigateActionDefaults.type,
        title: "Target",
    },
    target: {
        type: navigateActionControlTypes.target,
        title: " ",
        hidden: isPrevious,
    },
    transition: {
        type: navigateActionControlTypes.transition,
        options: transitions,
        optionTitles: transitionTitles,
        optionIcons: transitionIcons,
        defaultValue: navigateActionDefaults.transition,
        title: "Animate",
        hidden: isPrevious,
        displaySegmentedControl: true,
        segmentedControlDirection: "vertical",
        showIconWithTitle: true,
    },
    animation: {
        type: ControlType.Transition,
        title: "Animation",
        defaultValue: navigateActionDefaults.animation,
        hidden: isNavigationAnimationHidden,
    },
    appearsFrom: {
        type: navigateActionControlTypes.appearsFrom,
        options: appearsFromOptions,
        defaultValue: navigateActionDefaults.appearsFrom,
        optionTitles: ["Left", "Right", "Up", "Down"],
        title: "Direction",
        hidden: info => {
            if (isPrevious(info)) return true
            switch (info.transition) {
                case "flip":
                case "overlay":
                case "push":
                    return false
                default:
                    return true
            }
        },
    },
    backdropColor: {
        type: navigateActionControlTypes.backdropColor,
        title: "Backdrop",
        defaultValue: navigateActionDefaults.backdropColor,
        hidden: info => {
            if (isPrevious(info)) return true
            switch (info.transition) {
                case "overlay":
                case "modal":
                    return false
                default:
                    return true
            }
        },
    },
})

export function isNavigationAnimationHidden(navigationInfo: Partial<NavigateOptions>) {
    return navigationInfo.transition === "instant" || navigationInfo.type === "previous"
}

function isPrevious(info: Partial<NavigateOptions>): boolean {
    return info.type === "previous"
}
