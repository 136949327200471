import type { List } from "immutable"
import type { SVGProperties, BlendingMode, LayerProps, ImageFit, ConstraintValues } from "framer"

import { CanvasNode } from "./CanvasNode"
import { withPinsSizeRatioConstraints } from "../traits/mixins/withPinsSizeRatioConstraints"
import type { WithRect } from "../traits/Frame"
import { getSVGRecord } from "../records/SVGRecord"
import type { Rect, Size } from "framer"
import { updateConstrainedFrame, updateConstrainedSize } from "document/models/ConstraintSolver"
import type { WithName } from "../traits/Name"
import type { WithRotation } from "../traits/Rotation"
import type { WithSVG } from "../traits/SVG"
import type { WithOriginalFilename } from "../traits/OriginalFilename"
import type { WithLock } from "../traits/Lock"
import type { WithVisibility } from "../traits/Visibility"
import type { WithExport } from "document/models/CanvasTree/traits/Export"
import type { WithColorable } from "document/models/CanvasTree/traits/Colorable"
import { setDefaults } from "./MutableNode"
import type { ExportOptions } from "./ExportOptions"
import { collectVisualPropertiesForNode } from "../traits/utils/collectVisualPropertiesForNode"
import type { WithShadow } from "document/models/CanvasTree/traits/Shadow"
import type { Shadow } from "document/models/Shadow"
import { collectFilterPropsForNode } from "document/models/CanvasTree/traits/utils/collectEffectsForNode"
import type { WithFilters } from "document/models/CanvasTree/traits/Filters"
import type { WithFill, WithSupportsGradient, FillType } from "document/models/CanvasTree/traits/Fill"
import { collectFillPropsForNode } from "document/models/CanvasTree/traits/utils/collectBackgroundForNode"
import { collectNameForNode } from "document/models/CanvasTree/traits/utils/collectNameForNode"
import type { LinearGradient, RadialGradient } from "document/models/Gradient"
import type { WithBlending } from "../traits/Blending"
import type { WithFramePreset } from "../traits/FramePreset"
import { withClassDiscriminator } from "utils/withClassDiscriminator"
import type { VariableReference } from "../traits/VariableReference"
import type { WithOpacity } from "../traits/Opacity"
import { withDOMLayoutTraits } from "document/models/CanvasTree/traits/mixins/withDOMLayoutTraits"
import { Attribute } from "../utils/buildJSX"
import { TagProps } from "variants/types"
import { attributesFromProps } from "variants/utils/attributesFromProps"
import { collectStyle } from "../traits/collectStyles"

const svgStrokeRegExp = /<svg[^>]+stroke[^>]+>/

export function isSVGNode(node: CanvasNode): node is SVGNode {
    return node instanceof SVGNode
}

export class SVGNode
    extends withClassDiscriminator("SVGNode", withDOMLayoutTraits(withPinsSizeRatioConstraints(CanvasNode)))
    implements
        WithRect,
        WithName,
        WithVisibility,
        WithLock,
        WithOriginalFilename,
        WithSVG,
        WithFill,
        WithSupportsGradient,
        WithOpacity,
        WithRotation,
        WithShadow,
        WithBlending,
        WithFilters,
        WithExport,
        WithColorable {
    children?: CanvasNode[]
    name: string | null
    visible: boolean | VariableReference
    locked: boolean

    svg: string
    intrinsicWidth: number | null
    intrinsicHeight: number | null
    originalFilename: string

    fillType: FillType
    fillColor: string | VariableReference
    fillLinearGradient: LinearGradient | undefined
    fillRadialGradient: RadialGradient | undefined
    fillImage: string | VariableReference | null
    fillImageOriginalName: string | null
    fillImageResize: ImageFit
    fillImagePixelWidth: number | null
    fillImagePixelHeight: number | null

    rotation: number | VariableReference
    opacity: number | VariableReference

    shadows: readonly Shadow[] | undefined

    blendingMode: BlendingMode | undefined

    blur: number | VariableReference | undefined
    backgroundBlur: number | VariableReference | undefined
    brightness: number | VariableReference | undefined
    contrast: number | VariableReference | undefined
    grayscale: number | VariableReference | undefined
    hueRotate: number | VariableReference | undefined
    invert: number | VariableReference | undefined
    saturate: number | VariableReference | undefined
    sepia: number | VariableReference | undefined

    exportOptions: List<ExportOptions>

    colorable: boolean

    constructor(properties?: Partial<SVGNode>) {
        super()
        setDefaults<SVGNode>(this, getSVGRecord(), properties)
        delete this.children // See comment in `MutableNode.ts`
    }

    updateForRect(frame: Rect, parentSize: Size | null, constraintsLocked: boolean) {
        return updateConstrainedFrame(
            frame,
            parentSize,
            this.constraints(),
            this.constraintsLocked || constraintsLocked,
            !this.usesDOMRectCached()
        )
    }

    updateForSize(size: Partial<Size>, parentSize: Size | null): Partial<ConstraintValues & WithFramePreset> {
        return updateConstrainedSize(size, parentSize, this.constraintValues())
    }

    supportsGradient(): boolean {
        return !svgStrokeRegExp.test(this.svg)
    }

    getProps(): Partial<SVGProperties> & LayerProps {
        const props: Partial<SVGProperties> = {
            ...super.getProps(),
            ...collectVisualPropertiesForNode(this),
            ...this.newConstraintProperties(),
            opacity: this.resolveValue("opacity"),
            rotation: this.resolveValue("rotation"),
            svg: this.svg,
            visible: this.resolveValue("visible"),
            intrinsicHeight: this.intrinsicHeight || undefined,
            intrinsicWidth: this.intrinsicWidth || undefined,
        }

        if (this.__unsafeIsGroundNode()) {
            props.left = 0
            props.top = 0
        }

        collectFilterPropsForNode(this, props)
        if (this.colorable) {
            collectFillPropsForNode(this, props)
        }
        collectNameForNode(this, props)

        return props
    }

    getAttributes(defaultProps: Partial<TagProps>): Attribute[] {
        const props: TagProps = {
            layoutId: this.id,
            style: {},
            withExternalLayout: true,
            intrinsicHeight: this.intrinsicHeight || undefined,
            intrinsicWidth: this.intrinsicWidth || undefined,
            ...defaultProps,
        }

        if (this.colorable) collectFillPropsForNode(this, props as Partial<SVGProperties>)
        collectStyle(this, props.style, { withInlineVariables: true }, true)
        const attributes = attributesFromProps(props)
        // Escape newlines, single quotes, and backslashes.
        const safeSVG = this.svg.replace(/[\n\\']/g, c => (c === "\n" ? "\\n" : `\\${c}`))
        attributes.push({
            name: "svg",
            type: "variable",
            value: `'${safeSVG}'`,
        })

        return attributes
    }
}
