import type { MotionStyle } from "framer-motion"
import type { CanvasNode } from "../../nodes/CanvasNode"
import type { CollectTraitOptions } from "."
import { withShadow } from "document/models/CanvasTree/traits/Shadow"
import { isTextNode } from "../../nodes/TextNode"
import { isUndefined } from "utils/typeChecks"

export function collectTextShadow(
    node: CanvasNode,
    style: MotionStyle,
    { unset = false }: CollectTraitOptions = {}
): void {
    if (!withShadow(node) || !isTextNode(node)) return
    if (isUndefined(node.shadows)) {
        if (unset) style.textShadow = "none"
        return
    }
    const shadows: string[] = []
    for (const shadow of node.shadows) {
        shadows.push(`${shadow.x}px ${shadow.y}px ${shadow.blur}px ${shadow.color}`)
    }

    if (!shadows.length) {
        if (unset) style.textShadow = "none"
        return
    }

    style.textShadow = shadows.join(", ")
}
