import type { CanvasNode } from "document/models/CanvasTree"

export interface WithSizeToFit {
    sizeToFit: boolean
}

export const sizeToFitDefaults: WithSizeToFit = {
    sizeToFit: false,
}

const key: keyof WithSizeToFit = "sizeToFit"

export function withSizeToFit(node: CanvasNode): node is CanvasNode & WithSizeToFit {
    return key in node
}

export function reduceSizeToFit(node: CanvasNode, result: { sizeToFit?: boolean | null }) {
    if (!withSizeToFit(node)) return

    if (result.sizeToFit === undefined) {
        result.sizeToFit = node.sizeToFit
    } else if (result.sizeToFit !== null && node.sizeToFit !== result.sizeToFit) {
        result.sizeToFit = null
    }
}
