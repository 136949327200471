/* eslint-disable @typescript-eslint/no-explicit-any */
import { Exact } from "ts-essentials"

declare const checked: unique symbol
export type Checked<T> = T & {
    [checked]: "checked"
}

/**
 * Placeholder to use as the second parameter of `exactCheck` function.
 */
export const _ = undefined as unknown

/**
 * A type-checking utility that verifies that `node` matches the `SCHEMA` type exactly.
 * In case there is mismatch, it will raise a TS error on the `node` argument.
 * Because TS doesn't allow partial generic parameter inference
 * this function can be used either by specifying both generic parameters:
 *
 * ```
 * exactCheck<typeof node, SchemaType>(node)
 * ```
 *
 * or by providing a placeholder of the `SCHEMA` type as a second argument to the function call.
 * You can import the placeholder from this module or create one manually:
 *
 * ```
 * import { exactCheck, _ } from "./utils/exactCheck"
 *
 * exactCheck(node, _ as SchemaType)
 * ```
 *
 * Both NODE and SCHEMA types can be either an object type of a specific node type e.g. V60.FrameNode
 * or a union type of multiple node types, e.g. V60.FrameNode | V60.CodeComponentNode
 */
export function exactCheck<NODE extends { __class: string }, SCHEMA extends { __class: string }>(
    node: ExactNodeType<NODE, SCHEMA>,
    _placeholder?: SCHEMA
): Checked<NODE> {
    return (node as unknown) as Checked<NODE>
}

type ExactNodeType<NODE extends { __class: string }, SCHEMA extends { __class: string }> = SCHEMA extends any // SCHEMA can be a union, unwrap it
    ? NODE extends any // NODE can be a union, unwrap it
        ? Exact<NODE, SCHEMA>
        : never
    : never
