import type { BorderStyle } from "framer"
import type { CanvasNode } from "document/models/CanvasTree"
import { stylableNode } from "./utils/stylableNode"

export interface WithBorder {
    borderEnabled: boolean | undefined
    borderWidth: number | undefined
    borderColor: string | undefined
    borderStyle: BorderStyle | undefined
}

const key: keyof WithBorder = "borderWidth"

export const borderDefaults: WithBorder = {
    borderEnabled: undefined,
    borderWidth: undefined,
    borderColor: undefined,
    borderStyle: undefined,
}

export const withBorder = (node: CanvasNode): node is CanvasNode & WithBorder => {
    return key in node && stylableNode(node)
}
