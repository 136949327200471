import type { BoxShadow } from "document/models/Shadow"
import type { MotionStyle } from "framer-motion"
import type { Shadow } from "framer/render/types/Shadow"
import type { CanvasNode } from "../../nodes/CanvasNode"
import type { CollectTraitOptions } from "."
import { hasBoxShadow } from "document/models/CanvasTree/traits/BoxShadow"
import { withShadow } from "document/models/CanvasTree/traits/Shadow"
import { getFilterBlurForBoxBlur } from "document/models/CanvasTree/traits/utils/getFilterBlurForBoxBlur"
import { shouldRenderShadowUsingFilter } from "document/models/CanvasTree/traits/utils/wantsFilterShadow"
import { isNumber, isString } from "utils/typeChecks"
import { withFilters, WithFilters } from "document/models/CanvasTree/traits/Filters"
import { isVariableReference } from "../VariableReference"

const makeDropShadow = ({ x, y, blur, color }: BoxShadow | Shadow) => `drop-shadow(${x}px ${y}px ${blur}px ${color})`

function createShadowFilters(node: CanvasNode & WithFilters): string[] {
    const dropShadows: string[] = []

    if (hasBoxShadow(node) && node.boxShadows) {
        for (const shadow of node.boxShadows) {
            if (shadow.inset) continue
            const migratedShadow = shadow.updatedBoxShadow({ blur: getFilterBlurForBoxBlur(shadow.blur) })
            dropShadows.push(makeDropShadow(migratedShadow))
        }
    } else if (withShadow(node) && node.shadows) {
        for (const shadow of node.shadows) {
            dropShadows.push(makeDropShadow(shadow))
        }
    }

    return dropShadows
}

const enum Unit {
    Percent = "%",
    Degress = "deg",
    Pixels = "px",
}

type FiltersExcludeBgBlur = keyof Omit<WithFilters, "backgroundBlur">
const filterUnitMap: Map<FiltersExcludeBgBlur, Unit> = new Map([
    ["brightness", Unit.Percent],
    ["contrast", Unit.Percent],
    ["grayscale", Unit.Percent],
    ["hueRotate", Unit.Degress],
    ["invert", Unit.Percent],
    ["saturate", Unit.Percent],
    ["sepia", Unit.Percent],
    ["blur", Unit.Pixels],
])

export function collectFilters(
    node: CanvasNode,
    style: MotionStyle,
    { withInlineVariables = false, unset = false }: CollectTraitOptions = {}
): void {
    if (!withFilters(node)) return

    const filters: string[] = []

    for (const [filterKey, unit] of filterUnitMap) {
        if (!isNumber(node[filterKey]) && !isVariableReference(node[filterKey])) continue

        const value = node.resolveValue(filterKey, withInlineVariables)

        // We've already checked if node[filterKey] is number or variableReference above,
        // so here we don't double check if the resolved value is an inline variable
        // to avoid the (possibly) costly regex check.
        if (isNumber(value) || isString(value)) {
            const cssProp = filterKey === "hueRotate" ? "hue-rotate" : filterKey

            /**
             * number-percentage types are set as numbers, as when filters are read from the DOM
             * they are returned as numbers rather than percentages. This makes it simpler for Motion
             * to animate from filters read from the DOM.
             */
            unit === Unit.Percent
                ? filters.push(`${cssProp}(${(isNumber(value) ? value : parseFloat(value)) / 100})`)
                : filters.push(`${cssProp}(${value}${unit})`)
        }
    }

    if (shouldRenderShadowUsingFilter(node)) {
        filters.push(...createShadowFilters(node))
    }

    if (filters.length === 0) {
        if (unset) style.filter = style.WebkitFilter = "none"
        return
    }

    style.filter = style.WebkitFilter = filters.join(" ")
}
