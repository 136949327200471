let deterministicIDPrefix = "abcdefg"
let deterministicIDNext = 1000

export function deterministicID(): string {
    return deterministicIDPrefix + "-x-" + ++deterministicIDNext
}

export function resetDeterministicID(prefix: string): void {
    deterministicIDPrefix = prefix
    deterministicIDNext = 1000
}
