import type { Assets } from "@framerjs/framer-services"
import { unhandledError } from "@framerjs/shared"

export function readAssetUpdatesStream(
    assetInterface: Assets.Interface | Promise<Assets.Interface>,
    onUpdate: (event: Assets.AssetUpdateEvent) => Promise<void>
) {
    Promise.resolve(assetInterface)
        .then((assetService: Assets.Interface) => assetService.assetUpdatesStream({ replay: "latest" }).read(onUpdate))
        .catch(unhandledError)
}
