import type { Variable, VariableID, Variables } from "../../traits/Variables"

function getId(value: Variable): VariableID {
    return value.id
}

export function getChangedVariableIds(
    previous: Variables | undefined,
    next: Variables
): { updated: VariableID[]; deleted: VariableID[] } {
    if (!previous) return { updated: next.map(getId), deleted: [] }

    const previousIds = previous.map(getId)
    const nextIds = next.map(getId)
    const nextIdSet = new Set(nextIds)

    // Start with a list of deleted ids
    const deletedIds: VariableID[] = previousIds.filter(prevId => !nextIdSet.has(prevId))
    const updatedIds: VariableID[] = [...deletedIds]

    next.forEach(nextVariable => {
        const prevVariable = previous.find(prevVar => prevVar.id === nextVariable.id)
        if (!prevVariable) {
            // Newly added variable
            updatedIds.push(nextVariable.id)
        } else if (prevVariable !== nextVariable) {
            // Variable has changed, we don't need deep equality because the entire object is replaced on change
            updatedIds.push(nextVariable.id)
        }
    })

    return { updated: updatedIds, deleted: deletedIds }
}
