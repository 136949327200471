import * as React from "react"
import { cx } from "linaria"
import { isFiniteNumber } from "./utils/isFiniteNumber"
import type { OverrideType } from "./types"
import { slider } from "./Slider.styles"
import { frescoSettingsContext } from "./FrescoSettings"

type HTMLInputAttributes = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export type SliderProps = OverrideType<
    Omit<HTMLInputAttributes, "disabled" | "onMouseDown" | "onMouseUp" | "inputMode">,
    {
        value: number | null | undefined
        onChange: (value: number) => void
        enabled?: boolean
    }
>

export const Slider = React.forwardRef<HTMLInputElement, SliderProps>(
    (props, forwardedRef: React.Ref<HTMLInputElement>) => {
        const { value, onChange, enabled, min = 0, max = 100, className, ...rest } = props

        const fallbackRef = React.useRef<HTMLInputElement | null>(null)
        const ref = forwardedRef || fallbackRef

        const onChangeHandler = React.useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => {
                const target = event.target
                const newValue = Number(target.value)
                if (isFiniteNumber(newValue)) {
                    onChange(newValue)
                }
            },
            [onChange]
        )

        React.useEffect(() => {
            updateProgress(ref)
        }, [value, min, max, ref])

        const frescoSettings = React.useContext(frescoSettingsContext)

        const mouseDownHandler = React.useCallback(() => {
            if (frescoSettings.beginUndoGroup) frescoSettings.beginUndoGroup()
        }, [frescoSettings])

        const mouseUpHandler = React.useCallback(() => {
            if (frescoSettings.endUndoGroup) frescoSettings.endUndoGroup()
        }, [frescoSettings])

        return (
            <input
                ref={ref}
                className={cx(slider, className)}
                type="range"
                value={isFiniteNumber(value) ? value : ""}
                min={min}
                max={max}
                onChange={onChangeHandler}
                tabIndex={-1}
                disabled={value === undefined || enabled === false}
                {...rest}
                onMouseDown={mouseDownHandler}
                onMouseUp={mouseUpHandler}
            />
        )
    }
)

function updateProgress(ref: React.Ref<HTMLInputElement>) {
    const input = (ref as React.RefObject<HTMLInputElement>).current
    if (!input) return

    const { value, min, max } = input

    const currentValue = parseFloat(value)
    if (!isFiniteNumber(currentValue)) return

    const minParsed = parseFloat(min)
    const maxParsed = parseFloat(max)

    const minValue = isFiniteNumber(minParsed) ? minParsed : 0
    const maxValue = isFiniteNumber(maxParsed) ? maxParsed : 100

    const progress = Math.round(((currentValue - minValue) / (maxValue - minValue)) * 100)
    input.style.setProperty("--progress", `${progress}%`)
}
