/**
 * Interface for CanvasNode properties that can reference TokenNodes. Provides
 * a way for the VekterEngine to query and replace tokens without having to
 * inspect every property on the node.
 */
export interface WithTokenVariables<T> {
    /**
     * Returns any CSS variables currently in use.
     */
    tokenVariables(): string[]
    /**
     * Called when one or more Tokens are deleted, the implementation should
     * replace the variable with the value provided and return a new instance.
     */
    removeTokenVariables(variables: { [tokenId: string]: string }): T | undefined
}

const key: keyof WithTokenVariables<any> = "tokenVariables"

export function withTokenVariables<T>(node: any): node is object & WithTokenVariables<T> {
    return typeof node === "object" && node !== null && key in node
}
