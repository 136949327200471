import { useCallback, useMemo } from "react"
import { useSubscription } from "use-subscription"

interface Cache {
    [key: string]: unknown
}

const valueCache: Cache = {}
const listeners = new Set<(key: string) => void>()

window.addEventListener("storage", event => {
    if (event.storageArea !== localStorage || event.key === null) {
        return
    }

    try {
        if (event.newValue === null) {
            valueCache[event.key] = null
        } else if (event.oldValue !== event.newValue) {
            const value = JSON.parse(event.newValue)
            valueCache[event.key] = value
        }

        for (const notify of listeners) {
            notify(event.key)
        }
    } catch (error) {
        // Ignore error
    }
})

// This hook will not update if you call localStorage.setItem() directly.
// We would probably need to monkey patch the localStorage object to do that.
export function useLocalStorage<Value>(key: string, defaultValue: Value) {
    const subscription = useMemo(() => {
        function getCurrentValue() {
            if (key in valueCache) {
                return valueCache[key]
            }

            const serialized = localStorage.getItem(key)

            if (serialized) {
                try {
                    const value = JSON.parse(serialized)
                    valueCache[key] = value
                    return value
                } catch {
                    // Fall through
                }
            }

            return null
        }

        function subscribe(callback: () => void) {
            // Only notify the callback when the right key is updated.
            function listener(updatedKey: string) {
                if (updatedKey === key) {
                    callback()
                }
            }

            listeners.add(listener)
            return () => listeners.delete(listener)
        }

        return {
            getCurrentValue,
            subscribe,
        }
    }, [key])

    // Fallback to the defaultValue if the value in the localStorage is null.
    const localStorageValue: Value = useSubscription(subscription) ?? defaultValue

    const setValue = useCallback(
        (value: ((currentValue: Value) => Value) | Value | null) => {
            try {
                // Remove the value if it's null
                if (value === null) {
                    valueCache[key] = null
                    localStorage.removeItem(key)
                } else if (typeof value === "function") {
                    const getValue = value as Function
                    valueCache[key] = getValue(valueCache[key] || localStorageValue)

                    const serialized = JSON.stringify(valueCache[key])
                    localStorage.setItem(key, serialized)
                } else {
                    valueCache[key] = value
                    const serialized = JSON.stringify(value)
                    localStorage.setItem(key, serialized)
                }

                for (const notify of listeners) {
                    notify(key)
                }
            } catch (error) {
                // Ignore error
            }
        },
        [localStorageValue, key]
    )

    return [localStorageValue, setValue] as const
}
