import { nameDefaults } from "document/models/CanvasTree/traits/Name"
import { instructionalChildrenDefaults } from "document/models/CanvasTree/traits/Children"
import { aspectRatioDefaults } from "document/models/CanvasTree/traits/AspectRatioLock"
import { rotationDefaults } from "document/models/CanvasTree/traits/Rotation"
import { visibilityDefaults } from "document/models/CanvasTree/traits/Visibility"
import { lockDefaults } from "document/models/CanvasTree/traits/Lock"
import { sizeDefaults } from "document/models/CanvasTree/traits/Size"
import { positionDefaults } from "document/models/CanvasTree/traits/Position"
import { withExportDefaults } from "document/models/CanvasTree/traits/Export"
import { opacityDefaults } from "document/models/CanvasTree/traits/Opacity"
import { memoRecord } from "./memoRecord"

export const getShapeGroupRecord = memoRecord(() => {
    return {
        ...nameDefaults,
        ...instructionalChildrenDefaults,
        ...rotationDefaults,
        ...aspectRatioDefaults,
        ...opacityDefaults,
        ...visibilityDefaults,
        ...lockDefaults,
        ...sizeDefaults,
        ...positionDefaults,
        ...withExportDefaults,
    }
})
