import type { CanvasNode } from "../../nodes/CanvasNode"
import type { UnknownProps } from "../utils/collectVariantDefinitionForNode"
import { isStackComponent } from "../../nodes/FrameNode"
import { isTextNode } from "../../nodes/TextNode"

export function collectCentering(node: CanvasNode, props: UnknownProps) {
    // Centering is only required for Stacks and Text in Generated Components.
    if (!isStackComponent(node) && !isTextNode(node)) return

    const { center } = node.newConstraintProperties()
    props.center = center ?? false
}
