import { colorsDark } from "./colors/colorsDark"
import { colorsLight } from "./colors/colorsLight"

export { breakpoints } from "./breakpoints"
export { colors } from "./colors"
export type { ColorTokenMap } from "./colors"
export { cursors } from "./cursors"
export type { CursorTokens } from "./cursors"
export { dimensions } from "./dimensions"
export type { DimensionTokens } from "./dimensions"
export { shadows } from "./shadows"
export type { ShadowTokenMap } from "./shadows"
export { fonts } from "./fonts"
export { ZIndex } from "./zIndex"

export const colorValues = {
    dark: colorsDark,
    light: colorsLight,
}
