import {
    convertPropsToDeviceOptions,
    defaultProps as defaultDeviceProps,
    devicePresets,
    DeviceOptions,
    DevicePresetID,
    getDevicePreset,
} from "@framerjs/framer-runtime/components/Device"
import type { CanvasNode } from "document/models/CanvasTree/nodes/CanvasNode"
import { isDeviceNode } from "document/models/CanvasTree/nodes/DeviceNode"
import { withPreviewSettings, WithPreviewSettings } from "document/models/CanvasTree/traits/PreviewSettings"
import { getControlPropsValues } from "document/models/CanvasTree/traits/utils/codeComponentProps"
import { FramePresetID } from "document/models/CanvasTree/traits/utils/framePresets"
import { experiments } from "app/experiments"
import { backgroundForPreviewSettings } from "./backgroundForPreviewSettings"
import { withFramePreset } from "document/models/CanvasTree/traits/FramePreset"
import { isScreen } from "document/models/CanvasTree/traits/Screen"

// Mappings included here will have the preview default to the given device.
// If there's no mapping, the preview will default to no device.
/* eslint-disable @typescript-eslint/naming-convention */
export const frameDeviceMappings: Partial<Record<FramePresetID, DevicePresetID>> = {
    // Phones
    iPhone_320_568: "iphone-se",
    iPhone_375_667: "iphone-8",
    iPhone_414_736: "iphone-8-plus",
    iPhone_414_896: "iphone-11",
    iPhone_375_812: "iphone-11-pro",
    iPhone_414_896_pro: "iphone-11-pro-max",
    iPhone_390_844: "iphone-12",
    iPhone_375_812_mini: "iphone-12-mini",
    iPhone_390_844_pro: "iphone-12-pro",
    iPhone_428_926_pro: "iphone-12-pro-max",
    Android_360_640: "samsung-galaxy-s7",
    GooglePixel_360_780: "pixel-5",
    GooglePixel_360_760_xl: "pixel-4-xl",
    GooglePixel_360_760: "pixel-4",

    // Tablets
    iPad_810_1080: "ipad",
    iPad_834_1194: "ipad-pro-11",
    iPad_768_1024: "ipad-mini",
    iPad_820_1180: "ipad-air",
    iPad_1024_1366: "ipad-pro-12-9",
    Surface_1440_960: "surface-3",
    Surface_1368_912: "surface-pro-4",

    // Desktops/Laptops
    MacBook_1440_900: "macbook-air",
    MacBook_1440_900_pro: "macbook-pro-13",
    MacBook_Pro_16_1536_960: "macbook-pro-16",
    iMac_215_2048_1152: "imac-21-5",
    iMac_2560_1440: "imac-27",
    Pro_Display_XDR_3008_1692: "pro-display-xdr",
    DellXPS_1920_1080: "dell-xps",
    SurfaceBook_1500_1000: "surface-book",

    // Watches
    AppleWatch_44: "apple-watch-44",
    AppleWatch_40: "apple-watch-40",

    // TVs
    FullHD_TV_1920_1080: "tv-full-hd",
    "4K_TV_3840_2160": "tv-4k",
}
/* eslint-enable @typescript-eslint/naming-convention */

export function deviceConfigForNode(
    node: CanvasNode,
    overrideTheme?: "dark" | "light"
): {
    devicePreset: DevicePresetID | "no-device"
    deviceOptions?: DeviceOptions
    responsive?: boolean
} {
    if (isDeviceNode(node)) {
        const controlProps = node.getControlProps()
        const deviceCodeComponentProps = getControlPropsValues(controlProps)
        return {
            devicePreset: deviceCodeComponentProps.preset,
            deviceOptions: convertPropsToDeviceOptions(
                {
                    ...deviceCodeComponentProps,
                    skin: "clay",
                },
                { forceOldClay: true }
            ),
        }
    } else if (withPreviewSettings(node)) {
        const devicePreset = devicePresetForNode(node)
        const skin = node.previewSettings?.deviceSkin ?? "realistic"
        const deviceOptions =
            devicePreset !== "no-device"
                ? convertPropsToDeviceOptions({
                      ...defaultDeviceProps,
                      preset: devicePreset,
                      skin,
                      theme: overrideTheme || "light",
                      colorId: node.previewSettings?.deviceColorId,
                      hand: isHandAllowed({ devicePreset, deviceSkin: skin })
                          ? node.previewSettings?.deviceHand
                          : undefined,
                      shadow: node.previewSettings?.deviceShadow ?? skin === "clay",
                  })
                : undefined

        return {
            responsive: node.previewSettings?.responsive,
            devicePreset,
            deviceOptions: deviceOptions && {
                ...deviceOptions,
                background: backgroundForPreviewSettings(node.previewSettings),
            },
        }
    }
    return { devicePreset: "no-device", deviceOptions: undefined }
}

/**
 * Determines the device preset to use for the given node.
 *
 * This might be either the preset explicitly set by the user, or an implicit
 * preset based on the frame preset.
 */
export function devicePresetForNode(node: CanvasNode & WithPreviewSettings): DevicePresetID | "no-device" {
    const hasExplicitDevicePreset = node.previewSettings?.devicePreset !== undefined

    const devicePresetId = node.previewSettings?.devicePreset ?? "no-device"
    const implicitDevicePresetId = getDevicePresetFromFramePreset(node)

    if (!experiments.isOn("unifiedPreview") || hasExplicitDevicePreset || !implicitDevicePresetId) {
        return devicePresetId
    }

    const implicitPreset = devicePresets.find(devicePreset => devicePreset.id === implicitDevicePresetId)
    if (!implicitPreset) return devicePresetId

    const isDevicePortrait = implicitPreset.screenWidth < implicitPreset.screenHeight

    const nodeRect = node.rect(null)
    const isNodePortrait = nodeRect.width < nodeRect.height

    if (isNodePortrait === isDevicePortrait) {
        return implicitDevicePresetId
    }

    return devicePresetId
}

export function getDevicePresetFromFramePreset(node: CanvasNode & WithPreviewSettings): DevicePresetID | undefined {
    if (isScreen(node) && withFramePreset(node) && node.framePreset) {
        return frameDeviceMappings[node.framePreset]
    } else {
        return undefined
    }
}

export function isHandAllowed({
    devicePreset: presetId,
    deviceSkin,
}: {
    devicePreset?: DevicePresetID | "no-device"
    deviceSkin?: "clay" | "realistic"
}) {
    if (!presetId || presetId === "no-device") return false
    const preset = getDevicePreset(presetId)
    return deviceSkin !== "clay" && preset.realisticImage?.handOffset !== undefined
}
