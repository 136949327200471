import { isFourNumberList } from "utils/isFourNumberList"
import { isNumber, isObject } from "utils/typeChecks"

// Different from the Transition type in motion, the control value has all the default types because it can be spring or tween
// so if you're in ease, set some settings, then go to spring, set other settings, and then go back to ease, your settings are still saved
export interface Transition {
    type: "spring" | "tween"
    ease: [number, number, number, number]
    duration: number
    delay: number
    stiffness: number
    damping: number
    mass: number
}

export const fallbackTransition: Transition = {
    type: "spring",
    ease: [0.44, 0, 0.56, 1],
    duration: 0.3,
    delay: 0,
    stiffness: 500,
    damping: 60,
    mass: 1,
}
Object.freeze(fallbackTransition)

const transitionTypeKey: keyof Transition = "type"
const transitionEaseKey: keyof Transition = "ease"
const transitionDurationKey: keyof Transition = "duration"
const transitionDelayKey: keyof Transition = "delay"
const transitionStiffnessKey: keyof Transition = "stiffness"
const transitionDampingKey: keyof Transition = "damping"
const transitionMassKey: keyof Transition = "mass"
const transitionNumberKeys = [
    transitionDurationKey,
    transitionDelayKey,
    transitionStiffnessKey,
    transitionDampingKey,
    transitionMassKey,
]

function isValidTransitionType(value: unknown): value is Transition["type"] {
    return value === "spring" || value === "tween"
}

export function isTransition(value: unknown): value is Transition {
    if (!isObject(value)) return false
    if (!isValidTransitionType(value[transitionTypeKey])) return false
    if (!isFourNumberList(value[transitionEaseKey])) return false
    return transitionNumberKeys.every(numberKey => isNumber(value[numberKey]))
}

export function isPartialTransition(value: unknown): value is Partial<Transition> {
    if (!isObject(value)) return false
    if (isValidTransitionType(value[transitionTypeKey])) return true
    if (isFourNumberList(value[transitionEaseKey])) return true
    return transitionNumberKeys.some(numberKey => isNumber(value[numberKey]))
}

export const toTransition = (value: Partial<Transition>): Transition => {
    const result = { ...fallbackTransition }

    const type = value[transitionTypeKey]
    if (isValidTransitionType(type)) {
        result[transitionTypeKey] = type
    }

    const ease = value[transitionEaseKey]
    if (isFourNumberList(ease)) {
        result[transitionEaseKey] = ease
    }

    for (const numberKey of transitionNumberKeys) {
        const numberValue = value[numberKey]
        if (isNumber(numberValue)) {
            result[numberKey] = numberValue
        }
    }

    return result
}
