import * as React from "react"
import { useMemo, useRef, useCallback } from "react"
import { useMotionValue, useDragControls, DraggableProps, PanInfo, BoundingBox2D } from "framer-motion"
import sync from "framesync"
import { ControlType } from "../../render/types/PropertyControls"
import { FrameWithMotion } from "../../render/presentation/Frame/FrameWithMotion"
import { EmptyState } from "../../components/EmptyState"
import { addPropertyControls } from "../../utils/addPropertyControls"
import { isMotionValue } from "../../render/utils/isMotionValue"
import { RenderTarget } from "../../render/types/RenderEnvironment"
import { isFiniteNumber } from "../../render/utils/isFiniteNumber"
import { ScrollProps } from "./types"
import { useWheelScroll } from "./useWheelScroll"
import { useLayoutId } from "../../render/utils/useLayoutId"
export type { ScrollProps, ScrollEvents, ScrollConfig } from "./types"

const directionMap: { [key: string]: DraggableProps["drag"] } = {
    horizontal: "x",
    vertical: "y",
    both: true,
}

function convertScrollDirectionToDrag(scrollDirection?: "horizontal" | "vertical" | "both") {
    return scrollDirection ? directionMap[scrollDirection] : scrollDirection
}

const useUpdateChildSize = (
    dragDirection: "both" | "horizontal" | "vertical",
    children: React.ReactNode
): React.ReactNode | React.ReactNode[] => {
    return useMemo((): React.ReactNode | React.ReactChild[] => {
        return React.Children.map(children, (child: React.ReactChild) => {
            if (child === null || typeof child !== "object" || typeof child.type === "string") {
                return child
            }

            const update: Partial<{ width: number | string; height: number | string }> = {}
            switch (dragDirection) {
                case "vertical":
                    update.width = "100%"
                    break
                case "horizontal":
                    update.height = "100%"
                    break
                default:
                    return child
            }

            return React.cloneElement(child, update)
        })
    }, [dragDirection, children])
}

/**
 * @public
 */
export function Scroll(props: ScrollProps) {
    const {
        direction = "vertical",
        directionLock = false,
        dragEnabled: dragEnabled = true,
        dragElastic,
        dragMomentum,
        dragTransition,
        wheelEnabled = true,
        contentOffsetX = 0,
        contentOffsetY = 0,
        contentWidth,
        contentHeight,
        onScrollStart,
        onScroll,
        onScrollEnd,
        onDragStart,
        onDrag,
        onDragEnd,
        onUpdate,
        onDirectionLock,
        style,
        children,
        scrollAnimate,
        __layoutId,
        ...containerProps
    } = props
    const layoutId = useLayoutId(props, __layoutId, "scroll")
    const defaultX = useMotionValue(typeof contentOffsetX === "number" ? contentOffsetX : 0)
    const defaultY = useMotionValue(typeof contentOffsetY === "number" ? contentOffsetY : 0)
    const x = isMotionValue(contentOffsetX) ? contentOffsetX : defaultX
    const y = isMotionValue(contentOffsetY) ? contentOffsetY : defaultY
    const measuredConstraints = useRef<null | BoundingBox2D>(null)
    const dragControls = useDragControls()

    React.useEffect(() => {
        dragControls.updateConstraints()
    })

    function setMeasureDragConstraints(constraints: BoundingBox2D) {
        constraints = offsetToZero(constraints)

        if (contentWidth !== undefined) constraints.left = -contentWidth
        if (contentHeight !== undefined) constraints.top = -contentHeight

        return (measuredConstraints.current = constraints)
    }

    const { initial, prev } = useRef({
        initial: { x: 0, y: 0 },
        prev: { x: 0, y: 0 },
    }).current

    const isPreview = RenderTarget.current() === RenderTarget.preview

    const containerRef = useRef<HTMLDivElement>(null)

    const getLatestPoint = () => ({ x: x.get(), y: y.get() })

    const resetInitialPoint = useCallback(() => {
        const point = getLatestPoint()
        initial.x = point.x
        initial.y = point.y
        prev.x = point.x
        prev.y = point.y
    }, [])

    const getPointData = useCallback(() => {
        const point = getLatestPoint()

        const data = {
            point,
            velocity: { x: x.getVelocity(), y: y.getVelocity() },
            offset: { x: point.x - initial.x, y: point.y - initial.y },
            delta: { x: point.x - prev.x, y: point.y - prev.y },
        }

        prev.x = point.x
        prev.y = point.y

        return data
    }, [x, y])

    const updateScrollListeners = useCallback(() => {
        onUpdate && onUpdate({ x: x.get(), y: y.get() })
        onScroll && onScroll(getPointData())
    }, [onScroll, onUpdate, getPointData, x, y])

    const scheduleUpdateScrollListeners = useCallback(() => {
        sync.update(updateScrollListeners, false, true)
    }, [updateScrollListeners])

    const onMotionDragStart = (event: MouseEvent | TouchEvent, info: PanInfo) => {
        resetInitialPoint()
        onDragStart && onDragStart(event, info)
        onScrollStart && onScrollStart(info)
    }

    const onMotionDragTransitionEnd = () => onScrollEnd && onScrollEnd(getPointData())

    useWheelScroll(containerRef, {
        enabled: wheelEnabled,
        initial,
        prev,
        direction,
        offsetX: x,
        offsetY: y,
        onScrollStart,
        onScroll,
        onScrollEnd,
        constraints: measuredConstraints,
    })

    const overdragX = useMotionValue(0)
    const overdragY = useMotionValue(0)

    React.useLayoutEffect(() => {
        const setScrollX = (xValue: number) => {
            const element = containerRef.current
            if (!(element instanceof HTMLDivElement)) return
            element.scrollLeft = -xValue

            const constraints = measuredConstraints.current
            if (constraints) {
                let overdragXValue = 0
                if (xValue > constraints.right) overdragXValue = xValue
                if (xValue < constraints.left) overdragXValue = xValue - constraints.left
                overdragX.set(overdragXValue)
            }

            scheduleUpdateScrollListeners()
        }

        const currentX = x.get()
        if (currentX !== 0) setScrollX(currentX)

        return x.onChange(setScrollX)
    }, [x, overdragX, scheduleUpdateScrollListeners])

    React.useLayoutEffect(() => {
        const setScrollY = (yValue: number) => {
            const element = containerRef.current
            if (!(element instanceof HTMLDivElement)) return
            element.scrollTop = -yValue

            const constraints = measuredConstraints.current
            if (constraints) {
                let overdragYValue = 0

                if (yValue > constraints.bottom) overdragYValue = yValue
                if (yValue < constraints.top) overdragYValue = yValue - constraints.top
                overdragY.set(overdragYValue)
            }

            scheduleUpdateScrollListeners()
        }

        const currentY = y.get()
        if (currentY !== 0) setScrollY(currentY)

        return y.onChange(setScrollY)
    }, [y, overdragY, scheduleUpdateScrollListeners])

    const nativeOnScroll = React.useCallback(() => {
        const element = containerRef.current
        if (!(element instanceof HTMLDivElement)) return
        // we ignore native scroll changes when we are dragging or finishing a drag animation
        const xDelta = Math.abs(x.get() + element.scrollLeft)
        const yDelta = Math.abs(y.get() + element.scrollTop)
        if (xDelta > 1) x.set(-element.scrollLeft)
        if (yDelta > 1) y.set(-element.scrollTop)
    }, [x, y])

    const isEmpty = React.Children.count(children) === 0
    const width = direction !== "vertical" && !isEmpty ? "auto" : "100%"
    const height = direction !== "horizontal" && !isEmpty ? "auto" : "100%"
    const size = !containerProps.__fromCanvasComponent
        ? {
              width: containerProps.__fromCodeComponentNode ? "100%" : containerProps.width,
              height: containerProps.__fromCodeComponentNode ? "100%" : containerProps.height,
          }
        : {}

    return (
        <FrameWithMotion
            data-framer-component-type="Scroll"
            background="none" // need to set here to prevent default background from Frame
            {...containerProps}
            {...size}
            style={{
                ...style,
                willChange: isPreview ? "transform" : undefined, // allows the scroll content to be hardware accelerated
                overflow: "hidden",
            }}
            onScroll={nativeOnScroll}
            preserve3d={containerProps.preserve3d}
            ref={containerRef}
            layoutId={layoutId}
        >
            <FrameWithMotion
                data-framer-component-type="ScrollContentWrapper"
                animate={scrollAnimate}
                drag={dragEnabled && convertScrollDirectionToDrag(direction)}
                dragDirectionLock={directionLock}
                dragElastic={dragElastic}
                dragMomentum={dragMomentum}
                dragTransition={dragTransition}
                dragConstraints={containerRef}
                dragControls={dragControls}
                onDragStart={onMotionDragStart}
                onDrag={onDrag}
                onDragEnd={onDragEnd}
                onDragTransitionEnd={onMotionDragTransitionEnd}
                onDirectionLock={onDirectionLock}
                onMeasureDragConstraints={setMeasureDragConstraints}
                width={width}
                height={height}
                _dragX={x}
                _dragY={y}
                position="relative"
                x={overdragX}
                y={overdragY}
                style={{
                    display: isEmpty ? "block" : "inline-block",
                    willChange: isPreview ? "transform" : undefined, // makes the scroll content hardware accelerated
                    backgroundColor: "transparent",
                    overflow: "visible",
                    minWidth: "100%",
                    minHeight: "100%",
                }}
                preserve3d={containerProps.preserve3d}
            >
                <EmptyState
                    children={children!}
                    size={{
                        width: isFiniteNumber(containerProps.width) ? containerProps.width : "100%",
                        height: isFiniteNumber(containerProps.height) ? containerProps.height : "100%",
                    }}
                    insideUserCodeComponent={!containerProps.__fromCodeComponentNode}
                    title="Scroll"
                    description="Click and drag the connector to any frame on the canvas →"
                />
                {useUpdateChildSize(direction, children)}
            </FrameWithMotion>
        </FrameWithMotion>
    )
}

/**
 * Because we're overriding the usual drag x/y with scrollTop and scrollLeft
 * our constraints calculations need rebasing to 0
 */
function offsetToZero({ top, left, right, bottom }: BoundingBox2D) {
    const width = right - left
    const height = bottom - top

    return {
        top: -height,
        left: -width,
        right: 0,
        bottom: 0,
    }
}

addPropertyControls(Scroll, {
    direction: {
        type: ControlType.SegmentedEnum,
        title: "Direction",
        options: ["vertical", "horizontal", "both"],
        defaultValue: "vertical",
    },
    directionLock: {
        type: ControlType.Boolean,
        title: "Lock",
        enabledTitle: "1 Axis",
        disabledTitle: "Off",
        defaultValue: true,
    },
    dragEnabled: {
        type: ControlType.Boolean,
        title: "Drag",
        enabledTitle: "On",
        disabledTitle: "Off",
        defaultValue: true,
    },
    wheelEnabled: {
        type: ControlType.Boolean,
        title: "Wheel",
        enabledTitle: "On",
        disabledTitle: "Off",
        defaultValue: true,
    },
})
;(Scroll as any).supportsConstraints = true
