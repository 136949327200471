import { ControlDescription, ControlType, ImageControlDescription, PropertyControls } from "framer"
import { activeVariantKey } from "document/models/CanvasTree/nodes/CanvasComponentNode"
import { safeProperty } from "utils/names"
import { isString, isUndefined } from "utils/typeChecks"
import { DefaultAssetReferenceKey } from "@framerjs/framer-runtime/sandbox"

const reverseControlTypeMap: Record<ControlType, string> = {
    [ControlType.Boolean]: "ControlType.Boolean",
    [ControlType.Number]: "ControlType.Number",
    [ControlType.String]: "ControlType.String",
    [ControlType.FusedNumber]: "ControlType.FusedNumber",
    [ControlType.Enum]: "ControlType.Enum",
    [ControlType.SegmentedEnum]: "ControlType.SegmentedEnum",
    [ControlType.Color]: "ControlType.Color",
    [ControlType.Image]: "ControlType.Image",
    [ControlType.File]: "ControlType.File",
    [ControlType.ComponentInstance]: "ControlType.ComponentInstance",
    [ControlType.Array]: "ControlType.Array",
    [ControlType.EventHandler]: "ControlType.EventHandler",
    [ControlType.Transition]: "ControlType.Transition",
    [ControlType.Object]: "ControlType.Object",
}

// This key maps 1:1 with the arguments of useVariantState in library.
export const initialVariantKey = "variant"

function withDefaultAssetReference(
    control: ControlDescription
): control is ImageControlDescription & { defaultValue?: string } {
    return control.type === ControlType.Image
}

export function serializePropertyControls(
    propertyControls: PropertyControls = {}
): {
    controls: Record<string, { [key: string]: unknown }>
    props: string
    propVariables: Record<string, string>
    humanReadableVariantMap: Record<string, string>
} {
    const controls: Record<string, { [key: string]: unknown }> = {}
    const props: string[] = []
    const propVariables: Record<string, string> = {}
    const nameMap = new Map<string, number>()

    for (const controlKey in propertyControls) {
        const control = propertyControls[controlKey]

        if (!control) continue

        let key = controlKey
        const isVariantControlKey = key === activeVariantKey
        if (isVariantControlKey) key = initialVariantKey

        let name = safeProperty(control.title) || key
        const count = nameMap.get(name) ?? 0

        if (count === 0) {
            nameMap.set(name, 1)
        } else {
            nameMap.set(name, count + 1)
            name = `${name}${count}`
        }

        if (!isVariantControlKey) propVariables[key] = name

        controls[key] = {
            ...control,
            type: reverseControlTypeMap[control.type],
        }

        let prop = name
        if (key !== name) prop = `${prop}: ${key}`

        if ("defaultValue" in control && !isUndefined(control.defaultValue)) {
            prop = `${prop} = ${JSON.stringify(control.defaultValue)}`
        }

        // We store defaultValue on image variables to allow us to easily set
        // the default value in the generated template, but it is not a valid
        // property control so we should exclude it from the template, instead
        // setting a private __defaultAssetReference key.
        if (withDefaultAssetReference(control)) {
            controls[key].defaultValue = undefined
            if (!isUndefined(control.defaultValue)) controls[key][DefaultAssetReferenceKey] = control.defaultValue
        }

        /**
         * @FIXME
         * It is possible to push a prop with a duplicate name. We should make
         * that impossible somehow.
         */
        if (!isVariantControlKey) props.push(prop)
    }

    const humanReadableVariantMap = {}
    if (activeVariantKey in propertyControls) {
        const variantControl = propertyControls[activeVariantKey]
        if (variantControl?.type === ControlType.Enum) {
            variantControl.options.forEach((id, index) => {
                if (isString(id)) {
                    const value = id
                    const key = variantControl.optionTitles?.[index] ?? id
                    humanReadableVariantMap[key] = value
                }
            })
        }
    }

    return { controls, propVariables, props: props.join(", "), humanReadableVariantMap }
}
