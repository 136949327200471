import type { Point } from "framer"

namespace Matrix {
    export const convertPoint = (matrix: DOMMatrixReadOnly, point: Point): Point => {
        const { x, y } = point
        const z = 0
        let w = matrix.m14 * x + matrix.m24 * y + matrix.m34 * z + matrix.m44
        w = w || 1
        return {
            x: (matrix.m11 * x + matrix.m21 * y + matrix.m31 * z + matrix.m41) / w,
            y: (matrix.m12 * x + matrix.m22 * y + matrix.m32 * z + matrix.m42) / w,
            // z: (matrix.m13 * x + matrix.m23 * y + matrix.m33 * z + matrix.m43) / w
        }
    }

    export function equals(m: DOMMatrixReadOnly | null, n: DOMMatrixReadOnly | null) {
        if (m === n) return true
        if (!m || !n) return false
        if (m.isIdentity) return n.isIdentity
        if (
            // Compare tx, ty first since they're most likely to be different.
            m.m41 !== n.m41 ||
            m.m42 !== n.m42 ||
            // Compare a, b, c, d (2D components).
            m.m11 !== n.m11 ||
            m.m12 !== n.m12 ||
            m.m21 !== n.m21 ||
            m.m22 !== n.m22
        ) {
            return false
        }
        // As long as we're only dealing with 2D we don't need to check more.
        if (m.is2D && n.is2D) return true
        // Check the rest of the components.
        return (
            m.m13 === n.m13 &&
            m.m14 === n.m14 &&
            m.m23 === n.m23 &&
            m.m24 === n.m24 &&
            m.m31 === n.m31 &&
            m.m32 === n.m32 &&
            m.m33 === n.m33 &&
            m.m34 === n.m34 &&
            m.m43 === n.m43 &&
            m.m44 === n.m44
        )
    }

    // Chrome differs in rounding behavior when a translation-only vs mixed
    // transform matrix is used, so this function is mostly used as a fast way
    // to check whether there are any non-translation transforms without
    // decomposing the matrix.
    export const hasNonTranslationTransform = (m: Partial<DOMMatrixReadOnly>) => {
        return (
            m.m11 !== 1 ||
            m.m12 !== 0 ||
            m.m13 !== 0 ||
            m.m14 !== 0 ||
            m.m21 !== 0 ||
            m.m22 !== 1 ||
            m.m23 !== 0 ||
            m.m24 !== 0 ||
            m.m31 !== 0 ||
            m.m32 !== 0 ||
            m.m33 !== 1 ||
            m.m34 !== 0 ||
            m.m44 !== 1
        )
    }
}

export default Matrix
