import { getLogger } from "@framerjs/shared"
import type { CanvasTree } from "../models/CanvasTree"
import { safeLoadDocument } from "document/models/CanvasTree/TreeRepair"
import type { VekterEngine } from "document/VekterEngine"

export const log = getLogger("app")

export function serializeDocument(engine: VekterEngine): Record<string, unknown> | null {
    // Never serialize a tree that doesn't actually reflect user data
    if (!engine.treeReflectsDocument) {
        return null
    }
    return serializeTree(engine.tree)
}

export function serializeTree(tree: CanvasTree) {
    return tree.toJS()
}

// a function that translates the document into a simple json representation, keeping only the most important properties
export function snapshotDocument(engine: VekterEngine): any {
    function remap(node: any): any {
        const { __class, width, height, top, bottom, left, right } = node
        let { children } = node
        if (!children) {
            if (node.styledText) {
                return {
                    __class,
                    width,
                    height,
                    top,
                    bottom,
                    left,
                    right,
                    text: node.styledText.blocks.map((b: any) => b.text),
                }
            }
            return { __class, width, height, top, bottom, left, right }
        }

        children = children.map(remap)
        return { __class, width, height, top, bottom, left, right, children }
    }
    return remap(engine.tree.toJS().root)
}

export function loadDocument(url: URL | string): CanvasTree {
    let documentJSON: object | undefined = undefined
    const xhr = new XMLHttpRequest()
    xhr.open("GET", url.toString(), false)
    try {
        xhr.send()
        documentJSON = JSON.parse(xhr.responseText)
    } catch (exception) {
        log.error(`Retrieving document “${url}” failed. (${exception})`)
    }
    return loadDocumentJSON(documentJSON)
}

function reportErrors(errors: string[]) {
    errors.forEach(error => {
        log.warn("[repaired]", error)
    })
}

export function loadDocumentJSON(doc: any): CanvasTree {
    const errors: string[] = []
    try {
        const tree = safeLoadDocument(doc, errors)
        reportErrors(errors)
        return tree
    } catch (e) {
        reportErrors(errors)
        log.warn("tree failed to verify:", e)
        throw e
    }
}
