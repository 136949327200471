import type { ServiceChannel } from "./ServiceChannel"
import { assertNever } from "./private"

// ♻️
// IMPORTANT: Take care to apply changes to all supported languages
// when modifying code or documentation in the services runtime.

/**
 * Service runtime or service implementation errors.
 */
export class ServiceError extends Error {
    readonly code: ServiceError.Code = ServiceError.Code.badResponse
    readonly name: string = "ServiceError.BadResponse"
}

export namespace ServiceError {
    export const enum Code {
        serviceNotFound = 404,
        serviceNotCompatible = 426,
        serviceGone = 410,
        implementation = 500,
        timedOut = 504,
        badRequest = 400,
        badResponse = 422,
    }

    export class ServiceNotFound extends ServiceError {
        // Service was probably not registered, or using the wrong channel
        readonly code = Code.serviceNotFound
        readonly name = "ServiceError.ServiceNotFound"
    }

    export class ServiceNotCompatible extends ServiceError {
        // Service interface is different on either end of the channel
        readonly code = Code.serviceNotCompatible
        readonly name = "ServiceError.ServiceNotCompatible"
    }

    export class ServiceGone extends ServiceError {
        // Service was unregistered
        readonly code = Code.serviceGone
        readonly name = "ServiceError.ServiceGone"
    }

    export class Implementation extends ServiceError {
        readonly code = Code.implementation
        readonly name = "ServiceError.Implementation"
    }

    export class TimedOut extends ServiceError {
        readonly code = Code.timedOut
        readonly name = "ServiceError.TimedOut"
    }

    export class BadRequest extends ServiceError {
        readonly code: Code = Code.badRequest
        readonly name: string = "ServiceError.BadRequest"
    }

    export const BadResponse = ServiceError

    export function reconstructErrorResponse(error: ServiceChannel.MessageBody | undefined): ServiceError {
        if (!error) return new BadResponse()

        let message: string | undefined
        if (hasMessage(error)) {
            message = error.message
        }

        const reconstructedError = errorFromCode(error.code, message)
        if (hasStack(error)) {
            reconstructedError.stack = error.stack
        }

        return reconstructedError
    }

    function errorFromCode(code: unknown, message?: string): ServiceError {
        try {
            // Cast to ServiceError.Code to get exhaustiveness checking, but could be anything
            const maybeCode = code as ServiceError.Code
            switch (maybeCode) {
                case ServiceError.Code.serviceNotFound:
                    return new ServiceNotFound(message)
                case ServiceError.Code.serviceNotCompatible:
                    return new ServiceNotCompatible(message)
                case ServiceError.Code.serviceGone:
                    return new ServiceGone(message)
                case ServiceError.Code.implementation:
                    return new Implementation(message)
                case ServiceError.Code.timedOut:
                    return new TimedOut(message)
                case ServiceError.Code.badRequest:
                    return new BadRequest(message)
                case ServiceError.Code.badResponse:
                    return new BadResponse(message)
                default:
                    assertNever(maybeCode)
            }
        } catch {
            // Something other than a valid error code
            return new BadResponse(message)
        }
    }

    export function toMessageBody(error: unknown): { code: number; message?: string; stack?: string } {
        if (error instanceof ServiceError) {
            return { code: error.code, message: error.message, stack: error.stack }
        }

        let message: string | undefined
        let stack: string | undefined
        if (typeof error === "string") {
            message = error
        } else if (hasMessage(error)) {
            message = error.message
        }
        if (hasStack(error)) {
            stack = error.stack
        }

        return { code: ServiceError.Code.implementation, message, stack }
    }
}

function hasMessage<T>(error: T): error is T & { message: string } {
    return error && "message" in error && typeof ((error as unknown) as { message: unknown }).message === "string"
}

function hasStack<T>(error: T): error is T & { stack: string } {
    return error && "stack" in error && typeof ((error as unknown) as { stack: unknown }).stack === "string"
}
