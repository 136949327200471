import { TokenDefinition } from "framer"
import * as Immutable from "immutable"
import { List, OrderedMap, Set } from "immutable"
import { getLogger } from "@framerjs/shared"

import type { MaybeNodeID } from ".."
import { StyledTextDraft } from "document/models/StyledText/StyledTextDraft"
import { isFrameEvent } from "../traits/FrameEvents"
import { withCodeComponent } from "../traits/CodeComponent"
import type { ReplicaNodeOverrides } from "../traits/Template"
import type { ReplicaInfo } from "../traits/Template"
import { getClassByClassName, getImmutableRecordSubclassByClassName, getPojoObjectByName } from "./classList"
import type { CanvasNode, CanvasNodeWithTemplate, CanvasNodeWithTokens } from "./CanvasNode"
import { isCodeComponentNode } from "./CodeComponentNode"
import { ignoredOverrideKeys } from "./TemplateHelper"
import { resetDeterministicID } from "./utils/deterministicID"
import { isCodeComponentProp } from "../traits/utils/codeComponentProps"
import type { ControlProp } from "../traits/CodeComponent"
import { isPropertyWithPlainJavascriptData } from "../utils/isPropertyWithPlainJavascriptData"
import type { RawDraftContentState } from "draft-js"
import { ValueObject } from "utils/readonly"

const log = getLogger("tree")

export const propertiesToAdapt = { shadows: true, boxShadows: true }

// Take a plain javascript data structure, probably from JSON.parse(), and morph all Objects into their actual
// classess depending on their __class property and setting their prototype. It assumes this can be done and
// throws an exception otherwise.
export function adaptValue(value: any): any {
    if (!value) return value
    if (typeof value !== "object") return value
    if (Array.isArray(value)) {
        for (let i = 0, il = value.length; i < il; i++) {
            adaptValue(value[i])
        }
        return value
    } else if (value.__class) {
        const template = getPojoObjectByName(value.__class)
        if (template) return ValueObject.morphUsingTemplate(value, template)
        throw Error("unknown __class to adapt: " + value.class)
    } else {
        return value
    }
}

export function canvasNodeFromValue(
    value: unknown,
    errors: string[] = [],
    parentid: MaybeNodeID = null
): CanvasNode | null {
    if (typeof value !== "object" || value === null) return null

    if (!withId(value)) {
        errors.push("node missing id")
        return null
    }
    const id = value.id

    if (!withClass(value)) {
        errors.push(`${id}: node missing __class`)
        return null
    }
    const className = value.__class

    const constructor = getClassByClassName(className)

    if (!constructor) {
        errors.push(`${id}: unknown node class ${className}`)
        return null
    }

    if (withParentId(value) && value.parentid !== parentid) {
        errors.push(`${id}: parentid is different from parent. ${value.parentid} !== ${parentid}`)
        // harmless
    }

    const node = new (constructor as typeof CanvasNode)()
    resetDeterministicID(id)

    // The code component identifier needs to be known for stylable traits
    // If we postpone this, borders and shadows etc. of Stack, Page, and Scroll are lost on load
    if (isCodeComponentNode(node) && withCodeComponent(value)) {
        node.codeComponentIdentifier = value.codeComponentIdentifier
    }

    for (const key in value) {
        if (key === "children") continue
        if (key.startsWith("_")) continue

        // TODO should add this again
        // if (!(node as Object).hasOwnProperty(key)) continue

        if (propertiesToAdapt[key]) {
            node[key] = adaptValue(value[key])
        } else if (key === "guidesX" || key === "guidesY") {
            node[key] = reviveGuidesInfo(value[key])
        } else if (isFrameEvent(key) && Array.isArray(value[key])) {
            node[key] = value[key]
        } else if (key === "replicaInfo") {
            ;(node as CanvasNodeWithTemplate).replicaInfo = withReplicaInfo(value)
                ? reviveReplicaInfo(value[key])
                : null
        } else if (key === "tokens" && withTokens(value)) {
            ;(node as CanvasNodeWithTokens).tokens = reviveTokens(value[key], value.tokensIndex)
        } else if (key === "tokensIndex") {
            // Do nothing, this is handled when processing "tokens"
        } else if (isPropertyWithPlainJavascriptData(key)) {
            node[key] = value[key]
        } else if (isCodeComponentProp(key)) {
            node[key] = reviveCodeComponentProp(value[key])
        } else if (key in node) {
            node[key] = revive(value[key])
        } else {
            errors.push(`${id} Not setting key '${key}' in object '${className}', value: ${value[key]}`)
        }
    }

    if ("parentid" in node) {
        node.parentid = parentid
    }

    if ("mutable" in node) {
        node.mutable = true
    }

    // Some documents and some imports have names that aren't strings but objects.
    // This was caused by a bug in the Sketch importer and previously fixed via a migration.
    if (node.name && typeof node.name !== "string") {
        node.name = null
    }

    if (node.originalid) return node

    if (!withChildren(value) || !withChildren(node)) return node

    node.children = value.children.map(v => canvasNodeFromValue(v, errors, id)).filter(v => !!v) as CanvasNode[]

    return node
}

export function reviveCodeComponentProp(controlProp: ControlProp) {
    const { value } = controlProp
    if (typeof value !== "object" || value === null || !withClass(value)) return controlProp
    const constructor = getClassByClassName(value.__class)
    if (!constructor) return controlProp
    // FIXME: can we replace any with a meaningful type here?
    // According to research this can be either Node or Immutable.Record
    // but TypeScript isn't happy with CanvasNodeSubclass | ImmutableRecordSubclass, send help...
    return { type: controlProp.type, value: new (constructor as any)(value) }
}

function withId<T extends object>(obj: T | null | undefined): obj is T & { id: string } {
    return !!obj && typeof obj["id"] === "string"
}

function withClass<T extends object>(obj: T | null | undefined): obj is T & { __class: string } {
    return !!obj && typeof obj["__class"] === "string"
}

function withParentId<T extends object>(obj: T | null | undefined): obj is T & { parentid: string } {
    return !!obj && typeof obj["parentid"] === "string"
}

function withReplicaInfo<T extends object>(obj: T | null | undefined): obj is T & { replicaInfo: ReplicaInfo } {
    return !!obj && typeof obj["replicaInfo"] === "object" && obj["replicaInfo"] !== null
}

function withTokens<T extends object>(
    obj: object | null | undefined
): obj is T & { tokens: Record<string, TokenDefinition>; tokensIndex?: string[] } {
    return !!obj && typeof obj["tokens"] === "object" && obj["tokens"] !== null
}

function withChildren<T extends object>(obj: T | null | undefined): obj is T & { children: unknown[] } {
    return !!obj && Array.isArray(obj["children"])
}

export function revive(value: unknown) {
    // we shortcut how we load styled texts, so it can be lazy when to turn the data into an actual editor
    if (
        typeof value === "object" &&
        value !== null &&
        // Check that it’s a plain object (i.e., not a MutableNode instance or something)
        value.constructor === Object &&
        withClass(value)
    ) {
        const className = value.__class
        if (className === "StyledTextDraft") {
            return StyledTextDraft.createFromData((value as unknown) as RawDraftContentState)
        }
        const template = getPojoObjectByName(className)
        if (template) return ValueObject.morphUsingTemplate(value, template)
    }

    return Immutable.fromJS(value, immutableReviver)
}

export function reviveGuidesInfo(info: readonly number[]): Set<number> {
    return Set(info)
}

export function reviveReplicaInfo(info: ReplicaInfo): ReplicaInfo {
    if (!info) return info

    const { overrides } = info
    const revived = {}
    for (const nodeKey in overrides) {
        const override = overrides[nodeKey]
        const revivedNode: ReplicaNodeOverrides = {}

        // Handle _deleted specifically, because it is in ignoredOverrideKeys
        if (override._deleted) {
            revivedNode._deleted = override._deleted
        }

        for (const propKey in override) {
            if (ignoredOverrideKeys[propKey]) continue

            if (propertiesToAdapt[propKey]) {
                revivedNode[propKey] = adaptValue(override[propKey])
            } else if (isPropertyWithPlainJavascriptData(propKey)) {
                revivedNode[propKey] = override[propKey]
            } else if (isFrameEvent(propKey)) {
                revivedNode[propKey] = override[propKey]
            } else if (propKey === "guidesX" || propKey === "guidesY") {
                revivedNode[propKey] = reviveGuidesInfo(override[propKey])
            } else if (isCodeComponentProp(propKey)) {
                revivedNode[propKey] = reviveCodeComponentProp(override[propKey])
            } else {
                revivedNode[propKey] = revive(override[propKey])
            }
        }

        // Don't take on board empty overrides.
        if (Object.keys(revivedNode).length === 0) continue
        revived[nodeKey] = revivedNode
    }
    return { ...info, overrides: revived }
}

export function reviveTokens(
    tokens: { [key: string]: TokenDefinition },
    index?: string[]
): OrderedMap<string, CanvasNode> {
    // Fallback to the keys of the tokens object to migrate older documents
    // missing the index.
    index = index || Object.keys(tokens)
    const pairs: [string, CanvasNode][] = []

    for (const key of index) {
        const value = tokens[key]
        const node = canvasNodeFromValue(value)
        if (!node) {
            log.warn(`Could not revive token: ${value.name}`)
            continue
        }
        pairs.push([node.id, node])
    }
    return OrderedMap(pairs)
}

// we use immutablejs for anything except Node and Node.children
function immutableReviver(
    key: string | number,
    value: Immutable.Collection.Keyed<string, unknown> | Immutable.Collection.Indexed<unknown>
) {
    const className = (value as Immutable.Iterable<string, string | undefined>).get("__class")
    if (!className) {
        // Callbacks of arrays will end up here, the original array will be in `this`, but key information is lost, unfortunately.
        if (Immutable.Iterable.isIndexed(value)) {
            return List(value)
        } else {
            return value
        }
    }

    const constructor = getImmutableRecordSubclassByClassName(className)
    if (!constructor) {
        const template = getPojoObjectByName(className)
        if (template) return ValueObject.morphUsingTemplate(value.toObject(), template)
        return value
    }

    return new constructor(value as Immutable.Iterable<string, unknown>)
}
