import { ModulesState } from "@framerjs/framer-services"
import { Patch } from "@framerjs/vekter-web-merge/src/lib/immer"

export const enum ModuleMetadata {
    OriginalSymbolId = "originalSymbolId",
    PackageDependencies = "packageDependencies",
}

export const enum ModuleType {
    Canvas = "canvasComponent",
    Code = "codeFile",
}

export type RelativeFilePath = string

/** "$type/$name" */
export type TypeSlashName = string

/**
 * 20-character-long ID containing alphanumeric (upper case and lower case) characters.
 */
export type GlobalModuleID = Opaque<string, "GlobalModuleID">
export function asGlobalID(id: string): GlobalModuleID {
    return id as GlobalModuleID
}

/**
 * ID that includes the module's type and a locally unique ID containing 7 alphanumeric characters or "_".
 * E.g. "codeFile/AbcD_23"
 */
export type LocalModuleID = Opaque<string, "LocalModuleID">
export function asLocalID(id: string): LocalModuleID {
    return id as LocalModuleID
}

/**
 * 20-character-long ID containing alphanumeric (upper case and lower case) characters.
 */
export type SaveID = string

export type ModulesUpdateEvent = Omit<ModulesState.ModulesUpdateEvent, "patches" | "revision"> & {
    // We are using a more concrete type for patches, because the ipc version of it is incomplete
    // due to the limitations of the ipc type system.
    patches: Patch[]
}

type Opaque<T, LABEL extends string> = T & { readonly __OPAQUE__: LABEL }
