import * as React from "react"
import { isFiniteNumber } from "./utils/isFiniteNumber"
import { frescoSettingsContext } from "./FrescoSettings"
import { SegmentedControl, SegmentedControlItem } from "./SegmentedControl"
import { IconMinus, IconPlus } from "../"
import { OverrideType, HTMLDivAttributes } from "@framerjs/fresco/src/components/types"

export type StepperProps = OverrideType<
    HTMLDivAttributes,
    {
        /** The value. */
        value: number | null | undefined
        /** Called when the value changes. */
        onChange: (value: number) => void
        /** Lower limit. */
        min?: number
        /** Upper limit. */
        max?: number
        /** Step size when using arrow keys. */
        step?: number | "nudge"
    }
>

export const Stepper = React.memo(
    React.forwardRef<HTMLDivElement, StepperProps>(function Stepper(
        { value, onChange, min, max, step = 1, ...rest },
        forwardedRef
    ) {
        let stepDownEnabled = true
        let stepUpEnabled = true

        const { smallNudgeIncrement } = React.useContext(frescoSettingsContext)

        const smallStepIncrement = step === "nudge" ? smallNudgeIncrement : step

        const incrementValue = (change: -1 | 1) => {
            const stepSize = smallStepIncrement
            let newValue = isFiniteNumber(value) ? value + change * (stepSize || 1) : 0

            if (isFiniteNumber(min)) {
                newValue = Math.max(newValue, min)
            }
            if (isFiniteNumber(max)) {
                newValue = Math.min(newValue, max)
            }

            // Try to prevent JS rounding -0.2 to -0.19999999... and other shenanigans
            const factor = Math.pow(10, 7) // 32 bit precision
            newValue = Math.round(newValue * factor) / factor
            onChange(newValue)
        }

        const onStepDown = () => incrementValue(-1)

        const onStepUp = () => incrementValue(1)

        if (isFiniteNumber(value)) {
            if (isFiniteNumber(min)) {
                stepDownEnabled = value > min
            }
            if (isFiniteNumber(max)) {
                stepUpEnabled = value < max
            }
        }

        return (
            <SegmentedControl {...rest} ref={forwardedRef}>
                <SegmentedControlItem title="Decrease" selected={false} onSelect={onStepDown} enabled={stepDownEnabled}>
                    <IconMinus />
                </SegmentedControlItem>
                <SegmentedControlItem title="Increase" selected={false} onSelect={onStepUp} enabled={stepUpEnabled}>
                    <IconPlus />
                </SegmentedControlItem>
            </SegmentedControl>
        )
    })
)
