import { CanvasNode } from "../CanvasNode"
import { getShapeNodeRecord } from "../../records/shapes/ShapeNodeRecord"
import { BasicShape } from "document/models/CanvasTree/traits/utils/basicShape"
import { withFrameForShape } from "document/models/CanvasTree/traits/mixins/withFrameForShape"
import { withBaseShapeTraits } from "document/models/CanvasTree/traits/mixins/withBaseShapeTraits"
import { setDefaults } from "../MutableNode"
import type { WithPaths, WithShape } from "framer"
import { withClassDiscriminator } from "utils/withClassDiscriminator"

export function isOvalShapeNode(node: any): node is OvalShapeNode {
    return node instanceof OvalShapeNode
}

export default class OvalShapeNode
    extends withClassDiscriminator("OvalShapeNode", withFrameForShape(withBaseShapeTraits(CanvasNode)))
    implements WithShape {
    constructor(properties?: Partial<OvalShapeNode>) {
        super()
        setDefaults<OvalShapeNode>(this, getShapeNodeRecord(), properties)
        delete this.children // See comment in `MutableNode.ts`
    }

    calculatedPaths(): WithPaths {
        return BasicShape.calculatedPath(this)
    }
}
