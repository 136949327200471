import { LinearGradient as LinearGradientInterface, LinearGradientBase } from "framer"
import { LinearGradientRecord } from "document/models/CanvasTree/records/LinearGradientRecord"
import type { WithTokenVariables } from "document/models/CanvasTree/traits/TokenVariables"
import { isTokenCSSVariable } from "document/models/CanvasTree/nodes/TokenNode"
import { findValueForTokenCSSVariable } from "document/models/CanvasTree/utils/findValueForTokenCSSVariable"
import { GradientColorStop, GradientColorStops, equalColorStops } from "./GradientColorStop"
import type { Gradient } from "./Gradient"
import { withClassDiscriminator } from "utils/withClassDiscriminator"

export function isLinearGradient(node: any): node is LinearGradient {
    return node instanceof LinearGradient
}

export class LinearGradient extends withClassDiscriminator("LinearGradient", LinearGradientRecord)
    implements LinearGradientBase, WithTokenVariables<LinearGradient> {
    alpha: number
    angle: number
    stops: GradientColorStops
    private __css: string
    private __js: any
    private __overrideAngle: number | undefined

    toJS() {
        let __js = this.__js
        if (__js) {
            return __js
        }
        __js = super.toJS()
        __js["__class"] = this.__class
        // Alpha isn't compatible with variables
        if (this.stops.some((stop: GradientColorStop) => isTokenCSSVariable(stop.value))) {
            __js["alpha"] = 1
        }
        return (this.__js = __js)
    }

    toJSON() {
        return this.toJS()
    }

    toCSS(overrideAngle?: number) {
        const { __css, __overrideAngle } = this
        if (overrideAngle === __overrideAngle && __css) {
            return __css
        }
        this.__overrideAngle = overrideAngle
        return (this.__css = LinearGradientInterface.toCSS(this.toJS(), overrideAngle))
    }

    equals(other: any): boolean {
        return this.angle === other.angle && this.equalColors(other)
    }

    equalColors(other: Gradient): boolean {
        return this.alpha === other.alpha && equalColorStops(this.stops, other.stops)
    }

    tokenVariables(): string[] {
        const result: string[] = []
        this.stops.forEach((stop: GradientColorStop) => {
            if (isTokenCSSVariable(stop.value)) {
                result.push(stop.value)
            }
        })
        return result
    }

    removeTokenVariables(variables: { [tokenId: string]: string }): LinearGradient | undefined {
        let changed = false

        const stops = this.stops.map((stop: GradientColorStop) => {
            const replacement = findValueForTokenCSSVariable(stop.value, variables)
            if (replacement) {
                changed = true
                return new GradientColorStop(stop.merge({ value: replacement }))
            } else {
                return stop
            }
        })

        if (changed) {
            return new LinearGradient({ ...this.toJS(), stops })
        }
    }
}
