import type { CanvasNode } from "document/models/CanvasTree"

export interface WithPadding {
    padding: number | undefined
    paddingPerSide: boolean | undefined
    paddingTop: number | undefined
    paddingRight: number | undefined
    paddingBottom: number | undefined
    paddingLeft: number | undefined
}

export const paddingDefaults: WithPadding = {
    padding: undefined,
    paddingPerSide: undefined,
    paddingTop: undefined,
    paddingRight: undefined,
    paddingBottom: undefined,
    paddingLeft: undefined,
}

const key: keyof WithPadding = "padding"

export function withPadding(node: CanvasNode): node is CanvasNode & WithPadding {
    return key in node
}
