import * as React from "react"

export const IconPreviewReload = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
    function IconPreviewReload({ style, ...props }, forwardedRef) {
        return (
            <svg
                ref={forwardedRef}
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="10"
                style={{ transformOrigin: "5px 5px", ...style }}
                {...props}
            >
                <path
                    d="M 5 0 C 7.376 0 9.424 1.672 9.9 4 L 8.355 4 C 7.881 2.41 6.357 1.372 4.704 1.513 C 3.051 1.653 1.724 2.935 1.526 4.582 C 1.328 6.229 2.313 7.788 3.885 8.317 C 5.457 8.846 7.185 8.199 8.022 6.767 L 9.228 7.671 C 7.945 9.701 5.401 10.532 3.167 9.651 C 0.933 8.77 -0.359 6.426 0.089 4.067 C 0.537 1.708 2.599 0.001 5 0 Z"
                    fill="currentColor"
                ></path>
                <path
                    d="M 9.5 0.75 C 9.5 0.336 9.836 0 10.25 0 C 10.664 0 11 0.336 11 0.75 L 11 5 L 9.5 5 Z"
                    fill="currentColor"
                ></path>
                <path
                    d="M 6 4.25 C 6 3.836 6.336 3.5 6.75 3.5 L 11 3.5 L 11 5 L 6.75 5 C 6.336 5 6 4.664 6 4.25 Z"
                    fill="currentColor"
                ></path>
            </svg>
        )
    }
)
