import type { List } from "immutable"
import Path from "document/models/Path"
import type { Rect } from "framer"
import type { WithPath, PathSegment } from "framer"

export default function normalizeWithPath(withPath: WithPath): [WithPath, Rect] {
    const frame = Path.boundingBox(withPath)
    const updatedSegments = withPath.pathSegments.map(segment => {
        return segment!.merge({
            x: segment!.x - frame.x,
            y: segment!.y - frame.y,
        })
    }) as List<PathSegment>
    return [
        {
            pathClosed: withPath.pathClosed,
            pathSegments: updatedSegments,
        },
        frame,
    ]
}
