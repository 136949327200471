export function commonValue<T>(values: T[]): T | null {
    const il = values.length
    if (il === 0) {
        return null
    }

    const firstValue = values[0]

    for (let i = 1; i < il; i++) {
        const value = values[i]
        if (firstValue !== value) {
            return null
        }
    }

    return firstValue
}

export function commonPropertyValue<T, K extends keyof T>(nodes: T[], property: K): T[K] | null {
    const il = nodes.length
    if (il === 0) {
        return null
    }

    const firstValue = nodes[0][property]

    for (let i = 1; i < il; i++) {
        const value = nodes[i][property]
        if (firstValue !== value) {
            return null
        }
    }

    return firstValue
}
