import { DimensionType } from "framer"
import { CanvasNode } from "document/models/CanvasTree"
import { isPinnable } from "document/models/CanvasTree/traits/Pins"
import { nodeIsParentDirected } from "document/models/CanvasTree/nodes/utils/nodeIsParentDirected"

export function nodeLayoutDependsOnParent(node: CanvasNode) {
    const isParentDirected = nodeIsParentDirected(node)
    if (isParentDirected) return true

    const sizeDependsOnParent =
        isPinnable(node) &&
        (node.widthType === DimensionType.Percentage ||
            node.heightType === DimensionType.Percentage ||
            node.widthType === DimensionType.FractionOfFreeSpace ||
            node.heightType === DimensionType.FractionOfFreeSpace)

    if (sizeDependsOnParent) return true

    const isAutoWidth = isPinnable(node) && node.widthType === DimensionType.Auto
    const isAutoHeight = isPinnable(node) && node.heightType === DimensionType.Auto
    const positionDependsOnParent =
        isPinnable(node) &&
        ((node.top === null && node.bottom === null) ||
            (node.left === null && node.right === null) ||
            (node.top !== null && node.bottom !== null) ||
            (node.left !== null && node.right !== null) ||
            (isAutoWidth && node.left === null) ||
            (isAutoHeight && node.top === null))

    return positionDependsOnParent
}
