import type { CanvasNode } from "document/models/CanvasTree"

export interface WithIntrinsicSize {
    intrinsicWidth: number | null
    intrinsicHeight: number | null
}

export const intrinsicSizeDefaults: WithIntrinsicSize = {
    intrinsicWidth: null,
    intrinsicHeight: null,
}

const key: keyof WithIntrinsicSize = "intrinsicWidth"

export function withIntrinsicSize(node: CanvasNode): node is CanvasNode & WithIntrinsicSize {
    return key in node
}
