import * as React from "react"
import * as classes from "./IconDropdownButtonChevron.styles"

export function IconDropdownButtonChevron({ rotation }: { rotation?: number }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            className={classes.base}
            style={rotation ? { transform: `rotate(${rotation}deg)` } : undefined}
        >
            <path
                d="M 0 6 L 3 3 L 0 0"
                transform="translate(2.5 1) rotate(90 1.5 3)"
                fill="transparent"
                strokeWidth="1.5"
                stroke="currentColor"
                strokeLinecap="round"
            ></path>
        </svg>
    )
}
