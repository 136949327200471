import { DeviceOptions, getColorsFromTheme } from "./Device"
import { defaultPresetId, DevicePreset, DevicePresetID, getDevicePreset } from "./presets"

// Props for the DeviceCodeComponent. This is split into a separate module so
// that you can import the props interface and the funtion to convert props to
// DeviceOptions, without pulling the Framer library, on which the code
// component module depends.

export interface DeviceCodeComponentProps {
    width: number
    height: number
    children: React.ReactNodeArray

    preset: DevicePresetID | "custom"
    customWidth: number
    customHeight: number
    customBezel: number
    isMixedBezel: boolean
    bezelTop: number
    bezelRight: number
    bezelBottom: number
    bezelLeft: number
    customBezelRadius: number
    customScreenRadius: number
    orientation: "portrait" | "landscape"
    skin?: "realistic" | "clay"
    theme: "light" | "dark"
    shadow: boolean
    hand?: string
    backgroundColor: string
    colorId?: string
}

export const defaultProps: Omit<DeviceCodeComponentProps, "width" | "height" | "children"> = {
    preset: defaultPresetId,
    customWidth: 375,
    customHeight: 800,
    customBezel: 20,
    isMixedBezel: false,
    bezelTop: 20,
    bezelRight: 20,
    bezelBottom: 20,
    bezelLeft: 20,
    customBezelRadius: 20,
    customScreenRadius: 0,
    orientation: "portrait",
    skin: "clay",
    theme: "dark",
    shadow: true,
    backgroundColor: "#09f0",
}

export function convertPropsToDeviceOptions(
    props: Omit<DeviceCodeComponentProps, "width" | "height" | "children">,
    { forceOldClay = false }: { forceOldClay?: boolean } = {}
): DeviceOptions {
    let preset: Omit<DevicePreset, "id" | "title">
    if (props.preset === "custom") {
        preset = {
            screenWidth: props.customWidth,
            screenHeight: props.customHeight,
            screenRadius: props.customScreenRadius,
            clayBezelTop: props.isMixedBezel ? props.bezelTop : props.customBezel,
            clayBezelRight: props.isMixedBezel ? props.bezelRight : props.customBezel,
            clayBezelBottom: props.isMixedBezel ? props.bezelBottom : props.customBezel,
            clayBezelLeft: props.isMixedBezel ? props.bezelLeft : props.customBezel,
            clayBezelRadius: props.customBezelRadius,
        }
    } else {
        preset = getDevicePreset(props.preset)
    }
    const colors = getColorsFromTheme(props.theme)
    const shadowColor = colors.shadowColor
    const shadow = props.shadow ? `0 10px 30px ${shadowColor}` : undefined

    const rotate = !preset.disableRotation && props.orientation === "landscape"

    let deviceWidth: number
    let deviceHeight: number
    let screenOffsetTop: number
    let screenOffsetLeft: number
    let colorId = props.colorId
    let appearance: DeviceOptions["appearance"]
    if ((props.skin === undefined || props.skin === "realistic") && preset.realisticImage && props.preset) {
        deviceWidth = preset.realisticImage.width
        deviceHeight = preset.realisticImage.height
        screenOffsetTop = preset.realisticImage.screenOffsetTop
        screenOffsetLeft = preset.realisticImage.screenOffsetLeft
        colorId = colorId ?? preset.realisticImage.availableColors?.[0]?.id
        appearance = {
            type: "realistic",
            imageUrl: colorId
                ? `https://preview.framercdn.com/images/devices/${props.preset}-${colorId}.png`
                : `https://preview.framercdn.com/images/devices/${props.preset}.png`,
            imageWidth: preset.realisticImage.width,
            imageHeight: preset.realisticImage.height,
            rotateImage: rotate,
        }
    } else {
        deviceWidth = preset.screenWidth + (preset.clayBezelLeft ?? 0) + (preset.clayBezelRight ?? 0)
        deviceHeight = preset.screenHeight + (preset.clayBezelTop ?? 0) + (preset.clayBezelBottom ?? 0)
        screenOffsetTop = preset.clayBezelTop ?? 0
        screenOffsetLeft = preset.clayBezelLeft ?? 0

        // use external clay if available
        if (preset.externalClay && !forceOldClay) {
            deviceWidth = preset.externalClay.width
            deviceHeight = preset.externalClay.height
            screenOffsetTop = preset.externalClay.screenOffsetTop
            screenOffsetLeft = preset.externalClay.screenOffsetLeft
            appearance = {
                type: "external-clay",
                imageUrl: `https://preview.framercdn.com/images/devices/${props.preset}-${props.theme}.svg`,
                imageWidth: preset.externalClay.width,
                imageHeight: preset.externalClay.height,
            }
        } else {
            appearance = {
                type: "clay",
                bezelRadius: preset.clayBezelRadius !== undefined ? `${preset.clayBezelRadius}px` : undefined,
                bezelColor: colors.bezelColor,
                bezelShadeColor: colors.bezelShadeColor,
            }
        }
    }

    let screenWidth = preset.screenWidth
    let screenHeight = preset.screenHeight

    if (rotate) {
        // Computer screenOffsetRight pre-rotation, this will become the top
        // offset post-rotation.
        const screenOffsetRight = deviceWidth - screenWidth - screenOffsetLeft

        ;[deviceWidth, deviceHeight] = [deviceHeight, deviceWidth]
        ;[screenWidth, screenHeight] = [screenHeight, screenWidth]
        ;[screenOffsetTop, screenOffsetLeft] = [screenOffsetRight, screenOffsetTop]
    }

    const handOffset = preset.realisticImage?.handOffset

    return {
        deviceWidth,
        deviceHeight,
        appearance,
        screenWidth,
        screenHeight,
        screenOffsetTop,
        screenOffsetLeft,
        screenRadius: preset.screenRadius !== undefined ? `${preset.screenRadius}px` : undefined,
        screenMaskImage:
            appearance.type !== "realistic" && preset.screenMask
                ? makeScreenMaskImage({
                      mask: preset.screenMask,
                      // width/height of the mask = screen width/height pre-rotation
                      width: preset.screenWidth,
                      height: preset.screenHeight,
                      rotate,
                  })
                : undefined,
        screenColor: colors.screenColor,
        shadow,
        hand:
            props.hand !== undefined
                ? {
                      imageUrl: `https://preview.framercdn.com/images/hands/${props.hand}.png`,
                      offsetLeft: handOffset?.left,
                      offsetRight: handOffset?.right,
                      offsetBottom: handOffset?.bottom,
                  }
                : undefined,
        background: props.backgroundColor,
        theme: props.theme,
        colorId,
    }
}

function makeScreenMaskImage({
    mask,
    width,
    height,
    rotate = false,
}: {
    mask: string
    width: number
    height: number
    rotate?: boolean
}) {
    const transform = rotate
        ? // Rotate 90 degrees counter-clockwise around (0,0), then move the
          // result down into the viewport (rightmost transform is applied first).
          `transform="translate(0 ${width}) rotate(-90)"`
        : ""
    const encoded = encodeURIComponent(
        `<svg xmlns="http://www.w3.org/2000/svg" viewport="0 0 ${width} ${height}" preserveAspectRatio="none"><g x="0" y="0" ${transform}>${mask}</g></svg>`
    )
    return `url("data:image/svg+xml;utf8,${encoded}")`
}
