import type { MotionStyle } from "framer-motion"
import type { CanvasNode } from "../../nodes/CanvasNode"
import type { CollectTraitOptions } from "."
import { withFill, withOptionalFill } from "document/models/CanvasTree/traits/Fill"
import { withShape } from "document/models/CanvasTree/traits/Shape"
import { withSVG } from "document/models/CanvasTree/traits/SVG"
import { isTokenCSSVariable } from "../../nodes/TokenNode"
import { ConvertColor } from "framer"
import { isInlineVariable } from "variants/utils/inlineValues"

export function collectBackgroundFill(
    node: CanvasNode,
    style: MotionStyle,
    { withInlineVariables = false, unset = false }: CollectTraitOptions = {}
): void {
    if (withShape(node) || withSVG(node)) {
        // Currently this does not support SVG or Shape nodes, because they
        // render the background using a separate element and need the props
        return
    }

    if (withOptionalFill(node) && !node.fillEnabled) {
        if (unset) style.backgroundColor = "transparent"
        return
    }

    if (!withFill(node)) return

    switch (node.fillType) {
        case "color":
            if (node.fillColor) {
                const color = node.resolveValue("fillColor", withInlineVariables)
                if (!color) return

                if (isInlineVariable(color)) {
                    style.backgroundColor = color
                } else {
                    /**
                     * If this is a color, convert to RGBA so Motion can animate between different color types.
                     */
                    style.backgroundColor = isTokenCSSVariable(color) ? color : ConvertColor.toRgbString(color)
                }
            }
            break
        case "linear-gradient":
            if (node.fillLinearGradient) {
                style.background = node.fillLinearGradient.toCSS()
            }
            break
        case "radial-gradient":
            if (node.fillRadialGradient) {
                style.background = node.fillRadialGradient.toCSS()
            }
            break
    }
}
