import { DimensionType } from "framer"
import type { CanvasNode } from "../.."
import { withAspectRatio } from "../AspectRatioLock"
import { isPinnable } from "../Pins"

export function aspectRatioForNode(node: CanvasNode): number | null {
    if (!withAspectRatio(node)) return null
    return layoutSupportsAspectRatio(node) ? node.aspectRatio : null
}

export function layoutSupportsAspectRatio(node: CanvasNode) {
    if (isPinnable(node) && (node.widthType === DimensionType.Auto || node.heightType === DimensionType.Auto))
        return false
    if (!node.cache.parentDirected) return true
    if (!isPinnable(node)) return true
    const { widthType, heightType } = node
    return widthType === DimensionType.FixedNumber && heightType === DimensionType.FixedNumber
}
