import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springAnimation } from "./utils"
import { colors } from "../tokens"

const width = 50
const height = 50

export const AnimationPreview = React.memo(function AnimationPreview() {
    const play = useAnimation()
    const device = useAnimation()

    async function animation() {
        await Promise.all([
            play.start({
                scale: 0.8,
                opacity: 1,
                y: 10,
            }),
            device.start({
                y: 30,
                transition: springAnimation,
            }),
        ])
        await play.start({
            scale: 0.7,
            opacity: 0.5,
            transition: springAnimation,
        })
        await play.start({
            scale: 0.8,
            opacity: 1,
            transition: springAnimation,
        })
        return await Promise.all([
            play.start({
                scale: 0,
                opacity: 0,
                transition: springAnimation,
            }),
            device.start({
                y: -29,
                transition: springAnimation,
            }),
        ])
    }

    useLoop(animation)

    return (
        <div
            style={{
                overflow: "hidden",
                width: width,
                height: height,
                borderRadius: 8,
                background: colors.animationBackgroundLight,
            }}
        >
            <motion.div animate={play}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                    <path
                        d="M 15 0 C 23.284 0 30 6.716 30 15 C 30 23.284 23.284 30 15 30 C 6.716 30 0 23.284 0 15 C 0 6.716 6.716 0 15 0 Z"
                        fill={colors.animationForground}
                    ></path>
                    <path
                        d="M 13.034 9.969 C 12.368 9.548 11.5 10.027 11.5 10.814 L 11.5 19.186 C 11.5 19.973 12.368 20.452 13.034 20.031 L 19.661 15.845 C 20.283 15.453 20.283 14.547 19.661 14.155 Z"
                        fill="#4FAEFF"
                    ></path>
                </svg>
            </motion.div>
            <motion.div animate={device}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
                    <path
                        d="M 10 7 C 10 4.791 11.791 3 14 3 L 26 3 C 28.209 3 30 4.791 30 7 L 30 34 C 30 36.209 28.209 38 26 38 L 14 38 C 11.791 38 10 36.209 10 34 Z"
                        fill={colors.animationForground}
                    ></path>
                    <path
                        d="M 17 33 C 17 32.448 17.448 32 18 32 L 22 32 C 22.552 32 23 32.448 23 33 L 23 33 C 23 33.552 22.552 34 22 34 L 18 34 C 17.448 34 17 33.552 17 33 Z"
                        fill={colors.animationForgroundDimmed}
                    ></path>
                </svg>
            </motion.div>
        </div>
    )
})
