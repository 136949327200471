import type { Message } from "console-feed/lib/definitions/Component"

import { assertNever } from "@framerjs/shared"
import { Button, IconConsoleClear, IconClose, IconSectionCollapsed, IconSectionExpanded } from "@framerjs/fresco"
import ConsoleFeed from "console-feed/lib/Component"
import { motion, PanInfo, useMotionValue } from "framer-motion"
import { cx } from "linaria"
import * as React from "react"
import { useReducer, useRef, useState, useCallback, useEffect } from "react"
import * as classes from "./Console.styles"

const { theme } = classes

const LOG_MAX_LENGTH = 200
const LOG_CAP_LENGTH = 100

const defaultConsoleState = {
    warnCount: 0,
    errorCount: 0,
    logs: [],
}

type Action =
    | {
          type: "add"
          logs: Message[]
      }
    | {
          type: "clear"
      }

interface ConsoleState {
    errorCount: number
    warnCount: number
    logs: Message[]
}

export function logsReducer(state: ConsoleState, action: Action): ConsoleState {
    switch (action.type) {
        case "add": {
            // Use same log capping approach and caps used in Vekter.
            let oldLogs = state.logs
            if (state.logs.length + action.logs.length > LOG_MAX_LENGTH) {
                // We (temporarily?) use a strange method to cap the length, we go from 200 to 100.
                // This to workaround a performance problem in console-feed, which gives out react keys
                // based on array index.
                oldLogs = oldLogs.slice(action.logs.length + LOG_CAP_LENGTH)
            }

            const logs = oldLogs.concat(action.logs)
            const warnCount = logs.filter(log => log.method === "warn").length
            const errorCount = logs.filter(log => log.method === "error").length

            return {
                warnCount,
                errorCount,
                logs,
            }
        }
        case "clear":
            return defaultConsoleState
        default:
            return assertNever(action)
    }
}

export type ConsoleFn = React.Dispatch<Action>

export function useConsole(): [ConsoleState, ConsoleFn] {
    return useReducer(logsReducer, defaultConsoleState)
}

interface BadgeProps extends Pick<Props, "errorCount" | "warnCount"> {
    logCount: number
}

function Badge({ errorCount, warnCount, logCount }: BadgeProps) {
    if (errorCount > 0) {
        return <div className={cx(classes.badge, classes.badgeError)}>{errorCount}</div>
    }

    if (warnCount > 0) {
        return <div className={cx(classes.badge, classes.badgeWarn)}>{warnCount}</div>
    }

    return <div className={classes.badge}>{logCount}</div>
}

interface Props {
    dark?: boolean
    errorCount: number
    warnCount: number
    logs: Message[]
    onCloseConsole: () => void
    onClearConsole: () => void
    setIsActive?: (active: boolean) => void
}

const collapseThreshold = 10
const fallbackConsoleHeight = 100

interface PersistedState {
    height: number | undefined
    collapsed: boolean
}

// ConsoleFeed doesn't update it's styles when the varient or styles are updated.
// To force a new console with the desired theme, we need to render a new version of the ConsoleFeed.
const DarkConsole = ({ logs }: Pick<Props, "logs">) => {
    return <ConsoleFeed logs={logs} variant="dark" styles={theme.dark} />
}

const LightConsole = ({ logs }: Pick<Props, "logs">) => {
    return <ConsoleFeed logs={logs} variant="light" styles={theme.light} />
}

function useSessionStorage<Value>(identifier: string, initialState: Value): [Value, (state: Value) => void] {
    const persistState = (state: Value) => sessionStorage.setItem(identifier, JSON.stringify(state))

    const persistedState = sessionStorage.getItem(identifier)
    const currentState: Value = persistedState ? JSON.parse(persistedState) : initialState

    return [currentState, persistState]
}

export function Console({
    errorCount,
    warnCount,
    logs,
    onCloseConsole,
    onClearConsole,
    dark = document.body.classList.contains("dark"),
    setIsActive,
}: Props) {
    const [initialState, persistState] = useSessionStorage<PersistedState>("preview-console-state", {
        height: undefined,
        collapsed: false,
    })

    const container = useRef<HTMLDivElement>(null)

    const [isCollapsed, setCollapsed] = useState(initialState.collapsed)
    const [heightBeforeCollapse, setHeightBeforeCollapse] = useState<number | null>(null)

    const defaultHeight = (container?.current?.offsetHeight ?? 0) / 2
    const height = useMotionValue<number>(initialState.height || defaultHeight || fallbackConsoleHeight)
    const collapsedHeight = useMotionValue<number>(0)

    const [isDragging, setDragging] = useState(false)

    const onCollapse = () => {
        let newHeight = defaultHeight || fallbackConsoleHeight

        if (isCollapsed && heightBeforeCollapse && heightBeforeCollapse > collapseThreshold) {
            newHeight = heightBeforeCollapse
        } else if (!isCollapsed) {
            setHeightBeforeCollapse(height.get())
            newHeight = 0
        }

        height.set(newHeight)
        persistState({
            height: newHeight,
            collapsed: !isCollapsed,
        })

        setCollapsed(!isCollapsed)
    }

    const handleDragStart = () => {
        setDragging(true)
    }

    const handleDragEnd = () => {
        persistState({
            height: height.get(),
            collapsed: isCollapsed,
        })
        setDragging(false)
    }

    const handleResize = useCallback(
        (delta: PanInfo["delta"]) => {
            if (!container.current || !container.current.parentElement) return
            const newHeight = height.get() - (delta.y || 0)

            // Do not allow resizing the console larger then the preview window.
            if (container.current.offsetHeight - (delta.y || 0) > container.current.parentElement.offsetHeight) return

            // If the desired height of the console is less than the threshold,
            // collapse it, set the height to 0 for the future if the user
            // drags up from the collapsed console, and stop updating height.
            if (newHeight < collapseThreshold && !isCollapsed) {
                setCollapsed(true)
                height.set(0)
                return
            }

            // If the desired height is less than the collapseThreshold,
            // but the console is already collapsed, we want to continue to set the height
            // to allow it to be dragged back up if it passes the threshold.
            if (newHeight > collapseThreshold) {
                setCollapsed(false)
            }
            height.set(newHeight > 0 ? newHeight : 0)
        },
        [height, isCollapsed]
    )

    const handleDrag = useCallback((_, info) => handleResize(info.delta), [handleResize])

    const hasLogs = logs.length > 0

    // Returns an object to merge into props to install the given click handler
    // in a way that's gonna play nicely with the drag-to-resize behavior of the
    // Console's header.
    //
    // The optional `shouldStopPropagation` option will stop the event from
    // propagating, which can be used to prevent the click-header-to-collapse
    // behavior.
    const onClickProps = (
        clickHandler: (event: React.MouseEvent) => void,
        { shouldStopPropagation }: { shouldStopPropagation?: boolean } = {}
    ) => {
        const handler = (event: React.MouseEvent) => {
            // only trigger if the console header is not currently being dragged
            if (!isDragging) {
                clickHandler(event)

                if (shouldStopPropagation) {
                    event.stopPropagation()
                }
            }
        }

        // We use onPointerUp instead of onClick to guarantee that the event
        // will fire before dragging ends, so that we can do the `isDragging`
        // check above.
        //
        // We fall back to onMouseUp for browsers with no support for pointer
        // events.
        //
        // We cannot just default to onMouseUp, because in browsers that do
        // support pointer events, Motion calls preventDefault during
        // onPointerDown, which prevents onMouse events from happening. See
        // points 4 and 5 at: https://w3c.github.io/pointerevents/#mapping-for-devices-that-support-hover
        const supportsPointerEvents = window.onpointerup === null
        return supportsPointerEvents ? { onPointerUp: handler } : { onMouseUp: handler }
    }

    // Ensure that the console is considered disengaged on unmount
    useEffect(
        () => () => {
            setIsActive?.(false)
        },
        [setIsActive]
    )

    const onMouseClickDown = useCallback(() => {
        setIsActive?.(true)
    }, [setIsActive])

    const onMouseClickUp = useCallback(() => {
        setIsActive?.(false)
    }, [setIsActive])

    return (
        <div
            ref={container}
            className={classes.container}
            onMouseDownCapture={onMouseClickDown}
            onMouseUpCapture={onMouseClickUp}
        >
            <motion.div
                drag="y"
                dragConstraints={{ top: 0, right: 0, bottom: 0, left: 0 }}
                dragElastic={0}
                dragMomentum={false}
                className={classes.headerWrapper}
                onDrag={handleDrag}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
            >
                <div className={cx(classes.resizeCursor, isDragging && classes.resizeCursorDragging)} />
                <div className={cx(classes.header, !isCollapsed && classes.headerBorder)} {...onClickProps(onCollapse)}>
                    <Button
                        title={isCollapsed ? "Expand" : "Collapse"}
                        className={cx(classes.consoleButton, classes.collapseIcon)}
                        {...onClickProps(onCollapse, { shouldStopPropagation: true })}
                    >
                        {isCollapsed ? <IconSectionCollapsed /> : <IconSectionExpanded />}
                    </Button>

                    {hasLogs && <Badge errorCount={errorCount} warnCount={warnCount} logCount={logs.length} />}

                    <p className={classes.label}>Console</p>

                    {hasLogs && (
                        <Button
                            title="Clear"
                            className={classes.consoleButton}
                            {...onClickProps(onClearConsole, { shouldStopPropagation: true })}
                        >
                            <IconConsoleClear />
                        </Button>
                    )}

                    <Button
                        title="Close"
                        className={classes.consoleButton}
                        {...onClickProps(onCloseConsole, { shouldStopPropagation: true })}
                    >
                        <IconClose />
                    </Button>
                </div>
            </motion.div>

            <motion.div
                className={classes.consoleArea}
                style={{
                    height: isCollapsed ? collapsedHeight : height,
                    transition: isDragging ? "" : "0.3s height cubic-bezier(0.2, 1, 0.1, 1)",
                }}
            >
                {!isCollapsed && (dark ? <DarkConsole logs={logs} /> : <LightConsole logs={logs} />)}
                {!hasLogs && <div className={classes.empty}>No messages</div>}
            </motion.div>
        </div>
    )
}
