import { V60, V61 } from "../types"
import { transform } from "../utils/transform"
import { _, exactCheck } from "../utils/exactCheck"

export function migration_60_61(documentJSON: V60.Tree): V61.Tree {
    const migratedRoot = transform(documentJSON.root, node => {
        if (node.__class !== "TextNode") return exactCheck(node, _ as V61.TreeNode)

        return exactCheck(
            {
                ...node,
                textVerticalAlignment: "top" as const,
            },
            _ as V61.TextNode
        )
    })
    return { version: 61, root: migratedRoot }
}
