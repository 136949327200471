import type { Iterable } from "immutable"
import { ExportOptionsRecord } from "document/models/CanvasTree/records/ExportOptionsRecord"
import type { Export, ExportType, ExportScale, ExportExtensionMode } from "document/models/CanvasTree/traits/Export"
import { randomID } from "./NodeID"
import { withClassDiscriminator } from "utils/withClassDiscriminator"

export class ExportOptions extends withClassDiscriminator("ExportOptions", ExportOptionsRecord) implements Export {
    id: string
    enabled: boolean
    type: ExportType
    scale: ExportScale
    nameExtension: string
    nameExtensionMode: ExportExtensionMode

    private constructor(values: Iterable<string, unknown> | Partial<ExportOptions>) {
        super(values)
    }

    static create(options: Partial<ExportOptions>) {
        if (!options.id) {
            options = { id: randomID(), ...options }
        }
        return new ExportOptions(options)
    }

    updatedExportOptions(options: Partial<ExportOptions>) {
        return new ExportOptions(this.merge(options))
    }

    toJS() {
        const JS = super.toJS()
        JS["__class"] = this.__class
        return JS
    }

    toJSON() {
        return this.toJS()
    }
}
