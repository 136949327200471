import type { CanvasNode } from "document/models/CanvasTree"

export interface WithSVG {
    svg: string
}

export const svgDefaults: WithSVG = {
    svg: "",
}

const key: keyof WithSVG = "svg"

export function withSVG(node: CanvasNode): node is CanvasNode & WithSVG {
    return key in node
}
