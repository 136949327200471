import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springAnimationDelay } from "./utils"
import { colors } from "../tokens"

const width = 50
const height = 50

export const AnimationTools = React.memo(function AnimationTools() {
    const scroll = useAnimation()
    const type = useAnimation()
    const page = useAnimation()

    async function animation() {
        await Promise.all([
            scroll.start({
                opacity: 1,
                scale: 0.8,
                transition: springAnimationDelay,
            }),
            type.start({
                opacity: 0,
                scale: 0,
                transition: springAnimationDelay,
            }),
            page.start({
                opacity: 0,
                scale: 0,
                transition: springAnimationDelay,
            }),
        ])
        await Promise.all([
            scroll.start({
                opacity: 0,
                scale: 0,
                transition: springAnimationDelay,
            }),
            type.start({
                opacity: 1,
                scale: 0.8,
                transition: springAnimationDelay,
            }),
            page.start({
                opacity: 0,
                scale: 0,
                transition: springAnimationDelay,
            }),
        ])
        return await Promise.all([
            scroll.start({
                opacity: 0,
                scale: 0,
                transition: springAnimationDelay,
            }),
            type.start({
                opacity: 0,
                scale: 0,
                transition: springAnimationDelay,
            }),
            page.start({
                opacity: 1,
                scale: 0.8,
                transition: springAnimationDelay,
            }),
        ])
    }

    useLoop(animation)

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                overflow: "hidden",
                width: width,
                height: height,
                borderRadius: 8,
                background: colors.animationBackgroundLight,
            }}
        >
            <motion.div
                animate={scroll}
                style={{
                    position: "absolute",
                    marginTop: 1,
                    opacity: 1,
                    scale: 0.8,
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                    <path
                        d="M 0 6.429 C 0 2.878 2.878 0 6.429 0 L 23.571 0 C 27.122 0 30 2.878 30 6.429 L 30 9.714 C 30 12.081 28.081 14 25.714 14 L 4.286 14 C 1.919 14 0 12.081 0 9.714 Z M 0 20.286 C 0 17.919 1.919 16 4.286 16 L 25.714 16 C 28.081 16 30 17.919 30 20.286 L 30 23.571 C 30 27.122 27.122 30 23.571 30 L 6.429 30 C 2.878 30 0 27.122 0 23.571 Z"
                        fill="#FFFFFF"
                    ></path>
                </svg>
            </motion.div>
            <motion.div animate={type} style={{ position: "absolute", marginTop: 1, opacity: 0, scale: 0 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                    <path
                        d="M 0 6.43 C 0 2.879 2.879 0 6.43 0 L 23.57 0 C 27.121 0 30 2.879 30 6.43 L 30 23.57 C 30 27.121 27.121 30 23.57 30 L 6.43 30 C 2.879 30 0 27.121 0 23.57 Z"
                        fill={colors.animationForground}
                    ></path>
                    <path
                        d="M 9 9 L 21 9"
                        fill="transparent"
                        strokeWidth="4"
                        stroke={colors.animationForgroundDimmed}
                        strokeLinecap="round"
                    ></path>
                    <path
                        d="M 15 9 L 15 21"
                        fill="transparent"
                        strokeWidth="4"
                        stroke={colors.animationForgroundDimmed}
                        strokeLinecap="round"
                    ></path>
                </svg>
            </motion.div>
            <motion.div animate={page} style={{ position: "absolute", marginTop: 1, opacity: 0, scale: 0 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                    <path
                        d="M 17.143 8.286 C 17.143 6.422 18.418 4.856 20.143 4.412 L 20.143 26.16 C 18.418 25.716 17.143 24.15 17.143 22.286 Z M 26.143 4.286 C 28.352 4.286 30.143 6.077 30.143 8.286 L 30.143 22.286 C 30.143 24.495 28.352 26.286 26.143 26.286 L 23.143 26.286 L 23.143 4.286 Z"
                        fill={colors.animationForground}
                        opacity={0.5}
                    ></path>
                    <path
                        d="M 0 5 C 0 2.239 2.239 0 5 0 L 16 0 C 18.761 0 21 2.239 21 5 L 21 25 C 21 27.761 18.761 30 16 30 L 5 30 C 2.239 30 0 27.761 0 25 Z"
                        fill={colors.animationForground}
                    ></path>
                </svg>
            </motion.div>
        </div>
    )
})
