import { colors, fonts } from "@framerjs/fresco/tokens"
import { css } from "linaria"
import { breakpoints } from "../../store/lib/breakpoints"

import debugIcon from "./debug.svg"
import errorIcon from "./error.svg"
import infoDarkIcon from "./info-dark.svg"
import infoLightIcon from "./info-light.svg"
import warningIcon from "./warning.svg"

export const container = css`
    --empty-color: rgb(102, 102, 102);
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    max-height: 100%;
    flex-direction: column;
    background-color: ${colors.previewConsoleBackground};
`

export const headerWrapper = css`
    height: 40px;
    padding: 0 15px;
`

export const header = css`
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: default;
    user-select: none;
`

export const headerBorder = css`
    border-bottom: 1px solid ${colors.previewConsoleDivider};
`

export const resizeCursor = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    cursor: ns-resize;
`
// Keep the resize cursor active while dragging, even if the pointer leaves the
// resize zone. Implementation borrowed from Fresco's MouseTracker.
export const resizeCursorDragging = css`
    position: fixed;
    z-index: 2500;
    top: -100vh;
    left: -100vw;
    width: 200vw !important;
    height: 200vh !important;
    opacity: 0;
    pointer-events: auto;
    transform: translate3d(0, 0, 0);
`

export const label = css`
    flex-grow: 1;
    color: ${colors.buttonText};
    font-size: 12px;
    font-weight: 500; /* TODO: Audit (either change to fonts.weight.baseActive or change weight) */
`

export const consoleArea = css`
    position: relative;
    display: flex;
    overflow: auto;
    max-height: calc(100% - 37px);
    flex-direction: column-reverse;
    cursor: default;
    font-size: 11px;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: auto;
    user-select: auto;

    /*
     * Hack to override console-feed styling so icon can hidden when the preview window is narrow
     * NB: This will revert to the default behaviour if we move the console within Vekter as the
     * window width will no longer match the console width
     */
    [data-method] {
        /* Icon */
        > div:first-child {
            display: none;
        }

        /* Message */
        > div:nth-child(2) {
            margin-left: 0;

            * {
                word-break: break-word;
            }
        }
    }

    @media (min-width: ${breakpoints.ipad}) {
        [data-method] {
            > div:first-child {
                display: block;
            }

            > div:nth-child(2) {
                margin-left: 10px;
            }
        }
    }
`

export const consoleButton = css`
    display: flex;
    width: 12px;
    min-width: 12px;
    height: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-right: 10px;
    background-color: transparent;
    color: ${colors.previewConsoleButtonText};

    &:hover {
        background-color: transparent !important;
    }

    &:last-child {
        margin-right: 0;
    }
`

export const collapseIcon = css`
    width: 12px;
    height: 20px;
    margin-left: ${-(12 / 2 - 6 / 2)}px;
    color: ${colors.previewConsoleCollapseIcon};
    transition: transform 0.3s ease;
`

export const rotate = css`
    transform: rotateZ(-90deg);
`

export const badge = css`
    display: flex;
    width: 16px;
    min-width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    background-color: #bbb;
    border-radius: 10px;
    color: #fff;
    font-family: ${fonts.family.sansSerif};
    font-size: 10px;
    -webkit-font-smoothing: subpixel-antialiased;
    font-weight: 699; /* TODO: Audit (either change to fonts.weight.heading or add token) */
`

export const badgeError = css`
    background-color: #f02;
`

export const badgeWarn = css`
    background-color: #fb0;
`

export const empty = css`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: var(--empty-color);
`

// Theme
function backgroundImage(url: string) {
    return `url(${url})`
}

const darkSyntaxColors = {
    blue: "#00BBFF",
    lightBlue: "#AADDFF",
    teal: "#22CCDD",
    yellow: "#FFCC66",
    orange: "#FF8866",
    purple: "#BB88FF",
    purpleFade: "#EEAAFF",
    pink: "#FF66BB",
    default: "#00BBFF",
    gray: "#999999",
    grayFade: "#666666",
}

const lightSyntaxColors = {
    blue: "#00BBFF",
    lightBlue: "#AADDFF",
    teal: "#22CCDD",
    yellow: "#FFCC66",
    orange: "#FF8866",
    purple: "#BB88FF",
    purpleFade: "#EEAAFF",
    pink: "#FF66BB",
    default: "#00BBFF",
    gray: "#999999",
    grayFade: "#666666",
}

// FIXME: UPPER_CASE breaks naming convention
/* eslint-disable @typescript-eslint/naming-convention */

const base = {
    // Log icons
    LOG_ICON_WIDTH: 18,
    LOG_ICON_HEIGHT: 30,

    BASE_BACKGROUND_COLOR: "transparent",

    // Log colors
    // LOG_ICON => CSS background-image property
    // LOG_COLOR: any,
    // LOG_ICON: backgroundImage(logIcon),
    // LOG_BACKGROUND: any,

    // LOG_COMMAND_COLOR: "rgb(255, 0, 246)",
    // LOG_COMMAND_ICON: any,
    // LOG_COMMAND_BACKGROUND: any,
    // LOG_COMMAND_BORDER: any,

    // LOG_RESULT_COLOR: "rgb(255, 0, 246)",
    // LOG_RESULT_ICON: any,
    // LOG_RESULT_BACKGROUND: any,
    // LOG_RESULT_BORDER: any,

    LOG_WARN_ICON: backgroundImage(warningIcon),
    LOG_DEBUG_ICON: backgroundImage(debugIcon),
    LOG_ERROR_ICON: backgroundImage(errorIcon),

    // Fonts
    BASE_FONT_FAMILY: "Input Mono, Menlo, monospace",
    BASE_FONT_SIZE: "11px",
    // BASE_LINE_HEIGHT?: any,

    // Spacing
    PADDING: "9px 6px 9px 0", // Results in the row height

    // react-inspector
    // BASE_BACKGROUND_COLOR: any,
    // BASE_COLOR: any,

    // ARROW_COLOR: any,
    // ARROW_MARGIN_RIGHT: any,
    ARROW_FONT_SIZE: "7px",

    // TREENODE_FONT_FAMILY: any,
    // TREENODE_FONT_SIZE: any,
    // TREENODE_LINE_HEIGHT: any,
    // TREENODE_PADDING_LEFT: 10,

    TABLE_BORDER_COLOR: "rgba(255, 255, 255, 0.1)",
    TABLE_TH_BACKGROUND_COLOR: "rgba(255, 255, 255, 0.05)",
    TABLE_TH_HOVER_COLOR: "rgba(255, 255, 255, 0.1)",
    TABLE_SORT_ICON_COLOR: "rgba(255, 255, 255, 0.2)",
    // TABLE_DATA_BACKGROUND_IMAGE: any,
    // TABLE_DATA_BACKGROUND_SIZE: any,
}

export const theme = {
    dark: {
        ...base,

        BASE_COLOR: "#EEEEEE",

        LOG_INFO_ICON: backgroundImage(infoDarkIcon),

        LOG_BORDER: "transparent",

        LOG_INFO_COLOR: "rgb(153, 153, 153)",
        LOG_INFO_BACKGROUND: "rgba(153, 153, 153, 0.1)",
        LOG_INFO_BORDER: "#3A3A3A",

        LOG_WARN_COLOR: "rgb(255, 204, 1)",
        LOG_WARN_BACKGROUND: "rgba(255, 204, 0, 0.1)",
        LOG_WARN_BORDER: "#5E532C",

        LOG_ERROR_COLOR: "rgb(255, 51, 102)",
        LOG_ERROR_BACKGROUND: "rgba(255, 51, 102, 0.1)",
        LOG_ERROR_BORDER: "#5B2D37",

        LOG_DEBUG_COLOR: "rgb(0, 153, 255)",
        LOG_DEBUG_BACKGROUND: "rgba(0, 153, 255, 0.1)",
        LOG_DEBUG_BORDER: "rgba(0, 153, 255, 0.2)",

        OBJECT_NAME_COLOR: darkSyntaxColors.default,
        OBJECT_VALUE_NULL_COLOR: darkSyntaxColors.orange,
        OBJECT_VALUE_UNDEFINED_COLOR: darkSyntaxColors.orange,
        OBJECT_VALUE_REGEXP_COLOR: darkSyntaxColors.orange,
        OBJECT_VALUE_STRING_COLOR: darkSyntaxColors.purple,
        OBJECT_VALUE_SYMBOL_COLOR: darkSyntaxColors.orange,
        OBJECT_VALUE_NUMBER_COLOR: darkSyntaxColors.orange,
        OBJECT_VALUE_BOOLEAN_COLOR: darkSyntaxColors.orange,
        OBJECT_VALUE_FUNCTION_KEYWORD_COLOR: darkSyntaxColors.teal,
        OBJECT_HYPERLINK_COLOR: darkSyntaxColors.lightBlue,

        HTML_TAG_COLOR: darkSyntaxColors.blue,
        HTML_TAGNAME_COLOR: darkSyntaxColors.blue,
        HTML_TAGNAME_TEXT_TRANSFORM: darkSyntaxColors.lightBlue,
        HTML_ATTRIBUTE_NAME_COLOR: darkSyntaxColors.lightBlue,
        HTML_ATTRIBUTE_VALUE_COLOR: darkSyntaxColors.yellow,
        HTML_COMMENT_COLOR: darkSyntaxColors.grayFade,
        HTML_DOCTYPE_COLOR: darkSyntaxColors.grayFade,
    },
    light: {
        ...base,

        LOG_INFO_ICON: backgroundImage(infoLightIcon),

        BASE_COLOR: "#666",
        BASE_BACKGROUND_COLOR: "transparent",

        LOG_COLOR: "rgb(51, 51, 51)",
        LOG_BORDER: "none",

        LOG_INFO_COLOR: "rgb(153, 153, 153)",
        LOG_INFO_BACKGROUND: "rgba(153, 153, 153, 0.1)",
        LOG_INFO_BORDER: "#DFDFDF",

        LOG_WARN_COLOR: "rgba(255, 153, 0)",
        LOG_WARN_BACKGROUND: "rgba(255, 153, 0, 0.1)",
        LOG_WARN_BORDER: "#F3DCBF",

        LOG_ERROR_COLOR: "rgb(255, 51, 102)",
        LOG_ERROR_BACKGROUND: "rgba(255, 51, 102, 0.1)",
        LOG_ERROR_BORDER: "#F1C3CD",

        LOG_DEBUG_COLOR: "rgb(0, 153, 255)",
        LOG_DEBUG_BACKGROUND: "rgba(0, 153, 255, 0.1)",
        LOG_DEBUG_BORDER: "rgba(0, 153, 255, 0.2)",

        OBJECT_NAME_COLOR: lightSyntaxColors.default,
        OBJECT_VALUE_NULL_COLOR: lightSyntaxColors.orange,
        OBJECT_VALUE_UNDEFINED_COLOR: lightSyntaxColors.orange,
        OBJECT_VALUE_REGEXP_COLOR: lightSyntaxColors.orange,
        OBJECT_VALUE_STRING_COLOR: lightSyntaxColors.purple,
        OBJECT_VALUE_SYMBOL_COLOR: lightSyntaxColors.orange,
        OBJECT_VALUE_NUMBER_COLOR: lightSyntaxColors.orange,
        OBJECT_VALUE_BOOLEAN_COLOR: lightSyntaxColors.orange,
        OBJECT_VALUE_FUNCTION_KEYWORD_COLOR: lightSyntaxColors.teal,
        OBJECT_HYPERLINK_COLOR: lightSyntaxColors.lightBlue,

        HTML_TAG_COLOR: lightSyntaxColors.blue,
        HTML_TAGNAME_COLOR: lightSyntaxColors.blue,
        HTML_TAGNAME_TEXT_TRANSFORM: lightSyntaxColors.lightBlue,
        HTML_ATTRIBUTE_NAME_COLOR: lightSyntaxColors.lightBlue,
        HTML_ATTRIBUTE_VALUE_COLOR: lightSyntaxColors.yellow,
        HTML_COMMENT_COLOR: lightSyntaxColors.grayFade,
        HTML_DOCTYPE_COLOR: lightSyntaxColors.grayFade,
    },
}
