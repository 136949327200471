import type { CanvasNode } from "document/models/CanvasTree"

export interface WithColorable {
    colorable: boolean
}

export const colorableDefaults: WithColorable = {
    colorable: true,
}

const key: keyof WithColorable = "colorable"

export function withColorable(node: CanvasNode): node is CanvasNode & WithColorable {
    return key in node
}
