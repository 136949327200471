import { assert } from "@framerjs/shared"
import { getServiceMap } from "@framerjs/shared"
import { environment } from "."

export { getServiceMap } from "@framerjs/shared"
export type { ServiceMap } from "@framerjs/shared"

interface DomainMap {
    app: string
    canvas: string
    https: boolean
}

const desktopLocalhost: DomainMap = {
    // Desktop
    app: "127.0.0.1",
    canvas: "127.0.0.1",
    https: false,
}

const desktopLocalhostIPv4: DomainMap = {
    // Desktop make dev
    app: "localhost",
    canvas: "localhost",
    https: false,
}

const webLocal: DomainMap = {
    app: "web.framerlocal.com",
    canvas: "web.framerlocal.com",
    https: false,
}

const domainMaps: DomainMap[] = [
    {
        // Web development
        app: "development.framer.com",
        canvas: "framercanvas.dev",
        https: true,
    },
    {
        // Web development branch previews
        app: ".development.framer.com",
        canvas: ".framercanvas.dev",
        https: true,
    },
    {
        // Web production branch previews
        app: ".framer.com",
        canvas: ".framercanvas.com",
        https: true,
    },
    {
        // Web production
        app: "framer.com",
        canvas: "framercanvas.com",
        https: true,
    },
    webLocal,
    desktopLocalhost,
    desktopLocalhostIPv4,
]

// Can be set to true for testing only. In the future, this may be read from localStorage.
export const hasUnsafeSameOriginSandboxForTesting = false

type DomainKey = Exclude<keyof DomainMap, "https">

function domainMapForEnvironment(domain: DomainKey, hostname: string): DomainMap | undefined {
    // Figure out the right mappings given the current domain. As far as static resources are concerned, the sandbox
    // domains essentially behave as unauthenticated aliases of the primary app domain.
    for (const map of domainMaps) {
        const knownHostname = map[domain]
        if (hostname === knownHostname) {
            // Exact match
            return map
        } else if (knownHostname.startsWith(".") && hostname.endsWith(knownHostname)) {
            // Any subdomain
            return map
        }
    }

    return undefined
}

interface OriginInfo {
    origin: string
    isCrossOriginProtected: boolean
}

export function sandboxAppRelativeToEditor(relativePath: string): { url: string } & OriginInfo {
    const location = window.location

    const url = new URL(relativePath, location.href)
    if (url.origin !== location.origin) {
        throw Error(`Domain lookup requires relative paths. Received ${relativePath}`)
    }

    // Require a trusted environment with correct security
    const domainMap = domainMapForEnvironment("app", location.hostname)
    if (!domainMap || (domainMap.https && url.protocol !== "https:")) {
        throw Error(`Running on unknown app domain: ${url}`)
    }

    // On Web, the canvas domain may be specified beforehand
    let preconfiguredTo: string | undefined
    try {
        preconfiguredTo = new URL(getServiceMap().canvas).hostname
    } catch {
        // Ignore missing configuration, we always have the standard domain to fall back on
    }

    // The canvas domains behave like an alias to the app domain, which means the path can stay the same
    // Replacing the hostname provides the wanted security if CORS restrictions are active.
    const from = preconfiguredTo ? url.hostname : domainMap["app"]
    const to = preconfiguredTo ?? domainMap[hasUnsafeSameOriginSandboxForTesting ? "app" : "canvas"]

    if (url.hostname.endsWith(from)) {
        url.hostname = url.hostname.slice(0, -from.length) + to
    }

    // If the editor domain has a tunnel cookie set, we need to forward that to the sandbox.
    if (environment.isDebugBuild) {
        const tunnel = document.cookie.match(/\btunnel=([^;]*)/)?.[1] || ""
        url.searchParams.set("tunnel", tunnel)
    }

    return {
        url: url.href,
        origin: url.origin,
        isCrossOriginProtected: from !== to,
    }
}

export function assertAllowSameOriginForSandboxApp(relativePath: string) {
    // FIXME: the sandbox never worked in FramerCommand, but it started interfering with exporting. Just silence it
    // until we rip out the whole legacy export system.
    if (navigator.userAgent.indexOf("FramerCommand") !== -1) {
        return
    }

    const location = window.location

    const { origin, isCrossOriginProtected } = sandboxAppRelativeToEditor(relativePath)
    const isLocalhost = location.hostname === desktopLocalhost.app
    const isLocalhostIPv4 = location.hostname === desktopLocalhostIPv4.app
    const isLocalWeb = location.hostname === webLocal.app // FIXME: remove once we have framercanvaslocal.com
    const hasUnsafeSameOriginForLocalDevelopment =
        isLocalhost || isLocalhostIPv4 || isLocalWeb || hasUnsafeSameOriginSandboxForTesting

    // Verify that either the sandbox origin differs from the host to
    // ensure we can safely enable the allow-same-origin permission or that
    // we are running locally in a development environment.
    assert(
        isCrossOriginProtected || hasUnsafeSameOriginForLocalDevelopment,
        `Attempt to create unsafe sandboxed app for origin: ${origin} on page with origin: ${location.origin}`
    )
}

export function assertEditorApp(href: string): { origin: string } {
    const location = window.location

    const editorURL = new URL(href)
    const domainMap = domainMapForEnvironment("app", editorURL.hostname)
    assert(
        editorURL && editorURL.protocol === location.protocol && domainMap,
        `Attempt to create secure link from sandboxed app (${location.href}) to unknown editor domain (${href})`
    )

    return { origin: editorURL.origin }
}
