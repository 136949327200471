import { CanvasNode } from "document/models/CanvasTree/nodes/CanvasNode"
import { BasicShape } from "document/models/CanvasTree/traits/utils/basicShape"
import { withFrameForShape } from "document/models/CanvasTree/traits/mixins/withFrameForShape"
import { withBaseShapeTraits } from "document/models/CanvasTree/traits/mixins/withBaseShapeTraits"
import type { WithSpikes } from "document/models/CanvasTree/traits/Spikes"
import { getStarShapeRecord } from "document/models/CanvasTree/records/shapes/StarShapeRecord"
import { setDefaults } from "../MutableNode"
import type { WithPaths, WithShape } from "framer"
import type { WithRadius } from "document/models/CanvasTree/traits/Radius"
import { withClassDiscriminator } from "utils/withClassDiscriminator"
import { VariableReference } from "../../traits/VariableReference"

export default class StarShapeNode
    extends withClassDiscriminator("StarShapeNode", withFrameForShape(withBaseShapeTraits(CanvasNode)))
    implements WithShape, WithSpikes, WithRadius {
    spikeCount: number
    spikeDepth: number
    radius: number | VariableReference

    constructor(properties?: Partial<StarShapeNode>) {
        super()
        setDefaults<StarShapeNode>(this, getStarShapeRecord(), properties)
        delete this.children // See comment in `MutableNode.ts`
    }

    calculatedPaths(): WithPaths {
        return BasicShape.calculatedPath(this)
    }
}
