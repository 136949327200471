import { isMigratableTree, isTree, Latest } from "./types"
import { migration_55_56 } from "./migrations/migration_55_56"
import { migration_56_57 } from "./migrations/migration_56_57"
import { migration_57_58 } from "./migrations/migration_57_58"
import { migration_58_59 } from "./migrations/migration_58_59"
import { migration_59_60 } from "./migrations/migration_59_60"
import { migration_60_61 } from "./migrations/migration_60_61"
import { migration_61_62 } from "./migrations/migration_61_62"
import { migration_62_63 } from "./migrations/migration_62_63"
import { migration_63_64 } from "./migrations/migration_63_64"
import { migration_64_65 } from "./migrations/migration_64_65"
import { migration_65_66 } from "./migrations/migration_65_66"
import { migration_66_67 } from "./migrations/migration_66_67"
import { migration_67_68 } from "./migrations/migration_67_68"
import { migration_68_69 } from "./migrations/migration_68_69"
import { migration_69_70 } from "./migrations/migration_69_70"
import { migration_70_71 } from "./migrations/migration_70_71"
import { migration_71_72 } from "./migrations/migration_71_72"
import { migration_72_73 } from "./migrations/migration_72_73"
import { migration_73_74 } from "./migrations/migration_73_74"
import { migration_74_75 } from "./migrations/migration_74_75"
import { assert } from "./utils/assert"
import { absurd } from "./utils/absurd"

export function migrateDocument(treeJson: unknown): Latest.Tree {
    assert(isTree(treeJson), "'treeJson' is not a valid Tree")
    assert(isMigratableTree(treeJson), `'treeJson' is not migratable, received version is: ${treeJson.version}`)

    let tree = treeJson
    switch (tree.version) {
        // We are consciously relying on fall-through here
        /* eslint-disable no-fallthrough */
        case 55:
            tree = migration_55_56(tree)
        case 56:
            tree = migration_56_57(tree)
        case 57:
            tree = migration_57_58(tree)
        case 58:
            tree = migration_58_59(tree)
        case 59:
            tree = migration_59_60(tree)
        case 60:
            tree = migration_60_61(tree)
        case 61:
            tree = migration_61_62(tree)
        case 62:
            tree = migration_62_63(tree)
        case 63:
            tree = migration_63_64(tree)
        case 64:
            tree = migration_64_65(tree)
        case 65:
            tree = migration_65_66(tree)
        case 66:
            tree = migration_66_67(tree)
        case 67:
            tree = migration_67_68(tree)
        case 68:
            tree = migration_68_69(tree)
        case 69:
            tree = migration_69_70(tree)
        case 70:
            tree = migration_70_71(tree)
        case 71:
            tree = migration_71_72(tree)
        case 72:
            tree = migration_72_73(tree)
        case 73:
            tree = migration_73_74(tree)
        case 74:
            tree = migration_74_75(tree)
        case 75:
            return tree
        default:
            return absurd(tree)
        /* eslint-enable no-fallthrough */
    }
}
