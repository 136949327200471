import { Service, ServiceChannel, ServiceError, ServiceStream, ServiceManager } from "@framerjs/framer-services"
import { useEffect, useRef } from "react"
import { unhandledError } from "@framerjs/shared"

/**
 * Manages a ServiceStream for use within a component. Setup and teardown is transparently managed within the
 * lifecycle of the component. Should be used in favor of manual stream handling whenever possible.
 *
 * @param configuration The channel, service definition, and callbacks for stream creation and error handling.
 * @param onStreamValue The callback invoked when the stream produces a new value.
 */
export function useServiceStream<Interface, T extends object>(
    configuration: Readonly<{
        // The channel providing the service.
        channel: ServiceChannel | undefined
        // The service's generated namespace (from @framerjs/framer-services).
        service: { service: Service<Interface> }
        // The stream to read. Called after service discovery.
        onDiscover: (service: Interface) => ServiceStream<T>
        // Required error handling (or at least explicit non-handling) with optional retry support.
        onError: (error: Error) => { retry: true } | undefined
    }>,
    // Called for new stream values. Equivalent in behavior to the callback for ServiceStream.read().
    onStreamValue: (value: T) => Promise<void>
): void {
    const init = {
        service: configuration.service.service,
        onDiscover: configuration.onDiscover,
    }

    // Always use the service and stream configuration as passed in the initial call
    const initRef = useRef(init)
    if (initRef.current.service !== init.service) {
        throw new Error("useServiceStream: service must be identical between re-renders")
    }

    // Update the callback functions ref every time this hook is called. We don't want to cancel/replace the stream when
    // a callback changes, e.g. in response to a prop change in the containing component or simply because it's not
    // correctly wrapped with useCallback().
    const callbacks = { onStreamValue, onError: configuration.onError }
    const callbacksRef = useRef(callbacks)
    callbacksRef.current = callbacks

    // The current stream (if any)
    const streamRef = useRef<ServiceStream<object>>()

    // Cleanup for when the channel changes, or for manual cancel
    const cancelStream = () => {
        const stream = streamRef.current
        streamRef.current = undefined
        stream?.cancel().catch(() => {}) // Ignore errors
    }

    // Respond to channel changes
    const { channel } = configuration
    useEffect(() => {
        if (!channel) return // Nothing to read or clean up

        const readStream = async () => {
            let done = false
            let previousErrorCount = 0
            while (!done) {
                // Default to *not* looping indefinitely
                done = true

                try {
                    // Discover the service
                    const currentInit = initRef.current
                    const implementation = await ServiceManager.shared().discover(currentInit.service, channel, {
                        validateValues: true,
                    })

                    // Request a new stream
                    const stream = currentInit.onDiscover(implementation)
                    streamRef.current = stream

                    // Reset the error count if we get past discovery
                    previousErrorCount = 0

                    // Read until the stream ends
                    await stream.read(value => callbacksRef.current.onStreamValue(value))
                } catch (error) {
                    // Allow error recovery...
                    const recovery = callbacksRef.current.onError(error)

                    // ...but only once for every successful discovery
                    previousErrorCount++
                    if (previousErrorCount > 1) {
                        continue
                    }

                    if (recovery?.retry === true) {
                        // Wait until the next event loop (e.g. to let a frame's "unload" finish) before trying again
                        await new Promise(resolve => setTimeout(resolve, 0))
                        done = false
                    }
                }
            }
        }

        readStream().catch(unhandledError)
        return cancelStream
    }, [channel])
}

// Generic handler for useServiceStream() error handler
export function unhandledStreamError(err: Error): { retry: true } | undefined {
    if (err instanceof ServiceError.ServiceGone) {
        // The frame may have reloaded, try rediscovering the stream
        return { retry: true }
    } else {
        unhandledError(err)
    }
}
