import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springAnimation } from "./utils"
import { colors } from "../tokens"

export const AnimationImportMenuWindows = React.memo(function AnimationImportMenuWindows() {
    const figmaControl = useAnimation()
    const arrowControl = useAnimation()
    const framerControl = useAnimation()

    async function animation() {
        figmaControl.set({ scale: 1, opacity: 1, x: 0 })
        arrowControl.set({ opacity: 0, x: 40 })
        framerControl.set({ scale: 0, opacity: 0, x: 40 })

        await Promise.all([
            figmaControl.start({
                scale: 0,
                opacity: 0,
                x: -40,
                transition: { ...springAnimation.default, delay: 0.5 },
            }),
            arrowControl.start({
                opacity: 1,
                x: 0,
                transition: { ...springAnimation.default, delay: 0.5 },
            }),
        ])
        await Promise.all([
            arrowControl.start({
                opacity: 0,
                x: -40,
                transition: springAnimation,
            }),
            framerControl.start({
                scale: 1,
                opacity: 1,
                x: 0,
                transition: springAnimation,
            }),
            figmaControl.start({
                x: 0,
            }),
        ])
        return await Promise.all([
            framerControl.start({
                scale: 0,
                opacity: 0,
                x: 0,
                transition: springAnimation,
            }),
            figmaControl.start({
                scale: 1,
                opacity: 1,
                transition: springAnimation,
            }),
        ])
    }

    useLoop(animation)

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 40,
                height: 40,
                background: "transparent",
                overflow: "hidden",
            }}
        >
            <motion.div animate={figmaControl} style={{ position: "absolute", opacity: 0 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
                    <g transform="translate(7.5 1)">
                        <path
                            d="M 6.25 39 C 9.7 39 12.5 36.088 12.5 32.5 L 12.5 26 L 6.25 26 C 2.8 26 0 28.912 0 32.5 C 0 36.088 2.8 39 6.25 39 Z"
                            fill={colors.animationForground}
                            fillOpacity={0.5}
                        ></path>
                        <path
                            d="M 0 19.5 C 0 15.912 2.8 13 6.25 13 L 12.5 13 L 12.5 26 L 6.25 26 C 2.8 26 0 23.088 0 19.5 Z"
                            fill={colors.animationForground}
                            fillOpacity={0.75}
                        ></path>
                        <path
                            d="M 25 19.5 C 25 23.088 22.2 26 18.75 26 C 15.3 26 12.5 23.088 12.5 19.5 C 12.5 15.912 15.3 13 18.75 13 C 22.2 13 25 15.912 25 19.5 Z"
                            fill={colors.animationForground}
                            fillOpacity={0.75}
                        ></path>
                        <path
                            d="M 0 6.5 C 0 2.912 2.8 0 6.25 0 L 18.75 0 C 22.2 0 25 2.912 25 6.5 C 25 10.088 22.2 13 18.75 13 L 6.25 13 C 2.8 13 0 10.088 0 6.5 Z"
                            fill={colors.animationForground}
                        ></path>
                    </g>
                </svg>
            </motion.div>
            <motion.div animate={arrowControl} style={{ position: "absolute", opacity: 0 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
                    <g transform="translate(0 3)"></g>
                    <path
                        d="M 8 20 L 32 20 L 8 20 L 32 20"
                        fill="transparent"
                        strokeWidth="4"
                        stroke={colors.animationForground}
                        strokeLinecap="round"
                    ></path>
                    <path
                        d="M 22 10 L 32 20 L 22 30"
                        fill="transparent"
                        strokeWidth="4"
                        stroke={colors.animationForground}
                        strokeLinecap="round"
                    ></path>
                </svg>
            </motion.div>
            <motion.div animate={framerControl} style={{ position: "absolute", opacity: 0 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
                    <path
                        d="M 7 1 L 33 1 L 33 14 L 20 14 Z M 7 14 L 20 14 L 33 27 L 20 27 L 20 40 L 7 27 Z"
                        fill={colors.animationForground}
                    ></path>
                </svg>
            </motion.div>
        </div>
    )
})
