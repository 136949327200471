import { V65, V66 } from "../types"
import { transform } from "../utils/transform"
import { _, exactCheck } from "../utils/exactCheck"
import { withoutProps } from "../utils/withoutProps"

export function migration_65_66(documentJSON: V65.Tree): V66.Tree {
    const migratedRoot = transform(documentJSON.root, node => {
        switch (node.__class) {
            case "CodeComponentNode":
            case "SVGNode":
            case "TextNode":
            case "PathNode":
            case "RectangleShapeNode":
            case "OvalShapeNode":
            case "PolygonShapeNode":
            case "StarShapeNode": {
                const migratedNode = withoutProps({ ...node }, "children")
                return exactCheck(migratedNode, _ as V66.TreeNode)
            }
            default:
                return exactCheck(node, _ as V66.TreeNode)
        }
    })
    return { version: 66, root: migratedRoot }
}
