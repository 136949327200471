import type { CanvasNode } from "document/models/CanvasTree"
import { Color } from "framer"
import type { ImageFit } from "framer"
import { stylableNode } from "./utils/stylableNode"
import type { LinearGradient, RadialGradient } from "document/models/Gradient"
import type { VariableReference } from "./VariableReference"

export type FillType = "color" | "linear-gradient" | "radial-gradient" | "image"

export interface WithFill {
    fillType: FillType | undefined
    fillColor: string | VariableReference | undefined
    fillLinearGradient: LinearGradient | undefined
    fillRadialGradient: RadialGradient | undefined
    fillImage: string | VariableReference | null | undefined
    fillImageOriginalName: string | null | undefined
    fillImageResize: ImageFit | undefined
    fillImagePixelWidth: number | null | undefined
    fillImagePixelHeight: number | null | undefined
}

export interface WithOptionalFill extends WithFill {
    fillEnabled: boolean | undefined
}
export interface FillProperties {
    fillProperties?: WithFill
    fillDisabled?: boolean
}

export interface WithSupportsGradient extends WithFill {
    supportsGradient(): boolean
}

const fillKey: keyof WithFill = "fillType"
const fillEnabledKey: keyof WithOptionalFill = "fillEnabled"
const supportsGradientKey: keyof WithSupportsGradient = "supportsGradient"

export function withFill(node: CanvasNode): node is CanvasNode & WithFill {
    return fillKey in node && stylableNode(node)
}

export function withOptionalFill(node: CanvasNode): node is CanvasNode & WithOptionalFill {
    return fillEnabledKey in node && stylableNode(node)
}

function withSupportsGradient(node: CanvasNode): node is CanvasNode & WithSupportsGradient {
    return supportsGradientKey in node && stylableNode(node)
}

export function supportsGradient(node: CanvasNode): node is CanvasNode & WithFill {
    if (!stylableNode(node)) return false
    return withSupportsGradient(node) ? node.supportsGradient() : withFill(node)
}

export function hasActiveGradientFill(node: CanvasNode & WithFill) {
    return node.fillType === "linear-gradient" || node.fillType === "radial-gradient"
}

export function hasActiveImageFill(node: CanvasNode & WithFill & WithOptionalFill) {
    return node.fillEnabled && node.fillType === "image" && node.fillImage !== null
}

export function hasTransparentFill(node: CanvasNode & WithFill): boolean {
    if (node.fillType === "color") {
        const fillColor = node.resolveValue("fillColor")
        if (!fillColor) return false

        const color = Color(fillColor)
        return color.a === 0
    }

    if (node.fillType === "linear-gradient") {
        if (!node.fillLinearGradient) return false
        return node.fillLinearGradient.alpha === 0
    }

    if (node.fillType === "radial-gradient") {
        if (!node.fillRadialGradient) return false
        return node.fillRadialGradient.alpha === 0
    }

    return false
}
