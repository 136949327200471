import type { NodeTree } from "../NodeTree"
import type { MutableNode } from "./MutableNode"

export class NodeCache {
    latest: { tree: NodeTree } | null = null
    future: MutableNode | null = null

    constructor() {}

    tree() {
        return this.latest!.tree
    }

    reset() {
        this.future = null
    }
}
