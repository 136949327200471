// ♻️
// IMPORTANT: Take care to apply changes to all supported languages
// when modifying code or documentation in the services runtime.

/**
 * Channels implement the cross-process transport of the service messages that underlie service communication.
 */
export interface ServiceChannel {
    /** Optionally indicates whether the channel can be used at all. Must be accurate for the lifetime of the channel. */
    readonly disabled?: boolean
    /** Sends a message to the other end of the channel. */
    postMessage(message: ServiceChannel.Message): void
    /** Adds a listener for any message arriving at this end of the channel. */
    addMessageListener(_: (message: ServiceChannel.Message) => void): void
    /** Removes a listener. */
    removeMessageListener(_: (message: ServiceChannel.Message) => void): void
}

export namespace ServiceChannel {
    /** Marks oneway method requests. */
    export const onewayRequestId = "oneway"
    /** Marks oneway stream value responses. */
    export const onewayStreamResponseIdPrefix = "#oneway:"

    /**
     * Implementations only need to be able to (de)serialize the `Message` type. The `body` of messages is considered
     * opaque in that the channel should not need to be (or even able to be) aware of the type of the data.
     */
    export interface Message {
        type: MessageType
        id: string | typeof onewayRequestId
        serviceId: string // FIXME: rename to service?
        method: string
        stream?: string
        body?: MessageBody
    }

    export enum MessageType {
        Request = "request",
        Response = "response",
        Error = "error",
    }

    export function isMessage(obj: any): obj is Message {
        if (typeof obj !== "object") return false
        return (
            obj.type === ServiceChannel.MessageType.Request ||
            obj.type === ServiceChannel.MessageType.Response ||
            obj.type === ServiceChannel.MessageType.Error
        )
    }

    /** Message bodies are currently only JSON, but could be a serialized protobuf format. */
    type Primitive = string | number | boolean
    export interface MessageBody {
        readonly [key: string]:
            | Primitive
            | readonly Primitive[]
            | MessageBody
            | readonly MessageBody[]
            | undefined
            | null
    }
}
