import { List } from "immutable"
import StarShapeNode from "document/models/CanvasTree/nodes/shapes/StarShapeNode"
import OvalShapeNode from "document/models/CanvasTree/nodes/shapes/OvalShapeNode"
import RectangleShapeNode from "document/models/CanvasTree/nodes/shapes/RectangleShapeNode"
import PolygonShapeNode from "document/models/CanvasTree/nodes/shapes/PolygonShapeNode"
import type { DrawableNode } from "document/models/CanvasTree/nodes/TreeNode"
import type { Rect, Point } from "framer"
import { WithPaths, PathSegment, WithShape } from "framer"
import Path from "document/models/Path"
import type { WithSize } from "document/models/CanvasTree/traits/Size"
import { spikesDefaults } from "document/models/CanvasTree/traits/Spikes"
import { isVariableReference } from "../VariableReference"

export namespace BasicShape {
    export function isBasicShape(
        node: DrawableNode
    ): node is DrawableNode & (RectangleShapeNode | OvalShapeNode | StarShapeNode | PolygonShapeNode) {
        return (
            node instanceof RectangleShapeNode ||
            node instanceof OvalShapeNode ||
            node instanceof StarShapeNode ||
            node instanceof PolygonShapeNode
        )
    }

    export function calculatedPath(node: DrawableNode & WithSize & WithShape, newFrame?: Rect): WithPaths {
        if (node.mutable) {
            return calculatePath(node, newFrame)
        }

        const cache = node.cache
        if (!cache.calculatedPaths) {
            cache.calculatedPaths = calculatePath(node, newFrame)
        }

        return cache.calculatedPaths
    }

    function calculatePath(node: DrawableNode & WithSize & WithShape, newFrame?: Rect): WithPaths {
        const paths = path(node, newFrame)

        if (node instanceof OvalShapeNode) {
            return paths
        }

        return paths.map(currentPath => {
            return Path.applyRadius(currentPath.pathSegments, currentPath.pathClosed)
        })
    }

    export function path(node: WithSize & WithShape, newFrame?: Rect) {
        const { width, height } = newFrame || node

        if (node instanceof OvalShapeNode) {
            return makeOval(width, height)
        }

        if (node instanceof StarShapeNode) {
            return makeStar(
                width,
                height,
                node.spikeCount,
                node.spikeDepth,
                isVariableReference(node.radius) ? 0 : node.radius
            )
        }

        if (node instanceof PolygonShapeNode) {
            return makePolygon(width, height, node.polygonSides, isVariableReference(node.radius) ? 0 : node.radius)
        }

        if (node instanceof RectangleShapeNode) {
            return makeRectangle(
                width,
                height,
                isVariableReference(node.radius) ? 0 : node.radius,
                node.radiusPerCorner,
                node.radiusTopLeft,
                node.radiusTopRight,
                node.radiusBottomRight,
                node.radiusBottomLeft
            )
        }

        throw Error("can't make path, uknown basic shape")
    }

    export function makeRoundedRectangle(width: number, height: number) {
        return makeRectangle(width, height, Math.max(width, height))
    }

    export function makeRectangle(
        width: number,
        height: number,
        radius: number = 0,
        radiusPerCorner: boolean = false,
        radiusTopLeft: number = 0,
        radiusTopRight: number = 0,
        radiusBottomLeft: number = 0,
        radiusBottomRight: number = 0
    ) {
        const r1 = radiusPerCorner ? radiusTopLeft : radius
        const r2 = radiusPerCorner ? radiusTopRight : radius
        const r3 = radiusPerCorner ? radiusBottomLeft : radius
        const r4 = radiusPerCorner ? radiusBottomRight : radius

        const pathSegments = List([
            new PathSegment({ radius: r1 }),
            new PathSegment({ x: width, y: 0, radius: r2 }),
            new PathSegment({ x: width, y: height, radius: r3 }),
            new PathSegment({ x: 0, y: height, radius: r4 }),
        ])

        return [{ pathSegments, pathClosed: true }]
    }

    export function makeOval(width: number, height: number) {
        const ovalLen = (2 * (Math.sqrt(2) - 1)) / 3
        const pathSegments = List([
            new PathSegment({
                x: width / 2,
                y: 0,
                handleMirroring: "symmetric",
                handleOutX: ovalLen * width,
                handleOutY: 0,
                handleInX: -ovalLen * width,
                handleInY: 0,
            }),
            new PathSegment({
                x: width,
                y: height / 2,
                handleMirroring: "symmetric",
                handleOutY: ovalLen * height,
                handleOutX: 0,
                handleInY: -ovalLen * height,
                handleInX: 0,
            }),
            new PathSegment({
                x: width / 2,
                y: height,
                handleMirroring: "symmetric",
                handleOutX: -ovalLen * width,
                handleOutY: 0,
                handleInX: ovalLen * width,
                handleInY: 0,
            }),
            new PathSegment({
                x: 0,
                y: height / 2,
                handleMirroring: "symmetric",
                handleOutY: -ovalLen * height,
                handleOutX: 0,
                handleInY: ovalLen * height,
                handleInX: 0,
            }),
        ])
        return [{ pathSegments, pathClosed: true }]
    }

    export function makeStar(
        width: number,
        height: number,
        spikeCount: number = spikesDefaults.spikeCount,
        spikeDepth: number = spikesDefaults.spikeDepth,
        radius: number = 0
    ) {
        const outerRadius = 1
        const innerRadius = Math.max(0, Math.min(1, (100 - spikeDepth) / 100))

        const segments: Point[] = []

        let rotation = (Math.PI / 2) * 3
        let x = 0
        let y = 0
        const step = Math.PI / spikeCount

        for (let i = 0; i < spikeCount; i++) {
            x = Math.cos(rotation) * outerRadius
            y = Math.sin(rotation) * outerRadius
            segments.push({ x, y })
            rotation += step

            x = Math.cos(rotation) * innerRadius
            y = Math.sin(rotation) * innerRadius
            segments.push({ x, y })
            rotation += step
        }

        const pathSegments = List(
            segments.map(point => {
                return new PathSegment({
                    x: ((point.x + 1) * width) / 2,
                    y: ((point.y + 1) * height) / 2,
                    radius,
                })
            })
        )

        return [{ pathSegments, pathClosed: true }]
    }

    export function makePolygon(width: number, height: number, sides: number = 6, radius: number = 0) {
        const outerRadius = 1

        const segments: Point[] = []

        let rotation = (Math.PI / 2) * 3
        let x = 0
        let y = 0
        const step = (2 * Math.PI) / sides

        for (let i = 0; i < sides; i++) {
            x = Math.cos(rotation) * outerRadius
            y = Math.sin(rotation) * outerRadius
            segments.push({ x, y })
            rotation += step
        }

        const pathSegments = List(
            segments.map(point => {
                return new PathSegment({
                    x: ((point.x + 1) * width) / 2,
                    y: ((point.y + 1) * height) / 2,
                    radius,
                })
            })
        )

        return [{ pathSegments, pathClosed: true }]
    }
}
