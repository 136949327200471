// XXX:DRAFT Delete file

import { OrderedSet } from "immutable"
import { EditorState } from "draft-js"
import type { StyledText, SerializedStyledText } from "./StyledText"
import { Size, getStyleForTypefaceOrSelector, draftStyleFunction, TextAlignment, environment } from "framer"

function getBrowser() {
    if (environment.isChrome()) return "chrome"
    if (environment.isSafari()) return "safari"
    if (environment.isFirefox()) return "firefox"
    if (environment.isFramerX()) return "framerx"
    if (environment.isEdge()) return "edge"
    return ""
}

/** Only macOS version is currently supported */
function getOSVersion() {
    if (!environment.isMacOS()) return ""
    const navigator = window.navigator
    if (!navigator) return ""
    const userAgent = navigator.userAgent
    if (!userAgent) return ""
    const match = userAgent.match(/\d+[_]\d+/)
    if (!(match && match[0])) return ""
    return match[0]
}

/**
 * Current cache key format:
 * [os] [osVersion] [browser]
 *
 * Examples:
 * macos 10_14 framerx
 * macos 10_14 safari
 * windows  chrome
 * windows  firefox
 *
 * To make texts more lightweight, we do two things:
 * 1. simple texts (1 block with 1 range) we render the html raw
 * 2. when loaded from file and there is cached html and a size, we
 *    delay creating a full StyledTextDraft as long as possible
 *
 * At the moment, when a text field is part of a selection, it must be
 * materialized (revived) for its colors, sizes, etc that the property
 * panel needs to render.
 *
 * Especially between os releases, fonts might render differently size wise, so we reject any caches from wrong os
 * Since Framer Web, we also add the os name and browser to the cache key
 */
function getOSCacheKey(): string {
    if (process.env.NODE_ENV === "test") return "10_13"

    const os = environment.deviceOS() || ""
    const osVersion = getOSVersion()
    const browser = getBrowser()

    return [os, osVersion, browser].join(" ")
}

const osCacheKey = getOSCacheKey()

export class StyledTextCache {
    // copy constructor
    constructor(readonly html: string, readonly width: number, readonly size: Size, rawLoadedValue?: any) {
        if (rawLoadedValue) {
            this._rawLoadedValue = rawLoadedValue
        }
    }

    // used to construct the first cache when loading from file
    static fromRawValue(rawLoadedValue: any): StyledTextCache | undefined {
        const cached = rawLoadedValue.cached
        if (!cached) return undefined
        if (cached.os !== osCacheKey) return undefined

        const { html, width, size } = cached
        if (typeof html === "string" && width >= 0 && size) {
            if (size.width >= 0 && size.height >= 0) {
                const candidate = new StyledTextCache(html, width, size, rawLoadedValue)
                if (candidate.validateCaches()) {
                    return candidate
                }
            }
        }
        return undefined
    }

    // saved to file
    toJS(): { html: string; width: number; size: Size; os: string } {
        return { html: this.html, width: this.width, size: this.size, os: osCacheKey }
    }

    toJSON() {
        return this.toJS()
    }

    // these are only used as long as the real StyledTextDraft has not yet been created

    _rawLoadedValue: any | undefined
    _alignment: string | undefined
    _text: string | undefined

    // try to figure out if font per spec translates to same font per html, otherwise don't use the cache
    validateCaches(): boolean {
        const font = this.font()
        if (!font) return true

        const css = getStyleForTypefaceOrSelector(font)
        const escaped = (css.fontFamily || "").replace(/"/g, "&quot;")
        return this.html.includes(escaped)
    }

    // undefined is a proper value
    alignment(styledText: StyledText<EditorState>): TextAlignment {
        if (!this._rawLoadedValue) return styledText.alignment()

        if (this._alignment) {
            if (this._alignment === "undefined") return undefined
            return this._alignment as TextAlignment
        }

        const blocks = this._rawLoadedValue.blocks as any[]
        if (!blocks) return undefined

        let alignment: string | undefined = undefined
        for (let i = 0, il = blocks.length; i < il; i++) {
            const block = blocks[i]
            if (alignment) break
            const ranges = block.inlineStyleRanges as any[] | undefined
            if (ranges) {
                for (let j = 0, jl = ranges.length; j < jl; j++) {
                    const range = ranges[j]
                    if (alignment) break
                    const style = range.style
                    if (style && style.startsWith("ALIGN:")) {
                        alignment = style.slice(6)
                    }
                }
            }
        }

        this._alignment = alignment ? alignment : "undefined"
        return alignment as TextAlignment
    }

    text(): string | null {
        if (this._text !== undefined) return this._text
        if (!this._rawLoadedValue) return null
        const blocks = this._rawLoadedValue.blocks as any[]
        if (!blocks) return null

        const texts: string[] = []
        for (let i = 0, il = blocks.length; i < il; i++) {
            const block = blocks[i]
            texts.push(block.text || "")
        }

        this._text = texts.join("\n")
        return this._text
    }

    font(): string | null {
        if (!this._rawLoadedValue) return null

        const blocks = this._rawLoadedValue.blocks as any[]
        if (!blocks) return null

        for (let i = 0, il = blocks.length; i < il; i++) {
            const block = blocks[i]
            const ranges = block.inlineStyleRanges as any[] | undefined
            if (ranges) {
                for (let j = 0, jl = ranges.length; j < jl; j++) {
                    const range = ranges[j]
                    const style = range.style
                    if (style && style.startsWith("FONT:")) {
                        return style.slice(5)
                    }
                }
            }
            const data = block.data
            if (data) {
                const emptyStyle = data.emptyStyle as string[] | undefined
                if (emptyStyle) {
                    for (let j = 0, jl = emptyStyle.length; j < jl; j++) {
                        const style = emptyStyle[j]
                        if (style.startsWith("FONT:")) {
                            return style.slice(5)
                        }
                    }
                }
            }
        }
        return null
    }

    fonts(): string[] | null {
        const font = this.font()
        if (!font) return null
        return [font]
    }

    // this is used for exporting to html and preview
    serialize(): SerializedStyledText | null {
        if (!this._rawLoadedValue) return null

        const blocks = this._rawLoadedValue.blocks as any[]
        if (!blocks) return null
        if (blocks.length !== 1) return null

        let alignment: string | undefined = undefined

        const block = blocks[0]
        const text = block.text as string | undefined
        if (!text) return null
        const ranges = block.inlineStyleRanges as any[] | undefined
        if (!ranges) return null

        const styles: string[] = []
        for (let i = 0, il = ranges.length; i < il; i++) {
            const range = ranges[i]
            const style = range.style
            if (style) {
                styles.push(style)
                if (style.startsWith("ALIGN:")) {
                    alignment = style.slice(6)
                }
            }
        }
        const css = draftStyleFunction(OrderedSet<string>(styles), undefined, false)
        const startIndex = 0
        const endIndex = text.length

        const block1 = { text, inlineStyles: [{ css, startIndex, endIndex }] }
        return { blocks: [block1], alignment }
    }
}
