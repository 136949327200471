import type {
    BooleanControlDescription,
    ColorControlDescription,
    ControlDescription,
    EventHandlerControlDescription,
    ImageControlDescription,
    NumberControlDescription,
    StringControlDescription,
} from "framer"
import type { CanvasNode } from "document/models/CanvasTree"

import { ControlType, ConvertColor } from "framer"
import { assert } from "@framerjs/shared"
import { componentLoader } from "@framerjs/framer-runtime"
import { upperCaseFirstChar } from "utils/names"
import { titleCase } from "utils/titleCase"
import { isValidPropertyValueType } from "document/components/chrome/properties/codeComponentRows/utils/isValidPropertyValue"
import { getFallbackValue } from "../traits/utils/codeComponentProps"
import { isTokenCSSVariable } from "../nodes/TokenNode"

export type VariableType =
    | ControlType.Boolean
    | ControlType.Color
    | ControlType.EventHandler
    | ControlType.Image
    | ControlType.Number
    | ControlType.String
    | "controlReference"

export const allVariableTypes: VariableType[] = [
    ControlType.Boolean,
    ControlType.Color,
    ControlType.Image,
    ControlType.Number,
    ControlType.String,
    ControlType.EventHandler,
    "controlReference",
]

export function variableTypeTitle(type: VariableType): string {
    switch (type) {
        case ControlType.EventHandler:
            return "Event"
        default:
            return upperCaseFirstChar(type)
    }
}

export function isVariableType(value: unknown): value is VariableType {
    return allVariableTypes.some(t => t === value)
}

export type VariableID = string

export type Variable =
    | BooleanVariable
    | ColorVariable
    | NumberVariable
    | StringVariable
    | EventHandlerVariable
    | ImageVariable
    | ControlReferenceVariable

export type Variables = Variable[]

export interface VariableBase {
    id: VariableID
    name: string
    type: VariableType
    initialValue: unknown
    exposeInProps: boolean
}

export interface BooleanVariable extends VariableBase {
    type: ControlType.Boolean
    initialValue: boolean
    options?: BooleanVariableOptions
}

export interface ColorVariable extends VariableBase {
    type: ControlType.Color
    initialValue: string
}

export interface NumberVariable extends VariableBase {
    type: ControlType.Number
    initialValue: number
    options?: NumberVariableOptions
}

export interface StringVariable extends VariableBase {
    type: ControlType.String
    initialValue: string
    options?: StringVariableOptions
}

export interface EventHandlerVariable extends VariableBase {
    type: ControlType.EventHandler
}

export interface ImageVariable extends VariableBase {
    type: ControlType.Image
    initialValue: string | undefined
}

export interface ControlReferenceVariable extends VariableBase {
    type: "controlReference"
    initialValue: unknown
    entityIdentifier: string
    controlKey: string
    expectedType: ControlType
}

// Variable options

export interface BooleanVariableOptions {
    enabledTitle?: string
    disabledTitle?: string
}

export interface NumberVariableOptions {
    min?: number
    max?: number
    unit?: "%" | "°"
    step?: number
    displayStepper?: boolean
}

export interface StringVariableOptions {
    placeholder?: string
    displayTextArea?: boolean
}

export type VariableValueMap = Map<VariableID, unknown>

export interface WithVariables {
    variables: Variables
    getVariable(id: VariableID): Variable | undefined
    getVariableValue(id: VariableID): unknown
    hasVariable(id: VariableID): boolean
    getVariableValueMap(): VariableValueMap
}

export const variablesDefaults: Omit<
    WithVariables,
    "getVariables" | "getVariable" | "getVariableValue" | "hasVariable" | "getVariableValueMap"
> = {
    variables: [],
}

const key: keyof WithVariables = "variables"

export function withVariables(node: CanvasNode): node is CanvasNode & WithVariables {
    return key in node
}

export interface WithVariableSupport {
    supportsVariables(): boolean
}

// Control descriptions

function variableTitle(variable: Variable) {
    return variable.name ? titleCase(variable.name) : "Unknown"
}

function booleanControlDescription(variable: BooleanVariable): BooleanControlDescription {
    const { enabledTitle, disabledTitle } = variable.options ?? {}
    return {
        type: variable.type,
        title: variableTitle(variable),
        defaultValue: getDefaultValue(variable.type, variable.initialValue),
        enabledTitle,
        disabledTitle,
    }
}

function numberControlDescription(variable: NumberVariable): NumberControlDescription {
    const { min, max, step, unit, displayStepper } = variable.options ?? {}
    return {
        type: variable.type,
        title: variableTitle(variable),
        defaultValue: getDefaultValue(variable.type, variable.initialValue),
        min,
        max,
        step,
        unit,
        displayStepper,
    }
}

function colorControlDescription(variable: ColorVariable): ColorControlDescription {
    const defaultValue = isTokenCSSVariable(variable.initialValue)
        ? variable.initialValue
        : ConvertColor.toRgbString(variable.initialValue)

    return {
        type: variable.type,
        title: variableTitle(variable),
        defaultValue: getDefaultValue(variable.type, defaultValue),
    }
}

function stringControlDescription(variable: StringVariable): StringControlDescription {
    const { placeholder, displayTextArea } = variable.options ?? {}
    return {
        type: variable.type,
        title: variableTitle(variable),
        defaultValue: getDefaultValue(variable.type, variable.initialValue),
        placeholder,
        displayTextArea,
    }
}

/**
 * Here we extend the public `ImageControlDescription` type with a default value
 * to allow us to store default image url for use in the generated component in
 * the same structure that we store all our other variable defaults. This lets
 * us avoid publishing a new public type that could lead to end-user confusion,
 * since `defaultValue` is not a valid property control value for images. We
 * strip this value when we generate the Variant Instance's property controls.
 */
function imageControlDescription(variable: ImageVariable): ImageControlDescription & { defaultValue: string } {
    return {
        type: variable.type,
        title: variableTitle(variable),
        defaultValue: getDefaultValue(variable.type, variable.initialValue),
    }
}

function eventHandlerControlDescription(variable: EventHandlerVariable): EventHandlerControlDescription {
    return { type: variable.type, title: variableTitle(variable) }
}

function isCompatibleControlType(controlType: ControlType, expectedType: ControlType) {
    switch (controlType) {
        case ControlType.Enum:
        case ControlType.SegmentedEnum:
            return expectedType === ControlType.Enum || expectedType === ControlType.SegmentedEnum
        default:
            return controlType === expectedType
    }
}

function controlReferenceControlDescription(variable: ControlReferenceVariable): ControlDescription | null {
    const component = componentLoader.componentForIdentifier(variable.entityIdentifier)
    if (!component || !component.properties) return null
    // For now we only support references to components, not actions
    if (component.type !== "component") return null
    const control = component.properties[variable.controlKey]
    if (!control) return null
    if (!isCompatibleControlType(control.type, variable.expectedType)) return null
    assert(
        control.type === ControlType.Enum || control.type === ControlType.SegmentedEnum,
        "For now we only support enum variable references"
    )
    return {
        type: ControlType.Enum,
        title: variableTitle(variable),
        options: control.options,
        optionTitles: control.optionTitles,
        displaySegmentedControl: control.type === ControlType.SegmentedEnum || control.displaySegmentedControl,
        defaultValue: getDefaultValue(variable.expectedType, variable.initialValue) as any, // getDefaultValue will always return a valid value
    }
}

export function controlDescriptionFromVariable(variable: BooleanVariable): BooleanControlDescription
export function controlDescriptionFromVariable(variable: NumberVariable): NumberControlDescription
export function controlDescriptionFromVariable(variable: ColorVariable): ColorControlDescription
export function controlDescriptionFromVariable(variable: StringVariable): StringControlDescription
export function controlDescriptionFromVariable(
    variable: ImageVariable
): ImageControlDescription & { defaultValue: string }
export function controlDescriptionFromVariable(variable: EventHandlerVariable): EventHandlerControlDescription
export function controlDescriptionFromVariable(variable: ControlReferenceVariable): ControlDescription | null
export function controlDescriptionFromVariable(variable: Variable): ControlDescription | null
export function controlDescriptionFromVariable(variable: Variable): ControlDescription | null {
    switch (variable.type) {
        case ControlType.Boolean:
            return booleanControlDescription(variable)
        case ControlType.Number:
            return numberControlDescription(variable)
        case ControlType.Color:
            return colorControlDescription(variable)
        case ControlType.String:
            return stringControlDescription(variable)
        case ControlType.Image:
            return imageControlDescription(variable)
        case ControlType.EventHandler:
            return eventHandlerControlDescription(variable)
        case "controlReference": {
            return controlReferenceControlDescription(variable)
        }
    }
}

function getDefaultValue(type: ControlType.Boolean, initialValue: unknown): boolean
function getDefaultValue(type: ControlType.Color, initialValue: unknown): string
function getDefaultValue(type: ControlType.Image, initialValue: unknown): string
function getDefaultValue(type: ControlType.Number, initialValue: unknown): number
function getDefaultValue(type: ControlType.String, initialValue: unknown): string
function getDefaultValue(type: ControlType, initialValue: unknown): unknown
function getDefaultValue(type: ControlType, initialValue: unknown): unknown {
    return isValidPropertyValueType(type, initialValue) ? initialValue : getFallbackValue(type)
}
