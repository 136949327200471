import { Color } from "framer"
import { TokenNode, isTokenCSSVariable } from "./TokenNode"
import type { CanvasTree } from ".."
import { findTokenNode } from "../utils/tokens"
import { withClassDiscriminator } from "utils/withClassDiscriminator"

export function isColorToken(token: unknown): token is ColorTokenNode {
    return token instanceof ColorTokenNode
}

export function computeColor(
    value: string | undefined,
    tree: CanvasTree,
    defaultValue: string = "transparent"
): string {
    if (typeof value === "undefined") return defaultValue
    if (!isTokenCSSVariable(value)) return value

    const token = findTokenNode(value, tree)
    return token && typeof token.value === "string" ? token.value : defaultValue
}

export class ColorTokenNode extends withClassDiscriminator("ColorTokenNode", TokenNode) {
    value: string = "#fff"

    constructor(properties?: Partial<ColorTokenNode>) {
        super()
        // Enforce color values to be saved as rgb() for consistency.
        const overrides: Partial<ColorTokenNode> = {}
        if (properties && properties.value) {
            overrides.value = Color.toRgbString(Color(properties.value))
        }
        Object.assign(this, properties, overrides)
    }
}
