import type { MotionStyle } from "framer-motion"
import type { CanvasNode } from "../../nodes/CanvasNode"
import type { CollectTraitOptions } from "."
import { isNumber, isString, isUndefined } from "utils/typeChecks"
import { withFilters } from "document/models/CanvasTree/traits/Filters"
import { isVariableReference } from "../VariableReference"

export function collectBackgroundFilters(
    node: CanvasNode,
    style: MotionStyle,
    { withInlineVariables = false, unset = false }: CollectTraitOptions = {}
): void {
    if (!withFilters(node)) return
    if (isUndefined(node.backgroundBlur)) {
        if (unset) style.backdropFilter = "none"
        return
    }

    if (!isNumber(node.backgroundBlur) && !isVariableReference(node.backgroundBlur)) return

    const value = node.resolveValue("backgroundBlur", withInlineVariables)
    // We've already checked if node.backgroundBlur is number or variableReference above,
    // so here we don't double check if the resolved value is an inline variable
    // to avoid the (possibly) costly regex check.
    if (isNumber(value) || isString(value)) {
        style.backdropFilter = style.WebkitBackdropFilter = `blur(${value}px)`
    }
}
