export default function compareIndexArrays(arrayA: number[], arrayB: number[]): -1 | 0 | 1 {
    let i = 0
    while (i < arrayA.length && i < arrayB.length) {
        if (arrayA[i] < arrayB[i]) {
            return -1
        } else if (arrayA[i] > arrayB[i]) {
            return 1
        }
        i++
    }
    // Either array ended

    if (i >= arrayA.length && i >= arrayB.length) {
        return 0
    } else if (i >= arrayA.length) {
        return -1
    } else {
        return 1
    }
}
