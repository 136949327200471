import * as React from "react"

const tabbingEnabledContext = React.createContext(true)

type TabIndex = React.HtmlHTMLAttributes<HTMLElement>["tabIndex"]

export function useTabIndex(tabIndex: TabIndex): TabIndex {
    const tabbingEnabled = React.useContext(tabbingEnabledContext)
    return tabbingEnabled ? tabIndex : -1
}

export interface TabbableProps {
    enabled: boolean
    children?: React.ReactNode
}

/**
 * Control whether descendant elements are focusable by keyboard.
 * Keyboard focus can break navigation transitions because the browsert tries to scroll the input into view.
 */
export function Tabbable({ enabled, children }: TabbableProps) {
    return <tabbingEnabledContext.Provider value={enabled}>{children}</tabbingEnabledContext.Provider>
}
