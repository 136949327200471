import type { DrawableNode } from "document/models/CanvasTree"

export interface WithName {
    name: string | null
}

export const nameDefaults: WithName = {
    name: null,
}

const key: keyof WithName = "name"

export function withName(node: DrawableNode): node is DrawableNode & WithName {
    return key in node
}
