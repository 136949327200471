import * as React from "react"
import { isFiniteNumber } from "./utils/isFiniteNumber"
import { evalMath } from "./utils/evalMath"
import { TextInput, TextInputProps } from "./TextInput"
import type { OverrideType } from "./types"
import * as styles from "./NumberInput.styles"
import { frescoSettingsContext } from "./FrescoSettings"

export type NumberInputProps = OverrideType<
    Omit<TextInputProps, "constantChange">,
    {
        /** The value. */
        value: number | string | null | undefined
        /** Called when the value changes, the rawValue comes directly from the inner text input. The reset function can be called to reset the internal value. */
        onChange: (value: number, rawValue: string | undefined, reset: () => void) => void
        /** Track display value changes that have not been commited yet. */
        onDisplayValueChange?: (value: string) => void
        /** Lower limit. */
        min?: number
        /** Upper limit. */
        max?: number
        /** Step size when using arrow keys. */
        step?: number | "nudge"
        /** Value suffix. */
        unit?: string
        /** Set when the input is cleared. Defaults to zero. */
        defaultValue?: number
        /** Allows disabling the control. */
        enabled?: boolean
        ref?: React.RefObject<HTMLInputElement>
    }
>

export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
    (props, forwardedRef: React.Ref<HTMLInputElement>) => {
        const {
            value: externalValue,
            onChange,
            onDisplayValueChange,
            min,
            max,
            step = 1,
            unit,
            defaultValue,
            ...rest
        } = props

        const fallbackRef = React.useRef<HTMLInputElement>(null)
        const ref = forwardedRef || fallbackRef

        const externalDisplayValue =
            typeof externalValue === "string" || isFiniteNumber(externalValue) ? `${externalValue}${unit || ""}` : ""

        const [internalValue, setInternalValue] = React.useState(externalDisplayValue)
        const { smallNudgeIncrement, largeNudgeIncrement } = React.useContext(frescoSettingsContext)

        const smallStepIncrement = step === "nudge" ? smallNudgeIncrement : step
        const largeStepIncrement = step === "nudge" ? largeNudgeIncrement : smallStepIncrement * 10

        React.useLayoutEffect(() => {
            setInternalValue(externalDisplayValue)
        }, [externalDisplayValue])

        const resetHandler = React.useCallback(() => {
            setInternalValue(externalDisplayValue)
        }, [externalDisplayValue])

        const changeHandler = React.useCallback(
            (newValue: string, final: boolean) => {
                if (!final) {
                    setInternalValue(newValue)
                    if (onDisplayValueChange) {
                        onDisplayValueChange(newValue)
                    }
                    return
                } // else

                if (`${newValue}` === externalValue) return
                if (unit && `${newValue}` === `${externalValue}${unit}`) return

                // reset the input value
                if (newValue === "") {
                    if (externalValue === null) return

                    if (isFiniteNumber(defaultValue)) {
                        onChange(defaultValue, newValue, resetHandler)
                    }

                    resetHandler()
                    return
                }

                const newNumberValue = numValue(externalValue, newValue, min, max)

                if (newNumberValue === externalValue && !newValue.includes("%")) {
                    resetHandler()
                    return
                }

                onChange(newNumberValue, newValue, resetHandler)
            },
            [externalValue, unit, min, max, onChange, resetHandler, onDisplayValueChange, defaultValue]
        )

        const keyDownHandler = React.useCallback(
            (event: React.KeyboardEvent) => {
                const inputElement = (ref as React.RefObject<HTMLInputElement>).current
                if (!inputElement) return

                const { keyCode } = event
                const isArrowUp = keyCode === 38
                const isArrowDown = keyCode === 40
                if (!isArrowUp && !isArrowDown) return

                const multiplier = isArrowDown ? -1 : 1

                let increment = event.shiftKey ? largeStepIncrement : smallStepIncrement

                if (isFiniteNumber(increment)) {
                    increment *= multiplier
                }

                const newNumberValue = numValue(externalValue, inputElement.value, min, max, increment)
                onChange(newNumberValue, undefined, resetHandler)
                event.preventDefault()
            },
            [ref, largeStepIncrement, smallStepIncrement, externalValue, min, max, onChange, resetHandler]
        )

        return (
            <TextInput
                ref={ref}
                className={styles.numberInput}
                value={internalValue}
                onChange={changeHandler}
                constantChange
                onKeyDown={keyDownHandler}
                truncate={false}
                {...rest}
            />
        )
    }
)

function numValue(
    currentValue: number | string | null | undefined,
    newValue: string,
    min: number | undefined,
    max: number | undefined,
    increment?: number
): number {
    const parsedValue = parseFloat(newValue)
    const exprValue = evalMath(newValue)

    let result = 0
    if (Number.isFinite(exprValue)) {
        result = exprValue
    } else if (Number.isFinite(parsedValue)) {
        result = parsedValue
    } else if (typeof currentValue === "number" && Number.isFinite(currentValue)) {
        result = currentValue as number
    } else if (typeof currentValue === "string") {
        const convertedNumber = parseFloat(currentValue)
        if (Number.isFinite(convertedNumber)) {
            result = convertedNumber
        }
    }

    if (isFiniteNumber(increment)) {
        result += increment
    }
    if (isFiniteNumber(min)) {
        result = Math.max(min, result)
    }
    if (isFiniteNumber(max)) {
        result = Math.min(max, result)
    }

    // Try to prevent JS rounding -0.2 to -0.19999999... and other shenanigans
    const factor = Math.pow(10, 7) // 32 bit precision
    result = Math.round(result * factor) / factor
    return result
}
