import * as Framer from "framer"
import { BuiltInFramerComponentIdentifier, BuiltInPackageIdentifier } from "./BuiltInFramerComponentIdentifier"
import type { SandboxEntityMap, SandboxPackageInfo } from "./types"
import { entityDefinitionFromInfo } from "./collectEntities"
import { DeviceCodeComponent } from "../../components/Device/DeviceCodeComponent"
import {
    stackComponentName,
    scrollComponentName,
    pageComponentName,
    deviceComponentName,
} from "../../host/componentLoader/defaultComponentDefinitions"

// Components built into the Framer library.
const defaultFramerPackageInfo: SandboxPackageInfo = {
    name: "framer",
    displayName: "framer",
    depth: 1,
    exportsObject: {},
    dependencies: {},
    sourceModules: {},
}

// Components built into the Framer app itself. Not an actual package.
const builtInPackageInfo: SandboxPackageInfo = {
    name: BuiltInPackageIdentifier,
    displayName: "Built-in",
    depth: 1,
    exportsObject: {},
    dependencies: {},
    sourceModules: {},
}

const defaultComponentMap: SandboxEntityMap = {
    [BuiltInFramerComponentIdentifier.Stack]: entityDefinitionFromInfo(
        { name: stackComponentName, children: undefined, type: undefined },
        Framer.Stack,
        {
            packageInfo: defaultFramerPackageInfo,
        }
    ),
    [BuiltInFramerComponentIdentifier.Scroll]: entityDefinitionFromInfo(
        { name: scrollComponentName, children: true, type: undefined },
        Framer.Scroll,
        {
            packageInfo: defaultFramerPackageInfo,
        }
    ),
    [BuiltInFramerComponentIdentifier.Page]: entityDefinitionFromInfo(
        { name: pageComponentName, children: undefined, type: undefined },
        Framer.Page,
        {
            packageInfo: defaultFramerPackageInfo,
        }
    ),
    [BuiltInFramerComponentIdentifier.Device]: entityDefinitionFromInfo(
        { name: deviceComponentName, children: undefined, type: undefined },
        DeviceCodeComponent,
        {
            packageInfo: builtInPackageInfo,
        }
    ),
}

export function addDefaultComponents(entities: SandboxEntityMap) {
    Object.assign(entities, defaultComponentMap)
}
