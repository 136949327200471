import type { CanvasNode } from "document/models/CanvasTree"

export interface WithTarget {
    isTarget: boolean
}

export const targetDefaults: WithTarget = {
    isTarget: false,
}

const key: keyof WithTarget = "isTarget"

export function withTarget(node: CanvasNode): node is CanvasNode & WithTarget {
    return key in node
}
