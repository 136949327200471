export class PreviewHTTPError extends Error {
    url: string
    status: number

    constructor(response: Response) {
        super("HTTPError")

        this.name = "PreviewHTTPError"
        this.url = response.url
        this.status = response.status
    }
}
