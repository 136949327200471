import { nameDefaults } from "../traits/Name"
import { sizeDefaults } from "../traits/Size"
import { aspectRatioDefaults } from "../traits/AspectRatioLock"
import { pinDefaults } from "../traits/Pins"
import { rotationDefaults } from "../traits/Rotation"
import { svgDefaults } from "../traits/SVG"
import { originalFilenameDefaults } from "../traits/OriginalFilename"
import { visibilityDefaults } from "../traits/Visibility"
import { lockDefaults } from "../traits/Lock"
import { intrinsicSizeDefaults } from "../traits/IntrinsicSize"
import { withExportDefaults } from "document/models/CanvasTree/traits/Export"
import { colorableDefaults } from "document/models/CanvasTree/traits/Colorable"
import { opacityDefaults } from "document/models/CanvasTree/traits/Opacity"
import { shadowDefaults } from "document/models/CanvasTree/traits/Shadow"
import { svgFillDefaults } from "document/models/CanvasTree/traits/FillDefaults"
import { filterDefaults } from "document/models/CanvasTree/traits/Filters"
import { blendingDefaults } from "document/models/CanvasTree/traits/Blending"
import { memoRecord } from "./memoRecord"

export const getSVGRecord = memoRecord(() => {
    return {
        ...nameDefaults,
        ...sizeDefaults,
        ...aspectRatioDefaults,
        ...pinDefaults,
        ...svgFillDefaults,
        ...opacityDefaults,
        ...rotationDefaults,
        ...shadowDefaults,
        ...svgDefaults,
        ...originalFilenameDefaults,
        ...visibilityDefaults,
        ...lockDefaults,
        ...intrinsicSizeDefaults,
        ...filterDefaults,
        ...blendingDefaults,
        ...withExportDefaults,
        ...colorableDefaults,
    }
})
