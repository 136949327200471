/**
 * Converts a Unicode string to a string in which each 16-bit unit
 * is split into two 8-bit chars so that the string can be encoded with btoa.
 */
export function utf16asUtf8(utf16String: string): string {
    let result = ""
    let i = 0
    const len = utf16String.length
    while (i < len) {
        // Create 12-byte chunks of strings which is much more
        // efficient than appending to a single long string.
        // Note that every step of the loop reads a UTF-16 code
        // (2 bytes) and appends it as individual UTF-8 codes.
        let chunk = ""
        for (let j = 0; j < 4 && i + j < len; j++) {
            const code = utf16String.charCodeAt(i + j)
            chunk += String.fromCharCode(code & 0xff, code >> 8)
        }
        i += 4
        // Appending a chunk instead of a single character leverages
        // the rope data structure that most JavaScript engines use.
        result += chunk
    }
    return result
}
