export interface WithAspectRatio {
    aspectRatio: number | null
}

export const aspectRatioDefaults: WithAspectRatio = {
    aspectRatio: null,
}

const key: keyof WithAspectRatio = "aspectRatio"

export function withAspectRatio<T extends object>(node: T): node is T & WithAspectRatio {
    return key in node
}
