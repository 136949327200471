import { PostMessageChannel, ServiceManager, ServiceError } from "@framerjs/framer-services"
import * as React from "react"
import { useCallback, useEffect, useReducer, useRef } from "react"
import { getLogger, unhandledError } from "@framerjs/shared"
import { sandboxAppRelativeToEditor, assertAllowSameOriginForSandboxApp } from "../../environment/domains"

const logger = getLogger("services:hooks:useiframewithchannel")

type Props = React.HTMLAttributes<HTMLDivElement>

type Return = [React.ComponentType<Props>, PostMessageChannel | null]

export class IFrameWithChannelError extends Error {
    constructor(message: string, readonly extras: Record<string, unknown> = {}) {
        super(message)
    }
}

/**
 * Returns a component class that wraps an <iframe> element and a channel to communicate with that IFrame.
 * @param src The src of the <iframe> element that will be created.
 * @param targetOrigin Target origin for the postMessage. Defaults to the origin of the <iframe>.
 * @param onSetup Called when the <iframe> is created for the first time. If something
 *                needs to run when the <iframe> is reloaded, add an onload handler.
 * @param sandboxed When true will sandbox the iframe
 */
export function useIFrameWithChannel(config: {
    src: string | null
    targetOrigin?: string
    sandboxed: boolean
    onSetup?: (iframe: HTMLIFrameElement, channel: PostMessageChannel) => ReturnType<React.EffectCallback>
}): Return {
    const { sandboxed: isSandboxed = false, onSetup = () => {}, targetOrigin } = config

    let resolvedURL: string | null = config.src

    if (isSandboxed && config.src) {
        const { pathname, search, hash } = new URL(config.src)
        const relativePath = `${pathname}${search}${hash}`
        assertAllowSameOriginForSandboxApp(relativePath)

        resolvedURL = sandboxAppRelativeToEditor(relativePath).url
    }

    const [channel, setChannel] = useReducer(
        (oldChannel: PostMessageChannel | null, newChannel: PostMessageChannel | null) => {
            if (oldChannel) {
                ServiceManager.shared()
                    .unregister(oldChannel)
                    .catch(err => {
                        // No need to handle cases where service is already torn down.
                        if (!(err instanceof ServiceError.ServiceGone)) {
                            unhandledError(err)
                        }
                    })
            }
            return newChannel
        },
        null
    )

    // This will create an <iframe> synchronously once the container
    // component (`componentType`) has mounted. The reason for this
    // is so that no code can run between the <iframe> being created
    // and the `setUpIFrame` callback running.
    const ref = useRef<HTMLDivElement>(null)
    const iframeRef = useRef<HTMLIFrameElement | null>(null)
    useEffect(() => {
        if (!resolvedURL || !ref.current) {
            // The <div> is not in the DOM.
            iframeRef.current = null
            if (channel) setChannel(null)
            return
        }

        if (iframeRef.current) {
            // We already created an <iframe>.
            return
        }

        const iframe = document.createElement("iframe")
        iframe.src = resolvedURL
        iframe.style.border = "none"
        iframe.style.display = "block"
        iframe.style.height = "100%"
        iframe.style.width = "100%"

        // TODO: It's very unclear under what conditions this event is called
        // so we will likely also need to do some form of timeout. For example
        // a 404 will result in a load event, but a blocked network request
        // e.g. from an ad blocker will result in "load" never being called
        // by which time we'll likely have crashed somewhere else after
        // onSetup() is called with an invalid iframe/contentWindow.
        iframe.addEventListener("error", evt => {
            setChannel(null)
            logger.reportError(
                new IFrameWithChannelError("iframe load error", {
                    message: evt.message,
                    filename: evt.filename,
                    lineno: evt.lineno,
                    colno: evt.colno,
                    error: evt.error,
                })
            )
        })

        // Setup sandboxing if required.
        const iframeOrigin = new URL(resolvedURL, document.baseURI).origin
        const sandbox = iframe.sandbox as undefined | HTMLIFrameElement["sandbox"]
        if (isSandboxed) {
            sandbox?.add("allow-downloads")
            sandbox?.add("allow-popups")
            sandbox?.add("allow-same-origin")
            sandbox?.add("allow-scripts")

            iframe.allow = iframeFeaturePolicy()
        }

        ref.current.appendChild(iframe)

        const { contentWindow } = iframe
        if (!contentWindow) {
            // Something is wrong...
            ref.current.removeChild(iframe)
            if (channel) setChannel(null)
            return
        }
        iframeRef.current = iframe

        // Allow wildcard origin in situations where the origin is not
        // protected. This should only apply to the development env.
        const newChannel = new PostMessageChannel(contentWindow, targetOrigin ?? iframeOrigin)

        // When sandboxed we need the channel to be loaded before exposing it
        // otherwise we end up with "Failed to execute 'postMessage' on 'DOMWindow'" errors.
        if (isSandboxed) {
            iframe.addEventListener("load", () => setChannel(newChannel))
        } else {
            setChannel(newChannel)
        }

        // Allow setting up the <iframe> before it gets a chance to start
        // executing JavaScript (so we can inject globals, et cetera).
        return onSetup(iframe, newChannel)
    }, [channel, setChannel, onSetup, isSandboxed, resolvedURL, targetOrigin])

    // Clean-up iframe on unmount.
    useEffect(
        () => () => {
            iframeRef.current = null
            setChannel(null)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const componentType = useCallback(function IFrameWithChannel(props: Props) {
        useEffect(() => {
            // Throw an error if the component is re-mounted as this will cause the
            // iframe and internal channel to reload. Use an ErrorBoundary to handle
            // this error if desired.
            if (ref.current?.children.length === 0 && iframeRef.current) {
                throw new Error("IFrameWithChannel was re-rendered. The Service connection will be broken.")
            }
        })
        return <div {...props} ref={ref} />
    }, [])

    return [componentType, channel]
}

export function iframeFeaturePolicy(): string {
    // Collected from: document.featurePolicy.allowedFeatures() and https://flaviocopes.com/html-iframe-tag/#allow
    // See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Feature-Policy
    const features = [
        "accelerometer",
        "ambient-light-sensor",
        "autoplay",
        "camera",
        "display-capture",
        "encrypted-media",
        "fullscreen",
        "geolocation",
        "gyroscope",
        "magnetometer",
        "microphone",
        "midi",
        "picture-in-picture",
        "speaker",
        "usb",
        "vibrate",
        "vr",
        "xr-spatial-tracking",
    ]

    return features.join("; ")
}
