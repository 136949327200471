import type { Size, Rect } from "framer"
import { ConstraintMask, ConstraintValues, ConstraintProperties, DimensionType } from "framer"

import { CanvasNode } from "document/models/CanvasTree/nodes/CanvasNode"
import type { WithPins } from "../Pins"
import type { WithSize } from "../Size"
import type { WithAspectRatio } from "../AspectRatioLock"
import { withRotation } from "../Rotation"
import type { WithFractionOfFreeSpace } from "framer"
import type { WithSizeFunctions } from "../sizeFunctions"
import type { NewConstraintProperties } from "framer"
import { isMaster, isReplica } from "../Template"
import { aspectRatioForNode } from "../utils/layoutSupportsAspectRatio"
import { isNumber } from "utils/typeChecks"
import { withDOMLayout } from "document/models/CanvasTree/traits/DOMLayout"

function nullToUndefined<T>(value: T | null): T | undefined {
    if (value === null) {
        return undefined
    }
    return value
}

interface WithConstraintMask {
    constraints(): ConstraintMask
}

export function newConstraintProperties(
    node: CanvasNode & WithPins & WithSize & WithConstraintMask
): NewConstraintProperties {
    if (node.cache.newConstraintProps) return node.cache.newConstraintProps
    const constraints = node.constraints()
    const { left, right, top, bottom, centerAnchorX, centerAnchorY, width, height } = node
    const constraintProps: NewConstraintProperties = {
        _constraints: { enabled: true },
    }
    constraintProps.width = sizeValue(width, constraints.widthType)
    constraintProps.height = sizeValue(height, constraints.heightType)
    constraintProps.widthType = constraints.widthType
    constraintProps.heightType = constraints.heightType
    if (node.cache.parentDirected) {
        return (node.cache.newConstraintProps = constraintProps)
    }
    const updateSizeToCalculatedValues = isNumber(constraints.aspectRatio) || isMaster(node) || isReplica(node)
    if (updateSizeToCalculatedValues) {
        if (withDOMLayout(node) && node.usesDOMRectCached()) {
            // Nodes using DOM layout can't pre-calculate the
            // aspect-ratio-constrained size, so it needs to be passed in as
            // part of the constraints
            constraintProps._constraints.aspectRatio = constraints.aspectRatio
        } else {
            // We don't pass the aspect ratio because that results in the rect being recalculated
            // during rendering, while it is already pixelaligned by node.rect()
            // constraintProps._constraints.aspectRatio = constraints.aspectRatio

            const parentSize = node.cache.parentSize
            // This dependency on node.rect is scary, because it might feel logical
            // to calculate the rect using this function (newConstraintProperties),
            // but that would create an infinite loop.
            // Currently all node.rect's are using the old constraints to calculate the rect
            const rect = node.rect(parentSize)
            constraintProps._constraints.intrinsicWidth = rect.width
            constraintProps._constraints.intrinsicHeight = rect.height
            // XXX do we still need to set them on the constraintProps?
            if (constraints.widthType === DimensionType.FixedNumber) {
                constraintProps.width = rect.width
            }
            if (constraints.heightType === DimensionType.FixedNumber) {
                constraintProps.height = rect.height
            }
        }
    }
    constraintProps.left = constraints.left ? nullToUndefined(left) : undefined
    constraintProps.right = constraints.right ? nullToUndefined(right) : undefined
    constraintProps.top = constraints.top ? nullToUndefined(top) : undefined
    constraintProps.bottom = constraints.bottom ? nullToUndefined(bottom) : undefined
    // Width takes precedence CSS, so when left and right are set, we have to set width to undefined
    if (constraints.left && constraints.right) {
        constraintProps.width = undefined
    }
    // Height takes precedence CSS, so when top and bottom are set, we have to set height to undefined
    if (constraints.top && constraints.bottom) {
        constraintProps.height = undefined
    }

    if (!constraints.left && !constraints.right) {
        constraintProps.center = "x"
        constraintProps.left = `${centerAnchorX * 100}%`
    }
    if (!constraints.top && !constraints.bottom) {
        constraintProps.center = "y"
        constraintProps.top = `${centerAnchorY * 100}%`
    }
    if (!constraints.left && !constraints.right && !constraints.top && !constraints.bottom) {
        constraintProps.center = true
    }

    return (node.cache.newConstraintProps = constraintProps)
}

export function withPinsSizeRatioConstraints<T extends new (...args: any[]) => CanvasNode>(_Base: T) {
    return class extends CanvasNode implements WithPins, WithSize, WithAspectRatio, WithSizeFunctions {
        // WithPins
        constraintsLocked: boolean
        left: number | null
        right: number | null
        top: number | null
        bottom: number | null
        centerAnchorX: number
        centerAnchorY: number
        widthType: DimensionType
        heightType: DimensionType

        // WithSize
        width: number
        height: number

        // WithAspectRatioLock
        aspectRatio: number | null

        constraintProperties(): ConstraintProperties {
            if (this.cache.constraintProps) return this.cache.constraintProps
            const constraints = this.constraints()
            return (this.cache.constraintProps = {
                left: constraints.left ? this.left : null,
                right: constraints.right ? this.right : null,
                top: constraints.top ? this.top : null,
                bottom: constraints.bottom ? this.bottom : null,
                width: sizeValue(this.width, constraints.widthType),
                height: sizeValue(this.height, constraints.heightType),
                aspectRatio: constraints.aspectRatio,
                centerX: `${this.centerAnchorX * 100}%`,
                centerY: `${this.centerAnchorY * 100}%`,
                parentSize: null,
            })
        }

        newConstraintProperties(): NewConstraintProperties {
            return newConstraintProperties(this)
        }

        constraints(): ConstraintMask {
            const aspectRatio = aspectRatioForNode(this)

            if (this.cache.parentDirected) {
                return ConstraintMask.quickfix({
                    left: false,
                    right: false,
                    top: false,
                    bottom: false,
                    widthType: this.widthType,
                    heightType: this.heightType,
                    aspectRatio,
                    fixedSize: false,
                })
            }

            return ConstraintMask.quickfix({
                left: isNumber(this.left),
                right: isNumber(this.right),
                top: isNumber(this.top),
                bottom: isNumber(this.bottom),
                widthType: this.widthType,
                heightType: this.heightType,
                aspectRatio,
                fixedSize: false,
            })
        }

        constraintValues(): ConstraintValues {
            const constraints = this.constraints()
            return {
                left: constraints.left ? this.left : null,
                right: constraints.right ? this.right : null,
                top: constraints.top ? this.top : null,
                bottom: constraints.bottom ? this.bottom : null,
                widthType: this.widthType,
                heightType: this.heightType,
                width: this.width,
                height: this.height,
                aspectRatio: constraints.aspectRatio,
                centerAnchorX: this.centerAnchorX,
                centerAnchorY: this.centerAnchorY,
            }
        }

        minSize(parentSize: Size | null): Size {
            return ConstraintValues.toMinSize(this.constraintValues(), parentSize)
        }

        size(parentSize: Size | null, freeSpace: WithFractionOfFreeSpace | null, allowCache = true): Size {
            const { parentDirectedRect } = this.cache
            if (parentDirectedRect && allowCache) {
                return { width: parentDirectedRect.width, height: parentDirectedRect.height }
            }
            return ConstraintValues.toSize(this.constraintValues(), parentSize, null, freeSpace)
        }

        rect(parentSize: Size | null, pixelAlign = true): Rect {
            if (this.cache.parentDirectedRect) {
                return { ...this.cache.parentDirectedRect }
            }
            return ConstraintValues.toRect(this.constraintValues(), parentSize, null, pixelAlign)
        }

        matrix(parentSize?: Size | null, frame?: Rect): DOMMatrix {
            if (!frame && parentSize !== undefined) {
                frame = this.rect(parentSize)
            }
            if (!frame) {
                throw Error("Could not determine frame to build matrix from")
            }
            return new DOMMatrix()
                .translateSelf(frame.x, frame.y)
                .rotateSelf(0, 0, withRotation(this) ? this.resolveValue("rotation") ?? 0 : 0)
        }

        transformMatrix(parentSize: Size | null, frame?: Rect): DOMMatrix {
            if (!frame) {
                frame = this.rect(parentSize)
            }
            if (!frame) {
                throw Error("Could not determine frame to build matrix from")
            }
            return new DOMMatrix()
                .translateSelf(0.5 * frame.width, 0.5 * frame.height)
                .multiplySelf(this.matrix(parentSize, frame))
                .translateSelf(-0.5 * frame.width, -0.5 * frame.height)
        }

        isRotated(): boolean {
            if (withRotation(this)) {
                return (this.resolveValue("rotation") ?? 0) % 360 !== 0
            }
            return false
        }
    }
}

export function sizeValue(value: number, dimension: DimensionType) {
    switch (dimension) {
        case DimensionType.FixedNumber:
            return value
        case DimensionType.Percentage:
            return `${value * 100}%`
        case DimensionType.FractionOfFreeSpace:
            return `${value}fr`
        case DimensionType.Auto:
            return "auto"
    }
}
