import { css } from "linaria"
import { ThemeOverrideMode, themeTintOverride } from "../tokens/utils/overrides"
import { createCSSVariableValues } from "../tokens/utils/variables"
import { popoverColorTokensDark, dashboardColorTokensDark, prototypeViewColorTokensDark } from "./Theme.styles"

type ThemeOverride = Record<ThemeOverrideMode, string>

const graphicOverrides = {
    light: themeTintOverride("light", {
        tint: "graphicTint",
        tintDark: "graphicTint",
        tintDimmed: "graphicTintDimmed",
        layerItemIconDimmed: "layerItemIconGraphicDimmed",
    }),
    dark: themeTintOverride("dark", {
        tint: "graphicTint",
        tintDark: "graphicTint",
        tintDimmed: "graphicTintDimmed",
        layerItemIconDimmed: "layerItemIconGraphicDimmed",
    }),
}

const componentOverrides = {
    light: themeTintOverride("light", {
        tint: "componentTint",
        tintDark: "componentTint",
        tintDimmed: "componentTintDimmed",
        buttonBackgroundPrimaryActive: "componentTintDark",
        layerItemIconDimmed: "layerItemIconComponentDimmed",
        animationForgroundLight: "componentTintLight",
        animationForgroundDimmed: "componentTintDimmed",
    }),
    dark: themeTintOverride("dark", {
        tint: "componentTint",
        tintDark: "componentTint",
        tintDimmed: "componentTintDimmed",
        buttonBackgroundPrimaryActive: "componentTintDark",
        layerItemIconDimmed: "layerItemIconComponentDimmed",
        animationForgroundLight: "componentTintLight",
        animationForgroundDimmed: "componentTintDimmed",
    }),
}

export const themeOverrides: ThemeOverride = {
    graphics: css`
        body:not(.dark) & {
            ${graphicOverrides.light}
        }

        body.dark & {
            ${graphicOverrides.dark}
        }
    `,
    component: css`
        body:not(.dark) & {
            ${componentOverrides.light}
        }

        body.dark & {
            ${componentOverrides.dark}
        }
    `,
    popover: css`
        body.dark & {
            ${createCSSVariableValues([popoverColorTokensDark])}
        }
    `,
    dashboard: css`
        body.dark & {
            ${createCSSVariableValues([dashboardColorTokensDark])}
        }
    `,
    ["prototype-view"]: css`
        body.dark & {
            ${createCSSVariableValues([prototypeViewColorTokensDark])}
        }
    `,
}
