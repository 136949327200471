import { List } from "immutable"
import Bezier from "bezier-js"

import type { DrawableNode } from "document/models/CanvasTree"
import { Rect, Point } from "framer"
import { WithPaths, WithPath, isStraightCurve, PathSegment } from "framer"
import { reverseSegment } from "document/models/CanvasTree/utils/shapes"
import { commonPropertyValue } from "utils/commonValue"
import type { CanvasNodeCache } from "./CanvasTree/nodes/CanvasNodeCache"

namespace Path {
    export function toBezierJS(withPaths: WithPaths | WithPath, node?: DrawableNode) {
        let cache: CanvasNodeCache | null = null
        if (node && !node.mutable) {
            cache = node.cache as CanvasNodeCache
            if (cache.bezier) return cache.bezier
        }

        const bezierCurves: any[] = []

        let paths = []
        if (Array.isArray(withPaths)) {
            paths = withPaths
        } else {
            paths = [withPaths]
        }

        paths.forEach(({ pathSegments, pathClosed }) => {
            for (let i = 0, il = pathSegments.count(); i < il; i++) {
                const currentPoint = pathSegments.get(i)
                let nextPoint: PathSegment | undefined

                const isLastPoint = i === il - 1
                if (isLastPoint) {
                    if (pathClosed && il > 1) {
                        nextPoint = pathSegments.get(0)
                    }
                } else {
                    nextPoint = pathSegments.get(i + 1)
                }

                if (nextPoint) {
                    const curvePoints = [
                        PathSegment.point(currentPoint),
                        PathSegment.calculatedHandleOut(currentPoint),
                        PathSegment.calculatedHandleIn(nextPoint),
                        PathSegment.point(nextPoint),
                    ]
                    const bezier = new Bezier(curvePoints)
                    bezierCurves.push(bezier)
                }
            }
        })

        if (cache && !(node && node.mutable)) cache.bezier = bezierCurves
        return bezierCurves
    }

    export function isClosed(withPaths: WithPaths | WithPath): boolean {
        if (Array.isArray(withPaths)) {
            return commonPropertyValue(withPaths, "pathClosed") === true
        } else {
            return withPaths.pathClosed
        }
    }

    export function boundingBox(withPaths: WithPaths | WithPath): Rect {
        const bezierJS = toBezierJS(withPaths)

        const xs: number[] = []
        const ys: number[] = []

        for (let i = 0, il = bezierJS.length; i < il; i++) {
            const bezierCurve = bezierJS[i]
            const bezierBoundingBox = bezierCurve.bbox()
            xs.push(bezierBoundingBox.x.min, bezierBoundingBox.x.max)
            ys.push(bezierBoundingBox.y.min, bezierBoundingBox.y.max)
        }

        if (xs.length === 0) {
            let x = 0
            let y = 0

            // if we have just one open segment, we can still extract the x/y
            // information from it
            const paths = Array.isArray(withPaths) ? withPaths : [withPaths]
            const segment = paths[0] ? paths[0].pathSegments.get(0) : null

            if (segment) {
                x = segment.x
                y = segment.y
            }

            return { x, y, width: 1, height: 1 }
        }

        const minX = Math.min(...xs)
        const minY = Math.min(...ys)
        const maxX = Math.max(...xs)
        const maxY = Math.max(...ys)

        return {
            x: minX,
            y: minY,
            width: Math.max(maxX - minX, 1),
            height: Math.max(maxY - minY, 1),
        }
    }

    export function anchorBoundingBox(pathSegments: List<PathSegment>) {
        const xs: number[] = []
        const ys: number[] = []
        for (let i = 0, il = pathSegments.count(); i < il; i++) {
            const segment = pathSegments.get(i)
            xs.push(segment.x)
            ys.push(segment.y)
        }
        const minX = Math.min(...xs)
        const maxX = Math.max(...xs)
        const minY = Math.min(...ys)
        const maxY = Math.max(...ys)
        return {
            x: minX,
            y: minY,
            width: maxX - minX,
            height: maxY - minY,
        }
    }

    export function offset(withPath: WithPath, delta: Point) {
        return {
            pathSegments: withPath.pathSegments.map((segment: PathSegment) => {
                return segment.merge(Point.add(segment, delta))
            }) as List<PathSegment>,
            pathClosed: withPath.pathClosed,
        }
    }

    export function split(
        pathSegments: List<PathSegment>,
        pathClosed: boolean,
        segmentIndex: number,
        t: number
    ): List<PathSegment> {
        const bezierCurve = toBezierJS([{ pathSegments, pathClosed }])[segmentIndex]
        const splitPointResult = bezierCurve.split(t)
        let nextPointIndex = segmentIndex + 1
        if (segmentIndex === pathSegments.count() - 1) {
            nextPointIndex = 0
        }

        const fromSegment = pathSegments.get(segmentIndex)
        const toSegment = pathSegments.get(nextPointIndex)
        const straight = isStraightCurve(fromSegment, toSegment)

        const leftPointCtrlPoint = splitPointResult.left.points[1] as Point
        let newPoints = pathSegments.update(segmentIndex, currentFromPoint => {
            if (straight) {
                return currentFromPoint
            }
            return new PathSegment(
                currentFromPoint.merge({
                    handleOutX: leftPointCtrlPoint.x - currentFromPoint.x,
                    handleOutY: leftPointCtrlPoint.y - currentFromPoint.y,
                    handleMirroring: "asymmetric",
                })
            )
        })

        const rightPointCtrlPoint = splitPointResult.right.points[2] as Point
        newPoints = newPoints.update(nextPointIndex, currentToPoint => {
            if (straight) {
                return currentToPoint
            }
            return new PathSegment(
                currentToPoint.merge({
                    handleInX: rightPointCtrlPoint.x - currentToPoint.x,
                    handleInY: rightPointCtrlPoint.y - currentToPoint.y,
                    handleMirroring: "asymmetric",
                })
            )
        })

        const splitPointLocation = splitPointResult.left.points[3] as Point
        const splitControlPoint1 = splitPointResult.left.points[2] as Point
        const splitControlPoint2 = splitPointResult.right.points[1] as Point
        const vecSplitPoint = new PathSegment({
            x: splitPointLocation.x,
            y: splitPointLocation.y,
            handleInX: straight ? 0 : splitControlPoint1.x - splitPointLocation.x,
            handleInY: straight ? 0 : splitControlPoint1.y - splitPointLocation.y,
            handleOutX: straight ? 0 : splitControlPoint2.x - splitPointLocation.x,
            handleOutY: straight ? 0 : splitControlPoint2.y - splitPointLocation.y,
            handleMirroring: straight ? "straight" : "asymmetric",
        })

        return newPoints.insert(segmentIndex + 1, vecSplitPoint)
    }

    export function isFirstSegment(pathSegments: List<PathSegment>, closed: boolean, segmentIndex: number) {
        if (closed) return false
        return segmentIndex === 0
    }

    export function isLastSegment(pathSegments: List<PathSegment>, closed: boolean, segmentIndex: number) {
        if (closed) return false
        return segmentIndex === pathSegments.count() - 1
    }

    export function isEndSegment(pathSegments: List<PathSegment>, closed: boolean, segmentIndex: number) {
        return isFirstSegment(pathSegments, closed, segmentIndex) || isLastSegment(pathSegments, closed, segmentIndex)
    }

    export function reverse(pathSegments: List<PathSegment>) {
        return pathSegments.reverse().map((segment: PathSegment) => {
            return reverseSegment(segment)
        }) as List<PathSegment>
    }

    export function canClose(
        pathSegments: List<PathSegment>,
        closed: boolean,
        selectedSegment: number,
        hoveringSegment: number
    ) {
        if (pathSegments.count() === 1) {
            return false
        }
        const selectedIsFirst = isFirstSegment(pathSegments, closed, selectedSegment)
        const selectedIsLast = isLastSegment(pathSegments, closed, selectedSegment)
        const hoveringIsFirst = isFirstSegment(pathSegments, closed, hoveringSegment)
        const hoveringIsLast = isLastSegment(pathSegments, closed, hoveringSegment)
        return (selectedIsFirst && hoveringIsLast) || (selectedIsLast && hoveringIsFirst)
    }

    export function segmentIndexesToDelete(
        pathSegments: List<PathSegment>,
        closed: boolean,
        curveIndexesToRemove: number[]
    ) {
        const segmentCount = pathSegments.count()
        const pathClosedCurveRemoved = !closed || curveIndexesToRemove.includes(segmentCount - 1)
        return pathSegments
            .map((map: PathSegment, index: number) => {
                const isFirst = index === 0
                const isLast = index === segmentCount - 1
                const nextCurveRemoved = curveIndexesToRemove.includes(index)
                const prevCurveRemoved = curveIndexesToRemove.includes(index - 1)

                if (isFirst) {
                    return nextCurveRemoved && pathClosedCurveRemoved
                } else if (isLast) {
                    return prevCurveRemoved && pathClosedCurveRemoved
                } else {
                    return nextCurveRemoved && prevCurveRemoved
                }
            })
            .toArray()
    }

    export function radiusEnabledSegments(pathSegments: List<PathSegment>, closed: boolean) {
        const result: boolean[] = []
        for (let i = 0, il = pathSegments.count(); i < il; i++) {
            if (il < 3) {
                result.push(false)
                continue
            }
            const isFirst = i === 0
            const isLast = i === il - 1
            if (isFirst && !closed) {
                result.push(false)
                continue
            }
            if (isLast && !closed) {
                result.push(false)
                continue
            }
            const prevSegment = isFirst ? pathSegments.last() : pathSegments.get(i - 1)
            const nextSegment = isLast ? pathSegments.first() : pathSegments.get(i + 1)
            const segment = pathSegments.get(i)
            const enabled = isStraightCurve(prevSegment, segment) && isStraightCurve(segment, nextSegment)
            result.push(enabled)
        }
        return result
    }

    export function reduceRadius(
        pathSegments: List<PathSegment>,
        closed: boolean,
        result: { radius?: number | null },
        selectedIndexes?: number[]
    ) {
        if (result.radius === null) {
            return
        }
        const enabledIndexes = radiusEnabledSegments(pathSegments, closed)

        for (let i = 0, il = pathSegments.count(); i < il; i++) {
            const enabled = enabledIndexes[i]
            if (!enabled) {
                continue
            }
            if (selectedIndexes && !selectedIndexes.includes(i)) {
                continue
            }
            const segment = pathSegments.get(i)
            if (result.radius === undefined) {
                result.radius = segment.radius
            } else if (result.radius !== segment.radius) {
                result.radius = null
                return
            }
        }
    }

    export function applyRadius(pathSegments: List<PathSegment>, pathClosed: boolean): WithPath {
        const enabledIndexes = radiusEnabledSegments(pathSegments, pathClosed)
        const pathSegmentResult: PathSegment[] = []
        for (let i = 0, il = pathSegments.count(); i < il; i++) {
            const enabled = enabledIndexes[i]
            const segment = pathSegments.get(i)
            if (!enabled || segment.radius === 0) {
                pathSegmentResult.push(segment)
                continue
            }
            const isFirst = i === 0
            const isLast = i === il - 1
            const prevSegment = isFirst ? pathSegments.last() : pathSegments.get(i - 1)
            const nextSegment = isLast ? pathSegments.first() : pathSegments.get(i + 1)

            let prevRatio = 1
            let nextRatio = 1

            const prevSegmentRadiusEnabled =
                (isFirst ? enabledIndexes[il - 1] : enabledIndexes[i - 1]) && prevSegment.radius !== 0
            const nextSegmentRadiusEnabled = isLast
                ? enabledIndexes[0]
                : enabledIndexes[i + 1] && nextSegment.radius !== 0

            if (prevSegmentRadiusEnabled) {
                prevRatio = segment.radius / nonZero(prevSegment.radius + segment.radius)
            }
            if (nextSegmentRadiusEnabled) {
                nextRatio = segment.radius / nonZero(nextSegment.radius + segment.radius)
            }

            const prevMaxDistance = Point.distance(prevSegment, segment) * prevRatio
            const nextMaxDistance = Point.distance(segment, nextSegment) * nextRatio
            const maxDistance = Math.min(prevMaxDistance, nextMaxDistance)

            if (maxDistance === 0) {
                pathSegmentResult.push(segment)
                continue
            }

            const angle = curveAngle(segment, prevSegment, nextSegment)
            const maxR = maxRadius(angle, maxDistance)
            const radius = Math.min(segment.radius, maxR)

            const distance = radius / nonZero(Math.tan(angle / 2))

            const from = movePoint(segment, prevSegment, distance)
            const to = movePoint(segment, nextSegment, distance)

            const angleInDegrees = (angle * 180) / Math.PI
            const circleAngle = 180 - angleInDegrees
            const n = (2 * 360) / nonZero(circleAngle)

            const handleDistance = (4 / 3) * Math.tan(Math.PI / n)

            const fromHandle = movePoint(from, segment, handleDistance * radius)
            const toHandle = movePoint(to, segment, handleDistance * radius)

            const fromRel = Point.subtract(fromHandle, from)
            const toRel = Point.subtract(toHandle, to)

            const fromSegment = new PathSegment({
                ...from,
                handleMirroring: "disconnected",
                handleOutX: fromRel.x,
                handleOutY: fromRel.y,
            })
            const toSegment = new PathSegment({
                ...to,
                handleMirroring: "disconnected",
                handleInX: toRel.x,
                handleInY: toRel.y,
            })

            pathSegmentResult.push(fromSegment, toSegment)
        }
        return { pathSegments: List(pathSegmentResult), pathClosed }
    }
}

export default Path

function curveAngle(intersection: Point, a: Point, b: Point) {
    const angle =
        Math.atan2(b.y - intersection.y, b.x - intersection.x) - Math.atan2(a.y - intersection.y, a.x - intersection.x)
    const angle2 = angle >= 0 ? angle : 2 * Math.PI + angle
    return angle2 < Math.PI ? angle2 : 2 * Math.PI - angle2
}

function maxRadius(angle: number, maxDistance: number) {
    return maxDistance * Math.tan(angle / 2)
}

function movePoint(from: Point, to: Point, distance: number) {
    const toEndDistance = Point.distance(from, to)
    if (distance === 0 || toEndDistance === 0) {
        return from
    }
    const deltaX = to.x - from.x
    const deltaY = to.y - from.y
    const times = distance / toEndDistance
    return { x: from.x + times * deltaX, y: from.y + times * deltaY }
}

/** To prevent division by zero */
function nonZero(value: number) {
    return value === 0 ? 1 : value
}
