import type { StyledText } from "document/models/StyledText/StyledText"
import { StyledTextDraft } from "document/models/StyledText/StyledTextDraft"

export interface WithStyledText {
    styledText: StyledText<any>
}

export const styledTextDefaults: WithStyledText = {
    styledText: new StyledTextDraft() as StyledText<any>,
}

const key: keyof WithStyledText = "styledText"

export function withStyledText(target: any): target is WithStyledText {
    return key in target
}
