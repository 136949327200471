export interface Tree {
    version: 55
    root: TreeNode
}

export type TreeNode =
    | RootNode
    | FrameNode
    | CodeComponentNode
    | SVGNode
    | TextNode
    | ShapeContainerNode
    | ShapeGroupNode
    | BooleanShapeNode
    | PathNode
    | RectangleShapeNode
    | OvalShapeNode
    | PolygonShapeNode
    | StarShapeNode

export type NodeClass =
    | "RootNode"
    | "FrameNode"
    | "TextNode"
    | "CodeComponentNode"
    | "ShapeContainerNode"
    | "ShapeGroupNode"
    | "SVGNode"
    | "BooleanShapeNode"
    | "OvalShapeNode"
    | "PathNode"
    | "PolygonShapeNode"
    | "RectangleShapeNode"
    | "StarShapeNode"

export enum PropertyEnabled {
    enabled = 1,
    disabledHidden = 0,
    disabledVisible = -1,
}

export function isPropertyEnabled(value: unknown): value is PropertyEnabled {
    switch (value) {
        case PropertyEnabled.enabled:
        case PropertyEnabled.disabledHidden:
        case PropertyEnabled.disabledVisible:
            return true
        default:
            return false
    }
}

export interface Shadow {
    id?: string
    enabled: boolean
    color: string
    x: number
    y: number
    blur: number
}

export interface BoxShadow {
    id?: string
    enabled: boolean
    inset: boolean
    color: string
    x: number
    y: number
    blur: number
    spread: number
}

export interface WithShadow {
    shadows?: Shadow[]
}

export interface WithBoxShadow {
    boxShadows?: BoxShadow[]
}

export interface WithBlur {
    blurEnabled?: PropertyEnabled
    blur?: number
    blurType?: "layer" | "background"
}

export interface WithFilters {
    brightnessEnabled?: PropertyEnabled
    brightness?: number
    contrastEnabled?: PropertyEnabled
    contrast?: number
    grayScaleEnabled?: PropertyEnabled
    grayscale?: number
    hueRotateEnabled?: PropertyEnabled
    hueRotate?: number
    invertEnabled?: PropertyEnabled
    invert?: number
    saturateEnabled?: PropertyEnabled
    saturate?: number
    sepiaEnabled?: PropertyEnabled
    sepia?: number
}

export type BlendingMode =
    | "normal"
    | "multiply"
    | "screen"
    | "overlay"
    | "darken"
    | "lighten"
    | "color-dodge"
    | "color-burn"
    | "hard-light"
    | "soft-light"
    | "difference"
    | "exclusion"
    | "hue"
    | "saturation"
    | "color"
    | "luminosity"

export interface WithBlending {
    blendingEnabled: PropertyEnabled
    blendingMode?: BlendingMode
}

export interface WithBorder {
    borderEnabled?: PropertyEnabled
    borderWidth?: number
    borderColor?: string
    borderStyle?: "solid" | "dashed" | "dotted" | "double"
}

export interface WithStroke {
    strokeEnabled: PropertyEnabled | undefined
}

export type ControlType =
    | "boolean"
    | "number"
    | "string"
    | "fusednumber"
    | "enum"
    | "segmentedenum"
    | "color"
    | "image"
    | "file"
    | "componentinstance"
    | "array"
    | "eventhandler"
    | "transition"

export interface ArrayValue {
    type: ControlType
    value: unknown
    enabled: boolean
}

export interface RootNode extends BaseNode {
    __class: "RootNode"
    homeNodeId?: string | null
    guidesX?: number[]
    guidesY?: number[]
}

export interface FrameNode extends BaseNode, WithBorder, WithBoxShadow, WithBlur, WithFilters, WithBlending {
    __class: "FrameNode"
    deviceType: unknown
    isMaster?: boolean
    isExternalMaster?: string | null
    originalid?: string
    replicaInfo?: ReplicaInfo | null
    navigationTarget: string | null
    navigationTransition: string
    navigationTransitionDirection: string
    navigationTransitionBackdropColor: string
}

export interface TextNode extends BaseNode, WithShadow, WithBlur, WithFilters {
    __class: "TextNode"
    styledText: unknown
}

export interface CodeComponentNode extends BaseNode, WithBorder, WithBoxShadow, WithBlur, WithFilters {
    __class: "CodeComponentNode"
    codeComponentIdentifier: string
    codeComponentProps: { [propName: string]: unknown }
    codeComponentPackageVersion: string | null | undefined
    replicaInfo: unknown
    originalid: unknown
    deviceType: unknown
}

export interface ShapeContainerNode extends BaseNode {
    __class: "ShapeContainerNode"
    deviceType: unknown
    navigationTarget: string | null
    navigationTransition: string
    navigationTransitionDirection: string
    navigationTransitionBackdropColor: string
}

export interface ShapeGroupNode extends BaseNode {
    __class: "ShapeGroupNode"
}

export interface SVGNode extends BaseNode, WithShadow, WithBlur, WithFilters, WithBlending {
    __class: "SVGNode"
}

export interface BooleanShapeNode extends BaseNode, WithStroke, WithBoxShadow, WithStroke, WithBoxShadow {
    __class: "BooleanShapeNode"
}

export interface OvalShapeNode extends BaseNode, WithStroke, WithBoxShadow, WithStroke, WithBoxShadow {
    __class: "OvalShapeNode"
}

export interface PathNode extends BaseNode, WithStroke, WithBoxShadow, WithStroke, WithBoxShadow {
    __class: "PathNode"
}

export interface PolygonShapeNode extends BaseNode, WithStroke, WithBoxShadow, WithStroke, WithBoxShadow {
    __class: "PolygonShapeNode"
}

export interface RectangleShapeNode extends BaseNode, WithStroke, WithBoxShadow, WithStroke, WithBoxShadow {
    __class: "RectangleShapeNode"
}

export interface StarShapeNode extends BaseNode, WithStroke, WithBoxShadow, WithStroke, WithBoxShadow {
    __class: "StarShapeNode"
}

export interface BaseNode {
    readonly __class: string
    readonly id: string
    readonly name?: string | null
    readonly parentid?: string | null
    readonly children?: BaseNode[] | null
    readonly isExternalMaster?: string | null
}

interface ReplicaNodeOverrides {
    [property: string]: unknown
    _deleted?: string[]
}

interface ReplicaOverrides {
    [nodeId: string]: ReplicaNodeOverrides
}

export interface ReplicaInfo {
    master: string
    overrides: ReplicaOverrides
}
