import { USE_FREEZE } from "document/models/CanvasTree/nodes/MutableNode"

/** A namespace for extra functions on readonly arrays. These functions take a readonly array, but return a
 * regular array. Once the result is assigned to a readonly variable, these arrays should not be mutated
 * further. These function can be used the same as buildin Array methods like `concat`, `slice` or `map` and
 * `filter`. */
export namespace List {
    /** Appends one or many new elements to the end of the list. Always returns a copy. */
    export function push<T>(ls: readonly T[], ...elements: T[]) {
        return ls.concat(elements)
    }

    /** Appends one or many new elements to the beginning of the list. Always returns a copy. */
    export function unshift<T>(ls: readonly T[], ...elements: T[]) {
        return elements.concat(ls)
    }

    /** Insert a new element into the array. Always returns a copy. */
    export function insert<T>(a: readonly T[], index: number, replacement: T): T[] {
        const length = a.length
        if (index < 0 || index > length) throw Error("index out of range: " + index)

        const copy = a.slice()
        copy.splice(index, 0, replacement)
        return copy
    }

    /** Replace an element in the array. Always returns a copy. */
    export function replace<T>(a: readonly T[], index: number, replacement: T): T[] {
        const length = a.length
        if (index < 0 || index >= length) throw Error("index out of range: " + index)

        const copy = a.slice()
        copy.splice(index, 1, replacement)
        return copy
    }

    /** Remove an element from the array. Always returns a copy. */
    export function remove<T>(a: readonly T[], index: number): T[] {
        const length = a.length
        if (index < 0 || index >= length) throw Error("index out of range: " + index)

        const copy = a.slice()
        copy.splice(index, 1)
        return copy
    }

    /** Moves an element in the array. Always returns a copy. */
    export function move<T>(a: readonly T[], from: number, to: number): T[] {
        const length = a.length
        if (from < 0 || from >= length) throw Error("from index out of range: " + from)
        if (to < 0 || to >= length) throw Error("to index out of range: " + to)

        const copy = a.slice()
        if (to === from) return copy

        const element = copy[from]
        if (from < to) {
            copy.splice(to + 1, 0, element)
            copy.splice(from, 1)
        } else {
            copy.splice(from, 1)
            copy.splice(to, 0, element)
        }
        return copy
    }

    /** Takes two lists and returns a list of pairs, matching up a[0] with b[0], ..., a[n] with b[n], until
     * one of the list runs out of elements. */
    export function zip<T1, T2>(a: readonly T1[], b: readonly T2[]): [T1, T2][] {
        const res: [T1, T2][] = []
        const length = Math.min(a.length, b.length)
        for (let i = 0; i < length; i++) {
            res.push([a[i], b[i]])
        }
        return res
    }

    /** Updates an element of an array via a inline body, returns a copy of the array with one element
     * replaced. */
    export function update<T>(a: readonly T[], index: number, body: (currentElement: T) => T) {
        const res = a.slice()
        res[index] = body(res[index])
        return res
    }
}

/** Can be used to create writable placeholders for updates. */
export type Writeable<T> = { -readonly [P in keyof T]: T[P] }

/** A namespace for a few function that help in the creation and updating of objects that are fully readonly.
 * Best not used directly, but inside objects that have all their fields set to readonly. */
export namespace ValueObject {
    /** Morphs any object into an object like the template, by deleting extra fields, setting missing fields
     * to defaults, and switching the prototype. Can be used on objects deserialized using JSON. */
    export function morphUsingTemplate<T>(values: Record<string, unknown>, template: T): T {
        // Delete any unknown fields
        for (const field of Object.keys(values)) {
            if (template[field] === undefined) {
                delete values[field]
            }
        }

        // Fill in any missing fields
        for (const field of Object.keys(template)) {
            if (values[field] === undefined) {
                values[field] = template[field]
            }
        }

        Object.setPrototypeOf(values, Object.getPrototypeOf(template))
        if (USE_FREEZE) {
            Object.freeze(values)
        }
        return values as T
    }

    /** Intended to mutate the object once, can be used in the constructor of the object. */
    export function writeOnce<T>(object: Writeable<T>, values?: Record<string, unknown>): void {
        if (values) {
            Object.assign(object, values)
        }
        if (USE_FREEZE) {
            Object.freeze(object)
        }
    }

    /** Update the current object by making a copy and applying the values to that object. Should be used to create type-safe versions. */
    export function update<T>(object: Readonly<T>, values: Record<string, unknown>): T {
        const result = Object.assign(Object.create(Object.getPrototypeOf(object)), object, values)
        if (USE_FREEZE) {
            Object.freeze(result)
        }
        return result
    }
}
