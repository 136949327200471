/* eslint-disable no-console */

import { reportError } from "./errors"

// Prepare an object for JSON.stringify, so it is safe and useful to log into a string.
function jsonSafeCopy(obj: any, depth: number = 0, seen: Set<any> = new Set()): any {
    if (obj === null || typeof obj !== "object") return obj
    if (seen.has(obj)) return "[Circular]"
    if (depth > 2) return "..."

    seen.add(obj)
    try {
        if (typeof obj.toJSON === "function") {
            return jsonSafeCopy(obj.toJSON(), depth + 1, seen)
        } else if (Array.isArray(obj)) {
            return obj.map(v => jsonSafeCopy(v, depth + 1, seen))
        } else if (Object.getPrototypeOf(obj) !== Object.prototype) {
            return `[Object: ${obj.__class || obj.constructor?.name}]`
        } else {
            const result: Record<string, any> = {}
            for (const [key, v] of Object.entries(obj)) {
                result[key] = jsonSafeCopy(v, depth + 1, seen)
            }
            return result
        }
    } catch (e) {
        return `[Throws: ${e.message}]`
    } finally {
        seen.delete(obj)
    }
}

export const enum LogLevel {
    Trace = 0,
    Debug,
    Info,
    Warn,
    Error,
    NotLogging,
}

const levelNames = ["trace", "debug", "info", "warn", "error"]
const postfixNames = [":trace", ":debug", ":info", ":warn", ":error"]

function applyLogLevelSpec(spec: string, all: Logger[]) {
    // For every section of the specification string.
    for (const s of spec.split(/[ ,]/)) {
        let match = s.trim()
        if (match.length === 0) continue

        let level = LogLevel.Debug
        let inverted = false

        // Check if it match should be disabled.
        if (match.startsWith("-")) {
            match = match.slice(1)
            level = LogLevel.Warn
            inverted = true
        }

        // Check the specified log level for this matcher, if any.
        for (let i = 0; i <= LogLevel.Error; i++) {
            const postfix = postfixNames[i]
            if (match.endsWith(postfix)) {
                level = i
                if (inverted) {
                    // -:warn should also disable warning, so set log level at one higher
                    level += 1
                }
                match = match.slice(0, match.length - postfix.length)
                if (match.length === 0) {
                    match = "*"
                }
                break
            }
        }

        // Make a string matcher and set the log level for every matching logger.
        const regex = new RegExp("^" + match.replace(/[*]/g, ".*") + "$")
        for (const logger of all) {
            if (logger.id.match(regex)) {
                logger.level = level
            }
        }
    }
}

class LogEntry {
    time: number
    stringPrefix: string | undefined

    constructor(public logger: Logger, public level: LogLevel, public parts: unknown[]) {
        this.time = Date.now()
    }

    toMessage(): unknown[] {
        if (this.stringPrefix) return this.parts

        const r = [
            new Date(this.time).toISOString().substr(-14, 14),
            levelNames[this.level] + ": [" + this.logger.id + "]",
        ]
        let i = 0
        for (; i < this.parts.length; i++) {
            const part = this.parts[i]
            if (typeof part === "string") {
                r.push(part)
                continue
            }
            break
        }
        this.stringPrefix = r.join(" ")
        this.parts.splice(0, i, this.stringPrefix)
        return this.parts
    }

    toString(): string {
        return this.toMessage()
            .map(part => {
                if (typeof part === "string") return part

                // Create a useful string from any object, making sure it is safe and truncated if needed.
                const json = JSON.stringify(jsonSafeCopy(part))
                if (json?.length > 253) {
                    return json.slice(0, 250) + "..."
                }
                return json
            })
            .join(" ")
    }
}

// Default log level.
let logLevelSpec = "app:info"

// But different in node and even in CI.
const isNode = typeof process !== "undefined" && !!process.kill
const isCI = isNode && !!process.env.CI
if (isCI) {
    logLevelSpec = "-:warn"
} else if (isNode) {
    logLevelSpec = ""
}

// Recover the log level specification from local storage or an environment variable.
try {
    if (typeof window !== "undefined" && window.localStorage) {
        logLevelSpec = window.localStorage.logLevel || logLevelSpec
    }
} catch {
    // ignore
}
try {
    if (typeof process !== "undefined") {
        logLevelSpec = process.env.DEBUG || logLevelSpec
    }
} catch {
    // ignore
}
try {
    if (typeof window !== "undefined") {
        Object.assign(window, { setLogLevel })
    }
} catch {
    // ignore
}

const loggers: { [key: string]: Logger } = {}
const replayBuffer: LogEntry[] = []

function createLogEntry(logger: Logger, level: LogLevel, parts: unknown[]): LogEntry {
    const entry = new LogEntry(logger, level, parts)
    replayBuffer.push(entry)

    // Keep a limited number/timespan of entries in the replay buffer
    const oldest = Date.now() - 1000 * 60 * 60
    while (replayBuffer.length > 1000 || replayBuffer[0]?.time < oldest) {
        replayBuffer.shift()
    }

    return entry
}

/** Get the internal buffer of recent log messages. Use with care! But can be
 * used for scripting browser based tests. */
export function getLogReplayBuffer(): LogEntry[] {
    return replayBuffer
}

/** Get a logger for a certain id, will create a new logger if needed. */
export function getLogger(id: string): Logger {
    const existing = loggers[id]
    if (existing) return existing

    const logger = new Logger(id)
    loggers[id] = logger
    applyLogLevelSpec(logLevelSpec, [logger])
    return logger
}

/** Set all log levels via a string, returns previous setting. */
export function setLogLevel(spec: string, replay = true): string {
    // Persist the specification via local storage, if applicable.
    try {
        if (typeof window !== "undefined" && window.localStorage) {
            window.localStorage.logLevel = spec
        }
    } catch {
        // ignore
    }

    const previousSpec = logLevelSpec
    logLevelSpec = spec
    const all = Object.values(loggers)

    // Reset all loggers to default.
    for (const logger of all) {
        logger.level = LogLevel.Warn
    }

    // Apply new log rules to all loggers.
    applyLogLevelSpec(spec, all)

    // Replay the buffer for new setting.
    if (replay && replayBuffer.length > 0) {
        console?.log("--- LOG REPLAY ---")
        for (const entry of replayBuffer) {
            if (entry.logger.level > entry.level) continue
            if (entry.level >= LogLevel.Warn) {
                console?.warn(...entry.toMessage())
            } else {
                console?.log(...entry.toMessage())
            }
        }
        console?.log("--- END OF LOG REPLAY ---")
    }

    return previousSpec
}

/** If you see `logger.ts` show up as the source of log message,  you can
 * blackbox the script (in google chrome):
 * - go to developer tools
 * - open up settings
 * - go to blackbox
 * - add `.*logger.ts` as a pattern. That should make the original point of
 *   logging appear in the right hand side of the console.
 * */
export class Logger {
    level: LogLevel = LogLevel.Warn
    private didLog: { [key: string]: number } = {}

    constructor(readonly id: string) {}

    public extend(name: string): Logger {
        const id = this.id + ":" + name
        return getLogger(id)
    }

    /** Returns the messages this logger created that are still in the global replay buffer. */
    public getBufferedMessages(): LogEntry[] {
        return replayBuffer.filter(entry => entry.logger === this)
    }

    /** Set new level and return previous level. */
    public setLevel(level: LogLevel): LogLevel {
        const previous = this.level
        this.level = level
        return previous
    }

    /** Check if a trace messages will be output. */
    public isLoggingTraceMessages(): boolean {
        return this.level >= LogLevel.Trace
    }

    /** Only output something if the trace level is active for this logger.
     * Trace level messages are not be recorded for replay either. */
    trace = (...parts: unknown[]) => {
        if (this.level > LogLevel.Trace) return
        const entry = new LogEntry(this, LogLevel.Trace, parts)
        console?.log(...entry.toMessage())
    }

    /** Debug level is supposed to be used for things that log often and are disabled by default. */
    debug = (...parts: unknown[]) => {
        const entry = createLogEntry(this, LogLevel.Debug, parts)
        if (this.level > LogLevel.Debug) return
        console?.log(...entry.toMessage())
    }

    /** Info level is supposed to be used for once per big user action, or maybe
     * once per minute things. Some loggers log at this level by default. Don't
     * overuse. */
    info = (...parts: unknown[]) => {
        const entry = createLogEntry(this, LogLevel.Info, parts)
        if (this.level > LogLevel.Info) return
        console?.info(...entry.toMessage())
    }

    warn = (...parts: unknown[]) => {
        const entry = createLogEntry(this, LogLevel.Warn, parts)
        if (this.level > LogLevel.Warn) return
        console?.warn(...entry.toMessage())
    }

    warnOncePerMinute = (firstPart: string, ...parts: unknown[]) => {
        if (this.didLog[firstPart] > Date.now()) return
        this.didLog[firstPart] = Date.now() + 1000 * 60

        parts.unshift(firstPart)
        const entry = createLogEntry(this, LogLevel.Warn, parts)
        if (this.level > LogLevel.Warn) return
        console?.warn(...entry.toMessage())
    }

    error = (...parts: unknown[]) => {
        const entry = createLogEntry(this, LogLevel.Error, parts)
        if (this.level > LogLevel.Error) return
        console?.error(...entry.toMessage())
    }

    errorOncePerMinute = (firstPart: string, ...parts: unknown[]) => {
        if (this.didLog[firstPart] > Date.now()) return
        this.didLog[firstPart] = Date.now() + 1000 * 60

        parts.unshift(firstPart)
        const entry = createLogEntry(this, LogLevel.Error, parts)
        if (this.level > LogLevel.Error) return
        console?.error(...entry.toMessage())
    }

    reportError = (maybeError: unknown, extras?: Record<string, unknown>) => {
        // Attach the last 100 log entries as text.
        const logs = getLogReplayBuffer()
            .slice(-100)
            .map(entry => entry.toString().slice(0, 1000))
            .join("\n")
        const reportedError = reportError({
            caller: this.reportError,
            error: maybeError,
            tags: {
                handler: "logger",
                where: this.id,
            },
            extras: { ...extras, logs },
        })

        // Log the error to the console/buffer as well
        extras ? this.error(reportedError, extras) : this.error(reportedError)
    }
}

// ❗️
// The Shared package needs to work in any TypeScript environment. Reference DOM types without importing or depending
// on them so that the package compiles, but fails or skips functionality when used in a non-DOM environment.
declare const console: typeof global["console"] | undefined
