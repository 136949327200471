import * as React from "react"
import {
    SandboxEntityDefinition,
    SandboxEntityIdsByPackageIdMap,
    SandboxEntityMap,
    SandboxDesignComponentDefinition,
    SandboxErrorDefinition,
    FramerIndexModule,
    SandboxPackageMap,
    ProjectBundleGlobalEnvironment,
    ProjectBundleScriptsMap,
    SandboxReactComponentDefinition,
    isSandboxErrorDefinition,
    isSandboxDesignDefinition,
    isSandboxReactComponentDefinition,
} from "./types"
import { createErrorDefinition } from "./createErrorDefinition"
import { extractFileNameFromIdentifier } from "./extractFileNameFromIdentifier"
import { localPackageFallbackIdentifier } from "./localPackageFallbackIdentifier"
import { optionalReactDOM } from "../optionalReactDOM"
import { optionalReactDOMUnstableNativeDependencies } from "../optionalReactDOMUnstableNativeDependencies"
import { warn } from "./warn"
import { collectPackages } from "./collectPackages"
import { collectEntities } from "./collectEntities"
import { collectTokens } from "./externalDocuments"
import { addDefaultActions } from "../actions/addDefaultActions"
import type { ComponentLoaderState, EntityMap, HostPackageMap } from "../../host"
import { addDefaultComponents } from "./addDefaultComponents"
import type { EntityIdentifier, PackageIdentifier, TokenMap } from "../../host/componentLoader/types"
import { getPackageModuleInfo } from "../../utils/getPackageModuleInfo"
import { createErrorPlaceholder } from "./createErrorPlaceholder"

interface TokensByPackageIdMap {
    [packageId: string]: TokenMap
}

export interface SandboxComponentLoaderInterface {
    componentForIdentifier(identifier: EntityIdentifier): SandboxEntityDefinition | null
    reactComponentForIdentifier(identifier: EntityIdentifier): SandboxReactComponentDefinition | null
    componentsForPackage(packageIdentifier: PackageIdentifier): SandboxEntityDefinition[]
    componentIdentifiers(): EntityIdentifier[]
    errorForIdentifier(identifier: EntityIdentifier): SandboxErrorDefinition | null
    forEachComponent(callback: (component: SandboxEntityDefinition) => boolean | void): void
    forEachDesignComponents(callback: (component: SandboxDesignComponentDefinition) => boolean | void): void
    localPackageIdentifier(): string
    packageDisplayName(packageIdentifier: PackageIdentifier): string | undefined
    packageIdentifiers(): PackageIdentifier[]
    packageFileNames(packageIdentifier: PackageIdentifier): string[]
    // only returns tokens from the dependency packages, doesn't include "local" ones
    tokensForPackage(packageIdentifier: PackageIdentifier): TokenMap
    updateStateWithScripts(
        updatedScriptsMap: ProjectBundleScriptsMap,
        framer: any,
        defaultComponentMap: SandboxEntityMap
    ): void
}

const NON_ALPHABETIC_CHARS_REGEXP = /[^a-zA-Z]/g

interface WindowWithImportFromPackage extends Window {
    __framer_importFromPackage?: (
        packageAndFilename: string,
        exportIdentifier: string,
        updateCallback: (newValue: SandboxEntityDefinition["class"]) => void
    ) => SandboxEntityDefinition["class"]
}

interface SandboxComponentLoaderState {
    readonly localPackageIdentifier: string
    // All the packages referenced by the local package and any dependency under it.
    readonly packages: SandboxPackageMap
    // same as packages except the fields that can't/shouldn't be serialized when sent to Host
    readonly serializablePackages: HostPackageMap
    // A lookup table of all the components from all the packages.
    readonly entities: SandboxEntityMap
    // A lookup table of all the components from all the packages, specialized for module interoperability.
    readonly entitiesForModules: SandboxEntityMap
    // Various optimized data structures to avoid lots of repeated computation
    readonly entityIdsByPackage: SandboxEntityIdsByPackageIdMap
    readonly visibleComponentIdentifiers: EntityIdentifier[]
    readonly visiblePackageNames: PackageIdentifier[]
    readonly visiblePackageFileNames: { [identifier: string]: string[] }
    readonly tokensByPackage: TokensByPackageIdMap
}

export class ComponentLoader implements SandboxComponentLoaderInterface {
    // TODO-MODULES: This is a temporary solution for keeping track of the entities coming
    //               from the modules and the bundle simultaneously. This needs to be able
    //               to track entities from all sources (bundles/modules) in a single map.
    // FIXME: This needs to be rewritten for both performance reasons and the above.
    private localEntitiesInitialized: boolean
    private localEntities: SandboxEntityMap = {}
    private localEntityIdsByPackage: SandboxEntityIdsByPackageIdMap = {}
    private localVisibleComponentIdentifiers: EntityIdentifier[] = []

    private _entities?: SandboxEntityMap
    private get entities(): SandboxEntityMap {
        return this._entities || (this._entities = { ...this.state.entities, ...this.localEntities })
    }

    private _entityIdsByPackage?: SandboxEntityIdsByPackageIdMap
    private get entityIdsByPackage(): SandboxEntityIdsByPackageIdMap {
        if (this._entityIdsByPackage) return this._entityIdsByPackage

        // Merging the entityIdsByPackage structures requires deep merging of the entityIds arrays for each package.
        const combinedEntityIdsByPackage = { ...this.state.entityIdsByPackage }
        for (const [pkg, entityIds] of Object.entries(this.localEntityIdsByPackage)) {
            if (!combinedEntityIdsByPackage[pkg]) {
                combinedEntityIdsByPackage[pkg] = entityIds
            } else {
                combinedEntityIdsByPackage[pkg] = [...combinedEntityIdsByPackage[pkg], ...entityIds]
            }
        }
        this._entityIdsByPackage = combinedEntityIdsByPackage

        return this._entityIdsByPackage
    }

    private _visibleComponentIdentifiers?: EntityIdentifier[]
    private get visibleComponentIdentifiers(): EntityIdentifier[] {
        return (
            this._visibleComponentIdentifiers ||
            (this._visibleComponentIdentifiers = [
                ...this.state.visibleComponentIdentifiers,
                ...this.localVisibleComponentIdentifiers,
            ])
        )
    }

    private state: SandboxComponentLoaderState = {
        localPackageIdentifier: localPackageFallbackIdentifier,
        packages: {},
        serializablePackages: {},
        entities: {},
        entitiesForModules: {},
        entityIdsByPackage: {},
        visibleComponentIdentifiers: [],
        visiblePackageNames: [],
        visiblePackageFileNames: {},
        tokensByPackage: {},
    }

    private updateModulesCallbacks: (() => void)[] = []

    constructor() {
        addDefaultComponents(this.state.entities)
        this.setupPackagesToModulesHook()
    }

    private setupPackagesToModulesHook() {
        const global: WindowWithImportFromPackage = window
        if (!global.__framer_importFromPackage) {
            global.__framer_importFromPackage = (packageAndFilename, exportIdentifier, updateCallback) => {
                const entityIdForModule = packageAndFilename.includes("/")
                    ? `${packageAndFilename}_${exportIdentifier}` // e.g., "@framer/team.pkg/Button.js_Button"
                    : `${packageAndFilename}/${exportIdentifier}` // e.g., "framer/Stack"

                // Note: we never remove these callbacks because we can’t know when they are no longer needed.
                // There's only one callback per unique imported entity, so it shouldn’t grow too much in memory.
                this.updateModulesCallbacks.push(() => {
                    try {
                        updateCallback(this.state.entitiesForModules[entityIdForModule]?.class)
                    } catch (error) {
                        warn(`Failed to update module reference for ${entityIdForModule}`, error)
                    }
                })

                const entity = this.state.entitiesForModules[entityIdForModule]
                if (entity) {
                    return entity.class
                } else {
                    // TODO: Consider installing missing packages on paste.
                    const message = `Could not find code component "${exportIdentifier}" in "${packageAndFilename}"`
                    warn(message)
                    return createErrorPlaceholder(message)
                }
            }
        }
    }

    public getSerializableState(): ComponentLoaderState {
        return {
            localPackageIdentifier: this.state.localPackageIdentifier,
            packages: this.state.serializablePackages,
            // this is not safe, but to keep it fast we rely on the non-serializable
            // fields being filtered out by JSON.stringify()
            entities: this.entities as EntityMap,
            entityIdsByPackage: this.entityIdsByPackage,
            visibleComponentIdentifiers: this.visibleComponentIdentifiers,
            visiblePackageNames: this.state.visiblePackageNames,
            visiblePackageFileNames: this.state.visiblePackageFileNames,
            tokensByPackage: this.state.tokensByPackage,
        }
    }

    public componentForIdentifier(identifier: EntityIdentifier): SandboxEntityDefinition | null {
        return this.entities[identifier] || null
    }

    public reactComponentForIdentifier(identifier: EntityIdentifier): SandboxReactComponentDefinition | null {
        const definition = this.componentForIdentifier(identifier)
        if (!definition || !isSandboxReactComponentDefinition(definition)) return null
        return definition
    }

    public componentsForPackage(packageIdentifier: PackageIdentifier): SandboxEntityDefinition[] {
        const entityIds = this.entityIdsByPackage[packageIdentifier] || []
        return entityIds.map(entityId => this.entities[entityId])
    }

    // TODO: Allow more specific filtering across the entire tree of dependencies.
    public componentIdentifiers(): EntityIdentifier[] {
        return this.visibleComponentIdentifiers
    }

    public errorForIdentifier(identifier: EntityIdentifier): SandboxErrorDefinition | null {
        const { entities } = this
        const { visiblePackageFileNames } = this.state
        // All errors will be stored under the filename, not the component's identifier.

        const file = extractFileNameFromIdentifier(identifier)
        let errorDefinition: SandboxErrorDefinition | null = null

        const isModuleBackedEntity = identifier.startsWith("local-module:")

        // We determine if the entities are loaded by checking if we have visiblePackageFileNames
        // in case a "legacy" bundled entity,
        // or this.localEntitiesInitialized is true in case of a module-backed entity,
        const isLoaded =
            (!isModuleBackedEntity && Object.entries(visiblePackageFileNames).length) ||
            (isModuleBackedEntity && this.localEntitiesInitialized)

        // We can only determine if there is an error after the entities are loaded
        // Otherwise we return null which the renderer takes to mean "loading".
        if (isLoaded) {
            let packageFileExists = false

            for (const packageIdentifier in visiblePackageFileNames) {
                if (visiblePackageFileNames[packageIdentifier].includes(file)) {
                    // Check for the component && verify that the file hasn't errored.
                    if (entities[identifier] === undefined && !isSandboxErrorDefinition(entities[file])) {
                        // The file exists but the component doesn't, return that as an error
                        errorDefinition = createErrorDefinition(identifier, "Component cannot be found.")
                    }
                    packageFileExists = true
                    break
                }
            }

            if (!packageFileExists) {
                // The file doesn't exist, return that as error.
                // NOTE: In the current setup, it is not possible that the component is still
                // loading but if that is the case that will need to be detected here.
                // TODO: Show a more user friendly error if component class changes name.
                errorDefinition = createErrorDefinition(identifier, "Component file does not exist.", {
                    fileDoesNotExist: true,
                })
            }
            if (errorDefinition) return errorDefinition
        }
        const definition = entities[file]
        if (!isSandboxErrorDefinition(definition)) {
            // This is a valid component definition.
            return null
        }
        return definition
    }

    public forEachComponent(callback: (component: SandboxEntityDefinition) => boolean | void): void {
        for (const identifier of this.visibleComponentIdentifiers) {
            const component = this.state.entities[identifier]
            if (isSandboxErrorDefinition(component)) continue
            const abort = callback(component)
            if (abort) break
        }
    }

    public forEachDesignComponents(callback: (component: SandboxDesignComponentDefinition) => boolean | void): void {
        this.forEachComponent((component: SandboxEntityDefinition) => {
            if (isSandboxDesignDefinition(component)) return callback(component)
        })
    }

    // TODO: This is actually the name, not the identifier (which is [scope]name@version).
    public localPackageIdentifier(): string {
        return this.state.localPackageIdentifier
    }

    public packageDisplayName(packageIdentifier: PackageIdentifier): string | undefined {
        const packageInfo = this.state.packages[packageIdentifier]
        return packageInfo && packageInfo.displayName
    }

    // TODO: This is currently a list of package names, not identifiers.
    public packageIdentifiers(): PackageIdentifier[] {
        return this.state.visiblePackageNames
    }

    public packageFileNames(packageIdentifier: PackageIdentifier): string[] {
        return this.state.visiblePackageFileNames[packageIdentifier]
    }

    public tokensForPackage(packageIdentifier: PackageIdentifier): TokenMap {
        return this.state.tokensByPackage[packageIdentifier]
    }

    public updateLocalEntities(entities: SandboxEntityDefinition[], initialized: boolean) {
        const entityIds = [] as EntityIdentifier[]

        this.localEntitiesInitialized = initialized
        this.localEntities = {}
        this.localEntityIdsByPackage = { [this.localPackageIdentifier()]: entityIds }
        this.localVisibleComponentIdentifiers = entityIds

        for (const entityDefinition of entities) {
            const id = entityDefinition.identifier
            this.localEntities[id] = entityDefinition
            if (!isSandboxErrorDefinition(entityDefinition)) {
                entityIds.push(id)
            }
        }

        delete this._entities
        delete this._entityIdsByPackage
        delete this._visibleComponentIdentifiers
    }

    public updateStateWithScripts(updatedScriptsMap: ProjectBundleScriptsMap, framer: any): void {
        // be defensive in the situation when there is nothing to update
        if (Object.keys(updatedScriptsMap).length === 0) return

        // A special require function with the dependencies we'd like to feed.
        const require = (name: string) => {
            if (name === "react") return React
            if (name === "react-dom" || name === "ReactDOM") return optionalReactDOM()
            // needed for `react-native-web` to work
            if (name === "react-dom/unstable-native-dependencies" || name === "ReactDOMUnstableNativeDependencies") {
                return optionalReactDOMUnstableNativeDependencies()
            }
            if (name === "framer") return framer
            if (name === "framer/resource") return {} // not a real module, tricked by our webpack compiler
            throw Error(`Component loader: Can't require ${name}`)
        }

        const global: ProjectBundleGlobalEnvironment = window as any
        const head = document.head
        if (!head) throw Error("assert: head is not present")
        const mod: FramerIndexModule = { exports: {} }

        const bundleScriptNames: (keyof ProjectBundleScriptsMap)[] = ["vendors.js", "index.js"] // in the order of evaluation
        bundleScriptNames.forEach((scriptName: "vendors.js" | "index.js") => {
            let script = updatedScriptsMap[scriptName]
            if (!script) {
                // in the situation when only "vendors.js" script is updated
                // we need to re-evaluate "index.js" too in order for it to pick up its updated dependencies
                if (scriptName === "index.js" && updatedScriptsMap["vendors.js"]) {
                    script = global.componentScript
                } else {
                    return
                }
            }

            const escapedScriptName = scriptName.replace(NON_ALPHABETIC_CHARS_REGEXP, "_")
            const sourceURL = `//# sourceURL=${scriptName}`
            const evalScriptFunctionName = `eval_${escapedScriptName}_script`
            const scriptElementClassName = `bundle_script_${escapedScriptName}`
            const scriptContent = `function ${evalScriptFunctionName}(module, exports, require) {\n${script}\n}\n${sourceURL}`

            try {
                const previous = head.querySelector(`.${scriptElementClassName}`)
                if (previous) head.removeChild(previous)

                const scriptElement = document.createElement("script")
                scriptElement.className = scriptElementClassName
                scriptElement.setAttribute("type", "text/javascript")
                scriptElement.textContent = scriptContent
                head.appendChild(scriptElement)

                if (scriptName === "index.js") {
                    global.componentScript = scriptContent
                    global.componentSrc = scriptElement.src || window.location.toString()
                }

                global[evalScriptFunctionName](mod, mod.exports, require)
            } catch (error) {
                warn("An error occurred while reloading", error)
                return
            } finally {
                // In some cases WebPack will unset module.exports, so reset it here.
                if (!mod.exports) mod.exports = {}
            }
        })

        // Figure out the name of the local package (or fallback).
        let localPackageIdentifier = localPackageFallbackIdentifier
        if (mod.exports.__framer__) {
            const { packageJson } = mod.exports.__framer__
            if (packageJson && packageJson.name) {
                localPackageIdentifier = packageJson.name
            }
        }

        const [packages, serializablePackages] = collectPackages(mod.exports)

        const entities: SandboxEntityMap = {}
        addDefaultComponents(entities)
        const entitiesForModules: SandboxEntityMap = {}
        const entityIdsByPackage: { [identifier: string]: EntityIdentifier[] } = {}
        const visibleComponentIdentifiers: EntityIdentifier[] = []
        const visiblePackageNames: PackageIdentifier[] = []
        const visiblePackageFileNames: { [identifier: string]: string[] } = {}
        const tokensByPackage: { [packageId: string]: { [tokenId: string]: any } } = {}

        for (const name of Object.keys(packages)) {
            const packageInfo = packages[name]

            // Keep a list of all direct dependencies.
            if (packageInfo.depth <= 1) {
                visiblePackageNames.push(name)
                visiblePackageFileNames[name] = Object.keys(packageInfo.sourceModules)
            }

            // TODO: Review all unnecessary calls to componentIdentifiers().
            // TODO: Consider separate indexes for components etc.
            const packageEntities = collectEntities(packageInfo)

            // Store a list of entity ids that are in this package.
            entityIdsByPackage[name] = []
            for (const identifier of Object.keys(packageEntities)) {
                const definition = packageEntities[identifier]

                // The id will look something like "@framer/user.package-name/Filename.js"
                // TODO: Consider only inserting direct dependencies.
                const moduleInfo = getPackageModuleInfo(identifier)
                if (moduleInfo) {
                    entitiesForModules[moduleInfo.entityId] = definition
                }

                if (isSandboxErrorDefinition(definition)) continue
                entityIdsByPackage[name].push(identifier)
                // Keep a list of all component identifiers of direct dependencies.
                if (packageInfo.depth <= 1) {
                    visibleComponentIdentifiers.push(identifier)
                }
            }

            Object.assign(entities, packageEntities)
            const { depth, designJson } = packageInfo
            tokensByPackage[name] = depth > 0 && designJson ? collectTokens(designJson) : {}
        }

        addDefaultActions(entities, entityIdsByPackage)

        visiblePackageNames.sort((a, b) => {
            return packages[a].displayName.localeCompare(packages[b].displayName)
        })

        this.state = {
            localPackageIdentifier,
            packages,
            serializablePackages,
            entities,
            entitiesForModules,
            entityIdsByPackage,
            visibleComponentIdentifiers,
            visiblePackageNames,
            visiblePackageFileNames,
            tokensByPackage,
        }
        delete this._entities
        delete this._entityIdsByPackage
        delete this._visibleComponentIdentifiers
        // Update any references to entities in modules.
        this.updateModulesCallbacks.forEach(fn => fn())
    }
}

// Note: initializeRuntime injects this object into the Library
export const sandboxComponentLoader = new ComponentLoader()
