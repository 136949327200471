import { withOpacity, DeprecatedVisualProperties } from "framer"
import type { CanvasNode } from "document/models/CanvasTree"
import { withBlending } from "document/models/CanvasTree/traits/Blending"
import { withFilters } from "document/models/CanvasTree/traits/Filters"
import { withRadius, withRelativeRadius } from "document/models/CanvasTree/traits/Radius"
import { withRadiusPerCorner } from "document/models/CanvasTree/traits/RadiusPerCorner"
import { isNumber } from "utils/typeChecks"

export function collectVisualPropertiesForNode(node: CanvasNode): DeprecatedVisualProperties {
    const result: DeprecatedVisualProperties = {}
    if (withBlending(node) && node.blendingMode !== "normal") {
        result.blendingMode = node.blendingMode
    }
    if (withOpacity(node)) {
        result.opacity = node.opacity
    }
    if (withRadius(node)) {
        result.radius = node.resolveValue("radius")
    }
    if (withRelativeRadius(node) && node.radiusIsRelative) {
        result.radius = `${node.radius}%`
    }
    if (withRadiusPerCorner(node) && node.radiusPerCorner) {
        result.radius = {
            topLeft: node.radiusTopLeft,
            topRight: node.radiusTopRight,
            bottomLeft: node.radiusBottomLeft,
            bottomRight: node.radiusBottomRight,
        }
    }
    if (withFilters(node)) {
        if (isNumber(node.blur)) {
            result.blur = node.blur
        }
        if (isNumber(node.backgroundBlur)) {
            result.backgroundBlur = node.backgroundBlur
        }
    }

    return result
}
