/**
 * Javascript implementation of djb2 hash function
 * More info see: https://gist.github.com/eplawless/52813b1d8ad9af510d85
 * In case you wonder where this magic 5381 value comes from see:
 * https://stackoverflow.com/questions/10696223/reason-for-5381-number-in-djb-hash-function/10697529#10697529
 * @internal
 */
const getStringHash = (s: string) => {
    let hash = 5381,
        i = s.length

    while (i) {
        hash = (hash * 33) ^ s.charCodeAt(--i)
    }

    /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
     * integers. Since we want the results to be always positive, convert the
     * signed int to an unsigned by doing an unsigned bitshift. */
    return hash >>> 0
}

const getObjectHash = (obj: unknown) => {
    return getStringHash(JSON.stringify(obj))
}

const globalCache = new Map<string, unknown>()

function cacheGet(k: string) {
    return globalCache.get(k)
}

function cacheSet(k: string, v: unknown) {
    globalCache.set(k, v)
}

const timers: { [index: string]: number } = {}

export function cached<T>(key: string, f: Function, validator?: (result: any) => boolean, time = 0) {
    return (...args: T[]) => {
        const cacheKey = `${key}-${getObjectHash(args)}`
        const cacheValue = cacheGet(cacheKey)

        if (typeof cacheValue !== "undefined") {
            return cacheValue
        }

        const value = f(...args)

        if (time > 0) {
            cacheSet(cacheKey, value)
            timers[cacheKey] = window.setTimeout(() => cacheSet(cacheKey, undefined), time)
        }

        if (!validator || validator(value)) {
            cacheSet(cacheKey, value)
            timers[cacheKey] && window.clearTimeout(timers[cacheKey])
        }

        return value
    }
}
