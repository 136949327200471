import * as React from "react"
import { cx } from "linaria"
import { isFiniteNumber } from "./utils/isFiniteNumber"
import { MouseTracker, MouseTrackerEvent } from "./MouseTracker"
import * as styles from "./NumberTicker.styles"
import { IconTickerUp } from "../icons/IconTickerUp"
import { IconTickerDown } from "../icons/IconTickerDown"
import type { OverrideType, HTMLDivAttributes } from "./types"
import { frescoSettingsContext } from "./FrescoSettings"

export type NumberTickerProps = OverrideType<
    HTMLDivAttributes,
    {
        value: number | null
        onChange: (value: number) => void
        visible: boolean
        enabled: boolean | undefined
        min: number | undefined
        max: number | undefined
        step: number | "nudge" | undefined
        // Not sure about this API yet, we are using it to start and end undo groups in Vekter
        onDragStart: (() => void) | undefined
        onDragEnd: (() => void) | undefined
    }
>

export const NumberTicker = React.memo(function NumberTicker(props: NumberTickerProps) {
    const latestPropsRef = React.useRef(props)
    latestPropsRef.current = props

    const { enabled, visible, className, step } = props

    const isDraggingRef = React.useRef(false)
    const intervalRef = React.useRef<number | null>(null)
    const isAutoIncrementingRef = React.useRef(false)
    const startValueRef = React.useRef(0)

    const frescoSettings = React.useContext(frescoSettingsContext)

    const smallStepIncrement = (step === "nudge" ? frescoSettings.smallNudgeIncrement : step) || 1
    const largeStepIncrement = step === "nudge" ? frescoSettings.largeNudgeIncrement : smallStepIncrement * 10

    const increaseValue = React.useCallback(() => {
        if (!autoIncrementEnabled(isDraggingRef, latestPropsRef)) return

        isAutoIncrementingRef.current = true

        const newValue = (latestPropsRef.current.value || 0) + smallStepIncrement
        updateValue(newValue, latestPropsRef.current)
        startValueRef.current = newValue
        intervalRef.current = window.setTimeout(increaseValue, 50)
    }, [smallStepIncrement])

    const decreaseValue = React.useCallback(() => {
        if (!autoIncrementEnabled(isDraggingRef, latestPropsRef)) return

        isAutoIncrementingRef.current = true

        const newValue = (latestPropsRef.current.value || 0) - smallStepIncrement
        updateValue(newValue, latestPropsRef.current)
        startValueRef.current = newValue
        intervalRef.current = window.setTimeout(decreaseValue, 50)
    }, [smallStepIncrement])

    const dragStartHandler = React.useCallback(
        (event: MouseTrackerEvent) => {
            startValueRef.current = latestPropsRef.current.value || 0
            if (latestPropsRef.current.onDragStart) {
                latestPropsRef.current.onDragStart()
            }

            // top half
            if (event.progress.y < 0.5) {
                intervalRef.current = window.setTimeout(increaseValue, 250)
            } else {
                intervalRef.current = window.setTimeout(decreaseValue, 250)
            }
        },
        [increaseValue, decreaseValue]
    )

    const dragHandler = React.useCallback(
        (event: MouseTrackerEvent) => {
            if (!isDraggingRef.current && Math.abs(event.offset.y) > 2) {
                isDraggingRef.current = true
            }
            if (isDraggingRef.current) {
                const stepSize = event.shiftKey ? largeStepIncrement : smallStepIncrement
                let newValue = startValueRef.current - event.offset.y * stepSize
                if (event.shiftKey) {
                    newValue = Math.round(newValue / largeStepIncrement) * largeStepIncrement
                }
                updateValue(newValue, latestPropsRef.current)
            }
        },
        [largeStepIncrement, smallStepIncrement]
    )

    const dragEndHandler = React.useCallback(
        (event: MouseTrackerEvent) => {
            if (!isAutoIncrementingRef.current && !isDraggingRef.current) {
                const stepSize = event.shiftKey ? largeStepIncrement : smallStepIncrement
                const currentValue = latestPropsRef.current.value || 0
                const newValue = event.progress.y < 0.5 ? currentValue + stepSize : currentValue - stepSize
                updateValue(newValue, latestPropsRef.current)
            }

            // reset
            isDraggingRef.current = false
            isAutoIncrementingRef.current = false
            if (intervalRef.current) {
                window.clearInterval(intervalRef.current)
                intervalRef.current = null
            }

            if (latestPropsRef.current.onDragEnd) {
                latestPropsRef.current.onDragEnd()
            }
        },
        [largeStepIncrement, smallStepIncrement]
    )

    return (
        <MouseTracker
            className={cx(styles.numberTicker, (enabled === false || !visible) && styles.numberTickerHidden, className)}
            onDrag={dragHandler}
            onDragStart={dragStartHandler}
            onDragEnd={dragEndHandler}
            cursor={cursor(props)}
        >
            <div className={styles.tickerUp}>
                <IconTickerUp />
            </div>
            <div className={styles.tickerDown}>
                <IconTickerDown />
            </div>
        </MouseTracker>
    )
})

function updateValue(value: number, props: NumberTickerProps) {
    props.onChange(clampValue(value, props))
}

function clampValue(value: number, props: NumberTickerProps) {
    if (isFiniteNumber(props.min) && value < props.min) return props.min
    if (isFiniteNumber(props.max) && value > props.max) return props.max
    return value
}

function cursor(props: NumberTickerProps) {
    const currentValue = props.value || 0
    if (isFiniteNumber(props.min) && currentValue <= props.min) return "n-resize"
    if (isFiniteNumber(props.max) && currentValue >= props.max) return "s-resize"
    return "ns-resize"
}

function autoIncrementEnabled(
    isDraggingRef: React.MutableRefObject<boolean>,
    propsRef: React.MutableRefObject<NumberTickerProps>
) {
    if (isDraggingRef.current) return false
    const { visible, enabled } = propsRef.current
    if (!visible || enabled === false) return false
    return true
}
