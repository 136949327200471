// Note: this file is imported in Web in play/index.tsx, to power the /play
// endpoint. This means extra consideration for the imports below. If you add
// an import, and Web stops compiling with a lot of module-not-found errors,
// that's why. It's usually (a) non-relative imports, and (b) Vekter's tsconfig
// aliases that Web doesn't know about.
import { readAssetUpdatesStream } from "@framerjs/assets"
import { DeviceOptions } from "@framerjs/framer-runtime/components/Device"
import {
    Assets,
    channelToOpenerFrame,
    channelToParentFrame,
    ModulesState,
    PreviewDataSource,
    PreviewDesktop,
    PreviewSandbox,
    PreviewSettings,
    PreviewWrapper as PreviewWrapperService,
    ServiceEventEmitter,
    ServiceStream,
} from "@framerjs/framer-services"
import { assert, unhandledError } from "@framerjs/shared"
import { Methods } from "console-feed/lib/definitions/Methods"
import * as React from "react"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useIFrameWithChannel } from "../services/hooks/useIFrameWithChannel"
import { unhandledStreamError, useServiceStream } from "../services/hooks/useServiceStream"
import { decode } from "../utils/console-feed/transform"
import { AssetContext } from "./AssetContext"
import { Console, useConsole } from "./components/Console"
import { PreviewContainer } from "./components/PreviewContainer"
import { resolveBackground } from "./utils/backgroundForPreviewSettings"
import { scaleToFitConfig } from "./utils/scaleToFitConfig"
import { hasNodeChanged } from "./utils/hasNodeChanged"

const enum LocalStorageKey {
    DarkMode = "dark-mode",
    OverrideSystem = "dark-mode-override-system",
}

export interface Props {
    controlChannel: "none" | "parent" | "opener" | "both"
    forceDarkMode?: boolean
    highlightsDisabled?: boolean
    previewURL: string
}

interface PreviewWrapperProps extends Props {
    assetsService?: Assets.Interface | Promise<Assets.Interface> // Allows delayed asset service registration
    loadStream?: () => ServiceStream<PreviewWrapperService.LoadEvent>
    modulesStateService: ModulesState.Interface
    previewDataSourceService: PreviewDataSource.Interface
    previewDesktopService?: PreviewDesktop.Interface
    previewSettingsService: PreviewSettings.Interface
    sandboxed?: boolean
    targetOrigin?: string
}

const previewStateEmitter = new ServiceEventEmitter<PreviewWrapperService.StateEvent>()

export const PreviewWrapper = ({
    controlChannel,
    assetsService: assetServiceOrPromise,
    forceDarkMode = false,
    highlightsDisabled = false,
    loadStream,
    modulesStateService,
    previewDataSourceService,
    previewDesktopService,
    previewSettingsService,
    previewURL,
    sandboxed = true,
    targetOrigin,
}: PreviewWrapperProps) => {
    const [PreviewIFrame, previewChannel] = useIFrameWithChannel({
        src: previewURL,
        sandboxed,
        targetOrigin,
        onSetup: iframe => {
            iframe.allowFullscreen = true
        },
    })

    const previewSandboxRef = useRef<PreviewSandbox.Interface | undefined>()

    const emitPreviewStateUpdate = useCallback((update: PreviewWrapperService.StateEvent) => {
        previewStateEmitter.emit(update)
    }, [])

    useEffect(() => {
        const previewWrapperService: PreviewWrapperService.Interface = {
            toggleConsole: async ({ force }) => {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                setHasConsole(prev => {
                    const consoleShown = force !== undefined ? force : !prev
                    emitPreviewStateUpdate({ consoleShown })
                    return consoleShown
                })
            },
            stateStream: previewStateEmitter.newStream,
            loadStream:
                loadStream ||
                (() => {
                    throw new Error("Not implemented")
                }),
        }

        if (controlChannel === "parent" || controlChannel === "both") {
            PreviewWrapperService.on(channelToParentFrame).register(previewWrapperService)
        }
        if (controlChannel === "opener" || controlChannel === "both") {
            PreviewWrapperService.on(channelToOpenerFrame).register(previewWrapperService)
        }
    })

    // Logs

    const [hasConsole, setHasConsole] = useState(false)
    const [consoleState, consoleDispatch] = useConsole()

    const onClearConsole = useCallback(() => {
        consoleDispatch({ type: "clear" })
        previewDesktopService?.onLogClear().catch(unhandledError)
        emitPreviewStateUpdate({ hasErrors: false, hasWarnings: false })
    }, [consoleDispatch, previewDesktopService, emitPreviewStateUpdate])

    const onAddLog = useCallback(
        (logs: PreviewSandbox.Log[]) => {
            consoleDispatch({
                type: "add",
                logs: logs.map(({ id, method, data }) => ({
                    id,
                    method: method as Methods,
                    data: data.map(d => decode(d)),
                })),
            })
            if (logs.find(l => l.method === "error")) {
                previewDesktopService?.onLogError().catch(unhandledError)
                emitPreviewStateUpdate({ hasErrors: true })
            }
            if (logs.find(l => l.method === "warn")) {
                previewDesktopService?.onLogWarning().catch(unhandledError)
                emitPreviewStateUpdate({ hasWarnings: true })
            }
        },
        [consoleDispatch, previewDesktopService, emitPreviewStateUpdate]
    )

    useServiceStream(
        {
            channel: previewChannel || undefined,
            service: PreviewSandbox,
            onDiscover: service => service.previewLogStream(),
            onError: unhandledStreamError,
        },
        async ({ logs }) => onAddLog([...logs])
    )

    const handleToggleConsole = React.useCallback(() => {
        const consoleShown = !hasConsole
        setHasConsole(consoleShown)
        emitPreviewStateUpdate({ consoleShown })
    }, [emitPreviewStateUpdate, hasConsole])

    // Intercept metadata

    const [previewMetadata, setPreviewMetadata] = useState<PreviewSettings.MetadataUpdateEvent>()

    useEffect(() => {
        const stream = previewSettingsService.previewMetadataUpdateStream({ replay: "latest" })
        stream
            .read(async newMetadata => {
                setPreviewMetadata(prevMetadata => {
                    if (prevMetadata && hasNodeChanged(prevMetadata.node, newMetadata.node)) {
                        onClearConsole()
                    }
                    return newMetadata
                })
            })
            .catch(unhandledError)
        return () => void stream.cancel().catch(unhandledError)
    }, [previewSettingsService, onClearConsole])

    // Proxy PreviewSandbox from the sandbox to the parent/opener

    const [isPaused, setPaused] = useState(false)

    useEffect(() => {
        if (!previewChannel) return

        let canceled = false
        let unregisterFromParent: () => void | undefined
        let unregisterFromOpener: () => void | undefined

        PreviewSandbox.on(previewChannel)
            .discover()
            .then(previewSandbox => {
                if (canceled) return

                previewSandboxRef.current = previewSandbox

                const previewSandboxProxy: PreviewSandbox.Interface = {
                    pause: async () => {
                        setPaused(true)
                        return previewSandbox.pause()
                    },
                    resume: async () => {
                        setPaused(false)
                        return previewSandbox.resume()
                    },
                    reload: () => {
                        onClearConsole()
                        return previewSandbox.reload()
                    },
                    setAutoReload: async info => {
                        return previewSandbox.setAutoReload(info)
                    },
                    previewInteractionStream: previewSandbox.previewInteractionStream.bind(previewSandbox),
                    previewKeyboardEventStream: previewSandbox.previewKeyboardEventStream.bind(previewSandbox),
                    previewLogStream: previewSandbox.previewLogStream.bind(previewSandbox),
                    navigationEventStream: previewSandbox.navigationEventStream.bind(previewSandbox),
                    renderPhaseEventStream: previewSandbox.renderPhaseEventStream.bind(previewSandbox),
                    showHighlights: previewSandbox.showHighlights.bind(previewSandbox),
                }

                if (controlChannel === "parent" || controlChannel === "both") {
                    unregisterFromParent = PreviewSandbox.on(channelToParentFrame).register(previewSandboxProxy)
                }
                if (controlChannel === "opener" || controlChannel === "both") {
                    unregisterFromOpener = PreviewSandbox.on(channelToOpenerFrame).register(previewSandboxProxy)
                }
            })
            .catch(unhandledError)

        return () => {
            canceled = true
            unregisterFromParent?.()
            unregisterFromOpener?.()
        }
    }, [controlChannel, previewChannel, onClearConsole])

    // Set up preview services on the preview sandbox iframe

    useEffect(() => {
        if (!previewChannel) return
        return ModulesState.on(previewChannel).register(modulesStateService)
    }, [previewChannel, modulesStateService])

    useEffect(() => {
        if (!previewChannel) return
        return PreviewSettings.on(previewChannel).register(previewSettingsService)
    }, [previewChannel, previewSettingsService])

    useEffect(() => {
        if (!previewChannel) return
        return PreviewDataSource.on(previewChannel).register(previewDataSourceService)
    }, [previewChannel, previewDataSourceService])

    useEffect(() => {
        if (!previewChannel || !assetServiceOrPromise) return
        return registerWhenReady(assetServiceOrPromise, assetService =>
            Assets.on(previewChannel).register(assetService)
        )
    }, [previewChannel, assetServiceOrPromise])

    // Assets, for background image support

    const assetMap = useContext(AssetContext)
    assert(assetMap, "Missing AssetMap, the preview-wrapper may have not been bootstrapped correctly")

    const [, setAssetMapHash] = useState(0)

    useEffect(() => {
        if (!assetServiceOrPromise) return
        Promise.resolve(assetServiceOrPromise)
            .then(assetService => {
                readAssetUpdatesStream(assetService, async event => {
                    if (!event.assets) return
                    assetMap.update(event.assets)
                    // Trigger re-render.
                    setAssetMapHash(assetMap.hash)
                })
            })
            .catch(unhandledError)
    }, [assetMap, assetServiceOrPromise])

    // Dark mode

    const [isDarkMode, setDarkMode] = React.useState(forceDarkMode ?? false)

    const handleDarkMode = React.useCallback(() => {
        if (forceDarkMode) return

        const userDarkMode = localStorage.getItem(LocalStorageKey.DarkMode) === "true"
        const overrideSystem = localStorage.getItem(LocalStorageKey.OverrideSystem) === "true"
        setDarkMode(!!(overrideSystem ? userDarkMode : window.matchMedia("(prefers-color-scheme: dark)").matches))
    }, [forceDarkMode])

    React.useEffect(() => {
        if (forceDarkMode) return

        handleDarkMode()
        window.addEventListener("storage", handleDarkMode)

        return () => {
            window.removeEventListener("storage", handleDarkMode)
        }
    }, [handleDarkMode, forceDarkMode])

    React.useLayoutEffect(() => {
        document.body.classList.toggle("dark", isDarkMode)
    }, [isDarkMode])

    // Rest

    let deviceOptions: DeviceOptions | undefined
    if (previewMetadata?.node) {
        if (previewMetadata?.deviceOptions) {
            // Device
            deviceOptions = { ...previewMetadata.deviceOptions }
        } else if (
            !previewMetadata.responsive &&
            previewMetadata.node.type === "canvas" &&
            previewMetadata.node.width &&
            previewMetadata.node.height
        ) {
            // Scale to fit = render in a "fake" device
            const {
                width,
                height,
                borderRadius,
                backgroundEnabled,
                backgroundType,
                backgroundColor,
                backgroundImage,
                backgroundImageResize,
            } = previewMetadata.node
            deviceOptions = scaleToFitConfig({
                width,
                height,
                borderRadius,
                backgroundEnabled,
                backgroundType,
                backgroundColor,
                backgroundImage,
                backgroundImageResize,
            })
        } else {
            // Responsive mode = no device
        }
    }
    if (deviceOptions?.background) {
        deviceOptions.background = resolveBackground(deviceOptions.background)
    }

    const onScaleChange = useCallback(
        ({ scale }: { scale: number }) => {
            previewDesktopService?.onScaleChange({ scale }).catch(unhandledError)
        },
        [previewDesktopService]
    )

    const handleContainerClick = React.useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!previewSandboxRef.current) return
        if (e.defaultPrevented) return
        previewSandboxRef.current.showHighlights().catch(unhandledError)
    }, [])

    return (
        <div
            style={{
                height: "100%",
                position: "relative",
            }}
        >
            <PreviewContainer
                allowMobileOverride={!!previewMetadata?.deviceOptions}
                deviceOptions={isPaused ? undefined : deviceOptions}
                onScaleChange={onScaleChange}
                onClickCapture={highlightsDisabled ? undefined : handleContainerClick}
            >
                <PreviewIFrame style={{ width: "100%", height: "100%" }} />
            </PreviewContainer>
            {hasConsole && (
                <Console
                    {...consoleState}
                    dark={isDarkMode}
                    onCloseConsole={handleToggleConsole}
                    onClearConsole={onClearConsole}
                />
            )}
        </div>
    )
}

function registerWhenReady<T>(serviceOrPromise: T | Promise<T>, registerFn: (service: T) => () => void) {
    let unregister: (() => void) | undefined = undefined
    Promise.resolve(serviceOrPromise)
        .then(service => {
            unregister = registerFn(service)
        })
        .catch(unhandledError)
    return () => unregister && unregister()
}
