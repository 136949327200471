import * as React from "react"
import { cx } from "linaria"
import type { OverrideType, HTMLDivAttributes } from "./types"
import * as styles from "./InputWrapper.styles"

export type InputWrapperProps = OverrideType<
    HTMLDivAttributes,
    {
        /** Increases the input height. */
        large?: boolean
        /** Makes the wrapper contents adapt to an error state. */
        hasError?: boolean
    }
>

export function InputWrapper({ className, children, hasError, large = false, ...rest }: InputWrapperProps) {
    return (
        <div
            className={cx(
                styles.inputWrapper,
                large && styles.inputWrapperLarge,
                hasError && styles.inputWrapperError,
                className
            )}
            draggable={false} // Makes sure draggable ancestors can't start dragging from within the input wrapper
            {...rest}
        >
            {children}
        </div>
    )
}
