export function safeName(name: string | null | undefined): string | null {
    if (!name || name.length === 0) return null
    if (name.match(/^[0-9]/)) {
        name = "$" + name
    }
    name = name.replace(/^[^A-Za-z_$]+/, "_").replace(/[^A-Za-z0-9_$]+/g, "_")
    if (name.length === 0) return null
    return name
}

export function safeProperty(property: string | null | undefined): string | null {
    if (!property || property.length === 0) return null
    if (property.match(/^[0-9]/)) {
        property = "$" + property
    }
    property = property
        .split(" ")
        .map(part => upperCaseFirstChar(part))
        .join("")
        .replace(/^[^A-Za-z_$]+/, "_")
        .replace(/[^A-Za-z0-9_$]+/g, "_")

    if (property.length === 0) return null
    return lowerCaseFirstChar(property)
}

export function safeComponentName(name: string | null | undefined): string {
    return upperCaseFirstChar(safeName(name) || "Component")
}

export function upperCaseFirstChar(name: string): string {
    return upperOrLowerCasefirstChar(name, "upper")
}

export function lowerCaseFirstChar(name: string): string {
    return upperOrLowerCasefirstChar(name, "lower")
}

function upperOrLowerCasefirstChar(name: string, type: "upper" | "lower"): string {
    if (name.length === 0) return name
    const first = name[0]
    const newFirst = type === "upper" ? first.toUpperCase() : first.toLowerCase()
    if (first === newFirst) return name
    return newFirst + name.slice(1)
}

export function camelCaseToCSS(key: string) {
    let result = ""
    for (const char of key) {
        if (/[a-zA-Z]/.test(char) && char.toUpperCase() === char) {
            result += `-${char.toLowerCase()}`
        } else {
            result += char
        }
    }
    return result
}

export function svgAttrToCamelCase(name: string) {
    const idx = name.includes(":") ? name.indexOf(":") : name.indexOf("-")

    if (idx !== -1) {
        return name.slice(0, idx) + name[idx + 1].toUpperCase() + name.slice(idx + 2)
    }

    return name
}
