import type { VectorNode, PathNode } from "document/models/CanvasTree"
import { withShape } from "document/models/CanvasTree/traits/Shape"

import { convertPathToParent } from "document/models/CanvasTree/utils/shapes"
import type { PathBooleanType } from "document/models/CanvasTree/traits/PathBoolean"
import { getPathOperator } from "utils/pathOperator"
import type { WithPaths } from "framer"

export namespace CompoundShape {
    export function sourcePaths(children: VectorNode[]) {
        const sourceSegments: WithPaths[] = []

        for (const child of children) {
            if (!withShape(child)) {
                // N.B.: Currently true for all vector nodes, might change in the future
                continue
            }

            const calculatedPaths = child.calculatedPaths()
            const convertedPaths = convertPathToParent(child as PathNode, calculatedPaths)

            sourceSegments.push(convertedPaths)
        }
        return sourceSegments
    }

    export function executePathBoolean(sourceSegments: WithPaths[], pathBoolean: PathBooleanType) {
        const result = getPathOperator().booleanJoin(sourceSegments, pathBoolean)
        return result
    }
}
