import * as React from "react"

export function IconConsoleClear() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
            <path
                d="M 1 4 L 11 4"
                fill="transparent"
                strokeWidth="1.5"
                stroke="currentColor"
                strokeLinecap="round"
            ></path>
            <path
                d="M 2.75 4.5 L 2.75 9.15 C 2.75 9.758 3.242 10.25 3.85 10.25 L 8.15 10.25 C 8.758 10.25 9.25 9.758 9.25 9.15 L 9.25 4.5"
                fill="transparent"
                strokeWidth="1.5"
                stroke="currentColor"
            ></path>
            <path
                d="M 3.75 4 L 3.75 2.75 C 3.75 1.645 4.645 0.75 5.75 0.75 L 6.25 0.75 C 7.355 0.75 8.25 1.645 8.25 2.75 L 8.25 4"
                fill="transparent"
                strokeWidth="1.5"
                stroke="currentColor"
            ></path>
        </svg>
    )
}
