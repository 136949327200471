import * as React from "react"

export function IconTickerUp() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="4">
            <path
                d="M 5.4 3.2 C 5.647 3.53 5.412 4 5 4 L 1 4 C 0.588 4 0.353 3.53 0.6 3.2 L 2.6 0.533 C 2.8 0.267 3.2 0.267 3.4 0.533 Z"
                fill="currentColor"
            ></path>
        </svg>
    )
}
