import { css } from "linaria"
import { colors, fonts } from "../tokens"

export const input = css`
    padding-right: 0;
`

export const inputLabel = css`
    width: auto;
    padding-right: 6px;
    color: ${colors.inputLabel};
    font-size: 9px;
    line-height: ${fonts.size.label}px;
    pointer-events: none;
`

export const inputLabelDisabled = css`
    color: ${colors.inputLabelDisabled};
`

export const numberTicker = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
`
