import type { VariableID } from "./Variables"
import { isObject } from "utils/typeChecks"

const variableReferenceKey: keyof VariableReference = "type"
const variableReferenceValue: VariableReference["type"] = "variableReference"

export interface VariableReference {
    readonly type: "variableReference"
    id: VariableID
}

export function isVariableReference(value: unknown): value is VariableReference {
    return isObject(value) && variableReferenceKey in value && value[variableReferenceKey] === variableReferenceValue
}
