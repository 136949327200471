import { css } from "linaria"

// This keeps the cursor the same no matter where we drag.
export const mouseTrackerOverlay = css`
    position: fixed;
    z-index: 2500;
    top: -100vh;
    left: -100vw;

    /* These are somehow overridden causing number tickers to break in unexpected ways. */
    width: 200vw !important;
    height: 200vh !important;
    opacity: 0;
    pointer-events: auto;
    transform: translate3d(0, 0, 0);
`

export const mouseTrackerDragging = css`
    pointer-events: auto;
`
