import { Map } from "immutable"
import assert from "assert"

import { ImmutableNode, MutableNode, USE_FREEZE } from "./nodes/MutableNode"
import { CanvasNode } from "./nodes/CanvasNode"
import { NodeID, randomID, MaybeNodeID, NullID } from "./nodes/NodeID"
import { isMaster } from "./traits/Template"

type WithChildren = {
    children: MutableNode[]
}

// uses node._cache.future: MutableNode to collect all changes and commit them all at once
export class NodeTree {
    readonly root: ImmutableNode
    readonly latest: { tree: NodeTree } // all copies of this tree will share this single object
    private idToNode: Map<NodeID, ImmutableNode>

    // mutable future
    private futures: MutableNode[] = []
    private futureIdToNode: Map<NodeID, ImmutableNode>
    private previousFutures: MutableNode[] = []

    // state, set during commit to catch API misuse. Making children mutable *is* allowed even if this is false.
    private allowChangingChildren = true

    // state, set by the document engine to catch API misuse
    editClosed = false

    // state, set to true when this tree is the active tree in Vekter, false otherwise, like in the sandbox.
    inEditor = false

    constructor(from?: NodeTree, root?: ImmutableNode) {
        if (from && root) {
            this.latest = from.latest
            this.root = root
            this.idToNode = from.futureIdToNode.asImmutable()
            this.inEditor = from.inEditor
            assert(this.root.cache.latest === this.latest, "Root cache must point to latest node tree")
        } else if (root) {
            this.latest = { tree: this }
            this.root = root
            this.idToNode = this.importNodes(root)
            assert(!root.mutable, "Root must not be mutable")
            assert(this.root.cache.latest === this.latest, "Root cache must point to latest node tree")
        } else {
            this.latest = { tree: this }
            this.root = this.newRootNode()
            this.idToNode = this.importNodes(this.root)
        }

        this.futureIdToNode = this.idToNode.asMutable()

        if (process.env.NODE_ENV !== "production") {
            this.verify()
        }
        this.latest.tree = this
        if (USE_FREEZE) {
            Object.freeze(this.idToNode)
        }
    }

    private importNodes(root: MutableNode): Map<NodeID, ImmutableNode> {
        root.parentid = NullID
        const idToNode = Map<NodeID, ImmutableNode>().asMutable()
        root.walk(n => {
            idToNode.set(n.id, n)
            this.own(n)
            n.freeze()
        })
        return idToNode.asImmutable()
    }

    static createWithRoot(node: ImmutableNode): NodeTree {
        return new NodeTree(undefined, node.cloneWithIds())
    }

    // root node factory
    protected newRootNode(): MutableNode {
        return new MutableNode()
    }

    protected copy(root: ImmutableNode): this {
        return new NodeTree(this, root) as this
    }

    makeLatest(): this {
        this.latest.tree = this
        return this.reset()
    }

    reset(): this {
        this.futureIdToNode = this.idToNode.asMutable()
        this.futures.length = 0
        this.forEachNode(n => (n.cache.future = null))
        if (process.env.NODE_ENV !== "production") {
            this.verify()
        }
        return this
    }

    isLatest(): boolean {
        return this.latest.tree === this
    }

    // all nodes inserted in the tree are owned by the tree, cannot be disowned because of undo etc.
    protected own<T extends MutableNode>(node: T): T {
        assert(node.mutable, "Node must be mutable to own")
        assert(this.isLatest(), "Must be latest tree to take ownership")
        assert(!node.cache.latest, "Node must not have a latest tree in cache")
        node.cache.latest = this.latest
        return node
    }

    newId(): NodeID {
        return randomID()
    }

    // Public

    size(): number {
        return this.futureIdToNode.size
    }

    current<T extends MutableNode>(node: T): T {
        return this.get(node.id)! as T
    }

    has(id: MaybeNodeID | undefined): boolean {
        if (!id) return false
        return this.futureIdToNode.has(id)
    }

    hasNode(node: ImmutableNode): boolean {
        return node.cache.latest === this.latest
    }

    // will return the latest, perhaps mutable and uncommitted node
    get(id: MaybeNodeID): ImmutableNode | null {
        if (!id) return null
        const node = this.futureIdToNode.get(id)
        if (!node) return null
        return node.futureOrCurrent()
    }

    getNodeAtStart(id: MaybeNodeID): ImmutableNode | null {
        if (!id) return null
        const node = this.idToNode.get(id)
        if (!node) return null
        return node
    }

    // returns the current node, if possible, the future uncommitted node otherwise
    getCurrentOrFuture(id: MaybeNodeID): ImmutableNode | null {
        if (!id) return null
        const node = this.idToNode.get(id) || this.futureIdToNode.get(id) || null
        if (!node) return null
        return node
    }

    // returns only the node as it will be committed in the future
    getFuture(id: MaybeNodeID): ImmutableNode | null {
        if (!id) return null
        const node = this.futureIdToNode.get(id)
        if (!node) return null
        if (node.cache.future) return node.cache.future
        return node
    }

    getParent(id: NodeID): ImmutableNode | null {
        const node = this.get(id)
        if (!node) return null
        return this.get(node.parentid!)
    }

    /** Iterates over all nodes, the iteration has no particular order. */
    forEachNode(cb: (node: MutableNode) => void): void {
        this.futureIdToNode.forEach(node => {
            if (!node) return
            cb(node.futureOrCurrent())
        })
    }

    /** Iterates over all nodes as in the tree at start, the iteration has no particular order. */
    forEachNodeAtStart(cb: (node: MutableNode) => void): void {
        this.idToNode.forEach(node => {
            if (!node) return
            cb(node)
        })
    }

    insertNode(node: MutableNode, parentid?: MaybeNodeID, position: number = -1): MutableNode {
        assert(this.allowChangingChildren, "Changing children must be allowed when inserting")

        // console.log("insert", node.id, parentid || this.root.id, position)
        node.parentid = parentid || this.root.id
        this.own(node)

        node.cache.future = node
        this.futures.push(node)
        assert(!this.futureIdToNode.has(node.id), "Node already inserted in future")
        this.futureIdToNode.set(node.id, node)

        const futureParent = this.primeParents(node)
        if (!futureParent) throw Error("assertion failed: future parent does not exist")
        if (position < 0) {
            position += futureParent.children.length + 1
        }
        futureParent.children.splice(position, 0, node)

        const children = node.children
        if (!children) return node
        for (let i = 0, il = children.length; i < il; i++) {
            this.insertInternalNode(children[i], node.id)
        }
        return node
    }

    insertNodes(nodes: MutableNode[]): this {
        for (let i = 0, il = nodes.length; i < il; i++) {
            const node = nodes[i]
            this.insertNode(node, node.parentid)
        }
        return this
    }

    private insertInternalNode(node: MutableNode, parentid: NodeID): void {
        // console.log("insertInternal", node.id, parentid)
        node.parentid = parentid
        this.own(node)

        node.cache.future = node
        this.futures.push(node)
        this.futureIdToNode.set(node.id, node)

        const children = node.children
        if (!children) return
        for (let i = 0, il = children.length; i < il; i++) {
            this.insertInternalNode(children[i], node.id)
        }
    }

    move(id: NodeID, parentid: NodeID | null, position?: number): this {
        this.moveNode(this.get(id)!, parentid || this.root.id, position)
        return this
    }

    moveNode(node: ImmutableNode, parentid: NodeID, position: number = -1): MutableNode {
        node = node.futureOrCurrent()
        assert(this.allowChangingChildren, "Changing children must be allowed when moving")
        assert(!node.originalid || (node as any).replicaInfo, "Cannot move nodes of replica's")

        if (node.parentid === parentid) {
            return this.moveNodeIndex(node, position)
        }

        // console.log("move", node.id, node.parentid, "to", parentid, position)
        assert(node && this.futureIdToNode.has(node.id), "Node does not exist or is unknown")
        assert(!this.nodeIsAncestorOf(node, parentid), "Node must not be an ancestor of its new parent")

        // remove child from current parent
        if (node.parentid) {
            const mutableParent = this.mutableFutureParent(node)
            const children = mutableParent.children!
            const index = children.findIndex(c => c.id === node.id)
            assert(index >= 0, "Node must be known as child")
            children.splice(index, 1)
        }

        const mutable = this.mutableFutureNode(node)
        mutable.parentid = parentid
        const futureParent = this.primeParents(mutable)
        if (!futureParent) throw Error("assertion failed: parentid must exist")
        assert(futureParent.children, "Future parent must accept children")
        if (position < 0) {
            position += futureParent.children.length + 1
        }
        futureParent.children.splice(position, 0, mutable)
        return mutable
    }

    moveNodeIndex(node: ImmutableNode, position: number = -1): MutableNode {
        // console.log("moveIndex", node.id, node.parentid, "to", position)
        const futureParent = this.mutableFutureParent(node)
        const children = futureParent.children
        if (position < 0) {
            position += children.length + 1
        }

        const mutable = this.mutableFutureNode(node)
        const index = children.findIndex(c => c.id === node.id)
        assert(index >= 0, "Node to move not found in parent")
        if (index === position) return mutable

        futureParent.children.splice(index, 1)
        futureParent.children.splice(position, 0, mutable)

        return mutable
    }

    update(id: NodeID, properties: any): this {
        this.updateNode(this.get(id)!, properties)
        return this
    }

    updateNode(node: ImmutableNode, properties: any): MutableNode {
        assert(!this.editClosed, "Editing must not be closed")
        return node.set(properties, this)
    }

    updateNodes(nodes: ImmutableNode[], properties: any) {
        for (let i = 0, il = nodes.length; i < il; i++) {
            this.updateNode(nodes[i], properties)
        }
    }

    removeNode(node: ImmutableNode): void {
        node = node.futureOrCurrent()
        assert(this.allowChangingChildren, "Changing children must be allowed when removing")
        assert(!node.originalid || (node as any).replicaInfo, "Cannot delete nodes of replica's")

        // console.log("remove", node.id, node.parentid)
        assert(node.parentid, "Node must have a parent id")

        // remove child from current parent
        const mutableParent = this.mutableFutureParent(node)
        const children = mutableParent.children!
        let index = children.indexOf(node)
        if (index === -1) {
            // if the node is not found, try finding it by id
            index = children.findIndex(n => n.id === node.id)
        }
        // if not actually in parent, we assume there was a double delete
        if (index !== -1) {
            children.splice(index, 1)
        }

        // remove this and children from idToNode index and tree
        this.removeInternalNode(node, node.parentid!)
    }

    private removeInternalNode(node: ImmutableNode, parentid: NodeID): void {
        // console.log("remove internal", node.id)
        node = this.mutableFutureNode(node)
        if (node.parentid !== parentid) return

        const children = node.children
        if (children) {
            for (let i = 0, il = children.length; i < il; i++) {
                this.removeInternalNode(children[i], node.id)
            }
        }
        this.futureIdToNode.remove(node.id)
    }

    // when modifying the tree outside of normal API, can use this for new inserts
    unsafeInsertNode(node: MutableNode, parentid: NodeID) {
        assert(this.allowChangingChildren, "Changing children must be allowed when inserting")
        assert(node.parentid === parentid, "Parentid must have been set correctly")

        if (!node.cache.latest) {
            this.own(node)
        }
        if (node.mutable && !this.futureIdToNode.has(node.id)) {
            node.cache.future = node
            this.futures.push(node)
            this.futureIdToNode.set(node.id, node)
        }
        const children = node.children
        if (!children) return
        for (let i = 0, il = children.length; i < il; i++) {
            this.unsafeInsertNode(children[i], node.id)
        }
    }

    // when modifying the tree outside of normal API, can use this for removed nodes
    unsafeRemoveId(id: NodeID): void {
        this.futureIdToNode.remove(id)
    }

    // when modifying the tree outside of normal API
    unsafeGetFutures(): MutableNode[] {
        return this.futures
    }

    remove(id: NodeID): this {
        const node = this.futureIdToNode.get(id)
        if (!node) return this
        this.removeNode(node)
        return this
    }

    hasUncommittedChanges(): boolean {
        return this.futures.length > 0
    }

    /** Takes all the changes done against this tree, processes them, and build
     * a new tree that is a copy-on-write version of this one. */
    commit(onNodeChanged?: (previous: MutableNode | undefined, next: MutableNode | undefined) => void): this {
        // console.log("commit")
        assert(this.isLatest(), "Tree must be latest to commit")
        const futureRoot = this.root.cache.future
        if (futureRoot === null) {
            return this // No changes
        }

        // console.log(this.futures.map(n => n.id))
        assert(futureRoot, "Tree must have a future root to commit")
        assert(futureRoot.mutable, "Tree must have a mutable future root to commit")
        const futures = this.futures

        // also scan all futures for new template nodes
        // note, futures can grow as we iterate, for templates and such, bounded by tree.size
        for (let i = 0; i < futures.length; i++) {
            const future = futures[i]
            if (this.futureIdToNode.get(future.id) !== future) continue
            future.preCommit(this)
        }

        const futureslength = futures.length
        // first update all uncommitted nodes, might change parent.children
        for (let i = 0, il = futureslength; i < il; i++) {
            const future = futures[i]
            if (this.futureIdToNode.get(future.id) !== future) continue

            if (future !== futureRoot) {
                const parent = this.getMutableParent(future)!
                this.swapChild(parent, future)
            }
        }

        // just before freezing
        this.allowChangingChildren = false
        for (let i = 0, il = futureslength; i < il; i++) {
            const future = futures[i]
            if (this.futureIdToNode.get(future.id) !== future) continue
            future.preFreeze(this)
        }

        for (let i = futureslength; i < futures.length; i++) {
            const future = futures[i]
            if (this.futureIdToNode.get(future.id) !== future) continue

            if (future !== futureRoot) {
                const parent = this.getMutableParent(future)!
                this.swapChild(parent, future)
            }
        }

        // as a second round, freeze all nodes
        for (let i = 0, il = futures.length; i < il; i++) {
            const future = futures[i]

            const latest = this.futureIdToNode.get(future.id)
            if (!latest) {
                // Signal the node was deleted.
                onNodeChanged?.(this.idToNode.get(future.id), undefined)
                continue
            }

            // Happens when a node was removed then added again.
            if (latest !== future) continue

            future.freeze()
            onNodeChanged?.(this.idToNode.get(future.id), future)
        }

        // the last two rounds should not have changed futures anymore
        // assert(futureslength === futures.length)

        this.allowChangingChildren = true
        if (process.env.NODE_ENV !== "production" && window["perf"]) window["perf"].nodeUpdated(futures.length)

        assert(!futureRoot.mutable, "Future root must not be mutable anymore")
        assert(!futureRoot.cache.future, "Future root should not have a future cache anymore")
        const newTree = this.copy(futureRoot)
        this.previousFutures = this.futures.slice()
        futures.length = 0

        return newTree
    }

    /** returns all the nodes that were changed by the previous call to commit()
     * on this tree */
    getNodesChangedByCommit(): MutableNode[] {
        return this.previousFutures
    }

    // Private

    verify(): this {
        const count = this.verifyNode(this.root)
        assert(this.idToNode.size === count, "Node count must be equal to expected tree size")
        return this
    }

    private verifyNode(node: ImmutableNode) {
        assert(!node.mutable, "Node must not be mutable to verify")
        assert(!node.cache.future, "Node must not have a future to verify")
        if (USE_FREEZE) assert(Object.isFrozen(node), "Node object must be frozen")
        if (USE_FREEZE) assert(Object.isFrozen(node.children), "Node children must be frozen")
        assert(this.get(node.id) === node, "Node must exist: " + node.id)

        const n = node as any
        assert(!n.replicaInfo || typeof n.replicaInfo.master === "string", "replicaInfo.master != string")
        assert(!n.guidesX || typeof n.guidesX.union === "function", "guidesX != Set")
        assert(!n.styledText || n.styledText.__class === "StyledTextDraft", "styledText != StyledTextDraft")

        let count = 1
        const children = node.children
        if (children) {
            for (let i = 0, il = children.length; i < il; i++) {
                const child = children[i]
                assert(child.parentid === node.id, "Parent id of children must point back correctly")
                count += this.verifyNode(child)
            }
        }

        return count
    }

    mutableFutureNode(node: ImmutableNode): MutableNode {
        let future = node.cache.future
        if (future) return future

        assert(!this.editClosed, "Editing must not be closed")

        if (node.mutable) {
            future = node as MutableNode
        } else {
            assert(this.get(node.id) === node, "Node must be known and up-to-date")
            future = Object.assign(Object.create(node.constructor.prototype), node) as MutableNode
            future.mutable = true
            future.update += 1
            const children = future.children
            if (children) {
                future.children = children.slice()
            }
        }
        node.cache.future = future

        assert(node.cache.latest === this.latest, "Node cache must point to latest tree")
        assert(node.id === future.id && node.parentid === future.parentid, "Future ids must equal current ids")
        this.futures.push(future)
        this.futureIdToNode.set(future.id, future)
        if (future.parentid) {
            this.primeParents(future)
        }
        return future
    }

    private mutableFutureParent(node: ImmutableNode): MutableNode & WithChildren {
        assert(node.parentid, "Node must have a parent id")
        const parent = this.futureIdToNode.get(node.parentid!)
        assert(parent, "Node must have a known parent")
        return this.mutableFutureNode(parent) as MutableNode & WithChildren
    }

    private primeParents(child: MutableNode): (MutableNode & WithChildren) | undefined {
        assert(child.mutable, "Node must be mutable to prime parents")
        const parentid = child.parentid
        if (child.id === this.root.id) return
        const parent = this.futureIdToNode.get(parentid!)
        assert(parent, "Node parent must exist to prime")
        if (parent.cache.future) return parent.cache.future as MutableNode & WithChildren

        const mutableParent = this.mutableFutureNode(parent)
        this.primeParents(mutableParent)

        return mutableParent as MutableNode & WithChildren
    }

    private getMutableParent(node: ImmutableNode) {
        if (node.id === this.root.id) return null

        const parent = this.futureIdToNode.get(node.parentid!)
        assert(parent, "Parent must exist")
        assert(parent!.cache.future, "Parent must have a future")
        return parent!.cache.future
    }

    // replaces node with node.cache.future in node.parent.children list
    // any child movements in the tree always keep node.parent.future.children lists updated
    private swapChild(mutableParent: MutableNode, future: ImmutableNode) {
        assert(mutableParent.mutable, "Parent must be mutable to swap a child")
        assert(future.parentid === mutableParent.id, "Parent id pointer must be correct")
        const children = mutableParent.children!
        for (let i = 0, il = children.length; i < il; i++) {
            const child = children[i]
            if (child.id === future.id) {
                children[i] = future
                return
            }
        }
        assert(false, "child.cache.future not in parent.cache.future.children")
    }

    private nodeIsAncestorOf(node: ImmutableNode, nodeId: NodeID): boolean {
        let checkNode: ImmutableNode = this.get(nodeId)!
        assert(checkNode, "Node to check must exist")
        while (checkNode.id !== this.root.id) {
            if (checkNode.id === node.id) return true
            checkNode = this.get(checkNode.parentid)!
            assert(checkNode, "Ancestor node must exist")
        }
        if (checkNode.id === node.id) return true
        return false
    }

    clearCache() {
        this.futures = []
        this.futureIdToNode = this.idToNode.asMutable()
        this.root.walk(n => n.cache.reset())
    }

    /** Given a list of node id's, returns a list of nodes, ignoring any id that
     * does not exist in the tree. */
    getNodes(ids: NodeID[]): ImmutableNode[] {
        const result = []

        for (let i = 0, il = ids.length; i < il; i++) {
            const node = this.get(ids[i])
            if (!node) continue
            result.push(node)
        }
        return result
    }

    /** Given a list of potentially old nodes, returns a new list with the
     * latest versions, removing any node that no longer exists in the tree. */
    getLatestNodes<T extends ImmutableNode>(nodes: T[]): T[] {
        const result = []

        for (let i = 0, il = nodes.length; i < il; i++) {
            const node = this.get(nodes[i].id)
            if (!node) continue
            result.push(node)
        }

        return result as T[]
    }

    withoutDescendants(nodes: ImmutableNode[]): ImmutableNode[] {
        const result: ImmutableNode[] = []

        const set = new Set<NodeID>()
        for (let i = 0, il = nodes.length; i < il; i++) {
            set.add(nodes[i].id)
        }
        for (let i = 0, il = nodes.length; i < il; i++) {
            const node = nodes[i]

            let parentInSet = false
            let parentid = node.parentid
            while (parentid) {
                if (set.has(parentid)) {
                    parentInSet = true
                    break
                }
                parentid = this.get(parentid)!.parentid
            }
            if (parentInSet) continue

            result.push(node)
        }
        return result
    }

    // takes a list of randomly ordered nodes, and will return the topmost child node
    // similar to sortVisually(nodes)[-1]
    getTopMostChildNode(nodes: ImmutableNode[]): ImmutableNode | null {
        // collect all passed in nodes and all their parents
        const markedNodes = new Set<NodeID>()
        for (let i = 0, il = nodes.length; i < il; i++) {
            let node = nodes[i]
            markedNodes.add(node.id)

            while (node.parentid && !markedNodes.has(node.parentid)) {
                markedNodes.add(node.parentid)
                node = this.get(node.parentid)!
            }
        }

        // we are looking, recursively, for the last marked child
        let parent: ImmutableNode = this.root
        for (;;) {
            const children = parent.children
            if (!children) return parent === this.root ? null : (parent as ImmutableNode)
            let lastChild: ImmutableNode | null = null
            for (let i = 0, il = children.length; i < il; i++) {
                const child = children[i]
                if (markedNodes.has(child.id)) {
                    lastChild = child
                }
            }
            if (!lastChild) return parent === this.root ? null : (parent as ImmutableNode)
            parent = lastChild
        }
    }

    getAncestors(id: NodeID): NodeID[] {
        const result: NodeID[] = []

        let node = this.get(id)
        if (!node) throw Error("unknown node")

        node = this.get(node.parentid)
        while (node && node.id !== this.root.id) {
            result.push(node.id)
            node = this.get(node.parentid)
        }

        return result
    }

    ancestors(id: NodeID): ImmutableNode[] {
        const result: ImmutableNode[] = []

        let node = this.get(id)
        if (!node) throw Error("Unknown node")

        node = this.get(node.parentid)
        while (node) {
            result.push(node)
            node = this.get(node.parentid)
        }

        return result
    }

    dump(): string {
        const result: string[] = []
        this.dumpNode(result, this.root, "")
        return result.join("\n")
    }

    dumpNode(result: string[], immutableNode: ImmutableNode, spacing: string) {
        const node = immutableNode.futureOrCurrent()
        let mark = ""
        if (node instanceof CanvasNode && isMaster(node)) {
            mark = " m"
        } else if (node.replicaInfo) {
            mark = " r"
        } else if (node.originalid) {
            mark = " o"
        }
        result.push(spacing + node.id + " - " + node.__class + mark)

        const children = node.children
        if (!children) return
        children.forEach((c: ImmutableNode) => this.dumpNode(result, c, spacing + "  "))
    }
}
