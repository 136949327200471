import { Record } from "immutable"
import { withClassDiscriminator } from "../utils/withClassDiscriminator"
import { DevicePresetID } from "@framerjs/framer-runtime/components/Device"

export interface PreviewMetadata {
    height?: number
    id?: string
    name?: string
    responsive: boolean
    canChangeResponsive: boolean
    touchCursor: boolean
    highlights: boolean
    selectionLock: boolean
    settings: PreviewSettingsProps
    title?: string
    width?: number
}

export interface BackgroundPreviewSettings {
    backgroundEnabled?: boolean
    backgroundType?: "color" | "image"
    backgroundColor?: string
    backgroundImage?: string
    backgroundImageResize?: "fill" | "fit" | "stretch"
}

export interface PreviewSettingsProps extends BackgroundPreviewSettings {
    responsive: boolean
    touch: boolean

    // Device preset configured by the user. If this is undefined, we might
    // still show a default device based on the frame preset. For that code,
    // see devicePresetForNode in deviceConfigForNode.ts.
    devicePreset?: DevicePresetID | "no-device"
    deviceSkin?: "realistic" | "clay"
    // Defines which color to use for _clay_ device
    deviceTheme?: "dark" | "light"
    // Defines which image to use for _realistic_ device
    deviceColorId?: string
    deviceHand?: string
    // TODO: Remove? We don't have a shadow setting anymore.
    deviceShadow?: boolean

    // Legacy setting, but still used to detect frames with legacy device
    // settings in order to trigger the "Devices have moved" dialog.
    skin?: string

    // Legacy settings.
    // deviceType?: string
    // deviceDescriptor?: DeviceDescriptor
    // hand?: string
    // zoom: number
    // rotate: boolean
    // bezel: boolean
}

export const PreviewSettingsDefaults: PreviewSettingsProps = {
    responsive: false,
    touch: false,

    devicePreset: undefined,
    deviceSkin: undefined,
    deviceTheme: undefined,
    deviceColorId: undefined,
    deviceHand: undefined,
    deviceShadow: undefined,

    backgroundEnabled: undefined,
    backgroundType: undefined,
    backgroundColor: undefined,
    backgroundImage: undefined,
    backgroundImageResize: undefined,

    skin: undefined,
}

export const PreviewSettingsRecord = Record(PreviewSettingsDefaults)

export class PreviewSettings extends withClassDiscriminator("PreviewSettings", PreviewSettingsRecord)
    implements PreviewSettingsProps {
    responsive: boolean
    touch: boolean

    devicePreset?: DevicePresetID | "no-device"
    deviceSkin?: "realistic" | "clay"
    deviceTheme?: "dark" | "light"
    deviceColorId?: string
    deviceHand?: string
    deviceShadow?: boolean

    backgroundEnabled?: boolean
    backgroundType?: "color" | "image"
    backgroundColor?: string
    backgroundImage?: string
    backgroundImageResize?: "fill" | "fit" | "stretch"

    skin?: string

    toJS(): PreviewSettingsProps {
        const JS = super.toJS()
        JS["__class"] = this.__class
        return JS
    }

    toJSON() {
        return this.toJS()
    }
}
