export interface DimensionTokens {
    readonly panelMinWidth: number
    readonly panelPadding: number
    readonly panelHeaderHeight: number
    readonly panelSectionHeaderHeight: number

    // Content
    readonly contentHeaderPrimaryHeight: number
    readonly contentHeaderHeight: number
    readonly contentItemHeight: number
    readonly contentItemRadius: number
    readonly contentItemIndention: number
    readonly contentItemPadding: number
    readonly contentItemInputPadding: number
    readonly contentItemInputRadius: number

    // Canvas
    readonly canvasTooltipHeight: number

    // NUX (new user experience / empty state)
    readonly nuxRadius: number

    // Floating Window
    readonly floatingWindowRadius: number
    readonly floatingWindowToolbarHeight: number

    // Input
    readonly inputSpacing: number
    readonly inputHeight: number
    readonly inputHeightLarge: number
    readonly inputRadius: number
    readonly checkboxRadius: number
    readonly checkboxRadiusLarge: number
    readonly inputPaddingHorizontal: number
    readonly inputPaddingHorizontalLarge: number
    readonly inputDividerHeight: number
    readonly textAreaPaddingVertical: number

    readonly tabBarHeight: number

    readonly tooltipArrowHeight: number
    readonly tooltipArrowWidth: number

    readonly popoutIconSize: number

    // Properties Panel
    readonly propertiesSingleColumnWidth: number
    readonly propertiesDoubleColumnWidth: number

    // Packages
    readonly packageIconSize: number

    // Toasts
    readonly toastHeight: number
    readonly toastGap: number

    // Toolbar
    readonly toolSize: number
    readonly toolSizeWithLabel: number
    readonly toolDividerWidth: number
    readonly toolMargin: number
    readonly avatarSize: number
    readonly avatarSizeSmall: number

    // Insert Menu
    readonly insertMenuHeight: number
    readonly insertMenuHeightAssets: number
    readonly insertMenuWidth: number
    readonly insertMenuSearchBarHeight: number
    readonly insertMenuPreviewWidth: number
    readonly insertMenuPreviewHeight: number
    readonly insertMenuPreviewPadding: number

    // Code
    readonly codeEditorMinWidth: number

    readonly webBarHeight: number

    // Ruler
    readonly rulerWidth: number
    readonly rulerTickLength: number
    readonly rulerMaskWidth: number

    // Status Bar
    readonly statusBarHeight: number
}

export const dimensions: DimensionTokens = {
    inputSpacing: 10,
    panelMinWidth: 260,
    panelPadding: 15,
    panelHeaderHeight: 48,
    panelSectionHeaderHeight: 48,

    // Content
    contentHeaderPrimaryHeight: 48,
    contentHeaderHeight: 20,
    contentItemHeight: 29,
    contentItemRadius: 6,
    contentItemIndention: 16,
    contentItemPadding: 2,
    contentItemInputPadding: 2,
    contentItemInputRadius: 3,

    // Canvas
    canvasTooltipHeight: 30,

    // NUX (new user experience / empty state)
    nuxRadius: 10,

    // Floating Window
    floatingWindowRadius: 10,
    floatingWindowToolbarHeight: 40,

    // Input
    inputHeight: 26,
    inputHeightLarge: 30,
    inputRadius: 5,
    checkboxRadius: 3,
    checkboxRadiusLarge: 4,
    inputPaddingHorizontal: 8,
    inputPaddingHorizontalLarge: 10,
    inputDividerHeight: 14,
    textAreaPaddingVertical: 4,

    tabBarHeight: 48,

    tooltipArrowHeight: 8,
    tooltipArrowWidth: 28,

    popoutIconSize: 18,

    // Properties Panel
    propertiesSingleColumnWidth: 62,
    propertiesDoubleColumnWidth: 134,

    // Packages
    packageIconSize: 30,

    // Toasts
    toastHeight: 40,
    toastGap: 10,

    // Toolbar
    toolSize: 26,
    toolSizeWithLabel: 36,
    toolDividerWidth: 30,
    toolMargin: 14,
    avatarSize: 30,
    avatarSizeSmall: 18,

    // Insert Menu
    insertMenuHeight: 448,
    insertMenuHeightAssets: 540,
    insertMenuWidth: 680,
    insertMenuSearchBarHeight: 50,
    insertMenuPreviewWidth: 360,
    insertMenuPreviewHeight: 250,
    insertMenuPreviewPadding: 30,

    // Code
    codeEditorMinWidth: 400,

    webBarHeight: 60,

    // Ruler
    rulerWidth: 20,
    rulerTickLength: 5,
    rulerMaskWidth: 40,

    // Status Bar
    statusBarHeight: 24,
}
