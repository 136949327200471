import type { CanvasTree } from "../../CanvasTree"
import { CanvasNode } from "../CanvasNode"
import { getBooleanShapeRecord } from "document/models/CanvasTree/records/shapes/BooleanShapeRecord"
import type { PathBooleanType, WithPathBoolean } from "document/models/CanvasTree/traits/PathBoolean"
import { CompoundShape } from "document/models/CanvasTree/traits/utils/compoundShape"
import { withFrameForShape } from "document/models/CanvasTree/traits/mixins/withFrameForShape"
import { withBaseShapeTraits } from "document/models/CanvasTree/traits/mixins/withBaseShapeTraits"
import type { DrawableNode, VectorNode } from "document/models/CanvasTree"
import { setDefaults } from "../MutableNode"
import type { WithPaths, WithShape } from "framer"
import { withClassDiscriminator } from "utils/withClassDiscriminator"

export function isBooleanShapeNode(node: CanvasNode): node is DrawableNode & BooleanShapeNode {
    return node instanceof BooleanShapeNode
}

export default class BooleanShapeNode
    extends withClassDiscriminator("BooleanShapeNode", withFrameForShape(withBaseShapeTraits(CanvasNode)))
    implements WithShape, WithPathBoolean {
    children: VectorNode[]
    pathBoolean: PathBooleanType

    constructor(properties?: Partial<BooleanShapeNode>) {
        super()
        setDefaults<BooleanShapeNode>(this, getBooleanShapeRecord(), properties)
    }

    calculatedPaths(): WithPaths {
        const cache = this.cache
        if (cache.calculatedPaths) return cache.calculatedPaths

        const sourceSegments = CompoundShape.sourcePaths(this.children)
        cache.calculatedPaths = CompoundShape.executePathBoolean(sourceSegments, this.pathBoolean)
        return cache.calculatedPaths
    }

    normalizedPath() {
        this.calculatedPaths()
    }

    preCommit(tree: CanvasTree) {
        if (this.isEmptyGroup()) {
            tree.removeNode(this)
        } else {
            super.preCommit(tree)
        }
    }
}
