import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springAnimation } from "./utils"
import { colors } from "../tokens"

const width = 50
const height = 50

export const AnimationPackages = React.memo(function AnimationPackages() {
    const download = useAnimation()

    async function animation() {
        await download.start({
            scale: 0.8,
            opacity: 1,
            y: 0,
            transition: springAnimation,
        })
        await download.start({
            scale: 0.6,
            opacity: 0.5,
            transition: springAnimation,
        })
        await download.start({
            scale: 0.8,
            opacity: 1,
            transition: springAnimation,
        })
        await download.start({
            y: 80,
            transition: springAnimation,
        })
        return await download.start({
            y: -80,
            transition: { type: false },
        })
    }

    useLoop(animation)

    return (
        <div
            style={{
                position: "relative",
                overflow: "hidden",
                width: width,
                height: height,
                borderRadius: 8,
                background: colors.animationBackgroundLight,
            }}
        >
            <motion.div animate={download}>
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50">
                    <path
                        d="M 25 10 C 33.284 10 40 16.716 40 25 C 40 33.284 33.284 40 25 40 C 16.716 40 10 33.284 10 25 C 10 16.716 16.716 10 25 10 Z"
                        fill={colors.animationForground}
                    ></path>
                    <g transform="translate(21 19)">
                        <path
                            d="M 4 0 L 4 12"
                            fill="transparent"
                            strokeWidth="2"
                            stroke={colors.animationForgroundDimmed}
                            strokeLinecap="round"
                        ></path>
                        <path
                            d="M 4 12 L 0 8"
                            fill="transparent"
                            strokeWidth="2"
                            stroke={colors.animationForgroundDimmed}
                            strokeLinecap="round"
                        ></path>
                        <path
                            d="M 4 12 L 8 8"
                            fill="transparent"
                            strokeWidth="2"
                            stroke={colors.animationForgroundDimmed}
                            strokeLinecap="round"
                        ></path>
                    </g>
                </svg>
            </motion.div>
        </div>
    )
})
