import { RootNode } from "./RootNode"
import FrameNode from "./FrameNode"
import ShapeContainerNode from "./ShapeContainerNode"
import PathNode from "./shapes/PathNode"
import BooleanShapeNode from "./shapes/BooleanShapeNode"
import { SVGNode } from "./SVGNode"
import { TextNode } from "./TextNode"
import { ExportOptions } from "./ExportOptions"
import RectangleShapeNode from "./shapes/RectangleShapeNode"
import OvalShapeNode from "./shapes/OvalShapeNode"
import PolygonShapeNode from "./shapes/PolygonShapeNode"
import StarShapeNode from "./shapes/StarShapeNode"
import ShapeGroupNode from "./ShapeGroupNode"
import { PathSegment } from "framer"
import CodeComponentNode from "./CodeComponentNode"
import { LinearGradient, RadialGradient } from "document/models/Gradient"
import { GradientColorStop } from "document/models/GradientColorStop"
import { ColorTokenNode } from "./ColorTokenNode"
import { PreviewSettings } from "preview-next/PreviewSettings"
import { PageNode } from "./PageNode"
import { CanvasComponentNode } from "./CanvasComponentNode"
import { BoxShadow, Shadow } from "document/models/ShadowClass"

type ArrayElement<T extends readonly unknown[]> = T[number]

type CanvasNodeSubclass = ArrayElement<ReturnType<typeof getNodeSubclassList>>
function getNodeSubclassList() {
    return [
        RootNode,
        FrameNode,
        ShapeContainerNode,
        PageNode,
        PathNode,
        BooleanShapeNode,
        SVGNode,
        TextNode,
        RectangleShapeNode,
        OvalShapeNode,
        PolygonShapeNode,
        StarShapeNode,
        ShapeGroupNode,
        CanvasComponentNode,
        CodeComponentNode,
        ColorTokenNode,
    ]
}

type ImmutableRecordSubclass = ArrayElement<ReturnType<typeof getImmutableRecordSubclassList>>
function getImmutableRecordSubclassList() {
    return [LinearGradient, RadialGradient, GradientColorStop, ExportOptions, PathSegment, PreviewSettings]
}

type PojoClassList = ArrayElement<ReturnType<typeof getPojoObjectList>>
function getPojoObjectList() {
    return [Shadow.create({ id: "__shadow__" }), BoxShadow.create({ id: "__boxshadow__" })]
}

let classList: (CanvasNodeSubclass | ImmutableRecordSubclass)[]
let classMap: Record<string, ArrayElement<typeof classList>>
let nodeSubclassMap: Record<string, CanvasNodeSubclass>
let nodeDefaultsMap: Record<string, InstanceType<CanvasNodeSubclass>>
let immutableRecordSubclassMap: Record<string, ImmutableRecordSubclass>
let pojoObjectMap: Record<string, PojoClassList>

export function getClassList() {
    if (!classList) {
        precomputeClassMaps()
    }

    return classList
}

export function getClassByClassName(className: string) {
    if (!classMap) {
        precomputeClassMaps()
    }

    return classMap[className]
}

export function getNodeSubclassByClassName(className: string) {
    if (!nodeSubclassMap) {
        precomputeClassMaps()
    }

    return nodeSubclassMap[className]
}

export function getNodeDefaultsByClassName(className: string) {
    if (!nodeDefaultsMap) {
        precomputeClassMaps()
    }

    return nodeDefaultsMap[className]
}

export function getImmutableRecordSubclassByClassName(className: string) {
    if (!immutableRecordSubclassMap) {
        precomputeClassMaps()
    }

    return immutableRecordSubclassMap[className]
}

export function getPojoObjectByName(className: string) {
    if (!immutableRecordSubclassMap) {
        precomputeClassMaps()
    }

    return pojoObjectMap[className]
}

// We precompute and cache classList and classMaps since get* functions
// are often called in loops (through canvasNodeFromValue())
function precomputeClassMaps() {
    classList = [...getNodeSubclassList(), ...getImmutableRecordSubclassList()]
    classMap = {}
    nodeSubclassMap = {}
    nodeDefaultsMap = {}
    immutableRecordSubclassMap = {}

    for (const classType of classList) {
        if (!classType) continue
        classMap[classType.prototype.__class] = classType

        if (isImmutableRecordSubclass(classType)) {
            immutableRecordSubclassMap[classType.prototype.__class] = classType
        }

        if (!isNodeSubclass(classType)) continue

        nodeSubclassMap[classType.prototype.__class] = classType
        nodeDefaultsMap[classType.prototype.__class] = new classType({ id: "_DEFAULT_" })
    }

    pojoObjectMap = {}
    for (const defaults of getPojoObjectList()) {
        pojoObjectMap[defaults.__class] = defaults
    }
}

function isImmutableRecordSubclass(classType: ArrayElement<typeof classList>): classType is ImmutableRecordSubclass {
    return classType.prototype.__class !== "StyledTextDraft" && !isNodeSubclass(classType)
}

function isNodeSubclass(classType: ArrayElement<typeof classList>): classType is CanvasNodeSubclass {
    return classType.prototype.__class.endsWith("Node")
}
