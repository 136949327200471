import type {
    ComponentType,
    ComponentClass,
    FunctionComponent,
    ExoticComponent,
    ForwardRefExoticComponent,
    MemoExoticComponent,
} from "react"
import type { EntityIdentifier } from "../../host"
import { BUILT_IN_COMPONENT_IDENTIFIERS } from "./BuiltInFramerComponentIdentifier"

// Components like the progress indicator use `width` and `height` in a way
// that's hard to detect just by parsing their source (they basically pass props
// through other functions and don't even mention width/height). For them, we
// have an explicit allow list.
const KNOWN_COMPONENT_IDENTIFIERS_NEEDING_COMPATIBILITY = new Set(["@framer/framer.default/./Progress.tsx_Progress"])

const ARGS_REGEX = /^(?:function)?\s*[^(\s]*\s*\(([^)]*)\)/m
const WIDTH_HEIGHT_REGEX = /\b(width|height)\b/
const WIDTH_HEIGHT_IN_PROPS_DESTRUCTURING_REGEX = /(const|let|var)\{[^}]*\b(width|height)\b[^}]*\}=((this\.)?props|rest)/
const WIDTH_HEIGHT_FROM_PROPS_OBJECT_REGEX = /(props|rest)(\.|\[("|'))(width|height)/

export function isComponentUsingDeprecatedSizeProps(
    component: (ComponentType<unknown> | ExoticComponent) & { _framerUsesDeprecatedSizeProps?: boolean },
    identifier: EntityIdentifier
) {
    // Early return for components we know are going to need a compatibility
    // wrapper, even if the heuristics below fail to determine there's a need
    if (KNOWN_COMPONENT_IDENTIFIERS_NEEDING_COMPATIBILITY.has(identifier)) return true

    // Skip built-ins
    if (BUILT_IN_COMPONENT_IDENTIFIERS.includes(identifier)) return false

    const renderFunction = extractRenderFunctionFromComponent(component)
    if (!renderFunction) return

    const source = Function.prototype.toString.call(renderFunction)

    // Check for width / height in props destructuring in args
    const argsMatch = source.match(ARGS_REGEX)
    if (!argsMatch) return false

    const args = argsMatch[1]

    const usedInArgs = args && WIDTH_HEIGHT_REGEX.test(args)
    if (usedInArgs) return true

    // Check for props.width / height in body
    const body = source
        .slice(argsMatch.index + argsMatch[0].length)
        .replace(/[/][/].*$/gm, "") // strip single-line comments
        .replace(/\s+/g, "") // strip white space
        .replace(/[/][*][^/*]*[*][/]/g, "") // strip multi-line comments

    if (!body) return false

    return WIDTH_HEIGHT_FROM_PROPS_OBJECT_REGEX.test(body) || WIDTH_HEIGHT_IN_PROPS_DESTRUCTURING_REGEX.test(body)
}

// Tries to guess which function represents the render function for this
// component. Has rudementary support for memo / forwardRef components, but the
// goal isn't to be exhaustive, but to support the most common configurations only.
function extractRenderFunctionFromComponent(component: ComponentType<unknown> | ExoticComponent): Function | null {
    if (isComponentClass(component)) {
        return component.prototype.render
    }

    if (isFunctionComponent(component)) {
        return component
    }

    if (isMemoExoticComponent(component)) {
        return extractRenderFunctionFromComponent(component.type)
    }

    if (isForwardRefExoticComponentWithRenderFunction(component)) {
        return extractRenderFunctionFromComponent(component.render)
    }

    return null
}

// Type Guards
const REACT_MEMO_TYPE = Symbol.for("react.memo")
const REACT_FORWARD_REF_TYPE = Symbol.for("react.forward_ref")

function isComponentClass(component: unknown): component is ComponentClass {
    return typeof component === "function" && "prototype" in component && "isReactComponent" in component.prototype
}

function isFunctionComponent(component: unknown): component is FunctionComponent {
    return typeof component === "function" && !isComponentClass(component)
}

function isExoticComponent(component: unknown): component is ExoticComponent {
    return typeof component === "object" && component !== null && "$$typeof" in component
}

// The TS types for ForwardRefExoticComponent don't include a `render` function
// by default, because it's not always required, but we're only interested in
// components that do have it.
// See: https://github.com/facebook/react/blob/v16.6.0/packages/react/src/forwardRef.js#L42-L45
interface ForwardRefExoticComponentWithRenderFunction<P> extends ForwardRefExoticComponent<P> {
    render: ComponentType<unknown>
}

function isForwardRefExoticComponentWithRenderFunction(
    component: unknown
): component is ForwardRefExoticComponentWithRenderFunction<unknown> {
    return (
        isExoticComponent(component) &&
        component.$$typeof === REACT_FORWARD_REF_TYPE &&
        "render" in component &&
        typeof component["render"] === "function"
    )
}

function isMemoExoticComponent(component: unknown): component is MemoExoticComponent<any> {
    return isExoticComponent(component) && component.$$typeof === REACT_MEMO_TYPE
}
