import { Record, List } from "immutable"
import type { LinearGradientBase } from "framer"
import { GradientColorStop, GradientColorStops } from "document/models/GradientColorStop"

const gradientDefaults: LinearGradientBase & { stops: GradientColorStops } = {
    alpha: 1,
    angle: 180,
    stops: List([
        new GradientColorStop({ value: "rgba(0,0,0,0)", position: 0 }),
        new GradientColorStop({ value: "black", position: 1 }),
    ]),
}

export const LinearGradientRecord = Record(gradientDefaults)
