import * as React from "react"

export function IconInputSearch(props: React.HTMLAttributes<SVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" {...props}>
            <path
                d="M 11 11 L 13 13"
                fill="transparent"
                strokeWidth="1.5"
                stroke="currentColor"
                strokeLinecap="round"
            ></path>
            <path
                d="M 7.5 2 C 10.538 2 13 4.462 13 7.5 C 13 10.538 10.538 13 7.5 13 C 4.462 13 2 10.538 2 7.5 C 2 4.462 4.462 2 7.5 2 Z M 3.5 7.5 C 3.5 9.709 5.291 11.5 7.5 11.5 C 9.709 11.5 11.5 9.709 11.5 7.5 C 11.5 5.291 9.709 3.5 7.5 3.5 C 5.291 3.5 3.5 5.291 3.5 7.5 Z"
                fill="currentColor"
            ></path>
        </svg>
    )
}
