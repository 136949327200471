import { nameDefaults } from "document/models/CanvasTree/traits/Name"
import { aspectRatioDefaults } from "document/models/CanvasTree/traits/AspectRatioLock"
import { rotationDefaults } from "document/models/CanvasTree/traits/Rotation"
import { shapeStrokeDefaults } from "document/models/CanvasTree/traits/Stroke"
import { visibilityDefaults } from "document/models/CanvasTree/traits/Visibility"
import { lockDefaults } from "document/models/CanvasTree/traits/Lock"
import { pathSizeDefaults } from "document/models/CanvasTree/traits/Size"
import { positionDefaults } from "document/models/CanvasTree/traits/Position"
import { withExportDefaults } from "document/models/CanvasTree/traits/Export"
import { pathDefaults } from "framer"
import { opacityDefaults } from "document/models/CanvasTree/traits/Opacity"
import { boxShadowDefaults } from "document/models/CanvasTree/traits/BoxShadowDefaults"
import { pathFillDefaults } from "document/models/CanvasTree/traits/FillDefaults"
import { memoRecord } from "../memoRecord"

export const getPathRecord = memoRecord(() => {
    return {
        ...nameDefaults,
        ...pathFillDefaults,
        ...rotationDefaults,
        ...aspectRatioDefaults,
        ...shapeStrokeDefaults,
        ...opacityDefaults,
        ...boxShadowDefaults,
        ...visibilityDefaults,
        ...lockDefaults,
        ...pathDefaults,
        ...pathSizeDefaults,
        ...positionDefaults,
        ...withExportDefaults,
    }
})
