import type { ModulesState } from "@framerjs/framer-services"
import type { Mutable } from "utils/Mutable"
import { initializeEnvironment } from "environment"
import { bootstrapAssets } from "preview-next/bootstrapAssets"
import { ExportPreviewWrapper } from "preview-next/ExportPreviewWrapper"
import * as React from "react"
import * as ReactDOM from "react-dom"

initializeEnvironment({ name: "standalone", security: {} })
bootstrapAssets({ assetResolverType: "studio", imageBaseURL: "." })

const mainElement = document.querySelector("main")

const documentJSON = document.getElementById("__document__")?.textContent
const nodeId = document.getElementById("__node_id__")?.textContent
const index = document.getElementById("__scripts_index__")?.textContent
const vendors = document.getElementById("__scripts_vendors__")?.textContent
const modulesContent = document.getElementById("__modules__")?.textContent || `{ "data": [] }`
const modules = JSON.parse(modulesContent) as Record<string, Mutable<ModulesState.CompiledModule>>

if (documentJSON && nodeId && index && vendors) {
    ReactDOM.render(
        <ExportPreviewWrapper
            document={JSON.parse(documentJSON)}
            nodeId={nodeId}
            scripts={{ "index.js": index, "vendors.js": vendors }}
            modules={modules}
        />,
        mainElement
    )
} else {
    // eslint-disable-next-line no-console
    console.error(
        `This exported prototype seems to be corrupted.

You can try exporting it again, or contact us at https://www.framer.com/support/contact/`
    )
}
