import { rgba } from "../../tokens/utils/rgba"
import type {
    ColorTokenMap,
    PopoverColorTokenMap,
    DashboardColorTokenMap,
    PrototypeViewColorTokenMap,
} from "./colorTypes"
import { palette } from "./palette"

const tint = palette.blue60
const tintDark = palette.blue70
const tintDimmed = rgba(palette.blue60, 0.15)
const componentTint = palette.purple90
const componentTintDark = palette.purple95
const componentTintDimmed = rgba(palette.purple90, 0.15)
const componentTintLight = palette.purple48
const errorTint = palette.red100
const errorTintDimmed = rgba(palette.red100, 0.2)
const warningTint = palette.yellow110
const warningTintDimmed = rgba(palette.yellow110, 0.2)
const graphicTint = palette.purple60
const graphicTintDimmed = rgba(palette.purple60, 0.15)
const panelText = palette.dark20
const panelTextSelected = palette.white100
const panelBackground = palette.dark100
const popoverBackground = palette.dark82
const inputBackground = palette.dark82
const inputBackgroundHover = palette.dark84
const inputBackgroundActive = palette.dark88
const inputBorder = "transparent"
const inputBorderActive = tint
const inputText = palette.light60
const inputTextDisabled = palette.dark40
const inputPlaceholder = palette.dark20
const buttonText = palette.white100
const beta = palette.purple125

export const colorsDark: ColorTokenMap = {
    tint: tint,
    tintDark: tintDark,
    tintDimmed: tintDimmed,
    componentTint: componentTint,
    componentTintDark: componentTintDark,
    componentTintDimmed: componentTintDimmed,
    componentTintDimmedDrag: rgba(palette.purple90, 0.3),
    componentTintDimmedActive: rgba(palette.purple100, 0.4),
    componentTintLight: componentTintLight,
    errorTint: errorTint,
    errorTintDimmed: errorTintDimmed,
    warningTint: warningTint,
    warningTintDimmed: warningTintDimmed,
    graphicTint: graphicTint,
    graphicTintDimmed: graphicTintDimmed,
    beta: beta,

    // Panel
    panelTitle: palette.white100,
    panelText: panelText,
    panelTextSelected: panelTextSelected,
    panelBackground: panelBackground,
    panelDivider: palette.dark86,
    panelSectionHeaderIcon: palette.white100,
    panelChevron: palette.light100,
    panelSubtitle: palette.light80,

    // Canvas
    canvasCreatePreviewBorder: palette.blue60,
    canvasSelectionRectBackground: rgba(palette.blue60, 0.1),
    canvasSelectionRectBorder: rgba(palette.blue60, 0.5),
    canvasTooltipText: palette.white100,
    canvasTooltipBackground: rgba(palette.dark50, 0.6),

    // Dropdown Button
    dropdownText: palette.dark20,

    // Floating Window Toolbar
    floatingWindowToolbarIcon: palette.dark20,

    // Modal
    modalBackground: panelBackground,
    modalBackgroundTransparent: rgba(panelBackground, 0),
    modalSecondaryBackground: palette.dark94,
    modalBackdrop: rgba(palette.black100, 0.7),
    modalTitle: palette.white100,
    modalDescription: palette.light100,
    modalCloseIcon: palette.light52,

    // Variables Modal
    variablesModalEmptyStateIcon: palette.dark80,

    // Modal Tutorial
    modalTutorialRowHover: rgba(palette.black100, 0.4),

    // Button
    buttonBackground: inputBackground,
    buttonBackgroundHover: inputBackgroundHover,
    buttonBackgroundActive: inputBackgroundActive,
    buttonText: buttonText,
    buttonBackgroundPrimary: tint,
    buttonBackgroundPrimaryHover: tintDark,
    buttonBackgroundPrimaryActive: palette.blue80,
    buttonTextPrimary: buttonText,
    buttonBackgroundDestructive: palette.red100,
    buttonBackgroundDestructiveHover: palette.red110,
    buttonBackgroundDestructiveActive: palette.red130,
    buttonTextDestructive: palette.white100,

    // Row Overlay Button
    rowOverlayButtonBackground: palette.dark82,
    rowOverlayButtonBackgroundHover: palette.dark84,
    rowOverlayButtonBackgroundActive: palette.dark88,

    // Tab Bar
    tabBarText: palette.dark40,

    // Electron
    electronTabBarBackground: palette.dark86,
    electronTabBarBackgroundActive: palette.dark100,
    electronTabBarBorder: palette.dark74,
    electronTabBarText: palette.dark20,
    electronTabBarTextActive: palette.white100,

    // Input
    inputBackground: inputBackground,
    inputBorder: inputBorder,
    inputBorderError: errorTint,
    inputBorderActive: inputBorderActive,
    inputText: inputText,
    inputTextError: errorTint,
    inputTextDisabled: inputTextDisabled,
    inputPlaceholder: inputPlaceholder,
    inputLabel: palette.dark20,
    inputLabelDisabled: palette.dark60,
    inputIcon: palette.dark20,
    inputIconHover: palette.light80,
    inputIconDisabled: inputTextDisabled,

    // Padlock
    iconPadlock: palette.dark20,
    padLockConnector: palette.dark70,

    // Popover
    popoverBackground: popoverBackground,
    popoverDivider: palette.dark60,

    // Popup Button
    popupButtonBackground: inputBackground,
    popupButtonBorder: inputBorder,
    popupButtonText: buttonText,

    // Radio Button
    radioButtonBackground: palette.dark60,
    radioButtonBackgroundHover: palette.dark40,
    radioButtonLabel: buttonText,
    radioButtonLabelDisabled: palette.dark70,

    // Segmented Control
    segmentedControlBackground: inputBackground,
    segmentedControlBorder: inputBorder,
    segmentedControlItemBackground: inputBackground,
    segmentedControlItemBackgroundSelected: palette.dark60,
    segmentedControlItemBackgroundActive: inputBackgroundHover,
    segmentedControlItemText: palette.dark20,
    segmentedControlItemTextHover: palette.light90,
    segmentedControlItemTextSelected: palette.white100,
    segmentedControlItemTextSelectedHover: palette.white100,
    segmentedControlDivider: palette.dark70,

    // Slider
    sliderKnob: palette.white100,
    sliderTrack: palette.dark70,
    sliderTrackActive: tint,
    sliderTrackDisabled: palette.dark80,

    // Spinner
    spinnerBackground: palette.white100,

    // Swatch
    swatchBorder: rgba(palette.white100, 0.15),
    swatchBorderPlaceholder: rgba(palette.white100, 0.05),
    swatchBackgroundPlaceholderForFill: palette.white100,
    swatchBackgroundPlaceholderForBorder: palette.dark50,
    swatchBackgroundPlaceholderForShadow: rgba(palette.dark70, 0.5),

    // Checkbox
    checkboxBackground: palette.dark60,
    checkboxBackgroundActive: palette.dark40,
    checkboxBackgroundChecked: tint,
    checkboxBackgroundCheckedActive: tintDark,
    checkboxLabel: buttonText,
    checkboxLabelDisabled: palette.dark70,

    // Checkbox "more info" text
    moreInfoText: palette.light60,
    moreInfoDisclosureIcon: palette.light100,

    // Code Editor
    codeEditorBackground: palette.dark94,

    // TextArea
    textAreaScrollbar: palette.dark60,

    // Toolbar
    toolbarIcon: palette.dark20,
    toolbarIconHover: palette.light70,
    toolbarIconActive: tint,
    toolbarIconBadge: palette.orange100,
    toolbarTitleText: palette.dark20,
    toolbarInputText: palette.white100,
    toolbarImportMenuIconBackground: palette.dark90,

    // Animations
    animationForground: palette.white100,
    animationForgroundDimmed: palette.blue40,
    animationForgroundLight: palette.blue30,
    animationBackgroundLight: rgba(palette.white100, 0.2),
    animationNavigationTargetFrame: palette.dark50,
    animationNavigationTargetFrameSelected: "rgba(0, 153, 255, 0.3)",
    animationTriggerFrame: palette.dark70,
    animationTriggerCard: palette.dark60,

    // Toolbar Button
    toolbarButtonBackground: palette.dark80,
    toolbarButtonBackgroundActive: palette.white100,
    toolbarButtonBackgroundDisabled: palette.dark20,
    toolbarButtonBackgroundWarning: rgba(palette.yellow110, 0.2),
    toolbarButtonBackgroundWarningActive: rgba(palette.yellow110, 0.3),
    toolbarButtonBackgroundError: rgba(palette.red106, 0.2),
    toolbarButtonBackgroundErrorActive: rgba(palette.red106, 0.3),
    toolbarButtonText: palette.white100,
    toolbarButtonTextActive: palette.black100,
    toolbarButtonTextWarning: palette.yellow100,
    toolbarButtonTextError: palette.red106,

    // Pages
    pageRowBackgroundActive: inputBackgroundHover,

    // Layers Panel
    layerItemTextDimmed: rgba(palette.dark50, 0.8),
    layerItemTextInactive: rgba(palette.dark40, 0.5),
    layerItemTextInactiveSelected: rgba(palette.white100, 0.5),
    layerItemIcon: palette.dark20,
    layerItemIconDimmed: palette.purple120,
    layerItemIconComponentDimmed: palette.purple130,
    layerItemIconGraphicDimmed: palette.purple130,
    layerItemIconInactive: rgba(palette.dark50, 0.5),
    layerItemIconInactiveSelected: rgba(palette.white100, 0.5),
    layerItemPlaceholderText: palette.dark20,
    layerItemComponentLabel: palette.dark60,
    layerItemComponentLabelSelected: rgba(palette.white100, 0.5),

    // Code panel
    codePanelNoSearchResults: palette.dark50,

    // Canvas
    canvasBackground: palette.dark86,
    canvasFrameTitle: palette.dark50,
    canvasFrameOutline: "rgba(119, 119, 119, 0.3)",
    canvasFramePreview: "rgba(119, 119, 119, 0.1)",
    canvasFramePreviewOutline: "rgba(119, 119, 119, 0.3)",
    canvasConnectionLine: "rgba(120, 120, 120, 0.5)",
    canvasArrowInactive: palette.dark70,
    canvasSuggestionOutline: rgba(palette.white100, 0.05),
    canvasSuggestionBackground: rgba(palette.white100, 0.04),
    canvasSuggestionText: palette.dark20,

    // Rulers
    rulerBackground: "#2e2e2e",
    rulerBorderColor: "#3b3b3b",
    rulerTickColor: palette.dark60,
    rulerTextColor: palette.dark50,
    rulerFadeOut: rgba("#2e2e2e", 0),
    guideColor: "#00ADAD",
    guideHighlightColor: tint,

    // Selection Box
    selectionBox: "rgba(119, 119, 119,0.1)",
    selectionBoxOutline: "rgba(119, 119, 119, 0.3)",
    selectionBoxBackground: "rgba(0, 153, 255, 0.3)",
    selectionBoxComponentBackground: "rgba(136, 85, 255,0.2)",

    // Snapping
    snapLine: "#F5A",
    snapConstraint: "#F5A",

    // Text editor
    textEditorOutline: palette.dark50,

    // Crash
    error: palette.orange100,
    errorText: palette.white100,
    fatalErrorText: palette.white100,

    // Code export
    codeExportPanelFadeOut: rgba(panelBackground, 0),
    // The rgba coversion is a workaround as browsers make the text ::selection color lighter by default
    codeExportPanelSelection: "rgba(68, 68, 68, 0.99)",
    codeExportChipBackground: "rgba(255, 215, 6, 0.16)",

    // Font popover
    fontFamilyGoogleIcon: palette.dark70,
    fontFamilyGoogleIconHover: palette.dark20,

    // Packages Panel
    packagesCompactCardTextDimmed: palette.dark40,
    packagesPublishedPackageTitle: palette.light80,
    packagesDescriptionBorder: palette.dark80,
    packagesInputPlaceholder: "#777",
    packagesInputPlaceholderFocused: "#555",
    packagesMarkdownCodeBackground: "#222",
    packagesMarkdownCodeBlockBackground: "#1A1A1A",
    packagesMarkdownInnerBorder: "#000",
    packagesMarkdownTableRow: "rgba(255,255,255, 0.025)",
    packagesShareIcon: "#777",
    packagesTextColor: "#FFF",
    packagesTextColorLight: "#777",
    packagesTextColorLightest: "#555",

    // Preview
    previewConsoleBackground: palette.dark90,
    previewConsoleButtonText: palette.dark50,
    previewConsoleDivider: palette.dark80,
    previewConsoleCollapseIcon: palette.light90,
    previewConsoleBadge: palette.dark70,
    previewSpinnerBackground: palette.white100,
    previewCloseIconBackground: "rgba(255, 255, 255, 0.1)",
    previewCloseIconBackgroundHover: "rgba(255, 255, 255, 0.14)",
    previewCloseIconColor: "rgba(255, 255, 255, 0.21)",
    previewLabelDisabled: palette.dark20,
    previewRemovePrototypeUserIcon: palette.dark20,
    previewEmptyDocumentText: palette.dark40,
    previewDeviceColorDotInnerBorder: rgba(palette.white100, 0.1),

    // Shared Preview
    sharedPreviewStatusHeading: palette.light60,
    sharedPreviewStatusText: palette.dark20,
    sharedPreviewStatusIcon: palette.white100,
    sharedPreviewBackground: palette.dark100,
    sharedPreviewMobileQRBackground: palette.white100,
    sharedPreviewMobileQR: palette.black100,

    // Tooltip
    tooltipBackground: palette.dark70,

    // Badge
    badgeBackground: rgba(tint, 0.15),
    timeBadgeBackground: rgba(palette.white100, 0.2),
    betaBadgeBackground: rgba(beta, 0.15),
    betaBadgeLightBackground: palette.white100,

    // Avatars
    inactiveAvatarBackground: palette.dark30,

    // Feedback
    feedbackSidebarBackground: panelBackground,
    feedbackPrimaryText: palette.white100,
    feedbackSecondaryText: palette.dark40,
    feedbackTertiaryText: palette.dark20,
    feedbackFormButtonBackground: palette.dark82,
    feedbackFormButtonFill: palette.dark40,
    feedbackFormButtonSubmitFocus: palette.blue90,
    feedbackFormButtonFocus: palette.dark70,
    feedbackFormBackground: panelBackground,
    feedbackFormPlaceholder: palette.dark40,
    feedbackDivider: palette.dark86,
    feedbackLabelBackground: palette.dark82,
    feedbackHasRepliesBackground: rgba(palette.blue60, 0.1),
    feedbackDisclosureBackground: palette.dark82,
    feedbackDisclosureFill: palette.dark40,

    // Empty State
    emptyStateIcon: palette.light100,
    emptyStateCardBackground: palette.dark82,
    emptyStateBodyText: palette.dark20,
    emptyStateInputShape: palette.dark94,
    emptyStateTextShape: palette.dark90,

    // Error State
    errorStateIconColor: palette.dark20,
    errorStateCodeText: palette.dark20,
    errorStateCodeBackground: palette.dark86,
    errorStateTitleText: palette.white100,
    errorStateDescriptionText: palette.light80,

    // Dashboard
    dashboardTitleDivision: palette.dark84,
    dashboardItemIcon: palette.white100,
    dashboardSearchPlaceholder: palette.dark50,
    dashboardMoreButtonIcon: palette.dark50,
    dashboardEmptyState: palette.dark90,
    dashboardEmptyStateTitle: palette.white100,
    dashboardEmptyStateSubtitle: palette.dark20,
    dashboardEmptyStateIcon: palette.dark20,

    // Dashboard sidebar
    dashboardSidebarBackground: palette.dark100,
    dashboardSidebarTitle: palette.white100,
    dashboardSidebarText: palette.dark30,
    dashboardSidebarTextDimmed: palette.dark60,
    dashboardSidebarTextHover: palette.light100,
    dashboardSidebarDivider: palette.dark80,
    dashboardSidebarActions: palette.dark30,
    dashboardSidebarJoinTeamButton: palette.dark90,
    dashboardSidebarJoinTeamButtonHover: palette.dark93,
    dasboardSidebarUpgradeCardBackground: palette.dark90,
    dasboardSidebarUpgradeCardText: palette.dark50,

    // Dashboard learn
    dashboardLearnTitle: palette.white100,
    dashboardLearnCard: palette.dark90,
    dashboardLearnCardTitle: palette.white100,
    dashboardLearnCardBadge: palette.white100,
    dashboardLearnArticleTitle: palette.white100,
    dashboardLearnArticleSubtitle: palette.dark30,

    // Dashboard new project modal
    dashboardNewProjectModalDivider: palette.dark82,
    dashboardNewProjectModalTitle: palette.white100,
    dashboardNewProjectCardModalBackground: palette.dark82,
    dashboardNewProjectCardModalBackgroundBlank: palette.dark74,
    dashboardNewProjectModalCardContent: palette.light70,

    // Settings Modal
    settingsModalBackground: palette.dark93,
    settingsModalInputDivision: palette.dark84,
    settingsModalTeamMemberRole: palette.light80,
    settingsModalTeamSearchIcon: palette.dark20,
    settingsModalTeamRegenerateLinkIcon: palette.white100,
    settingsModalCloseIcon: palette.dark30,
    settingsModalText: palette.light80,
    settingsModalTextDimmed: palette.light100,
    settingsModalDivider: palette.dark82,
    settingsModalUnknownClientIcon: palette.dark82,
    settingsModalLabel: palette.white100,
    settingsModalSidebarBackground: palette.dark100,
    settingsModalNavigationLink: palette.light80,
    settingsModalNavigationLinkHover: palette.light100,
    settingsModalTitle: palette.white100,
    settingsModalPlanEmptyEditorsAvatar: palette.dark80,
    settingsModalPlanEmptyEditorsAvatarText: palette.white100,
    settingsModalPlanCardBackground: palette.dark82,
    settingsModalFontDeleteIcon: palette.light100,
    settingsModalTeamSearchFilterButtonActiveBackground: palette.white100,
    settingsModalTeamSearchFilterButtonActiveText: palette.dark80,
    settingsModalTeamSearchFilterTagBackground: palette.dark80,
    settingsModalTeamSearchFilterTagText: palette.white100,
    settingsModalInvoicesDate: palette.light80,
    settingsModalInvoicesId: palette.white100,
    settingsModalPlanMoreButton: palette.light100,
    settingsModalTitleBorder: palette.dark82,

    // Organization
    organizationTextDimmed: palette.light100,
    organizationMemberRowHover: palette.dark90,
    organizationMemberDetailBackButton: palette.white100,
    organizationMemberDetailSubtitle: palette.white100,
    organizationInviteRoleMenu: palette.white100,
    organizationMemberName: palette.white100,
    organizationNoteBackground: palette.dark82,
    organizationDetailTitle: palette.white100,

    // Custom notices
    noticeModalBackground: palette.dark93,
    noticeModalTitle: palette.white100,
    noticeModalDescription: palette.light80,
    noticeModalCloseIcon: palette.dark20,

    // Confirmation modals
    confirmationModalTitle: palette.white100,
    confirmationModalDescription: palette.light80,

    // Insert Menu
    insertMenuPrimaryText: palette.white100,
    insertMenuSecondaryText: palette.dark20,
    insertMenuFocusedText: palette.white100,
    insertMenuDeviceIconBackground: palette.dark20,
    insertMenuIconColor: palette.white100,
    insertMenuIconBackground: rgba(palette.white100, 0.3),
    insertMenuPreviewBackground: palette.dark90,
    insertMenuNavText: palette.light80,
    insertMenuNavTextHover: palette.light60,
    insertMenuChevronColor: palette.dark20,
    insertMenuUnfavorite: palette.dark70,
    insertMenuFavorite: palette.yellow110,
    insertMenuShowMoreIcon: palette.dark80,
    insertMenuGridItem: palette.dark90,
    insertMenuPreviewIcons: palette.light80,
    insertMenuScreenBackground: palette.dark60,
    insertMenuScreenBackgroundSecondary: palette.dark70,
    insertMenuUnsplash: palette.black100,
    insertMenuUnsplashBackground: palette.white100,

    // Insert Menu Interaction Previews
    insertMenuInteractionPreviewFrameFill: palette.dark60,
    insertMenuInteractionPreviewFrameFillDark: palette.dark70,
    insertMenuInteractionPreviewDashedLine: palette.dark70,
    insertMenuInteractionPreviewTextLarge: palette.dark50,
    insertMenuInteractionPreviewText: palette.dark30,

    // Table
    dashboardTableHeaderText: palette.dark20,
    dashboardTableBodyText: palette.light55,
    dashboardTableBodyTextLight: palette.light100,
    dashboardTableRowHighlight: palette.dark94,

    // Toasts
    toastBackground: palette.dark93,
    toastBackgroundPrimary: tint,
    toastIcon: palette.dark30,
    toastText: palette.light80,
    toastTextPrimary: palette.white100,

    // Shortcut Modal
    shortcutsModalListTitle: palette.dark20,
    shortcutsModalActionButton: palette.dark30,
    shortcutsModalDivider: palette.dark70,
    shortcutsModalKeyBackground: palette.dark60,

    // Loader
    loaderPrimary: palette.dark84,
    loaderSecondary: palette.dark82,

    // Upsells
    upsellCTAText: palette.white100,
    upsellCTAButtonBackground: palette.white100,
    upsellCTAButtonBackgroundHover: palette.light60,

    // Team creation
    teamCreationModalBackground: palette.dark93,
    teamCreationCloseIcon: palette.dark30,
    teamCreationText: palette.light52,
    teamCreationTextDimmed: palette.dark20,
    teamCreationNavigation: palette.dark82,
    teamCreationNavigationActive: tint,
    teamCreationBenefitIcon: tint,
    teamCreationBenefitDivider: palette.dark80,

    // Error boundary
    errorBoundaryDescription: palette.light100,

    // Highlights
    highlightsBorder: palette.blue60,
    highlightsBackground: rgba(palette.blue60, 0.2),

    // Status Bar
    statusBarCustomerBetaBackground: palette.purple60,
    statusBarCustomerBetaBorder: palette.purple60,
    statusBarCustomerBetaText: palette.white100,
    statusBarNonPayingCustomerBackground: tint,
    statusBarNonPayingCustomerBorder: tint,
    statusBarNonPayingCustomerText: palette.white100,
    statusBarDevelopmentBackground: "#ff8822",
    statusBarDevelopmentBorder: "#ff8822",
    statusBarDevelopmentText: palette.white100,
    statusBarEmployeeBetaBackground: palette.dark100,
    statusBarEmployeeBetaBorder: palette.dark86,
    statusBarEmployeeBetaText: palette.light52,
    statusBarPreviewLinkBackground: palette.blue60,
    statusBarPreviewLinkBorder: palette.blue60,
    statusBarPreviewLinkText: palette.white100,
    statusBarStableBackground: palette.green100,
    statusBarStableBorder: palette.green100,
    statusBarStableText: palette.white100,

    // Beta Features
    betaFeatureButtonText: palette.white100,
    betaFeatureButtonHover: palette.purple126,
    betaFeatureIcon: palette.white100,

    // User Test popover
    userTestButtonBackground: palette.dark80,
    userTestButtonBackgroundHover: palette.dark84,
    userTestText: palette.light80,
}

export const popoverColorsDark: PopoverColorTokenMap = {
    panelBackground: colorsDark.popoverBackground,
    inputBackground: palette.dark70,
    buttonBackground: palette.dark70,
    buttonBackgroundHover: palette.dark74,
    popupButtonBackground: palette.dark70,
    buttonBackgroundActive: inputBackgroundActive,
    radioButtonBackground: palette.dark70,
    radioButtonBackgroundHover: palette.dark74,
    segmentedControlBackground: palette.dark70,
    segmentedControlDivider: palette.dark60,
    segmentedControlItemBackgroundSelected: palette.dark40,
    checkboxLabel: palette.light80,
}

export const dashboardColorsDark: DashboardColorTokenMap = {
    toastBackground: palette.dark82,
}

export const prototypeViewColorsDark: PrototypeViewColorTokenMap = {
    toastBackground: palette.dark82,
}
