import type { PropertyControls } from "framer"
import type { CanvasNode } from "./CanvasNode"
import type { CanvasTree, FrameNode, NodeID, MaybeNodeID } from ".."
import type { IsVariant, IsGestureVariant, IsTopLevelVariant, GestureType, IsPrimaryVariant } from "../traits/Variant"
import type { IsReplica } from "../traits/Template"
import type { Variables, VariableID, Variable, WithVariables } from "../traits/Variables"

import { assert } from "@framerjs/shared"
import { ControlType } from "framer"
import { setDefaults } from "./MutableNode"
import { getCanvasComponentRecord } from "../records/CanvasComponentRecord"
import { withClassDiscriminator } from "utils/withClassDiscriminator"
import { ScopeNode } from "./ScopeNode"
import { isMaster, isReplica } from "../traits/Template"
import { CanvasComponentNodeCache } from "./CanvasComponentNodeCache"
import { isPrimaryVariant, isTopLevelVariant, isVariant, isGestureVariant } from "../traits/Variant"
import { controlDescriptionFromVariable } from "../traits/Variables"
import { getDefaultName } from "document/components/utils/nodes"
import { Dictionary } from "app/dictionary"
import { generatedComponentIdentifier } from "variants/GeneratedComponent"
import { withClearResolvedCache } from "../traits/ClearResolvedCache"
import { cleanupVariableReferencesWithValues } from "../utils/usedVariableReferences"

export function isCanvasComponentNode<T>(node: T): node is T & CanvasComponentNode {
    return node instanceof CanvasComponentNode
}

export const activeVariantKey = "framer-active-variant-key"

const noVariables: Variables = []
Object.freeze(noVariables)

/**
 * The canvas component is the visual representation of a React component with variants and variables
 */
export class CanvasComponentNode extends withClassDiscriminator("CanvasComponentNode", ScopeNode)
    implements WithVariables {
    cache: CanvasComponentNodeCache

    baseVariantId: NodeID = ""
    variables: Variables = noVariables

    // Id of the original symbol master that was turned into this canvas component
    originalSymbolId?: NodeID = undefined

    constructor(properties?: Partial<CanvasComponentNode>) {
        super(undefined, new CanvasComponentNodeCache())
        setDefaults<CanvasComponentNode>(this, getCanvasComponentRecord(), properties)
    }

    getVariable(id: VariableID): Variable | undefined {
        return this.variables.find(variable => variable.id === id)
    }

    getVariableValue(id: VariableID): unknown {
        return this.getVariable(id)?.initialValue
    }

    hasVariable(id: VariableID) {
        return !!this.getVariable(id)
    }

    /**
     * Return the presumed identifier of the code component instance.
     */
    get instanceIdentifier() {
        return generatedComponentIdentifier(this.id)
    }

    getPrimaryVariant(): FrameNode & IsPrimaryVariant {
        const node = this.tree().getNode(this.baseVariantId)
        assert(node, "primary variant node should exist")
        assert(isMaster(node), "primary variant should be master")
        assert(isVariant(node), "primary variant should be a variant")
        return node as FrameNode & IsPrimaryVariant
    }

    getReplicaVariants(): (FrameNode & IsReplica & IsVariant)[] {
        return this.children.filter(
            (child): child is FrameNode & IsReplica & IsVariant => isReplica(child) && isVariant(child)
        )
    }

    getTopLevelReplicaVariants(): (FrameNode & IsReplica & IsTopLevelVariant)[] {
        return this.children.filter(
            (child): child is FrameNode & IsReplica & IsVariant => isTopLevelVariant(child) && !isPrimaryVariant(child)
        )
    }

    getTopLevelVariants(): [FrameNode & IsPrimaryVariant, ...(FrameNode & IsReplica & IsTopLevelVariant)[]] {
        return [this.getPrimaryVariant(), ...this.getTopLevelReplicaVariants()]
    }

    getTopLevelVariantForGesture(gestureNode: CanvasNode & IsGestureVariant): FrameNode & IsTopLevelVariant {
        assert(gestureNode.replicaInfo.inheritsFrom, "gesture variant should have inheritFrom")
        const node = this.tree().getNode(gestureNode.replicaInfo.inheritsFrom)
        assert(node, "top level variant for gesture should exist")
        assert(isMaster(node) || isReplica(node), "top level variant for gesture should be master or replica")
        assert(isVariant(node), "top level variant for gesture should be a variant")
        return node as FrameNode & IsTopLevelVariant
    }

    getGesturesForTopLevelVariant(variantNode: CanvasNode & IsTopLevelVariant): { [key in GestureType]: MaybeNodeID } {
        const result: { [key in GestureType]: MaybeNodeID } = { hover: null, pressed: null }
        if (!variantNode.cache.replicaInstances) return result
        variantNode.cache.replicaInstances.forEach(instanceId => {
            const instance = this.tree().getNode(instanceId)
            if (!instance || !isGestureVariant(instance)) return
            if (instance.replicaInfo.inheritsFrom === variantNode.id) {
                result[instance.gesture] = instanceId
            }
        })

        return result
    }

    getControls(): PropertyControls {
        const result: PropertyControls = {}

        const topLevelVariants = this.getTopLevelVariants()
        if (topLevelVariants.length > 1) {
            const options = topLevelVariants.map(n => n.id)
            const optionTitles = topLevelVariants.map(option => option.name || getDefaultName(option))

            result[activeVariantKey] = {
                type: ControlType.Enum,
                title: Dictionary.VARIANT,
                options,
                optionTitles,
            }
        }

        this.variables.forEach(variable => {
            if (!variable.exposeInProps || !variable.name) return
            const controlDescription = controlDescriptionFromVariable(variable)
            if (!controlDescription) return
            result[variable.id] = controlDescription
        })

        return result
    }

    preCommit(tree: CanvasTree) {
        // Check which variables have been updated
        const changedVariableIDs = this.cache.getChangedVariableIds(this.variables)
        if (!changedVariableIDs) {
            return
        }

        // Cache is shared with all descendant nodes
        this.cache.updateVariableValueMap(this.variables)

        // Render all dependent nodes on variable change
        const nodesUsingVariables = this.cache.getNodesUsingVariables(this, changedVariableIDs.updated)
        nodesUsingVariables.forEach(nodeId => {
            const node = tree.getNode(nodeId)
            if (!node) return

            // Make sure resolved caches are cleared
            if (withClearResolvedCache(node)) {
                node.clearResolvedCache()
            }

            if (changedVariableIDs.deleted.length > 0) {
                cleanupVariableReferencesWithValues(tree, node, changedVariableIDs.deletedValues)
            }

            node.forceRender()
        })
    }

    getVariableValueMap() {
        return this.cache.getVariableValueMap(this.variables)
    }
}
