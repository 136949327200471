import * as React from "react"
import type { HTMLDivAttributes, OverrideType } from "./types"
import type { ThemeOverrideMode } from "../tokens/utils/overrides"
import { cx } from "linaria"
import { themeOverrides } from "./ThemeOverride.styles"
export type { ThemeOverrideMode }

export function isValidMode(mode: unknown): mode is ThemeOverrideMode {
    return mode !== false && mode !== undefined
}

export type ThemeOverrideProps = OverrideType<
    HTMLDivAttributes,
    {
        /**
         * By allowing for falsy values the mode assignment becomes easier
         * ```jsx
         * <ThemeOverride mode={graphicsModeActive && "graphics"}></ThemeOverride>
         * ```
         */
        mode?: ThemeOverrideMode | false
    }
>

export const ThemeOverride = React.forwardRef<HTMLDivElement, ThemeOverrideProps>(function ThemeOverride(
    { mode, className, ...rest },
    forwardedRef
) {
    const overrideClass = isValidMode(mode) ? themeOverrides[mode] : undefined

    return <div ref={forwardedRef} className={cx(className, overrideClass)} {...rest} />
})
