import { NodeCache } from "./NodeCache"
import type { ConstraintProperties, NewConstraintProperties, WithPaths, Size, Rect, StackDirection } from "framer"
import type { CanvasNode } from "./CanvasNode"
import type { CanvasTree } from "../CanvasTree"
import type { NodeID, MaybeNodeID } from "./NodeID"
import type { VariableID, VariableValueMap } from "../traits/Variables"
import type { LayoutProps } from "./utils/nodeLayoutProps"
import { isUndefined } from "utils/typeChecks"

export interface CachedPlaceholders {
    index: number
    sizes: Size[]
}

export interface BoundingBox {
    minX: number
    maxX: number
    minY: number
    maxY: number
}

export class CanvasNodeCache extends NodeCache implements BoundingBox {
    future: CanvasNode | null

    // Contains the id of the top most ancestor that is a master
    masterAncestorId: MaybeNodeID = null

    // signals if the node is in the selection, can change without any node or tree changes
    selected: boolean = false

    // indicates if a text node has it's text editor active
    isEditable: boolean = false

    // used to fine tune canvas rendering
    willChangeTransform: boolean = false

    // these are keys by which all further fields are reset
    lastUpdate = -1
    rerenderCounter = 0
    visible: boolean = false
    stackEnabled: boolean | undefined = undefined
    parentRect: Rect | null = null
    parentSize: Size | null = null
    parentMatrix: DOMMatrixReadOnly | null = null

    // render overrides
    groundNodeRect: Rect | undefined = undefined

    // layout properties
    constraintProps: ConstraintProperties | null = null
    newConstraintProps: NewConstraintProperties | null = null
    matrix: DOMMatrixReadOnly | null = null
    canvasRect: Rect | null = null
    parentDirected: boolean | undefined = undefined
    parentDirectedRect: Rect | null = null
    parentDirection: StackDirection | null = null

    // DOM layout-related
    domRect: Rect | null = null
    usesDOMRect: boolean | undefined = undefined
    needsMeasure: boolean = false
    layoutProps: Partial<LayoutProps> | null = null
    visibleChildrenIds: NodeID[] | null = null
    childDirected: boolean = false

    // vectors nodes
    calculatedPaths: WithPaths | null = null
    paperPath: any | null = null
    bezier: any | null = null

    // presentation
    reactElement: React.ReactElement | null = null
    props: any | null = null

    // template nodes collect all changes here, and capture them during commit phase
    templateProperties: { [key: string]: any } | null = null
    // template master nodes keep a list of all templates instances that use them
    replicaInstances: NodeID[] | null = null
    // linked nodes update peers when they themselve change, TODO merge templateInstances in here
    links: Set<NodeID> | null
    // for external masters
    json: any = null

    placeholders: CachedPlaceholders | null = null

    // bounding box for spatial queries
    minX = Infinity
    maxX = -Infinity
    minY = Infinity
    maxY = -Infinity

    reset() {
        super.reset()

        this.lastUpdate = -1
        this.parentMatrix = null
        this.masterAncestorId = null

        this.groundNodeRect = undefined
        this.parentRect = null
        this.parentSize = null
        this.visible = false
        this.constraintProps = null
        this.newConstraintProps = null
        this.matrix = null
        this.canvasRect = null
        this.parentDirected = undefined
        this.parentDirectedRect = null
        this.parentDirection = null

        this.domRect = null
        this.usesDOMRect = undefined
        this.needsMeasure = false
        this.layoutProps = null
        this.visibleChildrenIds = null
        this.childDirected = false

        this.calculatedPaths = null
        this.paperPath = null
        this.bezier = null
        this.reactElement = null
        this.props = null
    }

    tree(): CanvasTree {
        return this.latest!.tree as any
    }

    resetBoundingBox() {
        this.minX = Infinity
        this.maxX = -Infinity
        this.minY = Infinity
        this.maxY = -Infinity
    }

    setBoundingBox(rect: Rect) {
        this.minX = rect.x
        this.maxX = rect.x + rect.width
        this.minY = rect.y
        this.maxY = rect.y + rect.height
    }

    extendBoundingBox(box: BoundingBox) {
        if (this.minX > box.minX) this.minX = box.minX
        if (this.maxX < box.maxX) this.maxX = box.maxX
        if (this.minY > box.minY) this.minY = box.minY
        if (this.maxY < box.maxY) this.maxY = box.maxY
    }

    #variableValueMap: VariableValueMap | undefined

    getVariableValue(id: VariableID): unknown {
        return this.#variableValueMap?.get(id)
    }

    hasVariable(id: VariableID): boolean {
        return this.#variableValueMap?.has(id) === true
    }

    setVariableValueMap(variableValueMap: VariableValueMap | undefined) {
        this.#variableValueMap = variableValueMap
    }

    isEqualVariableValueMap(variableValueMap: VariableValueMap | undefined) {
        return this.#variableValueMap === variableValueMap
    }

    /**
     * Return a boolean based on whether or not the node has had it's cache
     * updated to include variables. This is currently always true of nodes that
     * are children of CanvasComponentNodes, and never true of nodes outside of
     * isolation, but could be true of children of Pages in a future where
     * Variables exist outside of isolation.
     */
    isNodeInVariablesContext() {
        return !isUndefined(this.#variableValueMap)
    }
}
