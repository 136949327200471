import { css } from "linaria"
import { colors } from "../tokens/colors"
import { dimensions } from "../tokens/dimensions"
import { cursors, fonts } from "../tokens"

export const tabBar = css`
    position: relative;
    display: flex;
    width: 100%;
    height: ${dimensions.tabBarHeight}px;
    min-height: ${dimensions.tabBarHeight}px;
    padding: 0 ${dimensions.panelPadding / 2}px;
`

export const tabBarItem = css`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 ${dimensions.panelPadding / 2}px;
    color: ${colors.tabBarText};
    cursor: ${cursors.button};
    font-size: ${fonts.size.base}px;
    font-weight: ${fonts.weight.heading};
    line-height: 22px;
    -webkit-user-select: none;
    user-select: none;
`

export const tabBarItemSelected = css`
    background: none;
    color: ${colors.buttonText};
`

export const divider = css`
    position: absolute;
    right: ${dimensions.panelPadding}px;
    bottom: 0;
    left: ${dimensions.panelPadding}px;
    height: 1px;
    background: ${colors.panelDivider};
`

export const noPointerEvents = css`
    & .${tabBarItem} {
        cursor: default;
        pointer-events: none;
    }
`
