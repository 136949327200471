import { PreviewDataSource, ServiceEventEmitter, UnsafeJSON } from "@framerjs/framer-services"
import * as React from "react"
import { useState } from "react"
import { configForTarget } from "./config"
import { PreviewWrapper } from "./PreviewWrapper"
import { usePreviewSettingsService } from "./StandalonePreviewWrapper"
import { useReadOnlyModulesStateService } from "./useReadOnlyModulesStateService"

export interface ExportPreviewWrapperProps {
    nodeId: string
    document: UnsafeJSON
    scripts: Record<string, string>
    modules: UnsafeJSON
}

/**
 * A PreviewWrapper variant that is fed directly with project resources.
 */
export const ExportPreviewWrapper = ({ nodeId, document, scripts, modules }: ExportPreviewWrapperProps) => {
    const config = configForTarget("export")
    const highlightsDisabled = !config.enableInteractionHighlights

    const [previewDataSourceService] = useState<PreviewDataSource.Interface>(() => ({
        loadDocument: async () => ({
            document,
            scripts,
        }),
        treeUpdateStream: new ServiceEventEmitter<PreviewDataSource.TreeUpdateEvent>().newStream,
        scriptUpdateStream: new ServiceEventEmitter<PreviewDataSource.ScriptUpdateEvent>().newStream,
    }))
    const [previewSettingsService] = usePreviewSettingsService({ document, nodeId, highlightsDisabled })

    const modulesURI = `data:application/json;base64,${btoa(JSON.stringify(modules))}`
    const modulesStateService = useReadOnlyModulesStateService(modulesURI)

    return (
        <PreviewWrapper
            controlChannel="none"
            highlightsDisabled={highlightsDisabled}
            previewDataSourceService={previewDataSourceService}
            previewSettingsService={previewSettingsService}
            modulesStateService={modulesStateService}
            previewURL={config.sandboxURL}
            sandboxed={false}
            targetOrigin="*"
        />
    )
}
