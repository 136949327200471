/**
 * This enum defines the list of all class discriminators.
 * Using an enum provides a) protection against typos;
 * b) protection against duplicates (if you create another class called `Color`
 * and try to add it into enum, TS will warn you that the enum member already exists.)
 */
export enum ClassDiscriminator {
    BooleanShapeNode = "BooleanShapeNode",
    BoxShadow = "BoxShadow",
    CanvasComponentNode = "CanvasComponentNode",
    CanvasNode = "CanvasNode",
    CodeComponentNode = "CodeComponentNode",
    Color = "Color",
    ColorTokenNode = "ColorTokenNode",
    CreateCodeComponentBaseTool = "CreateCodeComponentBaseTool",
    CreateFrameBaseTool = "CreateFrameBaseTool",
    CreateShapeBaseTool = "CreateShapeBaseTool",
    CreateTextTool = "CreateTextTool",
    ExportOptions = "ExportOptions",
    FeedbackTool = "FeedbackTool",
    FrameNode = "FrameNode",
    GradientColorStop = "GradientColorStop",
    GradientToolEngine = "GradientToolEngine",
    GuideMoveTool = "GuideMoveTool",
    HighlightTool = "HighlightTool",
    InitialTool = "InitialTool",
    LineAnchorMoveTool = "LineAnchorMoveTool",
    LinearGradient = "LinearGradient",
    Menu = "Menu",
    MoveTool = "MoveTool",
    MutableNode = "MutableNode",
    LinkTool = "LinkTool",
    OvalShapeNode = "OvalShapeNode",
    PageNode = "PageNode",
    PanTool = "PanTool",
    PathCurveBendTool = "PathCurveBendTool",
    PathDefaultTool = "PathDefaultTool",
    PathNode = "PathNode",
    PathSegment = "PathSegment",
    PathSegmentAdditionTool = "PathSegmentAdditionTool",
    PathSegmentHandleMoveTool = "PathSegmentHandleMoveTool",
    PathSegmentMoveTool = "PathSegmentMoveTool",
    PathSegmentSelectTool = "PathSegmentSelectTool",
    PolygonShapeNode = "PolygonShapeNode",
    PreviewSettings = "PreviewSettings",
    RadialGradient = "RadialGradient",
    RadiusTool = "RadiusTool",
    RectangleShapeNode = "RectangleShapeNode",
    RecoveryTool = "RecoveryTool",
    ResizeTool = "ResizeTool",
    RootNode = "RootNode",
    RotateTool = "RotateTool",
    SampleColorTool = "SampleColorTool",
    ScopeNode = "ScopeNode",
    SelectTool = "SelectTool",
    Shadow = "Shadow",
    ShapeContainerNode = "ShapeContainerNode",
    ShapeGroupNode = "ShapeGroupNode",
    SlotConnectTool = "SlotConnectTool",
    StarShapeNode = "StarShapeNode",
    StyledTextDraft = "StyledTextDraft",
    SVGNode = "SVGNode",
    TestTool = "TestTool",
    TextEditTool = "TextEditTool",
    TextNode = "TextNode",
    TokenNode = "TokenNode",
    VekterGradientTool = "VekterGradientTool",
    VekterPathEngine = "VekterPathEngine",
    VekterTool = "VekterTool",
    ZoomSelectTool = "ZoomSelectTool",
    ZoomTool = "ZoomTool",
}

/** @internal */
type ClassDiscriminatorList = keyof typeof ClassDiscriminator

// Define helper types to make `withClassDiscriminator` more readable
class EmptyClass {}
type EmptyClassType = typeof EmptyClass
type AnyClassType = new (...args: any[]) => {}

/**
 * A mixin that adds the `__class` property into classes.
 *
 * We use the `__class` property to reliably get the stringified class discriminator (e.g., in serialization).
 * `(class {}).name` is unreliable because bundlers may rename functions and classes.
 *
 * Note: this mixing won’t work with children of abstract classes due to TS limitations. For abstract classes, you’ll have to use `patchClassName`
 * and do a bit of extra work. See `withClassDiscriminator` docs.
 *
 * @example
 * // With a parent class
 * class RootNode extends withClassDiscriminator("RootNode", CanvasNode) { ... }
 *
 * // Without a parent class
 * class MutableNode extends withClassDiscriminator("MutableNode") { ... }
 */
export function withClassDiscriminator<BaseClassType extends AnyClassType = EmptyClassType>(
    classDiscriminator: ClassDiscriminatorList,
    Base: BaseClassType = class {} as BaseClassType
) {
    // ⚠️ NOTE: this function’s implementation is carefully replicated in Library → PathSegment.
    // If making changes here, make sure to copy them there as well.
    // (We can’t simply move `withClassDiscriminator` to library and avoid code duplication;
    // see https://github.com/framer/FramerStudio/pull/5874#issuecomment-655517491.)

    const WithClassDiscriminatorMixin = class extends Base {
        readonly __class: ClassDiscriminator = ClassDiscriminator[classDiscriminator]

        // Add a displayName for easier debugging
        static displayName = `WithClassDiscriminatorMixin(${classDiscriminator})`
    }

    // Also: forcefully put `__class` into prototype, so that code that needs to work with classes
    // (instead of instances) can also access the class discriminator
    ;(WithClassDiscriminatorMixin.prototype as any).__class = classDiscriminator

    return WithClassDiscriminatorMixin
}

/**
 * A function that helps to add the `__class` property where `withClassDiscriminator` is not supported
 * (e.g., when you’re extending an abstract class.)
 *
 * Apart from calling this function, you’ll also have to add the `__class` property manually,
 * like in the example.
 *
 * @example
 * class StyledTextDraft extends StyledText<EditorState> {
 *   // Add this ↓
 *   readonly __class: ClassDiscriminator = ClassDiscriminator.StyledTextDraft
 * }
 *
 * // And this ↓
 * patchClassDiscriminator(StyledTextDraft)
 */
export function patchClassDiscriminator<ClassType extends AnyClassType>(
    Class: ClassType,
    classDiscriminator: ClassDiscriminatorList
): void {
    ;(Class.prototype as any).__class = classDiscriminator
    ;(Class as any).displayName = `WithClassDiscriminatorMixin(${classDiscriminator})`
}
