import type { KeysMatching } from "./keysMatching"
import type { AnyNodeUpdate } from ".."
import type { VariableReference } from "../traits/VariableReference"

export type KeysSupportVariables = NonNullable<KeysMatching<AnyNodeUpdate, VariableReference>>

// TS should complain when keys support variables are missing here
// If the prop is used for styling a node you might want to update Clipboard.ts:pasteStyle, for variable fallback values
const allVariableKeysRecord: Record<KeysSupportVariables, boolean> = {
    opacity: true,
    fillColor: true,
    fillImage: true,
    radius: true,
    textContent: true,
    rotation: true,
    blur: true,
    backgroundBlur: true,
    brightness: true,
    contrast: true,
    grayscale: true,
    hueRotate: true,
    invert: true,
    saturate: true,
    sepia: true,
    visible: true,
}

export const allVariableKeys = Object.keys(allVariableKeysRecord) as KeysSupportVariables[]
