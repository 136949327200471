import type { CanvasNode } from "document/models/CanvasTree"
import type { BlendingMode } from "framer"
import { stylableNode } from "document/models/CanvasTree/traits/utils/stylableNode"

export interface WithBlending {
    blendingMode: BlendingMode | undefined
}

export const blendingDefaults: WithBlending = {
    blendingMode: undefined,
}

export const blendingModeKey: keyof WithBlending = "blendingMode"

export function withBlending(node: CanvasNode): node is CanvasNode & WithBlending {
    return blendingModeKey in node && stylableNode(node)
}
