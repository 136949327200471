import type { CanvasNode } from "document/models/CanvasTree"

export interface WithAutoSize {
    autoSize: boolean
}

export const autoSizeDefaults: WithAutoSize = {
    autoSize: true,
}

export function withAutoSize(node: CanvasNode): node is CanvasNode & WithAutoSize {
    return "autoSize" in node
}
