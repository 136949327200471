export function isShallowSetEqual<T>(a: Set<T>, b: Set<T>): boolean {
    if (a.size !== b.size) return false
    for (const item of a) {
        if (!b.has(item)) return false
    }
    return true
}

export function isShallowArrayEqual(a: unknown[], b: unknown[]): boolean {
    if (a === b) return true

    const alength = a.length
    if (alength !== b.length) return false

    for (let i = 0; i < alength; i++) {
        // eslint-disable-next-line eqeqeq
        if (a[i] != b[i]) return false
    }
    return true
}

interface ObjectWithStringKeys {
    [key: string]: unknown
}

export function isShallowObjectEqual(a: ObjectWithStringKeys, b: ObjectWithStringKeys): boolean {
    if (a === b) return true

    const keys = Object.keys(a)
    const length = keys.length
    if (length !== Object.keys(b).length) return false

    for (let i = length; i-- !== 0; ) {
        const key = keys[i]
        // eslint-disable-next-line eqeqeq
        if (a[key] != b[key]) return false
    }
    return true
}

export function isShallowEqual(a: unknown, b: unknown): boolean {
    if (a === b) return true

    if (a instanceof Object) {
        if (!(b instanceof Object)) return false
        if (Array.isArray(a)) {
            if (!Array.isArray(b)) return false
            return isShallowArrayEqual(a, b)
        }
        if (a instanceof Set) {
            if (!(b instanceof Set)) return false
            return isShallowSetEqual(a, b)
        }
        return isShallowObjectEqual(a as ObjectWithStringKeys, b as ObjectWithStringKeys)
    }
    return false
}
