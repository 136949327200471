import type { CanvasNode } from "document/models/CanvasTree"
import { FramePresetID, isFramePresetID } from "./utils/framePresets"

export interface WithFramePreset {
    framePreset: FramePresetID | null
}

export const withFramePresetDefaults = {
    framePreset: null,
}

const key: keyof WithFramePreset = "framePreset"

export function withFramePreset(node: CanvasNode): node is CanvasNode & WithFramePreset {
    return key in node
}

export function reduceFramePreset(node: CanvasNode, result: { framePresetsInSelection: (FramePresetID | null)[] }) {
    if (!withFramePreset(node)) return
    const framePreset = isFramePresetID(node.framePreset) ? node.framePreset : null
    result.framePresetsInSelection.push(framePreset)
}
