import { Set } from "immutable"
import { CanvasTree, CanvasNode, ScopeNode } from "document/models/CanvasTree"
import type { Point } from "framer"
import type { NodeID } from "../nodes/NodeID"
import { isEqual } from "utils/isEqual"
import { withRotation } from "./Rotation"
import { getAbsoluteOffset } from "./utils/getAbsoluteOffset"
import { isNumber } from "utils/typeChecks"

export type GuideNode = CanvasNode & WithGuides

export type GuideSelector = {
    nodeId: NodeID
    axis: "x" | "y"
    offset: number
}

export interface WithGuides {
    guidesX: Set<number>
    guidesY: Set<number>
}

export const guidesDefaults: WithGuides = {
    guidesX: Set<number>(),
    guidesY: Set<number>(),
}

const key: keyof WithGuides = "guidesX"

export function withGuides(node: CanvasNode): node is GuideNode {
    return key in node
}

const rangeThreshold = 3
export const closestGuideInRange = (
    tree: CanvasTree,
    activeScope: ScopeNode,
    mouse: Point,
    zoom: number
): GuideSelector | null => {
    const resultInPage = findClosestGuide(activeScope, mouse)
    const guideNode = getValidGuideNodeAtPoint(tree, activeScope, mouse)

    if (!guideNode && !resultInPage) {
        return null
    }

    let result = resultInPage
    if (guideNode) {
        const mouseInNode = getMousePositionInNode(tree, mouse, guideNode)
        const resultInNode = findClosestGuide(guideNode, mouseInNode)

        if (!resultInPage) {
            result = resultInNode
        } else if (!resultInNode) {
            result = resultInPage
        } else if (resultInNode && resultInPage) {
            // prioritize result in node
            result = resultInNode.distance > resultInPage.distance ? resultInPage : resultInNode
        }
    }

    if (!result) return null
    return result.distance * zoom < rangeThreshold ? result.guide : null
}

export const getValidGuideNodeAtPoint = (tree: CanvasTree, activeScope: ScopeNode, point: Point): GuideNode | null => {
    const validGroundNodes: GuideNode[] = tree.getNodesAtPoint(activeScope, point).filter(node => {
        return tree.isGroundNode(node) && isValidGuideNode(node)
    }) as GuideNode[]
    return validGroundNodes[0] || null
}
const getMousePositionInNode = (tree: CanvasTree, mouse: Point, node: CanvasNode): Point => {
    const nodeCanvasRect = tree.convertFrameToCanvas(node)
    return { x: mouse.x - nodeCanvasRect.x, y: mouse.y - nodeCanvasRect.y }
}

const findClosestGuide = (node: GuideNode, mouse: Point): { guide: GuideSelector; distance: number } | null => {
    const { guidesX, guidesY } = node
    const closestGuideX = findClosest(guidesX, mouse.x)
    const closestGuideY = findClosest(guidesY, mouse.y)

    if (closestGuideX && (!closestGuideY || closestGuideX.distance < closestGuideY.distance)) {
        return {
            guide: {
                nodeId: node.id,
                axis: "x",
                offset: closestGuideX.value,
            },
            distance: closestGuideX.distance,
        }
    }

    if (closestGuideY) {
        return {
            guide: {
                nodeId: node.id,
                axis: "y",
                offset: closestGuideY.value,
            },
            distance: closestGuideY.distance,
        }
    }

    return null
}

const findClosest = (set: Set<number>, target: number): { value: number; distance: number } | null => {
    let result: number | null = null
    let closestDistance: number = Infinity
    set.forEach((value: number) => {
        const distance = getDistance(value, target)
        if (distance < closestDistance) {
            closestDistance = distance
            result = value
        }
    })
    if (!isNumber(result)) return null

    return {
        value: result,
        distance: closestDistance,
    }
}

const getDistance = (a: number, b: number) => {
    return Math.abs(a - b)
}

export const isValidGuideNode = (node: CanvasNode): node is GuideNode => {
    if (withRotation(node) && node.rotation !== 0) return false
    return withGuides(node)
}

export const getGuideNode = (tree: CanvasTree, selector: GuideSelector): GuideNode | null => {
    const node = tree.getNode(selector.nodeId)
    return node && isValidGuideNode(node) ? node : null
}

export const containGuide = (node: GuideNode, selector: GuideSelector) => {
    const scopeNodeSelected = !selector.nodeId && node instanceof ScopeNode
    if (selector.nodeId !== node.id && !scopeNodeSelected) return false
    const guides = selector.axis === "y" ? node.guidesY : node.guidesX
    return guides.has(selector.offset)
}

export const isGuideInFrameRect = (tree: CanvasTree, node: GuideNode, selector: GuideSelector) => {
    if (node instanceof ScopeNode) return true
    const frameRect = tree.convertFrameToCanvas(node)
    const { offset, axis } = selector
    const lengthKey = axis === "x" ? "width" : "height"
    // the offset is relative to the starting point of the rect,
    // so using height/width would be enough
    return offset >= 0 && offset <= frameRect[lengthKey]
}

export const getGuidesKey = (axis: GuideSelector["axis"]): keyof WithGuides => {
    return axis === "x" ? "guidesX" : "guidesY"
}

export const getGuides = (node: GuideNode, axis: GuideSelector["axis"]): Set<number> => {
    return node[getGuidesKey(axis)]
}

export const isEqualGuide = (guideA: GuideSelector | undefined, guideB: GuideSelector | undefined) => {
    if (!guideA || !guideB) return false
    return isEqual(guideA, guideB)
}

export const getGuideAbsoluteOffset = (tree: CanvasTree, selector: GuideSelector) => {
    const guideNode = getGuideNode(tree, selector)
    if (!guideNode) return null
    const { axis, offset } = selector
    return getAbsoluteOffset(tree, guideNode, axis, offset)
}

export const getRelativeOffset = (
    tree: CanvasTree,
    node: GuideNode,
    axis: GuideSelector["axis"],
    absoluteOffset: GuideSelector["offset"]
): number => {
    const canvasRect = tree.convertFrameToCanvas(node)
    return absoluteOffset - canvasRect[axis]
}

export const getOrthogonalAxis = (axis: GuideSelector["axis"]) => (axis === "x" ? "y" : "x")
