import * as Sentry from "@sentry/browser"
import * as Integrations from "@sentry/integrations"
import { getLogger } from "@framerjs/shared"
import { channelToParentFrame, channelToOpenerFrame, ServiceManager, ServiceDebugging } from "@framerjs/framer-services"
import { unhandledError, setErrorReporter } from "@framerjs/shared"

let didInitialize = false
const userAgent = (typeof window !== "undefined" && window.navigator?.userAgent) || ""
const devMode = typeof window !== "undefined" && window.location?.host === "localhost:8009"

/**
 * Represents the state and basic settings of the current browser frame environment.
 */
export const environment = {
    /** Are we running in debug mode? E.g. with non-production React. */
    isDebugBuild: process.env.BUILD_TYPE !== "production" && process.env.NODE_ENV !== "test",

    isTest: process.env.NODE_ENV === "test",
    isDevMode: devMode,

    userAgent,
}

/**
 * Initializes a browser frame with settings, debugging setup and sanity checks.
 * This should be called before anything in React mounts.
 */
export function initializeEnvironment({
    name,
    sentryDSN,
    security,
}: {
    name: string
    sentryDSN?: string
    security: {
        allowChannelToParentWithOrigin?: string
        allowChannelToOpenerWithOrigin?: string
    }
}): void {
    if (didInitialize) return
    didInitialize = true

    // Error reporting. When testing error reporting locally, remove the isDebugBuild clause and the "environment" key
    // in the Sentry config. Also make sure localhost errors aren't ignored in Sentry's project settings.
    if (sentryDSN && !environment.isDebugBuild) {
        Sentry.init({
            dsn: sentryDSN,
            environment: process.env.BUILD_TYPE,
            release: process.env.VERSION,
            ignoreErrors: ["Component exceeded time limit"],
            integrations: [new Integrations.RewriteFrames()],
        })
    }

    // Use Sentry for logging infrastructure error reporting
    setErrorReporter(({ error, tags, extras }) => {
        Sentry.withScope(scope => {
            scope.setTags(tags)
            extras && scope.setExtras(extras)
            Sentry.captureException(error)
        })
    })

    // Services debugging
    ServiceDebugging.log = getLogger(name).extend("services")

    // Configure origin checks for the standard channels
    if (security.allowChannelToParentWithOrigin) {
        channelToParentFrame.initializeTrustedOrigin(security.allowChannelToParentWithOrigin)
    }
    if (security.allowChannelToOpenerWithOrigin) {
        channelToOpenerFrame.initializeTrustedOrigin(security.allowChannelToOpenerWithOrigin)
    }

    // Clean up services provided to the parent/opener frame while we can still get messages across the channel
    window.addEventListener("unload", () => {
        ServiceDebugging.log.debug("Unregistering services to parent/opener frame when unloading")
        ServiceManager.shared().unregister(channelToParentFrame).catch(unhandledError)
        ServiceManager.shared().unregister(channelToOpenerFrame).catch(unhandledError)
    })
}
