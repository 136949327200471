import { isEqual } from "../../utils/isEqual"

export interface DeviceResolution {
    width: number
    height: number
    pixelRatio: number
}

export function isSimilarDeviceResolution(a: DeviceResolution, b: DeviceResolution): boolean {
    if (isEqual(a, b)) return true

    const { screenSize: screenSizeA, aspectRatio: aspectRatioA } = screenSize(a)
    const { screenSize: screenSizeB, aspectRatio: aspectRatioB } = screenSize(b)

    // We consider the devices similar if the diagonal screen size and
    // aspect ratio's are similar. We mostly rely on the aspect ratio for
    // distinguishing between a phone & tablet and a very loose screenSize
    // check.
    if (isSimilar(screenSizeA, screenSizeB, { fuzz: 0.5 }) && isSimilar(aspectRatioA, aspectRatioB, { fuzz: 0.19 })) {
        return true
    }

    return false
}

/** Converts a screen rect into it's diagonal length and aspectRatio for comparison */
function screenSize({ width, height, pixelRatio }: DeviceResolution): { screenSize: number; aspectRatio: number } {
    const landscapeWidth = Math.max(width, height) / pixelRatio
    const landscapeHeight = Math.min(width, height) / pixelRatio
    return {
        screenSize: Math.sqrt(((landscapeWidth * landscapeWidth) / landscapeHeight) * landscapeWidth),
        aspectRatio: landscapeWidth / landscapeHeight,
    }
}

/**
 * Compares two values, if the percentage difference between them is within
 * the `fuzz` boundary (default: 10%) the two are considered similar and
 * the function returns true.
 */
function isSimilar(a: number, b: number, { fuzz = 0.1 } = {}): boolean {
    const low = Math.min(a, b)
    const high = Math.max(a, b)
    const diff = 1 - low / high
    return diff >= 0 && diff <= fuzz
}
