import type { CanvasNode } from "document/models/CanvasTree"

export interface WithBorderPerSide {
    borderPerSide: boolean | undefined
    borderTop: number | undefined
    borderRight: number | undefined
    borderBottom: number | undefined
    borderLeft: number | undefined
}

export const borderPerSideDefaults: WithBorderPerSide = {
    borderPerSide: undefined,
    borderTop: undefined,
    borderRight: undefined,
    borderBottom: undefined,
    borderLeft: undefined,
}

const key: keyof WithBorderPerSide = "borderPerSide"

export function withBorderPerSide(node: CanvasNode): node is CanvasNode & WithBorderPerSide {
    return key in node
}
