import type { ShadowTokenMap } from "./shadowTypes"

export const shadowsDark: ShadowTokenMap = {
    modal: "0px 10px 30px 0px rgba(0, 0, 0, 0.15)",
    popover: "0 2px 10px rgba(0, 0, 0, 0.1)",
    popoverArrowDropShadow: "drop-shadow(0px 5px 6px rgba(0, 0, 0))",
    buttonSelected: "0px 1px 3px 0px rgba(0, 0, 0, 0.5), 0px 1px 0px 0px rgba(0, 0, 0, 0.05)",
    sliderKnob: "0 1px 0 1px rgba(0,0,0,0.2), 0 0 0 1px rgba(0,0,0,0.2)",
    card: "0 5px 20px rgba(0, 0, 0, 0.3)",
    cardActive: "0px 0px 12px 0px rgba(0, 153, 255, 0)",
    imageBorderOverlay: "inset 0 0 0 1px rgba(255, 255, 255, 0.1)",
    insertMenuArtwork: "inset 0 0 0 1px rgba(255, 255, 255, 0.075)",
    tintOutline: "0px 0px 0px 5px rgba(0, 153, 255, 0.4)",
    tintOutlineSmall: "0px 0px 0px 2px rgba(0, 153, 255, 0.4)",
    componentOutline: "0px 0px 0px 5px rgba(136, 85, 255, 0.4)",
    componentOutlineSmall: "0px 0px 0px 5px rgba(136, 85, 255, 0.4)",
    svgScreenIcon: "drop-shadow(0 5px 10px rgba(0, 0, 0, 0.3))",
    dashboardNewProjectCard: "0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 1px 0 0 rgba(0, 0, 0, 0.05)",
}
