import type { CanvasNode } from "document/models/CanvasTree"
import type { VariableReference } from "./VariableReference"
import { stylableNode } from "document/models/CanvasTree/traits/utils/stylableNode"

export interface Filters {
    brightness: number | VariableReference | undefined
    contrast: number | VariableReference | undefined
    grayscale: number | VariableReference | undefined
    hueRotate: number | VariableReference | undefined
    invert: number | VariableReference | undefined
    saturate: number | VariableReference | undefined
    sepia: number | VariableReference | undefined
}

export interface WithFilters extends Filters {
    blur: number | VariableReference | undefined
    backgroundBlur: number | VariableReference | undefined
}

export const filterDefaults: WithFilters = {
    brightness: undefined,
    contrast: undefined,
    grayscale: undefined,
    hueRotate: undefined,
    invert: undefined,
    saturate: undefined,
    sepia: undefined,
    blur: undefined,
    backgroundBlur: undefined,
}

const key: keyof WithFilters = "brightness"

export function withFilters(node: CanvasNode): node is CanvasNode & WithFilters {
    return key in node && stylableNode(node)
}
