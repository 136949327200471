import { useCallback, useEffect } from "react"

export interface FocusTrapOptions {
    ref: React.Ref<HTMLElement>
    active: boolean
}

function getRefElement(ref: React.Ref<HTMLElement>) {
    if (ref && typeof ref === "object") {
        return ref.current
    }
}

export function useFocusTrap({ ref, active }: FocusTrapOptions) {
    const trapFocus = useCallback(() => {
        const element = getRefElement(ref)
        if (!element) return

        // The active element isn't known synchronously
        setTimeout(() => {
            if (!element.contains(document.activeElement)) {
                element.focus()
            }
        }, 0)
    }, [ref])

    useEffect(() => {
        if (!active) return
        const element = getRefElement(ref)
        if (!element) return

        trapFocus()

        element.addEventListener("focusout", trapFocus)

        return () => {
            element.removeEventListener("focusout", trapFocus)
        }
    }, [active, ref, trapFocus])
}
