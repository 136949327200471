import { ActionHandler, ControlType } from "framer"
import { addActionControls } from "./actionControls"

export interface OpenURLOptions {
    url: string
}

export function useOpenURL(options: OpenURLOptions): ActionHandler {
    return () => {
        const { url } = options
        if (!url) return
        const validURL = url.includes(":") ? url : `http://${url}`
        window.open(validURL)
    }
}

addActionControls(useOpenURL, "Open Link", {
    url: { type: ControlType.String, placeholder: "www.framer.com", title: "URL" },
})
