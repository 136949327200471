import type { WithOptionalFill, WithFill } from "document/models/CanvasTree/traits/Fill"

export const fillDefaults: WithOptionalFill = {
    fillEnabled: true,
    fillType: "color",
    fillColor: "rgba(255,255,255,1)",
    fillLinearGradient: undefined,
    fillRadialGradient: undefined,
    fillImage: null,
    fillImageOriginalName: null,
    fillImageResize: "fill",
    fillImagePixelWidth: null,
    fillImagePixelHeight: null,
}

export const codeComponentFillDefaults: WithOptionalFill = {
    ...fillDefaults,
    fillEnabled: false,
}

export const svgFillDefaults: WithFill = {
    fillType: "color",
    fillColor: "rgba(0,0,0,1)",
    fillLinearGradient: undefined,
    fillRadialGradient: undefined,
    fillImage: null,
    fillImageOriginalName: null,
    fillImageResize: "fill",
    fillImagePixelWidth: null,
    fillImagePixelHeight: null,
}

export const pathFillDefaults: WithOptionalFill = {
    fillEnabled: false,
    fillType: "color",
    fillColor: "rgba(0,170,255,0.5)",
    fillLinearGradient: undefined,
    fillRadialGradient: undefined,
    fillImage: null,
    fillImageOriginalName: null,
    fillImageResize: "fill",
    fillImagePixelWidth: null,
    fillImagePixelHeight: null,
}

export const shapeFillDefaults: WithOptionalFill = {
    fillEnabled: true,
    fillType: "color",
    fillColor: "#CCC",
    fillLinearGradient: undefined,
    fillRadialGradient: undefined,
    fillImage: null,
    fillImageOriginalName: null,
    fillImageResize: "fill",
    fillImagePixelWidth: null,
    fillImagePixelHeight: null,
}

export const shapeContainerFillDefaults: WithOptionalFill = {
    fillEnabled: true,
    fillType: "color",
    fillColor: "#FFF",
    fillLinearGradient: undefined,
    fillRadialGradient: undefined,
    fillImage: null,
    fillImageOriginalName: null,
    fillImageResize: "fill",
    fillImagePixelWidth: null,
    fillImagePixelHeight: null,
}
