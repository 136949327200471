import type { CanvasNode } from "document/models/CanvasTree"

export enum PathBooleanType {
    Unite,
    Subtract,
    Intersect,
    Exclude,
    Join,
}

export interface WithPathBoolean {
    pathBoolean: PathBooleanType
}

export const pathBooleanDefaults: WithPathBoolean = {
    pathBoolean: PathBooleanType.Unite,
}

const key: keyof WithPathBoolean = "pathBoolean"

export function withBooleanPath(node: CanvasNode): node is CanvasNode & WithPathBoolean {
    return key in node
}

export function nameForPathBooleanType(type: PathBooleanType): string {
    switch (type) {
        case PathBooleanType.Unite:
            return "Unite"
        case PathBooleanType.Intersect:
            return "Intersect"
        case PathBooleanType.Exclude:
            return "Exclude"
        case PathBooleanType.Subtract:
            return "Subtract"
        case PathBooleanType.Join:
            return "Join"
    }
}
