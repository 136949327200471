import { nameDefaults } from "document/models/CanvasTree/traits/Name"
import { sizeDefaults } from "document/models/CanvasTree/traits/Size"
import { pinDefaults } from "document/models/CanvasTree/traits/Pins"
import { basicChildrenDefaults } from "document/models/CanvasTree/traits/Children"
import { visibilityDefaults } from "document/models/CanvasTree/traits/Visibility"
import { lockDefaults } from "document/models/CanvasTree/traits/Lock"
import { opacityDefaults } from "document/models/CanvasTree/traits/Opacity"
import { withExportDefaults, withExportIncludesBackgroundDefaults } from "document/models/CanvasTree/traits/Export"
import { shapeContainerFillDefaults } from "document/models/CanvasTree/traits/FillDefaults"
import { autoSizeDefaults } from "document/models/CanvasTree/traits/AutoSize"
import { sizeToFitDefaults } from "document/models/CanvasTree/traits/SizeToFit"
import { radiusDefaults } from "document/models/CanvasTree/traits/Radius"
import { radiusPerCornerDefaults } from "document/models/CanvasTree/traits/RadiusPerCorner"
import { previewSettingsDefaults } from "../traits/PreviewSettings"
import { frameEventDefaults } from "document/models/CanvasTree/traits/FrameEvents"
import { memoRecord } from "./memoRecord"

export const getShapeContainerRecord = memoRecord(() => {
    return {
        ...nameDefaults,
        ...sizeDefaults,
        ...basicChildrenDefaults,
        ...pinDefaults,
        ...autoSizeDefaults,
        ...sizeToFitDefaults,
        ...visibilityDefaults,
        ...lockDefaults,
        ...opacityDefaults,
        ...shapeContainerFillDefaults,
        ...radiusDefaults,
        ...radiusPerCornerDefaults,
        ...withExportDefaults,
        ...withExportIncludesBackgroundDefaults,
        ...previewSettingsDefaults,
        ...frameEventDefaults,
    }
})
