import type { CanvasNode } from "../nodes/CanvasNode"

export interface WithClearResolvedCache {
    clearResolvedCache(): void
}

const key: keyof WithClearResolvedCache = "clearResolvedCache"

/** Allows caches to be cleared that rely on variable data. */
export function withClearResolvedCache(node: CanvasNode): node is CanvasNode & WithClearResolvedCache {
    return key in node
}
