// Default Experiments are the ones used on Web
export const defaultExperiments = {
    disableLayoutGuessing: "off",
    disableSecureCanvas: "off",
    domLayoutDebugging: "off",
    domLayoutStack: "off",
    domLayoutStackAutoSizing: "off",
    insertMenuAssets: "on",
    rawDraftRendering: "on",
    resizableInsertMenu: "off",
    sandboxExport: "off",
    sandboxExportDebugging: "off",
    sandboxLegacyCanvas: "on",
    sandboxNodeDebugging: "off",
    unifiedPreview: "on",
    codeModules: "off",
    componentSharing: "off",
    authRefreshingClient: "off",
    simplifiedTriggers: "off",
    deprecatedCodeComponentSizeWrapper: "on",
    deprecatedCodeComponentPropsCheck: "on",
}

type Experiments = typeof defaultExperiments
export type ExperimentName = keyof Experiments

export const desktopExperiments: Experiments = {
    ...defaultExperiments,
    unifiedPreview: "off",
}

export const employeeExperiments: Experiments = {
    ...defaultExperiments,
    disableLayoutGuessing: "on",
    domLayoutStack: "on",
    authRefreshingClient: "on",
    sandboxLegacyCanvas: "off",
    simplifiedTriggers: "on",
}
