import { ConvertColor } from "../types/Color/ConvertColor"
import { escapeHTML } from "./escapeHTML"

/**
 * Return the css formatted number string value with the given or a default unit.
 * @internal
 */
export function getCSSNumberValueWithUnit(value: string | number | undefined, defaultUnit: string): string | undefined {
    // Parse the empty values
    if (typeof value === "undefined") return
    if (typeof value === "string" && value.trim() === "") return

    // If this is a number we format it
    if (typeof value === "number") return `${value}${defaultUnit}`

    // If the string only contains a number, we also format it
    if (parseFloat(value).toString() === value) return `${value}${defaultUnit}`

    // If not we're going to assume this is a string with units included
    return value
}

/**
 * @internal
 */
export function getCSSColorFromStyle(css: React.CSSProperties) {
    let color

    if (typeof css["webkitTextFillColor"] === "string") {
        color = css["webkitTextFillColor"]
    }
    if (typeof css.WebkitTextFillColor === "string") {
        color = css.WebkitTextFillColor
    }
    if (typeof css.color === "string") {
        color = css.color
    }
    if (color) {
        return ConvertColor.toRgbString(color)
    }
}

/**
 * @internal
 */
export function getCSSTextAlignment(value?: any): "left" | "center" | "right" | undefined {
    if (typeof value === "string") value = value.toLowerCase()
    if (["left", "center", "right"].includes(value)) return value
}

/**
 * @internal
 */
export function getCSSNumericValueFromString(value: any, unit = ""): number | string {
    if (typeof value === "number") return value
    if (typeof value === "string") {
        if (unit && value.toLowerCase().endsWith(unit)) {
            return parseFloat(value)
        }
        return value
    }
    return value
}

/**
 * @internal
 *
 * We want to keep floats as strings to avoid weird floating point errors on user entered values
 */
export function getCSSNumericValueAsString(value: string | number | undefined): string | undefined {
    if (value === undefined) return
    if (typeof value === "number") {
        if (Number.isNaN(value)) return
        return `${value}`
    }
    if (Number.isNaN(parseFloat(value))) return
    return value
}

/**
 * Return the guessed unit for a number string
 * @example getCSSNumberUnit("12em"): [12, "em"]
 * @internal
 */
export function getCSSNumberUnit(
    value: string | number,
    defaultUnit = "",
    units = ["", "%", "cm", "em", "ex", "in", "mm", "pc", "pt", "px", "vw", "vh"]
): [number | null, string] {
    // If we got passed a number we don't have to convert anything
    if (typeof value === "number") return [value, defaultUnit]

    // See if we can convert this value to a number
    const num = parseFloat(value)
    if (Number.isNaN(num)) return [null, ""]

    // See if we can find a valid unit using a regex and only allowing valid ones
    const match = String(value).match(/[\d.\-+]*\s*(.*)/)
    if (!match) return [null, ""]
    if (!units.includes(match[1])) return [null, ""]

    return [parseFloat(value), match[1] || defaultUnit]
}

/**
 * Convert an object with css properties to an inline string to insert in html
 * @internal
 */
export function cssToString(css: Partial<CSSStyleDeclaration>): string {
    const inline = Object.keys(css)
        // Ignore undefined and null values
        .filter(k => typeof css[k] !== "undefined" && css[k] !== null)
        // Convert to inline key:value style string
        .map(k => `${k}: ${css[k]}`)
        .join("; ")
    return escapeHTML(inline)
}
