import type { CodeComponentNode } from "../.."
import type { Transition } from "document/models/Transition"
import type { ComponentLoaderInterface, EntityDefinition } from "@framerjs/framer-runtime"
import type { ActionControls, ObjectControlDescription } from "framer"
import type {
    ControlProp,
    ArrayControlProp,
    CodeComponentProps,
    ArrayValue,
    FusedNumberControlProp,
} from "../CodeComponent"
import { isReactComponentDefinition } from "@framerjs/framer-runtime"
import { isControlDescription, DefaultAssetReferenceKey } from "@framerjs/framer-runtime/sandbox"
import { ArrayControlDescription, ControlDescription, ControlType, PropertyControls } from "framer"
import {
    isValidPropertyValue,
    isValidFusedNumberPropertyValue,
} from "document/components/chrome/properties/codeComponentRows/utils/isValidPropertyValue"
import { randomID } from "../../nodes/NodeID"
import { isNumber, isBoolean, isObject } from "utils/typeChecks"
import { CONTROL_PREFIX, SlotLink } from "../CodeComponent"
import { fallbackTransition } from "document/models/Transition"

export function isValidArrayControlProp(controlProp: ControlProp): controlProp is ArrayControlProp {
    // If the runtime migration in updateCodeComponentsInDocument does not happen in time,
    // the control type could still be null.
    return (controlProp.type === null || controlProp.type === ControlType.Array) && Array.isArray(controlProp.value)
}

interface ValidSlotControlProp {
    type: ControlType.Array | ControlType.ComponentInstance | null
    value: SlotLink[]
}

export function isValidSlotControlProp(controlProp: ControlProp): controlProp is ValidSlotControlProp {
    if (controlProp.type && ![ControlType.Array, ControlType.ComponentInstance].includes(controlProp.type)) return false
    if (!Array.isArray(controlProp.value)) return false
    return controlProp.value.every(item => item.type === ControlType.ComponentInstance)
}

export function isCodeComponentProp(propKey: string) {
    return propKey.startsWith(CONTROL_PREFIX)
}

export function propWithoutControlPrefix(propKey: string) {
    return propKey.substring(CONTROL_PREFIX.length)
}

export function prefixControlProps(update: CodeComponentProps) {
    const props: CodeComponentProps = {}
    for (const key in update) {
        props[prefixControlKey(key)] = update[key]
    }

    return props
}

export function prefixControlKey(key: keyof CodeComponentProps) {
    return `${CONTROL_PREFIX}${key}`
}

export function getControlPropsValues<T extends CodeComponentProps>(controlProps: T): { [key in keyof T]: any } {
    // initialize the object with the input to map the type
    const result: { [key in keyof T]: any } = { ...controlProps }
    for (const key in controlProps) {
        result[key] = controlProps[key]?.value
    }
    return result
}

function exposedCodeComponentProps(
    controls: PropertyControls | ActionControls
): [string[], { [key: string]: ControlDescription }] {
    const result: string[] = []
    const propertyMap: { [key: string]: ControlDescription } = {}

    const propertyKeys = Object.keys(controls)
    for (let i = 0, il = propertyKeys.length; i < il; i++) {
        const key = propertyKeys[i]
        const control = controls[key]
        if (!control) continue

        result.push(key)
        propertyMap[key] = control
    }

    return [result, propertyMap]
}

export function defaultControlValuesWithTypes(
    controls: PropertyControls | ActionControls,
    defaults?: Partial<any>
): { [key: string]: ControlProp } {
    const [keys, propertyMap] = exposedCodeComponentProps(controls)

    const result: { [key: string]: ControlProp } = {}

    for (let i = 0, il = keys.length; i < il; i++) {
        const key = keys[i]
        const property = propertyMap[key]
        result[key] = getControlValueWithType(key, {}, defaults, property)
    }
    return result
}

export function defaultCodeComponentProps(component: EntityDefinition | null): { [key: string]: ControlProp } {
    if (!component || !isReactComponentDefinition(component)) return {}
    const { defaultProps } = component
    return defaultControlValuesWithTypes(component.properties, defaultProps)
}

/**
 * Returns control props that have been added to the component definition after the node has been created. And
 * returns control props that were missing control types.
 * The types are missing from documents older than tree version 74. During the migration from 73 to 74 the types
 * could not be added because the component definition is not available during migrations.
 */
export function getMissingAndUntypedControlProps(
    node: CodeComponentNode,
    componentDefinitionProvider: ComponentLoaderInterface
): CodeComponentProps | null {
    const component = componentDefinitionProvider.componentForIdentifier(node.codeComponentIdentifier)

    if (!component || !isReactComponentDefinition(component)) return null
    if (!component.properties) return null

    const [keys, propertyMap] = exposedCodeComponentProps(component.properties)
    const { defaultProps } = component

    const result: CodeComponentProps = {}
    const controlProps = node.getControlProps()

    for (let i = 0, il = keys.length; i < il; i++) {
        const key = keys[i]
        const property = propertyMap[key]
        if (!isControlDescription(property)) continue
        const controlProp = controlProps[key]
        // Skip any props that already have the correct type
        if (controlProp && controlProp.type === property.type) continue

        let controlValue = getControlValueWithType(key, controlProps, defaultProps, property)

        // Assemble fusednumber control from depracated fusednumber props.
        // This should only happen once when the type is undefined (resulted from the migration).
        if (property.type === ControlType.FusedNumber && controlProp && controlProp.type === null) {
            controlValue = {
                type: ControlType.FusedNumber,
                isFused: Boolean(controlProps[property.toggleKey as string]?.value),
                value: {
                    single: controlProp.value,
                    fused: property.valueKeys.map(valueKey => controlProps[valueKey as string]?.value ?? 0),
                },
            }
        }

        result[key] = controlValue
    }

    return Object.keys(result).length > 0 ? result : null
}

export function getControlValueWithType(
    key: string,
    codeComponentProps: CodeComponentProps,
    defaults: { [key: string]: unknown } | undefined,
    property: ControlDescription
): ControlProp {
    const { type } = property

    if (key in codeComponentProps) {
        const currentProp = codeComponentProps[key]
        if (currentProp && isValidValue(currentProp.value, property)) return { ...currentProp, type }
    }

    // Note that for fallback values we don't set `undefined` on the object because
    // the addition of the key would trigger a diff on the tree. (The key won't exist
    // in the loaded tree since JSON doesn't serialize `undefined`.)

    const propertyDefault = getPropertyControlDefault(property)
    if (isValidValue(propertyDefault, property)) {
        return {
            type,
            ...(propertyDefault !== undefined && { value: propertyDefault }),
            ...(type === ControlType.FusedNumber && { isFused: false }),
        }
    }

    const reactDefault = getReactDefaultWithType(defaults, key, property)
    if (isValidValue(reactDefault.value, property)) {
        return reactDefault
    }

    const value = getPropertyFallbackValue(property)
    return {
        type,
        ...(value !== undefined && { value }),
        ...(type === ControlType.FusedNumber && { isFused: false }),
    }
}

function isValidValue(value: unknown, control: ControlDescription): boolean {
    if (control.type === ControlType.Array) {
        return isValidArray(value, control.control)
    } else if (control.type === ControlType.ComponentInstance) {
        return isValidArray(value, control)
    }

    return isValidPropertyValue(control, value)
}

function isValidArray(array: unknown, control: ControlDescription): boolean {
    if (!Array.isArray(array)) {
        return false
    }
    for (const valueItem of array as ArrayValue[]) {
        if (valueItem.type !== control.type) {
            return false
        }

        if (valueItem.type === ControlType.FusedNumber) {
            return isValidFusedNumberPropertyValue(valueItem.value)
        }

        if (!isValidPropertyValue(control, valueItem.value)) {
            return false
        }
    }
    return true
}

function convertArrayDefaultToArrayValue(defaultValue: unknown, property: ArrayControlDescription) {
    if (!Array.isArray(defaultValue)) return

    const result: ArrayValue[] = []
    const { control } = property

    for (const defaultItem of defaultValue) {
        if (control.type === ControlType.FusedNumber) {
            const item: FusedNumberControlProp = {
                type: ControlType.FusedNumber,
                isFused: false,
                value: getPropertyFallbackValue(control) as FusedNumberControlProp["value"],
            }

            if (isNumber(defaultItem)) {
                item.value.single = defaultItem
            }

            result.push({ id: randomID(), value: item, type: control.type })
        } else if (isValidValue(defaultItem, control)) {
            result.push({ id: randomID(), value: defaultItem, type: control.type })
        }
    }
    return result
}

function convertObjectDefaultToObjectValue(defaultValue: unknown, property: ObjectControlDescription) {
    const defaultObjectValue = isObject(defaultValue) ? defaultValue : {}

    const result: { [key: string]: ControlProp } = {}

    Object.entries(property.controls).forEach(([key, control]) => {
        result[key] = getControlValueWithType(key, {}, defaultObjectValue, control)
    })

    return Object.keys(result).length > 0 ? result : undefined
}

function getReactDefaultWithType(
    defaults: { [key: string]: unknown } | undefined,
    key: string,
    property: ControlDescription
): ControlProp {
    if (!defaults) return { type: property.type, value: undefined }

    switch (property.type) {
        case ControlType.Array: {
            const defaultValue = defaults[key]
            const arrayValue = convertArrayDefaultToArrayValue(defaultValue, property)
            return { type: ControlType.Array, value: arrayValue }
        }
        case ControlType.Object: {
            const defaultValue = defaults[key]
            const objectValue = convertObjectDefaultToObjectValue(defaultValue, property)
            return { type: ControlType.Object, value: objectValue }
        }
        case ControlType.FusedNumber: {
            // collect all the react default that's targeted at fused number,
            // fill in the rest with fallback values
            const result: FusedNumberControlProp = {
                type: ControlType.FusedNumber,
                isFused: false,
                value: getPropertyFallbackValue(property) as FusedNumberControlProp["value"],
            }

            const { toggleKey, valueKeys } = property
            const singleDefault = defaults[key]
            if (isNumber(singleDefault)) {
                result.value.single = singleDefault
            }

            const toggleDefault = defaults[toggleKey as string]
            if (isBoolean(toggleDefault)) {
                result.isFused = toggleDefault
            }

            valueKeys.forEach((valueKey, idx) => {
                const fusedDefault = defaults[valueKey as string]
                if (isNumber(fusedDefault)) {
                    result.value.fused[idx] = fusedDefault
                }
            })
            return result
        }
        default:
            return { type: property.type, value: defaults[key] }
    }
}

export function getPropertyControlDefault(property: ControlDescription) {
    if (!property || !isObject(property)) return undefined

    if (property.type === ControlType.Image && DefaultAssetReferenceKey in property) {
        return property[DefaultAssetReferenceKey]
    }

    switch (property.type) {
        case ControlType.Array:
            return convertArrayDefaultToArrayValue(property.defaultValue, property)
        case ControlType.Object:
            return convertObjectDefaultToObjectValue(property.defaultValue, property)
        case ControlType.FusedNumber:
            if (!isNumber(property.defaultValue)) return undefined
            return { single: property.defaultValue, fused: Array(4).fill(property.defaultValue) }
        default:
            return property.defaultValue
    }
}

export function getFallbackValue(type: ControlType.Boolean): boolean
export function getFallbackValue(type: ControlType.Number): number
export function getFallbackValue(type: ControlType.String): string
export function getFallbackValue(type: ControlType.Color): string
export function getFallbackValue(type: ControlType.Transition): Transition
export function getFallbackValue(type: ControlType.Image): undefined
export function getFallbackValue(type: ControlType.EventHandler): undefined
export function getFallbackValue(type: ControlType): unknown
export function getFallbackValue(type: ControlType): unknown {
    switch (type) {
        case ControlType.Boolean:
            return true
        case ControlType.Number:
            return 0
        case ControlType.String:
            return ""
        case ControlType.Color:
            return "#09F"
        case ControlType.FusedNumber:
            return { single: 0, fused: [0, 0, 0, 0] }
        case ControlType.Transition:
            return fallbackTransition
        case ControlType.Object:
            return {}
        case ControlType.Image:
        case ControlType.File:
        case ControlType.ComponentInstance:
        case ControlType.EventHandler:
        case ControlType.Enum:
        case ControlType.SegmentedEnum:
        default:
            return undefined
    }
}

export function getPropertyFallbackValue(property: ControlDescription) {
    switch (property.type) {
        case ControlType.Number: {
            let value = getFallbackValue(property.type)
            if (isNumber(property.min)) {
                value = Math.max(property.min, value)
            }
            if (isNumber(property.max)) {
                value = Math.min(property.max, value)
            }
            return value
        }
        case ControlType.Enum:
        case ControlType.SegmentedEnum:
            return property.options[0]
        default:
            return getFallbackValue(property.type)
    }
}
