import type { MotionStyle } from "framer-motion"
import type { CanvasNode } from "../../nodes/CanvasNode"
import type { CollectTraitOptions } from "."
import { withBorder } from "document/models/CanvasTree/traits/Border"
import { withBorderPerSide } from "document/models/CanvasTree/traits/BorderPerSide"
import { withShape } from "../Shape"
import { ConvertColor } from "framer"
import { isTokenCSSVariable } from "../../nodes/TokenNode"

export function collectBorder(node: CanvasNode, style: MotionStyle): void {
    if (!withBorder(node) || !node.borderEnabled || withShape(node)) return

    if (withBorderPerSide(node) && node.borderPerSide) {
        style.borderTopWidth = node.borderTop
        style.borderBottomWidth = node.borderBottom
        style.borderLeftWidth = node.borderLeft
        style.borderRightWidth = node.borderRight
    } else {
        const width = node.borderWidth
        style.borderTopWidth = style.borderBottomWidth = style.borderLeftWidth = style.borderRightWidth = width
    }

    style.borderStyle = node.borderStyle
    style.borderColor = node.borderColor
}

const BORDER_LEFT_WIDTH_VAR = "--border-left-width"
const BORDER_RIGHT_WIDTH_VAR = "--border-right-width"
const BORDER_TOP_WIDTH_VAR = "--border-top-width"
const BORDER_BOTTOM_WIDTH_VAR = "--border-bottom-width"
const BORDER_STYLE_VAR = "--border-style"
const BORDER_COLOR_VAR = "--border-color"

const DEFAULT_BORDER_WIDTH = "0px"

export function collectBorderVariables(
    node: CanvasNode,
    style: MotionStyle,
    { unset = false }: CollectTraitOptions = {}
) {
    if (!withBorder(node)) return
    if (!node.borderEnabled) {
        if (unset) {
            style[BORDER_LEFT_WIDTH_VAR] = style[BORDER_RIGHT_WIDTH_VAR] = style[BORDER_TOP_WIDTH_VAR] = style[
                BORDER_BOTTOM_WIDTH_VAR
            ] = DEFAULT_BORDER_WIDTH
        }
        return
    }

    if (withBorderPerSide(node) && node.borderPerSide) {
        style[BORDER_LEFT_WIDTH_VAR] = node.borderLeft + "px"
        style[BORDER_RIGHT_WIDTH_VAR] = node.borderRight + "px"
        style[BORDER_TOP_WIDTH_VAR] = node.borderTop + "px"
        style[BORDER_BOTTOM_WIDTH_VAR] = node.borderBottom + "px"
    } else {
        style[BORDER_LEFT_WIDTH_VAR] = style[BORDER_RIGHT_WIDTH_VAR] = style[BORDER_TOP_WIDTH_VAR] = style[
            BORDER_BOTTOM_WIDTH_VAR
        ] = node.borderWidth + "px"
    }

    style[BORDER_STYLE_VAR] = node.borderStyle
    style[BORDER_COLOR_VAR] =
        isTokenCSSVariable(node.borderColor) || node.borderColor === undefined
            ? node.borderColor
            : ConvertColor.toRgbString(node.borderColor)
}
