export enum HardCodedCodeIdentifier {
    scroll = "framer/Scroll",
    page = "framer/Page",
    stack = "framer/Stack",
    device = "__builtin/Device",
    legacyDevice = "@framer/framer.default/./Device.tsx_Device",
    legacyUnsplash = "@framer/framer.default/./Unsplash.tsx_Unsplash",
    navigationAction = "framer/useNavigate",
    openURLAction = "framer/useOpenURL",
    consoleLogAction = "framer/useLog",
    setVariantAction = "framer/useSetVariant",
    triggerEventAction = "framer/useTriggerEvent",
}

export const ALL_IDENTIFIERS: string[] = Object.values(HardCodedCodeIdentifier)
