import * as React from "react"
import { useEffect, useState } from "react"
import { Frame } from "framer"
import { assertNever } from "@framerjs/shared"

enum PlaceholderType {
    Loading,
    Error,
}

const baseStyle: React.CSSProperties = {
    background: undefined,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "1.4em",
    textOverflow: "ellipsis",
    overflow: "hidden",
    minHeight: 0,
}

const textStyle: React.CSSProperties = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    flexShrink: 0,
    padding: `0 10px`,
}

const titleStyle: React.CSSProperties = {
    ...textStyle,
    // TODO: Use Fresco tokens for this.
    fontWeight: 500,
}

const messageStyle: React.CSSProperties = {
    ...textStyle,
    whiteSpace: "pre",
    maxHeight: "calc(50% - calc(20px * var(--framerInternalCanvas-canvasPlaceholderContentScaleFactor, 1)))",
    WebkitMaskImage: "linear-gradient(to bottom, black 80%, transparent 100%)",
}

export function RenderPlaceholder(props: { error?: { error: unknown; file?: string } }) {
    if (process.env.NODE_ENV !== "production" && window["perf"]) window["perf"].nodeRender()
    let type: PlaceholderType = PlaceholderType.Loading
    let title: string | undefined
    let message: string | undefined

    if (props.error) {
        type = PlaceholderType.Error
        const { error, file } = props.error
        title = file
        message = error instanceof Error ? error.message : "" + error
    }

    // Wait a bit before considering the placeholder "settled". Only show
    // error state etc. once it settles to avoid flashing errors.
    const [isSettled, setIsSettled] = useState(false)
    useEffect(() => {
        const timer = window.setTimeout(() => {
            setIsSettled(true)
        }, 1000)
        return () => clearTimeout(timer)
    }, [type])

    return (
        <Frame className={className(type)} style={baseStyle} width="100%" height="100%">
            <div className={isSettled ? "text" : "pending text"} style={titleStyle}>
                {placeholderTitle(type, title)}
            </div>
            {message && (
                <div className={isSettled ? "text" : "pending text"} style={messageStyle}>
                    {message}
                </div>
            )}
        </Frame>
    )
}

function className(type: PlaceholderType): string {
    switch (type) {
        case PlaceholderType.Error:
            return "framerInternalUI-errorPlaceholder"
        case PlaceholderType.Loading:
            return "framerInternalUI-componentPlaceholder"
        default:
            assertNever(type)
    }
}

function placeholderTitle(type: PlaceholderType, title: string | undefined) {
    switch (type) {
        case PlaceholderType.Loading:
            return "Loading"
        case PlaceholderType.Error:
            if (!title) {
                return "Error"
            }
            return `Error in ${stripSlash(title)}`
        default:
            assertNever(type)
    }
}

function stripSlash(title: string) {
    if (title.startsWith("./")) {
        return title.replace("./", "")
    }
    return title
}
