import { V67, V68 } from "../types"
import { transform } from "../utils/transform"
import { _, exactCheck } from "../utils/exactCheck"

export function migration_67_68(documentJSON: V67.Tree): V68.Tree {
    const migratedRoot = transform(documentJSON.root, node => {
        if (node.__class !== "TextNode") return exactCheck(node, _ as V68.TreeNode)
        // TextNode should always have a styledText with the `blocks` field, if not we skip the migration
        if (!isTextNodeWithStyledText(node)) return exactCheck(node as V68.TextNode, _ as V68.TreeNode)

        const migratedStyledText = migrateStyledText(node.styledText)
        const migratedNode = { ...node, styledText: migratedStyledText }

        // The schema didn't really change, we just transform the style of the text blocks inside styledText
        return exactCheck(migratedNode, _ as V68.TreeNode)
    })
    return { version: 68, root: migratedRoot }
}

function migrateStyledText(styledText: V68.StyledText): V68.StyledText {
    if (typeof styledText !== "object") return styledText

    const newValue = styledText
    for (const block of newValue.blocks) {
        for (const inlineStyleRange of block.inlineStyleRanges) {
            inlineStyleRange.style = transformTextDecoration(inlineStyleRange.style)
        }
        if (block.data.emptyStyle) {
            block.data.emptyStyle = block.data.emptyStyle.map(transformTextDecoration)
        }
    }

    return newValue
}

function transformTextDecoration(style: string): string {
    if (style.startsWith("UNDERLINE")) {
        return "TEXTDECORATION:underline"
    }

    if (style.startsWith("STRIKETHROUGH")) {
        return "TEXTDECORATION:line-through"
    }

    return style
}

function isObject(value: unknown): value is { [key: string]: unknown } {
    return !!value && typeof value === "object"
}

function isTextNodeWithStyledText(node: V67.TextNode): node is V68.TextNode {
    return (
        isObject(node) &&
        "styledText" in node &&
        typeof node.styledText === "object" &&
        !!node.styledText &&
        "blocks" in node.styledText
    )
}
