import { cx } from "linaria"
import * as React from "react"
import { colorsDark } from "../tokens/colors/colorsDark"
import { colorsLight } from "../tokens/colors/colorsLight"
import * as styles from "./PopupButton.styles"
import type { OverrideType } from "./types"
import { svgURL } from "./utils/svgURL"
import { truncateWithEllipsis } from "./utils/truncateWithEllipsis.styles"
import type { CSSProperties } from "./types"
import { useTabIndex } from "./Tabbable"

function valueForItem<T>(identifier: T | undefined, title: string | undefined | ((identifier: T) => void)) {
    if (identifier !== undefined) return identifier
    if (typeof title === "string") return title
    return undefined
}

function titleForItem<T>(
    title: undefined | string | ((identifier: T) => string),
    identifier: T,
    selected: boolean,
    multiSelect: boolean | undefined
) {
    if (!title) return "unknown"
    const displayTitle = typeof title === "string" ? title : title(identifier)
    return multiSelect && selected ? `— ${displayTitle}` : displayTitle
}

const multiSelectContext = React.createContext(false)

const multipleSelectionTitle = "Multiple"

type PopupButtonItemElement = React.ReactElement<PopupButtonItemProps<string | number>>

// Popup Button Item

export interface PopupButtonItemProps<T extends string | number> {
    /** Distinguish items that share the same title. */
    identifier?: T
    /** The title of the item. A function can be passed that receives the identifier as an argument. */
    title?: string | ((identifier: T | undefined) => string)
    /** The event handler receives the identifier as an argument. Allowing a single function to handle multiple items. */
    onSelect?: (identifier: T | undefined) => void
    /** Displays a checkmark in front of the item. */
    selected?: boolean
    /** Allows for rendering a divider. */
    type?: "divider"
    /** Allows for hiding the element. */
    visible?: boolean
    /** Makes the item non selectable. */
    enabled?: boolean
}

function PopupButtonItem<T extends string | number = string>(props: PopupButtonItemProps<T>) {
    const { visible, type, enabled, onSelect, title, identifier, selected } = props
    const multiSelect = React.useContext(multiSelectContext)
    if (visible === false) return null
    if (type === "divider") return <hr />
    const isSelected = !!selected && !!onSelect

    return (
        <option value={valueForItem(identifier, title)} disabled={enabled === false || !onSelect}>
            {titleForItem(title, identifier, isSelected, multiSelect)}
        </option>
    )
}

// React memo breaks the use of generics in Props
const MemoPopupButtonItem = React.memo(PopupButtonItem) as typeof PopupButtonItem
export { MemoPopupButtonItem as PopupButtonItem }

function isObject(value: unknown): value is object {
    return !!value && typeof value === "object"
}

function isPopupButtonItemElement(value: unknown): value is React.ReactElement<PopupButtonItemProps<string | number>> {
    return isObject(value) && React.isValidElement(value) && value.type === MemoPopupButtonItem
}

// Popup Button Item Group
export interface PopupButtonItemGroupProps {
    label?: string
    /** All children need to be PopupButtonItem components. */
    children?: React.ReactNode
}

export const PopupButtonItemGroup = React.memo(function PopupButtonItemGroup(props: PopupButtonItemGroupProps) {
    return <optgroup label={props.label}>{props.children}</optgroup>
})

function isPopupButtonItemGroupElement(value: unknown): value is React.ReactElement<PopupButtonItemGroupProps> {
    return isObject(value) && React.isValidElement(value) && value.type === PopupButtonItemGroup
}

// Popup Button

type HTMLSelectAttributes = React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>

export type PopupButtonProps = OverrideType<
    Omit<HTMLSelectAttributes, "disabled" | "inputMode">,
    {
        /** Allows for styling the background */
        wrapperStyle?: React.CSSProperties
        /** All children need to be PopupButtonItem or PopupButtonItemGroup components. */
        children?: React.ReactNode
        /** Allows disabling the entire control. */
        enabled?: boolean
        /** Increases the button height. */
        large?: boolean
    }
>

/**
 * The PopupButton component displays a single item from a list of items, and provide an interface for selecting items from the list..
 *
 * ```jsx
 * import * as React from "react"
 *
 * const fruits = ["apple", "banana", "cherry"]
 *
 * export function MyComponent() {
 *   const [activeFruit, setActiveFruit] = useState("apple")
 *
 *   return (
 *      <PopupButton>
 *          <PopupButtonItem
 *              title={"Choose"}
 *              enabled={false}
 *              visible={!activeFruit}
 *              selected={!activeFruit} />
 *          <PopupButtonItem type="divider" />
 *          {fruits.map(fruit => {
 *              return <PopupButtonItem
 *                  key={fruit}
 *                  identifier={fruit}
 *                  title={fruit}
 *                  selected={fruit === activeFruit}
 *                  onSelect={setActiveFruit}
 *              >
 *          })}
 *      <PopupButton>)
 * }
 * ```
 */
export const PopupButton = React.memo(
    React.forwardRef(function PopupButton(props: PopupButtonProps, ref: React.Ref<HTMLSelectElement>) {
        const { className, enabled, style: styleOverride, wrapperStyle, large, children, tabIndex, ...rest } = props

        const selectedValues: Set<string | number> = new Set()

        function checkIfSelected(element: PopupButtonItemElement) {
            const { selected, visible, title, identifier } = element.props
            if (selected && visible !== false) {
                const childValue = valueForItem(identifier, title)
                if (childValue !== undefined) {
                    selectedValues.add(childValue)
                }
            }
        }

        React.Children.forEach(children, child => {
            if (isPopupButtonItemElement(child)) {
                checkIfSelected(child)
            } else if (isPopupButtonItemGroupElement(child)) {
                React.Children.forEach(child.props.children, groupChild => {
                    if (isPopupButtonItemElement(groupChild)) {
                        checkIfSelected(groupChild)
                    }
                })
            }
        })

        const multipleItemsSelected = selectedValues.size > 1

        let value: string | number | undefined
        if (selectedValues.size === 1) {
            value = selectedValues.values().next().value
        } else if (multipleItemsSelected) {
            value = multipleSelectionTitle
        }

        const onChange = React.useCallback(
            (event: React.ChangeEvent<HTMLSelectElement>) => {
                let { selectedIndex } = event.target
                if (multipleItemsSelected) selectedIndex--

                const childElements: PopupButtonItemElement[] = []

                function addToChildElements(element: PopupButtonItemElement) {
                    const { type, visible } = element.props
                    if (type !== "divider" && visible !== false) childElements.push(element)
                }

                React.Children.forEach(children, child => {
                    if (isPopupButtonItemElement(child)) {
                        addToChildElements(child)
                    } else if (isPopupButtonItemGroupElement(child)) {
                        React.Children.forEach(child.props.children, groupChild => {
                            if (isPopupButtonItemElement(groupChild)) {
                                addToChildElements(groupChild)
                            }
                        })
                    }
                })

                const selectedChild = childElements[selectedIndex]
                if (!selectedChild) return
                const { onSelect, identifier } = selectedChild.props
                if (onSelect) onSelect(identifier)
            },
            [multipleItemsSelected, children]
        )

        const disabled = enabled === false

        return (
            <div
                className={cx(
                    styles.popupButtonWrapper,
                    large && styles.popupButtonWrapperLarge,
                    disabled && "disabled"
                )}
                style={
                    {
                        ...wrapperStyle,
                        [styles.backgroundImageLight]: backgroundImageLight,
                        [styles.backgroundImageLightLarge]: backgroundImageLightLarge,
                        [styles.backgroundImageDark]: backgroundImageDark,
                        [styles.backgroundImageDarkLarge]: backgroundImageDarkLarge,
                        [styles.backgroundImageLightDisabled]: backgroundImageLightDisabled,
                        [styles.backgroundImageLightLargeDisabled]: backgroundImageLightLargeDisabled,
                        [styles.backgroundImageDarkDisabled]: backgroundImageDarkDisabled,
                        [styles.backgroundImageDarkLargeDisabled]: backgroundImageDarkLargeDisabled,
                    } as CSSProperties
                }
            >
                <select
                    {...rest}
                    ref={ref}
                    value={value}
                    disabled={disabled}
                    tabIndex={useTabIndex(tabIndex)}
                    className={cx(
                        styles.popupButton,
                        truncateWithEllipsis,
                        disabled && styles.popupButtonDisabled,
                        large && styles.popupButtonLarge,
                        className
                    )}
                    style={styleOverride}
                    onChange={onChange}
                >
                    <PopupButtonItem
                        key="multiple-selected-item"
                        title={multipleSelectionTitle}
                        visible={multipleItemsSelected}
                        selected={multipleItemsSelected}
                        enabled={false}
                    />
                    <PopupButtonItem key="multiple-selected-divider" type="divider" visible={multipleItemsSelected} />
                    <multiSelectContext.Provider value={multipleItemsSelected}>{children}</multiSelectContext.Provider>
                </select>
            </div>
        )
    })
)

// These can't be moved to the CSS file, because Linaria can't handle base64 generation of the SVGs

const backgroundImageLight = svgURL(
    `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8"><path d="M 0 6 L 3 3 L 0 0" transform="translate(2.5 1) rotate(90 1.5 3)" fill="transparent" stroke-width="1.5" stroke="${colorsLight.inputIcon}" stroke-linecap="round"></path></svg>`
)

const backgroundImageLightLarge = svgURL(
    `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M4 6.5l4 4 4-4" fill="transparent" stroke-width="1.5" stroke="${colorsLight.inputIcon}" stroke-linecap="round"/></svg>`
)

const backgroundImageDark = svgURL(
    `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8"><path d="M 0 6 L 3 3 L 0 0" transform="translate(2.5 1) rotate(90 1.5 3)" fill="transparent" stroke-width="1.5" stroke="${colorsDark.inputIcon}" stroke-linecap="round"></path></svg>`
)

const backgroundImageDarkLarge = svgURL(
    `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M4 6.5l4 4 4-4" fill="transparent" stroke-width="1.5" stroke="${colorsDark.inputIcon}" stroke-linecap="round"/></svg>`
)

const backgroundImageLightDisabled = svgURL(
    `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8"><path d="M 0 6 L 3 3 L 0 0" transform="translate(2.5 1) rotate(90 1.5 3)" fill="transparent" stroke-width="1.5" stroke="${colorsLight.inputIconDisabled}" stroke-linecap="round"></path></svg>`
)

const backgroundImageLightLargeDisabled = svgURL(
    `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M4 6.5l4 4 4-4" fill="transparent" stroke-width="1.5" stroke="${colorsLight.inputIconDisabled}" stroke-linecap="round"/></svg>`
)

const backgroundImageDarkDisabled = svgURL(
    `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8"><path d="M 0 6 L 3 3 L 0 0" transform="translate(2.5 1) rotate(90 1.5 3)" fill="transparent" stroke-width="1.5" stroke="${colorsDark.inputIconDisabled}" stroke-linecap="round"></path></svg>`
)
const backgroundImageDarkLargeDisabled = svgURL(
    `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M4 6.5l4 4 4-4" fill="transparent" stroke-width="1.5" stroke="${colorsDark.inputIconDisabled}" stroke-linecap="round"/></svg>`
)
