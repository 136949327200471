import twemoji from "twemoji"
import * as twemojiParser from "twemoji-parser"

let useCustomEmoji = false
export function configure({ customEmoji }: { customEmoji: boolean }) {
    useCustomEmoji = customEmoji
}

/**
 * If the input string starts with an emoji, returns `[emoji, remainder]`. Otherwise, returns `[null, text]`.
 */
export function extract(text: string): [string | null, string] {
    const [emojiEntity] = twemojiParser.parse(text, { buildUrl: buildURL })
    if (!emojiEntity || emojiEntity.indices[0] > 0) {
        return [null, text]
    }
    return [emojiEntity.url, text.substring(emojiEntity.indices[1]).trimLeft()]
}

/** Replaces the emoji inside of the provided element with images. */
export function replace(element: HTMLElement) {
    twemoji.parse(element, buildURL)
}

export function extractInitials(text: string): string {
    const [, textWithoutEmoji] = extract(text)
    return textWithoutEmoji?.match(/./u)?.[0]?.toUpperCase() ?? ""
}

function buildURL(codepoints: string): string {
    return useCustomEmoji
        ? `https://static.framer.com/emoji/${codepoints}.png`
        : `https://twemoji.maxcdn.com/v/latest/72x72/${codepoints}.png`
}
