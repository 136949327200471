/**
 * Terminology we use in the app
 */
export enum Dictionary {
    PRIMARY = "Primary",
    INSTANCE = "Instance",
    PAGE = "Page",
    VARIANT = "Variant",
    HOVER = "Hover",
    PRESSED = "Pressed",
    ACTION = "Action",
}
