import * as React from "react"
import { cx } from "linaria"
import { truncateWithEllipsis } from "./utils/truncateWithEllipsis.styles"
import type { OverrideType, HTMLButtonAttributes, HTMLButtonMouseEvent } from "./types"
import * as styles from "./Button.styles"
import { useTabIndex } from "./Tabbable"

/**
 * Different styles providing multiple levels of emphasis
 * - default: Base styling for secondary actions
 * - primary: Use when a button is the default action
 * - link: Gives the appearance of an anchor link to match design whilst maintaining correct semantics
 * - destructive: Use when the button results in a destructive action, such as deleting content
 * */
type ButtonVariant = "default" | "primary" | "link" | "destructive"

export interface BaseButtonProps {
    /** Element component will render as, needs to be able to accept a `ref` */
    as?: React.ElementType
    /** The title is displayed when no children are present. When children are present the title is used as a tooltip. */
    title?: string
    /** Only called when the button is enabled. */
    onClick?: (event: HTMLButtonMouseEvent) => void
    /** Set button styling, e.g. primary for main CTA */
    variant?: ButtonVariant
    /** Allows disabling the control. */
    enabled?: boolean
    /** Increases the button height. */
    large?: boolean
}

export type ButtonProps = OverrideType<Omit<HTMLButtonAttributes, "disabled">, BaseButtonProps>

const variantStyles: Record<ButtonVariant, string | undefined> = {
    default: undefined,
    primary: styles.buttonPrimary,
    link: styles.buttonLink,
    destructive: styles.buttonDestructive,
}

export type ButtonComponent<P = HTMLButtonAttributes, E = HTMLButtonElement> = (
    p: BaseButtonProps & P,
    forwardedRef: React.Ref<E>
) => React.ReactElement

/**
 * The Button component
 *
 * ```jsx
 * import * as React from "react"
 *
 * export function MyComponent() {
 *   return <Button title={"Go Bananas"} onClick={performEasterEgg} />
 * }
 * ```
 */
function ButtonComponent<P, E = HTMLButtonElement>(props: ButtonProps & P, forwardedRef: React.Ref<E>) {
    const {
        as: Element = "button",
        title,
        onClick,
        variant,
        large,
        className,
        children,
        enabled = true,
        tabIndex,
        ...rest
    } = props

    return (
        <Element
            ref={forwardedRef}
            className={cx(
                styles.button,
                variant && variantStyles[variant],
                large && styles.buttonLarge,
                Element !== "button" && styles.customElement,
                className
            )}
            onClick={enabled ? onClick : undefined}
            title={children && title ? title : undefined}
            disabled={!enabled}
            type="button"
            tabIndex={useTabIndex(tabIndex)}
            role={!(Element === "button" || "href" in rest) ? "button" : undefined}
            {...rest}
        >
            {children || <div className={cx(styles.titleWrapper, truncateWithEllipsis)}>{title}</div>}
        </Element>
    )
}

export const Button = React.memo(React.forwardRef(ButtonComponent))

export const CustomButton = React.memo(
    React.forwardRef(ButtonComponent as ButtonComponent<{ [prop: string]: any }, HTMLElement>)
)
