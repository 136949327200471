// ❗️
// This code is generated by the Services build process. Do not modify it.
// See the Services README for more information.
//

import {
    Service,
    ServiceManager,
    ServiceChannel,
    ServiceOptions,
    ServiceMessageHelper,
    ServiceRuntimePrivate,
    ServiceValue,
    ServiceStream,
    ServiceStreamOptions,
    UnsafeJSON,
    float,
    integer,
    oneway,
} from "../../runtime"
export * from "../../runtime"

interface SharedService<Interface> {
    /**
     * Creates a new proxy for a given service. Since the presence of the service isn't checked,
     * errors may occur when actually calling methods on the proxy.
     *
     * const service = SomeService.on(someChannel).expect()
     * await service.someMethod()
     */
    expect: (options?: ServiceOptions) => Interface

    /**
     * Waits for the presence of the service, with a configurable timeout. Returns a proxy on success, or rejects with
     * a service error (e.g. the request timed out, service wasn't found).
     *
     * const service = await SomeService.on(someChannel).discover({ timeout: 1000 })
     * await service.someMethod()
     *
     * @param options.timeout Number of milliseconds to wait for the service's presence. Default = 30000
     */
    discover: (options?: ServiceOptions & { timeout?: number }) => Promise<Interface>

    /**
     * Adds an implementation for a specific service, exposing it on `channel`.
     * Implicitly registers the channel. Returns an unregister function.
     *
     * await SomeService.on(someChannel).register(someImplementation)
     */
    register: (implementation: Interface) => () => void
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace Assets {
    /**
     * Assets is not documented.
     */
    export interface Interface {
        assetUpdatesStream(_?: ServiceStreamOptions): ServiceStream<Assets.AssetUpdateEvent>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type Variant = ServiceValue<{
        url: string
        maxSideSize: integer
    }>

    export type AssetFontProperties = ServiceValue<{
        fontFamily: string
        fontSubFamily: string
        postscriptName: string
        preferredFamily: string
        preferredSubFamily: string
    }>

    export type AssetProperties = ServiceValue<{
        kind: string
        font: Assets.AssetFontProperties
    }>

    export type Asset = ServiceValue<{
        key: string
        name: string
        filename: string
        fileSize: integer
        ownerId: string
        ownerType: string
        mimeType: string
        uploadedBy: string
        createdAt: string
        updatedAt: string
        refreshTimeout: integer
        url: string
        variants: readonly Assets.Variant[]
        properties?: Assets.AssetProperties
    }>

    export type AssetUpdateEvent = ServiceValue<{
        assets?: readonly Assets.Asset[]
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace Beep {
    /**
     * Beep provides a native beeping service.
     */
    export interface Interface {
        beep(): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace CanvasSandboxService {
    /**
     * CanvasSandboxService is not documented.
     */
    export interface Interface {
        update(_: CanvasSandboxService.Update): oneway
        renderedUpdateStream(_?: ServiceStreamOptions): ServiceStream<CanvasSandboxService.RenderedUpdate>
        waitUntilResourcesLoaded(): Promise<void>
        waitForViewportSize(_: Size): Promise<CanvasSandboxService.ViewportResult>
        willStartUpdates(): Promise<void>
        waitForCompleteRenderAfterUpdates(): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type Update = ServiceValue<{
        fixmeOpaqueJSON: UnsafeJSON
    }>

    export type LayoutMetrics = ServiceValue<{
        x: float
        y: float
        width: float
        height: float
        childrenRects?: readonly Rect[]
    }>

    export type LayoutChange = ServiceValue<{
        nodeId: string
        layoutMetrics: CanvasSandboxService.LayoutMetrics
    }>

    export type RenderedUpdate = ServiceValue<{
        update: integer
        updateTime: integer
        renderTime: integer
        layoutChanges: readonly CanvasSandboxService.LayoutChange[]
    }>

    export type ViewportResult = ServiceValue<{
        pixelRatio: float
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace ChooseFile {
    /**
     * ChooseFile is not documented.
     */
    export interface Interface {
        chooseImage(_?: ChooseFile.Options): Promise<ChooseFile.ImageInfos>
        chooseFile(_?: ChooseFile.Options): Promise<ChooseFile.FileInfo>
        chooseMetadataImage(_?: ChooseFile.MetadataOptions): Promise<void>
        addImages(_?: ChooseFile.Images): Promise<ChooseFile.ImageInfos>
        addImageByURL(_?: ChooseFile.ImageURL): Promise<ChooseFile.ImageInfos>
        addFonts(_?: ChooseFile.Fonts): Promise<ChooseFile.FontInfos>
        deleteAsset(_?: ChooseFile.AssetKey): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type Options = ServiceValue<{
        allowedFileTypes: readonly string[]
    }>

    export type ImageSize = ServiceValue<{
        width: float
        height: float
        pixelWidth: float
        pixelHeight: float
    }>

    export type FileInfo = ServiceValue<{
        filename: string
        originalFileName: string
    }>

    export type FontInfoProperties = ServiceValue<{
        fontFamily: string
        fontSubFamily: string
        postscriptName: string
        preferredFamily: string
        preferredSubFamily: string
    }>

    export type FontInfo = ServiceValue<{
        filename: string
        originalFontName: string
        properties: ChooseFile.FontInfoProperties
    }>

    export type FontData = ServiceValue<{
        content: string
        originalFilename: string
    }>

    export type AssetKey = ServiceValue<{
        key: string
    }>

    export type ImageInfo = ServiceValue<{
        filename: string
        originalImageName: string
        imageSize: ChooseFile.ImageSize
    }>

    export type FontInfos = ServiceValue<{
        fonts: readonly ChooseFile.FontInfo[]
    }>

    export type Fonts = ServiceValue<{
        fonts: readonly ChooseFile.FontData[]
    }>

    export type ImageInfos = ServiceValue<{
        images: readonly ChooseFile.ImageInfo[]
    }>

    export type Images = ServiceValue<{
        images: readonly ChooseFile.ImageData[]
    }>

    export type ImageData = ServiceValue<{
        content: string
        originalFilename: string
    }>

    export type ImageURL = ServiceValue<{
        url: string
        originalFilename: string
        width: float
        height: float
    }>

    export type MetadataOptions = ServiceValue<{
        filename: string
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace Clipboard {
    /**
     * Clipboard is not documented.
     */
    export interface Interface {
        write(_: Clipboard.Write): Promise<void>
        read(_?: Clipboard.ReadOptions): Promise<Clipboard.Read>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type Write = ServiceValue<{
        items: readonly Clipboard.DataItem[]
    }>

    export type ReadOptions = ServiceValue<{
        types: readonly Clipboard.DataItem["type"][]
    }>

    export type Read = ServiceValue<{
        items: readonly Clipboard.DataItem[]
    }>

    export type DataItem = ServiceValue<
        | {
              type: "text/plain"
              string: string
          }
        | {
              type: "text/css"
              string: string
          }
        | {
              type: "image/svg+xml"
              string: string
          }
        | {
              type: "application/x-framer-style-v2"
              data: UnsafeJSON
          }
        | {
              type: "application/x-framer-layers"
              data: UnsafeJSON
          }
        | {
              type: "application/x-framer-sketch"
              data: UnsafeJSON
          }
        | {
              type: "application/x-framer-animation-transition"
              data: UnsafeJSON
          }
        | {
              type: "application/x-framer-layout-grid"
              data: UnsafeJSON
          }
    >
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace ColorSampler {
    /**
     * ColorSampler is not documented.
     */
    export interface Interface {
        sampleColor(): Promise<Color>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace ControlsVisibility {
    /**
     * ControlsVisibility is not documented.
     */
    export interface Interface {
        requestHiddenStateForComponentControls(
            _: ControlsVisibility.HiddenStateForComponentControlsRequest
        ): Promise<ControlsVisibility.HiddenStateForComponentControlsResponse>
        requestHiddenStateForActionControls(
            _: ControlsVisibility.HiddenStateForActionControlsRequest
        ): Promise<ControlsVisibility.HiddenStateForActionControlsResponse>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type HiddenStateForComponentControlsRequest = ServiceValue<{
        /**
         * UnsafeJSON is
         * {
         *     [nodeId: string]: {
         *         controlNames: string[]
         *         componentProps: { [props: string]: unknown }
         *     }
         * }
         */
        args: UnsafeJSON
    }>

    export type HiddenStateForComponentControlsResponse = ServiceValue<{
        hiddenControlsByNodeId: UnsafeJSON
    }>

    export type HiddenStateForActionControlsRequest = ServiceValue<{
        actionIdentifier: string
        controlNames: readonly string[]
        /** UnsafeJSON is { [props: string]: unknown } */
        actionProps: UnsafeJSON
    }>

    export type HiddenStateForActionControlsResponse = ServiceValue<{
        hiddenState: UnsafeJSON
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace Dependencies {
    /**
     * Manage dependencies
     */
    export interface Interface {
        addDependency(_: Dependencies.AddDependencyArguments): Promise<void>
        removeDependency(_: Dependencies.RemoveDependencyArguments): Promise<void>
        getDependencies(_: Dependencies.GetDependenciesArguments): Promise<Dependencies.GetDependenciesResult>
        getTypings(): Promise<Dependencies.GetTypingsResult>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type AddDependencyArguments = ServiceValue<{
        /** Name of the package */
        name: string
        /** Wanted version, needs to be a specific version, e.g. '1.0.5' */
        version?: string
    }>

    export type RemoveDependencyArguments = ServiceValue<{
        /** Name of the package */
        name: string
    }>

    export type GetDependenciesArguments = ServiceValue<{
        /** Request to also refresh dependencies version info */
        refreshLatestInfo: boolean
    }>

    export type Typings = ServiceValue<{
        module: string
        content: string
        typesPackage?: boolean
    }>

    export type Dependency = ServiceValue<{
        name: string
        current: string
        wanted: string
        latest?: string
    }>

    export type GetDependenciesResult = ServiceValue<{
        dependencies: readonly Dependencies.Dependency[]
    }>

    export type GetTypingsResult = ServiceValue<{
        typings: readonly Dependencies.Typings[]
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace EditorApp {
    /**
     * EditorApp is not documented.
     */
    export interface Interface {
        loadBlankProject(): Promise<void>
        loadProject(_: EditorApp.LoadProjectRequest): Promise<void>
        prepareNodeSnapshot(_: EditorApp.PrepareNodeSnapshotRequest): Promise<void>
        prepareScopeSnapshot(_: EditorApp.PrepareScopeSnapshotRequest): Promise<void>
        waitForRenderCycle(): Promise<void>
        waitUntilRendered(): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type LoadProjectRequest = ServiceValue<{
        document: string
        scripts: { readonly [_: string]: string }
    }>

    export type SizeObject = ServiceValue<{
        width: integer
        height: integer
    }>

    export type PrepareNodeSnapshotRequest = ServiceValue<{
        nodeId: string
        zoom: float
        padding: integer
        clipContentOutsideNodeRect: boolean
    }>

    export type PrepareScopeSnapshotRequest = ServiceValue<{
        scopeId?: string
        size?: EditorApp.SizeObject
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace ElectronThemeListener {
    /**
     * ElectronThemeListener is not documented.
     */
    export interface Interface {
        onThemeChange(_: ElectronThemeListener.Theme): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type Theme = ServiceValue<{
        isDarkMode: boolean
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace ElectronApp {
    /**
     * ElectronApp is not documented.
     */
    export interface Interface {
        appStateStream(_?: ServiceStreamOptions): ServiceStream<ElectronApp.AppState>
        windowStateStream(_?: ServiceStreamOptions): ServiceStream<ElectronApp.WindowState>
        selectDashboard(): Promise<void>
        selectProject(_: ElectronApp.SelectProjectPayload): Promise<void>
        moveProject(_: ElectronApp.MoveProjectPayload): Promise<void>
        closeProject(_: ElectronApp.CloseProjectPayload): Promise<void>
        newProject(): Promise<void>
        minimizeWindow(): Promise<void>
        maximizeWindow(): Promise<void>
        unmaximizeWindow(): Promise<void>
        closeWindow(): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type ProjectState = ServiceValue<{
        id: string
        url: string
        title?: string
        icon?: string
    }>

    export type WindowState = ServiceValue<{
        id: string
        isMaximized: boolean
        isFullScreen: boolean
        selectedProject?: string
        projects: { readonly [_: string]: ElectronApp.ProjectState }
        tabs: readonly string[]
    }>

    export type AppState = ServiceValue<{
        isDarkMode: boolean
        isAuthenticated: boolean
        selectedWindow?: string
        windows: { readonly [_: string]: ElectronApp.WindowState }
    }>

    export type MoveProjectPayload = ServiceValue<{
        id: string
        toIndex: integer
    }>

    export type SelectProjectPayload = ServiceValue<{
        id: string
    }>

    export type CloseProjectPayload = ServiceValue<{
        id: string
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace ElectronColorSampler {
    /**
     * ElectronColorSampler is not documented.
     */
    export interface Interface {
        bitmapStream(_?: ServiceStreamOptions): ServiceStream<ElectronColorSampler.Bitmap>
        onSelect(_: ElectronColorSampler.SelectPayload): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type Bitmap = ServiceValue<{
        data: readonly integer[]
    }>

    export type SelectPayload = ServiceValue<{
        color?: string
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace ElectronWebRendering {
    /**
     * ElectronWebRendering is not documented.
     */
    export interface Interface {
        willOpenWindow(_: ElectronWebRendering.WindowOptions): Promise<ElectronWebRendering.WindowReference>
        resizeWindow(_: ElectronWebRendering.ResizeOptions): Promise<void>
        takeSnapshotOfWindow(_: ElectronWebRendering.SnapshotOptions): Promise<ElectronWebRendering.Snapshot>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type WindowOptions = ServiceValue<{
        url: string
        width: integer
        height: integer
        offscreen?: boolean
    }>

    export type WindowReference = ServiceValue<{
        id: string
    }>

    export type ResizeOptions = ServiceValue<{
        ref: ElectronWebRendering.WindowReference
        width: integer
        height: integer
        preferredPixelRatio?: float
    }>

    export type SnapshotOptionsType = ServiceValue<"image/png" | "image/jpeg">

    export type SnapshotOptions = ServiceValue<{
        ref: ElectronWebRendering.WindowReference
        rect: Rect
        type: "image/png" | "image/jpeg"
        writeToClipboard?: boolean
    }>

    export type Snapshot = ServiceValue<{
        url: string
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace FeedbackService {
    /**
     * FeedbackService is not documented.
     */
    export interface Interface {
        getThreads(_: FeedbackService.GetThreadsRequest): Promise<FeedbackService.GetThreadsResponse>
        createThread(_: FeedbackService.CreateThreadRequest): Promise<FeedbackService.CreateThreadResponse>
        updateThread(_: FeedbackService.UpdateThreadRequest): Promise<FeedbackService.UpdateThreadResponse>
        createComment(_: FeedbackService.CreateCommentRequest): Promise<FeedbackService.CreateCommentResponse>
        updateComment(_: FeedbackService.UpdateCommentRequest): Promise<FeedbackService.UpdateCommentResponse>
        deleteComment(_: FeedbackService.DeleteCommentRequest): Promise<FeedbackService.DeleteCommentResponse>
        markAsSeen(_: FeedbackService.MarkAsSeenRequest): Promise<FeedbackService.MarkAsSeenResponse>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type GetThreadsRequest = ServiceValue<{
        archived: boolean
    }>

    export type GetThreadsResponse = ServiceValue<{
        threads: readonly FeedbackService.Thread[]
    }>

    export type CreateThreadResponse = ServiceValue<{
        thread: FeedbackService.Thread
    }>

    export type CreateThreadRequest = ServiceValue<{
        anchor: FeedbackService.Anchor
        text: string
    }>

    export type UpdateThreadRequest = ServiceValue<{
        id: string
        archived?: boolean
        anchor?: FeedbackService.Anchor
    }>

    export type UpdateThreadResponse = ServiceValue<{
        thread: FeedbackService.Thread
    }>

    export type CreateCommentRequest = ServiceValue<{
        threadId: string
        text: string
    }>

    export type CreateCommentResponse = ServiceValue<{
        comment: FeedbackService.Comment
    }>

    export type UpdateCommentRequest = ServiceValue<{
        id: string
        threadId: string
        text: string
    }>

    export type UpdateCommentResponse = ServiceValue<{
        comment: FeedbackService.Comment
    }>

    export type DeleteCommentRequest = ServiceValue<{
        id: string
        threadId: string
    }>

    export type DeleteCommentResponse = ServiceValue<{
        comment: FeedbackService.Comment
    }>

    export type MarkAsSeenRequest = ServiceValue<{
        threadId: string
        lastSeenCommentId: string
    }>

    export type MarkAsSeenResponse = ServiceValue<{
        thread: FeedbackService.Thread
    }>

    export type Thread = ServiceValue<{
        archived: boolean
        comments: readonly FeedbackService.Comment[]
        id: string
        anchor: FeedbackService.Anchor
        seenUntil: string | null
        updatedAt: string | null
    }>

    export type Comment = ServiceValue<{
        author: User
        createdAt: string
        deletedBy: User | null
        id: string
        text: string
        updatedAt: string | null
    }>

    export type Anchor = ServiceValue<
        | {
              type: "canvas"
              /** The anchor is only on a single page */
              pageId?: string
              /** The x position of the anchor within the canvas */
              x: integer
              /** The y position of the anchor within the canvas */
              y: integer
          }
        | {
              type: "node"
              /** The original page of the anchor at creation time */
              pageId?: string
              /** The original x position of the anchor within the canvas at creation time */
              x: integer
              /** The original y position of the anchor within the canvas at creation time */
              y: integer
              /** The id of the node the thread is anchored to */
              nodeId: string
              /** X axis inset from the `insetCorner` */
              insetX: integer
              /** Y axis inset from the `insetCorner` */
              insetY: integer
              /**
               * String representing a corner from which to apply the inset.
               * One of `nw/ne/se/sw` or `center`.
               * NOTE: `center` is always exactly center and will ignore the inset.
               */
              insetCorner: string
          }
    >
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace FeedbackDocument {
    /**
     * FeedbackDocument is not documented.
     */
    export interface Interface {
        openThread(_: FeedbackDocument.OpenThreadRequest): Promise<void>
        feedbackStateStream(_?: ServiceStreamOptions): ServiceStream<FeedbackDocument.FeedbackStateEvent>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type OpenThreadRequest = ServiceValue<{
        threadId: string
        commentId?: string
    }>

    export type FeedbackStateEvent = ServiceValue<{
        isActive: boolean
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace FigmaImporter {
    /**
     * Exposes an IPC interface to interact with the FigmaImporter.
     */
    export interface Interface {
        importFigmaFile(_: FigmaImporter.ImportFigmaFileRequest): Promise<FigmaImporter.ImportFigmaFileResponse>
        startImportFigmaFile(_: FigmaImporter.ImportFigmaFileRequest): Promise<void>
        importFigmaFileStream(
            _?: ServiceStreamOptions
        ): ServiceStream<FigmaImporter.ImportFigmaFileResponseWithProgress>
        fetchFigmaFile(_: FigmaImporter.FetchFigmaFileRequest): Promise<FigmaImporter.FetchFigmaFileResponse>
        isAuthenticatedWithFigma(): Promise<FigmaImporter.IsAuthenticatedWithFigmaResponse>
        authenticateWithFigma(): Promise<FigmaImporter.IsAuthenticatedWithFigmaResponse>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type ImportFigmaFileRequest = ServiceValue<{
        figmaFileId: string
        nodeId: string
    }>

    export type ImportFigmaFileResponse = ServiceValue<{
        rootNodes: readonly UnsafeJSON[]
    }>

    export type ImportFigmaFileResponseWithProgress = ServiceValue<{
        rootNodes?: readonly UnsafeJSON[]
        progress: integer
        step: string
    }>

    export type FetchFigmaFileRequest = ServiceValue<{
        figmaFileId: string
    }>

    export type FetchFigmaFileResponseStatus = ServiceValue<"ok" | "unauthenticated" | "error">

    export type FetchFigmaFileResponse = ServiceValue<{
        status: "ok" | "unauthenticated" | "error"
        figmaFile?: UnsafeJSON
    }>

    export type IsAuthenticatedWithFigmaResponse = ServiceValue<{
        isAuthenticated: boolean
    }>

    export type AuthenticateWithFigmaResponse = ServiceValue<{
        isAuthenticated: boolean
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace Files {
    /**
     * Files is not documented.
     */
    export interface Interface {
        saveFile(_: Files.File): Promise<void>
        deleteFile(_: Files.FilePath): Promise<void>
        getFile(_: Files.FilePath): Promise<Files.FileResult>
        getFileList(): Promise<Files.FileListResult>
        buildOutputStream(): ServiceStream<Files.BuildOutput>
        getBuildOutput(_: Files.FilePath): Promise<Files.FileResult>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type FilePath = ServiceValue<{
        path: string
    }>

    export type File = ServiceValue<{
        path: string
        content: string
    }>

    export type FileResult = ServiceValue<{
        file: Files.FileOrDirectoryMetadata
    }>

    export type FileListResult = ServiceValue<{
        fileList: readonly Files.FileOrDirectoryMetadata[]
    }>

    export type FileOrDirectoryMetadata = ServiceValue<
        | {
              type: "file"
              path: string
              content?: string
          }
        | {
              type: "directory"
              path: string
          }
    >

    export type BuildOutputFile = ServiceValue<{
        filename: string
        contents: string
    }>

    export type BuildOutput = ServiceValue<{
        files: readonly Files.BuildOutputFile[]
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace FileUpdates {
    /**
     * FileUpdates is not documented.
     */
    export interface Interface {
        fileUpdatesStream(): ServiceStream<FileUpdates.FileUpdateEvent>
        notify(_: FileUpdates.FileUpdateEvent): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type UpdatedFileType = ServiceValue<"added" | "changed" | "deleted">

    export type UpdatedFile = ServiceValue<{
        path: string
        type: "added" | "changed" | "deleted"
    }>

    export type FileUpdateEvent = ServiceValue<{
        files: readonly FileUpdates.UpdatedFile[]
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace GestureEventTarget {
    /**
     * GestureEventTarget is not documented.
     */
    export interface Interface {
        dispatchMagnificationEvent(_: MagnificationEvent): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace MenuActionTarget {
    /**
     * MenuActionTarget is not documented.
     */
    export interface Interface {
        validate(_: MenuAction): Promise<MenuValidationResult>
        dispatch(_: MenuAction): Promise<MenuDispatchResult>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace ContextMenuPresenter {
    /**
     * ContextMenuPresenter is not documented.
     */
    export interface Interface {
        showContextMenu(_: ContextMenu): Promise<ContextMenuResult>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace Modals {
    /**
     * Modals is not documented.
     */
    export interface Interface {
        setActiveModal(_: Modals.SetActiveModalRequest): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type SetActiveModalRequestModal = ServiceValue<"ImportSketch" | "ImportFigma">

    export type SetActiveModalRequest = ServiceValue<{
        modal: "ImportSketch" | "ImportFigma"
        source: string
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace ModulesAPI {
    /**
     * ModulesAPI is not documented.
     */
    export interface Interface {
        create(_: ModulesAPI.CreateRequest): Promise<ModulesAPI.ModuleWithSave>
        delete(_: ModulesAPI.DeleteRequest): Promise<ModulesAPI.ModuleWithSave>
        list(_: ModulesAPI.ListRequest): Promise<ModulesAPI.ListResponse>
        listNamespaces(): Promise<ModulesAPI.ListNamespacesResponse>
        listPublishedModules(
            _: ModulesAPI.ListPublishedModulesRequest
        ): Promise<ModulesAPI.ListPublishedModulesResponse>
        lookUpModules(_: ModulesAPI.LookupRequest): Promise<ModulesAPI.ListResponse>
        moduleEventsStream(): ServiceStream<ModulesAPI.ModuleEventList>
        publish(_: ModulesAPI.PublishRequest): Promise<ModulesAPI.Publish>
        save(_: ModulesAPI.SaveRequest): Promise<ModulesAPI.ModuleWithSave>
        saveBatch(_: ModulesAPI.SaveBatchRequest): Promise<ModulesAPI.ListResponse>
        update(_: ModulesAPI.UpdateRequest): Promise<ModulesAPI.ModuleWithSave>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type ModuleEvent = ServiceValue<
        | {
              type: "delete"
              id: string
          }
        | {
              type: "save"
              module: ModulesAPI.ModuleWithSave
          }
    >

    export type ModuleEventList = ServiceValue<{
        events: readonly ModulesAPI.ModuleEvent[]
    }>

    export type PublishInfo = ServiceValue<{
        namespaceId: string
        name: string
        version: string
        importURL: string
    }>

    export type ModuleWithSave = ServiceValue<{
        id: string
        /** An id that is unique to the owner (but may be the same across multiple owners). */
        localId: string
        ownerId: string
        ownerType: string
        createdAt: string
        updatedAt: string
        type: string
        name: string
        deletedAt: string | null
        title: string
        description: string | null
        saveId: string
        userId: string
        savedAt: string
        /** A map of file type to filename. */
        files: { readonly [_: string]: string }
        metadata: UnsafeJSON
        baseURL: string
        /** Short import URL, e.g. "https://framer.com/m/Button-2m3E.js" */
        importURL: string
        /** Information about the last publish of this module. */
        lastPublish: ModulesAPI.PublishInfo | null
    }>

    export type ListResponse = ServiceValue<{
        data: readonly ModulesAPI.ModuleWithSave[]
    }>

    export type FileUpload = ServiceValue<{
        /** A single filename (no paths allowed). */
        name: string
        /** The purpose of the file ("module", "source", etc). */
        type: string
        /** For module's content all the relative imports should be replaced with absolute URLs, if possible. */
        content: string
    }>

    export type CreateRequest = ServiceValue<{
        /** The module type ("codeFile", "canvas", etc). */
        type: string
        saveId: string
        name: string
        files: readonly ModulesAPI.FileUpload[]
        metadata?: UnsafeJSON
        title?: string
        description?: string
        transferEncoding?: string
        assets?: readonly string[]
    }>

    export type DeleteRequest = ServiceValue<{
        moduleId: string
    }>

    export type ListRequest = ServiceValue<{
        /** The module types to get ("codeFile", "canvas", etc). Leave empty/undefined for all types. */
        types?: readonly string[]
    }>

    export type ListNamespacesResponse = ServiceValue<{
        data: readonly ModulesAPI.Namespace[]
    }>

    export type Namespace = ServiceValue<{
        namespaceId: string
        ownerId: string
        ownerType: string
        name: string
    }>

    export type ListPublishedModulesRequest = ServiceValue<{
        /** The namespace to get published modules for. Can be either the name or the id of the namespace. */
        namespace: string
    }>

    export type PublishedModuleWithSave = ServiceValue<{
        namespaceId: string
        /** The namespace that the module is published under. */
        namespace: string
        /** The name that the module is published under. */
        name: string
        version: string
        userId: string
        visibility: string
        timestamp: string
        importURL: string
        module: ModulesAPI.ModuleWithSave
    }>

    export type ListPublishedModulesResponse = ServiceValue<{
        data: readonly ModulesAPI.PublishedModuleWithSave[]
    }>

    export type LookupQuery = ServiceValue<{
        url?: string
        moduleId?: string
        saveId?: string
        namespace?: string
        name?: string
        slug?: string
    }>

    export type LookupRequest = ServiceValue<{
        lookUp: readonly ModulesAPI.LookupQuery[]
    }>

    export type SaveRequest = ServiceValue<{
        moduleId: string
        /** A unique 20-character base62 string for identifying this save. */
        saveId: string
        /** Type of the module (should always be the same). */
        type: string
        /** The name of the module (should be unique among modules of the same type). */
        name: string
        /** The files to upload. Must be at least "source" and "module". */
        files: readonly ModulesAPI.FileUpload[]
        /** Arbitrary metadata that should be associated with the module. */
        metadata?: UnsafeJSON
        /** Set to gzip and compress the content in FileUpload for more efficient uploads. */
        transferEncoding?: string
        /** The asset keys that are used inside this module. */
        assets?: readonly string[]
    }>

    export type BatchSave = ServiceValue<{
        /** Can also be "$new" (to create a new module) or "$upsertName" (to create/update module with matching name). */
        moduleId: string
        /** A unique 20-character base62 string for identifying this save. */
        saveId: string
        /** Type of the module (should always be the same). */
        type: string
        /** The name of the module (should be unique among modules of the same type). */
        name: string
        /** The files to upload. Must be at least "source" and "module". */
        files: readonly ModulesAPI.FileUpload[]
        /** Arbitrary metadata that should be associated with the module. */
        metadata?: UnsafeJSON
        /** Label for the module to show in the UI (e.g., "Toggle"). */
        title?: string | null
        /** A short description for the module (optional). */
        description?: string | null
        /** Set to a save id to pull in all files and metadata from so they don't have to be uploaded again. */
        patchSaveId?: string
        /** Set to gzip and compress the content in FileUpload for more efficient uploads. */
        transferEncoding?: string
        /** The asset keys that are used inside this module. */
        assets?: readonly string[]
    }>

    export type SaveBatchRequest = ServiceValue<{
        batch: readonly ModulesAPI.BatchSave[]
    }>

    export type Publish = ServiceValue<{
        namespaceId: string
        name: string
        visibility: string
        version: string
        userId: string
        timestamp: string
        moduleId: string
        saveId: string
        importURL: string
    }>

    export type PublishRequest = ServiceValue<{
        /** The namespace to publish to. Can be either the name or the id of the namespace. */
        namespace: string
        /** The name under the namespace to publish to. */
        name: string
        moduleId: string
        saveId: string
        /** A version in the 1.2.3 format. Must be higher than the last publish. */
        version: string
    }>

    export type UpdateRequest = ServiceValue<{
        moduleId: string
        name?: string
        title?: string | null
        description?: string | null
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace ModulesState {
    /**
     * ModulesState is not documented.
     */
    export interface Interface {
        getModules(): Promise<ModulesState.GetModulesResponse>
        moduleUpdatesStream(_?: ServiceStreamOptions): ServiceStream<ModulesState.ModulesUpdateEvent>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type GetModulesResponse = ServiceValue<{
        modules: { readonly [_: string]: ModulesState.CompiledModule }
        initialized: boolean
        revision: integer
    }>

    export type CompiledModuleKind = ServiceValue<"unanalyzed" | "analyzed">

    export type CompiledModule = ServiceValue<{
        kind: "unanalyzed" | "analyzed"
        localId: string
        type: string
        name: string
        moduleURL?: string
        moduleContent?: string
        sourceMapContent?: string
        relativeImports?: readonly string[]
    }>

    export type ModulesUpdateEvent = ServiceValue<{
        patches: readonly ModulesState.Patch[]
        dependentModules: readonly string[]
        initialized: boolean
        revision: integer
    }>

    export type PatchOp = ServiceValue<"replace" | "remove" | "add">

    export type Patch = ServiceValue<{
        op: "replace" | "remove" | "add"
        path: UnsafeJSON
        value?: UnsafeJSON
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace PreviewSandbox {
    /**
     * API for the in-app preview window used by preview-next/preview.tsx
     */
    export interface Interface {
        pause(): Promise<void>
        resume(): Promise<void>
        reload(): Promise<void>
        setAutoReload(_: PreviewSandbox.AutoReloadOptions): Promise<void>
        previewInteractionStream(): ServiceStream<PreviewSandbox.InteractionEvent>
        previewKeyboardEventStream(): ServiceStream<PreviewSandbox.KeyboardEvent>
        previewLogStream(): ServiceStream<PreviewSandbox.LogsEvent>
        navigationEventStream(): ServiceStream<PreviewSandbox.NavigationEvent>
        renderPhaseEventStream(): ServiceStream<PreviewSandbox.PreviewRenderEvent>
        showHighlights(): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type AutoReloadOptions = ServiceValue<{
        autoReload: boolean
    }>

    export type InteractionEvent = ServiceValue<{
        type: string
    }>

    export type KeyboardEvent = ServiceValue<{
        key: string
        code: string
        keyCode: integer
        altKey: boolean
        ctrlKey: boolean
        metaKey: boolean
        shiftKey: boolean
    }>

    export type LogsEvent = ServiceValue<{
        logs: readonly PreviewSandbox.Log[]
    }>

    export type Log = ServiceValue<{
        /** Serialized log values */
        data: readonly string[]
        /** Name of the log type */
        method: string
        /** Unique ID */
        id: string
    }>

    export type NavigationEvent = ServiceValue<{
        key: string
    }>

    export type PreviewRenderEvent = ServiceValue<{
        phase?: string
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace PreviewWrapper {
    /**
     * Interface for interacting with the preview-wrapper.tsx that contains
     * the console.
     */
    export interface Interface {
        toggleConsole(_: PreviewWrapper.ToggleConsoleRequest): Promise<void>
        stateStream(): ServiceStream<PreviewWrapper.StateEvent>
        loadStream(): ServiceStream<PreviewWrapper.LoadEvent>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type ToggleConsoleRequest = ServiceValue<{
        force?: boolean
    }>

    export type StateEvent = ServiceValue<{
        consoleShown?: boolean
        hasErrors?: boolean
        hasWarnings?: boolean
    }>

    export type LoadEvent = ServiceValue<
        | {
              status: "loading"
          }
        | {
              status: "loaded"
          }
        | {
              status: "error"
              error: PreviewWrapper.Error
          }
    >

    export type Error = ServiceValue<
        | {
              type: "documentEmpty"
          }
        | {
              type: "frameNotFound"
          }
        | {
              type: "permissionDenied"
          }
        | {
              type: "unknown"
              message?: string
              extra?: UnsafeJSON
          }
    >
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace PreviewDataSource {
    /**
     * API for the owner of the Preview window to provide display info
     */
    export interface Interface {
        loadDocument(): Promise<PreviewDataSource.LoadDocumentResponse>
        scriptUpdateStream(): ServiceStream<PreviewDataSource.ScriptUpdateEvent>
        treeUpdateStream(): ServiceStream<PreviewDataSource.TreeUpdateEvent>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type LoadDocumentResponse = ServiceValue<{
        /**
         * Document tree may be unavailable at the time of the loadDocument
         * call. If so, the implementation of this service should follow-up with
         * the full tree later via the treeUpdateStream.
         */
        document?: UnsafeJSON
        initialUpdates?: readonly UnsafeJSON[]
        scripts: { readonly [_: string]: string }
    }>

    export type ScriptUpdateEvent = ServiceValue<{
        content: { readonly [_: string]: string }
    }>

    export type TreeUpdateEvent = ServiceValue<
        | {
              type: "tree"
              tree: UnsafeJSON
          }
        | {
              type: "changes"
              changes: readonly UnsafeJSON[]
          }
    >
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace PreviewSettings {
    /**
     * API for Preview settings
     */
    export interface Interface {
        toggleSelectionLock(_: PreviewSettings.SelectionLockRequest): Promise<void>
        toggleResponsive(_: PreviewSettings.ResponsiveRequest): Promise<void>
        toggleTouchCursor(_: PreviewSettings.TouchCursorRequest): Promise<void>
        setAppearance(_: PreviewSettings.SetApperanceRequest): Promise<void>
        toggleHighlights(): Promise<void>
        setProjectTitle(_: PreviewSettings.SetTitleRequest): Promise<void>
        openInExternalWindow(): Promise<void>
        openFullScreen(): Promise<void>
        addDevice(): Promise<void>
        previewMetadataUpdateStream(_?: ServiceStreamOptions): ServiceStream<PreviewSettings.MetadataUpdateEvent>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type SelectionLockRequest = ServiceValue<{
        force?: boolean
    }>

    export type ResponsiveRequest = ServiceValue<{
        force?: boolean
    }>

    export type TouchCursorRequest = ServiceValue<{
        force?: boolean
    }>

    export type SetApperanceRequestDeviceSkin = ServiceValue<"clay" | "realistic">

    export type SetApperanceRequestDeviceTheme = ServiceValue<"dark" | "light">

    export type SetApperanceRequest = ServiceValue<{
        devicePreset?: string
        shadow?: boolean
        responsive?: boolean
        deviceColorId?: string
        deviceSkin?: "clay" | "realistic"
        deviceTheme?: "dark" | "light"
    }>

    export type SetTitleRequest = ServiceValue<{
        title?: string
    }>

    export type MetadataUpdateEvent = ServiceValue<{
        devicePreset?: string
        deviceOptions?: PreviewSettings.DeviceOptions
        node?: PreviewSettings.Node
        responsive: boolean
        shadow?: boolean
        canChangeResponsive: boolean
        touchCursor: boolean
        selectionLock: boolean
        highlights: boolean
        title?: string
    }>

    export type BorderRadius = ServiceValue<{
        topLeft: integer
        topRight: integer
        bottomRight: integer
        bottomLeft: integer
    }>

    export type NodeBackgroundType = ServiceValue<"color" | "image">

    export type NodeBackgroundImageResize = ServiceValue<"fill" | "fit" | "stretch">

    export type Node = ServiceValue<
        | {
              type: "canvas"
              name?: string
              id: string
              width?: integer
              height?: integer
              borderRadius?: PreviewSettings.BorderRadius
              hasLegacyDevice?: boolean
              backgroundEnabled?: boolean
              backgroundType?: "color" | "image"
              backgroundColor?: string
              backgroundImage?: string
              backgroundImageResize?: "fill" | "fit" | "stretch"
          }
        | {
              type: "codeEditor"
              name?: string
              componentId: string
          }
    >

    export type DeviceOptionsAppearance = ServiceValue<
        | {
              type: "clay"
              bezelRadius?: string
              bezelColor?: string
              bezelShadeColor?: string
          }
        | {
              type: "external-clay"
              imageUrl: string
              imageWidth: integer
              imageHeight: integer
          }
        | {
              type: "realistic"
              imageUrl: string
              imageWidth: integer
              imageHeight: integer
              rotateImage?: boolean
          }
    >

    export type DeviceOptionsTheme = ServiceValue<"dark" | "light">

    export type DeviceOptions = ServiceValue<{
        deviceWidth: integer
        deviceHeight: integer
        noPadding?: boolean
        appearance:
            | {
                  type: "clay"
                  bezelRadius?: string
                  bezelColor?: string
                  bezelShadeColor?: string
              }
            | {
                  type: "external-clay"
                  imageUrl: string
                  imageWidth: integer
                  imageHeight: integer
              }
            | {
                  type: "realistic"
                  imageUrl: string
                  imageWidth: integer
                  imageHeight: integer
                  rotateImage?: boolean
              }
        screenWidth: integer
        screenHeight: integer
        screenOffsetTop?: integer
        screenOffsetLeft?: integer
        screenRadius?: string
        screenMaskImage?: string
        screenColor?: string
        shadow?: string
        background?: string
        theme?: "dark" | "light"
        colorId?: string
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace PreviewStore {
    /**
     * API for the owner of the Preview window
     */
    export interface Interface {
        toggleVisible(_: PreviewStore.ToggleVisibleRequest): Promise<void>
        toggleHighlights(_: PreviewStore.ToggleHighlightsRequest): Promise<void>
        updateStream(_?: ServiceStreamOptions): ServiceStream<PreviewStore.UpdateEvent>
        getSharedPreviewMetadata(): Promise<PreviewStore.GetSharedPreviewMetadataResponse>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type UpdateEvent = ServiceValue<{
        isVisible: boolean
    }>

    export type ToggleVisibleRequest = ServiceValue<{
        force?: boolean
        shownOnLaunch?: boolean
    }>

    export type ToggleHighlightsRequest = ServiceValue<{
        force: boolean
    }>

    export type GetSharedPreviewMetadataResponse = ServiceValue<{
        homeNode?: PreviewStore.NodeMetadata
        selectedNode?: PreviewStore.NodeMetadata
        documentEmpty: boolean
    }>

    export type NodeMetadata = ServiceValue<{
        nodeId: string
        deviceOptions?: PreviewSettings.DeviceOptions
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace PreviewWindowShortcuts {
    /**
     * PreviewWindowShortcuts is not documented.
     */
    export interface Interface {
        reload(): Promise<void>
        toggleConsole(): Promise<void>
        toggleHighlights(): Promise<void>
        toggleSelectionLock(): Promise<void>
        openFullScreen(): Promise<void>
        openInExternalWindow(): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace PreviewDesktop {
    /**
     * PreviewDesktop is not documented.
     */
    export interface Interface {
        onPreviewMetadataUpdate(_: PreviewSettings.MetadataUpdateEvent): Promise<void>
        onLogError(): Promise<void>
        onLogWarning(): Promise<void>
        onLogClear(): Promise<void>
        onScaleChange(_: PreviewDesktop.OnScaleChange): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type OnScaleChange = ServiceValue<{
        scale: float
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace ProjectPackage {
    /**
     * Exposes functionality to interact with the "current package" also referred to as the "project package".
     */
    export interface Interface {
        uploadDesignAsset(_: ProjectPackage.UploadDesignAssetArgs): Promise<void>
        uploadReadme(_: ProjectPackage.UploadReadmeArgs): Promise<void>
        fetchReadme(): Promise<ProjectPackage.FetchReadmeResponse>
        fetchPackageJson(): Promise<ProjectPackage.FetchPackageJsonResponse>
        addPackageDependency(_: ProjectPackage.AddDependencyArgs): Promise<void>
        publishPackage(_: ProjectPackage.PublishPackageArgs): Promise<void>
        buildPackage(): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type UploadDesignAssetArgs = ServiceValue<{
        encodedContent: string
        fileName: string
    }>

    export type UploadReadmeArgs = ServiceValue<{
        readme: string
    }>

    export type FetchReadmeResponse = ServiceValue<{
        readme: string
    }>

    export type AddDependencyArgs = ServiceValue<{
        packageName: string
    }>

    export type PublishPackageArgs = ServiceValue<{
        /** The version that will be published e.g. `3.2.0`. */
        version: string
        /** The package's human friendly name e.g. `Magic Move`. */
        displayName: string
        /** The package's name e.g. `@framer/gusso.magic-move`. */
        packageName: string
        /** E.g. gusso/magic-move. */
        slug: string
        /** Whether the package is a private or public package. */
        isPrivate: boolean
    }>

    export type FetchPackageJsonResponse = ServiceValue<{
        /** The contents of the `package.json` */
        package: UnsafeJSON
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace ProjectState {
    /**
     * Exposes an IPC interface to the project server.
     */
    export interface Interface {
        assetChangeStream(): ServiceStream<ProjectState.FileChange>
        stateStream(): ServiceStream<ProjectState.State>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type FileChange = ServiceValue<{
        relativePath: string
    }>

    export type OutdatedProps = ServiceValue<{
        name: string
        current: string
        wanted: string
        latest: string
        type: string
    }>

    export type State = ServiceValue<{
        tasks: UnsafeJSON
        isDocumentDirty: boolean
        currentLibraryVersion?: string
        availableLibraryVersions?: readonly string[]
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace SandboxComponentLoader {
    /**
     * SandboxComponentLoader is not documented.
     */
    export interface Interface {
        componentLoaderStateStream(
            _?: ServiceStreamOptions
        ): ServiceStream<SandboxComponentLoader.ComponentLoaderStateEvent>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type ComponentLoaderState = ServiceValue<{
        localPackageIdentifier: string
    }>

    export type ComponentLoaderStateEvent = ServiceValue<{
        hash: string
        state: SandboxComponentLoader.ComponentLoaderState
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace SandboxInitializer {
    /**
     * Used for initial discovery of a successfully loaded sandbox iframe/window,
     * and to provide a hook for configuration code that runs before anything
     * has rendered.
     */
    export interface Interface {
        initialize(_: SandboxInitializer.Options): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type Options = ServiceValue<{
        upgradeToMessagePortChannel: boolean
        experiments: SandboxInitializer.Experiments
    }>

    export type Experiments = ServiceValue<{
        rawDraftRendering: boolean
        domLayoutStack: boolean
        deprecatedCodeComponentSizeWrapper: boolean
        deprecatedCodeComponentPropsCheck: boolean
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace SnapshotViewport {
    /**
     * SnapshotViewport is not documented.
     */
    export interface Interface {
        setViewportSize(_: SnapshotViewport.SetViewportSizeRequest): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type SetViewportSizeRequest = ServiceValue<{
        width: integer
        height: integer
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace PublicStoreClient {
    /**
     * PublicStoreClient is not documented.
     */
    export interface Interface {
        findByName(_: PublicStoreClient.FindByNameArgs): Promise<PublicStoreClient.Package>
        containsPackage(_: PublicStoreClient.ContainsPackageArgs): Promise<PublicStoreClient.ContainsPackageResponse>
        toggleFavorite(_: PublicStoreClient.ToggleFavoriteArgs): Promise<PublicStoreClient.Package>
        fetchRecents(_: PublicStoreClient.FetchRecentsArgs): Promise<PublicStoreClient.PackageArray>
        fetchPackagesBySection(_: PublicStoreClient.FetchPackagesBySectionArgs): Promise<PublicStoreClient.PackageArray>
        fetchPackagesByPublisher(
            _: PublicStoreClient.FetchPackagesByPublisherArgs
        ): Promise<PublicStoreClient.PackageArray>
        fetchPackagesByPopularity(
            _: PublicStoreClient.FetchPackagesByPopularityArgs
        ): Promise<PublicStoreClient.PackageArray>
        fetchFeaturedPackages(_: PublicStoreClient.FetchFeaturedPackagesArgs): Promise<PublicStoreClient.PackageArray>
        search(_: PublicStoreClient.SearchArgs): Promise<PublicStoreClient.PackageArray>
        getPublishStatus(_: PublicStoreClient.GetPublishStatusArgs): Promise<PublicStoreClient.PublishStatus>
        fetchPreflight(_: PublicStoreClient.FetchPreflightArgs): Promise<PublicStoreClient.PreflightResponse>
        removePackage(_: PublicStoreClient.RemovePackageArgs): Promise<void>
        fetchMeta(_: PublicStoreClient.FetchMetaArgs): Promise<PublicStoreClient.FetchMetaResponse>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type FindByNameArgs = ServiceValue<{
        packageName: string
    }>

    export type ContainsPackageArgs = ServiceValue<{
        friendlyName: string
        spaceId?: string
    }>

    export type ContainsPackageResponse = ServiceValue<{
        contained: boolean
    }>

    export type ToggleFavoriteArgs = ServiceValue<{
        packageName: string
        favorite: boolean
    }>

    export type FetchRecentsArgs = ServiceValue<{
        offset: integer
        spaceIds?: readonly string[]
    }>

    export type FetchPackagesBySectionArgs = ServiceValue<{
        section: string
        offset: integer
        spaceIds?: readonly string[]
    }>

    export type FetchPackagesByPublisherArgs = ServiceValue<{
        publisherId: string
        offset: integer
        spaceIds?: readonly string[]
    }>

    export type FetchPackagesByPopularityArgs = ServiceValue<{
        offset: integer
        days: integer
        spaceIds?: readonly string[]
    }>

    export type FetchTrendingPackagesArgs = ServiceValue<{
        offset: integer
    }>

    export type FetchFeaturedPackagesArgs = ServiceValue<{
        offset: integer
    }>

    export type SearchArgs = ServiceValue<{
        offset: integer
        query: string
        spaceIds?: readonly string[]
    }>

    export type GetPublishStatusArgs = ServiceValue<{
        packageName: string
        version: string
        isPrivate: boolean
    }>

    export type FetchPreflightArgs = ServiceValue<{
        package?: string
        displayName?: string
        publishing?: boolean
        spaceId?: string
    }>

    export type RemovePackageArgs = ServiceValue<{
        packageName: string
    }>

    export type PublishingError = ServiceValue<{
        message: string
    }>

    export type PublishStatus = ServiceValue<
        | {
              status: "pending"
          }
        | {
              status: "completed"
          }
        | {
              status: "rejected"
              error: PublicStoreClient.PublishingError
          }
        | {
              status: "error"
              error: UnsafeJSON
          }
    >

    export type PreflightResponse = ServiceValue<{
        ok: boolean
        suggestedVersion?: string
        slug?: string
        package?: string
        errorMessage?: string
    }>

    export type Package = ServiceValue<{
        icon: string
        artwork: string
        friendlyName: string
        readme: string
        license?: string
        name: string
        author: string
        slug: string
        version: string
        updatedAt?: string
        tags: readonly string[]
        size: integer
        favorites: integer
        downloads: integer
        isPrivate: boolean
        isFavorite?: boolean
        publisherId: string
        spaceId?: string
        peerDependencies?: UnsafeJSON
    }>

    export type PackageArray = ServiceValue<{
        packages: readonly PublicStoreClient.Package[]
        totalCount: integer
    }>

    export type FetchMetaArgs = ServiceValue<{
        name?: string
        dependencies?: readonly string[]
    }>

    export type PackageMeta = ServiceValue<{
        isPrivate: boolean
        url: string
        ownerId: string
        spaceId?: string
        publisherId: string
    }>

    export type FetchMetaResponse = ServiceValue<{
        meta?: PublicStoreClient.PackageMeta
        dependenciesMeta: UnsafeJSON
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace PrivateStoreClient {
    /**
     * PrivateStoreClient is not documented.
     */
    export interface Interface {
        findByName(_: PublicStoreClient.FindByNameArgs): Promise<PublicStoreClient.Package>
        containsPackage(_: PublicStoreClient.ContainsPackageArgs): Promise<PublicStoreClient.ContainsPackageResponse>
        toggleFavorite(_: PublicStoreClient.ToggleFavoriteArgs): Promise<PublicStoreClient.Package>
        fetchRecents(_: PublicStoreClient.FetchRecentsArgs): Promise<PublicStoreClient.PackageArray>
        fetchPackagesBySection(_: PublicStoreClient.FetchPackagesBySectionArgs): Promise<PublicStoreClient.PackageArray>
        fetchPackagesByPublisher(
            _: PublicStoreClient.FetchPackagesByPublisherArgs
        ): Promise<PublicStoreClient.PackageArray>
        fetchPackagesByPopularity(
            _: PublicStoreClient.FetchPackagesByPopularityArgs
        ): Promise<PublicStoreClient.PackageArray>
        fetchFeaturedPackages(_: PublicStoreClient.FetchFeaturedPackagesArgs): Promise<PublicStoreClient.PackageArray>
        search(_: PublicStoreClient.SearchArgs): Promise<PublicStoreClient.PackageArray>
        getPublishStatus(_: PublicStoreClient.GetPublishStatusArgs): Promise<PublicStoreClient.PublishStatus>
        fetchPreflight(_: PublicStoreClient.FetchPreflightArgs): Promise<PublicStoreClient.PreflightResponse>
        removePackage(_: PublicStoreClient.RemovePackageArgs): Promise<void>
        fetchMeta(_: PublicStoreClient.FetchMetaArgs): Promise<PublicStoreClient.FetchMetaResponse>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace VideoTutorial {
    /**
     * VideoTutorial is not documented.
     */
    export interface Interface {
        fetchVideoState(_: VideoTutorial.FetchVideoStateArgs): Promise<VideoTutorial.VideoTutorialState>
        updateVideoState(_: VideoTutorial.UpdateVideoTutorialStateArgs): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type FetchVideoStateArgs = ServiceValue<{
        videoId: string
    }>

    export type VideoTutorialState = ServiceValue<{
        videoId: string
        /** The video player's time. This is used to restore playing time. */
        time: float
        /** The moment in time when the dialog was last modified. */
        lastModifiedAt: integer
    }>

    export type UpdateVideoTutorialStateArgs = ServiceValue<{
        videoId: string
        time?: float
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace UnsplashClient {
    /**
     * UnsplashClient is not documented.
     */
    export interface Interface {
        listPhotos(_: UnsplashClient.ListPhotosArgs): Promise<UnsplashClient.ListPhotosResponse>
        searchPhotos(_: UnsplashClient.SearchPhotosArgs): Promise<UnsplashClient.SearchPhotosResponse>
        getRandomPhoto(_: UnsplashClient.GetRandomPhotoArgs): Promise<UnsplashClient.Photo>
        downloadPhoto(_: UnsplashClient.DownloadPhotoArgs): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type UserLinks = ServiceValue<{
        html: string
    }>

    export type UnsplashUser = ServiceValue<{
        name: string
        links: UnsplashClient.UserLinks
    }>

    export type URLs = ServiceValue<{
        full: string
        raw: string
        regular: string
        small: string
        thumb: string
    }>

    export type Photo = ServiceValue<{
        id: string
        width: integer
        height: integer
        color: string
        alt_description?: string
        description?: string
        urls: UnsplashClient.URLs
        user: UnsplashClient.UnsplashUser
    }>

    export type ListPhotosArgsOrder_by = ServiceValue<"latest" | "oldest" | "popular">

    export type ListPhotosArgs = ServiceValue<{
        page?: integer
        per_page?: integer
        order_by?: "latest" | "oldest" | "popular"
    }>

    export type ListPhotosResponse = ServiceValue<{
        photos: readonly UnsplashClient.Photo[]
        total: integer
    }>

    export type SearchPhotosArgs = ServiceValue<{
        query: string
        page?: integer
        per_page?: integer
    }>

    export type SearchPhotosResponse = ServiceValue<{
        results: readonly UnsplashClient.Photo[]
        total: integer
    }>

    export type GetRandomPhotoArgs = ServiceValue<{
        query?: string
    }>

    export type DownloadPhotoArgs = ServiceValue<{
        id: string
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace UserNotifications {
    /**
     * Shows system notifications to the user
     */
    export interface Interface {
        requestPermission(): Promise<UserNotifications.RequestPermissionResponse>
        getPermissionStatus(): Promise<UserNotifications.GetPermissionStatusResponse>
        post(_: UserNotifications.PostRequest): Promise<UserNotifications.PostResponse>
        cancel(_: UserNotifications.CancelRequest): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type PermissionStatus = ServiceValue<"granted" | "denied" | "notDetermined">

    export type RequestPermissionResponse = ServiceValue<{
        status: UserNotifications.PermissionStatus
    }>

    export type GetPermissionStatusResponse = ServiceValue<{
        status: UserNotifications.PermissionStatus
    }>

    export type NotificationShow = ServiceValue<"always" | "backgrounded">

    export type Notification = ServiceValue<{
        /**
         * Notification ID
         *
         * You can use it to cancel the notification, and to replace previous
         * notification with the same ID.
         */
        id: string
        title: string
        body?: string
        /**
         * Sometimes, it makes sense to only show a notification if the app
         * is in the background — most commonly, when there's a dedicated piece
         * of UI directly in the app that serves similar purpose as the
         * notification, e.g., unread comments badge.
         */
        show: "always" | "backgrounded"
    }>

    export type PostRequest = ServiceValue<{
        notification: UserNotifications.Notification
    }>

    export type PostResponse = ServiceValue<
        | {
              type: "action"
          }
        | {
              type: "cancel"
              /**
               * Reason for the notification getting canceled
               *
               * Possible values are:
               * - dismissed   - dismissed by user or programmatically via `cancel`
               * - unsupported - notification API is not available
               * - suppressed  - suppressed due to notification's "show" condition
               */
              reason: string
          }
    >

    export type CancelRequest = ServiceValue<{
        notificationId: string
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace UserSessionService {
    /**
     * UserSessionService is not documented.
     */
    export interface Interface {
        getSessionIdentity(): Promise<UserSessionService.SessionIdentityResponse>
        permissionsStream(_?: ServiceStreamOptions): ServiceStream<UserSessionService.PermissionsResponse>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type SessionIdentityResponse = ServiceValue<{
        user: User
    }>

    export type PermissionsResponse = ServiceValue<{
        isViewOnly: boolean
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace WebProject {
    /**
     * Exposes an IPC interface to interact with the current project on web.
     */
    export interface Interface {
        currentProjectStream(_?: ServiceStreamOptions): ServiceStream<WebProject.CurrentProjectEvent>
        showMoveProjectMenu(_: WebProject.ShowMoveProjectMenuArguments): Promise<void>
        showUpgradeOrTrialModal(_: WebProject.ShowUpgradeOrTrialModalArguments): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type ProjectSpaceScope = ServiceValue<"user" | "organization">

    export type ProjectSpace = ServiceValue<{
        id: string
        icon?: string
        ownerId: string
        scope: "user" | "organization"
        displayName?: string
    }>

    export type ShareLink = ServiceValue<{
        id: string
        href: string
    }>

    export type CurrentProjectEvent = ServiceValue<{
        title?: string
        space: WebProject.ProjectSpace
        shareLinks: readonly WebProject.ShareLink[]
        canMoveProject: boolean
    }>

    export type Location = ServiceValue<{
        x: integer
        y: integer
    }>

    export type ShowMoveProjectMenuArguments = ServiceValue<{
        location: WebProject.Location
    }>

    export type ShowUpgradeOrTrialModalArgumentsShow = ServiceValue<"fonts" | "insert-menu">

    export type ShowUpgradeOrTrialModalArguments = ServiceValue<{
        show: "fonts" | "insert-menu"
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace ZzzTestingBeep {
    /**
     * ZzzTestingBeep is not documented.
     */
    export interface Interface {
        beep(): Promise<void>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace ZzzTestingStreams {
    /**
     * ZzzTestingStreams is not documented.
     */
    export interface Interface {
        beepStream(_?: ServiceStreamOptions): ServiceStream<ZzzTestingStreams.BeepEvent>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type BeepEvent = ServiceValue<{
        name: string
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace ZzzTestingTypes {
    /**
     * ZzzTestingTypes is not documented.
     */
    export interface Interface {
        test(): Promise<ZzzTestingTypes.Object>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type ObjectStringEnum = ServiceValue<"a" | "b" | "c">

    export type Object = ServiceValue<{
        name: string
        stringEnum: "a" | "b" | "c"
        objectEnum?: ZzzTestingTypes.ObjectEnum
    }>

    export type ObjectEnum = ServiceValue<
        | {
              foo: "string"
              bar: string
          }
        | {
              foo: "integer"
              count: integer
          }
        | {
              foo: "array"
              objects: readonly ZzzTestingTypes.Object[]
          }
    >

    export type Map = ServiceValue<{
        stringMap: { readonly [_: string]: integer }
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */
export namespace ZzzTestingOneway {
    /**
     * ZzzTestingOneway is not documented.
     */
    export interface Interface {
        beep(_: ZzzTestingOneway.BeepEvent): oneway
        beepStream(_?: ServiceStreamOptions): ServiceStream<ZzzTestingOneway.BeepEvent>
    }

    /*
     * Using the service
     */

    export declare const service: Service<Interface>

    export function on(channel: ServiceChannel): SharedService<Interface> {
        // Note: capture the shared service manager here so testing behavior is predictable
        const sharedServices = ServiceManager.shared()
        return {
            expect: options => sharedServices.expectWithoutDiscovery(service, channel, options),
            discover: options => sharedServices.discover(service, channel, options),
            register: implementation => sharedServices.register({ channel, service, implementation }),
        }
    }

    export async function unregister(implementation: Interface): Promise<void> {
        void ServiceManager.shared().unregister(implementation)
    }

    /*
     * Types
     */

    export type BeepEvent = ServiceValue<{
        name: string
    }>
}

/*
 **********
 *********
 ********
 *******
 ****** ♻️ Global types generated from .d.ts — do not modify
 *****
 ****
 ***
 **
 */

export type Point = ServiceValue<{
    x: float
    y: float
}>

export type Size = ServiceValue<{
    width: float
    height: float
}>

export type Rect = ServiceValue<{
    x: float
    y: float
    width: float
    height: float
}>

export type User = ServiceValue<{
    id: string
    name: string
    initials: string
    avatar?: string
}>

export type Color = ServiceValue<{
    color?: string
}>

export type MagnificationEventType = ServiceValue<"magnificationBegan" | "magnificationChanged" | "magnificationEnded">

export type MagnificationEvent = ServiceValue<{
    type: "magnificationBegan" | "magnificationChanged" | "magnificationEnded"
    magnification: float
    normalizedTouchOffset: Point
}>

export type MenuAction = ServiceValue<{
    action: string
}>

export type MenuValidationResult = ServiceValue<{
    states: readonly MenuActionState[]
}>

export type MenuDispatchResult = ServiceValue<{
    success: boolean
}>

export type MenuActionState = ServiceValue<{
    action: string
    enabled?: boolean
    deferToNative?: boolean
    label?: string
    checked?: boolean
    visible?: boolean
}>

export type ContextMenu = ServiceValue<{
    items: readonly MenuItemOptions[]
    location?: Point
}>

export type MenuItemOptions = ServiceValue<{
    label?: string
    role?: string
    type?: string
    enabled?: boolean
    checked?: boolean
    mixed?: boolean
    visible?: boolean
    accelerator?: string
    acceleratorLabel?: string
    acceleratorLabelWindows?: string
    submenu?: readonly MenuItemOptions[]
    index?: integer
    requiresUserInteraction?: boolean
    alternate?: boolean
}>

export type ContextMenuResult = ServiceValue<{
    indexPath: readonly integer[]
}>

/*
 ****
 ******
 ******
 ******
 ****** ❗️ Auto-generated runtime helpers. See above for service types.
 ****** ❗️ Note: these live with the type definitions to avoid import cycles.
 ******
 ******
 ******
 ****
 */
const { onewayMethodTemplate, voidMethodTemplate, valueMethodTemplate, streamMethodTemplate } = ServiceRuntimePrivate

export namespace ServiceRuntime {
    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace AssetsHelper {
        type Interface = Assets.Interface

        const service: Service<Interface> = {
            id: "__Assets__",
            fingerprint: "b9a181c6fe41ea67f2fdc4ae362d7bad",
            methods: {
                assetUpdatesStream: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    assetUpdatesStream: streamMethodTemplate.bind(/* this: */ -1, "assetUpdatesStream", helper),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(Assets as any).service = service || Assets.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace BeepHelper {
        type Interface = Beep.Interface

        const service: Service<Interface> = {
            id: "__Beep__",
            fingerprint: "61a1004615dc1e33269b7165105b3499",
            methods: {
                beep: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    beep: voidMethodTemplate.bind(/* this: */ -1, "beep", /* accepts argument: */ false, helper),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(Beep as any).service = service || Beep.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace CanvasSandboxServiceHelper {
        type Interface = CanvasSandboxService.Interface

        const service: Service<Interface> = {
            id: "__CanvasSandboxService__",
            fingerprint: "5d467b0a99b3e04019538eabcabdeb1d",
            methods: {
                update: {},
                renderedUpdateStream: {},
                waitUntilResourcesLoaded: {},
                waitForViewportSize: {},
                willStartUpdates: {},
                waitForCompleteRenderAfterUpdates: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    update: onewayMethodTemplate.bind(/* this: */ -1, "update", /* accepts argument: */ true, helper),
                    renderedUpdateStream: streamMethodTemplate.bind(/* this: */ -1, "renderedUpdateStream", helper),
                    waitUntilResourcesLoaded: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "waitUntilResourcesLoaded",
                        /* accepts argument: */ false,
                        helper
                    ),
                    waitForViewportSize: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "waitForViewportSize",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.CanvasSandboxServiceValidation.copyViewportResult : undefined,
                        helper
                    ),
                    willStartUpdates: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "willStartUpdates",
                        /* accepts argument: */ false,
                        helper
                    ),
                    waitForCompleteRenderAfterUpdates: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "waitForCompleteRenderAfterUpdates",
                        /* accepts argument: */ false,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(CanvasSandboxService as any).service = service || CanvasSandboxService.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace ChooseFileHelper {
        type Interface = ChooseFile.Interface

        const service: Service<Interface> = {
            id: "__ChooseFile__",
            fingerprint: "c1a5639db206c727fbac6de436f153ef",
            methods: {
                chooseImage: {},
                chooseFile: {},
                chooseMetadataImage: {},
                addImages: {},
                addImageByURL: {},
                addFonts: {},
                deleteAsset: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    chooseImage: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "chooseImage",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.ChooseFileValidation.copyImageInfos : undefined,
                        helper
                    ),
                    chooseFile: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "chooseFile",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.ChooseFileValidation.copyFileInfo : undefined,
                        helper
                    ),
                    chooseMetadataImage: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "chooseMetadataImage",
                        /* accepts argument: */ true,
                        helper
                    ),
                    addImages: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "addImages",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.ChooseFileValidation.copyImageInfos : undefined,
                        helper
                    ),
                    addImageByURL: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "addImageByURL",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.ChooseFileValidation.copyImageInfos : undefined,
                        helper
                    ),
                    addFonts: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "addFonts",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.ChooseFileValidation.copyFontInfos : undefined,
                        helper
                    ),
                    deleteAsset: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "deleteAsset",
                        /* accepts argument: */ true,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(ChooseFile as any).service = service || ChooseFile.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace ClipboardHelper {
        type Interface = Clipboard.Interface

        const service: Service<Interface> = {
            id: "__Clipboard__",
            fingerprint: "8b26d39afd8058e6367e1a91d3ed4339",
            methods: {
                write: {},
                read: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    write: voidMethodTemplate.bind(/* this: */ -1, "write", /* accepts argument: */ true, helper),
                    read: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "read",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.ClipboardValidation.copyRead : undefined,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(Clipboard as any).service = service || Clipboard.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace ColorSamplerHelper {
        type Interface = ColorSampler.Interface

        const service: Service<Interface> = {
            id: "__ColorSampler__",
            fingerprint: "2f07b191423077f3239a93a5dec7e417",
            methods: {
                sampleColor: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    sampleColor: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "sampleColor",
                        /* accepts argument: */ false,
                        validateValues ? ServiceRuntime.Validation.copyColor : undefined,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(ColorSampler as any).service = service || ColorSampler.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace ControlsVisibilityHelper {
        type Interface = ControlsVisibility.Interface

        const service: Service<Interface> = {
            id: "__ControlsVisibility__",
            fingerprint: "f4f66d27899f64717e5e4bea20f682fe",
            methods: {
                requestHiddenStateForComponentControls: {},
                requestHiddenStateForActionControls: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    requestHiddenStateForComponentControls: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "requestHiddenStateForComponentControls",
                        /* accepts argument: */ true,
                        validateValues
                            ? ServiceRuntime.ControlsVisibilityValidation.copyHiddenStateForComponentControlsResponse
                            : undefined,
                        helper
                    ),
                    requestHiddenStateForActionControls: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "requestHiddenStateForActionControls",
                        /* accepts argument: */ true,
                        validateValues
                            ? ServiceRuntime.ControlsVisibilityValidation.copyHiddenStateForActionControlsResponse
                            : undefined,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(ControlsVisibility as any).service = service || ControlsVisibility.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace DependenciesHelper {
        type Interface = Dependencies.Interface

        const service: Service<Interface> = {
            id: "__Dependencies__",
            fingerprint: "e35b0e46286d0c8f07971a985838412f",
            methods: {
                addDependency: {},
                removeDependency: {},
                getDependencies: {},
                getTypings: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    addDependency: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "addDependency",
                        /* accepts argument: */ true,
                        helper
                    ),
                    removeDependency: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "removeDependency",
                        /* accepts argument: */ true,
                        helper
                    ),
                    getDependencies: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "getDependencies",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.DependenciesValidation.copyGetDependenciesResult : undefined,
                        helper
                    ),
                    getTypings: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "getTypings",
                        /* accepts argument: */ false,
                        validateValues ? ServiceRuntime.DependenciesValidation.copyGetTypingsResult : undefined,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(Dependencies as any).service = service || Dependencies.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace EditorAppHelper {
        type Interface = EditorApp.Interface

        const service: Service<Interface> = {
            id: "__EditorApp__",
            fingerprint: "fae685e9f19f181d1454368d232b617f",
            methods: {
                loadBlankProject: {},
                loadProject: {},
                prepareNodeSnapshot: {},
                prepareScopeSnapshot: {},
                waitForRenderCycle: {},
                waitUntilRendered: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    loadBlankProject: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "loadBlankProject",
                        /* accepts argument: */ false,
                        helper
                    ),
                    loadProject: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "loadProject",
                        /* accepts argument: */ true,
                        helper
                    ),
                    prepareNodeSnapshot: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "prepareNodeSnapshot",
                        /* accepts argument: */ true,
                        helper
                    ),
                    prepareScopeSnapshot: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "prepareScopeSnapshot",
                        /* accepts argument: */ true,
                        helper
                    ),
                    waitForRenderCycle: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "waitForRenderCycle",
                        /* accepts argument: */ false,
                        helper
                    ),
                    waitUntilRendered: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "waitUntilRendered",
                        /* accepts argument: */ false,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(EditorApp as any).service = service || EditorApp.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace ElectronThemeListenerHelper {
        type Interface = ElectronThemeListener.Interface

        const service: Service<Interface> = {
            id: "__ElectronThemeListener__",
            fingerprint: "b1b753b36baf4618b02846a2bf250769",
            methods: {
                onThemeChange: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    onThemeChange: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "onThemeChange",
                        /* accepts argument: */ true,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(ElectronThemeListener as any).service = service || ElectronThemeListener.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace ElectronAppHelper {
        type Interface = ElectronApp.Interface

        const service: Service<Interface> = {
            id: "__ElectronApp__",
            fingerprint: "c53958d707eee4c41db8e90f9d016153",
            methods: {
                appStateStream: {},
                windowStateStream: {},
                selectDashboard: {},
                selectProject: {},
                moveProject: {},
                closeProject: {},
                newProject: {},
                minimizeWindow: {},
                maximizeWindow: {},
                unmaximizeWindow: {},
                closeWindow: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    appStateStream: streamMethodTemplate.bind(/* this: */ -1, "appStateStream", helper),
                    windowStateStream: streamMethodTemplate.bind(/* this: */ -1, "windowStateStream", helper),
                    selectDashboard: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "selectDashboard",
                        /* accepts argument: */ false,
                        helper
                    ),
                    selectProject: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "selectProject",
                        /* accepts argument: */ true,
                        helper
                    ),
                    moveProject: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "moveProject",
                        /* accepts argument: */ true,
                        helper
                    ),
                    closeProject: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "closeProject",
                        /* accepts argument: */ true,
                        helper
                    ),
                    newProject: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "newProject",
                        /* accepts argument: */ false,
                        helper
                    ),
                    minimizeWindow: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "minimizeWindow",
                        /* accepts argument: */ false,
                        helper
                    ),
                    maximizeWindow: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "maximizeWindow",
                        /* accepts argument: */ false,
                        helper
                    ),
                    unmaximizeWindow: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "unmaximizeWindow",
                        /* accepts argument: */ false,
                        helper
                    ),
                    closeWindow: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "closeWindow",
                        /* accepts argument: */ false,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(ElectronApp as any).service = service || ElectronApp.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace ElectronColorSamplerHelper {
        type Interface = ElectronColorSampler.Interface

        const service: Service<Interface> = {
            id: "__ElectronColorSampler__",
            fingerprint: "20d1c2306e05bdf220bdebbbbfb543b9",
            methods: {
                bitmapStream: {},
                onSelect: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    bitmapStream: streamMethodTemplate.bind(/* this: */ -1, "bitmapStream", helper),
                    onSelect: voidMethodTemplate.bind(/* this: */ -1, "onSelect", /* accepts argument: */ true, helper),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(ElectronColorSampler as any).service = service || ElectronColorSampler.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace ElectronWebRenderingHelper {
        type Interface = ElectronWebRendering.Interface

        const service: Service<Interface> = {
            id: "__ElectronWebRendering__",
            fingerprint: "33ebb72c0561bd975f387431839b401b",
            methods: {
                willOpenWindow: {},
                resizeWindow: {},
                takeSnapshotOfWindow: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    willOpenWindow: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "willOpenWindow",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.ElectronWebRenderingValidation.copyWindowReference : undefined,
                        helper
                    ),
                    resizeWindow: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "resizeWindow",
                        /* accepts argument: */ true,
                        helper
                    ),
                    takeSnapshotOfWindow: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "takeSnapshotOfWindow",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.ElectronWebRenderingValidation.copySnapshot : undefined,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(ElectronWebRendering as any).service = service || ElectronWebRendering.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace FeedbackServiceHelper {
        type Interface = FeedbackService.Interface

        const service: Service<Interface> = {
            id: "__FeedbackService__",
            fingerprint: "4ee227432f6212a9db60c0d1e57fa1ad",
            methods: {
                getThreads: {},
                createThread: {},
                updateThread: {},
                createComment: {},
                updateComment: {},
                deleteComment: {},
                markAsSeen: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    getThreads: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "getThreads",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.FeedbackServiceValidation.copyGetThreadsResponse : undefined,
                        helper
                    ),
                    createThread: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "createThread",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.FeedbackServiceValidation.copyCreateThreadResponse : undefined,
                        helper
                    ),
                    updateThread: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "updateThread",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.FeedbackServiceValidation.copyUpdateThreadResponse : undefined,
                        helper
                    ),
                    createComment: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "createComment",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.FeedbackServiceValidation.copyCreateCommentResponse : undefined,
                        helper
                    ),
                    updateComment: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "updateComment",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.FeedbackServiceValidation.copyUpdateCommentResponse : undefined,
                        helper
                    ),
                    deleteComment: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "deleteComment",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.FeedbackServiceValidation.copyDeleteCommentResponse : undefined,
                        helper
                    ),
                    markAsSeen: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "markAsSeen",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.FeedbackServiceValidation.copyMarkAsSeenResponse : undefined,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(FeedbackService as any).service = service || FeedbackService.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace FeedbackDocumentHelper {
        type Interface = FeedbackDocument.Interface

        const service: Service<Interface> = {
            id: "__FeedbackDocument__",
            fingerprint: "7bf4fe1fa7575a9f5f448bd54868864a",
            methods: {
                openThread: {},
                feedbackStateStream: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    openThread: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "openThread",
                        /* accepts argument: */ true,
                        helper
                    ),
                    feedbackStateStream: streamMethodTemplate.bind(/* this: */ -1, "feedbackStateStream", helper),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(FeedbackDocument as any).service = service || FeedbackDocument.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace FigmaImporterHelper {
        type Interface = FigmaImporter.Interface

        const service: Service<Interface> = {
            id: "__FigmaImporter__",
            fingerprint: "f96fe3da90fa195b9308740d9e21e8f7",
            methods: {
                importFigmaFile: {},
                startImportFigmaFile: {},
                importFigmaFileStream: {},
                fetchFigmaFile: {},
                isAuthenticatedWithFigma: {},
                authenticateWithFigma: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    importFigmaFile: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "importFigmaFile",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.FigmaImporterValidation.copyImportFigmaFileResponse : undefined,
                        helper
                    ),
                    startImportFigmaFile: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "startImportFigmaFile",
                        /* accepts argument: */ true,
                        helper
                    ),
                    importFigmaFileStream: streamMethodTemplate.bind(/* this: */ -1, "importFigmaFileStream", helper),
                    fetchFigmaFile: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "fetchFigmaFile",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.FigmaImporterValidation.copyFetchFigmaFileResponse : undefined,
                        helper
                    ),
                    isAuthenticatedWithFigma: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "isAuthenticatedWithFigma",
                        /* accepts argument: */ false,
                        validateValues
                            ? ServiceRuntime.FigmaImporterValidation.copyIsAuthenticatedWithFigmaResponse
                            : undefined,
                        helper
                    ),
                    authenticateWithFigma: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "authenticateWithFigma",
                        /* accepts argument: */ false,
                        validateValues
                            ? ServiceRuntime.FigmaImporterValidation.copyIsAuthenticatedWithFigmaResponse
                            : undefined,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(FigmaImporter as any).service = service || FigmaImporter.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace FilesHelper {
        type Interface = Files.Interface

        const service: Service<Interface> = {
            id: "__Files__",
            fingerprint: "c5bf053de2ec5854cd50f4b4c3b84ae8",
            methods: {
                saveFile: {},
                deleteFile: {},
                getFile: {},
                getFileList: {},
                buildOutputStream: {},
                getBuildOutput: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    saveFile: voidMethodTemplate.bind(/* this: */ -1, "saveFile", /* accepts argument: */ true, helper),
                    deleteFile: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "deleteFile",
                        /* accepts argument: */ true,
                        helper
                    ),
                    getFile: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "getFile",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.FilesValidation.copyFileResult : undefined,
                        helper
                    ),
                    getFileList: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "getFileList",
                        /* accepts argument: */ false,
                        validateValues ? ServiceRuntime.FilesValidation.copyFileListResult : undefined,
                        helper
                    ),
                    buildOutputStream: streamMethodTemplate.bind(/* this: */ -1, "buildOutputStream", helper),
                    getBuildOutput: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "getBuildOutput",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.FilesValidation.copyFileResult : undefined,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(Files as any).service = service || Files.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace FileUpdatesHelper {
        type Interface = FileUpdates.Interface

        const service: Service<Interface> = {
            id: "__FileUpdates__",
            fingerprint: "bf8799d0b289a18e9b9b6eccc76173fa",
            methods: {
                fileUpdatesStream: {},
                notify: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    fileUpdatesStream: streamMethodTemplate.bind(/* this: */ -1, "fileUpdatesStream", helper),
                    notify: voidMethodTemplate.bind(/* this: */ -1, "notify", /* accepts argument: */ true, helper),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(FileUpdates as any).service = service || FileUpdates.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace GestureEventTargetHelper {
        type Interface = GestureEventTarget.Interface

        const service: Service<Interface> = {
            id: "__GestureEventTarget__",
            fingerprint: "eea70ddc08f14b4c6d18b1989490f536",
            methods: {
                dispatchMagnificationEvent: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    dispatchMagnificationEvent: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "dispatchMagnificationEvent",
                        /* accepts argument: */ true,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(GestureEventTarget as any).service = service || GestureEventTarget.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace MenuActionTargetHelper {
        type Interface = MenuActionTarget.Interface

        const service: Service<Interface> = {
            id: "__MenuActionTarget__",
            fingerprint: "2288dedaaf67013b5294929342d641ea",
            methods: {
                validate: {},
                dispatch: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    validate: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "validate",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.Validation.copyMenuValidationResult : undefined,
                        helper
                    ),
                    dispatch: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "dispatch",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.Validation.copyMenuDispatchResult : undefined,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(MenuActionTarget as any).service = service || MenuActionTarget.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace ContextMenuPresenterHelper {
        type Interface = ContextMenuPresenter.Interface

        const service: Service<Interface> = {
            id: "__ContextMenuPresenter__",
            fingerprint: "a4b0d912535231b24a93419060dc1b1d",
            methods: {
                showContextMenu: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    showContextMenu: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "showContextMenu",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.Validation.copyContextMenuResult : undefined,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(ContextMenuPresenter as any).service = service || ContextMenuPresenter.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace ModalsHelper {
        type Interface = Modals.Interface

        const service: Service<Interface> = {
            id: "__Modals__",
            fingerprint: "29ee4d108d91b436102920fca517367a",
            methods: {
                setActiveModal: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    setActiveModal: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "setActiveModal",
                        /* accepts argument: */ true,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(Modals as any).service = service || Modals.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace ModulesAPIHelper {
        type Interface = ModulesAPI.Interface

        const service: Service<Interface> = {
            id: "__ModulesAPI__",
            fingerprint: "c61bd353ecc649dfa3f7205c6478fef3",
            methods: {
                create: {},
                delete: {},
                list: {},
                listNamespaces: {},
                listPublishedModules: {},
                lookUpModules: {},
                moduleEventsStream: {},
                publish: {},
                save: {},
                saveBatch: {},
                update: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    create: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "create",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.ModulesAPIValidation.copyModuleWithSave : undefined,
                        helper
                    ),
                    delete: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "delete",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.ModulesAPIValidation.copyModuleWithSave : undefined,
                        helper
                    ),
                    list: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "list",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.ModulesAPIValidation.copyListResponse : undefined,
                        helper
                    ),
                    listNamespaces: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "listNamespaces",
                        /* accepts argument: */ false,
                        validateValues ? ServiceRuntime.ModulesAPIValidation.copyListNamespacesResponse : undefined,
                        helper
                    ),
                    listPublishedModules: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "listPublishedModules",
                        /* accepts argument: */ true,
                        validateValues
                            ? ServiceRuntime.ModulesAPIValidation.copyListPublishedModulesResponse
                            : undefined,
                        helper
                    ),
                    lookUpModules: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "lookUpModules",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.ModulesAPIValidation.copyListResponse : undefined,
                        helper
                    ),
                    moduleEventsStream: streamMethodTemplate.bind(/* this: */ -1, "moduleEventsStream", helper),
                    publish: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "publish",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.ModulesAPIValidation.copyPublish : undefined,
                        helper
                    ),
                    save: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "save",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.ModulesAPIValidation.copyModuleWithSave : undefined,
                        helper
                    ),
                    saveBatch: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "saveBatch",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.ModulesAPIValidation.copyListResponse : undefined,
                        helper
                    ),
                    update: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "update",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.ModulesAPIValidation.copyModuleWithSave : undefined,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(ModulesAPI as any).service = service || ModulesAPI.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace ModulesStateHelper {
        type Interface = ModulesState.Interface

        const service: Service<Interface> = {
            id: "__ModulesState__",
            fingerprint: "2e3c8dddd143575960e971b769b19b4c",
            methods: {
                getModules: {},
                moduleUpdatesStream: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    getModules: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "getModules",
                        /* accepts argument: */ false,
                        validateValues ? ServiceRuntime.ModulesStateValidation.copyGetModulesResponse : undefined,
                        helper
                    ),
                    moduleUpdatesStream: streamMethodTemplate.bind(/* this: */ -1, "moduleUpdatesStream", helper),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(ModulesState as any).service = service || ModulesState.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace PreviewSandboxHelper {
        type Interface = PreviewSandbox.Interface

        const service: Service<Interface> = {
            id: "__PreviewSandbox__",
            fingerprint: "4eea0bd91b19b7b54cef26b6406a3290",
            methods: {
                pause: {},
                resume: {},
                reload: {},
                setAutoReload: {},
                previewInteractionStream: {},
                previewKeyboardEventStream: {},
                previewLogStream: {},
                navigationEventStream: {},
                renderPhaseEventStream: {},
                showHighlights: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    pause: voidMethodTemplate.bind(/* this: */ -1, "pause", /* accepts argument: */ false, helper),
                    resume: voidMethodTemplate.bind(/* this: */ -1, "resume", /* accepts argument: */ false, helper),
                    reload: voidMethodTemplate.bind(/* this: */ -1, "reload", /* accepts argument: */ false, helper),
                    setAutoReload: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "setAutoReload",
                        /* accepts argument: */ true,
                        helper
                    ),
                    previewInteractionStream: streamMethodTemplate.bind(
                        /* this: */ -1,
                        "previewInteractionStream",
                        helper
                    ),
                    previewKeyboardEventStream: streamMethodTemplate.bind(
                        /* this: */ -1,
                        "previewKeyboardEventStream",
                        helper
                    ),
                    previewLogStream: streamMethodTemplate.bind(/* this: */ -1, "previewLogStream", helper),
                    navigationEventStream: streamMethodTemplate.bind(/* this: */ -1, "navigationEventStream", helper),
                    renderPhaseEventStream: streamMethodTemplate.bind(/* this: */ -1, "renderPhaseEventStream", helper),
                    showHighlights: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "showHighlights",
                        /* accepts argument: */ false,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(PreviewSandbox as any).service = service || PreviewSandbox.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace PreviewWrapperHelper {
        type Interface = PreviewWrapper.Interface

        const service: Service<Interface> = {
            id: "__PreviewWrapper__",
            fingerprint: "69148581a519a3d13371956baf60b42f",
            methods: {
                toggleConsole: {},
                stateStream: {},
                loadStream: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    toggleConsole: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "toggleConsole",
                        /* accepts argument: */ true,
                        helper
                    ),
                    stateStream: streamMethodTemplate.bind(/* this: */ -1, "stateStream", helper),
                    loadStream: streamMethodTemplate.bind(/* this: */ -1, "loadStream", helper),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(PreviewWrapper as any).service = service || PreviewWrapper.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace PreviewDataSourceHelper {
        type Interface = PreviewDataSource.Interface

        const service: Service<Interface> = {
            id: "__PreviewDataSource__",
            fingerprint: "9b5b6138c70bfa4b98bb8acb885d2ce1",
            methods: {
                loadDocument: {},
                scriptUpdateStream: {},
                treeUpdateStream: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    loadDocument: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "loadDocument",
                        /* accepts argument: */ false,
                        validateValues
                            ? ServiceRuntime.PreviewDataSourceValidation.copyLoadDocumentResponse
                            : undefined,
                        helper
                    ),
                    scriptUpdateStream: streamMethodTemplate.bind(/* this: */ -1, "scriptUpdateStream", helper),
                    treeUpdateStream: streamMethodTemplate.bind(/* this: */ -1, "treeUpdateStream", helper),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(PreviewDataSource as any).service = service || PreviewDataSource.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace PreviewSettingsHelper {
        type Interface = PreviewSettings.Interface

        const service: Service<Interface> = {
            id: "__PreviewSettings__",
            fingerprint: "4e864b6699825f976da1007092211b98",
            methods: {
                toggleSelectionLock: {},
                toggleResponsive: {},
                toggleTouchCursor: {},
                setAppearance: {},
                toggleHighlights: {},
                setProjectTitle: {},
                openInExternalWindow: {},
                openFullScreen: {},
                addDevice: {},
                previewMetadataUpdateStream: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    toggleSelectionLock: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "toggleSelectionLock",
                        /* accepts argument: */ true,
                        helper
                    ),
                    toggleResponsive: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "toggleResponsive",
                        /* accepts argument: */ true,
                        helper
                    ),
                    toggleTouchCursor: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "toggleTouchCursor",
                        /* accepts argument: */ true,
                        helper
                    ),
                    setAppearance: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "setAppearance",
                        /* accepts argument: */ true,
                        helper
                    ),
                    toggleHighlights: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "toggleHighlights",
                        /* accepts argument: */ false,
                        helper
                    ),
                    setProjectTitle: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "setProjectTitle",
                        /* accepts argument: */ true,
                        helper
                    ),
                    openInExternalWindow: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "openInExternalWindow",
                        /* accepts argument: */ false,
                        helper
                    ),
                    openFullScreen: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "openFullScreen",
                        /* accepts argument: */ false,
                        helper
                    ),
                    addDevice: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "addDevice",
                        /* accepts argument: */ false,
                        helper
                    ),
                    previewMetadataUpdateStream: streamMethodTemplate.bind(
                        /* this: */ -1,
                        "previewMetadataUpdateStream",
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(PreviewSettings as any).service = service || PreviewSettings.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace PreviewStoreHelper {
        type Interface = PreviewStore.Interface

        const service: Service<Interface> = {
            id: "__PreviewStore__",
            fingerprint: "970e3c2f2c0fdad0073b6008f7171827",
            methods: {
                toggleVisible: {},
                toggleHighlights: {},
                updateStream: {},
                getSharedPreviewMetadata: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    toggleVisible: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "toggleVisible",
                        /* accepts argument: */ true,
                        helper
                    ),
                    toggleHighlights: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "toggleHighlights",
                        /* accepts argument: */ true,
                        helper
                    ),
                    updateStream: streamMethodTemplate.bind(/* this: */ -1, "updateStream", helper),
                    getSharedPreviewMetadata: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "getSharedPreviewMetadata",
                        /* accepts argument: */ false,
                        validateValues
                            ? ServiceRuntime.PreviewStoreValidation.copyGetSharedPreviewMetadataResponse
                            : undefined,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(PreviewStore as any).service = service || PreviewStore.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace PreviewWindowShortcutsHelper {
        type Interface = PreviewWindowShortcuts.Interface

        const service: Service<Interface> = {
            id: "__PreviewWindowShortcuts__",
            fingerprint: "4ee028237ee29dbb0e055e29fed87dbc",
            methods: {
                reload: {},
                toggleConsole: {},
                toggleHighlights: {},
                toggleSelectionLock: {},
                openFullScreen: {},
                openInExternalWindow: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    reload: voidMethodTemplate.bind(/* this: */ -1, "reload", /* accepts argument: */ false, helper),
                    toggleConsole: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "toggleConsole",
                        /* accepts argument: */ false,
                        helper
                    ),
                    toggleHighlights: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "toggleHighlights",
                        /* accepts argument: */ false,
                        helper
                    ),
                    toggleSelectionLock: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "toggleSelectionLock",
                        /* accepts argument: */ false,
                        helper
                    ),
                    openFullScreen: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "openFullScreen",
                        /* accepts argument: */ false,
                        helper
                    ),
                    openInExternalWindow: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "openInExternalWindow",
                        /* accepts argument: */ false,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(PreviewWindowShortcuts as any).service = service || PreviewWindowShortcuts.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace PreviewDesktopHelper {
        type Interface = PreviewDesktop.Interface

        const service: Service<Interface> = {
            id: "__PreviewDesktop__",
            fingerprint: "40a6169dc2d5fa19212c53b8411a5166",
            methods: {
                onPreviewMetadataUpdate: {},
                onLogError: {},
                onLogWarning: {},
                onLogClear: {},
                onScaleChange: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    onPreviewMetadataUpdate: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "onPreviewMetadataUpdate",
                        /* accepts argument: */ true,
                        helper
                    ),
                    onLogError: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "onLogError",
                        /* accepts argument: */ false,
                        helper
                    ),
                    onLogWarning: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "onLogWarning",
                        /* accepts argument: */ false,
                        helper
                    ),
                    onLogClear: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "onLogClear",
                        /* accepts argument: */ false,
                        helper
                    ),
                    onScaleChange: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "onScaleChange",
                        /* accepts argument: */ true,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(PreviewDesktop as any).service = service || PreviewDesktop.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace ProjectPackageHelper {
        type Interface = ProjectPackage.Interface

        const service: Service<Interface> = {
            id: "__ProjectPackage__",
            fingerprint: "a1104d8d48f6d702f903ac5bffc9030b",
            methods: {
                uploadDesignAsset: {},
                uploadReadme: {},
                fetchReadme: {},
                fetchPackageJson: {},
                addPackageDependency: {},
                publishPackage: {},
                buildPackage: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    uploadDesignAsset: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "uploadDesignAsset",
                        /* accepts argument: */ true,
                        helper
                    ),
                    uploadReadme: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "uploadReadme",
                        /* accepts argument: */ true,
                        helper
                    ),
                    fetchReadme: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "fetchReadme",
                        /* accepts argument: */ false,
                        validateValues ? ServiceRuntime.ProjectPackageValidation.copyFetchReadmeResponse : undefined,
                        helper
                    ),
                    fetchPackageJson: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "fetchPackageJson",
                        /* accepts argument: */ false,
                        validateValues
                            ? ServiceRuntime.ProjectPackageValidation.copyFetchPackageJsonResponse
                            : undefined,
                        helper
                    ),
                    addPackageDependency: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "addPackageDependency",
                        /* accepts argument: */ true,
                        helper
                    ),
                    publishPackage: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "publishPackage",
                        /* accepts argument: */ true,
                        helper
                    ),
                    buildPackage: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "buildPackage",
                        /* accepts argument: */ false,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(ProjectPackage as any).service = service || ProjectPackage.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace ProjectStateHelper {
        type Interface = ProjectState.Interface

        const service: Service<Interface> = {
            id: "__ProjectState__",
            fingerprint: "1deee6f8df3fd9d281785ed6ac525690",
            methods: {
                assetChangeStream: {},
                stateStream: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    assetChangeStream: streamMethodTemplate.bind(/* this: */ -1, "assetChangeStream", helper),
                    stateStream: streamMethodTemplate.bind(/* this: */ -1, "stateStream", helper),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(ProjectState as any).service = service || ProjectState.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace SandboxComponentLoaderHelper {
        type Interface = SandboxComponentLoader.Interface

        const service: Service<Interface> = {
            id: "__SandboxComponentLoader__",
            fingerprint: "a7681109569f5f67c6f2bab628c6daa1",
            methods: {
                componentLoaderStateStream: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    componentLoaderStateStream: streamMethodTemplate.bind(
                        /* this: */ -1,
                        "componentLoaderStateStream",
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(SandboxComponentLoader as any).service = service || SandboxComponentLoader.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace SandboxInitializerHelper {
        type Interface = SandboxInitializer.Interface

        const service: Service<Interface> = {
            id: "__SandboxInitializer__",
            fingerprint: "d377b0892e3adeaf2f23d084903aa69b",
            methods: {
                initialize: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    initialize: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "initialize",
                        /* accepts argument: */ true,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(SandboxInitializer as any).service = service || SandboxInitializer.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace SnapshotViewportHelper {
        type Interface = SnapshotViewport.Interface

        const service: Service<Interface> = {
            id: "__SnapshotViewport__",
            fingerprint: "5d7944c615588110457196b6afc7ce01",
            methods: {
                setViewportSize: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    setViewportSize: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "setViewportSize",
                        /* accepts argument: */ true,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(SnapshotViewport as any).service = service || SnapshotViewport.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace PublicStoreClientHelper {
        type Interface = PublicStoreClient.Interface

        const service: Service<Interface> = {
            id: "__PublicStoreClient__",
            fingerprint: "9a5863c82d326ca85b714ec14fc5ddd8",
            methods: {
                findByName: {},
                containsPackage: {},
                toggleFavorite: {},
                fetchRecents: {},
                fetchPackagesBySection: {},
                fetchPackagesByPublisher: {},
                fetchPackagesByPopularity: {},
                fetchFeaturedPackages: {},
                search: {},
                getPublishStatus: {},
                fetchPreflight: {},
                removePackage: {},
                fetchMeta: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    findByName: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "findByName",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPackage : undefined,
                        helper
                    ),
                    containsPackage: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "containsPackage",
                        /* accepts argument: */ true,
                        validateValues
                            ? ServiceRuntime.PublicStoreClientValidation.copyContainsPackageResponse
                            : undefined,
                        helper
                    ),
                    toggleFavorite: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "toggleFavorite",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPackage : undefined,
                        helper
                    ),
                    fetchRecents: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "fetchRecents",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPackageArray : undefined,
                        helper
                    ),
                    fetchPackagesBySection: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "fetchPackagesBySection",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPackageArray : undefined,
                        helper
                    ),
                    fetchPackagesByPublisher: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "fetchPackagesByPublisher",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPackageArray : undefined,
                        helper
                    ),
                    fetchPackagesByPopularity: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "fetchPackagesByPopularity",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPackageArray : undefined,
                        helper
                    ),
                    fetchFeaturedPackages: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "fetchFeaturedPackages",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPackageArray : undefined,
                        helper
                    ),
                    search: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "search",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPackageArray : undefined,
                        helper
                    ),
                    getPublishStatus: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "getPublishStatus",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPublishStatus : undefined,
                        helper
                    ),
                    fetchPreflight: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "fetchPreflight",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPreflightResponse : undefined,
                        helper
                    ),
                    removePackage: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "removePackage",
                        /* accepts argument: */ true,
                        helper
                    ),
                    fetchMeta: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "fetchMeta",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyFetchMetaResponse : undefined,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(PublicStoreClient as any).service = service || PublicStoreClient.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace PrivateStoreClientHelper {
        type Interface = PrivateStoreClient.Interface

        const service: Service<Interface> = {
            id: "__PrivateStoreClient__",
            fingerprint: "a762c30223d99fe2062dceb634663456",
            methods: {
                findByName: {},
                containsPackage: {},
                toggleFavorite: {},
                fetchRecents: {},
                fetchPackagesBySection: {},
                fetchPackagesByPublisher: {},
                fetchPackagesByPopularity: {},
                fetchFeaturedPackages: {},
                search: {},
                getPublishStatus: {},
                fetchPreflight: {},
                removePackage: {},
                fetchMeta: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    findByName: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "findByName",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPackage : undefined,
                        helper
                    ),
                    containsPackage: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "containsPackage",
                        /* accepts argument: */ true,
                        validateValues
                            ? ServiceRuntime.PublicStoreClientValidation.copyContainsPackageResponse
                            : undefined,
                        helper
                    ),
                    toggleFavorite: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "toggleFavorite",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPackage : undefined,
                        helper
                    ),
                    fetchRecents: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "fetchRecents",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPackageArray : undefined,
                        helper
                    ),
                    fetchPackagesBySection: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "fetchPackagesBySection",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPackageArray : undefined,
                        helper
                    ),
                    fetchPackagesByPublisher: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "fetchPackagesByPublisher",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPackageArray : undefined,
                        helper
                    ),
                    fetchPackagesByPopularity: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "fetchPackagesByPopularity",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPackageArray : undefined,
                        helper
                    ),
                    fetchFeaturedPackages: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "fetchFeaturedPackages",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPackageArray : undefined,
                        helper
                    ),
                    search: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "search",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPackageArray : undefined,
                        helper
                    ),
                    getPublishStatus: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "getPublishStatus",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPublishStatus : undefined,
                        helper
                    ),
                    fetchPreflight: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "fetchPreflight",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyPreflightResponse : undefined,
                        helper
                    ),
                    removePackage: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "removePackage",
                        /* accepts argument: */ true,
                        helper
                    ),
                    fetchMeta: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "fetchMeta",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.PublicStoreClientValidation.copyFetchMetaResponse : undefined,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(PrivateStoreClient as any).service = service || PrivateStoreClient.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace VideoTutorialHelper {
        type Interface = VideoTutorial.Interface

        const service: Service<Interface> = {
            id: "__VideoTutorial__",
            fingerprint: "222be82e50bcc2c3ce26f4a16e10ff8d",
            methods: {
                fetchVideoState: {},
                updateVideoState: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    fetchVideoState: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "fetchVideoState",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.VideoTutorialValidation.copyVideoTutorialState : undefined,
                        helper
                    ),
                    updateVideoState: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "updateVideoState",
                        /* accepts argument: */ true,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(VideoTutorial as any).service = service || VideoTutorial.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace UnsplashClientHelper {
        type Interface = UnsplashClient.Interface

        const service: Service<Interface> = {
            id: "__UnsplashClient__",
            fingerprint: "8ee51675b2fe29e5550eb11b6d0b54c1",
            methods: {
                listPhotos: {},
                searchPhotos: {},
                getRandomPhoto: {},
                downloadPhoto: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    listPhotos: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "listPhotos",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.UnsplashClientValidation.copyListPhotosResponse : undefined,
                        helper
                    ),
                    searchPhotos: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "searchPhotos",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.UnsplashClientValidation.copySearchPhotosResponse : undefined,
                        helper
                    ),
                    getRandomPhoto: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "getRandomPhoto",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.UnsplashClientValidation.copyPhoto : undefined,
                        helper
                    ),
                    downloadPhoto: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "downloadPhoto",
                        /* accepts argument: */ true,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(UnsplashClient as any).service = service || UnsplashClient.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace UserNotificationsHelper {
        type Interface = UserNotifications.Interface

        const service: Service<Interface> = {
            id: "__UserNotifications__",
            fingerprint: "b12f5fbc25df3b0f5372f8576a9abf8c",
            methods: {
                requestPermission: {},
                getPermissionStatus: {},
                post: {},
                cancel: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    requestPermission: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "requestPermission",
                        /* accepts argument: */ false,
                        validateValues
                            ? ServiceRuntime.UserNotificationsValidation.copyRequestPermissionResponse
                            : undefined,
                        helper
                    ),
                    getPermissionStatus: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "getPermissionStatus",
                        /* accepts argument: */ false,
                        validateValues
                            ? ServiceRuntime.UserNotificationsValidation.copyGetPermissionStatusResponse
                            : undefined,
                        helper
                    ),
                    post: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "post",
                        /* accepts argument: */ true,
                        validateValues ? ServiceRuntime.UserNotificationsValidation.copyPostResponse : undefined,
                        helper
                    ),
                    cancel: voidMethodTemplate.bind(/* this: */ -1, "cancel", /* accepts argument: */ true, helper),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(UserNotifications as any).service = service || UserNotifications.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace UserSessionServiceHelper {
        type Interface = UserSessionService.Interface

        const service: Service<Interface> = {
            id: "__UserSessionService__",
            fingerprint: "6a12d048c780c35c4d275dd6bc0e4ceb",
            methods: {
                getSessionIdentity: {},
                permissionsStream: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    getSessionIdentity: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "getSessionIdentity",
                        /* accepts argument: */ false,
                        validateValues
                            ? ServiceRuntime.UserSessionServiceValidation.copySessionIdentityResponse
                            : undefined,
                        helper
                    ),
                    permissionsStream: streamMethodTemplate.bind(/* this: */ -1, "permissionsStream", helper),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(UserSessionService as any).service = service || UserSessionService.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace WebProjectHelper {
        type Interface = WebProject.Interface

        const service: Service<Interface> = {
            id: "__WebProject__",
            fingerprint: "5f24f7bb3934c300a4f49b7fe995498f",
            methods: {
                currentProjectStream: {},
                showMoveProjectMenu: {},
                showUpgradeOrTrialModal: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    currentProjectStream: streamMethodTemplate.bind(/* this: */ -1, "currentProjectStream", helper),
                    showMoveProjectMenu: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "showMoveProjectMenu",
                        /* accepts argument: */ true,
                        helper
                    ),
                    showUpgradeOrTrialModal: voidMethodTemplate.bind(
                        /* this: */ -1,
                        "showUpgradeOrTrialModal",
                        /* accepts argument: */ true,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(WebProject as any).service = service || WebProject.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace ZzzTestingBeepHelper {
        type Interface = ZzzTestingBeep.Interface

        const service: Service<Interface> = {
            id: "__ZzzTestingBeep__",
            fingerprint: "61a1004615dc1e33269b7165105b3499",
            methods: {
                beep: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    beep: voidMethodTemplate.bind(/* this: */ -1, "beep", /* accepts argument: */ false, helper),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(ZzzTestingBeep as any).service = service || ZzzTestingBeep.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace ZzzTestingStreamsHelper {
        type Interface = ZzzTestingStreams.Interface

        const service: Service<Interface> = {
            id: "__ZzzTestingStreams__",
            fingerprint: "410a412a130a569a637850ef3c224089",
            methods: {
                beepStream: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    beepStream: streamMethodTemplate.bind(/* this: */ -1, "beepStream", helper),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(ZzzTestingStreams as any).service = service || ZzzTestingStreams.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace ZzzTestingTypesHelper {
        type Interface = ZzzTestingTypes.Interface

        const service: Service<Interface> = {
            id: "__ZzzTestingTypes__",
            fingerprint: "e7282e3450c861adc6fcb44f08e9b6b4",
            methods: {
                test: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    test: valueMethodTemplate.bind(
                        /* this: */ -1,
                        "test",
                        /* accepts argument: */ false,
                        validateValues ? ServiceRuntime.ZzzTestingTypesValidation.copyObject : undefined,
                        helper
                    ),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(ZzzTestingTypes as any).service = service || ZzzTestingTypes.service
    }

    /*
     ****
     ******
     ******
     ******
     ****** ❗️ Auto-generated runtime helper. See above for service types.
     ******
     ******
     ******
     ****
     */
    export namespace ZzzTestingOnewayHelper {
        type Interface = ZzzTestingOneway.Interface

        const service: Service<Interface> = {
            id: "__ZzzTestingOneway__",
            fingerprint: "38a3e6a8064d731e80e2a60fdde4df47",
            methods: {
                beep: {},
                beepStream: {},
            },
            newOutgoingWrapper: (options: ServiceOptions, helper: ServiceMessageHelper): Interface => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { validateValues } = options
                return {
                    beep: onewayMethodTemplate.bind(/* this: */ -1, "beep", /* accepts argument: */ true, helper),
                    beepStream: streamMethodTemplate.bind(/* this: */ -1, "beepStream", helper),
                } as Interface
            },
        }

        /*
         * Because ordering matters in the generated JavaScript, using this service definition object in the
         * "human readable" portion of the service namespace fails. Putting implementation detail first sucks,
         * so just use this insanely ugly hack to assign the declared const.
         */
        ;(ZzzTestingOneway as any).service = service || ZzzTestingOneway.service
    }

    export namespace Validation {
        export const copyPoint = (value: unknown): Point => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Point object but received: ${value}`)
            }

            return Object.freeze({
                x: ServiceRuntimePrivate.Validation.copyFloat((value as any).x),
                y: ServiceRuntimePrivate.Validation.copyFloat((value as any).y),
            })
        }
        export const copySize = (value: unknown): Size => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Size object but received: ${value}`)
            }

            return Object.freeze({
                width: ServiceRuntimePrivate.Validation.copyFloat((value as any).width),
                height: ServiceRuntimePrivate.Validation.copyFloat((value as any).height),
            })
        }
        export const copyRect = (value: unknown): Rect => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Rect object but received: ${value}`)
            }

            return Object.freeze({
                x: ServiceRuntimePrivate.Validation.copyFloat((value as any).x),
                y: ServiceRuntimePrivate.Validation.copyFloat((value as any).y),
                width: ServiceRuntimePrivate.Validation.copyFloat((value as any).width),
                height: ServiceRuntimePrivate.Validation.copyFloat((value as any).height),
            })
        }
        export const copyUser = (value: unknown): User => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected User object but received: ${value}`)
            }

            return Object.freeze({
                id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
                initials: ServiceRuntimePrivate.Validation.copyString((value as any).initials),
                avatar: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).avatar),
            })
        }
        export const copyColor = (value: unknown): Color => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Color object but received: ${value}`)
            }

            return Object.freeze({
                color: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).color),
            })
        }

        const MagnificationEventType__rawValues = new Set<unknown>([
            "magnificationBegan",
            "magnificationChanged",
            "magnificationEnded",
        ])

        export const copyMagnificationEventType = (value: unknown): MagnificationEventType => {
            if (!MagnificationEventType__rawValues.has(value)) {
                throw new Error(`Expected valid value for enum but received: ${value}`)
            }
            return value as MagnificationEventType
        }

        export const copyMagnificationEvent = (value: unknown): MagnificationEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected MagnificationEvent object but received: ${value}`)
            }

            return Object.freeze({
                type: ServiceRuntime.Validation.copyMagnificationEventType((value as any).type),
                magnification: ServiceRuntimePrivate.Validation.copyFloat((value as any).magnification),
                normalizedTouchOffset: ServiceRuntime.Validation.copyPoint((value as any).normalizedTouchOffset),
            })
        }
        export const copyMenuAction = (value: unknown): MenuAction => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected MenuAction object but received: ${value}`)
            }

            return Object.freeze({
                action: ServiceRuntimePrivate.Validation.copyString((value as any).action),
            })
        }
        export const copyMenuValidationResult = (value: unknown): MenuValidationResult => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected MenuValidationResult object but received: ${value}`)
            }

            return Object.freeze({
                states: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.Validation.copyMenuActionState
                )((value as any).states),
            })
        }
        export const copyMenuDispatchResult = (value: unknown): MenuDispatchResult => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected MenuDispatchResult object but received: ${value}`)
            }

            return Object.freeze({
                success: ServiceRuntimePrivate.Validation.copyBoolean((value as any).success),
            })
        }
        export const copyMenuActionState = (value: unknown): MenuActionState => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected MenuActionState object but received: ${value}`)
            }

            return Object.freeze({
                action: ServiceRuntimePrivate.Validation.copyString((value as any).action),
                enabled: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).enabled),
                deferToNative: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).deferToNative),
                label: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).label),
                checked: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).checked),
                visible: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).visible),
            })
        }
        export const copyContextMenu = (value: unknown): ContextMenu => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ContextMenu object but received: ${value}`)
            }

            return Object.freeze({
                items: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.Validation.copyMenuItemOptions
                )((value as any).items),
                location: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntime.Validation.copyPoint
                )((value as any).location),
            })
        }
        export const copyMenuItemOptions = (value: unknown): MenuItemOptions => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected MenuItemOptions object but received: ${value}`)
            }

            return Object.freeze({
                label: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).label),
                role: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).role),
                type: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).type),
                enabled: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).enabled),
                checked: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).checked),
                mixed: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).mixed),
                visible: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).visible),
                accelerator: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).accelerator),
                acceleratorLabel: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).acceleratorLabel),
                acceleratorLabelWindows: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).acceleratorLabelWindows),
                submenu: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyArray.bind(
                        undefined,
                        ServiceRuntime.Validation.copyMenuItemOptions
                    )
                )((value as any).submenu),
                index: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyInteger
                )((value as any).index),
                requiresUserInteraction: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).requiresUserInteraction),
                alternate: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).alternate),
            })
        }
        export const copyContextMenuResult = (value: unknown): ContextMenuResult => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ContextMenuResult object but received: ${value}`)
            }

            return Object.freeze({
                indexPath: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyInteger
                )((value as any).indexPath),
            })
        }
    }

    export namespace AssetsValidation {
        export const copyVariant = (value: unknown): Assets.Variant => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Variant object but received: ${value}`)
            }

            return Object.freeze({
                url: ServiceRuntimePrivate.Validation.copyString((value as any).url),
                maxSideSize: ServiceRuntimePrivate.Validation.copyInteger((value as any).maxSideSize),
            })
        }
        export const copyAssetFontProperties = (value: unknown): Assets.AssetFontProperties => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected AssetFontProperties object but received: ${value}`)
            }

            return Object.freeze({
                fontFamily: ServiceRuntimePrivate.Validation.copyString((value as any).fontFamily),
                fontSubFamily: ServiceRuntimePrivate.Validation.copyString((value as any).fontSubFamily),
                postscriptName: ServiceRuntimePrivate.Validation.copyString((value as any).postscriptName),
                preferredFamily: ServiceRuntimePrivate.Validation.copyString((value as any).preferredFamily),
                preferredSubFamily: ServiceRuntimePrivate.Validation.copyString((value as any).preferredSubFamily),
            })
        }
        export const copyAssetProperties = (value: unknown): Assets.AssetProperties => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected AssetProperties object but received: ${value}`)
            }

            return Object.freeze({
                kind: ServiceRuntimePrivate.Validation.copyString((value as any).kind),
                font: ServiceRuntime.AssetsValidation.copyAssetFontProperties((value as any).font),
            })
        }
        export const copyAsset = (value: unknown): Assets.Asset => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Asset object but received: ${value}`)
            }

            return Object.freeze({
                key: ServiceRuntimePrivate.Validation.copyString((value as any).key),
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
                filename: ServiceRuntimePrivate.Validation.copyString((value as any).filename),
                fileSize: ServiceRuntimePrivate.Validation.copyInteger((value as any).fileSize),
                ownerId: ServiceRuntimePrivate.Validation.copyString((value as any).ownerId),
                ownerType: ServiceRuntimePrivate.Validation.copyString((value as any).ownerType),
                mimeType: ServiceRuntimePrivate.Validation.copyString((value as any).mimeType),
                uploadedBy: ServiceRuntimePrivate.Validation.copyString((value as any).uploadedBy),
                createdAt: ServiceRuntimePrivate.Validation.copyString((value as any).createdAt),
                updatedAt: ServiceRuntimePrivate.Validation.copyString((value as any).updatedAt),
                refreshTimeout: ServiceRuntimePrivate.Validation.copyInteger((value as any).refreshTimeout),
                url: ServiceRuntimePrivate.Validation.copyString((value as any).url),
                variants: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.AssetsValidation.copyVariant
                )((value as any).variants),
                properties: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntime.AssetsValidation.copyAssetProperties
                )((value as any).properties),
            })
        }
        export const copyAssetUpdateEvent = (value: unknown): Assets.AssetUpdateEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected AssetUpdateEvent object but received: ${value}`)
            }

            return Object.freeze({
                assets: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyArray.bind(
                        undefined,
                        ServiceRuntime.AssetsValidation.copyAsset
                    )
                )((value as any).assets),
            })
        }
    }

    export namespace CanvasSandboxServiceValidation {
        export const copyUpdate = (value: unknown): CanvasSandboxService.Update => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Update object but received: ${value}`)
            }

            return Object.freeze({
                fixmeOpaqueJSON: ServiceRuntimePrivate.Validation.copyUnsafeJSON((value as any).fixmeOpaqueJSON),
            })
        }
        export const copyLayoutMetrics = (value: unknown): CanvasSandboxService.LayoutMetrics => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected LayoutMetrics object but received: ${value}`)
            }

            return Object.freeze({
                x: ServiceRuntimePrivate.Validation.copyFloat((value as any).x),
                y: ServiceRuntimePrivate.Validation.copyFloat((value as any).y),
                width: ServiceRuntimePrivate.Validation.copyFloat((value as any).width),
                height: ServiceRuntimePrivate.Validation.copyFloat((value as any).height),
                childrenRects: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyArray.bind(undefined, ServiceRuntime.Validation.copyRect)
                )((value as any).childrenRects),
            })
        }
        export const copyLayoutChange = (value: unknown): CanvasSandboxService.LayoutChange => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected LayoutChange object but received: ${value}`)
            }

            return Object.freeze({
                nodeId: ServiceRuntimePrivate.Validation.copyString((value as any).nodeId),
                layoutMetrics: ServiceRuntime.CanvasSandboxServiceValidation.copyLayoutMetrics(
                    (value as any).layoutMetrics
                ),
            })
        }
        export const copyRenderedUpdate = (value: unknown): CanvasSandboxService.RenderedUpdate => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected RenderedUpdate object but received: ${value}`)
            }

            return Object.freeze({
                update: ServiceRuntimePrivate.Validation.copyInteger((value as any).update),
                updateTime: ServiceRuntimePrivate.Validation.copyInteger((value as any).updateTime),
                renderTime: ServiceRuntimePrivate.Validation.copyInteger((value as any).renderTime),
                layoutChanges: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.CanvasSandboxServiceValidation.copyLayoutChange
                )((value as any).layoutChanges),
            })
        }
        export const copyViewportResult = (value: unknown): CanvasSandboxService.ViewportResult => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ViewportResult object but received: ${value}`)
            }

            return Object.freeze({
                pixelRatio: ServiceRuntimePrivate.Validation.copyFloat((value as any).pixelRatio),
            })
        }
    }

    export namespace ChooseFileValidation {
        export const copyOptions = (value: unknown): ChooseFile.Options => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Options object but received: ${value}`)
            }

            return Object.freeze({
                allowedFileTypes: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).allowedFileTypes),
            })
        }
        export const copyImageSize = (value: unknown): ChooseFile.ImageSize => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ImageSize object but received: ${value}`)
            }

            return Object.freeze({
                width: ServiceRuntimePrivate.Validation.copyFloat((value as any).width),
                height: ServiceRuntimePrivate.Validation.copyFloat((value as any).height),
                pixelWidth: ServiceRuntimePrivate.Validation.copyFloat((value as any).pixelWidth),
                pixelHeight: ServiceRuntimePrivate.Validation.copyFloat((value as any).pixelHeight),
            })
        }
        export const copyFileInfo = (value: unknown): ChooseFile.FileInfo => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FileInfo object but received: ${value}`)
            }

            return Object.freeze({
                filename: ServiceRuntimePrivate.Validation.copyString((value as any).filename),
                originalFileName: ServiceRuntimePrivate.Validation.copyString((value as any).originalFileName),
            })
        }
        export const copyFontInfoProperties = (value: unknown): ChooseFile.FontInfoProperties => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FontInfoProperties object but received: ${value}`)
            }

            return Object.freeze({
                fontFamily: ServiceRuntimePrivate.Validation.copyString((value as any).fontFamily),
                fontSubFamily: ServiceRuntimePrivate.Validation.copyString((value as any).fontSubFamily),
                postscriptName: ServiceRuntimePrivate.Validation.copyString((value as any).postscriptName),
                preferredFamily: ServiceRuntimePrivate.Validation.copyString((value as any).preferredFamily),
                preferredSubFamily: ServiceRuntimePrivate.Validation.copyString((value as any).preferredSubFamily),
            })
        }
        export const copyFontInfo = (value: unknown): ChooseFile.FontInfo => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FontInfo object but received: ${value}`)
            }

            return Object.freeze({
                filename: ServiceRuntimePrivate.Validation.copyString((value as any).filename),
                originalFontName: ServiceRuntimePrivate.Validation.copyString((value as any).originalFontName),
                properties: ServiceRuntime.ChooseFileValidation.copyFontInfoProperties((value as any).properties),
            })
        }
        export const copyFontData = (value: unknown): ChooseFile.FontData => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FontData object but received: ${value}`)
            }

            return Object.freeze({
                content: ServiceRuntimePrivate.Validation.copyString((value as any).content),
                originalFilename: ServiceRuntimePrivate.Validation.copyString((value as any).originalFilename),
            })
        }
        export const copyAssetKey = (value: unknown): ChooseFile.AssetKey => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected AssetKey object but received: ${value}`)
            }

            return Object.freeze({
                key: ServiceRuntimePrivate.Validation.copyString((value as any).key),
            })
        }
        export const copyImageInfo = (value: unknown): ChooseFile.ImageInfo => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ImageInfo object but received: ${value}`)
            }

            return Object.freeze({
                filename: ServiceRuntimePrivate.Validation.copyString((value as any).filename),
                originalImageName: ServiceRuntimePrivate.Validation.copyString((value as any).originalImageName),
                imageSize: ServiceRuntime.ChooseFileValidation.copyImageSize((value as any).imageSize),
            })
        }
        export const copyFontInfos = (value: unknown): ChooseFile.FontInfos => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FontInfos object but received: ${value}`)
            }

            return Object.freeze({
                fonts: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.ChooseFileValidation.copyFontInfo
                )((value as any).fonts),
            })
        }
        export const copyFonts = (value: unknown): ChooseFile.Fonts => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Fonts object but received: ${value}`)
            }

            return Object.freeze({
                fonts: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.ChooseFileValidation.copyFontData
                )((value as any).fonts),
            })
        }
        export const copyImageInfos = (value: unknown): ChooseFile.ImageInfos => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ImageInfos object but received: ${value}`)
            }

            return Object.freeze({
                images: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.ChooseFileValidation.copyImageInfo
                )((value as any).images),
            })
        }
        export const copyImages = (value: unknown): ChooseFile.Images => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Images object but received: ${value}`)
            }

            return Object.freeze({
                images: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.ChooseFileValidation.copyImageData
                )((value as any).images),
            })
        }
        export const copyImageData = (value: unknown): ChooseFile.ImageData => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ImageData object but received: ${value}`)
            }

            return Object.freeze({
                content: ServiceRuntimePrivate.Validation.copyString((value as any).content),
                originalFilename: ServiceRuntimePrivate.Validation.copyString((value as any).originalFilename),
            })
        }
        export const copyImageURL = (value: unknown): ChooseFile.ImageURL => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ImageURL object but received: ${value}`)
            }

            return Object.freeze({
                url: ServiceRuntimePrivate.Validation.copyString((value as any).url),
                originalFilename: ServiceRuntimePrivate.Validation.copyString((value as any).originalFilename),
                width: ServiceRuntimePrivate.Validation.copyFloat((value as any).width),
                height: ServiceRuntimePrivate.Validation.copyFloat((value as any).height),
            })
        }
        export const copyMetadataOptions = (value: unknown): ChooseFile.MetadataOptions => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected MetadataOptions object but received: ${value}`)
            }

            return Object.freeze({
                filename: ServiceRuntimePrivate.Validation.copyString((value as any).filename),
            })
        }
    }

    export namespace ClipboardValidation {
        export const copyWrite = (value: unknown): Clipboard.Write => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Write object but received: ${value}`)
            }

            return Object.freeze({
                items: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.ClipboardValidation.copyDataItem
                )((value as any).items),
            })
        }
        export const copyReadOptions = (value: unknown): Clipboard.ReadOptions => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ReadOptions object but received: ${value}`)
            }

            return Object.freeze({
                types: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.ClipboardValidation.copyDataItemType
                )((value as any).types),
            })
        }
        export const copyRead = (value: unknown): Clipboard.Read => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Read object but received: ${value}`)
            }

            return Object.freeze({
                items: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.ClipboardValidation.copyDataItem
                )((value as any).items),
            })
        }

        const DataItem__rawValueToObjectValidatorMap: { [key: string]: (_: unknown) => any } = {
            "text/plain": (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "text/plain",
                    string: ServiceRuntimePrivate.Validation.copyString((value as any).string),
                })
            },
            "text/css": (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "text/css",
                    string: ServiceRuntimePrivate.Validation.copyString((value as any).string),
                })
            },
            "image/svg+xml": (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "image/svg+xml",
                    string: ServiceRuntimePrivate.Validation.copyString((value as any).string),
                })
            },
            "application/x-framer-style-v2": (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "application/x-framer-style-v2",
                    data: ServiceRuntimePrivate.Validation.copyUnsafeJSON((value as any).data),
                })
            },
            "application/x-framer-layers": (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "application/x-framer-layers",
                    data: ServiceRuntimePrivate.Validation.copyUnsafeJSON((value as any).data),
                })
            },
            "application/x-framer-sketch": (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "application/x-framer-sketch",
                    data: ServiceRuntimePrivate.Validation.copyUnsafeJSON((value as any).data),
                })
            },
            "application/x-framer-animation-transition": (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "application/x-framer-animation-transition",
                    data: ServiceRuntimePrivate.Validation.copyUnsafeJSON((value as any).data),
                })
            },
            "application/x-framer-layout-grid": (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "application/x-framer-layout-grid",
                    data: ServiceRuntimePrivate.Validation.copyUnsafeJSON((value as any).data),
                })
            },
        }

        export const copyDataItemType = (value: unknown): Clipboard.DataItem["type"] => {
            if (typeof value !== "string") {
                throw new Error(`Expected string for type but received: ${value}`)
            }
            if (!DataItem__rawValueToObjectValidatorMap[value]) {
                throw new Error(`Expected valid value for type but received: ${value}`)
            }
            return value as any
        }

        export const copyDataItem = (value: unknown): Clipboard.DataItem => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected DataItem object but received: ${value}`)
            }

            const rawValue = (value as any)["type"]
            if (typeof rawValue !== "string") {
                throw new Error(`Expected string for type but received: ${value}`)
            }
            const validator = DataItem__rawValueToObjectValidatorMap[rawValue]
            if (!validator) {
                throw new Error(`Expected valid value for type but received: ${value}`)
            }

            return validator(value) as Clipboard.DataItem
        }
    }

    export namespace ControlsVisibilityValidation {
        export const copyHiddenStateForComponentControlsRequest = (
            value: unknown
        ): ControlsVisibility.HiddenStateForComponentControlsRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected HiddenStateForComponentControlsRequest object but received: ${value}`)
            }

            return Object.freeze({
                args: ServiceRuntimePrivate.Validation.copyUnsafeJSON((value as any).args),
            })
        }
        export const copyHiddenStateForComponentControlsResponse = (
            value: unknown
        ): ControlsVisibility.HiddenStateForComponentControlsResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected HiddenStateForComponentControlsResponse object but received: ${value}`)
            }

            return Object.freeze({
                hiddenControlsByNodeId: ServiceRuntimePrivate.Validation.copyUnsafeJSON(
                    (value as any).hiddenControlsByNodeId
                ),
            })
        }
        export const copyHiddenStateForActionControlsRequest = (
            value: unknown
        ): ControlsVisibility.HiddenStateForActionControlsRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected HiddenStateForActionControlsRequest object but received: ${value}`)
            }

            return Object.freeze({
                actionIdentifier: ServiceRuntimePrivate.Validation.copyString((value as any).actionIdentifier),
                controlNames: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).controlNames),
                actionProps: ServiceRuntimePrivate.Validation.copyUnsafeJSON((value as any).actionProps),
            })
        }
        export const copyHiddenStateForActionControlsResponse = (
            value: unknown
        ): ControlsVisibility.HiddenStateForActionControlsResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected HiddenStateForActionControlsResponse object but received: ${value}`)
            }

            return Object.freeze({
                hiddenState: ServiceRuntimePrivate.Validation.copyUnsafeJSON((value as any).hiddenState),
            })
        }
    }

    export namespace DependenciesValidation {
        export const copyAddDependencyArguments = (value: unknown): Dependencies.AddDependencyArguments => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected AddDependencyArguments object but received: ${value}`)
            }

            return Object.freeze({
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
                version: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).version),
            })
        }
        export const copyRemoveDependencyArguments = (value: unknown): Dependencies.RemoveDependencyArguments => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected RemoveDependencyArguments object but received: ${value}`)
            }

            return Object.freeze({
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
            })
        }
        export const copyGetDependenciesArguments = (value: unknown): Dependencies.GetDependenciesArguments => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected GetDependenciesArguments object but received: ${value}`)
            }

            return Object.freeze({
                refreshLatestInfo: ServiceRuntimePrivate.Validation.copyBoolean((value as any).refreshLatestInfo),
            })
        }
        export const copyTypings = (value: unknown): Dependencies.Typings => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Typings object but received: ${value}`)
            }

            return Object.freeze({
                module: ServiceRuntimePrivate.Validation.copyString((value as any).module),
                content: ServiceRuntimePrivate.Validation.copyString((value as any).content),
                typesPackage: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).typesPackage),
            })
        }
        export const copyDependency = (value: unknown): Dependencies.Dependency => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Dependency object but received: ${value}`)
            }

            return Object.freeze({
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
                current: ServiceRuntimePrivate.Validation.copyString((value as any).current),
                wanted: ServiceRuntimePrivate.Validation.copyString((value as any).wanted),
                latest: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).latest),
            })
        }
        export const copyGetDependenciesResult = (value: unknown): Dependencies.GetDependenciesResult => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected GetDependenciesResult object but received: ${value}`)
            }

            return Object.freeze({
                dependencies: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.DependenciesValidation.copyDependency
                )((value as any).dependencies),
            })
        }
        export const copyGetTypingsResult = (value: unknown): Dependencies.GetTypingsResult => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected GetTypingsResult object but received: ${value}`)
            }

            return Object.freeze({
                typings: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.DependenciesValidation.copyTypings
                )((value as any).typings),
            })
        }
    }

    export namespace EditorAppValidation {
        export const copyLoadProjectRequest = (value: unknown): EditorApp.LoadProjectRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected LoadProjectRequest object but received: ${value}`)
            }

            return Object.freeze({
                document: ServiceRuntimePrivate.Validation.copyString((value as any).document),
                scripts: ServiceRuntimePrivate.Validation.copyMap.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).scripts),
            })
        }
        export const copySizeObject = (value: unknown): EditorApp.SizeObject => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected SizeObject object but received: ${value}`)
            }

            return Object.freeze({
                width: ServiceRuntimePrivate.Validation.copyInteger((value as any).width),
                height: ServiceRuntimePrivate.Validation.copyInteger((value as any).height),
            })
        }
        export const copyPrepareNodeSnapshotRequest = (value: unknown): EditorApp.PrepareNodeSnapshotRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected PrepareNodeSnapshotRequest object but received: ${value}`)
            }

            return Object.freeze({
                nodeId: ServiceRuntimePrivate.Validation.copyString((value as any).nodeId),
                zoom: ServiceRuntimePrivate.Validation.copyFloat((value as any).zoom),
                padding: ServiceRuntimePrivate.Validation.copyInteger((value as any).padding),
                clipContentOutsideNodeRect: ServiceRuntimePrivate.Validation.copyBoolean(
                    (value as any).clipContentOutsideNodeRect
                ),
            })
        }
        export const copyPrepareScopeSnapshotRequest = (value: unknown): EditorApp.PrepareScopeSnapshotRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected PrepareScopeSnapshotRequest object but received: ${value}`)
            }

            return Object.freeze({
                scopeId: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).scopeId),
                size: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntime.EditorAppValidation.copySizeObject
                )((value as any).size),
            })
        }
    }

    export namespace ElectronThemeListenerValidation {
        export const copyTheme = (value: unknown): ElectronThemeListener.Theme => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Theme object but received: ${value}`)
            }

            return Object.freeze({
                isDarkMode: ServiceRuntimePrivate.Validation.copyBoolean((value as any).isDarkMode),
            })
        }
    }

    export namespace ElectronAppValidation {
        export const copyProjectState = (value: unknown): ElectronApp.ProjectState => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ProjectState object but received: ${value}`)
            }

            return Object.freeze({
                id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
                url: ServiceRuntimePrivate.Validation.copyString((value as any).url),
                title: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).title),
                icon: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).icon),
            })
        }
        export const copyWindowState = (value: unknown): ElectronApp.WindowState => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected WindowState object but received: ${value}`)
            }

            return Object.freeze({
                id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
                isMaximized: ServiceRuntimePrivate.Validation.copyBoolean((value as any).isMaximized),
                isFullScreen: ServiceRuntimePrivate.Validation.copyBoolean((value as any).isFullScreen),
                selectedProject: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).selectedProject),
                projects: ServiceRuntimePrivate.Validation.copyMap.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString,
                    ServiceRuntime.ElectronAppValidation.copyProjectState
                )((value as any).projects),
                tabs: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).tabs),
            })
        }
        export const copyAppState = (value: unknown): ElectronApp.AppState => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected AppState object but received: ${value}`)
            }

            return Object.freeze({
                isDarkMode: ServiceRuntimePrivate.Validation.copyBoolean((value as any).isDarkMode),
                isAuthenticated: ServiceRuntimePrivate.Validation.copyBoolean((value as any).isAuthenticated),
                selectedWindow: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).selectedWindow),
                windows: ServiceRuntimePrivate.Validation.copyMap.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString,
                    ServiceRuntime.ElectronAppValidation.copyWindowState
                )((value as any).windows),
            })
        }
        export const copyMoveProjectPayload = (value: unknown): ElectronApp.MoveProjectPayload => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected MoveProjectPayload object but received: ${value}`)
            }

            return Object.freeze({
                id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
                toIndex: ServiceRuntimePrivate.Validation.copyInteger((value as any).toIndex),
            })
        }
        export const copySelectProjectPayload = (value: unknown): ElectronApp.SelectProjectPayload => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected SelectProjectPayload object but received: ${value}`)
            }

            return Object.freeze({
                id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
            })
        }
        export const copyCloseProjectPayload = (value: unknown): ElectronApp.CloseProjectPayload => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected CloseProjectPayload object but received: ${value}`)
            }

            return Object.freeze({
                id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
            })
        }
    }

    export namespace ElectronColorSamplerValidation {
        export const copyBitmap = (value: unknown): ElectronColorSampler.Bitmap => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Bitmap object but received: ${value}`)
            }

            return Object.freeze({
                data: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyInteger
                )((value as any).data),
            })
        }
        export const copySelectPayload = (value: unknown): ElectronColorSampler.SelectPayload => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected SelectPayload object but received: ${value}`)
            }

            return Object.freeze({
                color: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).color),
            })
        }
    }

    export namespace ElectronWebRenderingValidation {
        export const copyWindowOptions = (value: unknown): ElectronWebRendering.WindowOptions => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected WindowOptions object but received: ${value}`)
            }

            return Object.freeze({
                url: ServiceRuntimePrivate.Validation.copyString((value as any).url),
                width: ServiceRuntimePrivate.Validation.copyInteger((value as any).width),
                height: ServiceRuntimePrivate.Validation.copyInteger((value as any).height),
                offscreen: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).offscreen),
            })
        }
        export const copyWindowReference = (value: unknown): ElectronWebRendering.WindowReference => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected WindowReference object but received: ${value}`)
            }

            return Object.freeze({
                id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
            })
        }
        export const copyResizeOptions = (value: unknown): ElectronWebRendering.ResizeOptions => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ResizeOptions object but received: ${value}`)
            }

            return Object.freeze({
                ref: ServiceRuntime.ElectronWebRenderingValidation.copyWindowReference((value as any).ref),
                width: ServiceRuntimePrivate.Validation.copyInteger((value as any).width),
                height: ServiceRuntimePrivate.Validation.copyInteger((value as any).height),
                preferredPixelRatio: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyFloat
                )((value as any).preferredPixelRatio),
            })
        }

        const SnapshotOptionsType__rawValues = new Set<unknown>(["image/png", "image/jpeg"])

        export const copySnapshotOptionsType = (value: unknown): ElectronWebRendering.SnapshotOptionsType => {
            if (!SnapshotOptionsType__rawValues.has(value)) {
                throw new Error(`Expected valid value for enum but received: ${value}`)
            }
            return value as ElectronWebRendering.SnapshotOptionsType
        }

        export const copySnapshotOptions = (value: unknown): ElectronWebRendering.SnapshotOptions => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected SnapshotOptions object but received: ${value}`)
            }

            return Object.freeze({
                ref: ServiceRuntime.ElectronWebRenderingValidation.copyWindowReference((value as any).ref),
                rect: ServiceRuntime.Validation.copyRect((value as any).rect),
                type: ServiceRuntime.ElectronWebRenderingValidation.copySnapshotOptionsType((value as any).type),
                writeToClipboard: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).writeToClipboard),
            })
        }
        export const copySnapshot = (value: unknown): ElectronWebRendering.Snapshot => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Snapshot object but received: ${value}`)
            }

            return Object.freeze({
                url: ServiceRuntimePrivate.Validation.copyString((value as any).url),
            })
        }
    }

    export namespace FeedbackServiceValidation {
        export const copyGetThreadsRequest = (value: unknown): FeedbackService.GetThreadsRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected GetThreadsRequest object but received: ${value}`)
            }

            return Object.freeze({
                archived: ServiceRuntimePrivate.Validation.copyBoolean((value as any).archived),
            })
        }
        export const copyGetThreadsResponse = (value: unknown): FeedbackService.GetThreadsResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected GetThreadsResponse object but received: ${value}`)
            }

            return Object.freeze({
                threads: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.FeedbackServiceValidation.copyThread
                )((value as any).threads),
            })
        }
        export const copyCreateThreadResponse = (value: unknown): FeedbackService.CreateThreadResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected CreateThreadResponse object but received: ${value}`)
            }

            return Object.freeze({
                thread: ServiceRuntime.FeedbackServiceValidation.copyThread((value as any).thread),
            })
        }
        export const copyCreateThreadRequest = (value: unknown): FeedbackService.CreateThreadRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected CreateThreadRequest object but received: ${value}`)
            }

            return Object.freeze({
                anchor: ServiceRuntime.FeedbackServiceValidation.copyAnchor((value as any).anchor),
                text: ServiceRuntimePrivate.Validation.copyString((value as any).text),
            })
        }
        export const copyUpdateThreadRequest = (value: unknown): FeedbackService.UpdateThreadRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected UpdateThreadRequest object but received: ${value}`)
            }

            return Object.freeze({
                id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
                archived: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).archived),
                anchor: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntime.FeedbackServiceValidation.copyAnchor
                )((value as any).anchor),
            })
        }
        export const copyUpdateThreadResponse = (value: unknown): FeedbackService.UpdateThreadResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected UpdateThreadResponse object but received: ${value}`)
            }

            return Object.freeze({
                thread: ServiceRuntime.FeedbackServiceValidation.copyThread((value as any).thread),
            })
        }
        export const copyCreateCommentRequest = (value: unknown): FeedbackService.CreateCommentRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected CreateCommentRequest object but received: ${value}`)
            }

            return Object.freeze({
                threadId: ServiceRuntimePrivate.Validation.copyString((value as any).threadId),
                text: ServiceRuntimePrivate.Validation.copyString((value as any).text),
            })
        }
        export const copyCreateCommentResponse = (value: unknown): FeedbackService.CreateCommentResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected CreateCommentResponse object but received: ${value}`)
            }

            return Object.freeze({
                comment: ServiceRuntime.FeedbackServiceValidation.copyComment((value as any).comment),
            })
        }
        export const copyUpdateCommentRequest = (value: unknown): FeedbackService.UpdateCommentRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected UpdateCommentRequest object but received: ${value}`)
            }

            return Object.freeze({
                id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
                threadId: ServiceRuntimePrivate.Validation.copyString((value as any).threadId),
                text: ServiceRuntimePrivate.Validation.copyString((value as any).text),
            })
        }
        export const copyUpdateCommentResponse = (value: unknown): FeedbackService.UpdateCommentResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected UpdateCommentResponse object but received: ${value}`)
            }

            return Object.freeze({
                comment: ServiceRuntime.FeedbackServiceValidation.copyComment((value as any).comment),
            })
        }
        export const copyDeleteCommentRequest = (value: unknown): FeedbackService.DeleteCommentRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected DeleteCommentRequest object but received: ${value}`)
            }

            return Object.freeze({
                id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
                threadId: ServiceRuntimePrivate.Validation.copyString((value as any).threadId),
            })
        }
        export const copyDeleteCommentResponse = (value: unknown): FeedbackService.DeleteCommentResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected DeleteCommentResponse object but received: ${value}`)
            }

            return Object.freeze({
                comment: ServiceRuntime.FeedbackServiceValidation.copyComment((value as any).comment),
            })
        }
        export const copyMarkAsSeenRequest = (value: unknown): FeedbackService.MarkAsSeenRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected MarkAsSeenRequest object but received: ${value}`)
            }

            return Object.freeze({
                threadId: ServiceRuntimePrivate.Validation.copyString((value as any).threadId),
                lastSeenCommentId: ServiceRuntimePrivate.Validation.copyString((value as any).lastSeenCommentId),
            })
        }
        export const copyMarkAsSeenResponse = (value: unknown): FeedbackService.MarkAsSeenResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected MarkAsSeenResponse object but received: ${value}`)
            }

            return Object.freeze({
                thread: ServiceRuntime.FeedbackServiceValidation.copyThread((value as any).thread),
            })
        }
        export const copyThread = (value: unknown): FeedbackService.Thread => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Thread object but received: ${value}`)
            }

            return Object.freeze({
                archived: ServiceRuntimePrivate.Validation.copyBoolean((value as any).archived),
                comments: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.FeedbackServiceValidation.copyComment
                )((value as any).comments),
                id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
                anchor: ServiceRuntime.FeedbackServiceValidation.copyAnchor((value as any).anchor),
                seenUntil: ServiceRuntimePrivate.Validation.copyNullable.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).seenUntil),
                updatedAt: ServiceRuntimePrivate.Validation.copyNullable.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).updatedAt),
            })
        }
        export const copyComment = (value: unknown): FeedbackService.Comment => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Comment object but received: ${value}`)
            }

            return Object.freeze({
                author: ServiceRuntime.Validation.copyUser((value as any).author),
                createdAt: ServiceRuntimePrivate.Validation.copyString((value as any).createdAt),
                deletedBy: ServiceRuntimePrivate.Validation.copyNullable.bind(
                    undefined,
                    ServiceRuntime.Validation.copyUser
                )((value as any).deletedBy),
                id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
                text: ServiceRuntimePrivate.Validation.copyString((value as any).text),
                updatedAt: ServiceRuntimePrivate.Validation.copyNullable.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).updatedAt),
            })
        }

        const Anchor__rawValueToObjectValidatorMap: { [key: string]: (_: unknown) => any } = {
            canvas: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "canvas",
                    pageId: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )((value as any).pageId),
                    x: ServiceRuntimePrivate.Validation.copyInteger((value as any).x),
                    y: ServiceRuntimePrivate.Validation.copyInteger((value as any).y),
                })
            },
            node: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "node",
                    pageId: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )((value as any).pageId),
                    x: ServiceRuntimePrivate.Validation.copyInteger((value as any).x),
                    y: ServiceRuntimePrivate.Validation.copyInteger((value as any).y),
                    nodeId: ServiceRuntimePrivate.Validation.copyString((value as any).nodeId),
                    insetX: ServiceRuntimePrivate.Validation.copyInteger((value as any).insetX),
                    insetY: ServiceRuntimePrivate.Validation.copyInteger((value as any).insetY),
                    insetCorner: ServiceRuntimePrivate.Validation.copyString((value as any).insetCorner),
                })
            },
        }

        export const copyAnchorType = (value: unknown): FeedbackService.Anchor["type"] => {
            if (typeof value !== "string") {
                throw new Error(`Expected string for type but received: ${value}`)
            }
            if (!Anchor__rawValueToObjectValidatorMap[value]) {
                throw new Error(`Expected valid value for type but received: ${value}`)
            }
            return value as any
        }

        export const copyAnchor = (value: unknown): FeedbackService.Anchor => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Anchor object but received: ${value}`)
            }

            const rawValue = (value as any)["type"]
            if (typeof rawValue !== "string") {
                throw new Error(`Expected string for type but received: ${value}`)
            }
            const validator = Anchor__rawValueToObjectValidatorMap[rawValue]
            if (!validator) {
                throw new Error(`Expected valid value for type but received: ${value}`)
            }

            return validator(value) as FeedbackService.Anchor
        }
    }

    export namespace FeedbackDocumentValidation {
        export const copyOpenThreadRequest = (value: unknown): FeedbackDocument.OpenThreadRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected OpenThreadRequest object but received: ${value}`)
            }

            return Object.freeze({
                threadId: ServiceRuntimePrivate.Validation.copyString((value as any).threadId),
                commentId: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).commentId),
            })
        }
        export const copyFeedbackStateEvent = (value: unknown): FeedbackDocument.FeedbackStateEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FeedbackStateEvent object but received: ${value}`)
            }

            return Object.freeze({
                isActive: ServiceRuntimePrivate.Validation.copyBoolean((value as any).isActive),
            })
        }
    }

    export namespace FigmaImporterValidation {
        export const copyImportFigmaFileRequest = (value: unknown): FigmaImporter.ImportFigmaFileRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ImportFigmaFileRequest object but received: ${value}`)
            }

            return Object.freeze({
                figmaFileId: ServiceRuntimePrivate.Validation.copyString((value as any).figmaFileId),
                nodeId: ServiceRuntimePrivate.Validation.copyString((value as any).nodeId),
            })
        }
        export const copyImportFigmaFileResponse = (value: unknown): FigmaImporter.ImportFigmaFileResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ImportFigmaFileResponse object but received: ${value}`)
            }

            return Object.freeze({
                rootNodes: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyUnsafeJSON
                )((value as any).rootNodes),
            })
        }
        export const copyImportFigmaFileResponseWithProgress = (
            value: unknown
        ): FigmaImporter.ImportFigmaFileResponseWithProgress => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ImportFigmaFileResponseWithProgress object but received: ${value}`)
            }

            return Object.freeze({
                rootNodes: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyArray.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyUnsafeJSON
                    )
                )((value as any).rootNodes),
                progress: ServiceRuntimePrivate.Validation.copyInteger((value as any).progress),
                step: ServiceRuntimePrivate.Validation.copyString((value as any).step),
            })
        }
        export const copyFetchFigmaFileRequest = (value: unknown): FigmaImporter.FetchFigmaFileRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FetchFigmaFileRequest object but received: ${value}`)
            }

            return Object.freeze({
                figmaFileId: ServiceRuntimePrivate.Validation.copyString((value as any).figmaFileId),
            })
        }

        const FetchFigmaFileResponseStatus__rawValues = new Set<unknown>(["ok", "unauthenticated", "error"])

        export const copyFetchFigmaFileResponseStatus = (
            value: unknown
        ): FigmaImporter.FetchFigmaFileResponseStatus => {
            if (!FetchFigmaFileResponseStatus__rawValues.has(value)) {
                throw new Error(`Expected valid value for enum but received: ${value}`)
            }
            return value as FigmaImporter.FetchFigmaFileResponseStatus
        }

        export const copyFetchFigmaFileResponse = (value: unknown): FigmaImporter.FetchFigmaFileResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FetchFigmaFileResponse object but received: ${value}`)
            }

            return Object.freeze({
                status: ServiceRuntime.FigmaImporterValidation.copyFetchFigmaFileResponseStatus((value as any).status),
                figmaFile: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyUnsafeJSON
                )((value as any).figmaFile),
            })
        }
        export const copyIsAuthenticatedWithFigmaResponse = (
            value: unknown
        ): FigmaImporter.IsAuthenticatedWithFigmaResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected IsAuthenticatedWithFigmaResponse object but received: ${value}`)
            }

            return Object.freeze({
                isAuthenticated: ServiceRuntimePrivate.Validation.copyBoolean((value as any).isAuthenticated),
            })
        }
        export const copyAuthenticateWithFigmaResponse = (
            value: unknown
        ): FigmaImporter.AuthenticateWithFigmaResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected AuthenticateWithFigmaResponse object but received: ${value}`)
            }

            return Object.freeze({
                isAuthenticated: ServiceRuntimePrivate.Validation.copyBoolean((value as any).isAuthenticated),
            })
        }
    }

    export namespace FilesValidation {
        export const copyFilePath = (value: unknown): Files.FilePath => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FilePath object but received: ${value}`)
            }

            return Object.freeze({
                path: ServiceRuntimePrivate.Validation.copyString((value as any).path),
            })
        }
        export const copyFile = (value: unknown): Files.File => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected File object but received: ${value}`)
            }

            return Object.freeze({
                path: ServiceRuntimePrivate.Validation.copyString((value as any).path),
                content: ServiceRuntimePrivate.Validation.copyString((value as any).content),
            })
        }
        export const copyFileResult = (value: unknown): Files.FileResult => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FileResult object but received: ${value}`)
            }

            return Object.freeze({
                file: ServiceRuntime.FilesValidation.copyFileOrDirectoryMetadata((value as any).file),
            })
        }
        export const copyFileListResult = (value: unknown): Files.FileListResult => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FileListResult object but received: ${value}`)
            }

            return Object.freeze({
                fileList: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.FilesValidation.copyFileOrDirectoryMetadata
                )((value as any).fileList),
            })
        }

        const FileOrDirectoryMetadata__rawValueToObjectValidatorMap: { [key: string]: (_: unknown) => any } = {
            file: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "file",
                    path: ServiceRuntimePrivate.Validation.copyString((value as any).path),
                    content: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )((value as any).content),
                })
            },
            directory: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "directory",
                    path: ServiceRuntimePrivate.Validation.copyString((value as any).path),
                })
            },
        }

        export const copyFileOrDirectoryMetadataType = (value: unknown): Files.FileOrDirectoryMetadata["type"] => {
            if (typeof value !== "string") {
                throw new Error(`Expected string for type but received: ${value}`)
            }
            if (!FileOrDirectoryMetadata__rawValueToObjectValidatorMap[value]) {
                throw new Error(`Expected valid value for type but received: ${value}`)
            }
            return value as any
        }

        export const copyFileOrDirectoryMetadata = (value: unknown): Files.FileOrDirectoryMetadata => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FileOrDirectoryMetadata object but received: ${value}`)
            }

            const rawValue = (value as any)["type"]
            if (typeof rawValue !== "string") {
                throw new Error(`Expected string for type but received: ${value}`)
            }
            const validator = FileOrDirectoryMetadata__rawValueToObjectValidatorMap[rawValue]
            if (!validator) {
                throw new Error(`Expected valid value for type but received: ${value}`)
            }

            return validator(value) as Files.FileOrDirectoryMetadata
        }

        export const copyBuildOutputFile = (value: unknown): Files.BuildOutputFile => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected BuildOutputFile object but received: ${value}`)
            }

            return Object.freeze({
                filename: ServiceRuntimePrivate.Validation.copyString((value as any).filename),
                contents: ServiceRuntimePrivate.Validation.copyString((value as any).contents),
            })
        }
        export const copyBuildOutput = (value: unknown): Files.BuildOutput => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected BuildOutput object but received: ${value}`)
            }

            return Object.freeze({
                files: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.FilesValidation.copyBuildOutputFile
                )((value as any).files),
            })
        }
    }

    export namespace FileUpdatesValidation {
        const UpdatedFileType__rawValues = new Set<unknown>(["added", "changed", "deleted"])

        export const copyUpdatedFileType = (value: unknown): FileUpdates.UpdatedFileType => {
            if (!UpdatedFileType__rawValues.has(value)) {
                throw new Error(`Expected valid value for enum but received: ${value}`)
            }
            return value as FileUpdates.UpdatedFileType
        }

        export const copyUpdatedFile = (value: unknown): FileUpdates.UpdatedFile => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected UpdatedFile object but received: ${value}`)
            }

            return Object.freeze({
                path: ServiceRuntimePrivate.Validation.copyString((value as any).path),
                type: ServiceRuntime.FileUpdatesValidation.copyUpdatedFileType((value as any).type),
            })
        }
        export const copyFileUpdateEvent = (value: unknown): FileUpdates.FileUpdateEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FileUpdateEvent object but received: ${value}`)
            }

            return Object.freeze({
                files: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.FileUpdatesValidation.copyUpdatedFile
                )((value as any).files),
            })
        }
    }

    export namespace ModalsValidation {
        const SetActiveModalRequestModal__rawValues = new Set<unknown>(["ImportSketch", "ImportFigma"])

        export const copySetActiveModalRequestModal = (value: unknown): Modals.SetActiveModalRequestModal => {
            if (!SetActiveModalRequestModal__rawValues.has(value)) {
                throw new Error(`Expected valid value for enum but received: ${value}`)
            }
            return value as Modals.SetActiveModalRequestModal
        }

        export const copySetActiveModalRequest = (value: unknown): Modals.SetActiveModalRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected SetActiveModalRequest object but received: ${value}`)
            }

            return Object.freeze({
                modal: ServiceRuntime.ModalsValidation.copySetActiveModalRequestModal((value as any).modal),
                source: ServiceRuntimePrivate.Validation.copyString((value as any).source),
            })
        }
    }

    export namespace ModulesAPIValidation {
        const ModuleEvent__rawValueToObjectValidatorMap: { [key: string]: (_: unknown) => any } = {
            delete: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "delete",
                    id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
                })
            },
            save: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "save",
                    module: ServiceRuntime.ModulesAPIValidation.copyModuleWithSave((value as any).module),
                })
            },
        }

        export const copyModuleEventType = (value: unknown): ModulesAPI.ModuleEvent["type"] => {
            if (typeof value !== "string") {
                throw new Error(`Expected string for type but received: ${value}`)
            }
            if (!ModuleEvent__rawValueToObjectValidatorMap[value]) {
                throw new Error(`Expected valid value for type but received: ${value}`)
            }
            return value as any
        }

        export const copyModuleEvent = (value: unknown): ModulesAPI.ModuleEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ModuleEvent object but received: ${value}`)
            }

            const rawValue = (value as any)["type"]
            if (typeof rawValue !== "string") {
                throw new Error(`Expected string for type but received: ${value}`)
            }
            const validator = ModuleEvent__rawValueToObjectValidatorMap[rawValue]
            if (!validator) {
                throw new Error(`Expected valid value for type but received: ${value}`)
            }

            return validator(value) as ModulesAPI.ModuleEvent
        }

        export const copyModuleEventList = (value: unknown): ModulesAPI.ModuleEventList => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ModuleEventList object but received: ${value}`)
            }

            return Object.freeze({
                events: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.ModulesAPIValidation.copyModuleEvent
                )((value as any).events),
            })
        }
        export const copyPublishInfo = (value: unknown): ModulesAPI.PublishInfo => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected PublishInfo object but received: ${value}`)
            }

            return Object.freeze({
                namespaceId: ServiceRuntimePrivate.Validation.copyString((value as any).namespaceId),
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
                version: ServiceRuntimePrivate.Validation.copyString((value as any).version),
                importURL: ServiceRuntimePrivate.Validation.copyString((value as any).importURL),
            })
        }
        export const copyModuleWithSave = (value: unknown): ModulesAPI.ModuleWithSave => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ModuleWithSave object but received: ${value}`)
            }

            return Object.freeze({
                id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
                localId: ServiceRuntimePrivate.Validation.copyString((value as any).localId),
                ownerId: ServiceRuntimePrivate.Validation.copyString((value as any).ownerId),
                ownerType: ServiceRuntimePrivate.Validation.copyString((value as any).ownerType),
                createdAt: ServiceRuntimePrivate.Validation.copyString((value as any).createdAt),
                updatedAt: ServiceRuntimePrivate.Validation.copyString((value as any).updatedAt),
                type: ServiceRuntimePrivate.Validation.copyString((value as any).type),
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
                deletedAt: ServiceRuntimePrivate.Validation.copyNullable.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).deletedAt),
                title: ServiceRuntimePrivate.Validation.copyString((value as any).title),
                description: ServiceRuntimePrivate.Validation.copyNullable.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).description),
                saveId: ServiceRuntimePrivate.Validation.copyString((value as any).saveId),
                userId: ServiceRuntimePrivate.Validation.copyString((value as any).userId),
                savedAt: ServiceRuntimePrivate.Validation.copyString((value as any).savedAt),
                files: ServiceRuntimePrivate.Validation.copyMap.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).files),
                metadata: ServiceRuntimePrivate.Validation.copyUnsafeJSON((value as any).metadata),
                baseURL: ServiceRuntimePrivate.Validation.copyString((value as any).baseURL),
                importURL: ServiceRuntimePrivate.Validation.copyString((value as any).importURL),
                lastPublish: ServiceRuntimePrivate.Validation.copyNullable.bind(
                    undefined,
                    ServiceRuntime.ModulesAPIValidation.copyPublishInfo
                )((value as any).lastPublish),
            })
        }
        export const copyListResponse = (value: unknown): ModulesAPI.ListResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ListResponse object but received: ${value}`)
            }

            return Object.freeze({
                data: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.ModulesAPIValidation.copyModuleWithSave
                )((value as any).data),
            })
        }
        export const copyFileUpload = (value: unknown): ModulesAPI.FileUpload => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FileUpload object but received: ${value}`)
            }

            return Object.freeze({
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
                type: ServiceRuntimePrivate.Validation.copyString((value as any).type),
                content: ServiceRuntimePrivate.Validation.copyString((value as any).content),
            })
        }
        export const copyCreateRequest = (value: unknown): ModulesAPI.CreateRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected CreateRequest object but received: ${value}`)
            }

            return Object.freeze({
                type: ServiceRuntimePrivate.Validation.copyString((value as any).type),
                saveId: ServiceRuntimePrivate.Validation.copyString((value as any).saveId),
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
                files: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.ModulesAPIValidation.copyFileUpload
                )((value as any).files),
                metadata: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyUnsafeJSON
                )((value as any).metadata),
                title: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).title),
                description: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).description),
                transferEncoding: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).transferEncoding),
                assets: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyArray.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )
                )((value as any).assets),
            })
        }
        export const copyDeleteRequest = (value: unknown): ModulesAPI.DeleteRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected DeleteRequest object but received: ${value}`)
            }

            return Object.freeze({
                moduleId: ServiceRuntimePrivate.Validation.copyString((value as any).moduleId),
            })
        }
        export const copyListRequest = (value: unknown): ModulesAPI.ListRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ListRequest object but received: ${value}`)
            }

            return Object.freeze({
                types: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyArray.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )
                )((value as any).types),
            })
        }
        export const copyListNamespacesResponse = (value: unknown): ModulesAPI.ListNamespacesResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ListNamespacesResponse object but received: ${value}`)
            }

            return Object.freeze({
                data: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.ModulesAPIValidation.copyNamespace
                )((value as any).data),
            })
        }
        export const copyNamespace = (value: unknown): ModulesAPI.Namespace => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Namespace object but received: ${value}`)
            }

            return Object.freeze({
                namespaceId: ServiceRuntimePrivate.Validation.copyString((value as any).namespaceId),
                ownerId: ServiceRuntimePrivate.Validation.copyString((value as any).ownerId),
                ownerType: ServiceRuntimePrivate.Validation.copyString((value as any).ownerType),
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
            })
        }
        export const copyListPublishedModulesRequest = (value: unknown): ModulesAPI.ListPublishedModulesRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ListPublishedModulesRequest object but received: ${value}`)
            }

            return Object.freeze({
                namespace: ServiceRuntimePrivate.Validation.copyString((value as any).namespace),
            })
        }
        export const copyPublishedModuleWithSave = (value: unknown): ModulesAPI.PublishedModuleWithSave => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected PublishedModuleWithSave object but received: ${value}`)
            }

            return Object.freeze({
                namespaceId: ServiceRuntimePrivate.Validation.copyString((value as any).namespaceId),
                namespace: ServiceRuntimePrivate.Validation.copyString((value as any).namespace),
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
                version: ServiceRuntimePrivate.Validation.copyString((value as any).version),
                userId: ServiceRuntimePrivate.Validation.copyString((value as any).userId),
                visibility: ServiceRuntimePrivate.Validation.copyString((value as any).visibility),
                timestamp: ServiceRuntimePrivate.Validation.copyString((value as any).timestamp),
                importURL: ServiceRuntimePrivate.Validation.copyString((value as any).importURL),
                module: ServiceRuntime.ModulesAPIValidation.copyModuleWithSave((value as any).module),
            })
        }
        export const copyListPublishedModulesResponse = (value: unknown): ModulesAPI.ListPublishedModulesResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ListPublishedModulesResponse object but received: ${value}`)
            }

            return Object.freeze({
                data: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.ModulesAPIValidation.copyPublishedModuleWithSave
                )((value as any).data),
            })
        }
        export const copyLookupQuery = (value: unknown): ModulesAPI.LookupQuery => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected LookupQuery object but received: ${value}`)
            }

            return Object.freeze({
                url: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).url),
                moduleId: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).moduleId),
                saveId: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).saveId),
                namespace: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).namespace),
                name: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).name),
                slug: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).slug),
            })
        }
        export const copyLookupRequest = (value: unknown): ModulesAPI.LookupRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected LookupRequest object but received: ${value}`)
            }

            return Object.freeze({
                lookUp: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.ModulesAPIValidation.copyLookupQuery
                )((value as any).lookUp),
            })
        }
        export const copySaveRequest = (value: unknown): ModulesAPI.SaveRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected SaveRequest object but received: ${value}`)
            }

            return Object.freeze({
                moduleId: ServiceRuntimePrivate.Validation.copyString((value as any).moduleId),
                saveId: ServiceRuntimePrivate.Validation.copyString((value as any).saveId),
                type: ServiceRuntimePrivate.Validation.copyString((value as any).type),
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
                files: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.ModulesAPIValidation.copyFileUpload
                )((value as any).files),
                metadata: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyUnsafeJSON
                )((value as any).metadata),
                transferEncoding: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).transferEncoding),
                assets: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyArray.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )
                )((value as any).assets),
            })
        }
        export const copyBatchSave = (value: unknown): ModulesAPI.BatchSave => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected BatchSave object but received: ${value}`)
            }

            return Object.freeze({
                moduleId: ServiceRuntimePrivate.Validation.copyString((value as any).moduleId),
                saveId: ServiceRuntimePrivate.Validation.copyString((value as any).saveId),
                type: ServiceRuntimePrivate.Validation.copyString((value as any).type),
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
                files: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.ModulesAPIValidation.copyFileUpload
                )((value as any).files),
                metadata: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyUnsafeJSON
                )((value as any).metadata),
                title: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyNullable.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )
                )((value as any).title),
                description: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyNullable.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )
                )((value as any).description),
                patchSaveId: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).patchSaveId),
                transferEncoding: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).transferEncoding),
                assets: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyArray.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )
                )((value as any).assets),
            })
        }
        export const copySaveBatchRequest = (value: unknown): ModulesAPI.SaveBatchRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected SaveBatchRequest object but received: ${value}`)
            }

            return Object.freeze({
                batch: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.ModulesAPIValidation.copyBatchSave
                )((value as any).batch),
            })
        }
        export const copyPublish = (value: unknown): ModulesAPI.Publish => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Publish object but received: ${value}`)
            }

            return Object.freeze({
                namespaceId: ServiceRuntimePrivate.Validation.copyString((value as any).namespaceId),
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
                visibility: ServiceRuntimePrivate.Validation.copyString((value as any).visibility),
                version: ServiceRuntimePrivate.Validation.copyString((value as any).version),
                userId: ServiceRuntimePrivate.Validation.copyString((value as any).userId),
                timestamp: ServiceRuntimePrivate.Validation.copyString((value as any).timestamp),
                moduleId: ServiceRuntimePrivate.Validation.copyString((value as any).moduleId),
                saveId: ServiceRuntimePrivate.Validation.copyString((value as any).saveId),
                importURL: ServiceRuntimePrivate.Validation.copyString((value as any).importURL),
            })
        }
        export const copyPublishRequest = (value: unknown): ModulesAPI.PublishRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected PublishRequest object but received: ${value}`)
            }

            return Object.freeze({
                namespace: ServiceRuntimePrivate.Validation.copyString((value as any).namespace),
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
                moduleId: ServiceRuntimePrivate.Validation.copyString((value as any).moduleId),
                saveId: ServiceRuntimePrivate.Validation.copyString((value as any).saveId),
                version: ServiceRuntimePrivate.Validation.copyString((value as any).version),
            })
        }
        export const copyUpdateRequest = (value: unknown): ModulesAPI.UpdateRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected UpdateRequest object but received: ${value}`)
            }

            return Object.freeze({
                moduleId: ServiceRuntimePrivate.Validation.copyString((value as any).moduleId),
                name: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).name),
                title: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyNullable.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )
                )((value as any).title),
                description: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyNullable.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )
                )((value as any).description),
            })
        }
    }

    export namespace ModulesStateValidation {
        export const copyGetModulesResponse = (value: unknown): ModulesState.GetModulesResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected GetModulesResponse object but received: ${value}`)
            }

            return Object.freeze({
                modules: ServiceRuntimePrivate.Validation.copyMap.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString,
                    ServiceRuntime.ModulesStateValidation.copyCompiledModule
                )((value as any).modules),
                initialized: ServiceRuntimePrivate.Validation.copyBoolean((value as any).initialized),
                revision: ServiceRuntimePrivate.Validation.copyInteger((value as any).revision),
            })
        }

        const CompiledModuleKind__rawValues = new Set<unknown>(["unanalyzed", "analyzed"])

        export const copyCompiledModuleKind = (value: unknown): ModulesState.CompiledModuleKind => {
            if (!CompiledModuleKind__rawValues.has(value)) {
                throw new Error(`Expected valid value for enum but received: ${value}`)
            }
            return value as ModulesState.CompiledModuleKind
        }

        export const copyCompiledModule = (value: unknown): ModulesState.CompiledModule => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected CompiledModule object but received: ${value}`)
            }

            return Object.freeze({
                kind: ServiceRuntime.ModulesStateValidation.copyCompiledModuleKind((value as any).kind),
                localId: ServiceRuntimePrivate.Validation.copyString((value as any).localId),
                type: ServiceRuntimePrivate.Validation.copyString((value as any).type),
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
                moduleURL: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).moduleURL),
                moduleContent: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).moduleContent),
                sourceMapContent: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).sourceMapContent),
                relativeImports: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyArray.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )
                )((value as any).relativeImports),
            })
        }
        export const copyModulesUpdateEvent = (value: unknown): ModulesState.ModulesUpdateEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ModulesUpdateEvent object but received: ${value}`)
            }

            return Object.freeze({
                patches: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.ModulesStateValidation.copyPatch
                )((value as any).patches),
                dependentModules: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).dependentModules),
                initialized: ServiceRuntimePrivate.Validation.copyBoolean((value as any).initialized),
                revision: ServiceRuntimePrivate.Validation.copyInteger((value as any).revision),
            })
        }

        const PatchOp__rawValues = new Set<unknown>(["replace", "remove", "add"])

        export const copyPatchOp = (value: unknown): ModulesState.PatchOp => {
            if (!PatchOp__rawValues.has(value)) {
                throw new Error(`Expected valid value for enum but received: ${value}`)
            }
            return value as ModulesState.PatchOp
        }

        export const copyPatch = (value: unknown): ModulesState.Patch => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Patch object but received: ${value}`)
            }

            return Object.freeze({
                op: ServiceRuntime.ModulesStateValidation.copyPatchOp((value as any).op),
                path: ServiceRuntimePrivate.Validation.copyUnsafeJSON((value as any).path),
                value: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyUnsafeJSON
                )((value as any).value),
            })
        }
    }

    export namespace PreviewSandboxValidation {
        export const copyAutoReloadOptions = (value: unknown): PreviewSandbox.AutoReloadOptions => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected AutoReloadOptions object but received: ${value}`)
            }

            return Object.freeze({
                autoReload: ServiceRuntimePrivate.Validation.copyBoolean((value as any).autoReload),
            })
        }
        export const copyInteractionEvent = (value: unknown): PreviewSandbox.InteractionEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected InteractionEvent object but received: ${value}`)
            }

            return Object.freeze({
                type: ServiceRuntimePrivate.Validation.copyString((value as any).type),
            })
        }
        export const copyKeyboardEvent = (value: unknown): PreviewSandbox.KeyboardEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected KeyboardEvent object but received: ${value}`)
            }

            return Object.freeze({
                key: ServiceRuntimePrivate.Validation.copyString((value as any).key),
                code: ServiceRuntimePrivate.Validation.copyString((value as any).code),
                keyCode: ServiceRuntimePrivate.Validation.copyInteger((value as any).keyCode),
                altKey: ServiceRuntimePrivate.Validation.copyBoolean((value as any).altKey),
                ctrlKey: ServiceRuntimePrivate.Validation.copyBoolean((value as any).ctrlKey),
                metaKey: ServiceRuntimePrivate.Validation.copyBoolean((value as any).metaKey),
                shiftKey: ServiceRuntimePrivate.Validation.copyBoolean((value as any).shiftKey),
            })
        }
        export const copyLogsEvent = (value: unknown): PreviewSandbox.LogsEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected LogsEvent object but received: ${value}`)
            }

            return Object.freeze({
                logs: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.PreviewSandboxValidation.copyLog
                )((value as any).logs),
            })
        }
        export const copyLog = (value: unknown): PreviewSandbox.Log => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Log object but received: ${value}`)
            }

            return Object.freeze({
                data: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).data),
                method: ServiceRuntimePrivate.Validation.copyString((value as any).method),
                id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
            })
        }
        export const copyNavigationEvent = (value: unknown): PreviewSandbox.NavigationEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected NavigationEvent object but received: ${value}`)
            }

            return Object.freeze({
                key: ServiceRuntimePrivate.Validation.copyString((value as any).key),
            })
        }
        export const copyPreviewRenderEvent = (value: unknown): PreviewSandbox.PreviewRenderEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected PreviewRenderEvent object but received: ${value}`)
            }

            return Object.freeze({
                phase: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).phase),
            })
        }
    }

    export namespace PreviewWrapperValidation {
        export const copyToggleConsoleRequest = (value: unknown): PreviewWrapper.ToggleConsoleRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ToggleConsoleRequest object but received: ${value}`)
            }

            return Object.freeze({
                force: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).force),
            })
        }
        export const copyStateEvent = (value: unknown): PreviewWrapper.StateEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected StateEvent object but received: ${value}`)
            }

            return Object.freeze({
                consoleShown: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).consoleShown),
                hasErrors: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).hasErrors),
                hasWarnings: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).hasWarnings),
            })
        }

        const LoadEvent__rawValueToObjectValidatorMap: { [key: string]: (_: unknown) => any } = {
            loading: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    status: "loading",
                })
            },
            loaded: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    status: "loaded",
                })
            },
            error: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    status: "error",
                    error: ServiceRuntime.PreviewWrapperValidation.copyError((value as any).error),
                })
            },
        }

        export const copyLoadEventStatus = (value: unknown): PreviewWrapper.LoadEvent["status"] => {
            if (typeof value !== "string") {
                throw new Error(`Expected string for status but received: ${value}`)
            }
            if (!LoadEvent__rawValueToObjectValidatorMap[value]) {
                throw new Error(`Expected valid value for status but received: ${value}`)
            }
            return value as any
        }

        export const copyLoadEvent = (value: unknown): PreviewWrapper.LoadEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected LoadEvent object but received: ${value}`)
            }

            const rawValue = (value as any)["status"]
            if (typeof rawValue !== "string") {
                throw new Error(`Expected string for status but received: ${value}`)
            }
            const validator = LoadEvent__rawValueToObjectValidatorMap[rawValue]
            if (!validator) {
                throw new Error(`Expected valid value for status but received: ${value}`)
            }

            return validator(value) as PreviewWrapper.LoadEvent
        }

        const Error__rawValueToObjectValidatorMap: { [key: string]: (_: unknown) => any } = {
            documentEmpty: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "documentEmpty",
                })
            },
            frameNotFound: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "frameNotFound",
                })
            },
            permissionDenied: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "permissionDenied",
                })
            },
            unknown: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "unknown",
                    message: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )((value as any).message),
                    extra: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyUnsafeJSON
                    )((value as any).extra),
                })
            },
        }

        export const copyErrorType = (value: unknown): PreviewWrapper.Error["type"] => {
            if (typeof value !== "string") {
                throw new Error(`Expected string for type but received: ${value}`)
            }
            if (!Error__rawValueToObjectValidatorMap[value]) {
                throw new Error(`Expected valid value for type but received: ${value}`)
            }
            return value as any
        }

        export const copyError = (value: unknown): PreviewWrapper.Error => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Error object but received: ${value}`)
            }

            const rawValue = (value as any)["type"]
            if (typeof rawValue !== "string") {
                throw new Error(`Expected string for type but received: ${value}`)
            }
            const validator = Error__rawValueToObjectValidatorMap[rawValue]
            if (!validator) {
                throw new Error(`Expected valid value for type but received: ${value}`)
            }

            return validator(value) as PreviewWrapper.Error
        }
    }

    export namespace PreviewDataSourceValidation {
        export const copyLoadDocumentResponse = (value: unknown): PreviewDataSource.LoadDocumentResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected LoadDocumentResponse object but received: ${value}`)
            }

            return Object.freeze({
                document: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyUnsafeJSON
                )((value as any).document),
                initialUpdates: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyArray.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyUnsafeJSON
                    )
                )((value as any).initialUpdates),
                scripts: ServiceRuntimePrivate.Validation.copyMap.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).scripts),
            })
        }
        export const copyScriptUpdateEvent = (value: unknown): PreviewDataSource.ScriptUpdateEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ScriptUpdateEvent object but received: ${value}`)
            }

            return Object.freeze({
                content: ServiceRuntimePrivate.Validation.copyMap.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).content),
            })
        }

        const TreeUpdateEvent__rawValueToObjectValidatorMap: { [key: string]: (_: unknown) => any } = {
            tree: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "tree",
                    tree: ServiceRuntimePrivate.Validation.copyUnsafeJSON((value as any).tree),
                })
            },
            changes: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "changes",
                    changes: ServiceRuntimePrivate.Validation.copyArray.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyUnsafeJSON
                    )((value as any).changes),
                })
            },
        }

        export const copyTreeUpdateEventType = (value: unknown): PreviewDataSource.TreeUpdateEvent["type"] => {
            if (typeof value !== "string") {
                throw new Error(`Expected string for type but received: ${value}`)
            }
            if (!TreeUpdateEvent__rawValueToObjectValidatorMap[value]) {
                throw new Error(`Expected valid value for type but received: ${value}`)
            }
            return value as any
        }

        export const copyTreeUpdateEvent = (value: unknown): PreviewDataSource.TreeUpdateEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected TreeUpdateEvent object but received: ${value}`)
            }

            const rawValue = (value as any)["type"]
            if (typeof rawValue !== "string") {
                throw new Error(`Expected string for type but received: ${value}`)
            }
            const validator = TreeUpdateEvent__rawValueToObjectValidatorMap[rawValue]
            if (!validator) {
                throw new Error(`Expected valid value for type but received: ${value}`)
            }

            return validator(value) as PreviewDataSource.TreeUpdateEvent
        }
    }

    export namespace PreviewSettingsValidation {
        export const copySelectionLockRequest = (value: unknown): PreviewSettings.SelectionLockRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected SelectionLockRequest object but received: ${value}`)
            }

            return Object.freeze({
                force: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).force),
            })
        }
        export const copyResponsiveRequest = (value: unknown): PreviewSettings.ResponsiveRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ResponsiveRequest object but received: ${value}`)
            }

            return Object.freeze({
                force: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).force),
            })
        }
        export const copyTouchCursorRequest = (value: unknown): PreviewSettings.TouchCursorRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected TouchCursorRequest object but received: ${value}`)
            }

            return Object.freeze({
                force: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).force),
            })
        }

        const SetApperanceRequestDeviceSkin__rawValues = new Set<unknown>(["clay", "realistic"])

        export const copySetApperanceRequestDeviceSkin = (
            value: unknown
        ): PreviewSettings.SetApperanceRequestDeviceSkin => {
            if (!SetApperanceRequestDeviceSkin__rawValues.has(value)) {
                throw new Error(`Expected valid value for enum but received: ${value}`)
            }
            return value as PreviewSettings.SetApperanceRequestDeviceSkin
        }

        const SetApperanceRequestDeviceTheme__rawValues = new Set<unknown>(["dark", "light"])

        export const copySetApperanceRequestDeviceTheme = (
            value: unknown
        ): PreviewSettings.SetApperanceRequestDeviceTheme => {
            if (!SetApperanceRequestDeviceTheme__rawValues.has(value)) {
                throw new Error(`Expected valid value for enum but received: ${value}`)
            }
            return value as PreviewSettings.SetApperanceRequestDeviceTheme
        }

        export const copySetApperanceRequest = (value: unknown): PreviewSettings.SetApperanceRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected SetApperanceRequest object but received: ${value}`)
            }

            return Object.freeze({
                devicePreset: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).devicePreset),
                shadow: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).shadow),
                responsive: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).responsive),
                deviceColorId: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).deviceColorId),
                deviceSkin: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntime.PreviewSettingsValidation.copySetApperanceRequestDeviceSkin
                )((value as any).deviceSkin),
                deviceTheme: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntime.PreviewSettingsValidation.copySetApperanceRequestDeviceTheme
                )((value as any).deviceTheme),
            })
        }
        export const copySetTitleRequest = (value: unknown): PreviewSettings.SetTitleRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected SetTitleRequest object but received: ${value}`)
            }

            return Object.freeze({
                title: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).title),
            })
        }
        export const copyMetadataUpdateEvent = (value: unknown): PreviewSettings.MetadataUpdateEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected MetadataUpdateEvent object but received: ${value}`)
            }

            return Object.freeze({
                devicePreset: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).devicePreset),
                deviceOptions: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntime.PreviewSettingsValidation.copyDeviceOptions
                )((value as any).deviceOptions),
                node: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntime.PreviewSettingsValidation.copyNode
                )((value as any).node),
                responsive: ServiceRuntimePrivate.Validation.copyBoolean((value as any).responsive),
                shadow: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).shadow),
                canChangeResponsive: ServiceRuntimePrivate.Validation.copyBoolean((value as any).canChangeResponsive),
                touchCursor: ServiceRuntimePrivate.Validation.copyBoolean((value as any).touchCursor),
                selectionLock: ServiceRuntimePrivate.Validation.copyBoolean((value as any).selectionLock),
                highlights: ServiceRuntimePrivate.Validation.copyBoolean((value as any).highlights),
                title: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).title),
            })
        }
        export const copyBorderRadius = (value: unknown): PreviewSettings.BorderRadius => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected BorderRadius object but received: ${value}`)
            }

            return Object.freeze({
                topLeft: ServiceRuntimePrivate.Validation.copyInteger((value as any).topLeft),
                topRight: ServiceRuntimePrivate.Validation.copyInteger((value as any).topRight),
                bottomRight: ServiceRuntimePrivate.Validation.copyInteger((value as any).bottomRight),
                bottomLeft: ServiceRuntimePrivate.Validation.copyInteger((value as any).bottomLeft),
            })
        }

        const NodeBackgroundType__rawValues = new Set<unknown>(["color", "image"])

        export const copyNodeBackgroundType = (value: unknown): PreviewSettings.NodeBackgroundType => {
            if (!NodeBackgroundType__rawValues.has(value)) {
                throw new Error(`Expected valid value for enum but received: ${value}`)
            }
            return value as PreviewSettings.NodeBackgroundType
        }

        const NodeBackgroundImageResize__rawValues = new Set<unknown>(["fill", "fit", "stretch"])

        export const copyNodeBackgroundImageResize = (value: unknown): PreviewSettings.NodeBackgroundImageResize => {
            if (!NodeBackgroundImageResize__rawValues.has(value)) {
                throw new Error(`Expected valid value for enum but received: ${value}`)
            }
            return value as PreviewSettings.NodeBackgroundImageResize
        }

        const Node__rawValueToObjectValidatorMap: { [key: string]: (_: unknown) => any } = {
            canvas: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "canvas",
                    name: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )((value as any).name),
                    id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
                    width: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyInteger
                    )((value as any).width),
                    height: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyInteger
                    )((value as any).height),
                    borderRadius: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntime.PreviewSettingsValidation.copyBorderRadius
                    )((value as any).borderRadius),
                    hasLegacyDevice: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyBoolean
                    )((value as any).hasLegacyDevice),
                    backgroundEnabled: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyBoolean
                    )((value as any).backgroundEnabled),
                    backgroundType: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntime.PreviewSettingsValidation.copyNodeBackgroundType
                    )((value as any).backgroundType),
                    backgroundColor: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )((value as any).backgroundColor),
                    backgroundImage: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )((value as any).backgroundImage),
                    backgroundImageResize: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntime.PreviewSettingsValidation.copyNodeBackgroundImageResize
                    )((value as any).backgroundImageResize),
                })
            },
            codeEditor: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "codeEditor",
                    name: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )((value as any).name),
                    componentId: ServiceRuntimePrivate.Validation.copyString((value as any).componentId),
                })
            },
        }

        export const copyNodeType = (value: unknown): PreviewSettings.Node["type"] => {
            if (typeof value !== "string") {
                throw new Error(`Expected string for type but received: ${value}`)
            }
            if (!Node__rawValueToObjectValidatorMap[value]) {
                throw new Error(`Expected valid value for type but received: ${value}`)
            }
            return value as any
        }

        export const copyNode = (value: unknown): PreviewSettings.Node => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Node object but received: ${value}`)
            }

            const rawValue = (value as any)["type"]
            if (typeof rawValue !== "string") {
                throw new Error(`Expected string for type but received: ${value}`)
            }
            const validator = Node__rawValueToObjectValidatorMap[rawValue]
            if (!validator) {
                throw new Error(`Expected valid value for type but received: ${value}`)
            }

            return validator(value) as PreviewSettings.Node
        }

        const DeviceOptionsAppearance__rawValueToObjectValidatorMap: { [key: string]: (_: unknown) => any } = {
            clay: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "clay",
                    bezelRadius: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )((value as any).bezelRadius),
                    bezelColor: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )((value as any).bezelColor),
                    bezelShadeColor: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )((value as any).bezelShadeColor),
                })
            },
            "external-clay": (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "external-clay",
                    imageUrl: ServiceRuntimePrivate.Validation.copyString((value as any).imageUrl),
                    imageWidth: ServiceRuntimePrivate.Validation.copyInteger((value as any).imageWidth),
                    imageHeight: ServiceRuntimePrivate.Validation.copyInteger((value as any).imageHeight),
                })
            },
            realistic: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "realistic",
                    imageUrl: ServiceRuntimePrivate.Validation.copyString((value as any).imageUrl),
                    imageWidth: ServiceRuntimePrivate.Validation.copyInteger((value as any).imageWidth),
                    imageHeight: ServiceRuntimePrivate.Validation.copyInteger((value as any).imageHeight),
                    rotateImage: ServiceRuntimePrivate.Validation.copyOptional.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyBoolean
                    )((value as any).rotateImage),
                })
            },
        }

        export const copyDeviceOptionsAppearanceType = (
            value: unknown
        ): PreviewSettings.DeviceOptionsAppearance["type"] => {
            if (typeof value !== "string") {
                throw new Error(`Expected string for type but received: ${value}`)
            }
            if (!DeviceOptionsAppearance__rawValueToObjectValidatorMap[value]) {
                throw new Error(`Expected valid value for type but received: ${value}`)
            }
            return value as any
        }

        export const copyDeviceOptionsAppearance = (value: unknown): PreviewSettings.DeviceOptionsAppearance => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected DeviceOptionsAppearance object but received: ${value}`)
            }

            const rawValue = (value as any)["type"]
            if (typeof rawValue !== "string") {
                throw new Error(`Expected string for type but received: ${value}`)
            }
            const validator = DeviceOptionsAppearance__rawValueToObjectValidatorMap[rawValue]
            if (!validator) {
                throw new Error(`Expected valid value for type but received: ${value}`)
            }

            return validator(value) as PreviewSettings.DeviceOptionsAppearance
        }

        const DeviceOptionsTheme__rawValues = new Set<unknown>(["dark", "light"])

        export const copyDeviceOptionsTheme = (value: unknown): PreviewSettings.DeviceOptionsTheme => {
            if (!DeviceOptionsTheme__rawValues.has(value)) {
                throw new Error(`Expected valid value for enum but received: ${value}`)
            }
            return value as PreviewSettings.DeviceOptionsTheme
        }

        export const copyDeviceOptions = (value: unknown): PreviewSettings.DeviceOptions => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected DeviceOptions object but received: ${value}`)
            }

            return Object.freeze({
                deviceWidth: ServiceRuntimePrivate.Validation.copyInteger((value as any).deviceWidth),
                deviceHeight: ServiceRuntimePrivate.Validation.copyInteger((value as any).deviceHeight),
                noPadding: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).noPadding),
                appearance: ServiceRuntime.PreviewSettingsValidation.copyDeviceOptionsAppearance(
                    (value as any).appearance
                ),
                screenWidth: ServiceRuntimePrivate.Validation.copyInteger((value as any).screenWidth),
                screenHeight: ServiceRuntimePrivate.Validation.copyInteger((value as any).screenHeight),
                screenOffsetTop: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyInteger
                )((value as any).screenOffsetTop),
                screenOffsetLeft: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyInteger
                )((value as any).screenOffsetLeft),
                screenRadius: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).screenRadius),
                screenMaskImage: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).screenMaskImage),
                screenColor: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).screenColor),
                shadow: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).shadow),
                background: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).background),
                theme: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntime.PreviewSettingsValidation.copyDeviceOptionsTheme
                )((value as any).theme),
                colorId: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).colorId),
            })
        }
    }

    export namespace PreviewStoreValidation {
        export const copyUpdateEvent = (value: unknown): PreviewStore.UpdateEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected UpdateEvent object but received: ${value}`)
            }

            return Object.freeze({
                isVisible: ServiceRuntimePrivate.Validation.copyBoolean((value as any).isVisible),
            })
        }
        export const copyToggleVisibleRequest = (value: unknown): PreviewStore.ToggleVisibleRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ToggleVisibleRequest object but received: ${value}`)
            }

            return Object.freeze({
                force: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).force),
                shownOnLaunch: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).shownOnLaunch),
            })
        }
        export const copyToggleHighlightsRequest = (value: unknown): PreviewStore.ToggleHighlightsRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ToggleHighlightsRequest object but received: ${value}`)
            }

            return Object.freeze({
                force: ServiceRuntimePrivate.Validation.copyBoolean((value as any).force),
            })
        }
        export const copyGetSharedPreviewMetadataResponse = (
            value: unknown
        ): PreviewStore.GetSharedPreviewMetadataResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected GetSharedPreviewMetadataResponse object but received: ${value}`)
            }

            return Object.freeze({
                homeNode: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntime.PreviewStoreValidation.copyNodeMetadata
                )((value as any).homeNode),
                selectedNode: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntime.PreviewStoreValidation.copyNodeMetadata
                )((value as any).selectedNode),
                documentEmpty: ServiceRuntimePrivate.Validation.copyBoolean((value as any).documentEmpty),
            })
        }
        export const copyNodeMetadata = (value: unknown): PreviewStore.NodeMetadata => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected NodeMetadata object but received: ${value}`)
            }

            return Object.freeze({
                nodeId: ServiceRuntimePrivate.Validation.copyString((value as any).nodeId),
                deviceOptions: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntime.PreviewSettingsValidation.copyDeviceOptions
                )((value as any).deviceOptions),
            })
        }
    }

    export namespace PreviewDesktopValidation {
        export const copyOnScaleChange = (value: unknown): PreviewDesktop.OnScaleChange => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected OnScaleChange object but received: ${value}`)
            }

            return Object.freeze({
                scale: ServiceRuntimePrivate.Validation.copyFloat((value as any).scale),
            })
        }
    }

    export namespace ProjectPackageValidation {
        export const copyUploadDesignAssetArgs = (value: unknown): ProjectPackage.UploadDesignAssetArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected UploadDesignAssetArgs object but received: ${value}`)
            }

            return Object.freeze({
                encodedContent: ServiceRuntimePrivate.Validation.copyString((value as any).encodedContent),
                fileName: ServiceRuntimePrivate.Validation.copyString((value as any).fileName),
            })
        }
        export const copyUploadReadmeArgs = (value: unknown): ProjectPackage.UploadReadmeArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected UploadReadmeArgs object but received: ${value}`)
            }

            return Object.freeze({
                readme: ServiceRuntimePrivate.Validation.copyString((value as any).readme),
            })
        }
        export const copyFetchReadmeResponse = (value: unknown): ProjectPackage.FetchReadmeResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FetchReadmeResponse object but received: ${value}`)
            }

            return Object.freeze({
                readme: ServiceRuntimePrivate.Validation.copyString((value as any).readme),
            })
        }
        export const copyAddDependencyArgs = (value: unknown): ProjectPackage.AddDependencyArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected AddDependencyArgs object but received: ${value}`)
            }

            return Object.freeze({
                packageName: ServiceRuntimePrivate.Validation.copyString((value as any).packageName),
            })
        }
        export const copyPublishPackageArgs = (value: unknown): ProjectPackage.PublishPackageArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected PublishPackageArgs object but received: ${value}`)
            }

            return Object.freeze({
                version: ServiceRuntimePrivate.Validation.copyString((value as any).version),
                displayName: ServiceRuntimePrivate.Validation.copyString((value as any).displayName),
                packageName: ServiceRuntimePrivate.Validation.copyString((value as any).packageName),
                slug: ServiceRuntimePrivate.Validation.copyString((value as any).slug),
                isPrivate: ServiceRuntimePrivate.Validation.copyBoolean((value as any).isPrivate),
            })
        }
        export const copyFetchPackageJsonResponse = (value: unknown): ProjectPackage.FetchPackageJsonResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FetchPackageJsonResponse object but received: ${value}`)
            }

            return Object.freeze({
                package: ServiceRuntimePrivate.Validation.copyUnsafeJSON((value as any).package),
            })
        }
    }

    export namespace ProjectStateValidation {
        export const copyFileChange = (value: unknown): ProjectState.FileChange => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FileChange object but received: ${value}`)
            }

            return Object.freeze({
                relativePath: ServiceRuntimePrivate.Validation.copyString((value as any).relativePath),
            })
        }
        export const copyOutdatedProps = (value: unknown): ProjectState.OutdatedProps => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected OutdatedProps object but received: ${value}`)
            }

            return Object.freeze({
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
                current: ServiceRuntimePrivate.Validation.copyString((value as any).current),
                wanted: ServiceRuntimePrivate.Validation.copyString((value as any).wanted),
                latest: ServiceRuntimePrivate.Validation.copyString((value as any).latest),
                type: ServiceRuntimePrivate.Validation.copyString((value as any).type),
            })
        }
        export const copyState = (value: unknown): ProjectState.State => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected State object but received: ${value}`)
            }

            return Object.freeze({
                tasks: ServiceRuntimePrivate.Validation.copyUnsafeJSON((value as any).tasks),
                isDocumentDirty: ServiceRuntimePrivate.Validation.copyBoolean((value as any).isDocumentDirty),
                currentLibraryVersion: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).currentLibraryVersion),
                availableLibraryVersions: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyArray.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )
                )((value as any).availableLibraryVersions),
            })
        }
    }

    export namespace SandboxComponentLoaderValidation {
        export const copyComponentLoaderState = (value: unknown): SandboxComponentLoader.ComponentLoaderState => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ComponentLoaderState object but received: ${value}`)
            }

            return Object.freeze({
                localPackageIdentifier: ServiceRuntimePrivate.Validation.copyString(
                    (value as any).localPackageIdentifier
                ),
            })
        }
        export const copyComponentLoaderStateEvent = (
            value: unknown
        ): SandboxComponentLoader.ComponentLoaderStateEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ComponentLoaderStateEvent object but received: ${value}`)
            }

            return Object.freeze({
                hash: ServiceRuntimePrivate.Validation.copyString((value as any).hash),
                state: ServiceRuntime.SandboxComponentLoaderValidation.copyComponentLoaderState((value as any).state),
            })
        }
    }

    export namespace SandboxInitializerValidation {
        export const copyOptions = (value: unknown): SandboxInitializer.Options => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Options object but received: ${value}`)
            }

            return Object.freeze({
                upgradeToMessagePortChannel: ServiceRuntimePrivate.Validation.copyBoolean(
                    (value as any).upgradeToMessagePortChannel
                ),
                experiments: ServiceRuntime.SandboxInitializerValidation.copyExperiments((value as any).experiments),
            })
        }
        export const copyExperiments = (value: unknown): SandboxInitializer.Experiments => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Experiments object but received: ${value}`)
            }

            return Object.freeze({
                rawDraftRendering: ServiceRuntimePrivate.Validation.copyBoolean((value as any).rawDraftRendering),
                domLayoutStack: ServiceRuntimePrivate.Validation.copyBoolean((value as any).domLayoutStack),
                deprecatedCodeComponentSizeWrapper: ServiceRuntimePrivate.Validation.copyBoolean(
                    (value as any).deprecatedCodeComponentSizeWrapper
                ),
                deprecatedCodeComponentPropsCheck: ServiceRuntimePrivate.Validation.copyBoolean(
                    (value as any).deprecatedCodeComponentPropsCheck
                ),
            })
        }
    }

    export namespace SnapshotViewportValidation {
        export const copySetViewportSizeRequest = (value: unknown): SnapshotViewport.SetViewportSizeRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected SetViewportSizeRequest object but received: ${value}`)
            }

            return Object.freeze({
                width: ServiceRuntimePrivate.Validation.copyInteger((value as any).width),
                height: ServiceRuntimePrivate.Validation.copyInteger((value as any).height),
            })
        }
    }

    export namespace PublicStoreClientValidation {
        export const copyFindByNameArgs = (value: unknown): PublicStoreClient.FindByNameArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FindByNameArgs object but received: ${value}`)
            }

            return Object.freeze({
                packageName: ServiceRuntimePrivate.Validation.copyString((value as any).packageName),
            })
        }
        export const copyContainsPackageArgs = (value: unknown): PublicStoreClient.ContainsPackageArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ContainsPackageArgs object but received: ${value}`)
            }

            return Object.freeze({
                friendlyName: ServiceRuntimePrivate.Validation.copyString((value as any).friendlyName),
                spaceId: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).spaceId),
            })
        }
        export const copyContainsPackageResponse = (value: unknown): PublicStoreClient.ContainsPackageResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ContainsPackageResponse object but received: ${value}`)
            }

            return Object.freeze({
                contained: ServiceRuntimePrivate.Validation.copyBoolean((value as any).contained),
            })
        }
        export const copyToggleFavoriteArgs = (value: unknown): PublicStoreClient.ToggleFavoriteArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ToggleFavoriteArgs object but received: ${value}`)
            }

            return Object.freeze({
                packageName: ServiceRuntimePrivate.Validation.copyString((value as any).packageName),
                favorite: ServiceRuntimePrivate.Validation.copyBoolean((value as any).favorite),
            })
        }
        export const copyFetchRecentsArgs = (value: unknown): PublicStoreClient.FetchRecentsArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FetchRecentsArgs object but received: ${value}`)
            }

            return Object.freeze({
                offset: ServiceRuntimePrivate.Validation.copyInteger((value as any).offset),
                spaceIds: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyArray.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )
                )((value as any).spaceIds),
            })
        }
        export const copyFetchPackagesBySectionArgs = (
            value: unknown
        ): PublicStoreClient.FetchPackagesBySectionArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FetchPackagesBySectionArgs object but received: ${value}`)
            }

            return Object.freeze({
                section: ServiceRuntimePrivate.Validation.copyString((value as any).section),
                offset: ServiceRuntimePrivate.Validation.copyInteger((value as any).offset),
                spaceIds: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyArray.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )
                )((value as any).spaceIds),
            })
        }
        export const copyFetchPackagesByPublisherArgs = (
            value: unknown
        ): PublicStoreClient.FetchPackagesByPublisherArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FetchPackagesByPublisherArgs object but received: ${value}`)
            }

            return Object.freeze({
                publisherId: ServiceRuntimePrivate.Validation.copyString((value as any).publisherId),
                offset: ServiceRuntimePrivate.Validation.copyInteger((value as any).offset),
                spaceIds: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyArray.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )
                )((value as any).spaceIds),
            })
        }
        export const copyFetchPackagesByPopularityArgs = (
            value: unknown
        ): PublicStoreClient.FetchPackagesByPopularityArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FetchPackagesByPopularityArgs object but received: ${value}`)
            }

            return Object.freeze({
                offset: ServiceRuntimePrivate.Validation.copyInteger((value as any).offset),
                days: ServiceRuntimePrivate.Validation.copyInteger((value as any).days),
                spaceIds: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyArray.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )
                )((value as any).spaceIds),
            })
        }
        export const copyFetchTrendingPackagesArgs = (value: unknown): PublicStoreClient.FetchTrendingPackagesArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FetchTrendingPackagesArgs object but received: ${value}`)
            }

            return Object.freeze({
                offset: ServiceRuntimePrivate.Validation.copyInteger((value as any).offset),
            })
        }
        export const copyFetchFeaturedPackagesArgs = (value: unknown): PublicStoreClient.FetchFeaturedPackagesArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FetchFeaturedPackagesArgs object but received: ${value}`)
            }

            return Object.freeze({
                offset: ServiceRuntimePrivate.Validation.copyInteger((value as any).offset),
            })
        }
        export const copySearchArgs = (value: unknown): PublicStoreClient.SearchArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected SearchArgs object but received: ${value}`)
            }

            return Object.freeze({
                offset: ServiceRuntimePrivate.Validation.copyInteger((value as any).offset),
                query: ServiceRuntimePrivate.Validation.copyString((value as any).query),
                spaceIds: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyArray.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )
                )((value as any).spaceIds),
            })
        }
        export const copyGetPublishStatusArgs = (value: unknown): PublicStoreClient.GetPublishStatusArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected GetPublishStatusArgs object but received: ${value}`)
            }

            return Object.freeze({
                packageName: ServiceRuntimePrivate.Validation.copyString((value as any).packageName),
                version: ServiceRuntimePrivate.Validation.copyString((value as any).version),
                isPrivate: ServiceRuntimePrivate.Validation.copyBoolean((value as any).isPrivate),
            })
        }
        export const copyFetchPreflightArgs = (value: unknown): PublicStoreClient.FetchPreflightArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FetchPreflightArgs object but received: ${value}`)
            }

            return Object.freeze({
                package: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).package),
                displayName: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).displayName),
                publishing: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).publishing),
                spaceId: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).spaceId),
            })
        }
        export const copyRemovePackageArgs = (value: unknown): PublicStoreClient.RemovePackageArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected RemovePackageArgs object but received: ${value}`)
            }

            return Object.freeze({
                packageName: ServiceRuntimePrivate.Validation.copyString((value as any).packageName),
            })
        }
        export const copyPublishingError = (value: unknown): PublicStoreClient.PublishingError => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected PublishingError object but received: ${value}`)
            }

            return Object.freeze({
                message: ServiceRuntimePrivate.Validation.copyString((value as any).message),
            })
        }

        const PublishStatus__rawValueToObjectValidatorMap: { [key: string]: (_: unknown) => any } = {
            pending: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    status: "pending",
                })
            },
            completed: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    status: "completed",
                })
            },
            rejected: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    status: "rejected",
                    error: ServiceRuntime.PublicStoreClientValidation.copyPublishingError((value as any).error),
                })
            },
            error: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    status: "error",
                    error: ServiceRuntimePrivate.Validation.copyUnsafeJSON((value as any).error),
                })
            },
        }

        export const copyPublishStatusStatus = (value: unknown): PublicStoreClient.PublishStatus["status"] => {
            if (typeof value !== "string") {
                throw new Error(`Expected string for status but received: ${value}`)
            }
            if (!PublishStatus__rawValueToObjectValidatorMap[value]) {
                throw new Error(`Expected valid value for status but received: ${value}`)
            }
            return value as any
        }

        export const copyPublishStatus = (value: unknown): PublicStoreClient.PublishStatus => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected PublishStatus object but received: ${value}`)
            }

            const rawValue = (value as any)["status"]
            if (typeof rawValue !== "string") {
                throw new Error(`Expected string for status but received: ${value}`)
            }
            const validator = PublishStatus__rawValueToObjectValidatorMap[rawValue]
            if (!validator) {
                throw new Error(`Expected valid value for status but received: ${value}`)
            }

            return validator(value) as PublicStoreClient.PublishStatus
        }

        export const copyPreflightResponse = (value: unknown): PublicStoreClient.PreflightResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected PreflightResponse object but received: ${value}`)
            }

            return Object.freeze({
                ok: ServiceRuntimePrivate.Validation.copyBoolean((value as any).ok),
                suggestedVersion: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).suggestedVersion),
                slug: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).slug),
                package: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).package),
                errorMessage: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).errorMessage),
            })
        }
        export const copyPackage = (value: unknown): PublicStoreClient.Package => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Package object but received: ${value}`)
            }

            return Object.freeze({
                icon: ServiceRuntimePrivate.Validation.copyString((value as any).icon),
                artwork: ServiceRuntimePrivate.Validation.copyString((value as any).artwork),
                friendlyName: ServiceRuntimePrivate.Validation.copyString((value as any).friendlyName),
                readme: ServiceRuntimePrivate.Validation.copyString((value as any).readme),
                license: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).license),
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
                author: ServiceRuntimePrivate.Validation.copyString((value as any).author),
                slug: ServiceRuntimePrivate.Validation.copyString((value as any).slug),
                version: ServiceRuntimePrivate.Validation.copyString((value as any).version),
                updatedAt: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).updatedAt),
                tags: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).tags),
                size: ServiceRuntimePrivate.Validation.copyInteger((value as any).size),
                favorites: ServiceRuntimePrivate.Validation.copyInteger((value as any).favorites),
                downloads: ServiceRuntimePrivate.Validation.copyInteger((value as any).downloads),
                isPrivate: ServiceRuntimePrivate.Validation.copyBoolean((value as any).isPrivate),
                isFavorite: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyBoolean
                )((value as any).isFavorite),
                publisherId: ServiceRuntimePrivate.Validation.copyString((value as any).publisherId),
                spaceId: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).spaceId),
                peerDependencies: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyUnsafeJSON
                )((value as any).peerDependencies),
            })
        }
        export const copyPackageArray = (value: unknown): PublicStoreClient.PackageArray => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected PackageArray object but received: ${value}`)
            }

            return Object.freeze({
                packages: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.PublicStoreClientValidation.copyPackage
                )((value as any).packages),
                totalCount: ServiceRuntimePrivate.Validation.copyInteger((value as any).totalCount),
            })
        }
        export const copyFetchMetaArgs = (value: unknown): PublicStoreClient.FetchMetaArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FetchMetaArgs object but received: ${value}`)
            }

            return Object.freeze({
                name: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).name),
                dependencies: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyArray.bind(
                        undefined,
                        ServiceRuntimePrivate.Validation.copyString
                    )
                )((value as any).dependencies),
            })
        }
        export const copyPackageMeta = (value: unknown): PublicStoreClient.PackageMeta => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected PackageMeta object but received: ${value}`)
            }

            return Object.freeze({
                isPrivate: ServiceRuntimePrivate.Validation.copyBoolean((value as any).isPrivate),
                url: ServiceRuntimePrivate.Validation.copyString((value as any).url),
                ownerId: ServiceRuntimePrivate.Validation.copyString((value as any).ownerId),
                spaceId: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).spaceId),
                publisherId: ServiceRuntimePrivate.Validation.copyString((value as any).publisherId),
            })
        }
        export const copyFetchMetaResponse = (value: unknown): PublicStoreClient.FetchMetaResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FetchMetaResponse object but received: ${value}`)
            }

            return Object.freeze({
                meta: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntime.PublicStoreClientValidation.copyPackageMeta
                )((value as any).meta),
                dependenciesMeta: ServiceRuntimePrivate.Validation.copyUnsafeJSON((value as any).dependenciesMeta),
            })
        }
    }

    export namespace VideoTutorialValidation {
        export const copyFetchVideoStateArgs = (value: unknown): VideoTutorial.FetchVideoStateArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected FetchVideoStateArgs object but received: ${value}`)
            }

            return Object.freeze({
                videoId: ServiceRuntimePrivate.Validation.copyString((value as any).videoId),
            })
        }
        export const copyVideoTutorialState = (value: unknown): VideoTutorial.VideoTutorialState => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected VideoTutorialState object but received: ${value}`)
            }

            return Object.freeze({
                videoId: ServiceRuntimePrivate.Validation.copyString((value as any).videoId),
                time: ServiceRuntimePrivate.Validation.copyFloat((value as any).time),
                lastModifiedAt: ServiceRuntimePrivate.Validation.copyInteger((value as any).lastModifiedAt),
            })
        }
        export const copyUpdateVideoTutorialStateArgs = (
            value: unknown
        ): VideoTutorial.UpdateVideoTutorialStateArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected UpdateVideoTutorialStateArgs object but received: ${value}`)
            }

            return Object.freeze({
                videoId: ServiceRuntimePrivate.Validation.copyString((value as any).videoId),
                time: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyFloat
                )((value as any).time),
            })
        }
    }

    export namespace UnsplashClientValidation {
        export const copyUserLinks = (value: unknown): UnsplashClient.UserLinks => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected UserLinks object but received: ${value}`)
            }

            return Object.freeze({
                html: ServiceRuntimePrivate.Validation.copyString((value as any).html),
            })
        }
        export const copyUnsplashUser = (value: unknown): UnsplashClient.UnsplashUser => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected UnsplashUser object but received: ${value}`)
            }

            return Object.freeze({
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
                links: ServiceRuntime.UnsplashClientValidation.copyUserLinks((value as any).links),
            })
        }
        export const copyURLs = (value: unknown): UnsplashClient.URLs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected URLs object but received: ${value}`)
            }

            return Object.freeze({
                full: ServiceRuntimePrivate.Validation.copyString((value as any).full),
                raw: ServiceRuntimePrivate.Validation.copyString((value as any).raw),
                regular: ServiceRuntimePrivate.Validation.copyString((value as any).regular),
                small: ServiceRuntimePrivate.Validation.copyString((value as any).small),
                thumb: ServiceRuntimePrivate.Validation.copyString((value as any).thumb),
            })
        }
        export const copyPhoto = (value: unknown): UnsplashClient.Photo => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Photo object but received: ${value}`)
            }

            return Object.freeze({
                id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
                width: ServiceRuntimePrivate.Validation.copyInteger((value as any).width),
                height: ServiceRuntimePrivate.Validation.copyInteger((value as any).height),
                color: ServiceRuntimePrivate.Validation.copyString((value as any).color),
                alt_description: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).alt_description),
                description: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).description),
                urls: ServiceRuntime.UnsplashClientValidation.copyURLs((value as any).urls),
                user: ServiceRuntime.UnsplashClientValidation.copyUnsplashUser((value as any).user),
            })
        }

        const ListPhotosArgsOrder_by__rawValues = new Set<unknown>(["latest", "oldest", "popular"])

        export const copyListPhotosArgsOrder_by = (value: unknown): UnsplashClient.ListPhotosArgsOrder_by => {
            if (!ListPhotosArgsOrder_by__rawValues.has(value)) {
                throw new Error(`Expected valid value for enum but received: ${value}`)
            }
            return value as UnsplashClient.ListPhotosArgsOrder_by
        }

        export const copyListPhotosArgs = (value: unknown): UnsplashClient.ListPhotosArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ListPhotosArgs object but received: ${value}`)
            }

            return Object.freeze({
                page: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyInteger
                )((value as any).page),
                per_page: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyInteger
                )((value as any).per_page),
                order_by: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntime.UnsplashClientValidation.copyListPhotosArgsOrder_by
                )((value as any).order_by),
            })
        }
        export const copyListPhotosResponse = (value: unknown): UnsplashClient.ListPhotosResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ListPhotosResponse object but received: ${value}`)
            }

            return Object.freeze({
                photos: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.UnsplashClientValidation.copyPhoto
                )((value as any).photos),
                total: ServiceRuntimePrivate.Validation.copyInteger((value as any).total),
            })
        }
        export const copySearchPhotosArgs = (value: unknown): UnsplashClient.SearchPhotosArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected SearchPhotosArgs object but received: ${value}`)
            }

            return Object.freeze({
                query: ServiceRuntimePrivate.Validation.copyString((value as any).query),
                page: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyInteger
                )((value as any).page),
                per_page: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyInteger
                )((value as any).per_page),
            })
        }
        export const copySearchPhotosResponse = (value: unknown): UnsplashClient.SearchPhotosResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected SearchPhotosResponse object but received: ${value}`)
            }

            return Object.freeze({
                results: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.UnsplashClientValidation.copyPhoto
                )((value as any).results),
                total: ServiceRuntimePrivate.Validation.copyInteger((value as any).total),
            })
        }
        export const copyGetRandomPhotoArgs = (value: unknown): UnsplashClient.GetRandomPhotoArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected GetRandomPhotoArgs object but received: ${value}`)
            }

            return Object.freeze({
                query: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).query),
            })
        }
        export const copyDownloadPhotoArgs = (value: unknown): UnsplashClient.DownloadPhotoArgs => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected DownloadPhotoArgs object but received: ${value}`)
            }

            return Object.freeze({
                id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
            })
        }
    }

    export namespace UserNotificationsValidation {
        const PermissionStatus__rawValues = new Set<unknown>(["granted", "denied", "notDetermined"])

        export const copyPermissionStatus = (value: unknown): UserNotifications.PermissionStatus => {
            if (!PermissionStatus__rawValues.has(value)) {
                throw new Error(`Expected valid value for enum but received: ${value}`)
            }
            return value as UserNotifications.PermissionStatus
        }

        export const copyRequestPermissionResponse = (value: unknown): UserNotifications.RequestPermissionResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected RequestPermissionResponse object but received: ${value}`)
            }

            return Object.freeze({
                status: ServiceRuntime.UserNotificationsValidation.copyPermissionStatus((value as any).status),
            })
        }
        export const copyGetPermissionStatusResponse = (
            value: unknown
        ): UserNotifications.GetPermissionStatusResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected GetPermissionStatusResponse object but received: ${value}`)
            }

            return Object.freeze({
                status: ServiceRuntime.UserNotificationsValidation.copyPermissionStatus((value as any).status),
            })
        }

        const NotificationShow__rawValues = new Set<unknown>(["always", "backgrounded"])

        export const copyNotificationShow = (value: unknown): UserNotifications.NotificationShow => {
            if (!NotificationShow__rawValues.has(value)) {
                throw new Error(`Expected valid value for enum but received: ${value}`)
            }
            return value as UserNotifications.NotificationShow
        }

        export const copyNotification = (value: unknown): UserNotifications.Notification => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Notification object but received: ${value}`)
            }

            return Object.freeze({
                id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
                title: ServiceRuntimePrivate.Validation.copyString((value as any).title),
                body: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).body),
                show: ServiceRuntime.UserNotificationsValidation.copyNotificationShow((value as any).show),
            })
        }
        export const copyPostRequest = (value: unknown): UserNotifications.PostRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected PostRequest object but received: ${value}`)
            }

            return Object.freeze({
                notification: ServiceRuntime.UserNotificationsValidation.copyNotification((value as any).notification),
            })
        }

        const PostResponse__rawValueToObjectValidatorMap: { [key: string]: (_: unknown) => any } = {
            action: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "action",
                })
            },
            cancel: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    type: "cancel",
                    reason: ServiceRuntimePrivate.Validation.copyString((value as any).reason),
                })
            },
        }

        export const copyPostResponseType = (value: unknown): UserNotifications.PostResponse["type"] => {
            if (typeof value !== "string") {
                throw new Error(`Expected string for type but received: ${value}`)
            }
            if (!PostResponse__rawValueToObjectValidatorMap[value]) {
                throw new Error(`Expected valid value for type but received: ${value}`)
            }
            return value as any
        }

        export const copyPostResponse = (value: unknown): UserNotifications.PostResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected PostResponse object but received: ${value}`)
            }

            const rawValue = (value as any)["type"]
            if (typeof rawValue !== "string") {
                throw new Error(`Expected string for type but received: ${value}`)
            }
            const validator = PostResponse__rawValueToObjectValidatorMap[rawValue]
            if (!validator) {
                throw new Error(`Expected valid value for type but received: ${value}`)
            }

            return validator(value) as UserNotifications.PostResponse
        }

        export const copyCancelRequest = (value: unknown): UserNotifications.CancelRequest => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected CancelRequest object but received: ${value}`)
            }

            return Object.freeze({
                notificationId: ServiceRuntimePrivate.Validation.copyString((value as any).notificationId),
            })
        }
    }

    export namespace UserSessionServiceValidation {
        export const copySessionIdentityResponse = (value: unknown): UserSessionService.SessionIdentityResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected SessionIdentityResponse object but received: ${value}`)
            }

            return Object.freeze({
                user: ServiceRuntime.Validation.copyUser((value as any).user),
            })
        }
        export const copyPermissionsResponse = (value: unknown): UserSessionService.PermissionsResponse => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected PermissionsResponse object but received: ${value}`)
            }

            return Object.freeze({
                isViewOnly: ServiceRuntimePrivate.Validation.copyBoolean((value as any).isViewOnly),
            })
        }
    }

    export namespace WebProjectValidation {
        const ProjectSpaceScope__rawValues = new Set<unknown>(["user", "organization"])

        export const copyProjectSpaceScope = (value: unknown): WebProject.ProjectSpaceScope => {
            if (!ProjectSpaceScope__rawValues.has(value)) {
                throw new Error(`Expected valid value for enum but received: ${value}`)
            }
            return value as WebProject.ProjectSpaceScope
        }

        export const copyProjectSpace = (value: unknown): WebProject.ProjectSpace => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ProjectSpace object but received: ${value}`)
            }

            return Object.freeze({
                id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
                icon: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).icon),
                ownerId: ServiceRuntimePrivate.Validation.copyString((value as any).ownerId),
                scope: ServiceRuntime.WebProjectValidation.copyProjectSpaceScope((value as any).scope),
                displayName: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).displayName),
            })
        }
        export const copyShareLink = (value: unknown): WebProject.ShareLink => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ShareLink object but received: ${value}`)
            }

            return Object.freeze({
                id: ServiceRuntimePrivate.Validation.copyString((value as any).id),
                href: ServiceRuntimePrivate.Validation.copyString((value as any).href),
            })
        }
        export const copyCurrentProjectEvent = (value: unknown): WebProject.CurrentProjectEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected CurrentProjectEvent object but received: ${value}`)
            }

            return Object.freeze({
                title: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString
                )((value as any).title),
                space: ServiceRuntime.WebProjectValidation.copyProjectSpace((value as any).space),
                shareLinks: ServiceRuntimePrivate.Validation.copyArray.bind(
                    undefined,
                    ServiceRuntime.WebProjectValidation.copyShareLink
                )((value as any).shareLinks),
                canMoveProject: ServiceRuntimePrivate.Validation.copyBoolean((value as any).canMoveProject),
            })
        }
        export const copyLocation = (value: unknown): WebProject.Location => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Location object but received: ${value}`)
            }

            return Object.freeze({
                x: ServiceRuntimePrivate.Validation.copyInteger((value as any).x),
                y: ServiceRuntimePrivate.Validation.copyInteger((value as any).y),
            })
        }
        export const copyShowMoveProjectMenuArguments = (value: unknown): WebProject.ShowMoveProjectMenuArguments => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ShowMoveProjectMenuArguments object but received: ${value}`)
            }

            return Object.freeze({
                location: ServiceRuntime.WebProjectValidation.copyLocation((value as any).location),
            })
        }

        const ShowUpgradeOrTrialModalArgumentsShow__rawValues = new Set<unknown>(["fonts", "insert-menu"])

        export const copyShowUpgradeOrTrialModalArgumentsShow = (
            value: unknown
        ): WebProject.ShowUpgradeOrTrialModalArgumentsShow => {
            if (!ShowUpgradeOrTrialModalArgumentsShow__rawValues.has(value)) {
                throw new Error(`Expected valid value for enum but received: ${value}`)
            }
            return value as WebProject.ShowUpgradeOrTrialModalArgumentsShow
        }

        export const copyShowUpgradeOrTrialModalArguments = (
            value: unknown
        ): WebProject.ShowUpgradeOrTrialModalArguments => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ShowUpgradeOrTrialModalArguments object but received: ${value}`)
            }

            return Object.freeze({
                show: ServiceRuntime.WebProjectValidation.copyShowUpgradeOrTrialModalArgumentsShow((value as any).show),
            })
        }
    }

    export namespace ZzzTestingStreamsValidation {
        export const copyBeepEvent = (value: unknown): ZzzTestingStreams.BeepEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected BeepEvent object but received: ${value}`)
            }

            return Object.freeze({
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
            })
        }
    }

    export namespace ZzzTestingTypesValidation {
        const ObjectStringEnum__rawValues = new Set<unknown>(["a", "b", "c"])

        export const copyObjectStringEnum = (value: unknown): ZzzTestingTypes.ObjectStringEnum => {
            if (!ObjectStringEnum__rawValues.has(value)) {
                throw new Error(`Expected valid value for enum but received: ${value}`)
            }
            return value as ZzzTestingTypes.ObjectStringEnum
        }

        export const copyObject = (value: unknown): ZzzTestingTypes.Object => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Object object but received: ${value}`)
            }

            return Object.freeze({
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
                stringEnum: ServiceRuntime.ZzzTestingTypesValidation.copyObjectStringEnum((value as any).stringEnum),
                objectEnum: ServiceRuntimePrivate.Validation.copyOptional.bind(
                    undefined,
                    ServiceRuntime.ZzzTestingTypesValidation.copyObjectEnum
                )((value as any).objectEnum),
            })
        }

        const ObjectEnum__rawValueToObjectValidatorMap: { [key: string]: (_: unknown) => any } = {
            string: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    foo: "string",
                    bar: ServiceRuntimePrivate.Validation.copyString((value as any).bar),
                })
            },
            integer: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    foo: "integer",
                    count: ServiceRuntimePrivate.Validation.copyInteger((value as any).count),
                })
            },
            array: (value: unknown): unknown => {
                if (typeof value !== "object" || value === null) {
                    throw new Error(`Expected non-null object but received: ${value}`)
                }

                return Object.freeze({
                    foo: "array",
                    objects: ServiceRuntimePrivate.Validation.copyArray.bind(
                        undefined,
                        ServiceRuntime.ZzzTestingTypesValidation.copyObject
                    )((value as any).objects),
                })
            },
        }

        export const copyObjectEnumFoo = (value: unknown): ZzzTestingTypes.ObjectEnum["foo"] => {
            if (typeof value !== "string") {
                throw new Error(`Expected string for foo but received: ${value}`)
            }
            if (!ObjectEnum__rawValueToObjectValidatorMap[value]) {
                throw new Error(`Expected valid value for foo but received: ${value}`)
            }
            return value as any
        }

        export const copyObjectEnum = (value: unknown): ZzzTestingTypes.ObjectEnum => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected ObjectEnum object but received: ${value}`)
            }

            const rawValue = (value as any)["foo"]
            if (typeof rawValue !== "string") {
                throw new Error(`Expected string for foo but received: ${value}`)
            }
            const validator = ObjectEnum__rawValueToObjectValidatorMap[rawValue]
            if (!validator) {
                throw new Error(`Expected valid value for foo but received: ${value}`)
            }

            return validator(value) as ZzzTestingTypes.ObjectEnum
        }

        export const copyMap = (value: unknown): ZzzTestingTypes.Map => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected Map object but received: ${value}`)
            }

            return Object.freeze({
                stringMap: ServiceRuntimePrivate.Validation.copyMap.bind(
                    undefined,
                    ServiceRuntimePrivate.Validation.copyString,
                    ServiceRuntimePrivate.Validation.copyInteger
                )((value as any).stringMap),
            })
        }
    }

    export namespace ZzzTestingOnewayValidation {
        export const copyBeepEvent = (value: unknown): ZzzTestingOneway.BeepEvent => {
            if (typeof value !== "object" || value === null) {
                throw new Error(`Expected BeepEvent object but received: ${value}`)
            }

            return Object.freeze({
                name: ServiceRuntimePrivate.Validation.copyString((value as any).name),
            })
        }
    }
}
