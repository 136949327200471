import { css } from "linaria"

export const container = css`
    width: 100%;
    height: 100%;
    flex: 1;
`

export const hidden = css`
    display: none;
`

export const responsive = css`
    /* Hack to disable all wrapping divs, so that we don't have to chain
      "width: 100%; height: 100%; flex: 1" in responsive mode */
    div {
        display: contents;
    }
`
