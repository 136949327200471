import * as React from "react"
import { cx } from "linaria"
import type { HTMLDivAttributes, OverrideType } from "./types"
import * as styles from "./TabBar.styles"

export type TabBarItemProps<T extends string | number | boolean | undefined> = OverrideType<
    Omit<HTMLDivAttributes, "onClick">,
    {
        /** Distinguish items that share the same title. */
        identifier?: T
        /** The title of the tab */
        title: string
        /** The current selected tab */
        selected: boolean
        /** The event handler receives the identifier as an argument. Allowing a single function to handle multiple items. */
        onSelect: (identifier: T | undefined) => void
    }
>

// This function is only used for its type. React forwardRef and memo break the use of generics in Props
function TabBarItemType<T extends string | number | boolean | undefined>(_props: TabBarItemProps<T>) {
    return <></>
}

export const TabBarItem = React.memo(
    React.forwardRef(function TabItem<T extends string | number | boolean | undefined>(
        props: TabBarItemProps<T>,
        forwardedRef: React.Ref<HTMLDivElement>
    ) {
        const { title, identifier, selected, onSelect, className, ...rest } = props

        const onClickHandler = React.useCallback(() => {
            onSelect(identifier)
        }, [onSelect, identifier])

        return (
            <div
                ref={forwardedRef}
                onClick={onClickHandler}
                className={cx(className, styles.tabBarItem, selected && styles.tabBarItemSelected)}
                {...rest}
            >
                {title}
            </div>
        )
    })
) as typeof TabBarItemType

export type TabBarProps = OverrideType<
    HTMLDivAttributes,
    {
        hideDivider?: boolean
    }
>

export const TabBar = React.memo(
    React.forwardRef<HTMLDivElement, TabBarProps>(function TabBar(props, forwardedRef) {
        const { className, children, hideDivider = false, ...rest } = props
        const onlyOneItem = React.Children.toArray(children).length <= 1

        return (
            <div
                ref={forwardedRef}
                className={cx(className, styles.tabBar, onlyOneItem && styles.noPointerEvents)}
                {...rest}
            >
                {children}
                {!hideDivider && <div className={styles.divider} />}
            </div>
        )
    })
)
