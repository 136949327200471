import * as React from "react"
import { Layer, LayerProps } from "./Layer"
import { Rect } from "../types/Rect"
import { RenderTarget } from "../types/RenderEnvironment"
import { FrameProps, DeprecatedCoreFrameProps, FrameWithMotion } from "./Frame"
import { ComponentPlaceholder, PlaceholderType } from "./ComponentPlaceholder"
import { isReactChild, isReactElement } from "../../utils/type-guards"
import { safeWindow } from "../../utils/safeWindow"
import { NewConstraintProperties, calculateRect, ParentSizeState } from "../types/NewConstraints"
import { Size } from "../../render/types/Size"
import { MotionStyle, AnimateSharedLayout, LayoutGroupContext } from "framer-motion"
import { runtime } from "../../utils/runtimeInjection"
import { nodeIdFromString } from "../utils/nodeIdFromString"
import { useLayoutId } from "../utils/useLayoutId"
import { AutomaticLayoutIds } from "../../components/AnimateLayout/LayoutIdContext"
import { ComponentContainerContext } from "./ComponentContainerContext"

/**
 * @internal
 */
export interface ComponentContainerProps extends Partial<NewConstraintProperties> {
    style: MotionStyle
    visible: boolean
    componentIdentifier: string
    name?: string
}

/**
 * @internal
 */
export interface ComponentContainerState {
    lastError?: {
        // Used to re-probe component for errors (see render method).
        children: React.ReactNode
        name: string
        message: string
        componentStack: string[]
    }
}

/**
 * @internal
 */
export interface ComponentContainerProperties extends ComponentContainerProps, LayerProps {}

/**
 * @internal
 */
export function ComponentContainer(props: Partial<ComponentContainerProperties>) {
    const layoutId = useLayoutId(props)

    return <Container {...props} __layoutId={layoutId} />
}

/**
 * @internal
 */
class Container extends Layer<ComponentContainerProperties & { __layoutId?: string }, ComponentContainerState> {
    static supportsConstraints = true
    state: ComponentContainerState = {}

    static defaultComponentContainerProps: ComponentContainerProps = {
        style: {},
        visible: true,
        componentIdentifier: "",
    }

    static readonly defaultProps: ComponentContainerProperties = {
        ...Layer.defaultProps,
        ...Container.defaultComponentContainerProps,
    }

    static contextType = ComponentContainerContext

    contentWrapperRef: HTMLDivElement | null = null

    private setContentWrapperRef = (ref: HTMLDivElement) => {
        // Don't set references for code components rendered inside other code components,
        // because they're not directly selectable on the canvas.
        const inCodeComponent = !!this.context
        if (RenderTarget.current() !== RenderTarget.canvas || inCodeComponent) {
            return
        }

        if (ref) {
            this.contentWrapperRef = ref
        }
    }

    private requestMeasure = () => {
        if (!this.contentWrapperRef) {
            return
        }

        const children = [...this.contentWrapperRef.children].filter(isMeasurable)
        const nodeId = nodeIdFromString(this.props.id!)

        runtime.queueMeasureRequest(nodeId, this.contentWrapperRef, children)
    }

    componentDidMount() {
        this.requestMeasure()
    }

    componentDidUpdate() {
        this.requestMeasure()
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        let stack = info.componentStack.split("\n").filter(line => line.length !== 0)
        let currentIndex = 0
        for (const line of stack) {
            if (line.startsWith(`    in ${this.constructor.name}`)) {
                break
            }
            currentIndex++
        }
        stack = stack.slice(0, currentIndex)
        this.setState({
            lastError: {
                children: this.props.children,
                name: error.name,
                message: error.message,
                componentStack: stack,
            },
        })
    }

    renderErrorPlaceholder(title: string, message: string): JSX.Element {
        return (
            <FrameWithMotion {...this.props} background={null}>
                <ComponentPlaceholder type={PlaceholderType.Error} title={title} message={message} />
            </FrameWithMotion>
        )
    }

    render() {
        if (process.env.NODE_ENV !== "production" && safeWindow["perf"]) safeWindow["perf"].nodeRender()
        let { children } = this.props
        const { componentIdentifier } = this.props
        const { lastError: error } = this.state

        // If the file of the component is in has a compile or load error, there will be no children
        // and there will be an error in the componentLoader. If so we render that error.
        // Note, cannot use React.Children.count when children = [null]
        const noChildren = !children || (Array.isArray(children) && children.filter(c => c).length === 0)
        if (noChildren) {
            const errorComponent = runtime.componentLoader.errorForIdentifier(componentIdentifier)
            if (errorComponent) {
                return this.renderErrorPlaceholder(errorComponent.file, errorComponent.error)
            }
        }

        // If an error occurred, componentDidCatch will set error. Additionally, we keep track of the child(ren)
        // reference of this container and only render the error when nothing changed. This means we will
        // re-render the component when something does change, which will either take us out of the error state
        // or update the children reference and keep showing the error. Effectively, this re-probes the component
        // for errors, without throwing an error twice in a row which would make React skip this error boundary
        // and go up the stack.
        if (error && error.children === children) {
            const component = runtime.componentLoader.componentForIdentifier(componentIdentifier)
            const file = component ? component.file : "???"
            return this.renderErrorPlaceholder(file, error.message)
        }

        // This is provided by the time budget logic in runtime
        safeWindow["__checkComponentBudget__"]?.()

        // FIXME: this suppresses warnings about Motion-backed types that aren't supported by the deprecated props.
        let frameProps = this.props as Partial<FrameProps & DeprecatedCoreFrameProps>

        if (RenderTarget.current() !== RenderTarget.canvas) {
            // For Code Overrides, we want the styling properties to be applied to the Frame,
            // and the rest to the actual component
            const {
                left,
                right,
                top,
                bottom,
                center,
                centerX,
                centerY,
                aspectRatio,
                parentSize,
                width,
                height,
                rotation,
                opacity,
                visible,
                _constraints,
                _initialStyle,
                name,
                // Remove the children and the componentIdentifier from the props passed into the component
                componentIdentifier: originalComponentIdentifier,
                children: originalChildren,
                style,
                duplicatedFrom,
                _canMagicMotion,
                widthType,
                heightType,
                ...childProps
            } = frameProps as Partial<FrameProps & DeprecatedCoreFrameProps & ComponentContainerProperties>
            children = React.Children.map(originalChildren, (child: React.ReactElement<typeof childProps>) => {
                if (!isReactChild(child) || !isReactElement(child)) {
                    return child
                }

                // For framer-motion's `layout` to work inside code components,
                // they need to be wrapped in AnimateSharedLayout.
                // Additionally, code components need to avoid generating layout ids for canvas layers.
                if (!isPageOrScroll(originalComponentIdentifier)) {
                    return (
                        <LayoutGroupContext.Provider value={this.props.__layoutId ?? null}>
                            <AnimateSharedLayout>
                                <AutomaticLayoutIds enabled={false}>
                                    {React.cloneElement(child, childProps)}
                                </AutomaticLayoutIds>
                            </AnimateSharedLayout>
                        </LayoutGroupContext.Provider>
                    )
                }

                return React.cloneElement(child, childProps)
            })
            frameProps = {
                style,
                _constraints,
                _initialStyle,
                left,
                right,
                top,
                bottom,
                center,
                centerX,
                centerY,
                aspectRatio,
                parentSize,
                width,
                height,
                rotation,
                visible,
                name,
                duplicatedFrom,
                id: frameProps.id,
                layoutId: this.props.__layoutId,
                _canMagicMotion,
                widthType,
                heightType,
            }
        }

        return (
            /* The background should come before the frameProps. It looks like there never should be a background in frameProps,
             * but published design components can contain an old version of the presentation tree that expects the background
             * that is passed to be rendered here
             * See the stackBackgroundTest.tsx integration test for an example of such a case
             */
            <ComponentContainerContext.Provider value>
                <FrameWithMotion background={null} overflow="visible" ref={this.setContentWrapperRef} {...frameProps}>
                    {children}
                </FrameWithMotion>
            </ComponentContainerContext.Provider>
        )
    }
}

;(ComponentContainer as any).rect = function (
    props: Partial<ComponentContainerProperties>,
    parentSize?: Size
): Rect | null {
    return calculateRect(props, parentSize || ParentSizeState.Unknown)
}

function isPageOrScroll(identifier?: string) {
    if (!identifier) return false
    if (identifier === "framer/Page") return true
    if (identifier === "framer/Scroll") return true
    return false
}

/**
 * Rough check if an element of a HTMLCollection has measurable layout (can be
 * rendered as anything other than "display: none") _without_ querying its
 * computed styles, because we want to avoid a style recalculation penalty.
 *
 * @param element an HTMLCollection node
 */

function isMeasurable(element: Node) {
    // Filter out certain HTMLElement subclasses that don't represent measurable elements
    if (
        element instanceof HTMLBaseElement ||
        element instanceof HTMLHeadElement ||
        element instanceof HTMLLinkElement ||
        element instanceof HTMLMetaElement ||
        element instanceof HTMLScriptElement ||
        element instanceof HTMLStyleElement ||
        element instanceof HTMLTitleElement
    ) {
        return false
    }

    return element instanceof HTMLElement || element instanceof SVGElement
}
