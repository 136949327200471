import {
    CanvasNode,
    TextNode,
    isDrawableNode,
    isBooleanShapeNode,
    isTextNode,
    isShapeContainerNode,
    isCodeComponentNode,
} from "document/models/CanvasTree"
import { withClip } from "../document/models/CanvasTree/traits/Clip"
import { withChildren } from "document/models/CanvasTree/traits/Children"
import { Rect, Color } from "framer"
import { localShadowFrame } from "framer"
import { hasBoxShadow } from "document/models/CanvasTree/traits/BoxShadow"
import { withShadow } from "document/models/CanvasTree/traits/Shadow"
import type { BoxShadow } from "document/models/Shadow"
import type { Shadow } from "document/models/ShadowClass"
import Matrix from "document/models/Matrix"
import { withStroke } from "document/models/CanvasTree/traits/Stroke"
import type { LayoutCache } from "document/LayoutCache"
import { isNumber } from "./typeChecks"

interface BoundingBox {
    minX: number
    maxX: number
    minY: number
    maxY: number
}

export interface CollectBoundingBoxArguments {
    node: CanvasNode
    box: BoundingBox
    matrix?: DOMMatrixReadOnly
    parentRect?: Rect | null
    ignoreInvisible?: boolean
    ignoreShadows?: boolean
    ignoreChildren?: boolean
    /** Used to look up code component sizes */
    layoutCache?: LayoutCache
}

export function collectBoundingBox({
    node,
    box,
    matrix = new DOMMatrix(),
    parentRect = null,
    layoutCache,
    ignoreInvisible = false,
    ignoreShadows = false,
    ignoreChildren = false,
}: CollectBoundingBoxArguments) {
    if (!node.isVisible() && ignoreInvisible) {
        return
    }

    matrix = matrix.multiply(node.transformMatrix(parentRect))
    let rect = node.rect(parentRect)

    // In the case of TextNodes with their overflow set to "Show"
    // the bounding box height is the larger of either the height of the
    // container, or the height of the styled text inside the container.
    //
    // The reason we're not always taking the height of the styled text is
    // that it's more likely that shorter text in a large container is intentional,
    // while large text overflowing a shorter container is not.
    if (isTextNode(node)) {
        const isClipped = withClip(node) && node.clip
        const textContentRect = getTextContentRect(node, parentRect)

        if (!isClipped && textContentRect.height > rect.height) {
            rect.y += textContentRect.y
            rect.height = textContentRect.height
            matrix = matrix.translate(0, textContentRect.y)
        }
    }

    if (isCodeComponentNode(node) && layoutCache) {
        const contentSize = layoutCache.getContentSize(node.id)
        if (contentSize) {
            rect = { ...rect, ...contentSize }
        }
    }

    let originRect = Rect.atOrigin(rect)

    if (
        withStroke(node) &&
        node.strokeEnabled &&
        node.strokeAlignment === "center" &&
        node.strokeColor &&
        Color(node.strokeColor).a !== 0 &&
        node.strokeWidth &&
        node.strokeWidth > 0
    ) {
        originRect = Rect.inflate(originRect, node.strokeWidth / 2)
    }

    let convertedRect = Rect.offset(rect, originRect)

    const cornerPoints = Rect.cornerPoints(originRect)
    const convertedCorners = cornerPoints.map(corner => Matrix.convertPoint(matrix, corner))
    convertedRect = Rect.fromPoints(convertedCorners)

    expandBoundingBoxWithRect(box, convertedRect)

    if (!ignoreShadows) {
        collectshadowBounds(node, box, matrix)
    }

    if (withClip(node) && node.clip) return
    if (!withChildren(node)) return
    if (isBooleanShapeNode(node)) return
    if (isShapeContainerNode(node)) return
    if (!ignoreChildren) {
        for (let i = 0, il = node.children.length; i < il; i++) {
            const child = node.children[i]
            collectBoundingBox({
                node: child,
                box,
                matrix,
                parentRect: rect,
                layoutCache,
                ignoreInvisible,
                ignoreShadows,
                ignoreChildren,
            })
        }
    }
}

export function isValidBoundingBox(box: BoundingBox) {
    return [box.minX, box.maxX, box.minY, box.maxY].every(isNumber)
}

export function validBoundingBox(box: BoundingBox, defaultBoundingBox: BoundingBox) {
    if (isValidBoundingBox(box)) return box
    return defaultBoundingBox
}

export function boundingBoxFromRect(rect: Rect): BoundingBox {
    return {
        minX: rect.x,
        minY: rect.y,
        maxX: Rect.maxX(rect),
        maxY: Rect.maxY(rect),
    }
}

export function expandBoundingBoxWithRect(box: BoundingBox, rect: Rect) {
    const minX = Math.floor(rect.x)
    const maxX = Math.ceil(rect.x + rect.width)
    const minY = Math.floor(rect.y)
    const maxY = Math.ceil(rect.y + rect.height)

    box.minX = Math.min(box.minX, minX)
    box.maxX = Math.max(box.maxX, maxX)
    box.minY = Math.min(box.minY, minY)
    box.maxY = Math.max(box.maxY, maxY)
}

export function getRectContainingBoundingBox(box: BoundingBox): Rect {
    const minX = Math.floor(box.minX)
    const minY = Math.floor(box.minY)
    const maxX = Math.ceil(box.maxX)
    const maxY = Math.ceil(box.maxY)
    return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY,
    }
}

function collectshadowBounds(
    node: CanvasNode,
    box: { minX: number; maxX: number; minY: number; maxY: number },
    matrix: DOMMatrixReadOnly,
    parentRect: Rect | null = null
) {
    if (!isDrawableNode(node)) {
        return
    }
    const rect = node.rect(parentRect)
    const normalizedRect = Rect.atOrigin(rect)

    let shadows: Readonly<(BoxShadow | Shadow)[]> | undefined
    if (hasBoxShadow(node)) shadows = node.boxShadows
    if (withShadow(node)) shadows = node.shadows
    if (!shadows) return
    for (let i = 0, il = shadows.length; i < il; i++) {
        const shadow = shadows[i]
        const shadowRect = localShadowFrame(shadow, normalizedRect)
        if (!shadowRect) continue
        const shadowRectCorners = Rect.cornerPoints(shadowRect)
        const convertedCorners = shadowRectCorners.map(corner => Matrix.convertPoint(matrix, corner))
        const shadowBounds = Rect.fromPoints(convertedCorners)
        const minX = Math.floor(shadowBounds.x)
        const maxX = Math.ceil(minX + shadowBounds.width)
        const minY = Math.floor(shadowBounds.y)
        const maxY = Math.ceil(minY + shadowBounds.height)
        box.minX = Math.min(box.minX, minX)
        box.maxX = Math.max(box.maxX, maxX)
        box.minY = Math.min(box.minY, minY)
        box.maxY = Math.max(box.maxY, maxY)
    }
}

export function getTextContentRect(node: TextNode<any>, parentRect: Rect | null = null): Rect {
    const container = node.rect(parentRect)
    const size = node.styledText.calculateSize(container.width) || node.calculateSize(container.width)

    const rect: Rect = {
        x: 0,
        y: 0,
        width: container.width,
        height: size.height,
    }

    switch (node.textVerticalAlignment) {
        case "center": {
            rect.y = (container.height - rect.height) / 2
            break
        }
        case "bottom": {
            rect.y = container.height - rect.height
            break
        }
    }

    return rect
}
