/*
Reword error messages like below
```
Module parse failed: 'return' outside of function (11:8)
    You may need an appropriate loader to handle this file type.
    |     render();
    |     {
    |         return React.createElement("div", null, "Hello");
    |     }
    | } undefined undefined
```
*/
const needle = "Module parse failed: "
export function rewordCompileError(message: string): string {
    if (!message.startsWith(needle)) return message

    const split = message.split("\n")
    const first = split[0]
    if (!first) return message
    const rest = split.slice(2)
    return first.slice(needle.length) + "\n" + rest.join("\n")
}
