import { V69, V70 } from "../types"
import { PropertyEnabled, isPropertyEnabled } from "../types/V55"
import { transform } from "../utils/transform"
import { _, exactCheck } from "../utils/exactCheck"
import { withoutProps } from "../utils/withoutProps"
import {
    ReplicaFromNode,
    transformReplicaOverrides,
    UpdateOverrides,
    GetInheritedPropValue,
} from "../utils/transformReplicaOverrides"

function migrateBoxShadows(boxShadows: V69.WithBoxShadow["boxShadows"]): V70.WithBoxShadow | undefined {
    if (!boxShadows) return
    const newShadows: V70.BoxShadow[] = []
    boxShadows.forEach(oldShadow => {
        const { enabled, ...shadow } = oldShadow
        if (enabled) {
            newShadows.push(shadow)
        }
    })
    return newShadows.length > 0 ? { boxShadows: newShadows } : undefined
}

function migrateShadows(shadows: V69.WithShadow["shadows"]): V70.WithShadow | undefined {
    if (!shadows) return
    const newShadows: V70.Shadow[] = []
    shadows.forEach(oldShadow => {
        const { enabled, ...shadow } = oldShadow
        if (enabled) {
            newShadows.push(shadow)
        }
    })
    return newShadows.length > 0 ? { shadows: newShadows } : undefined
}

function migrateBlur(node: V69.TreeNode & V69.WithBlur): V70.WithBlur | undefined {
    const blurEnabled = node.blurEnabled === PropertyEnabled.enabled
    if (blurEnabled) {
        switch (node.blurType) {
            case "background":
                return { backgroundBlur: node.blur }
            case "layer":
                return { blur: node.blur }
        }
    }
}

function migrateFilterEnabled(node: V69.TreeNode & V69.WithFilters): V70.WithFilters {
    const result: V70.WithFilters = {}

    if (node.brightnessEnabled === PropertyEnabled.enabled) result.brightness = node.brightness
    if (node.contrastEnabled === PropertyEnabled.enabled) result.contrast = node.contrast
    if (node.grayScaleEnabled === PropertyEnabled.enabled) result.grayscale = node.grayscale
    if (node.hueRotateEnabled === PropertyEnabled.enabled) result.hueRotate = node.hueRotate
    if (node.invertEnabled === PropertyEnabled.enabled) result.invert = node.invert
    if (node.saturateEnabled === PropertyEnabled.enabled) result.saturate = node.saturate
    if (node.sepiaEnabled === PropertyEnabled.enabled) result.sepia = node.sepia

    return result
}

function migrateBorderEnabled(node: V69.TreeNode & V69.WithBorder): V70.WithBorder | undefined {
    switch (node.borderEnabled) {
        case PropertyEnabled.enabled:
            return { borderEnabled: true }
        case PropertyEnabled.disabledHidden:
        case PropertyEnabled.disabledVisible:
            return { borderEnabled: false }
        default:
            return undefined
    }
}

function migrateStrokeEnabled(node: V69.TreeNode & V69.WithStroke): V70.WithStroke | undefined {
    switch (node.strokeEnabled) {
        case PropertyEnabled.enabled:
            return { strokeEnabled: true }
        case PropertyEnabled.disabledHidden:
        case PropertyEnabled.disabledVisible:
            return { strokeEnabled: false }
        default:
            return undefined
    }
}

function migrateBlendMode({ blendingMode }: V69.TreeNode & V69.WithBlending): V70.WithBlending | undefined {
    return !blendingMode || blendingMode === "normal" ? undefined : { blendingMode }
}

function isArray(value: unknown): value is unknown[] {
    return Array.isArray(value)
}

function isObject(value: unknown): value is { [key: string]: unknown } {
    return typeof value === "object" && value !== null
}

function isBoolean(value: unknown): value is boolean {
    return value === true || value === false
}

const typeKey: keyof V69.ArrayValue = "type"
const enabledKey: keyof V69.ArrayValue = "enabled"

function isOldArrayValue(value: unknown): value is V69.ArrayValue {
    if (!isObject(value)) return false
    if (!(typeof value[typeKey] === "string")) return false
    return isBoolean(value[enabledKey])
}

function isOldArrayControlData(value: unknown): value is V69.ArrayValue[] {
    if (!isArray(value)) return false
    return value.every(isOldArrayValue)
}

function migrateCodeComponentProps(
    codeComponentProps: V69.CodeComponentNode["codeComponentProps"],
    id: string
): V70.CodeComponentNode["codeComponentProps"] {
    const copy = { ...codeComponentProps }

    const keys = Object.keys(copy)
    for (const key of keys) {
        const value = copy[key]
        if (!isOldArrayControlData(value)) continue

        const newValue: V70.ArrayValue[] = []
        copy[key] = newValue

        value.forEach((oldArrayValue, idx) => {
            const { enabled, ...arrayValue } = oldArrayValue
            if (!enabled) return
            newValue.push({ ...arrayValue, id: `migrated_array_value_${id}_${key}_${idx}` })
        })
    }

    return copy
}

// Replicas

function isNumber(value: unknown): value is number {
    return typeof value === "number" && Number.isFinite(value)
}

function addToArray<T extends string>(list: T[], newValue: T) {
    if (!list.includes(newValue)) list.push(newValue)
}

function migrateReplicaBlendingMode(
    overrides: Partial<V70.WithBlending>,
    blendingMode: V69.WithBlending["blendingMode"]
) {
    if (blendingMode) overrides.blendingMode = blendingMode
}

function migrateReplicaBorderEnabled(overrides: Partial<V70.WithBorder>, borderEnabled: undefined | PropertyEnabled) {
    switch (borderEnabled) {
        case PropertyEnabled.enabled:
            overrides.borderEnabled = true
            break
        case PropertyEnabled.disabledHidden:
        case PropertyEnabled.disabledVisible:
            overrides.borderEnabled = false
            break
    }
}

function migrateReplicaStrokeEnabled(overrides: Partial<V70.WithStroke>, strokeEnabled: undefined | PropertyEnabled) {
    switch (strokeEnabled) {
        case PropertyEnabled.enabled:
            overrides.strokeEnabled = true
            break
        case PropertyEnabled.disabledHidden:
        case PropertyEnabled.disabledVisible:
            overrides.strokeEnabled = false
            break
    }
}

function migrateReplicaBoxShadow(
    overrides: V70.WithBoxShadow,
    boxShadows: V69.WithBoxShadow["boxShadows"],
    deletedProps: string[]
) {
    if (!boxShadows) {
        return
    }

    const prop: keyof V70.WithBoxShadow = "boxShadows"
    if (deletedProps.includes(prop)) {
        return
    }

    const migratedShadows = migrateBoxShadows(boxShadows)
    if (migratedShadows?.boxShadows) {
        overrides.boxShadows = migratedShadows.boxShadows
    } else {
        addToArray(deletedProps, prop)
    }
}

function migrateReplicaShadow<T extends V70.WithShadow>(
    overrides: Partial<T>,
    shadows: V69.WithShadow["shadows"],
    deletedProps: (string & keyof T)[]
) {
    if (!shadows) {
        return
    }

    const prop: keyof V70.WithShadow = "shadows"
    if (deletedProps.includes(prop)) {
        return
    }

    const migratedShadows = migrateShadows(shadows)
    if (migratedShadows?.shadows) {
        overrides.shadows = migratedShadows.shadows
    } else {
        addToArray(deletedProps, prop)
    }
}

function migrateReplicaBlur<T extends V70.WithBlur>(
    migratedOverrides: Partial<T>,
    originalNode: V69.TreeNode & V69.WithBlur,
    getInheritedValue: GetInheritedPropValue,
    { blur, blurEnabled, blurType }: V69.WithBlur,
    deletedProps: string[]
) {
    const blurKey: keyof V70.WithBlur = "blur"
    const backgroundBlurKey: keyof V70.WithBlur = "backgroundBlur"
    const blurEnabledKey: keyof V69.WithBlur = "blurEnabled"
    const blurTypeKey: keyof V69.WithBlur = "blurType"

    const blurEnabledDeletedIndex = deletedProps.indexOf(blurEnabledKey)
    const blurTypeDeletedIndex = deletedProps.indexOf(blurTypeKey)
    const indexesToRemove = [blurEnabledDeletedIndex, blurTypeDeletedIndex].filter(v => v !== -1)
    if (indexesToRemove.length > 0) {
        // sort in descending order
        indexesToRemove.sort((a, b) => b - a)

        indexesToRemove.forEach(indexToRemove => {
            deletedProps.splice(indexToRemove, 1)
        })

        if (!deletedProps.includes(blurKey)) {
            deletedProps.push(blurKey)
        }
        if (!deletedProps.includes(backgroundBlurKey)) {
            deletedProps.push(backgroundBlurKey)
        }
        return
    }

    const hasOverrides = isPropertyEnabled(blurEnabled) || !!blurType || isNumber(blur)
    if (!hasOverrides) return

    const originalValues: V69.WithBlur = {
        blur: getInheritedValue(originalNode, blurKey),
        blurEnabled: getInheritedValue(originalNode, blurEnabledKey),
        blurType: getInheritedValue(originalNode, blurTypeKey),
    }

    const blurEnabledValue = isPropertyEnabled(blurEnabled) ? blurEnabled : originalValues.blurEnabled
    const blurTypeValue = blurType || originalValues.blurType
    const blurValue = isNumber(blur) ? blur : originalValues.blur

    const shouldEnable = blurEnabledValue === PropertyEnabled.enabled

    switch (blurTypeValue) {
        case "layer":
            if (shouldEnable) {
                migratedOverrides.blur = blurValue
            } else {
                addToArray(deletedProps, "blur")
            }
            addToArray(deletedProps, "backgroundBlur")
            break
        case "background":
            if (shouldEnable) {
                migratedOverrides.backgroundBlur = blurValue
            } else {
                addToArray(deletedProps, "backgroundBlur")
            }
            addToArray(deletedProps, "blur")
    }
}

function migrateReplicaFilters<T extends V70.WithFilters>(
    migratedOverrides: Partial<T>,
    originalNode: V69.TreeNode & V69.WithFilters,
    getInheritedValue: GetInheritedPropValue,
    overrideValues: V69.WithFilters,
    deletedProps: string[]
) {
    function migrate(
        filterValueKey: keyof V69.WithFilters & keyof V70.WithFilters,
        filterEnabledKey: keyof V69.WithFilters
    ) {
        const indexOfEnabledKey = deletedProps.indexOf(filterEnabledKey)
        if (indexOfEnabledKey >= 0) {
            deletedProps.splice(indexOfEnabledKey, 1)
            if (!deletedProps.includes(filterValueKey)) {
                deletedProps.push(filterValueKey)
            }
            return
        }

        const enabled = overrideValues[filterEnabledKey]
        const value = overrideValues[filterValueKey]
        const brightnessIsOverridden = isPropertyEnabled(enabled) || isNumber(value)
        if (!brightnessIsOverridden) return { isOverridden: false }

        const enabledState = isPropertyEnabled(enabled) ? enabled : getInheritedValue(originalNode, filterEnabledKey)
        const valueState = isNumber(value) ? value : getInheritedValue(originalNode, filterValueKey)

        switch (enabledState) {
            case PropertyEnabled.enabled:
                migratedOverrides[filterValueKey] = valueState
                break
            default:
                addToArray(deletedProps, filterValueKey)
                break
        }
    }

    migrate("brightness", "brightnessEnabled")
    migrate("contrast", "contrastEnabled")
    migrate("grayscale", "grayScaleEnabled")
    migrate("hueRotate", "hueRotateEnabled")
    migrate("invert", "invertEnabled")
    migrate("saturate", "saturateEnabled")
    migrate("sepia", "sepiaEnabled")
}

type FrameReplica = ReplicaFromNode<V70.FrameNode>
type CodeComponentReplica = ReplicaFromNode<V70.CodeComponentNode>
type TextReplica = ReplicaFromNode<V70.TextNode>
type SVGReplica = ReplicaFromNode<V70.SVGNode>
type ShapeReplica = ReplicaFromNode<
    | V70.BooleanShapeNode
    | V70.OvalShapeNode
    | V70.PathNode
    | V70.PolygonShapeNode
    | V70.RectangleShapeNode
    | V70.StarShapeNode
>

function replicaMigration(
    originalNode: V69.TreeNode,
    getInheritedValue: GetInheritedPropValue,
    updateOverrides: UpdateOverrides
) {
    switch (originalNode.__class) {
        case "FrameNode": {
            updateOverrides(originalNode, _ as FrameReplica, overrides => {
                const {
                    borderEnabled,
                    boxShadows,
                    blendingMode,
                    blendingEnabled, // eslint-disable-line @typescript-eslint/no-unused-vars
                    blur,
                    blurEnabled,
                    blurType,
                    brightnessEnabled,
                    contrastEnabled,
                    grayScaleEnabled,
                    hueRotateEnabled,
                    invertEnabled,
                    saturateEnabled,
                    sepiaEnabled,
                    brightness,
                    contrast,
                    grayscale,
                    hueRotate,
                    invert,
                    saturate,
                    sepia,
                    _deleted,
                    ...keep
                } = overrides

                const result = keep as FrameReplica
                const deleted = (_deleted || []) as NonNullable<FrameReplica["_deleted"]>

                migrateReplicaBlendingMode(result, blendingMode)
                migrateReplicaBorderEnabled(result, borderEnabled)
                migrateReplicaBoxShadow(result, boxShadows, deleted)

                migrateReplicaBlur(result, originalNode, getInheritedValue, { blur, blurEnabled, blurType }, deleted)

                migrateReplicaFilters(
                    result,
                    originalNode,
                    getInheritedValue,
                    {
                        brightnessEnabled,
                        contrastEnabled,
                        grayScaleEnabled,
                        hueRotateEnabled,
                        invertEnabled,
                        saturateEnabled,
                        sepiaEnabled,
                        brightness,
                        contrast,
                        grayscale,
                        hueRotate,
                        invert,
                        saturate,
                        sepia,
                    },
                    deleted
                )

                if (deleted.length) result._deleted = deleted
                return result
            })
            break
        }
        case "CodeComponentNode": {
            updateOverrides(originalNode, _ as CodeComponentReplica, (overrides, id) => {
                const {
                    borderEnabled,
                    boxShadows,
                    blur,
                    blurEnabled,
                    blurType,
                    brightnessEnabled,
                    contrastEnabled,
                    grayScaleEnabled,
                    hueRotateEnabled,
                    invertEnabled,
                    saturateEnabled,
                    sepiaEnabled,
                    brightness,
                    contrast,
                    grayscale,
                    hueRotate,
                    invert,
                    saturate,
                    sepia,
                    _deleted,
                    ...keep
                } = overrides

                const result = keep as CodeComponentReplica
                const deleted = (_deleted || []) as NonNullable<CodeComponentReplica["_deleted"]>

                if (overrides.codeComponentProps) {
                    result.codeComponentProps = migrateCodeComponentProps(overrides.codeComponentProps, id)
                }
                migrateReplicaBorderEnabled(result, borderEnabled)
                migrateReplicaBoxShadow(result, boxShadows, deleted)

                migrateReplicaBlur(result, originalNode, getInheritedValue, { blur, blurEnabled, blurType }, deleted)

                migrateReplicaFilters(
                    result,
                    originalNode,
                    getInheritedValue,
                    {
                        brightnessEnabled,
                        contrastEnabled,
                        grayScaleEnabled,
                        hueRotateEnabled,
                        invertEnabled,
                        saturateEnabled,
                        sepiaEnabled,
                        brightness,
                        contrast,
                        grayscale,
                        hueRotate,
                        invert,
                        saturate,
                        sepia,
                    },
                    deleted
                )

                if (deleted.length) result._deleted = deleted
                return result
            })
            break
        }
        case "TextNode": {
            updateOverrides(originalNode, _ as TextReplica, overrides => {
                const {
                    shadows,
                    blur,
                    blurEnabled,
                    blurType,
                    brightnessEnabled,
                    contrastEnabled,
                    grayScaleEnabled,
                    hueRotateEnabled,
                    invertEnabled,
                    saturateEnabled,
                    sepiaEnabled,
                    brightness,
                    contrast,
                    grayscale,
                    hueRotate,
                    invert,
                    saturate,
                    sepia,
                    _deleted,
                    ...keep
                } = overrides

                const result = keep as TextReplica
                const deleted = (_deleted || []) as NonNullable<TextReplica["_deleted"]>

                migrateReplicaShadow(result, shadows, deleted)

                migrateReplicaBlur(result, originalNode, getInheritedValue, { blur, blurEnabled, blurType }, deleted)

                migrateReplicaFilters(
                    result,
                    originalNode,
                    getInheritedValue,
                    {
                        brightnessEnabled,
                        contrastEnabled,
                        grayScaleEnabled,
                        hueRotateEnabled,
                        invertEnabled,
                        saturateEnabled,
                        sepiaEnabled,
                        brightness,
                        contrast,
                        grayscale,
                        hueRotate,
                        invert,
                        saturate,
                        sepia,
                    },
                    deleted
                )

                if (deleted.length) result._deleted = deleted
                return result
            })
            break
        }
        case "SVGNode": {
            updateOverrides(originalNode, _ as SVGReplica, overrides => {
                const {
                    shadows,
                    blendingMode,
                    blendingEnabled, // eslint-disable-line @typescript-eslint/no-unused-vars
                    blur,
                    blurEnabled,
                    blurType,
                    brightnessEnabled,
                    contrastEnabled,
                    grayScaleEnabled,
                    hueRotateEnabled,
                    invertEnabled,
                    saturateEnabled,
                    sepiaEnabled,
                    brightness,
                    contrast,
                    grayscale,
                    hueRotate,
                    invert,
                    saturate,
                    sepia,
                    _deleted,
                    ...keep
                } = overrides

                const result = keep as SVGReplica
                const deleted = (_deleted || []) as NonNullable<SVGReplica["_deleted"]>

                migrateReplicaBlendingMode(result, blendingMode)
                migrateReplicaShadow(result, shadows, deleted)

                migrateReplicaBlur(result, originalNode, getInheritedValue, { blur, blurEnabled, blurType }, deleted)

                migrateReplicaFilters(
                    result,
                    originalNode,
                    getInheritedValue,
                    {
                        brightnessEnabled,
                        contrastEnabled,
                        grayScaleEnabled,
                        hueRotateEnabled,
                        invertEnabled,
                        saturateEnabled,
                        sepiaEnabled,
                        brightness,
                        contrast,
                        grayscale,
                        hueRotate,
                        invert,
                        saturate,
                        sepia,
                    },
                    deleted
                )

                if (deleted.length) result._deleted = deleted
                return result
            })
            break
        }
        case "BooleanShapeNode":
        case "OvalShapeNode":
        case "PathNode":
        case "PolygonShapeNode":
        case "RectangleShapeNode":
        case "StarShapeNode": {
            updateOverrides(originalNode, _ as ShapeReplica, overrides => {
                const { boxShadows, strokeEnabled, _deleted, ...keep } = overrides

                const result = keep as ShapeReplica
                const deleted = (_deleted || []) as NonNullable<ShapeReplica["_deleted"]>

                migrateReplicaStrokeEnabled(result, strokeEnabled)
                migrateReplicaBoxShadow(result, boxShadows, deleted)

                if (deleted.length) result._deleted = deleted
                return result
            })
            break
        }
    }
}

export function migration_69_70(documentJSON: V69.Tree): V70.Tree {
    const migratedRoot = transform(documentJSON.root, (node, idToNode) => {
        switch (node.__class) {
            case "FrameNode": {
                const migratedNode = {
                    ...withoutProps(
                        { ...node },
                        "borderEnabled",
                        "boxShadows",
                        "blendingMode",
                        "blendingEnabled",
                        "blur",
                        "blurEnabled",
                        "blurType",
                        "brightnessEnabled",
                        "contrastEnabled",
                        "grayScaleEnabled",
                        "hueRotateEnabled",
                        "invertEnabled",
                        "saturateEnabled",
                        "sepiaEnabled",
                        "brightness",
                        "contrast",
                        "grayscale",
                        "hueRotate",
                        "invert",
                        "saturate",
                        "sepia"
                    ),
                    ...migrateFilterEnabled(node),
                    ...migrateBlur(node),
                    ...migrateBlendMode(node),
                    ...migrateBoxShadows(node.boxShadows),
                    ...migrateBorderEnabled(node),
                }

                transformReplicaOverrides(migratedNode, idToNode, replicaMigration)
                return exactCheck(migratedNode, _ as V70.FrameNode)
            }
            case "CodeComponentNode": {
                const migratedNode = {
                    ...withoutProps(
                        { ...node },
                        "borderEnabled",
                        "boxShadows",
                        "blur",
                        "blurEnabled",
                        "blurType",
                        "brightnessEnabled",
                        "contrastEnabled",
                        "grayScaleEnabled",
                        "hueRotateEnabled",
                        "invertEnabled",
                        "saturateEnabled",
                        "sepiaEnabled",
                        "brightness",
                        "contrast",
                        "grayscale",
                        "hueRotate",
                        "invert",
                        "saturate",
                        "sepia"
                    ),
                    ...migrateFilterEnabled(node),
                    ...migrateBlur(node),
                    ...migrateBoxShadows(node.boxShadows),
                    ...migrateBorderEnabled(node),
                    codeComponentProps: migrateCodeComponentProps(node.codeComponentProps, node.id),
                }

                return exactCheck(migratedNode, _ as V70.CodeComponentNode)
            }
            case "TextNode": {
                const migratedNode = {
                    ...withoutProps(
                        { ...node },
                        "shadows",
                        "blur",
                        "blurEnabled",
                        "blurType",
                        "brightnessEnabled",
                        "contrastEnabled",
                        "grayScaleEnabled",
                        "hueRotateEnabled",
                        "invertEnabled",
                        "saturateEnabled",
                        "sepiaEnabled",
                        "brightness",
                        "contrast",
                        "grayscale",
                        "hueRotate",
                        "invert",
                        "saturate",
                        "sepia"
                    ),
                    ...migrateFilterEnabled(node),
                    ...migrateBlur(node),
                    ...migrateShadows(node.shadows),
                }

                return exactCheck(migratedNode, _ as V70.TextNode)
            }
            case "SVGNode": {
                const migratedNode = {
                    ...withoutProps(
                        { ...node },
                        "shadows",
                        "blendingMode",
                        "blendingEnabled",
                        "blur",
                        "blurEnabled",
                        "blurType",
                        "brightnessEnabled",
                        "contrastEnabled",
                        "grayScaleEnabled",
                        "hueRotateEnabled",
                        "invertEnabled",
                        "saturateEnabled",
                        "sepiaEnabled",
                        "brightness",
                        "contrast",
                        "grayscale",
                        "hueRotate",
                        "invert",
                        "saturate",
                        "sepia"
                    ),
                    ...migrateFilterEnabled(node),
                    ...migrateBlur(node),
                    ...migrateBlendMode(node),
                    ...migrateShadows(node.shadows),
                }

                return exactCheck(migratedNode, _ as V70.SVGNode)
            }
            case "BooleanShapeNode":
            case "OvalShapeNode":
            case "PathNode":
            case "PolygonShapeNode":
            case "RectangleShapeNode":
            case "StarShapeNode": {
                const migratedNode = {
                    ...withoutProps({ ...node }, "boxShadows", "strokeEnabled"),
                    ...migrateBoxShadows(node.boxShadows),
                    ...migrateStrokeEnabled(node),
                }

                return exactCheck(
                    migratedNode,
                    _ as
                        | V70.BooleanShapeNode
                        | V70.OvalShapeNode
                        | V70.PathNode
                        | V70.PolygonShapeNode
                        | V70.RectangleShapeNode
                        | V70.StarShapeNode
                )
            }
            default:
                return exactCheck(node, _ as V70.TreeNode)
        }
    })

    return { version: 70, root: migratedRoot }
}
