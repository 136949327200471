import type { DrawableNode } from "document/models/CanvasTree"

export interface WithOriginalFilename {
    originalFilename: string
}

export const originalFilenameDefaults: WithOriginalFilename = {
    originalFilename: "",
}

const key: keyof WithOriginalFilename = "originalFilename"

export function withOriginalFilename(node: DrawableNode): node is DrawableNode & WithOriginalFilename {
    return key in node
}
