import { V73, V72 } from "../types"

/**
 * Adds the new ReplicaInfo type with the optional `inheritsFrom` property which no documents will have.
 */
export function migration_72_73(documentJSON: V72.Tree): V73.Tree {
    return {
        version: 73,
        root: documentJSON.root,
    }
}
