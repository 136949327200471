import type { AssetSize } from "./types/Asset"
import { isAssetSize } from "./isAssetSize"

type AssetReference = string

// This includes the comma that seperates the mediatype from the data
const mediatype = "framer/asset-reference,"

export function isAssetReference(value: string): boolean {
    return value.startsWith(`data:${mediatype}`)
}

const originalFilenameKey = "originalFilename"
const packageIdentifierKey = "packageIdentifier"
const preferredSizeKey = "preferredSize"

export function createAssetReference(
    identifier: string,
    packageIdentifier: string | null = null,
    originalFilename: string | null = null,
    preferredSize: AssetSize | null = null
): AssetReference {
    if (identifier === "") {
        return ""
    }
    const reference = new URL(`data:${mediatype}${identifier}`)
    if (originalFilename) {
        reference.searchParams.set(originalFilenameKey, originalFilename)
    }
    if (packageIdentifier) {
        reference.searchParams.set(packageIdentifierKey, packageIdentifier)
    }
    if (preferredSize) {
        reference.searchParams.set(preferredSizeKey, preferredSize)
    }
    return reference.href
}

interface AssetReferenceParseResult {
    identifier: string
    originalFilename: string | null
    packageIdentifier: string | null
    preferredSize: AssetSize | null
}

export function parseAssetReference(reference: AssetReference): AssetReferenceParseResult | undefined {
    if (!isAssetReference(reference)) {
        return
    }
    try {
        const url = new URL(reference)
        const identifier = url.pathname.substring(mediatype.length)
        const originalFilename = url.searchParams.get(originalFilenameKey)
        const packageIdentifier = url.searchParams.get(packageIdentifierKey)
        const preferredSize = url.searchParams.get(preferredSizeKey)
        return {
            identifier,
            originalFilename,
            packageIdentifier,
            preferredSize: isAssetSize(preferredSize) ? preferredSize : null,
        }
    } catch {
        return
    }
}
