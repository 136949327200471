import { ServiceDebugging } from "../ServiceDebugging"
import type {
    ServiceStream,
    ServiceStreamOptions,
    ServiceStreamCallback,
    ServiceMessageHelper,
} from "../ServiceDefinition"
import { ServiceError } from "../ServiceErrors"
import { ServiceRuntimePrivate } from "../private"

/**
 * @private Infrastructure that should never be used outside the Framer Services package implementation.
 */
export namespace ServiceStreamsPrivate {
    export function isServiceStreamOptions(body: unknown): body is ServiceStreamOptions {
        if (!body) {
            return false
        }

        switch ((body as ServiceStreamOptions).replay) {
            case "latest":
            case undefined:
                break
            default:
                return false
        }

        return true
    }

    export class StreamReader<T extends object> implements ServiceStream<T> {
        constructor(
            private readonly method: string,
            private readonly options: ServiceStreamOptions | undefined,
            private readonly helper: ServiceMessageHelper
        ) {}

        [Symbol.asyncIterator](): AsyncIterator<T> {
            if (this.options?.oneway) {
                throw new ServiceError.BadRequest(
                    "Cannot read a oneway stream through an AsyncIterator. Use read() with a void callback instead."
                )
            }
            return this.newIterator()
        }

        readonly read = async (callback: ServiceStreamCallback<T>): Promise<void> => {
            const iterator = this.newIterator(callback)

            const { iteration } = this
            if (!iteration) {
                // Iteration context should have been set up with the iterator
                throw new ServiceError.BadRequest("Cannot start reading stream")
            }

            async function iterate() {
                // eslint-disable-next-line no-empty
                while (!(await iterator.next()).done) {}
            }

            void iterate()
            return Promise.race([iteration.isDonePromise, iteration.isCancelledPromise])
        }

        readonly cancel = async (): Promise<void> => {
            const { iteration } = this
            if (!iteration) {
                // Don't allow cancelling the stream before reading started
                throw new ServiceError.BadRequest("Cannot cancel a stream before reading it")
            }

            iteration.isCancelledPromise.resolve()
        }

        private iteration:
            | undefined
            | {
                  isDonePromise: ServiceRuntimePrivate.ResolvablePromise<void>
                  isCancelledPromise: ServiceRuntimePrivate.ResolvablePromise<void>
              }

        private newIterator(callback?: ServiceStreamCallback<T>): AsyncIterator<T> {
            if (this.iteration) {
                throw new ServiceError.BadRequest(
                    "ServiceStream instances can only be read once. If multiple AsyncIterators or read() calls are required, create a new stream for each by calling the associated service method. To broadcast events with an observer pattern, consider using a client-specific EventEmitter or similar."
                )
            }

            const iteration = (this.iteration = {
                isDonePromise: ServiceRuntimePrivate.newResolvablePromise(),
                isCancelledPromise: ServiceRuntimePrivate.newResolvablePromise(),
            })

            const requestReturn = () => {
                this.helper({
                    method: this.method,
                    stream: { id, method: "return" },
                }).catch(error => {
                    // There's not much we can do if the cancel failed on the service side or in transit.
                    log.debug("StreamReader received error trying to cancel iterator on the service side", error)
                })
            }

            // Contrary to what the types say, value = undefined is valid when done = true
            const doneResult: IteratorResult<T> = { done: true, value: (undefined as unknown) as T }

            // Validate and handle next responses
            const oneway = this.options?.oneway ?? false
            const validateNextResult = async (result: unknown) => {
                if (!isIteratorResult<T>(result)) {
                    // FIXME: reject instead of ending the iteration?
                    log.warn("StreamReader.next received an invalid iterator result for next()", result)
                    return doneResult
                }

                // Handle the result
                if (result.value) {
                    // Invoke the stream value callback
                    const promiseOrVoid = callback?.(result.value)

                    if (oneway) {
                        // Asynchronous oneway read callbacks could become implicitly interleaved without extra queueing
                        if (promiseOrVoid !== undefined) {
                            throw new ServiceError.BadRequest(
                                "ServiceStream callbacks cannot be async if oneway = true."
                            )
                        }
                    } else {
                        // Regular read callbacks do finish before requesting/handling the next value
                        await promiseOrVoid
                    }
                }

                return result
            }

            // Communicate cancellation to the service side, but don't make next() wait for it
            const nextCancelledPromise = iteration.isCancelledPromise.then(
                () => {
                    requestReturn()
                    return doneResult
                },
                (error: unknown) => {
                    requestReturn()
                    return Promise.reject(error)
                }
            )

            // The stream id will let the Router on the other end remember the corresponding iterator
            const id = ServiceRuntimePrivate.generateUniqueId()
            const log = ServiceDebugging.log.extend("StreamReader").extend(id)
            return {
                // Note: this uses explicit Promises to work around a strange crash in JavaScriptCore at time of writing
                // See https://github.com/framer/company/issues/13411 for more info
                next: (): Promise<IteratorResult<T>> => {
                    const nextValuePromise = this.helper(
                        {
                            method: this.method,
                            argument: this.options,
                            stream: { id, method: "next" },
                        },
                        validateNextResult
                    ).then(validateNextResult)

                    return Promise.race([
                        nextValuePromise
                            .then(result => {
                                // The next value may "never" resolve, e.g. if an event emitter isn't emitting new events
                                if (result.done) {
                                    iteration.isDonePromise.resolve()
                                }

                                return result
                            })
                            .catch(error => {
                                // Stop iterating if an error occurred
                                iteration.isDonePromise.reject(error)
                                iteration.isCancelledPromise.resolve()
                                return Promise.reject(error)
                            }),
                        nextCancelledPromise,
                    ])
                },
                return: async (): Promise<IteratorResult<T>> => {
                    // We want `next` to resolve with doneResult, and `return` to be sent to the service side
                    iteration.isCancelledPromise.resolve()

                    // The caller of `return` also gets a resolved doneResult
                    return doneResult
                },
                throw: async (error?: any): Promise<IteratorResult<T>> => {
                    // We want `next` to reject with `error`, and `return` to be sent to the service side
                    iteration.isCancelledPromise.reject(error)

                    // The caller of `throw` gets a resolved doneResult instead
                    return doneResult
                },
            }
        }
    }

    function isIteratorResult<T>(result: any): result is IteratorResult<T> {
        if (!result) return false
        return result.done === true || (result.done === false && result.value !== undefined)
    }
}

/** Callback for stream values, optionally preventing standard behavior by returning { ignore: true }. */
export type ServiceStreamValueInterceptor<T> = (value: T) => { ignore: boolean }
