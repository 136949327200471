export interface PackageModuleInfo {
    entityId: string
    exportIdentifier: string
    moduleURL: string
    packageName: string
}

const cdnPrefix = "https://framer.com/m/store"

export function getPackageModuleInfo(identifier: string): PackageModuleInfo | undefined {
    const match = identifier.match(/^(?:((?:@[^/]+\/)[^/]+)\/(?:\.\/)?|\.\/)(.*?)\.[jt]sx?_(.*)$/)
    if (!match) return

    // Example: @framer/framer.pkgName
    let packageName = match[1]
    // Support both ways to refer to a local component.
    if (!packageName || packageName === "|local|") {
        // Make the local identifier URL-friendly.
        packageName = "@framer/local"
    }
    // Example: path/Filename
    const filenameWithoutExtension = match[2]
    // Example: ExportIdentifier
    const exportIdentifier = match[3]
    return {
        entityId: `${packageName}/${filenameWithoutExtension}.js_${exportIdentifier}`,
        exportIdentifier,
        moduleURL: `${cdnPrefix}/${packageName}/${filenameWithoutExtension}.js?${exportIdentifier}`,
        packageName,
    }
}
