import type { CanvasNode } from "../../nodes/CanvasNode"
import type { UnknownProps } from "../utils/collectVariantDefinitionForNode"
import Path from "document/models/Path"
import { withStroke } from "../Stroke"
import { withShape } from "framer"
import { isNumber } from "utils/typeChecks"
import { WithStroke } from "@framerjs/document-migrations/src/types/V70"

export function collectPartialStroke(node: CanvasNode, props: UnknownProps) {
    if (!withStroke(node) || !withShape(node)) return

    const isStrokeEnabled = node.strokeEnabled === true

    props.strokeEnabled = isStrokeEnabled

    if (!isStrokeEnabled) return

    const calculatedPath = node.calculatedPaths()
    const pathClosed = Path.isClosed(calculatedPath)

    props.insideStroke = false

    if (isNumber(node.strokeWidth) && node.strokeWidth > 0) {
        const insideStroke = pathClosed && node.strokeAlignment === "inside"

        props.insideStroke = insideStroke
        props.strokeWidth = insideStroke ? node.strokeWidth * 2 : node.strokeWidth
    }
}

const partiallyCollectedStrokeProps = {
    strokeEnabled: true,
    insideStroke: true,
    strokeWidth: true,
}

export function isPartiallyCollectStrokeProperty(key: string): key is keyof WithStroke {
    return key in partiallyCollectedStrokeProps
}
