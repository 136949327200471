import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useLoop, springAnimation, springAnimationDelay } from "./utils"
import { colors } from "../tokens"

const width = 50
const height = 50

export const AnimationInvite = React.memo(function AnimationInvite() {
    const avatar = useAnimation()
    const link = useAnimation()

    async function animation() {
        await Promise.all([
            avatar.start({
                opacity: 1,
                scale: 0.8,
                top: 10,
                transition: springAnimation,
            }),
            link.start({
                opacity: 0,
                scale: 0,
                transition: springAnimation,
            }),
        ])
        await Promise.all([
            avatar.start({
                opacity: 0,
                scale: 0,
                transition: springAnimationDelay,
            }),
            link.start({
                opacity: 1,
                scale: 0.8,
                transition: springAnimationDelay,
            }),
        ])
        return await Promise.all([
            avatar.start({
                opacity: 1,
                scale: 0.8,
                top: 10,
                transition: springAnimationDelay,
            }),
            link.start({
                opacity: 0,
                scale: 0,
                transition: springAnimationDelay,
            }),
        ])
    }

    useLoop(animation)

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                position: "relative",
                width: width,
                height: height,
                borderRadius: 8,
                background: colors.animationBackgroundLight,
            }}
        >
            <motion.div
                animate={avatar}
                style={{
                    position: "absolute",
                    opacity: 1,
                    top: 10,
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                    <path
                        d="M 15 0 C 23.284 0 30 6.716 30 15 C 30 23.284 23.284 30 15 30 C 6.716 30 0 23.284 0 15 C 0 6.716 6.716 0 15 0 Z"
                        fill={colors.animationForground}
                    ></path>
                    <path
                        d="M 15 7.5 C 17.762 7.5 20 9.739 20 12.501 C 20 15.263 17.761 17.501 14.999 17.501 C 12.237 17.501 9.999 15.262 9.999 12.5 C 9.999 9.738 12.238 7.5 15 7.5 Z M 15 27.5 C 12.084 27.504 9.259 26.485 7.018 24.62 L 7.838 22.433 C 8.387 20.97 9.786 20 11.349 20 L 18.651 20 C 20.214 20 21.613 20.97 22.162 22.433 L 22.982 24.62 C 20.741 26.485 17.916 27.504 15 27.5 Z"
                        fill={colors.animationForgroundDimmed}
                    ></path>
                </svg>
            </motion.div>
            <motion.div animate={link} style={{ position: "absolute", opacity: 1 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28">
                    <g transform="translate(1.6 9) rotate(-45 12.5 5.5)">
                        <path
                            d="M 0 5.869 L 0 5.209 C 0 2.332 2.332 0 5.209 0 L 5.209 0 C 8.086 0 10.418 2.332 10.418 5.209 L 10.418 10.339 C 10.418 12.857 8.378 14.897 5.86 14.897 L 5.86 14.897"
                            transform="translate(12.342 -1.658) rotate(90 5.209 7.449)"
                            fill="transparent"
                            strokeWidth="3"
                            stroke={colors.animationForground}
                            strokeLinecap="round"
                        ></path>
                        <path
                            d="M 0 5.869 L 0 5.209 C 0 2.332 2.332 0 5.209 0 L 5.209 0 C 8.086 0 10.418 2.332 10.418 5.209 L 10.418 10.339 C 10.418 12.857 8.378 14.897 5.86 14.897 L 5.86 14.897"
                            transform="translate(2.239 -2.239) rotate(-90 5.209 7.449)"
                            fill="transparent"
                            strokeWidth="3"
                            stroke={colors.animationForground}
                            strokeLinecap="round"
                        ></path>
                    </g>
                </svg>
            </motion.div>
        </div>
    )
})
