import { isValidPropertyValueType } from "document/components/chrome/properties/codeComponentRows/utils/isValidPropertyValue"
import type { ControlProp } from "./CanvasTree/traits/CodeComponent"

export interface EventAction {
    readonly identifier: string
    readonly actionIdentifier: string
    readonly controls: Readonly<{ [key: string]: ControlProp }>
}

const keys: (keyof EventAction)[] = ["actionIdentifier", "identifier", "controls"]

export function isEventAction(value: any): value is EventAction {
    if (!value) return false
    if (typeof value !== "object") return false
    return keys.every(key => key in value)
}

export function controlValuesFromEventAction(eventAction: EventAction) {
    const result: { [key: string]: any } = {}

    const controlKeys = Object.keys(eventAction.controls)
    for (let i = 0, il = controlKeys.length; i < il; i++) {
        const key = controlKeys[i]
        const control = eventAction.controls[key]
        if (!isValidPropertyValueType(control.type, control.value)) continue
        result[key] = control.value
    }

    return result
}
