import type { CanvasNode } from "document/models/CanvasTree"

export interface WithRadiusPerCorner {
    radiusPerCorner: boolean
    radiusTopLeft: number
    radiusTopRight: number
    radiusBottomLeft: number
    radiusBottomRight: number
}

const radiusDefaults = {
    radiusTopLeft: 0,
    radiusTopRight: 0,
    radiusBottomRight: 0,
    radiusBottomLeft: 0,
}
export const radiusPerCornerDefaults: WithRadiusPerCorner = {
    radiusPerCorner: false,
    ...radiusDefaults,
}

export const radiusPerCornerKeys = Object.keys(radiusDefaults)

const key: keyof WithRadiusPerCorner = "radiusPerCorner"

export function withRadiusPerCorner(node: CanvasNode): node is CanvasNode & WithRadiusPerCorner {
    return key in node
}

export function reduceRadiusPerCorner(
    node: CanvasNode,
    result: {
        radiusPerCorner?: boolean | null
        radiusTopLeft?: number | null
        radiusTopRight?: number | null
        radiusBottomLeft?: number | null
        radiusBottomRight?: number | null
    }
) {
    if (!withRadiusPerCorner(node)) {
        return
    }
    if (result.radiusPerCorner === undefined) {
        result.radiusPerCorner = node.radiusPerCorner
        result.radiusTopLeft = node.radiusTopLeft
        result.radiusTopRight = node.radiusTopRight
        result.radiusBottomLeft = node.radiusBottomLeft
        result.radiusBottomRight = node.radiusBottomRight
    } else {
        if (result.radiusPerCorner !== null && result.radiusPerCorner !== node.radiusPerCorner) {
            result.radiusPerCorner = null
        }
        if (result.radiusTopLeft !== null && result.radiusTopLeft !== node.radiusTopLeft) result.radiusTopLeft = null
        if (result.radiusTopRight !== null && result.radiusTopRight !== node.radiusTopRight) {
            result.radiusTopRight = null
        }
        if (result.radiusBottomLeft !== null && result.radiusBottomLeft !== node.radiusBottomLeft) {
            result.radiusBottomLeft = null
        }
        if (result.radiusBottomRight !== null && result.radiusBottomRight !== node.radiusBottomRight) {
            result.radiusBottomRight = null
        }
    }
}
