import { List } from "immutable"
import type { CanvasNode } from "document/models/CanvasTree"
import type { ExportOptions } from "document/models/CanvasTree/nodes/ExportOptions"

export type ExportType = "png" | "jpg" | "html" | "pdf" | "webp" | "svg"
export type ExportScale = 0.5 | 1 | 1.5 | 2 | 3 | 4
export type ExportExtensionMode = "Suffix" | "Prefix" | "Folder"

export interface Export {
    id: string
    enabled: boolean
    type: ExportType
    scale: ExportScale
    nameExtension: string
    nameExtensionMode: ExportExtensionMode
}

export interface WithExport {
    exportOptions: List<ExportOptions>
}

export interface WithExportIncludesBackground extends WithExport {
    exportIncludesBackground: boolean
}

export const withExportDefaults = {
    exportOptions: List<ExportOptions>([]),
}

export const withExportIncludesBackgroundDefaults = {
    exportIncludesBackground: false,
}

const exportKey: keyof WithExport = "exportOptions"

export function withExport(node: CanvasNode): node is CanvasNode & WithExport {
    return exportKey in node
}

const includeBackgroundKey: keyof WithExportIncludesBackground = "exportIncludesBackground"

export function withExportIncludesBackground(node: CanvasNode): node is CanvasNode & WithExportIncludesBackground {
    return includeBackgroundKey in node
}

export interface ExportOptionRow {
    id: string
    uid: string
    idOccurrence: number
    enabled: boolean | null
    type: ExportType
    scale: ExportScale
    nameExtension: string | null
    nameExtensionMode: ExportExtensionMode
}
