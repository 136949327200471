import * as React from "react"
import { cx } from "linaria"
import { isLeftMouseClick } from "./utils/isLeftMouseClick"
import type { OverrideType, HTMLDivAttributes } from "./types"
import * as styles from "./MouseTracker.styles"
import { frescoSettingsContext } from "./FrescoSettings"

interface Point {
    x: number
    y: number
}

export interface MouseTrackerEvent {
    /** The client point. */
    client: Point
    /** The drag offset since drag start. */
    offset: Point
    /** The relative point inside the mouse tracker element. Ranging from zero to one. */
    progress: Point
    /** Returns true if the shift key was down. */
    shiftKey: boolean
}

export type MouseTrackerProps = OverrideType<
    Omit<HTMLDivAttributes, "onMouseDown">,
    {
        /**
         * Called on drag start and while dragging.
         * @param event - {@link MouseTrackerEvent}.
         */
        onDrag: (event: MouseTrackerEvent) => void
        /**
         * Called on drag start.
         * @param event - {@link MouseTrackerEvent}.
         */
        onDragStart?: (event: MouseTrackerEvent) => void
        /**
         * Called on drag end.
         * @param event - {@link MouseTrackerEvent}.
         */
        onDragEnd?: (event: MouseTrackerEvent) => void
        /** Allows overriding the cursor. */
        cursor?: string
        ref?: React.RefObject<HTMLDivElement>
    }
>

export const MouseTracker = React.forwardRef<HTMLDivElement, MouseTrackerProps>(function MouseTracker(
    props,
    forwardedRef: React.Ref<HTMLDivElement>
) {
    const { onDrag, onDragStart, onDragEnd, children, className, cursor, ...rest } = props

    const fallbackRef = React.useRef<HTMLDivElement>(null)
    const ref = forwardedRef || fallbackRef

    const latestEvents = { onDrag, onDragEnd, onDragStart }
    const latestEventsRef = React.useRef(latestEvents)
    latestEventsRef.current = latestEvents

    const didMovePastTresholdRef = React.useRef(false)

    const lastEventData = React.useRef<MouseTrackerEvent | null>(null)

    const [startPoint, setStartPoint] = React.useState<Point | null>(null)

    const frescoSettings = React.useContext(frescoSettingsContext)

    const [, forceUpdate] = React.useState(0)

    const startTracking = React.useCallback(
        (event: React.MouseEvent) => {
            if (!isLeftMouseClick(event)) return
            didMovePastTresholdRef.current = false

            const point = { x: event.clientX, y: event.clientY }
            const eventData = getMouseTrackerEvent(event.nativeEvent, ref as React.RefObject<HTMLDivElement>, point)
            lastEventData.current = eventData

            if (!point || !eventData) return

            if (frescoSettings.mouseTrackerWillStart) {
                frescoSettings.mouseTrackerWillStart()
            }

            if (onDragStart) {
                onDragStart(eventData)
            }

            onDrag(eventData)
            setStartPoint(point)
        },
        [ref, frescoSettings, onDragStart, onDrag]
    )

    // useLayoutEffect makes sure the effect is fired immediately after DOM changes
    // Fix: when rapidly clicking on the number ticker, lastEventData.current can still be null and passed into the handler
    React.useLayoutEffect(() => {
        if (!startPoint) return

        // stopTracking is called by our eventhandlers, we need to know that in cleanup
        let stopTrackingCalled = false
        const stopTracking = () => {
            stopTrackingCalled = true
            setStartPoint(null)
        }

        const stopOnEscape = (event: KeyboardEvent) => {
            if (event.keyCode === 27) {
                stopTracking()
            }
        }

        const handleMouseMove = (event: MouseEvent) => {
            const eventData = getMouseTrackerEvent(event, ref as React.RefObject<HTMLDivElement>, startPoint)
            event.preventDefault()

            if (!eventData) {
                setStartPoint(null)
                return
            }

            if (!didMovePastTresholdRef.current) {
                const movedPassedThreshold = Math.abs(eventData.offset.x) > 1 || Math.abs(eventData.offset.y) > 1
                didMovePastTresholdRef.current = movedPassedThreshold
                // We force update because we don't want didMovePastThreshold to become a dependency of this effect
                // We force a re-render in order to display the mouse tracker overlay
                forceUpdate(i => i + 1)
            }

            lastEventData.current = eventData
            latestEventsRef.current.onDrag(eventData)
        }

        window.addEventListener("mousemove", handleMouseMove)
        // Evoke mouseup callback at capturing phase to prevent the mouse tracker from dismissing canvas popovers
        window.addEventListener("mouseup", stopTracking, true)
        window.addEventListener("contextmenu", stopTracking)
        window.addEventListener("keydown", stopOnEscape)
        window.addEventListener("keyup", stopOnEscape)

        return () => {
            window.removeEventListener("mousemove", handleMouseMove)
            window.removeEventListener("mouseup", stopTracking, true)
            window.removeEventListener("contextmenu", stopTracking)
            window.removeEventListener("keydown", stopOnEscape)
            window.removeEventListener("keyup", stopOnEscape)

            const lastEvent = lastEventData.current as MouseTrackerEvent

            if (stopTrackingCalled) {
                latestEventsRef.current.onDragEnd?.(lastEvent)
                frescoSettings.mouseTrackerDidEnd?.()
            } else {
                // When this is called via another other cleanup path, we cannot just dispatch event-like things.
                queueMicrotask(() => {
                    latestEventsRef.current.onDragEnd?.(lastEvent)
                    frescoSettings.mouseTrackerDidEnd?.()
                })
            }

            lastEventData.current = null
            didMovePastTresholdRef.current = false
        }
    }, [startPoint, ref, frescoSettings])

    return (
        <div
            ref={ref}
            draggable={false} // Makes sure draggable ancestors can't start dragging from within the mouse tracker
            className={cx(className, styles.mouseTrackerDragging)}
            {...rest}
            onMouseDown={startTracking}
        >
            {children}
            {startPoint && didMovePastTresholdRef.current && (
                <div style={cursor ? { cursor } : undefined} className={styles.mouseTrackerOverlay} />
            )}
        </div>
    )
})

function getMouseTrackerEvent(
    event: MouseEvent,
    ref: React.RefObject<HTMLDivElement>,
    startPoint: Point
): MouseTrackerEvent | null {
    const element = ref.current
    if (!element) return null

    const { clientX, clientY } = event
    const client = { x: clientX, y: clientY }
    const offset = { x: clientX - startPoint.x, y: clientY - startPoint.y }

    const clientRect = element.getBoundingClientRect()
    let left = clientX - clientRect.left
    let top = clientY - clientRect.top
    const containerWidth = element.clientWidth || 1 // Prevent dividing by zero
    const containerHeight = element.clientHeight || 1
    left = Math.max(0, Math.min(left, containerWidth)) // Limit relative point
    top = Math.max(0, Math.min(top, containerHeight))
    const progress = { x: left / containerWidth, y: top / containerHeight }

    return { client, offset, progress, shiftKey: event.shiftKey }
}
