import { addPropertyControls, ControlType } from "framer"
import * as React from "react"
import { Device, getComponentSize } from "./Device"
import { convertPropsToDeviceOptions, defaultProps, DeviceCodeComponentProps } from "./DeviceCodeComponentProps"
import { DevicePresetID, deviceCodeComponentPresets, getDevicePreset } from "./presets"

// This is the "code component" part of the Device Component, i.e., the one that
// we register with the component loader, and expose its props to the public as
// property controls. Because of the latter, we need to be a bit more careful
// about changing its props.
//
// Note that this module depends on the library, while the "plain" one doesn't.

export function DeviceCodeComponent({ width, height, children, ...props }: DeviceCodeComponentProps) {
    const deviceOptions = convertPropsToDeviceOptions(props, { forceOldClay: true })

    const child = children && children[0]
    const resizedChild =
        child && React.isValidElement(child)
            ? React.cloneElement(child, {
                  width: deviceOptions.screenWidth,
                  height: deviceOptions.screenHeight,
              })
            : null

    return (
        <Device scaleTo={{ width, height }} deviceOptions={deviceOptions}>
            {resizedChild}
        </Device>
    )
}

const { componentWidth: defaultWidth, componentHeight: defaultHeight } = getComponentSize(
    convertPropsToDeviceOptions(defaultProps, { forceOldClay: true })
)
DeviceCodeComponent.defaultProps = {
    width: defaultWidth,
    height: defaultHeight,
    ...defaultProps,
}

addPropertyControls(DeviceCodeComponent, {
    preset: {
        type: ControlType.Enum,
        options: ["custom"].concat(deviceCodeComponentPresets.map(preset => preset.id)),
        optionTitles: ["Custom"].concat(deviceCodeComponentPresets.map(preset => preset.title)),
    },
    customWidth: {
        title: "Width",
        type: ControlType.Number,
        min: 0,
        displayStepper: true,
        hidden: props => props.preset !== "custom",
    },
    customHeight: {
        title: "Height",
        type: ControlType.Number,
        min: 0,
        displayStepper: true,
        hidden: props => props.preset !== "custom",
    },
    customBezel: {
        title: "Bezel",
        type: ControlType.FusedNumber,
        min: 0,
        toggleKey: "isMixedBezel",
        toggleTitles: ["a", "b"],
        valueKeys: ["bezelTop", "bezelRight", "bezelBottom", "bezelLeft"],
        valueLabels: ["T", "R", "B", "L"],
        hidden: props => props.preset !== "custom",
    },
    customBezelRadius: {
        title: "Bezel Radius",
        type: ControlType.Number,
        min: 0,
        displayStepper: true,
        hidden: props => props.preset !== "custom",
    },
    customScreenRadius: {
        title: "Screen Radius",
        type: ControlType.Number,
        min: 0,
        displayStepper: true,
        hidden: props => props.preset !== "custom",
    },
    orientation: {
        type: ControlType.Enum,
        displaySegmentedControl: true,
        options: ["portrait", "landscape"],
        optionTitles: ["Portrait", "Landscape"],
        hidden: props => !!props.preset && !supportsOrientation(props.preset),
    },
    skin: {
        type: ControlType.Enum,
        displaySegmentedControl: true,
        options: ["realistic", "clay"],
        optionTitles: ["Realistic", "Clay"],
        defaultValue: "clay",
        hidden: () => true,
    },
    theme: {
        type: ControlType.Enum,
        displaySegmentedControl: true,
        options: ["light", "dark"],
        optionTitles: ["Light", "Dark"],
        hidden: props => !!props.preset && !supportsThemes(props.preset, props.skin),
    },
    shadow: {
        type: ControlType.Boolean,
        enabledTitle: "On",
        disabledTitle: "Off",
    },
    backgroundColor: {
        type: ControlType.Color,
        title: "Background",
    },
    children: {
        title: "Content",
        type: ControlType.ComponentInstance,
    },
})

function supportsOrientation(presetId: DevicePresetID | "custom"): boolean {
    if (presetId === "custom") return true

    const preset = getDevicePreset(presetId)
    return !preset.disableRotation
}

function supportsThemes(presetId: DevicePresetID | "custom", skin: "realistic" | "clay" | undefined): boolean {
    if (presetId === "custom" || skin !== "realistic") return true

    const preset = getDevicePreset(presetId)
    // Note: if there's no realistic image, Device Component will fall back to
    // the clay appearance, which supports themes.
    return preset.realisticImage === undefined
}
