import type { CanvasNode } from "document/models/CanvasTree"

export interface WithScreen {
    isScreen?: boolean
}

export interface IsScreen {
    isScreen: true
}

export const screenFrameDefaults: WithScreen = {
    isScreen: undefined,
}

const key: keyof WithScreen = "isScreen"

export function withScreen(node: CanvasNode): node is CanvasNode & WithScreen {
    return key in node
}

export function isScreen(node: CanvasNode): node is CanvasNode & IsScreen {
    return withScreen(node) && node.isScreen === true
}
