import type { MotionStyle } from "framer-motion"
import type { CanvasNode } from "../../nodes/CanvasNode"
import { collectRadius } from "./collectRadius"
import { collectFilters } from "./collectFilters"
import { collectBackgroundFilters } from "./collectBackgroundFilters"
import { collectBlending } from "./collectBlending"
import { collectOpacity } from "./collectOpacity"
import { collectOverflow } from "./collectOverflow"
import { collectBoxShadow } from "./collectBoxShadow"
import { collectTextShadow } from "./collectTextShadow"
import { collectBackgroundFill } from "./collectBackgroundFill"
import { collectBorder, collectBorderVariables } from "./collectBorder"
import { collectRotate } from "./collectRotate"
import { collectLayout } from "./collectLayout"
import { collectCursor } from "./collectCursor"
import { collectPadding } from "./collectPadding"

export {
    collectRadius,
    collectFilters,
    collectBackgroundFilters,
    collectBlending,
    collectOpacity,
    collectOverflow,
    collectBoxShadow,
    collectTextShadow,
    collectBorder,
    collectBorderVariables,
    collectRotate,
    collectLayout,
    collectBackgroundFill,
    collectCursor,
    collectPadding,
}

export interface CollectTraitOptions {
    ignoreDefaults?: boolean
    withInlineVariables?: boolean
    unset?: boolean
}

export function collectStyle(
    node: CanvasNode,
    style: MotionStyle,
    options: CollectTraitOptions = {},
    includeOpacityAndRotation: boolean = true
) {
    collectRadius(node, style, options)
    collectFilters(node, style, options)
    collectBlending(node, style, options)
    collectBackgroundFilters(node, style)
    collectOverflow(node, style)
    collectBoxShadow(node, style)
    collectTextShadow(node, style)
    collectBackgroundFill(node, style, options)
    if (includeOpacityAndRotation) {
        collectOpacity(node, style, options)
        collectRotate(node, style, options)
    }
}
