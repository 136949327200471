import { assertNever, getServiceMap } from "@framerjs/shared"
import { environment } from "environment"
import { RenderTarget } from "framer"

/**
 * Defines a preview configuration being used. This includes the data source,
 * the communication channel and renderer as well as specific features enabled
 * in that environment.
 *  - "preview-web" In-app preview on web
 *  - "preview-studio" In-app preview on desktop
 *  - "play-web" Open in new tab preview on web
 *  - "play-studio" Open in new tab preview on desktop
 *  - "insert-menu" Component preview in the insert menu
 *  - "prototype" Prototype view on web
 *  - "export" Export to HTML from desktop
 */
export type PreviewTarget =
    | "preview-web"
    | "preview-studio"
    | "play-web"
    | "play-studio"
    | "insert-menu"
    | "prototype"
    | "export"

export type AssetResolverType = "studio" | "web"

/**
 * Defines the React component used to fetch and render the preview sandbox
 * - "standalone" Fetches a static version of the project assets.
 * - "local" Uses the PreviewDataSource + PreviewSettings service.
 * - "remote" Uses the web "live" preview socket.
 */
export type PreviewWrapperType = "standalone" | "local" | "remote"

interface PreviewConfig {
    assetResolverType: AssetResolverType
    // Channel on which we'll register PreviewWrapper and PreviewSandbox
    // services, allowing the other side to control the preview (e.g., clear
    // the console or reload) and/or access its state streams.
    controlChannel: "none" | "parent" | "opener" | "both"
    forceDarkMode: boolean
    enableInteractionHighlights: boolean
    enableInteractionTracking: boolean
    useAssetsService: boolean
    usePreviewDesktopService: boolean
    renderTarget: RenderTarget
    sandboxURL: string
    wrapper:
        | {
              type: "opener"
              // ServiceChannel from which the OpenerPreviewWrapper will source
              // preview data.
              sourceChannel: "parent" | "opener" | "socket"
          }
        | { type: "socket" }
        | { type: "standalone" }
        | { type: "export" }
}

let assetResolverType: "studio" | "web"
try {
    getServiceMap()
    assetResolverType = "web"
} catch {
    assetResolverType = "studio"
}

const baseConfig: Omit<PreviewConfig, "sandboxURL" | "wrapper"> = {
    assetResolverType,
    controlChannel: "none",
    forceDarkMode: false,
    enableInteractionHighlights: false,
    enableInteractionTracking: false,
    useAssetsService: false,
    usePreviewDesktopService: false,
    renderTarget: RenderTarget.preview,
}

export function configForTarget(target: PreviewTarget): PreviewConfig {
    switch (target) {
        case "preview-web":
            return {
                ...baseConfig,
                enableInteractionHighlights: true,
                useAssetsService: true,
                sandboxURL: getSandboxURL({ target }).href,
                controlChannel: "parent",
                wrapper: { type: "opener", sourceChannel: "parent" },
            }
        case "preview-studio": {
            return {
                ...baseConfig,
                forceDarkMode: true,
                usePreviewDesktopService: true,
                sandboxURL: getSandboxURL({
                    target,
                    // In Vekter make dev mode, we want to use preview with
                    // built-in library to allow live-reloading library changes.
                    htmlEntrypoint: environment.isDevMode ? "preview-next" : "preview-next-external-library",
                }).href,
                controlChannel: "both",
                wrapper: { type: "opener", sourceChannel: "opener" },
            }
        }
        case "play-web": {
            const currentURL = new URL(window.location.href)
            const useSocketWrapper = currentURL.searchParams.has("socketURL")
            return {
                ...baseConfig,
                useAssetsService: true,
                enableInteractionHighlights: true,
                sandboxURL: getSandboxURL({ target }).href,
                wrapper: useSocketWrapper ? { type: "socket" } : { type: "opener", sourceChannel: "opener" },
            }
        }
        case "play-studio": {
            return {
                ...baseConfig,
                assetResolverType: "studio",
                sandboxURL: getSandboxURL({ target, htmlEntrypoint: "preview-next-external-library" }).href,
                wrapper: { type: "opener", sourceChannel: "socket" },
            }
        }
        case "insert-menu": {
            const isWeb = assetResolverType === "web"
            return {
                ...baseConfig,
                // NOTE: We use "platform" here to differentiate between
                // studio & web because the insert menu is always used within
                // Vekter. For the preview & play configurations they can
                // be loaded outside of the Framer Studio app and the user
                // agent sniffing breaks. So in these cases we explictly
                // provide the platform in the config name.
                useAssetsService: isWeb,
                renderTarget: RenderTarget.thumbnail,
                sandboxURL: getSandboxURL({ target, htmlEntrypoint: "preview-next-prod-react" }).href,
                wrapper: { type: "opener", sourceChannel: "parent" },
            }
        }
        case "prototype":
            return {
                ...baseConfig,
                enableInteractionTracking: true,
                enableInteractionHighlights: true,
                useAssetsService: true,
                sandboxURL: getSandboxURL({ target, htmlEntrypoint: "preview-next-prod-react" }).href,
                controlChannel: "parent",
                wrapper: { type: "standalone" },
            }
        case "export":
            return {
                ...baseConfig,
                assetResolverType: "studio",
                sandboxURL: getSandboxURLForExport().href,
                wrapper: { type: "export" },
            }
        default:
            return assertNever(target)
    }
}

function getSandboxURL({
    target,
    htmlEntrypoint = "preview-next",
}: {
    target: PreviewTarget
    htmlEntrypoint?: "preview-next" | "preview-next-external-library" | "preview-next-prod-react"
}): URL {
    const currentURL = new URL(window.location.href)
    const isDebugBuild = environment.isDebugBuild

    const sandboxURL = new URL(currentURL.href)
    const ext = isDebugBuild ? ".debug.html" : ".html"

    if (isDebugBuild && htmlEntrypoint === "preview-next-prod-react") {
        // For debug builds, downgrade preview-next with production React to
        // preview-next with development React, i.e., preview-next.debug.html.
        htmlEntrypoint = "preview-next"
    }

    if (target === "play-studio") {
        sandboxURL.pathname = "/preview/" + htmlEntrypoint + ext
        sandboxURL.searchParams.set("target", "play-studio")
        sandboxURL.searchParams.set("imageBaseURL", "")
    } else {
        sandboxURL.pathname = sandboxURL.pathname.replace("/preview-next-wrapper", "/" + htmlEntrypoint)
    }

    return sandboxURL
}

function getSandboxURLForExport(): URL {
    const url = new URL("internal.html", window.location.href)
    url.searchParams.append("target", "export")
    url.searchParams.append("libraryURL", "node_modules/framer/build/framer.js")
    url.searchParams.append("imageBaseURL", ".")
    return url
}
