import * as React from "react"
import type { RefObject } from "react"

export interface TextInputOptions<T extends HTMLInputElement | HTMLTextAreaElement> {
    ref: React.Ref<T>
    value: string
    enabled?: boolean
    autoFocus?: boolean
    constantChange?: boolean
    stopUpDownKeyPropagation?: boolean
    stopUpDownKeyHandling?: boolean
    onChange: (value: string, final: boolean, reset: () => void) => void
    onBlur?: (event: React.FocusEvent<T>) => void
    onFocus?: (event: React.FocusEvent<T>) => void
    onKeyDown?: (event: React.KeyboardEvent<T>) => void
}

export interface TextInputBindings<T extends HTMLInputElement | HTMLTextAreaElement> {
    ref: React.Ref<T>
    internalValue: string
    changeHandler: (event: React.ChangeEvent<T>) => void
    blurHandler: (event: React.FocusEvent<T>) => void
    focusHandler: (event: React.FocusEvent<T>) => void
    keyDownHandler: (event: React.KeyboardEvent<T>) => void
}

export function useTextInput<T extends HTMLInputElement | HTMLTextAreaElement>({
    ref: forwardedRef,
    value,
    enabled = true,
    autoFocus,
    constantChange,
    stopUpDownKeyPropagation = true,
    stopUpDownKeyHandling = true,
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
}: TextInputOptions<T>): TextInputBindings<T> {
    const fallbackRef = React.useRef<T | null>(null)
    const ref = (forwardedRef || fallbackRef) as RefObject<T>

    const [internalValue, setInternalValue] = React.useState(value)
    const isCancelledRef = React.useRef(false)

    // When the external value changes, update the internal state
    React.useLayoutEffect(() => {
        setInternalValue(value)
    }, [value])

    // When the input gets enabled with autofocus, focus the element
    React.useEffect(() => {
        if (!enabled || !autoFocus) return
        const element = ref.current
        if (!element) return

        // Perform after timeout to prevent the <Keyboard> from stealing focus
        setTimeout(() => element.focus(), 0)
    }, [enabled, autoFocus, ref])

    const resetHandler = React.useCallback(() => {
        setInternalValue(value)
    }, [value])

    const changeHandler = React.useCallback(
        (event: React.ChangeEvent<T>) => {
            const element = event.nativeEvent.target as T
            const newValue = element.value
            setInternalValue(newValue)
            if (constantChange) onChange(newValue, false, resetHandler)
        },
        [onChange, resetHandler, constantChange]
    )

    const blurHandler = React.useCallback(
        (event: React.FocusEvent<T>) => {
            const isCancelled = isCancelledRef.current

            if (isCancelled) {
                resetHandler()
            } else {
                onChange(internalValue, true, resetHandler)
            }

            /**
             * @FIXME: this is a hack to fix an issue in Chrome: https://github.com/framer/company/issues/14352
             * Basically all our elements in the app are applied with "user-select: none",
             * which somehow makes the disabled input keep the text selection on blur.
             * So here onblur we get the selection in the document, and double confirm is it from our input,
             * if yes then clear the text selection.
             * This is ugly, but couldn't find any other way around.
             */
            const selection = document.getSelection()
            const inputEl = ref.current
            if (selection && inputEl && selection.anchorNode && selection.anchorNode.firstChild === inputEl) {
                selection.empty()
            }

            if (onBlur) onBlur(event)
        },
        [onBlur, ref, resetHandler, onChange, internalValue]
    )

    const focusHandler = React.useCallback(
        (event: React.FocusEvent<T>) => {
            const element = event.nativeEvent.target as T
            // Add a short delay so the text keeps being selected in Safari Webkit
            setTimeout(function () {
                if (document.activeElement === element) {
                    element.select()
                }
            }, 0)
            isCancelledRef.current = false

            onFocus && onFocus(event)
        },
        [onFocus]
    )

    const keyDownHandler = React.useCallback(
        (event: React.KeyboardEvent<T>) => {
            const element = event.currentTarget
            const selectionLength = (element.selectionEnd || 0) - (element.selectionStart || 0)
            const noSelection = selectionLength === 0

            switch (event.keyCode) {
                case 38: // up
                case 40: // down
                    if (stopUpDownKeyPropagation) event.stopPropagation()
                    if (stopUpDownKeyHandling) event.preventDefault()
                    break
                case 37: // left
                    if (element.selectionStart === 0 && noSelection) {
                        event.preventDefault()
                    }
                    event.stopPropagation()
                    break
                case 39: // right
                    if (element.value.length === element.selectionEnd && noSelection) {
                        event.preventDefault()
                    }
                    event.stopPropagation()
                    break
                case 13: // enter
                    onChange(element.value, true, resetHandler)
                    break
                case 27: // escape
                    setInternalValue(value)
                    isCancelledRef.current = true
                    element.value = value
                    element.blur()
                    event.preventDefault()
                    break
            }

            onKeyDown && onKeyDown(event)
        },
        [onKeyDown, stopUpDownKeyPropagation, stopUpDownKeyHandling, onChange, resetHandler, value]
    )

    return { ref, internalValue, changeHandler, blurHandler, focusHandler, keyDownHandler }
}
