import type { Variables, VariableID, VariableValueMap, Variable } from "../traits/Variables"
import type { NodeID } from "./NodeID"
import type { ScopeNode } from "./ScopeNode"
import { CanvasNodeCache } from "./CanvasNodeCache"
import { getUsedVariableReferenceIds } from "../utils/usedVariableReferences"
import { isShallowEqual } from "utils/isShallowEqual"
import { getChangedVariableIds } from "./utils/getChangedVariableIds"

type VariableDependencies = Map<VariableID, NodeID[]>

function getVariableDependencies(scope: ScopeNode): VariableDependencies {
    const dependencies: VariableDependencies = new Map()

    scope.walk(descendant => {
        getUsedVariableReferenceIds(descendant).forEach(id => {
            const nodeIds = dependencies.get(id) ?? []
            nodeIds.push(descendant.id)
            dependencies.set(id, nodeIds)
        })
    })

    return dependencies
}

function getUniqueDependentNodes(scope: ScopeNode, updatedVariables: VariableID[]): NodeID[] {
    const dependencies = getVariableDependencies(scope)
    const uniqueDependentNodes: Set<NodeID> = new Set()

    updatedVariables.forEach(updatedVariable => {
        const dependentNodes = dependencies.get(updatedVariable)
        dependentNodes?.forEach(dependentNode => uniqueDependentNodes.add(dependentNode))
    })

    return Array.from(uniqueDependentNodes)
}

export class CanvasComponentNodeCache extends CanvasNodeCache {
    private variables?: Variables

    private variableValueMap: VariableValueMap | undefined

    private nodesUsingVariables: {
        updatedVariables: VariableID[]
        nodes: NodeID[]
    } | null = null

    getChangedVariableIds(
        variables: Variables
    ): {
        updated: VariableID[]
        deleted: VariableID[]
        deletedValues: Map<VariableID, Variable["initialValue"]>
    } | null {
        if (this.variables === variables) {
            // The dependent nodes are expensive to calculate
            // We keep the cache around until anything changes that's not variables
            this.nodesUsingVariables = null
            return null
        }

        const { updated, deleted } = getChangedVariableIds(this.variables, variables)
        const deletedValues = new Map<string, Variable["initialValue"]>()

        if (this.variables) {
            for (const variable of this.variables) {
                if (deleted.includes(variable.id)) {
                    deletedValues.set(variable.id, variable.initialValue)
                }
            }
        }
        this.variables = variables
        return { updated, deleted, deletedValues }
    }

    getVariableValueMap(variables: Variables): VariableValueMap {
        return this.variableValueMap ?? this.updateVariableValueMap(variables)
    }

    updateVariableValueMap(variables: Variables): VariableValueMap {
        if (!this.variableValueMap) {
            this.variableValueMap = new Map()
        }
        this.variableValueMap.clear()
        for (const variable of variables) {
            this.variableValueMap.set(variable.id, variable.initialValue)
        }
        return this.variableValueMap
    }

    // Last result is cached by comparing updated variable ids
    getNodesUsingVariables(scope: ScopeNode, updatedVariables: VariableID[]): NodeID[] {
        if (this.nodesUsingVariables) {
            if (isShallowEqual(updatedVariables, this.nodesUsingVariables.updatedVariables)) {
                return this.nodesUsingVariables.nodes
            }
        }

        const nodes = getUniqueDependentNodes(scope, updatedVariables)
        this.nodesUsingVariables = { updatedVariables, nodes }
        return nodes
    }
}
