import type { CodeComponentProps } from "../traits/CodeComponent"
import { CanvasNodeCache } from "./CanvasNodeCache"
import type { PropertyControls } from "framer"
import { isShallowEqual } from "utils/isShallowEqual"

interface CodeComponentPropsCache {
    controls: PropertyControls
    controlProps: CodeComponentProps
    result: { [key: string]: unknown }
}

interface ResolvedCodeComponentPropsCache {
    customCodeComponentProps: { [key: string]: unknown }
    result: { [key: string]: unknown }
}

export class CodeComponentNodeCache extends CanvasNodeCache {
    private controlPropsCurrentVersion: number = -1
    private controlPropsCurrentCache: CodeComponentProps | null
    private controlPropsPreviousVersion: number = -1
    private controlPropsPreviousCache: CodeComponentProps | null

    /** version is the node update count */
    setControlProps = (version: number, value: CodeComponentProps): CodeComponentProps => {
        const canUpdateCache = version > this.controlPropsCurrentVersion
        if (!canUpdateCache) return value

        this.controlPropsPreviousVersion = this.controlPropsCurrentVersion
        this.controlPropsPreviousCache = this.controlPropsCurrentCache
        this.controlPropsCurrentVersion = version
        // FIXME: can we use shallow equal comparison?
        if (this.controlPropsCurrentCache && isShallowEqual(this.controlPropsCurrentCache, value)) {
            return this.controlPropsCurrentCache
        } else {
            this.controlPropsCurrentCache = value
            return value
        }
    }

    /** version is the node update count */
    getControlProps = (version: number): CodeComponentProps | null => {
        switch (version) {
            case this.controlPropsCurrentVersion:
                return this.controlPropsCurrentCache
            case this.controlPropsPreviousVersion:
                return this.controlPropsPreviousCache
            default:
                return null
        }
    }

    private customCodeComponentProps: CodeComponentPropsCache | null = null

    getCustomCodeComponentProps(
        controlProps: CodeComponentProps,
        controls: PropertyControls
    ): { [key: string]: unknown } | undefined {
        if (
            this.customCodeComponentProps &&
            controlProps === this.customCodeComponentProps.controlProps &&
            controls === this.customCodeComponentProps.controls
        ) {
            return this.customCodeComponentProps.result
        }
    }

    updateCustomCodeComponentProps(cache: CodeComponentPropsCache) {
        this.customCodeComponentProps = cache
    }

    clearCodeComponentProps = () => {
        this.controlPropsCurrentVersion = -1
        this.controlPropsCurrentCache = null
        this.controlPropsPreviousVersion = -1
        this.controlPropsPreviousCache = null
        this.customCodeComponentProps = null
    }

    private resolvedProps: ResolvedCodeComponentPropsCache | null = null
    private resolvedPropsWithInlineVariables: ResolvedCodeComponentPropsCache | null = null

    getResolvedCustomCodeComponentProps(
        customCodeComponentProps: { [key: string]: unknown },
        withInlineVariables: boolean
    ): { [key: string]: unknown } | undefined {
        if (withInlineVariables) {
            if (this.resolvedPropsWithInlineVariables?.customCodeComponentProps === customCodeComponentProps) {
                return this.resolvedPropsWithInlineVariables.result
            }
        } else {
            if (this.resolvedProps?.customCodeComponentProps === customCodeComponentProps) {
                return this.resolvedProps.result
            }
        }
    }

    updateResolvedCustomCodeComponentProps(cache: ResolvedCodeComponentPropsCache, withInlineVariables: boolean) {
        if (withInlineVariables) {
            this.resolvedPropsWithInlineVariables = cache
        } else {
            this.resolvedProps = cache
        }
    }

    clearResolvedCodeComponentProps() {
        this.resolvedProps = null
        this.resolvedPropsWithInlineVariables = null
    }
}
