import type { CanvasNode } from "../nodes/CanvasNode"
import type { ImmutableNode } from "../nodes/MutableNode"
import type { CanvasTree } from "../CanvasTree"
import type { ArrayValue } from "./CodeComponent"

import { RootNode } from "../nodes/RootNode"
import { isDrawableNode, isVectorNode } from "../nodes/TreeNode"
import { isCodeComponentNode } from "../nodes/CodeComponentNode"
import { isDeviceNode } from "../nodes/DeviceNode"
import { isReplica, isMaster } from "./Template"
import { TemplateHelper } from "../nodes/TemplateHelper"
import { isShapeContainerNode } from ".."
import { ControlType } from "framer"
import { isSVGNode } from "../nodes/SVGNode"
import { isCanvasComponentNode } from "../nodes/CanvasComponentNode"
import { isVariant } from "./Variant"
import { NodeID } from "../nodes/NodeID"
import { isActiveCanvasComponentInstance } from "../nodes/utils/canvasComponentInstanceHelpers"

export interface WithChildren {
    children: ImmutableNode[]
}

export const basicChildrenDefaults = {}

export const instructionalChildrenDefaults = {}

const key: keyof WithChildren = "children"

export function withChildren(node: ImmutableNode): node is ImmutableNode & WithChildren {
    return key in node && node.children !== undefined
}

export function getChildren(node: CanvasNode): CanvasNode[] {
    return withChildren(node) ? node.children : []
}

export function acceptsChild(tree: CanvasTree, parent: CanvasNode, child: CanvasNode, activeScopeId: NodeID): boolean {
    if (parent === child) {
        return false
    }
    if (parent.originalid) {
        return false
    }
    if (!isDrawableNode(parent) || !withChildren(parent)) {
        return false
    }
    if (child instanceof RootNode) {
        return false
    }
    if (isSVGNode(parent)) {
        return false
    }
    if (isShapeContainerNode(parent) && !isVectorNode(child)) {
        return false
    }
    if (isVectorNode(child)) {
        if (!isShapeContainerNode(parent) && !isVectorNode(parent)) {
            return false
        }
    }
    if (isCodeComponentNode(parent)) {
        return false
    }

    if (isCodeComponentNode(child) && isActiveCanvasComponentInstance(child, activeScopeId)) {
        // Prevent nesting component into itself
        return false
    }
    if (isDeviceNode(child)) {
        return false
    }
    if (isVariant(child)) {
        return isCanvasComponentNode(parent)
    }

    // check if sticking this child in the parent violates any kind of master/replica/codecomponent invariant
    let codeComponentCircle = false
    let hasReplicaNodes = false
    let hasMasterNodes = false
    child.walk(n => {
        if (codeComponentCircle) return
        if (isCodeComponentNode(n)) {
            codeComponentCircle = findComponentIn(tree, parent, n)
        }
        if (!hasReplicaNodes) {
            hasReplicaNodes = isReplica(n)
        }
        if (!hasMasterNodes) {
            hasMasterNodes = isMaster(n)
        }
    })
    if (codeComponentCircle) return false
    if (hasReplicaNodes && TemplateHelper.isInMasterOfReplica(tree, parent, child)) {
        return false
    }
    return true
}

export function findComponentIn(tree: CanvasTree, start: CanvasNode, current: CanvasNode | undefined | null): boolean {
    if (!current) return false
    // Check the `id`s for equality to account for futures
    if (current.id === start.id) return true
    if (isCodeComponentNode(current)) {
        const controlProps = current.getControlProps()
        const controlPropKeys = Object.keys(controlProps)

        for (const controlPropKey of controlPropKeys) {
            const array = controlProps[controlPropKey]?.value
            if (!Array.isArray(array)) continue
            for (const arrayValue of array as ArrayValue[]) {
                if (arrayValue.type !== ControlType.ComponentInstance) continue
                const slotId = arrayValue.value
                if (!slotId || typeof slotId !== "string") continue
                const found = findComponentIn(tree, start, tree.get(slotId))
                if (found) return true
            }
        }
    }

    const children = current.children
    if (!children) return false
    for (let i = 0, il = children.length; i < il; i++) {
        if (findComponentIn(tree, start, children[i])) return true
    }
    return false
}

export type DetectedCycles = { id: string; stack: string[] }[]
export function checkTreeForCycles(tree: CanvasTree, cycles?: DetectedCycles): boolean {
    let seenCycle = false

    function hasCycle(current: CanvasNode, stack: string[], set: Set<string>): void {
        const id = current.id
        if (set.has(id)) {
            seenCycle = true
            if (cycles) {
                cycles.push({ id, stack: stack.slice() })
            }
            return
        }

        set.add(id)
        stack.push(id)

        if (isCodeComponentNode(current)) {
            const controlProps = current.getControlProps()
            const controlPropKeys = Object.keys(controlProps)

            for (const controlPropKey of controlPropKeys) {
                const array = controlProps[controlPropKey]?.value
                if (!Array.isArray(array)) continue
                for (const arrayValue of array as ArrayValue[]) {
                    if (arrayValue.type !== ControlType.ComponentInstance) continue
                    const slotId = arrayValue.value
                    if (!slotId || typeof slotId !== "string") continue
                    const slot = tree.get(slotId)
                    if (!slot) continue
                    hasCycle(slot, stack, set)
                }
            }
        }

        const children = current.children
        if (children) {
            for (let i = 0, il = children.length; i < il; i++) {
                hasCycle(children[i], stack, set)
            }
        }

        set.delete(id)
        stack.pop()
    }

    const set = new Set<string>()
    const stack: string[] = []
    hasCycle(tree.root, stack, set)
    return seenCycle
}
