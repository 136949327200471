import { V67, V66 } from "../types"
import { transform } from "../utils/transform"
import { _, exactCheck } from "../utils/exactCheck"

export function migration_66_67(documentJSON: V66.Tree): V67.Tree {
    const migratedRoot = transform(documentJSON.root, node => {
        switch (node.__class) {
            case "FrameNode":
                return exactCheck({ overlayGrid: undefined, ...node }, _ as V67.TreeNode)

            default:
                return exactCheck(node, _ as V67.TreeNode)
        }
    })
    return { version: 67, root: migratedRoot }
}
